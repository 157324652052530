import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "Orders/List";
import { STATUS, VIEWS } from "Orders/List/constants";

import { COLUMNS } from "Orders/Table/View";

export const getTotal = R.compose(
  R.prop("total"),
  getters.pagination
);

const list = createSelector(
  getters.view,
  getters.ordersList,
  getters.invoicesList,
  getters.transactionsList,
  (view, ordersList, invoicesList, transactionsList) => {
    if (view === VIEWS.ORDERS) {
      return ordersList;
    }
    if (view === VIEWS.INVOICES) {
      return invoicesList;
    }
    if (view === VIEWS.TRANSACTIONS) {
      return transactionsList;
    }
    return {};
  }
);

export const getList = createSelector(
  list,
  (_, { handlers }) => handlers,
  (list, handlers) =>
    R.map(
      row => ({
        ...row,
        handlers
      }),
      R.values(list)
    )
);

const tabs = R.indexBy(R.prop("id"), [
  { id: STATUS.ALL_ORDERS, title: "All Orders" },
  { id: STATUS.ALL_INVOICES, title: "All Invoices" },
  { id: STATUS.ALL_TRANSACTIONS, title: "All Transactions" },
  { id: STATUS.PENDING_APPROVAL, title: "Pending Approval" },
  { id: STATUS.UNPAID, title: "Unpaid" },
  { id: STATUS.PAID, title: "Paid" },
  { id: STATUS.UNFULFILLED, title: "Unfulfilled" },
  { id: STATUS.ARCHIVED, title: "Archived" },
  { id: STATUS.OVERDUE, title: "Overdue" },
  { id: STATUS.FAILED, title: "Failed" },
  { id: STATUS.SUCCESSFUL, title: "Successful" },
  { id: STATUS.REFUNDS, title: "Refunds" },
  { id: STATUS.COMP_ORDERS, title: "Comp. Orders" },
  { id: STATUS.CONFIRMATION_NOT_SENT, title: "Confirmation Not Sent" }
]);

//Fix tabs if needed
const tabsMap = {
  [VIEWS.ORDERS]: R.props(
    [
      STATUS.ALL_ORDERS,
      STATUS.PENDING_APPROVAL,
      STATUS.UNPAID,
      STATUS.COMP_ORDERS,
      STATUS.UNFULFILLED,
      STATUS.SUCCESSFUL,
      STATUS.ARCHIVED
    ],
    tabs
  ),
  [VIEWS.INVOICES]: R.props(
    [
      STATUS.ALL_INVOICES,
      STATUS.OVERDUE,
      STATUS.UNPAID,
      STATUS.PAID,
      STATUS.UNFULFILLED
    ],
    tabs
  ),
  [VIEWS.TRANSACTIONS]: R.props(
    [STATUS.ALL_TRANSACTIONS, STATUS.FAILED, STATUS.SUCCESSFUL, STATUS.REFUNDS],
    tabs
  )
};

export const getTabs = createSelector(
  getters.view,
  getters.tabStats,
  (view, stats) =>
    R.map(
      t => ({ ...t, count: stats[t.id] || 0 }),
      tabsMap[view] || tabsMap[VIEWS.ORDERS]
    )
);

const columnMap = {
  [VIEWS.ORDERS]: R.props(
    [
      "order",
      "contact",
      "account",
      "approval",
      "payment",
      "fulfillment",
      "total",
      "actions"
    ],
    COLUMNS
  ),
  [VIEWS.INVOICES]: R.props(
    [
      "invoice",
      "customer",
      "payment",
      "dueDate",
      "notes",
      "createdAt",
      "total",
      "actions"
    ],
    COLUMNS
  ),
  [VIEWS.TRANSACTIONS]: R.props(
    [
      "amount",
      "invoice",
      "customer",
      "status",
      "transactionId",
      "transactedAt",
      "stripeActions"
    ],
    COLUMNS
  )
};

export const getColumns = createSelector(
  getters.view,
  R.prop(R.__, columnMap)
);
// eslint-disable-line no-underscore-dangle

const viewTitle = {
  [VIEWS.ORDERS]: "Requests",
  [VIEWS.INVOICES]: "Invoices",
  [VIEWS.TRANSACTIONS]: "Transactions"
};

export const getViewTitle = createSelector(
  getters.view,
  R.prop(R.__, viewTitle) // eslint-disable-line no-underscore-dangle
);

const breadcrumbs = {
  [VIEWS.ORDERS]: R.join(" / ", ["ORDERS", "REQUESTS"]),
  [VIEWS.INVOICES]: R.join(" / ", ["ORDERS", "INVOICES"]),
  [VIEWS.TRANSACTIONS]: R.join(" / ", ["ORDERS", "TRANSACTIONS"])
};

export const getBreadcrumbs = createSelector(
  getters.view,
  R.prop(R.__, breadcrumbs) // eslint-disable-line no-underscore-dangle
);

const placeholders = {
  [VIEWS.ORDERS]: "Search by order number...",
  [VIEWS.INVOICES]: "Search by invoice number...",
  [VIEWS.TRANSACTIONS]: "Search by transaction number..."
};

export const getPlaceholder = createSelector(
  getters.view,
  R.prop(R.__, placeholders) // eslint-disable-line no-underscore-dangle
);
