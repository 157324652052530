import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";
import ALL_TIMEZONES from "utils/timezones";
import { getters } from "Organizations/CloneEventModal/model";
import { getters as FormGetters } from "ui-kit/Form/model";
import {
  PAGES,
  NEW_EVENT_MODAL_HEIGHT,
  CONTACT_SUPPORT_MODAL_HEIGHT,
  DEFAULT_MODAL_HEIGHT,
  CREATE_NEW_EVENT_HEIGHT,
  DEFAULT_AVAILABLE_CURRENCIES,
  NAMESPACE
} from "./constants";

const getContentTypeName = id =>
  R.prop(id)({
    users: "Users",
    forms: "Forms",
    modules: "Modules",
    document_requests: "File Requests"
  });

const PAGE_TITLES = {
  [PAGES.SELECT_EVENT]: () => "Choose an event to clone",
  [PAGES.CLONE_OPTIONS]: name => `Select clone options for ${name}`,
  [PAGES.SELECT_CONTENT]: name => `Select content to clone from: ${name}`,
  [PAGES.CONFIRMATION]: name => `Cloning Event: ${name}`,
  [PAGES.CONTACT_SUPPORT]: () => "Contact Support"
};
export const getTimezones = () =>
  R.map(t => ({
    id: t.value,
    label: t.label
  }))(ALL_TIMEZONES);

// @TODO needs to be populated by a getter, fed by API's response data
export const getCurrencies = () => DEFAULT_AVAILABLE_CURRENCIES;

export const getHeaderTitle = createSelector(
  getters.selectedPage,
  getters.selectedEvent,
  (selectedPage, selectedEvent) => {
    return PAGE_TITLES[selectedPage]
      ? PAGE_TITLES[selectedPage](R.propOr("", "name")(selectedEvent))
      : "Start a new event";
  }
);

export const getEventsGroupedByYear = createSelector(
  getters.isLenndAdmin,
  getters.organization,
  getters.allUserEvents,
  getters.eventsFilter,
  (isLenndAdmin, organization, allUserEvents, eventsFilter) =>
    R.compose(
      R.sortBy(item => -item.year),
      R.reduce((accumulator, current) => {
        const year = moment(current.date_from).format("YYYY");
        const yearObjectIndex = R.findIndex(R.propEq("year", year))(
          accumulator
        );
        if (yearObjectIndex === -1) {
          return [
            ...accumulator,
            {
              year,
              list: [current]
            }
          ];
        }
        return [
          ...accumulator.slice(0, yearObjectIndex),
          {
            ...accumulator[yearObjectIndex],
            list: [...accumulator[yearObjectIndex].list, current]
          },
          ...accumulator.slice(yearObjectIndex + 1)
        ];
      }, []),
      R.sortBy(event => moment(event.date_from).format("YYYY-MM-DD")),
      R.filter(event => {
        const isNotDraft = isLenndAdmin
          ? R.propEq("is_draft", false)(event)
          : R.and(
              R.propEq("org_id", organization.id)(event),
              R.propEq("is_draft", false)(event)
            );
        if (R.isEmpty(eventsFilter)) {
          return isNotDraft;
        }
        return (
          isNotDraft &&
          R.contains(R.toLower(eventsFilter), R.toLower(event.name))
        );
      })
    )(allUserEvents)
);

export const getDayMap = createSelector(
  getters.newEventStartDate,
  getters.newEventEndDate,
  getters.dayGroups,
  getters.selectedDays,
  (startDate, endDate, dayGroups, selectedDays) => {
    const map = {};
    dayGroups.forEach(group => {
      group.days.forEach(day => {
        map[day] = {
          color: group.color,
          isSelected: selectedDays.includes(day)
        };
      });
    });
    for (
      let m = moment(startDate);
      m.isBefore(endDate) || m.isSame(endDate);
      m.add(1, "days")
    ) {
      const day = m.format("YYYY-MM-DD");
      if (!R.prop(day)(map)) {
        map[day] = {
          color: "#333333",
          isSelected: selectedDays.includes(day)
        };
      }
    }
    return map;
  }
);

export const modalHeight = createSelector(
  getters.selectedPage,
  selectedPage => {
    if (selectedPage === PAGES.NEW_EVENT) {
      return NEW_EVENT_MODAL_HEIGHT;
    }

    if (selectedPage === PAGES.CONTACT_SUPPORT) {
      return CONTACT_SUPPORT_MODAL_HEIGHT;
    }

    if (selectedPage === PAGES.CREATE_NEW_EVENT) {
      return CREATE_NEW_EVENT_HEIGHT;
    }

    return DEFAULT_MODAL_HEIGHT;
  }
);

export const getEventOptions = createSelector(
  getters.eventOptions,
  getters.excludedContent,
  getters.includeAllRecords,
  (eventOptions, excludedContent, includeAllRecords) => {
    const allExcludedContent = R.compose(
      R.flatten,
      R.map(key => excludedContent[key]),
      R.keys
    )(excludedContent);

    return R.compose(
      contentMap =>
        R.reduce((result, key) => {
          result.push({
            id: key,
            name: getContentTypeName(key),
            rows: contentMap[key],
            selected: R.compose(
              R.all(R.equals(true)),
              R.flatten,
              R.map(item => {
                return [
                  item.selected,
                  R.map(subitem => subitem.selected)(item.related)
                ];
              })
            )(contentMap[key]),
            countOfSelected: R.compose(
              R.length,
              R.filter(R.prop("selected"))
            )(contentMap[key])
          });

          return result;
        }, [])(R.keys(contentMap)),
      R.reduce((result, key) => {
        result[key] = R.map(item => ({
          ...item,
          selected: !allExcludedContent.includes(item.id),
          includeAllRecords: includeAllRecords.includes(item.id),
          related: R.map(subitem => ({
            ...subitem,
            selected: !allExcludedContent.includes(subitem.id)
          }))(item.related)
        }))(eventOptions[key]);

        return result;
      }, {}),
      R.keys
    )(eventOptions);
  }
);

export const getNewEventFields = createSelector(
  state => FormGetters.fields(state, { instanceId: NAMESPACE }),
  R.pluck("value")
);
