import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Summaries from "components/Event/FormsV2/Overview/SubmissionSummary/Summaries/Summaries";
import countSummaries from "components/Event/FormsV2/Overview/utils/summaries/submission";
import getFieldTypeForForms from "utils/Forms/get-field-type";
import getApprovalStatus from "utils/Forms/get-approval-status";

const hasSummaries = (submissions, subforms, details) =>
  subforms.some(field => {
    const summary = countSummaries(submissions, [field], details);
    return (
      summary.catering ||
      summary.inventory ||
      summary.credentials ||
      summary["catalog-item"]
    );
  });

const Report = ({ details, form, readOnly = false }) => {
  const { fields, submissions, title } = form;

  const countOfSubmissions = submissions.filter(
    ({ is_submitted }) => is_submitted
  ).length;
  // bail if no subforms or no submissions or no reports
  if (!fields.some(f => f.type === "subform") || !countOfSubmissions) {
    return <div />;
  }

  const hasSum = hasSummaries(
    submissions,
    fields.filter(({ type }) => type === "subform"),
    details
  );
  if (!hasSum) {
    return <div />;
  }
  return (
    <div styleName="container">
      <div styleName="heading">
        <div styleName="title">{title}</div>
        <div styleName="subTitle">
          {countOfSubmissions}&nbsp;Submission{countOfSubmissions !== 1
            ? "s"
            : ""}
        </div>
      </div>
      <div styleName="list">
        <Summaries
          submissions={submissions}
          details={details}
          fields={fields}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

Report.propTypes = {
  form: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string }))
      .isRequired,
    submissions: PropTypes.arrayOf(
      PropTypes.shape({ is_submitted: PropTypes.bool })
    ).isRequired,
    title: PropTypes.string
  }).isRequired,
  details: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

export default CSSModules(Report, css);
