import { get } from "lodash";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

export const isFetching = (state, recordId) =>
  get(state, ["orders", "orderSummaryByRecord", "fetching", "byId", recordId]);

export const orderSummaryByRecord = (state, recordId) =>
  get(
    state,
    [
      "orders",
      "orderSummaryByRecord",
      "orderSummaryByRecord",
      "byId",
      recordId
    ],
    {
      variants: [],
      review: []
    }
  );

const itemTypeSummary = itemTypeId => (state, recordId) => {
  return get(
    orderSummaryByRecord(state, recordId).variants.find(
      v => v.id === itemTypeId
    ),
    "items",
    []
  );
};

export const passesSummary = itemTypeSummary(CREDENTIAL_TYPE_ID);
export const mealsSummary = itemTypeSummary(MEAL_TYPE_ID);
