import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";

import Questions from "EventLight/Common/Items/Questions/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="widgets"
        title="Questions"
        description="Manage questions and assign them to items"
      />
      <Questions />
    </Page>
  </>
);

export default Layout;
