import PropTypes from "prop-types";
import React from "react";
import Editor from "../../Editors/MultipleSelectEditor";

const MultipleSelect = ({ onChange, value, options, usePortal }) => (
  <Editor
    onChange={onChange}
    options={options}
    value={value}
    usePortal={usePortal}
  />
);

MultipleSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultipleSelect;
