/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types";
import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import IntegrationSuccessFail from "../Modals/IntegrationSuccessFail";
import SelectEventbriteEventModal from "../Modals/SelectEvent";
import MapPassTypesModal from "components/Event/Settings/Credentials/Integrations/Ticketing/Modals/EditIntegration";
import NotifyModal from "Passes/SetupIntegrations/Modals/NotifyModal";
import ConnectModal from "Passes/SetupIntegrations/Modals/ConnectModal";
import SettingsModal from "Passes/SetupIntegrations/Modals/SettingsModal";
import { INTEGRATIONS as INTEGRATIONS_LIST } from "utils/integration-types";
import SelectEventModal from "Passes/SetupIntegrations/Modals/SelectEvent";

class TicketingWrapper extends Component {
  getCredentialGroups = () => {
    this.props.getItemGroupsByEventAndType(
      this.props.params.eventId,
      CREDENTIAL_TYPE_ID
    );
  };

  addCredentials = (eventbriteEvent, ticketTypeIds) => {
    if (!ticketTypeIds.length) return null;
    return this.props
      .syncTicketTypes(
        this.props.providerConfiguration,
        this.props.params.eventId,
        ticketTypeIds
      )
      .then(() => {
        this.props.showSnackbar({
          message: `${ticketTypeIds.length} ${
            this.props.providerConfiguration.provider.name
          } credential ${
            ticketTypeIds.length !== 1 ? "s have" : " has"
          } been added`
        });
        this.getCredentialGroups();
        this.props.getIntegrations({
          eventId: this.props.params.eventId
        });
      });
  };

  showSelectEventbriteEventModal = () => {
    this.props.router.replace({
      pathname: `/event/${this.props.params.eventId}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/items`
    });
    this.props.showModal({
      content: (
        <SelectEventbriteEventModal
          hideModal={this.props.hideModal}
          onSelect={() => {
            this.props.hideModal();
            this.showMapIntegrationPassTypesModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSelectEventModal = providerId => {
    const provider = this.props.providerConfigurations.find(
      p => p.id === providerId
    );
    const configuration = this.props.ticketingProviderConfigurations.find(
      p => p.provider.id === providerId
    );

    this.props.showModal({
      content: (
        <SelectEventModal
          provider={provider}
          providerConfiguration={configuration}
          onDone={async ({ resetProviderMapping = false }) => {
            await this.props.getTicketingProviderConfigurations(
              this.props.eventDetails.id
            );

            if (resetProviderMapping) {
              await this.props.deleteItemProviderId({
                eventId: this.props.params.eventId,
                providerId: provider.id
              });
            }

            this.showMapIntegrationPassTypesModal(configuration);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showIntegrationCallbackModal = success => {
    this.props.showModal({
      content: (
        <IntegrationSuccessFail
          success={success}
          onSuccess={() =>
            this.showSelectEventbriteEventModal({ initial: true })
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showMapIntegrationPassTypesModal = configuration => {
    this.props.showModal({
      content: (
        <MapPassTypesModal
          providerConfiguration={
            configuration || this.props.providerConfiguration
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditIntegrationSettingsModal = () => {
    this.props.showModal({
      content: (
        <SettingsModal
          name={this.props.provider.name}
          providerConfiguration={this.props.providerConfiguration}
          provider={this.props.provider}
          onDone={() => {
            this.props.getTicketingProviderConfigurations(
              this.props.params.eventId
            );
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showIntegrationNotifyModal = () => {
    this.props.showModal({
      content: (
        <NotifyModal
          integrationName={this.props.provider ? this.props.provider.name : ""}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showIntegrationConnectModal = authConfig => {
    const RETURN_TO = `${window.__LENND_APP_URL__}/event/${this.props.params.eventId}/passes/setup/integrations`;

    const REDIRECT_URI = encodeURIComponent(
      `${window.__LENND_APP_URL__}/integration/eventbrite/auth?returnTo=${RETURN_TO}&eventId=${this.props.params.eventId}`
    );

    if (authConfig.authType === "oauth2_code") {
      const clientId = authConfig.form.find(v => v.name === "client_id").value;
      window.location = `${authConfig.submit.form}?response_type=code&client_id=${clientId}&redirect_uri=${REDIRECT_URI}`;
      return true;
    }

    this.props.showModal({
      content: (
        <ConnectModal
          authConfig={authConfig}
          name={this.props.provider.name}
          provider={this.props.provider}
          onDone={() =>
            this.onConnect({
              provider: this.props.provider,
              selectEvent: [
                INTEGRATIONS_LIST.eventbrite.id,
                INTEGRATIONS_LIST.glownet.id,
                INTEGRATIONS_LIST.token.id
              ].includes(this.props.provider.id)
            })
          }
        />
      ),
      wrapper: ModalWrapper
    });
    return true;
  };

  configureProviderForEvent = async () => {
    if (!this.props.providerConfiguration) {
      const authConfig = await this.props.getAuth1stStep(this.props.provider);
      this.showIntegrationConnectModal(authConfig);
    }
  };

  onConnect = async ({ provider, selectEvent }) => {
    await this.props.getTicketingProviderConfigurations(
      this.props.params.eventId
    );

    if (selectEvent) {
      this.showSelectEventModal(provider.id);
    } else {
      this.showMapIntegrationPassTypesModal();
    }
  };

  disconnect = async () => {
    await this.props.removeProviderFromEvent(
      this.props.providerConfiguration,
      this.props.params.eventId
    );

    this.props.getTicketingProviderConfigurations(this.props.params.eventId);

    this.props.deleteItemProviderId({
      eventId: this.props.params.eventId,
      providerId: this.props.providerConfiguration.provider.id
    });

    this.props.showSnackbar({
      message: "Integration has been disconnected"
    });
  };

  render() {
    return this.props.children({
      configureProviderForEvent: this.configureProviderForEvent,
      disconnect: this.disconnect,
      showEditIntegrationModal: this.showMapIntegrationPassTypesModal,
      showEditIntegrationSettingsModal: this.showEditIntegrationSettingsModal,
      showIntegrationCallbackModal: this.showIntegrationCallbackModal,
      showSelectEventModal: this.showSelectEventModal,
      addCredentials: this.addCredentials
    });
  }
}

TicketingWrapper.propTypes = {
  getIntegrations: PropTypes.func.isRequired,
  syncTicketTypes: PropTypes.func.isRequired,
  configureProviderForEvent: PropTypes.func.isRequired,
  removeProviderFromEvent: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  provider: PropTypes.object,
  providerConfiguration: PropTypes.object,
  ticketTypes: PropTypes.array.isRequired,
  getTicketTypes: PropTypes.func.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired,
  showModal: PropTypes.func.isRequired
};

export default TicketingWrapper;
