export const NAMESPACE = "EventLightContentModal";

export const FORM_ID = "EventLightContentModal";

export const FIELD_IDS = {
  TITLE: "title",
  THUMBNAIL_URL: "thumbnail_url",
  URL: "url",
  VISIBLE: "visible",
  ORDER: "order",
  LABEL: "label",
  TAGS: "tags",
  DESCRIPTION: "description"
};
