import * as R from "ramda";
import { createSelector } from "reselect";

import CellAction from "./View/CellAction";

import {
  ACTIVITY_LOG_IDS,
  RECORD_TYPES,
  ACTIVITY_TYPE_FILTERS,
  ACTIVITY_INSTANCE_ID
} from "./constants";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import * as EventUserSelectors from "redux/modules/eventUsers/selectors";
import * as OrgUserSelectors from "redux/modules/organization/users/selectors";

import { getters } from "./model";

export const getSearchAndFilters = createSelector(
  state =>
    SearchBarGetters.searchTerm(state, {
      instanceId: ACTIVITY_INSTANCE_ID
    }),
  getters.filters,
  (searchTerm, filters) => ({
    search: searchTerm,
    filters
  })
);

export const getFilteredRows = createSelector(
  getters.days,
  R.identity
);

export const getIsOpenedActivityLog = createSelector(
  (state, props) => R.prop("id", props),
  getters.openedRows,
  (id, openedRows) =>
    R.compose(
      R.not,
      R.isNil,
      R.propOr(undefined, id)
    )(openedRows)
);

export const getLogRows = createSelector(
  (state, props) => R.prop("rows", props),
  (state, props) => R.prop("activityType", props),
  (state, props) => R.prop("moduleId", props),
  (rows, activityType, moduleId) =>
    R.map(({ id, ...log }) => ({
      id,
      moduleId,
      action: activityType,
      ...R.compose(
        R.mergeAll,
        R.map(key => ({ [key]: { value: R.prop(key, log) } })),
        R.keys
      )(log)
    }))(rows)
);

export const getColsByType = createSelector(
  (state, props) => R.prop("activityType", props),
  activityType =>
    R.propOr(
      [
        {
          id: ACTIVITY_LOG_IDS.RECORD,
          name: "Record",
          type: "text"
        },
        {
          id: ACTIVITY_LOG_IDS.TIMESTAMP,
          name: "Date/Timestamp",
          type: "text"
        },
        {
          id: ACTIVITY_LOG_IDS.ACTIONS,
          name: "Actions",
          Cell: CellAction
        }
      ],
      activityType,
      {
        [RECORD_TYPES.SEND_EMAIL]: [
          {
            id: ACTIVITY_LOG_IDS.RECIPIENT,
            name: "Recipient",
            type: "text"
          },
          {
            id: ACTIVITY_LOG_IDS.EMAIL,
            name: "Email",
            type: "text"
          },
          {
            id: ACTIVITY_LOG_IDS.TIMESTAMP,
            name: "Date/Timestamp",
            type: "text"
          },
          {
            id: ACTIVITY_LOG_IDS.ACTIONS,
            name: "Actions",
            Cell: CellAction
          }
        ]
      }
    )
);

const getUsers = createSelector(
  getters.isEventSection,
  EventUserSelectors.users,
  OrgUserSelectors.users,
  (isEvent, eventUsers, orgUsers) => (isEvent ? eventUsers : orgUsers)
);

export const getFilterUser = createSelector(
  getUsers,
  R.compose(
    R.map(({ user_id, user_fname, user_lname, user_email }) => ({
      id: user_id,
      name: `${user_fname} ${user_lname}`.toLowerCase(),
      email: user_email
    })),
    R.filter(R.prop("is_event_user"))
  )
);

export const filterSelected = createSelector(
  (state, props) => R.prop("type", props),
  getters.filters,
  (filterType, filters) => R.propOr([], filterType, filters)
);

export const getFilterByActivityType = createSelector(
  R.always(ACTIVITY_TYPE_FILTERS),
  R.identity
);
