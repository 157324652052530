/* eslint-disable no-underscore-dangle */
import * as R from "ramda";
import { connect } from "react-redux";

import React from "react";

import { TABLE_ACTIONS } from "../constants";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { actions as ActionsPopoverActions } from "ui-kit/ActionsPopover/model";
import { eventDetails } from "redux/modules/event/selectors";
import ActionsPopover from "ui-kit/ActionsPopover/View";
import Tooltip from "components/Global/Tooltip";
import {
  CheckIcon,
  Div,
  DownFilledIcon,
  MediumOutlineButton,
  SmallShadedBox,
  // Text1,
  Text2
} from "components/Base";

import { noop, withProps } from "utils/General";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import StatusDropdown from "./FieldTypes/StatusDropdown";

const decorate = connect(
  state => ({
    cando: canEventUserDo(state),
    eventDetails: eventDetails(state)
  }),
  {
    executeAction: ActionsPopoverActions.executeAction
  }
);

const LABEL = {
  [CREDENTIAL_TYPE_ID]: "Passes",
  [MEAL_TYPE_ID]: "Meals"
};

const ActionsCell = decorate(({ data, cando = R.always(false) }) => (
  <Div display="data">
    <ActionsPopover
      actions={[
        { id: R.prop("SEND_LINK", TABLE_ACTIONS), name: "Send Link" },
        { id: R.prop("SEND_EMAIL", TABLE_ACTIONS), name: "Send Email" },
        {
          id: R.prop("COPY_SHARE_LINK", TABLE_ACTIONS),
          name: "Copy Share Link"
        }
      ]}
      data={data}
    >
      <MediumOutlineButton RightIcon={DownFilledIcon} mr={2}>
        Share
      </MediumOutlineButton>
    </ActionsPopover>
    <ActionsPopover
      actions={[
        {
          id: R.prop("OPEN_GROUP_PROFILE", TABLE_ACTIONS),
          name: "Open Group Profile"
        },
        cando(
          `${R.path(["credentials", "id"], STANDARD_MODULES)}_edit_line_items`
        )
          ? {
              id: R.prop("EDIT_ALLOCATIONS", TABLE_ACTIONS),
              name: "Edit Allocations"
            }
          : null,
        {
          id: R.prop("VIEW_GUEST_LIST", TABLE_ACTIONS),
          name: "Go to Guest List"
        }
      ].filter(R.identity)}
      data={data}
    >
      <MediumOutlineButton>More...</MediumOutlineButton>
    </ActionsPopover>
  </Div>
));

const AllAssigned = withProps({ title: "All assigned" })(CheckIcon);

const CredentialCell = decorate(
  ({ data, field, executeAction = noop, cando = R.always(false) }) => {
    const value = R.pathOr({}, ["itemCounts", R.prop("id", field)], data);
    const allocatedCount = R.prop("allocatedCount", value);
    const pendingRequestCount = R.prop("pendingRequestCount", value);
    if (!allocatedCount && !pendingRequestCount) {
      if (
        cando(
          `${R.path(["credentials", "id"], STANDARD_MODULES)}_add_line_items`
        )
      ) {
        return (
          <Text2
            color="primary5"
            onClick={() =>
              executeAction({
                actionId: R.prop("ADD_ALLOCATIONS", TABLE_ACTIONS),
                data: { ...data, itemTypeId: R.prop("id", field) }
              })
            }
            bold
          >
            + Add {R.propOr("Items", R.prop("id", field), LABEL)}
          </Text2>
        );
      }
      return <span>&mdash;</span>;
    }

    const isComplete =
      allocatedCount > 0 && allocatedCount === R.prop("assignedCount", value);
    const remaining = Math.max(
      allocatedCount - R.prop("assignedCount", value),
      0
    );

    return (
      <Div
        display="row.flex-start.center"
        onClick={() =>
          executeAction({
            actionId: R.prop("SHOW_SUMMARY", TABLE_ACTIONS),
            data: { ...data, itemTypeId: R.prop("id", field) }
          })
        }
      >
        <Tooltip
          tooltip={
            isComplete ? (
              <span>
                <strong>All passes</strong> assigned to people <br />
                *some items may be excluded
              </span>
            ) : (
              <span>
                <strong>{R.prop("assignedCount", value)} passes</strong>{" "}
                assigned to people <br />
                {remaining && allocatedCount > 0 ? (
                  <span>
                    <strong>{remaining} passes</strong> left to assign
                  </span>
                ) : (
                  <span>
                    <strong>{pendingRequestCount} requests </strong>
                    pending review
                  </span>
                )}
                <br />
                *some items may be excluded
              </span>
            )
          }
        >
          <span>
            <SmallShadedBox
              pr={3}
              LeftIcon={isComplete ? AllAssigned : undefined}
              color={isComplete ? "success8" : undefined}
            >
              {R.prop("assignedCount", value)} of&nbsp;
              <strong>{allocatedCount}</strong>
            </SmallShadedBox>
          </span>
        </Tooltip>
      </Div>
    );
  }
);

const GuestLink = ({
  data = {},
  executeAction = noop,
  eventDetails = noop
}) => {
  const urlTxt = `${R.prop(
    "__LENND_APP_URL__",
    window
  )}/assignment-manager/${R.prop("slug", eventDetails)}/${R.prop(
    "uuid",
    eventDetails
  )}/${R.prop("groupId", data)}`;
  return (
    <Text2
      bold
      onClick={() =>
        executeAction({
          actionId: R.prop("VIEW_GUEST_LIST", TABLE_ACTIONS),
          data
        })
      }
      color="primary5"
      title={urlTxt}
    >
      {urlTxt}
    </Text2>
  );
};

const makeTitle = R.compose(
  R.join(", "),
  R.filter(R.identity),
  R.map(R.prop("name"))
);

const PrimaryContactsCell = decorate(
  ({ data, cando = R.always(false), executeAction = noop }) => {
    const value = R.prop("primaryContacts", data);
    if (value && R.prop("length", value)) {
      return (
        <Text2
          title={makeTitle(value)}
          onClick={() =>
            executeAction({
              actionId: R.prop("ADD_CONTACT", TABLE_ACTIONS),
              data
            })
          }
          color="primary5"
        >
          {R.path([0, "name"], value)}{" "}
          {R.prop("length", value) > 1
            ? `+ ${R.prop("length", value) - 1}`
            : ""}
        </Text2>
      );
    }

    if (cando(`${R.path(["contacts", "id"], STANDARD_MODULES)}_add`)) {
      return (
        <Text2
          bold
          onClick={() =>
            executeAction({
              actionId: R.prop("ADD_CONTACT", TABLE_ACTIONS),
              data
            })
          }
          color="primary5"
        >
          + Add Person
        </Text2>
      );
    }
    return "--";
  }
);

const PendingRequestsCell = decorate(({ data }) => {
  const value = R.prop("pendingRequestCount", data);
  if (value > 0) {
    const toolTipText =
      "This group has orders that are pending review. To resolve, go into Passes or Catering and verify that orders are approved or denied";
    const iconStyle = { color: "rgb(168, 94, 247)", cursor: "pointer" };
    return (
      <Div display="data.flex-start.center">
        <Text2 pb={1} mr={3}>
          {value} pending
        </Text2>
        <Tooltip tooltip={toolTipText}>
          <i className={"material-icons"} styleName={"icon"} style={iconStyle}>
            info
          </i>
        </Tooltip>
      </Div>
    );
  }
  return <Text2>&mdash;</Text2>;
});

const StatusCell = decorate(({ data }) => (
  <StatusDropdown
    data={data}
    options={[
      { label: "Enabled", value: true },
      { label: "Not Enabled", value: false }
    ]}
  />
));

// const AllocationCell = (key, data) => {
//   return (
//     <Div display="row.flex-end">
//       <Text1>{R.path(["ordersSummary", key], data)}</Text1>
//     </Div>
//   );
// };

const columns = {
  actions: ActionsCell,
  // allocationAllocated: ({ data }) => AllocationCell("allocatedCount", data),
  // allocationAssigned: ({ data }) => AllocationCell("assignedCount", data),
  // allocationIssued: ({ data }) => AllocationCell("issuedCount", data),
  // allocationRequested: ({ data }) => AllocationCell("requestedCount", data),
  groupName: ({ data }) => (
    <div
      style={{
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {data.groupName}
    </div>
  ),
  guestLink: GuestLink,
  guestListEnabled: StatusCell,
  pendingRequests: PendingRequestsCell,
  primaryContacts: PrimaryContactsCell,
  [CREDENTIAL_TYPE_ID]: CredentialCell,
  [MEAL_TYPE_ID]: CredentialCell
};

export default columns;
