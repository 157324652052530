import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { eventDetails } from "redux/modules/event/selectors";
import { recordType } from "redux/modules/modules/recordTypes/selectors";
import { guestListWithItems } from "redux/modules/guestLists/selectors";
import { getGuestListData } from "redux/modules/guestLists/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { guestLists } from "redux/modules/guestLists/selectors";

function mapStateToProps(state, props) {
  const guestListsToPass = guestLists(state);
  const guestListSettings = guestListsToPass.find(
    g => g.record_type_id === props.params.recordTypeId
  );

  return {
    eventDetails: eventDetails(state),
    guestList: recordType(
      state,
      STANDARD_MODULES.accounts.id,
      props.params.recordTypeId
    ),
    listData: guestListWithItems(state, props.params.recordTypeId),
    cando: canEventUserDo(state),
    guestListSettings: guestListSettings || {}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getGuestListData,
      showSnackbar
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
