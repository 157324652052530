import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { sortBy } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Dropdown } from "components/Base";

@CSSModules(css)
class TemplateSelect extends Component {
  @autobind
  onSelect(selected) {
    this.props.onSelect(selected ? { id: selected.value } : null);
  }

  render() {
    const { templates, selected } = this.props;
    const sorted = sortBy(templates, t => t.title);
    return (
      <Dropdown
        options={sorted.map(({ id, title }) => ({
          label: title,
          value: id
        }))}
        selected={selected ? selected.id : null}
        onChange={this.onSelect}
      />
    );
  }
}

export default TemplateSelect;
