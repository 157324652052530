import { createContext } from "redux-mvc";

import module from "../index";
import Layout from "./Layout";

const decorate = createContext({
  module,
  options: {
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels"
    ],
    persist: false
  }
});

export default decorate(Layout);
