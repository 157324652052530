import * as R from "ramda";
import { findBy } from "utils/General";
import moment from "moment";

const hasApprovals = record =>
  Boolean(record.approval_manifest && record.approval_manifest.id);

const isUserAnApprover = (record, userId) => {
  return hasApprovals(record)
    ? record.approval_manifest.all_approvers.some(a => a.user_id === userId)
    : false;
};

const isUserACurrentApprover = (record, userId) => {
  return hasApprovals(record)
    ? record.approval_manifest.current_approvers.some(a => a.user_id === userId)
    : false;
};

const getUserAsApprover = (record, userId) => {
  return hasApprovals(record)
    ? record.approval_manifest.all_approvers.find(a => a.user_id === userId)
    : null;
};

const getApprovalStatus = record => {
  if (!record.approval_manifest || !record.approval_manifest.id)
    return "approved";
  return hasApprovals(record) ? record.approval_manifest.status : null;
};

const getCurrentApprovers = record => {
  return hasApprovals(record) ? record.approval_manifest.current_approvers : [];
};

const isReviewed = record =>
  ["approved", "rejected"].includes(getApprovalStatus(record));

const isPending = record => getApprovalStatus(record) === "pending";

const isApproved = record => getApprovalStatus(record) === "approved";

const isRejected = record => getApprovalStatus(record) === "rejected";

const isManifestReviewed = manifest =>
  isReviewed({ approval_manifest: manifest });

const isManifestPending = manifest =>
  isPending({ approval_manifest: manifest });

const isManifestApproved = manifest =>
  isApproved({ approval_manifest: manifest });

const isManifestRejected = manifest =>
  isRejected({ approval_manifest: manifest });

const parseApprovalGroup = (approval_manifest, approverGroupIndex) => {
  const groups = R.compose(
    R.sortBy(x => x[0].sequence),
    R.values,
    R.groupBy(R.prop("sequence")),
    R.map(
      ({
        is_any,
        review,
        sequence,
        user_fname,
        user_lname,
        user_photo_url,
        user_id,
        reviewed_at
      }) => {
        return {
          reviewed_at,
          isAny: is_any,
          review,
          sequence,
          name: `${user_fname} ${user_lname}`,
          photoURL: user_photo_url,
          id: user_id
        };
      }
    )
  )(approval_manifest.all_approvers);

  let isApproved = true;
  let isDenied = false;
  let numPending = 0;
  let numToUser = 0;
  let totalNumApprovers = 0;
  let dateReviewed = null;

  R.addIndex(R.forEach)((group, sequence) => {
    if (group[0].isAny) {
      totalNumApprovers = totalNumApprovers + 1;
      if (!R.any(R.equals("approve"), R.map(R.prop("review"), group))) {
        isApproved = false;
        numPending = numPending + 1;
        if (sequence < approverGroupIndex) {
          numToUser = numToUser + 1;
        }
      }
    } else {
      totalNumApprovers = totalNumApprovers + R.length(group);
      if (!R.all(R.equals("approve"), R.map(R.prop("review"), group))) {
        isApproved = false;
        const numApproversPending = R.length(
          R.filter(R.propEq("review", null), group)
        );
        numPending = numPending + numApproversPending;

        if (sequence < approverGroupIndex) {
          numToUser = numToUser + numApproversPending;
        }
      }
    }
    if (R.any(R.equals("reject"), R.map(R.prop("review"), group))) {
      isDenied = true;
    }
  }, groups);

  const currentGroupIndex = R.path(
    ["current_approvers", 0, "sequence"],
    approval_manifest
  );

  if (isDenied) {
    dateReviewed = R.compose(
      R.prop("reviewed_at"),
      findBy("review", "reject"),
      R.flatten
    )(groups);
  } else {
    if (isApproved) {
      dateReviewed = R.compose(
        R.prop("reviewed_at"),
        R.head,
        R.reverse,
        R.sortBy(R.prop("reviewed_at")),
        R.filter(R.propEq("review", "approve")),
        R.flatten
      )(groups);
    }
  }

  return {
    numPending,
    isApproved,
    isDenied,
    currentGroupIndex,
    groups,
    numToUser,
    totalNumApprovers,
    dateReviewed: moment(dateReviewed).fromNow()
  };
};

const parseApproval = ({ record, userId }) => {
  return R.compose(
    ({ approval_manifest, userId, isApprover, userApprover, ...rest }) => {
      if (!R.isEmpty(approval_manifest) && !R.isNil(approval_manifest)) {
        let userApprover = findBy("user_id", userId)(
          approval_manifest.all_approvers
        );

        const approverGroupIndex =
          isApprover && !R.isNil(userApprover)
            ? R.prop("sequence", userApprover)
            : null;

        const approverApproved = !R.isNil(userApprover)
          ? R.propEq("review", "approve", userApprover)
          : false;

        const approverReviewed = !R.isNil(userApprover)
          ? !R.propEq("review", null, userApprover)
          : false;

        const atApproverStage = R.contains(
          userId,
          R.map(R.prop("user_id"), approval_manifest.current_approvers)
        );

        return {
          approverGroupIndex,
          approverApproved,
          approverReviewed,
          atApproverStage,
          approval_manifest,
          userId,
          isApprover,
          userApprover,
          ...rest,
          ...parseApprovalGroup(approval_manifest, approverGroupIndex)
        };
      }
      return {
        approval_manifest,
        userId,
        isApprover,
        userApprover,
        ...rest
      };
    },
    ({ record, userId }) => ({
      approval_manifest: record.approval_manifest,
      hasApprovals: hasApprovals(record),
      approvalStatus: getApprovalStatus(record),
      currentApprovers: getCurrentApprovers(record),
      isApprover: isUserAnApprover(record, userId),
      userId,
      approverReview: R.prop(null, "review", getUserAsApprover(record, userId)),
      isCurrentApprover: isUserACurrentApprover(record, userId)
    })
  )({ record, userId });
};

export {
  hasApprovals,
  isUserAnApprover,
  isUserACurrentApprover,
  getUserAsApprover,
  getApprovalStatus,
  getCurrentApprovers,
  parseApproval,
  // handle record with manifest as attribute
  isReviewed,
  isApproved,
  isPending,
  isRejected,
  // handle raw manifest
  isManifestReviewed,
  isManifestApproved,
  isManifestPending,
  isManifestRejected
};
