import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";

const styles = {
  container: {
    fontSize: 26,
    color: "white",
    flex: 1,
    alignItems: "center",
    display: "flex"
  }
};

@Radium
export default class HeaderTitle extends Component {
  render() {
    return <div style={styles.container}>{this.props.title}</div>;
  }
}
