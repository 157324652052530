import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { bindInstance } from "redux-mvc";
import PriceSelector from "Orders/CreateOrderModal/PriceSelector";

import { actions, getters } from "ui-kit/MiniItemsSelector";
import {
  MODE_SINGLE_SELECT,
  MODE_MULTIPLE_SELECT,
  MODE_PRICE_SELECT
} from "ui-kit/MiniItemsSelector/constants";

import {
  getSelectedVariantType,
  getIsSingleSelect,
  getSelectedItemsMap,
  getHasSearchTerm
} from "ui-kit/MiniItemsSelector/selectors";

import {
  Div,
  SmallCheckbox,
  NumberSelector,
  SmallFilledBox
} from "components/Base";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    itemGroups: getSelectedVariantType(state, props),
    selectedItemsMap: getSelectedItemsMap(state, props),
    selectedPricesMap: getters.selectedItemsPrice(state, props),
    hasSearchTerm: getHasSearchTerm(state, props),
    isSingleSelect: getIsSingleSelect(state, props),
    mode: getters.mode(state, props)
  }),
  bindInstance({
    selectGroup: actions.selectGroup,
    deselectGroup: actions.deselectGroup,
    toggleItem: actions.toggleItem,
    setItemCount: actions.setItemCount,
    setItemPrice: actions.setItemPrice
  })
);

const VariantsList = ({
  itemGroups = [],
  selectedItemsMap = {},
  selectedPricesMap = {},
  hasSearchTerm = false,
  mode = MODE_SINGLE_SELECT,
  selectGroup = noop,
  deselectGroup = noop,
  toggleItem = noop,
  setItemCount = noop,
  setItemPrice = noop
}) => (
  <Div
    style={{
      overflowY: "auto"
    }}
    flex={1}
  >
    {itemGroups.length ? (
      R.map(group => (
        <Div key={group.id} mb={8}>
          <Div
            fs={1}
            fw={3}
            color="gray6"
            px={5}
            display="row.space-between.center"
          >
            <Div fs={2} color="gray6" truncate uppercase>
              {group.name}
            </Div>
            {!hasSearchTerm ? (
              <Div display="row.flex-end.center">
                <Div uppercase mr={2}>
                  Select
                </Div>
                <Div
                  mr={2}
                  color="purple8"
                  underline
                  onClick={() => selectGroup(group)}
                >
                  All
                </Div>
                <Div
                  color="purple8"
                  underline
                  onClick={() => deselectGroup(group)}
                >
                  None
                </Div>
              </Div>
            ) : null}
          </Div>
          <Div>
            {R.map(item => (
              <Div
                key={item.id}
                shadow={1}
                bra={1}
                bg="white"
                my={2}
                px={1}
                py={1}
                display="row.flex-start.center"
                onClick={
                  item.disabled
                    ? undefined
                    : () =>
                        toggleItem({
                          id: item.id,
                          priceId: R.compose(
                            R.prop("id"),
                            R.find(R.propEq("is_default", true))
                          )(item.prices ? item.prices : [])
                        })
                }
              >
                <SmallCheckbox
                  selected={selectedItemsMap[item.id]}
                  disabled={item.disabled}
                />
                <Div
                  display="row.space-between.center"
                  truncate
                  ml={2}
                  fw={3}
                  width={1}
                  color={item.disabled ? "gray4" : "gray7"}
                >
                  {item.name}
                  {item.label && mode !== MODE_MULTIPLE_SELECT && (
                    <SmallFilledBox bg="neutral3">{item.label}</SmallFilledBox>
                  )}
                </Div>
                {selectedItemsMap[item.id] && mode === MODE_MULTIPLE_SELECT ? (
                  <Div
                    flex={1}
                    display="row.flex-end.center"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <NumberSelector
                      value={selectedItemsMap[item.id]}
                      onChangeValue={count => {
                        setItemCount({ id: item.id, count });
                      }}
                      minValue={1}
                    />
                  </Div>
                ) : null}
                {selectedItemsMap[item.id] &&
                R.length(R.propOr([], "prices", item)) &&
                mode === MODE_PRICE_SELECT ? (
                  <Div
                    flex={1}
                    display="row.flex-end.center"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <PriceSelector
                      prices={item.prices}
                      selectedPriceId={selectedPricesMap[item.id]}
                      onChange={priceId => {
                        setItemPrice({ id: item.id, priceId });
                      }}
                    />
                  </Div>
                ) : null}
              </Div>
            ))(group.items)}
          </Div>
        </Div>
      ))(itemGroups)
    ) : (
      <Div display="row.center.center" my={8} fs={2}>
        {hasSearchTerm
          ? "No items matched your search"
          : "No items are available"}
      </Div>
    )}
  </Div>
);

export default decorate(VariantsList);
