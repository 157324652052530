import * as R from "ramda";
import { getters } from "../model";

import { getFieldId, getSubmissionId } from "../utils";

export const getFieldById = (state, props) =>
  R.path(["byId", getFieldId(props)], getters.fields(state, props));

export const getSubmissionById = (state, props) =>
  R.path(["byId", getSubmissionId(props)], getters.submissions(state, props));

/*
  event templates by uuid
 */
export const getEventTemplateByUUID = (state, props) =>
  R.path(["byId", props.eventUUID], getters.eventTemplates(state, props));

/*
  event templates by uuid
*/

export const getAllRecordReferences = getters.recordReferences;

export const getAllRecordReferencesById = R.compose(
  R.prop("byId"),
  getters.recordReferences
);

export const getAllFieldReferencesById = R.compose(
  R.prop("byId"),
  getters.fieldReferences
);

// support articles
export const getSupportArticles = R.compose(
  R.prop("articles"),
  getters.support
);

export const getSupportArticlesById = (state, props) =>
  R.compose(R.prop(props.id), R.prop("articles"), getters.support)(state);

const emptyCategories = [];
export const getZoneCategoriesIdsByEventId = (state, props) => {
  if (!props.eventId) {
    return emptyCategories;
  }
  const eventCategories = R.path(
    ["byEventId", props.eventId],
    getters.zoneCategories(state, props)
  );
  return eventCategories || emptyCategories;
};

export const getZoneCategoriesByEventId = (state, props) => {
  const eventCategories = getZoneCategoriesIdsByEventId(state, props);
  const categories = R.propOr({}, "byId", getters.zoneCategories(state, props));
  // eslint-disable-next-line no-underscore-dangle
  return R.map(R.prop(R.__, categories), eventCategories);
};
