/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import SelectFieldsModal from "Orders/ImportModal/View/SelectFieldsModal";
import {
  Div,
  Text3,
  Text2,
  Text1,
  TinyToggle,
  MediumFilledButton,
  SettingsIcon
} from "components/Base";
import { RadioButton } from "material-ui/RadioButton";

import { noop, mapProps } from "utils/General";

import { actions, getters } from "Orders/ImportModal";
import {
  getCustomEnabledSections,
  isContactsModule
} from "Orders/ImportModal/selectors";

import LoadingOverlay from "ui-kit/LoadingOverlay";

const RowLayout = ({ onClick = noop, border, children, hover }) => (
  <Div
    display="row.flex-start.center"
    onClick={onClick}
    bb={border ? 1 : 0}
    bc="neutral2"
    p={2}
    bg={hover ? { default: "transparent", hover: "neutral2" } : "transparent"}
  >
    {children}
  </Div>
);

const RadioRow = ({
  id,
  icon: Icon,
  name = "",
  description = "",
  border,
  enabled,
  primaryAction,
  section
}) => (
  <RowLayout
    border={border}
    hover
    onClick={() => primaryAction(id, { meta: { section } })}
  >
    <RadioButton value={id} checked={enabled} style={{ width: "auto" }} />
    {(Icon && (
      <Div p={2} display="row.center.center">
        <Icon />
      </Div>
    )) ||
      null}
    <Text3 bold>{name}</Text3>
    <Div flex={1} />
    <Text2>{description}</Text2>
  </RowLayout>
);

const ButtonRow = ({
  primaryAction = noop,
  icon,
  border = false,
  name = ""
}) => (
  <RowLayout border={border}>
    <MediumFilledButton
      bg="gray1"
      color="gray6"
      LeftIcon={icon}
      onClick={() => primaryAction()}
    >
      {name}
    </MediumFilledButton>
  </RowLayout>
);

const ToggleRow = ({
  id,
  enabled,
  name = "",
  description = "",
  border = false,
  primaryAction = noop
}) => (
  <RowLayout onClick={() => primaryAction(id)} border={border} hover>
    <TinyToggle active={enabled} />
    <Div display="column" p={2}>
      <Text2 bold>{name}</Text2>
      {(description && <Text1>{description}</Text1>) || null}
    </Div>
  </RowLayout>
);

const rowTypes = {
  radio: RadioRow,
  toggle: ToggleRow,
  button: ButtonRow
};

const Section = section => {
  const {
    primaryAction = noop,
    secondaryAction = noop,
    id,
    enabled = false,
    toggle = false,
    name = "",
    scratched = false,
    disableSelectMore = false,
    fields = []
  } = section;

  return (
    <Div style={{ width: 600 }} flex={1} mt={3}>
      <Div
        display="row.flex-start.center"
        p={1}
        onClick={toggle ? () => primaryAction(id) : noop}
      >
        {(toggle && <TinyToggle active={enabled} />) || null}
        <Div fs={4} fw={3} color="gray7" bold ml={toggle ? 2 : 0}>
          {name}
        </Div>
        {(scratched && (
          <Div flex={1} ml={4} display="row.center.center">
            <Div style={{ height: 1 }} bt={1} bc="neutral2" flex={1} />
          </Div>
        )) ||
          null}
      </Div>
      {toggle && !enabled
        ? null
        : fields.map((field, index) =>
            React.createElement(rowTypes[field.rowType] || ToggleRow, {
              ...field,
              section,
              key: field.id,
              border: !(disableSelectMore && index === fields.length - 1)
            })
          )}
      {disableSelectMore ? null : (
        <ButtonRow
          name={`Show/Hide ${section.name} ${
            R.contains("fields", R.toLower(section.name)) ||
            R.contains("details", R.toLower(section.name)) ||
            R.contains("items", R.toLower(section.name))
              ? ""
              : "fields"
          }`}
          primaryAction={() => secondaryAction(section.id)}
          icon={SettingsIcon}
          section={section}
        />
      )}
    </Div>
  );
};

const getSections = createSelector(
  R.prop("sections"),
  R.propOr(noop, "toggleCustomField"),
  R.propOr(noop, "toggleCustomSection"),
  R.propOr(noop, "showSelectFields"),
  R.propOr(false, "loading"),
  R.propOr(false, "isContactsModuleImport"),
  (
    sections,
    toggleCustomField,
    toggleCustomSection,
    showSelectFields,
    loading,
    isContactsModuleImport
  ) => ({
    sections: R.map(
      section => ({
        ...section,
        primaryAction: toggleCustomSection,
        secondaryAction: showSelectFields,
        fields: R.map(
          field => ({ ...field, primaryAction: toggleCustomField }),
          section.fields || []
        )
      }),
      sections
    ),
    loading,
    isContactsModuleImport
  })
);

const decorate = R.compose(
  connect(
    state => ({
      sections: getCustomEnabledSections(state),
      isContactsModuleImport: isContactsModule(state),
      loading: getters.downloadingCustomized(state)
    }),
    {
      toggleCustomField: actions.toggleCustomField,
      toggleCustomSection: actions.toggleCustomSection,
      showSelectFields: actions.showSelectFieldsModal
    }
  ),
  mapProps(getSections)
);

const CustomizePage = ({
  sections = [],
  loading = false,
  isContactsModuleImport = false
}) => {
  const shouldLimitAccountFieldsForContactsImport = moduleId =>
    moduleId === STANDARD_MODULE_IDS.accounts.id && isContactsModuleImport;

  return (
    <Div
      flex={1}
      display="column.flex-start.center"
      p={4}
      style={{ maxHeight: 600, overflowY: "auto" }}
    >
      {R.map(
        section => (
          <Section
            {...section}
            key={section.id}
            disableSelectMore={shouldLimitAccountFieldsForContactsImport(
              section.id
            )}
          />
        ),
        sections
      )}
      <SelectFieldsModal />
      {loading && <LoadingOverlay />}
    </Div>
  );
};

export default decorate(CustomizePage);
