import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CRMCard = ({ children, color }) => (
  <div
    styleName="container"
    style={{
      borderTopColor: color
    }}
  >
    {children}
  </div>
);

CRMCard.defaultProps = {
  color: "#813CD2"
};

CRMCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  color: PropTypes.string.isRequired
};

export default CSSModules(CRMCard, css);
