import * as R from "ramda";

import { createSelector } from "reselect";
import { ACTION_TYPES } from "ui-kit/Table/constants";
import { ROW_ACTIONS } from "../constants";
import { COLUMN_ACTIONS } from "ui-kit/Table/constants";

export const getRowActions = createSelector(
  R.identity,
  () => {
    return [
      {
        id: ROW_ACTIONS.VIEW_RECORD,
        type: ACTION_TYPES.ITEM,
        name: "Submission Summary",
        leftIcon: "open_in_new",
        visible: true
      },
      {
        id: ROW_ACTIONS.VIEW_ORIGINAL_SUBMISSION,
        type: ACTION_TYPES.ITEM,
        name: "Original Submission",
        leftIcon: "open_in_new",
        visible: true
      }
    ].filter(a => a.visible);
  }
);

export const getColumnActions = R.always([
  {
    id: COLUMN_ACTIONS.EDIT,
    type: ACTION_TYPES.ITEM,
    name: "Edit Column",
    leftIcon: "edit"
  },
  {
    id: COLUMN_ACTIONS.HIDE,
    type: ACTION_TYPES.ITEM,
    name: "Hide Column",
    leftIcon: "remove_red_eye"
  },
  {
    type: "separator"
  },
  {
    id: COLUMN_ACTIONS.INSERT_LEFT,
    type: ACTION_TYPES.ITEM,
    name: "Insert Left",
    leftIcon: "arrow_back"
  },
  {
    id: COLUMN_ACTIONS.INSERT_RIGHT,
    type: ACTION_TYPES.ITEM,
    name: "Insert Right",
    leftIcon: "arrow_forward"
  }
]);
