import React from "react";
import { Div, Text0 } from "components/Base";

const HeaderMeal = ({ meals }) =>
  meals.length > 0 ? (
    <Div width={1} display="row" mb={3}>
      <Text0 color="neutral6" width={150}>
        TYPE
      </Text0>
      <Text0 color="neutral6" width={75} display="row.flex-end" pr={2}>
        REQUESTED
      </Text0>
      <Text0 color="neutral6" width={70} display="row.flex-end" pr={2}>
        APPROVED
      </Text0>
      <Text0 color="neutral6" width={75} display="row.flex-end" pr={2}>
        UNUSED
      </Text0>
      <Text0 color="neutral6" width={75} display="row.flex-end" pr={2}>
        USED
      </Text0>
      <Text0 color="neutral6" width={320} pl={4}>
        UTILIZATION
      </Text0>
    </Div>
  ) : (
    <Div fs={3} fw={3} color="neutral6">
      No meals for this day
    </Div>
  );

export default HeaderMeal;
