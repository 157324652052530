import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, DEFAULT_SIDEBAR_LINKS, FORM_FIELD_IDS } from "./constants";

const iniState = {
  loading: true,
  itemTypes: [],
  showSelectItemsModal: false,
  availableTypes: [],
  availableFeatures: [],
  availableCurrencies: [],
  availableLanguages: [],
  types: {},
  typeSettings: {},
  features: {},
  data: {
    name: "",
    slug: "",
    scope: "virtual",
    type: "Conference",
    background_image_url: null,
    logo_image_url: null,
    hightlight_color: null,
    timezone: "",
    date_from: "",
    date_to: "",
    date_groups: [],
    virtual_enable_stage: true,
    virtual_enable_roundtables: true,
    virtual_enable_networking: true,
    virtual_enable_expo: true,
    currency: null,
    language: null,
    is_virtual_event_published: false
  },
  settings: {
    enable_sidebar_video: false,
    sidebar_video_url: null,
    sidebar_video_title: null,
    intercom_id: null,
    intercom_method: "client",
    google_tag_manager_id: null,
    interview_schedule_title: null,
    interview_schedule_subtitle: null,
    watch_interview_replays_url: null,
    left_sidebar_logo_image_url: null,
    event_logo_url: null,
    event_title: null,
    event_subtitle: null,
    alerts_title: null,
    alerts_subtitle: null,
    alerts_view_all_label: null,
    videos_title: null,
    videos_subtitle: null,
    videos_view_all_label: null,
    enabled_gated_content: false,
    tickets_with_access_to_gated_content: [],
    content_section_1_label: null,
    content_section_1_content: null,
    content_section_2_label: null,
    content_section_2_content: null,
    content_section_3_label: null,
    content_section_3_content: null,
    left_sidebar_color: null,
    link_color: null,
    left_sidebar_secondary_color: null,
    font_family: "roboto",
    right_sidebar_links: DEFAULT_SIDEBAR_LINKS
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    getItemsToShow: R.identity,
    onSelectItemsToShow: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          settings = {},
          types = {},
          typeSettings = {},
          features = {},
          available_features,
          available_types,
          available_currencies,
          available_languages,
          date_groups,
          ...details
        }
      }
    ) => ({
      availableFeatures: available_features,
      availableTypes: available_types,
      availableCurrencies: available_currencies,
      availableLanguages: available_languages,
      data: {
        ...details,
        date_groups: R.isEmpty(date_groups) ? undefined : date_groups,
        type: details.type || "Conference"
      },
      types,
      typeSettings,
      features,
      settings: {
        ...state.settings,
        ...settings,
        [FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS]:
          settings[FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS] &&
          FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS.length
            ? settings[FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS]
            : DEFAULT_SIDEBAR_LINKS
      }
    }),
    updateSettings: (state, { payload: { field, value } }) => ({
      settings: R.assoc(field, value, state.settings)
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    }),
    updateType: (state, { payload: { field, value } }) => ({
      types: R.assoc(field, value, state.types)
    }),
    updateFeature: (state, { payload: { field, value } }) => ({
      features: R.assoc(field, value, state.features)
    }),
    onUpdateRightSidebarValue: (
      state,
      { payload: { linkId, fieldId, value } }
    ) => {
      const idx = state.settings[
        FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS
      ].findIndex(l => l.id === linkId);
      const updatedLinks =
        state.settings[FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS];
      updatedLinks[idx] = {
        ...updatedLinks[idx],
        [fieldId]: value
      };

      return {
        settings: {
          ...state.settings,
          [FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS]: updatedLinks
        }
      };
    },

    onReorderRightSidebarLinks: (state, { payload: links }) => {
      return {
        settings: {
          ...state.settings,
          [FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS]: links.map((link, idx) => ({
            ...link,
            order: idx
          }))
        }
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
