import React from "react";

import { Div } from "components/Base";

import { connect } from "react-redux";

import * as R from "ramda";

import { withRouter } from "react-router";

import { getItemTypes } from "SalesPortal/Home/selectors";

import Items from "SalesPortal/ItemsModal/View";
import { actions, getters } from "SalesPortal/Home";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      itemTypes: getItemTypes(state, props),
      salesData: getters.salesData(state, props),
      modalEnabled: getters.modalEnabled(state)
    }),
    {
      hideModal: () => actions.hideModal()
    }
  )
);

const ItemsModal = ({
  salesData,
  itemTypes,
  params: { eventId, slug },
  hideModal,
  modalEnabled
}) => {
  return (
    <Div
      width={1}
      height={1}
      style={{
        position: "absolute",
        zIndex: 4,
        backgroundColor: "rgba(0,0,0,0.3)"
      }}
      display="row.center.center"
    >
      <Items
        eventId={salesData.event_id}
        hideModal={hideModal}
        title={salesData.name}
        redirectLink={`/sales-portal/${slug}/${eventId}/checkout`}
        iniSelectedTab={modalEnabled}
        itemTypes={itemTypes}
      />
    </Div>
  );
};

export default decorate(ItemsModal);
