const baseIcon = {
  fontSize: 18,
  color: "#BFBFBF",
  marginRight: 2
};

const baseText = {
  lineHeight: "34px",
  color: "#2F2D2D",
  fontSize: 15
};

const baseContainer = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%"
};

export { baseIcon, baseText, baseContainer };
