import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class TextSettings extends Component {
  @autobind
  onReadOnlyChange() {
    this.props.updateTextSettings({
      ...this.props.settings,
      readOnly: !this.props.settings.readOnly
    });
  }

  render() {
    return (
      <div>
        {/*
        {this.props.settings.enableReadOnly && (
          <div className="input-wrapper">
            <label styleName="checkboxWrapper">
              <input
                type="checkbox"
                checked={this.props.settings.readOnly}
                onClick={this.onReadOnlyChange}
              />
              <div styleName="checkboxLabel">Read Only</div>
            </label>
          </div>
        )}
        */}
      </div>
    );
  }
}

TextSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  updateTextSettings: PropTypes.func.isRequired
};

export default TextSettings;
