import React, { useEffect, cloneElement } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "Portal/PortalWrapper/model";
import Loading from "components/Global/Loading";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { withRouter } from "react-router";

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.loading(state),
      id: getters.id(state),
      title: getters.title(state),
      backgroundImageUrl: getters.backgroundImageUrl(state),
      event: getters.event(state),
      accounts: getters.accounts(state)
    }),
    { init: actions.init }
  ),
  withRouter,
  CSSModules(css)
);

const MEDIA_HUB_EVENT_IDS = [
  2941,
  2652,
  3001,
  2680,
  3079,
  3084,
  3136,
  3219,
  3361,
  3362,
  3207,
  3328,
  3327,
  3494,
  3495,
  3503,
  3552
];

const Layout = ({
  init,
  location,
  params,
  loading,
  id,
  title,
  backgroundImageUrl,
  event,
  accounts,
  children,
  router
}) => {
  useEffect(() => {
    init({
      portalId: location.query.portalId || params.portalId
    });
  }, [init]);

  if (loading) {
    return <Loading className={css.loading} content="Loading Portal..." />;
  }

  const isCommunityHub = router.routes.some(
    r => r.name === "portalV2_PortalCommunity"
  );

  return (
    <>
      {/* //@TODO: Dynamic insert into header */}
      {isCommunityHub || MEDIA_HUB_EVENT_IDS.includes(event.id) ? (
        <>
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&family=Roboto+Condensed:wght@400;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap"
            rel="stylesheet"
          />
        </>
      ) : null}

      <div styleName="container">
        {cloneElement(children, {
          portal: {
            id,
            title,
            background_image_url: backgroundImageUrl,
            event,
            accounts
          }
        })}
      </div>
    </>
  );
};

export default decorate(Layout);
// export default CSSModules(decorate(Layout), css);
