import React, { Component } from "react";
import ClassNames from "classnames";
import { find } from "lodash";
import PropTypes from "prop-types";
import Radium from "radium";
import { Link } from "react-router";
import autobind from "autobind-decorator";

import HeaderAvatar from "../HeaderAvatar";
import HeaderTitle from "../HeaderTitle";
import HeaderHelp from "../HeaderHelp";
import Notifications from "Notifications/View";

import Themes from "components/Global/Themes/LenndThemes";

const styles = {
  container: {
    display: "flex",
    flex: 1,
    alignItems: "center"
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 45,
    backgroundColor: Themes.purple.headerBarBackBackgroundColor,
    width: 69,
    "@media (max-width: 1000px)": {}
  },
  logo: {
    width: 45,
    textAlign: "center",
    lineHeight: "45px",
    cursor: "pointer",
    backgroundColor: Themes.purple.headerBarBackBackgroundColor,
    "@media (max-width: 720px)": {
      width: 45
    }
  },
  logoImage: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  links: {
    flex: 1,
    flexWrap: "nowrap",
    display: "flex",
    marginLeft: 0,
    paddingLeft: 26,
    userSelect: "none"
  }
};

class MyHeader extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    location: PropTypes.object.isRequired,
    myEvents: PropTypes.array.isRequired,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired
  };

  render() {
    const currentRoutes = this.props.routes;

    const activeMyEventsLink = ClassNames({
      "nav-link": true,
      "nav-link-wrapper": true,
      active: find(currentRoutes, { name: "HomeView" })
    });

    return (
      <div style={styles.container}>
        <div style={styles.logo} onClick={this._goToHome}>
          <img
            src="//d2dks4tzxs6xee.cloudfront.net/img/header-logo-letter.png"
            width="12"
            height="18"
            style={styles.logoImage}
          />
        </div>

        <HeaderTitle
          name="Events"
          user={this.props.user}
          location={this.props.location}
          myEvents={this.props.myEvents}
          showModal={this.props.showModal}
          hideModal={this.props.hideModal}
        />

        <div style={styles.links}>
          <Link
            className={activeMyEventsLink}
            to={"/home"}
            activeClassName="active"
          >
            <span className="nav-link-label">Overview</span>
          </Link>
        </div>

        {/* <input className='styled' type="text" value="" placeholder="' styles={styles.search} /> */}

        <Notifications />

        <HeaderHelp
          showModal={this.props.showModal}
          hideModal={this.props.hideModal}
        />

        <div style={styles.avatar}>
          <HeaderAvatar />
        </div>
      </div>
    );
  }

  @autobind
  _goToHome() {
    this.props.router.push({ pathname: "/home" });
  }
}

export default Radium(MyHeader);
