import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
import getContactEmail from "components/Event/Accounts/Account/PrimaryContacts/utils/get-contact-email";

@CSSModules(css)
class ContactActionMenu extends Component {
  state = {
    showPopover: false
  };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleMenuChange = (e, val) => {
    switch (val) {
      case "open":
        this.props.gotoContact(this.props.row.contact_id);
        break;
      case "editRole":
        this.props.onEditRole({
          relationshipId: this.props.row.id,
          role: this.props.row.role
        });
        break;
      case "revokeCredentials":
      case "invokePrimary":
        this.updatePrimary();
        break;
      case "loginAccess":
        this.props.handleLoginAccess(this.props.row);
        break;
      case "loginDetails":
        this.props.onLoginDetails(this.props.row);
        break;
      case "editType":
        this.props.showChangeContactRecordTypeModal(
          this.props.row.contact_id,
          this.props.row.type.id
        );
      default:
    }
    this.onRequestClose();
  };

  deletePerson = () => {
    this.props.onDelete(this.props.row);
    this.setState({
      showPopover: false
    });
  };

  updatePrimary = () => {
    const data = {
      relationshipId: this.props.row.id,
      isPrimary: !this.props.row.is_primary
    };
    return this.props.onUpdatePrimary(data);
  };

  render() {
    const iconStyle = { paddingTop: 5, fontSize: 16 };
    const email = getContactEmail(this.props.row.values);

    return (
      <div>
        <div
          styleName="childWrapper"
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </div>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <Menu desktop onChange={this.handleMenuChange}>
            <MenuItem
              value="open"
              primaryText="Open record"
              leftIcon={
                <i className="material-icons" style={iconStyle}>
                  open_in_new
                </i>
              }
            />
            <MenuItem
              value="editRole"
              primaryText="Change role"
              leftIcon={
                <i className="material-icons" style={iconStyle}>
                  edit
                </i>
              }
            />

            {this.props.params.orgId ? null : (
              <MenuItem
                value="editType"
                primaryText="Edit Type"
                leftIcon={
                  <i className="material-icons" style={iconStyle}>
                    edit
                  </i>
                }
              />
            )}

            <MenuItem
              value="invokePrimary"
              primaryText={
                this.props.row.is_primary
                  ? "Remove as primary"
                  : "Mark as primary"
              }
              leftIcon={
                <i className="material-icons" style={iconStyle}>
                  star
                </i>
              }
            />

            {this.props.params.orgId ? null : (
              <MenuItem
                value="loginAccess"
                primaryText={
                  this.props.row.can_login
                    ? "Remove login access"
                    : "Give login access"
                }
                leftIcon={
                  <i className="material-icons" style={iconStyle}>
                    vpn_key
                  </i>
                }
                disabled={!email || !email.length}
              />
            )}

            {this.props.row.can_login ? (
              <MenuItem
                value="loginDetails"
                primaryText="Login details"
                leftIcon={
                  <i className="material-icons" style={iconStyle}>
                    share
                  </i>
                }
              />
            ) : (
              ""
            )}
            <DeleteConfirmation
              onConfirm={this.deletePerson}
              text="Are you sure you want to delete this person?"
            >
              <MenuItem
                desktop
                primaryText="Remove person"
                leftIcon={
                  <i className="material-icons" style={iconStyle}>
                    remove
                  </i>
                }
              />
            </DeleteConfirmation>
          </Menu>
        </Popover>
      </div>
    );
  }
}

ContactActionMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onEditRole: PropTypes.func.isRequired,
  handleLoginAccess: PropTypes.func.isRequired,
  gotoContact: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdatePrimary: PropTypes.func.isRequired,
  row: PropTypes.shape({
    contact_id: PropTypes.string,
    id: PropTypes.string,
    is_primary: PropTypes.bool,
    can_login: PropTypes.bool,
    role: PropTypes.string,
    values: PropTypes.object
  }).isRequired
};

export default ContactActionMenu;
