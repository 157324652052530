import * as R from "ramda";

import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";

import { getValue } from "../../utils";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class Form extends Component {
  render() {
    let status = getValue(this.props, "status", "");

    const statusMap = {
      incomplete: {
        status: "not-submitted",
        tooltip: "Incomplete"
      },
      complete: {
        status: "approved",
        tooltip: "Complete"
      },
      default: {
        status: "not-assigned",
        tooltip: "Not Assigned"
      }
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4
        }}
      >
        <Tooltip
          tooltip={R.pathOr(
            statusMap.default.tooltip,
            [status, "tooltip"],
            statusMap
          )}
          placement="bottom"
        >
          <span>
            <StatusCircle
              status={R.pathOr(
                statusMap.default.status,
                [status, "status"],
                statusMap
              )}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default decorate(Form);
