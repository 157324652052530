import { call, put, fork, all, select, takeEvery } from "redux-saga/effects";
import { actions as ActivityLogsActions } from "Event/ActivityLogs/model";
import { getSearchAndFilters } from "Event/ActivityLogs/selectors";

import { getters, actions } from "./model";

import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import Api from "./api";

const search = function*(action) {
  yield put(ActivityLogsActions.setLoading(true));
  try {
    const searchAndFilters = yield select(getSearchAndFilters);
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);
    const orgId = yield select(getOrgId);
    let currentPage = yield select(getters.currentPage);
    if (
      action &&
      [
        ActivityLogsActions.toggleFilter.type,
        ActivityLogsActions.search.type
      ].includes(action.type)
    ) {
      yield put(ActivityLogsActions.setDays([]));
      currentPage = -1;
    }
    const data = {
      ...searchAndFilters,
      currentPage: currentPage + 1
    };
    const {
      payload: { count_of_pages, current_page, ...dataLogs }
    } = yield call(Api.getActivityLogs, credentials, eventId, orgId, data);
    const canLoadMore = current_page < count_of_pages;
    yield put(actions.setPages({ count_of_pages, current_page }));
    yield put(ActivityLogsActions.setInitialData({ ...dataLogs, canLoadMore }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading activity logs"
        }
      ])
    );
  }
  yield put(ActivityLogsActions.setLoading(false));
};

const init = function*() {
  yield put(actions.setPages({ count_of_pages: 0, current_page: -1 }));
  yield put(ActivityLogsActions.setDays([]));
  yield fork(search);
};

const loadMore = function*() {
  const currentPage = yield select(getters.currentPage);
  const countOfPages = yield select(getters.countOfPages);
  if (countOfPages > currentPage) {
    yield put(ActivityLogsActions.setLoadingMessage(true));
    yield fork(search);
    yield put(ActivityLogsActions.setLoadingMessage(false));
  }
};

const watchLoadMore = function*() {
  yield takeEvery(ActivityLogsActions.loadMoreData.type, loadMore);
};

const watchSearch = function*() {
  yield takeEvery(
    [ActivityLogsActions.toggleFilter.type, ActivityLogsActions.search.type],
    search
  );
};

const rootSaga = function*() {
  yield all([fork(init), fork(watchLoadMore), fork(watchSearch)]);
};

export default rootSaga;
