import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, FILTERS } from "./constants";

const iniState = {
  loading: false,
  loadingMessage: false,
  days: [],
  openedRows: {},
  filters: {
    [FILTERS.BY_ACTIVITY_TYPE]: [],
    [FILTERS.BY_USERS]: []
  },
  canLoadMore: false,
  countOfActivities: 0,
  isEventSection: true
};

const model = createHandlers({
  iniState,
  reducers: {
    toggleRow: R.identity,
    search: R.identity,
    loadMoreData: R.identity,
    executeAction: R.identity,
    setInitialData: (
      { days },
      {
        payload: {
          days: newDays,
          count_of_activities,
          canLoadMore,
          isEventSection = true
        }
      }
    ) => ({
      days: R.concat(days, newDays),
      canLoadMore,
      countOfActivities: count_of_activities,
      isEventSection
    }),
    toggleActivityLog: (
      { openedRows },
      { payload: { id: activityId, records } }
    ) => ({
      openedRows: R.prop(activityId, openedRows)
        ? R.omit([activityId], openedRows)
        : R.mergeAll([openedRows, { [activityId]: records }])
    }),
    toggleFilter: ({ filters }, { payload: { id, type } }) => ({
      filters: {
        ...filters,
        [type]: R.symmetricDifference([id], R.prop(type, filters))
      }
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
