import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import { actions } from "Files/Manage";

import Header from "./Header";
import FieldFilter from "./FieldFilter";
import SortBy from "./SortBy";

const decorate = connect(
  null,
  { onToggleCollapsed: () => actions.toggleCollapse() }
);

const Sidebar = ({}) => {
  return (
    <Div
      width={250}
      py={5}
      pl={4}
      pr={3}
      style={{
        backgroundColor: "#813CD2",
        minHeight: "100%"
      }}
      display="column.flex-start.stretch"
    >
      <Header />

      <SortBy mb={2} />
      <FieldFilter mb={2} />

      <Div flex={1} />
      {/* footer */}
    </Div>
  );
};

export default decorate(Sidebar);
