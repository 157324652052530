import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./model";

export const getContactRecordTypes = createSelector(
  getters.contactRecordTypes,
  getters.assignedToPeopleTypes,
  (contactRecordTypes, assignedToPeopleTypes) =>
    R.map(
      record => ({
        ...record,
        selected: R.any(
          recordId => recordId === record.id,
          assignedToPeopleTypes
        )
      }),
      contactRecordTypes
    )
);
