import moment from "moment";

export const summary_mock = {
  due_dates: [
    {
      type: "documents",
      name: "Documents",
      id: "document-123",
      items: [
        {
          id: "document-1",
          name: "W9",
          color: "#E8833B",
          due_label: "Due in 2 days",
          due_raw: moment()
            .add(2, "days")
            .toISOString()
        }
      ]
    },
    {
      // This means there are requests created for this people type that have passes asigned
      // and from those passes we need to check if there are categories (credentials, parking, ticket)
      // that have due_dates -> the due_date is assigned to each passes category
      type: "requests",
      id: "request-123",
      name: "Passes",
      items: [
        // these are passes categories:
        {
          id: "passes-1",
          name: "Credentials",
          due_label: "Due in 2 days",
          color: null,
          due_raw: moment()
            .add(2, "days")
            .toISOString()
        },
        {
          id: "passes-2",
          name: "Parking",
          color: "#D3455B",
          due_label: "Due in 2 days",
          due_raw: moment()
            .add(2, "days")
            .toISOString()
        },
        {
          id: "passes-3",
          name: "Tickets",
          color: "#37AE9F",
          due_label: "Due in 2 days",
          due_raw: moment()
            .add(2, "days")
            .toISOString()
        }
      ]
    },
    {
      type: "catering",
      name: "Meals",
      id: "meal-123",
      items: [
        {
          id: "meal-1",
          name: "Hot Meals",
          color: null,
          due_label: "Due in 1 days",
          due_raw: moment()
            .add(1, "days")
            .toISOString()
        }
      ]
    },
    {
      type: "assets",
      name: "Assets & Equipment",
      id: "asset-123",
      items: [
        // these are all assets types
        {
          id: "asset-type-1",
          name: "Sport Equipment",
          due_label: "Due in 1 days",
          color: null,
          due_raw: moment()
            .add(1, "days")
            .toISOString()
        },
        {
          id: "asset-type-2",
          name: "Golf Carts",
          color: "#D3455B",
          due_label: "Due in 1 days",
          due_raw: moment()
            .add(1, "days")
            .toISOString()
        }
      ]
    }
  ],
  summary: []
};
