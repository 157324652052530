import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarWell = ({ children, style }) => (
  <div styleName="container" style={style}>
    {children}
  </div>
);

SidebarWell.propTypes = {
  onClick: PropTypes.func
};

export default CSSModules(SidebarWell, css);
