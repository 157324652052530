import { connect } from "react-redux";

import MiniItemsModal from "Passes/Common/View/MiniItemsModal";

import { MODE_MULTIPLE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import { actions, getters } from "../model";
import { USER_MODAL_ID } from "Passes/Common/constants";

import { getUserFilters } from "Passes/Common/selectors";

const decorate = connect(
  state => ({
    showModal: getters.showUserModal(state),
    itemGroups: getUserFilters(state),
    instanceId: USER_MODAL_ID,
    mode: MODE_MULTIPLE_SELECT,
    title: "Select submitters to show"
  }),
  {
    setFilters: () => actions.setTypeFilters(),
    hideModal: () => actions.setTypeFilters()
  }
);

export default decorate(MiniItemsModal);
