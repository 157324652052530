import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";

import ImportCSVSummary from "ui-kit/ImportCSVSummary";

import { actions } from "Orders/ImportModal";
import { getConfirmationCounters } from "Orders/ImportModal/selectors";

import { noop } from "utils/General";

const decorate = connect(
  state => ({
    counters: getConfirmationCounters(state)
  }),
  {
    onDownloadSkipped: actions.downloadSkippedReport,
    onDownloadReport: actions.downloadErrorsReport
  }
);

const ImportConfirmationPage = ({
  counters = {},
  onDownloadReport = noop,
  onDownloadSkipped = noop
}) => (
  <Div
    display="column"
    flex={1}
    bg="neutral0"
    p={4}
    style={{ maxHeight: 600, overflowY: "auto" }}
  >
    <ImportCSVSummary
      {...counters}
      onDownloadReport={onDownloadReport}
      onDownloadSkipped={onDownloadSkipped}
    />
  </Div>
);

export default decorate(ImportConfirmationPage);
