import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./";
import { USERS_GROUP_ID, PROFILES_GROUP_ID } from "./constants";

const getUsers = createSelector(
  getters.users,
  R.compose(
    R.sortBy(
      R.compose(
        R.toLower,
        R.propOr("", "name")
      )
    ),
    R.map(user => ({
      ...user,
      name:
        user.user_fname && user.user_fname.length
          ? [user.user_fname, user.user_lname].filter(v => v).join(" ")
          : user.user_email,
      id: user.user_id,
      label: user.permission_role_name
    }))
  )
);

const getProfiles = createSelector(
  getters.permissionProfiles,
  R.compose(
    R.sortBy(
      R.compose(
        R.toLower,
        R.prop("name")
      )
    ),
    R.map(({ name, id }) => ({
      name,
      id
    }))
  )
);

export const getUserAndPermissionFields = createSelector(
  getUsers,
  getProfiles,
  (users, permissionProfiles) => [
    {
      name: "Users",
      id: USERS_GROUP_ID,
      items: [
        {
          name: "Users",
          id: "users",
          items: users
        }
      ]
    },
    {
      name: "Profiles",
      id: PROFILES_GROUP_ID,
      items: [
        {
          name: "Profiles",
          id: "profiles",
          items: permissionProfiles
        }
      ]
    }
  ]
);
