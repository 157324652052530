import React from "react";
import SubNav from "./SubNav";
import { createSelector } from "reselect";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";

import Alerts from "./Alerts/View";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightAlerts"))(routes)) {
      return TAB_IDS.ALERTS;
    }
    return null;
  }
);

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Wrapper = ({ currentRoute }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.ALERTS]: Alerts
  });

  return (
    <>
      <PageTitle titles={["Alerts"]} />
      {/* <SubNav selectedView={currentRoute} /> */}
      <Page />
    </>
  );
};

export default decorate(Wrapper);
