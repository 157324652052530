/* eslint-disable no-underscore-dangle */
import thunkMiddleware from "redux-thunk";
import sequenceActionsMiddleware from "redux-combine-actions";
import promiseMiddleware from "redux-promise-middleware";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./sagas";

import rootReducer from "./modules";
import { applyMiddleware, compose, createStore } from "redux";
import { createTracker } from "redux-segment";
import { routerMiddleware } from "react-router-redux";

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  thunkMiddleware,
  sagaMiddleware,
  promiseMiddleware(),
  sequenceActionsMiddleware
];

if (window.__ENABLED_ANALYTICS__.segment) {
  middleware.push(createTracker());
}

export default function configureStore(initialState, browserHistory) {
  const appliedMiddleware = applyMiddleware(
    routerMiddleware(browserHistory),
    ...middleware
  );
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const createStoreWithMiddleware = composeEnhancers(appliedMiddleware);

  const store = createStoreWithMiddleware(createStore)(
    rootReducer,
    initialState
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("./modules", () => {
      const nextRootReducer = require("./modules");

      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
