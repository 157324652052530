import * as R from "ramda";
import {
  take,
  put,
  select,
  call,
  takeEvery,
  all,
  fork
} from "redux-saga/effects";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";

// import { receiveUser } from "redux/modules/user";
import { addReferences } from "redux/modules/entityReferences/actions";

import { actions, getters } from "Submission/Editor";
import { actions as PeopleBlockViewerActions } from "Submission/PeopleBlockViewer/model";
import { getters as PeopleBlockTableGetters } from "Submission/PeopleBlockTable";
import { actions as DatagridActions } from "ui-kit/Datagrid";

import { registerError } from "redux/modules/errors/actions";
import api from "Submission/Editor/api";

import { getSubmissionById } from "App/Data/sagas";

import { hideModal } from "redux/modules/modal/actions";

import {
  userId as getUserId,
  getCredentials
} from "redux/modules/user/selectors";

const getSubmission = function*({ payload: submissionId }) {
  const userId = yield select(getUserId);
  const credentials = yield select(getCredentials);
  yield put(actions.setFetching(true));
  try {
    const submission = yield call(getSubmissionById, { submissionId });

    yield put(actions.response(submission));

    // if getting submission for form that doesn't require login
    // and there is no current user, then persist user info from form submission data
    if (!R.path(["form", "require_login"], submission) && !userId) {
      const collaborator = R.path(["collaborators", 0], submission);

      if (collaborator) {
        /*
        // @DEPRECATED
        yield put(
          receiveUser({
            id: collaborator.user_id,
            credentials: {
              ...credentials,
              userId: collaborator.user_id
            }
          })
        );
        */
      }
    }

    // if references, add them
    if (R.path(["module_record", "references"], submission)) {
      yield put(addReferences(submission.module_record.references));
    }
  } catch (error) {
    yield all([
      put(actions.setError(error.error || "An error occurred!")),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting submission"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setFetching(false));
  }
};
const watchBulkEditDone = function*() {
  for (;;) {
    yield take(PeopleBlockViewerActions.bulkEditDone.type);
    yield put(hideModal());
    const submissionId = yield select(getters.id);
    yield call(getSubmission, { payload: submissionId });
  }
};

const watchReviewDone = function*() {
  for (;;) {
    yield take(PeopleBlockViewerActions.reviewResponse.type);
    const submissionId = yield select(getters.id);
    yield call(getSubmission, { payload: submissionId });
  }
};

const watchTableActions = function*() {
  for (;;) {
    const {
      error,
      meta: { instanceId }
    } = yield take([
      DatagridActions.addRowResponse.type,
      DatagridActions.removeRowResponse.type,
      DatagridActions.duplicateRowResponse.type,
      DatagridActions.updateCellResponse.type,
      DatagridActions.reviewRemainingResponse.type,
      DatagridActions.columnResizeResponse.type
    ]);
    const tables = yield select(PeopleBlockTableGetters.tableIds);
    if (!error && R.contains(instanceId, tables)) {
      const submissionId = yield select(getters.id);
      yield call(getSubmission, { payload: submissionId });
    }
  }
};

const watchUpdateTable = function*() {
  for (;;) {
    const {
      error,
      meta: { instanceId }
    } = yield take([
      DatagridActions.addRowRequest.type,
      DatagridActions.removeRowRequest.type,
      DatagridActions.duplicateRowRequest.type,
      DatagridActions.updateCellRequest.type,
      DatagridActions.reviewRemaining.type,
      DatagridActions.columnResizeRequest.type
    ]);
    const tables = yield select(PeopleBlockTableGetters.tableIds);
    if (!error && R.contains(instanceId, tables)) {
      yield put(
        DatagridActions.setUpdateEnabled(false, { meta: { instanceId } })
      );
      yield take(actions.response.type);
      yield put(
        DatagridActions.setUpdateEnabled(true, { meta: { instanceId } })
      );
    }
  }
};

const watchGetSubmission = function*() {
  yield takeEvery(actions.fetch.type, getSubmission);
};

const updateSubmission = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  try {
    yield call(api.updateSubmission, credentials, data);
    yield call(getSubmission, { payload: data.id });
  } catch (error) {
    yield all([
      put(actions.setError(error.error || "An error occurred!")),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred updating submission"
          }
        ])
      )
    ]);
  }
};

const watchUpdateSubmission = function*() {
  yield takeEvery(actions.updateSubmission.type, updateSubmission);
};

const addValue = function*({ payload: data }) {
  const transactionId = yield call([uuid, uuid.v4]);
  const credentials = yield select(getCredentials);
  yield put(
    actions.createValue(data, { optimist: { type: BEGIN, id: transactionId } })
  );
  try {
    const result = yield call(api.addValue, credentials, data);
    yield put(
      actions.createComplete(
        { value: result.value },
        { optimist: { type: COMMIT, id: transactionId } }
      )
    );
  } catch (error) {
    yield put(
      actions.setError(error.error || "An error occurred!", {
        optimist: { type: REVERT, id: transactionId }
      })
    );
  }
};

const watchAddValue = function*() {
  yield takeEvery(actions.addValue.type, addValue);
};

const addCustomerBlockValue = function*({ payload: data }) {
  const transactionId = yield call([uuid, uuid.v4]);
  const credentials = yield select(getCredentials);
  yield put(
    actions.createValue(data, { optimist: { type: BEGIN, id: transactionId } })
  );
  try {
    yield call(api.addCustomerBlockValue, credentials, data);
    yield put(
      actions.createCustomerBlockValueComplete(
        {
          accountValues: data.accountValues,
          contactValues: data.contactValues
        },
        { optimist: { type: COMMIT, id: transactionId } }
      )
    );
  } catch (error) {
    yield put(
      actions.setError(error.error || "An error occurred!", {
        optimist: { type: REVERT, id: transactionId }
      })
    );
  }
};

const watchAddCustomerBlockValue = function*() {
  yield takeEvery(actions.addCustomerBlockValue.type, addCustomerBlockValue);
};

const addFulfillmentBlockValue = function*({ payload: data }) {
  const transactionId = yield call([uuid, uuid.v4]);
  const credentials = yield select(getCredentials);
  yield put(
    actions.createValue(data, { optimist: { type: BEGIN, id: transactionId } })
  );
  try {
    yield call(api.addFulfillmentBlockValue, credentials, data);
    yield put(
      actions.createFulfillmentBlockValueComplete(
        {
          shipping_address_line_1: data.address.addressLine1,
          shipping_address_line_2: data.address.addressLine2,
          shipping_city: data.address.city,
          shipping_state: data.address.state,
          shipping_zip: data.address.zip,
          shipping_country: data.address.country,
          pickup_person_fname: data.beingPickedUpBy.fname,
          pickup_person_lname: data.beingPickedUpBy.lname
        },
        { optimist: { type: COMMIT, id: transactionId } }
      )
    );
  } catch (error) {
    yield put(
      actions.setError(error.error || "An error occurred!", {
        optimist: { type: REVERT, id: transactionId }
      })
    );
  }
};

const watchAddFulfillmentBlockValue = function*() {
  yield takeEvery(
    actions.addFulfillmentBlockValue.type,
    addFulfillmentBlockValue
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchAddValue),
    fork(watchAddCustomerBlockValue),
    fork(watchAddFulfillmentBlockValue),
    fork(watchGetSubmission),
    fork(watchUpdateSubmission),
    fork(watchBulkEditDone),
    fork(watchReviewDone),
    fork(watchTableActions),
    fork(watchUpdateTable)
  ]);
};

export default rootSaga;
