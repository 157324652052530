import * as R from "ramda";
import React from "react";

import { ADDRESS_FORM_ID, ADDRESS_FIELDS } from "Sponsors/SelfSetup/constants";

import { Div } from "components/Base";

import { WithThemeConsumer, VARIANTS, defaultTh } from "ui-kit/Theme";
import {
  Input,
  Label,
  Row,
  Column,
  WithFormInstanceProvider
} from "ui-kit/Form/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(ADDRESS_FORM_ID)),
  WithThemeConsumer({ variant: VARIANTS.SURFACE })
);

const Address = ({ Th = defaultTh, ...styleProps }) => (
  <Div
    {...Th(["bg"], {
      bra: 1,
      bold: true,
      ...styleProps
    })}
  >
    <Row>
      <Column flex={1}>
        <Label Th={Th}>Name of Venue/Location</Label>
        <Input width={1} fieldId={ADDRESS_FIELDS.NAME} />
      </Column>
    </Row>
    <Row mt={2}>
      <Column flex={1}>
        <Label Th={Th}>Address Line 1</Label>
        <Input width={1} fieldId={ADDRESS_FIELDS.ADD_1} />
      </Column>
    </Row>
    <Row mt={2}>
      <Column flex={1}>
        <Label>City</Label>
        <Input width={1} fieldId={ADDRESS_FIELDS.CITY} />
      </Column>
    </Row>
    <Row mt={2}>
      <Column flex={1}>
        <Label>State / Region</Label>
        <Input width={1} fieldId={ADDRESS_FIELDS.STATE} />
      </Column>
      <Column flex={1} ml={2}>
        <Label Th={Th}>Zip / Postal Code</Label>
        <Input width={1} fieldId={ADDRESS_FIELDS.ZIP} />
      </Column>
    </Row>
    <Row mt={2}>
      <Column flex={1}>
        <Label Th={Th}>Website URL</Label>
        <Input width={1} fieldId={ADDRESS_FIELDS.WEBSITE} />
      </Column>
    </Row>
  </Div>
);

export default decorate(Address);
