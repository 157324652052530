import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import styleSpects from "components/Base/Base/styleSpecs";

import { ROW_ACTIONS_ITEMS } from "Portal/PortalForm/constants";

import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

class SubmissionActions extends Component {
  viewSubmission = () => {
    const { data: row, field: column } = this.props;
    this.props.execute({
      actionId: ROW_ACTIONS_ITEMS.OPEN_SUBMISSION_MODAL,
      row,
      column
    });
  };

  editSubmission = () => {
    const { data: row, field: column } = this.props;
    this.props.execute({
      actionId: ROW_ACTIONS_ITEMS.EDIT,
      row,
      column
    });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "14px"
        }}
      >
        <div
          onClick={this.viewSubmission}
          className="underline-on-hover"
          style={{
            color: styleSpects.color.primary9
          }}
        >
          View
        </div>
        <span
          style={{
            fontWeight: "600",
            color: "#333"
          }}
        >
          &nbsp;|&nbsp;
        </span>
        <div
          onClick={this.editSubmission}
          className="underline-on-hover"
          style={{
            color: styleSpects.color.primary9
          }}
        >
          Edit
        </div>
      </div>
    );
  }
}

export default decorate(SubmissionActions);
