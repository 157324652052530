import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import { actions } from "ui-kit/FilterControls";
import { getOptions, getFiltersOn } from "ui-kit/FilterControls/selectors";

import { RadioButton } from "material-ui/RadioButton";

import { Div, Text2 } from "components/Base";
import { noop } from "utils/General";

import { TYPES } from "ui-kit/FilterControls/constants";

import { Panel } from "./Panel";

const decorate = R.compose(
  connect(
    (state, props) => ({
      options: getOptions(state, { ...props, type: TYPES.RADIO }),
      filtersOn: getFiltersOn(state, props)
    }),
    bindInstance({
      init: actions.init,
      toggleOption: actions.toggleOption
    })
  ),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND })
);

const RadioFilter = decorate(
  ({
    options = [],
    filtersOn = false,
    children: label,
    init = noop,
    toggleOption = noop,
    Th = defaultTh,
    collapsedDefault,
    ...styleProps
  }) => {
    useEffect(() => {
      init({ multiSelect: false });
      const isSomeOptionSelected = R.reduce(
        (actual, option) => actual && option.selected,
        true,
        options
      );
      if (
        R.isNil(isSomeOptionSelected) &&
        !R.isNil(R.path([0, "id"], options))
      ) {
        toggleOption(R.path([0, "id"], options), {
          meta: { option: R.path([0], options) }
        });
      }
    }, []);

    const safeToggleOption = option => {
      if (!option.selected) {
        toggleOption(option.id, {
          meta: { option }
        });
      }
    };

    return (
      <Panel
        filtersOn={filtersOn}
        label={label}
        collapsedDefault={collapsedDefault}
        bodyProps={{ ml: 3 }}
        {...styleProps}
      >
        {R.map(
          option => (
            <Div mb={1} key={option.id}>
              <RadioButton
                value={option.id}
                checked={option.selected}
                onClick={() => safeToggleOption(option)}
                label={
                  <Text2 {...Th(["color"], { active: option.selected })}>
                    {option.name}
                  </Text2>
                }
                iconStyle={{
                  alignSelf: "center",
                  width: 20,
                  height: 20,
                  marginRight: 6
                }}
                labelStyle={{ marginBottom: 0, lineHeight: "20px" }}
              />
            </Div>
          ),
          options
        )}
      </Panel>
    );
  }
);

export { RadioFilter };
