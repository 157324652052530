import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import FormElements from "components/Global/Modal/Layout/FormElements";
import WarningWrapper from "components/Global/Modal/Layout/WarningWrapper";
import {
  ButtonGroup,
  ButtonOutline,
  ButtonRed
} from "components/Global/Modal/Layout/Buttons";

const { Label, Input, InputSection, InputGroup } = FormElements;

@CSSModules(css)
class DeleteModuleModal extends Component {
  state = { valid: false };

  handleInputChange = e =>
    this.setState({ valid: e.target.value === "delete" });

  handleDelete = () => {
    if (this.state.valid) {
      this.props.hideModal();
      return this.props.onDelete(this.props.moduleId);
    }
    return false;
  };

  render() {
    return (
      <WarningWrapper
        hideModal={this.props.hideModal}
        width={500}
        heading="Delete module"
      >
        <div styleName="warning">
          <div styleName="icon">
            <i className="material-icons">warning</i>
            Warning
          </div>
          This will remove the module and all related data and{" "}
          <span className="bold">cannot be undone.</span>
        </div>

        <InputGroup>
          <Label>Type 'delete' to confirm</Label>
          <Input onChange={this.handleInputChange} autoFocus />
        </InputGroup>
        <ButtonGroup>
          <ButtonRed
            title="DELETE"
            onClick={this.handleDelete}
            style={this.state.valid ? {} : { cursor: "not-allowed" }}
          />
          <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
        </ButtonGroup>
      </WarningWrapper>
    );
  }
}

DeleteModuleModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  moduleId: PropTypes.string.isRequired
};

export default DeleteModuleModal;
