import { createModule } from "redux-mvc";

import doubleSelect from "ui-kit/DoubleSelect";
import filtersPanel from "ui-kit/FiltersPanel";
import searchBar from "ui-kit/SearchBar";
import miniItemsSelector from "ui-kit/MiniItemsSelector";

import model from "./model";

import sagasModule from "./sagas";

const module = createModule(model);
module.setRootSaga(sagasModule);

module.plugModule(doubleSelect);
module.plugModule(filtersPanel);
module.plugModule(searchBar);
module.plugModule(miniItemsSelector);

export default module;
