import React from "react";
import * as R from "ramda";
import { Panel, PanelTitle } from "EventLight/Common/FieldLayout";

import { connect } from "react-redux";
import { actions, getters } from "../model";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import TemplatePanel from "./TemplatePanel";
import EmptyState from "EventLight/Common/EmptyState";

import { MediumFilledButton, AddIcon } from "components/Base";

const decorate = R.compose(
  CSSModules(css),
  connect(
    state => ({
      list: getters.list(state)
    }),
    {
      openAddTemplateModal: actions.openAddTemplateModal,
      openEditTemplateModal: actions.openEditTemplateModal,
      deleteTemplate: actions.deleteEmailTemplate,
      duplicateTemplate: actions.duplicateEmailTemplate
    }
  )
);

const Body = ({
  list,
  openAddTemplateModal,
  openEditTemplateModal,
  deleteTemplate,
  duplicateTemplate
}) => {
  const templates = R.compose(
    R.map(template => (
      <TemplatePanel
        key={template.id}
        template={template}
        editTemplate={() => openEditTemplateModal({ template })}
        deleteTemplate={() => deleteTemplate({ templateId: template.id })}
        duplicateTemplate={() => duplicateTemplate({ templateId: template.id })}
      />
    )),
    R.filter(template => template.type !== "invoice")
  )(list);

  return (
    <Panel>
      <PanelTitle
        right={
          <MediumFilledButton
            color="white"
            bg="altB5"
            LeftIcon={AddIcon}
            onClick={() => openAddTemplateModal({ type: "custom" })}
          >
            Add
          </MediumFilledButton>
        }
      >
        Custom Templates
      </PanelTitle>
      <div styleName="well">
        {!templates.length ? (
          <EmptyState
            title="You haven't added any templates yet"
            description="Custom email templates make it easy to send common emails quickly"
          />
        ) : (
          templates
        )}
      </div>
    </Panel>
  );
};

export default decorate(Body);
