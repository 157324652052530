import React from "react";

const getSenderName = ({ email, fname, lname, id }, currentUser) => {
  if (id === currentUser.id) {
    return "You";
  }

  if (!fname) {
    return email;
  }

  if (fname && lname) {
    return `${fname} ${lname}`;
  }

  return fname;
};

// @TODO: Allow modules to have custom activity handlers
export default ({ user, type }, currentUser) => {
  if (type === "add-value") {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong> updated a value
      </span>
    );
  } else if (type === "create") {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong> created this record
      </span>
    );
  } else if (type === "message") {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong>
      </span>
    );
  }

  return (
    <span>
      <strong>{getSenderName(user, currentUser)}</strong> did action of type{" "}
      {type}
    </span>
  );
};
