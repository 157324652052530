import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getForms(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const payload = await api.get(getState().user.user.credentials, eventId);
      dispatch({ type: RECEIVE, payload: { forms: payload } });
      return payload.forms;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting forms"
          }
        ])
      );
    }
  };
}

export function addForm(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding form"
          }
        ])
      );
    }
  };
}

export function cloneForm(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.clone(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred cloning form"
          }
        ])
      );
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
