import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ViewPopover from "./Popover";
import ViewSelector from "./ViewSelector";
import { connect } from "react-redux";
import { Div, Popover } from "components/Base";
import { bindInstance } from "redux-mvc";
import { actions, getters } from "ui-kit/ViewPicker";

const decorate = connect(
  (state, props) => ({
    views: getters.views(state, props)
  }),
  bindInstance({
    init: actions.init
  })
);

class Layout extends Component {
  constructor(props) {
    super(props);

    props.init({
      views: props.views,
      activeViewId: props.activeViewId
    });
  }

  render() {
    const {
      instanceId,
      moduleId,
      toolbar,
      onClick,
      small = false,
      white = false,
      ...styleProps
    } = this.props;

    return (
      <Div
        width={1}
        style={{
          minWidth: 165,
          maxWidth: toolbar ? 165 : undefined
        }}
        className={white ? undefined : css.button2}
        bra={1}
        py={toolbar ? 0 : 2}
        px={3}
        bg={white ? "white" : undefined}
        {...styleProps}
      >
        {onClick ? (
          <ViewSelector
            instanceId={instanceId}
            small={small}
            white={white}
            onClick={onClick}
          />
        ) : (
          <Popover
            wrapperProps={{
              width: 1,
              height: 1
            }}
            innerSpanStyle={{
              width: "100%",
              height: "100%"
            }}
            Label={({ onClick }) => (
              <ViewSelector
                instanceId={instanceId}
                small={small}
                white={white}
                onClick={onClick}
              />
            )}
          >
            {({ closePopover }) => (
              <ViewPopover
                instanceId={instanceId}
                moduleId={moduleId}
                composeClosePopover={closePopover}
              />
            )}
          </Popover>
        )}
      </Div>
    );
  }
}

export default CSSModules(decorate(Layout), css);
