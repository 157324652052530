import { isArray } from "lodash";

const cleanObj = obj =>
  Object.keys(obj)
    .filter(key => obj[key])
    .reduce((map, key) => {
      if (isArray(obj[key])) {
        map[key] = JSON.stringify(obj[key]);
      } else {
        map[key] = obj[key];
      }
      return map;
    }, {});

export default options => {
  if (!options) return null;
  const cleanOptions = options ? cleanObj(options) : {};
  const cleanOptionKeys = Object.keys(cleanOptions);

  if (!cleanOptionKeys.length) return null;

  return cleanOptionKeys.reduce((a, b, idx) => {
    if (idx === cleanOptionKeys.length - 1) {
      return `${a}${b}=${cleanOptions[b]}`;
    }
    return `${a}${b}=${cleanOptions[b]}&`;
  }, "?");
};
