import PropTypes from "prop-types";
import React, { Component } from "react";
import { find } from "lodash";
import { Link } from "react-router";
import classNames from "classnames";
import SiteHeader from "components/Global/Headers/SiteHeader";

export default class LoggedOutHeader extends Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    location: PropTypes.object,
    hideNavItems: PropTypes.bool,
    slimNavItems: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  showHeaderLinks() {
    return !find(this.props.routes, { name: "formLandingConvert" });
  }

  render() {
    let linksClasses = { "base-header-links-loggedout": true };
    if (this.props.hideNavItems || this.props.slimNavItems) {
      linksClasses.hide = true;
    }
    linksClasses = classNames(linksClasses);

    let headerClasses = { "base-header": true, "logged-out": true };
    if (this.props.hideNavItems || this.props.slimNavItems) {
      headerClasses.blend = true;
    }
    headerClasses = classNames(headerClasses);

    let login;
    const path = this.props.location.pathname + this.props.location.search;
    if (path === "/") {
      login = "/login";
    } else {
      login = `/login?returnTo=${path}`;
    }

    const mobileLinks = this.showHeaderLinks() && (
      <ul className="links">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="/login">Login</a>
        </li>
        <li>
          <a href="http://blog.lennd.com/">Blog</a>
        </li>
      </ul>
    );

    const slogan = (
      <div className="base-header-slogan">Your events. Easier.</div>
    );

    const primaryLinks = this.showHeaderLinks() && (
      <ul className="base-header-links-loggedout">
        <li>
          <a href={login}>Login</a>
        </li>
      </ul>
    );

    return (
      <SiteHeader
        headerClasses={headerClasses}
        mobileLinks={mobileLinks}
        primaryLinks={primaryLinks}
        slogan={slogan}
      />
    );
  }
}
