import { createModule } from "redux-mvc";

import model from "Organizations/Home/model";
import sagas from "Organizations/Home/sagas";

import activityDetailsSidebar from "Schedules/ActivityDetailsSidebar";

const module = createModule(model);

module.plugModule(activityDetailsSidebar);

module.setRootSaga(sagas);

export default module;
