import React from "react";
import {
  CheckIcon,
  CloseIcon,
  Div,
  DownFilledIcon,
  MediumShadedButton,
  MediumShadedBox,
  Popover,
  Text2,
  Text3
} from "components/Base";

export const BulkApprovalButton = ({
  approveRemaining,
  denyRemaining,
  totalUserApprovals,
  LabelComp = MediumShadedButton,
  labelProps = {}
}) => (
  <Popover
    Label={({ onClick }) => (
      <LabelComp onClick={onClick} {...labelProps}>
        Review All <DownFilledIcon ml={1} color={labelProps.color} />
      </LabelComp>
    )}
  >
    {({ closePopover }) => (
      <Div bra={2} width={250}>
        <Div py={3} px={3} bb={1} bc="neutral4">
          <Text2>You are an approver on</Text2>
          <Text2 bold>{totalUserApprovals} line items</Text2>
        </Div>
        <Div
          py={3}
          px={3}
          bb={1}
          bc="neutral4"
          display="row.flex-start.center"
          onClick={() => {
            closePopover();
            approveRemaining();
          }}
          bg={{ default: "white", hover: "neutral1" }}
        >
          <MediumShadedBox mr={2} pill color="success5" OnlyIcon={CheckIcon} />
          <Text3 bold>Approve Remaining</Text3>
        </Div>
        <Div
          py={3}
          px={3}
          display="row.flex-start.center"
          onClick={() => {
            closePopover();
            denyRemaining();
          }}
          bg={{ default: "white", hover: "neutral1" }}
        >
          <MediumShadedBox pill mr={2} color="danger5" OnlyIcon={CloseIcon} />
          <Text3 bold>Deny Remaining</Text3>
        </Div>
      </Div>
    )}
  </Popover>
);
