import { all, takeEvery, fork, put, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "Portal/Settings/Page/model";
import { actions as treeTableActions } from "ui-kit/TreeTable/model";
import { getItemsToSave } from "ui-kit/TreeTable/selectors";
import { actions as addPageModalActions } from "Portal/Settings/AddPageModal/model";
import { actions as addFolderModalActions } from "Portal/Settings/AddFolderModal/model";
import { SIDEBAR_OPTIONS } from "Portal/Settings/Page/constants";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventId } = yield call(getParams);
    const {
      payload: { pages }
    } = yield call(Api.getPages, { credentials, eventId });

    yield put(
      treeTableActions.setInitialData({
        items: pages,
        canDrag: true
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred loading pages"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const filterPageStatus = function*({ payload: status }) {
  const items = yield select(getters.items);
  const filteredItems =
    status === SIDEBAR_OPTIONS.ALL
      ? items
      : R.filter(
          item => item.type === "folder" || item.status === status,
          items
        );
  yield all([
    yield put(actions.setSidebarOption(status)),
    yield put(
      treeTableActions.setFilterList(R.map(({ id }) => id, filteredItems))
    )
  ]);
};

const edit = function*() {};

const deleteItem = function*({ payload: { id } }) {
  try {
    const { credentials } = yield call(getParams);
    const data = {
      pageId: id
    };
    yield call(Api.deletePage, { credentials, data });
    yield call(init);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred updating ordering"
          }
        ])
      )
    ]);
  }
};

const closeAddPageModal = function*() {
  yield put(actions.setShowAddPageModal(false));
  yield put(addPageModalActions.resetData());
};

const updateItemsOrder = function*() {
  try {
    const { credentials } = yield call(getParams);
    const pages = yield select(getItemsToSave);
    const data = {
      bulk: true,
      pages
    };

    yield call(Api.reorderPages, { credentials, data });
    yield call(init);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred updating ordering"
          }
        ])
      )
    ]);
  }
};

const refetchCloseFolderModal = function*() {
  yield put(actions.closeAddFolderModal());
  yield call(init);
};

const refetchClosePageModal = function*() {
  yield put(actions.setShowAddPageModal(false));
  yield call(init);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefetchCloseFolderModal = function*() {
  yield takeEvery(
    addFolderModalActions.closeModal.type,
    refetchCloseFolderModal
  );
};

const watchRefetchClosePageModal = function*() {
  yield takeEvery(addPageModalActions.closeModal.type, refetchClosePageModal);
};

const watchEdit = function*() {
  yield takeEvery(actions.edit.type, edit);
};

const watchDelete = function*() {
  yield takeEvery(actions.deleteItem.type, deleteItem);
};

const watchFilterPageStatus = function*() {
  yield takeEvery(actions.filterPageStatus.type, filterPageStatus);
};

const watchCloseAddPageModal = function*() {
  yield takeEvery(actions.closeAddPageModal.type, closeAddPageModal);
};

const watchUpdateItemsOrder = function*() {
  yield takeEvery(treeTableActions.saveAfterReordering.type, updateItemsOrder);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchFilterPageStatus),
    fork(watchEdit),
    fork(watchDelete),
    fork(watchCloseAddPageModal),
    fork(watchRefetchCloseFolderModal),
    fork(watchRefetchClosePageModal),
    fork(watchUpdateItemsOrder)
  ]);
};

export default rootSaga;
