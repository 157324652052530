import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Internal from "./Internal";
import AssignItemBlocksModal from "./AssignItemBlocks";
import PortalMessageModal from "components/Event/Settings/Module/Modals/PortalMessageModal";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import { difference, find, map, prop, propEq } from "ramda";
import SelectRequiredFieldsModal from "Modules/SelectRequiredFieldsModal/View/index.js";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import * as R from "ramda";
import FormWizardModal from "Forms/WizardModal/View";

class PermissionsExternal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showIntakeSettingsModal: false,
      showCustomizeSectionsModal: false
    };
  }

  componentDidMount() {
    Promise.all([
      this.fetchPermissionSets(),
      this.props.getItemBlocks(this.props.eventId),
      this.props.getContactRecordTypes(this.props.eventId),
      this.props.getForms(this.props.eventId),
      this.props.getFields({
        moduleId: STANDARD_MODULES.contacts.id,
        options: {
          eventId: this.props.eventId
        }
      })
    ]).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    const { activePermissionSet, permissionSets } = oldProps;

    if (
      activePermissionSet &&
      !find(propEq("id", activePermissionSet.id))(newProps.permissionSets)
    ) {
      // if active pset no longer exists, reset to first
      this.setActivePermissionSet(
        newProps.permissionSets[0] ? newProps.permissionSets[0].id : null
      );
    }

    // set active pset to newest when adding one
    if (
      activePermissionSet &&
      permissionSets.length < newProps.permissionSets.length
    ) {
      const mapId = map(prop("id"));

      const selected = find(
        propEq(
          "id",
          difference(mapId(newProps.permissionSets), mapId(permissionSets))[0]
        )
      )(newProps.permissionSets);

      this.setActivePermissionSet(selected ? selected.id : null);
    }
  }

  fetchPermissionSets = async () => {
    return await this.props.getPermissionSets(this.props.eventId);
  };

  editPermissionSet = async values => {
    await this.props.updatePermissionSet({
      permissionSetId: this.props.activePermissionSet.id,
      values: { ...this.props.activePermissionSet, ...values }
    });
    return await this.fetchPermissionSets();
  };

  addPermissionSet = async () => {
    const { id } = await this.props.addPermissionSet({
      orgId: this.props.orgId,
      eventId: this.props.eventId
    });

    await this.props.updateRecordType({
      moduleId: this.props.moduleId,
      recordType: {
        id: this.props.selectedTypeId,
        permissionSetId: id
      },
      options: {
        orgId: this.props.orgId,
        eventId: this.props.eventId
      }
    });

    if (this.props.moduleId === STANDARD_MODULES.contacts.id) {
      await this.props.getContactRecordTypes(this.props.eventId);
    } else {
      await this.props.getAccountRecordTypes(this.props.eventId);
    }

    return await this.fetchPermissionSets();
  };

  updateSelectedIntakeForm = async formId => {
    return this.editPermissionSet({
      intakeFormId: formId
    });
  };

  editItemBlock = itemBlockId => {
    this.props.showModal({
      content: (
        <ItemBlockModal
          onDone={async () => {
            await this.fetchPermissionSets();

            return await this.props.getItemBlocks(this.props.eventId);
          }}
          itemBlockId={itemBlockId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  updateRecordTypeSetting = async (permissionSetId, typeId, value) => {
    const { types } = this.getPermissionSet(permissionSetId);
    const values = types.find(t => t.id === typeId);
    await this.props.updateRecordTypeForPermissionSet({
      permissionSetId,
      typeId,
      values: { ...values, ...value }
    });
    return await this.fetchPermissionSets();
  };

  getPermissionSet = id => this.props.permissionSets.find(p => p.id === id);

  showAssignItemBlockModal = (id, typeId) =>
    this.props.showModal({
      content: (
        <AssignItemBlocksModal
          onSave={({ blocksToAdd, blocksToRemove }) => {
            this.assignItemBlocks(blocksToAdd, id, typeId);
            this.removeItemBlockAssignments(blocksToRemove, id, typeId);
          }}
          permissionSetId={id}
          contactRecordTypeId={typeId}
        />
      ),
      wrapper: ModalWrapper
    });

  assignItemBlocks = async (blockIds, permissionSetId, recordTypeId) => {
    await Promise.all(
      blockIds.map(blockId =>
        this.props.addItemBlock({ blockId, permissionSetId, recordTypeId })
      )
    );
    return await this.fetchPermissionSets();
  };

  removeItemBlockAssignments = async (
    blockIds,
    permissionSetId,
    recordTypeId
  ) => {
    const { item_blocks } = this.getPermissionSet(permissionSetId);
    const itemsOfType = item_blocks.filter(
      i => i.record_type_id === recordTypeId
    );
    const ids = blockIds.map(
      blockId => itemsOfType.find(i => i.block_id === blockId).id
    );
    await Promise.all(
      ids.map(id => this.props.removeItemBlock(permissionSetId, id))
    );
    return await this.fetchPermissionSets();
  };

  setActivePermissionSet = setId => {
    this.props.router.push({
      pathname: setId
        ? `/event-light/${this.props.params.eventId}/crm/settings/${this.props.params.moduleId}/permissions/${setId}`
        : `/event-light/${this.props.params.eventId}/crm/settings/${this.props.params.moduleId}/permissions`
    });
  };

  showMessageModal = () => {
    this.props.showModal({
      content: (
        <PortalMessageModal
          title={this.props.activePermissionSet.title}
          message={this.props.activePermissionSet.message}
          onSave={({ title, message }) => {
            this.editPermissionSet({
              title: title && title.length ? title : null,
              message: message && message.length ? message : null
            });
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditColumnsModal = ({
    visibleFields,
    fieldOrder,
    onSave,
    requiredFields
  }) => {
    const columns = R.compose(
      R.map(f => ({
        id: f.id,
        name: f.name,
        type: f.type,
        source: f.source
      })),
      R.sortBy(f => (f.name ? f.name.toLowerCase() : f.name))
    )([
      ...this.props.contactFields,
      {
        id: "role",
        name: "Role",
        type: "text",
        source: "standard"
      }
    ]);

    this.props.showModal({
      content: (
        <SelectRequiredFieldsModal
          onSave={onSave}
          columns={columns}
          visibleFields={visibleFields}
          requiredFields={requiredFields}
          fieldOrder={fieldOrder}
          notDraggableFieldIds={[
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
          ]}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showFormWizardModal = () => {
    this.props.showModal({
      content: <FormWizardModal />,
      wrapper: ModalWrapper
    });
  };

  updateCloseDate = date =>
    this.editPermissionSet({
      closeDate: date
    });

  toggleIsLocked = isLocked =>
    this.editPermissionSet({
      isLocked
    });

  hideIntakeSettingsModal = () => {
    this.setState({ showIntakeSettingsModal: false });
  };

  openIntakeSettingsModal = () => {
    this.setState({ showIntakeSettingsModal: true });
  };

  onClickLinkInput = ref => {
    if (ref.current) {
      ref.current.setSelectionRange(0, event.target.value.length);
    }
  };

  onCopyLinkInput = ref => {
    // is element selectable?
    if (ref.current && ref.current.select) {
      ref.current.select();

      try {
        document.execCommand("copy");
        ref.current.blur();
        this.props.showSnackbar({ message: "Link Copied", action: "OK" });
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert("Please press Ctrl/Cmd+C to copy.");
      }
    }
  };

  disableIntakeForm = async formId => {
    await this.props.updateIntakeForm({
      permissionSetId: this.props.activePermissionSet.id,
      formId,
      isEnabled: false
    });

    return await this.fetchPermissionSets();
  };

  goToForm = (form, path = "") => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/module/${form.base_module_id}/form/${form.id}${path}`
    });
  };

  hideCustomizeSectionsModal = () => {
    this.setState({ showCustomizeSectionsModal: false });
  };

  openCustomizeSectionsModal = () => {
    this.setState({ showCustomizeSectionsModal: true });
  };

  onToggleRequiredItemBlock = async ({
    permissionSetId,
    recordTypeId,
    blockId,
    required
  }) => {
    await this.props.updateItemBlock({
      permissionSetId,
      recordTypeId,
      blockId,
      required
    });
    return await this.fetchPermissionSets();
  };

  render() {
    const {
      eventDetails,
      itemBlocks,
      permissionSets,
      peopleTypes,
      activePermissionSet,
      applicationForms
    } = this.props;

    const methods = {
      disableIntakeForm: this.disableIntakeForm,
      addPermissionSet: this.addPermissionSet,
      onAddItemBlock: this.showAssignItemBlockModal,
      onEditItemBlock: this.editItemBlock,
      updateRecordTypeSetting: this.updateRecordTypeSetting,
      updateSelectedIntakeForm: this.updateSelectedIntakeForm,
      showMessageModal: this.showMessageModal,
      updateCloseDate: this.updateCloseDate,
      toggleIsLocked: this.toggleIsLocked,
      showEditColumnsModal: this.showEditColumnsModal,
      showFormWizardModal: this.showFormWizardModal,
      hideIntakeSettingsModal: this.hideIntakeSettingsModal,
      openIntakeSettingsModal: this.openIntakeSettingsModal,
      fetchPermissionSets: this.fetchPermissionSets,
      onClickLinkInput: this.onClickLinkInput,
      onCopyLinkInput: this.onCopyLinkInput,
      goToForm: this.goToForm,
      hideCustomizeSectionsModal: this.hideCustomizeSectionsModal,
      openCustomizeSectionsModal: this.openCustomizeSectionsModal,
      onToggleRequiredItemBlock: this.onToggleRequiredItemBlock
    };

    const childProps = {
      peopleTypesOnly: this.props.peopleTypesOnly,
      selectedTypeName: this.props.selectedTypeName,
      loading: this.state.loading,
      itemBlocks,
      peopleTypes,
      permissionSets,
      activePermissionSet,
      showIntakeSettingsModal: this.state.showIntakeSettingsModal,
      eventDetails,
      applicationForms,
      showCustomizeSectionsModal: this.state.showCustomizeSectionsModal,
      isAccountPortal:
        STANDARD_MODULES.accounts.id === this.props.moduleId ? true : false
    };
    return <Internal {...childProps} {...methods} />;
  }
}

export default PermissionsExternal;
