import { createModule } from "redux-mvc";

import model from "./model";

import FormModule from "ui-kit/Form";

import sagasModule from "./sagas";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "organization"]
});

module.plugModule(FormModule);

module.setRootSaga(sagasModule);

export default module;
