import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Input from "@lennd/react-telephone-input";
import getValue from "utils/value-types/get-value/phone";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

require("@lennd/react-telephone-input/css/default.css");

const hasValue = value => /\+.+/.test(value);

@CSSModules(css)
class PhoneEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillReceiveProps(props) {
    if (this.props.value !== props.value) {
      this.setState({
        value: this.formatValue(props.value)
      });
    }
  }

  wrapValue(value) {
    return {
      type: "phone",
      value: hasValue(value) ? value : undefined
    };
  }

  formatValue(value) {
    return getValue(value || {});
  }

  @autobind
  onChange(value) {
    if (hasValue(value)) {
      this.setState({
        value
      });
      if (this.props.continuous) {
        this.props.onChange(this.wrapValue(value));
      }
    }
  }

  @autobind
  onBlur() {
    this.props.onChange(this.wrapValue(this.state.value));
  }

  render() {
    const { disabled, autocomplete } = this.props;
    return (
      <div styleName="phoneEditor">
        <Input
          ref="editor"
          disabled={disabled}
          value={this.state.value}
          autocomplete={autocomplete}
          pattern="\+.+"
          defaultCountry="us"
          flagsImagePath="https://d2dks4tzxs6xee.cloudfront.net/img/flags.png"
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}

PhoneEditor.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default PhoneEditor;
