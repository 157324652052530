import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getFilterOptions } from "Passes/Confirmations/selectors";

import PassesTypeSelect from "Passes/Confirmations/View/PassTypeSelect";
import SourceTypeSelect from "Passes/Confirmations/View/SourceTypeSelect";
import UserTypeSelect from "Passes/Confirmations/View/UserTypeSelect";

import FiltersPanel from "ui-kit/FiltersPanel/View";

const decorate = connect((state, props) => ({
  sections: getFilterOptions(state, props)
}));

const Filters = ({
  sections = [],
  attendeeTypes, // eslint-disable-line no-unused-vars
  groupTypes, // eslint-disable-line no-unused-vars
  ...styleProps
}) => (
  <FiltersPanel
    {...styleProps}
    sections={sections}
    footer={
      <Fragment>
        <PassesTypeSelect />
        <SourceTypeSelect />
        <UserTypeSelect />
      </Fragment>
    }
  />
);

export default decorate(Filters);
