import React, { Fragment } from "react";
import { mapProps, withProps, withState, withHandlers } from "utils/General";
import * as R from "ramda";
import {
  searchHandler,
  CheckCircleIcon,
  Div,
  FontIcon,
  Fade,
  Dropdown,
  BigFilledButton,
  LeftArrowIcon,
  BigInsetInput,
  PeopleIcon,
  RightArrowIcon,
  Text1,
  Text3,
  SmallToggle,
  AddIcon,
  BagIcon,
  Text2,
  Type13Card,
  Text4,
  SearchIcon,
  BigShadedInput,
  MediumClearButton
} from "components/Base";
import {
  Tab,
  Tabs,
  Sidebar,
  NotificationWell,
  DismissableWell,
  Loading
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";
import FieldCard from "./FieldCard";
import SortableFields from "./SortableFields";

const LIMITS = [0, 1, 2, 3, 4, 5, 10];
const mergeState = key => ({ updateSettings, settings }) => value => {
  updateSettings({ ...settings, [key]: value });
};
const commonActions = withHandlers({
  setTitle: mergeState("title"),
  setLimit: mergeState("limit"),
  setCollectionMode: mergeState("collectionMode"),
  setLookup: mergeState("lookupEnabled"),
  setMarkPrimary: mergeState("markAsPrimary"),
  setIsRequired: mergeState("isRequired"),
  setRole: mergeState("role"),
  setRoleFieldAlias: mergeState("roleFieldAlias"),
  setRecordType: mergeState("defaultRecordType"),
  setRowLabel: mergeState("rowLabel")
});
const defaultStateMapper = props => ({
  title: props.title,
  limit: props.limit || { enabled: false, value: 0 },
  collectionMode: props.collectionMode || "form",
  lookupEnabled: props.lookupEnabled || false,
  markAsPrimary: props.markAsPrimary || false,
  isRequired: props.isRequired || false,
  role: props.role || {
    enabled: false,
    type: "collect_for_each",
    value: undefined
  },
  roleFieldAlias: props.roleFieldAlias,
  defaultRecordType: undefined,
  rowLabel: props.rowLabel || null,
  ...props
});

const setupStateHandler = R.compose(
  withState(
    "customLimit",
    "setCustomLimit",
    props =>
      R.path(["limit", "value"], props) &&
      !LIMITS.includes(R.path(["limit", "value"], props))
  ),
  withState("settings", "updateSettings", defaultStateMapper),
  commonActions
);

const setupExistingBlockState = R.compose(
  withState(
    "customLimit",
    "setCustomLimit",
    props =>
      R.path(["limit", "value"], props) &&
      !LIMITS.includes(R.path(["limit", "value"], props))
  ),
  mapProps(defaultStateMapper),
  commonActions
);

const Toolbar = withProps({
  px: 3,
  height: 50,
  display: "row.flex-start.center",
  bg: "white",
  bb: 1,
  bc: "neutral3"
})(Div);

export const PeopleLoading = ({}) => (
  <Sidebar>
    <Toolbar>
      <LeftArrowIcon sizeWFS={4} mr={3} onClick={() => {}} />
      <PeopleIcon sizeWFS={4} mr={2} />
      <Div width={100} height={15} bg="gray1" />
    </Toolbar>
    <Loading />
  </Sidebar>
);

const Setup = ({
  peopleTypes,
  title,
  role,
  limit,
  customLimit,
  markAsPrimary,
  roleFieldAlias,
  isRequired,
  defaultRecordType,
  setTitle,
  setCustomLimit,
  setMarkPrimary,
  setRole,
  setLimit,
  setRoleFieldAlias,
  setIsRequired,
  setRecordType,
  setRowLabel,
  rowLabel
}) => (
  <Fragment>
    <Text2 bold mb={1}>
      Name of People Block
    </Text2>
    <BigInsetInput
      value={title}
      mb={5}
      width={1}
      onChange={setTitle}
      placeholder="Name..."
    />

    <Text2 bold mb={1}>
      Row Label
    </Text2>
    <BigInsetInput value={rowLabel} mb={5} width={1} onChange={setRowLabel} />

    <Div display="column" mb={5} pb={5} bc="gray2" bb={1}>
      <Div display="row.space-between.center">
        <Text2 bold>Mark people as "Primary Contacts"</Text2>
        <SmallToggle
          active={markAsPrimary}
          onClick={() => setMarkPrimary(!markAsPrimary)}
        />
      </Div>
    </Div>

    <Div display="column" mb={5} pb={5} bc="gray2" bb={1}>
      <Div display="row.space-between.center">
        <Text2 bold>Require at least one person added</Text2>
        <SmallToggle
          active={isRequired}
          onClick={() => setIsRequired(!isRequired)}
        />
      </Div>
    </Div>

    <Div display="column" mb={5} pb={5} bc="gray2" bb={1}>
      <Div display="row.space-between.center" mb={limit.enabled ? 2 : 0}>
        <Text2 bold>Allow more than one to be added</Text2>
        <SmallToggle
          active={limit.enabled}
          onClick={() =>
            setLimit({ enabled: !limit.enabled, value: limit.value })
          }
        />
      </Div>

      <Fade when={limit.enabled} collapse>
        <div>
          <Div mt={1} display="row.flex-start.center">
            <Div width={1 / 2}>
              <Dropdown
                value={customLimit ? "custom" : limit.value}
                style={{ minWidth: "inherit" }}
                onChange={({ value }) => {
                  if (value === "custom") {
                    return setCustomLimit(true);
                  }
                  setCustomLimit(false);
                  setLimit({
                    enabled: true,
                    value
                  });
                  return null;
                }}
                options={[
                  {
                    label: "No Limit",
                    value: 0
                  },
                  ...LIMITS.slice(1).map(val => ({
                    label: `Limit of ${val}`,
                    value: val
                  })),
                  {
                    label: "Custom Limit",
                    value: "custom"
                  }
                ]}
                usePortal
              />
            </Div>
            <Fade when={customLimit} collapse>
              <BigInsetInput
                value={limit.value}
                onChange={value =>
                  setLimit({ enabled: true, value: value || 1 })
                }
                width={1 / 2}
                ml={2}
              />
            </Fade>
          </Div>
        </div>
      </Fade>
    </Div>

    <Div display="column" mb={5} pb={5} bc="gray2" bb={1}>
      <Div display="row.space-between.center" mb={role.enabled ? 2 : 0}>
        <Text2 bold>Collect person's role</Text2>
        <SmallToggle
          active={role.enabled}
          onClick={() =>
            setRole({
              enabled: !role.enabled,
              type: role.type || "collect_for_each"
            })
          }
        />
      </Div>
      {role.enabled ? (
        <Dropdown
          disabled={!role.enabled}
          onChange={({ value }) => setRole({ ...role, type: value })}
          value={role.type}
          usePortal
          options={[
            {
              label: "Collect as a question for each person",
              value: "collect_for_each"
            },
            { label: "Set a default role", value: "specify_for_all" }
          ]}
        />
      ) : null}
      {role.enabled && role.type === "specify_for_all" ? (
        <BigInsetInput
          my={3}
          placeholder="e.g. Onsite Contact"
          value={role.value}
          onChange={value => setRole({ ...role, value })}
        />
      ) : null}
      {role.enabled && role.type === "collect_for_each" ? (
        <Div display="column" mt={3}>
          <Text2 bold>Name of "Role" field</Text2>
          <BigInsetInput
            mt={3}
            placeholder="e.g. Position"
            value={roleFieldAlias}
            onChange={setRoleFieldAlias}
          />
        </Div>
      ) : null}
    </Div>

    <Div mb={10}>
      <Text2 bold pb={2}>
        Set default people type:
      </Text2>
      <Dropdown
        value={defaultRecordType}
        onChange={({ value }) => setRecordType(value)}
        options={peopleTypes}
        usePortal
      />
    </Div>
  </Fragment>
);

export const PeopleBlock = R.compose(
  withState("activeSubtab", "setActiveTab", "fields"),
  searchHandler("fields", "fields")
)(
  ({
    onFieldReorder,
    itemBlocks,
    peopleTypes,
    onCreateField,
    fields = [],
    onSettingsChange,
    settings,
    activeSubtab,
    setActiveTab,
    onReturn,
    onCancel,
    tabs,
    onSearch,
    searchTerm,
    selectedFields,
    onCreateItemBlock,
    showFlashMsg
  }) => {
    return (
      <Sidebar>
        <Toolbar>
          <LeftArrowIcon sizeWFS={4} mr={3} onClick={onReturn} />
          <PeopleIcon sizeWFS={4} mr={2} />
          <Text4 bold flex={1}>
            {settings.title}
          </Text4>
          <MediumClearButton onClick={onCancel} color="primary5">
            Done
          </MediumClearButton>
        </Toolbar>

        <Tabs>
          {tabs.map(tab => (
            <Tab
              key={tab.key}
              flex={0}
              px={8}
              onClick={tab.onClick}
              height={40}
              active={tab.isActive}
            >
              {tab.title}
            </Tab>
          ))}
        </Tabs>
        {(() => {
          const activeScene = R.compose(
            R.prop("key"),
            R.find(R.prop("isActive"))
          )(tabs);
          switch (activeScene) {
            case "settings": {
              const WrappedSetup = setupExistingBlockState(Setup);
              return (
                <Div p={4} style={{ overflowY: "auto" }}>
                  <WrappedSetup
                    updateSettings={onSettingsChange}
                    {...settings}
                    peopleTypes={peopleTypes}
                  />
                </Div>
              );
            }
            case "fields":
            default: {
              return (
                <Fragment>
                  <DismissableWell
                    Icon={CheckCircleIcon}
                    isActive={showFlashMsg}
                  >
                    <Text1>People block created!</Text1>
                    <Text2 bold>Select fields to add to the form...</Text2>
                  </DismissableWell>
                  <Div p={4} style={{ overflowY: "auto" }}>
                    <Text4 bold pb={2}>
                      Showing {selectedFields.length} Fields
                    </Text4>
                    <FieldCard {...selectedFields[0]} />
                    <SortableFields
                      cards={selectedFields.slice(1).filter(f => f.canDrag)}
                      onReorder={onFieldReorder}
                    />
                    {selectedFields
                      .slice(1)
                      .filter(f => !f.canDrag)
                      .map(field => (
                        <FieldCard key={field.id} {...field} />
                      ))}
                    <BigShadedInput
                      width={1}
                      onChange={onSearch}
                      continuous
                      RightIcon={SearchIcon}
                      mt={4}
                      placeholder="Search..."
                    />
                    <Tabs bg={null}>
                      <Tab
                        fs={2}
                        px={4}
                        height={40}
                        onClick={() => setActiveTab("fields")}
                        active={activeSubtab === "fields"}
                      >
                        Available Fields ({fields.length})
                      </Tab>
                      <Tab
                        px={4}
                        fs={2}
                        height={40}
                        onClick={() => setActiveTab("blocks")}
                        active={activeSubtab === "blocks"}
                      >
                        Item Blocks ({itemBlocks.length})
                      </Tab>
                    </Tabs>
                    {activeSubtab === "fields" ? (
                      <Fragment>
                        <MediumClearButton
                          my={3}
                          onClick={onCreateField}
                          LeftIcon={AddIcon}
                        >
                          Create new people field
                        </MediumClearButton>
                        {fields.map(field => (
                          <Type13Card
                            key={field.id}
                            name={field.name}
                            Icon={withProps({
                              children: field.icon,
                              color: "neutral5",
                              fs: 5
                            })(FontIcon)}
                            onAdd={field.onAdd}
                          />
                        ))}
                        {!fields.length && searchTerm ? (
                          <Div bg="gray1" p={3} bra={1} my={3}>
                            <Text3>
                              No fields found for '{searchTerm}
                              '.
                            </Text3>
                          </Div>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <MediumClearButton
                          my={3}
                          onClick={onCreateItemBlock}
                          LeftIcon={AddIcon}
                        >
                          Create new item block
                        </MediumClearButton>
                        {itemBlocks.map(block => (
                          <Type13Card
                            key={block.id}
                            name={block.name}
                            onAdd={block.onAdd}
                            tooltip={block.message}
                          />
                        ))}
                      </Fragment>
                    )}
                  </Div>
                </Fragment>
              );
            }
          }
        })()}
      </Sidebar>
    );
  }
);

export const PeopleSetup = setupStateHandler(
  ({ peopleTypes, onReturn, onCancel, onAdd, settings, ...rest }) => (
    <Sidebar>
      <Toolbar>
        <LeftArrowIcon sizeWFS={4} mr={3} onClick={onReturn} />
        <PeopleIcon sizeWFS={4} mr={2} />
        <Text4 bold flex={1}>
          Create People Block
        </Text4>
        <MediumClearButton onClick={onCancel}>Cancel</MediumClearButton>
      </Toolbar>
      <NotificationWell Icon={BagIcon}>
        People blocks create person records from your people related form fields
      </NotificationWell>

      <Div pt={4} pb={10} px={7}>
        <Setup peopleTypes={peopleTypes} {...settings} {...rest} />
        <BigFilledButton
          disabled={!settings.title}
          display="row.center.center"
          RightIcon={RightArrowIcon}
          bg="altB5"
          onClick={() => onAdd(settings)}
          mt={9}
          width={1}
        >
          Select fields
        </BigFilledButton>
      </Div>
    </Sidebar>
  )
);
