import React from "react";
import { connect } from "react-redux";
import { actions } from "../model";
import { BigFilledButton, PopoverMenu } from "components/Base";

import { noop } from "utils/General";

const decorate = connect(
  null,
  {
    showBulkModal: actions.showBulkModal,
    addItem: actions.addUpdateZoneItem
  }
);

const AddItemPopover = ({
  disabled = false,
  addItem = noop,
  showBulkModal = noop
}) => (
  <PopoverMenu
    Label={({ onClick }) => (
      <BigFilledButton bg="altB5" ml={1} onClick={onClick} disabled={disabled}>
        Add
      </BigFilledButton>
    )}
    menuItems={[["Add Zone", addItem], ["Bulk Add Zones", showBulkModal]]}
  />
);

export default decorate(AddItemPopover);
