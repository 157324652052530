import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
  getIntegrationsWithOrders,
  getIsSyncing,
  getCanBeSynced,
  getHasSyncIssue,
  getOrderIdsWithSyncIssues,
  getOrderIds
} from "Orders/OrderModal/selectors";
import Tooltip from "components/Global/Tooltip";
import { actions, getters } from "Orders/OrderModal";

import {
  RefreshIcon,
  Text4,
  Text1,
  Text2,
  Div,
  BigOutlineButton,
  collapsableHandler,
  RightIcon,
  DownIcon
} from "components/Base";
import { addS } from "utils/General";
import { TABS } from "Orders/OrderModal/constants";

import { StatusTag } from "utils/status-comps";

import Panel from "ui-kit/Panel";

const decorate = connect(
  state => ({
    integrations: getIntegrationsWithOrders(state, {}),
    forcedSyncing: getters.syncing(state),
    syncing: getIsSyncing(state),
    canBeSynced: getCanBeSynced(state),
    hasSyncIssue: getHasSyncIssue(state),
    orderIdsWithSyncIssues: getOrderIdsWithSyncIssues(state),
    orderIds: getOrderIds(state)
  }),
  {
    syncOrder: actions.syncOrder,
    openExternalOrder: actions.openExternalOrder,
    selectTab: actions.setSelectedTab
  }
);

const IntegrationPanel = collapsableHandler(
  ({
    collapsed,
    toggleCollapsed,
    name,
    orders,
    orderIds,
    lastSynced,
    forcedSyncing,
    syncing,
    openExternalOrder,
    syncOrder,
    hasSyncIssue,
    orderIdsWithSyncIssues,
    canBeSynced,
    orderIdsToSync,
    selectTab
  }) => {
    return (
      <Panel pl={7} pr={8} py={3} display="row.flex-start.center">
        <Div display="column.flex-start" width={hasSyncIssue ? 380 : 300}>
          <Div display="row.flex-start.center">
            <RefreshIcon size={32} mr={2} />
            <Text4 bold>{name}</Text4>
            <StatusTag
              status={
                syncing || forcedSyncing
                  ? "syncing"
                  : hasSyncIssue
                    ? "sync_issue"
                    : lastSynced
                      ? "synced"
                      : "not_synced"
              }
              ml={2}
            />
            {!syncing && !forcedSyncing && hasSyncIssue ? (
              <Div display="row.flex-start.center">
                <BigOutlineButton
                  ml={2}
                  onClick={() => syncOrder(orderIdsWithSyncIssues)}
                >
                  Retry Sync
                </BigOutlineButton>

                <BigOutlineButton
                  ml={2}
                  onClick={() => selectTab(TABS.MESSAGES)}
                >
                  View Issue
                </BigOutlineButton>
              </Div>
            ) : !syncing && !forcedSyncing && canBeSynced ? (
              <BigOutlineButton
                ml={2}
                onClick={() => syncOrder(orderIdsToSync)}
              >
                {lastSynced ? "Re-Sync" : "Sync"}
              </BigOutlineButton>
            ) : null}
          </Div>
        </Div>
        <Div display="row.flex-end.center" flex={1}>
          {lastSynced ? (
            <Div display="column.flex-start.flex-end" mr={2}>
              <Fragment>
                <Text1 color="gray7">Last synced {lastSynced}</Text1>
                <Div display="row.flex-start.center">
                  <Text1 color="gray7" onClick={toggleCollapsed}>
                    {orders.length} synced order
                    {addS(orders.length)}
                  </Text1>
                  {collapsed ? (
                    <RightIcon
                      style={{
                        marginTop: 3
                      }}
                      size={14}
                    />
                  ) : (
                    <DownIcon
                      style={{
                        marginTop: 3
                      }}
                      size={14}
                    />
                  )}
                </Div>
                {collapsed ? null : (
                  <Div display="row.flex-start.center">
                    {orders.map((order, idx) => (
                      <Fragment key={order.id}>
                        <Div
                          fs={1}
                          underline
                          onClick={
                            order.url
                              ? () => openExternalOrder(order.url)
                              : undefined
                          }
                        >
                          {order.foreignOrderId}
                        </Div>
                        {idx < orders.length - 1 ? (
                          <Div fs={1} mr={1}>
                            ,
                          </Div>
                        ) : null}
                      </Fragment>
                    ))}
                  </Div>
                )}
              </Fragment>
            </Div>
          ) : !hasSyncIssue ? (
            <Div
              display="column.flex-start.flex-end"
              mr={2}
              textAlign="right"
              width={280}
            >
              <Text1>
                Orders are synced when they have {name} items and have been
                fully reviewed.
              </Text1>
            </Div>
          ) : null}
        </Div>
      </Panel>
    );
  }
);

const Integrations = ({
  integrations,
  openExternalOrder,
  syncing,
  handlers,
  syncOrder,
  forcedSyncing,
  hasSyncIssue,
  canBeSynced,
  orderIdsWithSyncIssues,
  orderIds,
  selectTab
}) => {
  if (!integrations || !integrations.length) return null;

  return (
    <Div>
      {integrations.map(integration => (
        <IntegrationPanel
          key={integration.id}
          collapsedDefault
          openExternalOrder={openExternalOrder}
          syncing={syncing}
          forcedSyncing={forcedSyncing}
          hasSyncIssue={hasSyncIssue}
          canBeSynced={canBeSynced}
          orderIdsWithSyncIssues={orderIdsWithSyncIssues}
          orderIdsToSync={orderIds}
          syncOrder={syncOrder}
          selectTab={selectTab}
          {...integration}
        />
      ))}
    </Div>
  );
};

export default decorate(Integrations);
