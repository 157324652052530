import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters } from "Event/MyApprovals/model";
import {
  getCountOfPendingApprovals,
  getPendingItems,
  getGreeting
} from "Event/MyApprovals/selectors";
import {
  Div,
  Text4,
  ClockIcon,
  collapsableHandler,
  Collapsable,
  LoadingIcon
} from "components/Base";
import Circle from "ui-kit/Circle";
import CardRow from "Event/MyApprovals/View/Common/CardRow";

const decorate = connect(state => ({
  data: getters.myApprovals(state),
  countOfPendingApprovals: getCountOfPendingApprovals(state),
  pendingItems: getPendingItems(state),
  loading: getters.loading(state),
  greeting: getGreeting(state)
}));

const CardCollapsable = collapsableHandler(
  ({ item, push, toggleCollapsed, collapsed }) => (
    <Div>
      <Div onClick={toggleCollapsed}>
        <CardRow
          collapsable
          total={item.pending + item.approved + item.rejected}
          missing={item.pending}
          push={push}
          done={item.approved + item.rejected}
          url={item.path}
          name={item.name}
          groups={item.groups}
          collapsed={collapsed}
        />
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div pl={8}>
          {R.map(
            group => (
              <CardRow
                key={group.id}
                total={group.pending + group.approved + group.rejected}
                missing={group.pending}
                push={push}
                done={group.approved + group.rejected}
                url={item.path}
                name={group.name}
              />
            ),
            item.groups
          )}
        </Div>
      </Collapsable>
    </Div>
  )
);

const PendingCard = ({
  countOfPendingApprovals,
  router: { push },
  pendingItems,
  loading,
  greeting
}) => (
  <Div width={1} px={8} py={6} brt={1} bg="white" shadow={1}>
    <Div display="column" mb={3}>
      <Div fw={4} color="black" fs={6}>
        {greeting}
      </Div>
      <Div mt={2}>
        {countOfPendingApprovals ? (
          <Text4>You have {countOfPendingApprovals} requests to review</Text4>
        ) : (
          <Text4>You don't have any requests to review</Text4>
        )}
      </Div>
    </Div>

    <Div mt={6}>
      <Div display="row.flex-start.center" mb={4}>
        <Circle size={30} bg="altB2">
          <ClockIcon color="altB6" size={22} />
        </Circle>
        <Text4 bold color="black" ml={2}>
          Pending Your Approval
        </Text4>
      </Div>
      {!pendingItems.itemTypes.length &&
      !pendingItems.modules.length &&
      !pendingItems.document_requests.length ? (
        loading ? (
          <Div display="row.center.center" p={5}>
            <LoadingIcon size={30} color="gray4" />
          </Div>
        ) : (
          <Div mt={5} ml={0}>
            <Text4 color="black">You're all caught up!</Text4>
          </Div>
        )
      ) : null}
      {pendingItems.itemTypes.length ? (
        <Div mt={2}>
          <Div fs={2} fw={3} color="gray6" uppercase mb={3}>
            Items
          </Div>

          {R.map(
            item =>
              !R.length(R.propOr([], "groups", item)) ? (
                <CardRow
                  key={item.id}
                  total={item.pending + item.approved + item.rejected}
                  missing={item.pending}
                  push={push}
                  done={item.approved + item.rejected}
                  url={item.path}
                  name={item.name}
                />
              ) : (
                <CardCollapsable
                  key={item.id}
                  item={item}
                  push={push}
                  collapsedDefault={false}
                />
              ),
            pendingItems.itemTypes
          )}
        </Div>
      ) : null}
      {pendingItems.modules.length ? (
        <Div
          {...{
            bt: pendingItems.itemTypes.length ? 1 : 0,
            mt: pendingItems.itemTypes.length ? 4 : 0,
            pt: pendingItems.itemTypes.length ? 4 : 0
          }}
          bc="gray2"
        >
          <Div fs={2} fw={3} color="gray6" uppercase mb={3}>
            Forms
          </Div>

          {R.map(
            item => (
              <CardRow
                key={item.id}
                total={item.pending + item.approved + item.rejected}
                missing={item.pending}
                push={push}
                done={item.approved + item.rejected}
                url={item.path}
                name={item.name}
              />
            ),
            pendingItems.modules
          )}
        </Div>
      ) : null}
      {pendingItems.document_requests.length ? (
        <Div
          {...{
            bt: pendingItems.modules.length ? 1 : 0,
            mt: pendingItems.modules.length ? 4 : 0,
            pt: pendingItems.modules.length ? 4 : 0
          }}
          bc="gray2"
        >
          <Div fs={2} fw={3} color="gray6" uppercase mb={3}>
            File Requests
          </Div>
          {R.map(
            item => (
              <CardRow
                key={item.id}
                total={item.pending + item.approved + item.rejected}
                missing={item.pending}
                push={push}
                done={item.approved + item.rejected}
                url={item.path}
                name={item.name}
              />
            ),
            pendingItems.document_requests
          )}
        </Div>
      ) : null}
    </Div>
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(PendingCard);
