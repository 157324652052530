import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Portal/PortalPagePGA/PortalPagePGA/model";
import { user as getUser } from "redux/modules/user/selectors";
import {
  relatedAccountId,
  relatedAccountName
} from "redux/modules/portal/user/selectors";
import { Helmet } from "react-helmet";
import Loading from "ui-kit/Loading";
import { Div } from "components/Base";

import * as R from "ramda";
import Body from "./Body";

const decorate = connect(
  (state, props) => ({
    user: getUser(state),
    relatedAccountId: relatedAccountId(state),
    relatedAccountName: relatedAccountName(state),
    isLoading: getters.loading(state, props)
  }),
  { init: actions.init }
);

const Layout = ({
  init,
  user,
  relatedAccountId,
  relatedAccountName,
  pageData,
  isLoading
}) => {
  useEffect(() => {
    init(pageData);

    if (R.path(["details", "intercom_id"])(pageData)) {
      // call intercom
      window.intercomSettings = {
        app_id: R.path(["details", "intercom_id"])(pageData),
        custom_launcher_selector: R.pathEq(
          ["details", "intercom_method"],
          "client"
        )(pageData)
          ? ".launch-intercom"
          : undefined
      };

      if (!window.Intercom) {
        (function() {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function() {
              i.c(arguments);
            };
            i.q = [];
            i.c = function(args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function() {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src =
                "https://widget.intercom.io/widget/" +
                R.path(["details", "intercom_id"])(pageData);
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }

      if (window.Intercom) {
        console.log(
          `[Intercom] Booting for Intercom ID ${R.path([
            "details",
            "intercom_id"
          ])(pageData)}...`
        );
        window.Intercom("update", {
          hide_default_launcher: true
        });

        window.Intercom("boot", {
          app_id: R.path(["details", "intercom_id"])(pageData),
          custom_launcher_selector: R.pathEq(
            ["details", "intercom_method"],
            "client"
          )(pageData)
            ? ".launch-intercom"
            : undefined,
          user_id: user.id,
          email: user.email,
          name: [user.fname, user.lname].filter(v => v).join(" "),
          company: relatedAccountName
            ? {
                id: relatedAccountId,
                name: relatedAccountName
              }
            : {}
        });
      }
    }

    // call google tag manager
    if (R.path(["details", "google_tag_manager_id"])(pageData)) {
      if (!window.google_tag_manager) {
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(
          window,
          document,
          "script",
          "dataLayer",
          R.path(["details", "google_tag_manager_id"])(pageData)
        );
      }
    }
  }, [pageData]);

  return (
    <>
      {R.path(["details", "event_title"])(pageData) ? (
        <Helmet>
          <title>
            {R.pathOr("", ["details", "event_title"])(pageData)} | Media Hub
            powered by Lennd
          </title>
        </Helmet>
      ) : null}
      {R.path(["details", "google_tag_manager_id"])(pageData) ? (
        <span
          dangerouslySetInnerHTML={{
            __html: `
              <!-- Google Tag Manager (noscript) -->
              <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${R.path(
                ["details", "google_tag_manager_id"]
              )(pageData)}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
              <!-- End Google Tag Manager (noscript) -->
              `
          }}
        />
      ) : null}

      {isLoading ? (
        <Div height={1} width={1} display="row.center.center">
          <Loading />
        </Div>
      ) : (
        <Body />
      )}
    </>
  );
};

export default R.compose(decorate)(Layout);
