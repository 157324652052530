import React from "react";

import { PackageContainer, EmailPanel } from "Sponsors/Common/View";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import { EMAIL_FIELDS } from "EventLight/Expo/Sales/constants";

const decorate = WithThemeConsumer({ variant: VARIANTS.BACKGROUND });

const Submitters = ({ Th = defaultTh }) => (
  <PackageContainer title="Emails to Submitters">
    <EmailPanel
      title="Submission Confirmation Email"
      fieldId={EMAIL_FIELDS.CONFIRMATION}
      Th={Th}
      accentBorder="neutral5"
    />
    <EmailPanel
      title="Approved Email"
      fieldId={EMAIL_FIELDS.APPROVED}
      Th={Th}
      mt={3}
      accentBorder="success6"
    />
    <EmailPanel
      title="Denied Email"
      fieldId={EMAIL_FIELDS.DENIED}
      Th={Th}
      mt={3}
      accentBorder="danger7"
    />
    <EmailPanel
      title="Payment Processed Email (Receipt)"
      fieldId={EMAIL_FIELDS.PAYMENT}
      Th={Th}
      mt={3}
      accentBorder="neutral5"
    />
  </PackageContainer>
);

export default decorate(Submitters);
