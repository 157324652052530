import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  content: "",
  title: "",
  subtitle: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (
      _,
      {
        payload: {
          details: { title, subtitle, content }
        }
      }
    ) => ({
      title,
      subtitle,
      content
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
