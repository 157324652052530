/* eslint-disable react/prop-types */

import React, { Component } from "react";
import View from "./View";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import * as R from "ramda";
import moment from "moment";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";
import AccountsApi from "redux/modules/accounts/list/api";
import ContactsApi from "utils/EventContacts/WebAPIUtils";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ViewOrderModal from "Orders/OrderModal/View";
import ItemDetailsModal from "Orders/ItemDetailsModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import Helpers from "utils/Global/Helpers";
import { padNumber } from "utils/General";
import debounce from "debounce-promise";
import fullnameToFirstLast from "utils/Contacts/fullname-to-first-last";

const formatNewRecordName = (moduleId, record) => {
  if (moduleId === STANDARD_MODULES.accounts.id) {
    return R.path(["values", STANDARD_MODULE_FIELDS.ACCOUNTS.NAME, "value"])(
      record
    );
  }

  return [
    R.path(["values", STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME, "value"])(
      record
    ),
    R.path(["values", STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME, "value"])(
      record
    )
  ]
    .filter(v => v && v.length)
    .join(" ");
};

const getDayColor = (dateGroups, day) => {
  const group = dateGroups.filter(g => g.days.includes(day));
  return group.length ? group[0].color : "#eee";
};

const formatLookupRecord = record => ({
  id: record.id,
  name: record.name,
  type: record.type ? record.type.name : null,
  value: record.id,
  label: record.name || ""
});

const steps = ["Items", "Order Details", "Confirmation"];

const constructState = props => {
  const selectedVariantPrices = {};

  // populate default prices
  props.variants.forEach(variant => {
    const priceId = variant.prices.length
      ? R.compose(
          price => (price ? price.id : null),
          R.find(R.propEq("is_default", true)),
          R.filter(R.prop("is_enabled"))
        )(variant.prices)
      : null;

    if (priceId) {
      selectedVariantPrices[variant.id] = priceId;
    }
  });

  return {
    saving: false,
    loading: true,
    searchTerm: "",
    isAllocation: props.isAllocation ? true : false,
    activeStepIndex: 0,
    activeItemTypeId:
      props.itemTypeId && props.canUserAddItemType(props.itemTypeId)
        ? props.itemTypeId
        : R.compose(
            R.head,
            R.filter(typeId => props.canUserAddItemType(typeId))
          )([CREDENTIAL_TYPE_ID, MEAL_TYPE_ID]),
    orderType: props.orderType || "individual",
    customerAccount: props.customerAccountId
      ? formatLookupRecord({
          id: props.customerAccountId,
          name: props.customerAccountName
        })
      : null,
    customerContact: props.customerContactId
      ? formatLookupRecord({
          id: props.customerContactId,
          name: props.customerContactName
        })
      : null,
    customerContactEmail: {
      type: "text",
      value: props.customerContactEmail
    },
    fulfillmentMethod: "pickup",
    selectedVariants: {},
    selectedVariantPrices,
    selectedVariantQuestions: {},
    selectedVariantsZones: {},
    fieldValues: {},
    orderId: null,
    orderNumber: null,
    pickupPersonName: { type: "text", value: null },
    shippingAddressLine1: { type: "text", value: null },
    shippingAddressLine2: { type: "text", value: null },
    shippingCity: { type: "text", value: null },
    shippingState: { type: "text", value: null },
    shippingZip: { type: "text", value: null },
    shippingCountry: { type: "text", value: null },
    requireAssignment: false
  };
};

class Controller extends Component {
  constructor(props) {
    super(props);

    const wait = 500; // milliseconds
    const onSearchContacts = inputValue => this.onSearchContacts(inputValue);
    this.debouncedOnSearchContacts = debounce(onSearchContacts, wait, {
      leading: false
    });

    const onSearchAccounts = inputValue => this.onSearchAccounts(inputValue);
    this.debouncedOnSearchAccounts = debounce(onSearchAccounts, wait, {
      leading: false
    });

    this.state = constructState(props);
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getItemTypesByEvent(this.props.params.eventId),
      this.props.getFields({
        moduleId: STANDARD_MODULES.orders.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      })
    ]);

    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.variants.length !== this.props.variants.length) {
      this.setState(constructState(this.props));
    }
  }

  get countOfSelectedVariants() {
    return Object.keys(this.state.selectedVariants).reduce(
      (a, id) => a + this.state.selectedVariants[id],
      0
    );
  }

  showAddRecordModal = (
    moduleId,
    values,
    stateValue,
    statePropertyToUpdate
  ) => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          onSave={createdRecord => {
            if (createdRecord) {
              this.setState({
                [statePropertyToUpdate]: formatLookupRecord({
                  id: createdRecord.id,
                  name: formatNewRecordName(moduleId, createdRecord),
                  type: createdRecord.type.name
                }),
                customerContactEmail:
                  moduleId === STANDARD_MODULES.contacts.id &&
                  createdRecord.values[STANDARD_MODULE_FIELDS.CONTACTS.EMAIL]
                    ? createdRecord.values[
                        STANDARD_MODULE_FIELDS.CONTACTS.EMAIL
                      ]
                    : this.state.customerContactEmail
              });
            }
          }}
          values={values}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showOrderModal = () => {
    this.props.hideModal();
    this.props.showModal({
      content: <ViewOrderModal orderId={this.state.orderId} />,
      wrapper: ModalWrapper
    });
  };

  handleSave = async () => {
    if (!this.state.saving) {
      this.setState({
        saving: true
      });
      const lineItems = R.compose(
        R.flatten,
        R.map(variantId => {
          let price = 0;
          const selectedPriceId = this.state.selectedVariantPrices[variantId];
          const quantity = this.state.selectedVariants[variantId];
          const questions = this.state.selectedVariantQuestions[variantId];
          const zoneOverrides = R.prop(
            variantId,
            this.state.selectedVariantsZones
          );

          if (selectedPriceId) {
            price = R.compose(
              R.propOr(0, "price"),
              R.find(
                R.propEq("id", this.state.selectedVariantPrices[variantId])
              ),
              R.prop("prices"),
              R.find(R.propEq("id", variantId))
            )(this.props.variants);
          }
          return R.times(
            () => ({
              variantId,
              quantity: 1,
              currency: null,
              price,
              questions,
              zoneOverrides: R.isNil(zoneOverrides)
                ? null
                : R.map(R.prop("id"), zoneOverrides)
            }),
            quantity
          );
        }),
        R.keys
      )(this.state.selectedVariants);

      const pickupPersonName = R.pathOr("", ["pickupPersonName", "value"])(
        this.state
      );
      const pickupPersonFname = pickupPersonName
        .split(" ")
        .slice(0, -1)
        .join(" ");
      const pickupPersonLname = pickupPersonName
        .split(" ")
        .slice(-1)
        .join(" ");

      const payload = {
        eventId: this.props.eventDetails.id,
        order: {
          isAllocation: this.state.isAllocation,
          isDraft: false,
          orderType: this.state.orderType,
          customerAccountId: R.path(["customerAccount", "id"])(this.state),
          customerContactId: R.path(["customerContact", "id"])(this.state),
          customerContactEmail:
            this.state.customerContactEmail &&
            this.state.customerContactEmail.value &&
            this.state.customerContactEmail.value.length
              ? {
                  type: "text",
                  value: this.state.customerContactEmail.value.trim()
                }
              : null,
          fulfillmentMethod: this.state.fulfillmentMethod,
          relatedRecordId: this.props.relatedRecordId,
          relatedRecord: {
            values: this.state.fieldValues
          },
          lineItems,
          pickupPersonFname,
          pickupPersonLname,
          shippingAddressLine1: this.state.shippingAddressLine1.value,
          shippingAddressLine2: this.state.shippingAddressLine2.value,
          shippingCity: this.state.shippingCity.value,
          shippingState: this.state.shippingState.value,
          shippingZip: this.state.shippingZip.value,
          shippingCountry: this.state.shippingCountry.value,
          options: {
            requireAssignment: this.state.requireAssignment
          }
        }
      };

      const order = await this.props.addOrder(payload);

      if (this.props.onDone) this.props.onDone(order);

      this.setState({
        saving: false,
        orderId: order.id,
        orderNumber: order.number,
        activeStepIndex: this.state.activeStepIndex + 1
      });
    }
  };

  onItemTypeSearchChange = searchTerm =>
    this.setState({
      searchTerm
    });

  onChangeItemType = activeItemTypeId => {
    this.setState({
      activeItemTypeId,
      searchTerm: ""
    });
  };

  onSelectCustomer = ({
    accountId,
    accountName,
    contactId,
    contactName,
    contactEmail
  }) => {
    this.setState({
      activeStepIndex: 1,
      orderType: !contactId ? "group" : "individual",
      customerAccount: accountId
        ? formatLookupRecord({
            id: accountId,
            name: accountName
          })
        : null,
      customerContact: contactId
        ? formatLookupRecord({
            id: contactId,
            name: contactName
          })
        : null,
      customerContactEmail: {
        type: "text",
        value: contactEmail
      }
    });
  };

  goToNextStep = () => {
    const { activeStepIndex } = this.state;
    if (steps[activeStepIndex + 1]) {
      this.setState({
        activeStepIndex: activeStepIndex + 1,
        searchTerm: ""
      });
    }
  };

  goToPreviousStep = () => {
    const { activeStepIndex } = this.state;
    if (steps[activeStepIndex - 1]) {
      this.setState({
        activeStepIndex: activeStepIndex - 1,
        searchTerm: ""
      });
    }
  };

  onChangeOrderType = orderType =>
    this.setState({ orderType, requireAssignment: false });

  onChangeOrderField = (fieldId, value) =>
    this.setState(state => {
      state.fieldValues[fieldId] = value;
      return state;
    });

  onChangeShippingField = (fieldId, value) =>
    this.setState(state => {
      state[fieldId] = value;
      return state;
    });

  onChangeFulfillmentMethod = fulfillmentMethod =>
    this.setState({ fulfillmentMethod });

  onChangeCustomerAccount = customerAccount =>
    this.setState({ customerAccount });

  onChangeCustomerContact = customerContact =>
    this.setState({
      customerContact,
      customerContactEmail: {
        type: "text",
        value: customerContact.email
      }
    });

  onChangeCustomerContactEmail = value =>
    this.setState({ customerContactEmail: value });

  onCreateCustomerAccount = customerAccount =>
    this.showAddRecordModal(
      STANDARD_MODULES.accounts.id,
      {
        [STANDARD_MODULE_FIELDS.ACCOUNTS.NAME]: {
          type: "text",
          value: customerAccount
        }
      },
      customerAccount,
      "customerAccount"
    );

  onCreateCustomerContact = customerContact => {
    const { fname, lname } = fullnameToFirstLast(customerContact);

    return this.showAddRecordModal(
      STANDARD_MODULES.contacts.id,
      {
        [STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME]: {
          type: "text",
          value: fname || ""
        },
        [STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME]: {
          type: "text",
          value: lname || ""
        }
      },
      customerContact,
      "customerContact"
    );
  };

  onSearchAccounts = async inputValue => {
    const result = await AccountsApi.search(
      null,
      this.props.eventDetails.id,
      inputValue,
      this.props.userCredentials
    );

    return R.compose(
      R.sortBy(R.prop("label")),
      R.map(formatLookupRecord)
    )(result.accounts);
  };

  onSearchContacts = async inputValue => {
    const contacts = await ContactsApi.searchContacts(
      this.props.userCredentials,
      null,
      this.props.eventDetails.id,
      inputValue
    );

    return R.compose(
      R.sortBy(R.prop("label")),
      R.map(record => ({
        id: record.id,
        email: record.values[STANDARD_MODULE_FIELDS.CONTACTS.EMAIL],
        label: [
          record.values[STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME],
          record.values[STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME]
        ]
          .filter(v => v && v.length)
          .join(" "),
        value: record.id
      }))
    )(contacts);
  };

  toggleIsAllocation = () =>
    this.setState({
      isAllocation: !this.state.isAllocation,
      orderType: !this.state.isAllocation ? "group" : this.state.orderType
    });

  bulkUpdateVariantQuantity = (ids, quantity) =>
    this.setState(state => {
      for (const id of ids) {
        if (!quantity || quantity <= 0) {
          delete state.selectedVariants[id];
        } else {
          state.selectedVariants = {
            ...state.selectedVariants,
            [id]: quantity <= 0 ? 0 : quantity
          };
        }
      }

      return state;
    });

  updateVariantQuantity = (id, quantity) =>
    this.setState(state => {
      if (!quantity || quantity <= 0) {
        delete state.selectedVariants[id];
        return state;
      }

      state.selectedVariants = {
        ...state.selectedVariants,
        [id]: quantity <= 0 ? 0 : quantity
      };
      return state;
    });

  updateVariantPriceId = (id, priceId) =>
    this.setState(state => {
      state.selectedVariantPrices = {
        ...state.selectedVariantPrices,
        [id]: priceId
      };
      return state;
    });

  updateRequireAssignment = requireAssignment =>
    this.setState({
      requireAssignment
    });

  showItemDetailsModal = ({ itemId, variantId }) => {
    this.props.showModal({
      content: (
        <ItemDetailsModal
          itemId={itemId}
          variantId={variantId}
          onSave={this.handleSelectedItemDetails}
          showInternalQuestions={true}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleSelectedItemDetails = variantsWithDetails => {
    this.setState(state => {
      Object.keys(variantsWithDetails).forEach(variantId => {
        state.selectedVariants = {
          ...state.selectedVariants,
          [variantId]: variantsWithDetails[variantId].quantity
        };
        state.selectedVariantPrices = {
          ...state.selectedVariantPrices,
          [variantId]: variantsWithDetails[variantId].priceId
        };
        state.selectedVariantQuestions = {
          ...state.selectedVariantQuestions,
          [variantId]: variantsWithDetails[variantId].questions
        };
      });
      return state;
    });
  };

  removeItem = variantId => {
    this.setState(state => ({
      selectedVariants: R.dissoc(variantId, state.selectedVariants)
    }));
  };

  updateVariantZones = ({ itemId: variantId, selectedZones }) => {
    this.setState(state => ({
      selectedVariantsZones: {
        ...state.selectedVariantsZones,
        [variantId]: selectedZones
      }
    }));
  };

  render() {
    const {
      loading,
      saving,
      searchTerm,
      isAllocation,
      activeStepIndex,
      activeItemTypeId,
      orderType,
      customerAccount,
      customerContact,
      customerContactEmail,
      fulfillmentMethod,
      selectedVariants,
      selectedVariantPrices,
      selectedVariantsZones,
      fieldValues,
      orderNumber,
      pickupPersonName,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingCity,
      shippingState,
      shippingZip,
      shippingCountry,
      requireAssignment
    } = this.state;
    const {
      canUserAddItemType,
      hideModal,
      eventDetails,
      itemGroups,
      variants,
      orderCustomFields,
      types
    } = this.props;

    const hasItemGroups = Boolean(itemGroups.length);
    const hasSearchTerm = Boolean(searchTerm.length);
    const lowercaseSearchTerm = hasSearchTerm
      ? searchTerm.toLowerCase().trim()
      : "";

    let itemTypes = R.compose(
      R.map(type => ({
        id: type.id,
        name: type.name,
        active: activeItemTypeId === type.id,
        onClick: () => this.onChangeItemType(type.id)
      })),
      R.filter(type => canUserAddItemType(type.id))
    )(types);

    const getItemsForTypeId = typeId =>
      hasItemGroups
        ? R.compose(
            R.filter(g => g.items.length),
            groups =>
              hasSearchTerm
                ? R.map(group => ({
                    ...group,
                    items: group.items.filter(item =>
                      item.name.toLowerCase().includes(lowercaseSearchTerm)
                    )
                  }))(groups)
                : groups,
            R.map(group => ({
              ...group,
              items: R.reduce((list, item) => {
                const isSingleVariant = item.variants.length === 1;
                const variant = item.variants[0];
                const variantId = variant.id;
                list.push({
                  id: variantId,
                  name: isSingleVariant ? variant.display_name : item.name,
                  color: item.background_color || "#ccc",
                  qty:
                    selectedVariants[variantId] >= 0
                      ? selectedVariants[variantId]
                      : 0,
                  description: item.description,
                  providerId: variant.provider_id,
                  prices: variant.prices,
                  selectedPriceId: selectedVariantPrices[variantId],
                  zones: R.prop("zones", variant),
                  showItemDetailsModal:
                    !isSingleVariant ||
                    (variant.questions && variant.questions.length)
                      ? () => {
                          this.showItemDetailsModal(
                            isSingleVariant
                              ? {
                                  variantId: variant.id
                                }
                              : {
                                  itemId: item.id
                                }
                          );
                        }
                      : undefined,
                  updateVariantPriceId: priceId =>
                    this.updateVariantPriceId(variantId, priceId),
                  updateVariantQuantity: qty =>
                    this.updateVariantQuantity(
                      variantId,
                      parseInt(qty, 10) || 0
                    )
                });
                return list;
              }, [])(group.items)
            })),
            R.filter(g => g.type_id === typeId)
          )(itemGroups)
        : [];

    const itemTypeProps = R.reduce((map, type) => {
      if (type.id !== MEAL_TYPE_ID) {
        map[type.id] = {
          mode: "quantity",
          itemGroups: getItemsForTypeId(type.id)
        };
      }
      return map;
    }, {})(types);

    const dayGroups = R.map(group => ({
      id: group.id,
      name: group.name,
      color: group.color
    }))(eventDetails.date_groups);

    const selectedCateringDays = R.pathOr(
      [],
      ["module_settings", "catering", "selected_days"]
    )(eventDetails);

    const mealDays = R.pathOr(
      [],
      ["module_settings", "catering", "meal_days"]
    )(eventDetails);

    const mealsWithHandlers = hasItemGroups
      ? R.compose(
          R.filter(meal => meal.validVariantIds.length),
          meals =>
            hasSearchTerm
              ? meals.filter(meal =>
                  meal.name.toLowerCase().includes(lowercaseSearchTerm)
                )
              : meals,
          R.map(({ id, name, variants }) => {
            const validVariantIds = R.compose(
              R.map(R.prop("id")),
              R.filter(v =>
                v.rules.find(
                  r =>
                    r.pattern === "is_valid_for" &&
                    selectedCateringDays.includes(r.value) &&
                    mealDays.filter(d => d.mealId === id && d.dayId === r.value)
                      .length
                )
              )
            )(variants);

            return {
              id,
              name,
              variants,
              validVariantIds,
              selectAllItems: () =>
                this.bulkUpdateVariantQuantity(validVariantIds, 1),
              deselectAllItems: () =>
                this.bulkUpdateVariantQuantity(validVariantIds, 0)
            };
          }),
          R.propOr([], "items"),
          R.head,
          R.filter(g => g.type_id === MEAL_TYPE_ID)
        )(itemGroups)
      : [];

    const mealsByDay = R.groupBy(R.prop("dayId"))(mealDays);

    const getMealItemVariantByMealDay = (itemId, day) => {
      if (!hasItemGroups) return null;

      const mealVariants = R.compose(
        R.prop("variants"),
        R.find(R.propEq("id", itemId))
      )(mealsWithHandlers);

      if (!mealVariants || !mealVariants.length) return null;

      return R.find(v =>
        Boolean(
          v.rules.find(r => r.pattern === "is_valid_for" && r.value === day)
        )
      )(mealVariants);
    };

    const mealDaysWithHandlers = R.compose(
      R.sortBy(R.prop("id")),
      R.map(day => {
        const dayInstance = moment(day);
        return {
          id: day,
          dayName: dayInstance.format("ddd"),
          dayNumber: dayInstance.format("D"),
          color: getDayColor(eventDetails.date_groups, day),
          meals: R.compose(
            R.reduce((mealMap, mealId) => {
              const variant = getMealItemVariantByMealDay(mealId, day);
              if (variant) {
                mealMap[mealId] = {
                  show: true,
                  qty:
                    selectedVariants[variant.id] >= 0
                      ? selectedVariants[variant.id]
                      : 0,
                  updateVariantQuantity: qty =>
                    this.updateVariantQuantity(
                      variant.id,
                      parseInt(qty, 10) || 0
                    )
                };
              }
              return mealMap;
            }, {}),
            R.map(R.prop("mealId")),
            R.propOr([], day)
          )(mealsByDay)
        };
      })
    )(selectedCateringDays);

    const selectedVariantsSummary =
      activeStepIndex >= 1
        ? R.compose(
            R.sortBy(R.prop("order")),
            R.map(variantId => {
              const variant = R.find(R.propEq("id", variantId))(variants);
              const selectedPrice = selectedVariantPrices[variantId]
                ? R.compose(
                    R.find(R.propEq("id", selectedVariantPrices[variantId])),
                    R.prop("prices")
                  )(variant)
                : null;
              const subname = formatVariantTitle(variant);
              const description = [subname, variant.item.description]
                .filter(v => v && v.length)
                .join(" · ");
              const selectedZones = R.propOr(
                null,
                variantId,
                selectedVariantsZones
              );
              return {
                id: variantId,
                typeId: R.pathOr("", ["item", "type_id"], variant),
                questions: R.propOr([], "questions", variant).reduce(
                  (acc, question, idx) => {
                    const questionText = R.pathOr(
                      "",
                      ["question", "name"],
                      question
                    );
                    if (idx === 0) {
                      return `${acc}${questionText}`;
                    }
                    return `${acc}, ${questionText}`;
                  },
                  ""
                ),
                order:
                  variant.type_id === CREDENTIAL_TYPE_ID
                    ? `0_${padNumber(variant.group_order)}_${padNumber(
                        variant.item.order
                      )}_${padNumber(variant.order)}`
                    : `1_${R.compose(
                        R.prop("value"),
                        R.find(R.propEq("pattern", "is_valid_for"))
                      )(variant.rules)}_${variant.item.order}`,
                name: variant.display_name,
                subname,
                description,
                color: variant.item.background_color,
                qty: selectedVariants[variantId],
                totalPrice: selectedPrice
                  ? selectedPrice.price * selectedVariants[variantId]
                  : null,
                eachPrice: selectedPrice ? selectedPrice.price : null,
                enabledZones: variant.type_id === CREDENTIAL_TYPE_ID,
                zones: R.isNil(selectedZones)
                  ? R.propOr([], "zones", variant)
                  : selectedZones
              };
            }),
            R.keys
          )(selectedVariants)
        : [];

    const total = R.compose(
      R.sum,
      R.map(R.prop("totalPrice"))
    )(selectedVariantsSummary);

    itemTypeProps[MEAL_TYPE_ID] = {
      mode: "quantity",
      dayGroups,
      meals: mealsWithHandlers,
      days: mealDaysWithHandlers
    };

    const countOfSelectedItems = this.countOfSelectedVariants;

    itemTypes = itemTypes.filter(t => {
      if (t.id === MEAL_TYPE_ID) {
        return itemTypeProps[t.id].meals.length;
      }
      return R.compose(
        R.length,
        R.flatten,
        R.map(R.prop("items"))
      )(itemTypeProps[t.id].itemGroups);
    });

    const fulfillmentMethods = [
      {
        id: "pickup",
        name: "Pickup/Will Call",
        selected: fulfillmentMethod === "pickup",
        onSelect: () => this.onChangeFulfillmentMethod("pickup")
      },
      {
        id: "mail",
        name: "Mail/Ship",
        selected: fulfillmentMethod === "mail",
        onSelect: () => this.onChangeFulfillmentMethod("mail")
      }
    ];

    const orderFields =
      activeStepIndex === 2
        ? R.map(field => ({
            id: field.id,
            name: field.name,
            Editor: resolveEditor(field),
            editorProps: resolveEditorProps(field, eventDetails),
            onChange: value => this.onChangeOrderField(field.id, value),
            value: fieldValues[field.id]
          }))(orderCustomFields)
        : [];

    let isValidStep = true;
    switch (activeStepIndex) {
      case 1:
        isValidStep = Boolean(countOfSelectedItems);
        break;
      case 2:
        const email = customerContactEmail.value
          ? customerContactEmail.value.trim()
          : customerContactEmail.value;
        isValidStep =
          orderType === "individual"
            ? Boolean(customerContact) &&
              customerContactEmail &&
              Helpers.isValidEmail(email) &&
              Boolean(countOfSelectedItems)
            : Boolean(customerAccount) &&
              Boolean(customerContact) &&
              customerContactEmail &&
              Helpers.isValidEmail(email) &&
              Boolean(countOfSelectedItems);
        break;
      default:
        break;
    }

    const pickupFields = [
      {
        id: "pickupPersonName",
        name: "Pickup Person",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {
          maxLength: 75
        },
        onChange: value =>
          this.onChangeShippingField("pickupPersonName", value),
        value: pickupPersonName
      }
    ];

    const shippingFields = [
      {
        id: "shippingAddressLine1",
        name: "Address Line 1",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {
          maxLength: 75
        },
        onChange: value =>
          this.onChangeShippingField("shippingAddressLine1", value),
        value: shippingAddressLine1
      },
      {
        id: "shippingAddressLine2",
        name: "Address Line 2",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {
          maxLength: 45
        },
        onChange: value =>
          this.onChangeShippingField("shippingAddressLine2", value),
        value: shippingAddressLine2
      },
      {
        id: "shippingCity",
        name: "City",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingCity", value),
        value: shippingCity
      },
      {
        id: "shippingState",
        name: "State",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingState", value),
        value: shippingState
      },
      {
        id: "shippingZip",
        name: "Zip Code",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingZip", value),
        value: shippingZip
      },
      {
        id: "shippingCountry",
        name: "Country",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingCountry", value),
        value: shippingCountry
      }
    ];

    return (
      <View
        {...{
          onSave: this.handleSave,
          onChangeItemType: this.onChangeItemType,
          onItemTypeSearchChange: this.onItemTypeSearchChange,
          onNextStep: this.goToNextStep,
          onSelectCustomer: this.onSelectCustomer,
          onPreviousStep: this.goToPreviousStep,
          onManageOrder: this.showOrderModal,
          onChangeOrderType: this.onChangeOrderType,
          onChangeCustomerAccount: this.onChangeCustomerAccount,
          onChangeCustomerContact: this.onChangeCustomerContact,
          onChangeCustomerContactEmail: this.onChangeCustomerContactEmail,
          onCreateCustomerAccount: this.onCreateCustomerAccount,
          onCreateCustomerContact: this.onCreateCustomerContact,
          onSearchAccounts: this.debouncedOnSearchAccounts,
          onSearchContacts: this.debouncedOnSearchContacts,
          toggleIsAllocation: this.toggleIsAllocation,
          removeItem: this.removeItem,
          updateVariantZones: this.updateVariantZones,
          toggleRequireAssignment: () =>
            this.updateRequireAssignment(!requireAssignment),
          //
          loading,
          saving,
          hideModal,
          isAllocation,
          steps,
          activeStepIndex,
          activeStep: steps[activeStepIndex],
          itemTypes,
          activeItemTypeId,
          itemTypeProps,
          isValidStep,
          countOfSelectedItems,
          orderNumber,
          selectedVariants: selectedVariantsSummary,
          orderType,
          customerAccount,
          customerContact,
          customerContactEmail,
          fulfillmentMethod,
          fulfillmentMethods,
          requireAssignment,
          orderFields,
          pickupFields,
          shippingFields,
          subtotal: total,
          total
        }}
      />
    );
  }
}

export default Controller;
