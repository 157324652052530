import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Event from "./Event";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import * as EventActions from "redux/modules/event/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: user(state),
    isFetchingEventDetails: EventSelectors.isFetching(state),
    eventDetails: EventSelectors.eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarActions,
      ...ModalActions,
      ...EventActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);
