import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";

const defaultState = { orders: {}, people: {}, line_items: {} };

const stats = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  stats,
  fetching
});
