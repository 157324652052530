import PropTypes from "prop-types";
import React, { Component } from "react";
import getValue from "utils/value-types/get-value/boolean";
import autobind from "autobind-decorator";
import CheckBox from "material-ui/Checkbox";

export default class CheckboxEditor extends Component {
  formatValue(value) {
    return getValue(value);
  }

  @autobind
  onClick() {
    this.props.onChange({
      type: "boolean",
      value: !this.formatValue(this.props.value)
    });
  }

  render() {
    const { value, disabled } = this.props;
    const checked = Boolean(this.formatValue(value));
    return (
      <CheckBox checked={checked} disabled={disabled} onCheck={this.onClick} />
    );
  }
}

CheckboxEditor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
