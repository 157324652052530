import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { eventDetails } from "redux/modules/event/selectors";
import { TABLE_ACTIONS } from "EventLight/GuestLists/GuestLists/constants";
import ActionsPopover from "ui-kit/ActionsPopover/View";
import { getSelectedGroupStatus } from "../../selectors";

const decorate = connect(state => ({
  eventDetails: eventDetails(state),
  isGuestListEnabledForGroupType: getSelectedGroupStatus(state)
}));

const settings = {
  true: {
    label: "Enabled",
    backgroundColor: "#70F57A",
    textColor: "#000000"
  },
  false: {
    label: "Not Enabled",
    backgroundColor: "#F7B9A2",
    textColor: "#000000"
  }
};

const StatusDropdown = props => {
  const data = R.prop("data", props);
  const currentValue = R.prop("guestListEnabled", data);
  const isGuestListEnabledForGroupType = R.prop(
    "isGuestListEnabledForGroupType",
    props
  );
  const containerStyle = {
    userSelect: "none",
    overflow: "hidden",
    display: "flex",
    alignItems: "left",
    justifyContent: "flex-start"
  };
  const popoverStyle = {
    marginRight: 4,
    borderRadius: 25,
    fontSize: 12,
    padding: "4px 8px",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    flexBasis: "auto",
    flexShrink: 0,
    backgroundColor: R.pathOr(
      "#EBEBEB",
      [currentValue, "backgroundColor"],
      settings
    ),
    color: R.pathOr("#494949", [currentValue, "textColor"], settings)
  };
  return (
    <div
      style={
        isGuestListEnabledForGroupType
          ? containerStyle
          : { ...containerStyle, cursor: "not-allowed" }
      }
      title={
        !isGuestListEnabledForGroupType &&
        "Guest list settings must be enabled for this group type"
      }
    >
      <ActionsPopover
        actions={[
          { id: R.prop("ENABLE_GROUP", TABLE_ACTIONS), name: "Enabled" },
          { id: R.prop("DISABLE_GROUP", TABLE_ACTIONS), name: "Not Enabled" }
        ]}
        data={data}
      >
        <div
          title={"Change Guest List status"}
          style={
            isGuestListEnabledForGroupType
              ? popoverStyle
              : { ...popoverStyle, pointerEvents: "none" }
          }
        >
          {R.pathOr("-", [currentValue, "label"], settings)}
        </div>
      </ActionsPopover>
    </div>
  );
};

export default decorate(StatusDropdown);
