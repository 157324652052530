import React, { useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import {
  DateTimeInput,
  Dropdown,
  Input,
  Toggle,
  WithFormInstanceProvider
} from "ui-kit/Form/View";
import {
  Div,
  SuperFilledButton,
  SuperOutlineButton,
  Text1,
  Text2,
  Text3
} from "components/Base";

import { getters, actions } from "Organizations/CloneEventModal/model";
import {
  getCurrencies,
  getTimezones,
  getNewEventFields
} from "Organizations/CloneEventModal/selectors";
import {
  DEFAULT_CURRENCY_ID,
  DEFAULT_TIMEZONE_ID,
  NEW_EVENT_DATE_FORMAT,
  FIELD_IDS,
  PAGES,
  NAMESPACE
} from "Organizations/CloneEventModal/constants";

import css from "./styles.scss";
import { MarkEventDays } from "ui-kit/Form/View/MarkEventDays";
import { FIELD_TYPES as FORM_FIELD_TYPES } from "ui-kit/Form/constants";

import { noop } from "utils/General";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(NAMESPACE)),
  connect(
    state => ({
      fields: getNewEventFields(state),
      timezones: getTimezones(state),
      currencies: getCurrencies(state),
      isCreatingNewEvent: getters.isCreatingNewEvent(state),
      isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state)
    }),
    {
      goToSelectEvent: () => actions.setSelectedPage(PAGES.NEW_EVENT),
      createEvent: actions.createEvent
    }
  ),
  CSSModules(css)
);

const CreateNewEvent = ({
  fields = {},
  timezones = [],
  currencies = [],
  goToSelectEvent = noop,
  createEvent = noop,
  isCreatingNewEvent = false,
  isLenndAdmin = false
}) => {
  const [wasCTATriggered, setWasCTATriggered] = useState(false);
  const [isEventNameValid, setIsEventNameValid] = useState(false);
  const [isEventStartValid, setIsEventStartValid] = useState(false);
  const [isEventEndValid, setIsEventEndValid] = useState(false);
  const handleCreateEvent = () => {
    setWasCTATriggered(true);

    if (fields[FIELD_IDS.EVENT_NAME]) {
      setIsEventNameValid(true);
    } else {
      setIsEventNameValid(false);
    }

    if (fields[FIELD_IDS.DATE_FROM]) {
      setIsEventStartValid(true);
    } else {
      setIsEventStartValid(false);
    }

    if (fields[FIELD_IDS.DATE_TO]) {
      setIsEventEndValid(true);
    } else {
      setIsEventEndValid(false);
    }

    if (isEventNameValid && isEventStartValid && isEventEndValid) {
      createEvent();
    }
  };

  return (
    <>
      <Div display="column.flex-start.flex-start" px={17.5} py={6}>
        <Div display="row" flex={1} width={1} mb={9}>
          <Div style={{ width: "160px" }}>
            <Text3 color="gray6" bold>
              Event Name
            </Text3>
          </Div>
          <Div flex={1} width="100%">
            <Input
              className={
                !isEventNameValid && wasCTATriggered ? css.inputError : ""
              }
              fieldId={FIELD_IDS.EVENT_NAME}
              placeholder="Enter event name"
              width={1}
              size="big"
              inputType="outline"
              value={fields[FIELD_IDS.EVENT_NAME]}
            />
            {!isEventNameValid && wasCTATriggered && (
              <Text1 className={css.errorLabel} mt={1.5} pl={3}>
                Please enter event name
              </Text1>
            )}
          </Div>
        </Div>

        <Div display="row.flex-start.center" width={1} mb={9}>
          <Div display="row" flex={1} width={1}>
            <Div style={{ width: "160px" }}>
              <Text3 color="gray6" bold>
                Starts At
              </Text3>
            </Div>
            <Div flex={1} width="100%">
              <DateTimeInput
                inputClassName={
                  !isEventStartValid && wasCTATriggered
                    ? css.dateTimeInputError
                    : css.dateTimeInput
                }
                inputType="outline"
                size="big"
                fieldId={FIELD_IDS.DATE_FROM}
                altFormat={NEW_EVENT_DATE_FORMAT}
                placeholder="Select date"
                value={fields[FIELD_IDS.DATE_FROM]}
              />
              {!isEventStartValid && wasCTATriggered && (
                <Text1 className={css.errorLabel} mt={1.5} pl={3}>
                  Please enter start date
                </Text1>
              )}
            </Div>
          </Div>
          <Div display="row" flex={1} width={1} pl={14}>
            <Div style={{ width: "110px" }}>
              <Text3 color="gray6" bold>
                Ends At
              </Text3>
            </Div>
            <Div flex={1} width="100%">
              <DateTimeInput
                inputClassName={
                  !isEventEndValid && wasCTATriggered
                    ? css.dateTimeInputError
                    : css.dateTimeInput
                }
                inputType="outline"
                size="big"
                fieldId={FIELD_IDS.DATE_TO}
                altFormat={NEW_EVENT_DATE_FORMAT}
                placeholder="Select date"
                value={fields[FIELD_IDS.DATE_TO]}
              />
              {!isEventEndValid && wasCTATriggered && (
                <Text1 className={css.errorLabel} mt={1.5} pl={3}>
                  Please enter end date
                </Text1>
              )}
            </Div>
          </Div>
        </Div>

        <Div display="row.flex-start.flex-start" width={1} mb={9}>
          <Div display="row" width={1}>
            <Div style={{ width: "160px" }}>
              <Text3 color="gray6" bold>
                Manage Dates
              </Text3>
            </Div>
            <Div display="row.flex-start.flex-start" flex={1} width="100%">
              <Div display="column.flex-start.flex-start" width={1}>
                <Text2 color="gray6">
                  Select event dates to mark their purpose.
                </Text2>
                <Text2 color="gray6" bold>
                  All days must be marked.
                </Text2>
                <MarkEventDays
                  fieldId={FIELD_IDS.SELECTED_DATES}
                  value={fields[FIELD_IDS.SELECTED_DATES]}
                  fieldType={FORM_FIELD_TYPES.MARK_EVENT_DAYS}
                  startDate={fields[FIELD_IDS.DATE_FROM]}
                  endDate={fields[FIELD_IDS.DATE_TO]}
                />
              </Div>
            </Div>
          </Div>
        </Div>

        <Div display="row.flex-start.center" width={1} mb={9}>
          <Div display="row" width={1}>
            <Div style={{ width: "160px" }}>
              <Text3 color="gray6" bold>
                Timezone
              </Text3>
            </Div>
            <Div flex={1} width="100%">
              <Dropdown
                fieldId={FIELD_IDS.TIMEZONE}
                options={timezones}
                iniValue={DEFAULT_TIMEZONE_ID}
                size="big"
                value={fields[FIELD_IDS.TIMEZONE]}
              />
            </Div>
          </Div>
          <Div display="row" width={1} pl={14}>
            <Div style={{ width: "110px" }}>
              <Text3 color="gray6" bold>
                Currency
              </Text3>
            </Div>
            <Div flex={1} width="100%">
              <Dropdown
                fieldId={FIELD_IDS.CURRENCY}
                options={currencies}
                iniValue={DEFAULT_CURRENCY_ID}
                size="big"
                value={fields[FIELD_IDS.CURRENCY]}
              />
            </Div>
          </Div>
        </Div>

        {isLenndAdmin && (
          <Div display="row" flex={1} width={1}>
            <Div style={{ width: "160px" }}>
              <Text3 color="gray6" bold>
                Sandbox Event
              </Text3>
            </Div>
            <Div flex={1} width="50%">
              <Toggle
                size="small"
                fieldId={FIELD_IDS.IS_SANDBOX}
                iniValue={false}
                value={fields[FIELD_IDS.IS_SANDBOX]}
              />
              <Text1 color="gray6" mt={1.5}>
                Please toggle this option if you are creating this event
                specifically for testing or demo purposes
              </Text1>
            </Div>
          </Div>
        )}
      </Div>

      <Div
        height={86}
        bt={1}
        bc="neutral2"
        width={1}
        display="row.space-between.center"
        px={5}
        style={{
          flexShrink: 0
        }}
      >
        <SuperOutlineButton onClick={goToSelectEvent}>Back</SuperOutlineButton>
        <SuperFilledButton
          onClick={handleCreateEvent}
          bg="altB5"
          disabled={isCreatingNewEvent}
        >
          Create Event
        </SuperFilledButton>
      </Div>
    </>
  );
};

export default decorate(CreateNewEvent);
