import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const NAMESPACE = "EventLightRegistration";

export const FORM_ID = "EventLightRegistrationForm";
export const FIELDS_MODAL_INSTANCE_ID = "SelectFieldsModal";

export const REQUIRED_ERROR_MESSAGE =
  "First Name, Last Name and Email fields must be visible and required.";

export const FIELD_IDS = {
  REGISTRATION_TYPE: "registration_type",
  TEMPLATE: "virtual_registration_template",
  DESCRIPTION: "virtual_registration_description",
  SPONSORS_ENABLED: "virtual_registration_enable_sponsors",
  SPONSORS_LABEL: "virtual_registration_sponsors_label",
  SPONSORS_DESCRIPTION: "virtual_registration_sponsors_description",
  SPEAKERS_ENABLED: "virtual_registration_enable_speakers",
  SPEAKERS_LABEL: "virtual_registration_speakers_label",
  SPEAKERS_DESCRIPTION: "virtual_registration_speakers_description",
  SCHEDULE_ENABLED: "virtual_registration_enable_schedule",
  SCHEDULE_LABEL: "virtual_registration_schedule_label",
  SCHEDULE_DESCRIPTION: "virtual_registration_schedule_description",
  REGISTRATION_IMAGE_URL: "virtual_registration_image_url",
  CONTACT_RECORD_TYPE_ID: "virtual_registration_contact_record_type_id",
  ACCOUNT_RECORD_TYPE_ID: "virtual_registration_account_record_type_id",
  REGISTRATION_SOURCE: "virtual_registration_source"
};

export const REGISTRATION_SOURCES = [
  {
    id: "lennd",
    label: "Fondi"
  },
  {
    id: "eventbrite",
    label: "Eventbrite"
  }
];

export const REGISTRATION_TYPES = [
  {
    id: "rsvp",
    label: "Collect RSVPs"
  },
  {
    id: "sell_tickets",
    label: "Sell Tickets"
  }
];

export const DEFAULT_FIELD_ORDER = {
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME]: -4,
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME]: -3,
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]: -2,
  [STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME]: -1
};
