import { createModule } from "redux-mvc";

import model from "./model";
import searchBar from "ui-kit/SearchBar";
import table from "ui-kit/Table";
import viewPicker from "ui-kit/ViewPicker";
import timeTable from "ui-kit/TimeTable";
import approversLabel from "ApproversLabel";

const thisModule = createModule({ ...model, observedNamespaces: ["user"] });
thisModule.plugModule(searchBar);
thisModule.plugModule(table);
thisModule.plugModule(viewPicker);
thisModule.plugModule(approversLabel);
thisModule.plugModule(timeTable);

const { actions, getters } = thisModule;

export { actions, getters };

export default thisModule;
