import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarSubTitle = ({ children, ...props }) => (
  <div styleName="title" {...props}>
    {children}
  </div>
);

export default CSSModules(SidebarSubTitle, css);
