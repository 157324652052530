import React, { cloneElement } from "react";
import { Div, LeftIcon } from "components/Base";
import Circle from "ui-kit/Circle";
import css from "./Sidebar/styles.scss";
import CSSModules from "react-css-modules";
import Tooltip from "components/Global/Tooltip";
import { withRouter } from "react-router";

const View = props => {
  const {
    children,
    toggleSidebar,
    isSidebarOpen,
    hideSidebar,
    router,
    partialPath
  } = props;
  if (hideSidebar === null) {
    return null;
  }
  if (hideSidebar) {
    return (
      <Div
        display="row"
        height={1}
        style={{ position: "absolute", width: "100%" }}
      >
        <Div flex={1} height={1}>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "fixed",
                left: "15px",
                top: "15px",
                zIndex: 2
              }}
            >
              <div
                onClick={() => router.push(partialPath)}
                style={{
                  cursor: "pointer",
                  zIndex: 1
                }}
              >
                <Tooltip tooltip="Back to portal home">
                  <span>
                    <Circle
                      size={40}
                      bg="white"
                      style={{ border: "solid 2px #cecece", color: "#4D4D4D" }}
                      className={css.circle}
                    >
                      <LeftIcon size={30} />
                    </Circle>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <Div>{cloneElement(children, { ...props })}</Div>
        </Div>
      </Div>
    );
  }

  return (
    <Div
      display="row"
      height={1}
      style={isSidebarOpen ? {} : { position: "absolute", width: "100%" }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={
            isSidebarOpen
              ? {
                  position: "fixed",
                  left: "315px",
                  top: "15px",
                  zIndex: 2
                }
              : { position: "fixed", left: "15px", top: "15px", zIndex: 2 }
          }
        >
          <div
            onClick={() => toggleSidebar(!isSidebarOpen)}
            style={{
              cursor: "pointer",
              zIndex: 1
            }}
          >
            <Circle
              size={40}
              bg="white"
              style={{ border: "solid 2px #cecece", color: "#4D4D4D" }}
              className={css.circle}
            >
              <LeftIcon
                size={30}
                style={{
                  transform: `rotate(${isSidebarOpen ? "0" : "180"}deg)`
                }}
              />
            </Circle>
          </div>
        </div>
      </div>
      <Div flex={1} height={1}>
        {cloneElement(children, { ...props })}
      </Div>
    </Div>
  );
};

export default CSSModules(withRouter(View), css);
