import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import { ReportView } from "components/Global/Module/Sidebar/ViewSelector/Popover/Icons";

@CSSModules(css)
class ReportPicker extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleSelect = report => {
    this.props.onSelect({
      type: "report",
      reportId: report.id,
      name: `${report.name} Report`
    });
    this.onRequestClose();
  };

  render() {
    const { children, reports } = this.props;
    return (
      <div>
        <span
          styleName="wrapper"
          role="button"
          tabIndex="0"
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {children}
        </span>
        <Popover
          animated={false}
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="container">
            <div styleName="heading">report views</div>
            {reports.map(r => (
              <div
                key={r.key}
                styleName="listItem"
                onClick={() => this.handleSelect(r)}
                role="button"
                tabIndex="0"
              >
                <ReportView size={19} />
                <span styleName="name">{r.name}</span>
              </div>
            ))}
          </div>
        </Popover>
      </div>
    );
  }
}

ReportPicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default ReportPicker;
