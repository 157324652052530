import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text2,
  Text4,
  Text5,
  CloseIcon,
  SuperClearInput,
  SearchIcon,
  BigFilledButton,
  BigOutlineButton,
  collapsableHandler,
  Collapsable,
  RightIcon,
  DownIcon,
  LoadingIcon
} from "components/Base";
import { getters, actions } from "SalesPortal/TypesModal";
import {
  getTypesFiltered,
  getSelectedItems
} from "SalesPortal/TypesModal/selectors";
import Checkbox from "material-ui/Checkbox";

const TypeCollapsable = collapsableHandler(
  ({ toggleCollapsed, collapsed, name, id, toggleVariant, items }) => (
    <Div key={id} width={1} bg="white" bra={1} bc="neutral2" ba={1} p={2}>
      <Div width={1} display="row.flex-start.center" onClick={toggleCollapsed}>
        {collapsed ? (
          <RightIcon color="black" size={24} />
        ) : (
          <DownIcon color="black" size={24} />
        )}
        <Div width={35}>
          <Checkbox
            checked={R.any(
              item => R.any(({ selected }) => selected === true, item.variants),
              items
            )}
            onClick={() => {}}
          />
        </Div>
        <Text2 color="black" bold>
          {name}
        </Text2>
      </Div>
      <Collapsable collapsed={collapsed}>
        {R.map(
          item => (
            <ItemCollapsable
              {...item}
              toggleVariant={toggleVariant}
              typeId={id}
            />
          ),
          items
        )}
      </Collapsable>
    </Div>
  )
);

const ItemCollapsable = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    id,
    toggleVariant,
    variants,
    typeId
  }) => (
    <Div key={id} width={1} bg="white" pl={8}>
      <Div width={1} display="row.flex-start.center" onClick={toggleCollapsed}>
        {collapsed ? (
          <RightIcon color="black" size={16} />
        ) : (
          <DownIcon color="black" size={16} />
        )}
        <Div width={35} ml={2}>
          <Checkbox
            checked={R.any(({ selected }) => selected === true, variants)}
            onClick={() => {}}
          />
        </Div>
        <Text1 color="black" bold>
          {name}
        </Text1>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div width={1} p={1} pl={5}>
          {R.map(
            variant => (
              <Div
                key={variant.id}
                width={1}
                display="row.flex-start.center"
                pl={8}
              >
                <Div width={35}>
                  <Checkbox
                    checked={variant.selected}
                    onClick={() =>
                      toggleVariant({
                        typeId,
                        variantId: variant.id,
                        itemId: id
                      })
                    }
                  />
                </Div>
                <Text1>{variant.name || "name"}</Text1>
              </Div>
            ),
            variants
          )}
        </Div>
      </Collapsable>
    </Div>
  )
);

const Loading = () => (
  <Div width={1} height={418} display="column.center.center">
    <Text4 bold>Loading</Text4>
    <LoadingIcon />
  </Div>
);

const decorate = connect(
  state => ({
    types: getTypesFiltered(state),
    itemFilter: getters.itemFilter(state),
    selectedItems: getSelectedItems(state),
    loading: getters.loading(state)
  }),
  {
    toggleVariant: actions.toggleVariant,
    setItemFilter: actions.setItemFilter,
    removeSeletedItem: actions.removeSeletedItem
  }
);

const Layout = ({
  handlers: { hideModal, onDone },
  types,
  toggleVariant,
  itemFilter,
  setItemFilter,
  selectedItems,
  removeSeletedItem,
  loading
}) => (
  <Div m={5} style={{ overflow: "none" }} display="row.center.center">
    <Div
      display="column.flex-start"
      bra={1}
      shadow={2}
      bg="white"
      height={621}
      width={820}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      <Div
        display="row.space-between.center"
        width={1}
        bg="primary7"
        height={65}
        px={4}
      >
        <Text5 bold color="white">
          Select items to showcase
        </Text5>
        <CloseIcon size={24} color="white" onClick={hideModal} />
      </Div>
      {loading ? (
        <Loading />
      ) : (
        <Div width={1}>
          <Div
            width={1}
            height={57}
            bg="white"
            bc="neutral2"
            bb={1}
            display="column.center"
            px={3}
          >
            <SuperClearInput
              LeftIcon={SearchIcon}
              value={itemFilter}
              onChange={value => setItemFilter(value)}
              continuous
            />
          </Div>
          <Div widht={1} height={418} display="row">
            <Div
              height={1}
              style={{ overflow: "auto" }}
              width={495}
              bg="neutral1"
              p={2}
            >
              {R.map(
                item => (
                  <TypeCollapsable {...item} toggleVariant={toggleVariant} />
                ),
                types
              )}
            </Div>
            <Div width={325} p={4} height={1} style={{ overflow: "auto" }}>
              <Text4 bold>Selected Items</Text4>
              <Div width={1} mt={2}>
                {R.map(
                  item => (
                    <Div key={item.id} bra={1} bg="altB1" p={2} mb={1}>
                      <Div display="row.space-between.center">
                        <Text2 bold>{`${item.typeName} / ${item.name}`}</Text2>
                        <CloseIcon
                          size={16}
                          onClick={() => {
                            removeSeletedItem({
                              typeId: item.typeId,
                              itemId: item.id
                            });
                          }}
                        />
                      </Div>
                      <Div width={1}>
                        {R.map(
                          variant => (
                            <Div p={1}>
                              <Text2 key={variant.id}>
                                {variant.name || "name"}
                              </Text2>
                            </Div>
                          ),
                          item.variants
                        )}
                      </Div>
                    </Div>
                  ),
                  selectedItems
                )}
              </Div>
            </Div>
          </Div>
        </Div>
      )}
      <Div
        width={1}
        height={81}
        display="row.flex-start.center"
        px={4}
        bc="neutral2"
        bt={1}
      >
        <BigFilledButton
          bg="altB5"
          width={154}
          mr={2}
          onClick={async () => {
            await onDone();
            hideModal({ update: true });
          }}
        >
          Done
        </BigFilledButton>
        <BigOutlineButton
          color="neutral5"
          onClick={() => {
            hideModal({ update: false });
          }}
        >
          Cancel
        </BigOutlineButton>
      </Div>
    </Div>
  </Div>
);

export default decorate(Layout);
