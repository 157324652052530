import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { SketchPicker } from "react-color";

import { noop } from "utils/General";
import { Div, Popover } from "components/Base";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const styles = {
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  },
  popover: {
    position: "absolute",
    zIndex: "2"
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  }
};

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.COLOR }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  )
);

export const UCColor = ({
  value = "",
  iniValue,
  fieldId,
  fieldType,
  onChange = noop,
  setIniValue = noop
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);

  return (
    <Div display="row.flex-start.flex-start">
      <Popover
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        Label={({ onClick }) => (
          <div style={styles.swatch} onClick={onClick}>
            <div
              style={{
                ...styles.color,
                backgroundColor: value ? `${value}` : undefined
              }}
            />
          </div>
        )}
      >
        {() => (
          <Div bg="white" display="row.flex-start.flex-start">
            <SketchPicker
              color={value ? `${value}` : undefined}
              onChange={color =>
                onChange(color.hex, {
                  meta: { fieldType, fieldId }
                })
              }
            />
          </Div>
        )}
      </Popover>
    </Div>
  );
};

export const Color = decorate(UCColor);
