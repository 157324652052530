import React from "react";
import NeutralButton from "components/Global/Module/Buttons/NeutralButton";
import ActionButton from "components/Global/Module/Buttons/ActionButton";
import ActionsButtonMenu from "components/Global/CRM/TableViews/ListView/ActionsButtonMenu";
import ReportMenu from "components/Event/Module/ActionsMenu/Report";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

const ReportActions = props => {
  return (
    <div styleName="actionButtons">
      {props.showSettingsModal ? (
        <div styleName="buttonWrapper">
          <ActionButton
            label="Configure Report"
            handleClick={props.showSettingsModal}
          />
        </div>
      ) : null}
      <div styleName="buttonWrapper">
        <ActionsButtonMenu menu={<ReportMenu {...props} />}>
          <NeutralButton
            label={
              <span className={css.buttonLabel}>
                Export <i className="material-icons">arrow_drop_down</i>
              </span>
            }
          />
        </ActionsButtonMenu>
      </div>
    </div>
  );
};

export default CSSModules(ReportActions, css);
