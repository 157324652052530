import { ERROR, CLEAR_ERROR } from "./constants";
import api from "./api";

export function sendInvite(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
