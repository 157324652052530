import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "../model";

import PortalPagePGA from "Portal/PortalPagePGA/PortalPagePGA/View";
import Loading from "ui-kit/Loading";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    pagePayload: getters.pagePayload(state, props)
  }),
  { init: actions.init }
);

const Layout = ({ init, loading, pagePayload: pageData }) => {
  useEffect(() => {
    init();
  }, []);

  return loading ? <Loading /> : <PortalPagePGA pageData={pageData} />;
};

export default decorate(Layout);
