import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { withRouter } from "react-router";
import { actions } from "../model";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import {
  Div,
  BigOutlineButton,
  BigFilledButton,
  MoreIcon
} from "components/Base";
import PopoverMenu from "ui-kit/PopoverMenu/View";

import { ACTION_TYPES } from "ui-kit/PopoverMenu/constants";
import { INTEGRATION_IDS, ACTIONS } from "../constants";

import ClassyModal from "./Modals/Classy";
import NeonOneModal from "./Modals/NeonOne";
import PardotModal from "./Modals/Pardot";
import SalesforceModal from "./Modals/Salesforce";
import EventbriteModal from "./Modals/Eventbrite";
import HubspotModal from "./Modals/Hubspot";

import SelectCampaignModal from "./Modals/SelectCampaign";

import { isCredentialsEnabled } from "SettingsIntegrations/selectors";

import {
  getClassyDetails,
  getNeonOneDetails,
  getPardotDetails,
  getSalesforceDetails,
  getRaisersEdgeDetails,
  getEventbriteDetails,
  getHubspotDetails
} from "../selectors";

const decorate = R.compose(
  CSSModules(css),
  connect(
    state => ({
      isCredentialsEnabled: isCredentialsEnabled(state),
      //
      classyDetails: getClassyDetails(state),
      neonOneDetails: getNeonOneDetails(state),
      pardotDetails: getPardotDetails(state),
      salesforceDetails: getSalesforceDetails(state),
      raisersEdgeDetails: getRaisersEdgeDetails(state),
      eventbriteDetails: getEventbriteDetails(state),
      hubspotDetails: getHubspotDetails(state)
    }),
    {
      openClassyModal: () => actions.setIsClassyModalOpen(true),
      openPardotModal: () => actions.setIsPardotModalOpen(true),
      openSalesforceModal: () => actions.setIsSalesforceModalOpen(true),
      openRaisersEdgeModal: () => actions.setIsRaisersEdgeModalOpen(true),
      openNeonOneModal: () => actions.setIsNeonOneModalOpen(true),
      openEventbriteModal: () => actions.setIsEventbriteModalOpen(true),
      openHubspotModal: () => actions.setIsHubspotModalOpen(true),

      //
      openCampaignsModal: actions.setIsCampaignsModalOpen,
      //
      connectRaisersEdge: actions.connectRaisersEdge,
      connectPardot: actions.connectPardot,
      connectSalesforce: actions.connectSalesforce,
      connectEventbrite: actions.connectEventbrite,
      connectHubspot: actions.connectHubspot
    }
  )
);

const Wrapper = ({ children }) => {
  return (
    <Div
      display="column"
      width={1}
      bra={3}
      bc="gray3"
      ba={1}
      p={5}
      mb={5}
      className={css.wrapper}
    >
      {children}
    </Div>
  );
};

const Logo = ({ backgroundImageUrl }) => {
  return (
    <Div
      width={48}
      height={48}
      bg="white"
      mr={3}
      bra={1}
      style={{
        backgroundImage: `url("${backgroundImageUrl}")`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    ></Div>
  );
};

const Title = ({ children }) => {
  return (
    <Div
      fw={3}
      color="black"
      style={{
        fontSize: "25px"
      }}
    >
      {children}
    </Div>
  );
};

const Description = ({ children }) => {
  return (
    <Div color="gray7" mb={1}>
      {children}
    </Div>
  );
};

const Link = ({ children }) => {
  return (
    <Div color="gray7" mb={2}>
      {children}
    </Div>
  );
};

const Button = ({ children, onClick }) => {
  return (
    <BigFilledButton
      className={css.button}
      bg="altB5"
      width={121}
      onClick={onClick}
    >
      {children}
    </BigFilledButton>
  );
};

const ButtonRow = ({ children }) => {
  return (
    <Div display="row.flex-start.center" mt={3}>
      {children}
    </Div>
  );
};

const PopoverButton = ({ id, settings = [] }) => {
  return (
    <PopoverMenu
      data={{
        id
      }}
      actions={[
        ...settings,
        {
          id: ACTIONS.DELETE,
          type: ACTION_TYPES.ITEM,
          name: "Disconnect Integration",
          leftIcon: "delete",
          confirm: true
        }
      ]}
    >
      <BigOutlineButton ml={2} OnlyIcon={MoreIcon} />
    </PopoverMenu>
  );
};

const Classy = ({ id, openConnectModal, openCampaignsModal, details }) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://pbs.twimg.com/profile_images/1410625041419673606/x3Tcoph5_400x400.png" />
        <Title>Classy</Title>
      </Div>
      <Description>Connect Fondi to your Classy instance</Description>
      <Link>
        Read the{" "}
        <a
          href="https://learn.lennd.com/en/articles/5097615-connect-your-classy-integration"
          target="_blank"
        >
          Classy integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const NeonOne = ({ id, openConnectModal, openCampaignsModal, details }) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://pbs.twimg.com/profile_images/1348997072150786056/dc65PYTk_400x400.jpg" />
        <Title>Neon CRM</Title>
      </Div>
      <Description>Connect Fondi to your Neon CRM instance</Description>
      <Link>
        Read the{" "}
        <a
          href="https://learn.lennd.com/en/articles/5101930-connect-your-neon-one-integration"
          target="_blank"
        >
          Neon CRM integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const Pardot = ({
  id,
  openConnectModal,
  openCampaignsModal,
  details,
  settings = []
}) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://pbs.twimg.com/profile_images/1268211215274315781/nxNwbMcM_400x400.png" />
        <Title>Pardot</Title>
      </Div>
      <Description>Connect Fondi to your Pardot instance</Description>
      <Link>
        Read the{" "}
        <a href="https://learn.lennd.com/en/articles/5248741-connect-your-pardot-integration">
          Pardot integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} settings={settings} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const Salesforce = ({
  id,
  openConnectModal,
  openCampaignsModal,
  details,
  settings = []
}) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://pbs.twimg.com/profile_images/1268205537637748736/jyoK_62Q_400x400.jpg" />
        <Title>Salesforce</Title>
      </Div>
      <Description>Connect Fondi to your Salesforce instance</Description>
      <Link>
        Read the{" "}
        <a href="https://learn.lennd.com/en/articles/5243994-connect-your-salesforce-integration">
          Salesforce integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} settings={settings} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const RaisersEdge = ({ id, openConnectModal, openCampaignsModal, details }) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://integrations.dotdigital.com/media/1881/raisers-edge-nxt.png" />
        <Title>Blackbaud Raiser's Edge NXT</Title>
      </Div>
      <Description>Connect Fondi to your Raiser's Edge instance</Description>
      <Link>
        Read the{" "}
        <a href="https://learn.lennd.com/en/articles/5248720-connect-your-raiser-s-edge-integration">
          Raiser's Edge integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const Eventbrite = ({
  id,
  openConnectModal,
  openCampaignsModal,
  details,
  settings = []
}) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://pbs.twimg.com/profile_images/1138511131482345473/7hMdWJFQ_400x400.png" />
        <Title>Eventbrite</Title>
      </Div>
      <Description>Connect Fondi to your Eventbrite organization</Description>
      <Link>
        Read the{" "}
        <a href="https://learn.lennd.com/en/articles/5248703-connect-your-eventbrite-integration">
          Eventbrite integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} settings={settings} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const Hubspot = ({ id, openConnectModal, openCampaignsModal, details }) => {
  return (
    <Wrapper>
      <Div display="row.flex-start.center" mb={2}>
        <Logo backgroundImageUrl="https://pbs.twimg.com/profile_images/1329180456286294018/DSFK8Mc0_400x400.png" />
        <Title>Hubspot</Title>
      </Div>
      <Description>Connect Fondi to your Hubspot instance</Description>
      <Link>
        Read the{" "}
        <a href="https://learn.lennd.com/en/articles/5248714-connect-your-hubspot-integration">
          Hubspot integration documentation
        </a>
        .
      </Link>
      {details.connected ? (
        <ButtonRow>
          <Button onClick={() => openCampaignsModal(id)}>Settings</Button>
          <PopoverButton id={id} />
        </ButtonRow>
      ) : (
        <ButtonRow>
          <Button onClick={openConnectModal}>Connect</Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const Layout = ({
  openCampaignsModal,
  //
  openClassyModal,
  openPardotModal,
  openSalesforceModal,
  openNeonOneModal,
  openEventbriteModal,
  openHubspotModal,
  //
  classyDetails,
  neonOneDetails,
  raisersEdgeDetails,
  pardotDetails,
  salesforceDetails,
  eventbriteDetails,
  hubspotDetails,
  //
  connectRaisersEdge,
  connectPardot,
  connectSalesforce,
  connectEventbrite,
  connectHubspot
}) => {
  return (
    <Div width={1} height={1}>
      <Div style={{ height: "calc(100% - 86px)" }}>
        <Div
          px={6}
          width={1}
          style={{
            maxWidth: 1170
          }}
          height={1}
        >
          <Classy
            id={INTEGRATION_IDS.CLASSY}
            openConnectModal={openClassyModal}
            openCampaignsModal={openCampaignsModal}
            details={classyDetails}
          />
          <NeonOne
            id={INTEGRATION_IDS.NEONONE}
            openConnectModal={openNeonOneModal}
            openCampaignsModal={openCampaignsModal}
            details={neonOneDetails}
          />

          <RaisersEdge
            id={INTEGRATION_IDS.RAISERSEDGE}
            openConnectModal={connectRaisersEdge}
            openCampaignsModal={openCampaignsModal}
            details={raisersEdgeDetails}
          />

          <Eventbrite
            id={INTEGRATION_IDS.EVENTBRITE}
            openConnectModal={
              eventbriteDetails.connected
                ? openEventbriteModal
                : connectEventbrite
            }
            openCampaignsModal={
              eventbriteDetails.configured
                ? openCampaignsModal
                : openEventbriteModal
            }
            details={eventbriteDetails}
            settings={
              eventbriteDetails.configured
                ? [
                    {
                      id: ACTIONS.EDIT_CONNECTION_SETTINGS,
                      type: ACTION_TYPES.ITEM,
                      name: "Connection Settings",
                      leftIcon: "edit",
                      confirm: false
                    }
                  ]
                : []
            }
          />

          <Hubspot
            id={INTEGRATION_IDS.HUBSPOT}
            openConnectModal={connectHubspot}
            openCampaignsModal={openHubspotModal}
            details={hubspotDetails}
          />

          <Salesforce
            id={INTEGRATION_IDS.SALESFORCE}
            openConnectModal={
              salesforceDetails.connected
                ? openSalesforceModal
                : connectSalesforce
            }
            openCampaignsModal={
              salesforceDetails.configured
                ? openCampaignsModal
                : openSalesforceModal
            }
            details={salesforceDetails}
            settings={
              salesforceDetails.configured
                ? [
                    {
                      id: ACTIONS.EDIT_CONNECTION_SETTINGS,
                      type: ACTION_TYPES.ITEM,
                      name: "Connection Settings",
                      leftIcon: "edit",
                      confirm: false
                    }
                  ]
                : []
            }
          />

          <Pardot
            id={INTEGRATION_IDS.PARDOT}
            openConnectModal={
              pardotDetails.connected ? openPardotModal : connectPardot
            }
            openCampaignsModal={
              pardotDetails.configured ? openCampaignsModal : openPardotModal
            }
            details={pardotDetails}
            settings={
              pardotDetails.configured
                ? [
                    {
                      id: ACTIONS.EDIT_CONNECTION_SETTINGS,
                      type: ACTION_TYPES.ITEM,
                      name: "Connection Settings",
                      leftIcon: "edit",
                      confirm: false
                    }
                  ]
                : []
            }
          />
        </Div>
      </Div>

      <ClassyModal />
      <NeonOneModal />
      <PardotModal />
      <EventbriteModal />
      <SalesforceModal />
      <HubspotModal />

      <SelectCampaignModal />
    </Div>
  );
};

export default withRouter(decorate(Layout));
