export const NAMESPACE = "EventLightContent";

export const TABLE_INSTANCE_ID = "EventLightContentTable";
export const PAGE_OPTIONS = [15, 45, 75];

export const FORM_ID = "EventLightContent";

export const FIELD_IDS = {
  TITLE: "title",
  THUMBNAIL_URL: "thumbnail_url",
  URL: "url",
  VISIBLE: "visible",
  ORDER: "order",
  LABEL: "label"
};
