import * as R from "ramda";

const EMPTY_ITEM = {
  name: "",
  description: "",
  variants: []
};

export const iniState = {
  newCategoryName: "",
  addingCategory: false,
  boothPackages: [],
  types: [],
  groups: [],
  items: [],
  variants: []
};

export const reducers = {
  addBoothPackage: (state, { payload: packageId }) => ({
    boothPackages: R.append(packageId, state.boothPackages)
  }),
  deleteBoothPackage: (state, { payload: packageId }) => ({
    boothPackages: R.without([packageId], state.boothPackages)
  }),
  addItemRequest: R.identity,
  addItem: ({ items }, { payload: { id } }) => ({
    items: [...items, { ...EMPTY_ITEM, id }]
  }),
  addItemResponse: (state, { payload: { oldId, newItem }, error }) =>
    error
      ? state
      : {
          items: [
            ...state.items.filter(i => i.id !== oldId),
            {
              ...state.items.find(i => i.id === oldId),
              ...newItem,
              group_order: `z` // ensures new items always show at bottom
            }
          ]
        },

  deleteItemRequest: R.identity,
  deleteItemResponse: R.identity,
  deleteItem: (state, { payload: id }) => ({
    items: state.items.filter(i => i.id !== id)
  }),
  updateItemResponse: R.identity,
  setItemData: (state, { payload: { types, groups, items, variants } }) => ({
    types,
    groups,
    items,
    variants
  }),

  setAddCategoryRequest: R.identity,
  setAddCategoryResponse: R.always({ addingCategory: "" }),
  closeAddCategoryModal: R.always({ addingCategory: "" })
};
