import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  viewMore: false
};

const model = createHandlers({
  iniState,
  reducers: {
    toggleShowMore: ({ viewMore }) => ({
      viewMore: R.not(viewMore)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
