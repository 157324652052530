import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import SliderSidebarLayout from "ui-kit/SliderSidebar/View";
import Body from "./Body";

import { getters as sliderSidebarGetters } from "ui-kit/SliderSidebar/model";

import { SIDEBAR_INSTANCE_ID } from "../constants";

const ActivityDetailSidebar = ({ isDrawerOpen }) => (
  <SliderSidebarLayout position="right" instanceId={SIDEBAR_INSTANCE_ID}>
    {isDrawerOpen && <Body />}
  </SliderSidebarLayout>
);

const decorate = R.compose(
  connect((state, props) => ({
    isDrawerOpen: sliderSidebarGetters.isDrawerOpen(state, {
      ...props,
      instanceId: SIDEBAR_INSTANCE_ID
    })
  }))
);

export default decorate(ActivityDetailSidebar);
