export const NAMESPACE = "Checkout";

export const FORM_INSTANCE_ID = "checkoutFormInstanceId";

export const DEFAULT_STRIPE_MESSAGE =
  "There was an issue with the payment information you provided. Please try again.";

export const FORM_RADIO_ID = "address";

export const BILLING_OPTIONS = [
  {
    id: "same",
    label: "Same as shipping"
  },
  {
    id: "different",
    label: "Enter different billing address"
  }
];
