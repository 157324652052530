import { makeInstanceSelector } from "redux-mvc";
import * as R from "ramda";
import { getters } from "./model";

const getKey = (_, props = {}) => props.instanceId;

export const getFilteredPassName = makeInstanceSelector(
  getters.data,
  getters.passFilter,
  (dataGrid, passFilter) => {
    if (R.isEmpty(passFilter)) {
      return dataGrid;
    }
    return {
      ...dataGrid,
      y: {
        ...dataGrid.y,
        groups: R.reduce(
          (acc, group) => {
            const filteredItems = R.filter(
              field => R.contains(R.toLower(passFilter), R.toLower(field.name)),
              group.items
            );
            if (R.isEmpty(filteredItems)) {
              return acc;
            }
            const filteredGroup = {
              ...group,
              items: filteredItems
            };
            return [...acc, filteredGroup];
          },
          [],
          R.pathOr([], ["y", "groups"], dataGrid)
        )
      }
    };
  }
)(getKey);

export const getColumns = makeInstanceSelector(
  getters.data,
  R.compose(
    R.reduce((acc, { items }) => [...acc, ...items], []),
    R.pathOr([], ["x", "groups"])
  )
)(getKey);

export const getRows = makeInstanceSelector(
  getFilteredPassName,
  getColumns,
  (data, columns) => {
    return R.compose(
      R.map(item => {
        const columnsForRows = R.reduce(
          (acc, column) => ({
            ...acc,
            [column.id]: {
              variantId: item.id,
              zoneId: column.id,
              columnName: column.name,
              hasValue: R.any(
                ({ x, y }) => x === item.id && y === column.id,
                data.values
              )
            }
          }),
          {},
          columns
        );
        return { passes: item, ...columnsForRows };
      }),
      R.reduce(
        (acc, { items, name, id }) => [
          ...acc,
          { name, id, isTitle: true },
          ...items
        ],
        []
      ),
      R.pathOr([], ["y", "groups"])
    )(data);
  }
)(getKey);

export const getRowsLength = makeInstanceSelector(
  getters.data,
  getColumns,
  (data, columns) => {
    return R.compose(
      R.length,
      R.map(item => {
        const columnsForRows = R.reduce(
          (acc, column) => ({
            ...acc,
            [column.id]: {
              variantId: item.id,
              zoneId: column.id,
              columnName: column.name,
              hasValue: R.any(
                ({ x, y }) => x === item.id && y === column.id,
                data.values
              )
            }
          }),
          {},
          columns
        );
        return { passes: item, ...columnsForRows };
      }),
      R.reduce(
        (acc, { items, name, id }) => [
          ...acc,
          { name, id, isTitle: true },
          ...items
        ],
        []
      ),
      R.pathOr([], ["y", "groups"])
    )(data);
  }
)(getKey);
