import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

@CSSModules(css)
class MyComponent extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleMenuSelect = (e, val) => {
    this.onRequestClose();
    return this.props.onPageSizeChange(val);
  };

  render() {
    return (
      <div data-testid="table-pagination-size-selector">
        <div
          className={css.select}
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          <span>Show: {this.props.pageSize}</span>
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <Menu desktop onChange={this.handleMenuSelect}>
            {this.props.pageSizeOptions.map(option => (
              <MenuItem
                key={option}
                value={option}
                onClick={() => {}}
                primaryText={`Show ${option}`}
              />
            ))}
          </Menu>
        </Popover>
      </div>
    );
  }
}

MyComponent.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.array.isRequired
};

export default MyComponent;
