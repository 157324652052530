import React from "react";
import { addS } from "utils/General";

import {
  Div,
  makeTable,
  Text3,
  ExpandIcon,
  ThreeQuarterSizeSquareIcon,
  PopoverMenu,
  SmallOutlineButton,
  FontIcon
} from "components/Base";

import {
  HeaderStyler,
  RowStyler,
  TableStyler
} from "components/Event/Settings/Layout/Tables/stylers";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const CredentialCell = CSSModules(
  ({ name, background_color, ...props }) => (
    <Div display="row.flex-start.center" {...props}>
      <ThreeQuarterSizeSquareIcon
        size={12}
        mr={2}
        style={{ minWidth: 12, color: background_color }}
      />
      <Text3 bold mr={3} primary>
        {name}
      </Text3>
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
      />
    </Div>
  ),
  css
);

const TypesTableWrapper = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 4 / 12 },
    { width: 3 / 12 },
    { width: 4 / 12 },
    { width: 2 / 12 }
  ],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Code</Text3>,
    () => <Text3 bold># of Passes</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name, IntegrationIcon, color, onCredentialClick, background_color }) => (
      <CredentialCell
        name={name}
        color={color}
        IntegrationIcon={IntegrationIcon}
        onClick={onCredentialClick}
        background_color={background_color}
      />
    ),
    ({ code }) => <Text3>{code}</Text3>,
    ({ variants }) => (
      <Text3>{`${variants.length} item${addS(variants.length)}`}</Text3>
    ),
    ({ onEdit, onClone, onDelete, moveCredentialUp, moveCredentialDown }) => (
      <PopoverMenu
        Label={({ onClick }) => (
          <SmallOutlineButton onClick={onClick} py={1}>
            <FontIcon fs={3}>more_horiz</FontIcon>
          </SmallOutlineButton>
        )}
        menuItems={[
          ["Edit", onEdit],
          ["Clone", onClone],
          ["Delete", onDelete],
          moveCredentialUp ? ["Move up", moveCredentialUp] : undefined,
          moveCredentialDown ? ["Move down", moveCredentialDown] : undefined
        ].filter(i => i)}
      />
    )
  ]
});

const TypesTable = ({ types, onReorder, dragTypeId }) => (
  <TypesTableWrapper
    rowsData={types}
    onReorder={onReorder}
    dragTypeId={dragTypeId}
  />
);

export { TypesTable };
