import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import { Div, Text2, MoneyIcon, MediumClearInput } from "components/Base";

import { getters, actions } from "Orders/TicketsPasses";

const decorate = connect(
  state => ({
    pricesTemp: getters.pricesTemp(state)
  }),
  {
    updatePriceTable: actions.updatePriceTable
  }
);

const QuickAssignPricesTable = ({ pricesTemp, updatePriceTable }) => (
  <Div>
    <Div display="row.space-between">
      <Div width={130} mb={1}>
        <Text2 color="neutral6" bold>
          PRICE
        </Text2>
      </Div>
      <Div flex={1}>
        <Text2 color="neutral6" bold>
          LABEL
        </Text2>
      </Div>
    </Div>
    {R.map(
      ({ tempId, name, amount }) => (
        <Div
          display="row.space-between"
          key={tempId}
          shadow={1}
          mb={1}
          p={2}
          bra={1}
        >
          <Div width={130}>
            <Div
              bc="neutral2"
              ba={1}
              bra={1}
              display="row"
              flex={1}
              height={40}
              mr={2}
            >
              <Div bg="neutral1" display="column.center.center" px={1}>
                <MoneyIcon color="neutral7" size={24} />
              </Div>
              <Div flex={1} height={40} display="column.center.center">
                <MediumClearInput
                  width={1}
                  placeholder="Price..."
                  value={amount}
                  color="neutral7"
                  type="number"
                  onChange={value => {
                    updatePriceTable({
                      tempId,
                      data: { amount: parseInt(value, 10) }
                    });
                  }}
                  continuous
                />
              </Div>
            </Div>
          </Div>
          <Div flex={1}>
            <Div
              bc="neutral2"
              ba={1}
              flex={1}
              height={40}
              display="column.center.center"
              bra={1}
            >
              <MediumClearInput
                width={1}
                placeholder="Label this price..."
                value={name}
                color="neutral7"
                onChange={value => {
                  updatePriceTable({
                    tempId,
                    data: { name: value }
                  });
                }}
                continuous
              />
            </Div>
          </Div>
        </Div>
      ),
      pricesTemp
    )}
  </Div>
);

export default decorate(QuickAssignPricesTable);
