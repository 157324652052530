import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  CREATE,
  CREATE_COMPLETE,
  UPDATE,
  UPDATE_COMPLETE,
  BULK_UPDATE,
  BULK_UPDATE_COMPLETE,
  DELETE,
  DELETE_COMPLETE,
  ERROR,
  CLEAR_ERROR,
  REORDER,
  START_DRAGGING_SIDEBAR_FIELD,
  STOP_DRAGGING_SIDEBAR_FIELD,
  SELECT_FORM_FIELD,
  DESELECT_FORM_FIELD,
  TOGGLE_EDIT_FIELD_DESCRIPTION,
  SUBFORM_CREATE,
  SUBFORM_CREATE_COMPLETE,
  SUBFORM_UPDATE,
  SUBFORM_UPDATE_COMPLETE,
  SUBFORM_UPDATE_WIDTH,
  SUBFORM_BULK_UPDATE,
  SUBFORM_BULK_UPDATE_COMPLETE,
  SUBFORM_DELETE,
  SUBFORM_DELETE_COMPLETE
} from "./constants";
import api from "./api";
import { sortedFormFields } from "../selectors";
import { registerError } from "redux/modules/errors/actions";

export function addField(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_COMPLETE,
        payload: {
          field: result.field,
          mappedFields: data.mappedFields,
          parentFields: data.parentFields,
          childFields: data.childFields
        },
        optimist: { type: COMMIT, id: transactionId }
      });

      // update field orders with new column
      const formFields = sortedFormFields(getState());
      dispatch(
        updateFieldOrder({
          formId: data.formId,
          fields: formFields.reduce((fieldMap, field, idx) => {
            fieldMap[field.id] = idx;
            return fieldMap;
          }, {})
        })
      );

      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function updateField(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: UPDATE_COMPLETE,
        payload: {
          field: result.field
        },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating form field"
          }
        ])
      );

      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function bulkUpdateFields(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: BULK_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: BULK_UPDATE_COMPLETE,
        payload: { fields: result.fields },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function deleteField(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: DELETE,
      payload: { fieldId: data.fieldId },
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      dispatch({
        type: DELETE_COMPLETE,
        payload: { fieldId: data.fieldId },
        optimist: { type: COMMIT, id: transactionId }
      });

      const formFields = sortedFormFields(getState());
      dispatch(
        updateFieldOrder({
          formId: data.formId,
          fields: formFields.reduce((fieldMap, field, idx) => {
            fieldMap[field.id] = idx;
            return fieldMap;
          }, {})
        })
      );
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function startDraggingSidebarField(sidebarField) {
  return dispatch =>
    dispatch({
      type: START_DRAGGING_SIDEBAR_FIELD,
      payload: {
        sidebarField
      }
    });
}

export function stopDraggingSidebarField(sidebarField) {
  return dispatch =>
    dispatch({
      type: STOP_DRAGGING_SIDEBAR_FIELD,
      payload: {
        sidebarField
      }
    });
}

export function updateFieldOrder(data) {
  return (dispatch, getState) => {
    dispatch({
      type: REORDER,
      payload: {
        fields: data.fields
      }
    });
    if (data.commit) {
      api.order(getState().user.user.credentials, data);
    }
  };
}

export function toggleEditFieldDescription(payload) {
  return {
    type: TOGGLE_EDIT_FIELD_DESCRIPTION,
    payload
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}

export function addSubformField(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: SUBFORM_CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: SUBFORM_CREATE_COMPLETE,
        payload: {
          subformId: data.subformId,
          sectionId: data.sectionId,
          field: result.field,
          mappedFields: data.mappedFields,
          parentFields: data.parentFields,
          childFields: data.childFields
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.field;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
      return null;
    }
  };
}

export function updateSubformField(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: SUBFORM_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: SUBFORM_UPDATE_COMPLETE,
        payload: {
          subformId: data.subformId,
          sectionId: data.sectionId,
          field: result.field,
          mappedFields: data.mappedFields,
          parentFields: data.parentFields,
          childFields: data.childFields
        },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function updateSubformFieldWidth(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBFORM_UPDATE_WIDTH,
      payload: data
    });
    try {
      await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function bulkUpdateSubformFields(data) {
  return async (dispatch, getState) => {
    try {
      await api.putBulk(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteSubformField(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: SUBFORM_DELETE,
      payload: {
        subformId: data.subformId,
        sectionId: data.sectionId,
        fieldId: data.fieldId
      },
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      dispatch({
        type: SUBFORM_DELETE_COMPLETE,
        payload: { fieldId: data.fieldId },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function selectFormField(fieldId) {
  return dispatch =>
    dispatch({
      type: SELECT_FORM_FIELD,
      payload: {
        fieldId
      }
    });
}

export function deselectFormField() {
  return dispatch =>
    dispatch({
      type: DESELECT_FORM_FIELD,
      payload: {}
    });
}
