import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";
import PrimaryFields from "components/Global/CRM/About";
import ActionTag from "components/Event/Accounts/Account/ActionTag";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import MoreInfoModal from "components/Global/CRM/Modals/MoreInfoModal";
import Tabs from "components/Global/CRM/Tabs";
import Tab from "components/Global/CRM/Tabs/Tab";
import RecordOwners from "components/Global/CRM/RecordOwners";
import RecordSubscription from "components/Global/CRM/RecordSubscription";
import Feed from "components/Global/Module/Feed";
import EditRecord from "./Edit";
import RelatedModule from "components/Global/CRM/RelatedModule";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";

const { ID, OWNERS } = SYSTEM_FIELD_IDS;

const FILE_FIELDS = [
  {
    id: "ea87516b-d6ca-40fc-9b17-e93b128f587b",
    name: "Title",
    internal_name: "title",
    type: "text",
    settings: {},
    source: "standard",
    is_primary_field: true,
    order: 0
  },
  {
    id: "d87fd0b2-a84e-4f70-92be-864b457115dc",
    name: "Description",
    internal_name: "description",
    type: "textarea",
    settings: {},
    source: "standard",
    order: 1
  },
  {
    id: "8fc10190-5c45-42de-8c9b-6a9c2e003b36",
    name: "Filename",
    internal_name: "filename",
    type: "text",
    settings: {},
    source: "standard",
    order: 2
  },
  {
    id: "f26fe10c-993d-485f-aa85-110dd748a66a",
    name: "URL",
    internal_name: "url",
    type: "text",
    settings: {},
    source: "standard",
    order: 3
  },
  {
    id: "250b390a-8307-4130-bb12-3df6e8ea0af3",
    name: "File Type",
    internal_name: "mimetype",
    type: "text",
    settings: {},
    source: "standard",
    order: 4
  }
];

@CSSModules(css)
class ModuleRecordView extends Component {
  state = { loading: true };

  componentWillMount() {
    Promise.all([this.fetchModule(), this.fetchRecord()]).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.invalidateRecord(this.props.params.recordId);
  }

  fetchModule = () =>
    this.props.getModule({
      moduleId: this.props.params.moduleId,
      options: {
        eventId: this.props.params.eventId
      }
    });

  fetchRecord = () =>
    this.props.getRecord({
      moduleId: this.props.params.moduleId,
      recordId: this.props.params.recordId,
      options: {
        eventId: this.props.params.eventId
      }
    });

  goToRecords = () =>
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/module/${this.props.params.moduleId}/dashboard`
    });

  showMoreInfoModal = () => {
    this.props.showModal({
      content: (
        <MoreInfoModal
          hideModal={this.props.hideModal}
          goToManageFields={() => {}}
          data={this.props.record}
          name={this.props.module.record_name}
          updateField={() => {}}
          eventDetails={this.props.eventDetails}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleValueChange = async (fieldId, value) => {
    await this.props.addValue({
      fieldId,
      recordId: this.props.params.recordId,
      moduleId: this.props.params.moduleId,
      value
    });
    return this.fetchRecord();
  };

  handleDeleteRecord = () =>
    this.props
      .deleteRecord({
        moduleId: this.props.params.moduleId,
        record: { id: this.props.record.id },
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      })
      .then(() => {
        this.goToRecords();
      });

  render() {
    const {
      params,
      module,
      record,
      eventDetails,
      showModal,
      hideModal,
      relatedModules,
      starredFields
    } = this.props;

    if (this.state.loading) return null;

    const enabled = {
      files: Boolean(
        eventDetails.enabled_modules.find(
          m => m.id === STANDARD_MODULE_IDS.files.id
        )
      )
    };

    return (
      <div styleName="container">
        <div styleName="topBar">
          <div styleName="header">
            <div styleName="returntoLink" onClick={this.goToRecords}>
              <i className="material-icons">arrow_back</i>
              Back
            </div>
            {/*
              <div styleName="recordSwitch">
              <i className="material-icons">keyboard_arrow_left</i>
              <i className="material-icons">keyboard_arrow_right</i>
              </div>
            */}
          </div>
        </div>
        <div styleName="body">
          <div styleName="sidebar">
            <ActionTag
              hideModal={hideModal}
              name={toString(
                getValue(get(this.props.record, ["values", ID]), "text"),
                "text"
              )}
              onDelete={this.handleDeleteRecord}
              showModal={showModal}
              type={{
                color: module.color,
                icon: module.icon,
                name: module.name
              }}
            />
            <PrimaryFields
              data={{
                starred_fields: starredFields,
                values: Object.keys(record.values).reduce((values, id) => {
                  values[id] = {
                    field_id: id,
                    value: record.values[id]
                  };
                  return values;
                }, {})
              }}
              disabled
              eventDetails={eventDetails}
              showStarredFields
              updateField={() => {}}
              viewAllProperties={this.showMoreInfoModal}
              userId={this.props.user.id}
              references={this.props.references}
            />
            <RecordOwners
              title="Record Owners"
              recordId={record.id}
              moduleId={module.id}
              recordNameSingular={module.record_name}
              recordNamePlural={module.record_name_plural}
              onOwnersChange={this.fetchRecord}
              owners={get(
                this.props.record,
                ["values", OWNERS, "value", "records"],
                []
              )}
            />
            {/* <RecordSubscription moduleId={module.id} recordId={record.id} /> */}
          </div>
          <div styleName="main">
            <div styleName="tabs">
              <Tabs>
                <Tab label="Related">
                  <div styleName="tabContent">
                    {enabled.files ? (
                      <RelatedModule
                        module={{
                          ...STANDARD_MODULE_IDS.files,
                          event_id: params.eventId,
                          preferences: {
                            visible_fields: FILE_FIELDS.map(f => f.id),
                            field_order: FILE_FIELDS.reduce((map, f, idx) => {
                              map[f.id] = idx;
                              return map;
                            }, {})
                          },
                          fields: {
                            fields: FILE_FIELDS
                          }
                        }}
                        record={{
                          id: params.recordId,
                          name,
                          valueName: "recordId",
                          moduleId: module.id,
                          moduleInternalName: module.internal_name,
                          values: []
                        }}
                      />
                    ) : null}
                    {relatedModules.map(relatedModule => (
                      <RelatedModule
                        key={`${relatedModule.id}_${relatedModule.lookup_field.id}`}
                        module={relatedModule}
                        record={{
                          id: params.recordId,
                          name: relatedModule.name,
                          valueName: "recordId",
                          moduleId: relatedModule.id,
                          moduleInternalName: relatedModule.internalName,
                          values: [
                            {
                              fieldId: relatedModule.lookup_field.id,
                              value: {
                                type: "lookup",
                                value: {
                                  moduleId: module.id,
                                  records: [params.recordId]
                                }
                              }
                            }
                          ]
                        }}
                      />
                    ))}
                  </div>
                </Tab>
                <Tab label="Edit Details">
                  <div styleName="tabContent">
                    <EditRecord
                      {...this.props}
                      onChange={this.handleValueChange}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div styleName="feed">
              <Feed
                className={css.feed}
                eventId={params.eventId}
                moduleId={module.id}
                name={module.record_name}
                recordId={record.id}
                whitelist={["notes", "activity"]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModuleRecordView.propTypes = {
  logAction: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string,
    moduleId: PropTypes.string,
    recordId: PropTypes.string
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  getRecord: PropTypes.func.isRequired,
  getModule: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
  addValue: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  relatedModules: PropTypes.array.isRequired,
  invalidateRecord: PropTypes.func.isRequired
};

export default ModuleRecordView;
