import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CheckinController from "./Controller";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import {
  checkin,
  searchCheckins,
  revertCheckin,
  getStats
} from "redux/modules/passesCheckin/actions";
import { cancelFulfillment } from "redux/modules/orders/fulfillments/actions";
import {
  currentPage,
  pageSize,
  totalPages,
  checkins,
  isFetching,
  countOfIssued,
  countOfTotal
} from "redux/modules/passesCheckin/selectors";

function mapStateToProps(state) {
  return {
    recordTypes: recordTypes(state, STANDARD_MODULES.accounts.id),
    checkins: checkins(state),
    fetching: isFetching(state),
    currentPage: currentPage(state),
    totalPages: totalPages(state),
    pageSize: pageSize(state),
    countOfIssued: countOfIssued(state),
    countOfTotal: countOfTotal(state)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      search: searchCheckins,
      checkin,
      cancelFulfillment,
      revertCheckin,
      getStats,
      getRecordTypes: () =>
        getRecordTypes({
          moduleId: STANDARD_MODULES.accounts.id,
          options: {
            eventId: props.params.eventId
          }
        })
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckinController);
