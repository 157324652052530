import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import * as FieldActions from "redux/modules/formsV2/form/fields/actions";

@CSSModules(css)
class FormAddGridModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      settings: {
        style: "card-and-table",
        allowNewRows: true
      },
      error: null
    };

    if (props.mode === "edit") {
      this.state = {
        ...this.state,
        ...props.field
      };
    }
  }

  @autobind
  onSave() {
    if (!this.state.name) {
      this.setState({ error: "Please enter the request form's name" });
    } else {
      if (this.props.mode !== "edit") {
        this.props.dispatch(
          FieldActions.addField({
            eventId: this.props.eventId,
            formId: this.props.formId,
            type: "subform",
            order: this.props.index,
            name: this.state.name,
            settings: this.state.settings
          })
        );
      } else {
        this.props.dispatch(
          FieldActions.updateField({
            fieldId: this.props.field.id,
            name: this.state.name,
            settings: this.state.settings
          })
        );
      }
      this.props.hideModal();
    }
  }

  render() {
    const {
      name,
      settings,
      settings: { allowNewRows, style },
      error
    } = this.state;
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title" styleName="title">
            {this.props.mode === "edit" ? "Edit" : "Add"} Requests Form
          </div>
        </div>

        <div className="modal-body" styleName="modalBody">
          <div className="modal-body-wrapper">
            <div className="input-wrapper">
              <label styleName="formLabel">Name</label>
              <input
                key="name"
                styleName="input"
                type="text"
                value={name}
                placeholder="Form Name"
                required="required"
                onChange={e =>
                  this.setState({ name: e.target.value, error: null })
                }
              />
            </div>

            <div className="input-wrapper">
              <label styleName="formLabel">Style</label>
              <select
                styleName="select"
                defaultValue={style}
                onChange={e =>
                  this.setState({
                    settings: { ...settings, style: e.target.value }
                  })
                }
              >
                <option value="card-and-table">Card + Table View</option>
                <option value="card">Card View Only</option>
                <option value="table">Table View Only</option>
              </select>
            </div>

            <div className="input-wrapper" styleName="checkboxWrapper">
              <input
                type="checkbox"
                checked={allowNewRows}
                onClick={() =>
                  this.setState({
                    settings: {
                      ...settings,
                      allowNewRows: !this.state.settings.allowNewRows
                    }
                  })
                }
                id="allowNewRows"
              />
              <label styleName="checkboxLabel" htmlFor="allowNewRows">
                Allow new rows to be added?
              </label>
            </div>

            {/* error */}
            {error !== null ? <div styleName="error">{error}</div> : ""}

            <div styleName="actionsWrapper">
              <div styleName="actionSave" onClick={this.onSave}>
                Save
              </div>
              <div styleName="actionCancel" onClick={this.props.hideModal}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FormAddGridModal.getModalClassName = () => "OptionsModal";

FormAddGridModal.propTypes = {
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func,
  hideModal: PropTypes.func,
  mode: PropTypes.string,
  field: PropTypes.object
};

export default FormAddGridModal;
