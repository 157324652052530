import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NoMatches = () => (
  <div styleName="optionContainer">
    <i className={["material-icons", css.processingIcon].join(" ")}>refresh</i>
    Loading...
  </div>
);

export default CSSModules(NoMatches, css);
