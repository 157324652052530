import React, { Component } from "react";
import { debounce } from "lodash";
import { ACCOUNT_PRIMARY_CONTACTS } from "SendEmailModal/utils/send-to-option-types";
import * as R from "ramda";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import View from "./View";
import { mapProps, withHandlers, withState } from "utils/General";
import moment from "moment";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import copy from "copy-to-clipboard";
import { ASSIGNMENT_MANAGER } from "utils/Emails/default-emails";
import SendEmailModal from "SendEmailModal/View";
import SummaryModal from "./SummaryModal";
import AddGroupModal from "./AddRecordModal";
import { PrimaryContactsModal } from "./PrimaryContacts";
import { AllocationsModal } from "./Items";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import SettingsModal from "./SettingsModal";
import { Div, LockIcon, ClockIcon } from "components/Base";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

class GuestListsController extends Component {
  componentDidMount() {
    this.fetchTypeData(this.props.params.recordTypeId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.recordTypeId !== this.props.params.recordTypeId) {
      this.fetchTypeData(this.props.params.recordTypeId);
      this.props.setSearchTerm("");
    }
  }

  fetchTypeData = async typeId => {
    await this.props.getGuestListData(typeId || this.props.params.recordTypeId);
    this.props.setLoading(false);
  };

  showSummaryModal = (id, name, itemTypeId) => {
    this.props.showModal({
      content: (
        <SummaryModal
          itemTypeId={itemTypeId}
          onClose={this.fetchTypeData}
          groupId={id}
          groupName={name}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showBulkEditModal = (id, name) => {
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          viaAllocations={true}
          showOverview={false}
          accountName={this.props.groupName}
          where={{
            eventId: this.props.eventDetails.id,
            requireAssignment: true,
            orderAccountId: id,
            orderType: "group"
          }}
          onDone={() => {
            this.props.hideModal();
            this.fetchTypeData();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  viewAccountProfile = id => {
    window.open(
      `${window.__LENND_APP_URL__}/event/${this.props.eventDetails.id}/account/${id}`
    );
  };

  viewAssignmentManager = id => {
    window.open(
      `${window.__LENND_APP_URL__}/assignment-manager/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}/${id}`
    );
  };

  showAddContactModal = (id, groupName) => {
    this.props.showModal({
      content: (
        <PrimaryContactsModal
          groupId={id}
          groupName={groupName}
          recordTypeName={this.props.guestList.name}
          recordTypeColor={this.props.guestList.background_color}
          recordTypeIcon={this.props.guestList.icon}
          hideModal={() => {
            this.props.hideModal();
            this.fetchTypeData();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showCheckin = id => {
    window.open(
      `${window.__LENND_APP_URL__}/event/${this.props.eventDetails.id}/passes/checkin`
    );
  };

  showReviewPending = id => {
    window.open(
      `${window.__LENND_APP_URL__}/event/${this.props.eventDetails.id}/account/${id}/credentials`
    );
  };

  showAddRecordModal = () => {
    this.props.showModal({
      content: (
        <AddGroupModal
          recordTypeId={this.props.params.recordTypeId}
          recordTypeName={this.props.guestList.name}
          recordTypeColor={this.props.guestList.background_color}
          recordTypeIcon={this.props.guestList.icon}
          onDone={() => {
            this.fetchTypeData();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSendEmailLinksModal = accountIds => {
    this.showSendEmailModal({ accountIds, ...ASSIGNMENT_MANAGER });
  };

  showSendEmailModal = ({ accountIds, subject, message }) => {
    this.props.showModal({
      content: (
        <SendEmailModal
          moduleId={STANDARD_MODULES.accounts.id}
          records={accountIds}
          selectedOptions={[ACCOUNT_PRIMARY_CONTACTS]}
          subject={subject}
          message={message}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSendEmailModalToEveryone = () =>
    this.showSendEmailModal({
      accountIds: this.getAllUnfilteredGroupIds(),

      // @TODO: Add reminder template
      subject: undefined,
      message: undefined
    });

  getAllGroupIds = () => R.map(R.prop("groupId"))(this.props.listData);

  getAllUnfilteredGroupIds = () =>
    R.map(R.prop("groupId"))(this.props.originalListData);

  gotoAccounts = () => {
    this.props.router.push(
      `/event/${this.props.params.eventId}/module/${STANDARD_MODULES.accounts.id}/dashboard`
    );
  };

  gotoCheckin = typeId => {
    this.props.router.push(
      `/event/${this.props.params.eventId}/passes/checkin${
        typeId ? `/${typeId}` : ""
      }`
    );
  };

  showAddItemsModal = (id, name, itemTypeId) => {
    this.props.showModal({
      content: (
        <AllocationsModal
          onSave={this.fetchTypeData}
          itemTypeId={itemTypeId}
          groupId={id}
          groupName={name}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleSearch = (searchTerm = "") => {
    this.props.setSearchTerm(searchTerm.toLowerCase());
  };

  search = debounce(this.handleSearch, 250);

  filterList = rows => {
    return rows.filter(({ name }) =>
      name.toLowerCase().includes(this.props.searchTerm)
    );
  };

  resolveAddAllocation = val => itemTypeId => {
    let allowAddItems = false;
    if (itemTypeId === CREDENTIAL_TYPE_ID) {
      allowAddItems = this.props.cando(
        `${STANDARD_MODULES.credentials.id}_add_line_items`
      );
    } else if (itemTypeId === MEAL_TYPE_ID) {
      allowAddItems = this.props.cando(
        `${STANDARD_MODULES.catering.id}_add_line_items`
      );
    }
    if (allowAddItems) {
      return () =>
        this.showAddItemsModal(val.groupId, val.groupName, itemTypeId);
    }
    return undefined;
  };

  showSettingsModal = () => {
    this.props.showModal({
      content: (
        <SettingsModal
          isModal={true}
          recordTypeId={this.props.params.recordTypeId}
          recordTypeName={this.props.guestList.name}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      params,
      loading,
      listData,
      originalListData,
      eventDetails,
      filterType,
      setFilterType,
      countOfNoAllocations,
      countOfNoPrimaryContacts,
      cando,
      searchTerm,
      guestListSettings
    } = this.props;

    const tableRows = R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      ),
      this.filterList,
      R.map(val => ({
        name: val.groupName || "",
        // linkSentStatus: "sent",
        // viewedAt: moment(new Date()).fromNow(),
        pendingRequestsCount: val.pendingRequestCount,
        checked: this.props.selectedRows.includes(val.groupId),
        onSelect: () => this.props.toggleRow(val.groupId),
        contacts: R.compose(
          R.filter(Boolean),
          R.map(R.prop("name"))
        )(val.primaryContacts),
        allocations: val.itemCounts,
        onAddContact: cando(`${STANDARD_MODULES.contacts.id}_add`)
          ? () => this.showAddContactModal(val.groupId, val.groupName)
          : undefined,
        onEditAllocation: cando(
          `${STANDARD_MODULES.credentials.id}_edit_line_items`
        )
          ? () => this.showBulkEditModal(val.groupId, val.groupName)
          : undefined,
        onShowSummary: itemTypeId =>
          this.showSummaryModal(val.groupId, val.groupName, itemTypeId),
        onAddAllocation: this.resolveAddAllocation(val),
        onReviewPendingRequests: () => this.showReviewPending(val.groupId),
        // onChangeAllocation: noop,
        onSendEmail: () =>
          this.showSendEmailModal({ accountIds: [val.groupId] }),
        onSendEmailLink: () => this.showSendEmailLinksModal([val.groupId]),
        onCopyShareLink: () => {
          copy(
            `${window.__LENND_APP_URL__}/assignment-manager/${eventDetails.slug}/${eventDetails.uuid}/${val.groupId}`
          );
          this.props.showSnackbar({
            message: "Link copied"
          });
        },
        onViewGroupProfile: () => this.viewAccountProfile(val.groupId),
        onViewGuestList: () => this.viewAssignmentManager(val.groupId)
        // onLockGuestList: noop,
        // onAdjustPassword: noop,
        // onViewGuestListNotes: noop,
      }))
    )(listData);

    const closeDate = guestListSettings.close_date;
    const isLocked = isFormLocked({
      close_date: guestListSettings.close_date,
      is_locked: guestListSettings.is_locked
    });
    const isEnabled = guestListSettings.is_enabled !== false;

    const allGroupIds = this.getAllGroupIds();
    return (
      <View
        {...{
          itemTypeIds: [
            CREDENTIAL_TYPE_ID,
            eventDetails.modules.catering.enabled ? MEAL_TYPE_ID : undefined
          ].filter(Boolean),
          breadcrumbs: "passes / manage / allocations",
          title: this.props.guestList.name,
          onSelectAll: () => this.props.toggleAllRows(allGroupIds),
          isAllSelected:
            this.props.selectedRows.length > 0 &&
            this.props.selectedRows.length === allGroupIds.length,
          countOfSelected: this.props.selectedRows.length,
          onAddGroup: cando(`${STANDARD_MODULES.accounts.id}_accounts_add`)
            ? this.showAddRecordModal
            : undefined,
          onSendLinks: () =>
            this.showSendEmailLinksModal(this.props.selectedRows),
          onSendEmails: () =>
            this.showSendEmailModal({ accountIds: this.props.selectedRows }),
          onInviteGroups: this.showAddRecordModal,
          onImportGroups: this.showAddRecordModal,
          recordTypeId: params.recordTypeId,
          tableRows,
          loading,
          filterText: getFilterText(filterType),
          onSearch: this.search,
          searchTerm,
          tabs: [
            ["All Groups", () => setFilterType(undefined), !filterType],
            [
              `No Allocations${
                countOfNoAllocations ? ` (${countOfNoAllocations})` : ""
              }`,
              () => setFilterType("no-allocations"),
              filterType === "no-allocations"
            ],
            [
              `No Primary Contacts${
                countOfNoPrimaryContacts ? ` (${countOfNoPrimaryContacts})` : ""
              }`,
              () => setFilterType("no-primary-contacts"),
              filterType === "no-primary-contacts"
            ]
          ],
          cards: [
            {
              subtitle: "Guests & Attendees",
              title: `${R.compose(
                R.sum,
                R.map(R.prop("assigneeCount"))
              )(originalListData) || 0}`,
              actions: [
                {},
                {}
                /*
                // @NOTE: Hiding due to using Issue view now for checkin
                cando(`${STANDARD_MODULES.credentials.id}_checkin`)
                  ? {
                      onClick: () => this.gotoCheckin(params.recordTypeId),
                      color: "altB5",
                      label: "Check in"
                    }
                  : {}
                */
              ]
            },
            {
              title: String(originalListData.length),
              subtitle: "Groups",
              actions: [
                {},
                {
                  onClick: this.gotoAccounts,
                  // color: "primary5",
                  label: "Manage"
                }
              ]
            },
            {
              title: `${R.compose(
                ({ assigned, allocated }) =>
                  allocated > 0 ? Math.round((assigned / allocated) * 100) : 0,
                R.reduce(
                  (
                    { allocated, assigned },
                    { assignedCount, allocatedCount }
                  ) => ({
                    allocated: allocated + allocatedCount,
                    assigned: assigned + assignedCount
                  }),
                  { allocated: 0, assigned: 0 }
                )
              )(originalListData)}%`,
              subtitle: "Allocation Usage",
              actions: [
                {},
                {
                  onClick: this.showSendEmailModalToEveryone,
                  label: "Send Email"
                }
              ]
            },
            {
              title: !isEnabled ? (
                <Div
                  disply="row.center.center"
                  bg="red2"
                  color="red9"
                  bra={1}
                  py={1}
                  px={2}
                  fw={3}
                  fs={1}
                  uppercase
                  display="inline"
                >
                  <LockIcon color="red9" size={11} mr={1} />
                  Not Enabled
                </Div>
              ) : isLocked ? (
                <Div
                  disply="row.center.center"
                  bg="red2"
                  color="red9"
                  bra={1}
                  py={1}
                  px={2}
                  fw={3}
                  fs={1}
                  uppercase
                  display="inline"
                >
                  <LockIcon color="red9" size={11} mr={1} />
                  Closed
                </Div>
              ) : (
                <Div
                  bg="teal5"
                  color="white"
                  bra={1}
                  py={1}
                  px={2}
                  fw={3}
                  fs={1}
                  uppercase
                  display="inline"
                >
                  Open
                </Div>
              ),
              subtitle: !isEnabled ? (
                <Div mt={3}>Guest Lists are not enabled</Div>
              ) : isLocked ? (
                <Div mt={3}>Locked to changes</Div>
              ) : closeDate ? (
                <Div mt={3} display="row.flex-start.center">
                  <ClockIcon color="gray5" size={12} mr={1} /> Closes{" "}
                  {moment(new Date(closeDate)).fromNow()}
                </Div>
              ) : (
                <Div mt={3}>Accepting names & changes</Div>
              ),
              actions: [
                {},
                {
                  onClick: this.showSettingsModal,
                  // color: "primary5",
                  label: "Settings"
                }
              ]
            }
          ]
        }}
      />
    );
  }
}

const getFilterText = type => {
  if (type === "no-allocations") return "No groups without allocations";
  if (type === "no-primary-contacts") {
    return "No groups without primary contacts";
  }
  return undefined;
};

export default R.compose(
  withState("filterType", "setFilter"),
  withState("searchTerm", "setSearchTerm", ""),
  withState("loading", "setLoading", true),
  withState("selectedRows", "setSelectedRows", []),
  withHandlers({
    setFilterType: ({
      filterType,
      setSelectedRows,
      setFilter
    }) => newFilterType => {
      if (filterType !== newFilterType) {
        setSelectedRows([]);
        setFilter(newFilterType);
      }
    },
    toggleRow: ({ setSelectedRows, selectedRows }) => id => {
      if (selectedRows.includes(id)) {
        return setSelectedRows(R.without([id], selectedRows));
      }
      return setSelectedRows([...selectedRows, id]);
    },
    toggleAllRows: ({ setSelectedRows, selectedRows }) => ids => {
      if (selectedRows.length === ids.length) {
        return setSelectedRows([]);
      }
      return setSelectedRows(ids);
    }
  }),
  mapProps(({ listData, filterType, ...rest }) => {
    const noAllocations = listData.filter(v => v.allocatedCount === 0);
    const noPrimaryContacts = listData.filter(v => !v.primaryContacts.length);
    let rows = listData;

    switch (filterType) {
      case "no-allocations":
        rows = noAllocations;
        break;
      case "no-primary-contacts":
        rows = noPrimaryContacts;
        break;
      default:
        rows = listData;
    }

    return {
      listData: rows,
      countOfNoAllocations: noAllocations.length,
      countOfNoPrimaryContacts: noPrimaryContacts.length,
      filterType,
      originalListData: listData,
      counts: {
        "no-allocations": 0
      },
      ...rest
    };
  })
)(GuestListsController);
