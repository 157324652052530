import { get } from "lodash";
export const emptyRecordTypes = [];

export const isFetching = state => state.modules.recordTypes.fetching;
export const recordTypes = (state, moduleId) =>
  get(state, `modules.recordTypes.recordTypes[${moduleId}]`, emptyRecordTypes);
export const allEventRecordTypes = (state, moduleId) =>
  get(
    state,
    `modules.recordTypes.allEventRecordTypes[${moduleId}]`,
    emptyRecordTypes
  );
export const recordType = (state, moduleId, recordTypeId) =>
  get(
    state,
    `modules.recordTypes.recordTypes[${moduleId}]`,
    emptyRecordTypes
  ).find(l => l.id === recordTypeId) || {};
