import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import Tooltip from "components/Global/Tooltip";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { showModal, hideModal } from "redux/modules/modal/actions";

import { getValue, getSettings } from "../../utils";
import { actions } from "../../model";

import styleSpects from "components/Base/Base/styleSpecs";

const joinItems = R.compose(
  R.join(", "),
  R.take(5),
  R.map(i => `${i.name}`.trim())
);

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class PeopleBlock extends Component {
  showBulkEditModal = () => {
    const moduleId = getSettings(this.props, "moduleId");
    const eventId = getSettings(this.props, "eventId");
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={
            moduleId === STANDARD_MODULE_IDS.accounts.id
              ? {
                  eventId,
                  orderAccountId: this.props.data.id,
                  orderType: "group"
                }
              : {
                  eventId,
                  orderContactId: this.props.data.id,
                  orderType: "individual"
                }
          }
          onDone={this.props.onDone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const people = getValue(this.props, "people", []);
    const count = getValue(this.props, "count", 0);

    const joined = joinItems(people);
    const tooltip =
      R.length(people) > 5
        ? `${joined} and ${R.length(people) - 5} other ${
            R.length(people) - 5 !== 1 ? "people" : "person"
          }`
        : joined;

    return R.length(people) === 1 ? (
      <div
        // onClick={this.showBulkEditModal}
        // className="underline-on-hover"
        title={tooltip}
        style={{
          fontSize: styleSpects.fontSize[2],
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {tooltip}
      </div>
    ) : count ? (
      <Tooltip tooltip={tooltip} placement="bottom">
        <div
          // onClick={this.showBulkEditModal}
          // className="underline-on-hover"
          style={{ fontSize: styleSpects.fontSize[2] }}
        >
          {count} {count !== 1 ? "people" : "person"}
        </div>
      </Tooltip>
    ) : null;

    /*(
      <div
        onClick={this.showBulkEditModal}
        className="underline-on-hover"
        style={{
          color: styleSpects.color.purple8,
          fontWeight: styleSpects.fontWeight[3],
          fontSize: styleSpects.fontSize[3],
          cursor: "pointer"
        }}
      >
        + Request
      </div>
    );*/
  }
}

export default decorate(PeopleBlock);
