import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, { accountId, eventId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/account/event/${eventId}/reports/assets`,
        data: {},
        credentials,
        qs: {
          accountId
        },
        success: result => {
          resolve(result.result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
