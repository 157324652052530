import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

class SubmissionNotFound extends Component {
  render() {
    return <div styleName="container">This submission has been deleted.</div>;
  }
}
export default CSSModules(SubmissionNotFound, css);
