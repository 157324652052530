import React from "react";
import * as R from "ramda";
import {
  take,
  takeEvery,
  call,
  select,
  put,
  all,
  fork
} from "redux-saga/effects";
import uuid from "node-uuid";
import { BEGIN, COMMIT } from "redux-optimist";

import * as snackbarActions from "redux/modules/snackbar/actions";
import { actions, getters } from "./model";
import { showModal } from "redux/modules/modal/actions";

import { getEditingField, getColumnById, getRowById } from "./selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getPathname } from "App/Router/selectors";

import { makeFuture } from "utils/General/sagas";

import { COLUMN_ACTIONS } from "./constants";

import AddEditFieldModal from "Modules/AddEditColumnModal/View";

import { actions as FieldTypesActions } from "ui-kit/FieldTypes/model";
import { getResult } from "ui-kit/FieldTypes/utils";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const saveValue = function*({ payload: { column, row, value }, meta = {} }) {
  const transactionId = yield call([uuid, uuid.v4]);

  yield put(
    actions.save(
      { value, column, row },
      {
        meta,
        optimist: { type: BEGIN, id: transactionId }
      }
    )
  );
};

const edit = function*() {
  for (;;) {
    const { payload: { cancel, clear } = {}, meta = {} } = yield take(
      actions.saveRequest.type
    );
    if (!cancel) {
      const instanceId = meta.instanceId;

      const { columnId, rowId } = yield select(getters.editing, { instanceId });

      const column = yield select(getColumnById, { id: columnId, instanceId });
      const row = yield select(getRowById, { id: rowId, instanceId });

      const field = yield select(getEditingField, {
        fieldId: columnId,
        instanceId
      });

      const value = clear
        ? null
        : getResult({ field: column, data: row, newField: field });

      yield call(saveValue, {
        payload: {
          column,
          row,
          value
        },
        meta
      });
    }
  }
};

// edit columns
const hideColumn = function*({ payload: { column }, meta }) {
  yield put(
    actions.removeColumn(column, {
      meta
    })
  );
};

// @NOTE: LP-4047 refactor remove getting the module id from the table
const getModuleId = function*() {
  const path = yield select(getPathname);
  const moduleId = R.path(
    [2],
    path.match(/(schedule|schedules|module|manage|custom)\/([a-z\d-]+)\/?/i)
  );

  return path.includes("/attendees")
    ? STANDARD_MODULE_IDS.contacts.id
    : moduleId === "master"
    ? STANDARD_MODULE_IDS.schedules.id
    : moduleId;
};

const showAddFieldModal = function*({ eventId, moduleId }) {
  const newField = makeFuture();
  yield put(
    showModal({
      content: (
        <AddEditFieldModal
          eventId={eventId}
          moduleId={moduleId}
          onSave={newField.done}
        />
      )
    })
  );
  return yield call(newField.onRealized);
};

const insertColumn = function*({ payload: { column, actionId }, meta }) {
  const eventId = yield select(getEventId);
  const moduleId = yield call(getModuleId);

  const columnIndex = yield select(getters.columnIndex, {
    instanceId: meta.instanceId
  });
  const delta = actionId === COLUMN_ACTIONS.INSERT_RIGHT ? +1 : 0;
  const position = R.indexOf(column.id, columnIndex) + delta;
  const newField = yield call(showAddFieldModal, { eventId, moduleId });

  const transactionId = yield call([uuid, uuid.v4]);
  yield put(
    actions.addColumn(
      { column: newField, position },
      { meta, optimist: { type: BEGIN, id: transactionId } }
    )
  );
  const visibleFields = yield select(getters.columnIndex, {
    instanceId: meta.instanceId
  });

  try {
    yield put(
      actions.updateVisibleFields({
        visibleFields,
        fieldOrder: visibleFields.reduce(
          (order, { id }, idx) => ({
            ...order,
            [id]: idx
          }),
          {}
        )
      })
    );

    yield all([
      put(
        actions.addColumnResponse(null, {
          meta,
          optimist: { type: COMMIT, id: transactionId }
        })
      ),
      put(
        snackbarActions.showSnackbar({
          message: "Field Added",
          action: "OK"
        })
      )
    ]);
  } catch (e) {
    yield put(
      actions.addColumnResponse(null, {
        meta,
        optimist: { type: COMMIT, id: transactionId }
      })
    );
  }
};

const showEditFieldModal = function*({ eventId, moduleId, column }) {
  const waitOnSave = makeFuture();
  yield put(
    showModal({
      content: (
        <AddEditFieldModal
          eventId={eventId}
          moduleId={moduleId}
          fieldId={column.id}
          onSave={waitOnSave.done}
        />
      )
    })
  );
  return yield call(waitOnSave.onRealized);
};

const editColumn = function*({ payload: { column }, meta }) {
  const eventId = yield select(getEventId);
  const moduleId = yield call(getModuleId);

  const newColumn = yield call(showEditFieldModal, {
    eventId,
    moduleId,
    column
  });

  yield all([
    put(
      actions.updateColumn({ ...newColumn, id: newColumn.columnId }, { meta })
    ),
    put(
      snackbarActions.showSnackbar({
        message: "Field Updated",
        action: "OK"
      })
    )
  ]);
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [COLUMN_ACTIONS.EDIT]: editColumn,
      [COLUMN_ACTIONS.INSERT_RIGHT]: insertColumn,
      [COLUMN_ACTIONS.INSERT_LEFT]: insertColumn,
      [COLUMN_ACTIONS.HIDE]: hideColumn
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchSaveValue = function*() {
  yield takeEvery(actions.saveValue.type, saveValue);
};

const delegateOnDataRefresh = function*() {
  for (;;) {
    const action = yield take(FieldTypesActions.updateData.type);
    yield put(actions.refreshRecords(action.payload, { meta: action.meta }));
  }
};

const delegateOnExecuteAction = function*() {
  for (;;) {
    const action = yield take(FieldTypesActions.executeAction.type);
    yield put(actions.executeAction(action.payload, { meta: action.meta }));
  }
};
const rootSaga = function*() {
  yield all([
    fork(edit),
    fork(watchSaveValue),
    fork(watchTableActions),
    fork(delegateOnDataRefresh),
    fork(delegateOnExecuteAction)
  ]);
};

export default rootSaga;
