import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/AddQuestionSetModal";

import { HEIGHTS } from "Items/AddQuestionSetModal/constants";

import { Div, Text2, BigOutlineInput } from "components/Base";

const bodyHeight = HEIGHTS.BODY - (HEIGHTS.HEADER + HEIGHTS.FOOTER);

const decorate = connect(
  state => ({
    name: getters.name(state)
  }),
  {
    setName: actions.setName
  }
);

const ItemQuestionBody = ({ name, setName }) => (
  <Div width={1} height={bodyHeight} p={6}>
    <Div width={1}>
      <Text2 color="black" mb={1}>
        Question Set Name
      </Text2>
      <BigOutlineInput
        width={1}
        value={name}
        onChange={value => setName(value)}
        continuous
      />
    </Div>
  </Div>
);

export default decorate(ItemQuestionBody);
