import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";
import AccountTypeIcon from "components/Global/CRM/AccountTypeIcon";
import UserTypeIcon from "components/PortalV2/UserTypeIcon";

const Account = CSSModules(
  ({ name, type, icon, onClick }) => (
    <div styleName="account" onClick={onClick}>
      {icon}
      <div styleName="body">
        <div styleName="title">{name}</div>
        <div styleName="subTitle">{type}</div>
      </div>
      <i className="material-icons arrow">keyboard_arrow_right</i>
    </div>
  ),
  css
);

class SwitchAccounts extends Component {
  updateActiveView(activeViewId) {
    this.props
      .updatePortalUser({
        eventId: this.props.portal.event.id,
        userId: this.props.portalUser.user_id,
        activeView: activeViewId
      })
      .then(() => {
        window.location = `/portals/${this.props.portal.event.slug}/${this.props.portal.event.uuid}`;
      });
  }

  render() {
    const { portalUser, accounts } = this.props;
    const accountsToShow = accounts.filter(
      a => a.id !== portalUser.active_view.id
    );

    if (
      !accountsToShow.length &&
      (this.props.portal.event.enable_individual_portal
        ? portalUser.user_id === portalUser.active_view.id
        : true)
    ) {
      return null;
    }

    return (
      <div>
        <div styleName="label">Switch accounts</div>

        {this.props.portal.event.enable_individual_portal &&
        portalUser.user_id !== portalUser.active_view.id ? (
          <Account
            name={`${portalUser.user_fname} ${portalUser.user_lname}`}
            type="Portal User"
            icon={
              <UserTypeIcon
                color="#686868"
                name={`${portalUser.user_fname} ${portalUser.user_lname}`}
                size={39}
                fontSize={26}
              />
            }
            onClick={() => this.updateActiveView(portalUser.user_id)}
          />
        ) : null}

        {accountsToShow.map(account => (
          <Account
            key={account.id}
            name={get(account, "name")}
            type={get(account, "type.name") || "Account"}
            icon={
              <AccountTypeIcon
                name={get(account, "name")}
                color="#4c4c4c"
                backgroundColor="#686868"
                icon={get(account, "type.icon")}
                size={39}
                fontSize={26}
              />
            }
            onClick={() => this.updateActiveView(account.id)}
          />
        ))}
      </div>
    );
  }
}

SwitchAccounts.defaultProps = {
  accounts: []
};

export default CSSModules(SwitchAccounts, css);
