import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import ModuleRecords from "components/Event/Module/Records";
import ListView from "components/Global/CRM/TableViews/ListView";
import getColumns from "components/Global/CRM/TableViews/utils/get-fields";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import AssetModal from "../Modals/AssetReport";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { browserHistory } from "react-router";
import resolveViewPath from "components/Event/Module/utils/resolve-view-path";
import { debounce } from "lodash";

import { isPassesRoute } from "components/Event/Module/utils";

@CSSModules(css)
class AccountsReports extends ModuleRecords {
  redirected = false;

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.context.moduleId !== this.props.context.moduleId ||
      nextProps.context.viewId !== this.props.context.viewId
    ) {
      // handle: module change
      this.fetchModule(nextProps);
      this.clearSearch();
    } else if (
      !nextProps.context.viewId &&
      nextProps.activeView.id &&
      !this.redirected &&
      !isPassesRoute(nextProps)
    ) {
      // handle: module landing with no view selected
      this.redirected = true;
      browserHistory.replace(
        `${this.baseUrl(this.props.context)}${resolveViewPath({
          views: nextProps.views,
          viewId: nextProps.activeView.id,
          search: this.props.location.search,
          groupId: this.props.params.groupId
        })}`
      );
      this.props.deselectAllRows();
    } else if (this.props.context.viewId !== nextProps.context.viewId) {
      // handle: view change
    }
  }

  fetchRecords = (props = this.props) => {
    this.props.getReport({
      eventId: props.context.eventId,
      reportId: props.context.viewId,
      options: {
        accountRecordTypeId: props.context.groupId
      }
    });
  };

  fetchModule = (props = this.props) =>
    Promise.all([
      this.props.deselectAllRows(),
      this.props.getModule({
        moduleId: props.context.moduleId,
        options: {
          eventId: props.context.eventId
        }
      }),
      this.props.getViews({
        moduleId: props.context.moduleId,
        options: {
          eventId: props.context.eventId,
          accountRecordTypeId: props.context.groupId
        }
      })
    ]).then(() =>
      Promise.all([
        this.fetchRecords(props),
        this.props.getRecordTypes({
          moduleId: props.context.moduleId,
          options: {
            eventId: props.context.eventId
          }
        })
      ])
    );

  showAssetReportModal = ({ column, row }) =>
    this.props.showModal({
      content: <AssetModal categoryId={column.id} accountId={row.id} />,
      wrapper: ModalWrapper
    });

  reportColumnDefinition = column => {
    switch (column.type) {
      case "catalog-item":
        column.onClick = this.showAssetReportModal;
        break;
      default:
    }
    return this.columnDefinition(column);
  };

  getColumns = (columns = []) =>
    columns.map(column => {
      column.columns = getColumns(
        column.columns.map(this.reportColumnDefinition),
        {
          isFieldVisible: this.isFieldVisible,
          getFieldOrder: this.getFieldOrder,
          getFieldWidth: this.getFieldWidth,
          meta: {
            orgId: this.props.context.orgId,
            eventId: this.props.context.eventId,
            moduleId: this.props.context.moduleId,
            helpers: {
              showModal: this.props.showModal,
              router: this.props.router,
              hideModal: this.onHideModal
            }
          }
        }
      );
      return column;
    });

  exportData = contentType => {
    const { fields, exportSheet } = this.props;
    const records = this.getRows();
    const columns = flattenFields(this.getColumns(fields));
    return exportSheet(contentType, "accounts", records, columns);
  };

  renderListView(rows) {
    const { fields, references, isFetchingRecords } = this.props;

    const availableCatalogItems = [].concat(
      ...flattenFields(fields)
        .filter(field => field.type === "catalog-item")
        .map(field => field.settings.availableCatalogItems)
    );

    return (
      <ListView
        fields={this.getColumns(fields)}
        loading={isFetchingRecords}
        moduleId={STANDARD_MODULE_IDS.accounts.id}
        noDataText=""
        plural="Groups"
        records={rows}
        references={{ ...references, availableCatalogItems }}
        singular="Group"
        onColumnResize={debounce(this.onColumnResize, 500, { leading: false })}
      />
    );
  }
}

AccountsReports.propTypes = {
  exportSheet: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isFetchingRecords: PropTypes.bool.isRequired
};

export default AccountsReports;
