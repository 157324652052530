import * as R from "ramda";
import Layout from "./Layout";
import { actions } from "../model";
import { createContext } from "redux-mvc";
import module from "Portal/ViewPortalAsModal";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization", "portal"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          moduleId: this.props.moduleId,
          recordTypeId: this.props.recordTypeId
        })
      );
    }
  },
  handlers: {}
});

export default decorate(Layout);
