import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { TAB_IDS } from "./constants";

import { getCurrentRoute } from "./selectors";
import PageTitle from "EventLight/Common/PageTitle";
import SubNav from "./SubNav";

import EmailTemplates from "./EmailTemplates/View";
import DocumentTemplates from "./DocumentTemplates/View";
import ApprovalWorkflows from "./ApprovalWorkflows/View";
import InvoiceSettings from "./InvoiceSettings/View";
import Webhooks from "./Webhooks/View";

import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Pages = {
  [TAB_IDS.EMAIL_TEMPLATES]: EmailTemplates,
  [TAB_IDS.DOCUMENT_TEMPLATES]: DocumentTemplates,
  [TAB_IDS.APPROVAL_WORKFLOWS]: ApprovalWorkflows,
  [TAB_IDS.INVOICE_SETTINGS]: InvoiceSettings,
  [TAB_IDS.WEBHOOKS]: Webhooks
};

const Wrapper = ({ currentRoute = "" }) => {
  return (
    <Div
      display="column.flex-start.stretch"
      height={1}
      style={{ maxHeight: "100%" }}
    >
      <PageTitle titles={["Settings"]} />
      <SubNav selectedView={currentRoute} />
      {React.createElement(R.propOr("div", currentRoute, Pages))}
    </Div>
  );
};

export default decorate(Wrapper);
