import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import AddModal from "./AddModal";
import { BigFilledButton } from "components/Base";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    showAddWebhookModal: getters.showAddWebhookModal(state)
  }),
  {
    init: actions.init,
    openWebhookModal: () => actions.openWebhookModal(true)
  }
);

const Layout = ({ openWebhookModal, init, showAddWebhookModal, loading }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {showAddWebhookModal ? <AddModal /> : null}
      <Page>
        <Title
          icon="outbound"
          title="Webhooks"
          description="Add Webhooks to receive notifications when data is created, updated or deleted"
          buttons={
            <BigFilledButton bg="altB5" ml={2} onClick={openWebhookModal}>
              Add Webhook
            </BigFilledButton>
          }
        />

        {loading ? <Loading /> : <Body />}
      </Page>
    </>
  );
};

export default decorate(Layout);
