import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";

import { CARD_ACTIONS } from "../constants";

import { ActionItem } from "PortalHub/ActionComponents/View";
import { Text3 } from "components/Base";

import styles from "./styles.scss";

const personButtons = [
  { label: "Report", id: CARD_ACTIONS.VIEW_PEOPLE_REPORT, icon: "filter" },
  { label: "Manage", id: CARD_ACTIONS.MANAGE_PEOPLE, primary: true }
];

const decorate = CSSModules(styles);

const PeopleType = ({ task = {} }) => (
  <ActionItem
    title={task.name}
    caption={`${task.added} people added • ${task.submitted} requests submitted`}
    icon="groups"
    expandable
    buttons={personButtons}
    id={task.id}
    data={task}
  >
    <div styleName="people-card">
      {task.description ? (
        <>
          <Text3 bold>Description: </Text3>
          <div dangerouslySetInnerHTML={{ __html: task.description }} />
        </>
      ) : null}
      {task.due_dates && !R.isEmpty(task.due_dates) ? (
        <>
          <Text3 bold>Due Dates: </Text3>
          <div>
            {R.prop("due_dates", task).map((dateLabel, index) => (
              <Text3 key={index} mb={2}>
                {dateLabel}
              </Text3>
            ))}
          </div>
        </>
      ) : null}
    </div>
  </ActionItem>
);

export default decorate(PeopleType);
