import { createSelector } from "reselect";
import * as R from "ramda";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { TAB_IDS } from "./constants";

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

const getRouteNames = createSelector(
  (_, props) => props.routes,
  R.map(R.propOr("", "name"))
);

const mapTabId = {
  EventLightCateringDashboard: TAB_IDS.DASHBOARD,
  EventLightCateringRequests: TAB_IDS.REQUESTS,
  EventLightCateringSettingsMeals: TAB_IDS.MEALS,
  EventLightCateringSettingsOverride: TAB_IDS.OVERRIDE,
  EventLightCateringSettingsApprovers: TAB_IDS.APPROVERS,
  EventLightCateringSendEmails: TAB_IDS.SEND_EMAILS
};

export const getCurrentRoute = createSelector(
  getRouteNames,
  R.reduce((id, route) => {
    const tabId = R.prop(route, mapTabId);
    if (tabId) {
      return R.reduced(tabId);
    }
    return id;
  }, "")
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  getEventDetails,
  getCurrentRoute,
  (routes, params, eventDetails, currentRoute) => {
    if (
      R.contains(currentRoute, [
        TAB_IDS.MEALS,
        TAB_IDS.APPROVERS,
        TAB_IDS.OVERRIDE
      ])
    ) {
      return [
        {
          key: "meals",
          label: "Meals",
          active: isActiveRoute(routes, "EventLightCateringSettingsMeals"),
          to: `/event-light/${eventDetails.id}/catering/settings`
        },
        {
          key: "approvers",
          label: "Approvers",
          active: isActiveRoute(routes, "EventLightCateringSettingsApprovers"),
          to: `/event-light/${eventDetails.id}/catering/settings/approvers`
        },
        {
          key: "override",
          label: "Manager Override Code",
          active: isActiveRoute(routes, "EventLightCateringSettingsOverride"),
          to: `/event-light/${eventDetails.id}/catering/settings/override`
        }
      ];
    }

    if ([TAB_IDS.REQUESTS].includes(currentRoute)) {
      return [
        {
          key: "all-requests",
          label: "All Requests",
          active:
            isActiveRoute(routes, "EventLightCateringRequests") &&
            params.viewId === "all-requests",
          to: `/event-light/${eventDetails.id}/catering/requests/all-requests`
        },
        {
          key: "my-approvals",
          label: "My Approvals",
          active:
            isActiveRoute(routes, "EventLightCateringRequests") &&
            params.viewId === "my-approvals",
          to: `/event-light/${eventDetails.id}/catering/requests/my-approvals`
        },
        {
          key: "issue",
          label: "Issue & Fulfill",
          active:
            isActiveRoute(routes, "EventLightCateringRequests") &&
            params.viewId === "issue",
          to: `/event-light/${eventDetails.id}/catering/requests/issue`
        }
      ];
    }

    return [];
  }
);
