import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";
import { getters } from "Modules/CloneModuleModal";
import { PAGES } from "./constants";

const getContentTypeName = id =>
  R.prop(id)({
    users: "Users",
    forms: "Forms",
    modules: "Modules",
    document_requests: "File Requests"
  });

export const getHeaderTitle = createSelector(
  getters.selectedPage,
  getters.selectedEvent,
  (selectedPage, selectedEvent) => {
    if (selectedPage === PAGES.SELECT_EVENT) {
      return "Choose an event";
    } else if (selectedPage === PAGES.SELECT_CONTENT) {
      return `Select content to clone from: ${R.propOr("", "name")(
        selectedEvent
      )}`;
    } else if (selectedPage === PAGES.CONFIRMATION) {
      return `Cloning Event: ${R.propOr("", "name")(selectedEvent)}`;
    }
  }
);

export const getEventsGroupedByYear = createSelector(
  getters.isLenndAdmin,
  getters.organization,
  getters.allUserEvents,
  getters.eventsFilter,
  (isLenndAdmin, organization, allUserEvents, eventsFilter) =>
    R.compose(
      R.sortBy(item => -item.year),
      R.reduce((accumulator, current) => {
        const year = moment(current.date_from).format("YYYY");
        const yearObjectIndex = R.findIndex(R.propEq("year", year))(
          accumulator
        );
        if (yearObjectIndex === -1) {
          return [
            ...accumulator,
            {
              year,
              list: [current]
            }
          ];
        }
        return [
          ...accumulator.slice(0, yearObjectIndex),
          {
            ...accumulator[yearObjectIndex],
            list: [...accumulator[yearObjectIndex].list, current]
          },
          ...accumulator.slice(yearObjectIndex + 1)
        ];
      }, []),
      R.sortBy(event => moment(event.date_from).format("YYYY-MM-DD")),
      R.filter(event => {
        const isNotDraft = isLenndAdmin
          ? R.propEq("is_draft", false)(event)
          : R.and(
              R.propEq("org_id", organization.id)(event),
              R.propEq("is_draft", false)(event)
            );
        if (R.isEmpty(eventsFilter)) {
          return isNotDraft;
        }
        return (
          isNotDraft &&
          R.contains(R.toLower(eventsFilter), R.toLower(event.name))
        );
      })
    )(allUserEvents)
);

export const getEventOptions = createSelector(
  getters.eventOptions,
  getters.excludedContent,
  getters.includeAllRecords,
  (eventOptions, excludedContent, includeAllRecords) => {
    const allExcludedContent = R.compose(
      R.flatten,
      R.map(key => excludedContent[key]),
      R.keys
    )(excludedContent);

    return R.compose(
      contentMap =>
        R.reduce((result, key) => {
          result.push({
            id: key,
            name: getContentTypeName(key),
            rows: contentMap[key],
            selected: R.compose(
              R.all(R.equals(true)),
              R.flatten,
              R.map(item => {
                return [
                  item.selected,
                  R.map(subitem => subitem.selected)(item.related)
                ];
              })
            )(contentMap[key]),
            countOfSelected: R.compose(
              R.length,
              R.filter(R.prop("selected"))
            )(contentMap[key])
          });

          return result;
        }, [])(R.keys(contentMap)),
      R.reduce((result, key) => {
        result[key] = R.map(item => ({
          ...item,
          selected: allExcludedContent.includes(item.id),
          includeAllRecords: includeAllRecords.includes(item.id),
          related: R.map(subitem => ({
            ...subitem,
            selected: allExcludedContent.includes(subitem.id)
          }))(item.related)
        }))(eventOptions[key]);

        return result;
      }, {}),
      R.keys
    )(eventOptions);
  }
);
