import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";
import { withRouter } from "react-router";

import { eventDetails } from "redux/modules/event/selectors";
import { getItem } from "redux/modules/items/items/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { getZoneGroups } from "redux/modules/items/zone-groups/actions";
import {
  addVariant,
  updateVariant,
  deleteVariant,
  bulkUpdateVariants
} from "redux/modules/items/item-variants/actions";

import { groups } from "redux/modules/items/item-groups/selectors";
import { item } from "redux/modules/items/items/selectors";
import { getEnabledFeatures, getItemName } from "./constants";
import { groups as zoneGroups } from "redux/modules/items/zone-groups/selectors";

function mapStateToProps(state, props) {
  const enabledFeatures = getEnabledFeatures({
    typeId: props.typeId
  });
  const itemName = getItemName(props.typeId);
  return {
    mode: props.itemId ? (props.clone ? "clone" : "update") : "create",
    item: props.itemId ? item(state, props.itemId) : null,
    eventDetails: eventDetails(state),
    groups: groups(state),
    zoneGroups: zoneGroups(state),
    enabledFeatures,
    itemName
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addVariant,
      getItem,
      getItemGroupsByEventAndType,
      getZoneGroups,
      updateVariant,
      deleteVariant,
      bulkUpdateVariants
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(External)
);
