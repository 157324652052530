import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ShowSidebar = ({
  sidebarIsActive,
  hideSidebar,
  showSidebar,
  moduleId,
  ...props
}) => {
  let button = (
    <div styleName="hide" onClick={() => showSidebar(moduleId)} {...props}>
      <i className="material-icons">keyboard_arrow_right</i>
    </div>
  );
  if (sidebarIsActive) {
    button = (
      <div styleName="hide" onClick={() => hideSidebar(moduleId)} {...props}>
        <i className="material-icons">keyboard_arrow_left</i>
      </div>
    );
  }
  return <div styleName="wrapper">{button}</div>;
};

export default CSSModules(ShowSidebar, css);
