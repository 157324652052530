import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getOrganizationPermissionGroups } from "redux/modules/permissions/permission-groups/actions";
import {
  getOrganizationPermissionProfiles,
  addOrganizationPermissionProfile,
  updatePermissionProfile,
  updatePermissionProfilePermissions,
  deletePermissionProfile
} from "redux/modules/permissions/permission-profiles/actions";
import { orgDetails } from "redux/modules/organization/selectors";

import { permissionGroups } from "redux/modules/permissions/permission-groups/selectors";
import { permissionProfiles } from "redux/modules/permissions/permission-profiles/selectors";

function mapStateToProps(state) {
  return {
    permissionGroups: permissionGroups(state),
    permissionProfiles: permissionProfiles(state),
    orgDetails: orgDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      getOrganizationPermissionGroups,
      getOrganizationPermissionProfiles,
      addOrganizationPermissionProfile,
      updatePermissionProfile,
      updatePermissionProfilePermissions,
      deletePermissionProfile
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Controller)
);
