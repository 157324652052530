import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  reports: [],
  recordIds: [],
  selectedReport: null,
  selectedFormat: "pdf",
  moduleId: null,
  loading: false,
  processing: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    goToModuleReports: R.identity,
    runReport: R.identity,
    setInitialData: (state, { payload: { reports, recordIds, moduleId } }) => ({
      reports,
      recordIds,
      moduleId,
      selectedReport: null
    })
  }
});

export default handlers;
