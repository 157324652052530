import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "../index";

import {
  Div,
  PopoverMenu,
  AddIcon,
  Text3,
  Text4,
  BigOutlineInput,
  SmallToggle
} from "components/Base";
import { addS } from "utils/General";

import Circle from "ui-kit/Circle";

import RecordTypesPopover from "./RecordTypesPopover";
import SortByPopover from "./SortByPopover";

import Sections from "./Sections";

const decorate = connect(
  state => ({
    template: getters.template(state)
  }),
  {
    updateTemplateName: value =>
      actions.updateTemplateField({ key: "name", value }),
    updateTemplateLandscape: actions.updateTemplateLandscape,
    updateTemplateField: actions.updateTemplateField
  }
);

const Body = ({
  handlers,
  template,
  updateTemplateName,
  updateTemplateLandscape
}) => (
  <Div
    width={1}
    style={{
      padding: "20px 30px 10px 30px",
      maxHeight: 600,
      minHeight: 400,
      overflowY: "auto"
    }}
    bg="neutral0"
  >
    <Div display="row.flex-start.center" mb={1}>
      <Text4 color="black" mr={3}>
        Report Title
      </Text4>
      <BigOutlineInput
        flex={1}
        value={template.name}
        bg="white"
        continuous
        onChange={value => updateTemplateName(value)}
      />
    </Div>

    <Div
      height={44}
      bg="white"
      bra={1}
      ba={2}
      bc="neutral2"
      display="row.space-between.center"
      px={3}
      mb={1}
    >
      <Text3 bold>Filter records</Text3>
      <RecordTypesPopover />
    </Div>

    <Div
      style={{
        minHeight: "44px"
      }}
      bg="white"
      bra={1}
      ba={2}
      bc="neutral2"
      display="row.space-between.center"
      px={3}
      mb={1}
    >
      <Text3 bold>Sort records</Text3>
      <SortByPopover
        {...{
          sortBy: template.sort_by,
          allFields: template.fields
        }}
      />
    </Div>

    <Div
      height={44}
      bg="white"
      bra={1}
      ba={2}
      bc="neutral2"
      display="row.space-between.center"
      px={3}
      mb={1}
    >
      <Text3 bold>Select fields to show</Text3>
      <Div display="row.flex-start.center">
        <Text3
          color="primary7"
          bold
          onClick={() =>
            handlers.showSelectFieldsModal({
              moduleId: template.module_id,
              selectedFieldIds: template.visible_fields
            })
          }
        >
          {template.visible_fields && template.visible_fields.length
            ? `Showing ${template.visible_fields.length} field${addS(
                template.visible_fields.length
              )}`
            : "Showing all fields"}
        </Text3>
      </Div>
    </Div>

    <Div fs={4} fw={3} color="black" mt={3} mb={2}>
      Sections
    </Div>
    <Sections handlers={handlers} />

    <Div display="row.flex-start.flex-start">
      <PopoverMenu
        Label={({ onClick }) => (
          <Div
            display="row.flex-start.center"
            mt={3}
            mb={3}
            width={1}
            onClick={onClick}
          >
            <Circle size={32} bg="primary7">
              <AddIcon size={24} color="white" />
            </Circle>
            <Div color="primary7" fw={4} ml={2}>
              Add Section
            </Div>
          </Div>
        )}
        menuItems={[
          [
            "Related Records",
            () =>
              handlers.showAddRelatedRecordsModal({
                moduleId: template.module_id
              })
          ]
        ]}
      />
    </Div>

    <Div fs={4} fw={3} color="black" mt={3} mb={2}>
      Settings
    </Div>
    <Div
      height={44}
      bg="white"
      bra={1}
      ba={2}
      bc="neutral2"
      display="row.space-between.center"
      px={3}
      mb={1}
    >
      <Text3 bold>Print in landscape format</Text3>
      <Div display="row.flex-start.center">
        <SmallToggle
          active={template.landscape}
          onClick={() => updateTemplateLandscape(!template.landscape)}
        />
      </Div>
    </Div>
  </Div>
);

export default decorate(Body);
