import React from "react";
import { Div, OpenInNewIcon } from "components/Base";
import { connect } from "react-redux";
import * as R from "ramda";
import FileIcon from "components/Atoms/FileIcon";
import { actions } from "../../model";

import { PAGE_ACTIONS } from "../../constants";

const decorator = connect(
  null,
  { executeAction: actions.executeAction }
);

const Title = ({ name }) => (
  <Div
    style={{
      fontSize: 14,
      fontWeight: 600,
      color: "#000000",
      marginBottom: 16,
      textTransform: "uppercase"
    }}
  >
    {name}
  </Div>
);

const Link = ({ mimetype, name, url, executeAction, type }) => (
  <Div
    width={1}
    style={{
      marginBottom: 19,
      cursor: "pointer"
    }}
    onClick={() => executeAction({ type: PAGE_ACTIONS.OPEN_LINK, url })}
  >
    <Div display="row.flex-start.center">
      <Div style={{ marginRight: 8 }} display="row.flex-start.center">
        {type === "link" ? (
          <OpenInNewIcon size={20} />
        ) : (
          <FileIcon size={15} mimetype={mimetype} />
        )}
      </Div>

      <Div style={{ fontSize: 14, fontWeight: 600, color: "#000000" }}>
        {name}
      </Div>
    </Div>
  </Div>
);

export const Links = ({ data: { name, content }, executeAction }) => (
  <Div width={1}>
    <Div
      style={{
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 23,
        color: "#000000"
      }}
    >
      {name}
    </Div>
    <Div>
      {R.map(
        ({ id, name, url, mimetype, type }) =>
          type === "title" ? (
            <Title key={id} name={name} />
          ) : (
            <Link
              key={id}
              name={name}
              url={url}
              mimetype={mimetype}
              executeAction={executeAction}
              type={type}
            />
          ),
        content
      )}
    </Div>
  </Div>
);

export default decorator(Links);
