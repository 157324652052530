import PropTypes from "prop-types";
import React from "react";

const DeleteItemModal = ({ name, handleDelete, hideModal }) => (
  <div>
    <div className="modal-header">
      <div className="modal-title">Delete catalog item?</div>
    </div>

    <div className="modal-body">
      <div className="modal-body-wrapper">
        <div>
          Are you sure you want to delete the item named <strong>{name}</strong>?
          This will affect all requests that have requested or ordered this
          item.
        </div>
        <br />
        <div className="actions-wrapper">
          <a className="action-cancel small clear" onClick={hideModal}>
            Cancel
          </a>
          <span
            className="action-positive small"
            onClick={() => {
              hideModal();
              handleDelete();
            }}
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default DeleteItemModal;
