import React from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const GroupDay = CSSModules(css)(({ name, color, onClick }) => (
  <div
    styleName={onClick ? "containerWithHover" : "container"}
    onClick={onClick}
  >
    <div
      styleName="colorBox"
      style={{
        backgroundColor: color,
        border: color === "#ffffff" ? "1px solid #eee" : `1px solid ${color}`
      }}
    />
    <div styleName="name">{name}</div>
    <div styleName="separator" />
  </div>
));

const MarkDaysAs = ({ groups, selectGroup }) => (
  <div styleName="markDaysAsContainer">
    <div styleName="top">
      <div styleName="title">
        {selectGroup ? "Mark day(s) as..." : "Select one or more days..."}
      </div>
    </div>
    <div styleName="groups">
      {groups.map(group => (
        <GroupDay
          key={group.id}
          id={group.id}
          name={group.name}
          color={group.color}
          onClick={
            selectGroup
              ? () => {
                  selectGroup(group.id);
                }
              : undefined
          }
        />
      ))}
    </div>
  </div>
);

export default CSSModules(css)(MarkDaysAs);
