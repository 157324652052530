export const NAMESPACE = "CreateAlerts";

export const ALERTS_FORM_ID = "AlertFieldsForm";

export const SMS_URL_ICON =
  "https://i1.pngguru.com/preview/494/794/356/ios-7-icons-updated-messages-message-apple-icon-png-clipart.jpg";

export const WIDTH = "600px";

export const INITIAL_ALERT_DATA = {
  alertId: null,
  title: "",
  content: "",
  contentText: "",
  type: "update",
  deliverViaPortal: true,
  deliverViaEmail: false,
  deliverViaSms: false,
  isDraft: false,
  sentAt: null,
  sendAt: null,
  sendAtType: "",
  sendToPortalLoginUsers: false,
  sendToPrimaryContacts: false,
  sendTo: {
    group_types: [],
    user_types: [],
    sessions: [],
    pass_types: []
  }
};

export const SCENES = {
  EDIT: "edit-alert",
  PREVIEW: "preview-alert"
};

export const FIELDS_IDS = {
  MESSAGE: {
    HEADER: "title",
    CONTENT: "content",
    CONTENT_TEXT: "contentText",
    TYPE: "type"
  },
  DELIVERY: {
    PORTAL_NOTIFICATION: "deliverViaPortal",
    EMAIL: "deliverViaEmail",
    SMS: "deliverViaSms",
    SEND_AT: "sendAt",
    SEND_AT_TYPE: "sendAtType"
  },
  SEND_TO: {
    ID: "sendTo",
    TYPE: "sendToSelectedType",
    PORTAL_LOGIN_USERS: "sendToPortalLoginUsers",
    PRIMARY_CONTACTS: "sendToPrimaryContacts"
  }
};

export const ALERT_TYPES = ["update", "warning", "breaking"];

export const SEND_DATE = {
  NOW: "now",
  LATER: "later"
};
