import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";
import getValue from "utils/value-types/get-value";
import Status from "../Status";
import resolveStatus from "Portal/PortalDocuments/utils/status";
import {
  DOCUMENT_REQUESTS,
  DOCUMENT_REQUEST_TYPES
} from "utils/standard-module-field-ids";

const { RECIPIENT_NAME } = DOCUMENT_REQUESTS;

const { NAME, DUE_DATE } = DOCUMENT_REQUEST_TYPES;

const Header = CSSModules(
  ({ record, requestType, hideModal, status }) => (
    <div styleName="header">
      <div>
        <div styleName="name">
          {getValue(get(requestType.values, [NAME]), "text")}
        </div>
        <div styleName="submitter">
          {getValue(get(record.values, [RECIPIENT_NAME]), "text")}
        </div>
        <div styleName="status">
          {resolveStatus({
            dueDate: get(requestType.values, [DUE_DATE]),
            status
          }).map(({ label, icon }) => (
            <Status icon={icon} label={label} />
          ))}
        </div>
      </div>
      <i className="material-icons close" onClick={hideModal}>
        close
      </i>
    </div>
  ),
  css
);

Header.propTypes = {};

export default Header;
