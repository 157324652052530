import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div, RightArrowIcon } from "components/Base";

const Direction = ({ a, b }) => (
  <Div
    display="row.flex-start.center"
    fs={1}
    p={1}
    bra={1}
    style={{
      color: "#c6b8d4",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }}
  >
    {a}
    <RightArrowIcon size={12} mx={1} style={{ color: "#c6b8d4" }} />
    {b}
  </Div>
);

const getDirectionComponent = dir => {
  switch (dir) {
    case "ASC":
      return <Direction a="A" b="Z" />;
    case "DESC":
      return <Direction a="Z" b="A" />;
    default:
      return null;
  }
};

const SortItem = ({ name, direction, removeIcon }) => (
  <div styleName="container">
    <div styleName="title" title={name}>
      {name}
    </div>
    <Div display="row.flex-end.center">
      <Div uppercase fs={1} color="neutral0" mr={2}>
        {getDirectionComponent(direction)}
      </Div>
      {removeIcon}
    </Div>
  </div>
);

SortItem.propTypes = {};

export default CSSModules(SortItem, css);
