import Helpers from "utils/Global/Helpers";

export default {
  getCustomers: ({ credentials, eventId, filter }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/search-customers/event/${eventId}`,
        credentials,
        qs: { q: filter },
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  getSecondaryAccountsOrContacts: ({ credentials, eventId, qs }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/search-customers/event/${eventId}`,
        credentials,
        qs,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
