import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import { isEqual } from "lodash";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ApproversLabel from "ApproversLabel/View";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import Tooltip from "components/Global/Tooltip";

import {
  Div,
  Text2,
  CheckIcon,
  Popover,
  MediumClearButton,
  MoreIcon,
  MenuCard,
  ReplyIcon,
  MenuItem,
  RightIcon,
  BoxGroup,
  DownFilledIcon
} from "components/Base";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

const IssuanceMenu = ({ onCheckin, onUndoCheckin }) => (
  <Div display="row">
    <Popover
      Label={({ onClick }) => (
        <BoxGroup addSpacers display="row.flex-start.center">
          <MediumClearButton
            bg="neutral5"
            color="primary5"
            onClick={onCheckin}
            uppercase
          >
            Pending Pick up
          </MediumClearButton>
          <MediumClearButton onClick={onClick} OnlyIcon={DownFilledIcon} />
        </BoxGroup>
      )}
    >
      {({ closePopover }) => (
        <MenuCard>
          <MenuItem
            onClick={() => {
              closePopover();
              onUndoCheckin();
            }}
            LeftIcon={ReplyIcon}
            RightIcon={RightIcon}
          >
            Undo Issuance
          </MenuItem>
        </MenuCard>
      )}
    </Popover>
  </Div>
);

class OrderActionsFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  print = () => {
    const value = getValue(this.props.value);

    this.props.dependentValues.helpers.printItems({
      lineItemIds: value.recordIds
    });
  };

  undoPrint = () => {
    const value = getValue(this.props.value);

    this.props.dependentValues.helpers.undoPrintItems({
      lineItemIds: value.recordIds
    });
  };

  checkin = () => {
    const value = getValue(this.props.value);

    this.props.dependentValues.helpers.issueItems({
      orderType: value.orderType,
      customerAccountId: value.customerAccountId,
      customerContactId: value.customerContactId,
      recordId: value.recordId,
      variantId: value.variantId,
      fulfillmentId: value.fulfillmentId,
      status: value.issued && "pending-pickup",
      itemName: value.itemName,
      recordName: value.recordName,
      quantity: value.quantity
    });
  };

  undoCheckin = () => {
    const value = getValue(this.props.value);
    this.props.dependentValues.helpers.issueItems({
      orderType: value.orderType,
      customerAccountId: value.customerAccountId,
      customerContactId: value.customerContactId,
      recordId: value.recordId,
      variantId: value.variantId,
      fulfillmentId: value.fulfillmentId,
      status: value.pickedUp
        ? "undo-fulfillment"
        : value.issued
        ? "undo-issuance"
        : undefined,
      itemName: value.itemName,
      recordName: value.recordName,
      quantity: value.quantity
    });
  };

  showBulkEditModal = () => {
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={{ lineItemId: this.props.value.value.recordIds }}
          onDone={() => {
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const value = getValue(this.props.value);

    if (value.mode === "issuance") {
      if (value.isPickedUp) {
        return (
          <Div display="row.flex-start.center" truncate>
            <Tooltip tooltip={value.title}>
              <Div
                style={{
                  maxWidth: "80%"
                }}
                truncate
                display="row.flex-start.center"
              >
                <CheckIcon color="success5" mr={2} />
                <Text2 color="neutral4" truncate>
                  {value.title}
                </Text2>
              </Div>
            </Tooltip>
            {value.enabled ? (
              <Popover
                Label={({ onClick }) => (
                  <MediumClearButton
                    ml={2}
                    OnlyIcon={MoreIcon}
                    onClick={onClick}
                  />
                )}
              >
                {({ closePopover }) => (
                  <MenuCard>
                    <MenuItem
                      LeftIcon={ReplyIcon}
                      RightIcon={RightIcon}
                      onClick={() => {
                        closePopover();
                        this.undoCheckin();
                      }}
                    >
                      Undo Fulfillment
                    </MenuItem>
                  </MenuCard>
                )}
              </Popover>
            ) : null}
          </Div>
        );
      }
      if (value.enabled && value.isIssued) {
        return (
          <IssuanceMenu
            {...{
              onCheckin: this.checkin,
              onUndoCheckin: this.undoCheckin
            }}
          />
        );
      }
      if (!value.enabled) {
        return null;
      }
      if (value.needsPayment) {
        return (
          <Div
            fw={3}
            height={32}
            color="gray7"
            width={1}
            display="row.flex-start.center"
            uppercase
            ml={3}
          >
            Unpaid
          </Div>
        );
      }
      if (value.isPending) {
        return (
          <Div
            fw={3}
            height={32}
            color="gray7"
            width={1}
            display="row.flex-start.center"
            uppercase
            ml={3}
          >
            Pending
          </Div>
        );
      }
      return (
        <MediumClearButton
          bg="neutral5"
          color="primary5"
          onClick={this.checkin}
          uppercase
        >
          Check-in
        </MediumClearButton>
      );
    }

    if (value.mode === "print") {
      if (value.printTitle) {
        return (
          <Div display="row.flex-start.center" truncate>
            <Tooltip tooltip={value.printTitle}>
              <Div
                style={{
                  maxWidth: "80%"
                }}
                truncate
                display="row.flex-start.center"
              >
                <CheckIcon color="success5" mr={2} />
                <Text2 color="neutral4" truncate>
                  {value.printTitle}
                </Text2>
              </Div>
            </Tooltip>
            {value.enabled ? (
              <Popover
                Label={({ onClick }) => (
                  <MediumClearButton
                    ml={2}
                    OnlyIcon={MoreIcon}
                    onClick={onClick}
                  />
                )}
              >
                {({ closePopover }) => (
                  <MenuCard>
                    <MenuItem
                      LeftIcon={ReplyIcon}
                      RightIcon={RightIcon}
                      onClick={() => {
                        closePopover();
                        this.undoPrint();
                      }}
                    >
                      Undo Print
                    </MenuItem>
                  </MenuCard>
                )}
              </Popover>
            ) : null}
          </Div>
        );
      }
      if (!value.enabled) {
        return null;
      }
      if (value.needsPayment) {
        return (
          <Div
            fw={3}
            height={32}
            color="gray7"
            width={1}
            display="row.flex-start.center"
            uppercase
            ml={3}
          >
            Unpaid
          </Div>
        );
      }
      if (value.isPending) {
        return (
          <Div
            fw={3}
            height={32}
            color="gray7"
            width={1}
            display="row.flex-start.center"
            uppercase
            ml={3}
          >
            Pending
          </Div>
        );
      }
      return (
        <MediumClearButton
          bg="neutral5"
          color="primary5"
          onClick={this.print}
          uppercase
        >
          Print
        </MediumClearButton>
      );
    }

    return (
      <ApproversLabel
        {...{
          // data
          instanceId: this.props.instanceId,
          type: value.type,
          status: value.status,
          manifestId: value.manifestId,
          recordIds: value.recordIds,
          // handlers
          handlers: {
            bulkEdit: this.showBulkEditModal
          },
          // style
          leftMenu: true,
          containerProps: {
            display: "row.flex-start.center",
            pl: 2,
            pr: 2
          }
        }}
      />
    );
  }
}

export default decorate(OrderActionsFormatter);
