import { createContext } from "redux-mvc";

import Layout from "./Layout";

import module from "../index";
import { actions } from "../model";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.getCateringDashboardData());
    }
  }
});

export default decorate(Layout);
