import React, { Component } from "react";
import moment from "moment";
import { get, isNil, isEqual } from "lodash";
import getValue from "utils/value-types/get-value/datetime";
import { Div } from "components/Base";

export default class DateTimeFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(props) {
    const DISPLAY_FORMAT = get(
      this.props.dependentValues,
      "meta.columnSettings.format",
      "LL LT"
    );
    const value = getValue(props.value);
    if (value && moment(value).isValid()) {
      if (
        !get(
          this.props,
          "dependentValues.meta.columnSettings.useDateTimeTimezone",
          false
        )
      ) {
        const offset = get(props.value, "value.offset");
        if (!isNil(offset)) {
          return moment(value)
            .utcOffset(offset)
            .format(DISPLAY_FORMAT);
        }
      }
      return moment(value).format(DISPLAY_FORMAT);
    }
    return value;
  }

  render() {
    const value = this.formatValue(this.props);

    return (
      <Div
        truncate
        title={value}
        style={{
          userSelect: "none",
          lineHeight: "34px"
        }}
      >
        {value || null}
      </Div>
    );
  }
}
