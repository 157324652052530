import React from "react";
import PropTypes from "prop-types";
import CanUserDo from "components/Global/Security/CanUserDo";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "../Popover";

import SendEmail from "SendEmailModal/View";
import AssignFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignContactForms";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AssignOwnersModal from "components/Global/Module/Modals/AssignOwners";
import ModuleActionsBar from "../Module/Module";
import AssignEventsModal from "components/Global/CRM/Modals/Assign/AssignEvents";
import AssignContactsToAccountModal from "components/Global/CRM/Modals/AssignContactsToAccount";
import RunReportModal from "Reports/RunReportModal/View";

@CSSModules(css)
class DataTableActionsBar extends ModuleActionsBar {
  sendEmailModal = async () => {
    this.props.showModal({
      content: (
        <SendEmail
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          records={this.props.selectedRows}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignFormsModal = () => {
    const { showModal, selectedRows } = this.props;
    showModal({
      content: (
        <AssignFormsModal
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          recordNameSingular="Person"
          recordNamePlural="People"
          recordIds={selectedRows}
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignOwnersModal = selectedRecords => {
    this.props.showModal({
      content: (
        <AssignOwnersModal
          moduleId={this.props.module.id}
          recordNameSingular="Person"
          recordNamePlural="People"
          recordIds={selectedRecords}
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignEventsModal = () => {
    this.props.showModal({
      content: (
        <AssignEventsModal
          selectedRecords={this.props.selectedRows}
          moduleId={this.props.module.id}
          recordNameSingular="Person"
          recordNamePlural="People"
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignAccountsModal = () => {
    this.props.showModal({
      content: (
        <AssignContactsToAccountModal
          contactIds={this.props.selectedRows}
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showRunReportModal = () => {
    this.props.showModal({
      content: (
        <RunReportModal
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          recordIds={this.props.selectedRows}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { selectedRows, recordTypes, moduleId, deselectAllRows } = this.props;
    const selectedRowsCount = selectedRows.length;
    const pluralize = text => `${text}${selectedRowsCount !== 1 ? "s" : ""}`;
    const isEvent = Boolean(this.props.eventDetails.id);

    return (
      <div styleName="container">
        <div styleName="count">{selectedRowsCount} selected</div>
        {/* TODO WRAP IN ORG PERMISSIONS */}
        {!isEvent ? (
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.showAssignEventsModal}
          >
            Assign Events
          </div>
        ) : (
          ""
        )}
        <CanUserDo action={`${moduleId}_update`} className={css.candoWrapper}>
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.showEditRecordsModal}
          >
            <i className="material-icons">edit</i>
            Edit
          </div>

          {isEvent ? (
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.showRunReportModal}
            >
              <i className="material-icons">show_chart</i>
              Run Report
            </div>
          ) : null}

          {isEvent ? (
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.sendEmailModal}
            >
              Send Email
              {selectedRowsCount !== 1 ? "s" : ""}
            </div>
          ) : null}

          <Popover label="More">
            {({ closeOnClick }) => (
              <Menu>
                {isEvent && recordTypes.length > 1 ? (
                  <MenuItem
                    primaryText={pluralize("Change Type")}
                    onClick={closeOnClick(this.changeRecordTypes)}
                  />
                ) : null}
                <MenuItem
                  primaryText="Assign Account"
                  onClick={closeOnClick(this.showAssignAccountsModal)}
                />
                <MenuItem
                  primaryText={pluralize("Generate Document")}
                  onClick={closeOnClick(this.showGenerateDocumentsModal)}
                />
              </Menu>
            )}
          </Popover>

          <CanUserDo action={`${moduleId}_delete`}>
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.showDeleteRecordsModal}
            >
              <i className="material-icons">delete</i>
              Delete
            </div>
          </CanUserDo>
        </CanUserDo>
        <div
          role="button"
          tabIndex={0}
          styleName="menuTextButton"
          onClick={deselectAllRows}
        >
          Clear selected
        </div>
      </div>
    );
  }
}

DataTableActionsBar.propTypes = {
  deselectAllRows: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  deleteRecords: PropTypes.func.isRequired,
  moduleId: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DataTableActionsBar;
