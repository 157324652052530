import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    orgId: orgDetails(state).id,
    eventId: eventDetails(state).id,
    userCredentials: getCredentials(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRelationship
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
