import { capitalize } from "lodash";

export default (views, newViewType, reportId) => {
  const countOfMatchingViewsByType = reportId
    ? views.filter(v => v.report_id === reportId).length
    : views.filter(v => v.view_type === newViewType).length;
  if (countOfMatchingViewsByType) {
    return `${capitalize(newViewType)} ${countOfMatchingViewsByType + 1}`;
  }
  return capitalize(newViewType);
};
