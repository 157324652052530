import React from "react";
import { connect } from "react-redux";
import { noop } from "utils/General";
import { bindInstance } from "redux-mvc";

import { Div } from "components/Base";

import PageLocation from "components/Global/ReactTable/Pagination/PageLocation";
import MutedPagination from "components/Global/ReactTable/Pagination/Muted/MutedPagination";
import SizeSelector from "components/Global/ReactTable/Pagination/SizeSelector";

import { actions } from "ui-kit/PaginationBar";
import { getPagination } from "ui-kit/PaginationBar/selectors";

const decorate = connect(
  (state, props) => ({
    pagination: getPagination(state, props)
  }),
  bindInstance({
    onPageSizeChange: actions.setPageSize,
    onPageChange: actions.setPage
  })
);

const SlimPaginationBar = ({
  pagination = {},
  pageSizeOptions = [10, 15, 25, 50],
  showPageSizeOptions = true,
  rowsText = "rows",
  onPageSizeChange = noop,
  onPageChange = noop,
  showLabel = true,
  ...styleProps
}) => (
  <Div
    bg="white"
    display="row.space-between.center"
    width={1}
    pt={2}
    {...styleProps}
  >
    {showLabel ? (
      <PageLocation
        pageSize={pagination.pageSize}
        pages={pagination.pages}
        page={pagination.page}
        total={pagination.total}
        rowsText={rowsText}
      />
    ) : (
      ""
    )}
    <MutedPagination
      canPrevious={pagination.page > 0}
      canNext={pagination.page < pagination.pages - 1}
      page={pagination.page}
      pages={pagination.pages}
      onPageChange={onPageChange}
    />
    {showPageSizeOptions ? (
      <SizeSelector
        pageSize={pagination.pageSize}
        rowsText={rowsText}
        onPageSizeChange={onPageSizeChange}
        pageSizeOptions={pageSizeOptions}
      />
    ) : (
      ""
    )}
  </Div>
);

export default decorate(SlimPaginationBar);
