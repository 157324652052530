import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";
import ItemAssetReportModule from "PortalHub/Reports/ItemAssetReport";
import MealsReportModule from "PortalHub/Reports/CateringReport";
import CredentialsReportModule from "PortalHub/Reports/CredentialsReport";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "portal", "modal", "reports", "@flopflip"]
});

module.plugModule(ItemAssetReportModule);
module.plugModule(MealsReportModule);
module.plugModule(CredentialsReportModule);

module.setRootSaga(sagasModule);

export default module;
