import React from "react";
import { connect } from "react-redux";
import { actions } from "Organizations/CloneEventModal/model";
import * as R from "ramda";
import {
  Div,
  Span,
  SuperOutlineButton,
  SuperFilledButton,
  Text1,
  Collapsable,
  collapsableHandler,
  SmallCheckbox,
  RightIcon,
  DownIcon
} from "components/Base";
import { PAGES } from "Organizations/CloneEventModal/constants";
import { getEventOptions } from "Organizations/CloneEventModal/selectors";

const decorate = connect(
  state => ({
    eventOptions: getEventOptions(state)
  }),
  {
    goToCloneOptions: () => actions.setSelectedPage(PAGES.CLONE_OPTIONS),
    cloneEvent: actions.cloneEvent,
    toggleExcludedContent: actions.toggleExcludedContent,
    toggleAllContentType: actions.toggleAllContentType,
    toggleChildren: actions.toggleChildren,
    toggleIncludeAllRecords: actions.toggleIncludeAllRecords
  }
);

const PrimaryHeader = ({
  onClick = () => {},
  collapsed,
  type,
  title,
  headingProps = {},
  selected,
  countOfSelected,
  toggleAllContentType,
  ...props
}) => (
  <Div display="row.flex-start.center" px={2} {...props} onClick={onClick}>
    {collapsed ? (
      <RightIcon color="black" size={20} mr={2} />
    ) : (
      <DownIcon color="black" size={20} mr={2} />
    )}
    <SmallCheckbox
      indeterminate={!selected && countOfSelected > 0}
      onClick={e => {
        e.stopPropagation();
        toggleAllContentType({
          type,
          selected: !selected
        });
      }}
      selected={countOfSelected === 0 ? false : selected}
    />
    <Div flex={1} p={2}>
      <Div fs={3} fw={3} color="gray9" {...headingProps}>
        {title} ({countOfSelected})
      </Div>
    </Div>
  </Div>
);

const CollapsableRow = collapsableHandler(
  ({ collapsed, toggleCollapsed, ...props }) => (
    <Row
      key={props.id}
      collapsable
      collapsed={collapsed}
      toggleCollapsed={toggleCollapsed}
      {...props}
    />
  )
);

const Row = ({
  id,
  type,
  name,
  selected,
  includeAllRecords,
  related,
  toggleExcludedContent,
  collapsable,
  collapsed,
  toggleCollapsed,
  toggleChildren,
  toggleIncludeAllRecords,
  nameProps = {},
  ...props
}) => (
  <Div {...props}>
    <Div
      display="row.flex-start.center"
      bg={{
        default: "white",
        hover: "neutral0"
      }}
      py={1}
      onClick={e => {
        if (related.length) {
          return toggleCollapsed();
        }
        e.stopPropagation();
        toggleExcludedContent({
          type,
          id
        });
      }}
    >
      <Div display="row.flex-start.center" flex={1}>
        {collapsable ? (
          collapsed ? (
            <RightIcon color="black" size={20} mr={2} />
          ) : (
            <DownIcon color="black" size={20} mr={2} />
          )
        ) : null}

        <SmallCheckbox
          onClick={e => {
            if (!collapsable) {
              return true;
            }
            e.stopPropagation();
            toggleChildren({
              type,
              id,
              selected: !selected
            });
          }}
          selected={selected}
          mr={2}
          ml={collapsable ? 0 : 7}
        />
        <Div {...nameProps} color="gray7">
          {name}
        </Div>
      </Div>
      {selected && type === "modules" ? (
        <Div
          display="row.flex-start.center"
          mr={4}
          onClick={e => {
            e.stopPropagation();
            toggleIncludeAllRecords({
              id
            });
          }}
        >
          <SmallCheckbox
            onClick={() => {}}
            selected={includeAllRecords}
            mr={2}
            ml={collapsable ? 0 : 7}
          />
          Include Record Data
        </Div>
      ) : null}
    </Div>
    {collapsable && !collapsed
      ? R.map(row => {
          const rowProps = {
            key: row.id,
            id: row.id,
            name: row.name,
            type: row.type,
            selected: row.selected,
            includeAllRecords: row.includeAllRecords,
            toggleExcludedContent: toggleExcludedContent,
            related: row.related,
            ml: 7,
            mt: 1,
            mb: 1
          };
          return row.related.length ? (
            <CollapsableRow {...rowProps} collapsedDefault />
          ) : (
            <Row {...rowProps} />
          );
        })(related)
      : null}
  </Div>
);

const ContentTypeCard = collapsableHandler(
  ({
    type,
    collapsed,
    toggleCollapsed,
    title,
    rows,
    toggleExcludedContent,
    toggleAllContentType,
    toggleIncludeAllRecords,
    toggleChildren,
    selected,
    countOfSelected
  }) => (
    <Div
      mb={2}
      width={1}
      bg="white"
      bc="neutral2"
      b={2}
      bra={1}
      style={{
        flexShrink: 0
      }}
    >
      <PrimaryHeader
        collapsed={collapsed}
        onClick={toggleCollapsed}
        title={title}
        selected={selected}
        countOfSelected={countOfSelected}
        type={type}
        toggleAllContentType={toggleAllContentType}
      />
      <Collapsable collapsed={collapsed}>
        <Div bg="white" p={3} bt={1} bc="neutral3">
          {!rows.length ? (
            <Div>
              There are no available <Span lowercase>{title}</Span> to copy.
            </Div>
          ) : null}
          {R.map(row => {
            const rowProps = {
              key: row.id,
              id: row.id,
              name: row.name,
              type: row.type,
              selected: row.selected,
              includeAllRecords: row.includeAllRecords,
              toggleExcludedContent,
              toggleIncludeAllRecords,
              toggleChildren,
              related: row.related,
              nameProps: {
                fw: 3
              }
            };
            return row.related.length ? (
              <CollapsableRow {...rowProps} collapsedDefault />
            ) : (
              <Row {...rowProps} />
            );
          })(rows)}
        </Div>
      </Collapsable>
    </Div>
  )
);

const SelectContent = ({
  cloneEvent,
  goToCloneOptions,
  eventOptions,
  toggleExcludedContent,
  toggleAllContentType,
  toggleIncludeAllRecords,
  toggleChildren,
  handlers
}) => (
  <Div display="column">
    <Div
      height={419}
      width={1}
      display="column.flex-start.center"
      px={5}
      pt={4}
      bg="neutral1"
      style={{
        overflowY: "auto"
      }}
    >
      {eventOptions.map(({ id, name, rows, selected, countOfSelected }) => {
        return (
          <ContentTypeCard
            key={id}
            type={id}
            title={name}
            rows={rows}
            selected={selected}
            countOfSelected={countOfSelected}
            collapsedDefault
            toggleExcludedContent={toggleExcludedContent}
            toggleAllContentType={toggleAllContentType}
            toggleIncludeAllRecords={toggleIncludeAllRecords}
            toggleChildren={toggleChildren}
          />
        );
      })}
    </Div>
    <Div
      height={86}
      bt={1}
      width={1}
      display="row.space-between.center"
      px={5}
      style={{
        flexShrink: 0
      }}
    >
      <SuperOutlineButton onClick={goToCloneOptions}>Back</SuperOutlineButton>
      <SuperFilledButton
        onClick={() => {
          cloneEvent();
          handlers.isCloning(true);
        }}
        bg="altB5"
      >
        Clone Event
      </SuperFilledButton>
    </Div>
  </Div>
);

export default decorate(SelectContent);
