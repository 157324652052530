import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import ColorPicker from "components/Global/ColorPicker";

@CSSModules(css)
class DropdownOption extends Component {
  @autobind
  handleOptionChange(event) {
    this.props.handleOptionChange(this.props.index, {
      ...this.props.value,
      value: event.target.value
    });
  }

  @autobind
  handleRemove() {
    this.props.handleRemove(this.props.index);
  }

  @autobind
  onSelect(value) {
    this.props.handleOptionChange(this.props.index, {
      ...this.props.value,
      ...value
    });
  }

  render() {
    const { showColorPicker, inputType } = this.props;
    let remove, colorPicker;

    if (!this.props.isLastOption) {
      colorPicker = (
        <div styleName="colorPickerWrapper">
          <ColorPicker onSelect={this.onSelect} selected={this.props.value} />
        </div>
      );
      remove = (
        <div styleName="removeWrapper" onClick={this.handleRemove}>
          <i className="material-icons" styleName="removeIcon">
            &#xE5CD;
          </i>
        </div>
      );
    }

    return (
      <div className="input-wrapper" styleName="wrapper">
        {showColorPicker ? colorPicker : ""}
        {remove}
        <input
          type={inputType}
          value={get(this.props.value, "value", "")}
          placeholder="New option..."
          styleName="option"
          onChange={this.handleOptionChange}
        />
      </div>
    );
  }
}

DropdownOption.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.object.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  isLastOption: PropTypes.bool.isRequired,
  inputType: PropTypes.string.isRequired,
  showColorPicker: PropTypes.bool.isRequired
};

export default DropdownOption;
