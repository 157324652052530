import React from "react";
import { connect } from "react-redux";

import { getters } from "Items/Manage";
import { getMealGroups } from "Items/Manage/selectors";
import { MEALS_FILTER_ID } from "Passes/Common/constants";

import { ItemFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  itemGroups: getMealGroups(state, props),
  iniSelected: getters.selectedMeals(state, props)
}));

const Meals = ({ itemGroups = [], iniSelected = [] }) => (
  <ItemFilter
    mt={2}
    collapsedDefault
    instanceId={MEALS_FILTER_ID}
    itemGroups={itemGroups}
    modalTitle="Select Meals to show"
    iniSelected={iniSelected}
    selectedText="Meals"
  >
    <Text2 bold>Meals</Text2>
  </ItemFilter>
);

export default decorate(Meals);
