import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "Orders/Table";

export const getAllSelected = createSelector(
  getters.selectedRows,
  getters.rows,
  (selectedRows, rows) => R.equals(R.length(selectedRows), R.length(rows))
);

export const getSelectionActive = createSelector(
  getters.selectedRows,
  R.compose(
    R.not,
    R.equals(0),
    R.length
  )
);

export const getIsRowSelected = (state, { original }) =>
  R.contains(
    R.toString(R.propOr("", "id", original)),
    getters.selectedRows(state)
  );
