import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const page = data.page ? `&page=${data.page}` : "";
      const options = {
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/integrations/eventbrite/user/events?eventId=${data.eventId}${page}`,
        credentials,
        data: {},
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
