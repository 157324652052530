export const NAMESPACE = "OrgEvents";

export const TABLE_INSTANCE_ID = "orgEventsTable";
export const VIEWPICKER_INSTANCE_ID = TABLE_INSTANCE_ID;

export const STATUS = {
  ALL: "all",
  UPCOMING: "upcoming",
  PAST: "past"
};

export const ROW_ACTIONS = {
  VIEW_RECORD: "row-viewRecord",
  OPEN_EVENT_MODAL: "row-openEventModal"
};

export const EVENT_VIEWS = {
  GRID: "grid",
  CALENDAR: "calendar"
};
