import React from "react";
import SubNav from "./SubNav";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";

import Portals from "./Portals/View";
import Pages from "./Pages/View";

import { getCurrentRoute, getLinks } from "./selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    links: getLinks(state, props),
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Wrapper = ({ currentRoute, links }) => {
  let Page = R.prop(currentRoute)({
    [TAB_IDS.PORTALS]: Portals,
    [TAB_IDS.PAGES]: Pages
  });

  if (!currentRoute) {
    const nextLink = links && links.length ? links[0].to : null;
    if (nextLink) {
      window.location = nextLink;
    }

    Page = null;
  }

  return (
    <>
      <PageTitle titles={["Portals"]} />
      <SubNav selectedView={currentRoute} />
      {Page ? <Page /> : null}
    </>
  );
};

export default decorate(Wrapper);
