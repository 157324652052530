import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  getReportByPersonAssignedTo: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/reports/person/assigned-to${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getReportByPersonNameOnOrder: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/reports/person/name-on-order${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getReportByPickupPerson: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/reports/person/pickup-person${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getReportByAccountOnOrder: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/reports/account/account-on-order${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getReportByItemType: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/reports/type${query ||
          ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getReportByVariantOption: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/reports/variant-option${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */
};
