import React from "react";
import { Div, MediumTextButton } from "../../index";

const Type7Card = ({
  title,
  TitleIcon,

  titleIconColor = "neutral7",
  sideTitle,
  onClickSideTitle,
  children
}) => (
  <Div mb={8}>
    <Div pb={2} display="row.flex-start.center">
      {TitleIcon && <TitleIcon size={24} color={titleIconColor} mr={2} />}
      <Div fs={6} fw={4} color="neutral8" flex={1}>
        {title}
      </Div>
      <MediumTextButton onClick={onClickSideTitle}>
        {sideTitle}
      </MediumTextButton>
    </Div>
    <Div p={5} bg="white" bra={1} shadow={1}>
      {children}
    </Div>
  </Div>
);

export { Type7Card };
