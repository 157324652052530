import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as R from "ramda";

import Controller from "./Controller";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { records } from "redux/modules/modules/records/selectors";
import { types as itemTypes } from "redux/modules/items/types/selectors";
import { users } from "redux/modules/eventUsers/selectors";
import { forms } from "redux/modules/formsV2/forms/selectors";

import { getUsers } from "redux/modules/eventUsers/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";

// const { isEdit, type, blockId } = props;

// const editorProps = approvalsDict[type].editorProps;

function mapStateToProps(state) {
  return {
    users: R.compose(
      R.sortBy(r => (r && r.user_lname ? r.user_lname.toLowerCase() : null)),
      R.filter(
        R.compose(
          R.equals(true),
          R.prop("is_event_user")
        )
      )
    )(users(state)),
    accounts: records(state, STANDARD_MODULE_IDS.accounts.id),
    contacts: records(state, STANDARD_MODULE_IDS.contacts.id),
    itemTypes: itemTypes(state),
    forms: forms(state)
  };
}

function mapDispatchToProps(dispatch, props) {
  const { eventId } = props;
  return bindActionCreators(
    {
      showModal,
      hideModal,
      getForms: () => getForms(eventId),
      getUsers: () => getUsers(eventId),
      getAccounts: () =>
        getRecords({
          moduleId: STANDARD_MODULE_IDS.accounts.id,
          options: { eventId }
        }),
      getContacts: () =>
        getRecords({
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          options: { eventId }
        }),
      getItemTypes: () => getItemTypesByEvent(eventId)
      // getForm //TODO
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
