import React from "react";
import { connect } from "react-redux";
import { getters } from "../model";
import { Div } from "components/Base";
import Content from "./Content";
import Loading from "ui-kit/Loading";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Body = ({ loading }) => (
  <Div
    height={1}
    bg="neutral0"
    style={{
      overflowY: "auto"
    }}
    width={1}
  >
    {loading ? <Loading /> : <Content />}
  </Div>
);

export default decorate(Body);
