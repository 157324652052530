import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  WIZARD_PAGE,
  ORDER_TYPE,
  FIELD_IDS,
  DEFAULT_VALUE,
  TYPE_SETTINGS,
  MATCH_OPTION
} from "Orders/ImportModal/constants";

import { MEAL_TYPE_ID } from "utils/item-types";

const mapIndexed = R.addIndex(R.map);

const getDefaultRecordTypeId = recordTypes =>
  R.compose(
    type => (type ? type.id : null),
    R.find(R.propEq("is_default", true))
  )(recordTypes) || R.path([0, "id"])(recordTypes);

export const iniState = {
  accountImportSettings: {
    allowImportToCreateRecords: false,
    duplicateMatchingFields: [],
    ifMoreThanOneMatchFound: MATCH_OPTION.FIRST_MATCH,
    updateMatchRecordValues: false
  },
  contactImportSettings: {
    allowImportToCreateRecords: false,
    duplicateMatchingFields: [],
    ifMoreThanOneMatchFound: MATCH_OPTION.FIRST_MATCH,
    updateMatchRecordValues: false
  },
  enabledFieldsGroup: [],
  errorsFileUrl: "",
  fieldsGroups: [],
  firstLines: [],
  handleDuplicatesModal: false,
  importedColumns: [],
  loading: false,
  ordersVariantsPayload: {},
  ordersZonesPayload: {},
  ordersModule: {},
  orderType: ORDER_TYPE.INDIVIDUAL,
  ratioBar: 0,
  requireAssignment: false,
  selectedFieldsFilter: "",
  selectedGroupId: -1,
  selectedVariantType: "",
  selectItemsModal: false,
  selectMoreModal: false,
  settingsType: TYPE_SETTINGS.CONTACT,
  skippedFileUrl: "",
  totalCountOfRecordsToProcess: 0,
  typesFilter: "",
  url: "",
  uploadCsvErrors: {},
  variantsTemp: []
};

export const reducers = {
  closeSelectItemsModal: state => ({
    variantsTemp: state.fieldsGroups[2].fields,
    selectItemsModal: false,
    typesFilter: ""
  }),
  confirmSelectItemsModal: (state, { payload: selectedItemIds }) => ({
    selectItemsModal: false,
    typesFilter: "",
    fieldsGroups: [
      ...state.fieldsGroups.slice(0, 2),
      {
        ...state.fieldsGroups[2],
        fields: R.map(field => {
          const enabled = selectedItemIds.includes(field.id);
          return {
            ...field,
            enabled,
            selected: (field.enabled && field.selected) || enabled
          };
        })(state.fieldsGroups[2].fields),
        selected: true
      },
      ...state.fieldsGroups.slice(3, 4)
    ]
  }),
  disableAllFields: state => ({
    enabledFieldsGroup: R.map(
      field =>
        field.required
          ? field
          : {
              ...field,
              enabled: false
            },
      state.enabledFieldsGroup
    )
  }),
  disableField: (state, { payload: { id } }) => {
    const index = R.findIndex(R.propEq("id", id), state.enabledFieldsGroup);
    return {
      enabledFieldsGroup: [
        ...state.enabledFieldsGroup.slice(0, index),
        {
          ...state.enabledFieldsGroup[index],
          enabled: false
        },
        ...state.enabledFieldsGroup.slice(index + 1)
      ]
    };
  },
  disableGroupItems: (state, { payload: { groupId } }) => ({
    variantsTemp: R.map(item => {
      const shouldUpdate = item.settings.groupId === groupId;

      return {
        ...item,
        enabled: shouldUpdate ? false : item.enabled,
        selected: shouldUpdate ? false : item.selected,
        name: `${item.settings.itemName} - ${item.settings.groupName}`
      };
    }, state.variantsTemp)
  }),
  disableTypeItems: (state, { payload: { typeId } }) => ({
    variantsTemp: R.map(item => {
      const shouldUpdate = item.settings.typeId === typeId;

      return {
        ...item,
        enabled: shouldUpdate ? false : item.enabled,
        selected: shouldUpdate ? false : item.selected,
        name: `${item.settings.itemName} - ${item.settings.groupName}`
      };
    }, state.variantsTemp)
  }),
  enableAllFields: state => ({
    enabledFieldsGroup: R.map(
      field =>
        field.required
          ? field
          : {
              ...field,
              enabled: true
            },
      state.enabledFieldsGroup
    )
  }),
  enableGroupItems: (state, { payload: { groupId } }) => ({
    variantsTemp: R.map(item => {
      const shouldUpdate = item.settings.groupId === groupId;

      return {
        ...item,
        enabled: shouldUpdate ? true : item.enabled,
        selected: shouldUpdate ? true : item.selected,
        name: `${item.settings.itemName} - ${item.settings.groupName}`
      };
    }, state.variantsTemp)
  }),
  enableTypeItems: (state, { payload: { typeId } }) => ({
    variantsTemp: R.map(item => {
      const shouldUpdate = item.settings.typeId === typeId;

      return {
        ...item,
        enabled: shouldUpdate ? true : item.enabled,
        selected: shouldUpdate ? true : item.selected,
        name: `${item.settings.itemName} - ${item.settings.groupName}`
      };
    }, state.variantsTemp)
  }),
  hideSelectMoreModal: () => ({
    selectedFieldsFilter: "",
    selectMoreModal: false,
    enabledFieldsGroup: []
  }),
  resetDefaultValue: (state, { payload: { groupId, fieldId } }) => ({
    fieldsGroups: R.map(
      group => ({
        ...group,
        fields:
          group.id !== groupId
            ? group.fields
            : R.map(
                field => ({
                  ...field,
                  defaultValue: field.id !== fieldId ? field.defaultValue : "",
                  column: field.id !== fieldId ? field.column : ""
                }),
                group.fields
              )
      }),
      state.fieldsGroups
    )
  }),
  saveCsvData: R.identity,
  setColumnValue: (state, { payload: { groupId, fieldId, value } }) => ({
    fieldsGroups: R.map(
      group => ({
        ...group,
        fields:
          group.id !== groupId
            ? group.fields
            : R.map(
                field => ({
                  ...field,
                  column: field.id !== fieldId ? field.column : value,
                  defaultValue:
                    field.id === fieldId && field.column !== DEFAULT_VALUE
                      ? ""
                      : field.defaultValue
                }),
                group.fields
              )
      }),
      state.fieldsGroups
    )
  }),
  setDataAfterImport: (
    state,
    {
      payload: {
        accountRecordTypes,
        contactRecordTypes,
        accountPayload,
        contactPayload,
        ordersPayload,
        imported_columns,
        ordersVariantsPayload,
        ordersZonesPayload,
        url,
        first_lines: firstLines,
        total_records: totalCountOfRecordsToProcess,
        sectionsToShow
      }
    }
  ) => {
    const fieldsGroups = [
      { ...contactPayload.fields_groups[0], showItemModal: false },
      { ...accountPayload.fields_groups[0], showItemModal: false },
      { ...ordersVariantsPayload.fields_groups[0], showItemModal: true },
      { ...ordersZonesPayload.fields_groups[0], showItemModal: false },
      { ...ordersPayload.fields_groups[0], showItemModal: false }
    ].filter(fieldsGroup => R.contains(fieldsGroup.name, sectionsToShow));
    const isIndividualOrder = state.orderType === ORDER_TYPE.INDIVIDUAL;
    const isGroupOrder = state.orderType === ORDER_TYPE.GROUP;
    const importedColumnsIncludesName = (
      name,
      isContactField,
      isAccountField
    ) => {
      let lowerCasedFieldName = name?.toLowerCase();
      return R.find(column => {
        let lowerCasedColumnName = column?.toLowerCase();
        if (isContactField) {
          lowerCasedColumnName = lowerCasedColumnName.replace("person ", " ");
        } else if (isAccountField) {
          lowerCasedColumnName = lowerCasedColumnName.replace("group ", " ");
        }
        return lowerCasedColumnName === lowerCasedFieldName;
      }, imported_columns);
    };
    const mapField = (
      field,
      index,
      isContactsFieldGroup,
      isAccountsFieldGroup
    ) => {
      const oldField = R.find(
        R.propEq("id", field.id),
        state.fieldsGroups[index].fields
      );
      return R.isNil(oldField)
        ? field
        : {
            ...field,
            column: oldField.column
              ? oldField.column
              : importedColumnsIncludesName(
                  oldField.name,
                  isContactsFieldGroup,
                  isAccountsFieldGroup
                )
              ? importedColumnsIncludesName(
                  oldField.name,
                  isContactsFieldGroup,
                  isAccountsFieldGroup
                )
              : "",
            enabled: oldField.enabled,
            defaultValue: oldField.defaultValue,
            selected: oldField.selected
          };
    };

    const orderedFieldsGroups = mapIndexed((fieldGroup, index) => {
      const isContactsFieldGroup = R.equals(
        fieldGroup.id,
        STANDARD_MODULE_IDS.contacts.id
      );
      const isAccountsFieldGroup = R.equals(
        fieldGroup.id,
        STANDARD_MODULE_IDS.accounts.id
      );
      const sortedFields = R.compose(
        R.map(field => ({
          ...field,
          name:
            field.source === "item-variant" &&
            field.settings.typeId === MEAL_TYPE_ID
              ? field.settings.variantDisplayName
              : field.name,
          column: field.column
            ? field.column
            : importedColumnsIncludesName(
                field.name,
                isContactsFieldGroup,
                isAccountsFieldGroup
              )
            ? importedColumnsIncludesName(
                field.name,
                isContactsFieldGroup,
                isAccountsFieldGroup
              )
            : "",
          selected:
            field.selected ||
            ((field.required || field.enabled) &&
              field.id !== FIELD_IDS.FULL_NAME),
          defaultValue: ""
        })),
        R.sortWith([R.descend(R.prop("required"))])
      )(fieldGroup.fields);
      const fullNameIndex = isContactsFieldGroup
        ? R.findIndex(R.propEq("id", FIELD_IDS.FULL_NAME))(sortedFields)
        : -1;

      const fields = R.equals(fullNameIndex, -1)
        ? sortedFields
        : [
            sortedFields[fullNameIndex],
            ...R.filter(field => field.id !== FIELD_IDS.FULL_NAME, sortedFields)
          ];
      return {
        ...fieldGroup,
        selected: true,
        locked:
          (isIndividualOrder && isContactsFieldGroup) ||
          (isGroupOrder && isAccountsFieldGroup),
        selectedRecordTypeId: isAccountsFieldGroup
          ? getDefaultRecordTypeId(accountRecordTypes)
          : isContactsFieldGroup
          ? getDefaultRecordTypeId(contactRecordTypes)
          : null,
        recordTypes: isAccountsFieldGroup
          ? accountRecordTypes
          : isContactsFieldGroup
          ? contactRecordTypes
          : null,
        fields: R.isEmpty(state.fieldsGroups)
          ? fields
          : mapIndexed(
              field =>
                mapField(
                  field,
                  index,
                  isContactsFieldGroup,
                  isAccountsFieldGroup
                ),
              fields
            )
      };
    }, fieldsGroups);
    return {
      wizardPage: WIZARD_PAGE.BULK_IMPORT,
      fieldsGroups: orderedFieldsGroups,
      importedColumns: imported_columns,
      ordersVariantsPayload,
      ordersZonesPayload,
      url,
      selectedVariantType:
        ordersVariantsPayload?.fields_groups[0]?.fields[0]?.settings?.typeId,
      variantsTemp: ordersVariantsPayload.fields_groups[0].fields,
      ordersModule: ordersPayload.fields_groups[0],
      firstLines,
      totalCountOfRecordsToProcess
    };
  },
  setDataAfterUpload: (
    state,
    { payload: { counters, page, errorsFileUrl, skippedFileUrl } }
  ) => ({
    wizardPage: page,
    importConfirmationCounters: counters,
    errorsFileUrl,
    skippedFileUrl
  }),
  setDefaultValue: (
    state,
    { payload: { groupId, fieldId, defaultValue } }
  ) => ({
    fieldsGroups: R.map(
      group => ({
        ...group,
        fields:
          group.id !== groupId
            ? group.fields
            : R.map(
                field => ({
                  ...field,
                  defaultValue:
                    field.id !== fieldId ? field.defaultValue : defaultValue
                }),
                group.fields
              )
      }),
      state.fieldsGroups
    )
  }),
  setLoadingStatus: (state, { payload: { loading, ratioBar } }) => ({
    loading,
    ratioBar
  }),
  setOrderTypeData: (state, { payload: orderType }) => ({
    orderType,
    requireAssignment:
      orderType === ORDER_TYPE.GROUP ? state.requireAssignment : false,
    fieldsGroups: R.map(fieldGroup => {
      if (fieldGroup.id === STANDARD_MODULE_IDS.accounts.id) {
        return {
          ...fieldGroup,
          locked: orderType === ORDER_TYPE.GROUP,
          selected: orderType === ORDER_TYPE.GROUP ? true : fieldGroup.selected,
          required: orderType === ORDER_TYPE.GROUP ? true : fieldGroup.required,
          fields:
            orderType === ORDER_TYPE.GROUP
              ? R.map(
                  field => ({
                    ...field,
                    selected: field.enabled
                  }),
                  fieldGroup.fields
                )
              : fieldGroup.fields
        };
      }

      return fieldGroup;
    }, state.fieldsGroups)
  }),
  setSelectedRecordTypeId: (state, { payload: { groupId, recordTypeId } }) => ({
    fieldsGroups: R.map(fieldGroup => {
      if (fieldGroup.id === groupId) {
        fieldGroup.selectedRecordTypeId = recordTypeId;
      }
      return fieldGroup;
    }, state.fieldsGroups)
  }),
  setUploadCsvErrors: (_, { payload: { uploadCsvErrors } }) => ({
    wizardPage: WIZARD_PAGE.REVIEW,
    uploadCsvErrors,
    importConfirmationCounters: uploadCsvErrors.counters || {}
  }),
  showHandleDuplicatesModal: (state, { payload: { groupId } }) => ({
    handleDuplicatesModal: true,
    settingsType:
      groupId === STANDARD_MODULE_IDS.contacts.id
        ? TYPE_SETTINGS.CONTACT
        : TYPE_SETTINGS.ACCOUNT
  }),
  showModal: (state, { payload: { groupId, showItemModal } }) => ({
    variantsTemp: state.fieldsGroups?.[2]?.fields || [],
    selectMoreModal: showItemModal === false,
    selectItemsModal: showItemModal === true,
    selectedGroupId: groupId,
    enabledFieldsGroup:
      showItemModal === false
        ? R.compose(
            R.propOr([], "fields"),
            R.find(R.propEq("id", groupId))
          )(state.fieldsGroups)
        : state.fieldsGroups
  }),
  toggleAllowImportSetting: state => ({
    [state.settingsType]: {
      ...state[state.settingsType],
      allowImportToCreateRecords: !state[state.settingsType]
        .allowImportToCreateRecords
    }
  }),
  toggleEnabledField: (state, { payload: { id } }) => {
    const index = R.findIndex(R.propEq("id", id), state.enabledFieldsGroup);
    return {
      enabledFieldsGroup: [
        ...state.enabledFieldsGroup.slice(0, index),
        {
          ...state.enabledFieldsGroup[index],
          enabled: !state.enabledFieldsGroup[index].enabled
        },
        ...state.enabledFieldsGroup.slice(index + 1)
      ]
    };
  },
  toggleField: (
    state,
    { payload: { groupId, fieldId, fieldSelected, groupLocked, fieldRequired } }
  ) => {
    const shouldNotUpdate =
      groupLocked &&
      fieldRequired &&
      fieldId !== FIELD_IDS.FIRST_NAME &&
      fieldId !== FIELD_IDS.LAST_NAME;

    const groupSelected = group => {
      if (group.locked === false && group.id === groupId) {
        const selectedFields = R.filter(
          R.propEq("selected", true),
          group.fields
        );
        if (R.length(selectedFields) === 0 && fieldSelected === false) {
          return true;
        } else if (R.length(selectedFields) === 1 && fieldSelected === true) {
          return false;
        }
      }
      return false;
    };

    const setFieldUpdates = ({ id, column, selected, defaultValue }) => {
      if (
        id === fieldId ||
        ((fieldId === FIELD_IDS.FIRST_NAME ||
          fieldId === FIELD_IDS.LAST_NAME ||
          fieldId === FIELD_IDS.FULL_NAME) &&
          (id === FIELD_IDS.FIRST_NAME ||
            id === FIELD_IDS.LAST_NAME ||
            id === FIELD_IDS.FULL_NAME))
      ) {
        return { column: "", selected: !selected, defaultValue: "" };
      }
      return { column, selected, defaultValue };
    };
    return {
      fieldsGroups: shouldNotUpdate
        ? state.fieldsGroups
        : R.map(
            group => ({
              ...group,
              selected: groupSelected(group),
              fields:
                group.id !== groupId
                  ? group.fields
                  : R.map(field => {
                      const updatedFields = setFieldUpdates(field);
                      return {
                        ...field,
                        ...updatedFields
                      };
                    }, group.fields)
            }),
            state.fieldsGroups
          )
    };
  },
  toggleGroup: (state, { payload: { id } }) => ({
    fieldsGroups: R.map(
      group => ({
        ...group,
        selected: group.id === id ? !group.selected : group.selected,
        fields:
          group.id !== id
            ? group.fields
            : R.map(
                field => ({
                  ...field,
                  selected:
                    group.selected === false
                      ? (field.enabled === true || field.required === true) &&
                        field.id !== FIELD_IDS.FULL_NAME
                      : false,
                  column: group.selected === true ? "" : field.column
                }),
                group.fields
              )
      }),
      state.fieldsGroups
    )
  }),
  toggleMatchSetting: (state, { payload: { ifMoreThanOneMatchFound } }) => ({
    [state.settingsType]: {
      ...state[state.settingsType],
      ifMoreThanOneMatchFound
    }
  }),
  toggleRequireAssignment: (_, { payload: currentRequireAssignment }) => ({
    requireAssignment: !currentRequireAssignment
  }),
  toggleUpateMatchingSetting: state => ({
    [state.settingsType]: {
      ...state[state.settingsType],
      updateMatchRecordValues: !state[state.settingsType]
        .updateMatchRecordValues
    }
  }),
  toggleVariantItem: (state, { payload: { id } }) => {
    const itemndex = R.findIndex(R.propEq("id", id), state.variantsTemp);
    const item = state.variantsTemp[itemndex];
    return {
      variantsTemp: [
        ...state.variantsTemp.slice(0, itemndex),
        {
          ...item,
          enabled: !item.enabled,
          selected: !item.selected,
          name: `${item.settings.itemName} - ${item.settings.groupName}`
        },
        ...state.variantsTemp.slice(itemndex + 1)
      ]
    };
  },
  updateEnabledFields: (state, { payload: selectedFieldIds }) => {
    const oldFields = R.compose(
      R.propOr([], "fields"),
      R.find(R.propEq("id", state.selectedGroupId))
    )(state.fieldsGroups);

    const newFields = oldFields.map(field => {
      if (
        field.id !== FIELD_IDS.FIRST_NAME &&
        field.id !== FIELD_IDS.LAST_NAME &&
        field.id !== FIELD_IDS.FULL_NAME &&
        field.required === false
      ) {
        const enabled = selectedFieldIds.includes(field.id);
        return {
          ...field,
          enabled,
          selected: (field.enabled && field.selected) || enabled
          // column: hasUpdated ? "" : field.column,
          // defaultValue: hasUpdated ? "" : field.defaultValue
        };
      }
      return field;
    });

    return {
      fieldsGroups: R.map(
        group => ({
          ...group,
          fields: group.id !== state.selectedGroupId ? group.fields : newFields
        }),
        state.fieldsGroups
      ),
      selectedGroupId: -1,
      selectMoreModal: false
    };
  }
};
