import React from "react";
import { withProps } from "utils/General";

import {
  makeTable,
  Text2,
  Text1,
  NumberSelector,
  Div,
  SuperClearInput,
  SearchIcon,
  SmallCheckbox
} from "components/Base";
import * as R from "ramda";

import PriceSelector from "Orders/CreateOrderModal/PriceSelector";

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  py: 2,
  pt: 3,
  px: 4,
  mx: 1
})(Div);

const RowStyler = ({ children }) => (
  <Div
    display="row.flex-start.center"
    bg="white"
    py={2}
    pt={3}
    px={4}
    mx={1}
    shadow={1}
    mb={1}
    bra={1}
  >
    {children}
  </Div>
);

const CredentialsTable = makeTable({
  TableStyler: Div,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 1 / 12 },
    { width: 7 / 12 },
    { width: 2 / 12 },
    { width: 2 / 12 }
  ],
  headerCellComps: [
    () => <Div />,
    ({ groupName }) => (
      <Text2 bold uppercase color="gray6">
        {groupName}
      </Text2>
    ),
    () => (
      <Text1 bold uppercase color="gray6">
        Price
      </Text1>
    ),
    () => (
      <Text1 bold uppercase color="gray6">
        Limit
      </Text1>
    )
  ],
  rowCellComps: [
    ({ selected, onToggle }) => {
      return (
        <Div>
          <SmallCheckbox selected={selected} onClick={onToggle} />
        </Div>
      );
    },
    ({ name }) => (
      <Div
        fw={3}
        fs={4}
        truncate
        color="gray7"
        style={{ flexShrink: 0 }}
        title={name}
      >
        {name}
      </Div>
    ),
    ({ prices, price, onChangePrice }) => {
      const pricesToUse = prices.filter(p => p.is_enabled);

      return pricesToUse.length ? (
        <Div display="row.flex-start">
          <PriceSelector
            prices={pricesToUse}
            selectedPriceId={price}
            onChange={onChangePrice}
          />
        </Div>
      ) : (
        <Div />
      );
    },
    ({ limit, onChangeLimit }) => {
      return (
        <Div display="row.flex-end">
          <NumberSelector
            value={limit}
            maxValue={undefined}
            minValue={0}
            onChangeValue={onChangeLimit}
            WrapperProps={{
              height: 30
            }}
          />
        </Div>
      );
    }
  ]
});

const Group = ({ group }) => (
  <Div mb={4}>
    <CredentialsTable
      rowsData={group.items}
      headerData={{ groupName: group.name }}
    />
  </Div>
);

const Credentials = ({ activeType, onSearchChange }) => {
  const groups = R.map(group => {
    return {
      ...group,
      items: R.reduce((all, item) => {
        R.compose(
          R.forEach(variant => {
            all.push({
              ...item,
              ...variant,
              name: variant.display_name
            });
          }),
          R.sortBy(R.prop("order"))
        )(item.variants);

        return all;
      }, [])(group.items)
    };
  })(activeType.groups);

  return (
    <Div py={1}>
      <Div bb={1} bc="gray2" mb={2}>
        <SuperClearInput
          continuous
          placeholder="Search..."
          onChange={onSearchChange}
          LeftIcon={SearchIcon}
          leftIconColor="gray6"
          flex={1}
        />
      </Div>
      {groups.map(group => (
        <Group key={group.id} group={group} />
      ))}
    </Div>
  );
};

export default Credentials;
