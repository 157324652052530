import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Organization from "./OrganizationHandler";

import { fetchOrganization } from "redux/modules/organization/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: user(state),
    orgDetails: orgDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOrganization
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
