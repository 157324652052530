import React from "react";
import NotFound from "components/Event/FormsV2/NotFound";
import PasswordField from "components/Global/Inputs/Password";
import AccountsList from "components/PortalV2/PortalSwitch/AccountsList";
import { Div, Span, Text3 } from "components/Base";
import PasswordChecklist from "react-password-checklist";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const LoggedInFooter = CSSModules(
  ({
    toggleRedirectToPortal,
    userName,
    logoutPath,
    isCurrentUser,
    redirectToPortal,
    showAddAccount
  }) => {
    const actionText = redirectToPortal ? (
      <span key="1" styleName="animate">
        Create a new submission?
      </span>
    ) : (
      <span key="2" styleName="animate">
        Looking for your other submissions?
      </span>
    );

    return (
      <Div
        display="row.space-between.center"
        pt={7}
        pb={0}
        px={10}
        bt={1}
        bc="neutral2"
      >
        {!showAddAccount ? (
          <Div onClick={toggleRedirectToPortal} color="gray7" underline>
            {actionText}
          </Div>
        ) : null}
        {isCurrentUser ? (
          <Div display={showAddAccount ? "row.center.center" : "column"}>
            <Div fs={2} color="gray6" mr={showAddAccount ? 1 : 0}>
              Logged in as {userName}{" "}
            </Div>
            <a href={logoutPath}>
              <Span
                color={{ default: "gray5", hover: "gray3" }}
                underline
                transition="fast"
                fs={2}
              >
                Logout
              </Span>
            </a>
          </Div>
        ) : null}
      </Div>
    );
  },
  css
);

const Body = CSSModules(
  ({
    event,
    accounts,
    form,
    redirectToPortal,
    processing,
    searchingUsers,
    searching,
    userExists,
    isVirtualUser,
    handleSubmit,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onAccountNameChange,
    onPasswordChange,
    firstName,
    lastName,
    accountName,
    email,
    password,
    search,
    error,
    userName,
    onUserClick,
    isLocked,
    requiresAccount,
    showAddAccount,
    toggleAddAccount
  }) => {
    let content = null;

    if (isLocked && !redirectToPortal) {
      content = (
        <div styleName="lockedMessage">
          <i className="material-icons" styleName="lockedMessageIcon">
            lock
          </i>
          <div>This form is no longer accepting submissions</div>
        </div>
      );
    } else if (showAddAccount) {
      content = (
        <form styleName="formWrapper" method="post" onSubmit={handleSubmit}>
          <div styleName="fieldWrapper">
            <div styleName="inputWrapper">
              <div styleName="inputGroup">
                <div styleName="inputLabel">Company/Group Name</div>
                <input
                  name="accountName"
                  onChange={onAccountNameChange}
                  required
                  styleName="input"
                  type="text"
                  value={accountName}
                  disabled={processing}
                />
              </div>
            </div>
            <button type="submit" styleName="button" disabled={processing}>
              {processing ? (
                <div styleName="buttonInner">
                  <i
                    className="fa fa-circle-o-notch"
                    styleName="buttonLoadingIcon"
                  />
                  <span>Processing...</span>
                </div>
              ) : (
                <div styleName="buttonInner">
                  <span>Continue to form</span>
                </div>
              )}
            </button>
          </div>
          {accounts.length ? (
            <Div color="gray7" underline onClick={toggleAddAccount} mt={3}>
              Use an existing company / group
            </Div>
          ) : null}
        </form>
      );
    } else if (accounts.length) {
      content = (
        <div>
          <Text3 pb={2} pt={4} bold>
            {redirectToPortal
              ? "View existing submissions for..."
              : "Fill out form as..."}
          </Text3>
          <AccountsList
            accounts={accounts}
            userName={userName}
            includeUser={redirectToPortal || !requiresAccount}
            onUserClick={onUserClick}
          />
          {requiresAccount && !redirectToPortal ? (
            <Div color="gray7" underline onClick={toggleAddAccount} mt={3}>
              Use a different company / group
            </Div>
          ) : null}
        </div>
      );
    } else {
      const actionButton = redirectToPortal ? (
        <button type="submit" styleName="buttonPurple">
          <span key="1" styleName="animate">
            {searching ? (
              <span styleName="spinnerWrapper">
                <i className="material-icons" styleName="spinner">
                  cached
                </i>
                Searching...
              </span>
            ) : (
              "View your submissions"
            )}
          </span>
        </button>
      ) : (
        <button type="submit" styleName="button" disabled={processing}>
          {processing ? (
            <div styleName="buttonInner">
              <i
                className="fa fa-circle-o-notch"
                styleName="buttonLoadingIcon"
              />
              {userExists === true && isVirtualUser === false ? (
                <span>Logging in...</span>
              ) : (
                <span>Processing...</span>
              )}
            </div>
          ) : (
            <div styleName="buttonInner">
              <span>Continue to form</span>
            </div>
          )}
        </button>
      );

      content = (
        <form
          styleName="formWrapper"
          method="post"
          onSubmit={userExists === null ? search : handleSubmit}
        >
          <div styleName="fieldWrapper">
            <div styleName="inputWrapper">
              <div styleName="inputGroup">
                <div styleName="inputLabel">Email</div>
                <input
                  autoFocus
                  name="email"
                  onChange={onEmailChange}
                  placeholder="your_email_address@email.com"
                  required
                  styleName={redirectToPortal ? "inputPurple" : "input"}
                  type="email"
                  value={email}
                  disabled={processing}
                />
                {searchingUsers ? (
                  <i
                    className="fa fa-circle-o-notch"
                    styleName="processingIcon"
                  />
                ) : (
                  ""
                )}
              </div>
              {userExists === true || userExists === false ? (
                <div styleName="inputGroup">
                  <div styleName="inputLabel">Password</div>
                  <PasswordField
                    name="password"
                    onChange={onPasswordChange}
                    placeholder={
                      userExists === false || isVirtualUser === true
                        ? "Create your password"
                        : "Enter your password"
                    }
                    required
                    className={redirectToPortal ? css.inputPurple : css.input}
                    value={password}
                    disabled={processing}
                  />

                  {userExists === false || isVirtualUser === true ? (
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "10px",
                        fontSize: "14px",
                        color: "#222"
                      }}
                    >
                      <PasswordChecklist
                        iconSize={12}
                        rules={["length", "specialChar", "number", "capital"]}
                        minLength={8}
                        value={password}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {userExists === false ? (
                <div>
                  <div styleName="inputGroup">
                    <div styleName="inputLabel">First Name</div>
                    <input
                      name="firstName"
                      onChange={onFirstNameChange}
                      required
                      styleName="input"
                      type="text"
                      value={firstName}
                      disabled={processing}
                    />
                  </div>
                  <div styleName="inputGroup">
                    <div styleName="inputLabel">Last Name</div>
                    <input
                      name="lastName"
                      onChange={onLastNameChange}
                      required
                      styleName="input"
                      type="text"
                      value={lastName}
                      disabled={processing}
                    />
                  </div>
                  {requiresAccount ? (
                    <div styleName="inputGroup">
                      <div styleName="inputLabel">Company/Group Name</div>
                      <input
                        name="accountName"
                        onChange={onAccountNameChange}
                        required
                        styleName="input"
                        type="text"
                        value={accountName}
                        disabled={processing}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>
            {userExists === null ? (
              <div styleName="nextStepButton" onClick={search}>
                Next
              </div>
            ) : (
              ""
            )}
            {userExists === true || userExists === false ? (
              <div styleName="actions">
                <div>{actionButton}</div>
                {userExists === true ? (
                  <div>
                    <a
                      href={`/forgot?email=${email}`}
                      styleName="actionForgotPassword"
                      target="_blank"
                    >
                      Forgot your password?
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {error ? <div styleName="error">{error}</div> : ""}
          </div>
        </form>
      );
    }

    return (
      <div styleName="bodyTop">
        <div styleName="logoContainer">
          <img styleName="logoImage" src={event.logo_image_url} />
        </div>
        <div styleName="bodyContent">
          <div styleName="bodyContentInner">
            <div styleName="details">
              <div styleName="superText">Invitation to fill out:</div>
              <div>{form.title}</div>
            </div>
            <div styleName="form">{content}</div>
          </div>
        </div>
      </div>
    );
  },
  css
);

const View = CSSModules(
  ({
    backgroundImageUrl,
    accounts,
    logoutPath,
    form,
    event,
    isFetching,
    errorMessages,
    redirectToPortal,
    searching,
    processing,
    searchingUsers,
    userExists,
    isVirtualUser,
    handleSubmit,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onAccountNameChange,
    onPasswordChange,
    firstName,
    lastName,
    accountName,
    email,
    password,
    search,
    error,
    toggleRedirectToPortal,
    userName,
    onUserClick,
    isLocked,
    requiresAccount,
    isCurrentUser,
    showAddAccount,
    toggleAddAccount
  }) => {
    if (errorMessages.length || (!isFetching && !form.id)) {
      return <NotFound />;
    }
    return (
      <div styleName="wrapper">
        <div
          styleName="background"
          style={{
            backgroundImage: backgroundImageUrl
              ? `url(${backgroundImageUrl})`
              : "none"
          }}
        />
        <div styleName="container">
          <Body
            {...{
              event,
              accounts,
              form,
              redirectToPortal,
              processing,
              searchingUsers,
              searching,
              userExists,
              isVirtualUser,
              handleSubmit,
              onEmailChange,
              onFirstNameChange,
              onLastNameChange,
              onAccountNameChange,
              onPasswordChange,
              firstName,
              lastName,
              accountName,
              email,
              password,
              search,
              error,
              userName,
              onUserClick,
              isLocked,
              requiresAccount,
              showAddAccount,
              toggleAddAccount
            }}
          />

          {isCurrentUser || userExists === true ? (
            <LoggedInFooter
              {...{
                toggleRedirectToPortal,
                userName,
                logoutPath,
                isCurrentUser,
                redirectToPortal,
                showAddAccount
              }}
            />
          ) : null}
        </div>
        <a
          styleName="branding"
          href={`https://www.lennd.com/?source=form_landing&form_id=${form.id}`}
          target="_blank"
        >
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/powered-by-lennd.svg"
            styleName="brandingImg"
          />
        </a>
      </div>
    );
  },
  css
);

export default View;
