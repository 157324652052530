import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "Orders/TicketsPasses";
import { QUICK_ASSIGN_PAGES } from "./constants";

export const isTablePriceBtnDisabled = createSelector(
  getters.pricesTemp,
  R.all(price => price.amount <= 0 || price.name.length < 2)
);

export const isCredentialsBtnDisabled = createSelector(
  getters.pricesTemp,
  getters.tempData,
  (pricesTemp, tempData) => {
    return R.or(
      R.all(price => price.amount <= 0 || price.name.length < 2, pricesTemp),
      R.all(R.propEq("selected", false), tempData)
    );
  }
);

export const getFilterCredentials = createSelector(
  getters.tempData,
  getters.credentialsFilter,
  (tempData, credentialsFilter) => {
    if (R.isEmpty(credentialsFilter)) {
      return tempData;
    }
    return R.filter(
      cred => R.contains(R.toLower(credentialsFilter), R.toLower(cred.name)),
      tempData
    );
  }
);

export const getFilterPass = createSelector(
  getters.data,
  getters.passesFilter,
  (data, passesFilter) => {
    if (R.isEmpty(passesFilter)) {
      return data;
    }
    return R.filter(
      pass => R.contains(R.toLower(passesFilter), R.toLower(pass.name)),
      data
    );
  }
);

export const getAssignBtnText = createSelector(
  getters.pricesTemp,
  getters.tempData,
  (pricesTemp, tempData) => {
    const pricesLength = R.compose(
      R.length,
      R.filter(price => price.amount > 0 && price.name.length >= 2)
    )(pricesTemp);

    const credentialsLength = R.compose(
      R.length,
      R.filter(R.propEq("selected", true))
    )(tempData);

    const textFormmater = (length, text) => {
      if (length === 0) {
        return "";
      } else if (length === 1) {
        return ` ${length} ${text}`;
      }
      return ` ${length} ${text}s`;
    };

    return `Finish: Assign${textFormmater(pricesLength, "price")}${
      pricesLength > 0 && credentialsLength > 0 ? " to" : ""
    }${textFormmater(credentialsLength, "item")}`;
  }
);

export const isEditPricesBtnDisabled = createSelector(
  R.propOr([], "prices"),
  R.all(price => price.amount <= 0 || price.name.length < 2)
);

export const getProfilesTemp = createSelector(
  getters.selectedPass,
  R.propOr([], "who_has_access")
);

export const getSelectedPrices = createSelector(
  getters.selectedPass,
  R.propOr([], "prices")
);

export const getProfileNoneSelected = createSelector(
  getters.profileLimitTemp,
  getters.profilePricesTemp,
  (profileLimitTemp, profilePricesTemp) =>
    profileLimitTemp < 1 && R.isEmpty(profilePricesTemp)
);

export const getProfilePricesNone = createSelector(
  getters.profilePricesTemp,
  R.all(R.propEq("selected", false))
);

export const getItemPricesTempNone = createSelector(
  getters.itemPricesTemp,
  R.all(R.propEq("selected", false))
);

export const getWhoHasAccessFormattedData = createSelector(
  getters.data,
  data => {
    const selectedItem = R.map(item => {
      return R.compose(
        R.map(profile => ({ ...profile, item })),
        R.propOr([], "who_has_access")
      )(item);
    }, data);

    return R.compose(
      R.reduce((acc, curr) => {
        const {
          id,
          name,
          users,
          item,
          selected,
          prices,
          who_has_access
        } = curr;
        const profileIndex = R.findIndex(el => el.id === id, acc);

        if (profileIndex === -1) {
          return [
            ...acc,
            {
              id,
              name,
              users,
              items: selected ? [item] : [],
              prices,
              who_has_access
            }
          ];
        }

        return [
          ...acc.slice(0, profileIndex),
          {
            ...acc[profileIndex],
            items: selected
              ? [...acc[profileIndex].items, item]
              : acc[profileIndex].items
          },
          ...acc.slice(profileIndex + 1)
        ];
      }, []),
      R.flatten
    )(selectedItem);
  }
);

export const getSelectedProfiles = createSelector(
  getWhoHasAccessFormattedData,
  R.filter(profile => profile.items.length > 0)
);

export const getFilteredAssignItems = createSelector(
  getters.assignItemsCredentials,
  getters.itemsFilter,
  (assignItemsCredentials, itemsFilter) => {
    if (R.isEmpty(itemsFilter)) {
      return assignItemsCredentials;
    }

    return R.filter(
      item => R.contains(R.toLower(itemsFilter), R.toLower(item.name)),
      assignItemsCredentials
    );
  }
);

export const isDisabledAssignItemsBtn = createSelector(
  getters.assignItemsCredentials,
  items => {
    const selectedItems = R.filter(R.propEq("selected", true), items);

    return (
      items.selected === 0 ||
      R.all(
        ({ prices, limit }) => R.isEmpty(prices) && limit === 0,
        selectedItems
      )
    );
  }
);

export const getQuickAssignPreviousText = createSelector(
  getters.selectedQuickAssignPage,
  selectedQuickAssignPage => {
    if (selectedQuickAssignPage === QUICK_ASSIGN_PAGES.CREDENTIALS) {
      return "Back";
    }
    return "Cancel";
  }
);

export const getQuickAssignNextText = createSelector(
  getters.selectedQuickAssignPage,
  getAssignBtnText,
  (selectedQuickAssignPage, assignBtnText) => {
    if (selectedQuickAssignPage === QUICK_ASSIGN_PAGES.CREDENTIALS) {
      return assignBtnText;
    }
    return "Next: Select Items to Price";
  }
);

export const isNextQuickAssignBtnDisabled = createSelector(
  getters.selectedQuickAssignPage,
  isTablePriceBtnDisabled,
  isCredentialsBtnDisabled,
  (
    selectedQuickAssignPage,
    isTablePriceBtnDisabled,
    isCredentialsBtnDisabled
  ) => {
    if (selectedQuickAssignPage === QUICK_ASSIGN_PAGES.PRICES) {
      return isTablePriceBtnDisabled;
    } else if (selectedQuickAssignPage === QUICK_ASSIGN_PAGES.CREDENTIALS) {
      return isCredentialsBtnDisabled || isTablePriceBtnDisabled;
    }
    return true;
  }
);

export const getAssignedPassesText = createSelector(
  getters.tempData,
  tempData => {
    const passesLength = R.compose(
      R.length,
      R.filter(R.propEq("selected", true))
    )(tempData);

    if (passesLength === 0) {
      return "";
    }
    return `${passesLength} pass${
      passesLength > 1 ? "es" : ""
    } will be assigned these prices:`;
  }
);

export const isAddAnotherBtnDisabled = createSelector(
  getters.categoriesList,
  categoriesList => R.length(categoriesList) <= 0
);
