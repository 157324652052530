import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { Div, Text0, CheckIcon, SmallCheckbox } from "components/Base";
import { getters, actions } from "Items/SelectOrderManagementFieldsModal";
import { getCategoryList } from "Items/SelectOrderManagementFieldsModal/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const decorate = connect(
  state => ({
    categoryList: getCategoryList(state),
    selectedFields: getters.selectedFields(state),
    moduleId: getters.moduleId(state),
    canDo: canEventUserDo(state)
  }),
  {
    toggleSelectedField: actions.toggleSelectedField,
    disableAllCategoryFields: actions.disableAllCategoryFields,
    enableAllCategoryFields: actions.enableAllCategoryFields
  }
);

const FieldsList = ({
  handlers,
  categoryList,
  selectedFields,
  toggleSelectedField,
  disableAllCategoryFields,
  enableAllCategoryFields,
  moduleId,
  canDo
}) => (
  <Div px={4}>
    {R.map(
      cat => (
        <Div key={cat.id}>
          <Div display="row.space-between.center" mt={4} mb={2}>
            <Div display="row.flex-start.center">
              <Div
                color="gray6"
                fs={1}
                fw={3}
                mr={2}
                uppercase
                style={{
                  letterSpacing: ".3px"
                }}
              >
                {cat.name}
              </Div>
              <Text0
                ml={2}
                mr={2}
                color="primary7"
                underline
                onClick={() => enableAllCategoryFields({ categoryId: cat.id })}
              >
                All
              </Text0>
              <Text0
                color="primary7"
                underline
                onClick={() => disableAllCategoryFields({ categoryId: cat.id })}
              >
                None
              </Text0>
            </Div>

            {/* {cat.is_report_category ? null : canDo(`${moduleId}_manage`) ? (
              <Text0
                ml={2}
                mr={2}
                color="primary7"
                underline
                onClick={() =>
                  handlers.showAddFieldModal({ categoryId: cat.id })
                }
              >
                Add Field
              </Text0>
              ) : null} */}
          </Div>
          {R.map(field => {
            const selected = R.any(R.propEq("id", field.id), selectedFields);
            return (
              <Div
                key={field.id}
                p={2}
                display="row.flex-start.center"
                bra={1}
                style={{
                  minHeight: "40px"
                }}
                bg={
                  field.is_auto_enabled
                    ? "neutral0"
                    : {
                        hover: "neutral1",
                        default: "neutral0"
                      }
                }
                mt={1}
                onClick={
                  field.is_auto_enabled
                    ? undefined
                    : () =>
                        toggleSelectedField({
                          selected,
                          name: field.name,
                          is_auto_enabled: field.is_auto_enabled,
                          fieldId: field.id,
                          is_report_field: field.is_report_field
                        })
                }
              >
                {field.is_auto_enabled ? (
                  <CheckIcon color="primary7" ml={1} mr={1} />
                ) : (
                  <SmallCheckbox selected={selected} />
                )}
                <Div fw={3} fs={1} ml={1} color="gray7">
                  {field.name}
                </Div>
              </Div>
            );
          }, cat.fields)}
        </Div>
      ),
      categoryList
    )}
  </Div>
);

export default decorate(FieldsList);
