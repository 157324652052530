import React from "react";
import * as R from "ramda";

import { Div, MediumOutlineButton } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import CSSModules from "react-css-modules";
import css from "../onboard.scss";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { LoadingIcon } from "components/Base";
import NotConfigured from "../NotConfigured";

const decorate = R.compose(
  connect(
    (state, props) => ({
      community: getters.communityDetails(state, props),
      loadingCommunity: getters.loadingCommunity(state)
    }),
    {
      init: actions.init,
      openCommunitySettingsModal: () =>
        actions.setShowCommunitySettingsModal(true),
      goToLearnLennd: actions.goToLearnLennd
    }
  ),
  CSSModules(css)
);

const Override = ({
  community,
  openCommunitySettingsModal,
  goToLearnLennd,
  loadingCommunity
}) => (
  <Div flex={1} display="column.flex-start.stretch">
    <CollapsablePanel
      heading={() => (
        <Div display="row.space-between.center" my={2} width={1} pr={4}>
          <div className={css.title}>Override Settings</div>
          <NotConfigured
            configured={R.prop("community_configured")(community)}
            loading={loadingCommunity}
            text={`Enabled`}
          />
        </Div>
      )}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsedDefault={true}
    >
      <div styleName="wrapper">
        <div styleName="row">
          <div>
            <div styleName="header">
              <span class="material-icons" style={{ color: "#DB874A" }}>
                groups
              </span>
              <div styleName="name">Community Portal</div>
            </div>
            <div styleName="description">
              Share livestreams, videos, interviews and media with your groups
              and people. Allow them to send messsages to each other, vote in
              polls and ask questions. Great for providing a virtual experience
              to media, volunteers and attendees.
            </div>
          </div>
          {loadingCommunity ? (
            <div styleName="loading-wrapper">
              <LoadingIcon color="gray3" size={32} />
            </div>
          ) : (
            <div styleName="actions">
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                mr={2}
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 12, marginRight: 8, height: 12 }}
                  >
                    border_color
                  </span>
                )}
                width={100}
                onClick={openCommunitySettingsModal}
              >
                Settings
              </MediumOutlineButton>
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 16, marginRight: 8, height: 16 }}
                  >
                    link
                  </span>
                )}
                onClick={goToLearnLennd}
              >
                Learn More
              </MediumOutlineButton>
            </div>
          )}
        </div>
      </div>
    </CollapsablePanel>
  </Div>
);

export default decorate(Override);
