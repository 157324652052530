import React from "react";
import { connect } from "react-redux";
import { Div, MediumFilledButton } from "components/Base";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters as PageOrdersGetters } from "Portal/PortalPageOrders/model";
import { actions } from "../model";
import { addS } from "utils/General";
import Tooltip from "components/Global/Tooltip";

import { itemsInCart, getInvalidMessage } from "../selectors";

const decorate = R.compose(
  connect(
    (state, props) => ({
      itemsInCartLen: itemsInCart(state, props),
      highlightColor: PageOrdersGetters.highlightColor(state, props),
      invalidMessage: getInvalidMessage(state, props)
    }),
    {
      checkout: actions.checkout
    }
  ),
  withRouter
);

const Layout = ({
  itemsInCartLen,
  highlightColor,
  checkout,
  router,
  invalidMessage
}) => (
  <Div
    display="row.space-between.center"
    bg="#FFFFFF"
    style={{
      padding: "16px 22px",
      marginBottom: "25px",
      position: "fixed",
      bottom: "0px",
      left: "25px",
      right: 0,
      maxWidth: "470px",
      margin: "0 auto",
      zIndex: 21,
      boxShadow: "#dadada 0px 1px 9px 0px",
      borderRadius: "5px 5px 0 0"
    }}
  >
    <Div display="column" mr={3}>
      <Div
        color="black"
        style={{ fontWeight: 500 }}
      >{`You have ${itemsInCartLen} item${addS(
        itemsInCartLen
      )} in your cart`}</Div>
      {invalidMessage ? (
        <Div fs={1} color="gray7" mt={1}>
          {invalidMessage}
        </Div>
      ) : null}
    </Div>

    <MediumFilledButton
      onClick={() => checkout(router)}
      bg={highlightColor}
      fw={3}
      color="white"
      disabled={invalidMessage && invalidMessage.length ? true : false}
    >
      View Cart
    </MediumFilledButton>
  </Div>
);

export default decorate(Layout);
