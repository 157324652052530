import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Panel from "ui-kit/Panel";
import Circle from "ui-kit/Circle";
import Pill from "ui-kit/Pill";
import AddButton from "./AddButton";
import { LargeMenuIcon } from "components/Global/NavIcons";
import {
  Div,
  InverseGridIcon,
  SearchIcon,
  MediumClearInput
} from "components/Base";

import { getModules } from "Event/Home/selectors";

import { getters, actions } from "Event/Home";

const decorate = connect(
  (state, props) => ({
    modules: getModules(state, props).filter(
      m =>
        ![
          STANDARD_MODULE_IDS.accounts.id,
          STANDARD_MODULE_IDS.contacts.id
        ].includes(m.id)
    ),
    query: getters.search(state)
  }),
  {
    onSetSearch: value => actions.setSearch(value)
  }
);

const IconGroup = ({ params, details, modules, query, onSetSearch }) => (
  <Panel
    p={0}
    // ml={4}
    style={{ overflow: "visible", height: "auto", flex: "none" }}
  >
    <Div px={4} pt={4} display="row.flex-start.center" mb={3}>
      <Circle size={40} bg="neutral1">
        <InverseGridIcon />
      </Circle>
      <Div ml={2} fs={5} fw={4} color="gray7" mr={5}>
        Apps
      </Div>
      <Pill
        leftIcon={<SearchIcon size={25} />}
        height={35}
        bg="neutral1"
        left
        right
        flex={1}
        mr={5}
      >
        <MediumClearInput
          placeholder="Search"
          continuous
          value={query}
          onChange={onSetSearch}
          style={{
            marginLeft: "-15px"
          }}
        />
      </Pill>
    </Div>

    <Div px={5} pb={5} styleName="body">
      {!modules.length ? (
        <div styleName="empty">Nothing matched your search. Try again.</div>
      ) : (
        modules.map(moduleDetails => (
          <LargeMenuIcon
            key={moduleDetails.id}
            module={moduleDetails}
            eventDetails={details}
            params={params}
            showLink
            showLabel
          />
        ))
      )}

      {!query ? <AddButton /> : null}
    </Div>
  </Panel>
);

IconGroup.propTypes = {
  params: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  styles: PropTypes.object,
  modules: PropTypes.array.isRequired
};

export default decorate(CSSModules(IconGroup, css));
