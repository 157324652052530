import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions, getters } from "../model";

import Circle from "ui-kit/Circle";
import MenuPopover from "ui-kit/MenuPopover";
import { PrevNext } from "ui-kit/TimeTable/View";
import OptionsButton from "./OptionsButton";
import {
  DownFilledIcon,
  AddIcon,
  MediumFilledButton,
  Div
} from "components/Base";
import MonthCalendar from "./MonthCalendar";

import CanUserDo from "components/Global/Security/CanUserDo";

import { noop } from "utils/General";

const wrapperStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 8
};

const decorate = connect(
  (state, props) => ({
    moduleId: getters.moduleId(state, props)
  }),
  bindInstance({
    create: actions.showAddEventModal
  })
);

const Toolbar = ({
  create = noop,
  instanceId,
  handlers,
  moduleId,
  ...styleProps
}) => (
  <Div display="row.flex-start.center" mb={2} {...styleProps}>
    <CanUserDo action={`${moduleId}_create`}>
      <Circle
        size={40}
        bg="warning5"
        onClick={create}
        style={{ cursor: "pointer" }}
        data-cy="create-activity"
      >
        <AddIcon color="white" size={30} />
      </Circle>
    </CanUserDo>
    <Div p={1} bg="#7a38c3" ml={2}>
      <MenuPopover
        Label={
          <MediumFilledButton RightIcon={DownFilledIcon} bg="primary7">
            Go to...
          </MediumFilledButton>
        }
      >
        {({ closePopover }) => <MonthCalendar closePopover={closePopover} />}
      </MenuPopover>
    </Div>
    <PrevNext instanceId={instanceId} />
    <Div display="row.center.stretch">
      <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
        <OptionsButton
          wrapperStyle={wrapperStyle}
          flex={1}
          handlers={handlers}
        />
      </CanUserDo>
    </Div>
  </Div>
);

export default decorate(Toolbar);
