const isViewingAs = (type, context) => {
  if (type === "org") {
    if (isViewingAsEvent(context)) return false;
    return Boolean(context.orgId);
  } else if (type === "event") {
    return Boolean(context.eventId);
  }

  return false;
};

const isViewingAsOrg = context => isViewingAs("org", context);
const isViewingAsEvent = context => isViewingAs("event", context);

export { isViewingAsOrg, isViewingAsEvent };
