import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import {
  NAMESPACE,
  PAGES,
  DEFAULT_VALUE,
  FIELD_IDS
} from "Modules/ImportModal/constants";
import { noop } from "utils/General";

const mapIndexed = R.addIndex(R.map);

const mapField = R.map(field => ({
  id: field.id,
  column: "",
  type: field.type,
  required: field.required
}));

const iniState = {
  onDoneFn: noop,
  selectedPage: PAGES.IMPORT_FILE,
  pasteText: "",
  moduleId: 0,
  loading: false,
  mapObject: {},
  urlFile: "",
  fieldGroups: [],
  selectMoreModal: false,
  fieldGroupsIndex: -1,
  enabledFieldsGroup: [],
  uploadCsvErrors: {},
  enabledGroups: [],
  eventId: 0,
  blockId: "",
  submissionId: "",
  maxAmount: 0,
  selectedFieldsFilter: "",
  importConfirmationCounters: {},
  accountId: "",
  recordTypeId: "",
  ratioBar: 0,
  pendingSeconds: null
};

const handlers = createHandlers({
  iniState,
  reducers: {
    downloadUrl: R.identity,
    uploadFile: R.identity,
    saveCsvData: R.identity,
    setLoadingStatus: (
      state,
      { payload: { loading, ratioBar, pendingSeconds } }
    ) => ({
      loading,
      ratioBar,
      pendingSeconds
    }),
    setInitialData: ({ moduleId, eventId, blockId, submissionId }) => ({
      moduleId,
      eventId,
      blockId,
      submissionId
    }),
    cancelInstance: state =>
      state
        ? {
            ...iniState,
            moduleId: state.moduleId,
            eventId: state.eventId,
            blockId: state.blockId,
            submissionId: state.submissionId,
            recordTypeId: state.recordTypeId,
            accountId: state.accountId
          }
        : { ...iniState },
    setDataAfterImport: (
      state,
      { payload: { url, mapObject, selectedPage } }
    ) => {
      const mappedObject = {
        ...mapObject,
        fields_groups: R.map(fieldGroup => {
          const sortedFields = R.sortWith([R.descend(R.prop("required"))])(
            fieldGroup.fields
          );
          const fullNameIndex = R.equals(
            fieldGroup.id,
            STANDARD_MODULE_IDS.contacts.id
          )
            ? R.findIndex(R.propEq("name", "Full Name"))(sortedFields)
            : -1;
          return {
            ...fieldGroup,
            fields: R.equals(fullNameIndex, -1)
              ? sortedFields
              : [
                  sortedFields[fullNameIndex],
                  ...R.filter(f => f.name !== "Full Name", sortedFields)
                ]
          };
        }, mapObject.fields_groups)
      };
      return {
        urlFile: url,
        mapObject: mappedObject,
        enabledGroups: R.map(
          group => ({
            name: group.name,
            id: group.id,
            locked: group.id === state.moduleId,
            enabled: group.id === state.moduleId
          }),
          mappedObject.fields_groups
        ),
        fieldGroups: mapIndexed(
          (fieldGroup, index) => ({
            module_id: fieldGroup.id,
            name: fieldGroup.name,
            fields: R.isEmpty(state.fieldGroups)
              ? R.compose(
                  mapField,
                  R.filter(
                    f =>
                      f.enabled &&
                      f.name !== "Full Name" &&
                      f.supported === true
                  )
                )(fieldGroup.id === state.moduleId ? fieldGroup.fields : [])
              : R.filter(
                  f =>
                    R.any(
                      imported =>
                        imported === f.column || f.column === "DefaultValue"
                    )(R.propOr([], "imported_columns")(mappedObject)),
                  R.propOr([], "fields")(state.fieldGroups[index])
                ),
            options: R.isEmpty(state.fieldGroups)
              ? fieldGroup.options.map(option => ({
                  ...option,
                  name: false
                }))
              : R.propOr([], "options", state.fieldGroups[index])
          }),
          mappedObject.fields_groups
        ),
        selectedPage
      };
    },
    setDataAfterUpload: (state, { payload: { counters } }) => ({
      selectedPage: PAGES.SUMMARY_SCENE,
      importConfirmationCounters: counters
    }),
    toggleGroup: (state, { payload: { index } }) => {
      if (state.enabledGroups[index].enabled === true) {
        return {
          fieldGroups: [
            ...state.fieldGroups.slice(0, index),
            {
              ...state.fieldGroups[index],
              fields: [],
              options: [
                {
                  ...state.fieldGroups[index].options[0],
                  name: false
                }
              ]
            },
            ...state.fieldGroups.slice(index + 1)
          ],
          enabledGroups: [
            ...state.enabledGroups.slice(0, index),
            {
              ...state.enabledGroups[index],
              enabled:
                state.enabledGroups[index].locked === false
                  ? false
                  : state.enabledGroups[index].enabled
            },
            ...state.enabledGroups.slice(index + 1)
          ]
        };
      }
      const fields = R.compose(
        mapField,
        R.filter(f => f.required)
      )(R.propOr([], "fields")(state.mapObject.fields_groups[index]));
      return {
        fieldGroups: [
          ...state.fieldGroups.slice(0, index),
          {
            ...state.fieldGroups[index],
            fields
          },
          ...state.fieldGroups.slice(index + 1)
        ],
        enabledGroups: [
          ...state.enabledGroups.slice(0, index),
          {
            ...state.enabledGroups[index],
            enabled:
              state.enabledGroups[index].locked === false
                ? true
                : state.enabledGroups[index].enabled
          },
          ...state.enabledGroups.slice(index + 1)
        ]
      };
    },
    toggleFieldGroups: (
      state,
      { payload: { index, type, id, required, groupId } }
    ) => {
      if (
        (groupId === STANDARD_MODULE_IDS.contacts.id &&
          (!required ||
            id === FIELD_IDS.FIRST_NAME ||
            id === FIELD_IDS.LAST_NAME)) ||
        groupId !== STANDARD_MODULE_IDS.contacts.id
      ) {
        if (state.fieldGroups[index].fields.find(field => field.id === id)) {
          let aditionalFields = [];

          if (FIELD_IDS.FULL_NAME === id) {
            aditionalFields = R.compose(
              mapField,
              R.filter(
                field =>
                  field.id === FIELD_IDS.FIRST_NAME ||
                  field.id === FIELD_IDS.LAST_NAME
              )
            )(R.propOr([], "fields", state.mapObject.fields_groups[index]));
          } else if (
            FIELD_IDS.FIRST_NAME === id ||
            FIELD_IDS.LAST_NAME === id
          ) {
            aditionalFields = R.compose(
              mapField,
              R.filter(field => field.id === FIELD_IDS.FULL_NAME)
            )(R.propOr([], "fields", state.mapObject.fields_groups[index]));
          }

          return {
            fieldGroups: [
              ...state.fieldGroups.slice(0, index),
              {
                ...state.fieldGroups[index],
                fields: [
                  ...state.fieldGroups[index].fields.filter(field => {
                    if (
                      id === FIELD_IDS.FIRST_NAME ||
                      id === FIELD_IDS.LAST_NAME
                    ) {
                      return (
                        field.id !== id &&
                        field.id !== FIELD_IDS.FIRST_NAME &&
                        field.id !== FIELD_IDS.LAST_NAME
                      );
                    } else if (FIELD_IDS.FULL_NAME === id) {
                      return (
                        field.id !== id && field.id !== FIELD_IDS.FULL_NAME
                      );
                    }
                    return field.id !== id;
                  }),
                  ...aditionalFields
                ]
              },
              ...state.fieldGroups.slice(index + 1)
            ]
          };
        }
        let aditionalFields = [];
        let filteredFields = state.fieldGroups[index].fields;
        if (id === FIELD_IDS.FULL_NAME) {
          filteredFields = state.fieldGroups[index].fields.filter(
            field =>
              field.id !== FIELD_IDS.FIRST_NAME &&
              field.id !== FIELD_IDS.LAST_NAME
          );
        } else if (id === FIELD_IDS.FIRST_NAME) {
          filteredFields = state.fieldGroups[index].fields.filter(
            field => field.id !== FIELD_IDS.FULL_NAME
          );
          aditionalFields = R.compose(
            mapField,
            R.filter(field => field.id === FIELD_IDS.LAST_NAME)
          )(R.propOr([], "fields", state.mapObject.fields_groups[index]));
        } else if (id === FIELD_IDS.LAST_NAME) {
          filteredFields = state.fieldGroups[index].fields.filter(
            field => field.id !== FIELD_IDS.FULL_NAME
          );
          aditionalFields = R.compose(
            mapField,
            R.filter(field => field.id === FIELD_IDS.FIRST_NAME)
          )(R.propOr([], "fields", state.mapObject.fields_groups[index]));
        }

        const allFields =
          R.isEmpty(filteredFields) &&
          !(
            id === FIELD_IDS.FULL_NAME ||
            id === FIELD_IDS.LAST_NAME ||
            id === FIELD_IDS.FIRST_NAME
          )
            ? R.propOr([], "fields")(state.mapObject.fields_groups[index])
            : [];
        const requiredFields = R.compose(
          mapField,
          R.filter(field => field.required)
        )(allFields);
        return {
          enabledGroups: [
            ...state.enabledGroups.slice(0, index),
            {
              ...state.enabledGroups[index],
              enabled:
                state.enabledGroups[index].locked === false
                  ? true
                  : state.enabledGroups[index].enabled
            },
            ...state.enabledGroups.slice(index + 1)
          ],
          fieldGroups: [
            ...state.fieldGroups.slice(0, index),
            {
              ...state.fieldGroups[index],
              fields: [
                ...requiredFields,
                ...filteredFields,
                { id, column: "", type, required },
                ...aditionalFields
              ]
            },
            ...state.fieldGroups.slice(index + 1)
          ]
        };
      }
      return {
        fieldGroups: state.fieldGroups
      };
    },
    setFieldGroupValue: (
      state,
      { payload: { index, value, type, id, required } }
    ) => ({
      fieldGroups: [
        ...state.fieldGroups.slice(0, index),
        {
          ...state.fieldGroups[index],
          fields: [
            ...state.fieldGroups[index].fields.filter(field => field.id !== id),
            !R.equals(value, DEFAULT_VALUE)
              ? { id, column: value, type, required }
              : { id, column: value, type, default_value: "", required }
          ]
        },
        ...state.fieldGroups.slice(index + 1)
      ]
    }),
    addDefaultValue: (
      state,
      { payload: { index, type, id, defaultValue, required } }
    ) => ({
      fieldGroups: [
        ...state.fieldGroups.slice(0, index),
        {
          ...state.fieldGroups[index],
          fields: [
            ...state.fieldGroups[index].fields.filter(field => field.id !== id),
            {
              id,
              column: DEFAULT_VALUE,
              type,
              default_value: defaultValue,
              required
            }
          ]
        },
        ...state.fieldGroups.slice(index + 1)
      ]
    }),
    removeDefaultValue: (state, { payload: { index, type, id } }) => ({
      fieldGroups: [
        ...state.fieldGroups.slice(0, index),
        {
          ...state.fieldGroups[index],
          fields: [
            ...state.fieldGroups[index].fields.filter(field => field.id !== id),
            { id, column: "", type }
          ]
        },
        ...state.fieldGroups.slice(index + 1)
      ]
    }),
    toggleGroupOptions: (state, { payload: { index, optionIndex } }) => {
      const allFields =
        R.isEmpty(state.fieldGroups[index].fields) &&
        state.fieldGroups[index].options[0].name === false
          ? R.propOr([], "fields")(state.mapObject.fields_groups[index])
          : [];
      const requiredFields = R.compose(
        mapField,
        R.filter(field => field.required)
      )(allFields);
      return {
        enabledGroups:
          state.enabledGroups[index].enabled === true
            ? state.enabledGroups
            : [
                ...state.enabledGroups.slice(0, index),
                {
                  ...state.enabledGroups[index],
                  enabled:
                    state.enabledGroups[index].locked === false
                      ? true
                      : state.enabledGroups[index].enabled
                },
                ...state.enabledGroups.slice(index + 1)
              ],
        fieldGroups: [
          ...state.fieldGroups.slice(0, index),
          {
            ...state.fieldGroups[index],
            fields: [...requiredFields, ...state.fieldGroups[index].fields],
            options: [
              ...state.fieldGroups[index].options.slice(0, optionIndex),
              {
                ...state.fieldGroups[index].options[optionIndex],
                name:
                  state.fieldGroups[index].options[optionIndex].name === false
                    ? true
                    : false
              },
              ...state.fieldGroups[index].options.slice(optionIndex + 1)
            ]
          },
          ...state.fieldGroups.slice(index + 1)
        ]
      };
    },
    showSelectMoreModal: (state, { payload: { index } }) => ({
      selectMoreModal: true,
      fieldGroupsIndex: index,
      enabledFieldsGroup: state.mapObject.fields_groups[index].fields
    }),
    hideSelectMoreModal: () => ({
      selectedFieldsFilter: "",
      selectMoreModal: false,
      fieldGroupsIndex: -1,
      enabledFieldsGroup: []
    }),
    toggleEnabledField: (state, { payload: { id } }) => {
      const index = R.findIndex(R.propEq("id", id), state.enabledFieldsGroup);
      return {
        enabledFieldsGroup: [
          ...state.enabledFieldsGroup.slice(0, index),
          {
            ...state.enabledFieldsGroup[index],
            enabled: !state.enabledFieldsGroup[index].enabled
          },
          ...state.enabledFieldsGroup.slice(index + 1)
        ]
      };
    },
    disableField: (state, { payload: { id } }) => {
      const index = R.findIndex(R.propEq("id", id), state.enabledFieldsGroup);
      return {
        enabledFieldsGroup: [
          ...state.enabledFieldsGroup.slice(0, index),
          {
            ...state.enabledFieldsGroup[index],
            enabled: false
          },
          ...state.enabledFieldsGroup.slice(index + 1)
        ]
      };
    },
    enableAllFields: state => ({
      enabledFieldsGroup: R.map(
        field =>
          field.required
            ? field
            : {
                ...field,
                enabled: true
              },
        state.enabledFieldsGroup
      )
    }),
    disableAllFields: state => ({
      enabledFieldsGroup: R.map(
        field =>
          field.required
            ? field
            : {
                ...field,
                enabled: false
              },
        state.enabledFieldsGroup
      )
    }),
    updateEnabledFields: (state, { payload: { index } }) => {
      const difference = R.differenceWith(
        (x, y) => x.id === y.id,
        state.enabledFieldsGroup,
        state.fieldGroups[index].fields
      );
      const newFields = R.compose(
        mapField,
        R.filter(
          field =>
            field.enabled &&
            !(
              field.name === "Full Name" ||
              field.id === FIELD_IDS.FIRST_NAME ||
              field.id === FIELD_IDS.LAST_NAME
            )
        )
      )(difference);

      return {
        selectedFieldsFilter: "",
        mapObject: {
          ...state.mapObject,
          fields_groups: [
            ...state.mapObject.fields_groups.slice(0, index),
            {
              ...state.mapObject.fields_groups[index],
              fields: state.enabledFieldsGroup
            },
            ...state.mapObject.fields_groups.slice(index + 1)
          ]
        },
        selectMoreModal: false,
        fieldGroupsIndex: -1,
        enabledFieldsGroup: [],
        fieldGroups: [
          ...state.fieldGroups.slice(0, index),
          {
            ...state.fieldGroups[index],
            fields: [
              ...newFields,
              ...state.fieldGroups[index].fields.filter(field =>
                state.enabledFieldsGroup.every(
                  f => !(field.id === f.id && !f.enabled)
                )
              )
            ]
          },
          ...state.fieldGroups.slice(index + 1)
        ]
      };
    },
    setUploadCsvErrors: (
      state,
      { payload: { uploadCsvErrors, selectedPage } }
    ) => ({
      uploadCsvErrors,
      selectedPage
    })
  },
  namespace: NAMESPACE
});

export default handlers;
