import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import SettingsPopover from "components/Global/Module/Sidebar/Popovers/Settings";
import Tooltip from "components/Global/Tooltip";
import EditName from "./EditName";
import { Div } from "components/Base";

@CSSModules(css)
class SidebarViewPicker extends Component {
  state = {
    showPopover: false,
    editing: false,
    name: this.props.activeViewName
  };

  onRequestClose = callback => {
    this.setState({ showPopover: false });
    if (typeof callback === "function") return callback();
    return true;
  };

  handleClick = e => {
    e.stopPropagation();
    if (this.state.editing) return;
    return this.setState({ showPopover: true });
  };

  showEditName = () => {
    this.setState({ editing: true });
  };

  saveName = name => {
    this.props.updateViewName(name);
    this.setState({ editing: false });
  };

  duplicateView = async () => {
    await this.props.duplicateView();
    this.showEditName();
  };

  cancelEditingName = () => this.setState({ editing: false });

  composeClosePopover = callback => (...args) => {
    if (typeof callback === "function") callback(...args);
    this.onRequestClose();
  };

  render() {
    return (
      <div>
        <div styleName="button" role="button" tabIndex={0}>
          <div
            styleName="buttonLeft"
            onClick={this.handleClick}
            ref={ref => {
              this.anchor = ref;
            }}
          >
            {this.state.editing ? (
              <EditName
                name={this.props.activeViewName}
                save={this.saveName}
                cancel={this.cancelEditingName}
              />
            ) : (
              <div styleName="name">
                <i className="material-icons">arrow_drop_down</i>
                <div styleName="label">{this.props.activeViewName}</div>
              </div>
            )}
          </div>
          <div styleName="buttonRight">
            <SettingsPopover
              isPublic={this.props.activeViewIsPublic}
              toggleIsPublic={this.props.toggleIsPublic}
              showEditName={this.showEditName}
              duplicateView={this.duplicateView}
              deleteView={this.props.deleteView}
            >
              <div styleName="visibilityIcon">
                <Tooltip tooltip="Configure view">
                  <i className="material-icons">settings</i>
                </Tooltip>
              </div>
            </SettingsPopover>
          </div>
        </div>

        <Popover
          open={this.state.showPopover}
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          transitionDuration={0.1}
          onClose={this.onRequestClose}
        >
          {this.props.children({
            closePopover: this.onRequestClose,
            composeClosePopover: this.composeClosePopover,
            showEditName: this.showEditName
          })}
        </Popover>
      </div>
    );
  }
}

SidebarViewPicker.propTypes = {
  activeViewIsPublic: PropTypes.bool.isRequired,
  activeViewName: PropTypes.string.isRequired,
  activeViewType: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  toggleIsPublic: PropTypes.func.isRequired,
  updateViewName: PropTypes.func.isRequired,
  duplicateView: PropTypes.func.isRequired,
  deleteView: PropTypes.func.isRequired
};

export default SidebarViewPicker;
