import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { AddCircleIcon, BigOutlineButton } from "components/Base";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { PackageContainer } from "Sponsors/Common/View";
import { Row } from "ui-kit/Form/View";
import { actions } from "Sponsors/SelfSetup";

import PackageTable from "Sponsors/PackageTable/View";
import { VIEWS } from "Sponsors/PackageTable/constants";

const decorate = R.compose(
  connect(
    null,
    {
      setPrices: actions.setPrices,
      deletePackage: id => actions.deletePackageRequest(id)
    }
  ),
  WithThemeConsumer({ variant: VARIANTS.SURFACE })
);

const PackagesList = ({
  Th = defaultTh,
  title = "",
  caption = "",
  onAdd = noop,
  handlers = {},
  items = [],
  testId = "",
  setPrices = noop,
  deletePackage = noop
}) => (
  <PackageContainer title={title} caption={caption}>
    {R.length(items) > 0 ? (
      <PackageTable
        items={items}
        handlers={{
          onSetPrices: setPrices,
          onDelete: deletePackage
        }}
        view={VIEWS.CREATE_PACKAGES}
      />
    ) : null}
    <Row width={1} mt={2}>
      <BigOutlineButton
        {...Th(["color", "bc"], { ml: 1 })}
        LeftIcon={AddCircleIcon}
        onClick={onAdd}
        data-cy={`${testId}_add`}
      >
        Add Item
      </BigOutlineButton>
    </Row>
  </PackageContainer>
);

export default decorate(PackagesList);
