import React from "react";
import { connect } from "react-redux";

import { getters } from "../index";
import { getResourceOptions, getScheduleTimezone } from "../selectors";

import { SCHEDULE_INSTANCE_ID } from "../constants";

import TimeTable from "ui-kit/TimeTable/View";

import { Div } from "components/Base";

import Toolbar from "./CalendarToolbar";

const decorate = connect((state, props) => ({
  resourceOptions: getResourceOptions(state, props),
  loading: getters.loading(state, props),
  timezone: getScheduleTimezone(state, props)
}));

const CalendarLayout = ({
  handlers,
  loading = false,
  resourceOptions = [],
  style = {},
  timezone,
  ...styleProps
}) => (
  <Div {...styleProps} style={{ ...style, overflowX: "hidden" }}>
    <Toolbar instanceId={SCHEDULE_INSTANCE_ID} mr={2} handlers={handlers} />
    <TimeTable
      resourceOptions={resourceOptions}
      loading={loading}
      instanceId={SCHEDULE_INSTANCE_ID}
      timezone={timezone}
    />
  </Div>
);

export default decorate(CalendarLayout);
