import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Avatar = ({ color }) => (
  <div styleName="avatar" style={{ backgroundColor: color }}>
    <i className="material-icons" styleName="icon">
      person
    </i>
  </div>
);

Avatar.defaultProps = {
  color: "#D4D4D4"
};

Avatar.propTypes = {
  color: PropTypes.string
};

export default CSSModules(Avatar, css);
