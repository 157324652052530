/* eslint-disable no-underscore-dangle */
import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "../model";
import { getIntakeForm } from "../selectors";
import { EDIT_MODAL_DATA } from "../constants";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import copy from "copy-to-clipboard";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { registerError } from "redux/modules/errors/actions";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";
import { getters as FormGetters } from "ui-kit/Form/model";
import { formatDateTimezone } from "./index";
import { getDataOrdered } from "../utils";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const getForms = function*({ payload: showLoading }) {
  try {
    if (showLoading) {
      yield put(actions.setLoadingForms(true));
    }
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const [{ forms }, { payload }] = yield all([
      call(apiCall, {
        method: "get",
        url: `/form/list/${eventId}`
      }),
      call(apiCall, {
        method: "get",
        url: `/hub/admin/event/${eventId}/type/${recordTypeId}/forms`
      })
    ]);

    const moduleId = yield select(getters.moduleId);
    const formsToUse =
      moduleId === STANDARD_MODULE_IDS.accounts.id
        ? forms.filter(f => f.scope === "account")
        : forms.filter(f => f.scope === "contact");

    yield put(actions.setForms(formsToUse));
    yield put(
      actions.setAssignedForms(R.sort((a, b) => a.order - b.order, payload))
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading forms"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingForms(false));
  }
};

const copyIntakeFormLink = function*() {
  const eventDetails = yield select(getEventDetails);
  const intakeForm = yield select(getIntakeForm);
  yield call(
    copy,
    `${window.__LENND_APP_URL__}/intake/${eventDetails.slug}/${intakeForm.id}`
  );
  yield put(showSnackbar({ message: "Copied" }));
};

const goToForms = function*() {
  const eventId = yield select(getEventId);

  yield call(
    window.open,
    `${window.__LENND_APP_URL__}/event-light/${eventId}/forms`,
    "_blank"
  );
};

const updateAssignedForms = function*() {
  try {
    const eventId = yield select(getEventId);
    const assignedIds = yield select(MiniItemsGetters.selectedItems);
    const recordTypeId = yield select(getters.selectedTypeId);
    const assignedItems = yield select(getters.assignedForms);

    const items = getDataOrdered({
      assignedIds,
      assignedItems
    });

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/forms`,
      data: { items }
    });

    yield all([
      put(actions.getForms()),
      put(actions.setShowAssignModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned forms"
        }
      ])
    );
  }
};

const deleteAssginedForm = function*({ payload: formId }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/form/${formId}`
    });

    yield put(actions.getForms());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred deleting assigned form"
        }
      ])
    );
  }
};

const updateAssginedForm = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);
    const formId = yield select(getters.selectedModalId);
    const fields = yield select(FormGetters.fields);
    const closeDate =
      R.pathOr(null, [EDIT_MODAL_DATA.DUE_DATE, "value"], fields) || null;
    const formattedDate = yield call(formatDateTimezone, closeDate);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/form/${formId}`,
      data: {
        alias: R.pathOr("", [EDIT_MODAL_DATA.ALIAS, "value"], fields),
        [EDIT_MODAL_DATA.REQUIRED]: R.pathOr(
          false,
          [EDIT_MODAL_DATA.REQUIRED, "value"],
          fields
        ),
        close_date: formattedDate,
        order: 0
      }
    });

    yield all([put(actions.getForms(true)), put(actions.closeUpdateModal())]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned form"
        }
      ])
    );
  }
};

export const toggleRequiredForm = function*({ fieldId, required }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/form/${fieldId}`,
      data: {
        required
      }
    });

    yield put(actions.getForms());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned form"
        }
      ])
    );
  }
};

const reorderForms = function*({ payload: orderedItems }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/forms`,
      data: {
        items: orderedItems.map(({ id }, order) => ({ id, order }))
      }
    });

    yield all([
      put(actions.getForms()),
      put(actions.setShowAssignModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned forms"
        }
      ])
    );
  }
};

const watchGetForms = function*() {
  yield takeEvery(actions.getForms.type, getForms);
};

const watchCopyIntakeFormLink = function*() {
  yield takeEvery(actions.copyIntakeFormLink.type, copyIntakeFormLink);
};

const watchGoToForms = function*() {
  yield takeEvery(actions.goToForms.type, goToForms);
};

const watchUpdateAssignedForms = function*() {
  yield takeEvery(actions.updateAssignedForms.type, updateAssignedForms);
};

const watchDeleteAssignedForm = function*() {
  yield takeEvery(actions.deleteAssginedForm.type, deleteAssginedForm);
};

const watchUpdateAssginedForm = function*() {
  yield takeEvery(actions.updateAssginedForm.type, updateAssginedForm);
};

const watchReorderForms = function*() {
  yield takeEvery(actions.reorderForms.type, reorderForms);
};

const rootSaga = function*() {
  yield all([
    fork(watchCopyIntakeFormLink),
    fork(watchGetForms),
    fork(watchGoToForms),
    fork(watchUpdateAssignedForms),
    fork(watchDeleteAssignedForm),
    fork(watchUpdateAssginedForm),
    fork(watchReorderForms)
  ]);
};

export default rootSaga;
