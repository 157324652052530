import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { Helmet } from "react-helmet";
import { Div } from "components/Base";
import { getters } from "HealthPass/HealthPassForm/model";
import Logo from "HealthPass/HealthPassForm/View/Logo";
import Steps from "HealthPass/HealthPassForm/View/Steps";
import WelcomePage from "HealthPass/HealthPassForm/View/WelcomePage";
import QuestionsPage from "HealthPass/HealthPassForm/View/QuestionsPage";
import Loading from "ui-kit/Loading";
import PlatformLoading from "components/Platform/PlatformLoading";
import MediaQuery from "react-responsive";
import PoweredbyLennd from "components/PortalV2/PoweredbyLennd";

import { getPageTitle } from "HealthPass/HealthPassForm/selectors";

const Small = props => <MediaQuery {...props} maxWidth={420} />;
const Big = props => <MediaQuery {...props} minWidth={421} />;
const Mobile = props => <MediaQuery {...props} maxWidth={820} />;
const Default = props => <MediaQuery {...props} minWidth={821} />;

const decorate = connect(state => ({
  activeIndex: getters.activeIndex(state),
  formData: getters.formData(state),
  loading: getters.loading(state),
  notFound: getters.notFound(state),
  pageTitle: getPageTitle(state)
}));

const Pages = ({ mobile, activeIndex, loading }) => {
  return !loading ? (
    <>
      <Logo />
      <Steps mobile={mobile} />
      {activeIndex === 0 && <WelcomePage mobile={mobile} />}
      {activeIndex === 1 && <QuestionsPage />}
    </>
  ) : (
    <Loading height={400} />
  );
};

const Layout = ({ activeIndex, formData, loading, pageTitle, notFound }) => {
  if (loading) {
    return <PlatformLoading message="Loading..." />;
  }
  if (notFound) {
    return (
      <Div width={1} height={1} display="row.center.center">
        <Div color="gray7">This link is not valid.</Div>
      </Div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Div bg="white">
        <Small>
          <Div
            style={{
              backgroundImage: R.propOr("", "background_image_url", formData)
                ? `url("${R.propOr("", "background_image_url", formData)}")`
                : "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              position: "fixed"
            }}
            height={330}
            width={1}
          />
        </Small>
        <Big>
          <Div
            style={{
              backgroundImage: R.propOr("", "background_image_url", formData)
                ? `url("${R.propOr("", "background_image_url", formData)}")`
                : "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              position: "fixed"
            }}
            width={1}
            height={440}
          />
        </Big>
        <Mobile>
          <Div
            display="column.flex-start.center"
            width={1}
            style={{ position: "absolute", top: 135, left: 0 }}
          >
            <Div
              bg="white"
              width={1}
              mb={4}
              p={4}
              style={{
                borderRadius: "6px",
                boxShadow:
                  "0 9px 17px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.17)"
              }}
            >
              <Pages mobile activeIndex={activeIndex} loading={loading} />
            </Div>

            <PoweredbyLennd
              style={{
                margin: "20px 0"
              }}
            />
          </Div>
        </Mobile>
        <Default>
          <Div
            style={{ position: "absolute", top: 135, left: 0 }}
            display="column.flex-start.center"
            width={1}
          >
            <Div
              bg="white"
              width={820}
              mb={4}
              pt={10}
              pb={8}
              px={14}
              style={{
                borderRadius: "6px",
                boxShadow:
                  "0 9px 17px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.17)"
              }}
            >
              <Pages activeIndex={activeIndex} loading={loading} />
            </Div>

            <PoweredbyLennd
              style={{
                margin: "20px 0"
              }}
            />
          </Div>
        </Default>
      </Div>
    </>
  );
};

export default decorate(Layout);
