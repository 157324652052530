import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tooltip from "components/Global/CRM/Tooltip";
import AccountUser from "components/Global/CRM/Users/AccountUser";

const Team = ({ users, onAddTeamMember }) => (
  <div>
    <div styleName="label">
      Your team
      <div styleName="tooltip">
        <Tooltip
          message="Workers or managers who can access the forms belonging to this group"
          placement="bottom"
        />
      </div>
    </div>
    {users.map(u => (
      <AccountUser key={u.id} {...u} />
    ))}
    <div styleName="button" onClick={onAddTeamMember}>
      <div styleName="addMember">
        <i className="material-icons">add</i>
        Add member...
      </div>
    </div>
  </div>
);

Team.defaultProps = {
  users: []
};

Team.propTypes = {
  onAddTeamMember: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object)
};

export default CSSModules(Team, css);
