import moment from "moment";
import hexRgb from "hex-rgb";

const longDash = String.fromCharCode(8211);

function getColor(date, colorHash = {}) {
  return hexRgb(colorHash[date] || "CCC").join(", ");
}

function isConsecutive(first, second) {
  return second.diff(first, "days") === 1;
}

function buildDateObject(dates, colorHash) {
  return dates.reduce((list, date, idx) => {
    const color = getColor(date, colorHash);
    if (idx === 0) return [{ color, label: moment(date).format("MMM D") }];
    const current = moment(date);
    const previous = moment(dates[idx - 1]);
    const previousColor = getColor(previous.format("YYYY-MM-DD"), colorHash);
    const lastIndex = list.length - 1;

    if (
      // is consecutive and same month (as previous date - it may span two different months) and of same color group
      isConsecutive(previous, current) &&
      color === previousColor &&
      current.format("MMM") === previous.format("MMM")
    ) {
      const value = list[lastIndex].label.split(longDash);
      if (value.length > 1) {
        list[lastIndex].label = `${value[0]}${longDash}${value[1].replace(
          /\d+/g,
          current.format("D")
        )}`;
      } else {
        list[lastIndex].label = `${value[0]}${longDash}${current.format("D")}`;
      }
    } else if (
      // is consecutive and new month and of same color group
      isConsecutive(previous, current) &&
      color === previousColor
    ) {
      list[lastIndex].label = `${
        list[lastIndex].label.split(/([–])/g)[0]
      }${longDash}${current.format("MMM D")}`;
    } else if (
      // is a new month
      current.format("MMM") !== previous.format("MMM")
    ) {
      list.push({ color, label: moment(date).format("MMM D") });
    } else {
      list.push({ color, label: moment(date).format("D") });
    }
    return list;
  }, []);
}
export default function(dateList = [], dateGroups = []) {
  const colorHash = dateGroups.reduce((acc, v) => {
    v.days.forEach(day => {
      acc[day] = v.color;
    });
    return acc;
  }, {});
  return buildDateObject(dateList, colorHash);
}
