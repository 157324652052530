import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/countries";
import countries from "./countries";
import { sortBy } from "lodash";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import { Dropdown } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class CountriesEditor extends Component {
  wrapValue(value) {
    return {
      type: "countries",
      value: value || undefined
    };
  }

  formatValue(value) {
    return getValue(value || {});
  }

  @autobind
  onChange(selected) {
    this.props.onChange(this.wrapValue(selected ? selected.value : selected));
  }

  render() {
    const { innerRef, disabled, required, noTether } = this.props;
    const countryCode = this.formatValue(this.props.value);

    return (
      <div>
        <Dropdown
          arrowRenderer={ArrowRenderer}
          options={sortBy(
            Object.keys(countries).map(v => ({
              value: v,
              label: countries[v]
            })),
            "label"
          )}
          ref={innerRef}
          openOnFocus
          clearable
          simpleValue
          disabled={disabled}
          required={required}
          value={countryCode}
          onChange={this.onChange}
          searchable
          noTether={noTether}
          usePortal={this.props.usePortal}
          menuPlacement="auto"
        />
      </div>
    );
  }
}

CountriesEditor.propTypes = {
  disabled: PropTypes.bool,
  innerRef: PropTypes.func,
  noTether: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.object
};

export default CountriesEditor;
