import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { ACCEPTABLE_FORMATS } from "Modules/ImportModal/constants";
import { Div, Text1, Text2, Text3 } from "components/Base";
import { getFields } from "Modules/ImportModal/selectors";

const decorate = connect(state => ({
  fields: getFields(state)
}));

const AcceptableFormats = ({ fields }) => (
  <Div mt={3} bg="white" bc="neutral2" bra={1} display="column">
    <Div display="row">
      <Div display="row" style={{ width: "50%" }} p={2}>
        <Text2 bold>FIELD</Text2>
      </Div>
      <Div display="column" style={{ width: "50%" }} p={2} bl={1} bc="neutral2">
        <Text2 bold>ACCEPTABLE FORMAT</Text2>
      </Div>
    </Div>
    {R.map(
      field => (
        <Div display="row" bt={1} bc="neutral2">
          <Div display="column" style={{ width: "50%", flexShrink: 0 }} p={2}>
            <Div display="row.flex-start.center">
              <Text3 bold>{field.column}</Text3>
              {field.required && (
                <Text2 color="red8" pl={2}>
                  {"(Required)"}
                </Text2>
              )}
            </Div>
            <Text1>{field.type}</Text1>
          </Div>
          <Div
            style={{ width: "50%", flexShrink: 0 }}
            p={2}
            bl={1}
            bc="neutral2"
            display="column"
            style={{
              whiteSpace: "pre-line"
            }}
          >
            <Text3>{ACCEPTABLE_FORMATS[field.type]}</Text3>
          </Div>
        </Div>
      ),
      fields
    )}
  </Div>
);

export default decorate(AcceptableFormats);
