import React from "react";
import * as R from "ramda";
import { Div, PeopleIcon } from "components/Base";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import {
  getFiltersOn,
  getColumnActions,
  getRowActions,
  getPeopleFilterOn,
  getPeopleFilterMessage
} from "../selectors";
import { actions, getters } from "EventLight/Common/Dashboard";
import { TABLE_INSTANCE_ID } from "EventLight/Common/Dashboard/constants";

import PaginationBar from "./PaginationBar";
import Toolbar from "./Toolbar";
import Tabs from "./Tabs";
import FiltersOn from "ui-kit/FiltersOn";
import Sidebar from "./Sidebar";
import EmptyState from "EventLight/Common/EmptyState";

import ShowNoResults from "ui-kit/ShowNoResults";

import Table, { TableWrapper } from "ui-kit/Table/View";
import Snackbar from "ui-kit/Snackbar/View";

import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      filtersOn: getFiltersOn(state, props),
      loading: getters.loading(state, props),
      showNoResults: getters.showNoResults(state, props),
      preferences: getters.preferences(state, props),
      columnActions: getColumnActions(state, props),
      rowActions: getRowActions(state, props),
      peopleFilterOn: getPeopleFilterOn(state, props),
      peopleFilterMessage: getPeopleFilterMessage(state, props),
      showSelectRowColumn: getters.showSelectRowColumn(state, props),
      showOpenRecordColumn: getters.showOpenRecordColumn(state, props),
      canShowDeDuplicate: selectFeatureFlag(
        flags.CAN_VIEW_RECORDS_DEDUPLICATION.NAME
      )(state),
      showSidebar: getters.showSidebar(state, props),
      showEmptyState: getters.showEmptyState(state, props),
      emptyStateIcon: getters.emptyStateIcon(state, props),
      emptyStateTitle: getters.emptyStateTitle(state, props),
      emptyStateDescription: getters.emptyStateDescription(state, props),
      moduleId: getters.moduleId(state)
    }),
    {
      onClearFilters: actions.clearFilters,
      updatePeopleType: actions.updatePeopleType
    }
  )
);

const Body = ({
  instanceId,
  handlers,
  filtersOn,
  onClearFilters,
  rowActions = [],
  columnActions = [],
  loading = false,
  showNoResults = false,
  peopleFilterOn,
  peopleFilterMessage,
  updatePeopleType,
  showSelectRowColumn,
  showOpenRecordColumn,
  canShowDeDuplicate,
  showSidebar,
  showEmptyState,
  emptyStateIcon,
  emptyStateTitle,
  emptyStateDescription,
  moduleId
}) => (
  <Div display="row.center.stretch" style={{ height: "calc(100vh - 116px)" }}>
    {showSidebar ? (
      <Sidebar handlers={handlers} instanceId={instanceId} />
    ) : null}
    <Div
      flex={1}
      display="column.center.stretch"
      width={5} // @NOTE: This is a hack to have the table width nicely fit
    >
      <Div
        pr={4}
        bg="white"
        display="column.space-between.stretch"
        style={{ flexShrink: 0, position: "relative" }}
        bb={1}
        bc="neutral3"
      >
        <Toolbar handlers={handlers} instanceId={instanceId} />
        <Tabs />
      </Div>

      <FiltersOn
        on={peopleFilterOn}
        onClearFilters={() => updatePeopleType(null)}
        caption={peopleFilterMessage}
        Icon={PeopleIcon}
        bg="primary1"
      />
      <FiltersOn
        on={filtersOn}
        onClearFilters={onClearFilters}
        caption="Only showing results that contain or match filters"
      />

      {!loading && showEmptyState ? (
        <EmptyState
          icon={emptyStateIcon}
          title={emptyStateTitle}
          description={emptyStateDescription}
          style={{
            margin: "30px",
            flex: 1
          }}
        />
      ) : showNoResults ? (
        <ShowNoResults flex={1} />
      ) : (
        <TableWrapper
          loading={loading}
          style={{ flex: 1 }}
          instanceId={TABLE_INSTANCE_ID}
        >
          <Table
            instanceId={TABLE_INSTANCE_ID}
            rowActions={rowActions}
            columnActions={columnActions}
            showEmptyTable={true}
            showOpenRecord={
              !showOpenRecordColumn || peopleFilterOn ? false : true
            }
            canShowDeDuplicate={canShowDeDuplicate}
            showCheckbox={showSelectRowColumn}
            moduleId={moduleId}
          />
        </TableWrapper>
      )}

      <PaginationBar />
    </Div>
    <Snackbar />
  </Div>
);

export default decorate(Body);
