import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Blockquote = ({ comment, ...props }) => (
  <div styleName="container">
    <div
      styleName="inner"
      dangerouslySetInnerHTML={{ __html: comment }}
      {...props}
    />
  </div>
);

Blockquote.propTypes = {
  comment: PropTypes.string.isRequired
};

export default CSSModules(Blockquote, css);
