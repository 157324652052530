import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import {
  Text2,
  BigOutlineButton,
  UploadIcon,
  LinkIcon,
  CloseIcon
} from "components/Base";
import { actions } from "EventLight/Expo/Sales/model";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { PackageContainer } from "Sponsors/Common/View";
import { Column, Row } from "ui-kit/Form/View";
import { getFloormapImageUrl } from "EventLight/Expo/Sales/selectors";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    state => ({
      imageUrl: getFloormapImageUrl(state)
    }),
    {
      showFloormapImageModal: actions.showFloormapImageModal,
      removeFloormap: actions.removeFloormap
    }
  )
);

const Venue = ({
  Th = defaultTh,
  imageUrl,
  showFloormapImageModal,
  removeFloormap
}) => (
  <PackageContainer
    title="Venue / Floormap"
    // caption={<Text2>View Example</Text2>}
  >
    <Column {...Th(["bg"], { flex: 1, p: 3, bra: 1 })}>
      {imageUrl ? (
        <Row style={{ flexGrow: 0 }}>
          <img style={{ width: "100%" }} src={imageUrl} />
        </Row>
      ) : null}
      <Row mt={2}>
        <BigOutlineButton
          onClick={showFloormapImageModal}
          LeftIcon={UploadIcon}
          {...Th(["bc", "color"])}
        >
          {imageUrl ? "Re-Upload Floormap" : "Upload Floormap"}
        </BigOutlineButton>
        {imageUrl ? (
          <BigOutlineButton
            onClick={removeFloormap}
            LeftIcon={CloseIcon}
            ml={2}
            {...Th(["bc", "color"])}
          >
            Remove
          </BigOutlineButton>
        ) : null}
        {/*
        <BigOutlineButton
          LeftIcon={LinkIcon}
          {...Th(["bc", "color"], { ml: 2 })}
        >
          Link to an existing Floormap
        </BigOutlineButton>
        */}
      </Row>
    </Column>
  </PackageContainer>
);

export default decorate(Venue);
