import { get } from "lodash";

export const isFetching = (state, recordId) =>
  get(state, `modules.subscription.fetching[${recordId}]`, false);
export const subscription = (state, recordId) =>
  get(state, `modules.subscription.subscription[${recordId}]`, {
    is_autosubscribed: false,
    is_subscribed: false,
    is_ignored: false
  });
