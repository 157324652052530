import PropTypes from "prop-types";
import React from "react";
import Panel from "components/Global-2016/Panel";
import ModuleSummaries from "components/Event/FormsV2/Overview/ModuleSummaries";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import validatedEntries from "components/Event/FormsV2/Overview/utils/summaries/validEntries";
import Entry from "./FieldEntry";

const SubmissionCard = ({
  actions,
  badges,
  contextMenu,
  entries,
  isLocked,
  onShowSummary,
  showSummaries,
  submitter,
  summaries
}) => (
  <Panel onMouseDown={onShowSummary} style={css.panel}>
    <div styleName="container">
      <div styleName="header">
        <div styleName="submitter">{submitter}</div>
        <div styleName="headerIcons">
          {isLocked ? (
            <i className={[css.lockedIcon, "material-icons"].join(" ")}>lock</i>
          ) : (
            ""
          )}
          {contextMenu}
        </div>
      </div>
      <div styleName="badgeContainer">{badges.map(b => b)}</div>
      <div styleName="fields">
        {entries.map((entry, index) => (
          <Entry key={`${entry.field.id}-${index}`} {...entry} />
        ))}
        {showSummaries ? (
          <ModuleSummaries
            showApprovals={summaries.some(({ values }) =>
              values.some(v => v.approved)
            )}
            showRejections={summaries.some(({ values }) =>
              values.some(v => v.rejected)
            )}
            counts={validatedEntries(summaries)}
          />
        ) : (
          ""
        )}
      </div>
      {actions}
    </div>
  </Panel>
);

SubmissionCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node).isRequired,
  onShowSummary: PropTypes.func.isRequired,
  contextMenu: PropTypes.node.isRequired,
  summaries: PropTypes.array.isRequired,
  entries: PropTypes.array.isRequired,
  badges: PropTypes.array.isRequired,
  submitter: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  showSummaries: PropTypes.bool
};

export default CSSModules(SubmissionCard, css);
