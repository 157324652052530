import { put, select, call, takeEvery, all, fork } from "redux-saga/effects";
import * as R from "ramda";

import { userId, getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import { actions } from "ApproversLabel";
import approvalsApi from "ApproversLabel/api";

import manageBy from "Items/Manage";

// Approvals
const manifest = function*({ payload, meta }) {
  const id = yield select(userId);
  const credentials = yield select(getCredentials);

  try {
    const result = yield call(approvalsApi.getManifest, {
      credentials,
      userId: id,
      recordType: payload.type,
      recordIds: payload.recordIds,
      manifestId: payload.manifestId
    });
    yield put(actions.manifestResponse(result.payload, { meta }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching manifest"
          }
        ])
      ),
      put(
        actions.setLoadingApproval(
          { id: payload.manifestId, status: false },
          { meta },
          true
        )
      )
    ]);
  }
};

const review = function*({
  type,
  payload: { type: targetType, recordIds },
  meta
}) {
  const credentials = yield select(getCredentials);
  const userIdToUse = yield select(userId);
  const response = R.propOr(null, type)({
    [actions.onApprove.type]: "approve",
    [actions.onDeny.type]: "reject"
  });
  const action = R.propOr(null, type)({
    [actions.onApprove.type]: "addReview",
    [actions.onDeny.type]: "addReview",
    [actions.onUndoReview.type]: "undoReview"
  });
  const property = R.propOr(null, targetType)({
    lineItem: "lineItemId",
    record: "recordId"
  });

  try {
    // make review
    yield call(approvalsApi[action], credentials, {
      userId: userIdToUse,
      bulk: true,
      reviews: R.map(id => ({
        // manifestId: 0,
        [property]: id,
        response,
        userId: userIdToUse
      }))(recordIds)
    });

    // notify listeners that review has been completed
    yield put(actions.reviewDone(null, { meta }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding review"
          }
        ])
      )
    ]);
  }
};

const remove = function*({ payload: lineItemIds, meta }) {
  const id = yield select(userId);
  const credentials = yield select(getCredentials);

  try {
    // remove line items
    yield call(approvalsApi.removeLineItems, {
      credentials,
      userId: id,
      bulk: true,
      lineItemIds
    });

    // refresh view and stats
    yield all([
      put(manageBy.actions.fetchData(null, { meta })),
      put(manageBy.actions.fetchStats(null, { meta }))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred removing line items"
          }
        ])
      )
    ]);
  }
};

const watchFetchManifest = function*() {
  yield takeEvery(actions.fetchManifest.type, manifest);
};
const watchRemove = function*() {
  yield takeEvery(actions.onRemove.type, remove);
};
const watchReview = function*() {
  yield takeEvery(
    [actions.onApprove.type, actions.onDeny.type, actions.onUndoReview.type],
    review
  );
};

const rootSaga = function*() {
  yield all([fork(watchFetchManifest), fork(watchReview), fork(watchRemove)]);
};

export default rootSaga;
