import { call, all, fork, takeEvery, select, put } from "redux-saga/effects";
import { actions } from "Notifications";
import { getCredentials } from "redux/modules/user/selectors";
import { actions as NotificationActions } from "ui-kit/Notifications/model";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";

import api from "./api";

const init = function*() {
  const credentials = yield select(getCredentials);
  try {
    const count = yield call(api.get, credentials);
    yield put(actions.setCountOfUnseenNotifications(count));
  } catch (error) {
  } finally {
  }
};

const openSiderbar = function*({ meta: { instanceId } }) {
  yield put(
    NotificationActions.setIsDrawerOpen(null, {
      meta: { instanceId: instanceId }
    })
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchOpenSidebar = function*() {
  yield takeEvery(actions.openSiderbar.type, openSiderbar);
};

const watchCloseSiderbar = function*() {
  yield takeEvery(sliderSidebarActions.close.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchOpenSidebar),
    fork(watchCloseSiderbar)
  ]);
};

export default rootSaga;
