import { useState, useEffect } from "react";
import moment from "moment";

const useNow = (delay = 30000) => {
  const [now, setNow] = useState(moment().utc());

  useEffect(() => {
    const timer = setInterval(() => setNow(moment().utc()));

    return () => clearInterval(timer);
  }, [delay]);

  return now;
};

export { useNow };
