import * as R from "ramda";
import { createSelector } from "reselect";
import { makeInstanceSelector } from "redux-mvc";

import { getters } from "ui-kit/FilterControls";
import { TYPES } from "ui-kit/FilterControls/constants";

const getInstance = (_, props) => R.prop("instanceId", props);

const optionsByType = {
  [TYPES.MULTIPLE]: makeInstanceSelector(
    (_, props) => R.prop("options", props),
    getters.selectedOptions,
    (options, selectedOptions) =>
      R.map(
        option =>
          R.assoc("selected", R.has(option.id, selectedOptions), option),
        options
      )
  )(getInstance),
  [TYPES.CHECKBOX]: makeInstanceSelector(
    (_, props) => R.prop("options", props),
    getters.selectedOptions,
    (options, selectedOptions) =>
      R.map(
        option =>
          R.assoc(
            "selected",
            R.isEmpty(selectedOptions)
              ? option.all
              : R.has(option.id, selectedOptions),
            option
          ),
        options
      )
  )(getInstance),
  [TYPES.TOGGLE]: R.compose(
    R.not,
    R.isEmpty,
    getters.selectedOptions
  )
};

optionsByType[TYPES.RADIO] = optionsByType[TYPES.CHECKBOX];

export const getOptions = (state, props) => {
  const selector = optionsByType[props.type] || optionsByType[TYPES.MULTIPLE];
  return selector(state, props);
};

export const getFiltersOn = makeInstanceSelector(
  getters.selectedOptions,
  selectedOptions => !R.isEmpty(selectedOptions)
)(getInstance);

export const makeGetAllFiltersOn = instances =>
  createSelector(
    R.map(
      instanceId => (state, props) =>
        getFiltersOn(state, { ...props, instanceId }),
      instances
    ),
    R.unapply(R.any(R.identity))
  );

export const getAllFiltersOn = (state, props) => {
  const instances = R.propOr([], "filters", props);
  return R.all(
    R.identity,
    R.map(
      instanceId => (state, props) =>
        getFiltersOn(state, { ...props, instanceId }),
      instances
    )
  );
};
