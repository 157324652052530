import * as R from "ramda";
import {
  put,
  all,
  takeEvery,
  fork,
  call,
  select,
  take
} from "redux-saga/effects";
import Helpers from "utils/Global/Helpers";
import { FORM_ID, FIELD_IDS } from "./constants";
import { apiCall } from "App/Data/sagas";

import { actions, getters } from "./model";
import { actions as FormActions } from "ui-kit/Form/model";
import { registerError } from "redux/modules/errors/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { getEditedField } from "./selectors";

const FIELDS_THAT_USE_JSON = [
  FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS,
  FIELD_IDS.CHECKOUT.VARIANTS
];

const getParams = function*() {
  const eventId = yield select(getEventId);

  return {
    eventId
  };
};

const init = function*({ payload: { recordTypeId } }) {
  try {
    const { eventId } = yield call(getParams);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/community`
    });

    yield put(
      actions.setInitData({
        recordTypeId,
        ...payload,
        settings:
          payload.settings && payload.settings.length
            ? R.reduce((map, s) => {
                map[s.key] = FIELDS_THAT_USE_JSON.includes(s.key)
                  ? s.json_value
                  : s.text_value;
                return map;
              }, {})(payload.settings)
            : {}
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting community settings"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/png"
      ]
    };

    const path = { path: "settings-page/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const uploadHeaderImg = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield put(actions.updateData({ id: "header_img", value: url }));
};

const saveSettings = function*() {
  try {
    const { eventId } = yield call(getParams);
    const settings = yield select(getters.settings);
    const recordTypeId = yield select(getters.recordTypeId);
    const inheritMediaHubFromRecordTypeId = yield select(
      getters.inheritMediaHubFromRecordTypeId
    );
    const mediaCenterKeys = R.values(FIELD_IDS.MEDIA_CENTER);

    const settingsToSave = R.compose(
      R.map(key => ({
        key,
        text_value: !FIELDS_THAT_USE_JSON.includes(key) ? settings[key] : null,
        json_value: FIELDS_THAT_USE_JSON.includes(key) ? settings[key] : null
      })),
      R.filter(key => {
        return mediaCenterKeys.includes(key);
      }),
      R.keys
    )(settings);

    const toSave = {
      inheritMediaHubFromRecordTypeId,
      settings: settingsToSave
    };

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/community`,
      data: toSave
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred saving settings"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.resetData());
    yield put(
      snackbarActions.showSnackbar({
        message: "Settings Saved"
      })
    );
    yield put(actions.closeModal());
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    // [FIELD_IDS.MEDIA_CENTER.CONTENT]: newValue, // R.slice(0, 330, newValue),
    // [FIELD_IDS.MEDIA_CENTER.TYPE]: R.head(newValue)
    [FIELD_IDS.MEDIA_CENTER.FONT_FAMILY]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.INTERCOM_METHOD]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_LOGO_IMAGE_URL]: R.path(
      [0, "url"],
      newValue
    ),
    [FIELD_IDS.MEDIA_CENTER.EVENT_LOGO_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LOGO_URL]: R.path(
      [0, "url"],
      newValue
    ),
    [FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_TYPE]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_TYPE]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_TYPE]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.SORT_VIDEOS_BY]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.INHERIT_PORTAL]: R.path([0], newValue)
  });

  if (fieldId === FIELD_IDS.MEDIA_CENTER.INHERIT_PORTAL) {
    yield put(actions.setInheritMediaHubFromRecordTypeId(processedValue));
  } else {
    yield put(
      actions.updateSettings({ field: fieldId, value: processedValue })
    );
  }
};

const getItemsToShow = function*() {
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/items/types/event/${eventId}`
    });

    yield put(actions.setItemTypes(payload));
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the item types"
        }
      ])
    );
  }
};

const onSelectItemsToShow = function*({ payload }) {
  yield put(actions.setShowSelectItemsModal(false));
  yield put(
    actions.updateSettings({
      field: FIELD_IDS.CHECKOUT.VARIANTS,
      value: payload
    })
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUploadHeaderImg = function*() {
  yield takeEvery(actions.uploadHeaderImg.type, uploadHeaderImg);
};

const watchSaveSettings = function*() {
  yield takeEvery(actions.saveSettings.type, saveSettings);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchGetItemsToShow = function*() {
  yield takeEvery(actions.getItemsToShow.type, getItemsToShow);
};

const watchOnSelectItemsToShow = function*() {
  yield takeEvery(actions.onSelectItemsToShow.type, onSelectItemsToShow);
};

const rootSaga = function*() {
  yield all([
    fork(watchUploadHeaderImg),
    fork(watchSaveSettings),
    fork(watchUpdateFields),
    fork(watchGetItemsToShow),
    fork(watchOnSelectItemsToShow),
    fork(watchInit)
  ]);
};

export default rootSaga;
