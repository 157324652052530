import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";
import autobind from "autobind-decorator";
import { get, isNil, isEqual } from "lodash";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "../LabelSimpleForm/view";
import moment from "moment";
import isTimeRequiredValid from "utils/value-types/validations/time/required";
import isDateRequiredValid from "utils/value-types/validations/date/required";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import getValue from "utils/value-types/get-value/datetime";

import FormDate from "components/Event/FormsV2/Sections/FormDate";
import FormTime from "components/Event/FormsV2/Sections/FormTime";

import { setOtherZone, setLocalZone } from "utils/General";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const timeFormat = "HH:mm";
const dateFormat = "YYYY-MM-DD";

const groupStyles = {
  width: "100%"
};

@CSSModules(css)
class FormDateTime extends FormBaseInput {
  constructor(props) {
    super(props);
    this.state = {
      value: props.formValues[props.field.id],
      ...this.formatValue(props.formValues[props.field.id])
    };
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: nextProps.formValues[this.props.field.id],
        ...this.formatValue(nextProps.formValues[this.props.field.id])
      });
    }
  }

  formatValue(valueObject) {
    const value = getValue(valueObject);
    if (value && moment(value).isValid()) {
      let dateTime = moment(value);
      if (!get(this.props, "field.settings.useDateTimeTimezone", false)) {
        const offset = get(valueObject, "value.offset");
        if (!isNil(offset)) {
          dateTime = dateTime.utcOffset(offset);
        }
      }
      return {
        date: {
          type: "date",
          value: dateTime.format(dateFormat)
        },
        time: {
          type: "time",
          value: dateTime.format(timeFormat)
        }
      };
    }
    return {};
  }

  wrapValue({ date, time }) {
    const value = {
      type: "datetime"
    };
    if (date && time) {
      const dateTime = moment(
        `${date.value} ${time.value}`,
        `${dateFormat} ${timeFormat}`
      );
      value.value = {
        iso: dateTime.toISOString(),
        offset: dateTime.utcOffset()
      };
    }
    return value;
  }

  @autobind
  async isValid() {
    const errors = [];
    const required = this.props.field.is_required;

    if (required) {
      if (
        !isTimeRequiredValid(this.state.time) ||
        !isDateRequiredValid(this.state.date)
      ) {
        errors.push("This is a required question");
      }
    }

    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  handleSave() {
    this.save(this.props.field.id, this.state.value);
  }

  @autobind
  handleDateChange(id, date) {
    this.setState(
      {
        date,
        value: this.wrapValue({ time: this.state.time, date })
      },
      this.handleSave
    );
  }

  @autobind
  handleTimeChange(_, time) {
    const date = new Date(`1/1/1970 ${time.value}`);
    const otherzone = setOtherZone(date, this.props.field.settings.timezone);
    const value = moment(otherzone).format(timeFormat);
    this.setState(
      {
        time: { type: "time", value },
        value: this.wrapValue({
          date: this.state.date,
          time: { type: "time", value }
        })
      },
      this.handleSave
    );
  }

  @autobind
  getTime() {
    let time;
    if (this.state.time) {
      const date = new Date(`1/1/1970 ${this.state.time.value}`);
      const localzone = setLocalZone(date, this.props.field.settings.timezone);
      time = { type: "time", value: moment(localzone).format(timeFormat) };
    }
    return time;
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }
  render() {
    const {
      field,
      field: {
        name,
        settings: { description, isAdminField }
      },
      connectDragSource,
      canShowSimpleSideBar
    } = this.props;
    const required = this.props.field.is_required;
    const readonly = this.props.field.is_readonly;

    const value = this.formatValue(this.state.value);
    const mockData = {
      id: 1,
      settings: field.settings
    };
    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        {name ? (
          !canShowSimpleSideBar ? (
            <Label
              isAdminField={isAdminField}
              required={required}
              description={description}
              readonly={readonly}
            >
              {name}
            </Label>
          ) : (
            <LabelSimpleForm
              isAdminField={isAdminField}
              required={required}
              description={description}
              readonly={readonly}
              instanceId={this.props.field.id}
              fieldData={this.props.field}
              isShown={this.state.isShown}
              setIsShown={() => this.handleToogleShowEditMode()}
            >
              {name}
            </LabelSimpleForm>
          )
        ) : null}
        <InputGroup style={groupStyles}>
          <div styleName="picker">
            <div styleName="input">
              <FormDate
                {...this.props}
                groupStyles={groupStyles}
                isEditing={false}
                field={mockData}
                isChildren={true}
                formValues={{
                  [mockData.id]: value.date
                }}
                updateFormValue={this.handleDateChange}
              />
            </div>
            <div styleName="middle"> at </div>
            <div styleName="input">
              <FormTime
                {...this.props}
                groupStyles={groupStyles}
                isEditing={false}
                field={mockData}
                isChildren={true}
                updateFormValue={this.handleTimeChange}
                formValues={{
                  [mockData.id]: this.getTime()
                }}
              />
            </div>
          </div>
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormDateTime.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormDateTime;
