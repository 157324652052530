import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import { Div, LoadingIcon } from "components/Base";

const View = Comp => ({ hideModal, loading, ...other }) => {
  return (
    <StyleWrapper
      heading={`Approval Workflow`}
      hideModal={hideModal}
      bodyStyles={{ padding: 0 }}
      width={500}
    >
      {loading ? (
        <Div pt={20} display="row.center.center">
          <LoadingIcon sizeWFS={5} />
        </Div>
      ) : (
        <ScrollableModalBody
          style={{
            minHeight: 400,
            display: "flex",
            backgroundColor: "rgba(224, 224, 224, 0.19)"
          }}
        >
          <Comp {...other} hideModal={hideModal} />
        </ScrollableModalBody>
      )}
    </StyleWrapper>
  );
};

export default View;
