const validLineItems = values =>
  values.filter(
    ({ approved, requested }) =>
      !(
        typeof approved === "number" &&
        typeof requested === "number" &&
        approved === 0 &&
        requested === 0
      )
  );

export default function(entries) {
  return entries.reduce((arr, entry) => {
    entry.values = validLineItems(entry.values);
    if (entry.values.length) {
      arr.push(entry);
    }
    return arr;
  }, []);
}
