import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Portal from "./CellPortal";
import BeforeClose from "./BeforeClose";
import { get } from "lodash";
import Number from "components/Global/Editors/NumberEditor";
import NumberWithDefaults from "components/Global/Editors/NumberWithDefaultsEditor";

export default class NumberEditor extends EditorBase {
  handleClose = () => this.props.onCommit();

  render() {
    const options = get(this.props.column, "settings.options", []);

    if (options.length) {
      return (
        <Portal
          ref="editor"
          onClose={this.handleClose}
          editor={
            <div style={{ width: this.props.column.width - 2 }}>
              <BeforeClose do={this.handleClose}>
                <NumberWithDefaults
                  {...this.props}
                  options={options}
                  value={this.state}
                  onChange={this.handleChange}
                  autofocus
                />
              </BeforeClose>
            </div>
          }
        />
      );
    }
    return (
      <Number
        {...this.props}
        className="editor-main"
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}

NumberEditor.propTypes = {
  ...EditorBase.propTypes,
  column: PropTypes.object.isRequired
};
