import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

import tabsModule from "ui-kit/Tabs";
import searchBarModule from "ui-kit/SearchBar";
import viewPickerGlobalModule from "ui-kit/ViewPicker";
import sliderSidebar from "ui-kit/SliderSidebar";
import {
  TABS,
  NAMESPACE,
  FILTER,
  SORTERS
} from "Schedules/ScheduleViewPicker/constants";

const iniState = {
  loading: false,
  schedules: [],
  activeScheduleId: null,
  activeViewId: null,
  activeSchedule: null,
  activeView: null,
  filterBy: FILTER.ALL,
  sortBy: R.head(SORTERS),
  tabs: TABS
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    selectSchedule: R.identity,
    selectViewOfSchedule: R.identity,
    setIsDrawerOpen: R.identity,
    editSettings: R.identity,
    shareSchedule: R.identity,
    cloneSchedule: R.identity,
    editUsers: R.identity,
    deleteSchedule: R.identity,
    setInitialData: (
      _,
      { payload: { schedules, activeScheduleId, activeViewId } }
    ) => ({
      schedules,
      activeScheduleId,
      activeViewId
    })
  }
});

const viewPickerModule = createModule(handlers);

viewPickerModule.plugModule(tabsModule);
viewPickerModule.plugModule(searchBarModule);
viewPickerModule.plugModule(viewPickerGlobalModule);
viewPickerModule.plugModule(sliderSidebar);

const { actions, getters } = viewPickerModule;

export { actions, getters };

export default viewPickerModule;
