import React, { Component } from "react";
import { RelatedAccountsModal } from "components/Global/Module/Modals/AddRecord/Layouts/Contact/RelatedAccounts";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";
import { getValue, getSettings } from "../../utils";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class AccountList extends Component {
  showRelatedAccountsModal = () => {
    this.props.showModal({
      content: (
        <RelatedAccountsModal
          contactId={this.props.data.id}
          onDone={() => {
            this.props.onDone();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const value = getValue(this.props);
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
      >
        {isFieldReadOnly || (
          <div
            className="pill pill-with-hover"
            onClick={this.showRelatedAccountsModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 20
            }}
          >
            <i
              className="material-icons"
              style={{
                fontSize: "16px"
              }}
            >
              add
            </i>
          </div>
        )}
        {value.map(v => (
          <div
            onClick={isFieldReadOnly ? noop : this.showRelatedAccountsModal}
            key={v.id}
            className={`pill ${isFieldReadOnly || `pill-with-hover`}`}
          >
            {v.name}
          </div>
        ))}
      </div>
    );
  }
}

export default decorate(AccountList);
