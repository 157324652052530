import { REQUEST, RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getCredentials(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST,
        payload: {
          accountId: data.accountId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          accountId: data.accountId,
          credentials: result.result.counts
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching account credentials"
          }
        ])
      );
    }
  };
}
