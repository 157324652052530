import * as R from "ramda";

import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import { ACTIVITY_FIELD_ID } from "./constants";

export const mapResources = R.map(resource => ({
  ...resource,
  records: R.map(
    event => ({
      id: event.id,
      resourceId: resource.title,
      title: R.path(
        // @NOTE: should the activity field id be in value-types?
        [ACTIVITY_FIELD_ID, "value"],
        event
      ),
      backgroundColor: resource.color,
      borderColor: resource.color,
      start: R.path(
        [STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT, "value", "iso"],
        event
      ),
      end: R.path([STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT, "value", "iso"], event)
    }),
    resource.records
  )
}));
