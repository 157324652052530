import React from "react";
import { Div, RightIcon, Text3 } from "components/Base";

const EmptyCard = ({ onClick, label }) => (
  <Div
    ba={1}
    bc={{ default: "neutral2", hover: "primary7" }}
    bra={1}
    display="row.space-between.center"
    px={4}
    py={2}
    onClick={onClick}
    mt={2}
    style={{
      minHeight: "52px"
    }}
  >
    <Text3 bold>Skip selecting a {label}</Text3>
    <RightIcon size={24} />
  </Div>
);

export default EmptyCard;
