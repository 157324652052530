import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, userId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/user/me/events`,
        credentials,
        data: {},
        success: result => {
          resolve(result.payload);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/user/me/starred-events`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
