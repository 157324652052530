import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { cando } from "redux/modules/permissions/user-permission-profile/selectors";
import { withRouter } from "react-router";

function mapStateToProps(state, props) {
  return {
    predicate: cando(
      state,
      props.params.eventId || props.params.orgId,
      state.user.user.id
    )
  };
}

const CanUserDo = ({
  action,
  all,
  any,
  custom,
  children,
  no,
  predicate,
  className
}) => {
  let result;
  if (action && predicate(action)) {
    result = children;
  } else if (any && any.some(predicate)) {
    result = children;
  } else if (all && all.every(predicate)) {
    result = children;
  } else if (custom && custom.predicate(custom.actions.filter(predicate))) {
    result = children;
  } else {
    result = no || "";
  }
  return <>{result}</>;
};

CanUserDo.propTypes = {
  action: PropTypes.string,
  all: PropTypes.array,
  any: PropTypes.array,
  custom: PropTypes.object,
  children: PropTypes.node,
  no: PropTypes.node,
  predicate: PropTypes.func,
  className: PropTypes.string
};

export default withRouter(connect(mapStateToProps)(CanUserDo));
