import Helpers from "utils/Global/Helpers";

module.exports = {
  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/spaces/${
          data.spaceId
        }/workflows/${data.workflowId}/triggers/${data.triggerId}/records`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};

// Update trigger records
// PUT

// this.props.updateTriggerRecords({
// spaceId: SPACE ID,
// workflowId: WORKFLOW ID,
//     "triggerId": "b326e81e-9d13-4386-87e4-dc7eba89dfb9",
//     "records": [{"type": "user", "id": 7}, {"type": "record", "id": "10b44ded-a13a-425b-ae43-d45961accf83"}]
// });
