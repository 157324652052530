import React from "react";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import LinkedRecordsModal from "components/Global/CRM/Modals/LinkedRecords";

const LinkedRecords = ({ showModal, ...props }) => (
  <MiniModalWrapper
    showModal={showModal}
    hideModal={props.hideModal}
    showHeader={false}
    height={600}
    width={745}
    onClick={e => e.stopPropagation()}
  >
    <LinkedRecordsModal {...props} />
  </MiniModalWrapper>
);

export default LinkedRecords;
