import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";
import { ORDER_TYPE } from "Orders/ImportModal/constants";

import FieldsGroups from "./FieldsGroups";
import TypeOrder from "./TypeOrder";
import PreviewMappedFields from "./PreviewMappedFields";
import RequireAssignment from "./RequireAssignment";

import { getters } from "Orders/ImportModal";

const decorate = connect(state => ({
  orderType: getters.orderType(state)
}));

const SelectItems = ({ orderType = ORDER_TYPE.INDIVIDUAL, ...styleProps }) => (
  <Div flex={1} display="row.flex-start.stretch" {...styleProps}>
    <Div
      flex={1}
      display="column.flex-start.center"
      width={1}
      style={{ overflowY: "auto" }}
    >
      <TypeOrder />
      {orderType === ORDER_TYPE.GROUP && <RequireAssignment />}
      <FieldsGroups />
    </Div>
    <PreviewMappedFields />
  </Div>
);

export default decorate(SelectItems);
