import PropTypes from "prop-types";
import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const DeletePopover = CSSModules(
  ({ title, message, cancel, handleDelete, confirmLabel = "Yes, Delete" }) => (
    <div className={["ignore-react-onclickoutside", css.container].join(" ")}>
      <i styleName="close" className="material-icons" onClick={cancel}>
        close
      </i>
      <div styleName="title">{title}</div>
      <div styleName="prompt">{message}</div>
      <div styleName="actions">
        <div styleName="cancel" onClick={cancel}>
          Cancel
        </div>
        <div styleName="delete" onClick={handleDelete}>
          {confirmLabel}
        </div>
      </div>
    </div>
  ),
  css
);

DeletePopover.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string
};

export default DeletePopover;
