import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";

import { getters, actions } from "./model";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import { openInNewWindow } from "utils/General";
import Api from "./api";
import { SIDEBAR_INSTANCE_ID } from "./constants";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const getAndOpenFile = function*({ payload: { fileId = "", fileSource } }) {
  yield put(actions.setLoading(true));
  yield put(
    sliderSidebarActions.setIsDrawerOpen(true, {
      meta: { instanceId: SIDEBAR_INSTANCE_ID }
    })
  );
  const { credentials, eventId } = yield call(getParams);
  try {
    const { payload } = yield call(Api.getFile, {
      credentials,
      eventId,
      fileId,
      fileSource
    });
    yield put(actions.setInitialData(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while fetching the File"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const closeAndRefresh = function*() {
  yield put(
    sliderSidebarActions.setIsDrawerOpen(false, {
      meta: { instanceId: SIDEBAR_INSTANCE_ID }
    })
  );
};

const openOnBrowser = function*() {
  const fileUrl = yield select(getters.url);
  yield call(openInNewWindow, fileUrl);
};

const download = function*() {
  // TODO: call to download file
};

const watchCloseSidebar = function*() {
  yield takeEvery(
    [actions.closeSidebar.type, sliderSidebarActions.close.type],
    closeAndRefresh
  );
};

const watchOpenSidebar = function*() {
  yield takeEvery(actions.openFile.type, getAndOpenFile);
};

const watchOpenOnBrowser = function*() {
  yield takeEvery(actions.onOpen.type, openOnBrowser);
};

const watchOpeOnDownload = function*() {
  yield takeEvery(actions.onDownload.type, download);
};

const rootSaga = function*() {
  yield all([
    fork(watchOpenSidebar),
    fork(watchOpenOnBrowser),
    fork(watchOpeOnDownload),
    fork(watchCloseSidebar)
  ]);
};

export default rootSaga;
