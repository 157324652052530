import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";

import { actions, getters } from "../../model";

const decorate = connect(
  state => ({
    collapsed: getters.collapsed(state) // no props, use the default instance
  }),
  { onToggleCollapsed: () => actions.toggleCollapse() } // no bind, use the default instance
);

const SidebarWrapper = ({
  sidebar,
  collapsed = true,
  children,
  ...styleProps
}) => (
  <Div
    height={1}
    width={1}
    display="row.flex-start.stretch"
    bg="#fff"
    style={{
      height: "calc(100vh - 65px)"
    }}
  >
    <Div
      height={1}
      width={collapsed ? 0 : 240}
      style={{
        position: "relative",
        overflow: "auto",
        flexShrink: 0
      }}
    >
      {collapsed ? null : sidebar}
    </Div>
    <Div
      style={{
        flex: "1 1 auto",
        overflowY: "auto",
        // padding: "0px 20px 20px 20px",
        padding: 0,
        backgroundColor: "#fff"
      }}
      {...styleProps}
    >
      {children}
    </Div>
  </Div>
);

export default decorate(SidebarWrapper);
