import { bindActionCreators } from "redux";
import LoggedOutView from "./LoggedOutView";
import { connect } from "react-redux";

import * as modalActions from "redux/modules/modal/actions";

import * as snackbarActions from "redux/modules/snackbar/actions";
import * as snackbarSelectors from "redux/modules/snackbar/selectors";

function mapStateToProps(state) {
  return {
    snackbar: snackbarSelectors.snackbar(state),
    user: state.user,
    sidebar: state.sidebar
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutView);
