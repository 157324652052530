import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import * as R from "ramda";
import View from "./View";
import { debounce } from "lodash";
import { withState } from "utils/General";
import moment from "moment";
import IssuanceModal from "./IssuanceModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import FindItemInventoryModal from "components/Global/Modals/FindItemInventory";
import ViewOrderModal from "Orders/OrderModal/View";

const showCheckinForUnpaidOrders = eventId =>
  ["2145", "2146"].includes(eventId);

const formatName = (name, id) => {
  if (id && (!name || !name.length)) {
    return "(No Name)";
  }
  return name;
};

class CheckinController extends Component {
  componentDidMount() {
    this.props.getRecordTypes();
    this.handleQueryUpdate();
    this.fetchStats();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.params.recordTypeId !== this.props.params.recordTypeId) {
      // refetch with new type filter
      this.props.setSearchTerm("");
      this.props.setStatusFilter(null);
      this.handleQueryUpdate({ page: 1 });
      this.fetchStats();
    }
  }

  async fetchStats() {
    return await this.props.getStats(this.props.params.recordTypeId);
  }

  refreshView() {
    return Promise.all([
      this.fetchStats(),
      this.handleQueryUpdate({ query: this.props.searchTerm })
    ]);
  }

  findRecord(recordId, orderType, variantId, isIssued, fulfillmentId) {
    return (
      this.props.checkins.find(
        r =>
          r.id === recordId &&
          r.order_type === orderType &&
          r.variant_id === variantId &&
          r.issued === isIssued &&
          r.fulfillment_id === fulfillmentId
      ) || {}
    );
  }

  handleCheckin = actions => async (
    quantity,
    recordId,
    orderType,
    customerAccountId,
    customerContactId,
    variantId
  ) => {
    await this.props.checkin({
      actions,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      variantId,
      options: { quantity }
    });
    return this.refreshView();
  };

  fulfillItems = this.handleCheckin(["fulfill"]);
  issueItems = this.handleCheckin(["issue"]);
  issueAndFulfill = this.handleCheckin(["issue", "fulfill"]);

  showIssuanceModal(
    recordId,
    orderType,
    customerAccountId,
    customerContactId,
    variantId,
    isIssued,
    fulfillmentId,
    status
  ) {
    const record = this.findRecord(
      recordId,
      orderType,
      variantId,
      isIssued,
      fulfillmentId
    );
    const undo = undoStatus => count =>
      this.handleRevertCheckin(
        count,
        recordId,
        orderType,
        customerAccountId,
        customerContactId,
        variantId,
        undoStatus,
        fulfillmentId
      );
    this.props.showModal({
      content: (
        <IssuanceModal
          status={status}
          onIssue={count =>
            this.issueItems(
              count,
              recordId,
              orderType,
              customerAccountId,
              customerContactId,
              variantId
            )
          }
          onFulfill={count =>
            this.fulfillItems(
              count,
              recordId,
              orderType,
              customerAccountId,
              customerContactId,
              variantId
            )
          }
          onIssueAndFulfill={count =>
            this.issueAndFulfill(
              count,
              recordId,
              orderType,
              customerAccountId,
              customerContactId,
              variantId
            )
          }
          onUndoIssuance={undo("issuance")}
          onUndoPickup={undo("fulfillment")}
          onUndoIssuanceAndPickup={undo("fulfillment-issuance")}
          itemName={record.item_name}
          recordName={record.record_name}
          totalAvailable={parseInt(record.quantity, 10)}
          //
          orderType={orderType}
          customerAccountId={customerAccountId}
          customerContactId={customerContactId}
          recordId={recordId}
          variantId={variantId}
          fulfillmentId={fulfillmentId}
          onDone={() => {
            this.refreshView();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  }

  handleFilterChange = statusFilter => {
    this.props.setStatusFilter(statusFilter);
    this.handleQueryUpdate({ page: 1, statusFilter });
  };

  handlePageChange = val => {
    this.handleQueryUpdate({
      page: val + 1,
      statusFilter: this.props.statusFilter,
      sortBy: this.props.sortBy,
      query: this.props.searchTerm
    });
  };

  handleQueryUpdate = ({
    page = this.props.currentPage,
    pageSize = this.props.pageSize,
    recordTypes = [this.props.params.recordTypeId],
    query,
    statusFilter = this.props.statusFilter,
    sortBy = this.props.sortBy
  } = {}) => {
    this.props.search({
      page,
      pageSize,
      recordTypes,
      searchTerm: query,
      status: statusFilter,
      sortCol: sortBy.column,
      sortDir: sortBy.direction
    });
  };
  focusSearch = () => {
    const node = findDOMNode(this);
    if (node) {
      const searchInput = node.getElementsByTagName("input")[0];
      if (searchInput) searchInput.focus();
    }
  };
  handleSearch = val => {
    this.props.setSearchTerm(val);
    this.props.setStatusFilter();
    this.props.setSortBy({});
    this.handleQueryUpdate({ query: val, page: 1 });
  };
  clearSearch = () => {
    this.props.setSearchTerm("");
    this.focusSearch();
    this.handleQueryUpdate({ page: 1 });
  };
  search = debounce(this.handleSearch, 250);
  handleTableChange = tableState => {
    // do nothing for now... we may not need this if we only care about pagination and sorting changes
    // console.log("tableState", tableState);
  };
  handleSortedChange = newSorted => {
    let column;
    switch (newSorted[0].id) {
      case "quantity":
        column = "quantity";
        break;
      case "passType":
        column = "item_name";
        break;
      case "contact":
      default:
        column = "record_name";
    }
    const direction = newSorted[0].desc ? "desc" : "asc";
    this.props.setSortBy({ column, direction });
    return this.handleQueryUpdate({
      query: this.props.searchTerm,
      // TODO include status filter
      sortBy: {
        column,
        direction
      }
    });
    // newSorted = [{ id: 'columnId', desc: true|false }]
  };
  gotoRecordType = id => {
    this.props.router.push(
      `/event/${this.props.params.eventId}/passes/checkin${id ? `/${id}` : ""}`
    );
  };

  handleRevertCheckin = async (
    quantity,
    recordId,
    orderType,
    customerAccountId,
    customerContactId,
    variantId,
    action,
    fulfillmentId
  ) => {
    await this.props.revertCheckin({
      variantId,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      action,
      options: { quantity, fulfillmentId }
    });
    return this.refreshView();
  };

  showViewOrderModal = number => {
    this.props.showModal({
      content: (
        <ViewOrderModal
          orderNumber={number}
          hideModal={() => {
            this.props.hideModal();
            this.refreshView();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showPassLookupModal = () => {
    this.props.showModal({
      content: <FindItemInventoryModal />,
      wrapper: ModalWrapper
    });
  };
  render() {
    const { recordTypes, checkins, fetching } = this.props;
    const tableProps = {
      loading: fetching,
      countOfPages: this.props.totalPages,
      currentPage: Math.max(this.props.currentPage - 1, 0),
      onPageChange: this.handlePageChange,
      onSortedChange: this.handleSortedChange,
      onPassLookup: this.showPassLookupModal,
      onTableChange: this.handleTableChange,
      tableRows: R.map(v => ({
        id: `${v.id}--${v.variant_id}--${v.issued}--${v.fulfillment_id}`,
        recordId: v.id,
        email: v.email,
        orderIds: v.order_ids.map((id, idx) => ({
          id,
          onClick: () => this.showViewOrderModal(id),
          isLast: idx === v.order_ids.length - 1
        })),
        passType: v.item_name,
        quantity: v.quantity,
        contact: {
          name:
            v.order_type === "individual"
              ? formatName(v.record_name, v.customer_contact_id)
              : formatName(v.account_name, v.customer_account_id),
          title:
            v.order_type === "individual"
              ? formatName(v.account_name, v.customer_account_id)
              : formatName(v.record_name, v.customer_contact_id),
          type: v.order_type
        },
        isIssued: v.issued,
        needsPayment: showCheckinForUnpaidOrders(this.props.params.eventId)
          ? false
          : v.payment_status === "unpaid",
        isFulfilled: v.fulfillment_id,
        actions: {
          onCheckin: () =>
            this.showIssuanceModal(
              v.id,
              v.order_type,
              v.customer_account_id,
              v.customer_contact_id,
              v.variant_id,
              v.issued,
              v.fulfillment_id,
              v.issued && "pending-pickup"
            ),
          // onCancelFulfillment: () => this.cancelFulfillment(v.fulfillment_id),
          onUndo: () =>
            this.showIssuanceModal(
              v.id,
              v.order_type,
              v.customer_account_id,
              v.customer_contact_id,
              v.variant_id,
              v.issued,
              v.fulfillment_id,
              v.issued && v.fulfillment_id
                ? "undo-fulfillment"
                : v.issued
                ? "undo-issuance"
                : undefined
            )
          // onUndoIssuance: () =>
          //   this.showIssuanceModal(v.id, v.variant_id, "undo-issuance"),
          // onUndoFulfillment: () =>
          //   this.showIssuanceModal(
          //     v.id,
          //     v.variant_id,
          //     "undo-fulfillment",
          //     v.fufillment_id
          //   ),
          // onUndoPickup: () =>
          //   this.showIssuanceModal(v.id, v.variant_id, "undo-pickup")
          // onUndoCheckin: () =>
          //   this.handleRevertCheckin(
          //     v.id,
          //     v.variant_id,
          //     v.issued,
          //     v.fulfillment_id
          //   )
        },
        checkin: v.fulfillment_created_at
          ? {
              title: `${moment(v.fulfillment_created_at).format(
                "h:mma M/D"
              )} - ${v.fulfillment_created_by.first_name} ${
                v.fulfillment_created_by.last_name
              }`
            }
          : undefined
      }))(checkins)
    };

    const checkinTypes = [
      ["All", () => this.gotoRecordType()],
      ...recordTypes.map(({ id, name }) => [
        name,
        () => this.gotoRecordType(id),
        id
      ])
    ].filter(t => t[2] !== this.props.params.recordTypeId);

    const activeCheckin = this.props.recordTypes.find(
      t => t.id === this.props.params.recordTypeId
    ) || { name: "All" };

    const tabs = [
      {
        title: "Full List",
        onClick: () => this.handleFilterChange(null),
        isActive: this.props.statusFilter === null
      },
      {
        title: "Not Checked In",
        onClick: () => this.handleFilterChange("no-status"),
        isActive: this.props.statusFilter === "no-status"
      },
      {
        title: "Issued / Pending Pickup",
        onClick: () => this.handleFilterChange("pending-pickup"),
        isActive: this.props.statusFilter === "pending-pickup"
      },
      {
        title: "Checked In",
        onClick: () => this.handleFilterChange("fulfilled"),
        isActive: this.props.statusFilter === "fulfilled"
      }
    ];

    return (
      <View
        {...{
          onSearch: this.search,
          onClearSearch: this.clearSearch,
          searchTerm: this.props.searchTerm,
          percentComplete:
            this.props.countOfTotal === 0
              ? 0
              : this.props.countOfIssued / this.props.countOfTotal,
          countOfComplete: this.props.countOfIssued,
          countOfRemaining: this.props.countOfTotal - this.props.countOfIssued,
          checkinTypes,
          activeCheckinLabel: activeCheckin.name,
          tabs,
          ...tableProps
        }}
      />
    );
  }
}

CheckinController.propTypes = {};

export default R.compose(
  withState("sortBy", "setSortBy", {}),
  withState("statusFilter", "setStatusFilter", null),
  withState("searchTerm", "setSearchTerm", "")
)(CheckinController);
