import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./model";
import {
  FORM_FIELD_IDS,
  FIELD_IDS,
  STANDARD_TYPE_TAB,
  TYPE_TAB_INSTANCE_ID,
  STANDARD_FEATURE_TAB,
  FEATURE_TAB_INSTANCE_ID
} from "./constants";
import * as FormSelectors from "ui-kit/Form/selectors";
import ALL_TIMEZONES from "utils/timezones";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { getters as TabGetters } from "ui-kit/Tabs";
import { isConferenceTemplate } from "redux/modules/event/selectors";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

export const getTimezones = () =>
  R.map(t => ({
    id: t.value,
    label: t.label
  }))(ALL_TIMEZONES);

export const getCanAddLiveEvents = R.always(false);

export const getEventTypes = createSelector(
  getCanAddLiveEvents,
  selectFeatureFlag(flags.IS_LENND_ADMIN.NAME),
  (canAddLiveEvents, isLenndAdmin) => {
    return [
      {
        id: "virtual",
        label: "Virtual",
        isDisabled: false
      },
      {
        id: "hybrid",
        label: "Hybrid",
        isDisabled: !isLenndAdmin && !canAddLiveEvents
      },
      {
        id: "live",
        label: "In Person",
        isDisabled: !isLenndAdmin && !canAddLiveEvents
      }
    ].filter(l => !l.isDisabled);
  }
);

export const getCountOfSelectedItems = createSelector(
  getters.settings,
  settings =>
    R.compose(
      R.length,
      R.propOr([], FORM_FIELD_IDS.HUB.TICKETS_WITH_ACCESS_TO_GATED_CONTENT)
    )(settings)
);

export const getSelectedItemsToShow = createSelector(
  getters.settings,
  settings =>
    R.compose(
      R.propOr([], FORM_FIELD_IDS.HUB.TICKETS_WITH_ACCESS_TO_GATED_CONTENT)
    )(settings)
);

export const getItemsToShow = createSelector(
  getters.itemTypes,
  itemTypes => {
    return R.compose(
      R.map(tab => ({
        id: tab.id,
        name: tab.name,
        items: R.map(
          item => ({
            id: item.id,
            name: item.name,
            items: R.compose(
              R.map(v => ({
                ...v,
                name: v.display_name
              })),
              R.flatten,
              R.map(R.prop("variants"))
            )(item.items)
          }),
          tab.groups
        )
      }))
    )(itemTypes);
  }
);

export const getAvailableTypes = createSelector(
  getters.data,
  getters.types,
  getters.availableTypes,
  getters.typeSettings,
  (data, types, availableTypes, typeSettings) => {
    return R.map(t => ({
      ...t,
      selected: types[t.id],
      autoselected: t.autoselected
        ? R.length(R.intersection(t.autoselected, [data.scope]))
        : false,
      disabled: !R.length(R.intersection(t.plans, [data.scope])),
      settings: R.propOr({}, t.id)(typeSettings),
      scopes:
        t.source === "standard"
          ? ["People & Groups"].filter(v => v)
          : R.prop(t.module_id)({
              [STANDARD_MODULE_IDS.accounts.id]: ["Groups"],
              [STANDARD_MODULE_IDS.contacts.id]: ["People"]
            })
    }))(availableTypes);
  }
);

export const getAvailableStandardTypes = createSelector(
  getAvailableTypes,
  types => R.filter(R.propEq("source", "standard"))(types)
);

export const getAvailableCustomTypes = createSelector(
  getAvailableTypes,
  types => R.filter(R.propEq("source", "custom"))(types)
);

export const getTypesToShow = createSelector(
  getAvailableStandardTypes,
  getAvailableCustomTypes,
  state => TabGetters.selectedTab(state, { instanceId: TYPE_TAB_INSTANCE_ID }),
  (standardTypes, customTypes, tab) =>
    tab === STANDARD_TYPE_TAB ? standardTypes : customTypes
);

export const getTypesToSave = createSelector(
  getAvailableTypes,
  types => {
    return R.compose(
      R.map(t => ({
        id: t.id,
        moduleId: t.module_id,
        settings: t.settings,
        source: t.source
      })),
      R.filter(t => t.autoselected || t.selected)
    )(types);
  }
);

export const getIsValid = createSelector(
  getters.data,
  getTypesToSave,
  isConferenceTemplate,
  (data, types, isConferenceTemplate) => {
    if (
      !data.name ||
      !data.name.length ||
      !data.slug ||
      !data.slug.length ||
      !data.date_from ||
      !data.date_from.length ||
      !data.date_to ||
      !data.date_to.length
    ) {
      return "Fill out all required fields";
    }

    if (
      !isConferenceTemplate &&
      R.contains(R.prop(FIELD_IDS.SCOPE)(data), ["live", "hybrid"])
    ) {
      if (
        !R.compose(
          R.length,
          R.filter(R.propEq("source", "standard"))
        )(types)
      ) {
        // if no standard types, ensure that at least 1 custom people type and 1 group type are selected
        const customAccountTypeSelected = R.compose(
          Boolean,
          R.length,
          R.filter(
            t =>
              t.moduleId === STANDARD_MODULE_IDS.accounts.id &&
              t.source === "custom"
          )
        )(types);
        const customContactTypeSelected = R.compose(
          Boolean,
          R.length,
          R.filter(
            t =>
              t.moduleId === STANDARD_MODULE_IDS.contacts.id &&
              t.source === "custom"
          )
        )(types);

        if (!customAccountTypeSelected || !customContactTypeSelected) {
          return "Select at least one type to be enabled";
        }
      }
    }

    return true;
  }
);

export const getAvailableFeatures = createSelector(
  getters.data,
  getters.features,
  getters.availableFeatures,
  selectFeatureFlag(flags.IS_LENND_ADMIN.NAME),
  (data, features, availableFeatures, isLenndAdmin) => {
    return R.compose(
      R.map(t => ({
        ...t,
        selected: features[t.id],
        autoselected: t.autoselected
          ? R.length(R.intersection(t.autoselected, [data.scope]))
          : false,
        disabled: !R.length(R.intersection(t.plans, [data.scope]))
      })),
      R.filter(t => (t.beta ? isLenndAdmin : true))
    )(availableFeatures);
  }
);

export const getAvailableStandardFeatures = createSelector(
  getAvailableFeatures,
  features => R.filter(R.propEq("source", "standard"))(features)
);

export const getAvailableCustomFeatures = createSelector(
  getAvailableFeatures,
  features => R.filter(R.propEq("source", "custom"))(features)
);

export const getFeaturesToShow = createSelector(
  getAvailableStandardFeatures,
  getAvailableCustomFeatures,
  state =>
    TabGetters.selectedTab(state, { instanceId: FEATURE_TAB_INSTANCE_ID }),
  (standardFeatures, customFeatures, tab) =>
    tab === STANDARD_FEATURE_TAB ? standardFeatures : customFeatures
);

export const getFeaturesToSave = createSelector(
  getAvailableFeatures,
  features => {
    return R.compose(
      R.map(f => ({
        id: f.id,
        moduleId: f.module_id,
        settings: f.settings,
        source: f.source
      })),
      R.filter(f => f.autoselected || f.selected)
    )(features);
  }
);
