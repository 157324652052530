import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import CanUserDo from "components/Global/Security/CanUserDo";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="crm">
    <CanUserDo
      any={[
        `${STANDARD_MODULE_IDS.accounts.id}_read`,
        `${STANDARD_MODULE_IDS.accounts.id}_read_owned_records`,
        `${STANDARD_MODULE_IDS.accounts.id}_read_typed_records`
      ]}
    >
      <SubNavLink
        name="Groups"
        active={
          params.moduleId === STANDARD_MODULE_IDS.accounts.id &&
          selectedView === TAB_IDS.MANAGE
        }
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/crm/manage/${STANDARD_MODULE_IDS.accounts.id}`
          })
        }
      />
    </CanUserDo>

    <CanUserDo
      any={[
        `${STANDARD_MODULE_IDS.contacts.id}_read`,
        `${STANDARD_MODULE_IDS.contacts.id}_read_owned_records`,
        `${STANDARD_MODULE_IDS.contacts.id}_read_typed_records`
      ]}
    >
      <SubNavLink
        name="People"
        active={
          params.moduleId === STANDARD_MODULE_IDS.contacts.id &&
          selectedView === TAB_IDS.MANAGE
        }
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/crm/manage/${STANDARD_MODULE_IDS.contacts.id}`
          })
        }
      />
    </CanUserDo>

    <CanUserDo any={[`${STANDARD_MODULE_IDS.contacts.id}_read`]}>
      <SubNavLink
        name="History"
        active={selectedView === TAB_IDS.HISTORY}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/crm/history/email`
          })
        }
      />
    </CanUserDo>

    <CanUserDo
      any={[
        `${STANDARD_MODULE_IDS.accounts.id}_manage`,
        `${STANDARD_MODULE_IDS.contacts.id}_manage`
      ]}
    >
      <SubNavLink
        name="Settings"
        active={[
          TAB_IDS.TYPES,
          TAB_IDS.FIELDS,
          TAB_IDS.GUEST_LIST,
          TAB_IDS.PORTAL,
          TAB_IDS.LAYOUT,
          TAB_IDS.DOCUMENT_TEMPLATES,
          TAB_IDS.REPORT_TEMPLATES,
          TAB_IDS.EMAIL_TEMPLATES,
          TAB_IDS.MANAGE_DUPLICATES
        ].includes(selectedView)}
        onClick={() =>
          // USE REACT REDUX ROUTER instead this
          router.push({
            pathname: `/event-light/${params.eventId}/crm/settings/fields`
          })
        }
      />
    </CanUserDo>
  </SubNavWrapper>
);

export default withRouter(SubNav);
