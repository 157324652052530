import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import * as R from "ramda";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "../LabelSimpleForm/view";
import Editor from "components/Global/Editors/CalculatedNumber";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import InputGroup from "components/Global-2016/Forms/InputGroup";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormCalculatedNumber extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false
    };
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }
  render() {
    const {
      fields,
      formValues,
      field: {
        name,
        settings: { description, isAdminField, ...settings }
      },
      canShowSimpleSideBar
    } = this.props;
    const required = this.props?.field.is_required;
    const readonly = this.props?.field.is_readonly;
    const { connectDragSource } = this.props;
    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <InputGroup style={this.props.groupStyles}>
          {name ? (
            !canShowSimpleSideBar ? (
              <Label
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
              >
                {name}
              </Label>
            ) : (
              <LabelSimpleForm
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
                instanceId={this.props.field.id}
                fieldData={this.props.field}
                isShown={this.state.isShown}
                setIsShown={() => this.handleToogleShowEditMode()}
              >
                {name}
              </LabelSimpleForm>
            )
          ) : null}
          <div styleName="picker">
            <Editor fields={fields} settings={settings} values={formValues} />
          </div>
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormCalculatedNumber.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormCalculatedNumber;
