import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";

export default function resolveProps(column, event) {
  const props = resolveEditorProps(column, event);
  if (column.type === "file") {
    return {
      ...props,
      allowDownload: true
    };
  }
  return props;
}
