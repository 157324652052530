import React from "react";

import { Div } from "components/Base";

import { HEIGHTS } from "Items/EditQuestionSetQuestionsModal/constants";

import Questions from "./Questions";

const bodyHeight = 661 - (HEIGHTS.HEADER + HEIGHTS.FOOTER);

const Body = () => (
  <Div width={1} height={bodyHeight}>
    <Questions />
  </Div>
);

export default Body;
