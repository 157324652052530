import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { CARD_TYPES } from "../../constants";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Div, MediumOutlineButton } from "components/Base";
import NotConfigured from "../NotConfigured";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { LoadingIcon } from "components/Base";
import Table from "../Table";
import { noop } from "utils/General";

const assignedRowsText = "Page";

const columns = [
  {
    name: "Title",
    value: "title",
    style: { flex: 1 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Description",
    value: "description",
    style: { flex: 1.4, fontSize: 15 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const decorate = R.compose(
  connect(
    state => ({
      assignedRows: getters.assignedPages(state),
      loading: getters.loadingPages(state)
    }),
    {
      goToLearnLennd: actions.goToLearnLennd,
      openModal: () => actions.openAssignModal(CARD_TYPES.PAGES),
      deleteRow: actions.removeAssinedPage,
      openUpdateModal: actions.openAddPageModal,
      reorderSharedFiles: actions.reorderPages
    }
  ),
  CSSModules(css)
);

const AssignPages = ({
  assignedRows = [],
  openModal,
  loading = false,
  deleteRow = noop,
  openUpdateModal = noop,
  reorderSharedFiles = noop
}) => {
  return (
    <CollapsablePanel
      heading={() => (
        <Div display="row.space-between.center" my={2} width={1} pr={4}>
          <div className={css.title}>Assign Pages</div>
          <NotConfigured
            configured={!R.isEmpty(assignedRows)}
            loading={loading}
            text={`${R.length(assignedRows)} ${assignedRowsText}${
              R.length(assignedRows) !== 1 ? "s" : ""
            } Shared`}
          />
        </Div>
      )}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsedDefault={true}
    >
      <div styleName="collapsable">
        <div styleName="description">
          Create pages for users to view on this portal
        </div>
        <div styleName="row-btn">
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                upload
              </span>
            )}
            width={1}
            onClick={openModal}
          >
            Select Page
          </MediumOutlineButton>
        </div>

        {loading ? (
          <div styleName="center">
            <LoadingIcon color="gray3" size={50} />
          </div>
        ) : (
          <Table
            rows={assignedRows}
            openUpdateModal={openUpdateModal}
            deleteRow={deleteRow}
            emptyText="You haven't linked any pages to this portal yet."
            onReorder={reorderSharedFiles}
            columns={columns}
          />
        )}
      </div>
    </CollapsablePanel>
  );
};

export default decorate(AssignPages);
