import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";

const getRouteNames = createSelector(
  (_, props) => props.routes,
  R.map(R.propOr("", "name"))
);

const mapTabId = {
  EventLightGuestLists: TAB_IDS.GUEST_LISTS
};

export const getCurrentRoute = createSelector(
  getRouteNames,
  R.reduce((id, route) => {
    const tabId = R.prop(route, mapTabId);
    if (tabId) {
      return R.reduced(tabId);
    }
    return id;
  }, "")
);
