import React from "react";
import { baseColors } from "../Base/colorSpecs";
import { Div, FontIcon } from "../index";

const colorGridMaker = ({ isHorz }) => ({
  selectedColor,
  colors = baseColors,
  onSelectColor
}) => {
  // @TODO: figure out something less baked-in than this color spec
  const hues = ["yellow", "orange", "red", "green", "blue", "purple", "gray"];
  const shades = [8, 5, 3];

  return (
    <Div display={`${isHorz ? "row" : "column"}.flex-start.flex-start`}>
      {hues.map((hue, i) => (
        <Div
          key={i}
          display={`${isHorz ? "column" : "row"}.flex-start.flex-start`}
        >
          {shades.map((shade, j) => {
            const colorName = `${hue}${shade}`;
            const selected = colors[hue][shade] === selectedColor;
            return (
              <Div
                display="row.center.center"
                key={j}
                m={1 / 2}
                bra={1}
                ba={2}
                bc={{
                  default: selected ? "purple5" : "transparent",
                  hover: "purple5"
                }}
                onClick={() => onSelectColor(colors[hue][shade])}
              >
                <FontIcon
                  bra={1}
                  bg={colorName}
                  fs={1}
                  p={1}
                  m={1 / 4}
                  color={selected ? "white" : "transparent"}
                >
                  check
                </FontIcon>
              </Div>
            );
          })}
        </Div>
      ))}
    </Div>
  );
};

const ColorGrid = colorGridMaker({ isHorz: true });
const VertColorGrid = colorGridMaker({ isHorz: false });

const IconGrid = ({ selectedIcon, icons = [], onSelectIcon }) => (
  <Div
    display="row.flex-start.flex-start"
    style={{
      flexWrap: "wrap"
    }}
  >
    {icons.map((icon, i) => {
      const selected = icon === selectedIcon;

      return (
        <FontIcon
          key={i}
          shadow={1}
          onClick={() => onSelectIcon(icon)}
          bb={1}
          br={1}
          bc="gray1"
          bg={{ default: selected ? "purple5" : "white", hover: "purple5" }}
          color={{ default: selected ? "white" : "gray7", hover: "white" }}
          px={3}
          py={2}
        >
          {icon}
        </FontIcon>
      );
    })}
  </Div>
);

export { ColorGrid, VertColorGrid, IconGrid };
