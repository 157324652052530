import { createModule } from "redux-mvc";

import handlers from "Toolbar/QuickActionsNavigation/handlers";

import eventHome from "Event/Home";

const module = createModule(handlers);

module.plugModule(eventHome);

const { actions, getters } = module;

export { actions, getters };

export default module;
