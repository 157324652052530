import * as R from "ramda";

import moment from "moment-timezone";
import { makeInstanceSelector } from "redux-mvc";

import { CALENDAR_VIEW } from "./constants";

import { DAY_GROUPS_IDS } from "./constants";

import { getters } from "./model";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const getInstance = R.prop("instanceId");

export const getToolbarTitle = makeInstanceSelector(
  getters.selectedRange,
  getters.currentDate,
  (selectedRange, currentDate) =>
    moment(currentDate).format(
      selectedRange === CALENDAR_VIEW.RESOURCE ? "MMMM DD" : "MMMM YYYY"
    )
)(getInstance);

export const getResources = makeInstanceSelector(
  getters.resources,
  R.map(resource => ({
    id: resource.title,
    title: resource.title,
    value: resource.value,
    records: resource.records
  }))
)(getInstance);

export const getEvents = makeInstanceSelector(
  getters.resources,
  R.compose(
    R.filter(event => event.start && event.end),
    R.flatten,
    R.map(R.propOr([], "records"))
  )
)(getInstance);

export const getEventsById = makeInstanceSelector(
  getEvents,
  R.indexBy(R.prop("id"))
)(getInstance);

export const getUpdatedEvents = makeInstanceSelector(
  getEventsById,
  getters.updatedEvents,
  (eventsById, updatedEvents) =>
    R.reduce(
      (events, id) => [
        ...events,
        {
          ...eventsById[id],
          ...updatedEvents[id]
        }
      ],
      [],
      R.uniq(R.concat(R.keys(eventsById), R.keys(updatedEvents)))
    )
)(getInstance);

export const getAllSortedEvents = makeInstanceSelector(
  getUpdatedEvents,
  R.sort((a, b) => (moment(a.start).isBefore(moment(b.start)) ? 1 : 0))
)(getInstance);

export const getFirstEventDate = makeInstanceSelector(
  getAllSortedEvents,
  R.path([0, "start"])
)(getInstance);

export const getEventsByDateMap = makeInstanceSelector(
  getAllSortedEvents,
  getters.timezone,
  (events, timezone) =>
    R.groupBy(
      event =>
        moment
          .utc(event.start)
          .tz(timezone)
          .startOf("day")
          .format(),
      events
    )
)(getInstance);

export const getEventsByMonth = makeInstanceSelector(
  getEventsByDateMap,
  getters.timezone,
  R.compose(
    R.map(([date, days]) => ({ date, days })),
    Object.entries,
    R.groupBy(byDate =>
      moment(byDate.date)
        .startOf("month")
        .format()
    ),
    R.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? -1 : 1)),
    R.map(([date, events]) => ({ date, events })),
    Object.entries
  )
)(getInstance);

export const getEventByResourceAndId = (state, props) => {
  const resources = getResources(state, props);

  const resource = R.find(R.propEq("id", props.resourceId), resources);
  if (resource) {
    return R.find(R.propEq("id", props.eventId), resource.records) || {};
  }
  return {};
};

export const getResourceById = (state, props) => {
  const resources = getResources(state, props);

  return R.find(R.propEq("id", props.resourceId), resources) || {};
};

const defaultAllowed = [
  CALENDAR_VIEW.DAY,
  CALENDAR_VIEW.MONTH,
  CALENDAR_VIEW.WEEK,
  CALENDAR_VIEW.RESOURCE
];

export const getDateRangeOptions = makeInstanceSelector(
  (_, props) => R.propOr(defaultAllowed, "allowedOptions", props),
  allowedOptions =>
    R.filter(
      R.compose(
        // eslint-disable-next-line no-underscore-dangle
        R.contains(R.__, allowedOptions),
        R.prop("id")
      ),
      [
        { id: CALENDAR_VIEW.DAY, label: "Day" },
        { id: CALENDAR_VIEW.MONTH, label: "Month" },
        { id: CALENDAR_VIEW.WEEK, label: "Week" },
        { id: CALENDAR_VIEW.RESOURCE, label: "Resource" }
      ]
    )
)(getInstance);

export const getDateGroups = makeInstanceSelector(
  R.compose(
    R.propOr({}, "date_groups"),
    getEventDetails
  ),
  R.compose(
    R.filter(group => group.firstDate.isValid()),
    R.map(group => ({
      ...group,
      firstDate: moment(
        R.reduce(
          (min, next) => (moment(min).isBefore(next) ? min : next),
          R.head(R.propOr([], "days", group)),
          R.propOr([], "days", group)
        ) || "a"
      )
    }))
  )
)(getInstance);

export const getLoadInDate = makeInstanceSelector(
  getDateGroups,
  R.path([0, "firstDate"])
)(getInstance);

export const getDayOcurrences = makeInstanceSelector(
  getEventsByDateMap,
  getDateGroups,
  getters.timezone,
  (activities, groups, timezone) =>
    R.compose(
      R.merge(R.map(R.merge({ hasEvents: true }), activities)),
      R.indexBy(day => day.date.format("YYYY-MM-DD")),
      R.flatten,
      R.map(group =>
        R.map(day => {
          const date = moment(day).tz(timezone);
          return {
            date,
            groupId: group.id,
            colorClass: R.prop(group.id, {
              [DAY_GROUPS_IDS.SHOW]: "show-days",
              [DAY_GROUPS_IDS.LOAD_IN]: "load-in",
              [DAY_GROUPS_IDS.LOAD_OUT]: "load-out",
              [DAY_GROUPS_IDS.OFF]: "days-off"
            }),
            hasEvents: R.has(date.format("YYYY-MM-DD"), activities),
            color: group.color
          };
        }, group.days)
      )
    )(groups)
)(getInstance);
