import React from "react";

export const Video = ({ url }) => (
  <div style={{ position: "relative", width: "100%", paddingBottom: "56.25%" }}>
    <iframe
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: 0
      }}
      src={url}
      allow="autoplay; encrypted-media"
      allowFullScreen
    ></iframe>
  </div>
);

export default Video;
