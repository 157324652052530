import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const PrimaryButton = props => (
  <button
    {...props}
    className={[
      props.disabled ? props.styles.buttonDisabled : props.styles.button,
      props.className
    ].join(" ")}
  >
    {props.children}
  </button>
);

PrimaryButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  styles: PropTypes.string
};

export default CSSModules(PrimaryButton, styles);
