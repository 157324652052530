import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

// @NOTE: Written as class so we can use `refs` to focus the input
class ModalInput extends Component {
  focus = () => this.input.focus();

  render() {
    return (
      <input
        styleName="input"
        type="text"
        {...this.props}
        ref={ref => {
          this.input = ref;
        }}
      />
    );
  }
}

export default CSSModules(ModalInput, css);
