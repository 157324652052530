import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";

const selectColor = percentage => {
  if (percentage >= 76) {
    return "green9";
  } else if (percentage >= 51) {
    return "green6";
  } else if (percentage >= 26) {
    return "lime5";
  }
  return "yellow4";
};

const InfoMeal = ({ meals }) => (
  <Div width={1}>
    {R.map(
      ({ name, requested, approved, unused, used, utlization_percent }) => (
        <Div key={name} width={1} display="row.flex-start.center" my={3}>
          <Div fs={2} fw={3} color="neutral7" width={150}>
            {name}
          </Div>
          <Div
            fs={2}
            fw={3}
            color="neutral5"
            width={75}
            display="row.flex-end"
            pr={2}
          >
            {requested}
          </Div>
          <Div
            fs={2}
            fw={3}
            color="neutral7"
            width={70}
            display="row.flex-end"
            pr={2}
          >
            {approved}
          </Div>
          <Div
            fs={2}
            fw={3}
            color="neutral7"
            width={75}
            display="row.flex-end"
            pr={2}
          >
            {unused}
          </Div>
          <Div
            fs={2}
            fw={3}
            color="neutral7"
            width={75}
            display="row.flex-end"
            pr={2}
          >
            {used}
          </Div>
          <Div width={320} pl={4}>
            <Div width={1} bra={3} bg="neutral2" height={8}>
              <Div
                height={1}
                bg={selectColor(utlization_percent)}
                style={{ width: `${utlization_percent}%` }}
              />
            </Div>
          </Div>
        </Div>
      ),
      meals
    )}
  </Div>
);

export default InfoMeal;
