import React, { Component } from "react";
import { set } from "lodash";
import { Div, CollapsablePanelType2 } from "components/Base";

import EventDaysFormatter from "components/Global/Table3/CellFormatters/EventDays";
import EventDaysEditor from "components/Global/Editors/EventDaysEditor";

class Dates extends Component {
  render() {
    const { dayGroups, startDate, endDate, dates, onDaySelect } = this.props;
    return (
      <div>
        <CollapsablePanelType2
          headingText="Dates Valid"
          headingExtras={
            <EventDaysFormatter
              dependentValues={set(
                {},
                "meta.eventDetails.date_groups",
                dayGroups
              )}
              value={{ value: dates }}
            />
          }
        >
          <Div display="row.center.center">
            <EventDaysEditor
              onChange={onDaySelect}
              value={{ value: dates }}
              endDate={endDate}
              startDate={startDate}
              dayGroups={dayGroups}
            />
          </Div>
        </CollapsablePanelType2>
      </div>
    );
  }
}

export default Dates;
