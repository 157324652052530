import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "HealthPass/HealthPassForm/sagas";

const module = createModule(model);

module.setRootSaga(sagasModule);

export default module;
