import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Main from "components/Global/CRM/Forms/Form/Main";
import Header from "components/Global/CRM/Forms/Form/Header";
import Title from "components/Global/CRM/Forms/Form/Title";
import SubTitle, { Green } from "components/Global/CRM/Forms/Form/SubTitle";
import ActionButton from "components/PortalV2/Home/UI/ActionButton";
import { Div, PopoverMenu, MoreIcon, FontIcon } from "components/Base";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import Tooltip from "components/Global/Tooltip";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import formCloseDateHasExpired from "components/Event/FormsV2/Utils/form-close-date-has-expired";
import moment from "moment";

const formatExpirationMessage = request => {
  if (formCloseDateHasExpired(request.close_date)) {
    return (
      <span>Closed on {moment(request.close_date).format("MMMM Do")}</span>
    );
  }
  return <span>Closes on {moment(request.close_date).format("MMMM Do")}</span>;
};

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    requests: getters.requests(state)
  }),
  {
    onAssignFileRequests: actions.onAssignFileRequests,
    onViewRequest: actions.onViewRequest,
    onViewDueDate: actions.onViewDueDate
  }
);

@CSSModules(css)
class AssignedRequest extends Component {
  addSubmission = () => {
    this.props.addSubmission(this.props.form);
  };

  render() {
    const {
      request,
      isManager,
      onUnassign,
      isAssignedToType,
      isAdminMode
    } = this.props;

    const { name, approval_status } = request;
    const isLocked = isFormLocked(request);

    let icon = <div style={{ width: 24 }} />;
    let headerProps = {};

    return (
      <div styleName="container">
        <Header {...headerProps}>
          {icon}
          <Main>
            <Title>
              <Div display="row.flex-start.center">
                {name || "(Untitled)"}
                {isAdminMode && isAssignedToType ? (
                  <Tooltip
                    tooltip="Request is assigned to group's type"
                    placement="bottom"
                  >
                    <FontIcon fs={2} ml={1} color="neutral3">
                      info
                    </FontIcon>
                  </Tooltip>
                ) : null}
              </Div>
            </Title>
            <SubTitle>
              <Div display="row.flex-start.center">
                {isLocked ? "Closed" : <Green>Open</Green>}
                <Div mx={1}>&middot;</Div>
                <span styleName="subCount">
                  {R.prop(approval_status)({
                    not_submitted: "Not Submitted",
                    pending: "Pending",
                    approved: "Approved",
                    denied: "Denied"
                  })}
                </span>
                <Div display="row.flex-start.center">
                  <Div mx={1}>&middot;</Div>
                  <span styleName="subCount">
                    {formatExpirationMessage(request)}
                  </span>
                </Div>
              </Div>
            </SubTitle>
          </Main>

          <ActionButton
            useTimer={false}
            icon={null}
            onClick={() =>
              this.props.onViewRequest({
                requestTypeId: request.id
              })
            }
            label="View"
          />
          {isAdminMode && isManager ? (
            <PopoverMenu
              menuItems={[
                [
                  "Set due date",
                  () => this.props.onViewDueDate({ requestTypeId: request.id })
                ],
                onUnassign ? ["Unassign", onUnassign] : undefined
              ].filter(o => o)}
              Label={({ onClick }) => (
                <Div
                  onClick={onClick}
                  b={1}
                  bc="neutral1"
                  display="row.center.center"
                  width={33}
                  height={33}
                  ml={2}
                  style={{
                    borderRadius: 100
                  }}
                >
                  <MoreIcon fs={4} />
                </Div>
              )}
            />
          ) : null}
        </Header>
      </div>
    );
  }
}

export default decorate(AssignedRequest);
