import { BEGIN, COMMIT } from "redux-optimist";
import {
  ADD,
  REMOVE,
  ADD_COMPLETE,
  REMOVE_COMPLETE,
  SAVE_VALUE,
  SAVE_VALUE_COMPLETE,
  BULK_SAVE_VALUES,
  BULK_SAVE_VALUES_COMPLETE
} from "./constants";
import api from "./api";
import moduleValueApi from "redux/modules/modules/values/api";
import lineItemsApi from "redux/modules/orders/bulk-edit-line-items/api";
import { refreshPeopleBlockOrder } from "redux/modules/formsV2/submission/actions";
import { registerError } from "redux/modules/errors/actions";
import uuid from "node-uuid";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventId as getFormEventId } from "redux/modules/formsV2/submission/selectors";

export function addPerson(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: ADD,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: ADD_COMPLETE,
        payload: {
          blockId: data.blockId,
          blockFieldId: data.blockFieldId,
          contactId: data.contactId,
          person: result.payload
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding person"
          }
        ])
      );
    }
  };
}

export function removePerson(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: REMOVE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      dispatch({
        type: REMOVE_COMPLETE,
        payload: {
          blockId: data.blockId,
          blockFieldId: data.blockFieldId,
          contactId: data.contactId
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      const result = await api.delete(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing person"
          }
        ])
      );
    }
  };
}

export function updatePersonValue(data) {
  return async (dispatch, getState) => {
    const eventId = getFormEventId(getState()) || getEventId(getState());

    const transactionId = uuid.v4();
    dispatch({
      type: SAVE_VALUE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      dispatch({
        type: SAVE_VALUE_COMPLETE,
        payload: {
          blockId: data.blockId,
          blockFieldId: data.blockFieldId,
          contactId: data.contactId,
          fieldId: data.fieldId,
          value: data.value
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      const result = await moduleValueApi.post(
        getState().user.user.credentials,
        {
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          recordId: data.contactId,
          fieldId: data.fieldId,
          value: data.value,
          userId: getState().user.user.id,
          eventId
        }
      );

      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred saving person value"
          }
        ])
      );
    }
  };
}

export function updatePersonValues(data) {
  return async (dispatch, getState) => {
    const eventId = getFormEventId(getState()) || getEventId(getState());

    const transactionId = uuid.v4();
    dispatch({
      type: BULK_SAVE_VALUES,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await moduleValueApi.post(
        getState().user.user.credentials,
        {
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          bulk: true,
          values: data.values,
          userId: getState().user.user.id,
          eventId
        }
      );
      dispatch({
        type: BULK_SAVE_VALUES_COMPLETE,
        payload: {
          blockId: data.blockId,
          blockFieldId: data.blockFieldId,
          values: data.values
        },
        optimist: { type: COMMIT, id: transactionId }
      });

      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred saving person values"
          }
        ])
      );
    }
  };
}

export function updatePersonItemBlockValue(data) {
  return async (dispatch, getState) => {
    try {
      const result = await lineItemsApi.put(
        getState().user.user.credentials,
        data
      );
      dispatch(
        refreshPeopleBlockOrder({
          orderId: data.orderId,
          contactId: data.contactId,
          blockFieldId: data.blockFieldId
        })
      );
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item block value"
          }
        ])
      );
    }
  };
}

export function updatePersonRole(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: SAVE_VALUE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: SAVE_VALUE_COMPLETE,
        payload: {
          blockId: data.blockId,
          blockFieldId: data.blockFieldId,
          contactId: data.contactId,
          fieldId: data.fieldId,
          value: data.value
        },
        optimist: { type: COMMIT, id: transactionId }
      });

      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred saving person values"
          }
        ])
      );
    }
  };
}
