import { get } from "lodash";

export default function resolveCellClass(column) {
  if (column.is_required) {
    return " react-grid-Cell-required";
  }
  if (
    get(column, "settings.readOnly") === true ||
    column.editable === false ||
    column.type === "static"
  ) {
    return " react-grid-Cell-locked";
  }
  return "";
}
