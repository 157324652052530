import { createModule } from "redux-mvc";

import model from "./model";
import rootSaga from "./sagas";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "event",
    "permissions",
    "organization",
    "modal",
    "@flopflip",
    "formsV2"
  ]
});
module.setRootSaga(rootSaga);

export default module;
