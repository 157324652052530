import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  FIELD_WIDTH_UPDATE,
  FIELD_WIDTH_UPDATE_COMPLETE,
  FIELD_VISIBILITY_UPDATE,
  FIELD_VISIBILITY_UPDATE_COMPLETE,
  ROW_ORDER_UPDATE,
  ROW_ORDER_UPDATE_COMPLETE,
  DEFAULT_FIELD_WIDTH_UPDATE,
  DEFAULT_FIELD_WIDTH_UPDATE_COMPLETE,
  DEFAULT_FIELD_VISIBILITY_UPDATE,
  DEFAULT_FIELD_VISIBILITY_UPDATE_COMPLETE,
  DEFAULT_ROW_ORDER_UPDATE,
  DEFAULT_ROW_ORDER_UPDATE_COMPLETE,
  ERROR,
  CLEAR_ERROR
} from "./constants";
import api from "./api";

export function updateFieldWidth(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: FIELD_WIDTH_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: FIELD_WIDTH_UPDATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function updateRowOrder(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: ROW_ORDER_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: ROW_ORDER_UPDATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function toggleFieldVisibility(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: FIELD_VISIBILITY_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: FIELD_VISIBILITY_UPDATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function updateDefaultFieldWidth(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: DEFAULT_FIELD_WIDTH_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: DEFAULT_FIELD_WIDTH_UPDATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function updateDefaultRowOrder(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: DEFAULT_ROW_ORDER_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: DEFAULT_ROW_ORDER_UPDATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function toggleDefaultFieldVisibility(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: DEFAULT_FIELD_VISIBILITY_UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: DEFAULT_FIELD_VISIBILITY_UPDATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
