import React, { PureComponent } from "react";
import * as R from "ramda";
import {
  BigOutlineButton,
  DarkTab,
  Div,
  FontIcon,
  Text6
} from "components/Base";
import { withState } from "utils/General";
import { injectFeatureToggles } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import Types from "./Types";
import ApprovalWorkflows from "../ApprovalWorkflows";

class View extends PureComponent {
  goToCredentials = () => {
    this.props.router.push({
      pathname: this.props.featureToggles[
        flags.CAN_VIEW_CREDENTIALS_MANAGEMENT.NAME
      ]
        ? `/event/${this.props.params.eventId}/credentials/orders`
        : `/event/${this.props.params.eventId}/credentials`
    });
  };

  goToTypes = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/passes/setup`
    });
    this.props.setTabValue("editEventSettingsCredentials");
  };

  goToApprovals = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/approvers`
    });
  };

  render() {
    const { activeTabValue } = this.props;

    const tabs = [
      ["editEventSettingsCredentials", "Types", this.goToTypes],
      [
        "editEventSettingsCredentialsApprovalWorkflows",
        "Approval Workflows",
        this.goToApprovals
      ]
    ];

    return (
      <Div style={{ minHeight: "100%" }}>
        <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
          <Div flex={1}>
            <Text6 pl={8} pt={6}>
              Credentials
            </Text6>

            <Div pt={3} pl={8}>
              {R.map(([routeName, tabName, onClick]) => {
                const active = R.equals(activeTabValue)(routeName);
                return (
                  <DarkTab
                    key={tabName}
                    px={2}
                    fs={3}
                    active={active}
                    onClick={!active ? onClick : undefined}
                  >
                    {tabName}
                  </DarkTab>
                );
              })(tabs)}
            </Div>
          </Div>
          <Div
            display="row.flex-start.center"
            style={{ alignSelf: "center" }}
            pr={4}
          >
            <BigOutlineButton onClick={this.goToCredentials}>
              Go to credentials
              <FontIcon color="inherit">keyboard_arrow_right</FontIcon>
            </BigOutlineButton>
          </Div>
        </Div>
        <Div style={{ minHeight: "100%" }} pb={14}>
          {R.prop(activeTabValue, {
            editEventSettingsCredentials: <Types />,
            editEventSettingsCredentialsApprovalWorkflows: <ApprovalWorkflows />
          })}
        </Div>
      </Div>
    );
  }
}

export default injectFeatureToggles([
  flags.CAN_VIEW_CREDENTIALS_MANAGEMENT.NAME
])(withState("activeTabValue", "setTabValue", props => props.route.name)(View));
