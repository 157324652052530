import React from "react";
import { connect } from "react-redux";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";
import { actions } from "Portal/CustomizeSectionsModal/model";
import { getSaveBtnDisabled } from "Portal/CustomizeSectionsModal/selectors";

const decorate = connect(
  state => ({ saveBtnDisabled: getSaveBtnDisabled(state) }),
  {
    save: actions.save
  }
);

const Footer = ({ save, hideModal, saveBtnDisabled, fetchPermissionSets }) => (
  <Div
    height={90}
    width={1}
    bg="white"
    bc="neutral2"
    bt={1}
    display="row.flex-start.center"
    px={8}
  >
    <BigFilledButton
      bg="altB5"
      onClick={() => save({ hideModal, fetchPermissionSets })}
      disabled={saveBtnDisabled}
    >
      Save Changes
    </BigFilledButton>
    <BigOutlineButton ml={2} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
