import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";

const widgets = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        [action.payload.widgetId]: {
          id: action.payload.widgetId,
          ...state[action.payload.widgetId],
          fetching: true
        }
      };
    case RECEIVE:
      return {
        ...state,
        [action.payload.widget.id]: {
          id: action.payload.widgetId,
          ...action.payload.widget,
          fetching: false
        }
      };
    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  widgets,
  error,
  fetching
});
