import Helpers from "utils/Global/Helpers";

module.exports = {
  get: eventId =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/catering/event/${eventId}/checkin/meals`,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
