import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Button from "components/Event/FormsV2/Overview/SubmissionSummary/Button";

const TableFooter = ({ rows, selectedRows, addRequest, approveRows }) => {
  const button = selectedRows.length ? (
    <Button onClick={() => approveRows(selectedRows)}>
      <i className="material-icons">check</i>
      {`Approve ${selectedRows.length} Selected`}
    </Button>
  ) : (
    <Button onClick={() => approveRows(rows)}>
      <i className="material-icons">check</i>
      {`Approve ${rows.length} remaining`}
    </Button>
  );
  return (
    <div styleName="footer">
      {rows.length ? button : ""}
      <Button onClick={addRequest}>
        <i className="material-icons">add</i>
        Add Request
      </Button>
    </div>
  );
};

TableFooter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  approveRows: PropTypes.func.isRequired,
  addRequest: PropTypes.func.isRequired
};

export default CSSModules(TableFooter, css);
