import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { withRouter } from "react-router";
import {
  fieldsToPass,
  typesToPass,
  shouldShowTypes,
  getTypeName
} from "../selectors";

import {
  Div,
  BigFilledButton,
  RightArrowIcon,
  LoadingIcon,
  Dropdown
} from "components/Base";

import { noop } from "utils/General";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Header from "./Header";

const Label = ({ children, required }) => (
  <Div
    display="row.flex-start.center"
    fs={1}
    color="gray5"
    uppercase
    mb={1}
    fw={3}
  >
    <Div>{children}</Div>
    {required ? (
      <Div ml={1} color="red7">
        *
      </Div>
    ) : null}
  </Div>
);

const FieldWrapper = ({ children }) => <Div mb={3}>{children}</Div>;

const Field = ({ Editor, name, onChange, value, editorProps, isRequired }) => {
  const maxLength = R.pathOr(
    "",
    ["column", "settings", "maxLength"],
    editorProps
  );
  return (
    <FieldWrapper>
      <Label required={isRequired}>{name}</Label>
      <Editor
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        {...editorProps}
      />
      {!R.isEmpty(maxLength) ? (
        <div
          style={{
            fontSize: 12,
            padding: "4px 0 0 4px",
            color: "#aaa",
            fontWeight: 600
          }}
        >
          {R.length(R.propOr("", "value", value))}/{maxLength} Character
          {R.length(R.propOr("", "value", value)) !== 1 ? "s" : ""}
        </div>
      ) : null}
    </FieldWrapper>
  );
};

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      loading: getters.loading(state),
      error: getters.error(state),
      typeId: getters.typeId(state),
      types: typesToPass(state),
      fields: fieldsToPass(state),
      showTypes: shouldShowTypes(state),
      typeName: getTypeName(state),
      isModalOpened: getters.isModalOpened(state)
    }),
    {
      init: actions.init,
      onTypeChange: actions.updateType,
      hideModal: actions.hideModal,
      handleSave: actions.handleSave,
      updateValue: actions.updateValue
    }
  )
);

const View = ({
  loading,
  error,
  onTypeChange = noop,
  typeId,
  types,
  hideModal = noop,
  handleSave = noop,
  fields,
  showTypes = false,
  typeName = "",
  updateValue = noop,
  iniTypeId = null,
  accountId = null,
  isModalOpened = false,
  init = noop
}) => {
  useEffect(() => {
    init({ iniTypeId, accountId });
  }, []);

  const nonNameFields = R.filter(
    ({ id }) =>
      id !== STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME &&
      id !== STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
    fields
  );
  const firstName = R.find(
    R.propEq("id", STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME),
    fields
  );
  const lastName = R.find(
    R.propEq("id", STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME),
    fields
  );

  return (
    <MiniModalWrapper
      showModal={isModalOpened}
      hideModal={hideModal}
      showHeader={false}
      width={400}
      style={{
        backgroundColor: "white",
        height: "90vh"
      }}
    >
      <Header title="Add Person" typeName={typeName} hideModal={hideModal} />
      <Div
        display="column.flex-start.stretch"
        bg="white"
        brb={1}
        width={1}
        height={1}
        bb={1}
        bc="neutral1"
        shadow={1}
        style={{ overflowY: "auto", flex: "1 1 auto" }}
      >
        <Div px={7} py={4}>
          {loading ? (
            <Div mt={6} display="row.center.center" flex={1}>
              <LoadingIcon size={60} color="neutral4" />
            </Div>
          ) : (
            <Div>
              {showTypes ? (
                <FieldWrapper>
                  <Label required>Type</Label>
                  <Dropdown
                    options={types}
                    selected={typeId}
                    onChange={onTypeChange}
                  />
                </FieldWrapper>
              ) : null}

              <Div display="row.flex-start.center">
                <Div flex={1}>
                  <Field
                    {...firstName}
                    onChange={value =>
                      updateValue({ fieldId: firstName.id, value })
                    }
                  />
                </Div>
                <Div flex={1} ml={2}>
                  <Field
                    {...lastName}
                    onChange={value =>
                      updateValue({ fieldId: lastName.id, value })
                    }
                  />
                </Div>
              </Div>
              {R.map(
                f => (
                  <Field
                    key={f.id}
                    {...f}
                    onChange={value => updateValue({ fieldId: f.id, value })}
                  />
                ),
                nonNameFields
              )}
            </Div>
          )}
        </Div>
      </Div>
      <Div
        bg="white"
        width={1}
        display="column.flex-start.stretch"
        px={7}
        py={3}
        bt={1}
        bc="neutral2"
      >
        {error ? (
          <Div fw={3} fs={2} py={2} color="danger5">
            {error}{" "}
          </Div>
        ) : null}
        <Div display="row.flex-start.center">
          <BigFilledButton
            bg="indigo7"
            onClick={handleSave}
            RightIcon={RightArrowIcon}
            width={383}
            style={{ borderRadius: 20 }}
          >
            Save &amp; Review
          </BigFilledButton>
        </Div>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(View);
