import * as R from "ramda";

import getRawCellValue from "./getRawCellValue";
import getEditorId from "./getEditorId";

export const getCellId = ({ columnId, rowId }) =>
  R.join(" ", [columnId, rowId]);
export const reverseCellId = id => {
  const [columnId, rowId] = R.split(" ", id);

  return { columnId, rowId };
};

export { getEditorId, getRawCellValue };

export {
  getMeta,
  getSettings,
  getCellValue,
  getCellType,
  getHandlers,
  getRow,
  getRowMetadata,
  getIsRowEmpty,
  getIsRowDraft,
  ep
} from "./getters";
