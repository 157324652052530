import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  Text0,
  Text1,
  Text2,
  Text3,
  Text4,
  BigFilledButton,
  BigOutlineButton,
  LoadingIcon
} from "components/Base";
import { getSelectedTypeItems } from "Orders/CreateOrderModal/RequestSummary/selectors";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import CanUserDo from "components/Global/Security/CanUserDo";

import { actions as AddZonesModalActions } from "Orders/AddZonesModal/model";

import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(
  (state, props) => ({
    selectedTypeItems: getSelectedTypeItems(state, props),
    formatAmountForEvent: formatAmountForEvent(state, props)
  }),
  {
    openZonesPicker: AddZonesModalActions.openZonesPicker
  }
);

const RequestType = ({
  type,
  removeItem,
  openZonesPicker,
  isLastStep,
  formatAmountForEvent
}) => (
  <CollapsablePanel
    expandedBg="transparent"
    bg="transparent"
    arrowColor="neutral7"
    mt={2}
    heading={
      <Div key={type.id} flex={1} color="neutral7">
        {type.name}
      </Div>
    }
  >
    {R.map(
      item => (
        <Div
          display="row.space-between"
          mt={3}
          pr={3}
          pb={3}
          style={{ borderBottom: "dotted 1px #e7e6ea" }}
          className="hoverContainer"
        >
          <Div>
            <Div display="row.flex-start">
              <Text3 color="black" bold>
                {item.qty}
              </Text3>
              <Text3 color="black" bold ml={6}>
                {item.name}
              </Text3>
            </Div>
            {!R.isEmpty(R.propOr("", "description", item)) ? (
              <Div fs={1} color="gray6" noLineHeight>
                {item.description}
              </Div>
            ) : null}
            {R.propOr(false, "enabledZones", item) ? (
              <Div color="gray6" fs={1} display="row.flex-start.flex-start">
                {`Zones: ${
                  !R.isEmpty(R.propOr([], "zones", item))
                    ? R.compose(
                        R.join(", "),
                        R.map(zone => R.prop("code", zone))
                      )(item.zones)
                    : " - "
                }`}
                <CanUserDo
                  action={`${STANDARD_MODULES.credentials.id}_can_create_zone_overrides`}
                >
                  {!isLastStep && (
                    <Text1
                      ml={3}
                      underline
                      onClick={() =>
                        openZonesPicker({
                          itemId: item.id,
                          defaultSelected: R.compose(
                            R.map(R.prop("id")),
                            R.propOr([], "zones")
                          )(item)
                        })
                      }
                    >
                      Edit Zones
                    </Text1>
                  )}
                </CanUserDo>
              </Div>
            ) : null}
            <Text0 className="showOnHover" onClick={() => removeItem(item.id)}>
              Remove
            </Text0>
          </Div>
          <Div display="column.flex-start.flex-end">
            <Text3 color="black" bold>
              {item.totalPrice ? formatAmountForEvent(item.totalPrice) : "— "}
            </Text3>
            {item.eachPrice ? (
              <Text2>{formatAmountForEvent(item.eachPrice)}</Text2>
            ) : null}
          </Div>
        </Div>
      ),
      type.items
    )}
  </CollapsablePanel>
);

const RequestSummary = ({
  onNextStep,
  total,
  subtotal,
  onPreviousStep,
  nextBtnDisabled,
  selectedTypeItems,
  removeItem,
  btnBg = "primary7",
  btnColor = "white",
  btnText = "NEXT STEP",
  openZonesPicker,
  saving = false,
  isLastStep = false,
  formatAmountForEvent
}) => {
  return (
    <Div width={0.32} height={1} display="column.space-between">
      <Div>
        <Div
          flex={1}
          height={47}
          bg=""
          px={3}
          display="row.space-between.center"
        >
          <Text3 color="black" bold>
            Request Summary
          </Text3>
        </Div>
        <Div p={4} pt={0} style={{ overflowY: "auto" }} height={388} bg="white">
          {R.map(
            type => (
              <RequestType
                type={type}
                key={type.id}
                removeItem={removeItem}
                openZonesPicker={openZonesPicker}
                isLastStep={isLastStep}
                formatAmountForEvent={formatAmountForEvent}
              />
            ),
            selectedTypeItems
          )}
        </Div>
      </Div>
      <Div
        width={1}
        height={165}
        bt={1}
        bc="neutral1"
        p={4}
        display="column.space-between"
        bg="white"
      >
        <Div>
          <Div display="row.space-between" px={1} mt={1}>
            <Text2 bold color="black">
              Subtotal
            </Text2>
            <Text2 bold color="black">
              {formatAmountForEvent(subtotal)}
            </Text2>
          </Div>
          <Div display="row.space-between" px={1} mt={5}>
            <Text4 bold color="black">
              Total
            </Text4>
            <Text4 bold color="primary7">
              {formatAmountForEvent(total)}
            </Text4>
          </Div>
        </Div>
        <Div display="row.flex-start.center">
          <BigOutlineButton mr={2} onClick={onPreviousStep}>
            BACK
          </BigOutlineButton>
          <Div flex={1}>
            <BigFilledButton
              bg={btnBg}
              color={btnColor}
              onClick={onNextStep}
              width={1}
              disabled={nextBtnDisabled}
            >
              {saving ? (
                <LoadingIcon size={20} color="white" ml={3} />
              ) : (
                btnText
              )}
            </BigFilledButton>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(RequestSummary);
