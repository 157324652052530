import * as R from "ramda";
import React from "react";

import { createContext } from "redux-mvc";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";

import { removePerson } from "redux/modules/portal/person/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { updateType } from "redux/modules/modules/records/actions";
import { sendEmail } from "redux/modules/email/actions";
import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";
import { addRecord } from "redux/modules/modules/records/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { eventDetails as portalEventDetails } from "redux/modules/portal/selectors";
import { accountName } from "redux/modules/accounts/profile/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { accounts } from "redux/modules/portal/user/selectors";

import Layout from "./Layout";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import PersonModal from "Portal/PortalPeople/PersonModal";
import AddPersonModal from "Portal/PortalPeople/AddPersonModal";
import EditRelatedContactModal from "components/Event/Accounts/Account/Modals/EditRelatedContactModal";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";
import ChangeRecordTypeModal from "components/Global/CRM/Modals/ChangeRecordType";
import AddItemsModal from "Portal/PortalPeople/PersonModal/Modals/AddItemsModal";
import DeleteRecordsModal from "components/Global/CRM/Modals/DeleteConfirmation";
import SendEmailModal from "SendEmailModal/View";
import LoginDetailsModal from "components/Event/Accounts/Account/People/LoginDetailsModal";
import ImportModal from "Modules/ImportModal/View";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import module, { actions } from "Accounts/PeoplePanels";
import rootSaga from "Accounts/PeoplePanels/sagas";

module.setRootSaga(rootSaga);

import {
  updateRelationship,
  deleteRelationship
} from "redux/modules/accounts/contactRelationship/actions";
import { deleteLineItem } from "redux/modules/orders/line-items/actions";

import { deleteAccountUser } from "redux/modules/accounts/users/actions";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => {
      let eventDetailsToPass = eventDetails(state);
      let accountNameToPass = null;
      let paramsToPass = props.params;
      const portalAccountId = R.path(["active_view", "id"], portalUser(state));

      if (!eventDetailsToPass || !eventDetailsToPass.id) {
        eventDetailsToPass = portalEventDetails(state);
      }

      if (portalAccountId) {
        paramsToPass.recordId = portalAccountId;

        const acct = accounts(state).find(({ id }) => id === portalAccountId);

        if (acct) {
          accountNameToPass = acct.name;
        }
      } else {
        accountNameToPass = accountName(state);
      }

      return {
        params: {
          ...props.params,
          recordId: portalAccountId || props.accountId || props.params.recordId,
          eventId:
            eventDetailsToPass.id || props.eventId || props.params.eventId
        },
        eventDetails: eventDetailsToPass,
        accountName: accountNameToPass,
        orgId: getOrgId(state)
      };
    },
    {
      hideModal,
      showModal,
      showSnackbar,
      navigate: push,
      deleteRelationship,
      updateRelationship,
      deleteAccountUser,
      deleteLineItem,
      updateType,
      removePerson,
      sendEmail,
      addRecord,
      addRelationship
    }
  ),
  createContext({
    module: module,
    options: {
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(R.join("|", module.modules))),
        R.prop("type")
      ),
      observedDomains: [
        "user",
        "event",
        "accounts",
        "routing",
        "portal",
        "items",
        "@flopflip"
      ]
    },
    lifeCycle: {
      componentDidMount() {
        this.store.dispatch(actions.refreshData());
      }
    },
    handlers: {
      refreshData() {
        this.store.dispatch(actions.refreshData());
      },
      viewDetails(id) {
        this.props.showModal({
          content: (
            <PersonModal
              moduleId={STANDARD_MODULE_IDS.contacts.id}
              accountId={this.props.params.recordId}
              recordId={id}
              isViewingAsAdmin={this.props.isAdminView}
              hideModal={() => {
                this.props.hideModal();
                this.handlers.refreshData();
              }}
            />
          ),
          wrapper: ModalWrapper,
          disableClickToClose: true
        });
      },
      addPerson({ typeId }) {
        this.props.showModal({
          content: (
            <AddPersonModal
              accountId={this.props.params.recordId}
              typeId={typeId}
              onSave={async ({ typeId, isPrimary, role, values }) => {
                const createdContact = await this.props.addRecord({
                  moduleId: STANDARD_MODULE_IDS.contacts.id,
                  typeId,
                  record: values,
                  options: {
                    eventId: this.props.eventDetails.id
                  }
                });

                this.props.showSnackbar({ message: "Person added" });

                await this.props.addRelationship({
                  eventId: this.props.eventDetails.id,
                  isPrimary,
                  role,
                  accountId: this.props.params.recordId,
                  contactId: createdContact.id
                });

                this.handlers.refreshData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      openProfile(id) {
        this.props.navigate(
          window.location.pathname.includes("light")
            ? `/event-light/${this.props.params.eventId}/contact/${id}?viaAccount=${this.props.params.recordId}`
            : `/event/${this.props.params.eventId}/contact/${id}?viaAccount=${this.props.params.recordId}`
        );
      },
      changeRole({ contactId, role }) {
        this.props.showModal({
          content: (
            <EditRelatedContactModal
              onSave={async ({ role }) => {
                await this.props.updateRelationship({
                  role,
                  contactId,
                  orgId: this.props.params.orgId,
                  eventId: this.props.params.eventId,
                  accountId: this.props.params.recordId
                });

                this.handlers.refreshData();
              }}
              relationshipId={contactId}
              role={role}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      editType(contactId, typeId) {
        this.props.showModal({
          content: (
            <ChangeRecordTypeModal
              moduleId={STANDARD_MODULE_IDS.contacts.id}
              typeId={typeId}
              update={async ({ type }) => {
                await this.props.updateType({
                  moduleId: STANDARD_MODULE_IDS.contacts.id,
                  recordId: contactId,
                  type,
                  options: {
                    orgId: this.props.params.orgId,
                    eventId: this.props.params.eventId
                  }
                });
                this.handlers.refreshData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      async togglePrimary(contactId, isPrimary) {
        await this.props.updateRelationship({
          isPrimary,
          contactId,
          orgId: this.props.orgId,
          eventId: this.props.params.eventId,
          accountId: this.props.params.recordId
        });

        return this.handlers.refreshData();
      },
      manageLogin(contactId) {
        this.props.showModal({
          content: (
            <BulkLoginDetails
              contacts={
                typeof contactId === "string"
                  ? [
                      {
                        contactId,
                        accountId: this.props.params.recordId
                      }
                    ]
                  : this.getSelectedContactRecords().map(record => ({
                      // @NOTE: what's selected contact records
                      contactId: record.contact_id,
                      accountId: this.props.params.recordId
                    }))
              }
              onDone={this.handlers.refreshData}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      async removeLogin(userId) {
        await this.props.deleteAccountUser({
          userId,
          eventId: this.props.params.eventId,
          accountId: this.props.params.recordId
        });
        this.handlers.refreshData();
      },
      showAddItemsModal({ contactId, contactIds, typeId }) {
        this.props.showModal({
          content: (
            <AddItemsModal
              activeTypeId={typeId}
              eventDetails={this.props.eventDetails}
              accountId={this.props.params.recordId}
              contactId={contactId}
              contactIds={contactIds}
              mode="add"
              onDone={this.handlers.refreshData}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      async removeLineItem(lineItemId) {
        await this.props.deleteLineItem({
          lineItemId
        });

        this.handlers.refreshData();
      },
      importRows({ id: typeId }) {
        this.props.showModal({
          content: (
            <ImportModal
              eventId={this.props.eventDetails.id}
              accountId={this.props.params.recordId}
              typeId={typeId}
              onDone={() => {
                this.handlers.refreshData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showRemovePersonModal(contactIds) {
        this.props.showModal({
          content: (
            <DeleteRecordsModal
              hideModal={this.props.hideModal}
              heading={`Remove ${contactIds.length} ${
                contactIds.length === 1 ? "person" : "people"
              }?`}
              message={
                <div>
                  {`
                      Are you sure you want to remove ${
                        contactIds.length === 1
                          ? "this"
                          : `these ${contactIds.length}`
                      } ${contactIds.length === 1 ? "person" : "people"}?
                      `}
                  <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                    This cannot be undone and will remove any assigned items.
                  </div>
                </div>
              }
              onConfirm={async () => {
                await Promise.all(
                  contactIds.map(({ contactId, contactUserId }) =>
                    this.props.removePerson({
                      eventId: this.props.eventDetails.id,
                      accountId: this.props.params.recordId,
                      contactId,
                      contactUserId
                    })
                  )
                );

                return this.handlers.refreshData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showSendEmailModal(contactId) {
        this.props.showModal({
          content: (
            <SendEmailModal
              moduleId={STANDARD_MODULE_IDS.contacts.id}
              records={[contactId]}
              selectedOptions={[]}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      loginDetails(contact) {
        this.props.showModal({
          content: (
            <LoginDetailsModal
              showFlashMessage={false}
              email={contact.email}
              name={contact.name}
              hideModal={this.props.hideModal}
              eventDetails={this.props.eventDetails}
              onEmailSend={async ({ customMessage }) => {
                const { eventDetails, accountName } = this.props;

                const variables = {
                  message: customMessage,
                  event_name: eventDetails.name,
                  event_slug: eventDetails.slug,
                  portal_id: eventDetails.uuid,
                  account_name: accountName,
                  user_email: contact.email
                };

                // @FIX SEND EMAIL
                await this.props.sendEmail({
                  eventId: eventDetails.id,
                  templateName: "notification-account-invite",
                  recipients: [{ email: contact.email }],
                  variables: Object.keys(variables).map(key => ({
                    name: key,
                    content: variables[key]
                  }))
                });

                this.props.showSnackbar({ message: "Email sent" });
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    }
  })
);

export default decorate(Layout);
