/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getPages: ({ credentials, eventId }) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sites/pages/event/${eventId}`,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    }),
  saveSections: ({ credentials, data, permissionSetId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/account/permission-sets/${permissionSetId}/settings`,
        credentials,
        data,
        success,
        error
      });
    }),
  getSettings: ({ credentials, permissionSetId }) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/account/permission-sets/${permissionSetId}/settings`,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    })
};
