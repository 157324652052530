import React from "react";
import { Div } from "components/Base";

import { INTEGRATIONS } from "utils/integration-types";

export const EVENTBRITE_LOGO_SRC = INTEGRATIONS.eventbrite.image_url;
export const FRONTGATE_LOGO_SRC = INTEGRATIONS.frontgate.image_url;
export const GINGERBREAD_SHED_LOGO_SRC = INTEGRATIONS.gingerbreadshed.image_url;
export const INTELLITIX_LOGO_SRC = INTEGRATIONS.intellitix.image_url;
export const GLOWNET_LOGO_SRC = INTEGRATIONS.glownet.image_url;
export const TOKEN_LOGO_SRC = INTEGRATIONS.token.image_url;

const EVENTBRITE_LOGO = (
  <Div
    size={15}
    bg="orange5"
    mr={2}
    style={{
      flexShrink: 0,
      backgroundImage: `url(${EVENTBRITE_LOGO_SRC})`,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    }}
  />
);

const FRONTGATE_LOGO = (
  <Div
    size={15}
    bg="red8"
    mr={2}
    style={{
      flexShrink: 0,
      backgroundImage: `url(${FRONTGATE_LOGO_SRC})`,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    }}
  />
);

const GINGERBREAD_SHED_LOGO = (
  <Div
    size={15}
    bg="white"
    mr={2}
    style={{
      flexShrink: 0,
      backgroundImage: `url(${GINGERBREAD_SHED_LOGO_SRC})`,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    }}
  />
);

const INTELLITIX_LOGO = (
  <Div
    size={15}
    bg="green7"
    mr={2}
    style={{
      flexShrink: 0,
      backgroundImage: `url(${INTELLITIX_LOGO_SRC})`,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    }}
  />
);

const GLOWNET_LOGO = (
  <Div
    size={15}
    bg="white"
    mr={2}
    style={{
      flexShrink: 0,
      backgroundImage: `url(${GLOWNET_LOGO_SRC})`,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    }}
  />
);

const TOKEN_LOGO = (
  <Div
    size={15}
    bg="white"
    mr={2}
    style={{
      flexShrink: 0,
      backgroundImage: `url(${TOKEN_LOGO_SRC})`,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    }}
  />
);

export const IntegrationIcons = {
  eventbrite: EVENTBRITE_LOGO,
  frontgate: FRONTGATE_LOGO,
  gingerbreadshed: GINGERBREAD_SHED_LOGO,
  intellitix: INTELLITIX_LOGO,
  glownet: GLOWNET_LOGO,
  token: TOKEN_LOGO
};

export const IntegrationIconsById = {
  [INTEGRATIONS.eventbrite.id]: EVENTBRITE_LOGO,
  [INTEGRATIONS.frontgate.id]: FRONTGATE_LOGO,
  [INTEGRATIONS.gingerbreadshed.id]: GINGERBREAD_SHED_LOGO,
  [INTEGRATIONS.intellitix.id]: INTELLITIX_LOGO,
  [INTEGRATIONS.glownet.id]: GLOWNET_LOGO,
  [INTEGRATIONS.token.id]: TOKEN_LOGO
};
