const textFilter = require("./text");
const dropdownFilter = require("./dropdown");
const checkboxFilter = require("./checkbox");
const sourceFilter = require("./source");

module.exports = data => {
  switch (data.type) {
    case "number":
    case "email":
    case "textarea":
    case "text":
      return textFilter(data);
    case "checkbox":
      return checkboxFilter(data);
    case "dropdown":
      return dropdownFilter(data);
    case "source":
      return sourceFilter(data);
    default:
      return true;
  }
};
