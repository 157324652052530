import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, TABS } from "Event/TasksWidget/constants";

const iniState = {
  loading: false,
  data: [],
  selectedTab: TABS.DUE
};

const model = createHandlers({
  iniState,
  reducers: {
    setInitialData: (state, { payload: { data } }) => ({
      data
    }),
    init: R.identity,
    goToMyTasks: R.identity,
    goToSchedule: R.identity,
    handleShowAddRecordModal: R.identity,
    viewTask: R.identity,
    toggleTask: (state, { payload: { task, toggled } }) => {
      const updatedTask = {
        ...task,
        is_complete: toggled
      };

      return {
        data: toggled
          ? {
              count_of_assigned_to_me: state.data.count_of_assigned_to_me - 1,
              count_of_due: task.is_due
                ? state.data.count_of_due - 1
                : state.data.count_of_due,
              assigned_to_me: R.filter(t => t.id !== task.id)(
                state.data.assigned_to_me
              ),
              completed: [...state.data.completed, updatedTask],
              due: R.filter(t => t.id !== task.id)(state.data.due)
            }
          : {
              count_of_assigned_to_me: state.data.count_of_assigned_to_me + 1,
              count_of_due: task.is_due
                ? state.data.count_of_due + 1
                : state.data.count_of_due,
              assigned_to_me: [...state.data.assigned_to_me, updatedTask],
              completed: R.filter(t => t.id !== task.id)(state.data.completed),
              due: task.is_due
                ? [...state.data.due, updatedTask]
                : state.data.due
            }
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
