import PropTypes from "prop-types";

export const EventbriteTicketClassesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    eventbrite_ticket_class_id: PropTypes.string
  })
);

export const EventbriteEventsPropTypes = PropTypes.shape({
  pagination: PropTypes.shape({
    object_count: PropTypes.number,
    page_number: PropTypes.number,
    page_size: PropTypes.number,
    page_count: PropTypes.number
  }),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      ticket_classes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          type: PropTypes.string,
          price: PropTypes.shape({
            value: PropTypes.number,
            currency: PropTypes.string
          }),
          description: PropTypes.string,
          sales_start: PropTypes.string,
          sales_end: PropTypes.string,
          maximum_quantity_per_order: PropTypes.number,
          quantity_total: PropTypes.number,
          quantity_sold: PropTypes.number,
          quantity_available: PropTypes.number
        })
      )
    })
  )
});
