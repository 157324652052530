import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";

import { actions, getters } from "Orders/OrderModal";
import { registerError } from "redux/modules/errors/actions";
import orderModalApi from "Orders/OrderModal/api";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getOrderId, getSelectedTab } from "Orders/OrderModal/selectors";
import { MESSAGE_TABS } from "Orders/OrderModal/constants";

// submissionEditor module
import {
  getOrderId as getSubmissionOrderId,
  getSubmissionRecordId,
  eventDetails
} from "Submission/Editor/selectors";

const getNonEmptyValue = R.compose(
  R.head,
  R.filter(
    R.compose(
      R.not,
      R.and(R.isEmpty, R.isNil)
    )
  )
);

const loadFeed = function*() {
  try {
    yield put(actions.setFeedLoading(true));

    const event = yield select(eventDetails);
    const eventId = getNonEmptyValue([yield select(getEventId), event.id]);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const recordId = yield select(getSubmissionRecordId);
    const credentials = yield select(getCredentials);

    const result = yield call(orderModalApi.getFeed, credentials, {
      eventId,
      orderId,
      recordId
    });
    yield put(actions.setFeed(result.payload.feed));
    yield put(actions.setRecipients(result.payload.recipients));
    yield put(actions.setFeedLoading(false));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching feed"
          }
        ])
      ),
      put(actions.setFeedLoading(false, {}, true))
    ]);
  }
};

const watchLoadFeed = function*() {
  yield takeEvery(actions.loadFeed.type, loadFeed);
};

const markResolved = function*({ payload: noteId }) {
  try {
    const credentials = yield select(getCredentials);
    yield call(orderModalApi.updateNote, credentials, {
      noteId,
      alert: false
    });
    yield put(actions.loadFeed());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred resolving note"
        }
      ])
    );
  }
};

const watchMarkResolved = function*() {
  yield takeEvery(actions.markResolved.type, markResolved);
};

const sendMessage = function*() {
  try {
    yield put(actions.setSending(true));
    const state = yield select(R.identity);
    const selectedTab = yield select(getSelectedTab);

    const action = R.prop(selectedTab.id)({
      [MESSAGE_TABS.MESSAGE]: orderModalApi.addMessage,
      [MESSAGE_TABS.NOTE]: orderModalApi.addNote
    });

    const event = yield select(eventDetails);
    const eventId = getNonEmptyValue([yield select(getEventId), event.id]);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const recordId = yield select(getSubmissionRecordId);
    const credentials = yield select(getCredentials);
    const content = getters.newMessage(state);

    yield call(action, credentials, {
      eventId,
      orderId,
      recordId,
      content,
      alert: false
    });
    yield put(actions.loadFeed());
    yield put(actions.setNewMessage(""));
    yield put(actions.setSending(false));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding to feed"
          }
        ])
      ),
      put(actions.setSending(false, {}, true))
    ]);
  }
};

const watchSendMessage = function*() {
  yield takeEvery(actions.sendMessage.type, sendMessage);
};

const loadTab = function*({ payload }) {
  if (payload === "messages") {
    yield put(actions.loadFeed());
  }
};

const watchSelectTab = function*() {
  yield takeEvery(actions.setSelectedTab.type, loadTab);
};

const rootSaga = function*() {
  yield all([
    fork(watchLoadFeed),
    fork(watchMarkResolved),
    fork(watchSendMessage),
    fork(watchSelectTab)
  ]);
};

export default rootSaga;
