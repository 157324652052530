import * as R from "ramda";
import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Submit,
  ButtonGroup,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import { search } from "redux/modules/accounts/list/api";
import AccountTypeIcon from "components/Global/CRM/AccountTypeIcon";

import { connect } from "react-redux";
import { user, getCredentials } from "redux/modules/user/selectors";
import { withRouter } from "react-router";

const decorate = R.compose(
  connect(state => ({
    userId: user(state).id,
    userCredentials: getCredentials(state)
  })),
  withRouter
);

export const Account = CSSModules(
  ({ type, name, onSelect }) => (
    <div styleName="accountContainer">
      <div styleName="iconWrapper">
        <AccountTypeIcon
          {...type}
          backgroundColor={type.background_color}
          color={type.text_color}
        />
      </div>
      <div styleName="name">{name}</div>
      {onSelect ? (
        <div styleName="select" onClick={onSelect}>
          select
        </div>
      ) : (
        ""
      )}
    </div>
  ),
  css
);

@CSSModules(css)
class AddRelatedAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      role: null,
      selected: null
    };
  }

  @autobind
  searchAccounts(query) {
    search(
      this.props.params.orgId,
      this.props.params.eventId,
      query.target.value,
      this.props.userCredentials
    ).then(({ accounts }) => {
      this.setState({ results: accounts });
    });
  }

  @autobind
  handleSelect(account) {
    this.setState({ selected: account });
  }

  @autobind
  handleRoleChange(e) {
    this.setState({ role: e.target.value });
  }

  @autobind
  handleDeselect() {
    this.setState({ selected: null, role: null });
  }

  @autobind
  addRelationship() {
    const { role, selected } = this.state;
    this.props.addRelationship({
      eventId: this.props.params.eventId,
      role,
      accountId: selected.id
    });
  }

  render() {
    const { hideModal, onAddAccount } = this.props;

    const results = this.state.results.length ? (
      <div styleName="listContainer">
        <div styleName="heading">Results</div>
        <div styleName="list">
          {this.state.results.map(a => (
            <Account key={a.id} {...a} onSelect={() => this.handleSelect(a)} />
          ))}
        </div>
      </div>
    ) : (
      ""
    );

    const selectedView = this.state.selected ? (
      <span>
        <div styleName="goBackWrapper">
          <div styleName="goBack" onClick={this.handleDeselect}>
            <i className="material-icons">keyboard_arrow_left</i>
            Back to results
          </div>
        </div>
        <Account {...this.state.selected} />
        <form onSubmit={this.addRelationship}>
          <div styleName="roleWrapper">
            <div styleName="label">
              <span>Role with this group</span>
              <span styleName="caption">Optional*</span>
            </div>
            <input
              type="text"
              autoFocus
              styleName="input"
              onChange={this.handleRoleChange}
            />
          </div>
          <ButtonGroup>
            <Submit title="Add" />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </span>
    ) : (
      <span>
        <div styleName="inputWrapper">
          <input
            autoFocus
            onChange={this.searchAccounts}
            placeholder="Search by group name"
            styleName="input"
            type="text"
          />
          <i className="material-icons">search</i>
        </div>
        {results}
        <div styleName="createAccount">
          Can't find the group you're looking for?
          <div styleName="accountButton" onClick={onAddAccount}>
            Create a group
          </div>
        </div>
      </span>
    );

    return (
      <StyleWrapper
        heading="Add related group"
        hideModal={hideModal}
        width={500}
      >
        {selectedView}
      </StyleWrapper>
    );
  }
}

AddRelatedAccountModal.propTypes = {
  addRelationship: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  onAddAccount: PropTypes.func.isRequired
};

export default decorate(AddRelatedAccountModal);
