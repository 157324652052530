import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { getters } from "../model";
import Loading from "ui-kit/Loading";

const decorator = connect((state, props) => ({
  title: getters.title(state, props),
  content: getters.content(state, props),
  loading: getters.loading(state, props)
}));

const Layout = ({ loading, title, content, ...otherProps }) => (
  <Div
    display="column.flex-start.flex-start"
    {...otherProps}
    style={{
      maxWidth: "615px",
      margin: "0px auto",
      marginTop: "53px"
    }}
  >
    {loading ? (
      <Div width={1} mt={5} display="row.center.center">
        <Loading />
      </Div>
    ) : (
      <>
        <Div
          style={{
            color: "#222222",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: 0,
            marginBottom: "32px"
          }}
        >
          {title}
        </Div>
        <Div
          style={{
            color: "#222222",
            fontFamily: "Roboto",
            fontSize: "16px",
            letterSpacing: 0,
            lineHeight: "30px",
            whiteSpace: "pre-wrap"
          }}
        >
          <span
            className="lennd-wysiwyg-content"
            dangerouslySetInnerHTML={{
              __html: content
            }}
          />
        </Div>
      </>
    )}
  </Div>
);

export default decorator(Layout);
