import * as R from "ramda";
import { connect } from "react-redux";
import React, { useMemo } from "react";
import { get } from "lodash";

import getLookupValue from "utils/value-types/get-value/lookup";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";

import { Div } from "components/Base";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import { CONTACTS } from "utils/standard-module-field-ids";

import { showModal } from "redux/modules/modal/actions";

import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { noop } from "utils/General";

import * as DataSelectors from "App/Data/selectors";

const { ID } = SYSTEM_FIELD_IDS;
const { EMAIL } = CONTACTS;

const getLinkedRecordIds = props => {
  const { records } = getLookupValue(props.value);
  return records;
};

const getRecordLabel = (recordId, linkedField, linkedRecord) => {
  if (!linkedField || linkedField.id === ID) {
    const value =
      R.path(["values", ID], linkedRecord) || R.prop(ID, linkedRecord);
    return toString(getValue(value, "text"), "text");
  }

  const value =
    R.path(["values", linkedField.id], linkedRecord) ||
    R.prop(linkedField.id, linkedRecord);
  const name = toString(getValue(value, linkedField.type), linkedField.type);

  // if attempting to get full name and no value, return email
  if ((!name || !name.length) && linkedField.id === "full_name") {
    return toString(getValue(linkedRecord.values[EMAIL], "email"), "email");
  }

  return name;
};

const getRecords = ({
  linkedFieldId,
  fieldReferences,
  recordReferences,
  recordIds
}) => {
  const field = R.prop(linkedFieldId, fieldReferences);
  return R.filter(
    R.compose(
      R.not,
      R.isNil
    ),
    R.map(recordId => {
      const record = R.prop(recordId, recordReferences);
      if (!record) {
        return null;
      }
      return {
        id: recordId,
        label: getRecordLabel(recordId, field, record)
      };
    }, recordIds)
  );
};

const decorate = connect(
  state => ({
    recordReferences: DataSelectors.getAllRecordReferencesById(state),
    fieldReferences: DataSelectors.getAllFieldReferencesById(state)
  }),
  { showModal }
);

const LinkedRecordsCellFormatted = props => {
  const {
    dependentValues,
    showModal,
    recordReferences,
    fieldReferences
  } = props;
  const recordIds = getLinkedRecordIds(props);
  const disableClickingRecord = get(
    dependentValues,
    "meta.columnSettings.disableClickingRecord"
  );

  if (!recordIds) return null;

  const linkedModuleId = get(dependentValues, "meta.columnSettings.moduleId");
  const linkedFieldId = get(dependentValues, "meta.columnSettings.fieldId");

  const records = useMemo(
    () =>
      getRecords({
        linkedFieldId,
        recordReferences,
        fieldReferences,
        recordIds
      }),
    [linkedFieldId, recordReferences, fieldReferences, recordIds]
  );

  const showRecordModal = recordId => {
    showModal({
      content: (
        <ViewRecordModal moduleId={linkedModuleId} recordId={recordId} />
      ),
      wrapper: ModalWrapper
    });
  };

  return (
    <Div
      display="row"
      style={{
        overflow: "hidden"
      }}
    >
      {R.map(
        record => (
          <Div
            key={record.id}
            bra={1}
            fw={3}
            bg={
              disableClickingRecord
                ? "neutral1"
                : { default: "neutral1", hover: "neutral0" }
            }
            onClick={
              disableClickingRecord ? noop : () => showRecordModal(record.id)
            }
            color="gray9"
            px={2}
            py={1}
            mr={1}
            truncate
            style={{
              flexShrink: 0
            }}
          >
            {record.label}
          </Div>
        ),
        records
      )}
    </Div>
  );
};

export default decorate(LinkedRecordsCellFormatted);
