import React, { Fragment } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import moment from "moment";
import {
  Div,
  Text0,
  Text2,
  Text3,
  Text4,
  Text6,
  BigFilledButton
} from "components/Base";
import MediaQuery from "react-responsive";
import { actions, getters } from "SalesPortal/Home";
import EventPage from "Common/EventPage";
import { hasItemsForButtonType } from "SalesPortal/Home/selectors";

const decorate = connect(
  state => ({
    data: getters.salesData(state),
    showingPhoto: getters.showingPhoto(state),
    showMapFloor: getters.showMapFloor(state),
    hasItemsForButtonType: id => hasItemsForButtonType(state, { id })
  }),
  {
    showModal: actions.showModal,
    showPhoto: actions.setShowingPhoto,
    setMapFloor: actions.setShowMapFloor
  }
);

const Mobile = props => <MediaQuery {...props} maxWidth={1023} />;
const Default = props => <MediaQuery {...props} minWidth={1024} />;

const formatNumber = number =>
  number
    ? number.length < 4
      ? number
      : `${number.slice(0, -3)},${number.slice(-3)}`
    : null;

const EventInfo = ({
  showModal,
  hasItemsForButtonType,
  data: {
    event_description: description,
    files,
    photos,
    event_instructions: instructions,
    event_date_from: dateFrom,
    event_date_to: dateTo,
    name,
    venue_name: venueName,
    city,
    state,
    number_of_attendees: attendees,
    address_line_1: addressLine1,
    zip_code: zipCode,
    background_url: coverImage,
    floor_map_image_url: floorMapimage,
    website_url: websiteUrl,
    primary_button_text: primaryButtonText,
    primary_button_color: primaryButtonColor,
    primary_button_opens_to: primaryButtonOpensTo,
    secondary_button_text: secondaryButtonText,
    secondary_button_opens_to: secondaryButtonOpensTo
  },
  showingPhoto,
  showPhoto,
  showMapFloor,
  setMapFloor
}) => {
  const mapsLink = venueName
    ? `https://maps.google.com/?q=${[
        venueName,
        addressLine1,
        city,
        state,
        zipCode
      ]
        .filter(v => v)
        .join(", ")}`
    : null;

  const startDate = moment(dateFrom).format("LL");
  const endDate = moment(dateTo).format("LL");

  const startDateSmall = moment(dateFrom).format("ddd MMM D");
  const endDateSmall = moment(dateTo).format("ddd MMM D");

  const startTime = moment(dateFrom).format("h:mmA");
  const endTime = moment(dateTo).format("h:mmA");

  const props = {
    showModal,
    description,
    files,
    photos,
    instructions,
    coverImage,
    floorMapimage,
    showingPhoto,
    showPhoto,
    showMapFloor,
    setMapFloor,
    venueName,
    city,
    state,
    addressLine1,
    zipCode,
    mapsLink,
    websiteUrl,
    primaryButtonText,
    primaryButtonColor,
    primaryButtonOpensTo,
    secondaryButtonText,
    secondaryButtonOpensTo,
    startDate,
    endDate,
    startDateSmall,
    endDateSmall,
    startTime,
    endTime
  };

  const showSecondButton =
    secondaryButtonText &&
    secondaryButtonText.length &&
    hasItemsForButtonType(secondaryButtonOpensTo);

  return (
    <EventPage {...props}>
      <Fragment>
        <Mobile>
          <Div width={1} py={2} px={3} display={["column", "column", "row"]}>
            <Div flex={1} bra={1} bg="neutral0" p={5}>
              <Div>
                <Text3>
                  {startDate !== endDate
                    ? `${startDate} - ${endDate}`
                    : startDate}
                </Text3>
              </Div>
              <Div width={1} mt={2}>
                <Text6 bold>{name}</Text6>
              </Div>
              <Text0 my={1}>{R.toUpper(`${city}, ${state}`)}</Text0>
              {attendees && attendees.length ? (
                <Text0 my={1}>{`${formatNumber(attendees)} ATTENDEES`}</Text0>
              ) : null}
              <BigFilledButton
                mt={6}
                width={1}
                bg={primaryButtonColor || "#329af0"}
                onClick={() => showModal(primaryButtonOpensTo)}
              >
                {primaryButtonText || "Select Items"}
              </BigFilledButton>
              {secondaryButtonText &&
              secondaryButtonText.length &&
              hasItemsForButtonType(secondaryButtonOpensTo) ? (
                <BigFilledButton
                  mt={2}
                  width={1}
                  onClick={() => showModal(secondaryButtonOpensTo)}
                >
                  {secondaryButtonText}
                </BigFilledButton>
              ) : null}
            </Div>
            <Div
              flex={1}
              p={6}
              bg="neutral1"
              bra={1}
              ml={[0, 0, 2]}
              mt={[2, 2, 0]}
            >
              <Div display="column" mb={5} pb={5} bc="gray3" bb={1}>
                <Div fs={3} fw={3} uppercase>
                  Date and Time
                </Div>
                <Div fs={4} fw={3} mt={4}>
                  {startDateSmall !== endDateSmall
                    ? `${startDateSmall} - ${endDateSmall}`
                    : startDateSmall}
                </Div>
                <Div fs={4}>{`${startTime} - ${endTime}`}</Div>
              </Div>
              <Div display="column">
                <Div fs={3} fw={3} uppercase>
                  Location
                </Div>
                {venueName ? (
                  <Div fs={4} fw={3} mt={4}>
                    {venueName}
                  </Div>
                ) : null}
                {addressLine1 ? <Text4>{addressLine1}</Text4> : null}
                {city ? <Text4>{`${city}, ${state} ${zipCode}`}</Text4> : null}
                <a href={mapsLink} target="_blank">
                  <Text2 mt={4} color="danger9" underline>
                    Get Directions
                  </Text2>
                </a>
              </Div>
              {websiteUrl && websiteUrl.length ? (
                <Div display="column" mt={5} pt={5} bc="gray3" bt={1}>
                  <Div fs={3} fw={3} uppercase>
                    Website
                  </Div>

                  <a href={websiteUrl} target="_blank">
                    <Text2 mt={4} color="danger9" underline>
                      Visit Website
                    </Text2>
                  </a>
                </Div>
              ) : null}
            </Div>
          </Div>
        </Mobile>
        <Default>
          <Div
            width={[1, 1, 324]}
            p={2}
            pb={0}
            display="column.flex-start"
            bg="white"
            height={330}
          >
            <Div
              display="column.space-between.flex-start"
              width={1}
              bra={1}
              bg="neutral0"
              p={5}
              height={1}
            >
              <Div>
                <Text3>
                  {startDate !== endDate
                    ? `${startDate} - ${endDate}`
                    : startDate}
                </Text3>
              </Div>
              <Div display="column" width={1}>
                <Div width={190}>
                  <Text6 bold>{name}</Text6>
                </Div>
                <Text0 my={1}>{R.toUpper(`${city}, ${state}`)}</Text0>
                {attendees && attendees.length ? (
                  <Text0 my={1}>{`${formatNumber(attendees)} ATTENDEES`}</Text0>
                ) : null}
                <BigFilledButton
                  mt={6}
                  width={1}
                  bg={primaryButtonColor || "#329af0"}
                  onClick={() => showModal(primaryButtonOpensTo)}
                >
                  {primaryButtonText && primaryButtonText.length
                    ? primaryButtonText
                    : "Select Items"}
                </BigFilledButton>
                {showSecondButton ? (
                  <BigFilledButton
                    mt={2}
                    width={1}
                    onClick={() => showModal(secondaryButtonOpensTo)}
                  >
                    {secondaryButtonText}
                  </BigFilledButton>
                ) : null}
              </Div>
            </Div>
          </Div>
        </Default>
      </Fragment>
    </EventPage>
  );
};

export default decorate(EventInfo);
