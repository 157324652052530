import React, { Fragment } from "react";
import * as R from "ramda";
import { Link } from "react-router";
import Title from "components/Global/Module/Sidebar/Title";
import { Div, FilterIcon, Text2, RightArrowIcon } from "components/Base";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const doReturn = R.compose(
  R.not,
  R.isEmpty,
  R.pathOr("", ["query", "returnTo"])
);

const SidebarTitle = ({ title, location, params, leftIcon, groupName }) => {
  if (
    doReturn(location) ||
    params.filterByAccountRecordTypeId ||
    params.filterByContactRecordTypeId
  ) {
    return (
      <Fragment>
        <Link
          to={{
            pathname: params.filterByAccountRecordTypeId
              ? `/event/${params.eventId}/module/${STANDARD_MODULE_IDS.accounts.id}/dashboard/type/${params.filterByAccountRecordTypeId}`
              : params.filterByContactRecordTypeId
              ? `/event/${params.eventId}/module/${STANDARD_MODULE_IDS.contacts.id}/dashboard/type/${params.filterByContactRecordTypeId}`
              : location.query.returnTo
          }}
        >
          <Title style={{ cursor: "pointer" }}>
            <i className="material-icons" title="Go Back">
              keyboard_arrow_left
            </i>
            {title}
          </Title>
        </Link>
        {params.filterByAccountRecordTypeId ||
        params.filterByContactRecordTypeId ? (
          <Div
            display="row"
            mb={2}
            bra={1}
            style={{
              backgroundColor: "#9E69DC"
            }}
          >
            <Div
              style={{
                backgroundColor: "#9e61e4"
              }}
              p={2}
              display="column.flex-start.center"
            >
              <FilterIcon
                style={{
                  color: "#CAACEC"
                }}
              />
            </Div>
            <Div display="column" p={2}>
              <Text2 color="white">Filtered to: {groupName}</Text2>
              <Link
                to={{
                  pathname: `/event/${params.eventId}/module/${params.moduleId}/view/${params.viewId}`
                }}
              >
                <Div display="row.flex-start.center" pt={2}>
                  <Text2 underline color="white">
                    View all records
                  </Text2>
                  <RightArrowIcon color="white" ml={2} />
                </Div>
              </Link>
            </Div>
          </Div>
        ) : null}
      </Fragment>
    );
  }
  if (leftIcon) {
    return (
      <Div display="row.flex-start.center" pb={3}>
        <Div style={{ flexShrink: 0 }}>{leftIcon}</Div>
        <Div
          color="white"
          fw={3}
          style={{
            fontSize: 22
          }}
        >
          {title}
        </Div>
      </Div>
    );
  }
  return <Title>{title}</Title>;
};

export default SidebarTitle;
