import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import { getFiltersOn, getColumnActions, getRowActions } from "../selectors";
import { actions, getters } from "EventLight/Agenda/Manage";
import { TABLE_INSTANCE_ID } from "EventLight/Agenda/Manage/constants";

import PaginationBar from "./PaginationBar";
import Toolbar from "./Toolbar";
import Tabs from "./Tabs";
import FiltersOn from "ui-kit/FiltersOn";
import EmptyState from "EventLight/Common/EmptyState";
import Sidebar from "./Sidebar";

import ShowNoResults from "ui-kit/ShowNoResults";

import Table, { TableWrapper } from "ui-kit/Table/View";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      filtersOn: getFiltersOn(state, props),
      loading: getters.loading(state, props),
      showNoResults: getters.showNoResults(state, props),
      preferences: getters.preferences(state, props),
      columnActions: getColumnActions(state, props),
      rowActions: getRowActions(state, props),
      showEmptyState: getters.showEmptyState(state, props)
    }),
    {
      onClearFilters: actions.clearFilters,
      showAddSessionModal: actions.showAddSessionModal
    }
  )
);

const Body = ({
  handlers,
  filtersOn,
  onClearFilters,
  rowActions = [],
  columnActions = [],
  loading = false,
  showEmptyState = false,
  showNoResults = false,
  showAddSessionModal,
  instanceId
}) => (
  <Div display="row.center.stretch" style={{ height: "calc(100vh - 116px)" }}>
    <Sidebar handlers={handlers} instanceId={instanceId} />
    <Div
      flex={1}
      display="column.center.stretch"
      width={5} // @NOTE: This is a hack to have the table width nicely fit
    >
      <Div
        pr={4}
        bg="white"
        display="column.space-between.stretch"
        style={{ flexShrink: 0, position: "relative" }}
        bb={1}
        bc="neutral3"
      >
        <Toolbar handlers={handlers} instanceId={instanceId} />
        <Tabs />
        {/* Removing create order button*/}
      </Div>
      <FiltersOn
        on={filtersOn}
        onClearFilters={onClearFilters}
        caption="Only showing sessions that contain or match filters"
        instanceId={instanceId}
      />

      {showEmptyState ? (
        <EmptyState
          icon="view_day"
          title="You haven't added any sessions to your schedule yet"
          description="Use sessions to build out your event's schedule. A session can be a presentation or any other activity you want to show to your attendees."
          style={{
            margin: "30px",
            flex: 1
          }}
          action="Add Session"
          onClick={showAddSessionModal}
        />
      ) : showNoResults ? (
        <ShowNoResults flex={1} />
      ) : (
        <TableWrapper
          loading={loading}
          style={{ flex: 1 }}
          instanceId={TABLE_INSTANCE_ID}
        >
          <Table
            instanceId={TABLE_INSTANCE_ID}
            rowActions={rowActions}
            columnActions={columnActions}
            showOpenRecord
          />
        </TableWrapper>
      )}

      <PaginationBar instanceId={instanceId} />
    </Div>
  </Div>
);

export default decorate(Body);
