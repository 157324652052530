import React from "react";
import { Div } from "components/Base";
import Sidebar from "./Sidebar";
import StatsToolbar from "./StatsToolbar";
import Body from "./Body";

const Layout = ({ handlers }) => (
  <Div
    height={1}
    width={1}
    display="row.center.center"
    style={{ overflowX: "auto" }}
  >
    <Sidebar />
    <Div
      height={1}
      bg="neutral1"
      flex={1}
      width={5}
      display="column.center.stretch"
    >
      <StatsToolbar handlers={handlers} />
      <Body handlers={handlers} />
    </Div>
  </Div>
);

export default Layout;
