import { get } from "lodash";

export const isFetching = (state, recordId, moduleId, fieldId) =>
  get(
    state,
    `modules.relatedRecords.fetching[${recordId}][${moduleId}][${fieldId}]`,
    false
  );
export const records = (state, recordId, moduleId, fieldId) =>
  get(
    state,
    `modules.relatedRecords.records[${recordId}][${moduleId}][${fieldId}].records`,
    []
  );
export const fields = (state, recordId, moduleId, fieldId) =>
  get(
    state,
    `modules.relatedRecords.records[${recordId}][${moduleId}][${fieldId}].fields`,
    []
  );
export const preferences = (state, recordId, moduleId, fieldId) =>
  get(
    state,
    `modules.relatedRecords.records[${recordId}][${moduleId}][${fieldId}].preferences`,
    {}
  );
