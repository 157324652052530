import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import autobind from "autobind-decorator";
import Popover from "@lennd/material-ui/Popover";

class ActionsButtonMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  onRequestClose() {
    this.setState({ showPopover: false });
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({ showPopover: true });
  }

  @autobind
  callHandler(handler) {
    return () => {
      this.onRequestClose();
      handler(this.props);
    };
  }

  render() {
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          {cloneElement(this.props.menu, {
            wrapClick: this.callHandler
          })}
        </Popover>
      </div>
    );
  }
}

ActionsButtonMenu.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.node.isRequired
};

export default ActionsButtonMenu;
