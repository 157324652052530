import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import View from "./View";

import { checkSession } from "redux/modules/user/actions";
import { user, isSessionCheckComplete } from "redux/modules/user/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails as eventEventDetails } from "redux/modules/event/selectors";
import { eventDetails as portalEventDetails } from "redux/modules/portal/selectors";

function mapStateToProps(state, props) {
  const eventDetailsToPass = props.routes.find(r =>
    ["PortalV2_LoggedInView", "PortalV2_LoggedOutView"].includes(r.name)
  )
    ? portalEventDetails(state)
    : eventEventDetails(state);
  return {
    user: user(state),
    orgDetails: orgDetails(state) || {},
    eventDetails: eventDetailsToPass || {},
    isSessionCheckComplete: isSessionCheckComplete(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkSession
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View);
