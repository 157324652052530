import React, { Component } from "react";
import ReactDOM from "react-dom";
import DocumentTitle from "../Global/DocumentTitle";
import Helpers from "utils/Global/Helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import JoinApi from "utils/Join/WebAPIUtils";
import { showSnackbar } from "redux/modules/snackbar/actions";
import PasswordChecklist from "react-password-checklist";

const styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    paddingTop: 50,
    paddingBottom: 50,
    width: "100%",
    height: "100%",
    overflowY: "auto"
  },
  title: {
    fontWeight: 600,
    fontSize: 42,
    color: "#FFFFFF",
    lineHeight: "50px"
  }
};

class JoinVirtualUserHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualUser: null,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      checked: false
    };
  }

  componentDidMount() {
    this.fetchVirtualUser();
    ReactDOM.findDOMNode(this.refs.UserFirstName).focus();
  }

  fetchVirtualUser = async () => {
    try {
      const { payload } = await JoinApi.receiveVirtualUser(
        this.props.params.virtualUserHash
      );
      const user = payload.user;

      if (!user.is_virtual_user) {
        this.props.showSnackbar({
          message: "Your account has already been signed up."
        });

        this.props.router.push({ pathname: "/login" });
      } else {
        this.setState({
          virtualUser: user,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email
        });
      }
    } catch (err) {
      // handle error thrown if invite has already been accepted
      this.props.showSnackbar({
        message: "Your account has already been signed up."
      });
      this.props.router.push({ pathname: "/login" });
    }
  };

  validateData = async () => {
    if (!Helpers.isValidEmail(this.state.email)) {
      this.props.showSnackbar({ message: "Enter your email" });
    } else if (!this.state.firstName || !this.state.lastName) {
      this.props.showSnackbar({ message: "Enter your first and last name" });
    } else if (!Helpers.isValidPassword(this.state.password)) {
      this.props.showSnackbar({
        message: "Enter a valid password"
      });
    } else if (!this.state.checked) {
      this.props.showSnackbar({
        message: "Please agree to house rules before signing up"
      });
    } else {
      await this.saveUser();
    }
  };

  isValid = () => {
    if (!Helpers.isValidEmail(this.state.email)) {
      return false;
    } else if (!this.state.firstName || !this.state.lastName) {
      return false;
    } else if (!Helpers.isValidPassword(this.state.password)) {
      return false;
    } else if (!this.state.checked) {
      return false;
    }
    return true;
  };

  saveUser = async () => {
    try {
      await JoinApi.convertVirtualUser({
        virtualUserHash: this.props.params.virtualUserHash,
        fname: this.state.firstName,
        lname: this.state.lastName,
        password: this.state.password
      });

      window.webAuth.login(
        {
          username: this.state.email,
          password: this.state.password,
          realm: window.__AUTH0_REALM__,
          // eslint-disable-next-line no-underscore-dangle
          redirectUri: `${window.__LENND_APP_URL__}/auth/callback`
        },
        err => {
          if (err) {
            console.error(
              `[Virtual User] Error logging in`,
              err.error_description
            );
            this.props.showSnackbar({
              message: err.error_description
            });
          } else {
            window.location = "/home?vuConverted=2";
          }
        }
      );
    } catch (err) {
      this.props.showSnackbar({
        message: `Error: ${err.message}`
      });
    }
  };

  handleChange = event => {
    const state = this.state;
    const field = event.target.getAttribute("name");
    state[field] =
      field === "email"
        ? event.target.value.toLowerCase().trim()
        : event.target.value.trim();
    this.setState(state);
  };

  handleTermsChange = event => {
    this.setState({
      checked: !this.state.checked
    });
  };

  render() {
    return (
      <DocumentTitle title="Join your event team on Lennd">
        <div className="page-join-virtual-user" style={styles.container}>
          {/* header */}
          <div className="page-join-corner-logo">
            <img
              src="//d2dks4tzxs6xee.cloudfront.net/img/join-corner-logo.png"
              width="34"
              height="34"
            />
          </div>

          <div className="page-join-middle" style={styles.middle}>
            <div style={styles.title}>Almost there...</div>
            <div className="page-join-subtitle">
              Just make sure everything below looks right.
            </div>
          </div>

          {/* form */}
          <div className="page-join-middle page-join-form-wrapper">
            <div className="inner-wrapper">
              <div className="input-wrapper">
                <label>First Name</label>
                <input
                  className="styled"
                  ref="UserFirstName"
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  placeholder="Your first name..."
                />
              </div>

              <div className="input-wrapper">
                <label>Last Name</label>
                <input
                  className="styled"
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  placeholder="Your last name....."
                />
              </div>

              <div className="input-wrapper">
                <label>Email Address</label>
                <div style={{}}>{this.state.email}</div>
              </div>
            </div>

            {/* form - bottom */}
            <div className="inner-wrapper page-join-form-wrapper-bottom">
              <div className="input-wrapper">
                <label>Create Password</label>
                <input
                  className="styled"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  placeholder="Enter your password here"
                />
              </div>

              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "#222"
                }}
              >
                <PasswordChecklist
                  iconSize={12}
                  rules={["length", "specialChar", "number", "capital"]}
                  minLength={8}
                  value={this.state.password}
                />
              </div>

              <div
                className="checkbox-wrapper clearfix"
                style={{ display: "flex", marginTop: "20px" }}
              >
                <input
                  type="checkbox"
                  id="agree"
                  name="checked"
                  className="styled checkbox"
                  onChange={this.handleTermsChange}
                  value={this.state.checked}
                />
                <label htmlFor="agree" className="checkbox-label">
                  I agree to the{" "}
                  <a
                    className="terms-and-conditions-link"
                    href="https://www.lennd.com/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    className="terms-and-conditions-link"
                    href="https://www.lennd.com/legal/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              <div
                className="page-join-submit"
                onClick={this.validateData}
                style={
                  !this.isValid()
                    ? {
                        backgroundColor: "#ccc"
                      }
                    : {}
                }
              >
                Continue
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        showSnackbar
      },
      dispatch
    )
  };
}

export default connect(
  null,
  mapDispatchToProps
)(JoinVirtualUserHandler);
