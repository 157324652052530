import { RECEIVE_PREVIEWS, RECEIVE } from "./constants";
import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function getEmailMergeTags(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({ type: RECEIVE, payload: { mergeTags: result.payload } });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting merge tags"
          }
        ])
      );
      return null;
    }
  };
}

export function generatePreviews({
  emailType,
  moduleId,
  eventId,
  message,
  recordIds,
  recipientIds,
  options = {},
  sourceRecordId,
  sourceFormId,
  sourceOrderId
}) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, {
        emailType,
        moduleId,
        eventId,
        message,
        recordIds,
        recipientIds,
        options,
        sourceRecordId,
        sourceFormId,
        sourceOrderId
      });

      dispatch({ type: RECEIVE_PREVIEWS, payload: result.payload });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting message previews"
          }
        ])
      );
      return null;
    }
  };
}
