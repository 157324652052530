import * as R from "ramda";
import { createSelector } from "reselect";
import moment from "moment";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

import { getters } from "Submission/Editor";
import { FIELD_TYPES } from "Submission/Editor/constants";

export const submission = getters.instance;

export const isFetching = getters.fetching;
export const isSaving = getters.isSaving;
export const error = getters.error;

export const form = getters.form;
export const eventDetails = getters.event;
export const preferences = getters.preferences;
export const collaborators = getters.collaborators;
export const comments = getters.comments;
export const sortedFormFields = createSelector(
  R.compose(
    R.propOr([], "fields"),
    getters.form
  ),
  R.sort((a, b) => a.order - b.order)
);

export const getOrderId = createSelector(
  getters.related_order,
  R.propOr("", "id")
);

export const getNonDraftOrderId = R.compose(
  R.ifElse(
    R.path(["related_order", "is_draft"]),
    R.always(null),
    R.pathOr("", ["related_order", "id"])
  ),
  submission
);

export const getSubmissionId = getters.id;

export const getApprovalStatus = createSelector(
  getters.module_record,
  R.path(["record", "approval_manifest", "status"])
);

export const getApprovalManifestId = createSelector(
  getters.module_record,
  R.path(["record", "approval_manifest", "id"])
);

export const getSubmissionRecordId = createSelector(
  getters.module_record,
  R.path(["record", "id"])
);

export const getCreatedAt = createSelector(
  getters.module_record,
  R.path(["record", "created_at"])
);

export const hasRelatedOrder = R.compose(
  sub =>
    R.and(
      R.compose(
        R.not,
        R.isNil(),
        R.path(["related_order", "id"])
      )(sub),
      R.compose(
        R.not,
        R.path(["related_order", "is_draft"])
      )(sub)
    ),
  submission
);

export const getSubmissionRecordFriendlyId = createSelector(
  getters.module_record,
  R.pathOr("", ["record", "friendly_id"])
);

export const getFormName = createSelector(
  getters.form,
  R.propOr("", "name")
);

export const getFormSlug = createSelector(
  getters.form,
  R.propOr("", "slug")
);

export const getInfo = createSelector(
  R.compose(
    R.head,
    getters.collaborators
  ),
  getFormName,
  getCreatedAt,
  (_, { now }) => now,
  (collaborator, formName, created_at, now) => {
    const components = [];

    if (collaborator) {
      components.push({
        title: "Submitted By",
        infos:
          collaborator.fname && collaborator.fname.length
            ? [
                [collaborator.fname, collaborator.lname]
                  .filter(v => v && v.length)
                  .join(" ")
              ]
            : [collaborator.email]
      });
    }

    components.push(
      { title: "Source", infos: [formName || "-"] },
      { title: "Submitted", infos: [moment(new Date(created_at)).from(now)] }
    );

    return components;
  }
);

export const getSubmitter = createSelector(
  R.compose(
    R.head,
    getters.collaborators
  ),
  collaborator => {
    if (!collaborator) {
      return {};
    }

    return {
      id: R.ifElse(R.prop("account_id"), R.prop("account_id"), R.always(null))(
        collaborator
      ),
      name: R.ifElse(R.prop("account_id"), R.prop("account_name"), c =>
        [c.fname, c.lname].filter(v => v && v.length).join(" ")
      )(collaborator),
      type: R.ifElse(R.prop("account_id"), R.always("account"), R.always(null))(
        collaborator
      ),
      recordType: collaborator.record_type_name,
      recordTypeIcon: collaborator.record_type_icon,
      recordTypeBackgroundColor: collaborator.record_type_background_color,
      recordTypeTextColor: collaborator.record_type_text_color
    };
  }
);

export const getSubmissionAccount = createSelector(
  R.compose(
    R.find(R.prop("accound_id")),
    getters.collaborators
  ),
  collaborator => {
    if (!collaborator) {
      return null;
    }

    return {
      id: collaborator.account_id,
      name: collaborator.account_name
    };
  }
);

export const isLocked = createSelector(
  getters.form,
  getters.is_locked,
  isFormLocked
);

export const getStatuses = createSelector(
  getters.module_record,
  isLocked,
  (module_record, isLocked) => {
    const approvalStatus = R.compose(
      R.append(R.__, []), // eslint-disable-line no-underscore-dangle
      R.pathOr("approved", ["record", "approval_manifest", "status"])
    )(module_record);

    const lockStatus = isLocked ? "locked" : "unlocked";
    const statuses = [approvalStatus, lockStatus];
    return statuses;
  }
);

export const customerBlockAccountValues = createSelector(
  getters.instance,
  R.pathOr({}, ["related_order", "customer", "account_values"])
);

export const customerBlockContactValues = createSelector(
  getters.instance,
  R.pathOr({}, ["related_order", "customer", "contact_values"])
);

export const customerBlockValues = createSelector(
  getters.instance,
  submission => {
    const customer = R.pathOr({}, ["related_order", "customer"])(submission);
    return R.mergeAll([customer.account_values, customer.contact_values]);
  }
);

export const fulfillmentBlockFieldsAndValues = createSelector(
  state => getters.instance(state),
  (state, props) => props.field,
  (submission, field) => {
    const order = R.pathOr({}, ["related_order"])(submission);
    let fields = [];

    if (order.shipping_method === "pickup") {
      fields = [
        {
          id: STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
          type: "text",
          name: "First Name",
          settings: {},
          required: true,
          savePath: "fname",
          path: "pickup_person_fname",
          value: {
            type: "text",
            value: order.pickup_person_fname
          }
        },
        {
          id: STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
          type: "text",
          name: "Last Name",
          settings: {},
          required: true,
          savePath: "lname",
          path: "pickup_person_lname",
          value: {
            type: "text",
            value: order.pickup_person_lname
          }
        }
      ].filter(f => f);
    } else if (order.shipping_method === "mail") {
      fields = [
        {
          id: "addressLine1",
          type: "text",
          name: "Address Line 1",
          settings: {},
          required: true,
          savePath: "addressLine1",
          path: "shipping_address_line_1",
          value: {
            type: "text",
            value: order.shipping_address_line_1
          }
        },
        {
          id: "addressLine2",
          type: "text",
          name: "Address Line 2",
          settings: {},
          required: false,
          savePath: "addressLine2",
          path: "shipping_address_line_2",
          value: {
            type: "text",
            value: order.shipping_address_line_2
          }
        },
        {
          id: "city",
          type: "text",
          name: "City",
          settings: {},
          required: true,
          savePath: "city",
          path: "shipping_city",
          value: {
            type: "text",
            value: order.shipping_city
          }
        },
        {
          id: "state",
          type: "text",
          name: "State",
          settings: {},
          required: true,
          savePath: "state",
          path: "shipping_state",
          value: {
            type: "text",
            value: order.shipping_state
          }
        },
        {
          id: "zip",
          type: "text",
          name: "Postal Code",
          settings: {},
          required: true,
          savePath: "zip",
          path: "shipping_zip",
          value: {
            type: "text",
            value: order.shipping_zip
          }
        },
        {
          id: "country",
          type: "text",
          name: "Country",
          settings: {},
          required: true,
          savePath: "country",
          path: "shipping_country",
          value: {
            type: "text",
            value: order.shipping_country
          }
        }
      ].filter(f => f);
    }

    return {
      fields,
      values: R.reduce((map, f) => {
        map[f.id] = f.value;
        return map;
      }, {})(fields)
    };
  }
);

export const getOrderDetailsBlockFieldIds = createSelector(
  R.compose(
    R.propOr([], "fields"),
    getters.form
  ),
  R.compose(
    R.map(R.path(["field", "id"])),
    R.propOr([], "customer_block_fields"),
    R.find(R.propEq("type", FIELD_TYPES.details))
  )
);
