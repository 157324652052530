import React from "react";
import * as R from "ramda";
import { Div, Text0, Text3 } from "components/Base";
import Circle from "ui-kit/Circle";

const GroupCard = ({ name, type_name, count_of_people, selectAccount, id }) => (
  <Div
    ba={1}
    bc={{ default: "neutral2", hover: "primary7" }}
    bra={1}
    display="row.space-between.center"
    p={2}
    onClick={() =>
      selectAccount({ accountId: id, accountName: name, filter: "" })
    }
    mt={2}
    style={{
      minHeight: "52px"
    }}
  >
    <Div display="row.flex-start.center" height={1}>
      <Circle bg="altB5" size={28}>
        <Text3 bold color="white">
          {R.toUpper(R.propOr("", 0)(name || ""))}
        </Text3>
      </Circle>
      <Div
        height={1}
        display="column.space-between"
        ml={2}
        style={{
          maxWidth: "290px"
        }}
      >
        <Text3 bold>{name}</Text3>
        <Text0>{type_name}</Text0>
      </Div>
    </Div>
    <Div
      height={25}
      display="column.center.flex-end"
      style={{
        width: "115px"
      }}
    >
      {/* <Text0 bold>1 order</Text0> */}
      <Text0 bold>
        {count_of_people} {count_of_people === 1 ? "person" : "people"}
      </Text0>
    </Div>
  </Div>
);

export default GroupCard;
