import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { blackOrWhiteByContrast } from "utils/General";
import rgb from "hex-rgb";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { BASE_COLORS } from "./constants";

@CSSModules(css)
class ColorChart extends Component {
  @autobind
  onSelect(color) {
    this.props.onSelect(color);
  }

  colorTint(hex, lum) {
    const tint = lum || 0;
    const tintedRGB = rgb(hex).map(c =>
      Math.round(Math.min(Math.max(0, c + (255 - c) * tint), 255))
    );

    return {
      backgroundColor: `rgb(${tintedRGB.join(",")})`,
      textColor: blackOrWhiteByContrast(...tintedRGB)
    };
  }

  isSelected(selected, color) {
    return selected.backgroundColor === color.backgroundColor;
  }

  render() {
    const { selected } = this.props;
    const rows = BASE_COLORS.map((color, index) => {
      const row = [0.5, 0.25, 0].map(tint => {
        const colors =
          BASE_COLORS.length - 1 === index && tint === 0.5
            ? { backgroundColor: "#FFFFFF", textColor: "#000000" }
            : this.colorTint(color.backgroundColor, tint);
        const isSelected = this.isSelected(selected, colors);
        return (
          <div
            key={`${color}-${tint}`}
            styleName={isSelected ? "colorBoxSelected" : "colorBoxWrapper"}
            onClick={this.onSelect.bind(null, colors)}
          >
            <div
              styleName="colorBox"
              style={{
                backgroundColor: colors.backgroundColor,
                border:
                  colors.backgroundColor === "#FFFFFF"
                    ? "1px solid #cccccc"
                    : ""
              }}
            >
              {isSelected && (
                <i
                  className="material-icons"
                  styleName="icon"
                  style={{ color: colors.textColor }}
                >
                  check
                </i>
              )}
            </div>
          </div>
        );
      });
      return (
        <div styleName="row" key={`${color}-${index}`}>
          {row}
        </div>
      );
    });
    return <div styleName="container">{rows}</div>;
  }
}

ColorChart.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string
  })
};

export default ColorChart;
