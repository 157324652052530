import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import SelectView from "ui-kit/SelectView";

import {
  getSelectedView,
  getViewsToSelect
} from "EventLight/Common/Manage/selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    views: getViewsToSelect(state, props),
    selectedView: getSelectedView(state, props)
  }))
);

export default decorate(SelectView);
