import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { actions, getters } from "Items/QuestionsDetails";
import {
  getQuestionIds,
  getQuestionsToShow
} from "Items/QuestionsDetails/selectors";

import Field from "./Field";

import { Div, Text2, LoadingIcon } from "components/Base";

const decorate = connect(
  (state, props) => ({
    questions: getQuestionIds(state, props),
    questionsToShow: getQuestionsToShow(state, props),
    loading: getters.loading(state, props),
    fullQuestions: getters.fullQuestions(state, props)
  }),
  {
    showFullQuestions: actions.showFullQuestions
  }
);

const metaData = {};

const Menu = ({
  questions = [],
  questionsToShow,
  showFullQuestions,
  fullQuestions,
  loading = false
}) => {
  if (loading) {
    return (
      <Div height={200} width={300} display="row.center.center">
        <LoadingIcon color="gray5" />
      </Div>
    );
  }
  return (
    <Div width={300} bra={1}>
      {questionsToShow.map((question, index) => (
        <Field id={question} metaData={metaData} index={index} />
      ))}
      {R.length(questions) > 3 && !fullQuestions ? (
        <Div
          mt={1}
          p={2}
          pl={3}
          onClick={() => {
            showFullQuestions();
          }}
          style={{ cursor: "pointer" }}
        >
          <Text2 underline>
            + {R.length(questions) - 3} other detail
            {R.length(questions) - 3 > 1 ? "s" : ""}
          </Text2>
        </Div>
      ) : null}
    </Div>
  );
};

export default decorate(Menu);
