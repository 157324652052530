import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "../model";
import { Div, Text3 } from "components/Base";

import { noop } from "utils/General";

import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES, FORM_ACTIONS } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.PHOTO }),
  connect(
    (state, props) => ({
      file: getValue(state, props)
    }),
    bindInstance({
      executeAction: actions.executeAction,
      setIniValue: actions.setIniValue
    })
  )
);

export const AttendeePhoto = decorate(
  ({
    iniValue,
    file = {},
    fieldId,
    fieldType,
    executeAction = noop,
    setIniValue = noop
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);

    return (
      <Div display="row.flex-start.stretch">
        <Div width={200} br={1} bc="neutral1">
          <Div
            p={2}
            pl={4}
            bg={{ default: "white", hover: "neutral0" }}
            onClick={() =>
              executeAction(
                { actionId: FORM_ACTIONS.UPLOAD_PHOTO },
                {
                  meta: {
                    fieldId,
                    fieldType
                  }
                }
              )
            }
          >
            <Text3 bold>Upload {file.url !== "" ? "New Photo" : "Photo"}</Text3>
          </Div>
          <Div
            p={2}
            pl={4}
            bg={{ default: "white", hover: "neutral0" }}
            onClick={() =>
              executeAction(
                { actionId: FORM_ACTIONS.TAKE_PHOTO },
                {
                  meta: {
                    fieldId,
                    fieldType
                  }
                }
              )
            }
          >
            <Text3 bold>Take {file.url !== "" ? "New Photo" : "Photo"}</Text3>
          </Div>
          {file.url && (
            <>
              <Div
                p={2}
                pl={4}
                bg={{ default: "white", hover: "neutral0" }}
                onClick={() =>
                  executeAction(
                    { actionId: FORM_ACTIONS.EDIT_PHOTO },
                    {
                      meta: {
                        fieldId,
                        fieldType
                      }
                    }
                  )
                }
              >
                <Text3 bold>Edit/Crop Photo</Text3>
              </Div>
              <Div
                p={2}
                pl={4}
                bg={{ default: "white", hover: "neutral0" }}
                onClick={() =>
                  executeAction(
                    { actionId: FORM_ACTIONS.REMOVE_PHOTO },
                    {
                      meta: {
                        fieldId,
                        fieldType
                      }
                    }
                  )
                }
              >
                <Text3 color="danger8" bold>
                  Remove Photo
                </Text3>
              </Div>
            </>
          )}
        </Div>
        <Div
          mt={2}
          ml={2}
          bra={1}
          display="row.center.center"
          style={{ minHeight: 100, width: 174 }}
        >
          {file.url ? (
            <img src={file.url} width={170} />
          ) : (
            <Div fs={2} color="gray6">
              No photo uploaded
            </Div>
          )}
        </Div>
      </Div>
    );
  }
);
