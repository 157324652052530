import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CardActions from "../CardActions";
import CardActionLink from "../CardActionLink";
import Messages from "components/Global/CRM/Forms/Form/Submission/Messages";
import { EVENTBRITE_LOGO_SRC } from "components/Event/Settings/Credentials/constants";
import css from "./styles.scss";
import CSSModules from "react-css-modules";

@CSSModules(css)
class SyncAction extends Component {
  @autobind
  createOrder(e) {
    e.stopPropagation();
    if (this.props.syncing) return false;
    return this.props
      .createOrder({
        eventId: this.props.eventId,
        credentialOrderId: this.props.orderId
      })
      .then(() => {
        // @NOTE: Hard-coding in `eventbrite` as integration being passed here because it's safe
        // to assume it's activated if user has gotten to this point
        this.props.showSnackbar({ message: "Order synced", action: "OK" });

        this.props.setSubmissionLocked(true, this.props.submission);

        return this.props.searchOrders(
          this.props.eventId,
          { ids: [this.props.orderId] },
          "eventbrite"
        );
      });
  }

  render() {
    const { submission, syncing } = this.props;
    return (
      <CardActions>
        <CardActionLink onClick={this.createOrder}>
          <span className={css.buttonLabel}>
            <img
              src={EVENTBRITE_LOGO_SRC}
              width="15"
              height="15"
              alt=""
              className={css.logo}
            />
            {syncing ? "Syncing..." : "Sync Credentials"}
          </span>
        </CardActionLink>
        <Messages
          countOfMessages={submission.count_of_messages}
          countOfUnreadMessages={submission.count_of_unread_messages}
          onClick={this.showSubmissionSummary}
        />
      </CardActions>
    );
  }
}

SyncAction.propTypes = {
  createOrder: PropTypes.func.isRequired,
  searchOrders: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  syncing: PropTypes.bool.isRequired,
  submission: PropTypes.object.isRequired,
  setSubmissionLocked: PropTypes.func.isRequired
};

export default SyncAction;
