import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { noop } from "utils/General";

import { TYPES } from "ui-kit/FilterControls/constants";
import { getOptions } from "ui-kit/FilterControls/selectors";
import { actions } from "ui-kit/FilterControls";

import { Div, TinyToggle } from "components/Base";

const decorate = connect(
  (state, props) => ({
    toggled: getOptions(state, { ...props, type: TYPES.TOGGLE })
  }),
  bindInstance({
    toggleOption: (_, ...params) => actions.toggleOption("toggle", ...params)
  })
);

const ToggleFilter = decorate(
  ({
    children,
    style = {},
    toggled = false,
    toggleOption = noop,
    ...styleProps
  }) => (
    <Div
      display="row.flex-start.center"
      bg="neutral0"
      p={2}
      bra={1}
      style={{ flexShrink: 0, cursor: "pointer", ...style }}
      {...styleProps}
      onClick={toggleOption}
    >
      <TinyToggle mr={2} active={toggled} onClick={noop} />
      {children}
    </Div>
  )
);

export { ToggleFilter };
