import React from "react";
import countSummary from "components/Event/FormsV2/Utils/count-approvals";

const badgeWrapper = (badges, Wrapper) => <Wrapper>{badges}</Wrapper>;

export const getSubmissionState = (submission, fields) => {
  const counts = countSummary(submission, fields);

  if (!submission.is_submitted) {
    return "incomplete";
  }
  if (counts.approved + counts.rejected === 0) {
    return "submitted";
  }
  if (counts.approved + counts.rejected < counts.requested) {
    return "reviewing";
  } else if (counts.approved + counts.rejected === counts.requested) {
    if (counts.rejected && counts.approved) {
      return "reviewed";
    } else if (counts.rejected) {
      return "rejected";
    } else if (counts.approved) {
      return "approved";
    }
  }
  if (submission.is_submitted) {
    return "submitted";
  }
  return "";
};

export const getAdminBadge = (
  submission,
  fields,
  badges = {},
  options = {}
) => {
  const badgeArray = [];

  switch (getSubmissionState(submission, fields)) {
    case "submitted":
      badgeArray.push(<badges.needsReview key="needs-review" />);
      break;
    case "reviewing":
      const counts = countSummary(submission, fields);
      const percent = Math.floor(
        ((counts.approved + counts.rejected) / counts.requested) * 100
      );
      badgeArray.push(
        <badges.inReview key="in-review" percent={`${percent}%`} />
      );
      break;
    case "reviewed":
      badgeArray.push(<badges.reviewed key="reviewed" />);
      break;
    case "rejected":
      badgeArray.push(<badges.rejected key="rejected" />);
      break;
    case "approved":
      badgeArray.push(<badges.approved key="approved" />);
      break;
    default:
  }
  if (submission.is_confirmation_sent) {
    badgeArray.push(<badges.sent key="sent" />);
  }

  if (options.synced) {
    badgeArray.push(<badges.synced key="synced" />);
  }

  if (badgeArray.length && badges.wrapper) {
    return badgeWrapper(badgeArray, badges.wrapper);
  }
  return badgeArray;
};

export const getPortalBadge = (submission, fields, badges = {}) => {
  const badgeArray = [];
  switch (getSubmissionState(submission, fields)) {
    case "submitted":
      badgeArray.push(<badges.submitted key="needs-review" />);
      break;
    case "reviewing":
      badgeArray.push(<badges.inReview key="in-review" />);
      break;
    case "reviewed":
      badgeArray.push(<badges.reviewed key="reviewed" />);
      break;
    case "rejected":
      badgeArray.push(<badges.rejected key="rejected" />);
      break;
    case "approved":
      badgeArray.push(<badges.approved key="approved" />);
      break;
    default:
      badgeArray.push(<badges.notSubmitted key="notsubmitted" />);
  }
  if (badgeArray.length && badges.wrapper) {
    return badgeWrapper(badgeArray, badges.wrapper);
  }
  return badgeArray;
};

export default { getAdminBadge, getPortalBadge };
