import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "Portal/IntakeSettingsModal/model";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import Api from "./api";
import recordTypeApi from "redux/modules/modules/recordTypes/api";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import { getAccountFieldsToSave, getContactFieldsToSave } from "./selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.contacts.id
});
const accountReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.accounts.id
});

const init = function*({ payload: { permissionSetId, intakeForm = {} } }) {
  if (!R.isEmpty(intakeForm)) {
    yield put(actions.populateData({ intakeForm }));
  }
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const [
      {
        fields: { fields: accountFields }
      },
      {
        fields: { fields: contactFields }
      },
      contactTypes
    ] = yield all([
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.accounts.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.contacts.id
      }),
      call(recordTypeApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: { eventId }
      })
    ]);

    const filteredAccountFields = R.filter(
      field => !R.contains(field.id, accountReadOnlyFields),
      accountFields
    );
    const filteredContactFields = R.filter(
      field => !R.contains(field.id, contactReadOnlyFields),
      contactFields
    );

    yield put(
      actions.setInitialData({
        contactFields: filteredContactFields,
        accountsFields: filteredAccountFields,
        permissionSetId,
        shouldUpdate: R.isEmpty(intakeForm),
        contactTypes: R.map(t => ({
          id: t.id,
          value: t.id,
          label: t.name
        }))(R.propOr([], "record_types", contactTypes))
      })
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting module fields"
          }
        ])
      )
    ]);
  }
};

const fetchFields = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    const [
      {
        fields: { fields: accountFields }
      },
      {
        fields: { fields: contactFields }
      }
    ] = yield all([
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.accounts.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.contacts.id
      })
    ]);

    const filteredAccountFields = R.filter(
      field => !R.contains(field.id, accountReadOnlyFields),
      accountFields
    );
    const filteredContactFields = R.filter(
      field => !R.contains(field.id, contactReadOnlyFields),
      contactFields
    );

    yield put(
      actions.setFieldsData({
        contactFields: filteredContactFields,
        accountsFields: filteredAccountFields
      })
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting module fields"
          }
        ])
      )
    ]);
  }
};

const previousView = function*() {
  yield put(actions.clearTempDescriptions());
  const activeIndex = yield select(getters.activeIndex);
  if (activeIndex > 0) {
    yield put(actions.setActiveIndex(activeIndex - 1));
  }
};

const save = function*(fetchPermissionSets) {
  try {
    const credentials = yield select(getCredentials);

    const welcomeTitle = yield select(getters.welcomeTitle);
    const welcomeDescription = yield select(getters.welcomeDescription);
    const showWelcomeDescription = yield select(getters.showWelcomeDescription);
    const terms = yield select(getters.terms);
    const showTerms = yield select(getters.showTerms);
    const groupTitle = yield select(getters.groupTitle);
    const groupDescription = yield select(getters.groupDescription);
    const contactTitle = yield select(getters.contactTitle);
    const contactDescription = yield select(getters.contactDescription);
    const showLogin = yield select(getters.showLogin);
    const groupFields = yield select(getAccountFieldsToSave);
    const contactFields = yield select(getContactFieldsToSave);
    const permissionSetId = yield select(getters.permissionSetId);
    const portalPage = yield select(getters.dropNewUsers);
    const contactRecordTypeId = yield select(getters.contactRecordTypeId);

    const data = {
      isEnabled: true,
      welcomeTitle,
      welcomeDescription,
      showWelcomeDescription,
      terms,
      showTerms,
      groupTitle,
      groupDescription,
      contactTitle,
      contactDescription,
      enableLogin: showLogin,
      groupFields,
      contactFields,
      portalPage,
      contactRecordTypeId
    };

    yield call(Api.saveIntakeForm, { data, credentials, permissionSetId });

    yield call(fetchPermissionSets);
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred geting module fields"
        }
      ])
    );
  }
};

const nextView = function*({ payload: { hideModal, fetchPermissionSets } }) {
  yield put(actions.clearTempDescriptions());
  const activeIndex = yield select(getters.activeIndex);
  if (activeIndex === 3) {
    yield call(save, fetchPermissionSets);
    yield call(hideModal);
  } else {
    yield put(actions.setActiveIndex(activeIndex + 1));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchNextView = function*() {
  yield takeEvery(actions.nextView.type, nextView);
};

const watchPreviousView = function*() {
  yield takeEvery(actions.previousView.type, previousView);
};

const watchFetchFieldsView = function*() {
  yield takeEvery(actions.fetchFields.type, fetchFields);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([
    fork(watchNextView),
    fork(watchPreviousView),
    fork(watchInit),
    fork(watchSave),
    fork(watchFetchFieldsView)
  ]);
};

export default rootSaga;
