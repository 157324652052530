import React from "react";
import { connect } from "react-redux";
import Table from "./Table";
import { getters, actions } from "Portal/PortalForm/model";
import { getBackgroundImageUrl } from "redux/modules/portal/selectors";
import Page from "Portal/Page";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    backgroundImageUrl: getBackgroundImageUrl(state)
  }),
  {
    addSubmission: actions.addSubmission
  }
);

const Layout = ({ loading, backgroundImageUrl }) => (
  <Page
    instanceId="form"
    title={null}
    description={null}
    backgroundImageUrl={backgroundImageUrl}
  >
    {loading ? <Loading /> : <Table />}
  </Page>
);

export default decorate(Layout);
