import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { Div, Text2, QuarterSizeDotIcon } from "components/Base";

import { noop } from "utils/General";

import { actions } from "ui-kit/SelectFields";

import { getFields } from "ui-kit/SelectFields/selectors";

const decorate = connect(
  (state, props) => ({
    fields: getFields(state, props)
  }),
  bindInstance({
    selectAll: actions.enableAllFields,
    clearAll: actions.disableAllFields
  })
);

const AllNone = ({ fields = [], selectAll = noop, clearAll = noop }) => (
  <Div display="row.flex-start.center">
    <Text2
      color="primary7"
      underline
      onClick={() => {
        selectAll(fields);
      }}
    >
      Select All
    </Text2>
    <QuarterSizeDotIcon color="primary7" mx={1} />
    <Text2
      color="primary7"
      underline
      onClick={() => {
        clearAll(fields);
      }}
    >
      Remove All
    </Text2>
  </Div>
);

export default decorate(AllNone);
