import React from "react";
import { connect } from "react-redux";
import { getters } from "Event/MyTasks/model";
import { getUserText } from "Event/MyTasks/selectors";
import { Div, LeftIcon } from "components/Base";
import { WIDTH } from "Event/MyTasks/constants";
import Toolbar from "./Toolbar";
import List from "./List";
import Loading from "ui-kit/Loading";
import { withRouter } from "react-router";

const decorate = connect(state => ({
  userText: getUserText(state),
  loading: getters.loading(state)
}));

const Body = ({ userText, loading, router, params }) => (
  <Div style={{ marginTop: -100 }} display="column.flex-start.center">
    <Div width={WIDTH}>
      <Div
        display="column"
        style={{
          marginBottom: "16px"
        }}
      >
        <Div
          onClick={() => router.push(`/event/${params.eventId}/home`)}
          color="primary3"
          fs={2}
          underline
          display="row.flex-start.center"
          mb={1}
        >
          <LeftIcon color="primary3" size={15} />
          Event Home
        </Div>
        <Div
          color="white"
          fw={3}
          style={{
            fontSize: "31px"
          }}
        >
          {userText}
        </Div>
      </Div>

      <Toolbar />
      {loading ? <Loading /> : <List />}
    </Div>
  </Div>
);

export default withRouter(decorate(Body));
