import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ModalFormLabel = ({ children, ...props }) => (
  <div styleName="label" {...props}>
    {children}
  </div>
);

export default CSSModules(ModalFormLabel, css);
