import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import DeletePopoverBase from "components/Atoms/DeletePopover";

const DeletePopover = ({ ...props }) => (
  <DeletePopoverBase
    handleDelete={props.delete}
    cancel={props.cancel}
    title="Delete file?"
    message="Are you sure you want to delete this file? This cannot be undone."
  />
);

DeletePopover.propTypes = {
  delete: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default DeletePopover;
