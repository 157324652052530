import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import moment from "moment-timezone";
import CSSModules from "react-css-modules";

import { getEventsByMonth } from "../selectors";

import { actions, getters } from "../model";

import EventItem from "./EventItem.js";
import { Div, Text2, Text6, TinyFilledBox } from "components/Base";
import styleSpects from "components/Base/Base/styleSpecs";

import { useNow } from "../utils";
import { noop } from "utils/General";

import styles from "./scheduleListStyles.scss";

const dayStyle = { lineHeight: `${styleSpects.fontSize[6]}px` };

const decorate = R.compose(
  connect(
    (state, props) => ({
      eventsByMonth: getEventsByMonth(state, props),
      today: getters.today(state, props)
    }),
    bindInstance({
      eventEdit: actions.eventEdit,
      onMonthClick: actions.jump
    })
  ),
  CSSModules(styles)
);

const ScheduleList = ({
  eventsByMonth = [],
  today,
  instanceId, // eslint-disable-line no-unused-vars
  timezone,
  eventEdit = noop,
  onMonthClick = noop,
  eventDateFormat,
  ...styleProps
}) => {
  const now = useNow();
  useEffect(() => {
    const elements = document.getElementsByClassName("in-progress");
    if (elements && elements[0]) {
      elements[0].scrollIntoView();
    }
  }, [eventsByMonth]);
  return (
    <Div display="column.flex-start.stretch" {...styleProps}>
      <Text6 p={5} bb={1} bc="neutral3" bold>
        Schedule
      </Text6>
      <Div style={{ overflow: "auto" }} pt={3} flex={1}>
        {R.map(
          month => (
            <Div display="column.flex-start.stretch">
              <div styleName="month">
                <Text6
                  bold
                  onClick={() =>
                    onMonthClick(
                      moment(month.date)
                        .add(3, "d")
                        .format()
                    )
                  }
                  mx={5}
                  my={2}
                >
                  {moment(month.date).format("MMMM")}
                </Text6>
                <TinyFilledBox
                  pill
                  color="white"
                  bg="neutral7"
                  styleName="go-to-month"
                  p={1}
                >
                  go to month
                </TinyFilledBox>
              </div>
              {R.map(day => {
                const dayColor = moment(day.date).isBefore(
                  today.format("YYYY-MM-DD")
                )
                  ? "neutral5"
                  : "primary7";

                return (
                  <Div display="row.flex-start.flex-start">
                    <Div
                      display="column.flex-start.flex-start"
                      mx={5}
                      width={35}
                    >
                      <Text6 color={dayColor} bold style={dayStyle}>
                        {moment(day.date).format("DD")}
                      </Text6>
                      <Text2 color={dayColor} bold>
                        {moment(day.date).format("ddd")}
                      </Text2>
                    </Div>
                    <Div flex={1}>
                      {R.map(
                        event => (
                          <EventItem
                            timezone={timezone}
                            event={event}
                            now={now}
                            eventEdit={eventEdit}
                            dateFormat={eventDateFormat}
                          />
                        ),
                        day.events
                      )}
                    </Div>
                  </Div>
                );
              }, month.days)}
            </Div>
          ),
          eventsByMonth
        )}
      </Div>
    </Div>
  );
};

export default decorate(ScheduleList);
