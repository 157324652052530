import React from "react";

import CollapsiblePanel from "ui-kit/CollapsiblePanel";
import FiltersOnTag from "ui-kit/FiltersOnTag";

import { Div } from "components/Base";

const Heading = ({ on = false, label, labelStyles = {} }) => (
  <Div display="row.center.center" py={2} flex={1}>
    <Div flex={1} display="row.flex-start.center" pl={3} {...labelStyles}>
      {label}
    </Div>
    <FiltersOnTag on={on} />
  </Div>
);

export const Panel = ({
  children,
  label,
  style,
  filtersOn = false,
  bodyProps = {},
  labelStyles = {},
  ...styleProps
}) => {
  return (
    <CollapsiblePanel
      heading={
        <Heading on={filtersOn} label={label} labelStyles={labelStyles} />
      }
      bra={1}
      display="column.flex-start.stretch"
      style={{ overflow: "initial", flexShrink: 0, ...style }}
      {...styleProps}
    >
      <Div pb={3} {...bodyProps}>
        {children}
      </Div>
    </CollapsiblePanel>
  );
};
