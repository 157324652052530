import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const NAMESPACE = "PortalIntakeSettingsModal";

export const STEPS = [
  "Welcome Screen",
  "Group Information",
  "Contact Information",
  "Form Settings"
];

export const MODAL_FOOTER_TEXTS = [
  "Next: Group Information",
  "Next: Contact Information",
  "Next: Form Settings",
  "Save"
];

export const DROP_USERS_OPTIONS = {
  portalHome: "home",
  peopleSection: "people"
};

export const DEFAULT_FIELD_ORDER = {
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME]: -3,
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME]: -2,
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]: -1,
  [STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME]: -1
};
