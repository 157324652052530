import PropTypes from "prop-types";
import React, { Component } from "react";
import Checkbox from "material-ui/Checkbox";
import RadioButtonUnchecked from "material-ui/svg-icons/toggle/radio-button-unchecked";
import RadioButtonChecked from "material-ui/svg-icons/toggle/radio-button-checked";
import getValue from "utils/value-types/get-value/text";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const styles = {
  label: {
    flex: 1,
    color: "#424242",
    fontSize: "16px",
    fontWeight: 600,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginTop: "3px"
  },
  item: {
    padding: "5px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

@CSSModules(css)
class RadioEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  @autobind
  wrapValue(value) {
    return {
      type: "text",
      value: value || undefined
    };
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  saveValue(value) {
    this.props.onChange(this.wrapValue(value));
    this.setState({ value });
  }

  renderOptions(options) {
    const radios = options.map(option => (
      <li key={option} style={styles.item}>
        <div className={css.checkbox}>
          <Checkbox
            style={{
              flex: 1,
              display: "flex"
            }}
            checkedIcon={<RadioButtonChecked />}
            uncheckedIcon={<RadioButtonUnchecked />}
            checked={option === this.state.value}
            value={option}
            onCheck={() => this.saveValue(option)}
            label={option}
            labelStyle={styles.label}
          />
        </div>
      </li>
    ));

    return <div styleName="itemList">{radios}</div>;
  }

  render() {
    const { options } = this.props;

    return (
      <div styleName="itemSelectBox">
        {!options.length ? (
          <div styleName="empty">No available options</div>
        ) : (
          this.renderOptions(options.filter(o => o))
        )}
      </div>
    );
  }
}

RadioEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  options: PropTypes.array.isRequired
};

export default RadioEditor;
