import React from "react";
import {
  HalfSizeDotIcon,
  Div,
  Text3,
  Text1,
  NumberSelector
} from "../../index";

const Type9Card = ({
  color,
  title,
  subTitle,
  count,
  onChangeCount,
  ...props
}) => {
  return (
    <Div
      bg={"white"}
      p={2}
      bra={1}
      shadow={2}
      display={"row.flex-start.center"}
      {...props}
    >
      <HalfSizeDotIcon color={color} />

      <Div
        ml={1}
        flex={1}
        display="column"
        style={{
          width: "80%",
          wordBreak: "break-word",
          paddingRight: "8px",
          textAlign: "justify"
        }}
      >
        <Text3 bold>{title}</Text3>
        {subTitle && subTitle.length ? <Text1>{subTitle}</Text1> : null}
      </Div>
      <NumberSelector value={count} onChangeValue={onChangeCount} />
    </Div>
  );
};

export { Type9Card };
