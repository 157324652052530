import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

import fieldTypesModule from "ui-kit/FieldTypes";

const checkout = createModule(model);

checkout.plugModule(fieldTypesModule);

checkout.setRootSaga(sagas);

export default checkout;
