import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import { defaultInstanceId } from "redux-mvc";

import { navigateTo } from "utils/General";

import { actions, getters } from "Files/Manage";
import { getVisibleRows, getColumns } from "Files/Manage/selectors";
import { actions as TableActions } from "ui-kit/Table/model";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

import { getCurrentPage, getPageSize } from "Files/Manage/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import {
  TABLE_VIEW_ID,
  TABLE_INSTANCE_ID,
  DEFAULT_TREE_NODE_WIDTH
} from "Files/Manage/constants";

import Api from "../api";
import { data as mockedData } from "./mockedData";

import tableSagas from "./treeTable";
import actionsSagas from "./actions";

const notResetPagination = [
  actions.setCurrentPage.type,
  actions.fetchData.type
];

const init = function*() {
  try {
    yield put(actions.fetchData());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred loading initializing view"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const getSearchParams = (state, actionType) => {
  const resetPagination = R.not(
    R.any(R.equals(actionType), notResetPagination)
  );

  const preferences = getters.preferences(state);

  return {
    view: getters.selectedTab(state),
    search: SearchBarGetters.searchTerm(state),
    preferences: JSON.stringify(preferences),
    page: resetPagination ? 1 : getCurrentPage(state) + 1,
    pageSize: getPageSize(state)
  };
};

const loading = function*(scrollTop = true) {
  for (;;) {
    const { payload } = yield take(actions.setLoading.type);
    if (payload && scrollTop) {
      const elm = yield call(
        [document, document.getElementById],
        TABLE_VIEW_ID
      );
      if (elm) {
        elm.scrollTop = 0;
      }

      const elms = yield call(
        [document, document.getElementsByClassName],
        TABLE_VIEW_ID
      );
      Array.prototype.forEach.call(elms, elm => {
        elm.scrollTop = 0;
      });
    }
  }
};

export const search = function*({ type }) {
  yield put(actions.setShowNoResults(false));
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  const state = yield select(R.identity);
  const data = getSearchParams(state, type);

  yield put(actions.setLoading(true));

  try {
    const result = yield call(Api.search, credentials, orgId, eventId, data);

    const columns = yield select(getColumns, {
      fields: R.pathOr([], ["payload", "fields"], result)
    });
    yield put(
      actions.receiveList({
        ...result.payload,
        fields: columns,
        records: mockedData.payload.records
      })
    );
    const rows = yield select(getVisibleRows);
    yield all([
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns,
            rows,
            columnWidths: DEFAULT_TREE_NODE_WIDTH
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      ),
      put(actions.setShowNoResults(R.length(rows) === 0)),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching data"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const watchSearch = function*() {
  yield takeEvery(
    action =>
      action.type === SearchBarActions.setSearchTerm.type &&
      R.path(["meta", "instanceId"], action) === defaultInstanceId,
    search
  );
};

const watchClearSearch = function*() {
  for (;;) {
    const action = yield take(SearchBarActions.clearSearch.type);
    if (R.path(["meta", "instanceId"], action) === defaultInstanceId) {
      yield call(search, action);
    }
  }
};

const exportData = function*({ type, payload: contentType }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const eventId = yield select(getEventId);

  const data = getSearchParams(state, type);

  yield put(actions.setLoading(true));
  try {
    const result = yield call(Api.export, {
      ...data,
      orgId: orgIdToUse,
      eventId,
      credentials,
      contentType
    });
    yield all([
      call(navigateTo, result.payload.url),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting data"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const watchExport = function*() {
  yield takeEvery(actions.exportData.type, exportData);
};

const watch = function*() {
  yield takeEvery(
    [
      actions.setSelectedFieldFilters.type,
      actions.setSelectedSortBy.type,
      actions.removeSelectedSortBy.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.fetchData.type,
      TableActions.refreshRecords.type
    ],
    search
  );
};

const watchRefreshRecords = function*() {
  yield takeEvery(
    action => action.type === TableActions.refreshRecords.type,
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(tableSagas),
    fork(actionsSagas),
    fork(loading),
    fork(watchInit),
    fork(watchSearch),
    fork(watchRefreshRecords),
    fork(watchExport),
    fork(watchClearSearch),
    fork(watch)
  ]);
};

export default rootSaga;
