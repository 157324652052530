import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class DefaultWrapper extends Component {
  render() {
    return (
      <div styleName="wrapper" className="ignore-react-onclickoutside">
        {this.props.children}
      </div>
    );
  }
}

DefaultWrapper.propTypes = {
  modal: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default DefaultWrapper;
