import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import DropdownOptions from "components/Global/Table3/ModalColumn/DropdownOptions";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      editing: getters.editing(state, props),
      dropdownSettings: getters.dropdownSettings(state, props)
    }),
    {
      setDropdownSettings: actions.setDropdownSettings
    }
  ),
  CSSModules(css)
);

const DropdownSettings = ({
  dropdownSettings,
  setDropdownSettings = noop,
  editing
}) => (
  <div styleName="dropdownOptionsWrapper">
    <DropdownOptions
      settings={{
        ...dropdownSettings,
        enableMultipleSelect: !editing || dropdownSettings.enableMultipleSelect
      }}
      onChange={setDropdownSettings}
    />
  </div>
);

export default decorate(DropdownSettings);
