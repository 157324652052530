import React from "react";
import * as R from "ramda";
import {
  Div,
  SmallToggle,
  Text3,
  Text1,
  SmallFilledButton,
  Cards,
  AddIcon
} from "components/Base";

import { ApprovalsCard } from "./ApprovalsCard";

const ApproverGroup = ({
  title,
  subtitle,
  rows,
  quanta,
  editApproverGroup,
  menuItems
}) => {
  return (
    <Div mt={2}>
      <ApprovalsCard
        rows={rows}
        title={title}
        subtitle={subtitle}
        quanta={quanta}
        editSelection={editApproverGroup}
        menuItems={menuItems}
      />
    </Div>
  );
};

const ApproverGroups = Cards(ApproverGroup, "APPROVER_GROUPS");

const ApproversView = ({
  multiStepApprovalsActive,
  toggleMultiStepApprovals,
  approverGroups,
  reorderApproverGroups,
  newApproverGroup
}) => {
  return (
    <Div>
      {/* NOTE: Disabled until multi-step approvals further worked out */}
      {/* <Div py={1} display={"row.flex-start.center"}>
        <SmallToggle
          onClick={toggleMultiStepApprovals}
          active={multiStepApprovalsActive || false}
        />
        <Text3 ml={1} bold color={"neutral7"}>{`Multi-Step Approval ${
          multiStepApprovalsActive ? "ON" : "OFF"
        }`}</Text3>
      </Div> */}
      <Div mt={1}>
        <ApproverGroups
          cards={approverGroups}
          onReorder={reorderApproverGroups}
        />
      </Div>
      <SmallFilledButton
        onClick={newApproverGroup}
        mt={2}
        LeftIcon={AddIcon}
        bg={"primary7"}
      >
        Add Approver Group
      </SmallFilledButton>
    </Div>
  );
};

const NoApproversView = ({ onClick }) => (
  <Div display={"column.flex-start.center"}>
    <Text1 bold>
      {
        "Approvers you specify here will review the items triggered by the criteria above."
      }
    </Text1>
    <SmallFilledButton
      onClick={onClick}
      mt={2}
      LeftIcon={AddIcon}
      bg={"primary7"}
    >
      Add Approver Group
    </SmallFilledButton>
  </Div>
);

////////////////////////////////////////////////////////////////////////////////

const DefaultApproversView = ({
  editDefaultApprovers,
  title,
  subtitle,
  rows,
  quanta,
  menuItems
}) => {
  return (
    <Div>
      <ApproverGroup
        title={title}
        subtitle={subtitle}
        rows={rows}
        quanta={quanta}
        editApproverGroup={editDefaultApprovers}
        menuItems={menuItems}
      />
    </Div>
  );
};

const NoDefaultApproverView = ({ onClick }) => (
  <Div display={"column.flex-start.center"}>
    <SmallFilledButton
      onClick={onClick}
      mt={2}
      LeftIcon={AddIcon}
      bg={"primary7"}
    >
      Add Approvers
    </SmallFilledButton>
  </Div>
);

////////////////////////////////////////////////////////////////////////////////

export {
  ApproversView,
  NoApproversView,
  DefaultApproversView,
  NoDefaultApproverView
};
