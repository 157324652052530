import React, { Component } from "react";
import { get, find, isEqual } from "lodash";
import getValue from "utils/value-types/get-value/catering_dietary_restrictions";
import { Div } from "components/Base";

class CateringDietaryRestrictionsCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  getRestrictionName(id) {
    const restrictions = get(
      this.props.dependentValues,
      "meta.eventDetails.module_settings.catering.dietary_restrictions",
      []
    );
    const restriction = find(restrictions, { id });
    return restriction ? restriction.value : "";
  }

  formatValue(props) {
    return getValue(props.value);
  }

  render() {
    const formattedValue = this.formatValue(this.props);
    const records =
      formattedValue && formattedValue.records ? formattedValue.records : [];

    return (
      <Div
        display="row.flex-start.center"
        style={{
          userSelect: "none",
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          alignItems: "flex-start"
        }}
      >
        {records.map(record => (
          <Div
            key={record}
            display="row.flex-start.center"
            m={1}
            style={{
              flexDirection: "row",
              flexBasis: "auto",
              flexShrink: 0,
              borderRadius: 25,
              backgroundColor: "#EBEBEB",
              fontSize: "12px",
              color: "#494949",
              padding: "3px 8px",
              lineHeight: "normal",
              whiteSpace: "nowrap"
            }}
          >
            {this.getRestrictionName(record)}
          </Div>
        ))}
      </Div>
    );
  }
}

export default CateringDietaryRestrictionsCellFormatter;
