import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions, getters } from "Sponsors/SelfSetup";
import { STEPS } from "Sponsors/SelfSetup/constants";

import Loading from "ui-kit/Loading";
import { Div, Text3 } from "components/Base";
import { PageTitle, StepDescription, FAQsPanel } from "Sponsors/Common/View";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import PackagesList from "./PackagesList";
import SelectItemsModal from "./SelectItemsModal";

import { SPONSORSHIP_TYPE_ID, BOOTH_TYPE_ID } from "utils/item-types";

const faqs = [
  {
    id: "have included items",
    title: `Do packages need to have items (e.g. "Table", "Chair") added to them?`,
    text:
      "No, but we do recommend you make use of the ability to track quantities of items you are committed to fulfilling. If you do not want to add items to packages, you can skip this step and think of a package as its own item.",
    isLast: true
  }
];

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.packageItemsLoading(state)
    }),
    {
      init: actions.init
    }
  ),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND })
);

const AddItemsPage = ({
  init = noop,
  Th = defaultTh,
  loading,
  ...styleProps
}) => {
  useEffect(() => {
    init(STEPS.ADD_ITEMS);
  }, []);

  return (
    <Div
      {...Th([], {
        display: "column",
        ...styleProps
      })}
    >
      <PageTitle>Add Items to your Packages</PageTitle>
      <StepDescription mt={4}>
        <Text3 {...Th(["color"], { ml: 2 })}>
          Step 3: Add your created items (e.g. "Table", "Chair", "VIP Pass") to
          your created packages (e.g "Gold Sponsor Package", "10x10 Bundle")
        </Text3>
      </StepDescription>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PackagesList title="Exibitor Packages" type={BOOTH_TYPE_ID} />
          <PackagesList title="Sponsor Packages" type={SPONSORSHIP_TYPE_ID} />
        </>
      )}

      <FAQsPanel questions={faqs} mt={6} />
      <SelectItemsModal />
    </Div>
  );
};

export default decorate(AddItemsPage);
