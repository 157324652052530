import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import * as R from "ramda";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import { getModule } from "redux/modules/modules/module/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import {
  getRecord,
  deleteRecord,
  invalidateRecord
} from "redux/modules/modules/record/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { logAction } from "redux/modules/log/actions";
import * as modalActions from "redux/modules/modal/actions";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import {
  moduleDetails,
  fields,
  fieldGroups,
  starredFields,
  preferences
} from "redux/modules/modules/module/selectors";
import { record, relatedModules } from "redux/modules/modules/record/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  const eventDetailsToPass = eventDetails(state);
  return {
    moduleId: props.moduleId,
    recordId: props.recordId,
    eventId: eventDetailsToPass.id,

    moduleDetails: moduleDetails(state, props.moduleId),
    fields: fields(state, props.moduleId),
    fieldGroups: R.compose(
      R.filter(fg => fg.fields.length),
      R.map(fg => ({
        ...fg,
        fields: R.filter(
          f =>
            !resolveReadOnlyFields({
              moduleId: props.moduleId
            }).includes(f.field_id)
        )(fg.fields)
      }))
    )(fieldGroups(state, props.moduleId)),

    starredFields: starredFields(state, props.moduleId),
    recordTypes: recordTypes(state, props.moduleId),
    eventDetails: eventDetailsToPass,

    record: record(state, props.recordId),
    relatedModules: relatedModules(state, props.recordId),
    references: references(state),

    // @NOTE: In the future likely want to hit a unique endpoint for pulling preferences.
    preferences: props.preferences || preferences(state, props.moduleId),

    readonly: !canEventUserDo(state)(`${props.moduleId}_update`)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModule,
      getRecordTypes,
      addValue,
      getRecord,
      deleteRecord,
      invalidateRecord,
      logAction,
      ...modalActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
