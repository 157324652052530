import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import listensToClickOutside from "react-onclickoutside";

import EditFormModal from "components/Event/FormsV2/Modals/FormEditModal";
import DeleteFormModal from "components/Event/FormsV2/Modals/FormDeleteModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as FormActions from "redux/modules/formsV2/form/actions";
import * as OverviewActions from "redux/modules/formsV2/overview/actions";
import { bulkEdit } from "redux/modules/formsV2/submissions/bulk/actions";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@listensToClickOutside
@CSSModules(css)
class FormOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false
    };
  }

  handleClickOutside() {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    }
  }

  @autobind
  toggleDropdown(e) {
    e.stopPropagation();
    this.setState({
      showDropdown: !this.state.showDropdown
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   * update form
   */
  @autobind
  showEditFormModal(e) {
    e.stopPropagation();
    const modal = (
      <EditFormModal
        bulkEdit={this.toggleLockAll}
        eventId={parseInt(this.props.params.eventId, 10)}
        form={this.props.form}
        handleEditForm={this.handleEditForm}
        hideModal={this.props.hideModal}
      />
    );
    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  }

  @autobind
  async handleEditForm(data) {
    await this.props.updateForm(data);
    this.props.showSnackbar({ message: "Form updated", action: "OK" });
  }

  @autobind
  async toggleLockAll(data) {
    await this.props.bulkEdit(data);
    await this.props.getOverview(this.props.params.formId);
    this.props.showSnackbar({
      message: `Submissions ${data.isLocked ? "locked" : "unlocked"}`,
      action: "OK"
    });
  }

  /**
   * delete form
   */
  @autobind
  showDeleteFormModal(form) {
    const modal = (
      <DeleteFormModal
        eventId={parseInt(this.props.params.eventId, 10)}
        form={form}
        hideModal={this.props.hideModal}
        handleDelete={this.handleDeleteForm}
      />
    );
    this.props.showModal({ content: modal });
  }

  @autobind
  handleDeleteForm(data) {
    this.props.deleteForm(data);
    this.props.showSnackbar({ message: "Form deleted", action: "OK" });
  }

  render() {
    let dropdown;

    if (this.state.showDropdown) {
      dropdown = (
        <div styleName="dropdown">
          <span className="atom-caret-up" styleName="optionsArrow" />
          <span
            className={[
              "atom-caret-up",
              css.optionsArrow,
              css.optionsArrowBack
            ].join(" ")}
          />
          <div
            key="edit"
            className={[css.dropdownRow, css.onlyRow].join(" ")}
            onClick={this.showEditFormModal}
          >
            <div styleName="bigText">Edit Form Details</div>
          </div>
        </div>
      );
    }

    return (
      <div styleName="wrapperStyle">
        <div styleName="downArrow" onClick={this.toggleDropdown}>
          <i className="material-icons" styleName="downArrowIcon">
            keyboard_arrow_down
          </i>
        </div>
        {dropdown}
      </div>
    );
  }
}

FormOptions.propTypes = {
  deleteForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  getOverview: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...OverviewActions,
      ...FormActions,
      bulkEdit
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FormOptions);
