import Helpers from "utils/Global/Helpers";
import { VIEWS } from "Orders/List/constants";

/* eslint no-underscore-dangle: "off" */

const getViewUrl = view => {
  if (view === VIEWS.ORDERS) {
    return "";
  }

  return `/${view}`;
};

export default {
  search: (credentials, userId, { view = "", ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/list${getViewUrl(view)}`,
        qs: data,
        credentials,
        success,
        error
      });
    }),
  export: (credentials, userId, { view = "", ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/list${getViewUrl(
          view
        )}/export`,
        qs: data,
        credentials,
        success,
        error
      });
    })
};
