import PropTypes from "prop-types";
import React from "react";
import Tooltip from "components/Global/Tooltip";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const SolidButton = CSSModules(
  ({ children, color, ...props }) => (
    <div
      styleName="solid"
      style={{
        backgroundColor: color
      }}
      {...props}
    >
      {children}
    </div>
  ),
  css
);

export const OutlineButton = CSSModules(
  ({ children, ...props }) => (
    <div styleName="outline" {...props}>
      {children}
    </div>
  ),
  css
);

export const HelpButton = CSSModules(
  ({ children, url }) => (
    <Tooltip tooltip={children} placement="top">
      <div
        styleName="outlineHelp"
        onClick={() => {
          const win = window.open(url, "_blank");
          win.focus();
        }}
      >
        ?
      </div>
    </Tooltip>
  ),
  css
);

const buttonPropTypes = {
  children: PropTypes.oneOf([PropTypes.array, PropTypes.func])
};

SolidButton.defaultProps = {
  color: "#813CD2"
};

SolidButton.propTypes = {
  color: PropTypes.string.isRequired,
  ...buttonPropTypes
};

OutlineButton.propTypes = {
  ...buttonPropTypes
};
