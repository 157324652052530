import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Notifications";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { INSTANCE_ID } from "../constants";

import Notifications from "ui-kit/Notifications/View";

const decorate = connect(
  (state, props) => ({
    countOfUnseenNotifications: getters.countOfUnseenNotifications(state, props)
  }),
  {
    init: actions.init,
    openSiderbar: () =>
      actions.openSiderbar(true, { meta: { instanceId: INSTANCE_ID } })
  }
);

const NotificationHeader = ({
  init,
  countOfUnseenNotifications,
  openSiderbar,
  useSidebar = true
}) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div
        className="header-notifications"
        styleName="notificationsButtonContainer"
        onClick={() => openSiderbar()}
      >
        {countOfUnseenNotifications > 0 ? (
          <div styleName="unseenCount">{countOfUnseenNotifications}</div>
        ) : null}
        <div styleName="notificationsButton">
          <i className="material-icons" styleName="icon">
            &#xE7F4;
          </i>
        </div>
      </div>
      {useSidebar ? <Notifications instanceId={INSTANCE_ID} /> : null}
    </>
  );
};

export default decorate(CSSModules(NotificationHeader, css));
