import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FormDisabledCheck = ({
  children = null,
  disabled = false,
  connectDragSource = null
}) => {
  return (
    <div styleName={disabled ? "disabled" : undefined}>
      {React.cloneElement(children, {
        connectDragSource: connectDragSource
      })}
    </div>
  );
};

export default CSSModules(FormDisabledCheck, css);
