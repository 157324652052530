import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function updateCateringSettings(data) {
  return async (dispatch, getState) => {
    try {
      return await api.updateCateringSettings(
        getState().user.user.credentials,
        data
      );
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating catering settings"
          }
        ])
      );
    }
  };
}

export function addMeal(data) {
  return async (dispatch, getState) => {
    try {
      return await api.addMeal(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding meal"
          }
        ])
      );
    }
  };
}
