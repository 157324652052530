import PropTypes from "prop-types";
import React from "react";

const TableIcon = ({ color, ...props }) => {
  const svg = `
  <svg width="15px" height="13px" viewBox="14 9 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <rect id="path-1" x="0" y="0" width="14" height="12.5" rx="1"></rect>
          <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="14" height="12.5" fill="white">
              <use xlink:href="#path-1"></use>
          </mask>
      </defs>
      <g id="Group-63" stroke="none" stroke-width="1" fill="none" fillRule="evenodd" transform="translate(14.000000, 9.000000)">
          <g id="Group-62" stroke="${color}">
              <use id="Rectangle-9" mask="url(#mask-2)" stroke-width="3" xlink:href="#path-1"></use>
              <g id="Group-56" transform="translate(1.000000, 3.000000)" stroke-width="1.5">
                  <path d="M0,1.41666667 L13.0096118,1.41666667" id="Path-6"></path>
                  <path d="M0,5.08333333 L13.0096118,5.08333333" id="Path-6-Copy"></path>
              </g>
              <path d="M7,1 L7,12" id="Path-7" stroke-width="1.5"></path>
          </g>
      </g>
  </svg>
  `;
  return <span {...props} dangerouslySetInnerHTML={{ __html: svg }} />;
};

TableIcon.propTypes = {
  color: PropTypes.string.isRequired
};

export default TableIcon;
