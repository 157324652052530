import React from "react";
import * as R from "ramda";
import {
  BoxGroup,
  MediumInsetInput,
  MediumFilledBox,
  Text2,
  Div,
  AddIcon,
  SmallTextButton,
  Popover,
  MediumFilledButton,
  TinyToggle,
  MoneyIcon,
  MoreIcon,
  PopoverMenu
} from "components/Base";
import { withProps, toClass, withState } from "utils/General";

import { connect } from "react-redux";
import { getCurrencySignForEvent } from "redux/modules/event/selectors";

const decorate = R.compose(
  withState("priceLabel", "updateLabel", ""),
  connect(state => ({
    currencySign: getCurrencySignForEvent(state)
  }))
);

const Well = withProps({ mb: 2, bg: "neutral1", px: 4, py: 3 })(Div);

const PriceWell = withState(
  "isEditing",
  "toggleEdit",
  false
)(
  ({
    name,
    value,
    onPriceChange,
    onNameChange,
    isEnabled,
    isDefault,
    togglePrice,
    onToggleIsDefault,
    onRemove,
    toggleEdit,
    isEditing,
    currencySign
  }) => (
    <Well display="row.space-between.center">
      <Div>
        <Div display="row.flex-start.center">
          {isEditing ? (
            <Div display="row.flex-start.center">
              <MediumInsetInput
                value={name}
                type="text"
                onChange={onNameChange}
              />
              <Div
                uppercase
                onClick={() => toggleEdit(false)}
                color="primary8"
                fs={1}
                ml={1}
              >
                Done
              </Div>
            </Div>
          ) : (
            <Text2 bold color="gray7">
              {name}
            </Text2>
          )}
          {isDefault ? (
            <Div
              bg="neutral2"
              color="gray6"
              fs={0}
              fw={3}
              px={1}
              ml={2}
              bra={1}
              uppercase
            >
              Default
            </Div>
          ) : null}
        </Div>
        <BoxGroup mt={2} display="row.flex-start.flex-start">
          <MediumFilledBox bg="neutral2" color="neutral5">
            {currencySign}
          </MediumFilledBox>
          <MediumInsetInput
            disabled={!isEnabled}
            value={Number.parseFloat(value).toFixed(2)}
            type="number"
            onChange={onPriceChange}
            min={0}
          />
        </BoxGroup>
      </Div>
      <Div display="row.flex-end.center">
        <TinyToggle active={isEnabled} onClick={togglePrice} />
        <PopoverMenu
          Label={({ onClick }) => (
            <Div
              bc="gray4"
              b={1}
              bra={1}
              ml={2}
              px={1}
              onClick={onClick}
              display="row.center.center"
            >
              <MoreIcon size={16} />
            </Div>
          )}
          menuItems={[
            ["Edit name", () => toggleEdit(true)],
            [
              isDefault ? "Remove as default" : "Set as default",
              onToggleIsDefault
            ],
            ["Remove price", onRemove]
          ]}
        />
      </Div>
    </Well>
  )
);

const PopoverLabel = toClass(({ onClick, hasPrices }) => (
  <SmallTextButton LeftIcon={AddIcon} onClick={onClick} color="primary5">
    Add {hasPrices.length ? "Another" : ""} Price
  </SmallTextButton>
));

const Prices = ({
  onAddNewPrice,
  priceLabel,
  prices,
  updateLabel,
  currencySign
}) => (
  <div>
    {prices.map(p => (
      <PriceWell currencySign={currencySign} {...p} />
    ))}
    {!prices.length ? (
      <Well display="column.center.center" py={8}>
        <Div display="row.center.center" bg="neutral2" pill size={50} mb={3}>
          <MoneyIcon size={30} color="neutral4" />
        </Div>
        <Div>No prices have been added</Div>
      </Well>
    ) : null}

    <Well display="row.center.center">
      <Popover
        Label={PopoverLabel}
        labelProps={{ hasPrices: Boolean(prices.length) }}
      >
        {({ closePopover }) => (
          <Div p={3} bg="white" bra={1} display="column">
            <Text2 bold pb={2}>
              Name
            </Text2>
            <MediumInsetInput
              autoFocus
              mb={2}
              onChange={val => updateLabel(val)}
            />
            <MediumFilledButton
              bg="primary5"
              onClick={() => {
                if (!priceLabel && !priceLabel.length) return false;

                onAddNewPrice(priceLabel);
                updateLabel("");
                return closePopover();
              }}
            >
              Add
            </MediumFilledButton>
          </Div>
        )}
      </Popover>
    </Well>
  </div>
);

export default decorate(Prices);
