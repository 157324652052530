import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions as ActionsPopoverActions } from "ui-kit/ActionsPopover/model";

import { getCards, getPageTitle, getFilterTabs } from "../selectors";

import { CARD_ACTIONS } from "../constants";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import {
  Div,
  Text2,
  MediumTextButton,
  BigFilledButton,
  AddIcon
} from "components/Base";
import { Title } from "Passes/Common";
import UISearchBar from "ui-kit/SearchBar/View";
import UITabs from "ui-kit/Tabs/View";
import { VARIANTS } from "ui-kit/Theme/constants";

import { noop } from "utils/General";

import styleSpects from "components/Base/Base/styleSpecs";

const Card = ({ card, executeAction = noop }) => (
  <Div
    width={215}
    height={123}
    display="column.flex-start.space-between"
    bra={2}
    bg="white"
    shadow={1}
    pl={4}
    pr={1}
    pt={3}
    pb={1}
    mr={3}
    style={{
      border: "1px solid #eee"
    }}
  >
    <Div flex={1} style={{ position: "relative" }}>
      <Div fw={4} fs={7} color="gray7" {...card.titleStyle}>
        {card.prependTitleIcon ? (
          <i
            className="material-icons"
            style={{
              color: R.pathOr("black", ["prependTitleIcon", "color"]),
              fontSize: styleSpects.fontSize[3]
            }}
          >
            {R.path(["prependTitleIcon", "name"])}
          </i>
        ) : null}
        {card.title}
      </Div>
      <Text2 mt={2} style={{ position: "absolute", top: 40 }}>
        {card.subtitle}
      </Text2>
    </Div>
    <Div
      display="row.space-between.center"
      style={{ flexDirection: "row-reverse" }}
    >
      {R.map(
        ({ id, color, label }) => (
          <MediumTextButton
            key={id}
            color={color}
            onClick={() => executeAction({ actionId: id })}
          >
            {label}
          </MediumTextButton>
        ),
        card.actions || []
      )}
    </Div>
  </Div>
);

const decorate = connect(
  (state, props) => ({
    cards: getCards(state, props),
    title: getPageTitle(state, props),
    filterTabs: getFilterTabs(state, props),
    cando: canEventUserDo(state)
  }),
  { executeAction: ActionsPopoverActions.executeAction }
);

const Cards = ({
  title = "",
  cards = "",
  filterTabs = [],
  executeAction = noop,
  cando = R.always(true)
}) => (
  <Div pt={4} px={6} bg="white" bb={1} bc="neutral3">
    <Div pb={3}>
      <Title>{title}</Title>
    </Div>
    <Div pb={5} display="row.flex-start.stretch">
      {R.map(
        card => (
          <Card key={card.id} card={card} executeAction={executeAction} />
        ),
        cards
      )}
    </Div>
    <Div display="row.flex-start.center" mb={5}>
      <UISearchBar
        variant={VARIANTS.BACKGROUND}
        flex={1}
        mr={2}
        placeholder="Search groups"
        height={43}
        style={{
          maxWidth: 445
        }}
      />
      {cando(`${STANDARD_MODULES.accounts.id}_accounts_add`) ? (
        <BigFilledButton
          width={45}
          bg="orange5"
          color="white"
          ml={2}
          onClick={() => executeAction({ actionId: CARD_ACTIONS.ADD_GROUP })}
        >
          <AddIcon color="white" size={25} />
        </BigFilledButton>
      ) : null}
    </Div>
    <UITabs tabs={filterTabs} />
  </Div>
);

export default decorate(Cards);
