import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RecordSubscription from "./RecordSubscription";

import * as EventSelectors from "redux/modules/event/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as RecordSubscriptionSelectors from "redux/modules/modules/subscription/selectors";

import {
  getSubscription,
  updateSubscription
} from "redux/modules/modules/subscription/actions";

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    user: UserSelectors.user(state),
    subscription: RecordSubscriptionSelectors.subscription(
      state,
      props.recordId
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubscription,
      updateSubscription
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordSubscription);
