import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import OrgNav from "components/Event/Module/Navigation/Org";
import ModuleNav from "components/Event/Module/Navigation/Module";
import DocumentsNav from "components/Event/Module/Navigation/Documents";
import AccountsNav from "components/Event/Module/Navigation/Accounts";
import ContactsNav from "components/Event/Module/Navigation/Contacts";

export default function resolveNavigation(props) {
  if (
    props.params.orgId &&
    [STANDARD_MODULE_IDS.accounts.id, STANDARD_MODULE_IDS.contacts.id].includes(
      props.moduleId
    )
  ) {
    // handle: org accounts + contacts
    return <OrgNav {...props} />;
  }
  if (props.moduleId === STANDARD_MODULE_IDS.documentRequests.id) {
    // handle: doc requests
    return <DocumentsNav {...props} />;
  } else if (props.moduleId === STANDARD_MODULE_IDS.accounts.id) {
    // handle: accounts
    return <AccountsNav {...props} />;
  } else if (
    props.moduleId === STANDARD_MODULE_IDS.contacts.id &&
    props.params.groupId
  ) {
    // handle: contacts + workspace
    return <ContactsNav {...props} />;
  }
  return <ModuleNav {...props} />;
}
