import React, { Component } from "react";
import { Div } from "components/Base";
import { isEqual } from "lodash";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import EditWrapper from "components/Global/Table3/CellFormatters/EditWrapper";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Tooltip from "components/Global/Tooltip";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class ItemGroupFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  showBulkEditModal = () => {
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={
            this.props.dependentValues.meta.moduleId ===
            STANDARD_MODULE_IDS.accounts.id
              ? {
                  eventId: this.props.dependentValues.meta.eventId,
                  orderAccountId: this.props.dependentValues.meta.rowId,
                  orderType: "group"
                }
              : {
                  eventId: this.props.dependentValues.meta.eventId,
                  orderContactId: this.props.dependentValues.meta.rowId,
                  orderType: "individual"
                }
          }
          onDone={() => {
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const count =
      this.props.value && this.props.value.value
        ? this.props.value.value.count
        : 0;
    const items =
      this.props.value && this.props.value.value
        ? this.props.value.value.items
        : [];
    const joined = items.map(i => `(${i.count}) ${i.name}`.trim());
    const tooltip =
      joined.length > 5
        ? `${joined.slice(0, 5).join(", ")} and ${joined.length - 5} other ${
            joined.length - 5 !== 1
              ? this.props.dependentValues.meta.columnSettings.labelPlural
              : this.props.dependentValues.meta.columnSettings.label
          }`
        : joined.slice(0, 5).join(", ");

    return (
      <EditWrapper onClick={this.showBulkEditModal}>
        {items.length === 1 ? (
          <Div color="black" fs={2}>
            {tooltip}
          </Div>
        ) : count ? (
          <Tooltip tooltip={tooltip} placement="bottom">
            <Div color="black" fs={2}>
              {count}{" "}
              {count !== 1
                ? this.props.dependentValues.meta.columnSettings.labelPlural
                : this.props.dependentValues.meta.columnSettings.label}
            </Div>
          </Tooltip>
        ) : (
          <Div color="purple8" fw={3} fs={3}>
            + Request
          </Div>
        )}
      </EditWrapper>
    );
  }
}

export default decorate(ItemGroupFormatter);
