import { createSelector } from "reselect";
import * as R from "ramda";
import { DROPDOWN_STATUS_VALUES } from "./constants";
import { getters } from "./model";

export const getTableFields = R.always([
  {
    id: "inventory_id",
    name: "Inventory ID",
    type: "text",
    settings: {},
    label: "Field",
    is_form_field: true,
    is_report_field: false,
    is_auto_enabled: true
  },
  {
    id: "category",
    name: "Category",
    type: "text",
    settings: {},
    label: "Field",
    is_form_field: true,
    is_report_field: false,
    is_auto_enabled: true
  },
  {
    id: "variant_name",
    name: "Item",
    type: "text",
    settings: {},
    label: "Field",
    is_form_field: true,
    is_report_field: false,
    is_auto_enabled: true
  },
  {
    id: "assigned_to",
    name: "Assigned To",
    type: "text",
    settings: {},
    label: "Field",
    is_form_field: true,
    is_report_field: false,
    is_auto_enabled: true
  },
  {
    id: "order_number",
    name: "Order #",
    type: "order-numbers",
    settings: {},
    label: "Field",
    is_form_field: true,
    is_report_field: false,
    is_auto_enabled: true
  }
]);

export const getDropdownStatus = R.always([
  { id: DROPDOWN_STATUS_VALUES.ALL_INVENTORY, label: "Show all statuses" },
  { id: DROPDOWN_STATUS_VALUES.ASSIGNED, label: "Assigned" },
  { id: DROPDOWN_STATUS_VALUES.UNASSIGNED, label: "Unassigned" }
]);

export const getTotal = createSelector(
  getters.pagination,
  R.prop("total")
);
