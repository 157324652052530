import * as R from "ramda";
import {
  put,
  all,
  takeEvery,
  fork,
  call,
  select,
  take
} from "redux-saga/effects";
import Helpers from "utils/Global/Helpers";
import {
  STEPS,
  FORM_ID,
  FIELD_IDS
} from "Portal/Settings/AddPageModal/constants";

import Api from "./api";

import { actions, getters } from "Portal/Settings/AddPageModal/model";
import { actions as FormActions } from "ui-kit/Form/model";
import { registerError } from "redux/modules/errors/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getActiveIndex } from "Portal/Settings/AddPageModal/selectors";
import { getEditedField } from "./selectors";

const FIELDS_THAT_USE_JSON = [
  FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS,
  FIELD_IDS.CHECKOUT.VARIANTS
];

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { id } }) {
  let data = {};
  yield put(
    FormActions.clearValues(null, {
      meta: {
        instanceId: FORM_ID
      }
    })
  );

  try {
    if (id) {
      const { credentials } = yield call(getParams);
      const result = yield call(Api.getPage, { credentials, pageId: id });
      data = result.payload;
    }
    yield put(
      actions.setInitData({
        id,
        ...data,
        settings:
          data.settings && data.settings.length
            ? R.reduce((map, s) => {
                map[s.key] = FIELDS_THAT_USE_JSON.includes(s.key)
                  ? s.json_value
                  : s.text_value;
                return map;
              }, {})(data.settings)
            : {}
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting page"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/png"
      ]
    };

    const path = { path: "settings-page/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const uploadHeaderImg = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield put(actions.updateData({ id: "header_img", value: url }));
};

const createPage = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);
    const id = yield select(getters.id);
    const data = yield select(getters.data);
    const settings = yield select(getters.settings);
    const customKeys = R.values(FIELD_IDS.CUSTOM);

    const settingsToSave = R.compose(
      R.map(key => ({
        key,
        text_value: !FIELDS_THAT_USE_JSON.includes(key) ? settings[key] : null,
        json_value: FIELDS_THAT_USE_JSON.includes(key) ? settings[key] : null
      })),
      R.filter(key => {
        return customKeys.includes(key);
      }),
      R.keys
    )(settings);

    const toSave = {
      pageId: id,
      eventId,
      title: R.prop(FIELD_IDS.CUSTOM.PAGE_TITLE)(settings),
      isFolder: false,
      parentPageId: data.parent_page_id,
      layout: data.layout,
      backgroundImageUrl: data.background_image_url,
      content: R.prop(FIELD_IDS.CUSTOM.PAGE_CONTENT)(settings),
      sidebarVideoUrl: data.sidebar_video_url,
      enableSidebarVideo: data.enable_sidebar_video,
      featuredContent: [],
      sections: [],
      settings: settingsToSave,
      description: R.prop(FIELD_IDS.CUSTOM.PAGE_SUBTITLE)(settings)
    };

    if (id) {
      yield call(Api.updatePage, { credentials, data: toSave });
    } else {
      yield call(Api.addPage, { credentials, data: toSave });
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding page"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.resetData());
    yield put(
      snackbarActions.showSnackbar({
        message: "Page Saved"
      })
    );
    yield put(actions.closeModal());
  }
};

const nextPage = function*() {
  yield put(actions.createPage());
};

const previousPage = function*() {
  const activeIndex = yield select(getActiveIndex);

  if (activeIndex > 0) {
    yield put(actions.setActivePage(STEPS[activeIndex - 1]));
  }
};

const importPages = function*() {};

const saveAsDraft = function*() {};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    // [FIELD_IDS.MEDIA_CENTER.CONTENT]: newValue, // R.slice(0, 330, newValue),
    // [FIELD_IDS.MEDIA_CENTER.TYPE]: R.head(newValue)
    [FIELD_IDS.MEDIA_CENTER.FONT_FAMILY]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.INTERCOM_METHOD]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_LOGO_IMAGE_URL]: R.path(
      [0, "url"],
      newValue
    ),
    [FIELD_IDS.MEDIA_CENTER.EVENT_LOGO_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LOGO_URL]: R.path(
      [0, "url"],
      newValue
    ),
    [FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_TYPE]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_TYPE]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_TYPE]: R.path([0], newValue),
    [FIELD_IDS.MEDIA_CENTER.SORT_VIDEOS_BY]: R.path([0], newValue)
  });

  yield put(actions.updateSettings({ field: fieldId, value: processedValue }));
};

const getItemsToShow = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(Api.getItemTypes, {
      credentials,
      eventId
    });
    yield put(actions.setItemTypes(payload));
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the item types"
        }
      ])
    );
  }
};

const onSelectItemsToShow = function*({ payload }) {
  yield put(actions.setShowSelectItemsModal(false));
  yield put(
    actions.updateSettings({
      field: FIELD_IDS.CHECKOUT.VARIANTS,
      value: payload
    })
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUploadHeaderImg = function*() {
  yield takeEvery(actions.uploadHeaderImg.type, uploadHeaderImg);
};

const watchNextPage = function*() {
  yield takeEvery(actions.nextPage.type, nextPage);
};

const watchPreviousPage = function*() {
  yield takeEvery(actions.previousPage.type, previousPage);
};

const watchImportPages = function*() {
  yield takeEvery(actions.importPages.type, importPages);
};

const watchSaveAsDraft = function*() {
  yield takeEvery(actions.saveAsDraft.type, saveAsDraft);
};

const watchCreatePage = function*() {
  yield takeEvery(actions.createPage.type, createPage);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchGetItemsToShow = function*() {
  yield takeEvery(actions.getItemsToShow.type, getItemsToShow);
};

const watchOnSelectItemsToShow = function*() {
  yield takeEvery(actions.onSelectItemsToShow.type, onSelectItemsToShow);
};

const rootSaga = function*() {
  yield all([
    fork(watchUploadHeaderImg),
    fork(watchNextPage),
    fork(watchImportPages),
    fork(watchPreviousPage),
    fork(watchSaveAsDraft),
    fork(watchCreatePage),
    fork(watchUpdateFields),
    fork(watchGetItemsToShow),
    fork(watchOnSelectItemsToShow),
    fork(watchInit)
  ]);
};

export default rootSaga;
