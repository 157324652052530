import React, { Fragment } from "react";
import {
  BadgeWrapper,
  Popover,
  MenuCard,
  MoreIcon,
  MenuItem,
  BigFilledButton,
  BoxGroup,
  DownFilledIcon,
  BigAvatar,
  CloseIcon,
  BigTextInput,
  RightIcon,
  CheckCircleIcon,
  CheckIcon,
  ControlledDataTable,
  MediumClearButton,
  DarkTab,
  Div,
  DownIcon,
  MediumTextButton,
  MediumOutlineButton,
  PopoverMenu,
  ReplyIcon,
  SearchIcon,
  Text0,
  Text1,
  Text2,
  Text4,
  Text6,
  makeRightBadge
} from "components/Base";
import { Breadcrumbs } from "Passes/Common";
import { withProps, isEmptyOrNil } from "utils/General";
const CONTAINER_PADDING = 5;
const Header = withProps({ py: 3 })(Div);
import Tooltip from "components/Global/Tooltip";

const IssuanceMenu = ({ value }) => (
  <Div display="row">
    <Popover
      Label={({ onClick }) => (
        <BoxGroup addSpacers display="row.flex-start.center">
          <MediumClearButton
            bg="neutral5"
            color="primary5"
            onClick={value.onCheckin}
            uppercase
          >
            Pending Pick up
          </MediumClearButton>
          <MediumClearButton onClick={onClick} OnlyIcon={DownFilledIcon} />
        </BoxGroup>
      )}
    >
      {({ closePopover }) => (
        <MenuCard>
          <MenuItem
            onClick={() => {
              closePopover();
              value.onUndo();
            }}
            LeftIcon={ReplyIcon}
            RightIcon={RightIcon}
          >
            Undo Issuance
          </MenuItem>
        </MenuCard>
      )}
    </Popover>
  </Div>
);

const COLUMNS = [
  {
    Header: <Header pl={CONTAINER_PADDING}>Contact</Header>,
    accessor: "contact",
    sortable: true,
    Cell: ({ value = {}, original }) => {
      let avatar = (
        <BigAvatar
          photoURL={value.photoUrl}
          bg="neutral3"
          text={value.name ? value.name : ""}
          singleInitial
        />
      );
      if (!isEmptyOrNil(original.checkin)) {
        const DotBadge = makeRightBadge(CheckCircleIcon);
        avatar = (
          <BadgeWrapper>
            <DotBadge size={14} color={"success5"} />
            {avatar}
          </BadgeWrapper>
        );
      }
      return (
        <Div pl={CONTAINER_PADDING} display="row.flex-start.center">
          {avatar}
          <Div ml={3}>
            <Text4 bold={isEmptyOrNil(original.checkin)}>{value.name}</Text4>
            <Text0 uppercase>
              {value.type}
              {value.title && value.title.length ? ` · ${value.title}` : ""}
            </Text0>
          </Div>
        </Div>
      );
    }
  },
  {
    Header: (
      <Header pr={2} textAlign="right">
        QTY
      </Header>
    ),
    sortable: true,
    accessor: "quantity",
    width: 80,
    Cell: ({ value }) => (
      <Text2 pr={2} width={1} bold textAlign="right">
        {value}
      </Text2>
    )
  },
  {
    Header: <Header>Pass Type</Header>,
    sortable: true,
    accessor: "passType",
    Cell: ({ value, original }) => (
      <Div>
        <Text2 bold>{value}</Text2>
        <Div display="row.flex-start.center">
          {original.isIssued && (
            <Div display="row.flex-start.center">
              <CheckIcon sizeWFS={1} mr={1 / 2} />
              <Text0 color="neutral4" bold uppercase>
                Issued
              </Text0>
            </Div>
          )}
          {original.isFulfilled && (
            <Div ml={2} display="row.flex-start.center">
              <CheckIcon sizeWFS={1} mr={1 / 2} />
              <Text0 color="neutral4" bold uppercase>
                Received
              </Text0>
            </Div>
          )}
        </Div>
      </Div>
    )
  },
  {
    Header: <Header>Email</Header>,
    sortable: false,
    accessor: "email",
    Cell: ({ value, original }) => (
      <Text2 color={isEmptyOrNil(original.checkin) ? undefined : "neutral4"}>
        {value}
      </Text2>
    )
  },
  {
    Header: <Header textAlign="right">Order</Header>,
    sortable: false,
    accessor: "orderIds",
    width: 80,
    Cell: ({ value }) => (
      <Div fs={2} display="row.flex-end.center" width={1}>
        {value.map(({ id, onClick, isLast }) => (
          <Fragment>
            <Div key={id} onClick={onClick} underline>
              #{id}
            </Div>
            {!isLast ? <span>,&nbsp;</span> : null}
          </Fragment>
        ))}
      </Div>
    )
  },
  {
    Header: <Header>Check In</Header>,
    sortable: false,
    accessor: "actions",
    Cell: props => {
      if (props.original.checkin) {
        return (
          <Div display="row.flex-start.center" truncate>
            <Tooltip tooltip={props.original.checkin.title}>
              <Div
                style={{
                  maxWidth: "80%"
                }}
                truncate
                display="row.flex-start.center"
              >
                <CheckIcon color="success5" mr={2} />
                <Text2 color="neutral4" truncate>
                  {props.original.checkin.title}
                </Text2>
              </Div>
            </Tooltip>
            {props.original.recordId ? (
              <Popover
                Label={({ onClick }) => (
                  <MediumClearButton
                    ml={2}
                    OnlyIcon={MoreIcon}
                    onClick={onClick}
                  />
                )}
              >
                {({ closePopover }) => (
                  <MenuCard>
                    <MenuItem
                      LeftIcon={ReplyIcon}
                      RightIcon={RightIcon}
                      onClick={() => {
                        closePopover();
                        props.value.onUndo();
                      }}
                    >
                      Undo Fulfillment
                    </MenuItem>
                  </MenuCard>
                )}
              </Popover>
            ) : null}
          </Div>
        );
      }
      if (props.original.recordId && props.original.isIssued) {
        return <IssuanceMenu {...props} />;
      }
      if (props.original.needsPayment) {
        return (
          <Div
            fw={3}
            height={32}
            color="gray7"
            width={1}
            display="row.flex-start.center"
            uppercase
            ml={3}
          >
            Unpaid
          </Div>
        );
      }
      if (props.original.recordId) {
        return (
          <MediumClearButton
            bg="neutral5"
            color="primary5"
            onClick={props.value.onCheckin}
            uppercase
          >
            Check-in
          </MediumClearButton>
        );
      }
      return null;
    }
  }
];

const CheckinView = ({
  activeCheckinLabel,
  checkinTypes,
  countOfComplete,
  countOfPages,
  countOfRemaining,
  currentPage,
  loading,
  onPassLookup,
  onClearSearch,
  onPageChange,
  onSearch,
  onSortedChange,
  onTableChange,
  percentComplete,
  searchTerm,
  tableRows,
  tabs
}) => (
  <Div bg="neutral0">
    <Div px={CONTAINER_PADDING} pt={CONTAINER_PADDING} bb={1} bc="neutral3">
      <Breadcrumbs>Check-in</Breadcrumbs>
      <Div display="row" pb={4}>
        <Div flex={1} pr={5}>
          <Div pb={2}>
            <Div display="row.flex-start.center">
              <Text6 bold mr={2}>
                {activeCheckinLabel}
              </Text6>
              <PopoverMenu
                Label={({ onClick }) => (
                  <MediumOutlineButton onClick={onClick} OnlyIcon={DownIcon} />
                )}
                menuItems={checkinTypes}
              />
              <Div flex={1} display="row.flex-end.center">
                <MediumOutlineButton onClick={onPassLookup}>
                  Lookup Pass ID
                </MediumOutlineButton>
              </Div>
            </Div>
          </Div>
          <Div
            bg="white"
            bra={2}
            shadow={4}
            width={1}
            display="row.flex-start.stretch"
          >
            <Div flex={1} display="row" py={1}>
              <BigTextInput
                placeholder="Search by name, email, barcode, or group..."
                continuous
                value={searchTerm}
                flex={1}
                onChange={onSearch}
              />
            </Div>
            {searchTerm.length ? (
              <Div
                width={50}
                bg={{ default: "primary5", hover: "primary7" }}
                display="row.center.center"
                onClick={onClearSearch}
              >
                <CloseIcon color="white" sizeWFS={5} />
              </Div>
            ) : (
              <Div width={50} display="row.center.center">
                <SearchIcon sizeWFS={5} />
              </Div>
            )}
          </Div>
        </Div>

        <Div flex={1} display="row">
          <Div flex={1} px={5}>
            <Text6 bold>{Math.round(percentComplete * 100)}%</Text6>
            <Text1 pb={3}>Checked-In</Text1>
            <div style={{ position: "relative" }}>
              <Div width={1} bg="neutral1" height={13} bra={2} />
              <Div
                width={percentComplete}
                bg="success5"
                height={7}
                brl={2}
                brr={percentComplete === 1 ? 2 : undefined}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: 3,
                  transform: "translateY(-60%)"
                }}
              />
            </div>
          </Div>
          <Div flex={1}>
            <Text6 bold>{countOfRemaining}</Text6>
            <Text1 pb={3}>Check-ins remaining</Text1>
            <Text1 italics>{countOfComplete} passes checked in</Text1>
          </Div>
        </Div>
      </Div>

      <Div display="row.flex-start.flex-end">
        {searchTerm.length
          ? [
              <DarkTab mr={4} px={3} active activeColor="neutral8">
                Results for '{searchTerm}'
              </DarkTab>,
              <MediumTextButton color="primary5" onClick={onClearSearch}>
                CLEAR SEARCH
              </MediumTextButton>
            ]
          : tabs.map(t => (
              <DarkTab
                key={t.title}
                active={t.isActive}
                onClick={t.isActive ? undefined : t.onClick}
              >
                {t.title}
              </DarkTab>
            ))}
      </Div>
    </Div>
    <Div>
      <ControlledDataTable
        columns={COLUMNS}
        currentPage={currentPage}
        loading={loading}
        onSortedChange={onSortedChange}
        onFetchData={onTableChange}
        onPageChange={onPageChange}
        pages={countOfPages}
        rows={tableRows}
        EmptyState={
          searchTerm.length > 0 ? (
            <Div display="column.center.center">
              <SearchIcon size={80} />
              <Text6 bold py={5}>
                Nothing results for "{searchTerm}"
              </Text6>
              <Div>
                <BigFilledButton onClick={onClearSearch}>
                  Clear Search
                </BigFilledButton>
              </Div>
            </Div>
          ) : (
            <Div display="column.center.center">
              <Text6 bold>There is no one to check in yet.</Text6>
            </Div>
          )
        }
      />
    </Div>
  </Div>
);

CheckinView.propTypes = {};

export default CheckinView;
