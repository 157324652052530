import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AddNewPerson = ({ addContact, message }) => (
  <div styleName="well">
    {message || <div>Can't find who you're looking for?</div>}
    <div
      styleName="createContactButton"
      onClick={addContact}
      role="button"
      tabIndex="0"
    >
      Add new person
    </div>
  </div>
);

export default CSSModules(AddNewPerson, css);
