import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import { Div, AddIcon } from "components/Base";
import * as R from "ramda";
import { isEqual } from "lodash";
import { RelatedAccountsModal } from "components/Global/Module/Modals/AddRecord/Layouts/Contact/RelatedAccounts";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class AccountListFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  sorter = (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  showRelatedAccountsModal = () => {
    this.props.showModal({
      content: (
        <RelatedAccountsModal
          contactId={this.props.dependentValues.meta.rowId}
          onDone={() => {
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const value = R.sort(this.sorter, getValue(this.props.value));
    return (
      <Div
        display="row.flex-start.center"
        onClick={this.showRelatedAccountsModal}
      >
        <Div
          bg={{ default: "neutral1", hover: "neutral0" }}
          display="flex.center.center"
          bra={1}
          ml={1}
          mr={2}
          style={{
            width: 20,
            height: 22,
            flexShrink: 0
          }}
        >
          <AddIcon size={16} color="gray9" />
        </Div>
        {value.map(v => (
          <Div
            key={v.id}
            bra={1}
            fw={3}
            bg={{ default: "neutral1", hover: "neutral0" }}
            color="gray9"
            px={2}
            py={1}
            mr={1}
            truncate
            style={{
              flexShrink: 0
            }}
          >
            {v.name}
          </Div>
        ))}
      </Div>
    );
  }
}

export default decorate(AccountListFormatter);
