import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import People from "./People";
import { withRouter } from "react-router";
import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";
import * as RelationshipActions from "redux/modules/accounts/contactRelationship/actions";
import {
  addAccountUser,
  deleteAccountUser
} from "redux/modules/accounts/users/actions";
import { createPortalUserFromContact } from "redux/modules/user/actions";
import { sendEmail } from "redux/modules/email/actions";
import { eventDetails } from "redux/modules/event/selectors";
import { addRecord } from "redux/modules/modules/records/actions";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      ...RelationshipActions,
      ...snackbarActions,
      ...modalActions,
      createPortalUserFromContact,
      deleteAccountUser,
      addAccountUser,
      sendEmail
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(People));
