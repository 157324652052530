import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

class ModifyRoleModal extends Component {
  constructor(props) {
    super(props);
    const { name, description } = props;

    this.state = { name, description };
  }

  @autobind
  onSave() {
    const { name, description } = this.state;
    const { done, hideModal } = this.props;
    if (!name) {
      this.setState({
        error: "Please enter the permission's name"
      });
    } else {
      done(name, description);
      hideModal();
    }
  }

  onChange(field, event) {
    this.setState({
      [field]: event.target.value
    });
  }

  render() {
    const { name, error, description } = this.state;
    const { hideModal } = this.props;
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">Update Permission</div>
          <div className="modal-description">
            Control access to features per user.
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div className="input-wrapper">
              <label>Permission Name</label>
              <input
                maxLength="255"
                className="styled has-example"
                type="text"
                placeholder="ex: All-Access"
                onChange={this.onChange.bind(this, "name")}
                defaultValue={name}
                required="required"
              />
            </div>
            <div className="input-wrapper">
              <label>Permission Description</label>
              <input
                maxLength="255"
                className="styled has-example"
                type="text"
                onChange={this.onChange.bind(this, "description")}
                defaultValue={description}
                placeholder="ex: Can manage all aspects of the event."
              />
            </div>

            <div className="error">{error}</div>
            <div className="actions-wrapper">
              <a className="action-cancel small clear" onClick={hideModal}>
                Cancel
              </a>
              <a className="action-positive small" onClick={this.onSave}>
                Save Permission
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModifyRoleModal.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  done: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ModifyRoleModal;
