import { createSelector } from "reselect";
import { getters } from "Modules/SelectDashboardFieldsModal";
import * as R from "ramda";
import { TABS } from "./constants";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export const getCategoryList = createSelector(
  getters.data,
  getters.fieldsFilter,
  getters.selectedTab,
  (data, fieldsFilter, selectedTab) => {
    const dataTab = R.filter(
      ({ is_report_category }) =>
        selectedTab === TABS.REPORTED
          ? is_report_category === true
          : is_report_category === false,
      data
    );
    if (R.isEmpty(fieldsFilter)) {
      return dataTab;
    }

    return R.reduce(
      (acc, cat) => {
        const fields = R.filter(
          field => R.contains(R.toLower(fieldsFilter), R.toLower(field.name)),
          cat.fields
        );

        if (R.length(fields) < 1) {
          return acc;
        }

        return [
          ...acc,
          {
            ...cat,
            fields
          }
        ];
      },
      [],
      dataTab
    );
  }
);

export const getReportingTitle = createSelector(
  getters.data,
  getters.selectedFields,
  (data, selectedFields) => {
    const reportCategoryList = R.filter(
      ({ is_report_category }) => is_report_category === true,
      data
    );

    const categoryListLength = R.length(reportCategoryList);

    if (categoryListLength < 1) {
      return "Reporting Fields";
    }

    const totalFieldsList = R.reduce(
      (acc, category) => [...acc, ...category.fields],
      [],
      reportCategoryList
    );

    const reportedFieldsList = R.filter(
      ({ is_report_field }) => is_report_field === true,
      selectedFields
    );

    return `Reporting Fields (${R.length(reportedFieldsList)} of ${R.length(
      totalFieldsList
    )})`;
  }
);

export const getFieldsTitle = createSelector(
  getters.moduleId,
  moduleId => {
    if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
      return "People Fields";
    }
    if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
      return "Group Fields";
    }
    return "Fields";
  }
);
