import PropTypes from "prop-types";
import React, { Component } from "react";
import EditProfile from "components/Global/CRM/About/EditProfileValue";
import { get } from "lodash";
import autobind from "autobind-decorator";

class PortalProfile extends Component {
  componentWillMount() {
    this.fetchAccountProfile();
  }

  @autobind
  fetchAccountProfile() {
    const { portalUser, portal } = this.props;
    const eventId = get(portal, "event.id");
    const accountId = get(portalUser, "active_view.id");
    this.props.getAccount({ eventId, accountId });
  }

  @autobind
  updateField(data) {
    const { portalUser } = this.props;
    const recordId = get(portalUser, "active_view.id");
    this.props
      .addValue({ recordId, ...data })
      .then(() => this.fetchAccountProfile());
  }

  render() {
    const { portal, account } = this.props;
    return (
      <div>
        <EditProfile
          bio={account}
          disabled={false}
          eventDetails={portal.event}
          updateField={this.updateField}
        />
      </div>
    );
  }
}

PortalProfile.propTypes = {
  addValue: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  getAccount: PropTypes.func.isRequired,
  portal: PropTypes.shape({
    background_image_url: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    event: PropTypes.shape({
      slug: PropTypes.string
    })
  }).isRequired,
  portalUser: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.object),
    active_view: PropTypes.string
  }).isRequired
};

export default PortalProfile;
