import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { addRecord } from "redux/modules/modules/records/actions";
import { getModule } from "redux/modules/modules/module/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import * as ModuleRecordTypesSelectors from "redux/modules/modules/recordTypes/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import { activeView } from "redux/modules/modules/views/selectors";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { references } from "redux/modules/entityReferences/selectors";
import { addReference } from "redux/modules/entityReferences/actions";
import { hideModal } from "redux/modules/modal/actions";

function mapStateToProps(state, props) {
  return {
    references: references(state),
    eventDetails: EventSelectors.eventDetails(state),
    isFetching: ModuleSelectors.isFetching(state, props.moduleId),
    module: ModuleSelectors.moduleDetails(state, props.moduleId),
    fields: ModuleSelectors.fields(state, props.moduleId),
    fieldGroups: ModuleSelectors.fieldGroups(state, props.moduleId),
    starredFields: ModuleSelectors.fieldGroups(state, props.moduleId),
    recordTypes: ModuleRecordTypesSelectors.recordTypes(state, props.moduleId),
    user: UserSelectors.user(state),

    // @NOTE: In the future likely want to hit a unique endpoint for pulling view
    activeView: activeView(state, props.moduleId, props.viewId, props.groupId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addReference,
      getRecordTypes,
      addRecord,
      getModule,
      hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
