import { bindActionCreators } from "redux";
import OrderActions from "../../OrderActions";
import { connect } from "react-redux";
import {
  addField as addFormField,
  addSubformField,
  bulkUpdateSubformFields,
  deleteField as deleteFormField,
  deleteSubformField,
  startDraggingSidebarField,
  stopDraggingSidebarField,
  updateField as updateFormField,
  updateFieldOrder,
  updateSubformField
} from "redux/modules/formsV2/form/fields/actions";
import { getItemBlock } from "redux/modules/items/item-block/actions";
import {
  addItemBlock,
  deleteItemBlock,
  getItemBlocks,
  updateItemBlock
} from "redux/modules/items/item-blocks/actions";
import * as ModuleActions from "redux/modules/modules/module/actions";
import { addValue } from "redux/modules/modules/values/actions";
import * as ModuleRecordsActions from "redux/modules/modules/records/actions";
import * as ModuleRecordTypesActions from "redux/modules/modules/recordTypes/actions";
import * as ModuleFieldsActions from "redux/modules/modules/fields/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { refreshForm, updateForm } from "redux/modules/formsV2/form/actions";
import { toggleFields } from "redux/modules/formsV2/form/fields/customerBlockFields/actions";
import {
  getPeopleBlocks,
  createPeopleBlock,
  updatePeopleBlock
} from "redux/modules/formsV2/people-blocks/actions";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import * as UserSelectors from "redux/modules/user/selectors";
import * as FormSelectors from "redux/modules/formsV2/form/selectors";
import { peopleBlocks } from "redux/modules/formsV2/people-blocks/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import { itemBlocks } from "redux/modules/items/item-blocks/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { fields, fieldGroups } from "redux/modules/modules/fields/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import React, { Component } from "react";
import {
  AddBlocksIcon,
  BagIcon,
  PeopleIcon,
  AddListIcon
} from "components/Base";
import { sortBy } from "lodash";
import * as R from "ramda";
import PropTypes from "prop-types";
import { fieldTypeIcon } from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon";
import { systemFormFields } from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/constants";
import Helpers from "utils/Global/Helpers";
import reduceFieldOrder from "components/Event/FormsV2/Utils/reduce-field-order";
import BLOCK_TYPES from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/blocks";

import {
  ORDER_ITEMS_BLOCK_TYPE,
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import View from "./View";
import AddFieldModal from "Modules/AddEditColumnModal/View";
import OrderModeModal from "components/Event/FormsV2/Form/Views/V3_Edit/Modals/OrderModeModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddItemBlockModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import ModalColumnEdit from "Modules/AddEditColumnModal/View";

function mapStateToProps(state) {
  const form = FormSelectors.form(state);
  const formFields = FormSelectors.fields(state) || [];

  const allFields = ModuleSelectors.fieldGroupFields(
    state,
    form.base_module_id
  );

  const formFieldIds = R.map(R.prop("module_field_id"))(formFields);
  const formReferenceFieldIds = R.compose(
    R.map(R.path(["settings", "referenceFieldId"])),
    R.filter(R.path(["settings", "isReferenceField"]))
  )(allFields);
  return {
    canAddItemBlocks: selectFeatureFlag(flags.CAN_ADD_ITEM_BLOCKS.NAME)(state),
    canAddPeopleBlocks: selectFeatureFlag(flags.CAN_ADD_PEOPLE_BLOCKS.NAME)(
      state
    ),
    canAddSubformBlocks: selectFeatureFlag(flags.CAN_ADD_SUBFORM_BLOCKS.NAME)(
      state
    ),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
    canAddCustomFieldsToForm: selectFeatureFlag(
      flags.CAN_ADD_CUSTOM_FIELDS_TO_FORM.NAME
    )(state),
    references: references(state),
    fieldsByModuleId: moduleId =>
      fields(state, moduleId).filter(
        f =>
          !resolveReadOnlyFields({ moduleId }).includes(f.id) &&
          !formFieldIds.includes(f.id) &&
          !formReferenceFieldIds.includes(f.id)
      ),
    fieldGroupsByModuleId: moduleId =>
      fieldGroups(state, moduleId)
        .map(g => ({
          ...g,
          fields: g.fields.filter(
            f =>
              !resolveReadOnlyFields({ moduleId }).includes(f.field_id) &&
              !formFieldIds.includes(f.field_id) &&
              !formReferenceFieldIds.includes(f.field_id)
          )
        }))
        .filter(g => g.fields.length),
    canAddOrderForm: selectFeatureFlag(
      flags.CAN_VIEW_CREDENTIALS_MANAGEMENT.NAME
    )(state),
    countOfOrderItemBlocks: formFields.filter(
      f => f.type === ORDER_ITEMS_BLOCK_TYPE
    ).length,
    itemBlocks: itemBlocks(state),
    peopleBlocks: peopleBlocks(state),
    user: UserSelectors.user(state),
    module: ModuleSelectors.moduleDetails(state, form.base_module_id),
    relatedModules: ModuleSelectors.relatedModules(state, form.base_module_id),
    lookupModules: ModuleSelectors.lookupModules(state, form.base_module_id),
    form,
    formFields,
    sortedFormFields: FormSelectors.sortedFormFields(state),
    formModuleFieldIds: formFields.reduce((fields, field) => {
      if (field.type === "subform") {
        return [
          ...fields,
          field.module_field_id,
          ...field.subform.form.fields.map(f => f.module_field_id)
        ];
      }
      return [...fields, field.module_field_id];
    }, []),
    allFields,
    availableFields: allFields.filter(
      f =>
        !resolveReadOnlyFields({ moduleId: form.base_module_id }).includes(
          f.id
        ) &&
        !formFieldIds.includes(f.id) &&
        !["lookup"].includes(f.type)
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        ...ModuleActions,
        ...ModuleRecordTypesActions,
        ...ModuleRecordsActions,
        getFields: ModuleFieldsActions.getFields,
        addField: ModuleFieldsActions.addField,
        addFormField,
        addItemBlock,
        addSubformField,
        addValue,
        bulkUpdateSubformFields,
        deleteFormField,
        deleteItemBlock,
        deleteSubformField,
        getItemBlock,
        getItemBlocks,
        getPeopleBlocks,
        createPeopleBlock,
        updatePeopleBlock,
        refreshForm,
        showModal,
        hideModal,
        showSnackbar,
        startDraggingSidebarField,
        stopDraggingSidebarField,
        toggleFields,
        updateFieldOrder,
        updateForm,
        updateFormField,
        updateItemBlock,
        updateSubformField
      },
      dispatch
    )
  };
}

class FieldsAndBlocksController extends Component {
  state = { search: "", activeView: "fields", loadingRelatedFields: {} };

  componentDidMount() {
    this.props.getModule({
      moduleId: this.props.form.base_module_id,
      options: {
        eventId: this.props.form.event_id
      }
    });
    this.getItemBlocks();
    this.getPeopleBlocks();
  }

  componentDidUpdate(prevProps) {
    // if all order blocks have been removed, convert to survey form
    if (
      prevProps.countOfOrderItemBlocks > 0 &&
      this.props.countOfOrderItemBlocks === 0
    ) {
      this.updateFormType("survey");
      this.props.removeOrderFields();
    }
  }
  getPeopleBlocks = () => {
    this.props.getPeopleBlocks(this.props.form.id);
  };

  getItemBlocks = () => {
    this.props.getItemBlocks(this.props.form.event_id);
  };

  updateSearch = search => this.setState({ search });

  createField = async typeId => {
    const { field } = await this.props.addField({
      moduleId: this.props.form.base_module_id,
      field: {
        name: `New ${typeId} field`,
        type: typeId,
        settings: {} // @TODO: Get default settings
      },
      options: {
        eventId: this.props.form.event_id
      }
    });

    await this.addField({
      fieldId: field.id,
      name: field.name
    });

    return this.fetchModule(this.props.form.base_module_id);
  };

  fetchModule = moduleId => {
    return this.props.getModule({
      moduleId,
      options: {
        eventId: this.props.form.event_id
      }
    });
  };

  createFieldAtIndex = ({ fieldType, moduleId, index }) => {
    const modal = (
      <AddFieldModal
        eventId={this.props.form.event_id}
        moduleId={moduleId}
        type={fieldType}
        onSave={field => {
          this.setState({ search: "" });
          this.addField({
            fieldId: field.id,
            name: field.name,
            order: index
          });
          this.props.getModule({
            moduleId: this.props.form.base_module_id,
            options: {
              eventId: this.props.form.event_id
            }
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  addModuleField = ({ moduleId }) => {
    const modal = (
      <AddFieldModal
        eventId={this.props.form.event_id}
        moduleId={moduleId}
        onSave={() => {
          this.setState({ search: "" });
          this.props.getModule({
            moduleId: this.props.form.base_module_id,
            options: {
              eventId: this.props.form.event_id
            }
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  addRelatedField = ({ moduleId }) => {
    const modal = (
      <AddFieldModal
        eventId={this.props.form.event_id}
        moduleId={moduleId}
        onSave={() => {
          this.setState({ search: "" });
          this.props.getFields({
            moduleId,
            options: {
              eventId: this.props.form.event_id
            }
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  editModuleField = ({ moduleId, fieldId }) => {
    const modal = (
      <ModalColumnEdit
        eventId={this.props.form.event_id}
        moduleId={moduleId}
        fieldId={fieldId}
        onSave={() => this.handleUpdateField(moduleId)}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleUpdateField = moduleId => {
    this.props.showSnackbar({ message: "Field updated", action: "OK" });
    return this.fetchModule(moduleId);
    // TODO refetch fields
  };

  addField = async ({ fieldId, name, baseModuleId, order, type, settings }) => {
    const field = await this.props.addFormField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      name,
      type: type || (baseModuleId ? "subform" : "forms-v3-reference-field"),
      order:
        typeof order !== "undefined" ? order : this.props.formFields.length + 1,
      moduleFieldId: fieldId,
      baseModuleId,
      settings
    });

    this.props.updateFieldOrder({
      formId: this.props.form.id,
      commit: true,
      fields: reduceFieldOrder(this.props.sortedFormFields)
    });

    this.props.showSnackbar({ message: "Field added" });
    return field;
  };

  addSubformField = async ({ fieldId, name, subformId }) => {
    const subformField = this.props.formFields.find(f => f.id === subformId);
    const subformSection =
      subformField.subform.form.fields_grouped_by_section[0];
    const addFieldAtPosition = subformSection.fields.length + 1;

    // create subform field
    const createdField = await this.props.addSubformField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: subformField.subform.form.id,
      name,
      type: "forms-v3-reference-field",
      order: addFieldAtPosition,
      moduleFieldId: fieldId,
      subformId: subformField.id,
      sectionId: subformSection.id
    });

    // reorder fields within section added to
    const fields = sortBy(subformSection.fields, "order");
    const insertIndex = addFieldAtPosition >= 0 ? addFieldAtPosition : 0;
    fields.splice(insertIndex, 0, createdField);
    this.props.bulkUpdateSubformFields({
      formId: subformField.subform.form.id,
      subformId: subformField.id,
      sectionId: subformSection.id,
      fields: fields.map((f, order) => ({ fieldId: f.id, order }))
    });
  };

  hasOrderBlock = () =>
    Boolean(this.props.formFields.find(f => f.type === ORDER_ITEMS_BLOCK_TYPE));

  addBlock = async ({ id, itemBlockId, order }) => {
    // @NOTE: We do this check here because we need to check before field is actually added
    const hasOrderBlock = this.hasOrderBlock();

    if (id === "image") {
      return this.addImageBlock({ order });
    }
    await this.props.addFormField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      itemBlockId,
      type: id,
      order:
        typeof order !== "undefined" ? order : this.props.formFields.length + 1
    });

    // if adding first order block: show dialog, update form type, and add other order fields
    if (id === ORDER_ITEMS_BLOCK_TYPE) {
      // if no order block as been added yet, add customer and fulfillment blocks
      if (!hasOrderBlock) {
        this.showOrderModeModal();
        this.updateFormType("order");
        await this.props.addOrderFields(order);
      }

      // reload form to view all metadata
      this.reloadForm();
    }
    this.props.showSnackbar({ message: "Block added", action: "OK" });
    return true;
  };

  addImageBlock = ({ order }) => {
    Helpers.getFilepicker({}, { path: "event-form-image/" }, InkBlobs => {
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.props.addFormField({
          version: 3,
          eventId: this.props.form.event_id,
          formId: this.props.form.id,
          type: "image",
          order:
            typeof order !== "undefined"
              ? order
              : this.props.formFields.length + 1,
          settings: {
            url: InkBlobs[0].url
          }
        });
        this.props.showSnackbar({ message: "Image Block added", action: "OK" });
      }
    });
  };

  showAddItemBlockModal = () => {
    this.props.showModal({
      content: <AddItemBlockModal onDone={this.getItemBlocks} />,
      wrapper: ModalWrapper
    });
  };
  showEditItemBlockModal = blockId => {
    this.props.showModal({
      content: (
        <ItemBlockModal
          hideModal={() => {
            this.getItemBlocks();
            this.reloadForm();
            this.props.hideModal();
          }}
          itemBlockId={blockId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  search = R.filter(
    R.compose(
      (val = "") =>
        val.toLowerCase().includes((this.state.search || "").toLowerCase()),
      R.prop("name")
    )
  );

  showOrderModeModal = () => {
    this.props.showModal({
      content: <OrderModeModal hideModal={this.props.hideModal} />,
      wrapper: ModalWrapper
    });
  };

  updateFormType = type =>
    this.props.updateForm({
      formId: this.props.form.id,
      type
    });

  reloadForm = () => this.props.refreshForm(this.props.form.id);
  isFieldSelected = fieldId => this.props.formModuleFieldIds.includes(fieldId);
  setActiveView = val => {
    this.setState({ activeView: val });
  };

  handleCreatePeopleBlock = async blockId => {
    const result = await this.addField({
      type: "people"
    });
    if (!blockId) {
      const block = await this.props.createPeopleBlock({
        eventId: this.props.form.event_id,
        formId: this.props.form.id,
        name: "People Block",
        formFieldId: result.field.id,
        collectionMode: "form"
      });
      this.reloadForm();
      return this.props.onCreatePeopleBlock(block.id);
    }

    await this.props.updatePeopleBlock({
      blockId,
      formFieldId: result.field.id
    });
    this.reloadForm();
    return this.props.onEditPeopleBlock(blockId);
  };

  handleCreateSubformBlock = () => {
    this.props.onCreateSubformBlock();
  };

  getRelatedFields = async (fieldId, moduleId) => {
    this.setState(state => {
      state.loadingRelatedFields[fieldId] = true;
      return state;
    });

    await this.props.getFields({
      moduleId,
      options: {
        eventId: this.props.form.event_id
      }
    });

    this.setState(state => {
      state.loadingRelatedFields[fieldId] = false;
      return state;
    });
  };

  orderCustomerBlockExists = () =>
    this.props.sortedFormFields.some(f => f.type === ORDER_CUSTOMER_BLOCK_TYPE);

  orderFulfillmentBlockExists = () =>
    this.props.sortedFormFields.some(
      f => f.type === ORDER_FULFILLMENT_BLOCK_TYPE
    );

  orderDetailsBlockExists = () =>
    this.props.sortedFormFields.some(f => f.type === ORDER_DETAILS_BLOCK_TYPE);

  orderItemBlocksExist = () =>
    this.props.sortedFormFields.some(f => f.type === ORDER_ITEMS_BLOCK_TYPE);

  addOrderCustomerField = async () => {
    await this.props.addOrderCustomerField();
    this.reloadForm();
  };

  addOrderFulfillmentField = async () => {
    await this.props.addOrderFulfillmentField();
    this.reloadForm();
  };

  addOrderDetailsField = async () => {
    await this.props.addOrderDetailsField();
    this.reloadForm();
  };

  render() {
    const { search, activeView } = this.state;
    const {
      availableFields,
      canAddOrderForm,
      form,
      module,
      allFields,
      itemBlocks,
      peopleBlocks,
      formFields,
      startDraggingSidebarField,
      stopDraggingSidebarField,
      relatedModules,
      canAddItemBlocks,
      canAddPeopleBlocks,
      canAddSubformBlocks,
      canAddCustomFieldsToForm
    } = this.props;

    let views = [
      {
        name: "Fields & Blocks",
        active: activeView === "fields",
        onClick: () => this.setActiveView("fields"),
        Icon: AddBlocksIcon
      }
    ];

    const formatFields = R.map(field => {
      const referenceFieldName = R.path(["settings", "referencedFieldName"])(
        field
      );
      const lookupFieldId = R.path(["settings", "lookupFieldId"])(field);
      const lookupField = lookupFieldId
        ? allFields.find(f => f.id === lookupFieldId)
        : null;
      const lookupFieldName = lookupField ? lookupField.name : null;

      return {
        id: field.id,
        name: field.name,
        subname: lookupField
          ? `${lookupFieldName}: ${referenceFieldName}`
          : null,
        icon: fieldTypeIcon(field.type),
        type: field.type,
        settings: field.settings,
        onEdit: () =>
          this.editModuleField({
            fieldId: field.id,
            moduleId: form.base_module_id
          }),
        onAdd: order => this.addField({ fieldId: field.id, order }),
        onDragStart: val =>
          startDraggingSidebarField({
            ...field,
            order: formFields.length - 1,
            ...val
          }),
        onDrop: () => stopDraggingSidebarField(field),
        rawField: field
      };
    });

    const fields = R.compose(
      this.search,
      formatFields
    )(availableFields);

    const relatedFieldGroups = R.compose(
      R.map(lookupField => {
        const relatedFieldGroups = this.props
          .fieldGroupsByModuleId(lookupField.settings.moduleId)
          .map(g => {
            return {
              ...g,
              fields: this.search(formatFields(g.fields.map(f => f.field))).map(
                f => ({
                  ...f,
                  onAdd: async order => {
                    // create reference field
                    const { field } = await this.props.addField({
                      moduleId: this.props.form.base_module_id,
                      field: {
                        name: f.name,
                        type: "reference",
                        settings: {
                          lookupFieldId: lookupField.id,
                          referenceFieldId: f.id
                        }
                      },
                      options: {
                        eventId: this.props.form.event_id
                      }
                    });

                    // add field to form
                    await this.addField({ fieldId: field.id, order });
                    // refresh module
                    this.props.getModule({
                      moduleId: this.props.form.base_module_id,
                      options: {
                        eventId: this.props.form.event_id
                      }
                    });

                    // refresh form
                    return this.reloadForm();
                  },
                  onEdit: () =>
                    this.editModuleField({
                      fieldId: f.id,
                      moduleId: lookupField.settings.moduleId
                    })
                })
              )
            };
          })
          .filter(g => g.fields.length);

        return {
          ...lookupField,
          relatedFieldGroups,
          isFetchingRelatedFields: Boolean(
            this.state.loadingRelatedFields[lookupField.id]
          ),
          getRelatedFields: () =>
            this.getRelatedFields(
              lookupField.id,
              lookupField.settings.moduleId
            ),
          onAddField: () =>
            this.addRelatedField({ moduleId: lookupField.settings.moduleId })
        };
      }),
      R.filter(f => {
        if (f.type !== "lookup") {
          return false;
        }
        if (
          this.props.form.scope === "contact" &&
          f.id === STANDARD_MODULE_FIELD_IDS.FORMSV3.SUBMITTING_ACCOUNT
        ) {
          return false;
        }
        return true;
      })
    )(allFields);

    const formBlockIds = R.reduce(
      (blocks, { people_block, item_block }) => {
        if (!item_block && !people_block) return blocks;
        if (people_block) {
          return [...blocks, people_block.block_id];
        }
        return [...blocks, item_block.id];
      },
      [],
      formFields
    );

    const subFormFields = R.filter(R.propEq("type", "subform"))(formFields);

    let itemBlocksWithHandlers = [];

    // @NOTE: Only allow people blocks if form is scoped to account
    if (canAddPeopleBlocks && form.scope === "account") {
      itemBlocksWithHandlers.push({
        name: "People",
        Icon: PeopleIcon,
        onLearnMore: () => {},
        onCreateItem: () => this.handleCreatePeopleBlock(),
        items: this.search(
          peopleBlocks.map(block => ({
            name: block.name,
            subname: "",
            message: "",
            onAdd: formBlockIds.includes(block.id)
              ? undefined
              : () => this.handleCreatePeopleBlock(block.id),
            onEdit: () => this.props.onEditPeopleBlock(block.id),
            onManage: formBlockIds.includes(block.id)
              ? () => this.props.onEditPeopleBlock(block.id)
              : undefined
          }))
        )
      });
    }

    itemBlocksWithHandlers = [
      ...itemBlocksWithHandlers,

      ...(canAddSubformBlocks
        ? [
            {
              name: "Sub Form",
              Icon: AddListIcon,
              onLearnMore: () => {},
              onCreateItem: this.handleCreateSubformBlock,
              items: R.compose(
                this.search,
                R.map(subFormField => {
                  const relatedModule = relatedModules.find(rm => {
                    return rm.fields.fields.some(
                      f => f.id === subFormField.module_field_id
                    );
                  });

                  return {
                    name: relatedModule ? relatedModule.name : "",
                    onManage: () =>
                      this.props.onEditSubformBlock(
                        subFormField.id,
                        relatedModule.name
                      )
                  };
                })
              )(subFormFields)
            }
          ]
        : []),

      ...(canAddItemBlocks
        ? [
            {
              name: "Item",
              Icon: BagIcon,
              onLearnMore: () => {},
              onCreateItem: this.showAddItemBlockModal,
              items: R.compose(
                this.search,
                R.map(block => ({
                  id: block.id,
                  name: block.name,
                  onAdd: () =>
                    this.addBlock({
                      id: ORDER_ITEMS_BLOCK_TYPE,
                      itemBlockId: block.id
                    }),
                  message: (
                    <span>
                      Type: {block.type.name} <br /> # of items:{" "}
                      {block.items.length}
                      <br />
                      {block.limit ? `Limit: ${block.limit}` : ""}
                    </span>
                  ),
                  onManage: formBlockIds.includes(block.id)
                    ? () => this.showEditItemBlockModal(block.id)
                    : undefined,
                  onEdit: () => this.showEditItemBlockModal(block.id)
                })),
                R.filter(block => {
                  if (!canAddOrderForm) {
                    return ![ORDER_ITEMS_BLOCK_TYPE].includes(block.id);
                  }
                  return true;
                })
              )(itemBlocks)
            }
          ]
        : [])
    ].filter(e => e);

    const blockElementsWithHandlers = BLOCK_TYPES.map(block => ({
      id: block.id,
      name: block.name,
      Icon: block.Icon,
      onAdd: order =>
        this.addBlock({
          id: block.id,
          order
        }),
      onDragStart: val =>
        startDraggingSidebarField({
          ...block,
          order: formFields.length - 1,
          ...val
        }),
      onDrop: () => stopDraggingSidebarField(block),
      field: block
    }));

    return (
      <View
        {...{
          activeView,
          onCreatePeopleBlock: this.props.onCreatePeopleBlock,
          onCreateSubformBlock: this.props.onCreateSubformBlock,
          itemBlocks: itemBlocksWithHandlers,
          addOrderCustomerField: this.addOrderCustomerField,
          addOrderFulfillmentField: this.addOrderFulfillmentField,
          addOrderDetailsField: this.addOrderDetailsField,
          itemBlocksExist: this.orderItemBlocksExist(),
          onEditOrderCustomer: this.orderCustomerBlockExists()
            ? this.props.onEditOrderCustomerBlock
            : undefined,
          onEditOrderFulfillment: this.orderFulfillmentBlockExists()
            ? this.props.onEditOrderFulfillmentBlock
            : undefined,
          onEditOrderDetails: this.orderDetailsBlockExists()
            ? this.props.onEditOrderDetailsBlock
            : undefined,
          systemFormFields: R.compose(
            R.values,
            R.mapObjIndexed((field, key) => ({
              id: field.id,
              name: field.name,
              icon: fieldTypeIcon(key),
              onAdd: index =>
                this.createFieldAtIndex({
                  moduleId: form.base_module_id,
                  fieldType: field.id,
                  index
                }),
              onDragStart: val =>
                startDraggingSidebarField({
                  ...field,
                  order: formFields.length - 1,
                  ...val
                }),
              onDrop: () => stopDraggingSidebarField(field),
              field
            }))
          )(systemFormFields),
          blocks: blockElementsWithHandlers,
          fields,
          relatedFieldGroups,
          onAddField: () => {
            this.addModuleField({
              moduleId: module.id
            });
          },
          views,
          searchTerm: search,
          onSearch: this.updateSearch,
          showAddItemBlockModal: this.showAddItemBlockModal,
          canAddCustomFieldsToForm: canAddCustomFieldsToForm
        }}
      />
    );
  }
}

FieldsAndBlocksController.propTypes = {
  addField: PropTypes.func.isRequired,
  addFormField: PropTypes.func.isRequired,
  addSubformField: PropTypes.func.isRequired,
  bulkUpdateSubformFields: PropTypes.func.isRequired,
  deleteSubformField: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formFields: PropTypes.array.isRequired,
  formModuleFieldIds: PropTypes.array.isRequired,
  getItemBlocks: PropTypes.func.isRequired,
  getModule: PropTypes.func.isRequired,
  lookupModules: PropTypes.array.isRequired,
  module: PropTypes.object.isRequired,
  relatedModules: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  sortedFormFields: PropTypes.array.isRequired,
  startDraggingSidebarField: PropTypes.func.isRequired,
  stopDraggingAndAddSidebarField: PropTypes.func.isRequired,
  stopDraggingSidebarField: PropTypes.func.isRequired,
  updateFieldOrder: PropTypes.func.isRequired,
  updateSubformField: PropTypes.func.isRequired
};

export default OrderActions(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FieldsAndBlocksController)
);
