import React from "react";
import {
  Div,
  Text2,
  Text3,
  Text5,
  SimpleTable,
  BigFilledButton,
  DownloadIcon,
  CancelIcon,
  CheckCircleIcon
} from "components/Base";

const SummaryTable = SimpleTable({
  columnProps: [
    [6 / 12, "left", { px: 2 }],
    [2 / 12, "right", { px: 2 }],
    [2 / 12, "center", { px: 2 }],
    [2 / 12, "center", { px: 2 }]
  ],
  headerCellComps: [
    "Item",
    "Requested",
    () => <CancelIcon title="Rejected" sizeWFS={2} color="neutral5" />,
    () => <CheckCircleIcon title="Approved" sizeWFS={2} color="altA5" />
  ],
  rowCellComps: ["item", "requested", "rejected", "approved"]
});

const makeRequestsTable = ({ fields }) =>
  SimpleTable({
    columnProps: fields.map(_ => [1 / fields.length, "left", { px: 2 }]),
    headerCellComps: fields.map(f => f.name),
    rowCellComps: fields.map(f => props => <Text2>{props[f.id]}</Text2>)
  });

const RequestsTable = ({ fields = [], values = [] }) => {
  const Table = makeRequestsTable({ fields });
  return (
    <Div pl={4}>
      <Table
        rowsData={values} // [{ [f.id]: '':String }]
      />
    </Div>
  );
};

const Summary = ({ title, values = [] }) => (
  <Div bra={1} maxWidth={500} bc="neutral2" ba={1} bl={3} p={3} pb={1} mb={5}>
    <Text3 bold pl={2}>
      {title}
    </Text3>
    <SummaryTable rowsData={values} />
  </Div>
);

const Report = ({ onClickToPrint, modules }) => (
  <Div bra={2} p={4} bg="white" mb={4}>
    <Div display="row.space-between.center" pb={3}>
      <Text5 bold>Assets</Text5>
      <BigFilledButton
        className="no-print"
        onClick={onClickToPrint}
        pill
        LeftIcon={DownloadIcon}
      >
        Print
      </BigFilledButton>
    </Div>
    {modules.map(({ title, summaryValues, requestValues }) => (
      <Div key={title} pb={6}>
        <Summary title={title} values={summaryValues} />
        <RequestsTable
          fields={requestValues.fields}
          values={requestValues.values}
        />
      </Div>
    ))}
  </Div>
);

const PortalReportView = ({ onClickToPrint, modules, showTitle = true }) => (
  <Div brr={2} brb={2} p={4} bg="white" mb={4}>
    <Div display="row.space-between.center" pb={3}>
      {showTitle ? <Text5 bold>Assets</Text5> : null}
      <BigFilledButton
        className="no-print"
        onClick={onClickToPrint}
        pill
        LeftIcon={DownloadIcon}
      >
        Print
      </BigFilledButton>
    </Div>
    {modules.map(({ title, summaryValues, requestValues }) => (
      <Div key={title} pb={6}>
        <Summary title={title} values={summaryValues} />
        <RequestsTable
          fields={requestValues.fields}
          values={requestValues.values}
        />
      </Div>
    ))}
  </Div>
);

export default PortalReportView;
