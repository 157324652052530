import { createModule } from "redux-mvc";

import model from "Event/TasksWidget/model";
import sagas from "Event/TasksWidget/sagas";

import activityDetailsSidebar from "Schedules/ActivityDetailsSidebar";

const module = createModule(model);

module.plugModule(activityDetailsSidebar);

module.setRootSaga(sagas);

export default module;
