import React from "react";
import { connect } from "react-redux";
import { Div, MediumOutlineButton, MediumOutlineInput } from "components/Base";
import { getters, actions } from "../../model";

const decorate = connect(
  (state, props) => ({
    dateLabel: getters.dateLabel(state, props),
    registrationUrl: getters.registrationUrl(state, props)
  }),
  {
    openRegistrationPage: actions.openRegistrationPage,
    copyValue: actions.copyValue
  }
);

const StatusBar = ({
  dateLabel,
  registrationUrl,
  openRegistrationPage,
  copyValue
}) => (
  <Div
    display="row.space-between.center"
    height={1}
    width={1}
    p={3}
    ba={1}
    bra={1}
    bc="neutral2"
  >
    {dateLabel ? (
      <Div fs={1} color="black">
        Begins {dateLabel}
      </Div>
    ) : (
      <Div />
    )}
    <Div display="row.flex-end.center">
      <MediumOutlineButton onClick={openRegistrationPage} mr={1} bg="#f8f9fa">
        View Registration Page
      </MediumOutlineButton>
      <Div>
        <MediumOutlineInput value={registrationUrl} disabled />
      </Div>
      <Div>
        <Div
          onClick={() => copyValue(registrationUrl)}
          bg="#f8f9fa"
          bt={1}
          bb={1}
          br={1}
          bc="rgb(231, 230, 234)"
          p={1}
          brr={1}
          pl={2}
          fw={3}
        >
          Copy
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(StatusBar);
