import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getClosingThisWeek, getAllForms } from "Forms/List/selectors";
import { Div, Text4 } from "components/Base";
import { WIDTH } from "Forms/List/constants";
import ClosingThisWeek from "./ClosingThisWeek";
import AllForms from "./AllForms";

const decorate = connect(state => ({
  closingList: getClosingThisWeek(state),
  allFormsList: getAllForms(state)
}));

const Body = ({ closingList, allFormsList }) => (
  <Div width={1} display="row.center.flex-start" mb={5}>
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      {R.isEmpty(closingList) && R.isEmpty(allFormsList) ? (
        <Text4 mt={3}>No forms matched your search</Text4>
      ) : null}
      {!R.isEmpty(closingList) ? <ClosingThisWeek /> : null}
      {!R.isEmpty(allFormsList) ? (
        <AllForms showTitle={!R.isEmpty(closingList)} />
      ) : null}
    </Div>
  </Div>
);

export default decorate(Body);
