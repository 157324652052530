import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { actions, getters } from "Items/QuestionsDetails";
import { getQuestionIds } from "Items/QuestionsDetails/selectors";

import Field from "Items/QuestionsDetails/View/Field";

import { Div } from "components/Base";

const decorate = connect(
  (state, props) => ({
    questions: getQuestionIds(state, props),
    showModal: getters.showModal(state, props)
  }),
  {
    hideModal: () => actions.hideModal()
  }
);

const metaData = {};

const QuestionsModal = ({
  questions = [],
  showModal = false,
  hideModal = noop
}) => (
  <MiniModalWrapper
    showModal={showModal}
    hideModal={hideModal}
    title="Edit Details"
  >
    <Div
      p={4}
      bg="white"
      brb={2}
      width={1}
      height={1}
      style={{
        overflowY: "auto"
      }}
    >
      {R.map(
        (question, index) => (
          <Field id={question} metaData={metaData} index={index} />
        ),
        questions
      )}
    </Div>
  </MiniModalWrapper>
);

export default decorate(QuestionsModal);
