export const PAGES = {
  NEW_EVENT: "newEvent",
  CREATE_NEW_EVENT: "createNewEvent",
  SELECT_EVENT: "selectEvent",
  CLONE_OPTIONS: "cloneOptions",
  SELECT_CONTENT: "selectContent",
  CONFIRMATION: "confirmation",
  CONTACT_SUPPORT: "contactSupport"
};

export const FIELD_IDS = {
  DATE_FROM: "dateFrom",
  DATE_TO: "dateTo",
  SELECTED_DATES: "selectedDates",
  EVENT_NAME: "eventName",
  IS_SANDBOX: "isSandbox",
  TIMEZONE: "timezone",
  CURRENCY: "currency"
};

export const NEW_EVENT_DATE_FORMAT = "ddd, MMM D, Y @ h:mma";

export const DEFAULT_CURRENCY_ID = "USD";

export const DEFAULT_TIMEZONE_ID = "America/Los_Angeles";

export const DEFAULT_DAY_GROUPS = [
  {
    id: "uuid-load-in-days",
    name: "Load In Days",
    color: "#E19517",
    days: []
  },
  {
    id: "uuid-show-days",
    name: "Show Days",
    color: "#01C07C",
    days: []
  },
  {
    id: "uuid-load-out-days",
    name: "Load Out Days",
    color: "#D0021B",
    days: []
  },
  {
    id: 0,
    name: "Day Off",
    color: "#ffffff",
    days: []
  }
];

export const NEW_EVENT_MODAL_HEIGHT = 475;

export const CONTACT_SUPPORT_MODAL_HEIGHT = 390;

export const DEFAULT_MODAL_HEIGHT = 569;

export const CREATE_NEW_EVENT_HEIGHT = 707;

export const DEFAULT_AVAILABLE_CURRENCIES = [
  {
    id: "USD",
    label: "USD"
  },
  {
    id: "CAD",
    label: "CAD"
  },
  {
    id: "AUD",
    label: "AUD"
  }
];

export const NAMESPACE = "CloneEventModal";
