import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { MenuIcon, Div } from "components/Base";

import { Row, Box } from "ui-kit/Form/View";

import { getRowManifest } from "Sponsors/PackageTable/selectors";

import columnsDef from "./ColumnDef";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect((state, props) => ({
    rowManifest: getRowManifest(state, props)
  }))
);

const RowGroup = ({
  item = [],
  rowManifest = [],
  handlers = {},
  drag = false,
  autoEnabled = false,
  Th = defaultTh,
  ...styleProps
}) => (
  <Box
    display="column.flex-start.stretch"
    {...Th(["bg"], {
      variant: Th.VARIANTS.SURFACE,
      p: 2,
      bra: 1,
      ...styleProps
    })}
    data-cy="rowGroup"
  >
    {rowManifest.map((row, index) => (
      <Div key={index} display="row.flex-start.center" mt={index > 0 ? 2 : 0}>
        {drag || autoEnabled ? (
          <Row style={{ cursor: "pointer" }}>
            <MenuIcon
              {...Th(["color"], {
                color: index !== 0 || autoEnabled ? "transparent" : null
              })}
            />
          </Row>
        ) : null}
        {R.map(
          column =>
            React.createElement(R.pathOr(Div, [column, "cell"], columnsDef), {
              key: column,
              item,
              Th,
              handlers
            }),
          row
        )}
      </Div>
    ))}
  </Box>
);

export default decorate(RowGroup);
