import React, { Component } from "react";
import autobind from "autobind-decorator";
import { DateTimePicker } from "react-widgets";
import getValue from "utils/value-types/get-value/time";
import moment from "moment";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

// TODO: Global localizer kind of sucks.
import momentLocalizer from "react-widgets-moment";

require("react-widgets/dist/css/react-widgets.css");

const DISPLAY_FORMAT = "h:mm a";

@CSSModules(css)
class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  componentWillMount() {
    moment.locale(Helpers.getLocale());
    momentLocalizer(moment);
  }

  formatValue(value) {
    const unwrappedValue = getValue(value);
    if (!unwrappedValue) return null;
    const time = moment(unwrappedValue, "HH:mm a");
    if (!time.isValid()) return null;
    return time.toDate();
  }

  wrapValue(value) {
    return {
      type: "time",
      value: value ? moment(value).format(DISPLAY_FORMAT) : undefined
    };
  }

  @autobind
  onChange(value) {
    this.props.onChange(this.wrapValue(value));
  }

  render() {
    const { disabled, placeholder, required } = this.props;
    return (
      <div className={css.no_focus}>
        <DateTimePicker
          containerClassName={css.custom_styles}
          disabled={disabled}
          required={required}
          culture={Helpers.getLocale()}
          value={this.state.value}
          placeholder={placeholder || moment().format(DISPLAY_FORMAT)}
          onChange={this.onChange}
          format={DISPLAY_FORMAT}
          parse={[DISPLAY_FORMAT]}
          date={false}
          time={true}
          step={5}
        />
      </div>
    );
  }
}

export default Time;
