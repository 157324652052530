import * as R from "ramda";
import {
  all,
  call,
  debounce,
  fork,
  put,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { replace } from "react-router-redux";

import { accounts } from "@lennd/value-types/src/constants/standard-modules";

import { actions } from "../model";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as FilterControlActions } from "ui-kit/FilterControls";
import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions as TabsActions } from "ui-kit/Tabs";
import { registerError } from "redux/modules/errors/actions";

import {
  getColumns,
  getGroupItems,
  getSelectedGroupTypeId
} from "../selectors";

import { eventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getPathname } from "App/Router/selectors";

import actionsSagas from "./actions";

import { apiCall } from "App/Data/sagas";

import { GROUP_FILTER_ID } from "../constants";

export const getRecordTypes = function*() {
  const eventId = R.prop("id", yield select(eventDetails));
  const userId = yield select(getUserId);
  let recordTypes;

  try {
    const apiResponse = yield call(apiCall, {
      method: "get",
      url: `/modules/${R.prop("id", accounts)}/record-types?eventId=${eventId}`,
      qs: { userId }
    });
    recordTypes = R.prop("record_types", apiResponse);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching the group types"
        }
      ])
    );
  }
  return recordTypes;
};

const loadRecordTypes = function*() {
  yield put(actions.setRecordTypes(yield call(getRecordTypes)));
};

export const getGuestList = function*(providedGroupTypeId = undefined) {
  const userId = yield select(getUserId);
  let selectedGroupTypeId;
  if (providedGroupTypeId) {
    selectedGroupTypeId = providedGroupTypeId;
  } else {
    selectedGroupTypeId = yield select(getSelectedGroupTypeId);
  }
  let guestList;

  try {
    if (selectedGroupTypeId) {
      yield put(actions.setLoading(true));
      guestList = yield call(apiCall, {
        method: "get",
        url: `/modules/${R.prop(
          "id",
          accounts
        )}/record-types/${selectedGroupTypeId}/items`,
        qs: { userId }
      });
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching the Guest List"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
  return guestList;
};

const loadGuestList = function*() {
  const guestListData = yield call(getGuestList);
  const groups = R.prop("groups", guestListData);

  yield put(
    actions.setList(
      R.map(group => ({ ...group, id: R.prop("groupId", group) }), groups)
    )
  );

  const groupItems = yield select(getGroupItems);
  const columns = yield select(getColumns);

  yield put(
    TableActions.setData({
      columns,
      rows: R.length(groups) ? groupItems : []
    })
  );
};

const watchFetchGuestLists = function*() {
  yield takeEvery(
    [
      action =>
        R.prop("type", action) ===
          R.path(["toggleOption", "type"], FilterControlActions) &&
        R.path(["meta", "instanceId"], action) === GROUP_FILTER_ID,
      R.path(["fetchGuestLists", "type"], actions)
    ],
    loadGuestList
  );
};

const updateItems = function*() {
  const groupItems = yield select(getGroupItems);
  const columns = yield select(getColumns);
  if (R.length(groupItems)) {
    yield put(
      TableActions.setData({
        columns,
        rows: groupItems
      })
    );
  }
};

const watchUpdateTable = function*() {
  yield takeEvery(
    [
      R.path(["setPage", "type"], actions),
      R.path(["setPageSize", "type"], actions),
      R.path(["setSelectedTab", "type"], TabsActions),
      R.path(["clearSearch", "type"], SearchBarActions)
    ],
    updateItems
  );
};

const watchSearch = function*() {
  yield debounce(
    500,
    R.path(["setSearchTerm", "type"], SearchBarActions),
    updateItems
  );
};

const syncRoute = function*() {
  const { payload: recordTypes } = yield take(
    R.path(["setRecordTypes", "type"], actions)
  );
  //eslint-disable-next-line no-unused-vars
  const [_, recordTypeId] = yield select(getPathname, {
    match: /\/guest-lists\/([a-z\d-]+)\/?/i
  });

  const pathname = yield select(getPathname);

  if (!recordTypeId) {
    const firstRecordTypeId = R.path([0, "id"], recordTypes);
    yield all([
      put(replace(`${pathname}/${firstRecordTypeId}`)),
      put(
        FilterControlActions.toggleOption(firstRecordTypeId, {
          meta: { instanceId: GROUP_FILTER_ID }
        })
      )
    ]);
  } else if (R.find(R.propEq("id", recordTypeId), recordTypes)) {
    yield put(
      FilterControlActions.toggleOption(recordTypeId, {
        meta: { instanceId: GROUP_FILTER_ID }
      })
    );
  }

  for (;;) {
    const { payload: id } = yield take(
      action =>
        action.type ===
          R.path(["toggleOption", "type"], FilterControlActions) &&
        R.path(["meta", "instanceId"], action) === GROUP_FILTER_ID
    );

    const pathname = yield select(getPathname);

    const base = R.replace(/\/guest-lists\/([a-z\d-]+)\/?/i, "", pathname);

    yield put(replace(`${base}/guest-lists/${id}`));
  }
};

const rootSaga = function*() {
  yield all([
    fork(syncRoute),
    fork(loadRecordTypes),
    fork(actionsSagas),
    fork(watchFetchGuestLists),
    fork(watchUpdateTable),
    fork(watchSearch)
  ]);
};

export default rootSaga;
