import React from "react";
import * as R from "ramda";
import { TABS } from "../constants";
import Details from "./Details";
import Managers from "./Managers";
import Assign from "./Assign";
import { connect } from "react-redux";
import { getters } from "../model";

const decorate = connect(state => ({
  activeTab: getters.activeTab(state)
}));

const Content = ({ activeTab }) =>
  React.createElement(
    R.propOr(Details, activeTab, {
      [TABS.DETAILS]: Details,
      [TABS.MANAGERS]: Managers,
      [TABS.ASSIGN]: Assign
    })
  );

export default decorate(Content);
