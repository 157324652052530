import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Records from "components/Event/Module/Records";
import { withRouter } from "react-router";
import can from "components/Global/Security";

import {
  addRecord,
  deleteRecord,
  updateType,
  getRecords
} from "redux/modules/modules/records/actions";
import { addValue } from "redux/modules/modules/values/actions";
import {
  deselectAllRows,
  clearSearch
} from "redux/modules/modules/records/settings/actions";
import { references } from "redux/modules/entityReferences/selectors";
import {
  searchTerm,
  selectedRows,
  tableLayoutOptions
} from "redux/modules/modules/records/settings/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getViews, updateView } from "redux/modules/modules/views/actions";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import * as modalActions from "redux/modules/modal/actions";
import * as ModuleActions from "redux/modules/modules/module/actions";
import * as ModuleRecordsSelectors from "redux/modules/modules/records/selectors";
import * as ModuleRecordTypesActions from "redux/modules/modules/recordTypes/actions";
import * as ModuleRecordTypesSelectors from "redux/modules/modules/recordTypes/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";

import * as UserSelectors from "redux/modules/user/selectors";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import searchFactory from "components/Global/CRM/TableViews/HelperComponents/SearchFactory";

const getContext = ({ context = {}, params = {} }) => ({
  ...params,
  ...context
});

function mapStateToProps(state, props) {
  const { moduleId, eventId, viewId, groupId } = getContext(props);

  return {
    views: views(state, moduleId),
    activeView: activeView(state, moduleId, viewId, groupId),
    fields: props.fields || ModuleRecordsSelectors.fields(state, moduleId),
    forms: ModuleSelectors.forms(state, moduleId),
    isFetchingRecords: ModuleRecordsSelectors.fetching(state, moduleId),
    module: ModuleSelectors.moduleDetails(state, moduleId),
    moduleSettings: tableLayoutOptions(state, moduleId),
    preferences: ModuleRecordsSelectors.preferences(state, moduleId),
    readOnly: !can(
      userPermissionProfile(state, eventId, state.user.user.id)
    ).do(`${moduleId}_update`),
    recordTypes: ModuleRecordTypesSelectors.recordTypes(state, moduleId),
    references: references(state),
    searchTerm: searchTerm(state, moduleId),
    selectedRows: selectedRows(state, moduleId),
    showSidebarAction: true,
    sidebarControlId: "inventory",
    user: UserSelectors.user(state),
    baseUrl: `/event/${eventId}/locations`,
    hideSidebar: true,
    disableViews: true
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      ...modalActions,
      ...ModuleActions,
      ...ModuleRecordTypesActions,
      getViews,
      addRecord,
      addValue,
      clearSearch,
      deleteRecord,
      deselectAllRows: () => deselectAllRows(getContext(props).moduleId),
      getRecords,
      showSnackbar,
      updateType,
      updateView
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(searchFactory(exportFactory(Records)))
);
