import PropTypes from "prop-types";
import React from "react";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";
import { get } from "lodash";
import Table from "../Table";
import Row from "../Row";

const filterFields = f =>
  !f.settings.isAdminField ||
  (f.settings.isAdminField && !f.settings.adminFieldIsAdminOnly);

const Request = ({ request, index, eventDetails }) => {
  const status = get(request, ["row", "review", "status"]);
  const fields = request.fields.filter(f => !hiddenFields.includes(f.type));
  const headers = [];
  if (index) {
    headers.push(<Row label="Request #" value={index} />);
  }
  if (status) {
    headers.push(
      <Row
        label="Approval Status"
        value={
          <span
            style={{ color: status === "approved" ? "#22C992" : "#EB3333" }}
          >
            {status.toUpperCase()}
          </span>
        }
      />
    );
  }

  return (
    <Table>
      {headers}
      {fields.filter(filterFields).map(field => {
        const value = get(request, ["row", "values", field.id]);
        return (
          <Row
            label={field.name}
            value={
              value
                ? toString(
                    getValue(value, value.type),
                    value.type,
                    eventDetails,
                    field.settings // passing field settings as `meta`
                  )
                : ""
            }
          />
        );
      })}
    </Table>
  );
};

Request.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  request: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired
};

export default Request;
