import { RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getLayoutVisibleFields(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          recordTypeId: data.recordTypeId,
          layoutType: data.layoutType,
          visibleFields: result.payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching layout fields"
          }
        ])
      );
    }
  };
}

export function addLayoutVisibleFields(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding layout fields"
          }
        ])
      );
    }
  };
}

export function removeLayoutVisibleFields(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing layout fields"
          }
        ])
      );
    }
  };
}

export function updateLayoutVisibleFields(data) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating layout fields"
          }
        ])
      );
    }
  };
}
