import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import TimePicker from "./TimePicker";
import { actions, getters } from "EventLight/Agenda/AddSessionModal/model";
import {
  Div,
  TinyToggle,
  SmallToggle,
  MediumFilledButton,
  Text1,
  SmallAvatar,
  Dropdown,
  BigOutlineButton,
  CheckIcon,
  CloseIcon,
  DatePicker,
  BigOutlineInput
} from "components/Base";
import Checkbox from "material-ui/Checkbox";

const DateSpan = ({ onClick, dateString }) => (
  <Div
    display="row.center.center"
    onClick={onClick}
    ba={1}
    bra={1}
    bc="neutral2"
    fs={2}
    color="black"
    width={107}
    height={40}
  >
    {dateString}
  </Div>
);

const decorate = connect(
  state => ({
    data: getters.data(state)
  }),
  {
    updateField: actions.updateField,
    toggleRequireRsvp: actions.toggleRequireRsvp,
    toggleRemindAttendees: actions.toggleRemindAttendees
  }
);

const AttendeeSettings = ({
  toggleRequireRsvp,
  data,
  toggleRemindAttendees
}) => (
  <Div height={500} bg="neutral0" style={{ overflowY: "auto" }}>
    <Div
      style={{
        paddingLeft: 40,
        paddingTop: 32,
        paddingBottom: 32,
        paddingRight: 128
      }}
    >
      <Div display="row.space-between.flex-start" mb={5}>
        <Div color="black" fw={3} fs={3}>
          Available to
        </Div>
        <Div>
          <Div display="row.flex-start.center" width={624}>
            <Div width={230}>
              <Checkbox
                checked={true}
                iconStyle={{ marginRight: 8 }}
                labelStyle={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#823DD1",
                  width: 300
                }}
                onCheck={() => {}}
                label="Everyone can access"
              />
            </Div>
            <Div width={230}>
              <Checkbox
                checked={false}
                iconStyle={{ marginRight: 8 }}
                labelStyle={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#1d1d1d"
                }}
                onCheck={() => {}}
                label="Limit Access"
              />
            </Div>
          </Div>
          <Div
            width={1}
            bra={1}
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            mt={5}
            bg="white"
          >
            <Div
              display="row.flex-start.center"
              onClick={toggleRequireRsvp}
              height={55}
              p={5}
            >
              <SmallToggle active={data.require_rsvp} />
              <Div ml={3} color="black" fw={3} style={{ fontSize: 18 }}>
                Require RSVP
              </Div>
            </Div>
            {data.require_rsvp ? (
              <Div p={5} pt={0}>
                <Div display="row.flex-start.center" mb={2}>
                  <div
                    style={{
                      width: 145,
                      fontSize: 14,
                      color: "#000",
                      fontWeight: 600
                    }}
                  >
                    Status
                  </div>
                  <Div
                    width={170}
                    height={35}
                    bg="neutral1"
                    bra={1}
                    p={1}
                    display="row.space-between.center"
                  >
                    <Div
                      width={80}
                      height={1}
                      display="row.flex-start.center"
                      color="white"
                      bg="teal6"
                      p={2}
                      bra={1}
                    >
                      <CheckIcon color="white" mr={1} />
                      Open
                    </Div>
                    <Div
                      width={80}
                      height={1}
                      display="row.flex-start.center"
                      color="black"
                      bg={{ default: "transparent", hover: "neutral2" }}
                      p={2}
                      bra={1}
                      transition="fast"
                    >
                      <CloseIcon color="black" mr={1} />
                      Closed
                    </Div>
                  </Div>
                </Div>
                <Div display="row.flex-start.center" mb={2}>
                  <div
                    style={{
                      width: 145,
                      fontSize: 14,
                      color: "#000",
                      fontWeight: 600
                    }}
                  >
                    RSVP Opens
                  </div>
                  <Div display="row.flex-start.center">
                    <Div mr={2}>
                      <DatePicker
                        defaultValue={data.starts_at}
                        onChange={() => {}}
                        labelProps={{
                          dateString: moment(data.starts_at).format("L")
                        }}
                        Label={DateSpan}
                      />
                    </Div>
                    <TimePicker />
                  </Div>
                </Div>
                <Div display="row.flex-start.center" mb={2}>
                  <div
                    style={{
                      width: 145,
                      fontSize: 14,
                      color: "#000",
                      fontWeight: 600
                    }}
                  >
                    RSVP Closes
                  </div>
                  <Div display="row.flex-start.center">
                    <Div mr={2}>
                      <DatePicker
                        defaultValue={data.starts_at}
                        onChange={() => {}}
                        labelProps={{
                          dateString: moment(data.starts_at).format("L")
                        }}
                        Label={DateSpan}
                      />
                    </Div>
                    <TimePicker />
                  </Div>
                </Div>
                <Div display="row.flex-start.center" mb={2}>
                  <div
                    style={{
                      width: 145,
                      fontSize: 14,
                      color: "#000",
                      fontWeight: 600
                    }}
                  >
                    Approval
                  </div>
                  <Div display="row.flex-start.center" py={2}>
                    <TinyToggle />
                    <Div fs={2} color="black" fw={2} ml={2}>
                      RSVP requires approval
                    </Div>
                  </Div>
                </Div>
                <Div display="row.flex-start.center">
                  <div
                    style={{
                      width: 145,
                      fontSize: 14,
                      color: "#000",
                      fontWeight: 600
                    }}
                  >
                    Capacity
                  </div>
                  <Div display="row.flex-start.center" py={2}>
                    <TinyToggle />
                    <Div fs={2} color="black" fw={2} mx={2}>
                      Limit Capacity
                    </Div>
                    <BigOutlineInput
                      display="row.flex-end.center"
                      width={73}
                      height={42}
                    />
                  </Div>
                </Div>
              </Div>
            ) : null}
          </Div>
          <Div
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            mt={5}
            bg="white"
            p={5}
            bra={1}
          >
            <Div width={1} display="row.space-between.flex-start" pb={2}>
              <Div color="black" fw={3} style={{ fontSize: 18 }}>
                Limit by Groups
              </Div>
              <SmallToggle />
            </Div>
            <Div display="row.flex-start.center">
              <Div color="primary7" underline fs={2} fw={3}>
                Add to all
              </Div>
              <Div color="primary7" underline fs={2} ml={2} fw={3}>
                Remove from all group types
              </Div>
            </Div>
            <Div display="row.flex-start.flex-start" mt={5}>
              <Div width={0.5}>
                <Checkbox
                  checked={true}
                  iconStyle={{ marginRight: 8 }}
                  labelStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000"
                  }}
                  onCheck={() => {}}
                  label="Media"
                />
                <Checkbox
                  checked={true}
                  iconStyle={{ marginRight: 8 }}
                  labelStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000"
                  }}
                  onCheck={() => {}}
                  label="Broadcast"
                />
              </Div>
              <Div width={0.5}>
                <MediumFilledButton color="primary7" bg="neutral1">
                  + Assign to specific groups
                </MediumFilledButton>
                <Text1 mt={4} color="neutral6" bold>
                  Specific groups assigned this request
                </Text1>
                <Div display="row.flex-start.center" mt={2}>
                  <SmallAvatar bg="altB5" text="Lantier" />
                  <Text1 ml={2} color="black" bold>
                    Lantier Tents
                  </Text1>
                </Div>
                <Div display="row.flex-start.center" mt={2}>
                  <SmallAvatar bg="neutral2" text="Space" />
                  <Text1 ml={2} color="black" bold>
                    Space Rental Co.
                  </Text1>
                </Div>
              </Div>
            </Div>
          </Div>
          <Div
            width={1}
            bra={1}
            height={55}
            p={5}
            display="row.space-between.center"
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            mt={5}
            bg="white"
          >
            <Div color="black" fw={3} style={{ fontSize: 18 }}>
              Limit by People
            </Div>
            <SmallToggle />
          </Div>
          <Div
            width={1}
            bra={1}
            height={55}
            p={5}
            display="row.space-between.center"
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            mt={5}
            bg="white"
          >
            <Div color="black" fw={3} style={{ fontSize: 18 }}>
              Limit by Pass or Ticket Types
            </Div>
            <SmallToggle />
          </Div>
        </Div>
      </Div>
      <Div display="row.space-between.flex-start" mb={5}>
        <Div color="black" fw={3} fs={3}>
          Notifications
        </Div>
        <Div width={624}>
          <Div display="row.flex-start.center" onClick={toggleRemindAttendees}>
            <TinyToggle active={data.send_reminders} />
            <Div color="black" fw={3} ml={2} style={{ fontSize: 14 }}>
              Remind attendees before the session is starting
            </Div>
          </Div>
          {data.send_reminders ? (
            <Div display="row.flex-start.flex-start" mt={2}>
              <Div width={200} mr={2}>
                <Dropdown
                  options={[]}
                  selected={null}
                  onChange={() => {}}
                  width={200}
                />
              </Div>
              <BigOutlineButton>
                <Div color="primary8" fw={3} fs={2}>
                  + Add reminder
                </Div>
              </BigOutlineButton>
            </Div>
          ) : null}
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(AttendeeSettings);
