import React, { Component } from "react";
import View from "./View";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.resources && props.resources.images ? false : true
    };
  }

  async componentDidMount() {
    if (this.state.loading) {
      await this.props.getResources(this.props.resourceType);

      return this.setState({
        loading: false
      });
    }
    return true;
  }

  goToView = view => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/passes${view}`
    });
  };

  goToTypes = () => {
    this.props.router.push(
      `/event/${
        this.props.eventDetails.id
      }/settings/catalog/type/${CREDENTIAL_TYPE_ID}/items`
    );
  };

  render() {
    const steps = [
      {
        id: "types",
        title: "Add your pass types",
        subtitle:
          "Add your credentials, internal tickets or other types of passes",
        onClick: () => this.goToTypes()
      },
      {
        id: "collect",
        title: "Start collecting requests",
        subtitle:
          "Create a form or setup another method to begin collecting requests for passes",
        onClick: () => this.goToView("/setup/collect")
      },
      {
        id: "manage",
        title: "Manage your requests",
        subtitle: "Review and manage requests that your users submit",
        onClick: () => this.goToView("/manage/all-orders")
      }
    ];
    return (
      <View
        {...{
          steps,
          loading: this.state.loading,
          resources: this.props.resources.images || []
        }}
      />
    );
  }
}

export default Controller;
