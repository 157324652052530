import React from "react";
import DocumentPreview from "components/Global/Modals/DocumentGeneration/DocumentPreview";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import ModuleView from "./ModuleView";

import { createContext } from "redux-mvc";

import * as R from "ramda";

import module, { actions } from "../index";

import Layout from "./Layout";

import rootSaga from "../sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          moduleId: this.props.moduleId,
          selected: this.props.selected,
          showSaveAndSend: this.props.showSaveAndSend
        })
      );
    }
  },
  handlers: {
    onDone(data) {
      if (this.props.onDone) {
        this.props.onDone(data);
      }
    },

    showDocumentPreviewModal(documents) {
      this.props.hideModal();

      this.props.showModal({
        content: (
          <DocumentPreview
            showSaveAndSend={this.props.showSaveAndSend}
            onDone={this.props.onDone}
            documents={documents}
          />
        ),
        wrapper: FileViewerWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"],
    persist: false
  }
});

export { ModuleView };

export default decorate(Layout);
