import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";

import { getters } from "Schedules/Schedule";
import CollapseButton from "Schedules/Schedule/View/Sidebar/CollapseButton";
import ScheduleViewPicker from "Schedules/ScheduleViewPicker/View";

const decorate = connect(state => ({
  collapsed: getters.collapsed(state) // no props, use the default instance
}));

const SidebarWrapper = ({ sidebar, collapsed = true, children, handlers }) => (
  <Div height={1} width={1} display="row.flex-start.stretch">
    <Div
      height={1}
      width={collapsed ? 50 : 250}
      style={{
        position: "relative",
        backgroundColor: "#813CD2",
        overflow: "auto",
        flexShrink: 0
      }}
    >
      {collapsed ? (
        <Div width={1} display="row.center.center" pt={2}>
          <CollapseButton />
          <ScheduleViewPicker handlers={handlers} collapsed />
        </Div>
      ) : (
        sidebar
      )}
    </Div>
    {children}
  </Div>
);

export default decorate(SidebarWrapper);
