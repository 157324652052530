export const NAMESPACE = "OrgLightDetails";

export const FORM_ID = "OrgLightDetailsForm";

export const FIELD_IDS = {
  NAME: "name",
  SLUG: "slug",
  BACKGROUND_IMAGE_URL: "background_image_url",
  LOGO_IMAGE_URL: "logo_image_url"
};
