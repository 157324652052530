import CateringEditor from "components/Global/Table3/CellEditors/CateringEditor";
import CheckboxEditor from "components/Global/Table3/CellEditors/CheckboxEditor";
import CountriesEditor from "components/Global/Table3/CellEditors/CountriesEditor";
import CredentialEditor from "components/Global/Table3/CellEditors/CredentialEditor";
import DateEditor from "components/Global/Table3/CellEditors/DateEditor";
import DateTimeEditor from "components/Global/Table3/CellEditors/DateTimeEditor";
import DependencyEditor from "components/Global/Table3/CellEditors/DependencyEditor";
import DietaryRestrictionsEditor from "components/Global/Table3/CellEditors/CateringDietaryRestrictionsEditor";
import DropDownEditor from "components/Global/Table3/CellEditors/DropdownEditor";
import EventDaysEditor from "components/Global/Table3/CellEditors/EventDaysEditor";
import FileEditor from "components/Global/Table3/CellEditors/FileEditor";
import MultipleSelectEditor from "components/Global/Table3/CellEditors/MultipleSelectEditor";
import NumberEditor from "components/Global/Table3/CellEditors/NumberEditor";
import PhoneEditor from "components/Global/Table3/CellEditors/PhoneEditor";
import RadioEditor from "components/Global/Table3/CellEditors/RadioEditor";
import TextEditor from "components/Global/Table3/CellEditors/TextEditor";
import TimeEditor from "components/Global/Table3/CellEditors/TimeEditor";
import UserEditor from "components/Global/Table3/CellEditors/UserEditor";
import ReferenceEditor from "components/Global/Table3/CellEditors/ReferenceEditor";
import PercentEditor from "components/Global/Table3/CellEditors/PercentEditor";
import CurrencyEditor from "components/Global/Table3/CellEditors/CurrencyEditor";
import LinkedRecordsEditor from "components/Global/Table3/CellEditors/LinkedRecordsEditor";
import CatalogItemEditor from "components/Global/Table3/CellEditors/CatalogItemEditor";

export default function resolveEditor(column) {
  if (
    column.type === "dropdown" &&
    column.settings &&
    column.settings.allowMultipleSelect &&
    column.editable !== false
  ) {
    return MultipleSelectEditor;
  } else if (column.type === "dropdown" && column.editable !== false) {
    return DropDownEditor;
  } else if (
    (column.type === "checkbox" || column.type === "boolean") &&
    column.editable !== false
  ) {
    return CheckboxEditor;
  } else if (column.type === "date" && column.editable !== false) {
    return DateEditor;
  } else if (column.type === "datetime" && column.editable !== false) {
    return DateTimeEditor;
  } else if (column.type === "time") {
    return TimeEditor;
  } else if (column.type === "dependency" && column.editable !== false) {
    return DependencyEditor;
  } else if (column.type === "credentials" && column.editable !== false) {
    return CredentialEditor;
  } else if (column.type === "event-days" && column.editable !== false) {
    return EventDaysEditor;
  } else if (column.type === "catering" && column.editable !== false) {
    return CateringEditor;
  } else if (
    column.type === "catering-dietary-restrictions" &&
    column.editable !== false
  ) {
    return DietaryRestrictionsEditor;
  } else if (
    (column.type === "number" || column.type === "quantity") &&
    column.editable !== false
  ) {
    return NumberEditor;
  } else if (column.type === "percent" && column.editable !== false) {
    return PercentEditor;
  } else if (column.type === "currency" && column.editable !== false) {
    return CurrencyEditor;
  } else if (column.type === "user" && column.editable !== false) {
    return UserEditor;
  } else if (column.type === "phone" && column.editable !== false) {
    return PhoneEditor;
  } else if (column.type === "file" && column.editable !== false) {
    return FileEditor;
  } else if (column.type === "countries" && column.editable !== false) {
    return CountriesEditor;
  } else if (column.type === "radio" && column.editable !== false) {
    return RadioEditor;
  } else if (column.type === "reference" && column.editable !== false) {
    return ReferenceEditor;
  } else if (column.type === "lookup" && column.editable !== false) {
    return LinkedRecordsEditor;
  } else if (column.type === "catalog-item" && column.editable !== false) {
    return CatalogItemEditor;
  } else if (column.type !== "static" && column.editable !== false) {
    return TextEditor;
  }
}
