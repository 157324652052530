import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Show from "./Show";

import {
  showSidebar,
  hideSidebar
} from "redux/modules/modules/sidebar/actions";
import { isVisible } from "redux/modules/modules/sidebar/selectors";

function mapStateToProps(state, props) {
  return {
    sidebarIsActive: isVisible(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSidebar,
      hideSidebar
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Show);
