/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  updateDetails: ({ credentials, orgId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}`,
        credentials,
        data,
        success,
        error
      });
    }),
  getToken: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/billing/token`,
        credentials,
        success,
        error
      });
    }),
  getSubscriptionDetails: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/billing/subscription`,
        credentials,
        success,
        error
      });
    }),
  getStripeDetails: (credentials, orgId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/stripe-connect/organizations/${orgId}/auth`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  deleteStripeDetails: (credentials, orgId, authId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/stripe-connect/organizations/${orgId}/auth/connections/${authId}`,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    }),
  postStripeUrl: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/stripe-connect/organizations/${data.orgId}/auth/standard-url`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
