import {
  REQUEST,
  RECEIVE_GROUP,
  RECEIVE_EVENT,
  RECEIVE_SETTINGS,
  ERROR,
  CLEAR_ERROR
} from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getDetails(groupId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const group = await api.get(groupId);
      dispatch({ type: RECEIVE_EVENT, payload: group.event });
      dispatch({ type: RECEIVE_GROUP, payload: group.group });
      dispatch({ type: RECEIVE_SETTINGS, payload: group.settings });
      return group;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
