import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { Div, Text6 } from "components/Base";
import { getShowSidebar, getTypeName, getShouldShowZones } from "../selectors";
import { actions, getters } from "Event/ItemCatalog";
import { SUB_TABS } from "Event/ItemCatalog/constants";
import Loading from "ui-kit/Loading";
import Sidebar from "./Sidebar";

const decorate = connect(
  (state, props) => ({
    showSidebar: getShowSidebar(state),
    typeName: getTypeName(state, props),
    activeSubTab: getters.activeSubTab(state),
    types: getters.types(state),
    loading: getters.loading(state),
    typeId: getters.typeId(state),
    routeName: getters.routeName(state),
    shouldShowZones: getShouldShowZones(state)
  }),
  {
    setZonesSubTab: actions.setZonesSubTab,
    setAccessGridSubTab: actions.setAccessGridSubTab,
    setApproversSubTab: actions.setApproversSubTab,
    setItemsSubTab: actions.setItemsSubTab,
    setActionsSubTab: actions.setActionsSubTab,
    setInventorySubTab: actions.setInventorySubTab
  }
);

const Body = ({
  children,
  showSidebar,
  typeName,
  activeSubTab,
  loading,
  handlers,
  shouldShowZones,
  setZonesSubTab,
  setAccessGridSubTab,
  setApproversSubTab,
  setItemsSubTab,
  setActionsSubTab,
  setInventorySubTab
}) => {
  return showSidebar ? (
    <Div display="row.flex-start">
      <Sidebar />
      {loading ? (
        <Loading />
      ) : (
        <Div
          flex={1}
          display="column.flex-start"
          style={
            activeSubTab === SUB_TABS.ACCESS_GRID ||
            activeSubTab === SUB_TABS.ZONES
              ? { maxWidth: 840, overflow: "auto" }
              : {}
          }
        >
          <Div bc="neutral3" bb={1} pl={6}>
            <Div display="row.flex-start.center" mt={2}>
              <Text6 bold color="black">
                {typeName}
              </Text6>
            </Div>
            <Div display="row.flex-start.center" mt={3}>
              <Div
                pb={2}
                bb={2}
                bc={
                  activeSubTab === SUB_TABS.ITEMS ? "primary8" : "transparent"
                }
                fw={3}
                color={activeSubTab === SUB_TABS.ITEMS ? "black" : "primary8"}
                px={1}
                transition="fast"
                onClick={setItemsSubTab}
              >
                Items
              </Div>
              <Div
                pb={2}
                bb={2}
                bc={
                  activeSubTab === SUB_TABS.APPROVERS
                    ? "primary8"
                    : "transparent"
                }
                fw={3}
                color={
                  activeSubTab === SUB_TABS.APPROVERS ? "black" : "primary8"
                }
                px={1}
                ml={4}
                transition="fast"
                onClick={setApproversSubTab}
              >
                Approvers
              </Div>
              {shouldShowZones && (
                <>
                  <Div
                    pb={2}
                    bb={2}
                    bc={
                      activeSubTab === SUB_TABS.ZONES
                        ? "primary8"
                        : "transparent"
                    }
                    fw={3}
                    color={
                      activeSubTab === SUB_TABS.ZONES ? "black" : "primary8"
                    }
                    px={1}
                    ml={4}
                    transition="fast"
                    onClick={setZonesSubTab}
                  >
                    Zones
                  </Div>

                  <Div
                    pb={2}
                    bb={2}
                    bc={
                      activeSubTab === SUB_TABS.INVENTORY
                        ? "primary8"
                        : "transparent"
                    }
                    fw={3}
                    color={
                      activeSubTab === SUB_TABS.INVENTORY ? "black" : "primary8"
                    }
                    px={1}
                    ml={4}
                    transition="fast"
                    onClick={setInventorySubTab}
                  >
                    Inventory
                  </Div>

                  <Div
                    pb={2}
                    bb={2}
                    bc={
                      activeSubTab === SUB_TABS.ACCESS_GRID
                        ? "primary8"
                        : "transparent"
                    }
                    fw={3}
                    color={
                      activeSubTab === SUB_TABS.ACCESS_GRID
                        ? "black"
                        : "primary8"
                    }
                    px={1}
                    ml={4}
                    transition="fast"
                    onClick={setAccessGridSubTab}
                  >
                    Access Grid
                  </Div>
                  <Div
                    pb={2}
                    bb={2}
                    bc={
                      activeSubTab === SUB_TABS.ACTIONS
                        ? "primary8"
                        : "transparent"
                    }
                    fw={3}
                    color={
                      activeSubTab === SUB_TABS.ACTIONS ? "black" : "primary8"
                    }
                    px={1}
                    ml={4}
                    transition="fast"
                    onClick={setActionsSubTab}
                  >
                    Actions
                  </Div>
                </>
              )}
            </Div>
          </Div>
          {activeSubTab === SUB_TABS.INVENTORY ? (
            <div
              style={{
                maxWidth: "880px",
                padding: 20
              }}
            >
              {React.cloneElement(children, { handlers })}
            </div>
          ) : (
            React.cloneElement(children, { handlers })
          )}
        </Div>
      )}
    </Div>
  ) : (
    <Div>{React.cloneElement(children, { handlers })}</Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(Body);
