import React, { PureComponent } from "react";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { withState, withHandlers } from "utils/General";
import { TypesReportTable } from "components/Global/OrderReports/Common";
import orderReportController from "components/Global/OrderReports";
import View from "components/Global/OrderReports/View";
import { paginatorResults, paginatorHandler } from "components/Base";
import {
  hasApprovals,
  isApproved,
  parseApproval,
  isRejected
} from "components/Global/Approvals/utils/approvals-helpers";

const getApprovedQty = lineItem =>
  !hasApprovals(lineItem) || isApproved(lineItem) ? lineItem.quantity : 0;

const getRejectedQty = lineItem =>
  isRejected(lineItem) ? lineItem.quantity : 0;

class Controller extends PureComponent {
  render() {
    const {
      approveLineItems,
      approveRemaining,
      cando,
      countOfRemainingReviews,
      denyRemaining,
      loading,
      currentPage,
      goToOrder,
      goToPage,
      onSelectResultsPerPage,
      removeAssignment,
      removeAssignments,
      rejectLineItems,
      onSearch,
      report,
      searchTerm,
      resultsPerPage,
      showAddOrderModal,
      showLinkedRecordsModal,
      removeApproval,
      toggleVisibleTable,
      visibleTables,
      user
    } = this.props;

    const views = [
      {
        id: "all-orders",
        name: "All Orders",
        active: true
      }
    ];
    const rowReducer = (
      rows,
      {
        order_number,
        order_id,
        price,
        assigned_to,
        quantity,
        account_name,
        contact_name,
        ...lineItem
      }
    ) => {
      const isItemApproved = !hasApprovals(lineItem) || isApproved(lineItem);
      const approval = parseApproval({
        record: lineItem,
        userId: user.id
      });
      const key = `${assigned_to.contact_name}-${approval.approvalStatus}`;
      let value = {};
      if (rows[key]) {
        value = rows[key];
        value.numApproved += isItemApproved ? quantity : 0;
        value.numRejected += isRejected(lineItem) ? quantity : 0;
        value.numRequired += quantity;
        value.orders = R.uniqBy(R.prop("number"), [
          ...rows[key].orders,
          { number: order_number, onClick: () => goToOrder(order_id) }
        ]);
        value.lineItems = [...rows[key].lineItems, lineItem];
        value.assignmentIds = [...rows[key].assignmentIds, assigned_to.id];
        value.approvalProps = {
          approveLineItems: () =>
            approveLineItems([...rows[key].lineItems, lineItem]),
          rejectLineItems: () =>
            rejectLineItems([...rows[key].lineItems, lineItem]),
          ...approval
        };
        value.accountName =
          account_name === value.accountName ? account_name : undefined;
        value.contactName =
          contact_name === value.contactName ? contact_name : undefined;
        if (cando(`${STANDARD_MODULE_IDS.credentials.id}_update_line_items`)) {
          value.onAssign = !lineItem.is_issued
            ? count =>
                showLinkedRecordsModal(count, [
                  ...rows[key].lineItems.map(r => r.id),
                  lineItem.id
                ])
            : undefined;
          value.onRemoveAssignment = () =>
            removeAssignments([...rows[key].assignmentIds, lineItem.id]);
        }
      } else {
        value = {
          numRequired: quantity,
          numRejected: isRejected(lineItem) ? quantity : 0,
          numApproved: isItemApproved ? quantity : 0,
          price: R.isNil(price) || R.isEmpty(price) ? undefined : price,
          orders: [
            { number: order_number, onClick: () => goToOrder(order_id) }
          ],
          onRemoveAssignment: undefined,
          onAssign: undefined,
          assignedTo: assigned_to.contact_name,
          accountName: account_name,
          contactName: contact_name,
          onRemove: () => removeApproval(lineItem.id),
          lineItems: [lineItem],
          assignmentIds: [assigned_to.id],
          approvalProps: {
            approveLineItems: () => approveLineItems([lineItem]),
            rejectLineItems: () => rejectLineItems([lineItem]),
            ...approval
          }
        };
        if (cando(`${STANDARD_MODULE_IDS.credentials.id}_update_line_items`)) {
          value.onAssign = !lineItem.is_issued
            ? count => showLinkedRecordsModal(count, [lineItem.id])
            : undefined;
          value.onRemoveAssignment = () => removeAssignment(assigned_to.id);
        }
      }
      rows[key] = value;
      return rows;
    };

    const reportWithHandlers = R.map(reportItem => {
      const tableHash = group => `${reportItem.name}-${group[0].account_id}`;
      let tableProps = {
        rowData: [],
        collapsableRowsData: []
      };
      R.forEach(group => {
        const preGroupedRows = R.compose(
          R.sortBy(R.prop("assignedTo")),
          R.values,
          R.reduce(rowReducer, {})
        )(group);
        if (preGroupedRows.length > 1) {
          tableProps.rowData.push({
            isNested: true,
            price: undefined,
            orders: [],
            onCollapse: () => toggleVisibleTable(tableHash(group)),
            accountName: group[0].account_name,
            numRequired: R.sum(R.map(R.prop("quantity"), group)),
            numApproved: R.sum(R.map(getApprovedQty, group)),
            numRejected: R.sum(R.map(getRejectedQty, group)),
            bulkApprovalProps: {
              countOfRemainingReviews: countOfRemainingReviews(group),
              onBulkApprove: () => approveRemaining(group),
              onBulkReject: () => denyRemaining(group)
            }
          });
          tableProps.collapsableRowsData.push({
            collapsedDefault: !visibleTables.includes(tableHash(group)),
            rowsData: preGroupedRows
          });
        } else {
          tableProps.rowData.push(preGroupedRows[0]);
          tableProps.collapsableRowsData.push(undefined);
        }
      })(
        R.values(
          R.groupBy(
            R.prop("account_id"),
            R.propOr([], "line_items", reportItem)
          )
        )
      );
      return {
        ReportTableComp: TypesReportTable,
        headerSubmessages: [],
        headerTitle: reportItem.name,
        headerIconColor: reportItem.background_color,
        percentComplete:
          (reportItem.count_of_reviewed / reportItem.total) * 100,
        numPendingReview: reportItem.count_of_pending,
        bulkApprovalProps: {
          countOfRemainingReviews: countOfRemainingReviews(
            reportItem.line_items
          ),
          onBulkApprove: () => approveRemaining(reportItem.line_items),
          onBulkReject: () => denyRemaining(reportItem.line_items)
        },
        ...tableProps
      };
    })(report);

    return (
      <View
        {...{
          countOfResults: report.length,
          currentPage: currentPage,
          goToPage: goToPage,
          loading,
          moduleId: STANDARD_MODULE_IDS.orders.id,
          onSearch,
          onSelectResultsPerPage: onSelectResultsPerPage,
          report: paginatorResults({
            records: reportWithHandlers,
            resultsPerPage,
            currentPage
          }),
          reportSource: "Passes / Manage",
          reportTitle: "Items by type",
          resultsPerPage: resultsPerPage,
          searchTerm,
          showAddOrderModal,
          views
        }}
      />
    );
  }
}
export default R.compose(
  withState("visibleTables", "setVisibleTables", []),
  withHandlers({
    toggleVisibleTable: props => id => {
      if (props.visibleTables.includes(id)) {
        return props.setVisibleTables(
          props.visibleTables.filter(t => t !== id)
        );
      }
      return props.setVisibleTables([id, ...props.visibleTables]);
    }
  })
)(
  paginatorHandler(
    orderReportController(Controller, "line_items", (val, base) => ({
      id: val.id,
      accountName: val.account_name,
      contactName: val.contact_name,
      assignedTo: val.assigned_to.contact_name,
      other: base.display_name
    })),
    { resultsPerPage: 5 }
  )
);
