import { createModule } from "redux-mvc";

import submissionEditor from "Submission/Editor";
import approversLabel from "ApproversLabel";
import selectiveApproversLabel from "SelectiveApproversLabel";
import manageBy from "Items/Manage";
import table from "ui-kit/Table";

import handlers from "Orders/OrderModal/handlers";

const module = createModule(handlers);

module.plugModule(submissionEditor);
module.plugModule(approversLabel);
module.plugModule(selectiveApproversLabel);
module.plugModule(manageBy);
module.plugModule(table);

const { actions, getters } = module;

export { actions, getters };

export default module;
