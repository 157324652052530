import React, { Component } from "react";
import DocumentPreview from "components/Global/Modals/DocumentGeneration/DocumentPreview";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import { showModal } from "redux/modules/modal/actions";

import { connect } from "react-redux";

import module, { actions } from "../index";

import Layout from "./Layout";

import rootSaga from "../sagas";

module.setRootSaga(rootSaga);

class ModuleView extends Component {
  constructor(props) {
    super(props);

    this.handlers = {
      showDocumentPreviewModal: documents => {
        this.props.hideModal();

        this.props.showModal({
          content: (
            <DocumentPreview
              showSaveAndSend={this.props.showSaveAndSend}
              onDone={this.props.onDone}
              documents={documents}
            />
          ),
          wrapper: FileViewerWrapper
        });
      }
    };
  }
  componentDidMount() {
    this.props.init({
      moduleId: this.props.moduleId,
      selected: this.props.selected,
      showSaveAndSend: this.props.showSaveAndSend
    });
  }

  render() {
    return <Layout {...this.props} handlers={this.handlers} />;
  }
}

export default connect(
  null,
  {
    init: actions.init,
    showModal: showModal
  }
)(ModuleView);
