import Helpers from "utils/Global/Helpers";

export default {
  getVenues: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/venues`,
        credentials,
        success,
        error
      });
    }),
  getTags: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/event-tags`,
        credentials,
        success,
        error
      });
    }),
  postVenue: ({ credentials, orgId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/venues`,
        credentials,
        data,
        success,
        error
      });
    }),
  putVenue: ({ credentials, orgId, data, venueId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/venues/${venueId}`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteVenue: ({ credentials, orgId, venueId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/venues/${venueId}`,
        credentials,
        success,
        error
      });
    }),
  postTag: ({ credentials, orgId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/event-tags`,
        credentials,
        data,
        success,
        error
      });
    }),
  putTag: ({ credentials, orgId, data, tagId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/event-tags/${tagId}`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteTag: ({ credentials, orgId, tagId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/event-tags/${tagId}`,
        credentials,
        success,
        error
      });
    })
};
