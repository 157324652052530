import { fork, takeEvery, all, call, put } from "redux-saga/effects";
import { actions } from "./model";

import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import { getOrganizationUserPermissionProfile } from "redux/modules/permissions/user-permission-profile/actions";
import { fetchOrganization } from "redux/modules/organization/actions";
import { apiCall } from "App/Data/sagas";
import { registerError } from "redux/modules/errors/actions";

const init = function*({ payload: { orgId, id } }) {
  yield put(getUserAccessLevels());
  yield put(getOrganizationUserPermissionProfile(orgId, id));
  yield put(fetchOrganization(orgId));
};

const bringAccess = function*({ payload: { orgId } }) {
  try {
    yield call(apiCall, {
      method: "post",
      url: `/admins/org-user/${orgId}`
    });
    yield call([location, location.reload]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading organization"
          }
        ])
      )
    ]);
  }
};

const watchBringAccess = function*() {
  yield takeEvery(actions.bringAccess.type, bringAccess);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchBringAccess)]);
};
export default rootSaga;
