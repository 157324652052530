import React, { Component } from "react";
import {
  any,
  compose,
  filter,
  groupBy,
  isEmpty,
  length,
  map,
  pathOr,
  pickBy,
  pipe,
  prop,
  propOr,
  times,
  uniq,
  values
} from "ramda";
import PropTypes from "prop-types";
import {
  BigFilledButton,
  Div,
  DownFilledIcon,
  getNamedColors,
  Popover,
  Text1,
  Text2
} from "components/Base";
import { hashStrPickEl, withProps } from "utils/General";
import { AssignMenu } from "../../Menus";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  enforceVariantLimitsOnAssigments,
  formatAssignmentsFromProps,
  formatContactValues,
  groupedAssignmentsFromProps,
  initials,
  mergeAssignments,
  resolveName,
  wrapAction
} from "../../utils";
import fullnameToFirstLast from "utils/Contacts/fullname-to-first-last";
import EventDays from "components/Global/StandAloneEditors/EventDays";
import Item from "../../GroupedItems";
import { LABELS, MEAL_TYPE_ID } from "utils/item-types";
import Helpers from "utils/Global/Helpers";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import getMetaData from "utils/value-types/get-meta-data";
import isValid from "utils/value-types/validations/is-valid";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import { noop } from "utils/General";

const FULL_NAME_FIELD_ID = "FULL_NAME";
const TOP_LEVEL_FIELD_IDS = [
  FULL_NAME_FIELD_ID,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
];

const eventColorSet = getNamedColors(
  ["purple", "blue", "teal", "yellow", "orange", "red"],
  [6, 8]
);
const colorBuilder = hashStrPickEl(eventColorSet);

const FieldSet = withProps({ py: 1, mb: 2 })(Div);
const FieldLabel = withProps({
  bold: true,
  color: "gray6",
  pb: 1,
  uppercase: true,
  display: "row.space-between.center"
})(Text1);

const populateItems = ({ items, resources }) => {
  return items.map(item => {
    const { background_color, type_id, name } =
      resources.find(a => a.id === item.id) || {};

    return {
      ...item,
      typeId: type_id,
      title: name,
      color: background_color
    };
  });
};

const defaultState = (
  { fname = "", lname = "", email = "", days_on_site = "" } = {},
  custom_values = {}
) => ({
  assignments: [],
  contact: {
    days_on_site: days_on_site || []
  },
  custom_values: {
    ...(custom_values || {}),
    [FULL_NAME_FIELD_ID]: {
      type: "text",
      value: [fname, lname].filter(v => v && v.length).join(" ")
    },
    [STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]: {
      type: "text",
      value: email || ""
    }
  },
  error: undefined
});

const formatAssignedItems = items => {
  const { id, item } = items[0];
  return {
    id,
    quantity: items.length,
    issued: compose(length, filter(prop("is_issued")))(items),
    typeId: item.type.id,
    assignments: items
  };
};

class PersonModal extends Component {
  state = {
    ...defaultState(
      this.props.defaultValues
        ? this.props.defaultValues.contact
        : this.props.member.contact,
      this.props.defaultValues
        ? this.props.defaultValues.custom_values
        : this.props.member.custom_values
    ),
    assignments: map(
      formatAssignedItems,
      values(
        groupBy(
          a => a.id,
          this.props.defaultValues
            ? this.props.defaultValues.assignments
            : this.props.member.assignments
        )
      )
    )
  };

  handleAssignmentChange = ({ typeId, values: valueList }) => {
    return this.setState(state => ({
      assignments: enforceVariantLimitsOnAssigments({
        assignments: mergeAssignments([
          ...state.assignments.filter(v => v.typeId !== typeId),
          ...populateItems({
            items: valueList.filter(v => v.quantity > 0),
            resources: this.props.resources
          })
        ]),
        variantLimits: this.props.managerDetails.settings.variant_limits
      })
    }));
  };

  handleRemoveAssignment = id => {
    this.setState(state => {
      const keepState = state.assignments.filter(a => a.id !== id);
      const assignment = state.assignments.find(a => a.id === id);

      const countOfIssued = pathOr([], ["assignments"], assignment).filter(
        a => a.is_issued
      ).length;

      const editableQuantity = assignment.quantity - countOfIssued;

      // if no editable meals, then return current state
      if (!editableQuantity) {
        return state;
      }

      const updatedAssignment = {
        ...assignment,
        quantity: assignment.quantity - 1
      };

      if (updatedAssignment.quantity > 0) {
        return { assignments: [...keepState, updatedAssignment] };
      }
      return { assignments: keepState };
    });
  };

  handleSave = e => {
    e.preventDefault();

    if (!this.validateEmail()) {
      return this.setState({ error: "Email address in invalid format" });
    }

    const requiredFields = filter(prop("is_required"))(
      this.props.managerDetails.settings.fields
    );
    const invalidFields = [];

    if (requiredFields.length) {
      requiredFields.forEach(field => {
        const isValidValue = isValid(
          this.state.custom_values[field.id],
          field.type
        );
        if (!isValidValue) {
          invalidFields.push(field.name);
        }
      });
    }

    if (invalidFields.length) {
      return this.setState({ error: "* All fields required." });
    }

    if (this.validateSubmission()) {
      this.setState({ error: undefined });
      const { fname, lname } = fullnameToFirstLast(
        propOr("", "value")(this.state.custom_values[FULL_NAME_FIELD_ID])
      );
      const valuesToSave = pickBy((val, key) => {
        return key !== FULL_NAME_FIELD_ID;
      })(this.state.custom_values);

      valuesToSave[STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME] = {
        type: "text",
        value: fname
      };

      valuesToSave[STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME] = {
        type: "text",
        value: lname && lname.length ? lname : ""
      };

      this.props.onSave({
        id: this.props.member.id,
        contact: formatContactValues(this.state.contact), // days on site
        custom_values: valuesToSave,
        assignments: this.state.assignments
      });
      return this.props.hideModal();
    }
    return this.setState({ error: "* All fields required." });
  };

  saveAndReset = async () => {
    try {
      await this.handleSave();
      this.reset();
    } catch (err) {
      noop();
    }
  };

  handleCopy = typeId => copyFrom => {
    let assignments = this.props.people.find(p => p.id === copyFrom)
      .assignments;
    if (typeId) {
      assignments = assignments.filter(a => a.item.type.id === typeId);
    }
    this.handleAssignmentChange({
      typeId,
      values: [
        ...formatAssignmentsFromProps(groupedAssignmentsFromProps(assignments))
      ]
    });
  };

  validateSubmission = () =>
    Boolean(
      propOr("", "value")(this.state.custom_values[FULL_NAME_FIELD_ID]).length
    ) &&
    (this.shouldShowEventDaysPicker()
      ? this.state.contact.days_on_site &&
        this.state.contact.days_on_site.length
      : true);

  validateEmail = () =>
    this.state.custom_values[STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL] &&
    this.state.custom_values[STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL].value
      .length
      ? Helpers.isValidEmail(
          this.state.custom_values[
            STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
          ].value.trim()
        )
      : true;

  reset = () => this.setState(defaultState());

  handleEventDaysChange = val =>
    this.setState({
      contact: { ...this.state.contact, days_on_site: val.value }
    });

  shouldShowEventDaysPicker = () =>
    !pipe(
      map(r => r.type_id),
      filter(id => id === MEAL_TYPE_ID),
      isEmpty
    )(this.props.resources);

  getRowMetaData = field =>
    getMetaData({
      row: {},
      rowId: 0,
      field,
      eventDetails: this.props.event,
      eventId: prop("id")(this.props.event)
    });

  saveFieldValue = (fieldId, value) => {
    this.setState({
      custom_values: {
        ...this.state.custom_values,
        [fieldId]: value
      }
    });
  };

  render() {
    const { people, resources, hideModal, event } = this.props;
    const resourceTypeIds = pipe(
      map(r => r.type_id),
      uniq
    )(resources);
    const fillItems = item => {
      const { background_color, type_id, name } =
        this.props.resources.find(a => a.id === item.id) || {};

      // @NOTE: We protect against nulls here since they can come back in undefined
      // via the item popovers
      let countOfIssued = pathOr([], ["assignments"], item).filter(
        a => a.is_issued
      ).length;

      const qty = item.quantity || 1;

      return {
        ...item,
        title: name,
        color: background_color,
        typeId: type_id,
        items: times(n => {
          return {
            id: item.id,
            onRemove: () => this.handleRemoveAssignment(item.id),
            issued: n + 1 <= countOfIssued
          };
        }, qty)
      };
    };

    const formatPeople = ({ id, contact }) => ({
      id,
      name: resolveName(contact),
      initials: initials(resolveName(contact)),
      color: colorBuilder(resolveName(contact))
    });

    const assignmentsList = map(fillItems, this.state.assignments);

    const resourceTypes = resourceTypeIds.filter(typeId =>
      assignmentsList.find(a => a.typeId === typeId)
    );

    // show event day picker if there are meals availble to be assigned
    const showEventDaysPicker = this.shouldShowEventDaysPicker();
    const disableContactValues = any(prop("issued"), this.state.assignments);

    const customFieldsWithHandlers = map(field => {
      const Editor = resolveEditor(field);
      return {
        id: field.id,
        name: field.name,
        Editor,
        required: field.is_required,
        value: this.state.custom_values[field.id],
        onChange: val => this.saveFieldValue(field.id, val),
        rowMetaData: this.getRowMetaData(field),
        editorProps: resolveEditorProps(field, this.props.event)
      };
    })(this.props.managerDetails.settings.fields);

    return (
      <StyleWrapper
        hideModal={hideModal}
        heading={`${this.props.member.id ? `Update` : `Add`} Person`}
        width={395}
      >
        <form onSubmit={this.handleSave}>
          {customFieldsWithHandlers
            .filter(f => TOP_LEVEL_FIELD_IDS.includes(f.id))
            .map(field => {
              const maxLength = pathOr(
                "",
                ["editorProps", "column", "settings", "maxLength"],
                field
              );
              const Editor = field.Editor;
              return (
                <FieldSet key={field.id}>
                  <FieldLabel>
                    <span>{field.name}</span>
                    {field.required ? <span>*Required</span> : null}
                  </FieldLabel>
                  <Editor
                    disabled={disableContactValues}
                    rowMetaData={field.rowMetaData}
                    value={field.value}
                    onChange={val => this.saveFieldValue(field.id, val)}
                    required={field.required}
                    maxLength={maxLength}
                    {...field.editorProps}
                  />
                  {!isEmpty(maxLength) ? (
                    <div
                      style={{
                        fontSize: 12,
                        padding: "4px 0 0 4px",
                        color: "#aaa",
                        fontWeight: 600
                      }}
                    >
                      {length(propOr("", "value", field.value))}/{maxLength}{" "}
                      Character
                      {length(propOr("", "value", field.value)) !== 1
                        ? "s"
                        : ""}
                    </div>
                  ) : null}
                </FieldSet>
              );
            })}

          {showEventDaysPicker ? (
            <FieldSet pb={2}>
              <FieldLabel>
                <span>days on-site</span>
                <span>*Required</span>
              </FieldLabel>
              <EventDays
                disabled={disableContactValues}
                dayGroups={event.date_groups}
                endDate={event.date_to}
                onChange={this.handleEventDaysChange}
                rowMetaData={{
                  meta: {
                    eventDetails: event
                  }
                }}
                startDate={event.date_from}
                value={{ value: this.state.contact.days_on_site }}
              />
            </FieldSet>
          ) : null}

          {customFieldsWithHandlers
            .filter(f => !TOP_LEVEL_FIELD_IDS.includes(f.id))
            .map(field => {
              const maxLength = pathOr(
                "",
                ["editorProps", "column", "settings", "maxLength"],
                field
              );
              const Editor = field.Editor;
              return (
                <FieldSet key={field.id}>
                  <FieldLabel>
                    <span>{field.name}</span>
                    {field.required ? <span>*Required</span> : null}
                  </FieldLabel>
                  <Editor
                    rowMetaData={field.rowMetaData}
                    value={field.value}
                    onChange={val => this.saveFieldValue(field.id, val)}
                    required={field.required}
                    maxLength={maxLength}
                    {...field.editorProps}
                  />
                  {!isEmpty(maxLength) ? (
                    <div
                      style={{
                        fontSize: 12,
                        padding: "4px 0 0 4px",
                        color: "#aaa",
                        fontWeight: 600
                      }}
                    >
                      {length(propOr("", "value", field.value))}/{maxLength}{" "}
                      Character
                      {length(propOr("", "value", field.value)) !== 1
                        ? "s"
                        : ""}
                    </div>
                  ) : null}
                </FieldSet>
              );
            })}

          {resourceTypes.length ? (
            <Div py={4}>
              {resourceTypes.map(typeId => (
                <Div pb={2}>
                  <Text1 pb={2} bold color="gray6">
                    {LABELS[typeId].label}
                  </Text1>
                  <Div>
                    {assignmentsList
                      .filter(a => a.typeId === typeId)
                      .map(a => (
                        <Item {...a} />
                      ))}
                  </Div>
                </Div>
              ))}
            </Div>
          ) : null}
          <Div mb={4} display="row.flex-start">
            <Popover
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              Label={({ onClick }) => (
                <BigFilledButton
                  type="button"
                  bg="blue6"
                  width={210}
                  onClick={onClick}
                  RightIcon={DownFilledIcon}
                  rightIconColor="blue2"
                  style={{ width: 210, justifyContent: "space-between" }}
                >
                  <Div fw={3}>Assign</Div>
                </BigFilledButton>
              )}
            >
              {({ closePopover }) => (
                <AssignMenu
                  people={map(
                    formatPeople,
                    filter(p => p.id !== this.props.id, people)
                  )}
                  person={this.state}
                  resourceTypeIds={resourceTypeIds}
                  onAssign={wrapAction(closePopover)(
                    this.handleAssignmentChange
                  )}
                  onCopy={this.handleCopy}
                  eventDays={this.state.contact.days_on_site}
                  assignments={this.state.assignments}
                />
              )}
            </Popover>
          </Div>
          {Boolean(this.state.error) && (
            <Text2 bold py={3} color="warning6">
              {this.state.error}
            </Text2>
          )}
          <Div display="row.flex-start.center" mb={4}>
            <BigFilledButton
              type="submit"
              flex={this.props.member.id ? 1 : undefined}
              bg="orange5"
              mr={3}
              // disabled={Boolean(this.state.error)}
            >
              {this.props.member.id ? "Update" : "Add Person"}
            </BigFilledButton>
            {/*
            // @NOTE: Hiding until UX is addressed
            {!this.props.member.id ? (
              <BigOutlineButton flex={1} onClick={this.saveAndReset}>
                Save & Add New Person
              </BigOutlineButton>
            ) : null}
            */}
          </Div>
        </form>
      </StyleWrapper>
    );
  }
}
PersonModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  member: PropTypes.shape({
    id: PropTypes.string,
    assignments: PropTypes.array
  })
};

export default PersonModal;
