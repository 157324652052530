import React from "react";
import * as R from "ramda";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import { Div, BigOutlineButton, BigFilledButton } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      customCloseDate: getters.customCloseDate(state),
      showAssigmentModal: getters.showAssigmentModal(state)
    }),
    {
      hideAssignmentModal: () => actions.setShowAssigmentModal(false),
      setCustomCloseDate: actions.setCustomCloseDate,
      updateDueDate: actions.updateDueDate
    }
  ),
  CSSModules(css)
);

const EditCloseDateModal = ({
  hideCreateModal,
  customCloseDate,
  setCustomCloseDate,
  updateDueDate,
  showAssigmentModal,
  hideAssignmentModal
}) => {
  return (
    <MiniModalWrapper
      showModal={showAssigmentModal}
      hideModal={hideCreateModal}
      title="Set Due Date"
      width={390}
      stopPropagation
      isPortal={true}
      style={{
        height: "auto"
      }}
    >
      <Div bg="white" p={5}>
        <Div mb={4}>
          <Div fw={3} color="gray7">
            Specify a due date:
          </Div>
          <DatePicker value={customCloseDate} onChange={setCustomCloseDate} />
        </Div>

        <Div display="row.flex-start.center">
          <BigFilledButton bg="primary7" mr={2} onClick={updateDueDate}>
            Save
          </BigFilledButton>
          <BigOutlineButton onClick={hideAssignmentModal}>
            Cancel
          </BigOutlineButton>
        </Div>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(EditCloseDateModal);
