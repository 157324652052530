import React, { Component } from "react";
import View from "components/Event/Settings/Users/Users/View";
import Fuse from "fuse.js";
import moment from "moment";
import * as R from "ramda";

import RemoveOrganizationUserModal from "components/Organization/Settings/Users/Modals/RemoveOrganizationUser";
import AddOrganizationUserModal from "components/Organization/Settings/Users/Modals/AddOrganizationUser";
import OrganizationUserModal from "components/Organization/Settings/Users/Modals/OrganizationUser";

const formatFullName = user => [user.user_fname, user.user_lname].join(" ");

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.user_id), opt))
  )(fuse.search(searchTerm));
};

class Controller extends Component {
  state = {
    searchTerm: ""
  };

  componentDidMount() {
    this.props.getUsers(this.props.params.orgId);
  }

  componentWillReceiveProps(nextProps) {
    this.usersFuse = new Fuse(nextProps.users, {
      threshold: 0.3,
      keys: ["user_fname", "user_lname", "user_email"],
      shouldSort: true
    });
  }

  onSearch = searchTerm => {
    if (searchTerm !== this.state.searchTerm) {
      this.setState({ searchTerm });
    }
  };

  showEditInfoModal = record => {
    const modal = (
      <OrganizationUserModal
        title={`${record.user_fname} ${record.user_lname}`}
        hideModal={this.props.hideModal}
        orgId={this.props.params.orgId}
        record={record}
        orgName={this.props.orgDetails.name}
        onUpdated={this.handleInfoUpdated}
        showPermissions
      />
    );

    this.props.showModal({ content: modal });
  };

  handleInfoUpdated = () => {
    this.props.getUsers(this.props.params.orgId);

    this.props.showSnackbar({ message: "User updated", action: "OK" });
  };

  resendInvitation = async user => {
    await this.props.resendInvite({
      orgId: this.props.params.orgId,
      userId: user.user_id
    });

    this.props.getUsers(this.props.params.orgId);
    this.props.showSnackbar({
      message: `Invitation resent to ${user.user_email}`,
      action: "OK"
    });
    return true;
  };

  handleRemoveMember = async data => {
    await this.props.removeUser({
      orgId: this.props.params.orgId,
      userId: data.userId
    });

    if (data.userId === this.props.user.id) {
      // removed self - redirect to home
      window.location = "/home";
    } else {
      this.props.getUsers(this.props.params.orgId);
      this.props.showSnackbar({ message: "User removed", action: "OK" });
    }
    return true;
  };

  showRemoveFromEventModal = (userName, userId, user) => {
    const modal = (
      <RemoveOrganizationUserModal
        user={user}
        userName={userName}
        userId={userId}
        hideModal={this.props.hideModal}
        handleRemoveMember={this.handleRemoveMember}
      />
    );

    this.props.showModal({ content: modal });
  };

  showAddOrganizationUserModal = () => {
    const modal = (
      <AddOrganizationUserModal
        hideModal={this.props.hideModal}
        addToOrgId={this.props.params.orgId}
        onAdded={this.handleUserAdded}
      />
    );

    this.props.showModal({ content: modal });
  };

  handleUserAdded = (data, user, action) => {
    this.props.getUsers(this.props.params.orgId);

    this.props.showSnackbar({
      message: action === "added" ? "User added" : "User invited",
      action: "OK"
    });
  };

  onCopy = () => {
    this.props.showSnackbar({
      message: "Copied",
      action: "OK"
    });
  };

  render() {
    const users = R.map(user => ({
      ...user,
      name: formatFullName(user),
      email: user.user_email,
      photoUrl: user.user_photo_url,
      permissionRole: user.permission_role_name,
      isPending: user.status === "pending",
      inviteSentAt: moment(new Date(user.invite_sent_at)).fromNow(),
      onCopy: this.onCopy,
      inviteLink: user.is_virtual_user
        ? `${window.__LENND_APP_URL__}/join/vu/${user.user_virtual_hash}`
        : window.__LENND_APP_URL__,
      onView: () => this.showEditInfoModal(user),
      onRemove: () =>
        this.showRemoveFromEventModal(formatFullName(user), user.user_id, user),
      onResend: () => this.resendInvitation(user)
    }))(this.props.users);

    const filteredUsers = searchWithFuse(
      this.state.searchTerm,
      this.usersFuse,
      users,
      "user_id"
    );

    return (
      <View
        {...{
          users: filteredUsers,
          onSearch: this.onSearch,
          onInviteUser: this.showAddOrganizationUserModal
        }}
      />
    );
  }
}

export default Controller;
