import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../index";
import {
  Div,
  Text3,
  Text2,
  Text1,
  Cards,
  DragIcon,
  CloseIcon,
  SmallOutlineInput,
  SmallFilledButton
} from "components/Base";

const decorate = connect(
  state => ({
    fields: getters.fields(state),
    selectedFields: getters.selectedFields(state),
    fieldIdEditingLabel: getters.fieldIdEditingLabel(state),
    tempLabel: getters.tempLabel(state)
  }),
  {
    reorderSelectedFields: actions.reorderSelectedFields,
    toggleField: actions.toggleField,
    setFieldIdEditingLabel: actions.setFieldIdEditingLabel,
    setTempLabel: actions.setTempLabel,
    updateFieldLabel: actions.updateFieldLabel
  }
);

const FieldCard = ({
  id,
  name,
  label,
  tag,
  toggleField,
  fieldIdEditingLabel,
  setFieldIdEditingLabel,
  tempLabel,
  setTempLabel,
  updateFieldLabel
}) => (
  <Div p={2} bra={1} bg="white" ba={1} bc="neutral2" shadow={1} mt={1}>
    <Div display="row.space-between.center">
      <Div display="row.flex-start.center">
        <DragIcon />
        <Text2 ml={1}>{name}</Text2>
      </Div>
      <CloseIcon
        size={12}
        onClick={() => toggleField({ id, name, tag, isSelected: true })}
        color={{ default: "neutral5", hover: "danger5" }}
      />
    </Div>
    {fieldIdEditingLabel !== id ? (
      <Div display="row.flex-start.center" p={1} px={5} pb={0}>
        <Text1 color="primary8" onClick={() => setFieldIdEditingLabel(id)}>
          {R.isEmpty(label) ? "Add Label" : "Edit"}
        </Text1>
        {!R.isEmpty(label) && (
          <Text1 color="black" ml={2}>{`Label: "${label}"`}</Text1>
        )}
      </Div>
    ) : (
      <Div display="row.flex-start.center" p={1} px={5} pb={0}>
        <SmallOutlineInput
          value={tempLabel}
          onChange={value => setTempLabel(value)}
        />
        <SmallFilledButton
          bg="primary7"
          ml={1}
          onClick={() => updateFieldLabel({ id })}
        >
          Save
        </SmallFilledButton>
        <SmallFilledButton ml={1} onClick={() => setFieldIdEditingLabel("")}>
          Cancel
        </SmallFilledButton>
      </Div>
    )}
  </Div>
);

const FieldCards = Cards(FieldCard, "FIELD");

const SelectFieldsShow = ({
  selectedFields,
  reorderSelectedFields,
  toggleField,
  fieldIdEditingLabel,
  setFieldIdEditingLabel,
  tempLabel,
  setTempLabel,
  updateFieldLabel
}) => (
  <Div
    height={463}
    bg="white"
    p={3}
    style={{
      flexShrink: 0,
      overflowY: "auto",
      flex: 1
    }}
  >
    <Div width={1} bg="neutral0" p={4} bra={1}>
      <Text3 bold mb={2}>
        Select fields to show:
      </Text3>
      <FieldCards
        cards={selectedFields}
        onReorder={orderedFields => reorderSelectedFields({ orderedFields })}
        toggleField={toggleField}
        fieldIdEditingLabel={fieldIdEditingLabel}
        setFieldIdEditingLabel={setFieldIdEditingLabel}
        tempLabel={tempLabel}
        setTempLabel={setTempLabel}
        updateFieldLabel={updateFieldLabel}
      />
    </Div>
  </Div>
);

export default decorate(SelectFieldsShow);
