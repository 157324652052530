import { createSelector } from "reselect";

import { getters } from "./index";

export const getTitle = createSelector(
  getters.questionSet,
  questionSet =>
    questionSet.name
      ? `Manage questions for ${questionSet.name}`
      : "Manage question for question set"
);

export const getQuestions = createSelector(
  getters.selectedQuestionIds,
  getters.questions,
  getters.questionsFilter,
  (selectedQuestionIds, questions, filter) => {
    const filterToUse =
      filter && filter.length ? filter.toLowerCase().trim() : null;
    const results = questions.map(q => ({
      ...q,
      selected: selectedQuestionIds.includes(q.id)
    }));
    if (filterToUse) {
      return results.filter(q => q.name.toLowerCase().includes(filterToUse));
    }
    return results;
  }
);

export const getSelectedQuestions = createSelector(
  getters.selectedQuestionIds,
  getters.requiredQuestionIds,
  getters.questions,
  (selectedQuestionIds, requiredQuestionIds, questions) => {
    return selectedQuestionIds
      .map((qId, idx) => ({
        ...(questions.find(q => q.id === qId) || {}),
        order: idx,
        required: requiredQuestionIds.includes(qId)
      }))
      .filter(q => q.id);
  }
);
