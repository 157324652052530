import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { createContext } from "redux-mvc";

import module, { actions } from "Sponsors/SelfSetup";

import { WithThemeProvider } from "ui-kit/Theme/View";
import { THEMES } from "ui-kit/Theme/constants";

import { getEvent } from "redux/modules/event/actions";
import { showModal } from "redux/modules/modal/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";

import Container from "./Layout";
import AddFieldModal from "Modules/AddEditColumnModal/View";
import EditFieldModal from "Modules/AddEditColumnModal/View";

import rootSaga from "Sponsors/SelfSetup/sagas";

module.setRootSaga(rootSaga);

const decorate = R.compose(
  connect(
    (state, props) => ({
      eventId: getEventId(state, props)
    }),
    { getEvent, showModal }
  ),
  createContext({
    module,
    options: {
      dispatchToGlobal: R.compose(
        R.not,
        R.test(
          new RegExp(
            R.join("|", R.append("BATCHING_REDUCER.BATCH", module.modules))
          )
        ),
        R.prop("type")
      ),
      observedDomains: ["user", "event", "routing"]
    },
    lifeCycle: {
      constructor() {
        this.props.getEvent(this.props.params.eventId);
      }
    },
    handlers: {
      showAddFieldModal(moduleId) {
        this.props.showModal({
          content: (
            <AddFieldModal
              eventId={this.props.eventId}
              moduleId={moduleId}
              onSave={data =>
                this.store.dispatch(actions.addQuestionField(data))
              }
            />
          )
        });
      },
      showEditFieldModal(moduleId, fieldId) {
        this.props.showModal({
          content: (
            <EditFieldModal
              eventId={this.props.eventId}
              moduleId={moduleId}
              fieldId={fieldId}
              onSave={() => this.store.dispatch(actions.updateFieldDetails())}
            />
          )
        });
      }
    }
  }),
  WithThemeProvider({ theme: THEMES.PORTAL })
);

export default decorate(Container);
