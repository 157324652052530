import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions } from "Portal/PortalHome/model";
import { Div, Text1, CheckIcon, MediumOutlineButton } from "components/Base";
import SubmissionsTable from "./SubmissionsTable";
import StatusCircle from "ui-kit/StatusCircle";
import { noop } from "utils/General";
import Tooltip from "ui-kit/Tooltip";
import moment from "moment/moment";

const getIcon = type =>
  R.prop(type)({
    document_request: (
      <Div width={26} height={26} ml={1}>
        <img
          alt="portal-icon-document"
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-document.png"
          width="26"
          height="26"
        />
      </Div>
    ),
    people: (
      <Div width={25} height={25} ml={1}>
        <img
          alt="portal-icon-people"
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-people.png"
          width="25"
          height="25"
        />
      </Div>
    ),
    task: (
      <Div width={25} height={25} ml={1}>
        <img
          alt="portal-icon-taks"
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-task.png"
          width="25"
          height="25"
        />
      </Div>
    ),
    form: (
      <Div width={26} height={26} ml={1}>
        <img
          alt="portal-icon-document"
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-document.png"
          width="26"
          height="26"
        />
      </Div>
    ),
    invoice: (
      <Div width={30} height={30} ml={1}>
        <img
          alt="portal-icon-invoice"
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-invoice.png"
          width="30"
          height="30"
        />
      </Div>
    )
  });

const decorate = connect(null, {
  executeAction: actions.executeAction
});

export const Task = ({ type, data, executeAction, ...otherProps }) => {
  const {
    count,
    description,
    due_label,
    id,
    is_past_due,
    is_required,
    meta = {},
    name,
    open_label,
    status,
    due_raw
  } = data;

  const formattedLabels = [open_label, due_label].filter(v => v).join(" · ");
  const formatedDueRaw =
    due_raw?.length &&
    `Due ${moment(due_raw).format("MMMM D, YYYY [at] hh:mm z A [GMT] Z")}`;
  const formattedLabelsDiv = (
    <Div
      fs={2}
      ml={2}
      mr={2}
      color="black"
      display="row.flex-end.center"
      style={{
        flexShrink: 0
      }}
    >
      {formattedLabels}
      {formatedDueRaw && (
        <Tooltip tooltip={formatedDueRaw}>
          <i
            style={{
              marginLeft: "8px",
              fontSize: "18px"
            }}
            className="material-icons"
          >
            schedule
          </i>
        </Tooltip>
      )}
    </Div>
  );
  const isCompletionMethodClick = R.propEq("completionMethod", "click")(meta);
  const isCompletionMethodManual = R.propEq("completionMethod", "manual")(meta);
  const isPeopleTask = id === "people";
  const countText = count ? (
    <Div bg="neutral2" pill pr={2} pl={2}>
      <Text1 bold>{`${count}${isPeopleTask && " people added"}`}</Text1>
    </Div>
  ) : null;
  const descriptionText =
    description && description.length ? (
      <Div
        width={1}
        fs={3}
        mt={1}
        mb={isPeopleTask ? 1 : 0}
        color="gray6"
        style={{
          whiteSpace: "pre-wrap",
          wordWrap: "break-word"
        }}
      >
        {description}
      </Div>
    ) : null;
  const icon = getIcon(type);
  const onMediumOutlineButtonClick = event => {
    event.stopPropagation();
    executeAction({ type: "toggle_task", data });
  };
  const rowWithMediumButton = status => (
    <Div display="column">
      {open_label || due_label ? formattedLabelsDiv : null}

      <MediumOutlineButton
        mt={2}
        LeftIcon={CheckIcon}
        onClick={onMediumOutlineButtonClick}
      >
        {status === "complete" ? "Completed" : "Mark as complete"}
      </MediumOutlineButton>
    </Div>
  );
  const submissionsExists =
    !R.isEmpty(R.propOr([], "submissions", data)) &&
    !R.isNil(R.propOr([], "submissions", data));
  const getStatusForCircle = () => {
    if (status === "pending") {
      return status;
    } else if (["complete", "approved"].includes(status)) {
      return "approved";
    } else if (is_past_due) {
      return "not-submitted";
    } else {
      return "default";
    }
  };
  const statusCircle = <StatusCircle status={getStatusForCircle()} size={30} />;
  const onDivClick = submissionsExists
    ? noop
    : () => executeAction({ type: type, data });

  let subTexts;
  if (isPeopleTask) {
    subTexts = [descriptionText, countText];
  } else {
    subTexts = [countText, descriptionText];
  }
  const getRowContent = () => {
    if (
      isCompletionMethodManual ||
      (isCompletionMethodClick && data.meta?.url && status === "complete")
    ) {
      return rowWithMediumButton(status);
    } else if (open_label || due_label) {
      return formattedLabelsDiv;
    } else {
      return null;
    }
  };

  return (
    <Div
      display="column"
      bg="white"
      px={3}
      py={3}
      bra={3}
      mb={3}
      shadow={{
        default: 0,
        hover: 2
      }}
      style={{
        cursor: "pointer",
        border: is_past_due ? "1px solid rgb(245, 159, 0)" : undefined
      }}
      onClick={onDivClick}
      {...otherProps}
    >
      <Div display="row.space-between.center" width={1} onClick={onDivClick}>
        <Div display="row.flex-start.center" width={1}>
          {statusCircle}
          {icon}
          <Div display="column" flex={1} ml={icon ? 2 : 3} mr={2}>
            <Div fs={5} fw={3} color="black">
              {name}
              {is_required && <span style={{ color: "red" }}> *</span>}
            </Div>
            {subTexts}
          </Div>
        </Div>
        {getRowContent()}
      </Div>

      {submissionsExists ? (
        <SubmissionsTable
          id={data.id}
          slug={data.form_slug}
          baseModuleId={data.base_module_id}
          isOpen={open_label !== "Closed"}
          count={data.count}
          countOfDrafts={data.count_of_drafts}
          countOfSubmitted={data.count_of_submitted}
          blockSubmit={
            R.propEq("single_submission", true, data) &&
            R.propOr(0, "count", data) >= 1
          }
        />
      ) : null}
    </Div>
  );
};

export default decorate(Task);
