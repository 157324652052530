import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { getters } from "../model";
import WelcomeScreen from "./WelcomeScreen";
import GroupInformation from "./GroupInformation";
import ContactInformation from "./ContactInformation";
import ReviewSettings from "./ReviewSettings";

const decorate = connect(state => ({
  activeIndex: getters.activeIndex(state)
}));

const Body = ({ activeIndex, handlers }) => (
  <Div style={{ height: "calc(100% - 173px)" }} bg="neutral0">
    {activeIndex === 0 && <WelcomeScreen />}
    {activeIndex === 1 && <GroupInformation handlers={handlers} />}
    {activeIndex === 2 && <ContactInformation handlers={handlers} />}
    {activeIndex === 3 && <ReviewSettings />}
  </Div>
);

export default decorate(Body);
