import { RECEIVE } from "./constants";
import { RECEIVE as RECEIVE_MODULE_RECORD } from "redux/modules/modules/record/constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { addReferences } from "redux/modules/entityReferences/actions";

export function getPerson(eventId, accountId, contactId) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId,
        contactId
      );
      dispatch(addReferences(payload.contact.references));
      dispatch({
        type: RECEIVE_MODULE_RECORD,
        payload: {
          recordId: contactId,
          record: payload.contact
        }
      });
      dispatch({
        type: RECEIVE,
        payload: {
          recordId: contactId,
          person: payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting person"
          }
        ])
      );
    }
  };
}

export function removePerson(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing person"
          }
        ])
      );
    }
  };
}
