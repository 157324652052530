import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Payments from "./Payments";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  getEmailTemplates,
  createEmailTemplate,
  deleteEmailTemplate,
  updateEmailTemplate
} from "redux/modules/eventEmailTemplates/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { goToAuthUrl } from "redux/modules/integrations/stripe-connect/actions";
import {
  getAuthStatus,
  disconnectPaymentsAuth
} from "redux/modules/integrations/stripe-connect/auth/actions";
import { getOwners } from "redux/modules/modules/owners/actions";

import { owners } from "redux/modules/modules/owners/selectors";
import { listArray } from "redux/modules/eventEmailTemplates/selectors";
import { eventId } from "redux/modules/event/selectors";
import { status } from "redux/modules/integrations/stripe-connect/auth/selectors";

function mapStateToProps(state) {
  return {
    eventId: eventId(state),
    status: status(state),
    templates: listArray(state),
    notifications: owners(state, STANDARD_MODULE_IDS.orders.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      goToAuthUrl,
      getAuthStatus,
      disconnectPaymentsAuth,
      getEmailTemplates,
      createEmailTemplate,
      deleteEmailTemplate,
      updateEmailTemplate,
      getNotifications: getOwners
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments);
