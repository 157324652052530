import React from "react";
import * as R from "ramda";
import { Link, withRouter } from "react-router";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "Modules/Dashboard";
import { isOpened, isActive } from "Modules/Dashboard/selectors";

import { Div, OpenInNewIcon } from "components/Base";
import CollapsiblePanel from "ui-kit/CollapsiblePanel";

import { noop, withProps } from "utils/General";

const Div4 = withProps({
  fs: 4
})(Div);
const Div3 = withProps({
  fs: 3
})(Div);
const Div2 = withProps({
  fs: 2
})(Div);

const getStyles = ({ isActive = false, level = 0 }) => ({
  box: {
    width: 1,
    style: {
      padding: level <= 0 ? "6px 0" : "4px 0",
      paddingLeft: 8,
      marginRight: 8,
      borderRadius: 3
    },
    display: "row.flex-start.center",
    bg: isActive
      ? "rgba(110, 40, 184, 0.5)"
      : {
          default: "transparent",
          hover: "purple7"
        }
  },
  divider: {
    my: 2,
    mr: 4,
    bb: 1,
    bc: "rgba(242, 241, 244, 0.15)"
  },
  container: {
    pl: 2,
    pb: 1
  },
  text: {
    color: "white",
    fw: isActive || level === 0 ? 3 : 2
  }
});

const tag = {
  0: Div4,
  1: Div3,
  2: Div2
};

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      isOpened: isOpened(state, props),
      isActive: isActive(state, props)
    }),
    bindInstance({
      toggleMenu: actions.toggleMenu,
      updatePeopleType: actions.updatePeopleType
    })
  )
);

const SidebarLink = link => {
  const {
    name,
    to = "",
    contactRecordTypeId = null,
    onClick = noop,
    children,
    level = 0,
    divide = false,
    toggleMenu,
    updatePeopleType,
    isOpened
  } = link;
  const styles = getStyles(link);

  if (divide) {
    return <Div {...styles.divider} />;
  }

  if (children && !name) {
    return (
      <Div py={2} display="column.flex-start.stretch">
        {children}
      </Div>
    );
  }

  const heading = (
    <Div {...styles.box}>
      {React.createElement(tag[level] || tag[2], {
        children: [name],
        ...styles.text
      })}
      {(link.external && (
        <Div pl={2} display="column.center.center">
          <OpenInNewIcon color="white" sizeWFS={2} />
        </Div>
      )) ||
        null}
    </Div>
  );
  if (children && name) {
    return (
      <CollapsiblePanel
        heading={React.cloneElement(heading, {
          ...styles.box,
          bg: "transparent"
        })}
        bg="transparent"
        collapsedDefault
        opened={isOpened}
        onClick={(...args) => {
          if (onClick !== noop) {
            onClick(...args);
          }
          toggleMenu(link.id);
        }}
        arrowColor="rgba(242, 241, 244, 0.3)"
      >
        <Div pb={1} display="column.flex-start.stretch" {...styles.container}>
          {children}
        </Div>
      </CollapsiblePanel>
    );
  }
  const dinamicProps = link.external ? { target: "_blank" } : {};
  return (
    <Link
      to={to}
      onClick={
        contactRecordTypeId
          ? () => updatePeopleType(contactRecordTypeId)
          : onClick
      }
      style={{ marginRight: 14, display: "block" }}
      {...dinamicProps}
    >
      {heading}
    </Link>
  );
};

export default decorate(SidebarLink);
