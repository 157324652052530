import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value/catering_dietary_restrictions";
import { Div } from "components/Base";

import { getMeta } from "ui-kit/Datagrid/utils";

class CateringDietaryRestrictionsCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  getRestrictionName(id) {
    const props = this.props;
    const restrictions = getMeta(
      props,
      ["eventDetails", "module_settings", "catering", "dietary_restrictions"],
      []
    );
    const restriction = R.find(R.propEq("id", id), restrictions);
    return restriction ? restriction.value : "";
  }

  formatValue(props) {
    return getValue(props.value);
  }

  render() {
    const { value } = this.props;

    // ensure we're handling proper data type
    if (R.prop("type", value) !== "catering_dietary_restrictions") {
      return null;
    }

    const formattedValue = this.formatValue(this.props);
    const records =
      formattedValue && formattedValue.records ? formattedValue.records : [];

    return (
      <Div
        display="row.flex-start.center"
        height={35}
        style={{
          userSelect: "none",
          overflow: "hidden"
        }}
      >
        {records.map(record => (
          <Div
            key={record}
            display="row.flex-start.center"
            mr={2}
            style={{
              flexDirection: "row",
              flexBasis: "auto",
              flexShrink: 0,
              borderRadius: 25,
              backgroundColor: "#EBEBEB",
              fontSize: "12px",
              color: "#494949",
              padding: "3px 8px",
              lineHeight: "normal",
              whiteSpace: "nowrap"
            }}
          >
            {this.getRestrictionName(record)}
          </Div>
        ))}
      </Div>
    );
  }
}

export default CateringDietaryRestrictionsCellFormatter;
