/* eslint-disable no-underscore-dangle */

import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "Modules/Dashboard";
import { getters as TableGetters } from "ui-kit/Table/model";

import { eventDetails } from "redux/modules/event/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getGroupUrl } from "Items/Manage/utils";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import getVisibleModules from "components/Event/utils/get-visible-modules";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { STATUS, TABLE_INSTANCE_ID } from "../constants";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { parseComboId } from "utils/General";

const contactTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "Unpaid",
    id: STATUS.UNPAID
  },
  {
    title: "Paid",
    id: STATUS.PAID
  }
];

const accountTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "No Primary Contacts",
    id: STATUS.NO_PRIMARY_CONTACTS
  },
  {
    title: "Unpaid",
    id: STATUS.UNPAID
  },
  {
    title: "Paid",
    id: STATUS.PAID
  }
];

const moduleTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "Pending",
    id: STATUS.PENDING
  },
  {
    title: "Approved",
    id: STATUS.APPROVED
  },
  {
    title: "Denied",
    id: STATUS.DENIED
  }
];

const filteredPeopleTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  }
  /*
  {
    title: "Primary Contacts",
    id: STATUS.PRIMARY_CONTACTS
  },
  {
    title: "Login Users",
    id: STATUS.LOGIN_USERS
  }
  */
];

// pagination
export const getPageSize = R.compose(
  R.prop("pageSize"),
  getters.pagination
);

export const getCurrentPage = R.compose(
  R.prop("page"),
  getters.pagination
);

// filters: fields
export const getFieldFilters = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      moduleId,
      for: "filter"
    });
    return R.compose(
      R.sortBy(f => preferences.sort_by[f.id]),
      R.filter(
        f =>
          !f.settings.allowMultipleSelect &&
          !readOnlyFields.includes(f.id) &&
          preferences.visible_fields.includes(f.id)
      )
    )(fields);
  }
);
export const getFieldFiltersSelectedCount = createSelector(
  getters.preferences,
  R.pathOr(0, ["filters", "filters", "filters", "length"])
);

export const getSelectedFieldFilters = createSelector(
  getFieldFilters,
  getters.preferences,
  (fields, preferences) => {
    const filters = R.pathOr({}, ["filters", "filters"])(preferences);
    if (filters.filters) {
      filters.filters.map(filter => {
        filter.title = R.compose(
          R.prop("name"),
          R.find(R.propEq("id", filter.fieldId))
        )(fields);
        return filter;
      });
    }
    return filters;
  }
);

// filters: bar
const getItemFiltersOn = createSelector(
  state => getters.preferences(state).filters,
  (...args) =>
    R.any(
      R.compose(
        R.not,
        val => (typeof val === "boolean" ? !val : R.isEmpty(val))
      ),
      args
    )
);

export const getFiltersOn = createSelector(
  getItemFiltersOn,
  (...args) => R.any(R.identity, args)
);

export const getPeopleFilterOn = createSelector(
  getters.preferences,
  preferences => Boolean(preferences.contact_record_type_id)
);

export const getPeopleFilterMessage = createSelector(
  getters.preferences,
  getters.peopleRecordTypes,
  (preferences, peopleRecordTypes) => {
    if (preferences.contact_record_type_id === "all") {
      return "Viewing all related people";
    }
    const type = R.find(R.propEq("id", preferences.contact_record_type_id))(
      peopleRecordTypes
    );
    if (type) {
      return `Viewing related people of type ${type.name}`;
    }
    return "Viewing filtered list of people related to groups";
  }
);

// filters: sort by
export const getSortBy = createSelector(
  getters.columns,
  state => getters.preferences(state).sort_by,
  (fields, sortBy) =>
    R.compose(
      R.filter(R.prop("name")),
      R.map(s => ({
        ...s,
        name: R.compose(
          R.prop("name"),
          R.find(R.propEq("id", s.fieldId))
        )(fields)
      }))
    )(sortBy)
);

// tabs
export const getTabs = createSelector(
  getters.tabStats,
  getters.moduleId,
  getters.preferences,
  getters.module,
  (stats, moduleId, preferences, module) => {
    let tabs = preferences.contact_record_type_id
      ? filteredPeopleTabs
      : moduleId === STANDARD_MODULE_IDS.accounts.id
      ? accountTabs
      : moduleId === STANDARD_MODULE_IDS.contacts.id
      ? contactTabs
      : moduleTabs;

    if (!module.has_approval) {
      tabs = tabs.filter(
        t => ![STATUS.PENDING, STATUS.APPROVED, STATUS.DENIED].includes(t.id)
      );
    }

    return tabs.map(tab => ({
      ...tab,
      title: R.isNil(stats[tab.id])
        ? tab.title
        : `${tab.title} (${stats[tab.id]})`
    }));
  }
);

// sidebar: links
export const isActive = (
  _,
  {
    routes = [],
    params = {},
    activeRoutes = [],
    activeParams = {},
    isRouteActive = false
  }
) => {
  if (isRouteActive) {
    return isRouteActive;
  }
  if (R.any(r => R.any(R.equals(r.name), activeRoutes), routes)) {
    if (activeParams) {
      return R.all(
        R.equals(true),
        R.map(([key, val]) => {
          if (val === null) {
            return !R.has(key, params) || R.isNil(R.prop(key, params));
          }
          return params[key] === val;
        }, Object.entries(activeParams))
      );
    }
    return true;
  }
  return false;
};

export const isOpened = (state, props) =>
  R.contains(props.id, getters.openedMenues(state, props));

// sidebar: navigation
// eslint-disable-next-line no-unused-vars
const getManageUrl = createSelector(
  getEventId,
  getters.moduleId,
  getGroupUrl,
  (eventId, moduleId, groupUrl) => (view, base = "/passes", search = "") =>
    `/event/${eventId}/module/${moduleId}${groupUrl}${base}${view}${search}`
);

const getReturnUrl = createSelector(
  R.path(["location", "pathname"]),
  R.path(["location", "search"]),
  (path, search) => `returnTo=${path}${search}`
);

const getEnabledModules = createSelector(
  eventDetails,
  state => {
    return userPermissionProfile(state, getEventId(state), getUserId(state));
  },
  (eventDetails, userPermissionProfile) => {
    const modules = getVisibleModules(
      userPermissionProfile,
      eventDetails.enabled_modules
    );
    return modules;
  }
);

export const getManageLinks = createSelector(
  getEnabledModules,
  state => canUserDo(state, getEventId(state)),
  getReturnUrl,
  getEventId,
  getters.moduleId,
  getters.recordTypeId,
  getters.preferences,
  getters.recordTypes,
  getters.peopleRecordTypes,
  getters.catalogItemsModules,
  getters.module,
  selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME),
  (
    enabledModules,
    canDo,
    returnTo,
    eventId,
    moduleId,
    recordTypeId,
    preferences,
    recordTypes,
    peopleRecordTypes,
    catalogItemsModules,
    thisModule,
    canViewInventoryV2
  ) => {
    let links = [];

    const canManageContacts = canDo(
      `${STANDARD_MODULE_IDS.contacts.id}_update`
    );
    const canManageAccounts = canDo(
      `${STANDARD_MODULE_IDS.accounts.id}_update`
    );
    const canManageModule = canDo(`${moduleId}_manage`);

    if (recordTypeId) {
      const canViewPasses = enabledModules.some(
        m => m.id === STANDARD_MODULE_IDS.credentials.id
      );
      const canViewCatering = enabledModules.some(
        m => m.id === STANDARD_MODULE_IDS.catering.id
      );
      const canViewInventory = enabledModules.some(
        m => m.id === STANDARD_MODULE_IDS.inventory.id
      );
      const canViewBooths = enabledModules.some(
        m => m.id === STANDARD_MODULE_IDS.booths.id
      );

      const enabledModuleIds = enabledModules.map(m => m.id);

      const catalogItemsModulesToUse = R.compose(
        R.sortBy(m => m.name.toLowerCase()),
        R.filter(m => enabledModuleIds.includes(m.id))
      )(catalogItemsModules);

      const catalogItemsModulesIds = catalogItemsModulesToUse.map(m => m.id);

      const otherModules = R.compose(
        R.sortBy(m => m.name.toLowerCase()),
        R.filter(
          m =>
            m.source === "custom" &&
            m.show_in_navigation &&
            !catalogItemsModulesIds.includes(m.id)
        )
      )(enabledModules);

      const itemsFilterProperty =
        moduleId === STANDARD_MODULE_IDS.accounts.id
          ? "groupTypeId"
          : "personTypeId";
      links = [
        {
          id: "people",
          name: "People",
          activeRoutes: [],
          enabled: moduleId === STANDARD_MODULE_IDS.accounts.id,
          links: [
            {
              id: "all",
              name: "All People",
              enabled: true,
              isRouteActive: preferences.contact_record_type_id === "all",
              contactRecordTypeId: "all"
            },
            ...(peopleRecordTypes.length > 1
              ? peopleRecordTypes.map(type => ({
                  id: type.id,
                  name: type.name,
                  enabled: true,
                  isRouteActive: preferences.contact_record_type_id === type.id,
                  contactRecordTypeId: type.id
                }))
              : [])
          ]
        },
        {
          id: "manage",
          name: "Manage",
          activeRoutes: ["ModulePasses", "ModuleCatering"],
          enabled: canViewPasses || canViewCatering || canViewInventory,
          links: [
            {
              id: "passes",
              name: "Passes",
              enabled: canViewPasses,
              to: `/event/${eventId}/passes/manage/all-requests?${itemsFilterProperty}=${recordTypeId}`
            },
            {
              id: "passes",
              name: "Catering",
              enabled: canViewCatering,
              to: `/event/${eventId}/catering/manage/all-requests?${itemsFilterProperty}=${recordTypeId}`
            },
            {
              id: "assetsv2",
              name: "Inventory",
              enabled: canViewInventory && canViewInventoryV2,
              to: `/event/${eventId}/inventory/manage/all-requests?${itemsFilterProperty}=${recordTypeId}`
            },
            {
              id: "booths",
              name: "Booths",
              enabled: canViewBooths,
              to: `/event/${eventId}/booths/manage/all-requests?${itemsFilterProperty}=${recordTypeId}`
            },
            {
              id: "assets",
              name: "Inventory",
              enabled:
                canViewInventory &&
                catalogItemsModules.length &&
                !canViewInventoryV2,
              links: R.map(module => ({
                id: module.id,
                name: module.name,
                to: `/event/${eventId}/module/${module.id}/${
                  moduleId === STANDARD_MODULE_IDS.accounts.id
                    ? "group-filter"
                    : "contact-filter"
                }/${recordTypeId}`
              }))(catalogItemsModules || [])
            },
            {
              id: "dashboardDivider",
              divide: true,
              enabled: otherModules.length
            },
            ...R.map(module => ({
              id: module.id,
              name: module.name,
              enabled: true,
              to: `/event/${eventId}/module/${module.id}/${
                moduleId === STANDARD_MODULE_IDS.accounts.id
                  ? "group-filter"
                  : "contact-filter"
              }/${recordTypeId}`
            }))(otherModules || [])
          ].filter(link => link.enabled)
        },
        {
          id: "settings",
          name: "Settings",
          enabled:
            moduleId === STANDARD_MODULE_IDS.accounts.id
              ? canManageAccounts
              : canManageContacts,
          links: [
            {
              id: "fields",
              name: "Manage Fields",
              enabled: canManageModule,
              to: `/event/${eventId}/settings/module/${moduleId}/types/${recordTypeId}/record-layout?${returnTo}`
            },
            {
              id: "fields",
              name: "Portal Settings",
              enabled: moduleId === STANDARD_MODULE_IDS.accounts.id,
              to: `/event/${eventId}/settings/module/${moduleId}/types/${recordTypeId}?${returnTo}`
            }
          ].filter(link => link.enabled)
        }
      ].filter(link => link.enabled);
    }

    if (
      [
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id
      ].includes(moduleId) &&
      recordTypes &&
      recordTypes.length
    ) {
      links = [
        {
          id: "people",
          name: "People",
          activeRoutes: [],
          enabled: moduleId === STANDARD_MODULE_IDS.accounts.id,
          links: [
            {
              id: "all",
              name: "All People",
              enabled: true,
              isRouteActive: preferences.contact_record_type_id === "all",
              contactRecordTypeId: "all"
            },
            ...(peopleRecordTypes.length > 1
              ? peopleRecordTypes.map(type => ({
                  id: type.id,
                  name: type.name,
                  enabled: true,
                  isRouteActive: preferences.contact_record_type_id === type.id,
                  contactRecordTypeId: type.id
                }))
              : [])
          ]
        },
        {
          id: "workspaces",
          name: "Dashboards",
          activeRoutes: [],
          enabled: true,
          links: recordTypes.map(type => ({
            id: type.id,
            name: type.name,
            enabled: true,
            activeRoutes: [],
            to: `/event/${eventId}/module/${moduleId}/dashboard/type/${type.id}`
          }))
        },
        {
          id: "settings",
          name: "Settings",
          enabled:
            moduleId === STANDARD_MODULE_IDS.accounts.id
              ? canManageAccounts
              : canManageContacts,
          links: [
            {
              id: "fields",
              name: "Manage Fields",
              enabled: canManageModule,
              to: `/event/${eventId}/settings/module/${moduleId}`
            }
          ].filter(link => link.enabled)
        }
      ].filter(
        link => link.enabled && R.gt(R.pathOr(0, ["links", "length"], link), 0)
      );
    }

    if (
      ![
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id
      ].includes(moduleId)
    ) {
      links = [
        {
          id: "settings",
          name: "Settings",
          enabled: canManageModule,
          links: [
            {
              id: "fields",
              name: "Manage Fields",
              enabled: true,
              to: `/event/${eventId}/settings/module/${moduleId}`
            },
            {
              id: "approvers",
              name: "Manage Approvers",
              enabled: thisModule.has_approval,
              to: `/event/${eventId}/settings/module/${moduleId}/approval-workflows`
            }
          ].filter(link => link.enabled)
        }
      ].filter(link => link.enabled);
    }

    return links;
  }
);

export const getToggledRows = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.identity
);

export const getCountOfToggledRows = createSelector(
  getToggledRows,
  R.length
);

export const getFieldsForEditModal = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.field_order[f.id]),
      R.filter(
        f =>
          preferences.visible_fields.includes(f.id) &&
          ![
            "form",
            "document-request",
            "item-group",
            "payment",
            "primary-contacts",
            "email-last-sent",
            "last-login-at",
            "count-of-emails-sent",
            "allocated-passes",
            "allocated-meals",
            "signature"
          ].includes(f.type) &&
          ![
            STANDARD_FIELD_IDS.ACCOUNTS.NAME,
            "synced-to-fuzion",
            "fuzion-exhibitor-id"
          ].includes(f.id) &&
          !readOnlyFields.includes(f.id)
      )
    )(fields);
  }
);

export const getToggledRowsWithName = createSelector(
  getters.rows,
  getToggledRows,
  getters.moduleId,
  (rows, toggledRows, moduleId) =>
    R.compose(
      R.map(row => ({
        id: row.id,
        name:
          moduleId === STANDARD_MODULE_IDS.accounts.id
            ? R.pathOr("(No Name)", [
                "values",
                STANDARD_FIELD_IDS.ACCOUNTS.NAME,
                "value"
              ])(row)
            : "(No Name)"
      })),
      R.filter(row => toggledRows.includes(row.id))
    )(rows)
);

export const getActiveViewId = createSelector(
  getters.preferences,
  R.prop("id")
);

export const getHasViewChanged = createSelector(
  getters.preferences,
  getters.originalPreferences,
  (preferences, originalPreferences) =>
    !R.equals(preferences, originalPreferences)
);

export const getSearchbarPlaceholder = createSelector(
  getters.moduleId,
  moduleId =>
    R.propOr("Search", moduleId)({
      [STANDARD_MODULE_IDS.accounts.id]: "Search by name",
      [STANDARD_MODULE_IDS.contacts.id]: "Search by name or email"
    })
);

export const getGroupedByField = createSelector(
  getters.preferences,
  R.propOr(null, "grouped_by")
);

export const availableFieldsToGroupBy = createSelector(
  getters.columns,
  fields =>
    R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      )
    )(fields)
);

export const selectedGroupByFieldName = createSelector(
  getters.columns,
  getGroupedByField,
  (fields, selectedFieldId) =>
    !R.isEmpty(selectedFieldId)
      ? R.propOr("", "name", R.find(R.propEq("id", selectedFieldId))(fields))
      : ""
);

export const getIsViewingFilteredPeopleType = createSelector(
  getters.preferences,
  preferences => Boolean(preferences.contact_record_type_id)
);

export const getIsApprovalsEnabled = createSelector(
  getters.module,
  R.prop("has_approval")
);

export const getPeopleReportSelectedRows = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.map(id => parseComboId(id)[1])
);

export const getPeopleReportContactsAndAccounts = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.map(id => ({
    accountId: parseComboId(id)[0],
    contactId: parseComboId(id)[1]
  }))
);

export const getContactsForLogin = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.map(id => ({
    contactId: id
  }))
);

export * from "./tableActions";
