import React from "react";
import { connect } from "react-redux";

import { getInvoiceStats, getPaymentStatus } from "Orders/OrderModal/selectors";

import { Div, Text2, Text3, Text5 } from "components/Base";

import { StatusTag } from "utils/status-comps";

import Panel from "ui-kit/Panel";

const decorate = connect(state => ({
  invoice: getInvoiceStats(state),
  paymentStatus: getPaymentStatus(state)
}));

const Invoice = ({ invoice, paymentStatus }) => {
  return (
    <Panel>
      <Div display="column.center.stretch" px={8} py={3}>
        <Div display="row.space-between.center" py={1}>
          <Text2>Requested Total</Text2>
          <Text2>{invoice.requested}</Text2>
        </Div>
        <Div display="row.space-between.center" py={1}>
          <Text3 bold>Approved Order Total</Text3>
          <Text3 bold>{invoice.approved}</Text3>
        </Div>
        {/*
        // @NOTE: Commenting out until implemented
        <Div display="row.space-between.center" py={1}>
          <Text2>Fees</Text2>
          <Text2>+{invoice.fees}</Text2>
        </Div>
        */}
        {invoice.applied && invoice.applied !== "0" ? (
          <Div display="row.space-between.center" py={1}>
            <Text2 bold>Payments Applied</Text2>
            <Text2 bold>-{invoice.applied}</Text2>
          </Div>
        ) : null}
      </Div>
      <Div
        display="row.space-between.center"
        px={8}
        py={3}
        bt={1}
        bc="neutral3"
      >
        <Text5 bold>Amount Due</Text5>
        <Div display="row">
          <StatusTag status={paymentStatus} />
          <Text3 bold ml={2}>
            {invoice.due}
          </Text3>
        </Div>
      </Div>
    </Panel>
  );
};

export default decorate(Invoice);
