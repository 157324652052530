import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import * as R from "ramda";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";

import Field from "Submission/Editor/View/Field";
import { actions } from "Submission/Editor";
import * as SubmissionSelectors from "Submission/Editor/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import { Div } from "components/Base";

const decorate = connect(
  state => ({
    user: UserSelectors.user(state),
    details: SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state),
    form: SubmissionSelectors.form(state)
  }),
  {
    addValue: actions.addValue
  }
);

class OrderDetailsField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldBeingEdited: null
    };
  }

  onChange = ({ id }, value) => {
    if (!isEqual(this.props.submission.values[id], value)) {
      this.props.addValue({
        version: getFormVersion(this.props.form),
        submissionRecordId: this.props.submission.submission_record_id,
        moduleFieldId: id,
        submissionId: this.props.submission.id,
        fieldId: id,
        value,
        userId: this.props.user.id
      });
    }
  };

  closeField = () => {
    this.setState({
      fieldBeingEdited: null
    });
  };

  editField = field => {
    if (!this.state.fieldBeingEdited) {
      this.setState({
        fieldBeingEdited: field.id
      });
    }
  };

  render() {
    const { readOnly, details, submission } = this.props;
    const values = submission.values;
    const fieldsToShow = R.sortBy(R.prop("order"))(
      this.props.field.customer_block_fields
    );

    const fieldsWithHandlers = R.map(f => {
      return {
        ...f.field,
        disabled: this.props.disabled,
        moduleId: f.module_id,
        name: f.alias || f.field.name,
        changeField: value => this.onChange(f.field, value),
        value: values ? values[f.field.id] : undefined
      };
    })(fieldsToShow);

    return (
      <Div width={1} bra={2} bc="gray2" b={1} p={5}>
        <Div fw={3} fs={4} color="gray7" mb={2}>
          {this.props.field.name}
        </Div>
        {fieldsWithHandlers.map(field => {
          return (
            <Field
              key={field.id}
              readOnly={readOnly}
              isEditing={field.id === this.state.fieldBeingEdited}
              eventDetails={details}
              editField={() => this.editField(field)}
              closeField={this.closeField}
              changeField={field.changeField}
              value={field.value}
              values={values}
              fields={fieldsToShow}
              field={field}
              metaData={this.props.getMetaData(
                fieldsToShow,
                submission,
                values,
                field
              )}
            />
          );
        })}
      </Div>
    );
  }
}

export default decorate(OrderDetailsField);
