import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";

import { getters } from "Accounts/PeoplePanels";
import {
  getters as PeopleTypeGetters,
  actions as PeopleTypeActions
} from "Accounts/PeopleTypePanel";
import { actions as PeopleTableActions } from "Accounts/PeopleTable/model";
import {
  getSelectedGroup,
  getAccountId
} from "Accounts/PeoplePanels/selectors";

import css from "./styles.scss";

import PeopleTable from "Accounts/PeopleTable/View";

import { noop } from "utils/General";

const styles = {
  editingWrapper: {
    marginTop: 35,
    marginBottom: 60
  },
  fillingOutWrapper: {
    marginBottom: 0
  }
};

const decorate = R.compose(
  connect(
    (state, props) => ({
      show: PeopleTypeGetters.showTable(state, props),
      selectedGroup: getSelectedGroup(state, props),
      accountId: getAccountId(state, props),
      updateRows: getters.updateRows(state, props)
    }),
    {
      hideTable: () => PeopleTypeActions.setShowTable(""),
      addTableId: PeopleTableActions.addTableId
    }
  ),
  CSSModules(css)
);

const TableView = ({
  show = false,
  updateRows = false,
  field = {},
  selectedGroup = {},
  accountId,
  handlers = {},
  addTableId = noop
}) => {
  useEffect(() => {
    if (show) {
      addTableId(selectedGroup.id);
    }
  }, [show]);

  return (
    show && (
      <div style={styles.editingWrapper}>
        <div
          styleName="zoomWrapper"
          className="react-grid-Lennd-Form-Wrapper show-drag-handle"
        >
          <div styleName="zoomWrapperInner" style={{ width: "100%" }}>
            <div
              className={`react-grid-Lennd-Form react-grid-Lennd-Form_${field.id}`}
              styleName="container"
            >
              <div
                style={{ overflowY: "auto", overflowX: "hidden" }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <PeopleTable
                  group={selectedGroup}
                  accountId={accountId}
                  handlers={handlers}
                  updateRows={updateRows}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default decorate(TableView);
