import React from "react";
import { Div, FontIcon, BigOutlineButton } from "components/Base";
import { SectionOverviewTemplate } from "Passes/SetupCommon";

const View = ({ loading, steps, resources }) => (
  <SectionOverviewTemplate
    width={640}
    steps={steps}
    loading={loading}
    resources={resources}
    title="Passes"
    whatSubText="Collect, approve and manage requests around your passes."
    whatSectionContent={
      <Div
        bg="neutral1"
        bra={1}
        width={640}
        height={300}
        display="row.center.center"
      >
        <FontIcon fs={7} color="neutral3">
          insert_photo
        </FontIcon>
      </Div>
    }
    doSectionContent={
      <Div display="row">
        <Div
          bg="neutral1"
          bra={1}
          width={300}
          height={200}
          mr={4}
          display="row.center.center"
        >
          <FontIcon fs={7} color="neutral3">
            play_arrow
          </FontIcon>
        </Div>
        <Div display="column">
          <Div fw={3} fs={4} mb={1} mt={2} color="gray7">
            Build forms to collect requests
          </Div>
          <Div mb={3}>
            Quickly set up forms with specific pass types to collect requests
          </Div>
          <Div display="row">
            <BigOutlineButton onClick={() => {}}>
              Watch tutorial
            </BigOutlineButton>
          </Div>
        </Div>
      </Div>
    }
  />
);

export default View;
