import { createModule, createHandlers } from "redux-mvc";

import sidebar from "ui-kit/Sidebar";
import snackbar from "ui-kit/Snackbar";
import toasts from "ui-kit/Toasts";
import myApprovals from "Event/MyApprovals";
import data from "App/Data";
import connection from "App/Connection";
import eventLayout from "EventLight/Layout";
import orgLayout from "OrgLight/Layout";
import eventGate from "EventLight/";
import orgGate from "OrgLight/";

const model = createHandlers({
  namespace: "MVCGlobal"
});

const module = createModule(model);

module.plugModule(sidebar);
module.plugModule(snackbar);
module.plugModule(toasts);
module.plugModule(myApprovals);
module.plugModule(data);
module.plugModule(connection);
module.plugModule(eventLayout);
module.plugModule(orgLayout);
module.plugModule(eventGate);
module.plugModule(orgGate);

export default module;
