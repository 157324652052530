import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";
import { getters } from "Forms/WizardModal";
import {
  PAGES,
  INVENTORY_PAGES,
  PASSES_PAGES,
  CUSTOM_PAGES,
  APPLICATIONS_PAGES,
  SCHEDULES_PAGES
} from "Forms/WizardModal/constants";
import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID
} from "utils/item-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { eventDetails } from "redux/modules/event/selectors";

export const getLayoutHeight = createSelector(
  getters.selectedPage,
  selectedPage => {
    if (selectedPage === PAGES.SELECT_METHOD) {
      return 690;
    }
    return 700;
  }
);

export const getStepIndex = createSelector(
  getters.selectedPage,
  getters.selectedInventory,
  getters.selectedPasses,
  getters.selectedCustomModules,
  getters.selectedApplications,
  getters.selectedSchedules,
  (
    selectedPage,
    selectedInventory,
    selectedPasses,
    selectedCustomModules,
    selectedApplications,
    selectedSchedules
  ) => {
    if (selectedPage === PAGES.INVENTORY) {
      if (selectedInventory === INVENTORY_PAGES.SELECT_METHOD) {
        return 0;
      } else if (selectedInventory === INVENTORY_PAGES.INVENTORY_CATEGORIES) {
        return 1;
      } else if (selectedInventory === INVENTORY_PAGES.SELECT_FIELDS) {
        return 2;
      } else if (selectedInventory === INVENTORY_PAGES.REVIEW) {
        return 2;
      }
      return 0;
    } else if (selectedPage === PAGES.PASSES) {
      if (selectedPasses === PASSES_PAGES.SELECT_TYPE) {
        return 0;
      } else if (selectedPasses === PASSES_PAGES.SELECT_ACCOUNTS) {
        return 1;
      } else if (selectedPasses === PASSES_PAGES.SELECT_PASSES) {
        return 2;
      } else if (selectedPasses === PASSES_PAGES.SELECT_FIELDS) {
        return 3;
      } else if (selectedPasses === PASSES_PAGES.REVIEW) {
        return 3;
      }
      return 0;
    } else if (selectedPage === PAGES.CUSTOM) {
      if (selectedCustomModules === CUSTOM_PAGES.SELECT_QUESTIONS) {
        return 1;
      } else if (selectedCustomModules === CUSTOM_PAGES.REVIEW) {
        return 2;
      }
      return 0;
    } else if (selectedPage === PAGES.APPLICATIONS) {
      if (selectedApplications === APPLICATIONS_PAGES.SELECT_METHOD) {
        return 0;
      } else if (selectedApplications === APPLICATIONS_PAGES.SELECT_ACCOUNTS) {
        return 1;
      } else if (selectedApplications === APPLICATIONS_PAGES.SELECT_FIELDS) {
        return 2;
      } else if (selectedApplications === APPLICATIONS_PAGES.SELECT_PASSES) {
        return 3;
      } else if (selectedApplications === APPLICATIONS_PAGES.REVIEW) {
        return 3;
      }
    } else if (selectedPage === PAGES.SCHEDULES) {
      if (selectedSchedules === SCHEDULES_PAGES.SELECT_QUESTIONS) {
        return 1;
      } else if (selectedSchedules === SCHEDULES_PAGES.REVIEW) {
        return 2;
      }
      return 0;
    }
    return 0;
  }
);

export const getFilterCategories = createSelector(
  getters.inventoryCategories,
  getters.selectedCategoriesFilter,
  (inventoryCategories, selectedCategoriesFilter) => {
    if (R.isEmpty(selectedCategoriesFilter)) {
      return inventoryCategories;
    }
    return R.filter(
      field =>
        R.contains(R.toLower(selectedCategoriesFilter), R.toLower(field.name)),
      inventoryCategories
    );
  }
);

export const getSelectedCategories = createSelector(
  getters.inventoryCategories,
  inventoryCategories => {
    return R.compose(
      R.map(category => ({
        ...category,
        fields: R.compose(
          R.sortBy(f => {
            if (f.type === "catalog-item") {
              return 0;
            } else if (f.type === "event-days") {
              return 1;
            }
            return 2;
          }),
          R.filter(f => !["lookup"].includes(f.type))
        )(category.fields)
      })),
      R.filter(R.prop("selected"))
    )(inventoryCategories);
  }
);

export const isAllCategoriesDisabled = createSelector(
  getters.inventoryCategories,
  R.all(R.propEq("selected", false))
);

export const shouldEnableSaveForm = createSelector(
  getSelectedCategories,
  selectedCategories =>
    R.all(
      category =>
        R.gt(
          R.compose(
            R.length,
            R.filter(R.prop("selected")),
            R.propOr([], "fields")
          )(category),
          0
        ),
      selectedCategories
    )
);

export const shouldEnableSavePassesForm = createSelector(() => true);

export const shouldEnableSaveModuleForm = createSelector(() => true);

export const shouldEnableSaveApplicationForm = createSelector(() => true);

export const getInventoryFormsLanguage = createSelector(
  getSelectedCategories,
  getters.formMethod,
  (selectedCategories, method) => {
    const submit = selectedCategories.length > 1 ? "Create Forms" : "Review";
    const header = "Select the questions to ask with each request";
    const subheader = "We will create a separate form for each category below.";
    const onClickCreateForm = selectedCategories.length > 1 ? true : false;

    const language = {
      bulk: {
        submit,
        header,
        subheader,
        onClickCreateForm
      },
      "multi-bulk": {
        submit: "Review",
        header,
        subheader:
          "We will create one form with a grid for each category below.",
        onClickCreateForm: false
      },
      single: {
        submit,
        header,
        subheader,
        onClickCreateForm
      }
    };

    return R.prop(method)(language);
  }
);

export const getItemsForActiveItemType = createSelector(
  getters.itemTypes,
  getters.selectedVariants,
  getters.toggledVariants,
  getters.selectedVariantPrices,
  getters.itemTypeSearch,
  getters.activeItemTypeId,
  (
    itemTypes,
    selectedVariants,
    toggledVariants,
    selectedVariantPrices,
    itemTypeSearch,
    activeItemTypeId
  ) => {
    const hasSearchTerm =
      itemTypeSearch && itemTypeSearch.length ? true : false;
    const lowercaseSearchTerm = hasSearchTerm
      ? itemTypeSearch.toLowerCase()
      : "";

    const itemTypeItemGroups = R.compose(
      R.filter(g => g.items.length),
      groups =>
        hasSearchTerm
          ? R.map(group => ({
              ...group,
              items: group.items.filter(item =>
                item.name.toLowerCase().includes(lowercaseSearchTerm)
              )
            }))(groups)
          : groups,
      R.map(group => {
        const items = R.reduce((list, item) => {
          R.compose(
            R.forEach(variant => {
              const variantId = variant.id;
              list.push({
                id: variantId,
                name: variant.display_name,
                color: item.background_color || "#ccc",
                providerId: variant.provider_id,
                qty:
                  selectedVariants[variantId] >= 0
                    ? selectedVariants[variantId]
                    : 0,
                selected: R.pathOr(false, [activeItemTypeId, variantId])(
                  toggledVariants
                ),
                description: item.description,
                prices: variant.prices,
                selectedPriceId: selectedVariantPrices[variantId]
              });
            }),
            R.sortBy(R.prop("order"))
          )(item.variants);
          return list;
        }, [])(group.items);

        const variantIds = R.map(R.prop("id"))(items);
        return {
          ...group,
          variantIds,
          items
        };
      }),
      R.filter(g => g.type_id === activeItemTypeId)
    )(itemTypes.itemGroups);

    return {
      mode: "quantity",
      enableToggle: true,
      quantityLabel: "Limit",
      itemGroups: itemTypeItemGroups
    };
  }
);

export const getMeals = createSelector(
  getters.itemTypes,
  getters.selectedVariants,
  getters.toggledVariants,
  getters.selectedVariantPrices,
  getters.itemTypeSearch,
  eventDetails,
  (
    itemTypes,
    selectedVariants,
    toggledVariants,
    selectedVariantPrices,
    itemTypeSearch,
    eventDetails
  ) => {
    const hasSearchTerm =
      itemTypeSearch && itemTypeSearch.length ? true : false;
    const lowercaseSearchTerm = hasSearchTerm
      ? itemTypeSearch.toLowerCase()
      : "";

    const selectedCateringDays = R.pathOr(
      [],
      ["module_settings", "catering", "selected_days"]
    )(eventDetails);

    const allMealItems = R.compose(
      R.propOr([], "items"),
      R.head,
      R.filter(g => g.type_id === MEAL_TYPE_ID)
    )(itemTypes.itemGroups);

    const allMealVariants = R.compose(
      R.flatten,
      R.map(i =>
        i.variants.map(v => ({
          ...v,
          item: i
        }))
      )
    )(allMealItems);

    const mealItemGroups = R.compose(
      R.filter(g => g.items.length),
      groups =>
        hasSearchTerm
          ? R.map(group => ({
              ...group,
              items: group.items.filter(item =>
                item.name.toLowerCase().includes(lowercaseSearchTerm)
              )
            }))(groups)
          : groups,
      R.sortBy(R.prop("order")),
      R.map(mealDay => {
        const dayInstance = moment(mealDay);
        const items = R.reduce((list, variant) => {
          const isValidVariant = variant.rules.find(
            r => r.pattern === "is_valid_for" && r.value === mealDay
          );

          if (isValidVariant) {
            const variantId = variant.id;
            list.push({
              id: variantId,
              name: variant.item.name,
              color: variant.item.background_color || "#ccc",
              providerId: variant.provider_id,
              qty:
                selectedVariants[variantId] >= 0
                  ? selectedVariants[variantId]
                  : 0,
              selected: R.pathOr(false, [MEAL_TYPE_ID, variantId])(
                toggledVariants
              ),
              description: variant.item.description,
              prices: variant.prices,
              selectedPriceId: selectedVariantPrices[variantId]
            });
          }

          return list;
        }, [])(allMealVariants);
        const variantIds = R.map(R.prop("id"))(items);

        return {
          id: mealDay,
          name: dayInstance.format("ddd, MMM D"),
          order: dayInstance.format("YYYY-MM-DD"),
          variantIds,
          items
        };
      })
    )(selectedCateringDays);

    return {
      mode: "quantity",
      enableToggle: true,
      quantityLabel: "Limit",
      itemGroups: mealItemGroups
    };
  }
);

export const getItemTypes = createSelector(
  getters.itemTypes,
  getters.activeItemTypeId,
  (itemTypes, activeItemTypeId) => {
    return R.compose(
      R.map(type => ({
        id: type.id,
        name: type.name,
        active: activeItemTypeId === type.id
      })),
      R.prop("types")
    )(itemTypes);
  }
);

export const getItemBlocks = createSelector(
  getters.itemBlocks,
  getters.selectedItemBlocks,
  (itemBlocks, selectedItemBlocks) => {
    return R.compose(
      R.reduce((map, key) => {
        map[key] = R.map(block => ({
          id: block.id,
          typeId: block.item_type_id,
          name: block.name,
          count: block.items.length,
          isDefault: false,
          selected: selectedItemBlocks.includes(block.id)
        }))(itemBlocks[key]);
        return map;
      }, {}),
      R.keys
    )(itemBlocks);
  }
);

export const getActiveTypeItemBlocks = createSelector(
  getItemBlocks,
  getters.activeItemTypeId,
  (itemBlocks, activeItemTypeId) => {
    return R.propOr([], activeItemTypeId)(itemBlocks);
  }
);

export const getItemTypeItemName = createSelector(
  getters.activeItemTypeId,
  activeItemTypeId =>
    R.propOr("Items", activeItemTypeId)({
      [CREDENTIAL_TYPE_ID]: "Passes",
      [MEAL_TYPE_ID]: "Meals",
      [BOOTH_TYPE_ID]: "Booths",
      [SPONSORSHIP_TYPE_ID]: "Sponsorships"
    })
);

export const getSelectedItemBlocks = createSelector(
  getActiveTypeItemBlocks,
  getters.activeItemTypeId,
  getters.toggledVariants,
  getItemTypeItemName,
  (
    activeTypeItemBlocks,
    activeItemTypeId,
    toggledVariants,
    itemTypeItemName
  ) => {
    const countOfSelected = R.compose(
      R.length,
      selected =>
        R.reduce((list, key) => {
          if (R.pathOr(false, [activeItemTypeId, key])(toggledVariants)) {
            list.push(key);
          }
          return list;
        }, [])(R.keys(selected)),
      R.prop(activeItemTypeId)
    )(toggledVariants);

    const selectedItemsBlock = {
      id: "to-create",
      name: `Selected ${itemTypeItemName}`,
      typeId: activeItemTypeId,
      isDefault: true,
      selected: true,
      count: countOfSelected
    };

    const selectedItemBlocks = R.filter(R.prop("selected"))(
      activeTypeItemBlocks
    );

    if (countOfSelected) {
      return [selectedItemsBlock, ...selectedItemBlocks];
    }
    return selectedItemBlocks;
  }
);

export const getUnselectedItemBlocks = createSelector(
  getActiveTypeItemBlocks,
  getters.activeItemTypeId,
  getters.toggledVariants,
  activeTypeItemBlocks => {
    const unselectedItemBlocks = R.filter(b => !b.selected)(
      activeTypeItemBlocks
    );

    return unselectedItemBlocks;
  }
);

export const getItemAccountFields = createSelector(
  getters.itemAccountFields,
  fields => {
    return fields;
  }
);

export const getItemContactFields = createSelector(
  getters.itemContactFields,
  fields => {
    return fields;
  }
);

export const getItemFields = createSelector(
  getItemAccountFields,
  getItemContactFields,
  getters.passesType,
  getters.accountSelectedTypeId,
  getters.contactSelectedTypeId,
  getters.accountTypes,
  getters.contactTypes,
  (
    accountItemFields,
    contactItemFields,
    passesType,
    accountSelectedTypeId,
    contactSelectedTypeId,
    accountTypes,
    contactTypes
  ) => {
    const accountFields = {
      id: STANDARD_MODULE_IDS.accounts.id,
      name: "Company/Group Information",
      viewAllLabel: "View all questions",
      fields: R.filter(R.propEq("enabled", true), accountItemFields),
      dropdown: {
        text: "Type of Company/Group",
        selectedTypeId: accountSelectedTypeId,
        typesList: accountTypes
      }
    };
    const contactFields = {
      id: STANDARD_MODULE_IDS.contacts.id,
      name: "Person Responsible",
      viewAllLabel: "View all questions",
      fields: R.filter(R.propEq("enabled", true), contactItemFields),
      dropdown: {
        text: "Person Type",
        selectedTypeId: contactSelectedTypeId,
        typesList: contactTypes
      }
    };

    if (passesType === "group") {
      return [accountFields, contactFields];
    }

    contactFields.name = "Individual/Attendee Information";

    return [contactFields, accountFields];
  }
);

export const getFilterModules = createSelector(
  getters.moduleFields,
  getters.moduleSelectedFilter,
  (moduleFields, moduleSelectedFilter) => {
    if (R.isEmpty(moduleSelectedFilter)) {
      return moduleFields;
    }
    return R.filter(
      field =>
        R.contains(R.toLower(moduleSelectedFilter), R.toLower(field.name)),
      moduleFields
    );
  }
);

export const getSelectedCustomModule = createSelector(
  getters.moduleFields,
  R.find(R.propEq("selected", true))
);

export const getSelectedSchedule = createSelector(
  getters.scheduleFields,
  getters.scheduleId,
  getters.scheduleFieldIds,
  (scheduleFields, scheduleId, scheduleFieldIds) =>
    R.compose(
      schedule => ({
        ...schedule,
        fields: R.map(
          field => ({
            ...field,
            selected: R.any(({ id }) => id === field.id, scheduleFieldIds),
            required: R.any(
              ({ id, required }) => id === field.id && required === true,
              scheduleFieldIds
            )
          }),
          schedule.fields
        )
      }),
      R.find(R.propEq("id", scheduleId))
    )(scheduleFields)
);

export const isScheduleReviewBtnDisabled = createSelector(
  getters.scheduleFieldIds,
  scheduleFieldIds => R.length(scheduleFieldIds) < 1
);

export const isCustomModuleReviewButtonDisabled = createSelector(
  getters.moduleFields,
  R.compose(
    R.none(R.propEq("selected", true)),
    R.propOr([], "fields"),
    R.find(R.propEq("selected", true))
  )
);

export const getApplicationsModuleId = createSelector(
  getters.collectFieldsGroupName,
  collectFieldsGroupName => {
    if (
      collectFieldsGroupName === "contactFields" ||
      collectFieldsGroupName === "attendeeFields"
    ) {
      return STANDARD_MODULE_IDS.contacts.id;
    } else if (collectFieldsGroupName === "accountFields") {
      return STANDARD_MODULE_IDS.accounts.id;
    }
    return "";
  }
);

export const shouldShowApplicationPasses = createSelector(
  getters.applicationsSelectFields,
  applicationsSelectFields =>
    R.pathOr(false, ["attendeeFields", "enabled"])(applicationsSelectFields)
);

export const getFilteredApplicationsSelectFields = createSelector(
  getters.applicationsSelectFields,
  applicationsSelectFields =>
    R.map(groupField => {
      return {
        ...groupField,
        list: R.filter(field => field.enabled, groupField.list)
      };
    }, applicationsSelectFields)
);

export const getApplicationCollectFields = createSelector(
  getters.collectFieldsList,
  getters.collectFieldsFilter,
  (collectFieldsList, collectFieldsFilter) => {
    if (R.isEmpty(collectFieldsFilter)) {
      return collectFieldsList;
    }
    return R.filter(
      field =>
        R.contains(R.toLower(collectFieldsFilter), R.toLower(field.name)),
      collectFieldsList
    );
  }
);

export const getPassesTitle = createSelector(
  getters.selectedPage,
  selectedPage => {
    if (selectedPage === PAGES.APPLICATIONS) {
      return "What items can attendees request?";
    }
    return "Select the items available for request";
  }
);

export const getApplicationTypesTitle = createSelector(
  getters.requestType,
  getters.selectedPage,
  (requestType, selectedPage) => {
    if (requestType === "individual") {
      return {
        title: "What type of person do you want to collect information for?",
        description:
          selectedPage === PAGES.APPLICATIONS
            ? "Use this application to quickly intake new people for your event while giving you the ability to review everyone that applies."
            : ""
      };
    }
    return {
      title: "What type of group do you want to collect information for?",
      description:
        selectedPage === PAGES.APPLICATIONS
          ? "Use this application to quickly intake new companies/groups for your event while giving you the ability to review everyone that applies."
          : ""
    };
  }
);

export const getFilterScheduleFields = createSelector(
  getters.scheduleFields,
  getters.schedulesFilter,
  (scheduleFields, schedulesFilter) => {
    if (R.isEmpty(schedulesFilter)) {
      return scheduleFields;
    }
    return R.filter(
      field => R.contains(R.toLower(schedulesFilter), R.toLower(field.name)),
      scheduleFields
    );
  }
);

export const getShowIconColor = createSelector(
  getters.selectedPage,
  getters.isblankForm,
  (selectedPage, isblankForm) =>
    isblankForm ||
    (selectedPage !== PAGES.SCHEDULES && selectedPage !== PAGES.CUSTOM)
);

export const getFieldsToShow = createSelector(
  getters.selectedPage,
  getSelectedCustomModule,
  getters.scheduleFieldIds,
  (selectedPage, selectedModule, scheduleFieldIds) => {
    return selectedPage === PAGES.CUSTOM
      ? R.compose(
          R.map(f => ({
            id: f.id,
            required: f.required
          })),
          R.filter(R.prop("selected")),
          R.prop("fields")
        )(selectedModule)
      : scheduleFieldIds;
  }
);
