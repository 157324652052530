import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import autobind from "autobind-decorator";
import { Link } from "react-router";
import Helpers from "utils/Global/Helpers";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import EditNoteModal from "components/Global/CRM/Modals/EditNoteModal";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

@CSSModules(css)
class Note extends Component {
  @autobind
  updateNote(message) {
    const trimmedNote = message.trim();

    if (!trimmedNote.length) {
      return;
    }

    this.props.onUpdate(trimmedNote);
  }

  @autobind
  onMenuSelect(event, val) {
    event.stopPropagation();
    switch (val) {
      case "edit":
        this.props.showModal({
          content: (
            <EditNoteModal done={this.updateNote} note={this.props.note} />
          ),
          wrapper: ModalWrapper
        });
        break;
      default:
    }
  }

  render() {
    const { note, authorUrl, currentUser, disabled, title } = this.props;
    const author =
      note.author_user_id === currentUser.id
        ? "You"
        : `${note.author_user_fname} ${note.author_user_lname}`;
    const menu =
      note.author_user_id === currentUser.id && !disabled ? (
        <IconMenu
          onChange={this.onMenuSelect}
          iconButtonElement={
            <i className={[css.icon, "material-icons"].join(" ")}>more_vert</i>
          }
        >
          <MenuItem value="edit" primaryText="Edit note" />
          <DeleteConfirmation
            onConfirm={this.props.onDelete}
            text="Are you sure you want to delete this note?"
          >
            <MenuItem value="delete" primaryText="Delete note" />
          </DeleteConfirmation>
        </IconMenu>
      ) : (
        ""
      );

    const avatar = note.author_photo_url ? (
      <div
        styleName="notesImage"
        style={{ backgroundImage: `url(${note.author_photo_url})` }}
      />
    ) : (
      <div styleName="notesImage">
        {Helpers.buildUserAcronym(
          note.author_user_fname,
          note.author_user_lname
        )}
      </div>
    );

    return (
      <div styleName="panel">
        <div styleName="notesTop">
          <div style={{ display: "flex", flex: 1 }}>
            <Link to={authorUrl}>{avatar}</Link>
            <div styleName="context">
              <div>
                <Link to={authorUrl}>
                  <span styleName="semiBold">{author}</span>
                </Link>{" "}
                left a note about <span styleName="semiBold">{title}</span>
              </div>
              <div styleName="date">
                {moment(note.created_at).calendar()}{" "}
                {note.event.name && (
                  <span>
                    in <strong>{note.event.name}</strong>
                  </span>
                )}{" "}
              </div>
            </div>
          </div>
          <div>{note.message}</div>
        </div>
        {menu}
      </div>
    );
  }
}

Note.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  note: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    author_user_fname: PropTypes.string,
    author_user_lname: PropTypes.string,
    author_user_id: PropTypes.string,
    author_photo_url: PropTypes.string,
    event: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  authorUrl: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired
};
export default Note;
