import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getDayMap, getSelectedDays, getDateGroupsById } from "../selectors";
import { actions } from "../model";

import { noop } from "utils/General";

import EventDaySelector from "components/Global/EventDaySelector";
import { Div } from "components/Base";

const decorate = connect(
  (state, props) => ({
    eventDetails: getEventDetails(state, props),
    dayMap: getDayMap(state, props),
    selectedDays: getSelectedDays(state, props),
    dateGroups: getDateGroupsById(state, props)
  }),
  {
    // avoid 2nd and 3rd params; it ruins redux mvc
    handleDaysClick: day => actions.daysToggle(day),
    handleGroupSelect: days => actions.groupDaysSelect(days),
    handleGroupDeselect: days => actions.groupDaysDeselect(days),
    initSelectedDays: actions.initSelectedDays
  }
);

const Days = ({
  eventDetails = {},
  dayMap = {},
  dateGroups = {},
  selectedDays = [],
  handleDaysClick = noop,
  handleGroupSelect = noop,
  handleGroupDeselect = noop
}) => (
  <Div p={5}>
    <EventDaySelector
      startDate={eventDetails.date_from}
      endDate={eventDetails.date_to}
      dayGroups={eventDetails.date_groups}
      dayMap={dayMap}
      showCountOfSelectedDays
      countOfSelectedDays={R.length(selectedDays)}
      handleDayClick={handleDaysClick}
      handleGroupSelectAll={groupId =>
        handleGroupSelect(R.pathOr([], [groupId, "days"], dateGroups))
      }
      handleGroupDeselectAll={groupId =>
        handleGroupDeselect(R.pathOr([], [groupId, "days"], dateGroups))
      }
    />
  </Div>
);

export default decorate(Days);
