import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE, SUMMARY_TYPE } from "Inventory/Dashboard/constants";

const iniState = {
  selectedModeId: "dashboard",
  stats: null,
  categoryStats: [],
  categories: [],
  loading: false,
  showAddOrderModal: false,
  plannedValue: 0,
  summaryType: SUMMARY_TYPE.PENDING,
  scrollToCategoryId: null
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    updateCountPlanning: R.identity,
    downloadReport: R.identity,
    refetchData: R.identity,
    updatePlannedQty: R.identity,
    setInitialData: (state, { payload: { data } }) => ({
      stats: R.propOr(null, "stats", data),
      categoryStats: R.propOr([], "category_stats", data),
      categories: R.propOr([], "categories", data)
    })
  },
  namespace: NAMESPACE
});

export default handlers;
