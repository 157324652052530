import React from "react";
import { connect } from "react-redux";
import {
  Div,
  MediumFilledButton,
  PopoverMenu,
  DownFilledIcon
} from "components/Base";
import { eventList } from "Portal/PortalPeople/selectors";

import { getters, actions } from "Portal/PortalPeople/model";
import { PEOPLE_FILTERS } from "Portal/PortalPeople/constants";

const decorate = connect(
  (state, props) => ({
    filterby: getters.filterby(state),
    eventList: eventList(state, props)
  }),
  {
    selectFilter: actions.selectFilter
  }
);

const FilterBy = ({ filterby, eventList, selectFilter }) => (
  <Div pill bg="neutral2" p={1} display="row">
    <MediumFilledButton
      pill
      bg={filterby === PEOPLE_FILTERS.ALL ? "neutral1" : "neutral2"}
      color="gray8"
      onClick={() => selectFilter({ type: PEOPLE_FILTERS.ALL })}
      mr={1}
    >
      All
    </MediumFilledButton>
    <PopoverMenu
      Label={({ onClick }) => (
        <MediumFilledButton
          RightIcon={DownFilledIcon}
          pill
          bg={filterby === PEOPLE_FILTERS.BY_EVENT ? "neutral1" : "neutral2"}
          color="gray8"
          onClick={onClick}
        >
          By Event
        </MediumFilledButton>
      )}
      menuItems={eventList
        .map(event => [
          event.name,
          () => selectFilter({ type: PEOPLE_FILTERS.BY_EVENT, id: event.id })
        ])
        .filter(i => i)}
    />
  </Div>
);

export default decorate(FilterBy);
