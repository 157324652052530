import * as R from "ramda";

import { ORDER_TYPE } from "Orders/ImportModal/constants";

export const iniState = {
  // @NOTE: Will need to figure out a better solution here if we turn on order type
  // enabledCustomFields: [ORDER_TYPE.INDIVIDUAL, ORDER_TYPE.GROUP],
  enabledCustomFields: {},
  selectedCustomFields: [ORDER_TYPE.INDIVIDUAL],
  selectedCustomSections: [],
  activeSelectFieldsSection: "",
  showSelectFieldsModal: false,
  downloadingCustomized: false
};

const toggleCustomField = {
  singleSelect: (
    { selectedCustomFields },
    { payload: id, meta: { section } }
  ) => ({
    selectedCustomFields: R.concat(
      [id],
      R.without(R.map(R.prop("id"), section.fields), selectedCustomFields)
    )
  }),
  default: ({ selectedCustomFields }, { payload: id }) => ({
    selectedCustomFields: R.contains(id, selectedCustomFields)
      ? R.without([id], selectedCustomFields)
      : R.concat([id], selectedCustomFields)
  })
};

export const reducers = {
  toggleCustomSection: ({ selectedCustomSections }, { payload: id }) => ({
    selectedCustomSections: R.contains(id, selectedCustomSections)
      ? R.without([id], selectedCustomSections)
      : R.concat([id], selectedCustomSections)
  }),
  toggleCustomField: (state, action) =>
    R.prop(
      R.pathOr("default", ["meta", "section", "type"], action),
      toggleCustomField
    )(state, action),
  showSelectFieldsModal: (_, { payload: sectionId }) => ({
    showSelectFieldsModal: true,
    activeSelectFieldsSection: sectionId
  }),
  setEnabledCustomFields: (
    { enabledCustomFields, selectedCustomFields, activeSelectFieldsSection },
    { payload: selectedFieldIds }
  ) => {
    return {
      showSelectFieldsModal: false,
      enabledCustomFields: {
        ...enabledCustomFields,
        [activeSelectFieldsSection]: selectedFieldIds
      },
      selectedCustomFields: R.uniq(
        R.concat(selectedFieldIds, selectedCustomFields)
      )
    };
  },
  downloadCustomizedRequest: R.always({ downloadingCustomized: true }),
  downloadCustomizedResponse: R.always({ downloadingCustomized: false })
};
