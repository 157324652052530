import React from "react";
import SelectAll from "components/Global/ReactTable/CellFormatters/SelectAllCell";

export default ({
  onClick,
  selectAll,
  isSelectAllChecked,
  isChecked,
  isFiller
}) => ({
  id: "checkbox",
  visible: true,
  width: 39,
  sortable: false,
  resizable: false,
  Header: () =>
    isFiller ? null : (
      <SelectAll checked={isSelectAllChecked()} onClick={selectAll} />
    ),
  Cell: props =>
    isFiller ? null : (
      <SelectAll
        checked={isChecked(props.original.id)}
        onClick={() => onClick(props.original.id)}
      />
    )
});
