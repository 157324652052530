import React from "react";
import { connect } from "react-redux";

import { getters } from "../index";
import {
  getCountOfToggledRows,
  getFieldsForEditModal,
  getIsViewingMasterSchedule,
  getToggledRows
} from "../selectors";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { withProps } from "utils/General";
import { withRouter } from "react-router";

import {
  TABLE_INSTANCE_ID,
  VIEWPICKER_INSTANCE_ID
} from "Schedules/Schedule/constants";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as ViewPickerActions } from "ui-kit/ViewPicker";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import { Div, EditIcon, MediumFilledButton } from "components/Base";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import CanUserDo from "components/Global/Security/CanUserDo";
import GroupByFields from "./GroupBy";
import OptionsButton from "./OptionsButton";
import FeedButton from "./FeedButton";

const Button = ({ LeftIcon, RightIcon, children, onClick }) => (
  <MediumFilledButton
    className={css.button1}
    color="white"
    hover={false}
    onClick={onClick}
    mr={2}
    LeftIcon={LeftIcon}
    RightIcon={RightIcon}
  >
    {children}
  </MediumFilledButton>
);

const ScheduleToolbar = ({
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  fields,
  references,
  handlers
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="white">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${STANDARD_MODULES.accounts.id}_update`}>
      <Button
        LeftIcon={withProps({
          color: "purple4"
        })(EditIcon)}
        onClick={() =>
          handlers.showBulkEditModal({ toggledRows, fields, references })
        }
      >
        Edit
      </Button>
      {/* @NOTE: hide run repor */}

      <CanUserDo action={`${STANDARD_MODULES.accounts.id}_delete`}>
        <Button
          onClick={() => {
            handlers.showDeleteRecordsModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="white" onClick={() => clearSelectedRows()}>
      Clear selected
    </Div>
  </Div>
);

const decorate = connect(
  (state, props) => ({
    moduleId: getters.moduleId(state),
    countOfToggledRows: getCountOfToggledRows(state),
    toggledRows: getToggledRows(state),
    fields: getFieldsForEditModal(state),
    references: getters.references(state),
    isViewingMasterSchedule: getIsViewingMasterSchedule(state, props)
  }),
  {
    clearSelectedRows: () =>
      TableActions.clearSelectedRows(null, {
        meta: {
          instanceId: TABLE_INSTANCE_ID
        }
      }),
    handleOpen: () =>
      ViewPickerActions.setIsDrawerOpen(true, {
        meta: { instanceId: VIEWPICKER_INSTANCE_ID }
      })
  }
);

const Toolbar = ({
  handlers,
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  fields,
  references,
  isViewingMasterSchedule
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    mb={2}
    style={{ position: "relative", backgroundColor: "#813CD2", flexShrink: 0 }}
  >
    {!countOfToggledRows ? (
      <>
        <GroupByFields mr={3} />

        <Div display="row.flex-start.center">
          <UISearchBar
            variant={VARIANTS.SURFACE}
            flex={1}
            mr={2}
            placeholder="Search"
            size="medium"
            style={{
              maxWidth: 445
            }}
          />

          <CanUserDo
            action={`${
              isViewingMasterSchedule ? STANDARD_MODULES.schedules.id : moduleId
            }_can_manage_data_feeds`}
          >
            <FeedButton />
          </CanUserDo>

          <CanUserDo
            any={[
              `${
                isViewingMasterSchedule
                  ? STANDARD_MODULES.schedules.id
                  : moduleId
              }_manage`,
              `${
                isViewingMasterSchedule
                  ? STANDARD_MODULES.schedules.id
                  : moduleId
              }_export`
            ]}
          >
            <OptionsButton handlers={handlers} />
          </CanUserDo>
        </Div>
      </>
    ) : (
      <ScheduleToolbar
        {...{
          countOfToggledRows,
          toggledRows,
          clearSelectedRows,
          fields,
          references,
          handlers
        }}
      />
    )}
  </Div>
);

export default CSSModules(withRouter(decorate(Toolbar)), css);
