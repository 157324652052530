import React from "react";
import { Div, MenuIcon } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../../model";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";
import { SIDEBAR_INSTANCE_ID } from "../../constants";

import {
  getEventWebsiteUrl,
  getRightSidebarLogoUrl,
  getMobileTitleLine1,
  getMobileTitleLine2,
  getRightSidebarLinks
} from "../../selectors";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";
import Drawer from "@lennd/material-ui/Drawer";

const decorator = R.compose(
  connect(
    (state, props) => ({
      isNavigationOpen: getters.isNavigationOpen(state, props),
      eventWebsiteUrl: getEventWebsiteUrl(state),
      rightSidebarLogoUrl: getRightSidebarLogoUrl(state),
      mobileTitleLine1: getMobileTitleLine1(state),
      mobileTitleLine2: getMobileTitleLine2(state),
      rightSidebarLinks: getRightSidebarLinks(state)
    }),
    {
      openNavigation: () => actions.setIsNavigationOpen(true),
      closeNavigation: () => actions.setIsNavigationOpen(false),
      openNetworkSidebar: () =>
        sliderSidebarActions.setIsDrawerOpen(true, {
          meta: {
            instanceId: SIDEBAR_INSTANCE_ID
          }
        })
    }
  ),
  CSSModules(css)
);

const RightSection = ({
  className,
  openNavigation,
  closeNavigation,
  isNavigationOpen,
  eventWebsiteUrl,
  rightSidebarLogoUrl,
  mobileTitleLine1,
  mobileTitleLine2,
  rightSidebarLinks,
  openNetworkSidebar
}) => {
  const classNames = ["link-section", className].join(" ").trim();

  function openTab(url) {
    window.open(url, "_blank");
  }

  return (
    <Div className={classNames} height={1}>
      <Div className="desktop-navigation">
        {rightSidebarLogoUrl ? (
          <Div
            className="top-item-container"
            onClick={() => openTab(eventWebsiteUrl)}
          >
            <img
              src={rightSidebarLogoUrl}
              height={70}
              alt="Right Sidebar Logo"
            />
          </Div>
        ) : null}

        {rightSidebarLinks.map(link => (
          <Div
            id={link.type === "intercom" ? "live-support" : undefined}
            className={
              link.type === "intercom"
                ? "item-container launch-intercom"
                : "item-container"
            }
            onClick={
              link.type === "network"
                ? openNetworkSidebar
                : link.type === "link"
                ? () => openTab(link.url)
                : undefined
            }
            style={{
              cursor: "pointer"
            }}
          >
            <Div className="item">
              {link.count ? (
                <div className="unseenCount">{link.count}</div>
              ) : null}
              <img
                src={link.imageUrl}
                alt={`${link.label} - Icon`}
                width={link.imageWidth}
                height={link.imageHeight}
                className="item-icon"
                style={{
                  opacity: link.imageOpacity
                }}
              />
              <Div className="item-text" textAlign="center" uppercase>
                {link.label}
              </Div>
            </Div>
          </Div>
        ))}
      </Div>

      <Div
        className="bottom-bar"
        display="row.space-between.stretch"
        width={1}
        height={1}
        style={{
          fontFamily: "'Roboto Condensed', sans-serif",
          fontSize: "14px",
          letterSpacing: "0.4px"
        }}
      >
        <Div
          onClick={() => openTab(eventWebsiteUrl)}
          display="row.flex-start.center"
          style={{
            padding: "9px 13px"
          }}
        >
          {rightSidebarLogoUrl ? (
            <img
              src={rightSidebarLogoUrl}
              alt="Right Sidebar Logo"
              height={50}
            />
          ) : null}
          <Div
            pl={2}
            style={{
              color: "#192957",
              fontFamily: "Roboto Condensed",
              fontSize: "14px",
              fontWeight: "bold",
              letterSpacing: "-0.15px",
              lineHeight: "16px",
              marginLeft: "12px"
            }}
          >
            {mobileTitleLine1 ? <div>{mobileTitleLine1}</div> : null}
            {mobileTitleLine2 ? <div>{mobileTitleLine2}</div> : null}
          </Div>
        </Div>
        <Div
          display="row.flex-end.center"
          onClick={openNavigation}
          bg="#f4f4f4"
          style={{
            borderLeft: "1px solid #ebebeb",
            padding: "0 16px 0 21px"
          }}
        >
          <Div
            style={{
              color: "#323232",
              fontFamily: "Roboto Condensed",
              fontSize: "14px",
              fontWeight: "bold",
              letterSpacing: "-0.15px",
              marginRight: "10px"
            }}
          >
            RESOURCES
          </Div>
          <MenuIcon size={35} color="#323232" />
        </Div>
      </Div>

      <Drawer anchor="bottom" open={isNavigationOpen} onClose={closeNavigation}>
        {rightSidebarLinks.map((link, idx) => (
          <Div
            id={link.type === "intercom" ? "live-support" : undefined}
            className={link.type === "intercom" ? "launch-intercom" : undefined}
            p={4}
            bb={idx === rightSidebarLinks.length - 1 ? 0 : 1}
            bc="#ededed"
            onClick={
              link.type === "network"
                ? () => {
                    closeNavigation();
                    openNetworkSidebar();
                  }
                : link.type === "link"
                ? () => openTab(link.url)
                : undefined
            }
            style={{
              cursor: "pointer"
            }}
          >
            <Div display="row.flex-start.center">
              <img
                style={{
                  marginRight: 5,
                  opacity: link.imageOpacity
                }}
                alt={`${link.label} - Icon`}
                src={link.imageUrl}
                width={link.smallImageWidth}
                height={link.smallImageHeight}
              />
              <Div uppercase>{link.label}</Div>
            </Div>
          </Div>
        ))}
      </Drawer>
    </Div>
  );
};

export default decorator(RightSection);
