import React, { Component } from "react";
import * as R from "ramda";
import Fuse from "fuse.js";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";
import resolveFieldTypeIconName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon-name";
import View from "./View";
import Empty from "./Empty";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const mapIndexed = R.addIndex(R.map);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

const sortByOrder = R.sort((a, b) => a.order - b.order);
const sortVisibleModules = modules => modules.sort((a, b) => a.order - b.order);
const sortHiddenModules = modules =>
  modules.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
const getVisibleModules = modules => modules.filter(m => m.is_visible);
const getHiddenModules = modules => modules.filter(m => !m.is_visible);

class Internal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsSearchTerm: "",
      typesSearchTerm: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    this.fieldsFuse = new Fuse(nextProps.fields, {
      threshold: 0.3,
      keys: ["name", "type"],
      shouldSort: true
    });
  }

  onFieldsSearch = searchTerm =>
    this.setState({ fieldsSearchTerm: searchTerm });

  render() {
    const {
      starredFields,
      fieldGroups,
      relatedModules,
      loading,
      selectedLayoutId,

      showCreateFieldGroupModal,
      showFieldGroupModal,
      showDeleteFieldGroupModal,

      showCreateFieldModal,
      showUpdateFieldModal,
      showDeleteFieldModal,

      showRelatedModuleFieldsModal,
      hideRelatedModule,
      showRelatedModule,
      handleRelatedModulesReorder,

      removeStar,
      addStar,
      addPrimary,
      removePrimary,
      hideField,
      showField,
      handleAboutFieldsReorder,
      handleLayoutFieldsReorder,

      activeLayout
    } = this.props;

    const { fieldsSearchTerm } = this.state;

    if (loading) {
      return <View loading />;
    }

    if (!selectedLayoutId || !activeLayout || !activeLayout.id) {
      return <Empty action={() => {}} text="No layout has been created." />;
    }

    const isDefaultFieldGroup = id =>
      R.propEq("source", "standard")(
        R.find(R.propEq("id", id))(fieldGroups || []) || {}
      );

    const isStarred = id =>
      !R.isNil(
        R.find(R.propEq("field_id", id))(activeLayout.starred_fields || [])
      );

    const isPrimaryField = field =>
      R.pathOr(false, ["is_primary_field"], field);

    const isLocked = id =>
      R.propEq("source", "standard")(
        R.find(R.propEq("id", id))(activeLayout.fields || []) || {}
      );

    const allRelatedModules = mapIndexed(
      relatedModule => ({
        ...relatedModule,
        fieldId: relatedModule.lookup_field.id,
        subname: relatedModule.lookup_field.name,
        onManage: relatedModule.is_visible
          ? () => showRelatedModuleFieldsModal(relatedModule)
          : undefined,
        onRemove: () => hideRelatedModule(relatedModule),
        onAdd: () =>
          showRelatedModule(
            relatedModule,
            getVisibleModules(relatedModules).length
          )
      }),
      relatedModules || []
    );

    const layoutWithHandlers = {
      loading,
      id: activeLayout.id,
      name: activeLayout.name,
      onFieldsSearch: this.onFieldsSearch,
      onCreateFieldGroup: showCreateFieldGroupModal,
      onCreateField: showCreateFieldModal,
      onAboutFieldsReorder: handleAboutFieldsReorder,
      onRelatedModulesReorder: handleRelatedModulesReorder,
      aboutFields: mapIndexed(
        f => ({
          id: f.field_id,
          name: R.pathOr("", ["field", "name"], f),
          locked: isLocked(R.pathOr("", ["field", "id"], f)),
          onRemove: () => removeStar(f.field)
        }),
        sortByOrder(activeLayout.starred_fields || [])
      ),
      fieldGroups: mapIndexed(
        fieldGroup => ({
          id: fieldGroup.id,
          name: fieldGroup.name,
          onEdit: () => showFieldGroupModal(fieldGroup),
          onDelete: () => showDeleteFieldGroupModal(fieldGroup),
          isDefault: isDefaultFieldGroup(fieldGroup.id),
          onReorder: fields =>
            handleLayoutFieldsReorder(fields, fieldGroup, activeLayout),
          fields: R.map(field => {
            const id = field.field.id;
            return {
              id,
              name: R.pathOr("", ["field", "name"], field),
              typeName: resolveFieldTypeName(
                R.pathOr("", ["field", "type"], field)
              ),
              typeIcon: resolveFieldTypeIconName(
                R.pathOr("", ["field", "type"], field)
              ),
              visible: field.field.visible,
              locked: isLocked(id),
              starred: isStarred(id),
              onClickName: () => showUpdateFieldModal(field.field, fieldGroup),
              onClickExpand: () =>
                showUpdateFieldModal(field.field, fieldGroup),
              onEdit: () => showUpdateFieldModal(field.field, fieldGroup),
              onDelete: () => showDeleteFieldModal(field.field),
              onClickStar: () =>
                isStarred(id) ? removeStar(field.field) : addStar(field.field),
              onClickVisible: () =>
                field.field.visible
                  ? hideField(id, fieldGroup.id)
                  : showField(id, fieldGroup.id)
            };
          }, searchWithFuse(fieldsSearchTerm, this.fieldsFuse, fieldGroup.fields, "field_id") || [])
        }),
        activeLayout.field_groups || []
      ),
      relatedModules: {
        all: allRelatedModules,
        visible: sortVisibleModules(getVisibleModules(allRelatedModules)),
        hidden: sortHiddenModules(getHiddenModules(allRelatedModules))
      }
    };

    return <View {...layoutWithHandlers} />;
  }
}

export default Internal;
