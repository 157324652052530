import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getDocumentTemplatesByModuleId: ({ credentials, eventId, moduleId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/documents/templates/event/${eventId}/modules/${moduleId}`,
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  getDocumentTemplates: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/documents/templates/event/${eventId}`,
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  deleteDocumentTemplate: ({ credentials, templateId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/documents/templates/${templateId}`,
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  getModulesByEventId: ({ credentials, eventId, options = {} }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/event/${eventId}${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  postDocumentTemplate: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/documents/templates`,
        data,
        credentials,
        success,
        error
      });
    }),
  putDocumentTemplate: ({ credentials, data, templateId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/documents/templates/${templateId}`,
        data,
        credentials,
        success,
        error
      });
    }),
  getRelatedModules: ({ credentials, moduleId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/related-modules${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  getMergeTags: ({ credentials, eventId, moduleId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/documents/${eventId}/email-merge-tags?moduleId=${moduleId}`,
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
