import * as R from "ramda";
import { createSelector } from "reselect";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import { fields } from "redux/modules/modules/fields/selectors";
import { getters } from "EventLight/Common/Manage";

import { GROUP_BY_FILTER_ID } from "Passes/Common/constants";
import { MANAGE_BY } from "EventLight/Common/Manage/constants";

import { getters as FilterControlsGetters } from "ui-kit/FilterControls";

export const showCheckinForUnpaidOrders = eventId =>
  ["2145", "2146"].includes(eventId);

export const prependNotEmpty = val => arr =>
  arr.length > 0 && !R.isEmpty(val) ? R.prepend(val, arr) : arr;

// Filters selectors

export const readOnlyOrderFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.orders.id
});

export const orderFields = state =>
  fields(state, STANDARD_MODULES.orders.id).filter(
    f => !readOnlyOrderFields.includes(f.id)
  );

export const formatName = (name, id) => {
  if (id && (!name || !name.length)) {
    return "(No Name)";
  }
  return name;
};

export const getStatuses = (issued, pickedUp) => {
  const components = [];
  if (issued > 0) {
    components.push(`${issued} issued`);
  }
  if (pickedUp > 0) {
    components.push(`${pickedUp} picked up`);
  }
  return components;
};

export const handlersSelector = (_, { handlers }) => handlers;

const selectedFilterGroupBy = createSelector(
  (state, props) =>
    FilterControlsGetters.selectedOptions(state, {
      ...props,
      instanceId: GROUP_BY_FILTER_ID
    }),
  R.compose(
    R.head,
    R.keys
  )
);

export const getGroupBy = createSelector(
  getters.view,
  selectedFilterGroupBy,
  (view, filter) => {
    return view || filter || MANAGE_BY.ALL_PASSES;
  }
);

export const getKey = (_, props = {}) => props.instanceId;
