import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call,
  delay
} from "redux-saga/effects";
import * as R from "ramda";
import { apiCall } from "App/Data/sagas";
import Api from "./api";

import { getCredentials } from "redux/modules/user/selectors";
import {
  orgDetails as getOrgDetails,
  orgId as getOrgId
} from "redux/modules/organization/selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import {
  CLASSY_FORM_ID,
  FIELD_IDS,
  ACTIONS,
  INTEGRATION_IDS,
  NEONONE_FORM_ID,
  PARDOT_FORM_ID,
  SALESFORCE_FORM_ID,
  EVENTBRITE_FORM_ID,
  HUBSPOT_FORM_ID
} from "./constants";

import {
  actions as FormActions,
  getters as FormGetters
} from "ui-kit/Form/model";
import { actions as PopoverActions } from "ui-kit/PopoverMenu/model";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const orgDetails = yield select(getOrgDetails);

  return {
    credentials,
    orgId,
    orgDetails
  };
};

const loadIntegrations = function*() {
  try {
    const { orgId } = yield call(getParams);
    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/organizations/${orgId}/integrations`
    });

    yield put(actions.setIntegrationData(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting integration details"
        }
      ])
    );
  }
};

const init = function*({ payload: { query } }) {
  try {
    yield put(actions.setLoading(true));
    const { orgId } = yield call(getParams);

    const { payload: lenndEvents } = yield call(apiCall, {
      method: "get",
      url: `/user/me/events`
    });

    yield put(
      actions.setLenndEvents(R.filter(R.propEq("org_id", orgId))(lenndEvents))
    );

    yield call(loadIntegrations);

    // handle success state for OAuth integrations
    if (query["raisers-edge_auth_status"] === "success") {
      yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.RAISERSEDGE));
    } else if (query["pardot_auth_status"] === "success") {
      yield put(actions.setIsPardotModalOpen(true));
    } else if (query["salesforce_auth_status"] === "success") {
      yield put(actions.setIsSalesforceModalOpen(true));
    } else if (query["eventbrite-fondi_auth_status"] === "success") {
      yield put(actions.setIsEventbriteModalOpen(true));
    } else if (query["hubspot_auth_status"] === "success") {
      yield put(actions.setIsHubspotModalOpen(true));
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting integration details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

// raisers edge
const connectRaisersEdge = function*() {
  try {
    const { orgId } = yield call(getParams);
    const {
      payload: { url }
    } = yield call(apiCall, {
      method: "get",
      url: `/integrations/raisers-edge/org/${orgId}/generate-url`
    });

    window.location = url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

// classy

const onClassyModal = function*({ payload: showModal }) {
  yield put(actions.setError(null));
};

const connectClassy = function*() {
  try {
    const { orgId } = yield call(getParams);
    const fields = yield select(FormGetters.fields, {
      instanceId: CLASSY_FORM_ID
    });

    // build data
    const data = {
      clientId: R.path([FIELD_IDS.CLASSY.CLIENT_ID, "value"])(fields),
      clientSecret: R.path([FIELD_IDS.CLASSY.CLIENT_SECRET, "value"])(fields),
      classyOrgId: R.path([FIELD_IDS.CLASSY.ORG_ID, "value"])(fields)
    };

    // validate
    if (
      !data.clientId ||
      !data.clientId.length ||
      !data.clientSecret ||
      !data.clientSecret.length ||
      !data.classyOrgId ||
      !data.classyOrgId.length
    ) {
      yield put(actions.setError("Please fill out all the fields"));
      return false;
    }

    // call
    yield call(apiCall, {
      method: "post",
      url: `/integrations/classy/org/${orgId}/auth`,
      data
    });

    // confirm
    yield put(actions.setError(null));
    yield put(actions.setIsClassyModalOpen(false));
    yield put(showSnackbar({ message: "Integration Connected" }));
    yield call(loadIntegrations);

    // open campaigns modal
    yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.CLASSY));
  } catch (error) {
    yield put(
      actions.setError(
        error.message
          ? error.message
          : "There was an issue connecting your integration."
      )
    );
  }

  return true;
};

// neon one

const onNeonOneModal = function*({ payload: showModal }) {
  yield put(actions.setError(null));
};

const connectNeonOne = function*() {
  try {
    yield put(actions.setError(null));

    const { orgId } = yield call(getParams);
    const fields = yield select(FormGetters.fields, {
      instanceId: NEONONE_FORM_ID
    });

    // build data
    const data = {
      apiKey: R.path([FIELD_IDS.NEONONE.API_KEY, "value"])(fields),
      neonOrgId: R.path([FIELD_IDS.NEONONE.ORG_ID, "value"])(fields)
    };

    // validate
    if (
      !data.apiKey ||
      !data.apiKey.length ||
      !data.neonOrgId ||
      !data.neonOrgId.length
    ) {
      yield put(actions.setError("Please fill out all the fields"));
      return false;
    }

    // call
    yield call(apiCall, {
      method: "post",
      url: `/integrations/neon-one/org/${orgId}/auth`,
      data
    });

    // confirm
    yield put(actions.setError(null));
    yield put(actions.setIsNeonOneModalOpen(false));
    yield put(showSnackbar({ message: "Integration Connected" }));
    yield call(loadIntegrations);

    // open campaigns modal
    yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.NEONONE));
  } catch (error) {
    yield put(
      actions.setError(
        error.message
          ? error.message
          : "There was an issue connecting your integration."
      )
    );
  }

  return true;
};

// pardot
const onPardotModal = function*({ payload: showModal }) {
  yield put(actions.setError(null));
};

const connectPardot = function*() {
  try {
    const { orgId } = yield call(getParams);
    const {
      payload: { url }
    } = yield call(apiCall, {
      method: "get",
      url: `/integrations/pardot/org/${orgId}/generate-url`
    });

    window.location = url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

const savePardotSettings = function*() {
  try {
    const { orgId } = yield call(getParams);
    const fields = yield select(FormGetters.fields, {
      instanceId: PARDOT_FORM_ID
    });

    // build data
    const data = {
      businessUnitId: R.path([FIELD_IDS.PARDOT.BUSINESS_UNIT_ID, "value"])(
        fields
      ),
      allowDuplicateEmails: Boolean(
        R.path([FIELD_IDS.PARDOT.ALLOW_DUPLICATE_EMAIL, "value"])(fields)
      ),
      overwritePardotData: Boolean(
        R.path([FIELD_IDS.PARDOT.OVERWRITE_PARDOT_DATA, "value"])(fields)
      )
    };

    // validate
    if (!data.businessUnitId || !data.businessUnitId.length) {
      yield put(actions.setError("Please fill out all the fields"));
      return false;
    }

    // call
    yield call(apiCall, {
      method: "put",
      url: `/integrations/pardot/org/${orgId}/auth`,
      data
    });

    // confirm
    yield put(actions.setError(null));
    yield put(actions.setIsPardotModalOpen(false));
    yield put(showSnackbar({ message: "Integration Connected" }));
    yield call(loadIntegrations);

    // open campaigns modal
    yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.PARDOT));
  } catch (error) {
    yield put(
      actions.setError("There was an issue connecting your integration.")
    );

    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

// salesforce
const onSalesforceModal = function*({ payload: showModal }) {
  yield put(actions.setError(null));
};

const connectSalesforce = function*() {
  try {
    const { orgId } = yield call(getParams);
    const {
      payload: { url }
    } = yield call(apiCall, {
      method: "get",
      url: `/integrations/salesforce/org/${orgId}/generate-url`
    });

    window.location = url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

const saveSalesforceSettings = function*() {
  yield put(actions.setIsSalesforceModalOpen(false));

  // open campaigns modal
  yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.SALESFORCE));
};

// eventbrite
const onEventbriteModal = function*({ payload: showModal }) {
  yield put(actions.setError(null));

  if (showModal) {
    const { orgId } = yield call(getParams);
    const { payload: eventbriteOrganizations } = yield call(apiCall, {
      method: "get",
      url: `/integrations/eventbrite/org/${orgId}/organizations`
    });
    yield put(
      actions.setEventbriteOrganizations(
        eventbriteOrganizations.map(o => ({
          id: o.id,
          label: o.name
        }))
      )
    );
  }
};

const connectEventbrite = function*() {
  try {
    const { orgId } = yield call(getParams);
    const {
      payload: { url }
    } = yield call(apiCall, {
      method: "get",
      url: `/integrations/eventbrite/org/${orgId}/generate-url`
    });

    window.location = url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

const saveEventbriteSettings = function*() {
  try {
    const { orgId } = yield call(getParams);
    const fields = yield select(FormGetters.fields, {
      instanceId: EVENTBRITE_FORM_ID
    });

    // build data
    const data = {
      eventbriteOrgId: R.path([
        FIELD_IDS.EVENTBRITE.EVENTBRITE_ORG_ID,
        "value",
        0
      ])(fields)
    };

    // validate
    if (!data.eventbriteOrgId || !data.eventbriteOrgId.length) {
      yield put(actions.setError("Please fill out all the fields"));
      return false;
    }

    // call
    yield call(apiCall, {
      method: "put",
      url: `/integrations/eventbrite/org/${orgId}/auth`,
      data
    });

    // confirm
    yield put(actions.setError(null));
    yield put(actions.setIsEventbriteModalOpen(false));
    yield put(showSnackbar({ message: "Integration Connected" }));
    yield call(loadIntegrations);

    // open campaigns modal
    yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.EVENTBRITE));
  } catch (error) {
    yield put(
      actions.setError("There was an issue connecting your integration.")
    );

    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

// hubspot
const onHubspotModal = function*({ payload: showModal }) {
  yield put(actions.setError(null));
};

const connectHubspot = function*() {
  try {
    const { orgId } = yield call(getParams);
    const {
      payload: { url }
    } = yield call(apiCall, {
      method: "get",
      url: `/integrations/hubspot/org/${orgId}/generate-url`
    });

    window.location = url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

const saveHubspotSettings = function*() {
  try {
    const { orgId } = yield call(getParams);
    const fields = yield select(FormGetters.fields, {
      instanceId: HUBSPOT_FORM_ID
    });

    // build data
    const data = {
      // businessUnitId: R.path([FIELD_IDS.PARDOT.BUSINESS_UNIT_ID, "value"])(
      //   fields
      // ),
      // allowDuplicateEmails: Boolean(
      //   R.path([FIELD_IDS.PARDOT.ALLOW_DUPLICATE_EMAIL, "value"])(fields)
      // ),
      // overwritePardotData: Boolean(
      //   R.path([FIELD_IDS.PARDOT.OVERWRITE_PARDOT_DATA, "value"])(fields)
      // )
    };

    // validate
    // if (!data.businessUnitId || !data.businessUnitId.length) {
    //   yield put(actions.setError("Please fill out all the fields"));
    //   return false;
    // }

    // call
    yield call(apiCall, {
      method: "put",
      url: `/integrations/hubspot/org/${orgId}/auth`,
      data
    });

    // confirm
    yield put(actions.setError(null));
    yield put(actions.setIsHubspotModalOpen(false));
    yield put(showSnackbar({ message: "Integration Connected" }));
    yield call(loadIntegrations);

    // open campaigns modal
    yield put(actions.setIsCampaignsModalOpen(INTEGRATION_IDS.HUBSPOT));
  } catch (error) {
    yield put(
      actions.setError("There was an issue connecting your integration.")
    );

    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while connecting integration"
        }
      ])
    );
  }

  return true;
};

const syncHubspotEvent = function*({ payload: { eventId } }) {
  try {
    const { orgId, credentials } = yield call(getParams);
    yield put(actions.setSyncing(eventId));

    const { payload } = yield call(apiCall, {
      method: "post",
      url: `/integrations/hubspot/org/${orgId}/event/${eventId}/sync`
    });

    yield delay(500);

    let progressResult = yield call(Api.getStatus, credentials, {
      jobId: payload.job_id
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getStatus, credentials, {
        jobId: payload.job_id
      });
    }

    yield put(actions.setSyncing(null));
  } catch (error) {
    yield put(actions.setSyncing(null));
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred syncing integration"
        }
      ])
    );
  }
};

// general
const showEditCampaigns = function*({ payload: { data } }) {
  yield put(actions.setIsCampaignsModalOpen(data.id));
};

const showEditConnectionSettings = function*({ payload: { data } }) {
  const action = R.prop(data.id)({
    [INTEGRATION_IDS.CLASSY]: actions.setIsClassyModalOpen,
    [INTEGRATION_IDS.NEONONE]: actions.setIsNeonOneModalOpen,
    [INTEGRATION_IDS.PARDOT]: actions.setIsPardotModalOpen,
    [INTEGRATION_IDS.SALESFORCE]: actions.setIsSalesforceModalOpen,
    [INTEGRATION_IDS.RAISERSEDGE]: actions.setIsRaisersEdgeModalOpen,
    [INTEGRATION_IDS.EVENTBRITE]: actions.setIsEventbriteModalOpen,
    [INTEGRATION_IDS.HUBSPOT]: actions.setIsHubspotModalOpen
  });

  yield put(action(true));
};

const disconnectIntegration = function*({ payload: { data } }) {
  try {
    const { orgId } = yield call(getParams);

    const url = R.prop(data.id)({
      [INTEGRATION_IDS.CLASSY]: `/integrations/classy/org/${orgId}/auth`,
      [INTEGRATION_IDS.NEONONE]: `/integrations/neon-one/org/${orgId}/auth`,
      [INTEGRATION_IDS.PARDOT]: `/integrations/pardot/org/${orgId}/auth`,
      [INTEGRATION_IDS.SALESFORCE]: `/integrations/salesforce/org/${orgId}/auth`,
      [INTEGRATION_IDS.RAISERSEDGE]: `/integrations/raisers-edge/org/${orgId}/auth`,
      [INTEGRATION_IDS.EVENTBRITE]: `/integrations/eventbrite/org/${orgId}/auth`,
      [INTEGRATION_IDS.HUBSPOT]: `/integrations/hubspot/org/${orgId}/auth`
    });

    yield call(apiCall, {
      method: "delete",
      url
    });

    yield put(showSnackbar({ message: "Integration Disconnected" }));
    yield call(loadIntegrations);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred disconnecting integration"
        }
      ])
    );
  }
};

const loadCampaignMappings = function*({ payload: showModal }) {
  if (!showModal) {
    yield put(
      actions.setIniIntegrationMappings({
        campaigns: [],
        mappings: []
      })
    );
    yield put(actions.setSelectedEventId(null));
    yield put(actions.setSelectedCampaignId(null));
    return false;
  }

  try {
    yield put(actions.setLoadingMappings(true));

    const { orgId } = yield call(getParams);
    const integrationId = yield select(getters.isCampaignsModalOpen);

    // get campaigns
    const campaignsUrl = R.prop(integrationId)({
      [INTEGRATION_IDS.CLASSY]: `/integrations/classy/org/${orgId}/campaigns`,
      [INTEGRATION_IDS.NEONONE]: `/integrations/neon-one/org/${orgId}/events`,
      [INTEGRATION_IDS.PARDOT]: `/integrations/pardot/org/${orgId}/campaigns`,
      [INTEGRATION_IDS.SALESFORCE]: `/integrations/salesforce/org/${orgId}/campaigns`,
      [INTEGRATION_IDS.RAISERSEDGE]: `/integrations/raisers-edge/org/${orgId}/events`,
      [INTEGRATION_IDS.EVENTBRITE]: `/integrations/eventbrite/org/${orgId}/events`,
      [INTEGRATION_IDS.HUBSPOT]: `/integrations/hubspot/org/${orgId}/campaigns`
    });
    const { payload: campaignsPayload } = yield call(apiCall, {
      method: "get",
      url: campaignsUrl
    });

    // get mappings
    const mappingsUrl = R.prop(integrationId)({
      [INTEGRATION_IDS.CLASSY]: `/integrations/classy/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.NEONONE]: `/integrations/neon-one/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.PARDOT]: `/integrations/pardot/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.SALESFORCE]: `/integrations/salesforce/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.RAISERSEDGE]: `/integrations/raisers-edge/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.EVENTBRITE]: `/integrations/eventbrite/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.HUBSPOT]: `/integrations/hubspot/org/${orgId}/campaign-mappings`
    });
    const { payload: mappingsPayload } = yield call(apiCall, {
      method: "get",
      url: mappingsUrl
    });

    yield put(
      actions.setIniIntegrationMappings({
        campaigns: campaignsPayload,
        mappings: mappingsPayload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting settings"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingMappings(false));
  }
  return true;
};

const saveCampaignMappings = function*() {
  try {
    const { orgId } = yield call(getParams);
    const integrationId = yield select(getters.isCampaignsModalOpen);

    const url = R.prop(integrationId)({
      [INTEGRATION_IDS.CLASSY]: `/integrations/classy/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.NEONONE]: `/integrations/neon-one/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.PARDOT]: `/integrations/pardot/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.SALESFORCE]: `/integrations/salesforce/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.RAISERSEDGE]: `/integrations/raisers-edge/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.EVENTBRITE]: `/integrations/eventbrite/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.HUBSPOT]: `/integrations/hubspot/org/${orgId}/campaign-mappings`
    });

    const existingMappings = yield select(getters.mappings);
    const selectedEventId = yield select(getters.selectedEventId);
    const selectedCampaignId = yield select(getters.selectedCampaignId);

    const mappings =
      selectedEventId && selectedCampaignId
        ? {
            ...existingMappings,
            [selectedEventId]: selectedCampaignId
          }
        : existingMappings;

    yield call(apiCall, {
      method: "put",
      url,
      data: {
        mappings
      }
    });

    yield put(actions.setIsCampaignsModalOpen(null));
    yield put(showSnackbar({ message: "Settings Updated" }));
    yield call(loadIntegrations);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving settings"
        }
      ])
    );
  }
};

const removeCampaignMapping = function*({ payload: { eventId, campaignId } }) {
  try {
    const { orgId } = yield call(getParams);
    const integrationId = yield select(getters.isCampaignsModalOpen);

    const url = R.prop(integrationId)({
      [INTEGRATION_IDS.CLASSY]: `/integrations/classy/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.NEONONE]: `/integrations/neon-one/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.PARDOT]: `/integrations/pardot/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.SALESFORCE]: `/integrations/salesforce/org/${orgId}/campaign-mappings`,
      [INTEGRATION_IDS.RAISERSEDGE]: `/integrations/raisers-edge/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.EVENTBRITE]: `/integrations/eventbrite/org/${orgId}/event-mappings`,
      [INTEGRATION_IDS.HUBSPOT]: `/integrations/hubspot/org/${orgId}/campaign-mappings`
    });

    const existingMappings = yield select(getters.mappings);

    const mappings = R.pickBy((cId, eId) => {
      if (`${eId}` === `${eventId}` && `${cId}` === `${campaignId}`) {
        return false;
      }
      return true;
    })(existingMappings);

    yield call(apiCall, {
      method: "put",
      url,
      data: {
        mappings
      }
    });

    yield put(actions.setIsCampaignsModalOpen(null));
    yield put(showSnackbar({ message: "Settings Updated" }));
    yield call(loadIntegrations);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving settings"
        }
      ])
    );
  }
};

const syncIntegration = function*({ payload: { eventId } }) {
  try {
    const { orgId, credentials } = yield call(getParams);
    const integrationId = yield select(getters.isCampaignsModalOpen);
    yield put(actions.setSyncing(eventId));

    const url = R.prop(integrationId)({
      [INTEGRATION_IDS.CLASSY]: `/integrations/classy/org/${orgId}/event/${eventId}/sync`,
      [INTEGRATION_IDS.NEONONE]: `/integrations/neon-one/org/${orgId}/event/${eventId}/sync`,
      [INTEGRATION_IDS.RAISERSEDGE]: `/integrations/raisers-edge/org/${orgId}/event/${eventId}/sync`,
      [INTEGRATION_IDS.EVENTBRITE]: `/integrations/eventbrite/org/${orgId}/event/${eventId}/sync`,
      [INTEGRATION_IDS.HUBSPOT]: `/integrations/hubspot/org/${orgId}/event/${eventId}/sync`
    });

    const { payload } = yield call(apiCall, {
      method: "post",
      url
    });

    yield delay(500);

    let progressResult = yield call(Api.getStatus, credentials, {
      jobId: payload.job_id
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getStatus, credentials, {
        jobId: payload.job_id
      });
    }

    yield put(actions.setSyncing(null));
  } catch (error) {
    yield put(actions.setSyncing(null));
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred syncing integration"
        }
      ])
    );
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchConnectRaisersEdge = function*() {
  yield takeEvery(actions.connectRaisersEdge.type, connectRaisersEdge);
};

const watchConnectClassy = function*() {
  yield takeEvery(actions.connectClassy.type, connectClassy);
};

const watchClassyModal = function*() {
  yield takeEvery(actions.setIsClassyModalOpen.type, onClassyModal);
};

const watchConnectPardot = function*() {
  yield takeEvery(actions.connectPardot.type, connectPardot);
};

const watchPardotModal = function*() {
  yield takeEvery(actions.setIsPardotModalOpen.type, onPardotModal);
};

const watchSavePardotSettings = function*() {
  yield takeEvery(actions.savePardotSettings.type, savePardotSettings);
};

const watchConnectSalesforce = function*() {
  yield takeEvery(actions.connectSalesforce.type, connectSalesforce);
};

const watchSalesforceModal = function*() {
  yield takeEvery(actions.setIsSalesforceModalOpen.type, onSalesforceModal);
};

const watchSaveSalesforceSettings = function*() {
  yield takeEvery(actions.saveSalesforceSettings.type, saveSalesforceSettings);
};

const watchConnectEventbrite = function*() {
  yield takeEvery(actions.connectEventbrite.type, connectEventbrite);
};

const watchEventbriteModal = function*() {
  yield takeEvery(actions.setIsEventbriteModalOpen.type, onEventbriteModal);
};

const watchSaveEventbriteSettings = function*() {
  yield takeEvery(actions.saveEventbriteSettings.type, saveEventbriteSettings);
};

const watchConnectHubspot = function*() {
  yield takeEvery(actions.connectHubspot.type, connectHubspot);
};

const watchHubspotModal = function*() {
  yield takeEvery(actions.setIsHubspotModalOpen.type, onHubspotModal);
};

const watchSaveHubspotSettings = function*() {
  yield takeEvery(actions.saveHubspotSettings.type, saveHubspotSettings);
};

const watchSyncHubspotEvent = function*() {
  yield takeEvery(actions.syncHubspotEvent.type, syncHubspotEvent);
};

const watchConnectNeonOne = function*() {
  yield takeEvery(actions.connectNeonOne.type, connectNeonOne);
};

const watchNeonOneModal = function*() {
  yield takeEvery(actions.setIsNeonOneModalOpen.type, onNeonOneModal);
};

const watchCampaignMappingsModal = function*() {
  yield takeEvery(actions.setIsCampaignsModalOpen.type, loadCampaignMappings);
};

const watchSaveCampaignMappings = function*() {
  yield takeEvery(actions.saveCampaignMappings.type, saveCampaignMappings);
};

const watchRemoveMapping = function*() {
  yield takeEvery(actions.removeMapping.type, removeCampaignMapping);
};

const watchSyncIntegration = function*() {
  yield takeEvery(actions.syncIntegration.type, syncIntegration);
};

const watchActions = function*() {
  for (;;) {
    const action = yield take(PopoverActions.executeAction.type);

    const delegate = R.prop(action.payload.actionId, {
      [ACTIONS.DELETE]: disconnectIntegration,
      [ACTIONS.EDIT_CONNECTION_SETTINGS]: showEditConnectionSettings,
      [ACTIONS.EDIT_CAMPAIGNS]: showEditCampaigns
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchActions),
    fork(watchCampaignMappingsModal),
    fork(watchSaveCampaignMappings),
    fork(watchRemoveMapping),
    fork(watchSyncIntegration),
    fork(watchConnectRaisersEdge),
    fork(watchConnectClassy),
    fork(watchClassyModal),
    fork(watchConnectPardot),
    fork(watchPardotModal),
    fork(watchSavePardotSettings),

    fork(watchConnectSalesforce),
    fork(watchSalesforceModal),
    fork(watchSaveSalesforceSettings),

    fork(watchConnectEventbrite),
    fork(watchEventbriteModal),
    fork(watchSaveEventbriteSettings),

    fork(watchConnectHubspot),
    fork(watchHubspotModal),
    fork(watchSaveHubspotSettings),
    fork(watchSyncHubspotEvent),

    fork(watchConnectNeonOne),
    fork(watchNeonOneModal)
  ]);
};

export default rootSaga;
