import { withProps } from "utils/General";

import DoubleSelect from "ui-kit/DoubleSelect/View";

import { CONFIRMATIONS } from "../constants";
import { STATUS } from "EventLight/Common/Manage/constants";

const options = [
  {
    name: "Confirmation Not Sent",
    id: CONFIRMATIONS.NOT_SENT,
    options: [
      { id: STATUS.APPROVED, name: "Approved" },
      { id: STATUS.DENIED, name: "Denied" }
    ]
  },
  {
    name: "Confirmation Sent",
    id: CONFIRMATIONS.SENT,
    options: []
  },
  {
    name: "Unpaid Orders",
    id: CONFIRMATIONS.UNPAID,
    options: [
      { id: STATUS.SENT, name: "Invoice Sent" },
      { id: STATUS.NOT_SENT, name: "Invoice Not Sent" }
    ]
  },
  {
    name: "Paid Orders",
    id: CONFIRMATIONS.PAID,
    options: []
  }
];

const iniSelected = { [CONFIRMATIONS.NOT_SENT]: [] };

const decorate = withProps({
  options,
  iniSelected
});

export default decorate(DoubleSelect);
