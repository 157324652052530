import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NavBar from "./NavBar";

import * as PortalFormsActions from "redux/modules/portal/forms/actions";
import * as PortalFormsSelectors from "redux/modules/portal/forms/selectors";
import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";
import { getPortalUser } from "redux/modules/portal/user/actions";

function mapStateToProps(state) {
  return {
    forms: PortalFormsSelectors.forms(state),
    isFetching: PortalFormsSelectors.isFetching(state),
    isSaving: SubmissionSelectors.isSaving(state),
    portalUser: portalUser(state),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPortalUser,
      ...PortalFormsActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
