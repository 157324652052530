import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/stripe-connect/auth/standard-url`,
        credentials,
        data,
        success: result => {
          resolve(result.url);
        },
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
