const keyMirror = require("keymirror");

module.exports = {
  ActionTypes: keyMirror({
    ADD_PHOTOS: null,
    RECEIVE_PHOTOS: null,

    RECEIVE_EVENT_DETAILS: null,
    UPDATE_EVENT_STATUS: null,
    UPDATE_EVENT_NAME: null,
    UPDATE_EVENT_DATE: null,
    UPDATE_EVENT_COVER_PHOTO: null,

    RECEIVE_SETS: null,
    UPDATE_SELECTED_SET: null,
    UPDATE_DEFAULT_SET_COUNT: null,
    ADD_SET: null,
    DELETE_SET: null,
    UPDATE_SET: null,
    COPY_TO_SET: null,
    REMOVE_FROM_SET: null,

    RECEIVE_FREQUENT_TEAMMATES: null,
    RECEIVE_EVENT_TEAMMATES: null,
    RECEIVE_EVENT_ROLES: null,
    UPDATE_VENDOR_ROLES: null,
    ADD_VENDOR: null,
    REMOVE_VENDOR: null,
    CREATE_VENDOR: null,
    INVITE_COUPLE: null,
    RECEIVE_COUPLE_INVITES: null,

    ADD_LOG_ITEM: null,
    RECEIVE_LATEST_LOG_ITEM: null,

    RECEIVE_TAGS: null,
    RECEIVE_SUGGESTED_TAGS: null,
    ADD_TAG: null,
    REMOVE_TAG: null,

    RECEIVE_FEATURES: null,
    ADD_FEATURE: null,
    REMOVE_FEATURE: null,

    RECEIVE_VIDEOS: null,
    ADD_VIDEO: null,
    REMOVE_VIDEO: null
  }),

  PayloadSources: keyMirror({
    SERVER_ACTION: null,
    VIEW_ACTION: null
  })
};
