import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import {
  Div,
  Span,
  MediumOutlineButton,
  Popover,
  BigTextBox,
  ErrorIcon,
  Text2,
  MediumFilledButton,
  UpArrowIcon,
  DownArrowIcon
} from "components/Base";
import { actions, getters } from "EventLight/Integrations/Fuzion";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    isActivated: getters.isActivated(state),
    createdBy: getters.createdBy(state),
    createdOn: getters.createdOn(state),
    isSyncingToFuzion: getters.isSyncingToFuzion(state),
    isSyncingFromFuzion: getters.isSyncingFromFuzion(state),
    lastSyncedFromFuzionAt: getters.lastSyncedFromFuzionAt(state),
    lastSyncedToFuzionAt: getters.lastSyncedToFuzionAt(state),
    enableSyncingFromFuzion: getters.enableSyncingFromFuzion(state),
    hasMappings: getters.hasMappings(state)
  }),
  {
    showConnectModal: () => actions.setShowConnectModal(true),
    showMappingModal: () => actions.setShowMappingModal(true),
    deleteConnection: actions.deleteConnection,
    syncFromFuzion: actions.syncFromFuzion,
    syncToFuzion: actions.syncToFuzion
  }
);

const SectionTitle = ({ children }) => (
  <Div fw={3} fs={4} color="gray7" mb={3} bb={1} bc="neutral2" pb={2}>
    {children}
  </Div>
);

const SectionBody = ({ children }) => (
  <Div
    style={{
      marginBottom: 50
    }}
  >
    {children}
  </Div>
);

const Body = ({
  loading,
  isActivated = false,
  deleteConnection,
  createdBy,
  createdOn,
  showConnectModal,
  showMappingModal,
  hasMappings,
  isSyncingToFuzion,
  isSyncingFromFuzion,
  syncFromFuzion,
  syncToFuzion,
  lastSyncedFromFuzionAt,
  lastSyncedToFuzionAt,
  handlers,
  enableSyncingFromFuzion
}) => {
  return (
    <Div display="row.flex-start">
      {loading ? (
        <Loading />
      ) : (
        <Div px={8} maxWidth={550}>
          <SectionTitle>Fuzion Settings</SectionTitle>
          <SectionBody>
            <Div>
              <a
                href="https://fuzion.freeman.com/"
                target="_blank"
                style={{
                  textDecoration: "underline"
                }}
              >
                Fuzion by Freeman
              </a>{" "}
              is a data integration platform that equips you to easily connect
              different event technologies and deliver seamless, personalized
              attendee experiences.
              <br />
              <br />
              Leverage Lennd and Fuzion to keep your Exhibitors' details up to
              date. Collect documents, digital assets and more through Lennd and
              sync the data back to your other systems.
            </Div>

            {loading ? (
              <div />
            ) : isActivated ? (
              <Div py={5}>
                Your event was connected by <Span fw={3}>{createdBy}</Span> on{" "}
                <Span fw={3}>{createdOn}</Span>
                <Div display="row.flex-start.center" my={4}>
                  <MediumFilledButton
                    bg="orange5"
                    color="white"
                    onClick={showMappingModal}
                    mr={3}
                  >
                    Manage Integration
                  </MediumFilledButton>

                  <Popover
                    Label={({ onClick }) => (
                      <MediumOutlineButton
                        bc="danger5"
                        color="danger5"
                        onClick={onClick}
                      >
                        Disconnect Fuzion
                      </MediumOutlineButton>
                    )}
                    labelProps={{ buttonTitle: "Click Me" }}
                    wrapperProps={{ display: "row.flex-start.flex-start" }}
                  >
                    {({ closePopover }) => (
                      <Div px={5} py={3} width={300}>
                        <BigTextBox color="danger5" LeftIcon={ErrorIcon}>
                          Warning
                        </BigTextBox>
                        <Text2 px={1} pb={4}>
                          Disconnecting Fuzion will cause your data to stop
                          syncing between Fuzion and Lennd
                        </Text2>
                        <Div display="row.flex-start.center">
                          <MediumFilledButton
                            uppercase
                            onClick={R.compose(
                              closePopover,
                              deleteConnection
                            )}
                            bg="danger5"
                            mr={2}
                          >
                            Confirm
                          </MediumFilledButton>
                          <MediumOutlineButton uppercase onClick={closePopover}>
                            Cancel
                          </MediumOutlineButton>
                        </Div>
                      </Div>
                    )}
                  </Popover>
                </Div>
                {hasMappings ? (
                  <Div display="column" mt={8} mb={4}>
                    <SectionTitle>Sync Data</SectionTitle>

                    <Div mb={3}>
                      Lennd and Fuzion use webhooks to keep shared records in
                      sync automatically. However, you can use the controls
                      below to force full syncs in either direction.
                    </Div>

                    <Div display="row.flex-start.center">
                      {enableSyncingFromFuzion ? (
                        <Div display="column" mr={3}>
                          <MediumOutlineButton
                            RightIcon={DownArrowIcon}
                            onClick={
                              isSyncingToFuzion || isSyncingFromFuzion
                                ? undefined
                                : syncFromFuzion
                            }
                          >
                            {isSyncingFromFuzion
                              ? "Syncing..."
                              : "Sync from Fuzion to Lennd"}
                          </MediumOutlineButton>
                          <Div fs={1} mt={1}>
                            {lastSyncedFromFuzionAt}
                          </Div>
                        </Div>
                      ) : null}

                      <Div display="column">
                        <MediumOutlineButton
                          RightIcon={UpArrowIcon}
                          onClick={
                            isSyncingToFuzion || isSyncingFromFuzion
                              ? undefined
                              : syncToFuzion
                          }
                        >
                          {isSyncingToFuzion
                            ? "Syncing..."
                            : "Sync from Lennd to Fuzion"}
                        </MediumOutlineButton>
                        <Div fs={1} mt={1}>
                          {lastSyncedToFuzionAt}
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                ) : null}
              </Div>
            ) : (
              <div>
                <Div py={5}>
                  To connect your Fuzion event, click on the button below.
                </Div>
                <Div>
                  <MediumFilledButton
                    bg="orange5"
                    color="white"
                    onClick={showConnectModal}
                    mr={3}
                  >
                    Connect Fuzion Event
                  </MediumFilledButton>
                </Div>
              </div>
            )}
          </SectionBody>
        </Div>
      )}
    </Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(Body);
