import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ContactBadge from "../ContactBadge";
import AccountBadge from "../AccountBadge";
import { Div, FontIcon } from "components/Base";
import { addS } from "utils/General";

@CSSModules(css)
class Navbar extends Component {
  componentDidUpdate() {
    if (
      !this.props.isOpenSubmission &&
      !("id" in this.props.portalUser) &&
      this.props.portalId &&
      this.props.user &&
      this.props.user.id
    ) {
      this.props.getPortalUser({
        userId: this.props.user.id,
        eventId: this.props.portalId
      });
    }
  }

  renderBadge = () => {
    const { collaborator, portalUser } = this.props;

    // @NOTE: this allows a collaborator to be passed in to override the name in the nav bar
    // handle: collaborator as an account
    if (get(collaborator, "account_id") && portalUser.accounts) {
      // ensure we have an account. an account could not exist if viewing someone else's submission and you don't
      // belong to the same account that they used to fill it out as
      const account = portalUser.accounts.find(
        a => a.id === collaborator.account_id
      );
      if (account) {
        return (
          <AccountBadge
            {...portalUser.accounts.find(a => a.id === collaborator.account_id)}
          />
        );
      }
    }

    // handle: collaborator as a user
    if (get(collaborator, "user_id")) {
      return <ContactBadge user={this.props.user} />;
    }

    // handle: active view of portal
    if (
      "active_view" in portalUser &&
      portalUser.active_view.type === "account" &&
      portalUser.accounts.some(a => a.id === portalUser.active_view.id)
    ) {
      return (
        <AccountBadge
          {...portalUser.accounts.find(a => a.id === portalUser.active_view.id)}
        />
      );
    }
    return <ContactBadge user={this.props.user} />;
  };

  render() {
    const {
      portalId,
      portalSlug,
      eventName,
      isSaving,
      showSaveState,
      isOpenSubmission,
      isOrderFormType,
      totalQtyOfCartItems
    } = this.props;

    // is open submission
    if (isOpenSubmission) {
      return (
        <div
          styleName={
            isOrderFormType ? "containerWithCart" : "openSubmissionContainer"
          }
        >
          <div styleName="navLeft">
            {isOrderFormType ? (
              <Div display="row.flex-start.center" ml={3} className={css.cart}>
                <FontIcon style={{ color: "rgba(255,255,255,.64" }}>
                  shopping_cart
                </FontIcon>
                <Div color="white" fs={2} ml={1}>
                  {totalQtyOfCartItems} item{addS(totalQtyOfCartItems)}
                </Div>
              </Div>
            ) : null}
            <div styleName="eventTitleHideSmall">{eventName}</div>
          </div>
          <div styleName="navRight">
            {showSaveState ? (
              isSaving ? (
                <span key="saving" styleName="saveStatus">
                  <i styleName="saveIconSpin" className="material-icons">
                    cached
                  </i>
                  Saving...
                </span>
              ) : (
                <span key="saved" styleName="saveStatus">
                  <i styleName="saveIcon" className="material-icons">
                    check
                  </i>
                  All changes saved
                </span>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    // is not open submission
    return (
      <div styleName="container">
        <div styleName="navLeft">
          <Link
            to={`/portals/${portalSlug}/${portalId}`}
            styleName="iconWrapper"
          >
            <i className="material-icons" styleName="icon">
              home
            </i>
          </Link>
          <div styleName="eventTitleHideSmall">{eventName}</div>
        </div>
        <div styleName="navRight">
          {isOrderFormType ? (
            <Div display="row.flex-start.center" className={css.cart}>
              <FontIcon style={{ color: "rgba(255,255,255,.64" }}>
                shopping_cart
              </FontIcon>
              <Div color="white" fs={2} ml={1}>
                {totalQtyOfCartItems} item{addS(totalQtyOfCartItems)}
              </Div>
            </Div>
          ) : null}
          {showSaveState ? (
            isSaving ? (
              <span key="saving" styleName="saveStatusHideSmall">
                <i styleName="saveIconSpin" className="material-icons">
                  cached
                </i>
                Saving...
              </span>
            ) : (
              <span key="saved" styleName="saveStatusHideSmall">
                <i styleName="saveIcon" className="material-icons">
                  check
                </i>
                All changes saved
              </span>
            )
          ) : (
            ""
          )}
          {isOrderFormType ? (
            <Div className={css.badgeWithOrder}>{this.renderBadge()}</Div>
          ) : (
            this.renderBadge()
          )}
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  eventName: PropTypes.string.isRequired,
  getPortalUser: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  portalId: PropTypes.string.isRequired,
  portalSlug: PropTypes.string.isRequired,
  portalUser: PropTypes.shape({
    accounts: PropTypes.array,
    active_view: PropTypes.shape({
      id: PropTypes.string
    }),
    id: PropTypes.string
  }).isRequired,
  showSaveState: PropTypes.bool,
  user: PropTypes.object.isRequired,
  collaborator: PropTypes.object
};

export default Navbar;
