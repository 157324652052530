import PropTypes from "prop-types";
import React, { Component } from "react";
import Editor from "../../Editors/DateEditor";

export default class DateEditor extends Component {
  render() {
    return (
      <Editor
        {...this.props}
        displayType={this.props.column.settings.displayType}
      />
    );
  }
}

DateEditor.propTypes = {
  column: PropTypes.object.isRequired
};
