import PropTypes from "prop-types";
import React, { Component } from "react";
import FormGridModal from "components/Event/FormsV2/Modals/FormGridModal";
import Dialog from "components/Global/Modals/Dialog";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import * as FieldActions from "redux/modules/formsV2/form/fields/actions.js";
import {
  ORDER_ITEMS_BLOCK_TYPE,
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FieldOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    };
  }

  onClickSettings = () => {
    this.setState({ open: false });
    if (this.props.field.type === "subform") {
      this.props.showModal({
        content: <FormGridModal mode="edit" field={{ ...this.props.field }} />
      });
    } else if (this.props.field.type === ORDER_ITEMS_BLOCK_TYPE) {
      this.props.showEditItemBlockModal(this.props.field);
    } else {
      this.props.showEditQuestionModal(this.props.field);
    }
  };

  onSelectField = () => {
    this.setState({ open: false });
    this.props.dispatch(FieldActions.selectFormField(this.props.field.id));
  };

  onClickDelete = () => {
    this.setState({ open: false });
    this.props.showModal({
      content: (
        <Dialog
          onConfirm={() => this.props.deleteField(this.props.field.id)}
          confirmLabel="Continue"
          title="Are you sure?"
          message={
            <div>
              This will only remove the field from this form and will not delete
              any data associated with the field.
            </div>
          }
        />
      )
    });
  };

  handleClick = event => {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  handleMarkAsRequired = () => {
    this.setState({ open: false });
    this.props.dispatch(
      FieldActions.updateField({
        fieldId: this.props.field.id,
        isRequired: !this.props.field.is_required
      })
    );
  };

  handleMarkAsReadonly = () => {
    this.setState({ open: false });
    this.props.dispatch(
      FieldActions.updateField({
        fieldId: this.props.field.id,
        isRequired: false,
        isReadonly: !this.props.field.is_readonly
      })
    );
  };

  onSelectItemBlock = () => {
    this.props.showEditItemBlockModal(this.props.field);
    this.handleRequestClose();
  };

  render() {
    const { connectDragSource } = this.props;
    const menuItems = [];
    if (
      ![
        "image",
        "separator",
        "header",
        // ORDER_ITEMS_BLOCK_TYPE,
        ORDER_CUSTOMER_BLOCK_TYPE,
        ORDER_FULFILLMENT_BLOCK_TYPE,
        ORDER_DETAILS_BLOCK_TYPE
      ].includes(this.props.field.type)
    ) {
      if (
        ![
          "subform",
          "people",
          // ORDER_ITEMS_BLOCK_TYPE,
          ORDER_CUSTOMER_BLOCK_TYPE,
          ORDER_FULFILLMENT_BLOCK_TYPE,
          ORDER_DETAILS_BLOCK_TYPE
        ].includes(this.props.field.type)
      ) {
        menuItems.push(
          <MenuItem
            primaryText={
              this.props.field.is_required
                ? "Mark as optional"
                : "Mark as required"
            }
            onClick={this.handleMarkAsRequired}
          />,
          ![ORDER_ITEMS_BLOCK_TYPE].includes(this.props.field.type) ? (
            <MenuItem
              primaryText={
                this.props.field.is_readonly
                  ? "Make field editable"
                  : "Make field read-only"
              }
              onClick={this.handleMarkAsReadonly}
            />
          ) : null,
          <MenuItem primaryText="Edit" onClick={this.onClickSettings} />
        );
      }
      /*
      // @NOTE: Removing until connected
      menuItems.push(
        <MenuItem primaryText="Edit" onClick={this.onClickSettings} />
      );
      */
    }
    /*
    // @NOTE: Removing until connected
    } else if (ORDER_ITEMS_BLOCK_TYPE === this.props.field.type) {
      menuItems.push(
        <MenuItem primaryText="Edit" onClick={this.onSelectItemBlock} />
      );
    } else if (
      [ORDER_CUSTOMER_BLOCK_TYPE, ORDER_FULFILLMENT_BLOCK_TYPE].includes(
        this.props.field.type
      )
    ) {
      menuItems.push(
        <MenuItem primaryText="Edit" onClick={this.onSelectField} />
      );
    }
    */

    menuItems.push(
      <MenuItem primaryText="Remove" onClick={this.onClickDelete} />
    );

    return (
      <div styleName="options">
        <i
          className="material-icons"
          styleName="icon"
          role="button"
          tabIndex="0"
          onClick={this.handleClick}
        >
          more_horiz
        </i>
        <Popover
          open={this.state.open}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <Menu>{menuItems.filter(i => i)}</Menu>
        </Popover>
        {connectDragSource(
          <i className="material-icons" styleName="icon">
            drag_handle
          </i>
        )}
      </div>
    );
  }
}

FieldOptions.propTypes = {
  field: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  showEditQuestionModal: PropTypes.func,
  showModal: PropTypes.func.isRequired
};

export default FieldOptions;
