import { get } from "lodash";

export const isFetching = (state, eventId, moduleId = "all") =>
  get(
    state,
    ["inventoryV2", "reports", "needsOverview", "fetching", eventId, moduleId],
    false
  );

export const needsOverviewReport = (state, eventId, moduleId = "all") =>
  get(
    state,
    ["inventoryV2", "reports", "needsOverview", "report", eventId, moduleId],
    []
  );
