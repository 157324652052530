import PropTypes from "prop-types";
import React, { Component } from "react";
import EditorBase from "./TableConnector";
import DependencyEditor from "components/Global/Editors/DependencyEditor";

export default class DependencyEditorEditor extends EditorBase {
  render() {
    return (
      <DependencyEditor
        {...this.props}
        style={{
          width: "100%",
          height: 35
        }}
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}
