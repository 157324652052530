import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Schedules/TemplatesPicker/constants";

export const model = createHandlers({
  iniState: {
    documents: [],
    selected: [],
    showGenerateTemplateModal: false
  },
  reducers: {
    init: R.identity,
    printCurrentView: R.identity,
    manageTemplates: R.identity,
    generateTemplate: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
