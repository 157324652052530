import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  creating: false,
  selected: [],
  templates: [],
  selectedTemplate: null,
  moduleId: null,
  showSaveAndSend: true,
  generateDetailsErrors: [],
  showDetailsErrorsModal: false
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    createDocuments: R.identity,
    setInitialData: (
      _,
      { payload: { selected, templates, showSaveAndSend, moduleId } }
    ) => ({
      selected,
      templates,
      loading: false,
      showSaveAndSend,
      moduleId
    })
  },
  namespace: NAMESPACE
});

export default handlers;
