import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../index";
import UploadDocumentTemplateModal from "Modules/UploadDocumentTemplateModal/View";

const decorate = connect(
  state => ({
    showUploadTemplateModal: getters.showUploadTemplateModal(state),
    template: getters.template(state),
    templateUrl: getters.templateUrl(state),
    templateTitle: getters.templateTitle(state),
    templateImageUrl: getters.templateImageUrl(state),
    modules: getters.modules(state),
    moduleId: getters.moduleId(state)
  }),
  {
    setTemplatesFilter: actions.setTemplatesFilter,
    hideUploadTemplateModal: () => actions.hideTemplateModal()
  }
);

const UploadDocumentModal = ({
  showUploadTemplateModal,
  template,
  hideUploadTemplateModal,
  modules,
  templateUrl,
  templateTitle,
  templateImageUrl,
  moduleId
}) =>
  showUploadTemplateModal ? (
    <UploadDocumentTemplateModal
      hideModal={hideUploadTemplateModal}
      template={template}
      modules={modules}
      templateUrl={templateUrl}
      templateTitle={templateTitle}
      templateImageUrl={templateImageUrl}
      moduleId={moduleId}
    />
  ) : null;

export default decorate(UploadDocumentModal);
