import PropTypes from "prop-types";
import React from "react";
import { Action } from "components/Global/CRM/Forms/Form/Submission/Actions";
import Submission from "components/Global/CRM/Forms/Form/Submission";
import {
  getAdminBadge,
  getSubmissionState
} from "components/Global/CRM/Forms/utils/utils";
import MenuItem from "material-ui/MenuItem";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";

import {
  Approved,
  BadgeGroup,
  NeedsReview,
  Rejected,
  Reviewed,
  Sent,
  Submitted
} from "components/Global/CRM/Forms/Form/Submission/Badges";

import InReview from "components/Event/FormsV2/Overview/StatusBadges/InReview";

export const ACCOUNT_ADMIN_BADGES = {
  approved: Approved,
  wrapper: BadgeGroup,
  inReview: InReview,
  needsReview: NeedsReview,
  rejected: Rejected,
  reviewed: Reviewed,
  sent: Sent,
  submitted: Submitted
};

class AccountSubmission extends Submission {
  getContext = (submission, form) => {
    const context = {};
    switch (getSubmissionState(submission, form.fields)) {
      case "submitted":
        context.verb = "Submitted";
        context.actions = [
          <Action onClick={() => this.showSubmissionSummary()}>Review</Action>
        ];
        context.menuItems = [
          // <MenuItem key="approve" value="approve" primaryText="Approve remaining" />,
          // <MenuItem key="deny" value="deny" primaryText="Deny remaining" />,
          submission.is_locked ? (
            <MenuItem key="unlock" value="unlock" primaryText="Unlock" />
          ) : (
            <MenuItem key="lock" value="lock" primaryText="Lock" />
          ),
          this.props.deleteSubmission ? (
            <DeleteConfirmation
              onConfirm={this.deleteSubmission}
              text="Are you sure you want to delete this submission?"
            >
              <MenuItem key="delete" value="delete" primaryText="Delete" />
            </DeleteConfirmation>
          ) : (
            undefined
          )
        ];
        context.timeAgo = submission.created_at;
        break;
      case "reviewing":
        context.verb = "Submitted";
        context.actions = [
          <Action onClick={() => this.showSubmissionSummary()}>
            Continue review
          </Action>
        ];
        context.menuItems = [
          // <MenuItem key="approve" value="approve" primaryText="Approve remaining" />,
          // <MenuItem key="deny" value="deny" primaryText="Deny remaining" />,
          submission.is_locked ? (
            <MenuItem key="unlock" value="unlock" primaryText="Unlock" />
          ) : (
            <MenuItem key="lock" value="lock" primaryText="Lock" />
          ),
          this.props.deleteSubmission ? (
            <DeleteConfirmation
              onConfirm={this.deleteSubmission}
              text="Are you sure you want to delete this submission?"
            >
              <MenuItem key="delete" value="delete" primaryText="Delete" />
            </DeleteConfirmation>
          ) : (
            undefined
          )
        ];
        context.timeAgo = submission.created_at;
        break;
      case "rejected":
      case "reviewed":
      case "approved":
        context.verb = "Approved";
        context.actions = [
          <Action onClick={() => this.showSubmissionSummary(submission.id)}>
            View form
          </Action>
          /*
          <Action
            onClick={() =>
              this.props.sendConfirmation(submission, this.props.form)
            }
          >
            {submission.is_confirmation_sent ? "Resend" : "Send"}
          </Action>
          */
        ];
        context.menuItems = [
          submission.is_locked ? (
            <MenuItem key="unlock" value="unlock" primaryText="Unlock" />
          ) : (
            <MenuItem key="lock" value="lock" primaryText="Lock" />
          ),
          this.props.deleteSubmission ? (
            <DeleteConfirmation
              onConfirm={this.deleteSubmission}
              text="Are you sure you want to delete this submission?"
            >
              <MenuItem key="delete" value="delete" primaryText="Delete" />
            </DeleteConfirmation>
          ) : (
            undefined
          )
        ];
        context.timeAgo = submission.updated_at;
        break;
      default:
    }

    context.badges = getAdminBadge(
      submission,
      form.fields,
      ACCOUNT_ADMIN_BADGES
    );
    return context;
  };

  onContextMenuChange = (event, type) => {
    switch (type) {
      case "lock":
      case "unlock":
        this.props.setSubmissionLocked(
          !this.props.submission.is_locked,
          this.props.submission
        );
      case "approve":
      case "deny":
        // this.props.viewSubmission({ id: this.props.submission.id });
        break;
      default:
        break;
    }
  };
}

AccountSubmission.propTypes = {
  deleteSubmission: PropTypes.func.isRequired,
  formPath: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  submission: PropTypes.shape({
    is_locked: PropTypes.bool,
    updated_at: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired,
  sendConfirmation: PropTypes.func.isRequired,
  setSubmissionLocked: PropTypes.func.isRequired,
  showSubmissionSummary: PropTypes.func.isRequired
};

export default AccountSubmission;
