import * as R from "ramda";
import moment from "moment";

import { createSelector } from "reselect";

import * as FormSelectors from "ui-kit/Form/selectors";
import { getters } from "./model";

import {
  SCHEDULING_EXTRA_FIELD_IDS,
  FIELDS_ONLY_EDITOR,
  ACTIVITY_STARTS_AT_ID,
  ACTIVITY_ENDS_AT_ID,
  ACTIONS,
  ACTIVITY_NAME
} from "./constants";
import { ACTION_TYPES } from "ui-kit/ActionsPopover/constants";

const getDateTime = (fieldId, fields, values) => {
  const dateTimeIso = R.pathOr(null, ["value", "iso"], R.prop(fieldId, values));
  const fieldSettings = R.prop(
    "settings",
    R.find(R.propEq("id", fieldId))(fields)
  );
  const dateTimeValue = fieldSettings.timezone
    ? moment(dateTimeIso).tz(fieldSettings.timezone)
    : dateTimeIso;
  return moment(dateTimeValue);
};

export const getStartAt = createSelector(
  getters.fields,
  getters.values,
  (fields, values) => getDateTime(ACTIVITY_STARTS_AT_ID, fields, values)
);

export const getEndsAt = createSelector(
  getters.fields,
  getters.values,
  (fields, values) => getDateTime(ACTIVITY_ENDS_AT_ID, fields, values)
);

export const isEditingField = createSelector(
  getters.editingFieldId,
  (_, { field: { id } }) => id,
  (editingFieldId, fieldId) => R.equals(editingFieldId, fieldId)
);

export const fieldIds = createSelector(
  getters.fieldsById,
  R.keys
);

export const optionValues = createSelector(
  getters.startsAtDate,
  getters.startsAtTime,
  getters.endsAtDate,
  getters.endsAtTime,
  getters.isAllDay,
  getters.recurrence,
  (
    startsAtDate,
    startsAtTime,
    endsAtDate,
    endsAtTime,
    isAllDay,
    recurrence
  ) => ({
    [SCHEDULING_EXTRA_FIELD_IDS.ALL_DAY]: {
      value: isAllDay
    },
    [SCHEDULING_EXTRA_FIELD_IDS.RECURRENCE]: {
      value: recurrence
    },
    [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE]: {
      value: startsAtDate
    },
    [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME]: {
      value: startsAtTime
    },
    [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE]: {
      value: endsAtDate
    },
    [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME]: {
      value: endsAtTime
    }
  })
);

export const activityDuration = createSelector(
  getStartAt,
  getEndsAt,
  (startsAt, endsAt) => `${endsAt.diff(startsAt, "minutes")} m`
);

export const settingValues = createSelector(
  getters.visibility,
  getters.color,
  getters.notifications,
  (visibility, color, notifications) => ({
    [SCHEDULING_EXTRA_FIELD_IDS.VISIBILITY]: {
      value: visibility
    },
    [SCHEDULING_EXTRA_FIELD_IDS.REMINDERS]: {
      value: notifications
    },
    [SCHEDULING_EXTRA_FIELD_IDS.COLOR]: {
      value: color
    }
  })
);

export const getFieldValueById = createSelector(
  getters.startsAtDate,
  getters.startsAtTime,
  getters.endsAtDate,
  getters.endsAtTime,
  getters.values,
  (state, props) => R.prop("fieldId", props),
  (startsAtDate, startsAtTime, endsAtDate, endsAtTime, values, fieldId) =>
    R.path([fieldId, "value"], {
      ...values,
      [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE]: {
        value: startsAtDate
      },
      [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME]: {
        value: startsAtTime
      },
      [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE]: {
        value: endsAtDate
      },
      [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME]: {
        value: endsAtTime
      }
    })
);

export const getIsComplete = createSelector(
  getters.values,
  R.pathOr(false, [SCHEDULING_EXTRA_FIELD_IDS.IS_COMPLETED, "value"])
);

export const getActivityName = createSelector(
  getters.values,
  R.pathOr("", [ACTIVITY_NAME, "value"])
);

export const showEditor = createSelector(
  isEditingField,
  (_, { field: { type } }) => type,
  (isEditing, fieldType) =>
    isEditing || R.contains(fieldType, FIELDS_ONLY_EDITOR)
);

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

export const getFields = createSelector(
  getters.fields,
  R.filter(R.prop("editable"))
);

export const getDatetimeFields = createSelector(
  getters.fields,
  R.compose(
    R.map(R.prop("id")),
    R.filter(
      R.anyPass([R.propEq("type", "datetime"), R.propEq("type", "date")])
    )
  )
);

export const getActionButtons = R.always([
  {
    id: ACTIONS.DELETE,
    type: ACTION_TYPES.ITEM,
    name: "Delete",
    confirm: "Are you sure you want to delete this activity?",
    confirmLabel: "Delete",
    style: { color: "danger8", fw: 3, fs: 2 }
  }
]);

export const getHasDependentTasks = createSelector(
  getters.dependentOn,
  dependsOnTasks => dependsOnTasks && Boolean(dependsOnTasks.length)
);
