import React from "react";

import { Div } from "components/Base";

import SearchBar from "./SearchBar";

const Toolbar = ({ instanceId = "" }) => (
  <Div display="row.flex-start.center" height={75}>
    <SearchBar instanceId={instanceId} />
  </Div>
);

export default Toolbar;
