import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import { getWhoHasAccessFormattedData } from "Orders/TicketsPasses/selectors";

import { Div, Text3, Text6 } from "components/Base";

import ProfileRow from "Orders/TicketsPasses/View/ProfileRow";

const decorate = connect(state => ({
  whoHasAccess: getWhoHasAccessFormattedData(state)
}));

const WhoHasAccess = ({ whoHasAccess }) => (
  <Div p={6} display="column.center.center">
    <Div width={740}>
      <Div display="row.space-between.flex-end" mb={2}>
        <Text6 bold color="black">
          User Profiles
        </Text6>
        <Div display="row">
          <Div width={300} mr={2}>
            <Text3 color="neutral5" bold>
              AVAILABLE TICKETS & PASSES
            </Text3>
          </Div>
          <Div width={32} height={1} ml={2} />
        </Div>
      </Div>
      <Div>
        {R.map(
          profile => (
            <ProfileRow key={profile.id} {...profile} />
          ),
          whoHasAccess
        )}
      </Div>
    </Div>
  </Div>
);
export default decorate(WhoHasAccess);
