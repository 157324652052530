/* eslint no-underscore-dangle: "off" */

import Helpers from "../Global/Helpers";

export default {
  /**
   * contact list
   */
  fetchContactList: (credentials, query) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${query.eventId}/contacts`,
        data: {},
        credentials,
        success: result => {
          resolve(result.list);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  searchContacts: (credentials, orgId, eventId, name) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: eventId
          ? `${window.__LENND_API_BASE_URL__}/contacts/event/${eventId}/contacts/search?q=${name}`
          : `${window.__LENND_API_BASE_URL__}/contacts/organization/${orgId}/contacts/search?q=${name}`,

        data: {},
        credentials,
        success: result => {
          resolve(result.contacts);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  /**
   * contact
   */
  fetchContact: (credentials, eventId, contactId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${eventId}/contacts/${contactId}`,
        data: {},
        credentials,
        success: result => {
          resolve(result.contact);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  fetchContacts: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${eventId}/contacts`,
        data: {},
        credentials,
        success: result => {
          resolve(result.contacts);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  addContacts: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contacts`,
        data,
        credentials,
        success: result => {
          resolve(result.contacts);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateContact: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contacts/${data.contactId}`,
        data,
        credentials,
        success: result => {
          resolve(result.contact);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateContactNotifications: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contact-notifications`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  // /**
  //  * notes
  //  */
  // createNote: (credentials, data) =>
  //   new Promise((resolve, reject) => {
  //     const options = {
  //       method: "post",
  //       url: `${window.__LENND_API_BASE_URL__}/contacts/event/${
  //         data.eventId
  //       }/contact-notes`,
  //       data,
  //       credentials,
  //       success: result => {
  //         resolve(result.note);
  //       },
  //       error: err => {
  //         reject(err);
  //       }
  //     };
  //     Helpers.request(options);
  //   }),

  // updateNote: (credentials, data) =>
  //   new Promise((resolve, reject) => {
  //     const options = {
  //       method: "put",
  //       url: `${window.__LENND_API_BASE_URL__}/contacts/event/${
  //         data.eventId
  //       }/contact-notes/${data.noteId}`,
  //       data,
  //       credentials,
  //       success: result => {
  //         resolve(result.note);
  //       },
  //       error: err => {
  //         reject(err);
  //       }
  //     };
  //     Helpers.request(options);
  //   }),

  // deleteNote: (credentials, data) =>
  //   new Promise((resolve, reject) => {
  //     const options = {
  //       method: "delete",
  //       url: `${window.__LENND_API_BASE_URL__}/contacts/event/${
  //         data.eventId
  //       }/contact-notes/${data.noteId}`,
  //       data,
  //       credentials,
  //       success: () => {
  //         resolve();
  //       },
  //       error: err => {
  //         reject(err);
  //       }
  //     };
  //     Helpers.request(options);
  //   }),

  /**
   * detail groups
   */
  fetchColumns: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contact-lists/${data.listId}/columns`,
        data: {},
        credentials,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  addColumn: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contact-lists/${data.listId}/columns`,
        data,
        credentials,
        success: result => {
          resolve(result.column);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateColumn: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contact-lists/${data.listId}/columns/${data.columnId}`,
        data,
        credentials,
        success: result => {
          resolve(result.group);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  deleteColumn: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contact-lists/${data.listId}/columns/${data.columnId}`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateColumnValue: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contact-lists/${data.listId}/columns/${data.columnId}/values`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateStandardFieldValue: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/contacts/${data.contactId}/standard-field/${data.fieldId}/value`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateFieldValue: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/contacts/event/${data.eventId}/values`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    })
};
