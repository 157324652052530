import * as R from "ramda";

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { bindInstance } from "redux-mvc";

import { getAttendeeListRows } from "Items/Manage/selectors";
import { actions, getters } from "Items/Manage";

import CardByPerson from "Items/Manage/View/CardByPerson";
import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      rows: getAttendeeListRows(state, props),
      mode: getters.mode(state, props)
    }),
    bindInstance({
      viewProfile: actions.viewContactProfile
    })
  )
);

const AttendeeListView = ({ rows, handlers, mode, m = 3, instanceId = "" }) => (
  <Div m={m}>
    {rows.map(
      ({
        person,
        passes,
        allLineItemIds,
        allCredentialLineItemIds,
        allMealLineItemIds
      }) => (
        <CardByPerson
          instanceId={instanceId}
          key={person.customer_contact_id}
          passes={passes}
          allLineItemIds={allLineItemIds}
          allCredentialLineItemIds={allCredentialLineItemIds}
          allMealLineItemIds={allMealLineItemIds}
          person={person}
          mode={mode}
          handlers={handlers}
        />
      )
    )}
  </Div>
);

export default decorate(AttendeeListView);
