import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { noop } from "utils/General";
import { withRouter } from "react-router";

import { actions, getters } from "Modules/Dashboard";
import { getTabs } from "Modules/Dashboard/selectors";
import {
  getters as SearchBarGetters,
  actions as SearchBarActions
} from "ui-kit/SearchBar";

import { DarkTab, Div, MediumTextButton } from "components/Base";

const decorate = connect(
  (state, props) => ({
    tabs: getTabs(state, props),
    selectedTab: getters.selectedTab(state),
    searchTerm: SearchBarGetters.searchTerm(state)
  }),
  {
    onClearSearch: () => SearchBarActions.clearSearch(),
    onSelectTab: actions.setSelectedTab
  }
);

const Tabs = ({
  tabs,
  searchTerm = "",
  onClearSearch = noop,
  selectedTab = noop,
  onSelectTab = noop,
  ...styleProps
}) => (
  <Div
    display="row.flex-start.flex-end"
    px={5}
    height={40}
    bc="gray3"
    bb={1}
    style={{
      flexShrink: 0
    }}
    {...styleProps}
  >
    {searchTerm.length
      ? [
          <DarkTab mr={4} px={3} active activeColor="neutral8">
            Results for '{searchTerm}'
          </DarkTab>,
          <MediumTextButton color="primary5" onClick={onClearSearch}>
            CLEAR SEARCH
          </MediumTextButton>
        ]
      : tabs.map(t => (
          <DarkTab
            key={t.id}
            active={t.id === selectedTab}
            onClick={t.id === selectedTab ? noop : () => onSelectTab(t.id)}
          >
            {t.title}
          </DarkTab>
        ))}
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(Tabs);
