/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getDetails: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}`,
        credentials,
        success,
        error
      });
    }),
  updateDetails: ({ credentials, orgId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}`,
        credentials,
        data,
        success,
        error
      });
    })
};
