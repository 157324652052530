import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import * as Selectors from "redux/modules/formsV2/form/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import * as FormActions from "redux/modules/formsV2/form/actions";
import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import * as ContactForms from "redux/modules/contacts/assignedForms/actions";
import * as AccountForms from "redux/modules/accounts/assignedForms/actions";
import { getLoggedInUserDetails, login } from "redux/modules/user/actions";
import { createOpenFormSubmission } from "redux/modules/portal/openFormSubmissions/actions";
import * as PortalUserActions from "redux/modules/portal/user/actions";
import * as PortalUserSelectors from "redux/modules/portal/user/selectors";
import { addAccountUser } from "redux/modules/accounts/users/actions";

function mapStateToProps(state) {
  return {
    userCredentials: getCredentials(state),
    user: user(state),
    portalUser: PortalUserSelectors.portalUser(state),
    form: Selectors.form(state),
    isFetching: Selectors.isFetching(state),
    errorMessages: [state.formsV2.form.error].filter(e => e)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginUser: login,
      fetchUser: getLoggedInUserDetails,
      createOpenFormSubmission,
      assignFormToContact: ContactForms.addAssignedForms,
      assignFormToAccount: AccountForms.addAssignedForms,
      addRecord,
      addAccountUser,
      ...FormActions,
      ...ModalActions,
      ...SnackbarActions,
      ...PortalUserActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
