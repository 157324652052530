/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import { connect } from "react-redux";

import EnvironmentTag from "components/Platform/EnvironmentTag";
import PlatformLoading from "components/Platform/PlatformLoading";
import Layout from "EventLight/Layout/View";

import Snackbar from "components/Global/Snackbar";
import UiSnackbar from "ui-kit/Snackbar/View";
import Modal from "components/Global/Modal";

import { hasFetchedAccessLevels } from "redux/modules/user/access-levels/selectors";
import {
  hasFetchedUserPermissionProfile,
  userPermissionProfile
} from "redux/modules/permissions/user-permission-profile/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as EventSelectors from "redux/modules/event/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import { noop } from "utils/General";
import { actions } from "./model";

const decorate = connect(
  (state, props) => ({
    isFetchingEventDetails: EventSelectors.isFetching(state),
    eventDetails: EventSelectors.eventDetails(state),
    user: UserSelectors.user(state),
    isEventError: EventSelectors.isEventError(state),
    fetchedAccessLevels: hasFetchedAccessLevels(state),
    fetchedPermissions: hasFetchedUserPermissionProfile(
      state,
      props.params.eventId
    ),
    userPermissionProfile: userPermissionProfile(state, props.params.eventId),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
    isCustomerSuccess: selectFeatureFlag(flags.IS_CUSTOMER_SUCCESS.NAME)(state)
  }),
  {
    init: actions.init,
    bringAccess: actions.bringAccess
  }
);

const DontHavePermission = ({ text = "" }) => (
  <div
    style={{
      fontWeight: 600,
      textAlign: "center",
      color: "#333",
      fontSize: 40,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    {text}
  </div>
);

const EventLightGate = ({
  user = {},
  userPermissionProfile = {},
  fetchedAccessLevels = false,
  fetchedPermissions = false,
  params = {},
  eventDetails = {},
  isFetchingEventDetails = false,
  isEventError = false,
  children = React.Fragment,
  init = noop,
  bringAccess = noop,
  isLenndAdmin = false,
  isCustomerSuccess = false
}) => {
  useEffect(() => {
    init({ eventId: params.eventId, id: user.id });
  }, []);

  if (isEventError) {
    return <DontHavePermission text="You do not have access to this event." />;
  }

  if (!user.id || !fetchedAccessLevels || !fetchedPermissions) {
    return <PlatformLoading />;
  }

  if (
    userPermissionProfile?.dontHavePermission &&
    (isLenndAdmin || isCustomerSuccess)
  ) {
    bringAccess({ eventId: params.eventId });
    return <DontHavePermission text="Bringing you access to this event ... " />;
  }

  if (
    userPermissionProfile?.dontHavePermission &&
    !isLenndAdmin &&
    !isCustomerSuccess
  ) {
    return <DontHavePermission text="You do not have access to this event." />;
  }

  if (isFetchingEventDetails && !("id" in eventDetails)) {
    return <PlatformLoading message="Loading event ..." />;
  }
  return (
    <>
      <Layout>{children}</Layout>
      <EnvironmentTag />
      <Snackbar />
      <Modal />
      <UiSnackbar />
    </>
  );
};

export default decorate(EventLightGate);
