import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";
import { actions } from "Modules/ImportModal";
import * as R from "ramda";
import importModalApi from "Modules/ImportModal/api";
import { getters } from "Modules/ImportModal";
import { userId } from "redux/modules/user/selectors";
import { orgId } from "redux/modules/organization/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId } from "redux/modules/event/selectors";
import { handleGetCountOfRowsWithErrors } from "./selectors";
import { navigateTo } from "utils/General";
import { registerError } from "redux/modules/errors/actions";
import { PAGES } from "Modules/ImportModal/constants";
import Helpers from "utils/Global/Helpers";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const id = yield select(userId);
  const org = yield select(orgId);
  const event = yield select(eventId);
  const eventIdProp = yield select(getters.eventId);
  const module = yield select(getters.moduleId);
  const blockId = yield select(getters.blockId);
  const submissionId = yield select(getters.submissionId);
  const maxAmount = yield select(getters.maxAmount);
  const accountId = yield select(getters.accountId);
  const recordTypeId = yield select(getters.recordTypeId);

  return {
    credentials,
    userId: id,
    orgId: org,
    eventId: event || eventIdProp,
    moduleId: module,
    blockId,
    submissionId,
    maxAmount,
    accountId,
    recordTypeId
  };
};

const downloadUrl = function*() {
  const params = yield call(getParams);

  yield put(actions.setLoadingStatus({ loading: true, ratioBar: 0.7 }));
  try {
    const result = yield call(importModalApi.getDownloadUrl, params);
    yield call(navigateTo, result.url);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching download url"
          }
        ])
      )
    ]);
  }
  yield put(actions.setLoadingStatus({ loading: false, ratioBar: 0 }));
};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "text/csv",
        "application/excel",
        "application/x-excel",
        "application/x-msexcel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ]
    };

    const path = { path: "module-import/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const importFile = function*(url) {
  const {
    credentials,
    orgId,
    eventId,
    moduleId,
    blockId,
    submissionId,
    maxAmount,
    accountId,
    recordTypeId
  } = yield call(getParams);
  const blockProps = R.or(R.isEmpty(blockId), R.isEmpty(submissionId))
    ? {}
    : {
        block_id: blockId,
        submission_record_id: submissionId,
        max_amount: maxAmount
      };

  const portalProps = R.or(R.isEmpty(accountId), R.isEmpty(recordTypeId))
    ? {}
    : {
        account_id: accountId,
        record_type_id: recordTypeId
      };

  yield put(actions.setLoadingStatus({ loading: true, ratioBar: 0.7 }));
  try {
    const { payload } = yield call(importModalApi.setImportFile, {
      moduleId: moduleId || STANDARD_MODULE_IDS.contacts.id,
      credentials,
      data: {
        url,
        org_id: orgId,
        event_id: eventId,
        ...blockProps,
        ...portalProps
      }
    });
    yield put(
      actions.setDataAfterImport({
        url,
        selectedPage: PAGES.MAP_CSV,
        mapObject: payload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred importing the file"
        }
      ])
    );
  }
  yield put(actions.setLoadingStatus({ loading: false, ratioBar: 0 }));
};

const saveCsvData = function*() {
  const {
    credentials,
    userId,
    orgId,
    eventId,
    moduleId,
    blockId,
    submissionId,
    maxAmount,
    accountId,
    recordTypeId
  } = yield call(getParams);
  const fieldGroups = yield select(getters.fieldGroups);
  const urlFile = yield select(getters.urlFile);

  const blockProps = R.or(R.isEmpty(blockId), R.isEmpty(submissionId))
    ? {}
    : {
        block_id: blockId,
        submission_record_id: submissionId,
        max_amount: maxAmount
      };

  const portalProps = R.or(R.isEmpty(accountId), R.isEmpty(recordTypeId))
    ? {}
    : {
        account_id: accountId,
        record_type_id: recordTypeId
      };

  const data = {
    url: urlFile,
    org_id: orgId,
    event_id: eventId,
    user_id: userId,
    fields_groups: fieldGroups.filter(el => el.fields.length > 0),
    ...blockProps,
    ...portalProps
  };
  yield put(actions.setLoadingStatus({ loading: true, ratioBar: 0 }));
  try {
    const {
      payload: { import_id: importId }
    } = yield call(importModalApi.postCsvData, {
      credentials,
      moduleId,
      data
    });

    yield delay(500);

    let payload = yield call(importModalApi.getCsvUploadStatus, {
      credentials,
      moduleId,
      importId
    });

    while (payload.finished === false) {
      yield delay(500);
      payload = yield call(importModalApi.getCsvUploadStatus, {
        credentials,
        moduleId,
        importId
      });
      const currentRecord = payload.current_record || 0;
      const totalRecords = payload.total_records || 1;
      yield put(
        actions.setLoadingStatus({
          loading: true,
          ratioBar: currentRecord / totalRecords,
          pendingSeconds: payload.pending_seconds
        })
      );
    }
    const { success } = payload;
    const { counters } = payload.detail;
    const countOfErrors = handleGetCountOfRowsWithErrors(payload.detail);
    const hasError = Boolean(countOfErrors);

    if (success) {
      if (!hasError) {
        yield put(actions.setDataAfterUpload({ counters }));

        const onDoneFn = yield select(getters.onDoneFn);
        if (onDoneFn) {
          onDoneFn();
        }
      } else {
        yield put(
          actions.setUploadCsvErrors({
            uploadCsvErrors: payload.detail,
            selectedPage: PAGES.INVALID_ROWS
          })
        );
      }
    } else {
      const error = { message: R.propOr("", "error", payload.detail) };
      yield put(
        registerError([
          {
            system: error,
            user: error.message
          }
        ])
      );
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: error.message
        }
      ])
    );
  }
  yield put(actions.setLoadingStatus({ loading: false, ratioBar: 0 }));
};

const uploadFile = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield call(importFile, url);
};

const watchSaveCsvData = function*() {
  yield takeEvery(actions.saveCsvData.type, saveCsvData);
};

const watchDownloadUrl = function*() {
  yield takeEvery(actions.downloadUrl.type, downloadUrl);
};

const watchUploadFile = function*() {
  yield takeEvery(actions.uploadFile.type, uploadFile);
};

const rootSaga = function*() {
  yield all([
    fork(watchDownloadUrl),
    fork(watchUploadFile),
    fork(watchSaveCsvData)
  ]);
};

export default rootSaga;
