/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getPages: ({ credentials, eventId }) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sites/pages/event/${eventId}`,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    }),
  reorderPages: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sites/pages`,
        data,
        credentials,
        success,
        error
      });
    }),
  deletePage: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/sites/pages/${data.pageId}`,
        data,
        credentials,
        success,
        error
      });
    })
};
