import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "../index";
import { Div, Text3, Text1, Text2, SmallCheckbox } from "components/Base";

const decorate = connect(
  state => ({
    fields: getters.fields(state),
    selectedFields: getters.selectedFields(state)
  }),
  {
    enableAllFields: () => actions.enableAllFields(),
    disableAllFields: () => actions.disableAllFields(),
    toggleField: actions.toggleField
  }
);

const AvailableFields = ({
  fields,
  selectedFields,
  enableAllFields,
  disableAllFields,
  toggleField
}) => (
  <Div
    width={391}
    height={463}
    bg="neutral0"
    p={3}
    style={{ overflowY: "auto", flexShrink: 0 }}
  >
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Available Fields
      </Text3>
      <Div display="row.flex-start.center">
        <Text1 color="primary7" underline onClick={enableAllFields}>
          All
        </Text1>
        <Text1 color="primary7" underline ml={2} onClick={disableAllFields}>
          None
        </Text1>
      </Div>
    </Div>
    <Div mt={3}>
      {R.map(({ id, name, tag }) => {
        const isSelected = R.any(
          selected => selected.id === id,
          selectedFields
        );
        return (
          <Div
            display="row.flex-start.center"
            bg="white"
            bc="neutral1"
            bra={1}
            px={2}
            mt={1}
            key={id}
            onClick={() => toggleField({ name, tag, id, isSelected })}
            style={{
              minHeight: 38
            }}
          >
            <SmallCheckbox selected={isSelected} />
            <Text2 bold ml={1}>
              {name}
            </Text2>
          </Div>
        );
      }, fields)}
    </Div>
  </Div>
);

export default decorate(AvailableFields);
