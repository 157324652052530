import React, { Fragment } from "react";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import Item from "components/Global/CRM/Modals/Assign/Item";
import {
  Div,
  SuperClearInput,
  SearchIcon,
  CloseIcon,
  LoadingIcon,
  Paginator
} from "components/Base";
import RecordCard from "components/Global/CRM/RecordCardList/RecordCard";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

const Loading = () => (
  <Div display="column.center.center" height={370}>
    <LoadingIcon size={60} color="neutral4" />
    <Div color="neutral4" mt={6}>
      Loading records...
    </Div>
  </Div>
);

const AddNewRecord = ({ filter, showAddRecordModal }) => (
  <Div
    display="flex.center.center"
    color={{ default: "gray6", hover: "gray9" }}
    bra={1}
    fw={3}
    fs={3}
    p={3}
    mt={4}
    onClick={showAddRecordModal}
  >
    + Add a record for "{filter}"
  </Div>
);

const Empty = ({ children }) => (
  <Div
    display="column.center.center"
    style={{
      backgroundColor: "#EEE"
    }}
    color="gray5"
    bra={1}
    fw={3}
    fs={3}
    p={3}
  >
    {children}
  </Div>
);

const RecordItem = ({ name, onClick, icon, ...props }) => (
  <Div
    onClick={onClick}
    display="row.space-between.center"
    mb={1}
    bra={1}
    py={2}
    px={2}
    {...props}
  >
    <Div flex={1} fs={1} fw={3} color="gray9" truncate>
      {name}
    </Div>
    {icon}
  </Div>
);

const SelectedItem = ({ name, onClick }) => (
  <RecordItem
    name={name}
    onClick={onClick}
    style={{
      backgroundColor: "#FDE7CB"
    }}
    icon={<CloseIcon size={15} color="primary7" />}
  />
);

const Header = ({ filter, onFilterChange, hideModal }) => (
  <Div
    display="row.flex-start.center"
    flex={1}
    bb={1}
    bc="neutral0"
    pl={1}
    pr={3}
  >
    <SuperClearInput
      continuous
      value={filter}
      placeholder="Find an existing record"
      onChange={onFilterChange}
      LeftIcon={SearchIcon}
      leftIconColor="gray6"
      flex={1}
      style={{
        height: 65
      }}
    />
    <CloseIcon size={30} onClick={hideModal} />
  </Div>
);

const Records = ({
  results,
  selectionMode,
  showAddRecordModal,
  filter,
  //
  countOfResults,
  currentPage,
  goToPage,
  onSelectResultsPerPage,
  resultsPerPage
}) => (
  <Div
    width={473}
    height={1}
    px={5}
    py={4}
    style={{
      backgroundColor: "#FAF9FC",
      overflowY: "auto"
    }}
  >
    {!results.length ? (
      <Fragment>
        <Empty>No records matched your search</Empty>
        <AddNewRecord showAddRecordModal={showAddRecordModal} filter={filter} />
      </Fragment>
    ) : (
      <Div>
        {results.map(record => (
          <RecordCard
            key={record.id}
            name={record.name}
            type={record.type}
            onClick={record.selectRecord}
            metaValues={record.metaValues}
            selected={record.isSelected}
            selectionMode={selectionMode}
          />
        ))}
        <Div mt={3}>
          <Paginator
            showingResultsProps={{ label: "records" }}
            goTo={goToPage}
            resultsPerPage={resultsPerPage}
            paginatorLength={5}
            totalNumResults={countOfResults}
            currentPage={currentPage}
            selectOptions={[25, 50, 100]}
            onSelectResultsPerPage={onSelectResultsPerPage}
            showPerPageSelector={true}
            showResultsDisplay={true}
          />
        </Div>
      </Div>
    )}
  </Div>
);

const Panel = ({ title, children }) => (
  <Div
    py={3}
    px={3}
    bra={1}
    style={{
      backgroundColor: "#FAFAFC"
    }}
  >
    <Div mb={2} fw={3}>
      {title}
    </Div>
    {children}
  </Div>
);

const Sidebar = ({
  toAdd,
  onRecordSelect,
  selectedRecords,
  records,
  recordIds,
  recordNamePlural,
  recordNameSingular,
  isExistingRecordSelected,
  onExistingRecordSelect
}) => (
  <Div
    height={1}
    width={272}
    px={3}
    py={3}
    bg="white"
    style={{
      overflowY: "auto"
    }}
  >
    <Panel title="Selected:">
      <Div>
        {!Object.keys(toAdd).length ? <Empty>No records selected</Empty> : null}
        {Object.keys(toAdd).map(recordId => {
          const record = toAdd[recordId];
          return (
            <SelectedItem
              key={recordId}
              name={record.name}
              onClick={() => onRecordSelect(record)}
            />
          );
        })}
      </Div>
    </Panel>

    {Object.keys(selectedRecords).length ? (
      <Panel
        title="Existing linked records:"
        description={`These records are currently assigned to 1 or more of the ${
          recordIds.length
        } selected ${
          recordIds.length !== 1 ? recordNamePlural : recordNameSingular
        }.`}
      >
        <Div mt={4}>
          {Object.keys(selectedRecords).map(recordId => {
            const record = selectedRecords[recordId];
            const isExistingRecordSelectedCheck = isExistingRecordSelected(
              record
            );
            return (
              <Item
                key={record.id}
                name={records[recordId].name}
                onClick={() => onExistingRecordSelect(record)}
                selected={isExistingRecordSelectedCheck}
                faded={isExistingRecordSelectedCheck}
                style={{
                  backgroundColor: "#F5F3F8",
                  marginBottom: 3
                }}
                icon={
                  isExistingRecordSelectedCheck ? (
                    <span>Undo</span>
                  ) : (
                    <i className={["material-icons"].join(" ")}>close</i>
                  )
                }
              />
            );
          })}
        </Div>
      </Panel>
    ) : null}
  </Div>
);

const Footer = ({ saving, saveAndClose, hideModal }) => (
  <Div display="row.space-between.center" px={5}>
    <ButtonGroup>
      <Submit
        key="submit"
        disabled={saving}
        title={
          <Div display="row.center.center">
            <span>{saving ? "Saving" : "Save"}</span>
            {saving ? <LoadingIcon size={20} color="white" ml={3} /> : null}
          </Div>
        }
        onClick={saveAndClose}
      />
      <ButtonOutline key="cancel" title="Cancel" onClick={hideModal} />
    </ButtonGroup>
  </Div>
);

const View = ({
  loading,
  saving,
  recordIds,
  recordNamePlural,
  recordNameSingular,
  filter,
  results,
  toAdd,
  selectedRecords,
  records,
  onFilterChange,
  hideModal,
  onRecordSelect,
  isExistingRecordSelected,
  onExistingRecordSelect,
  saveAndClose,
  selectionMode,
  showAddRecordModal,
  //
  countOfResults,
  currentPage,
  goToPage,
  onSelectResultsPerPage,
  resultsPerPage
}) => (
  <StyleWrapper width={745} hideModal={hideModal} hideHeader>
    <Body
      style={{
        height: 527,
        maxHeight: "inherit"
      }}
    >
      <Header
        filter={filter}
        onFilterChange={onFilterChange}
        hideModal={hideModal}
      />

      {loading ? (
        <Loading />
      ) : (
        <Div display="row" height={460}>
          <Records
            {...{
              records,
              results,
              selectionMode,
              showAddRecordModal,
              filter,
              //
              countOfResults,
              currentPage,
              goToPage,
              onSelectResultsPerPage,
              resultsPerPage
            }}
          />
          <Sidebar
            {...{
              toAdd,
              onRecordSelect,
              selectedRecords,
              records,
              recordIds,
              recordNamePlural,
              recordNameSingular,
              isExistingRecordSelected,
              onExistingRecordSelect
            }}
          />
        </Div>
      )}
    </Body>
    <Footer
      {...{
        saving,
        saveAndClose,
        hideModal
      }}
    />
  </StyleWrapper>
);

export default View;
