import * as R from "ramda";
import Layout from "./Layout";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import module from "HealthPass/HealthPassForm";
import { actions } from "HealthPass/HealthPassForm/model";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization"],
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    )
  },
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          ...this.props.params,
          ...this.props.location.query
        })
      );
    }
  }
});

export default R.compose(
  withRouter,
  decorate
)(Layout);
