import * as R from "ramda";
import { STATUS, TAB_IDS } from "./constants";
import { makeInstanceSelector } from "redux-mvc";
import { getters } from "./model";

const getKey = (_, props = {}) => props.instanceId;

export const getSelectedTabTotal = makeInstanceSelector(
  getters.tabStats,
  getters.selectedTab,
  (stats, tab) => stats[tab] || 0
)(getKey);

// pagination

export const getPageSize = R.compose(
  R.prop("pageSize"),
  getters.pagination
);

export const getCurrentPage = R.compose(
  R.prop("page"),
  getters.pagination
);

export const getLinks = makeInstanceSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  (routes, params) => {
    if (R.any(R.propEq("name", "EventLightCRMHistory"))(routes)) {
      return [
        {
          id: TAB_IDS.EMAILS,
          name: "Emails",
          active: params.historyType === TAB_IDS.EMAILS,
          path: `/event-light/${params.eventId}/crm/history/${TAB_IDS.EMAILS}`
        },
        {
          id: TAB_IDS.SMS,
          name: "SMS",
          active: params.historyType === TAB_IDS.SMS,
          path: `/event-light/${params.eventId}/crm/history/${TAB_IDS.SMS}`
        }
      ];
    }

    return [
      {
        id: TAB_IDS.EMAILS,
        name: "Emails",
        active: params.historyType === TAB_IDS.EMAILS,
        path: `/event-light/${params.eventId}/emails/history/${TAB_IDS.EMAILS}`
      },
      {
        id: TAB_IDS.SMS,
        name: "SMS",
        active: params.historyType === TAB_IDS.SMS,
        path: `/event-light/${params.eventId}/emails/history/${TAB_IDS.SMS}`
      }
    ];
  }
)(getKey);

export const getTabs = makeInstanceSelector(getters.tabStats, stats => {
  const tabs = [
    {
      title: "Show All",
      id: STATUS.ALL
    },
    {
      title: "Errors",
      id: STATUS.ERROR
    }
  ];

  return tabs.map(tab => ({
    ...tab,
    title: R.isNil(stats[tab.id])
      ? tab.title
      : `${tab.title} (${stats[tab.id]})`
  }));
})(getKey);

export const getSearchPlaceholder = makeInstanceSelector(
  getters.historyType,
  historyType => {
    if (historyType === TAB_IDS.EMAILS) {
      return "Search by name or email";
    }

    return "Search by name or phone number";
  }
)(getKey);
