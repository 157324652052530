import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RecordOwners from "./RecordOwners";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { deleteOwners } from "redux/modules/modules/owners/actions";

function mapStateToProps(state) {
  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      deleteOwners
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordOwners);
