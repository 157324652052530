import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Schedules/Schedule";
import AddScheduleModal from "Schedules/Create/View/Layout";

const decorate = connect(
  state => ({
    showCreateModal: getters.showCreateModal(state)
  }),
  {
    hideModal: () => actions.setShowCreateModal(false)
  }
);

const Modal = ({ hideModal, showCreateModal }) => {
  return showCreateModal && <AddScheduleModal hideModal={hideModal} />;
};

export default decorate(Modal);
