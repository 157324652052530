import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { without, get, find } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Input from "components/Global-2016/Forms/Input";
import TextArea from "components/Global-2016/Forms/TextArea";
import BoolCheckbox from "components/Global-2016/Forms/BoolCheckbox";
import DropdownBuilder from "components/Global-2016/Forms/DropdownBuilder";

import DropdownOptions from "components/Global/Table3/ModalColumn/DropdownOptions";
import DateEditorSettings from "components/Global/Table3/ModalColumn/DateEditorSettings";
import PhoneEditorSettings from "components/Global/Table3/ModalColumn/PhoneEditorSettings";
import DateTimeEditorSettings from "components/Global/Table3/ModalColumn/DateTimeEditorSettings";
import ContactSettings from "components/Global/Table3/ModalColumn/ContactSettings";
import PercentSettings from "components/Global/Table3/ModalColumn/PercentSettings";
import CalculatedNumberSettings from "components/Global/Table3/ModalColumn/CalculatedNumberSettings";
import CurrencySettings, {
  currencOptions
} from "components/Global/Table3/ModalColumn/CurrencySettings";
import AdvancedSettings from "components/Global/Table3/ModalColumn/AdvancedSettings";
import RelatedFields from "components/Global/Table3/ModalColumn/RelatedFields";
import MappedFields from "components/Global/Table3/ModalColumn/MappedFields";
import ContactMappedToAccountSettings from "components/Global/Table3/ModalColumn/ContactMappedToAccountSettings";

import getMappableFieldSettings from "utils/value-types/get-mappable-field-settings";

import * as FieldActions from "redux/modules/formsV2/form/fields/actions.js";

@CSSModules(css)
class FormQuestionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...currencOptions.USD,
      name: "",
      type: "text",
      fromLabel: "",
      toLabel: "",
      required: false,
      isAdminField: false,
      readOnly: false,
      options: [{}],
      allowMultipleSelect: false,
      description: "",
      placeholder: "",
      displayType: "calendar",
      phoneDisplayType: "mask",
      useDateTimeTimezone: false,
      calculatedNumberExpression: "",
      parentFields: [],
      childFields: [],
      mappedFields: [],
      error: null,
      percentFormat: "0%",
      contactSettings: {
        destinationId: null
      }
    };

    // if in edit mode, update state accordingly
    if (props.mode === "edit") {
      const fieldRelationships = get(props, "fieldRelationships");
      const fieldMapping = get(props, "fieldMapping");
      this.state = {
        ...this.state,
        ...props.field.settings,
        name: props.field.name,
        type: props.field.type,
        parentFields: fieldRelationships
          ? fieldRelationships
              .filter(f => f.childFieldId === props.field.id)
              .map(f => f.parentFieldId)
          : [],
        childFields: fieldRelationships
          ? fieldRelationships
              .filter(f => f.parentFieldId === props.field.id)
              .map(f => f.childFieldId)
          : [],
        mappedFields: fieldMapping
          ? fieldMapping.filter(f => f.parentFieldId === props.field.id)
          : []
      };

      if (this.isContactField(props.field.type)) {
        // incoming settings: contact
        this.state.contactSettings = {
          ...this.state.contactSettings,
          accountContact: get(props.field, "settings.accountContact"),
          destinationId: get(props.field, "settings.destinationId")
        };
      }
    }
  }

  isContactField(type) {
    return ["contact", "contact-reference", "reference"].includes(type);
  }

  @autobind
  updateDropdownOptions(options) {
    this.setState({ options });
  }

  @autobind
  updateDropdownAllowMultiple(allowMultipleSelect) {
    this.setState({ allowMultipleSelect });
  }

  @autobind
  handleRequireChange() {
    this.setState({ required: !this.state.required });
  }

  @autobind
  handleAdminFieldChange() {
    this.setState({ isAdminField: !this.state.isAdminField });
  }

  @autobind
  handleReadOnlyFieldChange() {
    this.setState({ readOnly: !this.state.readOnly });
  }

  @autobind
  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  @autobind
  handlePlaceholderChange(event) {
    this.setState({ placeholder: event.target.value });
  }

  @autobind
  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  @autobind
  handleTypeChange(event) {
    this.setState({
      type: event.target.value,
      name: ""
    });
  }

  @autobind
  onAdd(e) {
    e.preventDefault();
    if (!this.state.name) {
      this.setState({ error: "Please enter the question's name" });
    } else if (
      this.isContactField(this.state.type) &&
      !get(this.state, "contactSettings.destinationId")
    ) {
      this.setState({ error: "Please select a destination" });
    } else {
      const data = {
        eventId: this.props.eventId,
        formId: this.props.formId,
        order: this.props.index,
        type: this.state.type,
        name: this.state.name,
        settings: {
          required: this.state.required,
          isAdminField: this.state.isAdminField,
          readOnly: this.state.readOnly,
          description: this.state.description,
          placeholder: this.state.placeholder
        },
        parentFields: this.state.parentFields,
        childFields: this.state.childFields,
        mappedFields: this.state.mappedFields
      };

      if (["dropdown", "radio"].includes(data.type)) {
        data.settings.options = this.state.options;
      }

      if (data.type === "dropdown") {
        data.settings.allowMultipleSelect = this.state.allowMultipleSelect;
      }

      if (data.type === "date") {
        data.settings.displayType = this.state.displayType;
      }

      if (data.type === "phone") {
        data.settings.phoneDisplayType = this.state.phoneDisplayType;
      }

      if (data.type === "percent") {
        data.settings.percentFormat = this.state.percentFormat;
      }

      if (data.type === "calculated-number") {
        data.settings.calculatedNumberExpression = this.state.calculatedNumberExpression;
        data.settings.readOnly = true; // Default as read only
      }

      if (data.type === "currency") {
        data.settings.currencyPrefix = this.state.currencyPrefix;
        data.settings.currencySuffix = this.state.currencySuffix;
        data.settings.currencyFormat = this.state.currencyFormat;
        data.settings.currencyName = this.state.currencyName;
      }

      if (data.type === "datetime") {
        data.settings.useDateTimeTimezone = this.state.useDateTimeTimezone;
      }

      if (this.isContactField(data.type)) {
        data.settings.accountContact = this.state.contactSettings.accountContact;
        data.settings.destinationId = this.state.contactSettings.destinationId;
      }

      this.props.dispatch(FieldActions.addField(data));

      this.props.hideModal();
    }
  }

  @autobind
  onUpdate(e) {
    e.preventDefault();

    const {
      name,
      type,
      options,
      required,
      isAdminField,
      readOnly,
      allowMultipleSelect,
      contactSettings,
      displayType,
      phoneDisplayType,
      calculatedNumberExpression,
      percentFormat,
      currencyFormat,
      currencyPrefix,
      currencySuffix,
      currencyName,
      useDateTimeTimezone,
      placeholder,
      description,
      parentFields,
      childFields,
      mappedFields
    } = this.state;

    if (!name) {
      this.setState({ error: "Please enter the question's name" });
    } else if (
      this.isContactField(type) &&
      !get(this.state, "contactSettings.destinationId")
    ) {
      this.setState({ error: "Please select a destination" });
    } else {
      const data = {
        fieldId: this.props.field.id,
        name,
        type,
        settings: {
          required,
          placeholder,
          description,
          isAdminField,
          readOnly
        },
        parentFields,
        childFields,
        mappedFields
      };

      if (["dropdown", "radio"].includes(type)) {
        data.settings.options = options;
      }

      if (type === "dropdown") {
        data.settings.allowMultipleSelect = allowMultipleSelect;
      }

      if (type === "date") {
        data.settings.displayType = displayType;
      }

      if (type === "datetime") {
        data.settings.useDateTimeTimezone = useDateTimeTimezone;
      }

      if (data.type === "phone") {
        data.settings.phoneDisplayType = phoneDisplayType;
      }

      if (data.type === "percent") {
        data.settings.percentFormat = percentFormat;
      }
      if (data.type === "calculated-number") {
        data.settings.calculatedNumberExpression = calculatedNumberExpression;
      }

      if (data.type === "currency") {
        data.settings.currencyFormat = currencyFormat;
        data.settings.currencyPrefix = currencyPrefix;
        data.settings.currencySuffix = currencySuffix;
        data.settings.currencyName = currencyName;
      }

      if (this.isContactField(type)) {
        data.settings.destinationId = contactSettings.destinationId;
        data.settings.accountContact = this.state.contactSettings.accountContact;
      }

      this.props.dispatch(FieldActions.updateField(data));

      this.props.hideModal();
    }
  }

  @autobind
  updateFieldRelationship(relationshipType, fieldId, toggle) {
    this.setState(state => {
      const key =
        relationshipType === "parent" ? "parentFields" : "childFields";
      if (toggle === true && !state[key].includes(fieldId)) {
        state[key].push(fieldId);
      } else if (toggle === false && state[key].includes(fieldId)) {
        state[key] = without(state[key], fieldId);
      }
      state.error = null;
      return state;
    });
  }

  @autobind
  updateMappedFields(parentFieldId, sourceFieldId, destinationFieldId) {
    this.setState(state => {
      const mappedFields = state.mappedFields.filter(
        f => f.destinationFieldId !== destinationFieldId
      );
      if (sourceFieldId) {
        mappedFields.push({ parentFieldId, sourceFieldId, destinationFieldId });
      }
      return {
        ...state,
        mappedFields
      };
    });
  }

  @autobind
  updateContactSettings(settings) {
    this.setState({
      contactSettings: settings
    });
  }

  render() {
    const {
      field,
      fields,
      availableChildFields,
      availableParentFields,
      mode
    } = this.props;
    const { type, parentFields, childFields, mappedFields, error } = this.state;
    const fieldType = getMappableFieldSettings(type);
    const canBeParentField = get(fieldType, "canBeParentField");
    const canBeChildField = get(fieldType, "canBeChildField");
    const mappableFields = get(fieldType, "mappableFields", []);
    const hasMappableFields = mappableFields.length;

    return (
      <div>
        <div className="modal-header">
          <div className="modal-title" styleName="title">
            {this.props.mode === "edit" ? "Edit" : "Add"} Field
          </div>
        </div>

        <div className="modal-body" styleName="modalBody">
          <form
            className="modal-body-wrapper"
            onSubmit={mode === "edit" ? this.onUpdate : this.onAdd}
          >
            {/* Type */}
            <div className="input-wrapper">
              <label styleName="formLabel">Field Type</label>
              <select
                key="type"
                styleName="select"
                disabled={mode === "edit"}
                onChange={this.handleTypeChange}
                defaultValue={this.state.type}
              >
                {this.props.fieldTypes.map(field => (
                  <option value={field.id} key={field.id}>
                    {field.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Required */}
            {this.state.type !== "checkbox" ? (
              <div className="input-wrapper">
                <BoolCheckbox
                  containerStyles={css.inputGroup}
                  label="Is this field required?"
                  type="text"
                  checked={this.state.required}
                  onClick={this.handleRequireChange}
                />
              </div>
            ) : (
              ""
            )}

            <div className="input-wrapper">
              <BoolCheckbox
                containerStyles={css.inputGroup}
                label="Is Admin Field?"
                type="text"
                checked={this.state.isAdminField}
                onClick={this.handleAdminFieldChange}
              />
            </div>

            <div className="input-wrapper">
              <BoolCheckbox
                containerStyles={css.inputGroup}
                label="Is Read Only?"
                type="text"
                checked={this.state.readOnly}
                onClick={this.handleReadOnlyFieldChange}
              />
            </div>

            {/* Label */}
            <div className="input-wrapper">
              <Input
                containerStyles={css.inputGroup}
                label="Field Label"
                type="text"
                value={this.state.name}
                placeholder="Add label name here"
                required="required"
                onChange={this.handleNameChange}
              />
            </div>

            {/* Hint/Description Text */}
            <div className="input-wrapper">
              <TextArea
                containerStyles={css.inputGroup}
                label="Field Description Text"
                onChange={this.handleDescriptionChange}
                placeholder="Description..."
                rows={2}
                type="text"
                value={this.state.description}
              />
            </div>

            {/* Placeholder */}
            {["text", "dropdown", "email", "textarea"].includes(
              this.state.type
            ) ? (
              <div className="input-wrapper">
                <Input
                  containerStyles={css.inputGroup}
                  label="Field Placeholder"
                  onChange={this.handlePlaceholderChange}
                  placeholder="Add placeholder"
                  type="text"
                  value={this.state.placeholder}
                />
              </div>
            ) : (
              ""
            )}

            {/* Options: Dropdown */}
            {this.state.type === "dropdown" || this.state.type === "radio" ? (
              <DropdownOptions
                options={this.state.options}
                enableMultipleSelect={this.state.type === "dropdown"}
                allowMultipleSelect={!!this.state.allowMultipleSelect}
                updateDropdownAllowMultiple={this.updateDropdownAllowMultiple}
                updateDropdownOptions={this.updateDropdownOptions}
              />
            ) : (
              ""
            )}

            {/* Options: Date */}
            {this.state.type === "date" ? (
              <DateEditorSettings
                value={this.state}
                onChange={({ displayType }) => this.setState({ displayType })}
              />
            ) : (
              ""
            )}

            {this.state.type === "percent" ? (
              <PercentSettings
                value={this.state}
                onChange={({ percentFormat }) =>
                  this.setState({ percentFormat })
                }
              />
            ) : (
              ""
            )}

            {this.state.type === "calculated-number" ? (
              <CalculatedNumberSettings
                columns={fields}
                value={this.state}
                onChange={options => this.setState({ ...options })}
              />
            ) : (
              ""
            )}

            {this.state.type === "currency" ? (
              <CurrencySettings
                value={this.state}
                onChange={options => this.setState({ ...options })}
              />
            ) : (
              ""
            )}

            {/* Options: Date Time */}
            {this.state.type === "datetime" ? (
              <DateTimeEditorSettings
                value={this.state}
                onChange={({ useDateTimeTimezone }) =>
                  this.setState({ useDateTimeTimezone })
                }
              />
            ) : (
              ""
            )}

            {/* Options: Phone */}
            {this.state.type === "phone" ? (
              <PhoneEditorSettings
                value={this.state}
                disabled={
                  this.props.mode === "edit" &&
                  get(this.props, "section.schema.phoneDisplayType") !== "mask"
                }
                onChange={({ phoneDisplayType }) =>
                  this.setState({ phoneDisplayType })
                }
              />
            ) : (
              ""
            )}

            {/* Options: Contact */}
            {this.isContactField(this.state.type) ? (
              <div styleName="dropdownOptionsWrapper">
                <ContactSettings
                  settings={this.state.contactSettings}
                  updateContactSettings={this.updateContactSettings}
                />
                <ContactMappedToAccountSettings
                  value={this.state.contactSettings}
                  onChange={this.updateContactSettings}
                />
              </div>
            ) : (
              ""
            )}

            {/* Options: Mapped Fields */}
            {get(field, "id") ? (
              <div>
                <AdvancedSettings title="Parent Fields">
                  {canBeChildField ? (
                    <RelatedFields
                      availableFields={availableParentFields.filter(f => {
                        // remove if field that's being edited
                        if (mode === "edit" && field.id === f.id) {
                          return false;
                        }
                        // check if selected field type can be a child of this field
                        if (
                          get(fieldType, "canBeChildOfFieldTypes").includes(
                            "all"
                          ) ||
                          get(fieldType, "canBeChildOfFieldTypes").includes(
                            f.type
                          )
                        ) {
                          // check if field can be parent field
                          return get(
                            getMappableFieldSettings(f.type),
                            "canBeParentField"
                          );
                        }
                        return false;
                      })}
                      columns={fields}
                      columnType={type}
                      relationships={parentFields}
                      updateFieldRelationship={(fieldId, toggle) => {
                        this.updateFieldRelationship("parent", fieldId, toggle);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {!canBeChildField ? (
                    <div>This field cannot be a child field.</div>
                  ) : (
                    ""
                  )}
                </AdvancedSettings>

                <AdvancedSettings title="Child Fields">
                  {canBeParentField ? (
                    <RelatedFields
                      availableFields={availableChildFields.filter(c => {
                        // remove if field that's being edited
                        if (mode === "edit" && field.id === c.id) {
                          return false;
                        }

                        // If this field type can have grid children
                        const childGridFieldTypes = get(
                          getMappableFieldSettings(field.type),
                          "canBeParentOfGridFieldTypes",
                          []
                        );
                        if (c.origin === "grid") {
                          // Check it's allowed types for `c` fields type
                          return childGridFieldTypes.includes(c.type);
                        }

                        // Otherwise, check if `c` field can be a child of the the current field
                        return get(
                          getMappableFieldSettings(c.type),
                          "canBeChildField"
                        );
                      })}
                      columns={fields}
                      columnType={type}
                      relationships={childFields}
                      updateFieldRelationship={(fieldId, toggle) => {
                        this.updateFieldRelationship("child", fieldId, toggle);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {!canBeParentField ? (
                    <div>This field cannot be a parent field.</div>
                  ) : (
                    ""
                  )}
                </AdvancedSettings>

                <AdvancedSettings title="Mapped Fields">
                  {hasMappableFields ? (
                    <MappedFields
                      fields={availableChildFields.filter(
                        f => f.origin === "form"
                      )}
                      parentFieldId={field.id}
                      mappableFields={mappableFields}
                      mappedFields={mappedFields}
                      updateMappedFields={this.updateMappedFields}
                    />
                  ) : (
                    ""
                  )}
                  {!hasMappableFields ? (
                    <div>There are no available mappable fields.</div>
                  ) : (
                    ""
                  )}
                </AdvancedSettings>
              </div>
            ) : (
              ""
            )}

            {/* Error */}
            {error ? <div styleName="error">{error}</div> : ""}

            <div styleName="actionsWrapper">
              <button type="submit" styleName="actionSave">
                Save
              </button>
              <button
                type="button"
                styleName="actionCancel"
                onClick={this.props.hideModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

FormQuestionModal.propTypes = {
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  index: PropTypes.number,
  dispatch: PropTypes.func,
  hideModal: PropTypes.func,
  styles: PropTypes.object,
  fieldTypes: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  availableChildFields: PropTypes.array.isRequired,
  availableParentFields: PropTypes.array.isRequired,
  field: PropTypes.object,
  mode: PropTypes.string
};

FormQuestionModal.getModalClassName = () => "OptionsModal";

export default FormQuestionModal;
