import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "../index";

const decorate = createContext({
  module,
  options: {
    persist: false
  }
});

export default decorate(Layout);
