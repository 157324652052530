import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/AddQuestionSetModal";

import { HEIGHTS } from "Items/AddQuestionSetModal/constants";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(
  state => ({
    name: getters.name(state),
    saving: getters.saving(state)
  }),
  {
    save: actions.save
  }
);

const Footer = ({ hideModal, save, name, onDone, saving }) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={HEIGHTS.FOOTER}
  >
    <BigFilledButton
      bg="altB4"
      color="white"
      width={268}
      onClick={() => save({ onDone, hideModal })}
      disabled={saving || name.length < 2}
    >
      {saving ? "Saving..." : "Save"}
    </BigFilledButton>
    <BigOutlineButton width={121} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
