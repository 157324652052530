import React from "react";
import { Div, LoadingIcon } from "components/Base";
import { connect } from "react-redux";
import { getters } from "SalesPortal/Home";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Loading = ({ loading, children }) =>
  loading ? (
    <Div width={1} height={1} display="row.center.center">
      <LoadingIcon size={50} color="gray5" />
    </Div>
  ) : (
    <>{children || null}</>
  );

export default decorate(Loading);
