import { createSelector } from "reselect";

import * as R from "ramda";
import moment from "moment";

import { getters } from "Portal/PortalPagePGA/PortalPagePGA/model";

import { ICON_SPECS, ICONS_FALLBACK } from "./constants";

export const sessionDates = createSelector(
  getters.sessions,
  R.map(session => moment(session.date).format("M/D"))
);

export const getHasEnabledPhoneNumber = createSelector(
  getters.preferences,
  R.prop("has_enabled_phone_number")
);

export const getHasEnabledUpdateNotifications = createSelector(
  getters.preferences,
  R.prop("has_enabled_update_notifications")
);

export const newsAndAlertsDates = createSelector(
  getters.alerts,
  R.map(alert => moment(alert.date).format("M/D"))
);

const resolveConferenceLogoUrl = (urls = []) => {
  const urlsToUse = urls.filter(u => u);
  if (!urlsToUse.length) {
    return null;
  }
  if (
    urlsToUse.some(url => url.includes("webex")) ||
    urlsToUse.some(url => url.includes("cisco"))
  ) {
    return {
      width: 101,
      height: 29.36,
      src: "https://d2dks4tzxs6xee.cloudfront.net/img/webex-cisco-new2.png"
    };
  } else if (urlsToUse.some(url => url.includes("zoom"))) {
    return {
      height: 26,
      src: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/zoom-logo.png"
    };
  }
  return null;
};

export const filteredSessionsBySelection = createSelector(
  getters.sessions,
  getters.preferences,
  getters.selectedSessionDate,
  (sessions, preferences, selectedSessionDate) =>
    R.compose(
      R.map(s => ({
        ...s,
        subscribed_to_notifications: R.path(["session_notifications", s.id])(
          preferences
        ),
        conferenceLogoUrl: resolveConferenceLogoUrl([
          s.conference_url,
          s.video_url
        ])
      })),
      R.pathOr([], [selectedSessionDate, "sessions"])
    )(sessions)
);

export const filteredNewsAndAlertsBySelection = createSelector(
  getters.alerts,
  getters.selectedNewsAndAlertsDate,
  (alerts, selectedNewsAndAlertsDate) =>
    R.pathOr([], [selectedNewsAndAlertsDate, "items"], alerts)
);

//

export const getIntercomId = createSelector(
  getters.settings,
  R.prop("intercom_id")
);

export const getIntercomMethod = createSelector(
  getters.settings,
  R.prop("intercom_method")
);

export const getLeftSidebarColor = createSelector(
  getters.settings,
  R.prop("left_sidebar_color")
);

export const getLinkColor = createSelector(
  getters.settings,
  R.prop("link_color")
);

export const getLeftSidebarSecondaryColor = createSelector(
  getters.settings,
  R.prop("left_sidebar_secondary_color")
);

export const getLeftSidebarLogoImageUrl = createSelector(
  getters.settings,
  R.prop("left_sidebar_logo_image_url")
);

export const getInterviewScheduleTitle = createSelector(
  getters.settings,
  R.prop("interview_schedule_title")
);

export const getInterviewScheduleSubtitle = createSelector(
  getters.settings,
  R.prop("interview_schedule_subtitle")
);

export const getInterviewReplaysUrl = createSelector(
  getters.settings,
  R.prop("watch_interview_replays_url")
);

export const getEnableRequestInterviewButton = createSelector(
  getters.settings,
  R.prop("enable_request_interview_button")
);

//

export const getVideoStreams = createSelector(
  getters.settings,
  R.propOr([], "video_streams")
);

//

export const getEventTitle = createSelector(
  getters.settings,
  R.prop("event_title")
);

export const getEventSubtitle = createSelector(
  getters.settings,
  R.prop("event_subtitle")
);

export const getEventLogoUrl = createSelector(
  getters.settings,
  R.prop("event_logo_url")
);

export const getContentSection1Label = createSelector(
  getters.settings,
  R.prop("content_section_1_label")
);

export const getContentSection1Type = createSelector(
  getters.settings,
  R.prop("content_section_1_type")
);

export const getContentSection1Link = createSelector(
  getters.settings,
  R.prop("content_section_1_link")
);

export const getContentSection1Content = createSelector(
  getters.settings,
  R.prop("content_section_1_content")
);

export const getContentSection2Label = createSelector(
  getters.settings,
  R.prop("content_section_2_label")
);

export const getContentSection2Type = createSelector(
  getters.settings,
  R.prop("content_section_2_type")
);

export const getContentSection2Link = createSelector(
  getters.settings,
  R.prop("content_section_2_link")
);

export const getContentSection2Content = createSelector(
  getters.settings,
  R.prop("content_section_2_content")
);

export const getContentSection3Label = createSelector(
  getters.settings,
  R.prop("content_section_3_label")
);

export const getContentSection3Type = createSelector(
  getters.settings,
  R.prop("content_section_3_type")
);

export const getContentSection3Link = createSelector(
  getters.settings,
  R.prop("content_section_3_link")
);

export const getContentSection3Content = createSelector(
  getters.settings,
  R.prop("content_section_3_content")
);

export const getContentSection = createSelector(
  getters.settings,
  (_, props) => R.prop("params")(props),
  (settings, params) => {
    return {
      label: R.prop(`content_section_${params.sectionId}_label`)(settings),
      content: R.prop(`content_section_${params.sectionId}_content`)(settings)
    };
  }
);

//

export const getEnableAlertsSection = createSelector(
  getters.settings,
  R.prop("enable_alerts_section")
);

export const getAlertsTitle = createSelector(
  getters.settings,
  R.prop("alerts_title")
);

export const getAlertsSubtitle = createSelector(
  getters.settings,
  R.prop("alerts_subtitle")
);

export const getAlertsViewAllLabel = createSelector(
  getters.settings,
  R.prop("alerts_view_all_label")
);

//

export const getEnableSocialSection = createSelector(
  getters.settings,
  R.prop("enable_social_section")
);

export const getSocialTitle = createSelector(
  getters.settings,
  R.prop("social_title")
);

export const getSocialSubtitle = createSelector(
  getters.settings,
  R.prop("social_subtitle")
);

export const getSocialFeedId = createSelector(
  getters.settings,
  R.prop("social_feed_id")
);

export const getSocialFeedContainerId = createSelector(
  getters.settings,
  R.prop("social_feed_container_id")
);

//

export const getEnableVideosSection = createSelector(
  getters.settings,
  R.prop("enable_videos_section")
);

export const getVideosTitle = createSelector(
  getters.settings,
  R.prop("videos_title")
);

export const getVideosSubtitle = createSelector(
  getters.settings,
  R.prop("videos_subtitle")
);

export const getVideosViewAllLabel = createSelector(
  getters.settings,
  R.prop("videos_view_all_label")
);

//

export const getEventWebsiteUrl = createSelector(
  getters.settings,
  R.prop("event_website_url")
);

export const getRightSidebarLogoUrl = createSelector(
  getters.settings,
  R.prop("right_sidebar_logo_url")
);

export const getMobileTitleLine1 = createSelector(
  getters.settings,
  R.prop("mobile_title_line_1")
);

export const getMobileTitleLine2 = createSelector(
  getters.settings,
  R.prop("mobile_title_line_2")
);

//

export const getShowCountOfPeople = createSelector(
  getters.settings,
  R.prop("show_count_of_people")
);

export const getNetworkChatLabel = createSelector(
  getters.settings,
  R.prop("network_chat_label")
);

export const getNetworkPeopleLabel = createSelector(
  getters.settings,
  R.prop("network_people_label")
);

export const getEnablePolls = createSelector(
  getters.settings,
  R.prop("enable_polls")
);

export const getEnableQA = createSelector(
  getters.settings,
  R.prop("enable_qa")
);

export const getUseMaterialIcons = createSelector(
  getters.settings,
  R.prop("use_material_icons")
);

export const getUseEmbargoes = createSelector(
  getters.settings,
  R.prop("use_embargoes")
);

//

export const getRightSidebarLinks = createSelector(
  getters.settings,
  settings => {
    return R.compose(
      R.map(link => {
        const icon = ICON_SPECS[link.icon]
          ? ICON_SPECS[link.icon]
          : ICON_SPECS[ICONS_FALLBACK[link.icon]];

        return {
          ...link,
          label: link.label,
          url: link.value,
          count: null,
          imageUrl: R.prop("url")(icon),
          imageWidth: R.prop("width")(icon),
          imageHeight: R.prop("height")(icon),
          imageOpacity: R.prop("opacity")(icon),
          smallImageHeight: R.prop("smallHeight")(icon),
          smallImageWidth: R.prop("smallWidth")(icon)
        };
      }),
      R.propOr([], "right_sidebar_links")
    )(settings);
  }
);
