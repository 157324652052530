import React from "react";
import { connect } from "react-redux";
import { actions } from "../../model";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import MiniItemsSelector from "ui-kit/MiniItemsSelector/View";

import { noop } from "utils/General";

import { getEnabledPeopleTypeIds, getPeopleTypes } from "../../selectors";

const decorate = connect(
  state => ({
    peopleTypes: getPeopleTypes(state),
    assignedIds: getEnabledPeopleTypeIds(state)
  }),
  {
    updateEnabledPeopleTypes: actions.updateEnabledPeopleTypes,
    hideModal: () => actions.setShowPeopleTypesModal(false)
  }
);

const SelectPeopleTypesModal = ({
  assignedIds = [],
  hideModal = noop,
  peopleTypes = [],
  updateEnabledPeopleTypes = noop
}) => {
  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title="People Types"
      display="column.flex-start.stretch"
      width={845}
      height={600}
    >
      <MiniItemsSelector
        iniClearSearch
        itemGroups={[{ id: "peopleTypes", items: peopleTypes }]}
        iniSelected={assignedIds}
        onCancel={hideModal}
        onDone={updateEnabledPeopleTypes}
      />
    </MiniModalWrapper>
  );
};

export default decorate(SelectPeopleTypesModal);
