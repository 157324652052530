import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import MyHeader from "./MyHeader";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";

import * as EventsSelectors from "redux/modules/events/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    myEvents: EventsSelectors.events(state),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarActions,
      ...ModalActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MyHeader);
