import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import { getters } from "../model";
import { Div, SearchIcon } from "components/Base";
import { withProps } from "utils/General";
import AddItemsInfo from "./AddItemsInfo";
import CustomersResults from "./CustomersResults";
import SearchBar from "ui-kit/SearchBar/View";
import { SEARCH_BAR_INSTANCE } from "../constants";
import Loading from "ui-kit/Loading";

const SearchIconPurple = withProps({
  color: "primary7"
})(SearchIcon);

const decorate = connect(state => ({
  searchTerm: SearchBarGetters.searchTerm(state, {
    instanceId: SEARCH_BAR_INSTANCE
  }),
  loading: getters.loading(state)
}));

const CustomersPage = ({ handlers, searchTerm, onSelectCustomer, loading }) => (
  <Div p={8}>
    <SearchBar
      RightIcon={SearchIconPurple}
      width={1}
      placeholder="Enter the customer name (groups or people)..."
      instanceId={SEARCH_BAR_INSTANCE}
      placeholderWithDots={false}
    />
    {!loading ? (
      <>
        {R.isEmpty(searchTerm) ? (
          <AddItemsInfo handlers={handlers} />
        ) : (
          <CustomersResults
            onSelectCustomer={onSelectCustomer}
            handlers={handlers}
          />
        )}
      </>
    ) : (
      <Loading />
    )}
  </Div>
);

export default decorate(CustomersPage);
