import React, { Component } from "react";
import { withRouter } from "react-router";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (document.getElementById("scroll-top-on-change")) {
        document.getElementById(
          "scroll-top-on-change"
        ).parentNode.scrollTop = 0;
      }
    }
  }

  render() {
    return (
      <div
        id="scroll-top-on-change"
        style={{ height: "100%", overflowY: "visible" }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(ScrollToTop);
