import { registerError } from "redux/modules/errors/actions";
import {
  REQUEST,
  RECEIVE,
  RECEIVE_EVENT_HOME_ACCOUNT_RECORD_TYPES,
  GET_ERROR
} from "./constants";
import api from "./api";

export function getEvent(eventId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      let event = {
        id: eventId
      };
      try {
        event = await api.get(getState().user.user.credentials, eventId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("[Error: getEvent]:", err);
      }
      dispatch({ type: RECEIVE, payload: event });
      return event;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting event"
          }
        ])
      );
      dispatch({ type: GET_ERROR });
      return error;
    }
  };
}

export function addEvent(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.event;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred creating event"
          }
        ])
      );
      return error;
    }
  };
}

export function cloneEvent(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.clone(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred cloning event"
          }
        ])
      );
      return error;
    }
  };
}

export function getEventAccountRecordTypes(eventId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getEventAccountRecordTypes(
        getState().user.user.credentials,
        eventId
      );
      dispatch({
        type: RECEIVE_EVENT_HOME_ACCOUNT_RECORD_TYPES,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching account record types"
          }
        ])
      );
      return error;
    }
  };
}
