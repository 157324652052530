import React from "react";
import { connect } from "react-redux";

import { getters, actions } from "Orders/ImportModal";

import { Div, TinyToggle } from "components/Base";

import { noop } from "utils/General";

const decorate = connect(
  state => ({
    requireAssignment: getters.requireAssignment(state)
  }),
  {
    toggleRequireAssignment: actions.toggleRequireAssignment
  }
);

const RequireAssignment = ({
  requireAssignment = false,
  toggleRequireAssignment = noop
}) => (
  <Div display="column" px={5} mb={2} width={1}>
    <Div fs={4} fw={3} mb={2} color="neutral7">
      Collect Attendee Names
    </Div>
    <Div width={340} ml={5} py={2} mb={4}>
      <Div
        display="row.flex-start.center"
        onClick={() => toggleRequireAssignment(requireAssignment)}
      >
        <TinyToggle active={requireAssignment} onClick={noop} />
        <Div ml={2}>
          <Div fw={3} fs={2} color="gray7">
            {requireAssignment
              ? "Show on Assignment Manager"
              : "Don't show on Assignment Manager"}
          </Div>
          <Div fs={1} color="gray5">
            {requireAssignment
              ? "Items can be assigned to additional attendees"
              : "Order items will not show on the assignment manager"}
          </Div>
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(RequireAssignment);
