/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  refreshZoneAssignments: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/event/${eventId}/refresh-zone-assignments`,
        credentials,
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
