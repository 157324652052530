import React from "react";
import moment from "moment";
import * as R from "ramda";
import { Div, Text1, Text3 } from "components/Base";

const EventCard = ({
  name,
  start,
  end,
  location,
  color = "blue5",
  onClick,
  scheduleName,
  noTopMargin,
  todaysTime,
  isStartDate,
  isEndDate,
  goToSchedule
}) => {
  let displayString = "";
  if (isStartDate && isEndDate) {
    // is same day
    displayString = `${moment(start).format("h:mma")} - ${moment(end).format(
      "h:mma"
    )} `;
  } else if (isStartDate) {
    // is start day
    displayString = `${moment(start).format("h:mma")} (ends ${moment(
      end
    ).format("ddd, MMM D")})`;
  } else if (isEndDate) {
    // is end day
    displayString = `Ends ${moment(end).format("h:mma")}`;
  } else {
    // is a middle day
    displayString = `All day (ends ${moment(end).format("ddd, MMM D")})`;
  }

  const isGoingOn =
    moment(start).isBefore(moment()) &&
    moment(end).isAfter(moment()) &&
    moment(todaysTime).isSame(moment(), "day");

  return (
    <Div
      ml={3}
      mt={noTopMargin ? 0 : 1}
      bra={1}
      bg={color}
      pl={1}
      onClick={onClick}
    >
      <Div
        bg={onClick ? { default: "white", hover: "neutral0" } : "white"}
        py={2}
        px={3}
        bc="neutral2"
        ba={2}
        bl={0}
      >
        <Text3 bold>{name}</Text3>
        <Div pt={1 / 2} display="row.flex-start.center">
          <Text1>{displayString}</Text1>
          {!R.isNil(location) && <Text1 pl={1}>{`in ${location}`}</Text1>}
          {isGoingOn && (
            <Text1 color="primary7" pl={1}>
              In Progress
            </Text1>
          )}
          {scheduleName && (
            <Div display="row.flex-start.center">
              {scheduleName ? <Text1 pl={1}>·</Text1> : null}
              {scheduleName ? (
                <Text1
                  onClick={e => {
                    e.stopPropagation();
                    goToSchedule();
                  }}
                  underline={{ default: false, hover: true }}
                  color={color}
                  pl={1}
                >
                  {scheduleName}
                </Text1>
              ) : null}
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

export { EventCard };
