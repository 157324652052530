import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as R from "ramda";

import IntegrationSuccessFailModal from "components/Event/Settings/Credentials/Integrations/Eventbrite/Modals/IntegrationSuccessFail";
import SelectEventbriteEventModal from "components/Event/Settings/Credentials/Integrations/Eventbrite/Modals/SelectEvent";
import SelectEventModal from "Passes/SetupIntegrations/Modals/SelectEvent";

import View from "./View";
import CircularProgress from "material-ui/CircularProgress";
import {
  EVENTBRITE_LOGO_SRC,
  FRONTGATE_LOGO_SRC,
  GINGERBREAD_SHED_LOGO_SRC,
  INTELLITIX_LOGO_SRC,
  GLOWNET_LOGO_SRC,
  TOKEN_LOGO_SRC
} from "components/Event/Settings/Credentials/constants";
import TicketingWrapper from "components/Event/Settings/Credentials/Integrations/Ticketing/TicketingWrapper";
import { INTEGRATIONS as INTEGRATIONS_LIST } from "utils/integration-types";
import MapPassTypesModal from "components/Event/Settings/Credentials/Integrations/Ticketing/Modals/EditIntegration";

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    if (this.props.location.query.eventbrite_auth_status) {
      this.showIntegrationCallbackModal(
        this.props.location.query.eventbrite_auth_status === "success"
          ? true
          : false
      );
    }

    Promise.all([
      this.props.getTicketingProviders({}),
      this.props.getTicketingProviderConfigurations(this.props.eventDetails.id)
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  showIntegrationCallbackModal = success => {
    this.props.showModal({
      content: (
        <IntegrationSuccessFailModal
          success={success}
          onSuccess={() => this.showSelectEventbriteEventModal()}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSelectEventbriteEventModal = () => {
    this.props.router.replace({
      pathname: `/event/${this.props.params.eventId}/passes/setup/integrations`
    });
    const provider = this.props.ticketingProviders.find(
      p => p.id === INTEGRATIONS_LIST.eventbrite.id
    );
    const configuration = this.props.ticketingProviderConfigurations.find(
      p => p.provider.id === INTEGRATIONS_LIST.eventbrite.id
    );

    this.props.showModal({
      content: (
        <SelectEventbriteEventModal
          provider={provider}
          orgId={configuration.settings.eventbriteOrganizationId}
          eventId={configuration.settings.eventbriteEventId}
          onDone={async ({ resetProviderMapping = false }) => {
            await this.props.getTicketingProviderConfigurations(
              this.props.eventDetails.id
            );

            if (resetProviderMapping) {
              await this.props.deleteItemProviderId({
                eventId: this.props.params.eventId,
                providerId: provider.id
              });
            }

            this.showMapIntegrationPassTypesModal(configuration);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSelectEventModal = providerId => {
    const provider = this.props.ticketingProviders.find(
      p => p.id === providerId
    );
    const configuration = this.props.ticketingProviderConfigurations.find(
      p => p.provider.id === providerId
    );

    this.props.showModal({
      content: (
        <SelectEventModal
          provider={provider}
          providerConfiguration={configuration}
          onDone={async ({ resetProviderMapping = false }) => {
            await this.props.getTicketingProviderConfigurations(
              this.props.eventDetails.id
            );

            if (resetProviderMapping) {
              await this.props.deleteItemProviderId({
                eventId: this.props.params.eventId,
                providerId: provider.id
              });
            }

            this.showMapIntegrationPassTypesModal(configuration);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showMapIntegrationPassTypesModal = configuration => {
    this.props.showModal({
      content: <MapPassTypesModal providerConfiguration={configuration} />,
      wrapper: ModalWrapper
    });
  };

  connectIntegration = provider => this.props.getAuth1stStep(provider);

  render() {
    const {
      ticketingProviders,
      ticketingProviderConfigurations,
      loading,
      ticketTypes
    } = this.props;

    if (loading) {
      return <CircularProgress color="#ccc" />;
    }

    const connectionExists = Boolean(ticketingProviderConfigurations.length);

    const TICKETING_PROVIDERS = [
      {
        id: "eventbrite",
        name: "Eventbrite",
        icon: EVENTBRITE_LOGO_SRC,
        color: "orange5",
        Wrapper: TicketingWrapper,
        enableEvent: true,
        enableMapping: true,
        enableSettings: false,
        onEditEvent: this.showSelectEventbriteEventModal,
        visible: true
      },
      {
        id: "frontgate",
        name: "Front Gate Tickets",
        icon: FRONTGATE_LOGO_SRC,
        color: "orange5",
        Wrapper: TicketingWrapper,
        enableEvent: false,
        enableMapping: true,
        enableSettings: false,
        onEditEvent: undefined
      },
      {
        id: "intellitix",
        name: "Intellitix",
        icon: INTELLITIX_LOGO_SRC,
        color: "green7",
        Wrapper: TicketingWrapper,
        enableEvent: false,
        enableMapping: true,
        enableSettings: false,
        onEditEvent: undefined,
        visible: true
      },
      {
        id: "gingerbread",
        name: "Elevate Tickets",
        icon: GINGERBREAD_SHED_LOGO_SRC,
        color: "white",
        Wrapper: TicketingWrapper,
        enableEvent: false,
        enableMapping: true,
        enableSettings: true,
        onEditEvent: undefined,
        visible: true
      }
      /*
      {
        id: "glownet",
        name: "Glownet",
        icon: GLOWNET_LOGO_SRC,
        color: "white",
        Wrapper: TicketingWrapper,
        enableEvent: true,
        enableMapping: true,
        enableSettings: true,
        onEditEvent: this.showSelectEventModal,
        visible: true
      },
      {
        id: "token",
        name: "Token",
        icon: TOKEN_LOGO_SRC,
        color: "white",
        Wrapper: TicketingWrapper,
        enableEvent: true,
        enableMapping: true,
        enableSettings: true,
        onEditEvent: this.showSelectEventModal,
        visible: true
      }
      */
    ];

    const ticketingProvidersWithHandlers = R.compose(
      R.map(
        ({
          id,
          name,
          icon,
          color,
          Wrapper,
          enableEvent,
          enableMapping,
          enableSettings,
          onEditEvent
        }) => {
          let shouldEnableMapping = enableMapping;
          const provider = ticketingProviders.find(i => i.slug === id) || {
            name
          };
          const notices = [];

          const providerConfiguration = ticketingProviderConfigurations.find(
            config => provider && config.provider.id === provider.id
          );

          if (providerConfiguration) {
            const user = providerConfiguration.createdBy;
            if (user) {
              notices.push(
                `Connected As: ${[user.fname, user.lname].join(" ")}`
              );
            }

            /*
          if (
            providerConfiguration.synchronizationEvents &&
            providerConfiguration.synchronizationEvents.length > 0
          ) {
            notices.push(
              `Last Synced: ${moment(
                providerConfiguration.synchronizationEvents[0]
              ).fromNow()}`
            );
          } else {
            notices.push("Not synced yet");
          }
          */

            if (
              providerConfiguration.provider.id ===
                INTEGRATIONS_LIST.eventbrite.id &&
              (!providerConfiguration.settings.eventbriteEventId ||
                !providerConfiguration.settings.eventbriteEventId.length)
            ) {
              shouldEnableMapping = false;
              notices.push("No event selected yet");
            }
          }

          const activated = Boolean(providerConfiguration);

          return {
            id,
            icon,
            color,
            name,
            activated,
            disabled: connectionExists && !activated,
            onConnect: provider
              ? () => this.connectIntegration(provider)
              : undefined,
            notices,
            Wrapper,
            provider,
            providerConfiguration,
            //
            enableEvent,
            enableMapping: shouldEnableMapping,
            enableSettings,
            //
            onEditEvent: onEditEvent
              ? () => onEditEvent(provider.id)
              : undefined
          };
        }
      ),
      R.sortBy(p => p.name.toLowerCase())
    )(TICKETING_PROVIDERS);

    return (
      <View
        {...{
          ticketingProviders: ticketingProvidersWithHandlers,
          ticketTypes
        }}
      />
    );
  }
}

export default Controller;
