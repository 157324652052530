import React from "react";
import { createContext } from "redux-mvc";
import Layout from "./Layout";
import { showModal } from "redux/modules/modal/actions";
import * as R from "ramda";
import { connect } from "react-redux";

import module from "../index";
import { actions } from "../model";

import EditCollaboratorInfoModal from "components/Global/Modals/EditCollaboratorInfoModal";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";

const decorate = R.compose(
  connect(
    null,
    {
      showModal
    }
  ),
  createContext({
    module,
    handlers: {
      showEditUserModal(user) {
        const modal = (
          <EditCollaboratorInfoModal
            showPermissions
            userId={user.user_id}
            onUpdated={() => {
              this.store.dispatch(actions.refreshData());
            }}
          />
        );

        this.props.showModal({ content: modal });
      },
      showAddEventTeamUserModal() {
        const modal = (
          <AddEventTeamUserModal
            addToEventId={this.props.eventDetails.id}
            onAdded={() => {
              this.store.dispatch(actions.refreshData());
            }}
          />
        );

        this.props.showModal({ content: modal });
      }
    },
    options: {
      persist: false
    }
  })
);

export default decorate(Layout);
