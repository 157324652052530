import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import {
  AddPersonIcon,
  BigOutlineButton,
  CheckIcon,
  CloseIcon,
  Div,
  ExpandVertIcon,
  HistoryIcon,
  ReplyIcon,
  QuarterSizeDotIcon,
  SmallTextButton,
  Span,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  MediumOutlineButton,
  SuperShadedButton,
  LoadingIcon
} from "components/Base";
import { withProps } from "utils/General";
import TimeKeeper from "./TimeKeeper";
import Background from "components/Event/FormsV2/Sections/FormBackground";
import {
  Navbar,
  Navatar,
  NavText
} from "components/PortalV2/GroupManager/Navbar";
import FocusedInput from "Passes/Checkin/IssuanceModal/FocusedInput";
import moment from "moment";

const Box = withProps({ p: 3, bg: "white", bra: 1 })(Div);
const TinyText = withProps({ fs: 0 })(Span);
const InactiveButton = withProps({ bg: "neutral0", disabled: true })(
  BigOutlineButton
);

const Heading = ({ children, Icon }) => (
  <Div pb={3} display="row.inline-flex.center">
    {Boolean(Icon) && <Icon color="white" sizeWFS={4} mr={3} />}
    <Text4 color="white" bold>
      {children}
    </Text4>
  </Div>
);

const Status = ({ isPlaceholder = false, message, success = false }) => [
  <Div
    bra={3}
    bg={isPlaceholder ? "neutral2" : success ? "success5" : "danger7"}
    display="column.center.center"
    style={{ height: 185 }}
  >
    {isPlaceholder ? (
      ""
    ) : success ? (
      <CheckIcon title="Success" color="white" size={180} />
    ) : (
      [
        <CloseIcon title="Failed" color="white" size={150} />,
        message ? (
          <Div fs={3} fw={4} color="white" pb={2}>
            {message}
          </Div>
        ) : null
      ]
    )}
  </Div>
];

const AvatarWithName = ({ name, initials, subname, scanQuery }) => (
  <Div display="row.flex-start.center" flex={1}>
    <Div pill size={40} mr={2} bg="gray4" display="row.center.center">
      <Text5 color="white" uppercase fs={10} bold>
        {initials || "-"}
      </Text5>
    </Div>
    <Div display="column.flex-start.flex-start">
      <Text4 bold truncate maxWidth={200} title={name}>
        {name}
      </Text4>
      {subname ? (
        <TinyText color="gray5" fw={3} uppercase>
          {subname}
        </TinyText>
      ) : null}
      {scanQuery ? (
        <TinyText color="gray5" fw={3} uppercase>
          {scanQuery}
        </TinyText>
      ) : null}
    </Div>
  </Div>
);

const Header = ({ cateringUrl, user }) => (
  <Navbar>
    <Div display="row.flex-start.center">
      <Link
        to={{ pathname: cateringUrl }}
        style={{ display: "flex", alignItems: "center" }}
      >
        <ReplyIcon sizeWFS={2} color="white" mr={2} />
      </Link>
      <TimeKeeper>
        {({ now }) => <NavText color="white">{now}</NavText>}
      </TimeKeeper>
    </Div>
    <Div display="row.flex-end.center">
      <NavText pr={1} display={["none", "none", "flex"]}>
        Logged in as
      </NavText>
      <NavText bold>{user.name}</NavText>
      <Navatar content={user.initials} />
    </Div>
  </Navbar>
);

export const ContactCard = ({
  initials,
  mealsConsumed,
  mealsAvailable,
  message,
  name,
  accountName,
  onOverride,
  onUndo,
  overridden,
  overrideReason,
  status,
  sourceQuery,
  undone,
  scanTimestamp
}) => (
  <Div py={3} bc="neutral1" bb={1}>
    <Div display="row.flex-start.center" flex={1} style={{ flexWrap: "wrap" }}>
      <AvatarWithName
        name={name}
        initials={initials}
        subname={accountName}
        scanQuery={sourceQuery}
      />
      <Div display="row.flex-start.center">
        <Div display="column.flex-end.flex-end">
          {resolveBadge(status, undone, overridden)}
          <Div display="row.flex-end.center">
            {mealsConsumed && mealsAvailable > 0 ? (
              <TinyText fw={3} pl={12} pt={1} color="gray5">
                {mealsConsumed} / {mealsAvailable}
              </TinyText>
            ) : null}
            {Boolean(message) ? (
              <Div display="row.flex-end.center">
                {mealsConsumed && mealsAvailable > 0 ? (
                  <QuarterSizeDotIcon size={10} />
                ) : null}
                <TinyText fw={3} pt={1} color="gray5">
                  {message}
                </TinyText>
              </Div>
            ) : null}
          </Div>
          <TinyText fw={3} pl={12} pt={1} color="gray5">
            {moment(scanTimestamp)
              .local()
              .format("h:mm A, MMM D")}
          </TinyText>
        </Div>
        <Div width={120} display="row.flex-end.center">
          {resolveAction(status, undone, overridden, {
            onUndo,
            onOverride
          })}
        </Div>
      </Div>
    </Div>
    {Boolean(overrideReason) ? (
      <Div display="column">
        {Boolean(overrideReason) && (
          <TinyText fw={3} pl={12} pt={1} maxWidth={420}>
            <Span uppercase>Override Note:</Span> {overrideReason}
          </TinyText>
        )}
      </Div>
    ) : null}
  </Div>
);

const resolveBadge = (status, undone, overridden) => {
  const Badge = withProps({
    bra: 1,
    px: 2,
    py: 1,
    display: "row.center.center"
  })(Div);
  if (undone) {
    return (
      <Badge bg="neutral2">
        <TinyText fw={3} color="neutral6" uppercase>
          Action Reversed
        </TinyText>
      </Badge>
    );
  }
  if (overridden) {
    return (
      <Badge bg="success1">
        <TinyText fw={3} color="success6" uppercase>
          Added via Override
        </TinyText>
      </Badge>
    );
  }
  switch (status) {
    case "success":
      return (
        <Badge bg="success1">
          <TinyText fw={3} color="success6" uppercase>
            Successful
          </TinyText>
        </Badge>
      );
    default:
      return (
        <Badge bg="danger2">
          <TinyText fw={3} color="danger6" uppercase>
            Denied
          </TinyText>
        </Badge>
      );
  }
};

const resolveMealsRemaining = (mealsConsumed, mealsAvailable) => {
  const Badge = withProps({
    bra: 1,
    px: 2,
    py: 1,
    display: "row.center.center"
  })(Div);

  let bgColor = "success1";
  let fontColor = "success6";

  const remainingCount = mealsAvailable - mealsConsumed;
  if (remainingCount < 5) {
    bgColor = "danger1";
    fontColor = "danger6";
  } else if (remainingCount < 10) {
    bgColor = "warning1";
    fontColor = "warning6";
  }

  return (
    <Badge bg={bgColor}>
      <TinyText fw={3} color={fontColor} uppercase>
        {remainingCount} remaining
      </TinyText>
    </Badge>
  );
};

const resolveAction = (status, undone, overridden, { onOverride, onUndo }) => {
  if (undone || overridden) {
    return undefined;
  }
  if (status === "success") {
    return <BigOutlineButton onClick={onUndo}>Undo</BigOutlineButton>;
  }
  if (onOverride) {
    return <BigOutlineButton onClick={onOverride}>Override</BigOutlineButton>;
  }
  return undefined;
};

const ActionBar = ({
  modalIsActive,
  activeMeal,
  onScan,
  showMealPickerModal,
  showSearchModal
}) => (
  <Div mb={8} display="row.flex-=start.center">
    <Box
      flex={1}
      py={0}
      display="row.flex-start.center"
      style={{ flexWrap: "wrap" }}
    >
      <Div
        width={[1, 1, 293]}
        py={3}
        br={[0, 0, 2]}
        bc="gray3"
        display="row.space-between.center"
        bb={[1, 1, 0]}
      >
        <Div display="column" style={{ maxWidth: 225 }}>
          <TinyText fw={3} color="gray5" uppercase>
            Currently scanning:
          </TinyText>
          <Text3 bold truncate>
            {activeMeal
              ? `${activeMeal.subname}: ${activeMeal.name}`
              : "Select a meal..."}
          </Text3>
        </Div>
        <MediumOutlineButton
          onClick={showMealPickerModal}
          mr={3}
          OnlyIcon={ExpandVertIcon}
        />
      </Div>
      <Div py={3} pl={[0, 0, 3]} display="row.flex-start.center" flex={1}>
        <FocusedInput onSubmit={onScan} allowFocus={!modalIsActive} />
      </Div>
    </Box>
    <Div
      bg={{ default: "white", hover: "neutral1" }}
      ml={2}
      onClick={showSearchModal}
      height={56}
      width={56}
      display="column.center.center"
      bra={1}
    >
      <AddPersonIcon size={30} color="neutral5" />
    </Div>
  </Div>
);

const CateringCheckingView = ({
  activeMeal,
  backgroundUrl,
  cateringUrl,
  checkinsCount,
  // eventId,
  latestScan,
  list = [],
  // notifyModalActive,
  modalIsActive,
  onOverridesFilterClick,
  onScan,
  onViewAllScans,
  overridesCount,
  scanning,
  user,
  showMealPickerModal,
  showSearchModal
}) => (
  <div>
    <Background
      backgroundUrl={backgroundUrl}
      style={{ backgroundColor: "#A7A7A7", zIndex: -1, height: 405 }}
      shadow
    />
    <Header cateringUrl={cateringUrl} user={user} />
    <Div
      pb={10}
      style={{
        zIndex: 1,
        maxWidth: 900,
        width: "90%",
        margin: "205px auto 0"
      }}
    >
      <ActionBar
        activeMeal={activeMeal}
        showMealPickerModal={showMealPickerModal}
        showSearchModal={showSearchModal}
        onScan={onScan}
        modalIsActive={modalIsActive}
      />
      <Div display="row.flex-start.flex-start" style={{ flexWrap: "wrap" }}>
        {/* {Boolean(latestScan.id) && ( */}
        <Div width={[1, 1, 290]} mr={[0, 0, 6]}>
          <Heading>Latest Scan Result</Heading>
          <Box flex={1} style={{ minHeight: 373 }}>
            {scanning ? (
              <Div
                display="column.center.center"
                style={{
                  paddingTop: 130
                }}
              >
                <LoadingIcon size={60} color="neutral4" />
                <Div color="neutral5" mt={3}>
                  Scanning...
                </Div>
              </Div>
            ) : (
              <span>
                <Status
                  isPlaceholder={!Boolean(latestScan.id)}
                  message={latestScan.message}
                  success={latestScan.success}
                />
                <Div mt={3} bt={1} bb={1} bc="gray1" py={3}>
                  <AvatarWithName
                    name={latestScan.name}
                    initials={latestScan.initials}
                    subname={
                      latestScan.accountName !== latestScan.name
                        ? latestScan.accountName
                        : null
                    }
                  />
                  <Text1 bold py={2} pl={12}>
                    ID: {latestScan.source}
                  </Text1>
                </Div>
                {latestScan.mealsConsumed &&
                latestScan.mealsAvailable &&
                latestScan.mealsAvailable > 0 ? (
                  <Div py={3} display="column.flex-start.flex-start">
                    <TinyText uppercase fw={3}>
                      Meals Usage
                    </TinyText>
                    <Div py={3} display="row.flex-start.flex-start">
                      <Text2 pr={3}>
                        {latestScan.mealsConsumed} of{" "}
                        {latestScan.mealsAvailable}
                      </Text2>
                      {resolveMealsRemaining(
                        latestScan.mealsConsumed,
                        latestScan.mealsAvailable
                      )}
                    </Div>
                  </Div>
                ) : null}
                <Div py={3} display="row.space-between.center">
                  <Div pr={3} title={latestScan.scannedAtFormatted}>
                    <TinyText uppercase fw={3}>
                      Scanned:
                    </TinyText>
                    {latestScan.id ? (
                      <TimeKeeper fromNow={latestScan.scannedAt}>
                        {({ fromNow }) => <Text2>{fromNow}</Text2>}
                      </TimeKeeper>
                    ) : (
                      ""
                    )}
                  </Div>
                  <Div flex={1}>
                    <TinyText uppercase fw={3}>
                      Scanned By:
                    </TinyText>
                    <Text2>{latestScan.scannedBy}</Text2>
                  </Div>
                </Div>
              </span>
            )}
          </Box>
          <Box
            mt={3}
            display="row.space-between.center"
            onClick={onViewAllScans}
            shadow={{ default: 0, hover: 2 }}
            transition="fast"
            style={{ cursor: "pointer" }}
          >
            <TinyText uppercase fw={3}>
              Checkins this meal:
            </TinyText>
            <Text3>{checkinsCount}</Text3>
          </Box>
          <Box
            mt={3}
            display="row.space-between.center"
            onClick={onOverridesFilterClick}
            shadow={{ default: 0, hover: 2 }}
            transition="fast"
            style={{ cursor: "pointer" }}
          >
            <TinyText uppercase fw={3}>
              Manager Overrides:
            </TinyText>
            <Text3>{overridesCount}</Text3>
          </Box>
        </Div>
        {/* )} */}
        <Div flex={1} mt={[3, 3, 0]}>
          <Div display="row.space-between.center">
            <Heading Icon={HistoryIcon}>Recent Scans</Heading>
          </Div>
          <Box px={[2, 2, 6]}>
            {list.length ? (
              <Fragment>
                {list.map(p => (
                  <ContactCard key={p.id} {...p} />
                ))}
                <SuperShadedButton
                  onClick={onViewAllScans}
                  display="row.center.center"
                >
                  View all scans
                </SuperShadedButton>
              </Fragment>
            ) : (
              <Text4 bold display="row.center.center" py={5}>
                No recent scans
              </Text4>
            )}
          </Box>
        </Div>
      </Div>
    </Div>
  </div>
);

CateringCheckingView.propTypes = {
  onOverridesFilterClick: PropTypes.func.isRequired,
  backgroundUrl: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      initials: PropTypes.string,
      message: PropTypes.string,
      name: PropTypes.string,
      onOverride: PropTypes.func.isRequired,
      onUndo: PropTypes.func.isRequired,
      status: PropTypes.oneOf(["success", "denied"])
    })
  ),
  latestScan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    initials: PropTypes.string,
    message: PropTypes.string,
    name: PropTypes.string,
    onOverride: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    success: PropTypes.bool,
    scannedAt: PropTypes.string,
    scannedAtFormatted: PropTypes.string,
    scannedBy: PropTypes.string
  })
};

export default CateringCheckingView;
