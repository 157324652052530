/* eslint-disable no-underscore-dangle */

import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { WithFormInstanceProvider, RadioList } from "ui-kit/Form/View";
import { FORM_INSTANCE_ID, FORM_RADIO_ID, BILLING_OPTIONS } from "../constants";
import { withRouter } from "react-router";
import { Div, LeftIcon, SuperFilledButton } from "components/Base";
import Loading from "ui-kit/Loading";

import { actions, getters } from "../model";
import { getFieldsValidated } from "../selectors";

import Row from "./Form/Row";
import Field from "./Form/Field";
import Label from "./Form/Label";
import Section from "./Form/Section";

import StripeScriptLoader from "Orders/PaymentModal/StripeScriptLoader";
import { StripeProvider, Elements } from "react-stripe-elements";

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";

const PAYMENT_FIELD_STYLE = {
  border: "1px solid rgb(231, 230, 234)",
  color: "rgb(73, 73, 73)",
  backgroundColor: "rgb(255, 255, 255)",
  width: "100%",
  fontWeight: 600,
  fontSize: "14px",
  borderRadius: "4px",
  padding: "9px 8px",
  boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 3px inset"
};

const getFieldsRefs = R.map(({ id }) => ({ id, ref: React.createRef() }));

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      loading: getters.loading(state, props),
      validatedFields: getFieldsValidated(state, props),
      saving: getters.saving(state, props),
      invalidIds: getters.invalidIds(state),
      error: getters.error(state, props)
    }),
    {
      init: actions.init,
      submitForm: actions.submitForm,
      onPaymentFieldChange: actions.onPaymentFieldChange
    }
  ),
  WithFormInstanceProvider(R.always(FORM_INSTANCE_ID))
);

const CheckoutBody = ({
  submitForm,
  fields,
  validatedFields,
  onPaymentFieldChange,
  saving,
  error,
  stripe,
  init,
  loading,
  items,
  total,
  invalidIds,
  paymentsEnabled,
  router: { params, push }
}) => {
  useEffect(() => {
    init({ paymentsEnabled, fields, total });
  }, [fields, fields]);

  const fieldsRefs = getFieldsRefs(validatedFields);

  const scrollToInvalidField = () => {
    if (!R.isEmpty(invalidIds)) {
      const firstInvalidId = invalidIds[0];
      const invalidField = R.find(
        field => field.id === firstInvalidId,
        fieldsRefs
      );

      if (invalidField.ref.current) {
        invalidField.ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(scrollToInvalidField, [invalidIds]);

  if (loading) return <Loading />;

  return (
    <Div
      display="column.flex-start.flex-start"
      bg="#FFFFFF"
      style={{ borderRadius: 6 }}
    >
      <Div
        width={1}
        display="row.center.center"
        style={{
          padding: 22,
          position: "relative",
          borderBottom: "1px solid #e2e2e2",
          marginBottom: 32
        }}
      >
        <Div
          color="#333333"
          style={{
            fontSize: 22,
            fontWeight: 600,
            position: "absolute",
            left: 0
          }}
          display="row.flex-start.center"
          onClick={() =>
            push(
              `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/cart`
            )
          }
        >
          <LeftIcon size={30} color="#333333" />
          <Div>Cart</Div>
        </Div>
        <Div color="#424242" style={{ fontSize: 25, fontWeight: "bold" }}>
          Checkout{" "}
          <span style={{ fontWeight: 500 }}>({items.length} Items)</span>
        </Div>
      </Div>

      <Div style={{ padding: 22 }} maxWidth={700} width={1}>
        {validatedFields.map((field, idx) => (
          <div key={field.id} ref={fieldsRefs[idx].ref}>
            {field.type === "section" ? (
              <Section key={field.id} field={field} />
            ) : (
              <Field field={field} />
            )}
          </div>
        ))}

        {paymentsEnabled && R.gt(total, 0) ? (
          <>
            <Section
              field={{
                id: "payment",
                name: "Payment Information",
                settings: {}
              }}
            />
            <Row>
              <Label
                field={{
                  name: "Card Number",
                  required: true,
                  isValid: true,
                  settings: {}
                }}
              />
              <div style={PAYMENT_FIELD_STYLE}>
                <CardNumberElement onChange={onPaymentFieldChange} />
              </div>
            </Row>
            <Row display="row.flex-start.flex-start">
              <Div width={0.5} style={{ marginRight: 10 }}>
                <Label
                  field={{
                    name: "CVC",
                    required: true,
                    isValid: true,
                    settings: {}
                  }}
                />
                <div style={PAYMENT_FIELD_STYLE}>
                  <CardCVCElement onChange={onPaymentFieldChange} />
                </div>
              </Div>
              <Div width={0.5}>
                <Label
                  field={{
                    name: "Expires",
                    required: true,
                    isValid: true,
                    settings: {}
                  }}
                />
                <div style={PAYMENT_FIELD_STYLE}>
                  <CardExpiryElement onChange={onPaymentFieldChange} />
                </div>
              </Div>
            </Row>
          </>
        ) : null}

        {R.gt(total, 0) ? (
          <>
            <Row>
              <Label
                field={{
                  name: "Billing Information",
                  required: true,
                  isValid: true,
                  settings: {}
                }}
              />
              <RadioList
                fieldId={FORM_RADIO_ID}
                options={BILLING_OPTIONS}
                iniValue={R.head(BILLING_OPTIONS).id}
                ml={3}
                mb={2}
              />
            </Row>
          </>
        ) : null}

        {error && error.length ? (
          <Div bg="danger3" color="gray7" p={3} bra={1} mt={2}>
            {error}
          </Div>
        ) : null}
      </Div>
      <Div
        display="row.flex-start.center"
        width={1}
        style={{ padding: 22, borderTop: "1px solid #e2e2e2" }}
      >
        <Div style={{ marginRight: 80 }}>
          <Div
            color="#000000"
            style={{
              fontSize: 15,
              marginBottom: 7
            }}
          >
            Payment processing details will be sent upon approval.
          </Div>
          {/* <Div
            color="#1A1A1A"
            style={{
              fontSize: 13,
              opacity: 0.5
            }}
          >
            By submitting this order, you agree to the terms & conditions
          </Div> */}
        </Div>
        <SuperFilledButton
          color="#161616"
          bg="#F4D288"
          onClick={
            saving
              ? undefined
              : () => submitForm({ stripe, total, items, params })
          }
        >
          {saving ? "Processing..." : "Submit"}
        </SuperFilledButton>
      </Div>
    </Div>
  );
};

const CheckoutDecoratedWithPayment = injectStripe(decorate(CheckoutBody));

const Checkout = props => (
  <div style={{ width: "100%", maxWidth: 1000 }}>
    <StripeScriptLoader>
      <StripeProvider apiKey={window.__STRIPE_API_KEY__}>
        <Elements>
          <CheckoutDecoratedWithPayment {...props} />
        </Elements>
      </StripeProvider>
    </StripeScriptLoader>
  </div>
);

export default Checkout;
