import { createModule } from "redux-mvc";

import miniItemsSelector from "ui-kit/MiniItemsSelector";
import handlers from "./handlers";

const module = createModule(handlers);

module.plugModule(miniItemsSelector);

const { actions, getters } = module;

export { actions, getters };

export default module;
