import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import {
  NAMESPACE,
  ADD_QUESTION,
  PAGES
} from "Items/AddQuestionModal/constants";

import { currencOptions } from "components/Global/Table3/ModalColumn/CurrencySettings";

const iniState = {
  questionId: null,
  //
  itemQuestionModalPage: PAGES.SELECT_FIELDS,
  addQuestion: ADD_QUESTION.NO_SET,
  existingSetsFilter: "",
  filteredSets: "",
  sets: [],
  selectedQuestionSetIds: [],
  loading: false,
  saving: false,
  //
  questionSetName: "",
  //
  questionName: "",
  questionType: "text",
  questionDescription: "",
  isInternal: false,
  questionSettings: {},
  questionTypeSettings: {
    dropdown: {
      order: {},
      options: [],
      enableMultipleSelect: true,
      allowMultipleSelect: false
    },
    datetime: {
      useDateTimeTimezone: false,
      format: "LLL"
    },
    date: {
      displayType: "calendar",
      showTimepicker: false,
      dateFormat: "MMMM Do, YYYY",
      timeFormat: "h:mm a"
    },
    percent: {
      percentFormat: "0%"
    },
    currency: {
      ...currencOptions.USD
    },
    phone: {
      phoneDisplayType: "mask"
    }
  }
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    save: R.identity,
    cancelInstance: R.identity,
    setInitialData: (state, { payload: { sets, showQuestionSets } }) => ({
      sets,
      itemQuestionModalPage: showQuestionSets
        ? PAGES.ADD_QUESTION
        : PAGES.SELECT_FIELDS
    }),
    setQuestionTypeSetting: (state, { payload: { type, settings } }) => {
      return {
        questionTypeSettings: {
          ...state.questionTypeSettings,
          [type]: settings
        }
      };
    },
    toggleQuestionSet: (state, { payload: setId }) => ({
      selectedQuestionSetIds: state.selectedQuestionSetIds.includes(setId)
        ? state.selectedQuestionSetIds.filter(id => id !== setId)
        : [...state.selectedQuestionSetIds, setId]
    }),
    setInitialQuestion: (state, { payload: question }) => {
      const opts = {
        questionId: question.id,
        questionName: question.name,
        questionDescription: question.settings.description || "",
        questionSettings: question.settings,
        questionType: question.type,
        selectedQuestionSetIds: question.question_set_ids,
        addQuestion: ADD_QUESTION.EXISTING_SET,
        questionTypeSettings: state.questionTypeSettings[question.type]
          ? {
              ...state.questionTypeSettings,
              [question.type]: question.settings
            }
          : state.questionTypeSettings,
        isInternal: R.propOr(false, "is_internal", question)
      };

      return opts;
    },
    resetState: state => ({
      itemQuestionModalPage: PAGES.SELECT_FIELDS,
      addQuestion:
        state.addQuestion !== ADD_QUESTION.NEW_SET
          ? state.addQuestion
          : ADD_QUESTION.NO_SET,
      existingSetsFilter: "",
      filteredSets: "",
      sets: [],
      //
      questionSetName: "",
      //
      questionName: "",
      questionDescription: "",
      questionSettings: {},
      questionTypeSettings: {
        dropdown: {
          order: {},
          options: [],
          enableMultipleSelect: true,
          allowMultipleSelect: false
        },
        datetime: {
          useDateTimeTimezone: false,
          format: "LLL"
        },
        date: {
          displayType: "calendar",
          showTimepicker: false,
          dateFormat: "MMMM Do, YYYY",
          timeFormat: "h:mm a"
        },
        percent: {
          percentFormat: "0%"
        },
        currency: {
          ...currencOptions.USD
        },
        phone: {
          phoneDisplayType: "mask"
        }
      }
    })
  }
});

export default handlers;
