import React from "react";
import { connect } from "react-redux";

import { getSelectedView } from "../selectors";

import { getters } from "../index";

import { SCHEDULE_VIEWS } from "../constants";

import AllowAccessUsers from "Schedules/AllowAccessUsersModal/View";
import ActivityDetailSidebar from "Schedules/ActivityDetailsSidebar/View";

import SidebarWrapper from "./Sidebar/SidebarWrapper";
import Sidebar from "./Sidebar";
import CreateScheduleModal from "./CreateScheduleModal";
import GridLayout from "./GridLayout";
import CalendarLayout from "./CalendarLayout";

const views = {
  [SCHEDULE_VIEWS.GRID]: GridLayout,
  [SCHEDULE_VIEWS.CALENDAR]: CalendarLayout
};

const decorate = connect((state, props) => ({
  selectedView: getSelectedView(state, props),
  showActivityDetails: getters.showActivityDetailsModal(state, props)
}));

const Layout = ({ handlers, selectedView }) => {
  return (
    <SidebarWrapper
      handlers={handlers}
      sidebar={<Sidebar handlers={handlers} />}
    >
      {React.createElement(views[selectedView] || "div", {
        handlers,
        bg: "primary8",
        display: "column.flex-start.stretch",
        flex: 1,
        pl: 2,
        pt: 2,
        pb: 2,
        pr: 4,
        style: { overflow: "hidden" }
      })}
      <AllowAccessUsers />
      <CreateScheduleModal />
      <ActivityDetailSidebar />
    </SidebarWrapper>
  );
};

export default decorate(Layout);
