import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "../../model";
import {
  getItemData,
  getPersonMinQty,
  getPersonItemQtyLabel,
  getPersonItemQtyField,
  getShowPriceField
} from "../../selectors";
import {
  EDIT_MODAL_DATA,
  REQUEST_ITEM_SETTINGS_MODAL_FORM_ID
} from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { Input, Dropdown } from "ui-kit/Form/View";
import { BigFilledButton } from "components/Base";
import { actions as FormActions } from "ui-kit/Form/model";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(REQUEST_ITEM_SETTINGS_MODAL_FORM_ID)),
  connect(
    state => ({
      data: getItemData(state),
      minQty: getPersonMinQty(state),
      qtyLabel: getPersonItemQtyLabel(state),
      qtyField: getPersonItemQtyField(state),
      showPriceField: getShowPriceField(state)
    }),
    {
      hideUpdateModal: () => actions.setShowUpdatePersonItemModal(false),
      updateModalData: actions.updateItemData,
      clearValues: FormActions.clearValues
    }
  ),
  CSSModules(css)
);

const UpdatePersonItemModal = ({
  hideUpdateModal,
  data,
  updateModalData,
  clearValues,
  minQty,
  qtyLabel,
  qtyField,
  showPriceField
}) => {
  useEffect(
    () => () =>
      clearValues(null, {
        meta: {
          instanceId: REQUEST_ITEM_SETTINGS_MODAL_FORM_ID
        }
      }),
    []
  );

  return (
    <MiniModalWrapper
      showModal
      hideModal={hideUpdateModal}
      title="Edit Item"
      display="column.flex-start.stretch"
      width={450}
      height={400}
    >
      <div styleName="edit-modal">
        <div styleName="body">
          <div styleName="fieldWrapper">
            <div styleName="label">{qtyLabel}</div>
            <Input
              fieldId={EDIT_MODAL_DATA.MAX_QUANTITY}
              iniValue={R.prop(qtyField, data)}
              size="big"
              width={1}
              type="number"
              min={minQty}
            />
          </div>
          {showPriceField ? (
            <div styleName="fieldWrapper">
              <div styleName="label">Price</div>
              <Input
                fieldId={EDIT_MODAL_DATA.PRICE}
                iniValue={R.prop(EDIT_MODAL_DATA.PRICE, data)}
                size="big"
                width={1}
                type="number"
                min={0}
              />
            </div>
          ) : null}
          <div styleName="fieldWrapper">
            <div styleName="label">Status</div>
            <Dropdown
              fieldId={EDIT_MODAL_DATA.STATUS}
              iniValue={R.prop(EDIT_MODAL_DATA.STATUS, data)}
              width="100%"
              clearable={false}
              options={[
                { id: "approved", label: "Approved" },
                { id: "pending", label: "Pending" }
              ]}
              placeholder="Select status..."
            />
          </div>
        </div>
        <div styleName="footer">
          <BigFilledButton bg="primary7" onClick={updateModalData}>
            Update
          </BigFilledButton>
        </div>
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(UpdatePersonItemModal);
