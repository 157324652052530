/* eslint-disable no-underscore-dangle */
import {
  put,
  takeEvery,
  all,
  fork,
  call,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { openFilePicker } from "utils/General/sagas";
import { SHARE_FILE_ID, EDIT_MODAL_DATA } from "../constants";
import { actions as FormActions } from "ui-kit/Form/model";
import { getters as FormGetters } from "ui-kit/Form/model";
import { actions as mediaContentModalActions } from "ui-kit/MediaContentModal/model";
import { MEDIA_CONTENT_INSTANCE_ID } from "ui-kit/MediaContentModal/constants";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { actions as addContentModalActions } from "EventLight/Content/AddContentModal/model";

const getSharedFiles = function*({ payload: showLoading = false }) {
  try {
    if (showLoading) {
      yield put(actions.setLoadingSharedFiles(true));
    }
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const { payload: files } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/files`
    });

    yield put(
      actions.setAssignedSharedFiles(R.sort((a, b) => a.order - b.order, files))
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading shared files"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingSharedFiles(false));
  }
};

const reorderSharedFiles = function*({ payload: orderedItems }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/files`,
      data: { items: orderedItems.map(({ id }, order) => ({ id, order })) }
    });

    yield put(actions.getSharedFiles());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading shared files"
        }
      ])
    );
  }
};

const showFilePicker = function*() {
  const opts = {
    multiple: false,
    fromSources: ["local_file_system", "dropbox"]
  };

  const files = yield call(openFilePicker, opts);
  const url = R.pathOr("", [0, "url"], files);
  const name = R.pathOr("", [0, "filename"], files);

  const fields = yield select(FormGetters.fields, {
    instanceId: SHARE_FILE_ID
  });

  const title = R.pathOr("", ["title", "value"], fields);

  yield put(actions.setUploadedFileData({ name, url }));

  if (R.isEmpty(title)) {
    yield put(
      FormActions.setFieldValue(name, {
        meta: {
          instanceId: SHARE_FILE_ID,
          fieldId: EDIT_MODAL_DATA.TITLE
        }
      })
    );
  }
};

const downloadShareFile = function*({ payload: url }) {
  yield call(window.open, url, "_blank");
};

const openContentModal = function*() {
  yield put(
    mediaContentModalActions.setShowModal(true, {
      meta: { instanceId: MEDIA_CONTENT_INSTANCE_ID }
    })
  );
};

const onSelectSharedFile = function*(contentId) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "post",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/file`,
      data: { contentId }
    });

    yield put(actions.getSharedFiles(true));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "This file is already assigned"
        }
      ])
    );
  }
};

const removeAssinedSharedFile = function*({ payload: fileId }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/file/${fileId}`
    });

    yield put(actions.getSharedFiles());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting shared files"
        }
      ])
    );
  }
};

const updateSharedFile = function*({ payload: { content_id } }) {
  yield put(addContentModalActions.showContentModal({ id: content_id }));
};

const watchSelectMedia = function*() {
  for (;;) {
    const {
      payload: { id },
      meta: { instanceId }
    } = yield take(mediaContentModalActions.selectRecord.type);
    if (instanceId === MEDIA_CONTENT_INSTANCE_ID) {
      yield call(onSelectSharedFile, id);
    }
  }
};

const watchGetSharedFiles = function*() {
  yield takeEvery(actions.getSharedFiles.type, getSharedFiles);
};

const watchRefreshAfterSaving = function*() {
  yield takeEvery(mediaContentModalActions.selectRecord.type, getSharedFiles);
};

const watchReorderSharedFiles = function*() {
  yield takeEvery(actions.reorderSharedFiles.type, reorderSharedFiles);
};

const watchShowFilePicker = function*() {
  yield takeEvery(actions.showFilePicker.type, showFilePicker);
};

const watchDownloadShareFile = function*() {
  yield takeEvery(actions.downloadShareFile.type, downloadShareFile);
};

const watchOpenContentModal = function*() {
  yield takeEvery(actions.openContentModal.type, openContentModal);
};

const watchOnSelectSharedFile = function*() {
  yield takeEvery(actions.onSelectSharedFile.type, onSelectSharedFile);
};

const watchRemoveAssinedSharedFile = function*() {
  yield takeEvery(
    actions.removeAssinedSharedFile.type,
    removeAssinedSharedFile
  );
};

const watchUpdateSharedFile = function*() {
  yield takeEvery(actions.updateSharedFile.type, updateSharedFile);
};

const watchUpdateFile = function*() {
  yield takeEvery(addContentModalActions.save.type, getSharedFiles);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetSharedFiles),
    fork(watchReorderSharedFiles),
    fork(watchShowFilePicker),
    fork(watchDownloadShareFile),
    fork(watchOpenContentModal),
    fork(watchSelectMedia),
    fork(watchOnSelectSharedFile),
    fork(watchRemoveAssinedSharedFile),
    fork(watchUpdateSharedFile),
    fork(watchUpdateFile),
    fork(watchRefreshAfterSaving)
  ]);
};

export default rootSaga;
