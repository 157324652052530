import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { withRouter } from "react-router";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import {
  getViews,
  createView,
  updateView,
  deleteView,
  setActiveView
} from "redux/modules/modules/views/actions";
import { updateViewMeta } from "redux/modules/modules/views/meta/actions";
import { addForm } from "redux/modules/formsV2/forms/actions";

import { user } from "redux/modules/user/selectors";
import { moduleDetails, forms } from "redux/modules/modules/module/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";
import {
  records,
  fields,
  preferences
} from "redux/modules/modules/records/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { tableLayoutOptions } from "redux/modules/modules/records/settings/selectors";
import {
  updateTableLayout,
  deselectAllRows
} from "redux/modules/modules/records/settings/actions";

function mapStateToProps(state, props) {
  return {
    fields: fields(state, props.params.moduleId),
    moduleSettings: tableLayoutOptions(state, props.params.moduleId),
    moduleDetails: moduleDetails(state, props.params.moduleId),
    forms: forms(state, props.params.moduleId),
    preferences: preferences(state, props.params.moduleId),
    views: views(state, props.params.moduleId),
    activeView: activeView(
      state,
      props.params.moduleId,
      props.params.viewId,
      props.params.groupId
    ),
    records: records(state, props.params.moduleId),
    recordTypes: recordTypes(state, props.params.moduleId),
    user: user(state),
    canViewEventReports: selectFeatureFlag(flags.CAN_VIEW_EVENT_REPORTS.NAME)(
      state
    )
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      deselectAllRows: () => deselectAllRows(props.params.moduleId),
      hideModal,
      showModal,
      showSnackbar,
      getViews,
      createView,
      updateView,
      deleteView,
      setActiveView,
      updateViewMeta,
      updateTableLayout,
      addForm,
      getRecordTypes
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
