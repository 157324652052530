import React from "react";

import { connect } from "react-redux";

import { Div, Text1, PeopleIcon } from "components/Base";
import {
  getScheduledDate,
  getStatsRecipients,
  getStatsSMS
} from "../selectors";

const decorate = connect(
  (state, props) => ({
    recipientsCount: getStatsRecipients(state, props),
    recipientsSMSCount: getStatsSMS(state, props),
    sendAt: getScheduledDate(state, props)
  }),
  null
);

const PreviewRecipients = ({
  recipientsCount = "",
  recipientsSMSCount = "",
  sendAt = ""
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    p={4}
    bg="white"
    bra={1}
    ba={1}
    shadow={1}
    bc="neutral1"
    mb={4}
  >
    <PeopleIcon color="neutral9" size={40} />
    <Div display="column.flex-start.center" ml={4}>
      <Text1 bold color="neutral9" mb={2}>
        Recipients
      </Text1>
      <Text1 color="neutral9">{recipientsCount}</Text1>
    </Div>
    <Div display="column.flex-start.center" ml={4}>
      <Text1 bold color="neutral9" mb={2}>
        Recipients w/ SMS
      </Text1>
      <Text1 color="neutral9">{recipientsSMSCount}</Text1>
    </Div>
    <Div display="column.flex-start.center" ml={4}>
      <Text1 bold color="neutral9" mb={2}>
        Scheduled
      </Text1>
      <Text1 color="neutral9">{sendAt}</Text1>
    </Div>
  </Div>
);

export default decorate(PreviewRecipients);
