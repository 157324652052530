import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";

import { getCredentials } from "redux/modules/user/selectors";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";

import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgDetails = yield select(getOrgDetails);

  return {
    credentials,
    orgDetails
  };
};

const formatUsers = R.sortBy(r => {
  if (r.user_lname) {
    return r.user_lname.toLowerCase();
  }
  return null;
});

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, orgDetails } = yield call(getParams);

    const { payload } = yield call(Api.get, credentials, orgDetails.id);

    const users = formatUsers(payload);

    yield put(
      actions.setInitialData({
        users
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getUsers = function*() {
  try {
    const { credentials, orgDetails } = yield call(getParams);

    const { payload } = yield call(Api.get, credentials, orgDetails.id);

    const users = formatUsers(payload);

    yield put(actions.setUsers(users));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting users"
        }
      ])
    );
  }
};

const watchGetUsers = function*() {
  yield takeEvery(actions.getUsers.type, getUsers);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchGetUsers), fork(watchInit)]);
};

export default rootSaga;
