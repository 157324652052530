import React from "react";
import { Div, Text2, Text4 } from "components/Base";

const Option = ({
  styleProps = {},
  btnStyleProps = {},
  title,
  onClick = {},
  btnText = "SELECT",
  description,
  children = null,
  style = {}
}) => (
  <Div
    bra={2}
    ba={2}
    width={1}
    bc={{ default: "neutral2", hover: "info6" }}
    bg="white"
    display="row.space-between.center"
    pr={5}
    mt={2}
    {...styleProps}
    style={{ minHeight: "100px", overflow: "hidden", ...style }}
  >
    <Div display="row.flex-start.center">
      <Div
        width={143}
        bg="neutral1"
        style={{ minHeight: "100px", flexShrink: 0 }}
        display="row.center.center"
        p={4}
      >
        {children}
      </Div>
      <Div pl={5}>
        <Text4 bold color="black">
          {title}
        </Text4>
        <Text2>{description}</Text2>
      </Div>
    </Div>
    <Div
      bra={1}
      bg={{ default: "neutral2", hover: "info6" }}
      color={{
        default: "neutral9",
        hover: "white"
      }}
      px={4}
      py={2}
      style={{ cursor: "pointer", flexShrink: 0 }}
      fs={2}
      transition="fast"
      textAlign="center"
      width={110}
      ml={2}
      {...btnStyleProps}
      onClick={onClick}
    >
      {btnText}
    </Div>
  </Div>
);

export default Option;
