import { get } from "lodash";

export const visibleFields = (state, recordTypeId, layoutType) =>
  get(
    state,
    `modules.layoutVisibleFields[${recordTypeId}][${layoutType}].fields`,
    []
  );
export const defaultFields = (state, recordTypeId, layoutType) =>
  get(
    state,
    `modules.layoutVisibleFields[${recordTypeId}][${layoutType}].default_fields`,
    []
  );
