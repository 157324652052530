export default {
  success: true,
  payload: {
    id: "some_page_id",
    logo_image_url: "https://cdn.filestackcontent.com/EExv57LgS2GAGaYsV2Jm",
    background_image_url:
      "https://cdn.filestackcontent.com/KsPYFHw5Tc2bBChDv2D7",
    title: "Media Center",
    reminders_enabled: false,
    active_alerts: [
      {
        id: 1,
        type: "breaking",
        content:
          "Weather Warning Signs have been posted – 1:46 pm CDT. Stand by for updates.",
        date: "24m ago"
      }
    ],
    session_details: {
      title: "Media Interview: Brooks Koepka",
      starts_at: new Date(),
      ends_at: new Date(),
      description:
        "We are really looking forward to sharing a great event experience with you. Please see below for some very helpful links and information regarding your participation with the festival and be aware of the upcoming important festival deadlines. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In suspendisse faucibus tellus mauris in tellus turpis. Semper massa iaculis nunc nibh vel nisl odio hendrerit vestibulum. Laoreet donec neque turpis cras gravida. Scelerisque nec purus amet urna. Ligula nunc ornare tellus massa netus. Semper massa iaculis nunc nibh vel nisl odio hendrerit vestibulum. Laoreet donec neque turpis cras gravida. Scelerisque nec purus amet urna. Ligula nunc ornare tellus massa netus., We are really looking forward to sharing a great event experience with you. Please see below for some very helpful links and information regarding your participation with the festival and be aware of the upcoming important festival deadlines. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In suspendisse faucibus tellus mauris in tellus turpis. Semper massa iaculis nunc nibh vel nisl odio hendrerit vestibulum. Laoreet donec neque turpis cras gravida. Scelerisque nec purus amet urna. Ligula nunc ornare tellus massa netus. Semper massa iaculis nunc nibh vel nisl odio hendrerit vestibulum. Laoreet donec neque turpis cras gravida. Scelerisque nec purus amet urna. Ligula nunc ornare tellus massa netus.",
      video_url: "https://www.youtube.com/embed/-Qj_zt8UOv0",
      video_thumbnail_url: "https://img.youtube.com/vi/-Qj_zt8UOv0/0.jpg",
      conference_url: "https://meet.google.com/pik-cgdx-hfp?authuser=1",
      people: [
        {
          id: 1,
          name: "Josh Parolin",
          group: "Lennd, Inc."
        },
        {
          id: 2,
          name: "Sandy Baker",
          group: "Lennd, Inc."
        }
      ],
      questions: [
        {
          id: 1,
          message: "How long have you been golfing for?",
          author: "Josh Parolin",
          posted_at: "Today @ 1:14PM"
        },
        {
          id: 2,
          message: "What is the next tournamenet you'll be in?",
          author: "Josh Parolin",
          posted_at: "Today @ 1:20PM"
        }
      ],
      related_content: [
        {
          id: 1,
          type: "title",
          name: "Competition"
        },
        {
          id: 2,
          type: "link",
          open_in_new_window: true,
          name: "Players Guide",
          url: "https://someurl.com/"
        },
        {
          id: 3,
          type: "link",
          open_in_new_window: true,
          name: "Media Guide",
          url: "https://someurl.com/"
        },
        {
          id: 4,
          type: "link",
          open_in_new_window: true,
          name: "Pairings",
          url: "https://someurl.com/"
        },
        {
          id: 5,
          type: "link",
          open_in_new_window: true,
          name: "Hole Locaitons",
          url: "https://someurl.com/"
        },
        {
          id: 6,
          type: "title",
          name: "Video & Photos"
        },
        {
          id: 7,
          type: "link",
          open_in_new_window: true,
          name: "Press Images",
          url: "https://someurl.com/"
        },
        {
          id: 8,
          type: "link",
          open_in_new_window: true,
          name: "B-Roll Footage",
          url: "https://someurl.com/"
        },
        {
          id: 9,
          type: "title",
          name: "Assets"
        },
        {
          id: 10,
          type: "file",
          format: "pdf",
          open_in_new_window: true,
          name: "Wed - Tiger Woods - Transcript.pdf",
          url: "https://someurl.com/"
        },
        {
          id: 11,
          type: "link",
          open_in_new_window: true,
          name: "All Transcripts",
          url: "https://someurl.com/"
        },
        {
          id: 12,
          type: "link",
          open_in_new_window: true,
          name: "Event Logos",
          url: "https://someurl.com/"
        },
        {
          id: 13,
          type: "link",
          open_in_new_window: true,
          name: "PGA Logos",
          url: "https://someurl.com/"
        }
      ]
    },
    left_sidebar_sections: [
      {
        id: 1,
        name: "Schedule",
        type: "sessions",
        content: [
          {
            date: "2020-03-29",
            sessions: [
              {
                id: 1,
                name: "2020 Championship Kickoff Stream",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 2,
                name: "Press Conference: PGA Team",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 3,
                name: "2020 Championship Kickoff Stream",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 4,
                name: "Press Conference: PGA Team",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 5,
                name: "2020 Championship Kickoff Stream",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 6,
                name: "Press Conference: PGA Team",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 7,
                name: "2020 Championship Kickoff Stream",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 8,
                name: "Press Conference: PGA Team",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 9,
                name: "2020 Championship Kickoff Stream",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 10,
                name: "Press Conference: PGA Team",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 11,
                name: "2020 Championship Kickoff Stream",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              },
              {
                id: 12,
                name: "Press Conference: PGA Team",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              }
            ]
          },
          {
            date: "2020-03-30",
            sessions: [
              {
                id: 3,
                name: "Press Conference: Defending Champ - Brooks Koepka",
                date_label: "3pm - 3:30pm",
                has_video: true,
                opens_at_label: null,
                is_special_access: true
              },
              {
                id: 4,
                name: "Press Conference: Tiger Woods",
                date_label: "3:00pm",
                has_video: false,
                opens_at_label: "Opens at 2:30pm",
                is_special_access: false
              }
            ]
          },
          {
            date: "2020-03-31",
            sessions: [
              {
                id: 5,
                name: "18th Hole Interviews: All Pairings",
                date_label: null,
                has_video: true,
                opens_at_label: null,
                is_special_access: true
              },
              {
                id: 6,
                name: "Live: Day 1 Leaderboard",
                date_label: "3pm - 3:30pm",
                has_video: false,
                opens_at_label: null,
                is_special_access: false
              }
            ]
          },
          {
            date: "2020-04-01",
            sessions: [
              {
                id: 7,
                name: "Press Conference: Brooks Koepka",
                date_label: null,
                has_video: true,
                opens_at_label: null,
                is_special_access: true
              }
            ]
          }
        ]
      }
    ],
    footer_sections: [
      [
        {
          id: 4,
          name: null,
          type: "twitter",
          content: "@PGAChampionship"
        }
      ],
      [
        {
          id: 5,
          name: "Resources",
          type: "links",
          content: [
            {
              id: 1,
              type: "title",
              name: "Competition"
            },
            {
              id: 2,
              type: "link",
              open_in_new_window: true,
              name: "Players Guide",
              url: "https://someurl.com/"
            },
            {
              id: 3,
              type: "link",
              open_in_new_window: true,
              name: "Media Guide",
              url: "https://someurl.com/"
            },
            {
              id: 4,
              type: "link",
              open_in_new_window: true,
              name: "Pairings",
              url: "https://someurl.com/"
            },
            {
              id: 5,
              type: "link",
              open_in_new_window: true,
              name: "Hole Locaitons",
              url: "https://someurl.com/"
            },
            {
              id: 6,
              type: "title",
              name: "Video & Photos"
            },
            {
              id: 7,
              type: "link",
              open_in_new_window: true,
              name: "Press Images",
              url: "https://someurl.com/"
            },
            {
              id: 8,
              type: "link",
              open_in_new_window: true,
              name: "B-Roll Footage",
              url: "https://someurl.com/"
            },
            {
              id: 9,
              type: "title",
              name: "Assets"
            },
            {
              id: 10,
              type: "file",
              format: "pdf",
              open_in_new_window: true,
              name: "Wed - Tiger Woods - Transcript.pdf",
              url: "https://someurl.com/"
            },
            {
              id: 11,
              type: "link",
              open_in_new_window: true,
              name: "All Transcripts",
              url: "https://someurl.com/"
            },
            {
              id: 12,
              type: "link",
              open_in_new_window: true,
              name: "Event Logos",
              url: "https://someurl.com/"
            },
            {
              id: 13,
              type: "link",
              open_in_new_window: true,
              name: "PGA Logos",
              url: "https://someurl.com/"
            }
          ]
        }
      ],
      [
        {
          id: 14,
          name: "Media Alerts",
          type: "alerts",
          content: [
            {
              id: 1,
              type: "breaking",
              content:
                "Weather Warning Signs have been posted – 1:46 pm CDT. Stand by for updates.",
              date: "1:46pm CDT Apr 6"
            },
            {
              id: 2,
              type: "warning",
              content:
                "Play has been suspended due to dangerous conditions - 3:35 pm CDT. Stand by for updates.",
              date: "1:46pm CDT Apr 6"
            },
            {
              id: 3,
              type: "warning",
              content:
                "Lexi Thompson recorded a hole-in-one on the 166-yard No. 6 hole Thursday at Kemper Lakes Golf Club. She used an 8-iro...",
              date: "1:46pm CDT Apr 6"
            },
            {
              id: 4,
              type: "warning",
              content:
                "Xander Schauffele's PGA Championship media interview is now scheduled for 3:00 p.m. on Wednesday (today)."
            },
            {
              id: 5,
              type: "warning",
              content:
                "Lee-Anne Pace of South Africa has been disqualified from the 2018 KPMG Women’s PGA Championship due to a rules infra..."
            },
            {
              id: 5,
              type: "warning",
              content:
                "Lee-Anne Pace of South Africa has been disqualified from the 2018 KPMG Women’s PGA Championship due to a rules infra..."
            }
          ]
        },
        {
          id: 15,
          name: "Contact Us",
          type: "links",
          content: [
            {
              id: 1,
              type: "link",
              open_in_new_window: true,
              name: "Contact PGA Championship Media",
              url: "https://someurl.com/"
            }
          ]
        }
      ]
    ]
  }
};
