import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  token: null,
  stripeStatus: {},
  billAnnually: false,
  billingPortal: {},
  subscription: {
    current_period_starts_at: null,
    current_period_ends_at: null,
    plan_id: null,
    plan_name: null,
    plan_scope: null,
    count_of_admin_seats: 0,
    count_of_used_admin_seats: 0,
    count_of_available_registrations: 0,
    count_of_used_registrations: 0
  },
  data: {
    billing_email: null
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    goToStripeAuthUrl: R.identity,
    viewStripeDashboard: R.identity,
    disconnectPaymentsAuth: R.identity,
    saveBillingDetails: R.identity,
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
