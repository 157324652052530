import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";
import { createContext } from "redux-mvc";
import { connect } from "react-redux";
import module, { actions } from "Event/Home";
import sagas from "Event/Home/sagas";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Page from "Event/Home/View/Page";
import { showModal } from "redux/modules/modal/actions";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CreateOrderModal from "Orders/CreateOrderModal";
import FormWizardModal from "Forms/WizardModal/View";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";
import SubmitFormModal from "Modules/SubmitFormModal/View";

module.setRootSaga(sagas);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.getEventRecordTypes());
    }
  },
  handlers: {
    showSubmitFormModal: function() {
      this.props.showModal({
        content: <SubmitFormModal hideModal={this.props.hideModal} />,
        wrapper: ModalWrapper
      });
    },
    showAddRecordModal: function({ moduleId }) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={moduleId}
            onSave={() => this.store.dispatch(actions.getEventRecordTypes())}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddAccountModal: function(groupId, groupName) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={STANDARD_MODULE_IDS.accounts.id}
            onSave={() => this.store.dispatch(actions.getEventRecordTypes())}
            onlyRecordTypeId={groupId}
            onlyRecordTypeName={groupName}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddContactModal: function(groupId, groupName) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={STANDARD_MODULE_IDS.contacts.id}
            onSave={() => this.store.dispatch(actions.getEventRecordTypes())}
            onlyRecordTypeId={groupId}
            onlyRecordTypeName={groupName}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showCreateOrderModal: function() {
      this.props.showModal({
        content: (
          <CreateOrderModal
            onDone={() => {
              this.props.showSnackbar({ message: "Order created" });
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddFormModal: function() {
      this.props.showModal({
        content: <FormWizardModal />,
        wrapper: ModalWrapper
      });
    },
    showAddEventTeamUserModal: function() {
      const modal = (
        <AddEventTeamUserModal
          hideModal={this.props.hideModal}
          addToEventId={this.props.params.eventId}
          onAdded={() => {
            this.props.showSnackbar({ message: "User invited" });
          }}
        />
      );

      this.props.showModal({ content: modal });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "events",
      "permissions",
      "userAccessLevels",
      "@flopflip",
      "EventMyApprovals"
    ]
  }
});

export default R.compose(
  connect(
    state => ({
      canDo: canEventUserDo(state)
    }),
    { showModal }
  ),
  withRouter,
  decorate
)(Page);
