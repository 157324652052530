import * as R from "ramda";
import React, { useRef } from "react";
import moment from "moment-timezone";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { getEvents, getResources } from "../selectors";
import { actions, getters } from "../model";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import Tabs from "ui-kit/Tabs/View";
import styles from "./styles.scss";

import { Div, Text2, Text4 } from "components/Base";
import styleSpects from "components/Base/Base/styleSpecs";

import { useCalendar } from "../utils";

import { CALENDAR_VIEW } from "../constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      currentDate: getters.currentDate(state, props),
      events: getEvents(state, props),
      resources: getResources(state, props)
    }),
    bindInstance({
      init: actions.init,
      eventCreate: actions.eventCreate,
      eventEdit: actions.eventEdit,
      eventResizeStart: actions.eventResizeStart,
      eventResizeRequest: actions.eventResizeRequest,
      eventDragStart: actions.eventDragStart,
      eventDropRequest: actions.eventDropRequest
    })
  ),
  CSSModules(styles)
);

const ResourceLayout = ({
  currentDate = "",
  resourceOptions = [],
  loading,
  instanceId,
  ...props
}) => {
  const wrapperRef = useRef();
  const calendarRef = useRef();

  const width = R.length(props.resources) * 200 + 73;
  const { overflow } = useCalendar({
    ...props,
    currentDate,
    selectedRange: CALENDAR_VIEW.RESOURCE,
    wrapperRef,
    calendarRef,
    width
  });

  return (
    <div styleName="calendar" ref={wrapperRef}>
      <div styleName="tabs-container" style={overflow ? { width } : {}}>
        <div styleName="day-container">
          <Text2 color="neutral6">{moment(currentDate).format("ddd")}</Text2>
          <Text4 color="primary7" bold>
            {moment(currentDate).format("MMM")}
          </Text4>
          <Div
            fs={7}
            fw={3}
            color="primary7"
            style={{ lineHeight: `${styleSpects.fontSize[7]}px` }}
          >
            {moment(currentDate).format("DD")}
          </Div>
        </div>
        {resourceOptions && resourceOptions.length ? (
          <>
            <div styleName="spacer">a</div>
            <Tabs
              tabs={resourceOptions}
              instanceId={instanceId}
              iniSelected={0}
              pl={4}
              bb={1}
              bc="neutral3"
              flex={1}
            />
          </>
        ) : null}
      </div>
      <div ref={calendarRef} style={{ maxWidth: width, width }} />
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default decorate(ResourceLayout);
