import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export default function resolveRecordName(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.files.id:
      return !props.plural ? "File" : "Files";
    case STANDARD_MODULE_IDS.documentRequests.id:
      return !props.plural ? "File Request" : "File Requests";
    case STANDARD_MODULE_IDS.accounts.id:
      return !props.plural ? "Group" : "Groups";
    case STANDARD_MODULE_IDS.contacts.id:
      return !props.plural ? "Person" : "People";
    case STANDARD_MODULE_IDS.orders.id:
      return !props.plural ? "Order" : "Orders";
    default:
      return !props.plural ? "Record" : "Records";
  }
}
