import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import PercentEditor from "components/Global/Editors/Percent";
import isRequiredValid from "utils/value-types/validations/number/required";
import Label from "components/Global-2016/Forms/Label";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import { isEqual } from "lodash";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormPercent extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: props.formValues[props.field.id],
      isShown: false
    };
  }

  @autobind
  async isValid() {
    const errors = [];
    const required = this.props.field.is_required;
    const value = this.state.value;

    if (required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: nextProps.formValues[this.props.field.id]
      });
    }
  }

  @autobind
  handleSave(value) {
    this.setState(
      {
        value
      },
      () => this.save(this.props.field.id, this.state.value)
    );
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  render() {
    let handleSave;
    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }
    const {
      field: {
        name,
        settings: { description, placeholder, percentFormat, isAdminField }
      },
      canShowSimpleSideBar,
      connectDragSource
    } = this.props;
    const required = this.props.field.is_required;
    const readonly = this.props.field.is_readonly;
    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <InputGroup style={this.props.groupStyles}>
          {/* {name && (
            <Label required={required} description={description}>
              {name}
            </Label>
          )} */}
          {name ? (
            !canShowSimpleSideBar ? (
              <Label
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
              >
                {name}
              </Label>
            ) : (
              <LabelSimpleForm
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
                instanceId={this.props.field.id}
                fieldData={this.props.field}
                isShown={this.state.isShown}
                setIsShown={() => this.handleToogleShowEditMode()}
              >
                {name}
              </LabelSimpleForm>
            )
          ) : null}
          <div styleName="picker">
            <PercentEditor
              disabled={this.props.disabled}
              placeholder={placeholder}
              description={description}
              format={percentFormat}
              value={this.state.value}
              onChange={this.handleSave}
              onBlur={handleSave}
              required={required}
            />
          </div>
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormPercent.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormPercent;
