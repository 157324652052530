import * as R from "ramda";
import { put, takeEvery, select, call, all, fork } from "redux-saga/effects";
import { push } from "react-router-redux";

import { actions, getters } from "Schedules/Create";
import { actions as ScheduleActions } from "Schedules/Schedule";
import { actions as AllowAccessUsersModalActions } from "Schedules/AllowAccessUsersModal";
import {
  selectedUserAndProfiles,
  getIsProject
} from "Schedules/Create/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import api from "Schedules/Schedule/api";
import { registerError } from "redux/modules/errors/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";

const saveNewSchedule = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  yield put(actions.setLoading(true));

  try {
    const name = yield select(getters.scheduleName);
    const color = yield select(getters.color);
    const icon = yield select(getters.icon);
    const isPrivate = yield select(getters.sharing);
    const datetimeOptions = yield select(getters.dateTimeOptions);
    const dateFormat = yield select(getters.dateFormat);
    const timeFormat = yield select(getters.timeFormat);
    const timezone = yield select(getters.timeZone);
    const showOnPortals = yield select(getters.showOnPortals);
    const showOnProfiles = yield select(getters.showOnProfileRecords);
    const redirectAfterSave = yield select(getters.redirectAfterSave);
    const { profileIds, userIds } = yield select(selectedUserAndProfiles);
    const isProject = yield select(getIsProject);

    const { payload } = yield call(api.createNewSchedule, credentials, {
      eventId,
      name,
      color: color.backgroundColor,
      icon,
      isPrivate,
      isProject,
      datetimeOptions,
      dateFormat,
      timeFormat,
      timezone,
      showOnPortals,
      showOnProfiles,
      shareWith: {
        userIds,
        profileIds
      }
    });

    yield put(actions.createNewScheduleResponse());
    if (redirectAfterSave) {
      yield put(push(`/event-light/${eventId}/schedules/${payload.module_id}`));
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred creating schedule"
        }
      ])
    );
  }
};

const init = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const scheduleId = yield select(getters.scheduleId);

  try {
    const { payload } = yield call(api.getSchedule, {
      credentials,
      moduleId: scheduleId,
      eventId
    });

    const { share_with } = payload;

    const { users, profiles } = share_with;

    yield all([
      put(actions.setInitialData(payload)),
      put(
        AllowAccessUsersModalActions.setSelectedItemIds([
          ...R.map(
            R.compose(
              R.toString,
              R.prop("id")
            ),
            users
          ),
          ...R.map(R.prop("id"), profiles)
        ])
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading the schedule"
        }
      ])
    );
  }
};

const updateSchedule = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const scheduleId = yield select(getters.scheduleId);

  yield put(actions.setLoading(true));

  try {
    const name = yield select(getters.scheduleName);
    const color = yield select(getters.color);
    const icon = yield select(getters.icon);
    const isPrivate = yield select(getters.sharing);
    const datetimeOptions = yield select(getters.dateTimeOptions);
    const dateFormat = yield select(getters.dateFormat);
    const timeFormat = yield select(getters.timeFormat);
    const timezone = yield select(getters.timeZone);
    const showOnPortals = yield select(getters.showOnPortals);
    const showOnProfiles = yield select(getters.showOnProfileRecords);
    const { profileIds, userIds } = yield select(selectedUserAndProfiles);

    yield call(
      api.updateSchedule,
      { credentials, moduleId: scheduleId, eventId },
      {
        eventId,
        name,
        color: color.backgroundColor,
        icon,
        isPrivate,
        datetimeOptions,
        dateFormat,
        timeFormat,
        timezone,
        showOnPortals,
        showOnProfiles,
        shareWith: {
          userIds,
          profileIds
        }
      }
    );

    yield put(ScheduleActions.setShowCreateModal(false));
    yield put(ScheduleActions.refreshSchedule(scheduleId));

    yield put(
      snackbarActions.showSnackbar({
        message: "Schedule Updated",
        action: "OK"
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white creating schedule"
        }
      ])
    );
  }
};

const watchUpdateSchedule = function*() {
  yield takeEvery(actions.updateSchedule.type, updateSchedule);
};

const watchSaveNewSchedule = function*() {
  yield takeEvery(actions.saveNewSchedule.type, saveNewSchedule);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchSaveNewSchedule),
    fork(watchInit),
    fork(watchUpdateSchedule)
  ]);
};

export default rootSaga;
