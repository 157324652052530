import React from "react";
import {
  Div,
  FilterIcon,
  MediumFilledButton,
  CloseIcon
} from "components/Base";
import { connect } from "react-redux";
import FilterBy from "./FilterBy";
import { FILTER } from "../constants";
import { getters, actions } from "../model";
import { currentEventName, selectedEventCount } from "../selectors";
import UnseenCount from "./UnseenCount";
import * as R from "ramda";

import { bindInstance } from "redux-mvc";

const decorate = connect(
  (state, props) => ({
    eventId: getters.eventId(state, props),
    currentEventName: currentEventName(state, props),
    showFilters: getters.showFilters(state, props),
    selectedEventCount: selectedEventCount(state, props)
  }),
  bindInstance({
    selectFilter: actions.selectFilter,
    toggleFilters: actions.toggleFilters
  })
);

export const Filter = ({
  eventId,
  selectFilter,
  instanceId,
  currentEventName,
  toggleFilters,
  showFilters,
  selectedEventCount,
  ...styleProps
}) => {
  return (
    <Div
      height={55}
      display="row.flex-start.center"
      style={{
        flexShrink: 0
      }}
      {...styleProps}
    >
      <Div display="row.flex-start.center">
        <FilterBy instanceId={instanceId} />
        {eventId && (
          <Div pill bg="#fbfafb" ml={2} p={1}>
            <MediumFilledButton
              pill
              bg="#fbfafb"
              color="gray8"
              onClick={() => selectFilter({ type: FILTER.ALL })}
              LeftIcon={CloseIcon}
            >
              {currentEventName}
              {selectedEventCount ? (
                <UnseenCount ml={2} count={selectedEventCount} />
              ) : null}
            </MediumFilledButton>
          </Div>
        )}
      </Div>

      <FilterIcon
        onClick={toggleFilters}
        style={{ marginLeft: "auto", marginRight: 10 }}
        size={25}
        color={showFilters ? "#7700d0" : "black"}
      />
    </Div>
  );
};

export default decorate(Filter);
