import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "EventLight/Expo/Sales/model";
import { STEPS } from "EventLight/Expo/Sales/constants";

import { Div, Text3, DownIcon, BigOutlineButton } from "components/Base";
import {
  PageTitle,
  PackageContainer,
  StepDescription
} from "Sponsors/Common/View";
import { Row } from "ui-kit/Form/View";

import VisiblePackages from "./VisiblePackages";
import Questions from "./Questions";
import SelectQuestionsModal from "./SelectQuestionsModal";
import SelectItemsModal from "./SelectItemsModal";

import { WithThemeConsumer, defaultTh, VARIANTS, os } from "ui-kit/Theme";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const decorate = R.compose(
  connect(
    null,
    {
      init: actions.init,
      showSelectQuestions: actions.setSelectingQuestions
    }
  ),
  WithThemeConsumer({ variant: VARIANTS.SURFACE })
);

const ApplicationPage = ({
  init = noop,
  showSelectQuestions = noop,
  Th = defaultTh,
  handlers,
  ...styleProps
}) => {
  useEffect(() => {
    init(STEPS.APPLICATION);
  }, []);

  return (
    <Div display="column" {...os(styleProps)}>
      <PageTitle>Choose what packages and questions to display</PageTitle>
      <StepDescription mt={4}>
        <Text3 {...Th(["color"], { ml: 2 })}>
          Select which packages (e.g. "Silver Sponsor Package", "10x10 Bundle"),
          which add-ons (e.g. "Extra Electricity", "Extra Floor Pass") and which
          questions you would like to show on your Sales Portal.
        </Text3>
      </StepDescription>
      <VisiblePackages />

      <PackageContainer title="Configure Checkout">
        <Text3 mb={2}>
          Customize the questions you would like your exhibitors or sponsors to
          fill out.
        </Text3>
        <Questions
          mt={5}
          ml={4}
          title="Personal Contact Information"
          moduleId={STANDARD_MODULES.contacts.id}
          handlers={handlers}
          footer={
            <>
              <Row
                onClick={() =>
                  showSelectQuestions(STANDARD_MODULES.contacts.id)
                }
              >
                <DownIcon {...Th(["color"], { sizeWFS: 6, mr: 2 })} />
                <Text3>View all questions </Text3>
              </Row>
              <BigOutlineButton
                {...Th(["color"], { hover: true, ml: 1 })}
                onClick={() =>
                  handlers.showAddFieldModal(STANDARD_MODULES.contacts.id)
                }
              >
                Create New Question
              </BigOutlineButton>
            </>
          }
        />
        <Questions
          mt={5}
          ml={4}
          title="Exhibitor / Group Information"
          moduleId={STANDARD_MODULES.accounts.id}
          handlers={handlers}
          footer={
            <>
              <Row
                onClick={() =>
                  showSelectQuestions(STANDARD_MODULES.accounts.id)
                }
              >
                <DownIcon {...Th(["color"], { sizeWFS: 6, mr: 2 })} />
                <Text3>View all questions </Text3>
              </Row>
              <BigOutlineButton
                {...Th(["color"], { hover: true, ml: 1 })}
                onClick={() =>
                  handlers.showAddFieldModal(STANDARD_MODULES.accounts.id)
                }
              >
                Create New Question
              </BigOutlineButton>
            </>
          }
        />
        <Questions
          mt={5}
          ml={4}
          title="Custom Questions"
          moduleId={STANDARD_MODULES.orders.id}
          handlers={handlers}
          footer={
            <>
              {/*
              <Row
                onClick={() => showSelectQuestions(STANDARD_MODULES.orders.id)}
              >
                <DownIcon {...Th(["color"], { sizeWFS: 6, mr: 2 })} />
                <Text3>View all questions </Text3>
              </Row>
              */}
              <Row />
              <BigOutlineButton
                {...Th(["color"], { hover: true, ml: 1 })}
                onClick={() =>
                  handlers.showAddFieldModal(STANDARD_MODULES.orders.id)
                }
              >
                Create Custom Question
              </BigOutlineButton>
            </>
          }
        />
      </PackageContainer>
      <SelectQuestionsModal />
      <SelectItemsModal />
    </Div>
  );
};

export default decorate(ApplicationPage);
