import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AddRecord from "./AddRecord";
import { map, prop, compose, filter, sortBy } from "ramda";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { withRouter } from "react-router";

import { getFields } from "redux/modules/modules/fields/actions";
import { getLayoutVisibleFields } from "redux/modules/modules/layouts/visibleFields/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { addReference } from "redux/modules/entityReferences/actions";
import { hideModal } from "redux/modules/modal/actions";

import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { fields } from "redux/modules/modules/fields/selectors";
import { user } from "redux/modules/user/selectors";
import {
  visibleFields,
  defaultFields
} from "redux/modules/modules/layouts/visibleFields/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { activeView } from "redux/modules/modules/views/selectors";

const REQUIRED_FIELD_IDS = [
  STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
];

const getFieldsToShowHelper = ({
  moduleId,
  layoutFields,
  defaultLayoutFields,
  moduleFields,
  readOnlyFields,
  activeView
}) => recordTypeId => {
  // attempt to get fields marked as visible for the given record type
  let resolvedFields = compose(
    sortBy(prop("order")),
    filter(f => f),
    map(f => {
      if (readOnlyFields.includes(f.id)) return null;

      const matchingField = layoutFields.find(
        vf => vf.field_id === f.id && vf.record_type_id === recordTypeId
      );
      if (!matchingField) return null;
      return {
        ...f,
        order: matchingField ? matchingField.order : null,
        isVisible: Boolean(matchingField),
        isRequired: REQUIRED_FIELD_IDS.includes(f.id)
      };
    })
  )(moduleFields);

  // if no fields visible, fallback to active view or default fields
  if (!resolvedFields.length) {
    // if an active view is specified, use that
    if (
      activeView &&
      activeView.visible_fields &&
      activeView.visible_fields.length
    ) {
      resolvedFields = filter(
        f =>
          !readOnlyFields.includes(f.id) &&
          activeView.visible_fields.includes(f.id)
      )(moduleFields);

      if (activeView.sort_by) {
        resolvedFields = sortBy(f => activeView.field_order[f.id])(
          resolvedFields
        );
      }
    } else {
      // otherwise, fall back to default fields
      resolvedFields = filter(f => !readOnlyFields.includes(f.id))(
        defaultLayoutFields
      );
    }
  }

  if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
    // if accounts, ensure name field is included
    if (
      !resolvedFields.some(
        f => f.id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME
      )
    ) {
      const nameField = moduleFields.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME
      );
      if (nameField) {
        resolvedFields.unshift(nameField);
      }
    }
  } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
    // if contacts, ensure name, email fields are included
    if (
      !resolvedFields.some(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      )
    ) {
      const emailField = moduleFields.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      );
      if (emailField) {
        resolvedFields.unshift(emailField);
      }
    }
    if (
      !resolvedFields.some(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
      )
    ) {
      const lnameField = moduleFields.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
      );
      if (lnameField) {
        resolvedFields.unshift(lnameField);
      }
    }
    if (
      !resolvedFields.some(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME
      )
    ) {
      const fnameField = moduleFields.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME
      );
      if (fnameField) {
        resolvedFields.unshift(fnameField);
      }
    }
  }

  return resolvedFields;
};

function mapStateToProps(state, props) {
  const orgDetailsToPass = orgDetails(state);
  const eventDetailsToPass = eventDetails(state);
  const orgId = prop("id", orgDetailsToPass);
  const eventId = prop("id", eventDetailsToPass);
  const moduleFields = fields(state, props.moduleId);

  const getFieldsToShow = getFieldsToShowHelper({
    moduleId: props.moduleId,
    layoutFields: visibleFields(state, undefined, "add-record-layout"),
    defaultLayoutFields: defaultFields(state, undefined, "add-record-layout"),
    activeView: activeView(state, props.moduleId, props.viewId, props.groupId),
    moduleFields,
    readOnlyFields: resolveReadOnlyFields({
      moduleId: props.moduleId
    })
  });

  return {
    orgId,
    eventId,
    references: references(state),
    orgDetails: orgDetailsToPass,
    eventDetails: eventDetailsToPass,
    recordTypes: recordTypes(state, props.moduleId),
    user: user(state),
    moduleDetails: ModuleSelectors.moduleDetails(state, props.moduleId),
    moduleFields,
    getFieldsToShow
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFields,
      getLayoutVisibleFields,
      addReference,
      getRecordTypes,
      addRecord,
      getModule,
      hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddRecord)
);
