import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import { BigTextButton } from "components/Base";

const Footer = ({ mode, activeStep }) => {
  switch (activeStep.id) {
    case "orderItems":
      return (
        <ButtonGroup className={css.container}>
          <div styleName="quantity">
            quantity: {activeStep.meta.countOfSelectedItems}
          </div>
          <Submit
            title={
              activeStep.processing ? (
                <span>
                  <i
                    className={[
                      "fa",
                      "fa-circle-o-notch",
                      css.processingIcon
                    ].join(" ")}
                  />
                  Saving...
                </span>
              ) : mode === "update" ? (
                "Save"
              ) : (
                "Continue"
              )
            }
            onClick={activeStep.goToNextStep}
            disabled={!activeStep.isValid}
            className={css.nextButton}
          />
        </ButtonGroup>
      );
    case "orderDetails":
      return (
        <ButtonGroup className={css.container}>
          <ButtonOutline
            title="BACK"
            onClick={activeStep.goToPreviousStep}
            className={css.grayButton}
          />
          <Submit
            title="CONTINUE"
            onClick={activeStep.goToNextStep}
            disabled={!activeStep.isValid}
            className={css.nextButton}
          />
        </ButtonGroup>
      );
    case "orderReview": {
      const submitTitle = activeStep.processing ? (
        <span>
          <i
            className={["fa", "fa-circle-o-notch", css.processingIcon].join(
              " "
            )}
          />
          PROCESSING ORDER
        </span>
      ) : (
        "PROCESS ORDER"
      );
      return (
        <ButtonGroup className={css.container}>
          <ButtonOutline
            title="BACK"
            onClick={activeStep.goToPreviousStep}
            className={css.grayButton}
          />
          <Submit
            title={submitTitle}
            onClick={activeStep.goToNextStep}
            disabled={activeStep.processing || !activeStep.isValid}
            className={css.nextButton}
          />
        </ButtonGroup>
      );
    }
    case "orderPayment": {
      const submitTitle = activeStep.processing ? (
        <span>
          <i
            className={["fa", "fa-circle-o-notch", css.processingIcon].join(
              " "
            )}
          />
          SAVING
        </span>
      ) : (
        "SAVE"
      );

      return (
        <ButtonGroup className={css.container}>
          <ButtonOutline
            title={
              <span className={css.label}>
                <i className={["material-icons", css.buttonArrow].join(" ")}>
                  arrow_back
                </i>
                BACK
              </span>
            }
            onClick={activeStep.goToPreviousStep}
            className={css.grayButton}
          />
          {/* <Submit
            title={submitTitle}
            onClick={activeStep.goToNextStep}
            disabled={!activeStep.isValid}
            className={css.nextButton}
          /> */}
          <BigTextButton
            color="primary5"
            transition="fast"
            onClick={activeStep.goToNextStep}
          >
            Save Draft
          </BigTextButton>
        </ButtonGroup>
      );
    }
    case "orderConfirmation":
      return (
        <div styleName="container">
          <Submit
            title="DONE"
            onClick={activeStep.goToNextStep}
            disabled={!activeStep.isValid}
            className={css.doneButton}
          />
        </div>
      );
    default:
      return <div />;
  }
};

Footer.propTypes = {
  activeStep: PropTypes.shape({
    id: PropTypes.string,
    processing: PropTypes.bool,
    isValid: PropTypes.bool,
    goToNextStep: PropTypes.func,
    goToPreviousStep: PropTypes.func,
    meta: PropTypes.object
  }).isRequired
};

export default CSSModules(Footer, css);
