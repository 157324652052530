import PropTypes from "prop-types";
import React, { Component } from "react";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import resolveEditorProps from "./resolveEditorProps";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import ButtonGroup from "components/Global/Buttons/CancelSaveButtonGroup";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import ACCOUNT_FIELD_IDS from "components/Event/Accounts/constants";
import TextCellFormatter from "components/Global/Table3/CellFormatters/Text";
import { get } from "lodash";
import { equals } from "ramda";

const FORCE_TEXT_FORMATTER_FOR_TYPES = [
  ACCOUNT_FIELD_IDS.name,
  CONTACT_STANDARD_FIELD_IDS.fname,
  CONTACT_STANDARD_FIELD_IDS.lname
];

@CSSModules(css)
class InlineInput extends Component {
  state = { value: this.props.value || {} };

  componentDidUpdate(prevProps) {
    if (!equals(prevProps.value, this.props.value)) {
      this.setState({
        value: this.props.value
      });
    }
  }

  handleUpdate = value => {
    this.setState({ value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const inputValue = this.state.value;
    if (inputValue.value === get(this.props.value, "value")) {
      return this.props.closeForm();
    }
    const data = {
      value: {
        type: this.props.field.type,
        value: inputValue.value
      },
      source: this.props.field.source,
      fieldId: this.props.field.id
    };
    return this.props.handleSave(data);
  };

  handleCancel = e => {
    e.preventDefault();
    return this.props.closeForm();
  };

  preventEnter(e) {
    // TODO: Disabling savin on enter until we can normalize the save trigger on inputs
    if (e.which === 13) {
      e.preventDefault();
      return false;
    }
  }

  handleClick = () => this.props.openForm(this.props.field.id);

  render() {
    const {
      activeElement,
      disabled,
      eventDetails,
      field,
      maxWidth,
      metaData,
      value
    } = this.props;

    if (field.id === activeElement && !disabled) {
      const Editor = resolveEditor(field);
      return (
        <li styleName="listItemActive">
          <div styleName="form">
            <span styleName="listItemTitleActive">{field.name}</span>
            <form
              onKeyPress={this.preventEnter}
              onSubmit={this.handleSubmit}
              styleName="listItemInfoActive"
            >
              <Editor
                autoFocus
                ref={ref => {
                  this.editor = ref;
                }}
                onChange={this.handleUpdate}
                value={this.state.value}
                rowMetaData={metaData}
                {...resolveEditorProps(field, eventDetails)}
              />
              <div styleName="buttonWrapper">
                <ButtonGroup handleCancel={this.handleCancel} />
              </div>
            </form>
          </div>
        </li>
      );
    }

    const Formatter = FORCE_TEXT_FORMATTER_FOR_TYPES.includes(field.id)
      ? TextCellFormatter
      : resolveFormatter(field);
    return (
      <li styleName="listItem">
        <div styleName="listMargin">
          <span styleName="listItemTitle">{field.name}</span>
          <span
            role="button"
            tabIndex="0"
            onClick={this.props.readOnly ? null : this.handleClick}
            styleName="listItemInfo"
            style={maxWidth ? { maxWidth } : {}}
          >
            <Formatter value={value} dependentValues={metaData} />
          </span>
        </div>
      </li>
    );
  }
}

InlineInput.propTypes = {
  activeElement: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  eventDetails: PropTypes.object.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  handleSave: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  openForm: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  value: PropTypes.shape({
    value: PropTypes.any
  })
};

export default InlineInput;
