import * as R from "ramda";
import {
  put,
  take,
  all,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import { channel } from "redux-saga";
import { push } from "react-router-redux";
import { actions, getters } from "Portal/IntakeForm/model";
import {
  getLoginData,
  getForgotData,
  getGroupValues,
  getContactValues,
  getGroupDataFields,
  getContactDataFields
} from "Portal/IntakeForm/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import {
  userId as getUserId,
  user as getUser
} from "redux/modules/user/selectors";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import Helpers from "utils/Global/Helpers";

import { registerError } from "redux/modules/errors/actions";
import Api from "Portal/IntakeForm/api";
import PortalUsersApi from "redux/modules/portal/users/api";
import UserApi from "redux/modules/user/api";
import STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const getParams = function*() {
  const formData = yield select(getters.formData);
  const intakeId = yield select(getters.intakeId);
  const userId = yield select(getUserId);

  return {
    userId,
    eventId: formData.event_id,
    intakeId
  };
};

const loginChannel = channel();

const init = function*({ payload: { intakeId, a: accountId, c: contactId } }) {
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  const user = yield select(getUser);

  try {
    yield put(actions.setIntakeId(intakeId));

    const { payload } = yield call(Api.getForm, {
      credentials,
      intakeId,
      query: {
        accountId,
        contactId
      }
    });

    let eventUserGroups = [];

    if (userId) {
      const eventUserResult = yield call(Api.getEventUserGroups, {
        credentials,
        eventId: payload.event_id
      });

      eventUserGroups = eventUserResult.payload;
    }

    const userEmail = user && user.email ? user.email : null;
    const selectedAccountId =
      eventUserGroups && eventUserGroups.length ? eventUserGroups[0].id : null;
    const account = selectedAccountId
      ? R.find(a => a.id === selectedAccountId)(eventUserGroups)
      : null;
    const selectedContact =
      account && userEmail
        ? R.find(c =>
            c.email ? c.email.toLowerCase().trim() === userEmail : false
          )(account.contacts)
        : null;

    yield put(
      actions.seedFormData({
        userEmail,
        formPayload: payload,
        eventUserGroups,
        isLoggedIn: Boolean(userId),
        selectedAccountId,
        selectedContactId: selectedContact ? selectedContact.id : null,
        contactValues: selectedContact ? selectedContact.values : {},
        groupValues: selectedAccountId
          ? R.compose(
              R.prop("values"),
              R.find(a => a.id === selectedAccountId)
            )(eventUserGroups)
          : {}
      })
    );
    yield put(actions.setLoading(false));
    if (
      contactId &&
      payload.selected_contact_values &&
      payload.selected_contact_values[STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]
    ) {
      yield put(
        actions.checkUser({
          id: STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL,
          value:
            payload.selected_contact_values[
              STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
            ]
        })
      );
    }
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred getting intake form data"
          }
        ])
      )
    ]);
  }
};

const handleCallback = function*({ payload }) {
  const credentials = yield select(getCredentials);

  try {
    const convertSessionPayload = yield call(Api.convertSession, credentials, {
      intakeFormId: payload.intakeFormId,
      sessionId: payload.sessionId,
      userId: credentials.userId
    });

    if (
      !convertSessionPayload.payload ||
      convertSessionPayload.payload.expired
    ) {
      window.location = `/intake/${payload.slug}/${payload.intakeFormId}`;
    }

    if (payload.redirectTo === "home") {
      window.location = `/portals/${convertSessionPayload.payload.event.slug}/${convertSessionPayload.payload.event.uuid}`;
    } else if (payload.redirectTo === "people") {
      window.location = `/portals/${convertSessionPayload.payload.event.slug}/${convertSessionPayload.payload.event.uuid}/people`;
    }
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred converting session"
        }
      ])
    );
  }
};

const handleLogin = function*({ payload }) {
  if (payload.err) {
    console.error(
      `[Intake Landing] Error logging in`,
      payload.err.error_description
    );
    yield put(actions.setSaving(false));
    yield put(actions.setError(payload.err.error_description));
  } else {
    yield put(actions.setSaving(true));
  }
};

const saveValues = function*() {
  const { eventId, intakeId, userId } = yield call(getParams);
  const sessionId = yield select(getters.sessionId);
  const agreedToTerms = yield select(getters.termsAccepted);
  const groupValues = yield select(getGroupValues);
  const contactValues = yield select(getContactValues);
  const groupFields = yield select(getGroupDataFields);
  const contactFields = yield select(getContactDataFields);
  const userExists = yield select(getters.userExists);
  const isVirtualUser = yield select(getters.isVirtualUser);
  const portalUserExists = yield select(getters.portalUserExists);
  const formData = yield select(getters.formData);
  const credentials = yield select(getCredentials);
  const selectedAccountId = yield select(getters.selectedAccountId);
  const selectedContactId = yield select(getters.selectedContactId);
  const isUserLoggedIn = yield select(getters.isLoggedIn);
  const fname = R.pathOr("", [STANDARD_FIELD_IDS.CONTACTS.FIRST_NAME, "value"])(
    contactValues
  );
  const lname = R.pathOr("", [STANDARD_FIELD_IDS.CONTACTS.LAST_NAME, "value"])(
    contactValues
  );
  let email = R.pathOr("", [STANDARD_FIELD_IDS.CONTACTS.EMAIL, "value"])(
    contactValues
  );
  email = email.toLowerCase().trim();
  const password = R.pathOr("", ["password", "value"])(contactValues);
  let job = "";

  yield put(actions.setError(""));
  yield put(actions.setSaving(true));

  if (isUserLoggedIn) {
    // handle: user is already logged in
    job = "handleRegisterExistingUser";
  } else if (userExists === false) {
    // handle: user does not exist
    yield call(UserApi.post, credentials, {
      email,
      password,
      firstName: fname,
      lastName: lname,
      eventId,
      contactTypeId: formData.contact_record_type_id,
      createContactAsDraft: false
    });
    job = "handleRegisterNewUser";
  } else if (
    userExists === true &&
    isVirtualUser === false &&
    portalUserExists === false
  ) {
    // handle: user exists, is not a virtual user, is not a portal user
    job = "handleRegisterExistingUser";
  } else if (
    userExists === true &&
    isVirtualUser === true &&
    portalUserExists === true
  ) {
    // handle: user exists, is a virtual user, is a portal user
    const {
      payload: { user }
    } = yield call(UserApi.convert, credentials, {
      email,
      password
    });
  } else if (
    userExists === true &&
    isVirtualUser === true &&
    portalUserExists === false
  ) {
    // handle: user exists, is a virtual user, is a not portal user
    job = "handleRegisterExistingUser";
  } else if (userExists === true) {
    job = "handleLogin";
  }

  // create session
  try {
    const { payload: createdSessionId } = yield call(
      Api.createSession,
      credentials,
      intakeId,
      {
        sessionId,
        intakeFormId: intakeId,
        eventId,
        agreedToTerms,
        selectedAccountId,
        selectedContactId,
        groupValues: R.pick(groupFields.map(f => f.id))(groupValues),
        contactValues: R.compose(
          R.pick(contactFields.map(f => f.id)),
          R.omit(["password", "confirm-password"])
        )(contactValues)
      }
    );

    yield put(actions.setSessionId(createdSessionId));

    // process login
    if (isUserLoggedIn) {
      yield put(
        actions.handleCallback({
          intakeFormId: intakeId,
          sessionId: createdSessionId,
          slug: formData.event_slug,
          redirectTo: formData.redirect_to
        })
      );
    } else {
      window.webAuth.login(
        {
          username: email,
          password,
          realm: window.__AUTH0_REALM__,
          redirectUri: `${window.__LENND_APP_URL__}/intake-callback?intakeFormId=${intakeId}&sessionId=${createdSessionId}&job=${job}&redirectTo=${formData.redirect_to}&slug=${formData.event_slug}`
        },
        err =>
          loginChannel.put({
            type: actions.handleLogin.type,
            payload: {
              err
            }
          })
      );
    }
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred saving session"
        }
      ])
    );
  }
};

const nextPage = function*() {
  const activeIndex = yield select(getters.activeIndex);

  if (activeIndex < 2) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    yield put(actions.setActiveIndex(activeIndex + 1));
  } else {
    yield call(saveValues);
  }
};

const backPage = function*() {
  const activeIndex = yield select(getters.activeIndex);

  if (activeIndex > 0) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    yield put(actions.setActiveIndex(activeIndex - 1));
  }
};

const goToLoginPage = function*() {
  const { intakeId } = yield call(getParams);
  const formData = yield select(getters.formData);
  window.location = `/portal-login/${formData.event_slug}/${formData.event_uuid}?returnTo=/intake/${formData.event_slug}/${intakeId}`;
};

const goToFormPage = function*() {
  const { intakeId } = yield call(getParams);
  const formData = yield select(getters.formData);
  yield put(push(`/intake/${formData.event_slug}/intake/${intakeId}`));
};

const intakeLogin = function*() {
  const { eventId, intakeId } = yield call(getParams);
  const login = yield select(getLoginData);
  const data = {
    id: intakeId,
    eventId,
    login
  };
};

const handleLogout = function*() {
  const { intakeId } = yield call(getParams);
  const formData = yield select(getters.formData);
  window.location = `/logout?returnTo=/intake/${formData.event_slug}/${intakeId}`;
};

const forgotPassword = function*() {
  const { eventId, intakeId } = yield call(getParams);
  const forgot = yield select(getForgotData);
  const data = {
    id: intakeId,
    eventId,
    forgot
  };
};

const onEmailUpdate = function*({ payload: { id, value } }) {
  if (id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL) {
    const { eventId } = yield call(getParams);
    const credentials = yield select(getCredentials);
    const email = value ? value.value.trim().toLowerCase() : null;

    if (!email || !Helpers.isValidEmail(email)) return false;

    yield put(actions.setSearching(true));

    const {
      payload: { userExists, isVirtualUser, portalUserExists }
    } = yield call(PortalUsersApi.searchUsers, credentials, {
      eventId,
      email
    });

    yield put(actions.setSearching(false));
    yield put(
      actions.setUserData({
        userExists,
        isVirtualUser,
        portalUserExists
      })
    );
  }
  return true;
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchCallback = function*() {
  yield takeEvery(actions.handleCallback.type, handleCallback);
};

const watchLoginCallback = function*() {
  yield takeEvery(actions.handleLogin.type, handleLogin);
};

const watchLogout = function*() {
  yield takeEvery(actions.handleLogout.type, handleLogout);
};

const watchLoginChannel = function*() {
  while (true) {
    const action = yield take(loginChannel);
    yield put(action);
  }
};

const watchNextPage = function*() {
  yield takeEvery(actions.nextPage.type, nextPage);
};

const watchBackPage = function*() {
  yield takeEvery(actions.backPage.type, backPage);
};

const watchGoToLoginPage = function*() {
  yield takeEvery(actions.goToLoginPage.type, goToLoginPage);
};

const watchGoToFormPage = function*() {
  yield takeEvery(actions.goToFormPage.type, goToFormPage);
};

const watchLogin = function*() {
  yield takeEvery(actions.login.type, intakeLogin);
};

const watchForgot = function*() {
  yield takeEvery(actions.forgotPassword.type, forgotPassword);
};

const watchEmailUpdate = function*() {
  yield takeEvery(
    [actions.updateContactValues.type, actions.checkUser.type],
    onEmailUpdate
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchCallback),
    fork(watchNextPage),
    fork(watchGoToLoginPage),
    fork(watchGoToFormPage),
    fork(watchEmailUpdate),
    fork(watchLogin),
    fork(watchForgot),
    fork(watchLogout),
    fork(watchLoginChannel),
    fork(watchLoginCallback),
    fork(watchBackPage)
  ]);
};

export default rootSaga;
