/* eslint-disable no-underscore-dangle */
import { createSelector } from "reselect";
import * as R from "ramda";

import { credentialsRequests } from "redux/modules/portal/reports/selectors";

const mapIndexed = R.addIndex(R.map);

export const getAllAssignmentsRows = createSelector(
  credentialsRequests,
  R.compose(
    mapIndexed((assignmentRow, idx) => ({
      id: R.propOr(idx, "id", assignmentRow),
      contactName: {
        type: "text",
        value: R.prop("contactName", assignmentRow)
      },
      type: {
        type: "text",
        value: R.prop("type", assignmentRow)
      },
      quantity: {
        type: "text",
        value: R.prop("quantity", assignmentRow)
      },
      details: {
        type: "text",
        value: R.prop("details", assignmentRow)
      },
      status: {
        type: "text",
        value: R.prop("status", assignmentRow)
      }
    })),
    R.propOr([], "assignments")
  )
);

export const getSummaryValues = createSelector(
  credentialsRequests,
  R.compose(
    R.map(summaryRow => ({
      id: R.prop("id", summaryRow),
      name: {
        type: "text",
        value: R.prop("name", summaryRow)
      },
      requested: {
        type: "text",
        value: R.prop("requested", summaryRow)
      },
      approved: {
        type: "text",
        value: R.prop("approved", summaryRow)
      },
      rejected: {
        type: "text",
        value: R.prop("rejected", summaryRow)
      },
      pending: {
        type: "text",
        value: R.prop("pending", summaryRow)
      }
    })),
    R.propOr([], "counts")
  )
);

export const getSummaryFields = R.always([
  {
    id: "name",
    type: "text",
    name: "Item"
  },
  {
    id: "requested",
    type: "text",
    name: "Requested"
  },
  {
    id: "pending",
    type: "text",
    name: "Pending 🕑"
  },
  {
    id: "rejected",
    type: "text",
    name: "Rejected 🚫"
  },
  {
    id: "approved",
    type: "text",
    name: "Approved ✅"
  }
]);

export const getAllFields = R.always([
  {
    id: "contactName",
    type: "text",
    name: "Assigned To"
  },
  {
    id: "type",
    type: "text",
    name: "Item"
  },
  {
    id: "quantity",
    type: "text",
    name: "Quantity"
  },
  {
    id: "details",
    type: "text",
    name: "Details"
  },
  {
    id: "status",
    type: "order-status",
    name: "Status"
  }
]);
