import * as R from "ramda";
import React from "react";
import numeral from "numeral";

import { getValue, getSettings } from "../../utils";

const Currency = props => {
  const value = getValue(props);
  const prefix = getSettings(props, "prefix", "$");
  const format = getSettings(props, "format", "0,0.00");

  if (!R.isEmpty(value)) {
    return (
      <div>
        {prefix} {numeral(value).format(format)}
      </div>
    );
  }

  return <div />;
};

export default Currency;
