import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const IconSelectorLabel = ({ icon = "business" }) => (
  <div styleName="iconSelector">
    <i className="material-icons">{icon}</i>
    <i className="material-icons">keyboard_arrow_down</i>
  </div>
);

IconSelectorLabel.propTypes = {
  icon: PropTypes.string
};

export default CSSModules(IconSelectorLabel, css);
