import { RECEIVE, REQUEST } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getItemBlocksByEventAndType(eventId, typeId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getItemBlocksByEventAndType(
        getState().user.user.credentials,
        eventId,
        typeId
      );
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item blocks"
          }
        ])
      );
    }
  };
}

export function getItemBlocks(eventId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.get(getState().user.user.credentials, eventId);
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item blocks"
          }
        ])
      );
    }
  };
}

export function addItemBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addItemBlock(
        getState().user.user.credentials,
        data
      );
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding item block"
          }
        ])
      );
    }
  };
}

export function updateItemBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateItemBlock(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item block"
          }
        ])
      );
    }
  };
}

export function bulkUpdateItemBlocks(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkUpdateItemBlocks(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred bulk updating item blocks"
          }
        ])
      );
    }
  };
}

export function deleteItemBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteItemBlock(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting item block"
          }
        ])
      );
    }
  };
}
