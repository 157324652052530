import React from "react";
import { connect } from "react-redux";
import {
  Div,
  Text0,
  Text3,
  Text5,
  CheckIcon,
  RightIcon
} from "components/Base";
import {
  getWelcomeColor,
  getGroupColor,
  getContactColor
} from "Portal/IntakeForm/selectors";
import { getters } from "Portal/IntakeForm/model";
import Circle from "ui-kit/Circle";

const decorate = connect(state => ({
  welcomeColor: getWelcomeColor(state),
  groupColor: getGroupColor(state),
  contactColor: getContactColor(state),
  activeIndex: getters.activeIndex(state)
}));

const Steps = ({
  welcomeColor,
  groupColor,
  contactColor,
  activeIndex,
  mobile
}) => {
  const Text = mobile ? Text3 : Text5;

  return (
    <Div
      display={
        mobile ? "column.flex-start.flex-start" : "row.flex-start.center"
      }
      height={mobile ? "inherit" : 80}
      mt={mobile ? 4 : 0}
      mb={mobile ? 6 : 4}
    >
      <Div display="row.flex-start.center">
        {activeIndex === 0 ? (
          <Div
            bg="white"
            shadow={2}
            width={16}
            height={16}
            style={{ borderRadius: 8 }}
            display="row.center.center"
          >
            <Circle size={10} bg="indigo7" />
          </Div>
        ) : (
          <Circle size={16} bg="neutral2">
            <CheckIcon size={10} color="white" />
          </Circle>
        )}
        <Text ml={2} bold color={welcomeColor}>
          Welcome
        </Text>
      </Div>
      {mobile ? null : <RightIcon color="neutral5" size={16} mx={5} />}

      <Div display="row.flex-start.center">
        {activeIndex === 1 ? (
          <Div
            bg="white"
            shadow={2}
            width={16}
            height={16}
            style={{ borderRadius: 8 }}
            display="row.center.center"
          >
            <Circle size={10} bg="indigo7" />
          </Div>
        ) : (
          <Circle size={16} bg="neutral2">
            {activeIndex > 1 ? (
              <CheckIcon size={10} color="white" />
            ) : (
              <Text0 color="neutral5">2</Text0>
            )}
          </Circle>
        )}
        <Text ml={2} bold color={groupColor}>
          Company Info
        </Text>
      </Div>

      {mobile ? null : <RightIcon color="neutral5" size={16} mx={5} />}

      <Div display="row.flex-start.center">
        {activeIndex === 2 ? (
          <Div
            bg="white"
            shadow={2}
            width={16}
            height={16}
            style={{ borderRadius: 8 }}
            display="row.center.center"
          >
            <Circle size={10} bg="indigo7" />
          </Div>
        ) : (
          <Circle size={16} bg="neutral2">
            {activeIndex > 2 ? (
              <CheckIcon size={10} color="white" />
            ) : (
              <Text0 color="neutral5">3</Text0>
            )}
          </Circle>
        )}
        <Text ml={2} bold color={contactColor}>
          Contact Info
        </Text>
      </Div>
    </Div>
  );
};

export default decorate(Steps);
