/* eslint-disable no-underscore-dangle */
import React from "react";
import * as R from "ramda";
import copy from "copy-to-clipboard";
import { all, call, put, select, spawn, take } from "redux-saga/effects";

import { CARD_ACTIONS, TABLE_ACTIONS } from "../constants";
import { ACCOUNT_PRIMARY_CONTACTS } from "SendEmailModal/utils/send-to-option-types";
import { ASSIGNMENT_MANAGER } from "utils/Emails/default-emails";
import { accounts } from "@lennd/value-types/src/constants/standard-modules";

import { actions } from "../model";
import { actions as ActionsPopoverActions } from "ui-kit/ActionsPopover/model";

import { hideModal, showModal } from "redux/modules/modal/actions";

import { getGroupIds, getSelectedGroupType } from "../selectors";
import {
  eventDetails,
  eventId as getEventId
} from "redux/modules/event/selectors";

import SendEmailModal from "SendEmailModal/View";
import { PrimaryContactsModal } from "Passes/GuestLists/PrimaryContacts";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddGroupModal from "Passes/GuestLists/AddRecordModal";
import SummaryModal from "Passes/GuestLists/SummaryModal";
import { AllocationsModal } from "Passes/GuestLists/Items";

import { makeFuture } from "utils/General/sagas";
import { apiCall } from "App/Data/sagas";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { push } from "react-router-redux";

const addAllocations = function*({ payload: { data } }) {
  const handleDone = yield call(makeFuture);

  yield put(
    showModal({
      content: (
        <AllocationsModal
          onSave={R.prop("done", handleDone)}
          itemTypeId={R.prop("itemTypeId", data)}
          groupId={R.prop("groupId", data)}
          groupName={R.prop("groupName", data)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  yield call(R.prop("onRealized", handleDone));

  yield all([put(hideModal()), put(actions.fetchGuestLists())]);
};

const addContactModal = function*({ payload: { data } }) {
  const handleDone = yield call(makeFuture);

  const groupType = yield select(getSelectedGroupType);

  yield put(
    showModal({
      content: (
        <PrimaryContactsModal
          groupId={R.prop("groupId", data)}
          groupName={R.prop("groupName", data)}
          recordTypeName={R.prop("name", groupType)}
          recordTypeColor={R.prop("background_color", groupType)}
          recordTypeIcon={R.prop("icon", groupType)}
          hideModal={R.prop("done", handleDone)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  yield call(R.prop("onRealized", handleDone));

  yield all([put(hideModal()), put(actions.fetchGuestLists())]);
};

const addGroup = function*() {
  const handleDone = yield call(makeFuture);
  const selectedGroupType = yield select(getSelectedGroupType);
  yield put(
    showModal({
      content: (
        <AddGroupModal
          recordTypeId={R.prop("id", selectedGroupType)}
          recordTypeName={R.prop("name", selectedGroupType)}
          recordTypeColor={R.prop("background_color", selectedGroupType)}
          recordTypeIcon={R.prop("icon", selectedGroupType)}
          onDone={R.prop("done", handleDone)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  yield call(R.prop("onRealized", handleDone));

  yield all([put(hideModal()), put(actions.fetchGuestLists())]);
};

const copyShareLink = function*({ payload: { data } }) {
  const event = yield select(eventDetails);
  yield call(
    copy,
    `${window.__LENND_APP_URL__}/assignment-manager/${event.slug}/${event.uuid}/${data.groupId}`
  );
};

const editAllocations = function*({ payload: { data } }) {
  const handleDone = yield call(makeFuture);
  const event = yield select(eventDetails);

  yield put(
    showModal({
      content: (
        <BulkEditLineItemsModal
          viaAllocations={true}
          showOverview={false}
          accountName={data.groupName}
          where={{
            eventId: event.id,
            requireAssignment: true,
            orderAccountId: data.groupId,
            orderType: "group"
          }}
          onDone={handleDone.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  yield call(R.prop("onRealized", handleDone));

  yield all([put(hideModal()), put(actions.fetchGuestLists())]);
};

const goToPortals = function*() {
  yield put(push(`/event-light/${yield select(getEventId)}/portals`));
};

const openGroupProfile = function*({ payload: { data } }) {
  const event = yield select(eventDetails);
  yield call(
    [window, window.open],
    `${window.__LENND_APP_URL__}/event-light/${event.id}/account/${data.groupId}`
  );
};

const reviewPending = function*({ payload: { data } }) {
  const event = yield select(eventDetails);
  yield call(
    [window, window.open],
    `${window.__LENND_APP_URL__}/event/${event.id}/account/${data.id}/credentials`
  );
};

const sendEmail = function*({ payload: { data } }) {
  yield put(
    showModal({
      content: (
        <SendEmailModal
          moduleId={R.prop("id", accounts)}
          records={[R.prop("groupId", data)]}
          selectedOptions={[ACCOUNT_PRIMARY_CONTACTS]}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const sendLink = function*({ payload: { data } }) {
  yield put(
    showModal({
      content: (
        <SendEmailModal
          moduleId={R.prop("id", accounts)}
          records={[R.prop("groupId", data)]}
          selectedOptions={[ACCOUNT_PRIMARY_CONTACTS]}
          {...ASSIGNMENT_MANAGER}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showSendEmailModal = function*() {
  const groupIds = yield select(getGroupIds);
  yield put(
    showModal({
      content: (
        <SendEmailModal
          moduleId={R.prop("id", accounts)}
          records={groupIds}
          selectedOptions={[ACCOUNT_PRIMARY_CONTACTS]}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showSettingsModal = function*() {
  const handleDone = yield call(makeFuture);
  const selectedGroupType = yield select(getSelectedGroupType);

  yield put(
    actions.setSettingsModalProps({
      moduleId: R.prop("module_id", selectedGroupType),
      onClose: R.prop("done", handleDone),
      recordTypeId: R.prop("id", selectedGroupType),
      recordTypeName: R.prop("name", selectedGroupType)
    })
  );
  yield put(actions.setIsSettingsModalVisible(true));
};

const showSummaryModal = function*({ payload: { data } }) {
  const handleDone = yield call(makeFuture);

  yield put(
    showModal({
      content: (
        <SummaryModal
          itemTypeId={R.prop("itemTypeId", data)}
          onClose={R.prop("done", handleDone)}
          groupId={R.prop("groupId", data)}
          groupName={R.prop("groupName", data)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  yield call(R.prop("onRealized", handleDone));

  yield all([put(hideModal()), put(actions.fetchGuestLists())]);
};

const updateGroupStatus = function*(data, value) {
  const isGuestListEnabled = R.prop("guestListEnabled", data);
  if (
    (isGuestListEnabled && value === "enabled") ||
    (!isGuestListEnabled && value === "disabled")
  ) {
    return;
  }
  try {
    const eventId = yield select(getEventId);
    const recordId = R.prop("id", data);

    const { payload } = yield call(apiCall, {
      method: "put",
      url: `/modules/event/${eventId}/record/${recordId}/guest-list-status/${value}`
    });
    if (payload) {
      yield put(
        showSnackbar({ message: `Guest List for group has been ${value}` })
      );
      yield put(actions.fetchGuestLists());
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "Error updating status"
        }
      ])
    );
  }
};

const viewGuestList = function*({ payload: { data } }) {
  const event = yield select(eventDetails);
  yield call(
    [window, window.open],
    `${window.__LENND_APP_URL__}/assignment-manager/${event.slug}/${event.uuid}/${data.groupId}`
  );
};

const disableGroup = function*({ payload: { data } }) {
  yield call(updateGroupStatus, data, "disabled");
};

const enableGroup = function*({ payload: { data } }) {
  yield call(updateGroupStatus, data, "enabled");
};

const mapDelegate = {
  [CARD_ACTIONS.ADD_GROUP]: addGroup,
  [CARD_ACTIONS.PORTALS]: goToPortals,
  [CARD_ACTIONS.SEND_EMAIL]: showSendEmailModal,
  [CARD_ACTIONS.SETTINGS]: showSettingsModal,
  [TABLE_ACTIONS.ADD_ALLOCATIONS]: addAllocations,
  [TABLE_ACTIONS.ADD_CONTACT]: addContactModal,
  [TABLE_ACTIONS.COPY_SHARE_LINK]: copyShareLink,
  [TABLE_ACTIONS.DISABLE_GROUP]: disableGroup,
  [TABLE_ACTIONS.EDIT_ALLOCATIONS]: editAllocations,
  [TABLE_ACTIONS.ENABLE_GROUP]: enableGroup,
  [TABLE_ACTIONS.OPEN_GROUP_PROFILE]: openGroupProfile,
  [TABLE_ACTIONS.REVIEW_PENDING_REQUESTS]: reviewPending,
  [TABLE_ACTIONS.SEND_LINK]: sendLink,
  [TABLE_ACTIONS.SEND_EMAIL]: sendEmail,
  [TABLE_ACTIONS.SHOW_SUMMARY]: showSummaryModal,
  [TABLE_ACTIONS.VIEW_GUEST_LIST]: viewGuestList
};

const watchExecuteAction = function*() {
  for (;;) {
    const action = yield take(ActionsPopoverActions.executeAction.type);

    const delegate = R.prop(
      R.path(["payload", "actionId"], action),
      mapDelegate
    );
    if (delegate) {
      yield spawn(delegate, action);
    }
  }
};

export default watchExecuteAction;
