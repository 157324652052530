import React, { Component } from "react";
import autobind from "autobind-decorator";

import ManageEventActions from "actions/Event/DetailsActions";

import Loading from "ui-kit/Loading";
import PermissionSelect from "components/Global/Inputs/PermissionSelect";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class EditCollaboratorInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      eventPermissionsRole: null,
      workEventAs: 0,
      eventRole: ""
    };
  }

  async componentDidMount() {
    const user = await this.props.getUser(
      this.props.eventDetails.id,
      this.props.userId
    );
    this.setState({
      loading: false,
      eventPermissionsRole: user.permission_role,
      workEventAs: 0,
      eventRole: user.role
    });
  }

  @autobind
  _handleChange(event) {
    this.setState({ message: event.target.value });
  }

  @autobind
  onEventUserSave() {
    const data = {
      eventRole: this.state.eventRole,
      eventWorkedAs: this.state.workEventAs
    };

    if (this.props.showPermissions) {
      data.eventPermissionsRole = this.state.eventPermissionsRole;
    }

    ManageEventActions.updateUserFromEvent(
      this.props.currentUser.credentials,
      this.props.eventDetails.id,
      this.props.userId,
      data,
      () => {
        this.props.onUpdated();
        this.props.hideModal();

        // If the current user is updating their own permissions, refresh so it's accurate
        if (this.props.userId === this.props.currentUser.id) {
          this.props.getEvents(this.props.userId);
        }
      }
    );
  }

  @autobind
  onWorkEventAsChange(event) {
    this.setState({ workEventAs: event.target.value });
  }

  @autobind
  onEventRoleChange(event) {
    this.setState({ eventRole: event.target.value });
  }

  @autobind
  onPermissionsChange(permission) {
    this.setState({ eventPermissionsRole: permission });
  }

  render() {
    // permissions
    let eventPermissionsField;
    if (this.props.user.is_event_user && this.props.showPermissions) {
      eventPermissionsField = (
        <div className="input-wrapper">
          <label>
            <span className={css.label}>Change Permissions</span>
            <PermissionSelect
              value={this.state.eventPermissionsRole}
              onSelect={this.onPermissionsChange}
            />
          </label>
        </div>
      );
    }

    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">
            {this.props.user.user_fname} {this.props.user.user_lname}
          </div>
          <div className="modal-description modal-description-big">
            {this.props.eventDetails.name}
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            {this.state.loading ? (
              <Loading />
            ) : (
              <>
                {this.props.user.is_event_user ? (
                  <div className="input-wrapper">
                    <label>
                      <span className={css.label}>
                        What's the user's role in this event?
                      </span>
                    </label>

                    <input
                      className="styled"
                      type="text"
                      name=""
                      value={this.state.eventRole}
                      placeholder="ex: Event Director"
                      onChange={this.onEventRoleChange}
                    />
                  </div>
                ) : (
                  ""
                )}

                {eventPermissionsField}
              </>
            )}

            <div className="actions-wrapper">
              <a
                className="action-positive small"
                onClick={this.onEventUserSave}
              >
                Save
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCollaboratorInfoModal;
