import React from "react";

import PageLocation from "../PageLocation";
import PrimaryPagination from "./PrimaryPagination";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import GeneralPagination from "../General";

@CSSModules(css)
class ReactTablePagination extends GeneralPagination {
  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent,
      NextComponent
    } = this.props;

    return pages === 1 ? null : (
      <div className={css.container}>
        <PrimaryPagination
          {...{
            // Computed
            pages,
            // Props
            page,
            showPageSizeOptions,
            pageSizeOptions,
            pageSize,
            showPageJump,
            canPrevious,
            canNext,
            onPageSizeChange,
            className,
            PreviousComponent,
            NextComponent,
            // Helpers
            getSafePage: this.getSafePage,
            changePage: this.changePage,
            applyPage: this.applyPage
          }}
        />
        <PageLocation
          pageSize={pageSize}
          pages={pages}
          page={page}
          total={this.props.data.length}
          rowsText={this.props.rowsText}
        />
      </div>
    );
  }
}

export default ReactTablePagination;
