import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ContextMenuItems extends Component {
  render() {
    return (
      <div>
        {this.props.menu.map((item, index) => {
          let row;
          const handleClick = () => {
            item.action(this.props.info);
          };
          if (item.type === "separator") {
            row = <div key={index} className={css.menuSeparator} />;
          } else {
            row = (
              <div key={index} className={css.menuRow} onClick={handleClick}>
                <i className={`material-icons ${css.menuRowIcon}`}>
                  {item.icon}
                </i>
                <div>{item.title}</div>
              </div>
            );
          }
          return row;
        })}
      </div>
    );
  }
}

export default ContextMenuItems;
