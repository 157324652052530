import React from "react";
import {
  Div,
  SuperOutlineButton,
  BigOutlineInput,
  EditIcon,
  Text4,
  Text2,
  RightArrowIcon,
  SearchIcon,
  BigShadedInput
} from "components/Base";
import * as R from "ramda";
import Header from "Forms/WizardModal/View/Header";
import { SELECT_FORM_STEPS } from "Forms/WizardModal/constants";

const SelectForm = ({
  handlers,
  back,
  moduleFields,
  selectId,
  setFilter,
  setNewModuleName,
  createAction,
  createButton,
  createTitle,
  createDescription,
  createButtonBg,
  createButtonColor,
  showModuleName = true,
  showRightIcon = false,
  placeholder = "",
  moduleTitle = "module"
}) => (
  <Div width={1} height={1}>
    <Header handlers={handlers} steps={SELECT_FORM_STEPS} />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      style={{ height: "calc(100% - 157px)", overflow: "auto" }}
    >
      <Div
        height={130}
        bra={1}
        bc={{ default: "neutral2", hover: "info6" }}
        bg="white"
        py={5}
        pr={5}
        width={1}
        display="row"
        transition="fast"
        style={{ flexShrink: 0 }}
      >
        <Div height={1} width={80} display="row.center">
          <EditIcon size={36} color="info6" />
        </Div>
        <Div height={1} style={{ width: "calc(100% - 80px)" }}>
          <Text4 bold color="black">
            {createTitle}
          </Text4>
          <Text2>{createDescription}</Text2>
          <Div display="row.space-between" mt={2}>
            {showModuleName && (
              <BigOutlineInput
                bra={1}
                ba={2}
                width={490}
                placeholder="Your form & module name"
                continuous
                onChange={text => setNewModuleName(text)}
              />
            )}
            <Div
              bra={1}
              bg={createButtonBg || { default: "neutral2", hover: "info7" }}
              color={createButtonColor || { default: "gray7", hover: "white" }}
              px={4}
              py={2}
              style={{ cursor: "pointer" }}
              fs={2}
              fw={3}
              transition="fast"
              onClick={() => {
                createAction();
              }}
              width={showRightIcon ? 190 : 180}
              textAlign="center"
              height={40}
              display={
                showRightIcon ? "row.space-between.center" : "row.center.center"
              }
            >
              <Div>{createButton}</Div>
              {showRightIcon && <RightArrowIcon color="white" />}
            </Div>
          </Div>
        </Div>
      </Div>

      <Div
        bra={1}
        bc={{ default: "neutral2", hover: "info6" }}
        bg="white"
        py={5}
        pr={5}
        width={1}
        display="column"
        transition="fast"
        mt={2}
        style={{ flexShrink: 0 }}
      >
        <Div display="row" width={1} mb={3}>
          <Div width={80} display="row.center">
            <RightArrowIcon size={36} />
          </Div>
          <Div style={{ width: "calc(100% - 80px)" }}>
            <Text4 bold color="black">
              Create from an existing {moduleTitle}
            </Text4>
            <Text2>
              Data collected from this form will be saved into this{" "}
              {moduleTitle}.
            </Text2>
          </Div>
        </Div>
        <Div display="row.space-between" mt={2} width={1} pl={5}>
          <BigShadedInput
            continuous
            LeftIcon={SearchIcon}
            placeholder={placeholder}
            onChange={text => setFilter(text)}
            bg="neutral1"
            width={1}
          />
        </Div>
        <Div pl={5} mt={2}>
          {R.map(
            mod => (
              <Div
                width={1}
                ba={2}
                bc={{ default: "neutral2", hover: "info6" }}
                bg="white"
                bra={2}
                transition="fast"
                display="row.space-between.center"
                style={{ flexShrink: 0 }}
                px={4}
                mb={1}
                height={56}
                key={mod.id}
                onClick={() => {
                  selectId(mod.id);
                }}
              >
                <Div display="row.flex-start.center">
                  <Text4 bold ml={3}>
                    {mod.name}
                  </Text4>
                </Div>
                <Div
                  bra={1}
                  bg={{ default: "neutral2", hover: "info6" }}
                  color={{
                    default: "gray7",
                    hover: "white"
                  }}
                  px={4}
                  py={2}
                  style={{ cursor: "pointer" }}
                  fs={2}
                  fw={3}
                  transition="fast"
                  textAlign="center"
                  width={110}
                  ml={2}
                >
                  SELECT
                </Div>
              </Div>
            ),
            moduleFields
          )}
        </Div>
      </Div>
    </Div>
    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      p={5}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperOutlineButton onClick={handlers.hideModal}>
        Cancel
      </SuperOutlineButton>
    </Div>
  </Div>
);

export default SelectForm;
