import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Portal/PortalPeople/model";
import {
  Div,
  MediumFilledButton,
  MediumOutlineButton,
  Text3
} from "components/Base";

const decorate = connect(
  (state, props) => ({
    selectedIds: getters.selectedIds(state, props)
  }),
  {
    removeAllSelectedIds: actions.removeAllSelectedIds,
    openModal: () => actions.setIsAddUserModalVisible(true)
  }
);

export const Actions = ({ selectedIds, removeAllSelectedIds, openModal }) => (
  <Div
    display="row.flex-start.center"
    bg="neutral0"
    p={2}
    bra={3}
    shadow={{
      default: 1
    }}
  >
    <Text3 bold mr={2}>
      {R.length(selectedIds)} selected
    </Text3>

    <MediumOutlineButton onClick={removeAllSelectedIds} mr={2}>
      Clear selection
    </MediumOutlineButton>
    <MediumFilledButton bg="indigo7" onClick={openModal}>
      Add to this event
    </MediumFilledButton>
  </Div>
);

export default decorate(Actions);
