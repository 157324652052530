import * as R from "ramda";
import React, { useMemo } from "react";

import { EventDays } from "ui-kit/Form/View";

import { getValue, getField } from "../../utils";

import { getSelectedDays } from "utils/EventDays";

const EventDaysEditor = props => {
  const eventDays = getValue(props, R.identity, "");
  const field = getField(props);

  const value = useMemo(() => getSelectedDays({ eventDays }), [eventDays]);

  return (
    <EventDays
      iniValue={value}
      fieldId={field.id}
      instanceId={props.instanceId}
      withPopover={false}
    />
  );
};

export default EventDaysEditor;
