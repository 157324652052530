import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";
import { noop } from "utils/General";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";

const BoolCheckbox = ({
  label = null,
  description = "",
  disabled = false,
  onClick = noop,
  onBlur = noop,
  isAdminField = false,
  containerStyles = null,
  checked = false,
  caption = "",
  required = false,
  style = {},
  labelStyle = {},
  checkboxStyle = {},
  canShowSimpleSideBar = false,
  readOnly = false,
  instanceId = null,
  fieldData = null,
  isShown = false,
  setIsShown = noop
}) => (
  <InputGroup styles={containerStyles} style={style} onClick={onClick}>
    {!canShowSimpleSideBar ? (
      <Label
        required={required}
        isAdminField={isAdminField}
        description={description}
        style={labelStyle}
      >
        {label}
      </Label>
    ) : (
      <LabelSimpleForm
        isAdminField={isAdminField}
        required={required}
        description={description}
        readonly={readOnly}
        instanceId={instanceId}
        fieldData={fieldData}
        isShown={isShown}
        setIsShown={setIsShown}
      >
        {label}
      </LabelSimpleForm>
    )}
    <div styleName="inputWrapper" onBlur={onBlur} style={checkboxStyle}>
      <Checkbox
        label={caption}
        checked={checked || false}
        disabled={disabled}
      />
    </div>
  </InputGroup>
);

BoolCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  isAdminField: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  caption: PropTypes.string,
  containerStyles: PropTypes.object,
  canShowSimpleSideBar: PropTypes.bool
};

export default CSSModules(BoolCheckbox, css);
