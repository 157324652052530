import React from "react";
import { Div, FontIcon } from "components/Base";
import Checkbox from "material-ui/Checkbox";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

const RecordColumn = ({ title, value, ...props }) => (
  <Div display="column" width={75} {...props}>
    <Div fs={0} uppercase color="gray6" truncate>
      {title}
    </Div>
    <Div fs={0} color={value ? "gray9" : "gray6"} truncate>
      {value || "—"}
    </Div>
  </Div>
);

const RecordCard = ({
  name,
  onClick,
  metaValues,
  type,
  selectionMode,
  selected
}) => {
  let selection = null;
  if (selectionMode === "single") {
    selection = (
      <RadioButtonGroup
        name="record"
        valueSelected={selected ? "record" : null}
      >
        <RadioButton value="record" />
      </RadioButtonGroup>
    );
  } else if (selectionMode === "multiple") {
    selection = <Checkbox checked={selected} />;
  }
  return (
    <Div
      display="row.flex-start.flex-start"
      width={1}
      px={3}
      py={3}
      mb={1}
      bg={
        onClick
          ? {
              default: "white",
              hover: "neutral0"
            }
          : "white"
      }
      bra={1}
      style={{
        border: "1px solid #eee"
      }}
      onClick={onClick}
    >
      {selection ? <Div>{selection}</Div> : null}
      <Div flex={1} display="row.flex-start.center">
        <Div display="column" flex={1}>
          <Div fw={3} fs={2} mb={1} color="gray9" truncate>
            {name}
          </Div>
          <Div display="row.flex-start.center">
            {metaValues.map((metaValue, idx) => (
              <RecordColumn
                key={idx}
                title={metaValue.name}
                value={metaValue.value}
                mr={3}
              />
            ))}
          </Div>
        </Div>
        {type ? (
          <Div
            pill
            display="row.center.center"
            bg="neutral4"
            size={45}
            style={{
              backgroundColor: type.background_color
            }}
          >
            <FontIcon
              style={{
                color: type.text_color
              }}
            >
              {type.icon}
            </FontIcon>
          </Div>
        ) : null}
      </Div>
    </Div>
  );
};

export default RecordCard;
