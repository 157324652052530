import React from "react";
import { Div } from "components/Base";
import ReviewedCard from "./ReviewedCard";
import PendingCard from "./PendingCard";

const SetupChecklists = () => (
  <Div style={{ maxWidth: 880, marginTop: -10 }}>
    <PendingCard />
    <ReviewedCard />
  </Div>
);

export default SetupChecklists;
