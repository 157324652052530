import React from "react";

import {
  Div,
  ErrorIcon,
  CheckCircleIcon,
  DownloadIcon,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  BigFilledButton
} from "components/Base";
import { addS, noop } from "utils/General";

const tense = (len, future) => {
  if (future) {
    return "will be";
  }
  return len !== 1 ? "were" : "was";
};

const sizeMap = {
  1: Text1,
  2: Text2,
  3: Text3,
  4: Text4,
  5: Text5
};

const CounterText = ({ size = 4, children, ...styleProps }) =>
  React.createElement(sizeMap[size] || Text4, styleProps, children);

const createMessage = ({ count, name = "", append = "" }) =>
  `${count} ${name}${addS(count)} ${append}`;

export const Counters = ({
  rowsProcessed = 0,
  accountsCreated = 0,
  existingAccounts = 0,
  contactsCreated = 0,
  existingContacts = 0,
  ordersCreated = 0,
  orderVariantsCreated = 0,
  peopleCreated = 0,
  textSize = 5,
  willBeImported = false
}) => (
  <React.Fragment>
    <CounterText size={textSize} bold mb={2}>
      {createMessage({
        count: rowsProcessed,
        name: "row",
        append: `${tense(rowsProcessed, willBeImported)} processed`
      })}
    </CounterText>
    {accountsCreated > 0 && (
      <CounterText size={textSize - 1}>
        {createMessage({
          count: accountsCreated,
          name: "new group",
          append: `${tense(accountsCreated, willBeImported)} created`
        })}
      </CounterText>
    )}
    {existingAccounts > 0 && (
      <CounterText size={textSize - 1}>
        {createMessage({
          count: existingAccounts,
          name: "existing group",
          append: `${tense(existingAccounts)} found`
        })}
      </CounterText>
    )}
    {contactsCreated > 0 && (
      <CounterText size={textSize - 1}>
        {createMessage({
          count: contactsCreated,
          name: "new person",
          append: `${tense(contactsCreated, willBeImported)} created`
        })}
      </CounterText>
    )}
    {existingContacts > 0 && (
      <CounterText size={textSize - 1}>
        {createMessage({
          count: existingContacts,
          name: "existing person",
          append: `${tense(existingContacts)} found`
        })}
      </CounterText>
    )}
    {ordersCreated > 0 && (
      <CounterText size={textSize - 1}>
        {createMessage({
          count: ordersCreated,
          name: "new order",
          append: `${tense(ordersCreated, willBeImported)} created`
        })}
      </CounterText>
    )}
    {orderVariantsCreated > 0 && (
      <CounterText size={textSize - 1}>
        {createMessage({
          count: orderVariantsCreated,
          name: "new line item",
          append: `${tense(ordersCreated, willBeImported)} created`
        })}
      </CounterText>
    )}
    {peopleCreated > 0 && (
      <CounterText size={textSize - 1}>{`${peopleCreated} ${
        peopleCreated !== 1 ? "people" : "person"
      } ${tense(peopleCreated, willBeImported)} added`}</CounterText>
    )}
  </React.Fragment>
);

const ImportSummary = props => {
  const {
    skippedRows = 0,
    onDownloadSkipped = noop,
    onDownloadReport = noop,
    willBeImported = false
  } = props;

  return (
    <React.Fragment>
      <Div
        display="column.center.center"
        flex={1}
        bg="green0"
        p={6}
        bra={3}
        style={{
          flexShrink: 0
        }}
      >
        <CheckCircleIcon color="altA7" size={100} mb={4} />
        <Counters {...props} />
      </Div>
      {skippedRows ? (
        <Div display="column.center.center" p={6}>
          <Div display="row.center.center" p={4}>
            <ErrorIcon sizeWFS={7} color="altB3" />
            <Text5 bold ml={2}>
              {createMessage({
                count: skippedRows,
                name: "row",
                append: `${tense(
                  skippedRows,
                  willBeImported
                )} skipped due to issues`
              })}
            </Text5>
          </Div>
          <Div display="column.center.stretch" width={300}>
            <BigFilledButton
              bg="altB5"
              LeftIcon={DownloadIcon}
              onClick={() => onDownloadSkipped()}
            >
              Download skipped rows .csv
            </BigFilledButton>
            <BigFilledButton
              color="black"
              bg="neutral2"
              LeftIcon={DownloadIcon}
              mt={2}
              onClick={() => onDownloadReport()}
            >
              Issues report for skipped rows
            </BigFilledButton>
          </Div>
        </Div>
      ) : null}
    </React.Fragment>
  );
};

export default ImportSummary;
