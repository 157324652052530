import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RelatedModule from "./RelatedModule";
import { withRouter } from "react-router";

import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as RelatedRecordsActions from "redux/modules/modules/relatedRecords/actions";
import * as RelatedRecordsSelectors from "redux/modules/modules/relatedRecords/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";
import { deleteRecord } from "redux/modules/modules/records/actions";
import { addReference } from "redux/modules/entityReferences/actions";

import { references } from "redux/modules/entityReferences/selectors";

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetching: RelatedRecordsSelectors.isFetching(
      state,
      props.record.id,
      props.module.id,
      props.module.lookup_field ? props.module.lookup_field.id : null
    ),
    preferences: RelatedRecordsSelectors.preferences(
      state,
      props.record.id,
      props.module.id,
      props.module.lookup_field ? props.module.lookup_field.id : null
    ),
    records: RelatedRecordsSelectors.records(
      state,
      props.record.id,
      props.module.id,
      props.module.lookup_field ? props.module.lookup_field.id : null
    ),
    user: UserSelectors.user(state),
    references: references(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...snackbarActions,
      ...modalActions,
      ...RelatedRecordsActions,
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      deleteRecord,
      addReference
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RelatedModule)
);
