import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class AdvancedSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowing: false
    };
  }

  render() {
    const { title } = this.props;
    const { isShowing } = this.state;
    return (
      <div styleName="wrapper">
        <div
          styleName="bar"
          onClick={() => this.setState({ isShowing: !isShowing })}
        >
          <div styleName="barTitle">{title}</div>
          <i className="material-icons" styleName="barIcon">
            &#xE313;
          </i>
        </div>
        {isShowing && <div styleName="body">{this.props.children}</div>}
      </div>
    );
  }
}

AdvancedSettings.propTypes = {
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default AdvancedSettings;
