import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const layouts = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], action.payload.layouts)
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
      return false;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  layouts,
  fetching
});
