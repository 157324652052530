import * as R from "ramda";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import CSSModules from "react-css-modules";

import { noop } from "utils/General";

import { actions } from "./model";

import { SEVERITY } from "./constants";

import { filterNilOrEmpty } from "ui-kit/utils";
import { MediumFilledButton, CloseIcon } from "components/Base";

import css from "./styles.scss";

const mapBg = {
  [SEVERITY.INFO]: "#329af0", // blue5
  [SEVERITY.INFO_DARK]: "#212121",
  [SEVERITY.WARNING]: "#fcc419", // warning5
  [SEVERITY.ERROR]: "#c92a2a",
  [SEVERITY.SUCCESS]: "#40c057"
};

const mapIcon = {
  [SEVERITY.INFO]: "info_outline",
  [SEVERITY.INFO_DARK]: "info_outline",
  [SEVERITY.WARNING]: "report_problem",
  [SEVERITY.ERROR]: "error_outline",
  [SEVERITY.SUCCESS]: "check_circle_outline"
};

const decorate = R.compose(
  connect(
    null,
    bindInstance({ executeAction: actions.executeAction })
  ),
  CSSModules(css)
);

const Alert = ({
  buttonAction = {},
  clickAction = {},
  severity = SEVERITY.INFO_DARK,
  data = {},
  executeAction = noop,
  close = noop,
  children,
  style: customStyle = {},
  className = ""
}) => {
  const style = useMemo(
    () =>
      filterNilOrEmpty({
        backgroundColor: mapBg[severity] || mapBg[SEVERITY.INFO]
      }),
    [severity]
  );

  const emptyClickAction = !(R.isNil(clickAction) || R.isEmpty(clickAction));

  return (
    <div
      styleName="alert"
      style={{ ...style, ...customStyle }}
      className={className}
      onClick={
        emptyClickAction
          ? () => executeAction({ data, actionId: clickAction.id })
          : noop
      }
    >
      <div styleName="icon">
        <i className="material-icons">
          {mapIcon[severity] || mapIcon[SEVERITY.INFO]}
        </i>
      </div>
      {children}
      <div styleName="spacer">a</div>
      {!(R.isNil(buttonAction) || R.isEmpty(buttonAction)) ? (
        <MediumFilledButton
          ml={2}
          onClick={() => executeAction({ data, actionId: buttonAction.id })}
        >
          {buttonAction.name}
        </MediumFilledButton>
      ) : (
        <CloseIcon
          color="white"
          px={2}
          sizeWFS={4}
          onClick={e => {
            e.stopPropagation();
            close();
          }}
        />
      )}
    </div>
  );
};

export default decorate(Alert);
