import * as R from "ramda";
import {
  put,
  select,
  call,
  takeEvery,
  take,
  all,
  fork,
  delay
} from "redux-saga/effects";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getMode } from "EventLight/Common/Manage/selectors";

import { actions as ViewPickerActions } from "ui-kit/ViewPicker";
import { push } from "react-router-redux";
import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions as FilterControlsActions } from "ui-kit/FilterControls";

import { actions, getters } from "EventLight/Common/Manage"; // circular reference otherwise

import api from "EventLight/Common/Manage/api";

import filters from "./filters";
import search, { getItemTypes } from "./search";
import tableSagas from "./table";
import actionsSagas from "./actions";
import { getters as FilterControlsGetters } from "ui-kit/FilterControls";

import {
  SUMMARIES,
  VIEWPICKER_INSTANCE_ID,
  UPDATE_ZONES_ACTION
} from "EventLight/Common/Manage/constants";
import {
  REFRESH_DASHBOARDS,
  GROUP_BY_FILTER_ID,
  ATTENDEE_FILTER_ID,
  GROUP_FILTER_ID
} from "Passes/Common/constants";

import { review } from "App/Data/sagas";

import Api from "../api";

import * as STANDARD_MODULE from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const stats = function*({ meta }) {
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  const eventId = yield select(getEventId);
  try {
    yield put(actions.setLoadingStats(true, { meta }));

    const result = yield call(api.getStats, { credentials, userId, eventId });
    yield put(actions.setStats(result.payload, { meta }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching stats"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingStats(false, { meta }));
  }
};

const watchStats = function*() {
  yield takeEvery(actions.showStatsModal.type, stats);
};

const goToAccountProfile = function*({ payload: accountId }) {
  const id = yield select(getEventId);

  // eslint-disable-next-line no-underscore-dangle
  const url = `${window.__LENND_APP_URL__}/event-light/${id}/account/${accountId}`;

  yield call([window, window.open], url, "_blank");
};

const goToContactProfile = function*({ payload: contactId }) {
  const id = yield select(getEventId);

  // eslint-disable-next-line no-underscore-dangle
  const url = `${window.__LENND_APP_URL__}/event-light/${id}/contact/${contactId}`;

  yield call([window, window.open], url, "_blank");
};

const watchGoToAccountProfile = function*() {
  yield takeEvery(actions.viewAccountProfile.type, goToAccountProfile);
};

const watchGoToContactProfile = function*() {
  yield takeEvery(actions.viewContactProfile.type, goToContactProfile);
};

const fetchSummary = function*(action = {}) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  const { id, type } = yield select(getters.summary, {
    instanceId: R.path(["meta", "instanceId"], action)
  });

  try {
    if (type !== "") {
      let summary = {};

      if (type === SUMMARIES.ACCOUNT) {
        const { record = {} } = yield call(Api.getAccount, {
          recordId: id,
          moduleId: STANDARD_MODULE.accounts.id,
          eventId,
          credentials
        });

        const name = R.pathOr(
          "",
          ["record", "values", STANDARD_MODULE_FIELDS.ACCOUNTS.NAME, "value"],
          record
        );
        const moduleType = R.pathOr(
          "",
          ["record", "values", STANDARD_MODULE_FIELDS.MODULE.TYPE, "value"],
          record
        );

        summary = {
          name,
          moduleType
        };
      }
      if (type === SUMMARIES.CATEGORY) {
        const { payload: categories } = yield call(Api.getCategory, {
          categoryId: id,
          credentials
        });

        summary = {
          name: R.path([0, "name"])(categories),
          moduleType: null
        };
      }
      if (type === SUMMARIES.ITEM_TYPE) {
        yield put({ type: "IS_ITEM_TYPE" });
        const { payload: item } = yield call(Api.getItem, {
          itemId: id,
          credentials
        });

        summary = {
          name: R.path([0, "name"])(item),
          moduleType: R.path([0, "group_name"])(item),
          photoUrl: R.path([0, "photo_url"])(item)
        };
      }

      yield put(
        actions.setSelectedSummary({ type, ...summary }, { meta: action.meta })
      );
    }
  } catch (e) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};

const watchFetchSummary = function*() {
  yield takeEvery(actions.fetchSummary.type, fetchSummary);
};

const updateViewFields = function*({ payload: fields, meta }) {
  yield put(
    actions.updateVisibleFields(
      {
        visibleFields: fields.map(f => f.id),
        fieldOrder: fields.reduce((map, f, idx) => {
          map[f.id] = idx;
          return map;
        }, {})
      },
      { meta }
    )
  );

  yield put(actions.fetchData(null, { meta }));
};

const watchUpdateViewFields = function*() {
  yield takeEvery(actions.updateViewFields.type, updateViewFields);
};

const initData = function*({ payload: { mode } = {}, meta = {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const state = yield select(R.identity);
  const itemTypes = getItemTypes(state, meta);
  const itemTypeId = itemTypes[0];
  const instanceId = meta.instanceId;

  const { payload: preferences } = yield call(Api.getActiveView, {
    credentials,
    eventId,
    itemTypeId,
    mode: getters.mode(state, { instanceId })
  });
  const modeFromPath = yield select(getMode, { instanceId });

  yield all([
    put(
      ViewPickerActions.setActiveView(
        {
          activeViewId: preferences.id,
          activeViewName: preferences.name
        },
        {
          meta: {
            instanceId: VIEWPICKER_INSTANCE_ID
          }
        }
      )
    ),
    put(
      actions.initPreferences(
        { mode: mode || modeFromPath, preferences },
        { meta }
      )
    )
  ]);

  if (preferences && preferences.grouped_by) {
    yield put(
      FilterControlsActions.setSelectedOptions(
        { [preferences.grouped_by]: 1 },
        {
          options: {},
          meta: { instanceId: GROUP_BY_FILTER_ID }
        }
      )
    );
  }

  if (R.path(["quick_filters", "contactTypeIds"])(preferences)) {
    yield put(
      FilterControlsActions.setSelectedOptions(
        R.path(["quick_filters", "contactTypeIds"])(preferences),
        {
          options: {},
          meta: { instanceId: ATTENDEE_FILTER_ID }
        }
      )
    );
  }

  if (R.path(["quick_filters", "accountTypeIds"])(preferences)) {
    yield put(
      FilterControlsActions.setSelectedOptions(
        R.path(["quick_filters", "accountTypeIds"])(preferences),
        {
          options: {},
          meta: { instanceId: GROUP_FILTER_ID }
        }
      )
    );
  }

  yield all([
    put(actions.fetchData(null, { meta })),
    put(actions.fetchSummary(null, { meta })),
    put(actions.fetchViews(null, { meta }))
  ]);
};

const watchInitData = function*() {
  yield takeEvery(actions.initData.type, initData);
};

const printItems = function*({ payload: { lineItemIds }, meta }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  yield put(showSnackbar({ message: "Preparing file to print..." }));

  try {
    const initialResult = yield call(Api.printItems, {
      credentials,
      data: {
        eventId,
        lineItemIds
      }
    });

    yield delay(200);

    let progressResult = yield call(api.getStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(1000);
      progressResult = yield call(api.getStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    if (progressResult.payload.status === "error") {
      yield all([
        put(
          registerError([
            {
              system: "An error occurred printing",
              user: "An error occurred printing"
            }
          ])
        )
      ]);
      return false;
    }

    yield call(
      [window, window.open],
      progressResult.payload.payload.url,
      "_blank"
    );

    yield put(actions.fetchData({ silent: true }, { meta }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred printing"
          }
        ])
      )
    ]);
  }

  return true;
};

const redirectToAttendeeBreakDown = function*({ payload: { url } }) {
  yield put(SearchBarActions.clearSearch());
  yield put(push(url));
};

const watchRedirectToAttendeeBreakDown = function*() {
  yield takeEvery(
    actions.redirectToAttendeeBreakDown.type,
    redirectToAttendeeBreakDown
  );
};

const watchPrintItems = function*() {
  yield takeEvery(actions.printItems.type, printItems);
};

const undoPrintItems = function*({ payload: { lineItemIds }, meta }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    yield call(Api.undoPrintItems, {
      credentials,
      data: {
        eventId,
        lineItemIds
      }
    });

    yield put(actions.fetchData({ silent: true }, { meta }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred undoing print items"
          }
        ])
      )
    ]);
  }
};

const watchUndoPrintItems = function*() {
  yield takeEvery(actions.undoPrintItems.type, undoPrintItems);
};

const watchReview = function*() {
  for (;;) {
    const { payload, type, meta } = yield take([
      actions.approve.type,
      actions.deny.type
    ]);
    const response = R.propOr(null, type, {
      [actions.approve.type]: "approve",
      [actions.deny.type]: "reject"
    });
    try {
      const { success } = yield call(review, {
        response,
        targetType: "lineItemId",
        recordIds: payload.recordIds
      });

      yield put(actions.fetchData({ silent: false }, { meta }));
      if (success) {
        yield put(showSnackbar({ message: "Review finished" }));
      }
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred approving line items"
          }
        ])
      );
    }
  }
};

const saveNewZones = function*({
  payload: { itemId: lineItemId, selectedZones }
}) {
  try {
    const credentials = yield select(getCredentials);
    yield call(Api.updateLineItem, {
      credentials,
      data: {
        actions: [
          {
            action: UPDATE_ZONES_ACTION,
            set: {
              zoneIds: R.map(R.prop("id"), selectedZones)
            },
            where: {
              lineItemId: [lineItemId]
            }
          }
        ]
      }
    });

    yield put(actions.fetchData());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred updating line item zones"
          }
        ])
      )
    ]);
  }
};

const watchOnSaveZonesEdition = function*() {
  yield takeEvery(actions.updateVariantZones.type, saveNewZones);
};

const watchRefreshDashboards = function*() {
  for (;;) {
    yield delay(300000);
    const selectedOptions = yield select(
      FilterControlsGetters.selectedOptions,
      { instanceId: REFRESH_DASHBOARDS }
    );

    if (R.not(R.isEmpty(selectedOptions))) {
      const mode = yield select(getMode);

      yield put(actions.initData({ mode }));
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(tableSagas),
    fork(actionsSagas),
    fork(watchInitData),
    fork(watchFetchSummary),
    fork(filters),
    fork(search),
    fork(watchStats),
    fork(watchGoToContactProfile),
    fork(watchGoToAccountProfile),
    fork(watchUpdateViewFields),
    fork(watchPrintItems),
    fork(watchUndoPrintItems),
    fork(watchReview),
    fork(watchRedirectToAttendeeBreakDown),
    fork(watchOnSaveZonesEdition),
    fork(watchRefreshDashboards)
  ]);
};

export default rootSaga;
