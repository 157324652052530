import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import CircularProgress from "material-ui/CircularProgress";
import { SmallMenuIcon } from "components/Global/NavIcons";

const TAGS = ["conference", "festival", "vendor management"];

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class TemplatesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: !props.templates.length,
      search: ""
    };
  }

  componentWillMount() {
    this.props
      .getTemplates({
        eventId: this.props.eventDetails.id
      })
      .then(() => {
        if (this.state.loading) {
          this.setState({ loading: false });
        }
      });
  }

  moduleNameMatchesSearch = name =>
    name
      .toLowerCase()
      .trim()
      .indexOf(this.state.search) > -1;

  templateTagsMatcheSearch = tags =>
    tags
      .map(t => `#${t}`)
      .join(" ")
      .toLowerCase()
      .trim()
      .indexOf(this.state.search) > -1;

  searchQueryExists = () => Boolean(this.state.search.trim().length);

  searchOnTag = search => this.setState({ search });

  clearSearch = () => this.setState({ search: "" });

  updateSearch = e => this.setState({ search: e.target.value });

  useTemplate = template => this.props.onSave(template);

  render() {
    const { loading } = this.state;
    const { templates } = this.props;

    const searchQueryExists = this.searchQueryExists();
    const templatesToShow = searchQueryExists
      ? templates.filter(
          t =>
            this.moduleNameMatchesSearch(t.module.name) ||
            this.templateTagsMatcheSearch(t.tags)
        )
      : templates;

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading="Templates"
        hideModal={this.props.hideModal}
        width={745}
        height={600}
      >
        <div styleName="inputWrapper">
          <input
            onChange={this.updateSearch}
            value={this.state.search}
            placeholder="Filter templates..."
            styleName="input"
            type="text"
          />
          {searchQueryExists ? (
            <i
              className="material-icons"
              role="link"
              tabIndex="0"
              onClick={this.clearSearch}
              styleName="clearSearch"
            >
              close
            </i>
          ) : (
            <i className="material-icons">search</i>
          )}
        </div>
        <Body
          style={{
            height: 400,
            padding: "20px 30px 10px 30px"
          }}
        >
          <div styleName="quickTags">
            <span styleName="quickTagsTitle">Tags:</span>
            {TAGS.map(t => (
              <span
                styleName="quickTag"
                role="link"
                tabIndex="0"
                onClick={() => this.searchOnTag(`#${t}`)}
                key={t}
              >
                #{t}
              </span>
            ))}
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div styleName="templates">
              {!templatesToShow.length ? (
                <div styleName="empty">
                  Nothing matched your search. Try again.
                </div>
              ) : (
                templatesToShow.map(template => (
                  <div
                    role="button"
                    tabIndex="0"
                    styleName="template"
                    key={template.id}
                    onClick={() => this.useTemplate(template)}
                  >
                    <div>
                      <SmallMenuIcon
                        eventDetails={this.props.eventDetails}
                        params={{
                          eventId: this.props.eventDetails.id
                        }}
                        module={template.module}
                      />
                    </div>
                    <div styleName="info">
                      <div styleName="name">{template.module.name}</div>
                      <div styleName="description">{template.description}</div>
                      {/*
                      <div styleName="tags">
                        {template.tags.map(t => (
                          <span styleName="tag" key={t}>
                            #{t}
                          </span>
                        ))}
                      </div>
                      */}
                      <div styleName="tags">
                        <span styleName="tag">
                          {template.module.event_name}
                        </span>
                      </div>
                    </div>
                    <div styleName="addLabel">
                      <i className="material-icons" styleName="addIcon">
                        add
                      </i>
                      <span>Add</span>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </Body>
      </StyleWrapper>
    );
  }
}

TemplatesModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getTemplates: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.id
    })
  ).isRequired
};

export default TemplatesModal;
