import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/stripe-connect/auth/${eventId}`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  delete: (credentials, authId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/stripe-connect/auth/connections/${authId}`,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    })
};
