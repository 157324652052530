import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getSchedule: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${moduleId}`,
        qs: { eventId },
        credentials,
        success,
        error
      });
    }),
  deleteSchedule: ({ credentials, moduleId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${moduleId}`,
        credentials,
        success,
        error
      });
    }),
  getSchedules: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedules/event/${eventId}`,
        qs: { eventId },
        credentials,
        success,
        error
      });
    }),
  getFields: ({ credentials, moduleId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${moduleId}/fields`,
        credentials,
        qs: data,
        success,
        error
      });
    }),
  addValues: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/schedules/field-values`,
        credentials,
        data,
        success,
        error
      });
    }),
  search: (credentials, userId, eventId, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/search`,
        qs: { eventId, orgId, ...data },
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, eventId, recordIds, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/export`,
        qs: { eventId: eventId, ...data },
        data: {
          recordIds
        },
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/views`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/views`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/active-view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${data.moduleId}/active-view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  addActivity: ({ credentials, moduleId }, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedule/${moduleId}/activities`,
        data,
        credentials,
        success,
        error
      });
    }),

  deleteActivity: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/record`,
        credentials,
        data,
        success,
        error
      });
    }),

  updateSchedule: ({ credentials, moduleId, eventId }, schedule) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedules/${moduleId}`,
        qs: { eventId },
        credentials,
        data: schedule,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  createNewSchedule: (credentials, schedule) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/schedules/schedules`,
        data: schedule,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
