import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";

const SubNav = ({ selectedView, router, params }) => (
  <SubNavWrapper supportId="booths">
    {/*
    <SubNavLink
      name="Dashboard"
      active={selectedView === TAB_IDS.DASHBOARD}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/booths`
        })
      }
    />
    */}
    <SubNavLink
      name="Requests"
      active={selectedView === TAB_IDS.REQUESTS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/booths/requests/all-requests`
        })
      }
    />
    <SubNavLink
      name="Settings"
      active={selectedView === TAB_IDS.SETTINGS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/booths/settings`
        })
      }
    />
  </SubNavWrapper>
);

export default withRouter(SubNav);
