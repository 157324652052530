import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import SignupActions from "actions/Global/SignupActions";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ALL_TIMEZONES from "utils/timezones";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";
import Api from "redux/modules/user/api";
import { getLoggedInUserDetails } from "redux/modules/user/actions";

const decorate = connect(
  state => ({
    userCredentials: getCredentials(state)
  }),
  {
    getLoggedInUserDetails
  }
);

const formStyles = {
  error: {
    fontWeight: 600,
    color: "#c0392b",
    marginTop: 15,
    marginBottom: 15
  }
};

@CSSModules(css)
class AccountSettingsForm extends Component {
  state = {
    fname: this.props.user.fname,
    lname: this.props.user.lname,
    slug: this.props.user.slug,
    email: this.props.user.email,
    phone: this.props.user.phone,
    location: this.props.user.location,
    timezone: this.props.user.timezone,
    photo_url: this.props.user.photo_url,
    error: null,
    submitting: false
  };

  handlePickPoint = () => {
    const callback = files => {
      if (files.length) {
        this.setState({
          photo_url: files[0].url
        });
      }
    };

    const options = {
      multiple: false,
      accept: ["image/*"],
      fromSources: ["local_file_system", "dropbox"],
      maxSize: 10485760 // 10mb
    };

    const path = { path: "all-photos/" };

    Helpers.getFilepicker(options, path, callback);
  };

  handleUpdateAccount = async values => {
    try {
      this.setState({
        error: null
      });
      await Api.updateUser(this.props.userCredentials, values);
      this.props.getLoggedInUserDetails();
    } catch (err) {
      // This is kind of a bad idea, telling someone that the user already exists.
      // From a security stand point, a hackers now knows 50% of the information they need to gain access.
      this.setState({
        submitting: false,
        error:
          "A user by this email already exists. Please login or try a different email address."
      });
      return false;
    }

    this.props.showSnackbar({
      message: "Your information has been updated",
      action: "OK"
    });
    this.setState({ submitting: false });
  };

  onFieldChange = e =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  onSubmit = e => {
    e.preventDefault();

    this.setState({ submitting: true });

    const values = this.state;

    if (
      !values.fname ||
      !values.fname.length ||
      !values.lname ||
      !values.lname.length ||
      !values.email ||
      !values.email.length
    ) {
      this.setState({
        submitting: false,
        error: "Please enter your name and email address."
      });
      return false;
    } else if (!Helpers.isValidEmail(values.email)) {
      this.setState({
        submitting: false,
        error: "Please enter a valid email address."
      });
      return false;
    }

    this.handleUpdateAccount(values);
  };

  render() {
    const { submitting, error } = this.state;

    // @NOTE: Temp disable until we re-activate username capability
    // const usernameOptions = Helpers.buildUsernameOptions(fname.value, lname.value, id.value);

    const profilePhotoClasses = [css.photoWrapper];
    let profilePhotoStyle = {};

    if (this.state.photo_url && this.state.photo_url !== "") {
      profilePhotoStyle = {
        backgroundImage: `url(${this.state.photo_url})`
      };
    } else {
      profilePhotoClasses.push("empty");
    }

    return (
      <form onSubmit={this.onSubmit}>
        <div className="settings-photos-wrapper clearfix">
          <div className="settings-profile-photo">
            <div className="settings-title profile-photo">Profile Photo</div>
            <div
              className={profilePhotoClasses.join(" ")}
              style={profilePhotoStyle}
            >
              <div
                data-name="photoUrl"
                styleName="actions"
                onClick={this.handlePickPoint}
              >
                <div styleName="uploadProfileLink">
                  <i
                    className="fa fa-cloud-upload"
                    styleName="uploadProfileIcon"
                  />
                  Upload Profile Photo
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="input-wrapper">
          <label>First Name</label>
          <div>
            <input
              className="styled"
              type="text"
              placeholder="First Name"
              name="fname"
              value={this.state.fname}
              onChange={this.onFieldChange}
            />
          </div>
        </div>

        <div className="input-wrapper">
          <label>Last Name</label>
          <div>
            <input
              className="styled"
              type="text"
              placeholder="Last Name"
              name="lname"
              value={this.state.lname}
              onChange={this.onFieldChange}
            />
          </div>
        </div>

        <div className="input-wrapper">
          <label>Email Address (This is the email you use to login)</label>
          <div>
            <input
              className="styled"
              type="email"
              placeholder="Email"
              name="email"
              value={this.state.email}
              onChange={this.onFieldChange}
            />
          </div>
        </div>

        <div className="input-wrapper">
          <label>Phone</label>
          <div>
            <input
              className="styled"
              type="text"
              placeholder="Phone"
              name="phone"
              value={this.state.phone}
              onChange={this.onFieldChange}
            />
          </div>
        </div>

        {/*
        // @NOTE: Commenting this out until we reintroduce the concept of usernames
        // and have a user case for showing location
        <div className="input-wrapper">
          <label htmlFor="slug">Username</label>
          <select className="styled" name="slug" {...slug}>
            {usernameOptions.map((username) => {
              return (
                <option key={username} value={username}>{username}</option>
              );
            })}
          </select>
          {slug.touched && slug.error && <div style={formStyles.error}>{slug.error}</div>}
        </div>

        <div className="input-wrapper">
          <label>Primary Location (City, State)</label>
          <div>
            <input className="styled" type="text" placeholder="San Diego, CA" {...location}/>
          </div>
        </div>
        */}

        <div className="input-wrapper">
          <label>Timezone</label>
          <select
            className="styled"
            name="timezone"
            value={this.state.timezone}
            onChange={this.onFieldChange}
          >
            {ALL_TIMEZONES.map(({ value, label }) => (
              <option value={value}>{label}</option>
            ))}
          </select>
        </div>

        {error ? <div style={formStyles.error}>{error}</div> : null}

        <div>
          <a className="action-positive small" onClick={this.onSubmit}>
            {submitting ? <i /> : <i />} Save Changes
          </a>
        </div>

        <br />
        <br />
        <br />
      </form>
    );
  }
}

export default decorate(AccountSettingsForm);
