import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  selectedSubOptions: [],
  selectedOption: ""
};

const reducers = {
  toggleSubOption: (
    { selectedSubOptions, selectedOption },
    { payload: id, meta }
  ) => {
    const option = R.pathOr("", ["option", "id"], meta);
    if (option === selectedOption) {
      return {
        selectedSubOptions: R.contains(id, selectedSubOptions)
          ? R.without([id], selectedSubOptions)
          : R.uniq(R.concat([id], selectedSubOptions))
      };
    }
    return {
      selectedOption: option,
      selectedSubOptions: [id]
    };
  },
  selectOption: (_, { payload: id }) => ({
    selectedOption: id,
    selectedSubOptions: []
  }),
  init: (_, { payload: iniSelected }) => ({
    selectedOption: R.head(R.keys(iniSelected)),
    selectedSubOptions: R.head(R.values(iniSelected))
  })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = handlers;

const module = createModule(handlers);

export { actions, getters };

export default module;
