import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "Portal/IntakeSettingsModal/model";
import {
  Popover,
  MediumOutlineInput,
  Div,
  CloseIcon,
  EditIcon,
  Text2,
  MediumFilledButton,
  MediumOutlineButton,
  Text1,
  Text0
} from "components/Base";

const decorate = connect(
  null,
  {
    removeAlias: actions.removeAlias
  }
);

const LabelPopover = ({
  name,
  setTempAlias,
  id,
  alias,
  tempAlias,
  updateAlias,
  removeAlias,
  bgDefault = "white",
  bgHover = "neutral0"
}) => (
  <Div display="row.flex-start.center">
    <Popover
      Label={({ onClick }) => (
        <Div
          onClick={ev => {
            setTempAlias({ id, text: alias });
            onClick(ev);
          }}
          display="row.flex-start.center"
          bg={{ default: bgDefault, hover: bgHover }}
          transition="fast"
          p={2}
          bra={2}
          className="hoverContainer"
        >
          <Div>
            <Text2 bold color="black">
              {name}
            </Text2>
            {!R.isEmpty(alias) ? <Text0>{alias}</Text0> : null}
          </Div>
          <EditIcon ml={2} className="showOnHover" />
        </Div>
      )}
    >
      {({ closePopover }) => (
        <Div width={300} bra={1} p={3} shadow={1}>
          <Div display="row.space-between.center" mb={2}>
            <Text2 bold color="black">
              Label
            </Text2>
            <Div
              display="row.flex-start.center"
              onClick={() => {
                removeAlias({ id });
                closePopover();
              }}
            >
              <CloseIcon color="neutral6" mr={2} />
              <Text2 color="neutral6">Remove custom label</Text2>
            </Div>
          </Div>
          <MediumOutlineInput
            bg="neutral0"
            color="neutral6"
            width={1}
            value={R.propOr("", "text", tempAlias)}
            onChange={value => {
              setTempAlias({ id, text: value });
            }}
          />
          <Div width={1} bg="neutral0" bra={1} mt={2} p={2}>
            <Text1>Field</Text1>
            <Text1 color="black">{name}</Text1>
          </Div>
          <Div display="row.space-between.center" mt={2}>
            <Div display="row.flex-start.center">
              <MediumFilledButton
                color="white"
                bg="altB5"
                onClick={() => {
                  updateAlias();
                  closePopover();
                }}
              >
                Save
              </MediumFilledButton>
              <MediumOutlineButton ml={1} onClick={closePopover}>
                Cancel
              </MediumOutlineButton>
            </Div>
          </Div>
        </Div>
      )}
    </Popover>
  </Div>
);

export default decorate(LabelPopover);
