import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "./model";

export const newsAndAlertsDates = createSelector(
  getters.newsAndAlerts,
  R.values
);
