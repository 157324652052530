import React, { Component } from "react";
import moment from "moment";
import {
  Div,
  modalHandler,
  Text4,
  CheckCircleIcon,
  NoneIcon
} from "components/Base";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { BaseHeader } from "components/Global/Modal/Layout/Headers";

class MealsModal extends Component {
  constructor(props) {
    super(props);

    const state = {
      date: props.selectedDays.length ? props.selectedDays.sort()[0] : null
    };
    const today = moment(new Date()).format("YYYY-MM-DD");

    if (props.activeMeal && props.activeMeal.variant.rules) {
      const variantDate = props.activeMeal.variant.rules[0].value;
      if (props.selectedDays.includes(variantDate)) {
        state.date = variantDate;
      }
    } else if (props.selectedDays.includes(today)) {
      state.date = today;
    }
    this.state = state;
  }

  onDayChange = e => this.setState({ date: e.target.value });

  getVariantsForDate = () =>
    this.props.variants.filter(v => {
      return (
        v.rules.filter(r => r.value === this.state.date).length &&
        this.props.mealDays.some(
          d => d.mealId === v.item.id && d.dayId === this.state.date
        )
      );
    });

  render() {
    const { hideModal, selectedDays, goToCheckinMeal } = this.props;
    const { date } = this.state;
    const variantsForDate = this.getVariantsForDate();

    return (
      <StyleWrapper
        heading="Switch scanning to:"
        hideModal={hideModal}
        width={500}
        Header={BaseHeader}
        containerStyles={{
          padding: 0
        }}
      >
        <Div p={5}>
          <Div display="row.flex-start-center">
            <Text4 bold>{moment(date).format("dddd, MMM DD")}</Text4>
          </Div>
          <Div py={3}>
            {variantsForDate.map(l => (
              <Div
                key={l.id}
                display="row.flex-start.center"
                mb={3}
                onClick={() => goToCheckinMeal(l.id)}
                p={3}
                bra={1}
                shadow={{ default: 1, hover: 2 }}
              >
                {l.active ? (
                  <CheckCircleIcon color="success5" sizeWFS={5} mr={2} />
                ) : (
                  <NoneIcon sizeWFS={5} mr={2} />
                )}
                <Text4 bold>{l.item.name}</Text4>
              </Div>
            ))}
          </Div>
          <Div display="row.flex-end.center">
            <Div fw={3} mr={2}>
              Select day:
            </Div>
            <Div>
              <select
                onChange={this.onDayChange}
                value={date}
                style={{
                  padding: "3px 22px 3px 11px",
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                {selectedDays.map(day => (
                  <option key={day} value={day}>
                    {moment(day).format("dddd, MMM DD")}
                  </option>
                ))}
              </select>
            </Div>
            {/* <MediumFilledButton onClick={() => {}}>Today</MediumFilledButton> */}
          </Div>
        </Div>
      </StyleWrapper>
    );
  }
}

MealsModal.propTypes = {};

export default modalHandler()(MealsModal);
