/* eslint-disable prettier/prettier */
import React from "react";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./ModalBody";

import { connect } from "react-redux";
import { modalHeight } from "Organizations/CloneEventModal/selectors";

const decorate = connect(state => ({
  modalHeight: modalHeight(state)
}));

const Frame = ({ children = React.Component, modalHeight = 0 }) => (
  <Div m={5} style={{ overflow: "none" }} display="row.center.center">
    <Div
      display="column.flex-start"
      bra={1}
      shadow={2}
      bg="white"
      width={1019}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minHeight: `${modalHeight}px`,
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </Div>
  </Div>
);

const Layout = ({ hideModal, allUserEvents, isCloning, modalHeight }) => {
  const handlers = {
    hideModal,
    allUserEvents,
    isCloning
  };

  return (
    <Frame modalHeight={modalHeight}>
      <Header handlers={handlers} />
      <Body handlers={handlers} />
    </Frame>
  );
};

export default decorate(Layout);
