import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { withProps } from "utils/General";

import CheckBox from "material-ui/Checkbox";
import { Div, Text0, Text1, Text2, BigAvatar } from "components/Base";

import { StatusTag } from "utils/status-comps";
import { actions } from "Passes/Confirmations";
import { getIsAllSelected } from "Passes/Confirmations/selectors";

const Header = withProps({ py: 2, pl: 2 })(Div);

import { liftToArr } from "utils/General";

const reduce = R.addIndex(R.reduce);

const sumarize = (glue = "", maxLen = 40) => arr =>
  reduce(
    (str, component, index) =>
      R.length(str) + R.length(component) < maxLen
        ? str === ""
          ? component
          : R.join(glue, [str, component])
        : R.reduced(`${str}... +${R.length(arr) - index} more`),
    "",
    R.filter(
      R.compose(
        R.not,
        R.either(R.isNil, R.isEmpty)
      ),
      arr
    )
  );

const SelectAll = connect(
  state => ({
    allSelected: getIsAllSelected(state)
  }),
  {
    selectAll: value => actions.selectAll(!value)
  }
)(({ allSelected, selectAll }) => (
  <Div
    display="row.flex-start.center"
    onClick={() => selectAll(allSelected)}
    p={2}
  >
    <CheckBox checked={allSelected} style={{ width: "auto" }} />
  </Div>
));

const SelectRow = connect(
  null,
  {
    toggleRow: actions.toggleRow
  }
)(({ value, original, toggleRow }) => (
  <Div
    p={2}
    display="row.flex-start.center"
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      toggleRow(original.id);
    }}
  >
    <CheckBox checked={value} style={{ width: "auto" }} />
  </Div>
));

const COLUMNS = {
  select: {
    Header: <SelectAll />,
    accessor: "selected",
    width: 40,
    resizable: false,
    Cell: props => <SelectRow {...props} />
  },
  emailTo: {
    Header: <Header display="row.flex-start.center">Email to</Header>,
    accessor: "emailTo",
    Cell: ({ value }) => (
      <Text2 bold display="row.flex-start.center" p={1}>
        {value}
      </Text2>
    )
  },
  passTypes: {
    Header: <Header>Items</Header>,
    accessor: "passTypes",
    width: 240,
    Cell: ({ value }) => (
      <Div display="column.flex-start.flex-start" pl={1}>
        <Text1>{sumarize(", ")(value)}</Text1>
      </Div>
    )
  },
  attendee: {
    Header: <Header pl={2}>Name on Order</Header>,
    accessor: "attendee",
    Cell: ({ value }) => (
      <Div display="row.flex-start.center" pl={2}>
        <BigAvatar
          photoURL={value.photoUrl}
          bg="neutral3"
          text={value.name || ""}
          singleInitial
        />
        <Div ml={2}>
          <Text2 bold width={1}>
            {value.name}
          </Text2>
          <Div display="row.flex-start.center">
            <Text0 uppercase>
              {[value.groupName].filter(v => v && v.length).join(" · ")}
            </Text0>
          </Div>
        </Div>
      </Div>
    )
  },
  order: {
    Header: (
      <Header pr={3} display="row.flex-end.center">
        Order #
      </Header>
    ),
    width: 80,
    accessor: "order",
    Cell: ({ value, original }) => (
      <Div display="row.flex-end.center" width={1}>
        {liftToArr(value).map(value => (
          <Text2
            key={value.id}
            mr={2}
            onClick={() => original.handlers.viewOrder(value.number, value.id)}
            bb={1}
            bc="neutral4"
          >
            #{value.number}
          </Text2>
        ))}
      </Div>
    )
  },
  status: {
    Header: <Header>Confirmation Status</Header>,
    accessor: "status",
    Cell: ({ value }) => (
      <Div display="column.flex-start.flex-start" pl={2}>
        <StatusTag status={value} />
      </Div>
    )
  },
  paymentStatus: {
    Header: <Header>Payment Status</Header>,
    accessor: "paymentStatus",
    Cell: ({ value }) => (
      <Div display="column.flex-start.flex-start" pl={2}>
        <StatusTag status={value} />
      </Div>
    )
  }
};

export default COLUMNS;
