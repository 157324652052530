import PropTypes from "prop-types";
import React, { Component, Children } from "react";
import ReactDOM from "react-dom";
import Tether from "tether";

const attachmentPositions = [
  "top left",
  "top center",
  "top right",
  "middle left",
  "middle center",
  "middle right",
  "bottom left",
  "bottom center",
  "bottom right"
];

class TetherComponent extends Component {
  static propTypes = {
    renderElementTag: PropTypes.string,
    renderElementTo: PropTypes.any,
    attachment: PropTypes.oneOf(attachmentPositions).isRequired,
    targetAttachment: PropTypes.oneOf(attachmentPositions),
    offset: PropTypes.string,
    targetOffset: PropTypes.string,
    targetModifier: PropTypes.string,
    enabled: PropTypes.bool,
    classes: PropTypes.object,
    classPrefix: PropTypes.string,
    optimizations: PropTypes.object,
    constraints: PropTypes.array
  };

  static defaultProps = {
    renderElementTag: "div",
    renderElementTo: null
  };

  _targetNode = null;
  _elementParentNode = null;
  _tether = false;

  componentDidMount() {
    this._targetNode = this.props.targetNode;
    this._update();
  }

  componentDidUpdate() {
    this._update();
  }

  componentWillUnmount() {
    this._destroy();
  }

  disable() {
    this._tether.disable();
  }

  enable() {
    this._tether.enable();
  }

  position() {
    this._tether.position();
  }

  _destroy() {
    if (this._elementParentNode) {
      ReactDOM.unmountComponentAtNode(this._elementParentNode);
      this._elementParentNode.parentNode.removeChild(this._elementParentNode);
    }

    if (this._tether) {
      this._tether.destroy();
    }

    this._elementParentNode = null;
    this._tether = null;
  }

  _update() {
    const { children, renderElementTag, renderElementTo } = this.props;
    const elementComponent = this.props.elementComponent;

    // if no element component provided, bail out
    if (!elementComponent) {
      // destroy Tether elements if they have been created
      if (this._tether) {
        this._destroy();
      }
      return;
    }

    // create element node container if it hasn't been yet
    if (!this._elementParentNode) {
      // create a node that we can stick our content Component in
      this._elementParentNode = document.createElement(renderElementTag);

      // append node to the end of the body
      const renderTo = renderElementTo || document.body;
      renderTo.appendChild(this._elementParentNode);
    }

    // render element component into the DOM
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      elementComponent,
      this._elementParentNode,
      () => {
        // don't update Tether until the subtree has finished rendering
        this._updateTether();
      }
    );
  }

  _updateTether() {
    const { ...options } = this.props;
    const tetherOptions = {
      target: this._targetNode,
      element: this._elementParentNode,
      ...options
    };

    if (!this._tether) {
      this._tether = new Tether(tetherOptions);
    } else {
      this._tether.setOptions(tetherOptions);
    }

    this._tether.position();
  }

  render() {
    return <div />;
  }
}

export default TetherComponent;
