import {
  take,
  call,
  put,
  all,
  fork,
  select,
  takeEvery
} from "redux-saga/effects";
import * as R from "ramda";
import { getters, actions } from "Accounts/RequestsAndOrders";
import { showSnackbar } from "redux/modules/snackbar/actions";

import RequestsAndOrdersApi from "Accounts/RequestsAndOrders/api";

import { review } from "App/Data/sagas";

import { userId } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import { navigateTo } from "utils/General";
import { registerError } from "redux/modules/errors/actions";
import {
  getOrderSummaryForAccount,
  getOrderSummaryForContact
} from "redux/modules/orders/orderSummaryByRecord/actions";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const id = yield select(userId);
  const event = yield select(getEventId);

  return {
    userId: id,
    eventId: event,
    credentials
  };
};

const watchReview = function*() {
  for (;;) {
    const { payload, type } = yield take([
      actions.approveRequests.type,
      actions.denyRequests.type
    ]);
    const response = R.propOr(null, type, {
      [actions.approveRequests.type]: "approve",
      [actions.denyRequests.type]: "reject"
    });

    try {
      yield put(actions.setIsLoading(true));
      const { success } = yield call(review, {
        response,
        targetType: "lineItemId",
        recordIds: payload.recordIds
      });
      if (success) {
        const eventId = yield select(getEventId);
        const recordId = yield select(getters.recordId);
        const hasContactId = yield select(getters.hasContactId);
        const getSummaries = hasContactId
          ? getOrderSummaryForContact
          : getOrderSummaryForAccount;
        yield all([
          put(
            getSummaries({
              eventId: eventId,
              ...(hasContactId
                ? { contactId: recordId }
                : { accountId: recordId })
            })
          ),
          put(actions.setIsLoading(false))
        ]);
      }
    } catch (error) {
      yield all([
        put(
          registerError([
            {
              system: error,
              user: "An error occurred approving line items"
            }
          ])
        ),
        put(actions.setIsLoading(false))
      ]);
    }
  }
};

const exportItemSummary = function*({ payload: typeId }) {
  try {
    yield put(showSnackbar({ message: "Exporting..." }));

    const { credentials } = yield call(getParams);
    const hasContactId = yield select(getters.hasContactId);
    const recordId = yield select(getters.recordId);
    const apiToUse = hasContactId
      ? RequestsAndOrdersApi.exportContactSummary
      : RequestsAndOrdersApi.exportAccountSummary;

    const result = yield call(apiToUse, credentials, {
      recordId,
      typeId
    });
    yield call(navigateTo, result.payload.url);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred exporting summary"
        }
      ])
    );
  }
};

const watchExportItemSummaries = function*() {
  yield takeEvery(actions.exportItemSummaries.type, exportItemSummary);
};

const rootSaga = function*() {
  yield all([fork(watchReview), fork(watchExportItemSummaries)]);
};

export default rootSaga;
