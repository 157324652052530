import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import { getBackgroundUrl } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  backgroundUrl: getBackgroundUrl(state)
}));

const Container = ({ children, backgroundUrl = "", handlers }) => (
  <Div
    display="column.flex-start.stretch"
    height={1}
    style={{ position: "relative" }}
    bg="neutral0"
  >
    {/* <Div
      height={350}
      width={1}
      style={{ background: backgroundUrl, position: "absolute", zIndex: -1 }}
    /> */}
    <Div pt={10} px={10}>
      {React.cloneElement(children, { handlers })}
    </Div>
  </Div>
);

export default decorate(Container);
