import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import Tooltip from "components/Global/Tooltip";

import { showModal, hideModal } from "redux/modules/modal/actions";

import { getValue } from "../../utils";
import { actions } from "../../model";

import styleSpects from "components/Base/Base/styleSpecs";

const joinItems = R.compose(
  R.join(", "),
  R.take(5),
  R.map(i => `${i.name}`.trim())
);

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class EventUsers extends Component {
  render() {
    const users = getValue(this.props, "users", []);
    const count = getValue(this.props, "count", 0);

    const joined = joinItems(users);
    const tooltip =
      R.length(users) > 5
        ? `${joined} and ${R.length(users) - 5} other ${
            R.length(users) - 5 !== 1 ? "users" : "user"
          }`
        : joined;

    return R.length(users) === 1 ? (
      <div
        // onClick={this.showBulkEditModal}
        // className="underline-on-hover"
        title={tooltip}
        style={{
          fontSize: styleSpects.fontSize[2],
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {tooltip}
      </div>
    ) : count ? (
      <Tooltip tooltip={tooltip} placement="bottom">
        <div
          // onClick={this.showBulkEditModal}
          // className="underline-on-hover"
          style={{ fontSize: styleSpects.fontSize[2] }}
        >
          {count} {count !== 1 ? "users" : "user"}
        </div>
      </Tooltip>
    ) : (
      <div
        // onClick={this.showBulkEditModal}
        // className="underline-on-hover"
        style={{
          // color: styleSpects.color.purple8,
          // fontWeight: styleSpects.fontWeight[3],
          fontSize: styleSpects.fontSize[2]
          // cursor: "pointer"
        }}
      >
        No users added
      </div>
    );
  }
}

export default decorate(EventUsers);
