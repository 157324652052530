import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import UploadButton from "components/Atoms/UploadButton";
import File from "components/Event/FormsV2/Sections/FormFileUpload/File";
import FileFactory from "components/Global/Editors/FileUploadEditor/Factory";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FileUploadView = CSSModules(
  ({
    deleteFile,
    showFilepicker,
    files,
    disabled,
    allowDownload,
    placeholder,
    column
  }) => (
    <div>
      <div styleName="wrapper">
        {!disabled ? (
          <div styleName="attach">
            <UploadButton onClick={showFilepicker} />
          </div>
        ) : (
          ""
        )}
        {!files.length ? (
          <div styleName="empty">
            {get(column, ["settings", "placeholder"]) || placeholder}
          </div>
        ) : (
          ""
        )}
        {files.map(file => (
          <File
            key={file.id}
            id={file.id}
            allowDownload={allowDownload}
            mimetype={file.mimetype}
            disabled={disabled}
            url={file.url}
            filename={file.filename}
            deleteFile={deleteFile}
          />
        ))}
      </div>
    </div>
  ),
  css
);

FileUploadView.propTypes = {
  allowDownload: PropTypes.bool,
  column: PropTypes.object,
  deleteFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  files: PropTypes.array,
  placeholder: PropTypes.string,
  showFilepicker: PropTypes.func.isRequired
};

export default props => (
  <FileFactory {...props}>
    <FileUploadView />
  </FileFactory>
);
