export const NAMESPACE = "PortalHome";

export const FILTER = {
  TASKS: "tasks",
  SCHEDULE: "schedule"
};

export const TABS = [
  { id: FILTER.TASKS, title: "Tasks" },
  { id: FILTER.SCHEDULE, title: "Schedule" }
];

export const GROUP_BY = [
  { id: "all", text: "All", selected: true },
  { id: "pending", text: "Pending", selected: false },
  { id: "done", text: "Done", selected: false }
];

export const TASKS_ORDER = [
  { id: "past_due", text: "Past Due" },
  { id: "due_next_7_days", text: "Due Next 7 Days" },
  { id: "due_this_month", text: "Upcoming" }
];

export const TASK_ACTIONS = {
  FORM: "form",
  DOCUMENT_REQUEST: "document_request",
  INVOICE: "invoice",
  PEOPLE: "people",
  CUSTOM_TASK: "task",
  ASSIGNED_TASK: "assigned_task",
  TOGGLE_TASK: "toggle_task",
  OPEN_FILE: "open_file",
  GO_TO_PAGE: "go_to_page",
  GO_TO_ALERT: "go_to_alert"
};

export const ROW_ACTIONS_ITEMS = {
  EDIT_ROW: "row-edit",
  DELETE_ROW: "row-delete",
  OPEN_SUBMISSION_MODAL: "row-openSubmissionModal",
  OPEN_RECORD: "openRecordRow"
};

export const CART_REQUESTS_ID = "cart_requests_form_id";

export const CART_TABS = {
  ALL: "all",
  SUBMITTED: "submitted",
  DRAFT: "draft"
};
