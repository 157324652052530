import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import moment from "moment";
import css from "./styles.scss";

@CSSModules(css)
class OptionDetails extends Component {
  static getValue(key, val) {
    switch (key) {
      case "salesStart":
      case "salesEnd":
        return val ? moment(val).format("M/D/YYYY") : null;
      case "quantityTotal":
      case "quantitySold":
      case "quantityAvailable":
      case "maxOrderQuantity":
      case "type":
      case "description":
        return val;
      default:
        return false;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  @autobind
  toggleDetails() {
    this.setState(state => ({ collapsed: !state.collapsed }));
  }

  render() {
    if (this.state.collapsed) {
      return (
        <div onClick={this.toggleDetails} styleName="actionText">
          Details
        </div>
      );
    }

    const details = Object.keys(this.props.details).map(key => {
      const name = key.replace(/([_])/g, " ");
      const value = OptionDetails.getValue(key, this.props.details[key]);
      if (value) {
        return (
          <div key={key} styleName="detailsWrapper">
            <div styleName="label">{name}:</div>
            <div styleName="content">{value}</div>
          </div>
        );
      }
      return "";
    });

    return (
      <div>
        <div styleName="details">{details}</div>
        <div styleName="actionText" onClick={this.toggleDetails}>
          Collapse
        </div>
      </div>
    );
  }
}

OptionDetails.propTypes = {
  details: PropTypes.object
};

export default OptionDetails;
