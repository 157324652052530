import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getClosingThisWeek } from "Forms/List/selectors";
import { Div, Text5 } from "components/Base";
import FormRow from "./FormRow";

const decorate = connect(state => ({
  list: getClosingThisWeek(state)
}));

const ClosingThisWeek = ({ list }) => (
  <Div mt={5}>
    <Div display="row.flex-start.center" pl={1}>
      <Text5 bold color="black">
        Closing this week
      </Text5>
      <Text5 ml={1} color="black">
        ({R.length(list)})
      </Text5>
    </Div>
    <Div mt={2}>
      {R.map(
        row => (
          <FormRow row={row} />
        ),
        list
      )}
    </Div>
  </Div>
);

export default decorate(ClosingThisWeek);
