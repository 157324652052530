import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import moment from "moment-timezone";

import DateRange from "components/Global/EventDaySelector/DateRange";
import DaysSelected from "components/Global/EventDaySelector/DaysSelected";
import Calendar from "components/Global/EventDaySelector/Calendar";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class EventDaySelector extends Component {
  getDaysGroupedByWeek(start, end) {
    const days = {};
    for (let m = moment(start); m.isBefore(end); m.add(1, "days")) {
      const data = {
        day: m.format("D"),
        ydm: m.format("YYYY-MM-DD"),
        isoWeek: m.isoWeek()
      };
      if (typeof days[data.isoWeek] !== "undefined") {
        days[data.isoWeek].push(data);
      } else {
        days[data.isoWeek] = [data];
      }
    }
    return days;
  }

  getAvailableDays(start, end) {
    const days = [];
    for (let m = moment(start); m.isBefore(end); m.add(1, "days")) {
      days.push(m.format("YYYY-MM-DD"));
    }
    return days;
  }

  render() {
    const {
      startDate,
      endDate,
      showCountOfSelectedDays,
      countOfSelectedDays,
      dayMap,
      handleDayClick
    } = this.props;
    const dateRangeStart = moment(startDate).startOf("isoWeek");
    const dateRangeEnd = moment(endDate).endOf("isoWeek");

    const datesAvailableStart = moment(startDate).startOf("day");
    const datesAvailableEnd = moment(endDate).endOf("day");

    const dates = {
      weeks: this.getDaysGroupedByWeek(dateRangeStart, dateRangeEnd),
      availableDayKeys: this.getAvailableDays(
        datesAvailableStart,
        datesAvailableEnd
      ),
      availableDayMap: dayMap || {}
    };

    return (
      <div styleName="container">
        <div styleName="days">
          <DateRange
            label={`${datesAvailableStart.format(
              "MMM D"
            )} - ${datesAvailableEnd.format("MMM D")}`}
          />
          <Calendar dates={dates} handleDayClick={handleDayClick} />

          {showCountOfSelectedDays === true ? (
            <DaysSelected
              label={`${countOfSelectedDays} ${
                countOfSelectedDays !== 1 ? "Days" : "Day"
              } Selected`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

EventDaySelector.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  handleDayClick: PropTypes.func.isRequired,
  showCountOfSelectedDays: PropTypes.bool,
  countOfSelectedDays: PropTypes.number,
  dayMap: PropTypes.object
};

export default EventDaySelector;
