import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { liftToArr } from "utils/General";

import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { getValue, getSettings } from "../../utils";

import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);
class OrderNumbers extends Component {
  showOrderModal = orderId =>
    this.props.showModal({
      content: <OrderModal onDone={this.props.onDone} orderId={orderId} />,
      wrapper: ModalWrapper
    });

  render() {
    const value = getValue(this.props);
    const enableViewOrder = getSettings(this.props, "enableViewOrder", true);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%"
        }}
      >
        {liftToArr(value).map(value => (
          <div
            style={{
              fontSize: "14px",
              marginRight: "8px",
              cursor: enableViewOrder ? "pointer" : undefined
            }}
            className={enableViewOrder ? "underline-on-hover" : undefined}
            key={value.id}
            onClick={
              enableViewOrder ? () => this.showOrderModal(value.id) : null
            }
          >
            #{value.number}
          </div>
        ))}
      </div>
    );
  }
}

export default decorate(OrderNumbers);
