import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";

import userInfoModalModule from "EventLight/Team/UserInfoModal";

const module = createModule(model);

module.setRootSaga(sagasModule);

module.plugModule(userInfoModalModule);

export default module;
