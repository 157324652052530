import React from "react";
import Badge from "components/Atoms/Badge";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Reviewed = () => <Badge styleName="badge">Reviewed</Badge>;

Reviewed.propTypes = {};

export default CSSModules(Reviewed, css);
