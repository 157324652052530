import { ERROR } from "redux/modules/errors/constants";
import { showSnackbar } from "redux/modules/snackbar/actions";

export function registerError(payload, shouldShowSnackbar = true) {
  return dispatch => {
    if (shouldShowSnackbar) {
      dispatch(
        showSnackbar({
          message: payload.map(error => error.user).join(", ")
        })
      );
    }

    if (process.env.NODE_ENV !== "production") {
      //eslint-disable-next-line no-console
      console.error(payload[0] && payload[0].system);
    }

    dispatch({
      type: ERROR,
      payload
    });
  };
}
