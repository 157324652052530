import React, { Component } from "react";
import PropTypes from "prop-types";
import InternalHandler from "./Internal";

class ExternalHandler extends Component {
  getMeal = () => this.props.getItem(this.props.mealId);

  saveMeal = meal => {
    this.props.onDone(meal);
    this.props.hideModal();
  };

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

ExternalHandler.propTypes = {
  mealId: PropTypes.string.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getItem: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ExternalHandler;
