import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters } from "EventLight/Inventory/Dashboard";
import Circle from "ui-kit/Circle";
import { Div, Text1, Text6, AddIcon, RemoveIcon } from "components/Base";

const decorate = connect(state => ({
  stats: getters.stats(state),
  loading: getters.loading(state)
}));

const StatsToolbar = ({
  stats,
  handlers,
  loading,
  router: { push },
  params: { eventId }
}) => (
  <Div
    height={85}
    bg="white"
    shadow={2}
    p={2}
    width={1}
    display="row.space-between.center"
    style={{
      zIndex: 1,
      minWidth: "675px"
    }}
  >
    <Div display="row.flex-start.center">
      <Div
        bra={1}
        bg={{ default: "white", hover: "neutral0" }}
        height={1}
        width={150}
        style={{ cursor: "pointer" }}
        p={3}
        display="column.space-between"
        onClick={() =>
          push(`/event-light/${eventId}/inventory/requests/all-requests`)
        }
      >
        {loading ? (
          <RemoveIcon color="neutral6" size={30} />
        ) : (
          <Text6 bold color="neutral7">
            {R.propOr(0, "count_of_pending", stats)}
          </Text6>
        )}
        <Div display="row.flex-start.center">
          <Circle size={8} bg="primary7" />
          <Text1 ml={1}>Pending Requests</Text1>
        </Div>
      </Div>

      <Div
        bra={1}
        bg={{ default: "white", hover: "neutral0" }}
        height={1}
        width={150}
        style={{ cursor: "pointer" }}
        p={3}
        display="column.space-between"
        onClick={() =>
          push(`/event-light/${eventId}/inventory/requests/all-requests`)
        }
      >
        {loading ? (
          <RemoveIcon color="neutral6" size={30} />
        ) : (
          <Text6 bold color="neutral7">
            {R.propOr(0, "count_of_approved", stats)}
          </Text6>
        )}
        <Div display="row.flex-start.center">
          <Circle size={8} bg="altA7" />
          <Text1 ml={1}>Approved Requests</Text1>
        </Div>
      </Div>

      <Div
        bra={1}
        bg={{ default: "white", hover: "neutral0" }}
        height={1}
        width={150}
        style={{ cursor: "pointer" }}
        p={3}
        display="column.space-between"
        onClick={() =>
          push(`/event-light/${eventId}/inventory/requests/all-requests`)
        }
      >
        {loading ? (
          <RemoveIcon color="neutral6" size={30} />
        ) : (
          <Text6 bold color="neutral7">
            {R.propOr(0, "total", stats)}
          </Text6>
        )}
        <Div display="row.flex-start.center">
          <Circle size={8} />
          <Text1 ml={1}>Total Requests</Text1>
        </Div>
      </Div>

      <Div
        bra={1}
        bg={{ default: "white", hover: "neutral0" }}
        height={1}
        width={150}
        style={{ cursor: "pointer" }}
        p={3}
        display="column.space-between"
        onClick={() =>
          push(`/event-light/${eventId}/inventory/requests/all-requests`)
        }
      >
        {loading ? (
          <RemoveIcon color="neutral6" size={30} />
        ) : (
          <Text6 bold color="neutral7">
            {R.propOr(0, "count_of_unpaid_orders", stats)}
          </Text6>
        )}
        <Div display="row.flex-start.center">
          <Circle size={8} bg="warning7" />
          <Text1 ml={1}>Unpaid Items</Text1>
        </Div>
      </Div>
    </Div>
    <Div
      display="row.flex-start.center"
      pr={2}
      style={{ position: "relative" }}
      width={75}
      height={80}
    >
      <Circle
        size={50}
        bg={{ default: "primary7", hover: "primary6" }}
        style={{ cursor: "pointer" }}
        transition="fast"
        onClick={handlers.showCreateOrderModal}
        shadow={{ default: 1, hover: 3 }}
        style={{ position: "absolute", bottom: "-25px" }}
      >
        <AddIcon size={24} color="white" />
      </Circle>
    </Div>
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(StatsToolbar);
