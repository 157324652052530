import React from "react";
import {
  withState,
  withProps,
  withStateHandlers,
  compose
} from "utils/General";
import {
  Div,
  MediumShadedInput,
  SearchIcon,
  MediumFilledButton,
  SmallCheckbox
} from "components/Base";
import * as R from "ramda";

const MODE_SINGLE_SELECT = "single-select";
const MODE_MULTIPLE_SELECT = "multiple-select";
const DEFAULT_MODE = MODE_MULTIPLE_SELECT;

// Attempt to use variantId first, else fall back to item id
const getItemId = item => (item.variants ? item.variants[0].id : item.id);

const getInitialMap = map =>
  R.compose(
    R.reduce(
      (acc, item) => ({
        ...acc,
        [getItemId(item)]: map[getItemId(item)] || item.qty || 0
      }),
      {}
    ),
    R.flatten,
    R.map(R.prop("items"))
  );

const View = compose(
  withState("searchTerm", "setSearch", ""),
  withStateHandlers(
    ({ initialMap = {}, itemGroups }) => ({
      selectedItemsMap: getInitialMap(initialMap)(itemGroups)
    }),
    {
      selectItem: ({ selectedItemsMap }) => itemId => {
        selectedItemsMap[itemId] = 1;
        return selectedItemsMap;
      },
      deselectItem: ({ selectedItemsMap }) => itemId => {
        selectedItemsMap[itemId] = 0;
        return selectedItemsMap;
      },
      toggleItem: ({ selectedItemsMap }, { mode }) => itemId => {
        // if in single select mode, remove all existing values
        if (mode === MODE_SINGLE_SELECT) {
          let cleanMap = {};
          Object.keys(selectedItemsMap).forEach(key => {
            cleanMap[key] = 0;
          });
          cleanMap[itemId] = selectedItemsMap[itemId] ? 0 : 1;
          return { selectedItemsMap: cleanMap };
        }

        selectedItemsMap[itemId] = selectedItemsMap[itemId] ? 0 : 1;
        return selectedItemsMap;
      },
      selectAllItemsInGroup: (
        { selectedItemsMap },
        { itemGroups }
      ) => groupId => {
        const itemGroup = itemGroups.find(group => group.id === groupId);
        itemGroup.items.filter(item => !item.disabled).forEach(item => {
          selectedItemsMap[getItemId(item)] = 1;
        });
        return selectedItemsMap;
      },
      deselectAllItemsInGroup: (
        { selectedItemsMap },
        { itemGroups }
      ) => groupId => {
        const itemGroup = itemGroups.find(group => group.id === groupId);
        itemGroup.items.forEach(item => {
          selectedItemsMap[getItemId(item)] = 0;
        });
        return selectedItemsMap;
      },
      selectAllItems: ({}, { itemGroups }) => () => {
        const cleanMap = {};

        itemGroups.forEach(group => {
          group.items.filter(item => !item.disabled).forEach(item => {
            cleanMap[getItemId(item)] = 1;
          });
        });

        return {
          selectedItemsMap: cleanMap
        };
      },
      deselectAllItems: ({}, { itemGroups }) => () => {
        const cleanMap = {};

        itemGroups.forEach(group => {
          group.items.forEach(item => {
            cleanMap[getItemId(item)] = 0;
          });
        });

        return {
          selectedItemsMap: cleanMap
        };
      }
    }
  ),
  withProps(
    ({
      selectedItemsMap,
      itemGroups,
      searchTerm,
      onDone,
      mode = DEFAULT_MODE
    }) => {
      const searchTermLowerCase = searchTerm.toLowerCase();
      const isSingleSelect = mode === MODE_SINGLE_SELECT;
      return {
        isSingleSelect,
        hasSearchTerm: searchTerm.length,
        countOfSelected: R.compose(
          R.sum,
          R.map(key => selectedItemsMap[key]),
          R.keys
        )(selectedItemsMap),
        itemGroups: searchTermLowerCase.length
          ? R.compose(
              R.filter(group => group.items.length),
              R.map(group => ({
                ...group,
                items: R.filter(item =>
                  item.name.toLowerCase().includes(searchTermLowerCase)
                )(group.items)
              }))
            )(itemGroups)
          : itemGroups.filter(group => group.items.length),
        onDone: () => onDone(selectedItemsMap)
      };
    }
  )
)(
  ({
    setSearch,
    selectedItemsMap,
    toggleItem,
    selectAllItems,
    deselectAllItems,
    selectAllItemsInGroup,
    deselectAllItemsInGroup,
    itemGroups,
    countOfSelected,
    hasSearchTerm,
    buttonLabel = "Save",
    isSingleSelect,
    onDone,
    maxHeight = 200
  }) => {
    return (
      <Div width={1} bg="gray0">
        <MediumShadedInput
          onChange={val => setSearch(val)}
          width={1}
          LeftIcon={SearchIcon}
          placeholder="Search..."
          mb={3}
          continuous
        />
        <Div
          style={{
            maxHeight,
            overflowY: "auto"
          }}
        >
          {itemGroups.length ? (
            R.map(group => (
              <Div key={group.id} mb={4}>
                <Div
                  uppercase
                  fs={1}
                  fw={3}
                  color="gray6"
                  px={5}
                  display="row.space-between.center"
                >
                  <Div truncate>{group.name}</Div>
                  {!isSingleSelect && !hasSearchTerm ? (
                    <Div display="row.flex-end.center">
                      <Div mr={2}>Select</Div>
                      <Div
                        mr={2}
                        color="purple8"
                        underline
                        onClick={() => selectAllItemsInGroup(group.id)}
                      >
                        All
                      </Div>
                      <Div
                        color="purple8"
                        underline
                        onClick={() => deselectAllItemsInGroup(group.id)}
                      >
                        None
                      </Div>
                    </Div>
                  ) : null}
                </Div>
                <Div px={4}>
                  {R.map(item => (
                    <Div
                      key={item.id}
                      shadow={1}
                      bra={1}
                      bg="white"
                      my={2}
                      px={1}
                      py={1}
                      display="row.flex-start.center"
                      onClick={
                        item.disabled
                          ? undefined
                          : () => toggleItem(getItemId(item))
                      }
                    >
                      <SmallCheckbox
                        selected={selectedItemsMap[getItemId(item)]}
                        disabled={item.disabled}
                      />
                      <Div
                        truncate
                        ml={2}
                        fw={3}
                        color={item.disabled ? "gray4" : "gray7"}
                      >
                        {item.name}
                      </Div>
                    </Div>
                  ))(group.items)}
                </Div>
              </Div>
            ))(itemGroups)
          ) : (
            <Div display="row.center.center" my={8} fs={2}>
              {hasSearchTerm
                ? "No items matched your search"
                : "No items are available"}
            </Div>
          )}
        </Div>
        <Div display="row.space-between.center" bg="white" px={4} py={2}>
          <Div>
            <Div fw={3} color="gray7">
              {countOfSelected} selected
            </Div>
            <Div fs={1} fw={3} display="row.flex-start.center">
              {!isSingleSelect && !hasSearchTerm ? (
                <Div onClick={selectAllItems} color="purple8" underline mr={2}>
                  Select all
                </Div>
              ) : null}
              {!isSingleSelect && !hasSearchTerm ? (
                <Div onClick={deselectAllItems} color="purple8" underline>
                  Clear selected
                </Div>
              ) : null}
            </Div>
          </Div>
          <MediumFilledButton bg="altB5" onClick={onDone}>
            {buttonLabel}
          </MediumFilledButton>
        </Div>
      </Div>
    );
  }
);

export default View;
