import { createModule } from "redux-mvc";

import tableModule from "ui-kit/Table";

import model from "./model";

import sagasModule from "./sagas";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "event",
    "events",
    "permissions",
    "organization",
    "userAccessLevels",
    "@flopflip",
    "EventMyApprovals"
  ]
});

module.setRootSaga(sagasModule);

module.plugModule(tableModule);

export default module;
