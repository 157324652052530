import { combineReducers } from "redux";
import Api from "utils/EventContacts/WebAPIUtils";
import { get } from "lodash";
import { getContact } from "redux/modules/contacts/profile/actions";

// ------------------------------------
// selectors
// ------------------------------------
export const contactProfile = (state, contactId) =>
  get(state.eventContactProfile, ["profile", "overviewById", contactId]);

// ------------------------------------
// Constants
// ------------------------------------
const INVALIDATE_CONTACT_INFO = "INVALIDATE_CONTACT_INFO";
const REQUEST_CONTACT_INFO = "REQUEST_CONTACT_INFO";
const RECEIVE_CONTACT_INFO = "RECEIVE_CONTACT_INFO";
const REQUEST_ACTIVITY_DETAILS = "REQUEST_ACTIVITY_DETAILS";
const RECEIVE_ACTIVITY_DETAILS = "RECEIVE_ACTIVITY_DETAILS";
const DELETE_CONTACT = "DELETE_CONTACT";

// ------------------------------------
// Actions Creators
// ------------------------------------

const requestContactInfo = id => ({
  type: REQUEST_CONTACT_INFO,
  id
});
const requestActivityDetails = () => ({
  type: REQUEST_ACTIVITY_DETAILS
});

const receiveContactInfo = details => ({
  type: RECEIVE_CONTACT_INFO,
  details
});
const receiveActivityDetails = details => ({
  type: RECEIVE_ACTIVITY_DETAILS,
  details
});

const invalidateContact = id => ({
  type: INVALIDATE_CONTACT_INFO,
  id
});

const fetchContactInfo = (eventId, contactId) => (dispatch, getState) =>
  Api.fetchContact(getState().user.user.credentials, eventId, contactId).then(
    result => {
      dispatch(receiveContactInfo(result));
    }
  );

const fetchContactActivity = (eventId, contactId) => (dispatch, getState) => {
  const credentials = getState().user.user.credentials;
  dispatch(requestActivityDetails());
  Api.fetchContact(credentials, eventId, contactId).then(result => {
    dispatch(receiveActivityDetails(result.activity));
  });
};

const checkinContact = data => (dispatch, getState) => {
  const { eventId, contactId } = data;
  const user = getState().user.user;

  return Api.checkinContact(user.credentials, data).then(() =>
    dispatch(fetchContactInfo(eventId, contactId))
  );
};

const updateContactNotifications = data => (dispatch, getState) => {
  const { eventId, contactId } = data;
  const user = getState().user.user;

  return Api.updateContactNotifications(user.credentials, data).then(() =>
    dispatch(fetchContactInfo(eventId, contactId))
  );
};

// --------------------
// Fields

// data = {
//   fieldType/origin,
//   eventId,
//   contactId,
//   value: { type: '', value: '' }
//   fieldId
// }
const updateField = data => (dispatch, getState) => {
  const apiFunc = Api.updateStandardFieldValue;
  const credentials = getState().user.user.credentials;
  apiFunc(credentials, data).then(() => {
    dispatch(
      getContact({
        eventId: data.eventId,
        contactId: data.contactId
      })
    );
    return null;
  });
};

export const actions = {
  invalidateContact,
  fetchContactInfo,
  fetchContactActivity,
  updateField,
  // createNote,
  // deleteNote,
  // updateNote,
  checkinContact,
  updateContactNotifications
};

// ------------------------------------
// Reducer
// ------------------------------------
const overviewDefault = {
  accounts: [],
  credentials: [],
  field_groups: [],
  starred_fields: [],
  fields: [],
  form_responses: [],
  inventory_transactions: [],
  isFetching: true,
  notes: [],
  schedule_activities: [],
  values: {}
};
const initialState = {
  lastUpdated: null,
  overviewById: {},
  userActivity: []
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case INVALIDATE_CONTACT_INFO:
      delete state.overviewById[action.id];
      return Object.assign({}, state);

    case REQUEST_CONTACT_INFO:
      return Object.assign({}, state, {
        overviewById: {
          ...state.overviewById,
          [action.id]: overviewDefault
        }
      });

    case REQUEST_ACTIVITY_DETAILS:
      return Object.assign({}, state, {
        userActivity: { isFetching: true }
      });

    case RECEIVE_CONTACT_INFO:
      return Object.assign({}, state, {
        overviewById: {
          ...state.overviewById,
          [action.details.id]: {
            ...action.details,
            isFetching: false
          }
        }
      });

    case RECEIVE_ACTIVITY_DETAILS:
      return Object.assign({}, state, {
        userActivity: action.details
      });

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  profile
});

export default rootReducer;
