import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import { Link } from "react-router";

import DocumentTitle from "components/Global/DocumentTitle";
import FormTemplatePanel from "components/Event/Settings/Forms/FormTemplatePanel";
import Heading from "components/Event/Settings/Heading";
import EditTemplateModal from "components/Event/Settings/Modals/EditTemplateModal";
import ShouldShowFormsV2 from "utils/should-show-forms-v2";

@CSSModules(css)
class Forms extends Component {
  componentDidMount() {
    this.props.getEmailTemplates(null, this.props.params.eventId);
  }

  @autobind
  editTemplate(template) {
    this.props.updateEmailTemplate(this.props.params.eventId, template);
  }

  @autobind
  deleteTemplate(id) {
    this.props.deleteEmailTemplate(this.props.params.eventId, id);
  }

  @autobind
  createTemplate(template) {
    this.props.createEmailTemplate(this.props.params.eventId, template);
  }

  @autobind
  showNewModal() {
    const emptyTemplate = {
      title: "",
      content_formatted: "",
      content_plaintext: ""
    };
    this.props.showModal({
      content: (
        <EditTemplateModal
          done={this.createTemplate}
          template={emptyTemplate}
        />
      )
    });
  }

  @autobind
  duplicate(id) {
    const dupe = { ...this.props.list[id] };
    dupe.title = `Copy of ${dupe.title}`;
    dupe.contentFormatted = dupe.content_formatted;
    dupe.contentPlaintext = dupe.content_plaintext;
    this.createTemplate(dupe);
  }

  render() {
    const { params, list, showModal } = this.props;
    const title = `${this.props.details.name} - Details`;

    const templateProps = {
      editTemplate: this.editTemplate,
      deleteTemplate: this.deleteTemplate,
      duplicate: this.duplicate,
      showModal
    };

    let templates = Object.keys(list).map(template => (
      <FormTemplatePanel
        key={template}
        template={list[template]}
        {...templateProps}
      />
    ));

    templates.push(
      <span key="link" styleName="newMessageLink" onClick={this.showNewModal}>
        Create new message
      </span>
    );

    if (!Object.keys(list).length) {
      templates = (
        <div>
          No form templates have been created.&nbsp;
          <span onClick={this.showNewModal} styleName="clickHere">
            Click here
          </span>{" "}
          to create one.
        </div>
      );
    }

    return (
      <DocumentTitle title={title}>
        <div styleName="container">
          <div styleName="subContainer">
            <Heading
              title="Form Settings"
              linkTo={
                ShouldShowFormsV2(params.eventId)
                  ? `/event/${params.eventId}/forms-v2`
                  : `/event/${params.eventId}/forms`
              }
              linkTitle="Go to forms"
            />

            <div styleName="body">
              <div styleName="bodyHeading">Form Email Templates</div>
              <div styleName="well">{templates}</div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Forms.propTypes = {
  params: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  deleteEmailTemplate: PropTypes.func.isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  createEmailTemplate: PropTypes.func.isRequired
};

export default Forms;
