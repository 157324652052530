import * as R from "ramda";
import { getters } from "Items/QuestionsDetails";

export const getEditingValue = (state, props) =>
  R.prop(R.prop("id", props), getters.editing(state, props));

export const getQuestion = (state, props) =>
  R.prop(R.prop("id", props), getters.questions(state, props));

export const getQuestionIds = (state, props) =>
  R.keys(getters.questions(state, props));

export const getQuestionsToShow = (state, props) =>
  getQuestionIds(state, props).slice(
    0,
    getters.fullQuestions(state, props)
      ? getQuestionIds(state, props).length
      : 3
  );
