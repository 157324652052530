import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../../../model";

import {
  Div,
  Text1,
  Text5,
  CloseIcon,
  BigFilledButton,
  InfoIcon,
  Dropdown,
  RightIcon
} from "components/Base";
import { Input } from "ui-kit/Form/View";
import { FieldWrapper, Label } from "EventLight/Common/FieldLayout";
import Loading from "ui-kit/Loading";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";

import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { CLASSY_FORM_ID, FIELD_IDS } from "../../../constants";
import {
  getIntegrationName,
  getIntegrationObjectName,
  getLenndEvents,
  getCampaigns,
  getExistingMappings,
  getIsMappingValid
} from "../../../selectors";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { noop } from "utils/General";

import CSSModules from "react-css-modules";
import css from "../../styles.scss";
import { getIsValid } from "ui-kit/Datagrid/selectors";

const Header = ({ title = "", hideModal = noop }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="white"
    p={4}
    bb={1}
    bc="neutral2"
    brt={1}
  >
    <Text5 bold color="neutral8">
      {title}
    </Text5>

    <Div onClick={hideModal}>
      <CloseIcon color="neutral6" sizeWFS={6} />
    </Div>
  </Div>
);

const decorate = R.compose(
  WithFormInstanceProvider(R.always(CLASSY_FORM_ID)),
  CSSModules(css),
  connect(
    (state, props) => ({
      showModal: getters.isCampaignsModalOpen(state, props),
      data: getters.data(state),
      error: getters.error(state),
      loading: getters.loadingMappings(state),
      integrationName: getIntegrationName(state),
      objectName: getIntegrationObjectName(state),
      lenndEvents: getLenndEvents(state),
      campaigns: getCampaigns(state),
      existingMappings: getExistingMappings(state),
      isValid: getIsMappingValid(state)
    }),
    {
      hideModal: () => actions.setIsCampaignsModalOpen(false),
      saveCampaignMappings: actions.saveCampaignMappings,
      setSelectedCampaignId: actions.setSelectedCampaignId,
      setSelectedEventId: actions.setSelectedEventId,
      removeMapping: actions.removeMapping,
      syncIntegration: actions.syncIntegration
    }
  )
);

const Modal = ({
  showModal,
  hideModal = noop,
  saveCampaignMappings = noop,
  setSelectedCampaignId = noop,
  setSelectedEventId = noop,
  removeMapping = noop,
  syncIntegration = noop,
  integrationName,
  objectName = "Campaign",
  data,
  error,
  loading,
  lenndEvents = [],
  campaigns = [],
  existingMappings = [],
  isValid
}) => (
  <MiniModalWrapper
    hideModal={hideModal}
    isPortal={false}
    showModal={showModal}
    showHeader={false}
    style={{
      width: 550,
      backgroundColor: "white",
      height: "auto",
      maxHeight: "500px"
    }}
    bg="neutral0"
  >
    <Header
      title={`Map Your ${integrationName} ${objectName}s`}
      hideModal={hideModal}
    />
    <Div
      width={1}
      height={1}
      p={4}
      display="column.flex-start.stretch"
      bb={1}
      bg="neutral0"
      bc="neutral1"
      shadow={1}
      style={{ overflowY: "auto", flex: "1 1 auto" }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Div width={1} display="column" width={1} mt={4}>
          <Div display="row.flex-start.center" width={1}>
            {/* campaigns */}
            <Div display="column" flex={1}>
              <Div fw={3} color="black" fs={3} mb={1}>
                {integrationName} {objectName}s
              </Div>
              <Div>
                <Dropdown
                  usePortal
                  onChange={({ value }) => setSelectedCampaignId(value)}
                  options={campaigns}
                />
              </Div>
            </Div>

            <Div
              px={3}
              display="row.center.center"
              fs={1}
              uppercase
              color="black"
              style={{
                marginTop: "21px"
              }}
            >
              Syncs To <RightIcon />
            </Div>

            {/* events */}
            <Div display="column" flex={1}>
              <Div fw={3} color="black" fs={3} mb={1}>
                Fondi Events
              </Div>
              <Div>
                <Dropdown
                  usePortal
                  onChange={({ value }) => setSelectedEventId(value)}
                  options={lenndEvents}
                />
              </Div>
            </Div>
          </Div>

          {existingMappings.length ? (
            <Div mt={5} pt={5} bt={1} bc="gray3">
              <Div fs={3} fw={3} mb={2} color="black">
                Existing Mappings
              </Div>
              {existingMappings.map(m => (
                <Div display="column" width={1} px={2} py={2} bb={1} bc="gray3">
                  <Div display="row.flex-start.center">
                    {m.campaign_name} <RightIcon /> {m.event_name}
                  </Div>
                  <Div display="row.flex-start.center">
                    <Div
                      fs={2}
                      color="primary7"
                      onClick={
                        m.syncing
                          ? () => {}
                          : () =>
                              syncIntegration({
                                eventId: m.event_id,
                                campaignId: m.campaign_id
                              })
                      }
                    >
                      {m.syncing ? "Syncing..." : "Sync Registrations"}
                    </Div>
                    &nbsp;&middot;&nbsp;
                    <DeleteConfirmation
                      width={200}
                      onConfirm={() =>
                        removeMapping({
                          eventId: m.event_id,
                          campaignId: m.campaign_id
                        })
                      }
                      text="Are you sure you want to remove this mapping?"
                      paragraph="Removing this mapping will discontinue any syncing between your campaign and the Fondi event."
                      confirmLabel="Remove"
                    >
                      <Div fs={2} color="primary7">
                        Remove Mapping
                      </Div>
                    </DeleteConfirmation>
                  </Div>
                </Div>
              ))}
            </Div>
          ) : null}
        </Div>
      )}
    </Div>
    <Div
      bg="white"
      width={1}
      display="column.flex-start.stretch"
      p={4}
      bt={1}
      bc="neutral2"
    >
      {error && error.length ? (
        <Div
          bra={1}
          bg="danger3"
          p={1}
          mb={1}
          fs={1}
          color="black"
          display="row.center.center"
        >
          {error}
        </Div>
      ) : null}
      <BigFilledButton
        bg="altB5"
        color="white"
        width={1}
        display="row.center.center"
        onClick={saveCampaignMappings}
        disabled={!isValid}
      >
        Add Mapping
      </BigFilledButton>
    </Div>
  </MiniModalWrapper>
);

export default decorate(Modal);
