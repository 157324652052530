import can from "components/Global/Security";

export default (userPermissionProfile, modules, hideSettings) => {
  const canDo = can(userPermissionProfile).do;
  const visibleModules = modules.filter(
    m =>
      (m &&
        m.source &&
        m.show_in_navigation !== false &&
        m.internal_name !== "settings" &&
        canDo(`${m.id}_read`)) ||
      // whitelist portal
      (m && m.internal_name === "portal") ||
      // whitelist reports
      (m && m.internal_name === "reports")
  );
  if (hideSettings) return visibleModules;

  return [
    ...visibleModules,
    ...modules.filter(
      m => m && m.internal_name === "settings" && canDo(`${m.id}_read`)
    )
  ];
};
