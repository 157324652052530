import { createHandlers } from "redux-mvc";
import * as R from "ramda";

import { STATUS, NAMESPACE, TAB_IDS } from "./constants";

const iniState = {
  historyType: TAB_IDS.SMS,
  rows: [],
  columns: [],
  searchTerm: "",
  selectedTab: STATUS.ALL,
  pagination: {
    pageSize: 25,
    pages: 0,
    page: 0
  },
  tabStats: Object.values(STATUS).reduce(
    (acc, val) => ({ ...acc, [val]: 0 }),
    {}
  ),
  stats: { total: 0, issued: 0, approved: 0, pendingPickup: 0 },
  loading: true,
  showNoResults: false
};

const zeroIndexPage = R.compose(
  R.dec,
  R.pathOr(1, ["pagination", "page"])
);

const model = createHandlers({
  iniState,
  reducers: {
    receiveList: ({ tabStats }, { payload }) => ({
      tabStats: {
        ...tabStats,
        ...payload.counters
      },
      pagination: {
        ...payload.pagination,
        page: zeroIndexPage(payload)
      },
      showNoResults: (payload.records || []).length === 0
    }),
    setPageSize: (state, { payload }) => ({
      pagination: {
        ...state.pagination,
        pageSize: payload
      }
    }),
    setCurrentPage: ({ pagination }, { payload }) => ({
      pagination: {
        ...pagination,
        page: payload
      }
    }),
    setSelectedTab: (state, { payload: selectedTab }) => ({
      selectedTab
    }),
    initData: R.identity,
    fetchData: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
