import React, { Fragment } from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import Lightbox from "react-images";
import {
  Div,
  Text0,
  Text1,
  Text2,
  Text3,
  OpenInNewIcon,
  LeftIcon,
  AttachIcon
} from "components/Base";
import MediaQuery from "react-responsive";

const Mobile = props => <MediaQuery {...props} maxWidth={1023} />;
const Default = props => <MediaQuery {...props} minWidth={1024} />;

const Location = ({
  venueName,
  addressLine1,
  city,
  state,
  zipCode,
  mapsLink,
  websiteUrl,
  startDateSmall,
  endDateSmall,
  startTime,
  endTime,
  width = 324
}) => (
  <Div width={width} p={2} bg="white">
    <Div p={6} bg="neutral1" bra={1}>
      <Div display="column" mb={5} pb={5} bc="gray3" bb={1}>
        <Div fs={1} fw={3} uppercase>
          Date and Time
        </Div>
        <Div fs={3} fw={3} mt={4}>
          {startDateSmall !== endDateSmall
            ? `${startDateSmall} - ${endDateSmall}`
            : startDateSmall}
        </Div>
        <Div fs={3}>{`${startTime} - ${endTime}`}</Div>
      </Div>
      <Div fs={1} fw={3} uppercase>
        Location
      </Div>
      {venueName ? (
        <Div fs={3} fw={3} mt={4}>
          {venueName}
        </Div>
      ) : null}
      {addressLine1 ? <Text3>{addressLine1}</Text3> : null}
      {city ? <Text3>{`${city}, ${state} ${zipCode}`}</Text3> : null}
      <a href={mapsLink} target="_blank">
        <Text2 mt={4} color="danger9" underline>
          Get Directions
        </Text2>
      </a>

      {websiteUrl && websiteUrl.length ? (
        <Div display="column" mt={5} pt={5} bc="gray3" bt={1}>
          <Div fs={1} fw={3} uppercase>
            Website
          </Div>

          <a href={websiteUrl} target="_blank">
            <Text2 mt={4} color="danger9" underline>
              Visit Website
            </Text2>
          </a>
        </Div>
      ) : null}
    </Div>
  </Div>
);

const ImageContainer = ({ src, onClick = () => {}, label = "" }) => (
  <Div>
    <Div
      width={[280, 280, 300]}
      height={160}
      bra={1}
      bg="info7"
      mt={2}
      onClick={onClick}
      display="row.center.center"
      mb={2}
      style={{
        position: "relative",
        backgroundImage: `url('${src}')`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    >
      <Div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
          top: 0,
          background:
            "linear-gradient(170.42deg, rgba(255, 255, 255, 0) 11.96%, rgba(26, 9, 56, 0.41) 69.46%), linear-gradient(0deg, rgba(105, 68, 209, 0.07), rgba(105, 68, 209, 0.07))"
        }}
        bra={1}
      >
        <Div
          display="row.flex-start.center"
          style={{ position: "absolute", bottom: 0, right: 0 }}
          pr={2}
          pb={2}
        >
          <OpenInNewIcon color="white" />
          <Text1 bold color="white" ml={2}>
            {label}
          </Text1>
        </Div>
      </Div>
    </Div>
  </Div>
);

const LeftColumn = ({ coverImage, children, urlText, router, urlLink }) => (
  <Fragment>
    <Div
      width={1}
      height={330}
      bg="neutral2"
      display="row.center.center"
      style={{
        overflow: "hidden",
        position: "relative",
        backgroundImage: `url('${coverImage}')`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    >
      <MediaQuery maxWidth={1039}>
        {R.isEmpty(urlText) === false && (
          <Div
            style={{ position: "absolute", bottom: "10px", left: "10px" }}
            display="row.flex-start.center"
            onClick={() => {
              router.push(urlLink);
            }}
          >
            <LeftIcon color="white" mr={1} size={24} />
            <Div color="white" fs={3} fw={4}>
              {urlText}
            </Div>
          </Div>
        )}
      </MediaQuery>
    </Div>
    <Mobile>{children}</Mobile>
  </Fragment>
);

const Content = ({
  description,
  photos,
  files,
  instructions,
  showingPhoto,
  showPhoto,
  floorMapimage,
  showMapFloor,
  setMapFloor
}) => (
  <Fragment>
    {description && description.length ? (
      <Div width={1} p={6}>
        <Text1>EVENT DESCRIPTION</Text1>
        <Div p={3}>
          <Div fs={3} className="lennd-wysiwyg-content">
            <span
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
          </Div>
        </Div>
      </Div>
    ) : null}
    {photos.length > 0 || floorMapimage ? (
      <Div
        width={1}
        display={[
          "column.flex-start.center",
          "column.flex-start.center",
          "row.space-between"
        ]}
        p={5}
        style={{ flexShrink: 0, flexWrap: "wrap" }}
      >
        {photos.length > 0 ? (
          <ImageContainer
            onClick={() => {
              showPhoto(0);
            }}
            src={photos[0].photo_url}
            label={`VIEW ALL (${photos.length})`}
          />
        ) : null}
        {floorMapimage ? (
          <ImageContainer
            onClick={() => {
              setMapFloor(0);
            }}
            src={floorMapimage}
            label="OPEN MAP"
          />
        ) : null}
        {photos.length > 0 ? (
          <Lightbox
            images={photos.map(({ photo_url }, idx) => ({
              src: photo_url,
              caption: null
            }))}
            currentImage={showingPhoto}
            isOpen={showingPhoto !== null}
            onClickPrev={() =>
              showingPhoto - 1 < 0
                ? showPhoto(showingPhoto - photos.length - 1)
                : showPhoto(showingPhoto - 1)
            }
            onClickNext={() => {
              if (showingPhoto + 1 > photos.length - 1) {
                showPhoto(0);
              } else {
                showPhoto(showingPhoto + 1);
              }
            }}
            onClose={() => {
              showPhoto(null);
            }}
          />
        ) : null}

        {floorMapimage ? (
          <Lightbox
            images={[{ src: floorMapimage, caption: "Floor Map" }]}
            currentImage={showMapFloor}
            isOpen={showMapFloor !== null}
            onClose={() => {
              setMapFloor(null);
            }}
          />
        ) : null}
      </Div>
    ) : null}

    {files && files.length ? (
      <Div width={1} p={6}>
        <Text1>FILES</Text1>
        <Div px={3} pt={3}>
          {files.map(file => (
            <Div
              display="row.flex-start.center"
              mb={3}
              p={2}
              bg={{
                hover: "gray0",
                default: "White"
              }}
              bc="gray2"
              bra={1}
              onClick={() => window.open(file.file_url, "_blank")}
            >
              <AttachIcon size={20} mr={2} />
              <Div display="column">
                <Div display="row.flex-start.center" fw={3}>
                  {file.title}
                </Div>
              </Div>
            </Div>
          ))}
        </Div>
      </Div>
    ) : null}

    {R.isEmpty(instructions) === false && (
      <Div width={1} p={6}>
        <Text1 uppercase>Event Details &amp; Notes</Text1>
        <Div p={3}>
          <Div fs={3} className="lennd-wysiwyg-content">
            <span
              dangerouslySetInnerHTML={{
                __html: instructions
              }}
            />
          </Div>
        </Div>
      </Div>
    )}
  </Fragment>
);

const EventPage = ({
  description,
  files = [],
  photos = [],
  instructions = "",
  coverImage,
  floorMapimage = "",
  showingPhoto = null,
  showPhoto = () => {},
  showMapFloor = null,
  setMapFloor = () => {},
  children,
  urlText = "",
  urlLink = "",
  router,
  venueName,
  city,
  state,
  addressLine1,
  zipCode,
  mapsLink,
  websiteUrl,
  startDateSmall,
  endDateSmall,
  startTime,
  endTime
}) => {
  const leftColumnProps = {
    description,
    files,
    photos,
    instructions,
    coverImage,
    showingPhoto,
    showPhoto,
    floorMapimage,
    showMapFloor,
    setMapFloor,
    children,
    urlText,
    router,
    urlLink
  };

  const locationProps = {
    venueName,
    city,
    state,
    addressLine1,
    zipCode,
    mapsLink,
    websiteUrl,
    startDateSmall,
    endDateSmall,
    startTime,
    endTime
  };
  return (
    <Fragment>
      <MediaQuery maxWidth={1039} minWidth={1024}>
        <Div
          width={[1, 1, 1024]}
          bg="white"
          bra={[0, 0, 1]}
          mb={3}
          style={{ flexShrink: 0, marginTop: "46px" }}
        >
          <Div display="row">
            {children}
            <Default>
              <Div display="column" width={700}>
                <LeftColumn {...leftColumnProps} />
              </Div>
            </Default>
          </Div>
          <Div display="row" width={1}>
            <Div width={700}>
              <Content {...leftColumnProps} />
            </Div>
            <Location {...locationProps} />
          </Div>
        </Div>
      </MediaQuery>
      <MediaQuery minWidth={1040}>
        <Div
          width={[1, 1, 1024]}
          bg="white"
          bra={[0, 0, 1]}
          mb={3}
          style={{
            flexShrink: 0,
            marginTop: "125px",
            boxShadow:
              "0px 7.43943px 17.3587px rgba(0, 0, 0, 0.0423), 0px 32.2375px 49.5962px rgba(0, 0, 0, 0.01)",
            position: "relative",
            overflow: "visible"
          }}
        >
          <Div display="row">
            {R.isEmpty(urlText) === false && (
              <Div
                style={{ position: "absolute", top: "-45px", left: "-26px" }}
                display="row.flex-start.center"
                bg="black"
                bra={1}
                color="white"
                pl={1}
                pr={2}
                py={1}
                onClick={() => {
                  router.push(urlLink);
                }}
              >
                <LeftIcon color="white" mr={1} size={24} />
                <Div color="white" fs={3} fw={4}>
                  {urlText}
                </Div>
              </Div>
            )}
            {children}

            <Default>
              <Div display="column" width={700}>
                <LeftColumn {...leftColumnProps} />
              </Div>
            </Default>
          </Div>
          <Div display="row" width={1}>
            <Div width={700}>
              <Content {...leftColumnProps} />
            </Div>
            <Location {...locationProps} />
          </Div>
        </Div>
      </MediaQuery>
      <Mobile>
        <Div
          width={1}
          bg="white"
          display="row"
          bra={[0, 0, 1]}
          mb={3}
          style={{ flexShrink: 0 }}
        >
          <Mobile>
            <Div display="column" width={1}>
              <LeftColumn {...leftColumnProps} />
              <Content {...leftColumnProps} />
            </Div>
          </Mobile>
        </Div>
      </Mobile>
    </Fragment>
  );
};

export default withRouter(EventPage);
