import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CollapsablePanel from "components/Global/CRM/CollapsablePanel";
import ActionButton from "components/Global/CRM/ActionButton";
import Account from "./Account";

@CSSModules(css)
class RelatedAccounts extends Component {
  @autobind
  removeRelationship(data) {
    this.props.removeRelationship(data);
  }

  render() {
    const {
      accounts,
      params,
      readOnly,
      canViewAccounts,
      viewMoreModal
    } = this.props;
    const list = accounts.map(c => (
      <Account
        key={c.relationship_id}
        onRemove={this.removeRelationship}
        onEdit={this.props.editRelationship}
        params={params}
        readOnly={readOnly}
        canViewAccounts={canViewAccounts}
        {...c}
      />
    ));

    return (
      <CollapsablePanel title="Groups">
        <div>
          <div>{list.slice(0, 3)}</div>
          {list.length > 3 ? (
            <div styleName="viewAll" onClick={viewMoreModal.bind(null, list)}>
              View {accounts.length} groups
            </div>
          ) : (
            ""
          )}
          {Boolean(this.props.addRelationship) && !readOnly ? (
            <div styleName="actionButton">
              <ActionButton
                onClick={this.props.addRelationship}
                title={list.length ? "Add another group" : "Add related group"}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </CollapsablePanel>
    );
  }
}

RelatedAccounts.propTypes = {
  addRelationship: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  removeRelationship: PropTypes.func.isRequired,
  viewMoreModal: PropTypes.func.isRequired
};

export default RelatedAccounts;
