import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";
import { withRouter } from "react-router";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar, hideSnackbar } from "redux/modules/snackbar/actions";
import {
  addRecordType,
  updateRecordType,
  deleteRecordType
} from "redux/modules/modules/recordTypes/actions";
import { updateDefaultRecordType } from "redux/modules/modules/recordTypes/default/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { addLayout } from "redux/modules/modules/layouts/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";

export function mapStateToProps(state, props) {
  const recordTypesToPass = recordTypes(state, props.params.moduleId);
  const selectedRecordType = props.params.typeId
    ? recordTypesToPass.find(t => t.id === props.params.typeId)
    : recordTypesToPass[0];

  return {
    eventId: eventDetails(state).id,
    module: moduleDetails(state, props.params.moduleId),
    recordTypes: recordTypesToPass,
    selectedRecordType
  };
}

function mapDispatchToProps(dispatch, props) {
  const { eventId, orgId } = props.params;

  return bindActionCreators(
    {
      getModuleRecordTypes: () =>
        getRecordTypes({
          moduleId: props.params.moduleId,
          options: { orgId, eventId }
        }),
      showModal,
      hideModal,
      showSnackbar,
      hideSnackbar,
      addRecordType,
      updateRecordType,
      deleteRecordType,
      updateDefaultRecordType,
      addLayout
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(External)
);
