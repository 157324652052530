export default function basePath({ orgId, eventId }) {
  if (orgId) {
    return window.location.pathname.includes("light")
      ? `/org-light/${orgId}`
      : `/organization/${orgId}`;
  }
  return window.location.pathname.includes("light")
    ? `/event-light/${eventId}`
    : `/event/${eventId}`;
}
