import React from "react";

import {
  Div,
  Text6,
  Text4,
  CalendarIcon,
  BigFilledButton
} from "components/Base";

const ShowNoSchedules = ({ handlers }) => (
  <Div height={300} display="column.center.center" bg="white" bra={1}>
    <Div display="column.center.center">
      <CalendarIcon size={48} />
      <Text6 bold mt={5}>
        You haven't added any schedules yet.
      </Text6>
      <Text4>Your master schedule will roll up any schedules you create.</Text4>
      <BigFilledButton
        mt={8}
        bg="warning7"
        color="white"
        onClick={handlers.showAddScheduleModal}
      >
        Create Schedule
      </BigFilledButton>
    </Div>
  </Div>
);

export default ShowNoSchedules;
