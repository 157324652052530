import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { withRouter } from "react-router";
import { Div, FontIcon } from "components/Base";
import { getItemTypesWithHandlers } from "../selectors";
import { actions } from "../index";
import { addS } from "utils/General";

const decorate = connect(
  (state, props) => ({
    types: getItemTypesWithHandlers(state, props)
  }),
  {
    updateTypeId: actions.updateTypeId
  }
);

const Sidebar = ({
  types,
  router: { push },
  params: { eventId },
  updateTypeId
}) => (
  <Div
    display="column"
    bg="gray0"
    width={260}
    height={1}
    px={5}
    pt={5}
    pb={8}
    style={{
      flexShrink: 0,
      minHeight: "100%"
    }}
  >
    {types.map(type => (
      <Div
        key={type.id}
        display="row.flex-start.center"
        p={2}
        onClick={() => {
          updateTypeId(type.id);
          push(`/event/${eventId}/settings/catalog/type/${type.id}/items`);
        }}
        bra={1}
        bg={
          type.active
            ? "gray2"
            : {
                default: "gray0",
                hover: "gray2"
              }
        }
      >
        <Div
          display="column.center.center"
          width={36}
          height={36}
          bra={2}
          mr={3}
          style={{
            backgroundColor: type.color,
            flexShrink: 0
          }}
        >
          <FontIcon fs={4} color="white">
            {type.icon}
          </FontIcon>
        </Div>

        <Div display="column">
          <Div fs={3} fw={3} color="black">
            {type.name}
          </Div>
          <Div fs={1} color="gray7">
            {type.countOfItems} item
            {addS(type.countOfItems)} · {type.countOfCategories}{" "}
            {type.countOfCategories === 1 ? "category" : "categories"}
          </Div>
        </Div>
      </Div>
    ))}
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(Sidebar);
