/* eslint-disable no-underscore-dangle */
import moment from "moment";
import * as R from "ramda";

const round = (precision, rawKey, direction = "round") => momentObj => {
  const methods = {
    hours: {
      name: "Hours",
      maxValue: 24
    },
    minutes: {
      name: "Minutes",
      maxValue: 60
    },
    seconds: {
      name: "Seconds",
      maxValue: 60
    },
    milliseconds: {
      name: "Milliseconds",
      maxValue: 1000
    }
  };

  const keys = {
    mm: methods.milliseconds.name,
    milliseconds: methods.milliseconds.name,
    Milliseconds: methods.milliseconds.name,
    s: methods.seconds.name,
    seconds: methods.seconds.name,
    Seconds: methods.seconds.name,
    m: methods.minutes.name,
    minutes: methods.minutes.name,
    Minutes: methods.minutes.name,
    H: methods.hours.name,
    h: methods.hours.name,
    hours: methods.hours.name,
    Hours: methods.hours.name
  };

  let maxValue;
  let value = 0;
  let rounded = false;
  let subRatio = 1;

  const needsPluralized = k =>
    k.length > 1 && k !== "mm" && k.slice(-1) !== "s";
  const key = keys[
    needsPluralized(rawKey) ? `${rawKey}s` : rawKey
  ].toLowerCase();

  if (!methods[key]) {
    throw new Error(
      `Invalid method ${key}. Try one of: ${Object.keys(methods).join()}`
    );
  }

  const get = `get${methods[key].name}`;
  const set = `set${methods[key].name}`;

  Object.keys(methods).forEach(k => {
    if (k === key) {
      value = momentObj._d[get]();
      maxValue = methods[k].maxValue;
      rounded = true;
    } else if (rounded) {
      subRatio *= methods[k].maxValue;
      value += momentObj._d[`get${methods[k].name}`]() / subRatio;
      momentObj._d[`set${methods[k].name}`](0);
    }
  });

  value = Math[direction](value / precision) * precision;
  value = Math.min(value, maxValue);
  momentObj._d[set](value);

  return momentObj;
};

const ceil = (precision, key) => round(precision, key, "ceil");

const floor = (precision, key) => round(precision, key, "floor");

const isAtMidnight = momentObj =>
  momentObj.clone().format("HHmmSS") === "000000";

const scheduleDayFormatter = (start, end) => {
  if (R.isNil(end)) {
    return moment(start).format("MMM D, YYYY");
  }
  if (R.isNil(start)) {
    return `Ends ${moment(end).format("MMM D, YYYY")}`;
  }
  if (!moment(start).isSame(end, "year")) {
    return `${moment(start).format("MMM D, YYYY")} — ${moment(end).format(
      "MMM D, YYYY"
    )}`;
  }
  if (!moment(start).isSame(end, "month")) {
    return `${moment(start).format("MMM D")} — ${moment(end).format(
      "MMM D, YYYY"
    )}`;
  }
  if (!moment(start).isSame(end, "day")) {
    return `${moment(start).format("MMM D")} — ${moment(end).format(
      "D, YYYY"
    )}`;
  }
  return moment(start).format("MMM D, YYYY");
};

const getNow = () => moment();

const setLocalZone = (date = moment(), timezone = moment.tz.guess()) => {
  const dateWithoutZone = moment
    .tz(date, timezone)
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const localZone = moment(dateWithoutZone).format("Z");
  const dateWithLocalZone = [dateWithoutZone, localZone].join("");
  return new Date(dateWithLocalZone);
};

const setLocalZoneWithoutUTC = (
  date = moment(),
  timezone = moment.tz.guess()
) => {
  const dateWithoutZone = moment
    .tz(date, timezone)
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const localZone = moment(dateWithoutZone).format("Z");
  const dateWithLocalZone = [dateWithoutZone, localZone].join("");
  return new Date(dateWithLocalZone);
};

const setOtherZone = (date, timezone = moment.tz.guess()) => {
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const otherZone = moment.tz(date, timezone).format("Z");
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("");
  return new Date(dateWithOtherZone);
};

const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const dateRange = ({ start, end, format = "YYYY-MM-DD", step = "days" }) => {
  const range = [];
  for (const m = moment(start).utc(); m.isSameOrBefore(end); m.add(1, step)) {
    range.push(m.format(format));
  }
  return range;
};

const displayTimezone = (timezone = "") => {
  return timezone
    ? `All times listed below are in ${moment()
        .tz(timezone)
        .format("z")}`
    : `All times listed below are displayed in your local time zone.`;
};

export {
  dateRange,
  round,
  ceil,
  floor,
  isAtMidnight,
  scheduleDayFormatter,
  getNow,
  getCurrentTimezone,
  setLocalZone,
  setLocalZoneWithoutUTC,
  setOtherZone,
  displayTimezone
};
