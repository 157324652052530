import * as R from "ramda";
import { createSelector } from "reselect";
import moment from "moment";

import { SORTERS, FILTER } from "Schedules/ScheduleViewPicker/constants";
import { getters } from "Schedules/ScheduleViewPicker";

import { getters as TabGetters } from "ui-kit/Tabs";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";

import { userId } from "redux/modules/user/selectors";
import { NAMESPACE } from "Schedules/ScheduleViewPicker/constants";

export const getTabsById = createSelector(
  getters.tabs,
  tabs => R.indexBy(R.prop("id"), tabs)
);

export const getSorters = R.always(SORTERS);

const mappedSchedules = createSelector(
  getters.schedules,
  R.map(({ id, created_by_user_id, created_at, updated_at, name, views }) => ({
    id,
    name,
    views,
    createdBy: created_by_user_id,
    createdAt: created_at,
    updatedAtTimestamp: updated_at,
    updatedAt: updated_at ? moment(updated_at).fromNow() : null
  }))
);

const getIndividualSchedules = createSelector(
  mappedSchedules,
  schedules =>
    R.filter(
      R.compose(
        R.not,
        R.propEq("id", "master")
      )
    )(schedules)
);

const getFilterFn = createSelector(
  TabGetters.selectedTab,
  userId,
  (filterBy, userId) =>
    R.equals(filterBy, FILTER.BY_USER)
      ? R.filter(
          R.compose(
            R.equals(R.toString(userId)),
            R.toString,
            R.prop("createdBy")
          )
        )
      : R.filter(R.T)
);

export const getMasterSchedule = createSelector(
  mappedSchedules,
  schedules => R.head(R.filter(R.propEq("id", "master"))(schedules))
);

export const getActiveSchedule = createSelector(
  mappedSchedules,
  getters.activeScheduleId,
  (schedules, activeScheduleId) =>
    R.head(R.filter(R.propEq("id", activeScheduleId))(schedules))
);

export const getFilteredIndividualSchedules = createSelector(
  getMasterSchedule,
  getters.activeScheduleId,
  getIndividualSchedules,
  R.compose(
    R.toLower,
    state => SearchBarGetters.searchTerm(state, { instanceId: NAMESPACE })
  ),
  getters.sortBy,
  getFilterFn,
  (
    masterSchedule,
    activeScheduleId,
    allIndividualSchedules,
    searchTerm,
    sortBy,
    filterFn
  ) => {
    let individualSchedules = [...allIndividualSchedules];
    individualSchedules = filterFn(individualSchedules);
    const { sortFn } = sortBy;
    individualSchedules = R.filter(s => s.id !== activeScheduleId)([
      masterSchedule,
      ...sortFn(individualSchedules)
    ]);
    if (R.not(R.isEmpty(searchTerm))) {
      individualSchedules = R.filter(
        R.compose(
          R.contains(searchTerm),
          R.toLower,
          R.prop("name")
        ),
        individualSchedules
      );
    }
    return individualSchedules;
  }
);
