/* eslint-disable react/prop-types */

import React, { useEffect, useLayoutEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { FORBIDDEN_OPERATION_ERROR } from "constants/ErrorMessages";

import EnvironmentTag from "components/Platform/EnvironmentTag";
import PlatformLoading from "components/Platform/PlatformLoading";
import Layout from "OrgLight/Layout/View";

import Snackbar from "components/Global/Snackbar";
import Modal from "components/Global/Modal";

import { registerError } from "redux/modules/errors/actions";

import { hasFetchedAccessLevels } from "redux/modules/user/access-levels/selectors";
import { hasFetchedUserPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import { orgDetails, isFetching } from "redux/modules/organization/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { noop } from "utils/General";
import { actions } from "./model";

const decorate = connect(
  (state, props) => ({
    isFetchingOrgDetails: isFetching(state),
    orgDetails: orgDetails(state),
    user: UserSelectors.user(state),
    fetchedAccessLevels: hasFetchedAccessLevels(state),
    fetchedPermissions: hasFetchedUserPermissionProfile(
      state,
      props.params.orgId
    ),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
    isCustomerSuccess: selectFeatureFlag(flags.IS_CUSTOMER_SUCCESS.NAME)(state),
    registerError: registerError(state)
  }),
  {
    init: actions.init,
    bringAccess: actions.bringAccess
  }
);

const DontHavePermission = ({ text = "" }) => (
  <div
    style={{
      fontWeight: 600,
      textAlign: "center",
      color: "#333",
      fontSize: 40,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    {text}
  </div>
);

const OrgLightGate = ({
  params = {},
  user = {},
  fetchedAccessLevels = {},
  fetchedPermissions = {},
  isFetchingOrgDetails = noop,
  orgDetails = noop,
  init = noop,
  bringAccess = noop,
  children = React.Fragment,
  isLenndAdmin = false,
  isCustomerSuccess = false
}) => {
  useLayoutEffect(() => {
    init({ orgId: params.orgId, id: user.id });
  }, []);

  useEffect(() => {
    if (
      orgDetails?.id &&
      orgDetails?.error?.status === FORBIDDEN_OPERATION_ERROR
    ) {
      if (isLenndAdmin || isCustomerSuccess) {
        bringAccess({ orgId: params.orgId });
      }
    }
  }, [isLenndAdmin, isCustomerSuccess]);

  if (!R.prop("id")(user) || !fetchedAccessLevels || !fetchedPermissions) {
    return <PlatformLoading />;
  }

  if (
    orgDetails?.error?.status === FORBIDDEN_OPERATION_ERROR &&
    (isLenndAdmin || isCustomerSuccess)
  ) {
    return <DontHavePermission text="Bringing you access to this event ... " />;
  }

  if (
    orgDetails?.error?.status === FORBIDDEN_OPERATION_ERROR &&
    (!isLenndAdmin && !isCustomerSuccess)
  ) {
    return <DontHavePermission text="You do not have access to this event." />;
  }

  if (isFetchingOrgDetails && !("id" in orgDetails)) {
    return <PlatformLoading message="Loading organization..." />;
  }
  return (
    <>
      <Layout>{children}</Layout>
      <EnvironmentTag />
      <Snackbar />
      <Modal />
    </>
  );
};

export default decorate(OrgLightGate);
