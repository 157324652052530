import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import View from "./View";

import { actions as UserActions } from "redux/modules/user";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      updateUser: UserActions.updateUser
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
