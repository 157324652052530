import { createSelector } from "reselect";
import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";

import { getters as SelectGetters } from "ui-kit/SelectRows";
import { getters } from "Accounts/PeopleTypePanel";
import { getPagination } from "ui-kit/PaginationBar/selectors";

export const getKey = (s, props) => R.prop("instanceId", props);
export const getColor = getters.background_color;

const getContacts = (_, props) => R.pathOr([], ["group", "contacts"], props);

export const getTotal = createSelector(
  getContacts,
  R.length
);

const getPaginationIndexes = makeInstanceSelector(
  (state, props) =>
    getPagination(state, { ...props, total: getTotal(state, props) }),
  pagination => ({
    start: pagination.page * pagination.pageSize,
    end: (pagination.page + 1) * pagination.pageSize
  })
)(getKey);

export const getPeople = makeInstanceSelector(
  getContacts,
  getPaginationIndexes,
  (contacts, { start, end }) => R.slice(start, end, contacts)
)(getKey);

export const getSelectedIdsByType = makeInstanceSelector(
  getPeople,
  SelectGetters.selectedRows,
  (people, selected) =>
    R.filter(
      R.contains(R.__, selected), // eslint-disable-line no-underscore-dangle
      R.map(R.prop("id"), people)
    )
)(getKey);

export const getSelectedIdsWithUserId = makeInstanceSelector(
  getPeople,
  SelectGetters.selectedRows,
  (people, selected) =>
    R.compose(
      R.filter(person => R.contains(person.contactId)(selected)),
      R.map(person => ({
        contactId: person.id,
        contactUserId: person.contact_user_id
      }))
    )(people)
)(getKey);

export const getSelectedCountByType = makeInstanceSelector(
  getSelectedIdsByType,
  R.length
)(getKey);
