import React from "react";
import PropTypes from "prop-types";
import { Div, Span } from "components/Base";

const Avatar = ({ size, content, style, ...props }) => (
  <Div
    size={size}
    color="gray7"
    style={{ minWidth: size, fontSize: size, ...style }}
    display="row.center.center"
    bg="white"
    pill
    {...props}
  >
    <Span fw={3} style={{ fontSize: ".5em" }} display="row.center.center">
      {content}
    </Span>
  </Div>
);
export default Avatar;
