import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

const iniState = {
  selectedOptions: {},
  multiSelect: false,
  showModal: false,
  sortBy: {}
};

const reducers = {
  init: (
    { selectedOptions },
    { payload: { iniSelected = [], multiSelect } }
  ) => {
    return {
      selectedOptions: R.isEmpty(iniSelected)
        ? selectedOptions
        : R.indexBy(R.prop("id"), iniSelected),
      multiSelect
    };
  },
  updateSelectedOptions: (_, { payload }) => {
    return {
      selectedOptions: R.indexBy(R.prop("id"), payload)
    };
  },
  toggleOption: (
    { selectedOptions, multiSelect },
    { payload: id, meta: { option = {} } }
  ) => {
    if (option.all) {
      return {
        selectedOptions: {}
      };
    }
    if (R.has(id, selectedOptions)) {
      return {
        selectedOptions: R.omit([id], selectedOptions)
      };
    } else {
      return {
        selectedOptions: multiSelect
          ? R.assoc(id, 1, selectedOptions)
          : { [id]: 1 }
      };
    }
  },
  clear: () => ({
    selectedOptions: {}
  }),
  hideModal: R.always({ showModal: false }),
  showModal: R.always({ showModal: true }),
  applyFilters: R.always({ showModal: false }),
  clearAll: R.identity
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: "UIFilterControls"
});

const { actions, getters } = handlers;

const module = createModule({ ...handlers, batch: true });

export { actions, getters };

export default module;
