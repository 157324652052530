import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ChangeNames = ({ icon, label, action }) => (
  <div styleName="button" onClick={action}>
    {icon ? (
      <i className="material-icons" styleName="icon">
        {icon}
      </i>
    ) : null}
    <span>{label}</span>
  </div>
);

ChangeNames.propTypes = {
  action: PropTypes.func.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default CSSModules(ChangeNames, css);
