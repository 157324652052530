import React from "react";
import * as R from "ramda";
import { Div, LoadingIcon, Dropdown } from "components/Base";
import { Submit, ButtonOutline } from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Header from "./Header";
import { withProps } from "utils/General";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const Label = ({ children, required }) => (
  <Div
    display="row.flex-start.center"
    fs={1}
    color="gray5"
    uppercase
    mb={1}
    fw={3}
  >
    <Div>{children}</Div>
    {required ? (
      <Div ml={1} color="red7">
        *
      </Div>
    ) : null}
  </Div>
);

const FieldWrapper = ({ children }) => <Div mb={3}>{children}</Div>;

const Field = ({ Editor, name, onChange, value, editorProps, isRequired }) => {
  const maxLength = R.pathOr(
    "",
    ["column", "settings", "maxLength"],
    editorProps
  );
  return (
    <FieldWrapper>
      <Label required={isRequired}>{name}</Label>
      <Editor
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        {...editorProps}
      />
      {!R.isEmpty(maxLength) ? (
        <div
          style={{
            fontSize: 12,
            padding: "4px 0 0 4px",
            color: "#aaa",
            fontWeight: 600
          }}
        >
          {R.length(R.propOr("", "value", value))}/{maxLength} Character
          {R.length(R.propOr("", "value", value)) !== 1 ? "s" : ""}
        </div>
      ) : null}
    </FieldWrapper>
  );
};

const View = props => {
  const {
    loading,
    onTypeChange,
    typeId,
    types,
    hideModal,
    handleSave,
    fields,
    showTypes,
    saving
  } = props;
  const typeName = R.compose(
    R.propOr("type", "label"),
    R.find(R.propEq("value", typeId))
  )(types);
  const HeaderTypeName = withProps({
    typeName
  })(Header);
  const nonNameFields = R.filter(
    ({ id }) =>
      id !== STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME &&
      id !== STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
    fields
  );
  const firstName = R.find(
    R.propEq("id", STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME),
    fields
  );
  const lastName = R.find(
    R.propEq("id", STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME),
    fields
  );

  const validForm = () => {
    if (firstName?.value && lastName?.value) {
      return R.or(
        R.isEmpty(firstName.value.value),
        R.isEmpty(lastName.value.value)
      );
    } else {
      return true;
    }
  };

  const onHandleSave = () => {
    if (saving) return;
    handleSave();
  };

  return (
    <StyleWrapper
      bodyStyles={{ padding: 0 }}
      hideModal={hideModal}
      heading="Add Person"
      Header={HeaderTypeName}
      width={500}
    >
      <Body style={{ height: 400 }}>
        <Div px={7} py={4}>
          {loading ? (
            <Div mt={6} display="row.center.center" flex={1}>
              <LoadingIcon size={60} color="neutral4" />
            </Div>
          ) : (
            <Div>
              {showTypes ? (
                <FieldWrapper>
                  <Label required>Type</Label>
                  <Dropdown
                    options={types}
                    selected={typeId}
                    onChange={onTypeChange}
                  />
                </FieldWrapper>
              ) : null}

              <Div display="row.flex-start.center">
                <Div flex={1}>
                  <Field {...firstName} />
                </Div>
                <Div flex={1} ml={2}>
                  <Field {...lastName} />
                </Div>
              </Div>
              {R.map(
                f => (
                  <Field key={f.id} {...f} />
                ),
                nonNameFields
              )}
            </Div>
          )}
        </Div>
      </Body>

      <Div
        display="row.space-between.center"
        style={{
          padding: "0 30px"
        }}
      >
        <Div display="row.space-between.center" py={3} width={1}>
          <Div display="row.flex-start.center" flex={1}>
            <Submit
              title={
                saving ? (
                  <Div display="row.center.center">
                    <LoadingIcon size={15} color="white" ml={1} mr={2} /> Saving
                  </Div>
                ) : (
                  "Save"
                )
              }
              onClick={onHandleSave}
              disabled={validForm()}
              style={{
                marginRight: "10px"
              }}
            />
          </Div>

          <ButtonOutline title="Cancel" onClick={hideModal} />
        </Div>
      </Div>
    </StyleWrapper>
  );
};

export default View;
