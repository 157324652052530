import {
  put,
  takeEvery,
  all,
  fork,
  call,
  select,
  take
} from "redux-saga/effects";

import * as R from "ramda";

import { defaultInstanceId } from "redux-mvc";

import { actions as TableActions } from "ui-kit/Table/model";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import { actions, getters } from "./model";
import { getCurrentPage, getPageSize } from "./selectors";

import { TABLE_INSTANCE_ID } from "./constants";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

import { apiCall } from "App/Data/sagas";

const initData = function*({ meta = {}, payload: { historyType } }) {
  yield all([
    put(actions.setHistoryType(historyType)),
    put(actions.fetchData(null, { meta }))
  ]);
};

const watchInitData = function*() {
  yield takeEvery(actions.initData.type, initData);
};

export const search = function*({ type, meta = {} }) {
  yield put(actions.setLoading(true, { meta }, true));

  const instanceId =
    meta.instanceId && meta.instanceId !== TABLE_INSTANCE_ID
      ? meta.instanceId
      : null;

  try {
    const searchTerm = yield select(SearchBarGetters.searchTerm);
    const eventId = yield select(getEventId);
    const userId = yield select(getUserId);
    const page = yield select(getCurrentPage);
    const pageSize = yield select(getPageSize);
    const resetPagination = R.not(
      R.any(R.equals(type), [
        actions.setCurrentPage.type,
        actions.fetchData.type
      ])
    );
    const historyType = yield select(getters.historyType);
    const status = yield select(getters.selectedTab);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/email/event/${eventId}/history/${historyType}`,
      qs: {
        userId,
        page: resetPagination ? 1 : page + 1,
        pageSize,
        status,
        ...(searchTerm ? { search: searchTerm } : {})
      }
    });

    yield all([
      payload.preferences
        ? put(
            TableActions.setData(
              {
                canEditCells: false,
                columns: payload.fields,
                rows: payload.records,
                columnWidths: payload.preferences.field_widths
              },
              {
                meta: {
                  instanceId
                }
              }
            )
          )
        : null,
      put(
        actions.receiveList(
          {
            ...payload
          },
          { meta }
        )
      )
    ]);

    yield put(actions.setLoading(false, { meta }, true));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching passes list"
          }
        ])
      ),
      put(actions.setLoading(false, { meta }, true))
    ]);
  }
};

const watchSearch = function*() {
  yield takeEvery(SearchBarActions.setSearchTerm.type, search);
};

const watchClearSearch = function*() {
  for (;;) {
    const action = yield take(SearchBarActions.clearSearch.type);
    const instanceId = R.path(["meta", "instanceId"], action);
    if (R.isNil(instanceId) || instanceId === defaultInstanceId) {
      yield call(search, action);
    }
  }
};

const watch = function*() {
  yield takeEvery(
    [
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setSelectedTab.type,
      actions.fetchData.type,
      TableActions.refreshRecords.type
    ],
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInitData),
    fork(watchSearch),
    fork(watchClearSearch),
    fork(watch)
  ]);
};

export default rootSaga;
