import React from "react";
import { connect } from "react-redux";
import { noop } from "utils/General";

import { Div, MediumFilledButton, BigFilledButton } from "components/Base";

import { MODES } from "Items/Manage/constants";

import { actions, getters } from "Items/Manage";
import { bindInstance } from "redux-mvc";

const decorate = connect(
  (state, props) => ({
    mode: getters.mode(state, props)
  }),
  bindInstance({
    setMode: actions.setMode
  })
);

const buttonSizes = {
  medium: MediumFilledButton,
  big: BigFilledButton
};

const ModeToggle = ({
  mode,
  setMode = noop,
  size = "medium",
  ...styleProps
}) => (
  <Div
    bg="neutral0"
    display="row.stretch.center"
    ba={2}
    mb={4}
    bra={1}
    bc="neutral0"
    style={{ flexShrink: 0 }}
    p={1}
    {...styleProps}
  >
    {React.createElement(
      buttonSizes[size] || MediumFilledButton,
      {
        flex: 1,
        bg: mode === MODES.REVIEW ? "white" : "neutral0",
        color: mode === MODES.REVIEW ? "primary7" : "neutral7",
        onClick: () => setMode(MODES.REVIEW)
      },
      "Review"
    )}
    {React.createElement(
      buttonSizes[size] || MediumFilledButton,
      {
        flex: 1,
        bg: mode === MODES.ISSUANCE ? "white" : "neutral0",
        color: mode === MODES.ISSUANCE ? "primary7" : "neutral7",
        onClick: () => setMode(MODES.ISSUANCE)
      },
      "Issue"
    )}
  </Div>
);

export default decorate(ModeToggle);
