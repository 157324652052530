import * as R from "ramda";
import React, { useMemo } from "react";
import { getValue } from "../../utils";

const sortValues = R.sort((a, b) => {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
});

const EventListFormatter = props => {
  const value = getValue(props);

  const list = useMemo(() => sortValues(value), [value]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
      }}
    >
      {R.map(
        v => (
          <div key={v.id} className="pill">
            {v.name}
          </div>
        ),
        list
      )}
    </div>
  );
};

export default EventListFormatter;
