import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import Fuse from "fuse.js";
import View from "./View";
import CircularProgress from "material-ui/CircularProgress";
import {
  locations,
  locationAreas
} from "@lennd/value-types/src/constants/standard-modules";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import DeleteItemModal from "./Modals/DeleteItem";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { LOCATION_AREAS } from "utils/standard-module-field-ids";
const mapIndexed = R.addIndex(R.map);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      loading: true,
      activeTabValue: props.route.name
    };
  }

  componentDidMount() {
    this.itemsFuse = new Fuse(this.props.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });

    return Promise.all([this.getLocations(), this.getLocationAreas()]).then(
      () => {
        this.setState({ loading: false });
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    this.itemsFuse = new Fuse(nextProps.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });
  }

  getLocations = () => {
    const { eventId } = this.props.params;
    this.props.getRecords({
      moduleId: locations.id,
      options: { eventId }
    });
  };

  getLocationAreas = () => {
    const { eventId } = this.props.params;
    this.props.getRecords({
      moduleId: locationAreas.id,
      options: { eventId }
    });
  };

  onSearch = searchTerm => this.setState({ searchTerm });

  filterRecords = records => {
    const { parentRecordId } = this.props.params;
    if (parentRecordId) {
      return records.filter(record =>
        Object.keys(record.values).find(
          id =>
            record.values[id].type === "lookup" &&
            record.values[id].value.records.includes(parentRecordId)
        )
      );
    }
    return records;
  };

  showAddLocationModal = () => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={locations.id}
          onSave={() => this.getLocations()}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewLocationModal = id => {
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={locations.id}
          recordId={id}
          onClose={() => {
            this.props.getLocations();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  deleteLocation = async id => {
    await this.props.deleteRecord({
      moduleId: locations.id,
      eventId: this.props.eventDetails.id,
      record: { id },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    return this.getLocations();
  };

  showAddLocationAreaModal = locationId => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={locationAreas.id}
          onSave={() => this.getLocationAreas()}
          values={
            locationId
              ? {
                  [LOCATION_AREAS.LOCATION]: {
                    type: "lookup",
                    value: {
                      moduleId: locations.id,
                      records: [locationId]
                    }
                  }
                }
              : {}
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewItemModal = id => {
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={locationAreas.id}
          recordId={id}
          onClose={() => {
            this.getLocationAreas();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  deleteItem = item => {
    this.props.showModal({
      content: (
        <DeleteItemModal
          name={item.name}
          handleDelete={async () => {
            await this.props.deleteRecord({
              moduleId: locationAreas.id,
              eventId: this.props.eventDetails.id,
              record: { id: item.id },
              options: {
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId
              }
            });

            return this.getLocationAreas();
          }}
          hideModal={this.props.hideModal}
        />
      )
    });
  };

  render() {
    const { loading, activeTabValue, searchTerm } = this.state;
    const { locations } = this.props;

    if (loading) {
      return <CircularProgress color="#ccc" />;
    }

    const locationsWithHandlers = R.map(location => {
      const items =
        searchWithFuse(searchTerm, this.itemsFuse, location.items, "id") || [];
      return {
        id: location.id,
        name: location.name,
        countOfItems: items.length,
        onEdit: () => this.showViewLocationModal(location.id),
        onDelete: items.length
          ? undefined
          : () => this.deleteLocation(location.id),
        onAddItem: () => this.showAddLocationAreaModal(location.id),
        collapsedDefault: !items.length,
        forceCollapse: searchTerm.length && !items.length,
        items: R.map(item => ({
          id: item.id,
          name: item.name,
          onClick: () => this.showViewItemModal(item.id),
          onEdit: () => this.showViewItemModal(item.id),
          onDelete: () => this.deleteItem(item)
        }))(items)
      };
    })(locations);

    const tabs = [["editEventSettingsLocations", "Manage"]];

    return (
      <View
        {...{
          loading,
          tabs,
          activeTabValue,
          locations: locationsWithHandlers,
          onSearch: this.onSearch,
          showAddLocationModal: this.showAddLocationModal,
          showAddLocationAreaModal: this.showAddLocationAreaModal
        }}
      />
    );
  }
}

Controller.propTypes = {};

export default Controller;
