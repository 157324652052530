import * as R from "ramda";

import { createHandlers } from "redux-mvc";
import moment from "moment";

import { NAMESPACE, MAX_NUMBER_OF_ALERTS } from "./constants";

const getClosesDate = (
  dates = [],
  day = moment()
    .startOf("day")
    .valueOf()
) => {
  const temp = dates.map(d =>
    Math.abs(
      day -
        moment(d)
          .startOf("day")
          .valueOf()
    )
  );
  const closestIndex = temp.indexOf(Math.min(...temp));
  return closestIndex === -1 ? 0 : closestIndex;
};

const getDates = R.pluck("date");

const iniState = {
  loading: true,
  logoImageUrl: null,
  featuredVideo: null,
  playing: false,
  sessions: [],
  alerts: [],
  selectedSessionDate: null,
  selectedNewsAndAlertsDate: null,
  videoReplays: [],
  isSubscribedToNotifications: false,
  hasEnabledPhoneNumber: false,
  videoModal: {
    show: false,
    data: null
  },
  pageId: null,
  isNavigationOpen: false,
  countOfDates: 0,
  settings: {},
  preferences: {},
  countOfPeople: 0,
  networkEnabled: false
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    executeAction: R.identity,
    showVideoModal: (_, { payload }) =>
      R.always({ videoModal: { show: true, data: payload } })(),
    hideVideoModal: R.always({ videoModal: { show: false, data: null } }),
    refresh: R.identity,
    sessionDelete: R.identity,
    sessionCreate: R.identity,
    sessionUpdate: R.identity,
    alertUpdate: ({ alerts }, { payload: { alert } }) => ({
      alerts: R.map(
        R.when(
          R.propEq("date", moment(alert.created_at).format("YYYY-MM-DD")),
          /* eslint-disable no-underscore-dangle */
          R.evolve({
            items: R.map(R.when(R.propEq("id", alert.id), R.merge(R.__, alert)))
          })
        ),
        alerts
      )
    }),
    alertDelete: ({ alerts }, { payload: { id, created_at } }) => ({
      alerts: R.map(
        R.when(
          R.propEq("date", moment(created_at).format("YYYY-MM-DD")),
          R.evolve({
            items: R.reject(R.propEq("id", id))
          })
        ),
        alerts
      )
    }),
    alertCreate: ({ alerts }, { payload }) => {
      const date = moment(payload.created_at).format("YYYY-MM-DD");
      const dateExists = R.find(R.propEq("date", date))(alerts);
      let newsAlerts = [];
      if (dateExists) {
        newsAlerts = R.map(
          R.when(
            R.propEq("date", date),
            R.evolve({ items: R.concat([payload]) })
          ),
          alerts
        );
      } else {
        newsAlerts = R.concat(alerts, [{ date, items: [payload] }]);
      }

      return { alerts: newsAlerts };
    },
    pageUpdate: R.identity,
    setInitialData: (
      {
        selectedSessionDate,
        selectedNewsAndAlertsDate,
        sessions: prevSessions,
        alerts: prevAlerts,
        countOfPeople: prevCountOfPeople
      },
      {
        payload: {
          countOfPeople,
          preferences,
          details: {
            network_enabled: networkEnabled,
            has_enabled_phone_number: hasEnabledPhoneNumber,
            has_enabled_update_notifications: isSubscribedToNotifications,
            logo_image_url: logoImageUrl,
            featured_video: featuredVideo,
            video_replays: videoReplays,
            sessions,
            alerts,
            ...settings
          },
          id: pageId
        }
      }
    ) => {
      let selectedSessionDateIndex = null;
      let selectedAlertDateIndex = null;

      const sortedAlerts = alerts
        .sort((a, b) => moment(b.date).diff(a.date))
        .slice(0, MAX_NUMBER_OF_ALERTS);

      if (R.isNil(selectedSessionDate) && R.isNil(selectedNewsAndAlertsDate)) {
        selectedSessionDateIndex = getClosesDate(getDates(sessions));
        selectedAlertDateIndex = getClosesDate(getDates(sortedAlerts));
      } else {
        selectedSessionDateIndex = getClosesDate(getDates(sessions));
        selectedAlertDateIndex = getClosesDate(getDates(sortedAlerts));

        let nextSelectedSessionDateIndex = sessions[selectedSessionDateIndex];
        let preSelectedSessionDateIndex = prevSessions[selectedSessionDate];

        let nextSelectedAlertDateIndex = sortedAlerts[selectedAlertDateIndex];
        let preSelectedAlertDateIndex = prevAlerts[selectedNewsAndAlertsDate];

        if (nextSelectedSessionDateIndex) {
          const nextDate = R.prop("date", nextSelectedSessionDateIndex);
          const prevDate = R.propOr("", "date", preSelectedSessionDateIndex);
          if (prevDate && prevDate !== nextDate) {
            const index = R.findIndex(R.propEq("date", prevDate))(sessions);
            selectedSessionDateIndex =
              index > -1 ? index : selectedSessionDateIndex;
          }
        }

        if (nextSelectedAlertDateIndex) {
          const nextDate = R.prop("date", nextSelectedAlertDateIndex);
          const prevDate = R.propOr("", "date", preSelectedAlertDateIndex);
          if (prevDate && prevDate !== nextDate) {
            const index = R.findIndex(R.propEq("date", prevDate))(sortedAlerts);
            selectedAlertDateIndex =
              index > -1 ? index : selectedAlertDateIndex;
          }
        }
      }

      return {
        logoImageUrl,
        featuredVideo,
        sessions,
        networkEnabled,
        countOfDates: alerts.length,
        countOfPeople:
          typeof countOfPeople !== "undefined"
            ? countOfPeople
            : prevCountOfPeople,
        alerts: sortedAlerts,
        videoReplays,
        hasEnabledPhoneNumber,
        isSubscribedToNotifications,
        pageId,
        loading: false,
        selectedSessionDate: selectedSessionDateIndex,
        selectedNewsAndAlertsDate: selectedAlertDateIndex,
        settings,
        preferences
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
