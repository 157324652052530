import React from "react";
import { Div, SuperOutlineButton, CheckIcon } from "components/Base";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

const View = ({ hideModal }) => (
  <StyleWrapper
    heading="Create Form"
    hideModal={hideModal}
    width={600}
    bodyStyles={{ padding: 0 }}
  >
    <Div
      display="column.center.center"
      p={7}
      style={{ margin: "0 auto", maxWidth: 450 }}
    >
      <Div
        display="row.center.center"
        pill
        ba={3}
        bc="altA5"
        p={3}
        mt={3}
        mb={3}
      >
        <CheckIcon size={50} color="altA5" />
      </Div>
      <Div fs={5} fw={3} mb={2} style={{ textAlign: "center" }}>
        You're creating an order form!
      </Div>
      <Div style={{ textAlign: "center" }} mb={4}>
        By adding an Order Item block, you'll see some additional options to
        customize your order form.
      </Div>
      <SuperOutlineButton onClick={hideModal} mb={4}>
        Get started
      </SuperOutlineButton>
    </Div>
  </StyleWrapper>
);

export default View;
