import React from "react";

import { REFRESH_DASHBOARDS } from "Passes/Common/constants";

import { ToggleFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";
import Tooltip from "components/Global/Tooltip";

const RefreshDashboards = props => (
  <Tooltip
    tooltip="Refreshes results every 5 minutes to keep you up to date with any changes"
    placement="top"
  >
    <ToggleFilter instanceId={REFRESH_DASHBOARDS} {...props} bg="transparent">
      <Text2 bold>Auto-Refresh</Text2>
    </ToggleFilter>
  </Tooltip>
);

export default RefreshDashboards;
