import React from "react";
import { Div, LoadingIcon } from "components/Base";
import { Submit } from "components/Global/Modal/Layout/Buttons";
import { noop } from "utils/General";

const Footer = ({ hideModal = noop, saving = false }) => (
  <Div
    display="row.space-between.center"
    style={{
      padding: "0 30px"
    }}
    bg="white"
  >
    <Div display="row.space-between.center" py={3} width={1}>
      <Div display="row.flex-start.center" flex={1}>
        <Submit
          title={
            saving ? (
              <Div display="row.center.center">
                <LoadingIcon size={15} color="white" ml={1} mr={2} /> Saving
              </Div>
            ) : (
              "Done"
            )
          }
          onClick={hideModal}
          disabled={false}
          style={{
            marginRight: "10px"
          }}
        />
      </Div>
    </Div>
  </Div>
);

export default Footer;
