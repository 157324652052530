import React from "react";
import { connect } from "react-redux";
import { actions } from "Forms/WizardModal";
import SelectRecordTypes from "Forms/WizardModal/View/Common/SelectRecordTypes";

import { PASSES_PAGES } from "Forms/WizardModal/constants";

const decorate = connect(
  null,
  {
    back: () => actions.setSelectedPasses(PASSES_PAGES.SELECT_TYPE)
  }
);

const SelectAcc = ({ back, handlers }) => (
  <SelectRecordTypes back={back} handlers={handlers} />
);

export default decorate(SelectAcc);
