import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const EDITOR_FORMAT_MAP = {
  mask: "M/D/YYYY",
  calendar: "MMMM Do, YYYY"
};

@CSSModules(css)
class DateEditorSettings extends Component {
  @autobind
  onChange(e) {
    this.props.onChange({
      ...this.props.value,
      dateFormat: EDITOR_FORMAT_MAP[e.target.value],
      displayType: e.target.value
    });
  }

  render() {
    const {
      value: { displayType }
    } = this.props;
    return (
      <div>
        <div className="input-wrapper">
          <Label>Date Input Type</Label>
          <label styleName="radioWrapper">
            <input
              onChange={this.onChange}
              checked={displayType === "mask"}
              name="date-input"
              value="mask"
              type="radio"
            />
            <div styleName="radioLabel">Simple Date Input</div>
          </label>
          <label styleName="radioWrapper">
            <input
              onChange={this.onChange}
              checked={displayType === "calendar"}
              name="date-input"
              type="radio"
              value="calendar"
            />
            <div styleName="radioLabel">Date Calendar Picker</div>
          </label>
        </div>
      </div>
    );
  }
}

DateEditorSettings.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DateEditorSettings;
