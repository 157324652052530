import * as R from "ramda";
import {
  put,
  all,
  takeEvery,
  select,
  call,
  fork,
  take,
  throttle
} from "redux-saga/effects";
import { camelcase } from "utils/General";
import Helpers from "utils/Global/Helpers";
import moment from "moment";
import { STEPS } from "EventLight/Expo/Sales/constants";

import {
  ADDRESS_FORM_ID,
  SETTINGS_FORM_ID,
  PAYMENT_FORM_ID,
  ADDRESS_FIELDS,
  PAYMENT_FIELDS,
  SETTINGS_FIELDS,
  DATETIME_FORMAT
} from "EventLight/Expo/Sales/constants";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { actions, getters } from "EventLight/Expo/Sales/model";
import { actions as FormActions } from "ui-kit/Form/model";

import * as FormSelectors from "ui-kit/Form/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import {
  getLastIndexOfPhoto,
  getLastIndexOfFile
} from "EventLight/Expo/Sales/selectors";
import { registerError } from "redux/modules/errors/actions";

import Api from "../api";

const setShowcaseFields = function*() {
  const eventShowcase = yield select(getters.eventShowcase);
  const dates = {
    [SETTINGS_FIELDS.DATE]: {
      value: moment(eventShowcase[SETTINGS_FIELDS.DATE]).format(DATETIME_FORMAT)
    },
    [SETTINGS_FIELDS.END_DATE]: {
      value: moment(eventShowcase[SETTINGS_FIELDS.END_DATE]).format(
        DATETIME_FORMAT
      )
    }
  };

  const forms = [
    {
      id: SETTINGS_FORM_ID,
      values: R.mergeAll([
        R.map(
          value => ({
            value,
            meta: {
              instanceId: SETTINGS_FORM_ID
            }
          }),
          R.pick(
            [
              SETTINGS_FIELDS.NAME,
              SETTINGS_FIELDS.ATTENDEES,
              SETTINGS_FIELDS.HEADER,
              SETTINGS_FIELDS.DESCRIPTION,
              SETTINGS_FIELDS.DETAILS,
              SETTINGS_FIELDS.TERMS,
              SETTINGS_FIELDS.CONFIRMATION,
              SETTINGS_FIELDS.PRIMARY_BUTTON_TEXT,
              SETTINGS_FIELDS.PRIMARY_BUTTON_COLOR,
              SETTINGS_FIELDS.PRIMARY_BUTTON_OPENS_TO,
              SETTINGS_FIELDS.SECONDARY_BUTTON_TEXT,
              SETTINGS_FIELDS.SECONDARY_BUTTON_OPENS_TO
            ],
            eventShowcase
          )
        ),
        dates
      ])
    },
    {
      id: ADDRESS_FORM_ID,
      values: R.map(
        value => ({
          value,
          meta: {
            instanceId: ADDRESS_FORM_ID
          }
        }),
        R.pick(R.values(ADDRESS_FIELDS), eventShowcase)
      )
    },
    {
      id: PAYMENT_FORM_ID,
      values: R.map(
        value => ({
          value,
          meta: {
            instanceId: PAYMENT_FORM_ID
          }
        }),
        R.pick(R.values(PAYMENT_FIELDS), eventShowcase)
      )
    }
  ];

  yield put(FormActions.bulkWriteForms(forms));
};

const refreshEventShowcase = function*({ payload: { callWhenDone } = {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    const { payload } = yield call(Api.getEventShowcase, {
      credentials,
      eventId
    });

    yield put(actions.receiveEventShowcase(payload));

    if (callWhenDone) {
      yield put(callWhenDone());
    }

    yield call(setShowcaseFields);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred refreshing the sales portal"
          }
        ])
      )
    ]);
  }
};

const init = function*() {
  for (;;) {
    // @NOTE: Refresh sales portal on every step besides application
    const { payload } = yield take(actions.init.type);
    if (payload === STEPS.APPLICATION) {
      yield put(
        actions.refreshEventShowcase({
          callWhenDone: actions.getModuleFields
        })
      );
    } else {
      yield put(actions.refreshEventShowcase());
    }
  }
};

const formatDate = ({ fieldId, value }) => {
  if (R.contains(fieldId, [SETTINGS_FIELDS.DATE, SETTINGS_FIELDS.END_DATE])) {
    return moment(value, DATETIME_FORMAT);
  }
  return value;
};

export const updateEventShowcaseDetails = function*({
  payload: { fieldId, value, bulkPayload, refresh = true }
}) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(Api.updateEventShowcase, {
      credentials,
      eventId,
      data: fieldId
        ? { [camelcase(fieldId)]: formatDate({ value, fieldId }) }
        : bulkPayload
    });
    if (refresh) {
      yield put(actions.receiveEventShowcase(payload));
      yield call(setShowcaseFields);
    }
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating the sales portal"
          }
        ])
      )
    ]);
  }
};

//

const toggleCompletedStep = function*({ payload: stepId }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventShowcase = yield select(getters.eventShowcase);
  try {
    yield call(Api.updateEventShowcase, {
      credentials,
      eventId,
      data: {
        completedSteps: eventShowcase.completed_steps.includes(stepId)
          ? eventShowcase.completed_steps.filter(id => id !== stepId)
          : [...eventShowcase.completed_steps, stepId]
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding sales portal photo"
          }
        ])
      )
    ]);
  }
};

const addEventShowcasePhoto = function*({ payload: { photos } }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.addEventShowcasePhoto, {
      credentials,
      eventId,
      data: {
        bulk: true,
        photos
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding sales portal photo"
          }
        ])
      )
    ]);
  }
};

const updateEventShowcasePhoto = function*({ payload: {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcasePhoto, {
      credentials,
      eventId,
      data: {}
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating sales portal photo"
          }
        ])
      )
    ]);
  }
};

const deleteEventShowcasePhoto = function*({ payload: id }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.deleteEventShowcasePhoto, {
      credentials,
      eventId,
      data: {
        photoId: id
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred deleting sales portal photo"
          }
        ])
      )
    ]);
  }
};

const reorderEventShowcasePhotos = function*({ payload: photos }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcasePhoto, {
      credentials,
      eventId,
      data: {
        bulk: true,
        photos: photos.map(({ id }, idx) => ({ id, order: idx }))
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred reordering sales portal photos"
          }
        ])
      )
    ]);
  }
};

//

const addEventShowcaseFile = function*({ payload: { files } }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.addEventShowcaseFile, {
      credentials,
      eventId,
      data: {
        bulk: true,
        files
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding sales portal file"
          }
        ])
      )
    ]);
  }
};

const updateEventShowcaseFile = function*({ payload: {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcaseFile, {
      credentials,
      eventId,
      data: {}
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating sales portal file"
          }
        ])
      )
    ]);
  }
};

const updateFilename = function*({ payload: { id, title } }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcaseFile, {
      credentials,
      eventId,
      data: {
        id,
        title
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating sales portal file"
          }
        ])
      )
    ]);
  }
};

const deleteEventShowcaseFile = function*({ payload: id }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.deleteEventShowcaseFile, {
      credentials,
      eventId,
      data: {
        fileId: id
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred deleting sales portal file"
          }
        ])
      )
    ]);
  }
};

const reorderEventShowcaseFiles = function*({ payload: files }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcaseFile, {
      credentials,
      eventId,
      data: {
        bulk: true,
        files: files.map(({ id }, idx) => ({ id, order: idx }))
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred reordering sales portal files"
          }
        ])
      )
    ]);
  }
};

const getFilePicker = ({
  path = "event-showcase-background-image/",
  type = "image",
  multiple = false
}) =>
  new Promise(resolve => {
    const options = {
      multiple,
      accept:
        type === "image"
          ? [
              "image/bmp",
              "image/gif",
              "image/jpeg",
              "image/svg+xml",
              "image/png"
            ]
          : undefined
    };

    const pathToUse = { path };

    Helpers.getFilepicker(options, pathToUse, resolve);
  });

const showBgModal = function*({ payload: {} }) {
  const files = yield call(getFilePicker, {
    path: "event-showcase-background-image/"
  });
  const url = files[0].url;

  yield fork(updateEventShowcaseDetails, {
    payload: { fieldId: "backgroundImageUrl", value: url }
  });
};

const showFloormapImageModal = function*({ payload: {} }) {
  const files = yield call(getFilePicker, {
    path: "event-showcase-floormap-image/"
  });
  const url = files[0].url;

  yield fork(updateEventShowcaseDetails, {
    payload: { fieldId: "floormapImageUrl", value: url }
  });
};

const removeFloormap = function*() {
  yield fork(updateEventShowcaseDetails, {
    payload: { fieldId: "floormapImageUrl", value: null }
  });
};

const showSalesDeckModal = function*({ payload: {} }) {
  const lastIndex = yield select(getLastIndexOfFile);
  const eventShowcase = yield select(getters.eventShowcase);

  const files = yield call(getFilePicker, {
    path: "event-showcase-sales-files/",
    type: "file",
    multiple: true
  });

  yield fork(addEventShowcaseFile, {
    payload: {
      files: files.map((f, idx) => ({
        pageId: eventShowcase.id,
        title: f.filename,
        description: null,
        fileUrl: f.url,
        order: lastIndex + idx + 1
      }))
    }
  });
};

const showImageModal = function*({ payload: {} }) {
  const lastIndex = yield select(getLastIndexOfPhoto);
  const eventShowcase = yield select(getters.eventShowcase);

  const files = yield call(getFilePicker, {
    path: "event-showcase-image/",
    multiple: true
  });

  yield fork(addEventShowcasePhoto, {
    payload: {
      photos: files.map((f, idx) => ({
        pageId: eventShowcase.id,
        title: f.filename,
        description: null,
        photoUrl: f.url,
        order: lastIndex + idx + 1
      }))
    }
  });
};

//

const watchRefreshEventShowcase = function*() {
  yield takeEvery(actions.refreshEventShowcase.type, refreshEventShowcase);
};

const watchToggleCompletedStep = function*() {
  yield takeEvery(actions.toggleCompletedStep.type, toggleCompletedStep);
};

const watchAddEventShowcasePhoto = function*() {
  yield takeEvery(actions.addEventShowcasePhoto.type, addEventShowcasePhoto);
};

const watchUpdateEventShowcasePhoto = function*() {
  yield takeEvery(
    actions.updateEventShowcasePhoto.type,
    updateEventShowcasePhoto
  );
};

const watchDeleteEventShowcasePhoto = function*() {
  yield takeEvery(
    actions.deleteEventShowcasePhoto.type,
    deleteEventShowcasePhoto
  );
};

const watchReorderEventShowcasePhotos = function*() {
  yield takeEvery(
    actions.reorderEventShowcasePhotos.type,
    reorderEventShowcasePhotos
  );
};

const watchAddEventShowcaseFile = function*() {
  yield takeEvery(actions.addEventShowcaseFile.type, addEventShowcaseFile);
};

const watchUpdateEventShowcaseFile = function*() {
  yield takeEvery(
    actions.updateEventShowcaseFile.type,
    updateEventShowcaseFile
  );
};

const watchDeleteEventShowcaseFile = function*() {
  yield takeEvery(
    actions.deleteEventShowcaseFile.type,
    deleteEventShowcaseFile
  );
};

const watchReorderEventShowcaseFiles = function*() {
  yield takeEvery(
    actions.reorderEventShowcaseFiles.type,
    reorderEventShowcaseFiles
  );
};

const followFieldUpdate = function*({ id, fieldId, fieldType }) {
  if (
    ![
      SETTINGS_FIELDS.CONFIRMATION,
      SETTINGS_FIELDS.DESCRIPTION,
      SETTINGS_FIELDS.DETAILS,
      SETTINGS_FIELDS.TERMS,
      SETTINGS_FIELDS.PRIMARY_BUTTON_COLOR
    ].includes(fieldId) &&
    ![FIELD_TYPES.DATE, FIELD_TYPES.DROPDOWN].includes(fieldType)
  ) {
    for (;;) {
      const {
        meta: { instanceId }
      } = yield take(FormActions.blurField.type);
      if (instanceId === id) {
        break;
      }
    }
  }

  let newValue = yield select(FormSelectors.getFieldValue, {
    instanceId: id,
    fieldId
  });

  yield fork(updateEventShowcaseDetails, {
    payload: { fieldId, value: newValue, refresh: false }
  });
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldType, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if ([ADDRESS_FORM_ID, SETTINGS_FORM_ID].includes(instanceId)) {
      yield call(followFieldUpdate, {
        id: instanceId,
        fieldId,
        fieldType
      });
    }
  }
};

const watchShowBgModal = function*() {
  yield takeEvery(actions.showBgModal.type, showBgModal);
};

const watchFloormapImageModal = function*() {
  yield takeEvery(actions.showFloormapImageModal.type, showFloormapImageModal);
};

const watchRemoveFloormap = function*() {
  yield takeEvery(actions.removeFloormap.type, removeFloormap);
};

const watchSalesDeckModal = function*() {
  yield takeEvery(actions.showSalesDeckModal.type, showSalesDeckModal);
};

const watchImageModal = function*() {
  yield takeEvery(actions.showImageModal.type, showImageModal);
};

const watchUpdateEventShowcaseDetails = function*() {
  yield takeEvery(
    actions.updateEventShowcaseDetails.type,
    updateEventShowcaseDetails
  );
};

const watchUpdateFilename = function*() {
  yield takeEvery(actions.updateFilename.type, updateFilename);
};

const rootSaga = function*() {
  yield all([
    fork(init),
    fork(watchRefreshEventShowcase),
    fork(watchToggleCompletedStep),
    fork(watchAddEventShowcasePhoto),
    fork(watchUpdateEventShowcasePhoto),
    fork(watchDeleteEventShowcasePhoto),
    fork(watchReorderEventShowcasePhotos),
    fork(watchAddEventShowcaseFile),
    fork(watchUpdateEventShowcaseFile),
    fork(watchDeleteEventShowcaseFile),
    fork(watchReorderEventShowcaseFiles),
    fork(watchUpdateEventShowcaseDetails),
    fork(watchUpdateFields),
    fork(watchShowBgModal),
    fork(watchFloormapImageModal),
    fork(watchRemoveFloormap),
    fork(watchSalesDeckModal),
    fork(watchImageModal),
    fork(watchUpdateFilename)
  ]);
};

export default rootSaga;
