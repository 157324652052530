import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Sources = ({ ...props }) => <div />;

Sources.propTypes = {};

export default CSSModules(Sources, css);
