import * as R from "ramda";

import React from "react";

import { indexedColors } from "components/Base/Base/colorSpecs";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Circle = CSSModules(
  ({ size, onClick, children, bg = "neutral3", style, className }) => (
    <div
      display="row.center.center"
      className={className}
      onClick={onClick}
      style={{
        backgroundColor: indexedColors[bg] || indexedColors.neutral3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
        width: size,
        height: size,
        borderRadius: "50%",
        cursor: onClick ? "pointer" : undefined
      }}
    >
      {children}
    </div>
  ),
  css
);

const StatusCircle = ({ status, size = 25, onClick }) =>
  R.propOr(
    <Circle
      size={size}
      style={{
        backgroundColor: "white",
        border: `solid 2px ${indexedColors.neutral2}`,
        flexShrink: 0
      }}
    >
      <i
        className="material-icons"
        style={{ fontSize: 18, color: indexedColors.neutral2 }}
      >
        check
      </i>
    </Circle>,
    status,
    {
      task_not_complete: (
        <Circle
          size={size}
          className={onClick ? css.taskNotCompleteClickable : undefined}
          onClick={onClick}
          style={{
            backgroundColor: "white",
            border: `solid 1px ${indexedColors.gray3}`,
            flexShrink: 0
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, color: indexedColors.gray5 }}
          >
            check
          </i>
        </Circle>
      ),
      task_complete: (
        <Circle
          size={size}
          className={onClick ? css.taskCompleteClickable : undefined}
          onClick={onClick}
          style={{
            backgroundColor: indexedColors.success7,
            flexShrink: 0
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, color: "white" }}
          >
            check
          </i>
        </Circle>
      ),
      approved: (
        <Circle
          size={size}
          style={{
            backgroundColor: indexedColors.success7,
            flexShrink: 0
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, color: "white" }}
          >
            check
          </i>
        </Circle>
      ),
      "not-submitted": (
        <Circle
          size={size}
          style={{
            backgroundColor: "white",
            border: `solid 4px ${indexedColors.yellow7}`,
            flexShrink: 0
          }}
        />
      ),
      pending: (
        <Circle
          size={size}
          style={{
            backgroundColor: indexedColors.yellow7,
            border: `solid 4px ${indexedColors.yellow2}`,
            flexShrink: 0
          }}
        />
      ),
      rejected: (
        <Circle
          size={size}
          style={{
            backgroundColor: indexedColors.orange6,
            border: `solid 2px ${indexedColors.orange6}`,
            flexShrink: 0
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, color: "white" }}
          >
            close
          </i>
        </Circle>
      ),
      default: (
        <Circle
          size={size}
          style={{
            backgroundColor: "white",
            border: `solid 4px ${indexedColors.gray2}`,
            flexShrink: 0
          }}
        />
      ),
      "not-assigned": (
        <Circle
          size={size}
          style={{
            backgroundColor: "white",
            border: `solid 2px ${indexedColors.neutral2}`,
            flexShrink: 0
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, color: indexedColors.neutral2 }}
          >
            add
          </i>
        </Circle>
      )
    }
  );

export default StatusCircle;
