import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const DateRange = ({ label }) => <div styleName="range">{label}</div>;

DateRange.propTypes = {
  label: PropTypes.string.isRequired
};

export default CSSModules(DateRange, css);
