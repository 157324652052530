import React from "react";
import { connect } from "react-redux";
import { noop } from "utils/General";

import { bindInstance } from "redux-mvc";

import { Div } from "components/Base";

import PaginationBar from "components/Global/ReactTable/Pagination/General";

import { actions, getters } from "EventLight/Common/Manage";

const decorate = connect(
  (state, props) => ({
    pagination: getters.pagination(state, props)
  }),
  bindInstance({
    onPageSizeChange: actions.setPageSize,
    onPageChange: actions.setCurrentPage
  })
);

const BottomPagination = ({
  pagination,
  onPageSizeChange = noop,
  onPageChange = noop
}) => (
  <Div bg="white" bt={1} bc="neutral3" width={1}>
    <PaginationBar
      {...{
        ...pagination,
        onPageSizeChange,
        onPageChange,
        showPageSizeOptions: true,
        pageSizeOptions: [25, 50, 100],
        showPageJump: false,
        canPrevious: pagination.page > 0,
        canNext: pagination.page < pagination.pages - 1,
        total: pagination.total,
        rowsText: "rows"
      }}
    />
  </Div>
);

export default decorate(BottomPagination);
