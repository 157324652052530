/* eslint-disable no-use-before-define */
import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";

import { debounce } from "utils/General/sagas";
import { apiCall } from "App/Data/sagas";

import { actions, getters } from "EventLight/Common/Manage";
import { actions as TableActions } from "ui-kit/Table/model";

import {
  actions as ViewPickerActions,
  getters as ViewPickerGetters
} from "ui-kit/ViewPicker";

import { getItemTypes } from "./search";

import { actions as SearchBarActions } from "ui-kit/SearchBar";
import {
  actions as FilterControlsActions,
  getters as FilterControlsGetters
} from "ui-kit/FilterControls";

import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getGroupBy } from "EventLight/Common/Manage/selectors";

import {
  TABLE_INSTANCE_ID,
  VIEWPICKER_INSTANCE_ID
} from "EventLight/Common/Manage/constants";
import {
  GROUP_BY_FILTER_ID,
  ATTENDEE_FILTER_ID,
  GROUP_FILTER_ID
} from "Passes/Common/constants";
import { registerError } from "redux/modules/errors/actions";

import { COMMIT } from "redux-optimist";

import Api from "EventLight/Common/Manage/api";

const resizeColumns = function*({ payload: resizedColumns }) {
  const state = yield select(R.identity);
  const preferences = getters.preferences(state);
  const updatedFieldWidths = {
    ...preferences.field_widths,
    ...resizedColumns.reduce((map, field) => {
      map[field.id] = field.value;
      return map;
    }, {})
  };

  yield put(actions.updateFieldWidths(updatedFieldWidths));
};

const watchResizeColumns = debounce(
  action =>
    action.type === TableActions.resizeColumns.type &&
    R.path(["meta", "instanceId"], action) === TABLE_INSTANCE_ID,
  resizeColumns,
  500
);

const untoggleRows = function*({ meta = {} }) {
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: meta.instanceId
      }
    })
  );
};

const watchUntoggleRows = function*() {
  yield takeEvery(
    [
      actions.initData.type,
      actions.setSelectedFieldFilters.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.setSelectedTab.type,
      actions.fetchData.type,
      ViewPickerActions.revertViewChanges.type,
      ViewPickerActions.deleteView.type,
      ViewPickerActions.selectView.type,
      ViewPickerActions.addView.type,
      SearchBarActions.clearSearch.type
    ],
    untoggleRows
  );
};

const hideColumn = function*({ payload: field, meta = {} }) {
  const state = yield select(R.identity);
  const preferences = getters.preferences(state);
  const columns = getters.columns(state);

  yield all([
    put(
      TableActions.setColumns(
        columns.filter(c => c.id !== field.id),
        {
          meta: {
            instanceId: meta.instanceId
          }
        }
      )
    ),
    put(actions.setColumns(columns.filter(c => c.id !== field.id))),
    put(
      actions.setPreferences({
        ...preferences,
        visible_fields: preferences.visible_fields.filter(
          fId => fId !== field.id
        )
      })
    )
  ]);
};

const watchSaveCell = function*() {
  for (;;) {
    const { meta, optimist, payload } = yield take(TableActions.save.type);

    if (payload.column.type === "attendee-photo") {
      const blob = R.path(["value", "file", "blob"], payload);
      let file = R.path(["value", "file"], payload);

      if (blob) {
        // eslint-disable-next-line no-underscore-dangle
        const filepicker = window.filestack.init(window.__FILEPICKER_API_KEY__);
        file = yield call(R.always(filepicker.upload(blob)));
      }

      const userId = yield select(getUserId);
      yield call(apiCall, {
        url: `/contacts/${payload.value.personId}/photo`,
        method: "put",
        data: {
          contactId: payload.value.personId,
          value: {
            type: "file",
            value: file.url ? { files: [file] } : null
          }
        },
        qs: {
          // NOTE: remove when endpoint is validated
          userId
        }
      });
    }

    yield put(
      TableActions.saveResponse(null, {
        optimist: { ...optimist, type: COMMIT },
        meta
      })
    );
  }
};

const watchHideColumn = function*() {
  yield takeEvery(actions.hideColumn.type, hideColumn);
};

const fetchViews = function*({ meta = {} }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const state = yield select(R.identity);
  const itemTypes = getItemTypes(state, meta);
  const itemTypeId = itemTypes[0];

  try {
    const { payload: views } = yield call(Api.getViews, {
      credentials,
      eventId: eventIdToUse,
      itemTypeId
    });
    yield put(
      ViewPickerActions.setViews(views, {
        meta: {
          instanceId: VIEWPICKER_INSTANCE_ID
        }
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred gettings views"
        }
      ])
    );
  }
};

const watchFetchViews = function*() {
  yield takeEvery(actions.fetchViews.type, fetchViews);
};

const addView = function*({ payload: newViewData = {} }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = getters.preferences(state);
  const itemTypes = getItemTypes(state, {});
  const itemTypeId = itemTypes[0];

  try {
    const { payload } = yield call(Api.addView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      itemTypeId,
      name: newViewData.name
        ? newViewData.name
        : preferences.name && preferences.name.length
        ? preferences.id === "default"
          ? preferences.name
          : `${preferences.name} (Copy)`
        : "New View",
      visibleFields: preferences.visible_fields,
      fieldOrder: preferences.field_order,
      sortBy: preferences.sort_by,
      filters: preferences.filters,
      fieldWidths: preferences.field_widths,
      quickFilters: preferences.quick_filters,
      groupedBy: preferences.grouped_by
    });

    yield all([
      put(actions.addViewResponse(payload)),
      put(
        ViewPickerActions.addNewActiveView(payload, {
          meta: {
            instanceId: VIEWPICKER_INSTANCE_ID
          }
        })
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred adding view"
        }
      ])
    );
  }
};

const watchAddView = function*() {
  yield takeEvery(ViewPickerActions.addView.type, addView);
};

const updateView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);
  const itemTypes = getItemTypes(state, {});
  const itemTypeId = itemTypes[0];

  try {
    yield call(Api.updateView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      viewId: preferences.id,
      itemTypeId,
      ...data
    });
    yield put(actions.fetchViews());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating view"
        }
      ])
    );
  }
};

const watchUpdateView = function*() {
  yield takeEvery(
    [ViewPickerActions.updateView.type, ViewPickerActions.updateViewName.type],
    updateView
  );
};

const updateViewName = function*({ payload }) {
  yield put(actions.updateViewName(payload));
};

const watchUpdateViewName = function*() {
  yield takeEvery(ViewPickerActions.updateViewName.type, updateViewName);
};

const revertViewChanges = function*({ payload }) {
  yield put(actions.revertViewChanges(payload));
};

const watchRevertViewChanges = function*() {
  yield takeEvery(ViewPickerActions.revertViewChanges.type, revertViewChanges);
};

const saveViewChanges = function*({ meta = {} }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);
  const itemTypes = getItemTypes(state, meta);
  const itemTypeId = itemTypes[0];

  try {
    if (preferences.id === "default") {
      const { payload } = yield call(Api.addView, {
        credentials,
        eventId: eventIdToUse,
        orgId: orgIdToUse,
        itemTypeId,
        name: preferences.name,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by
      });

      yield all([
        put(
          ViewPickerActions.addNewActiveView(payload, {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          })
        ),
        put(actions.addViewResponse(payload))
      ]);
    } else {
      yield put(actions.saveViewChangesResponse(preferences));

      yield call(Api.updateView, {
        credentials,
        eventId: eventIdToUse,
        orgId: orgIdToUse,
        itemTypeId,
        viewId: preferences.id,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by
      });
      yield put(actions.fetchViews());
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving view changes"
        }
      ])
    );
  }
};

const watchSaveViewChanges = function*() {
  yield takeEvery(ViewPickerActions.saveViewChanges.type, saveViewChanges);
};

const deleteView = function*({ payload: data, meta = {} }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);
  const instanceId = meta.instanceId;
  const itemTypes = getItemTypes(state, {});
  const itemTypeId = itemTypes[0];

  try {
    yield call(Api.deleteView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      viewId: preferences.id,
      ...data
    });

    const [activeViewResult] = yield all([
      call(Api.getActiveView, {
        credentials,
        eventId: eventIdToUse,
        itemTypeId,
        mode: getters.mode(state, { instanceId })
      }),
      put(actions.fetchViews())
    ]);

    yield put(actions.selectActiveView(activeViewResult.payload));

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting view"
        }
      ])
    );
  }
};

const watchDeleteView = function*() {
  yield takeEvery(ViewPickerActions.deleteView.type, deleteView);
};

const selectView = function*({ payload: viewId, meta = {} }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const itemTypes = getItemTypes(state, meta);
  const itemTypeId = itemTypes[0];

  const views = ViewPickerGetters.views(state, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });
  const view = views.find(v => v.id === viewId);
  yield put(actions.selectView(view));

  // populate: group by
  if (view && view.grouped_by) {
    yield put(
      FilterControlsActions.setSelectedOptions(
        { [view.grouped_by]: 1 },
        {
          options: {},
          meta: { instanceId: GROUP_BY_FILTER_ID }
        }
      )
    );
  }

  if (view && view.quick_filters) {
    yield put(actions.updateSelectViewQuickFilters(view));
  }

  try {
    yield call(Api.selectView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      itemTypeId,
      viewId
    });

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred selecting view"
        }
      ])
    );
  }
};

const watchSelectView = function*() {
  yield takeEvery(ViewPickerActions.selectView.type, selectView);
};

// watch for "grouped by" changes
const watchApplyGroupedByFilter = function*() {
  for (;;) {
    const action = yield take(FilterControlsActions.toggleOption.type);
    if (
      R.contains(R.path(["meta", "instanceId"], action), [
        GROUP_BY_FILTER_ID,
        ATTENDEE_FILTER_ID, // Attendee Type
        GROUP_FILTER_ID // Group Type,
      ])
    ) {
      const state = yield select(R.identity);

      if (R.path(["meta", "instanceId"], action) === GROUP_BY_FILTER_ID) {
        // update: group by
        const preferences = getters.preferences(state);
        const groupBy = getGroupBy(state, { instanceId: TABLE_INSTANCE_ID });

        yield all([
          put(
            actions.setPreferences({
              ...preferences,
              grouped_by: groupBy
            })
          )
        ]);
      } else {
        // update: quick filters
        yield call(updateQuickFilters);
      }
    }
  }
};

const updateQuickFilters = function*() {
  const state = yield select(R.identity);
  const preferences = getters.preferences(state);
  const quickFilters = {
    contactTypeIds: FilterControlsGetters.selectedOptions(state, {
      instanceId: ATTENDEE_FILTER_ID
    }),
    accountTypeIds: FilterControlsGetters.selectedOptions(state, {
      instanceId: GROUP_FILTER_ID
    }),
    //
    selectedMeals: yield select(getters.selectedMeals),
    selectedSources: yield select(getters.selectedSources),
    selectedRequester: yield select(getters.selectedRequester),
    selectedPasses: yield select(getters.selectedPasses),
    selectedInventoryItems: yield select(getters.selectedInventoryItems),
    selectedBoothItems: yield select(getters.selectedBoothItems),
    selectedSponsorshipItems: yield select(getters.selectedSponsorshipItems)
  };

  yield all([
    put(
      actions.setPreferences({
        ...preferences,
        quick_filters: quickFilters
      })
    )
  ]);
};

const watchQuickFilterChanges = function*() {
  yield takeEvery(
    [
      actions.setSelectedMeals.type,
      actions.setSelectedSources.type,
      actions.setSelectedRequester.type,
      actions.setSelectedPasses.type,
      actions.setSelectedInventoryItems.type,
      actions.setSelectedBoothItems.type,
      actions.setSelectedSponsorshipItems.type,
      actions.clearFilters.type
    ],
    updateQuickFilters
  );
};

const rootSaga = function*() {
  yield all([
    // table actions
    fork(watchUntoggleRows),
    fork(watchResizeColumns),
    fork(watchHideColumn),
    fork(watchSaveCell),
    // view actions
    fork(watchFetchViews),
    fork(watchAddView),
    fork(watchDeleteView),
    fork(watchUpdateView),
    fork(watchUpdateViewName),
    fork(watchRevertViewChanges),
    fork(watchSelectView),
    fork(watchSaveViewChanges),
    fork(watchApplyGroupedByFilter),
    fork(watchQuickFilterChanges)
  ]);
};

export default rootSaga;
