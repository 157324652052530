import * as R from "ramda";

import React from "react";
import { Link, withRouter } from "react-router";

import { ButtonContainer } from "ui-kit/FilterControls/View";
import { Text2, LeftIcon } from "components/Base";

import { mapProps } from "utils/General";

const decorate = R.compose(
  withRouter,
  mapProps(props => ({
    baseUrl: props.location.pathname.replace(
      /\/(account|category|item-type)\/.*$/,
      ""
    )
  }))
);

const BackToAllRequests = ({ baseUrl }) => (
  <Link to={baseUrl}>
    <ButtonContainer mt={2}>
      <LeftIcon mr={2} sizeWFS={4} />
      <Text2 bold>Back to all requests</Text2>
    </ButtonContainer>
  </Link>
);

export default decorate(BackToAllRequests);
