import * as R from "ramda";
import { createHandlers } from "redux-mvc";

// Initial State
const iniState = {
  loadingApproval: {},
  manifests: {}
};

// Handlers
const handlers = createHandlers({
  iniState,
  reducers: {
    onUndoReview: R.identity,
    onApprove: R.identity,
    onDeny: R.identity,
    onRemove: R.identity,
    reviewDone: R.identity,
    fetchManifest: (state, { payload }) => ({
      loadingApproval: {
        ...state.loadingApproval,
        [payload.manifestId]: true
      }
    }),
    manifestResponse: (state, { payload }) => ({
      manifests: {
        ...state.manifests,
        [payload.id]: payload
      },
      loadingApproval: {
        ...state.loadingApproval,
        [payload.id]: false
      }
    }),
    setLoadingApproval: (state, { payload }) => ({
      loadingApproval: {
        ...state.loadingApproval,
        [payload.id]: payload.status
      }
    })
  },
  namespace: "ApproversLabel"
});

export default handlers;
