import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ItemSummary from "./ItemSummary";
import { catalogItems } from "@lennd/value-types/src/constants/standard-modules";

import { getRecords } from "redux/modules/modules/records/actions";
import { records } from "redux/modules/modules/records/selectors";

function mapStateToProps(state, props) {
  return {
    record:
      records(state, catalogItems.id).find(
        r => r.id === props.catalogItem.id
      ) || {}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecords
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemSummary);
