import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Event/TasksWidget/model";
import { getDueLength, getAssignedLength } from "Event/TasksWidget/selectors";
import { Div, CheckIcon, Text0 } from "components/Base";
import { TABS } from "Event/TasksWidget/constants";
import Circle from "ui-kit/Circle";

const decorate = connect(
  state => ({
    selectedTab: getters.selectedTab(state),
    dueLength: getDueLength(state),
    assignedLength: getAssignedLength(state)
  }),
  {
    setSelectedTab: actions.setSelectedTab
  }
);

const Layout = ({ selectedTab, setSelectedTab, dueLength, assignedLength }) => (
  <Div bb={1} bc="neutral2">
    <Div display="row.flex-start.center" p={4}>
      <Circle size={40} bg="neutral1">
        <CheckIcon size={24} />
      </Circle>
      <Div color="neutral7" fw={4} ml={2} fs={5}>
        My Tasks
      </Div>
    </Div>
    <Div display="row.flex-start.center" px={8}>
      <Div
        pb={2}
        mr={2}
        color={selectedTab === TABS.DUE ? "primary7" : "black"}
        bc={selectedTab === TABS.DUE ? "primary7" : "transparent"}
        bb={2}
        onClick={() => setSelectedTab(TABS.DUE)}
        px={1}
        display="row.flex-start.center"
      >
        <Div
          fs={2}
          fw={3}
          color={selectedTab === TABS.DUE ? "primary7" : "black"}
        >
          Due
        </Div>
        {dueLength ? (
          <Div
            bra={2}
            px={2}
            bg="danger7"
            height={15}
            display="row.center.center"
            ml={1}
          >
            <Text0 color="white">{dueLength}</Text0>
          </Div>
        ) : null}
      </Div>
      <Div
        pb={2}
        mr={2}
        bc={selectedTab === TABS.ASSIGNED_TO_ME ? "primary7" : "transparent"}
        bb={2}
        onClick={() => setSelectedTab(TABS.ASSIGNED_TO_ME)}
        px={1}
        display="row.flex-start.center"
      >
        <Div
          fs={2}
          fw={3}
          color={selectedTab === TABS.ASSIGNED_TO_ME ? "primary7" : "black"}
        >
          Assigned to me
        </Div>
        {assignedLength ? (
          <Div
            bra={2}
            px={2}
            bg="neutral2"
            height={15}
            display="row.center.center"
            ml={1}
          >
            <Text0 color="neutral7">{assignedLength}</Text0>
          </Div>
        ) : null}
      </Div>
      <Div
        pb={2}
        color={selectedTab === TABS.COMPLETED ? "primary7" : "black"}
        bc={selectedTab === TABS.COMPLETED ? "primary7" : "transparent"}
        bb={2}
        fs={2}
        fw={3}
        onClick={() => setSelectedTab(TABS.COMPLETED)}
        px={1}
      >
        Completed
      </Div>
    </Div>
  </Div>
);

export default decorate(Layout);
