import React from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  BigShadedInput,
  SuperFilledButton,
  SearchIcon,
  CheckIcon,
  AddIcon,
  CloseIcon
} from "components/Base";
import CheckBox from "material-ui/Checkbox";

const fieldProps = {
  display: "row.flex-start.center",
  height: 32,
  bra: 1,
  mt: 1,
  bc: "neutral3",
  px: 2,
  wdith: 1
};

const FieldsModal = ({
  hideModal,
  fieldsList,
  showAddFieldModal,
  moduleId,
  toggleEnabled,
  disableField,
  enableAll,
  disableAll,
  setSearchFilter
}) => (
  <Div
    display="row.center.center"
    flex={1}
    style={{
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      zIndex: 5
    }}
  >
    <Div
      display="column.space-between"
      width={715}
      height={528}
      bra={1}
      bg="white"
      p={6}
    >
      <Div>
        <Text5 bold>Select Fields to Collect</Text5>
        <Div display="row.space-between" height={360} width={1} mt={3}>
          <Div
            width={330}
            bg="white"
            mr={3}
            py={1}
            height={360}
            style={{ overflowY: "auto" }}
          >
            <BigShadedInput
              continuous
              RightIcon={SearchIcon}
              flex={1}
              placeholder="Search fields"
              onChange={text => {
                setSearchFilter(text);
              }}
              bg="neutral0"
              width={1}
              mb={3}
            />
            <Div display="row.space-between" px={1}>
              <Text3 bold>All Fields</Text3>
              <Div onClick={enableAll}>
                <Text1 color="primary9" underline>
                  Select All
                </Text1>
              </Div>
            </Div>
            <Div width={1}>
              {R.map(
                field => (
                  <Div {...fieldProps}>
                    {field.locked ? (
                      <CheckIcon color="primary9" size={20} ml={1} mr={4} />
                    ) : (
                      <CheckBox
                        key={field.id}
                        style={{ width: "auto" }}
                        checked={field.enabled}
                        onClick={() => {
                          toggleEnabled({ fieldId: field.id });
                        }}
                      />
                    )}
                    <Text2 bold>{field.name}</Text2>
                  </Div>
                ),
                fieldsList
              )}
            </Div>
          </Div>
          <Div
            width={310}
            height={360}
            bg="neutral0"
            bra={2}
            p={4}
            style={{ overflowY: "auto" }}
          >
            <Div display="row.space-between.center" px={1}>
              <Text3 bold>Set the field order</Text3>
              <Div onClick={disableAll}>
                <Text1 color="primary9" underline>
                  Remove all
                </Text1>
              </Div>
            </Div>
            {R.compose(
              R.map(field => (
                <Div
                  shadow={1}
                  mt={1}
                  bg={{ default: "white", hover: "gray1" }}
                  color="black"
                  width={1}
                  display="row.space-between.center"
                  bra={1}
                  height={33}
                  px={3}
                  key={field.id}
                >
                  <Text2 bold>{field.name}</Text2>
                  {field.locked === false && (
                    <CloseIcon
                      color={{ default: "neutral6", hover: "danger8" }}
                      onClick={() => {
                        disableField({ fieldId: field.id });
                      }}
                    />
                  )}
                </Div>
              )),
              R.filter(R.propEq("enabled", true))
            )(fieldsList)}
          </Div>
        </Div>
      </Div>
      <Div display="row.space-between">
        <Div display="row.flex-start">
          <SuperFilledButton
            bg="altB5"
            width={164}
            onClick={() => {
              hideModal({ save: true });
            }}
          >
            Done
          </SuperFilledButton>
          <Div
            width={175}
            ml={2}
            onClick={() => {
              showAddFieldModal({
                moduleId,
                source: "applications"
              });
            }}
            bg="white"
            bc="neutral1"
            bra={1}
            display="row.center.center"
            ba={2}
            pl={2}
            pr={3}
          >
            <AddIcon size={24} mr={2} />
            <Text4 color="neutral5" bold>
              Add Field
            </Text4>
          </Div>
        </Div>
        <Div
          width={102}
          ml={2}
          onClick={hideModal}
          bg="white"
          bc="neutral1"
          bra={1}
          display="row.center.center"
          ba={2}
        >
          <Text4 color="neutral5" bold>
            Cancel
          </Text4>
        </Div>
      </Div>
    </Div>
  </Div>
);

export default FieldsModal;
