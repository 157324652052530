import React, { useEffect } from "react";

const TwitterEmbed = ({ content = "" }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, [content]);

  return (
    <section className="twitterContainer">
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-theme="light"
          data-tweet-limit="1"
          data-chrome="nofooter noborders transparent"
          href={`https://twitter.com/${content}`}
        >
          Tweets by {content}
        </a>
      </div>
    </section>
  );
};

export default TwitterEmbed;
