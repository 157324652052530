import React from "react";
import { connect } from "react-redux";

import { getters, actions } from "Orders/ImportModal";

import {
  getActiveFieldsToSelect,
  getIniEnabledCustomFields
} from "Orders/ImportModal/selectors";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";
import SelectFields from "ui-kit/SelectFields/View";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";

import { noop } from "utils/General";

const decorate = connect(
  state => ({
    showModal: getters.showSelectFieldsModal(state),
    fields: getActiveFieldsToSelect(state),
    activeSelectFieldsSection: getters.activeSelectFieldsSection(state),
    iniSelectedFields: getIniEnabledCustomFields(state)
  }),
  {
    hideModal: () => actions.setShowSelectFieldsModal(false),
    onDone: actions.setEnabledCustomFields,
    onClose: () => actions.setShowSelectFieldsModal(false)
  }
);

const modal = {
  "e9d14c00-e74c-4520-85c4-0a3f6b6cc9d1": SelectAndCreateVariants
};

const titles = {
  "e9d14c00-e74c-4520-85c4-0a3f6b6cc9d1": "Choose items available to import",
  "f8e14c00-e74c-4520-85c4-0a3f6b6cc9d1": "Choose zones available to import"
};

const SelectFieldsModal = ({
  showModal = false,
  title = "Select more fields to map CSV data into",
  fields = [],
  iniSelectedFields = [],
  onClose = noop,
  onDone = noop,
  activeSelectFieldsSection = ""
}) => (
  <MiniModalWrapper
    showModal={showModal}
    title={titles[activeSelectFieldsSection] || title}
    hideModal={() => onClose()}
    width={865}
    style={{
      height: "95%"
    }}
  >
    {(showModal &&
      React.createElement(modal[activeSelectFieldsSection] || SelectFields, {
        instanceId: "importOrdersFieldSelector",
        fields: fields,
        iniSelected: iniSelectedFields,
        iniClearSearch: true,
        onCancel: onClose,
        onDone: onDone
      })) ||
      null}
  </MiniModalWrapper>
);

export default decorate(SelectFieldsModal);
