import React from "react";
import * as R from "ramda";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import { Div, TinyToggle, DownIcon, RightIcon } from "components/Base";

const Field = field => (
  <Div
    key={field.name}
    bra={1}
    bg="white"
    p={2}
    mb={2}
    display="row.space-between.center"
    style={{
      boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.08)"
    }}
  >
    <Div display="column">
      <Div fw={3} truncate>
        {field.name}
      </Div>
      <Div color="gray6" fs={1} truncate>
        {field.type}
      </Div>
    </Div>

    <Div>
      <TinyToggle
        active={field.isVisible}
        onClick={field.onToggleVisible}
        mr={3}
      />
      <TinyToggle active={field.isRequired} onClick={field.onToggleRequired} />
    </Div>
  </Div>
);

const Fields = ({ activeStep }) => (
  <Div>
    <ScrollableModalBody
      style={{
        minHeight: 400,
        backgroundColor: "#fff"
      }}
    >
      <Div display="column" width={1} px={8} py={3}>
        {activeStep.groups.map(group => (
          <Div key={group.name} bg="neutral0" bra={1} p={3}>
            <Div display="row.space-between.center" mb={3}>
              <Div fw={3} color="gray7">
                {group.name}
              </Div>
              <Div display="row.flex-start.center" mr={2}>
                <Div color="gray5" fs={1} mr={2}>
                  Include
                </Div>
                <Div color="gray5" fs={1}>
                  Require
                </Div>
              </Div>
            </Div>

            {group.primary.map(field => <Field key={field.name} {...field} />)}

            <Div display="row.space-between.flex-end" mt={3} ml={1}>
              <Div
                display="row.flex-start.center"
                onClick={group.toggleSecondaryFields}
              >
                {group.isShowingSecondaryFields ? (
                  <DownIcon size={25} mr={1} color="gray7" />
                ) : (
                  <RightIcon size={25} mr={1} color="gray7" />
                )}
                <Div color="gray7" fs={2} fw={3}>
                  {group.seeAllLabel}
                </Div>
              </Div>
              {group.isShowingSecondaryFields ? (
                <Div display="row.flex-start.center" mr={5}>
                  <Div color="gray5" fs={1} mr={2}>
                    Include
                  </Div>
                  <Div color="gray5" fs={1}>
                    Require
                  </Div>
                </Div>
              ) : null}
            </Div>

            {group.isShowingSecondaryFields ? (
              <Div mt={2} px={3}>
                {group.secondary.map(field => (
                  <Field key={field.name} {...field} />
                ))}
              </Div>
            ) : null}
          </Div>
        ))}
      </Div>
    </ScrollableModalBody>
  </Div>
);

export default Fields;
