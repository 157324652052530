import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  addQuestionSet: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/items/question-sets`,
        credentials,
        success,
        data,
        error
      });
    }),
  updateQuestionSet: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/question-sets`,
        credentials,
        success,
        data,
        error
      });
    }),
  getQuestionSet: (credentials, questionSetId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/question-sets/${questionSetId}`,
        credentials,
        success,
        error
      });
    })
};
