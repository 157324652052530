import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/AssignQuestionsItemsModal";

import { Div, BigClearInput, SearchIcon } from "components/Base";

import QuestionsSetsCollapsable from "./QuestionsSetsCollapsable";

import IndividualQuestionsCollapsable from "./IndividualQuestionsCollapsable";

import SelectedQuestions from "./SelectedQuestions";

const decorate = connect(
  state => ({
    questionsFilter: getters.questionsFilter(state)
  }),
  {
    setQuestionsFilter: actions.setQuestionsFilter
  }
);

const AssignItems = ({ questionsFilter, setQuestionsFilter, handlers }) => (
  <Div width={1} height={1}>
    <Div
      height={60}
      width={1}
      bb={1}
      bc="neutral1"
      display="row.flex-start.center"
      px={2}
    >
      <BigClearInput
        LeftIcon={SearchIcon}
        onChange={value => setQuestionsFilter(value)}
        width={1}
        continuous
        value={questionsFilter}
        placeholder="Filter questions..."
      />
    </Div>
    <Div
      display="row.flex-start.center"
      width={1}
      style={{ height: "calc(100% - 60px)" }}
    >
      <Div
        width={467}
        height={1}
        bg="neutral0"
        p={2}
        style={{ overflow: "auto" }}
      >
        <QuestionsSetsCollapsable handlers={handlers} />
        <IndividualQuestionsCollapsable />
      </Div>
      <Div width={276} height={1}>
        <SelectedQuestions />
      </Div>
    </Div>
  </Div>
);

export default decorate(AssignItems);
