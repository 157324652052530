const keyMirror = require("keymirror");

module.exports = {
  ActionTypes: keyMirror({
    RECEIVE_EVENT_PROFILE_DETAILS: null,
    RECEIVE_EVENT_PROFILE_LOCATION: null,
    RECEIVE_EVENT_PROFILE_FEATURES: null,
    RECEIVE_EVENT_PROFILE_PHOTOS: null,
    RECEIVE_EVENT_PROFILE_VIDEOS: null,
    RECEIVE_EVENT_PROFILE_TEAM: null,
    RECEIVE_EVENT_PROFILE_ROLES: null,

    RECEIVE_EVENT_GROUPS: null,
    CREATE_EVENT_GROUP: null,
    DELETE_EVENT_GROUP: null,
    UPDATE_EVENT_GROUP: null,

    EVENT_PROFILE_UPDATE_NAME: null,
    EVENT_PROFILE_UPDATE_DATE: null
  }),

  PayloadSources: keyMirror({
    SERVER_ACTION: null,
    VIEW_ACTION: null
  })
};
