import { ADD_TEMPLATES } from "./constants";

export default function(state = [], action) {
  switch (action.type) {
    case ADD_TEMPLATES:
      return [...action.payload];
    default:
      return state;
  }
}
