import React from "react";
import {
  Div,
  SuperFilledButton,
  SuperOutlineButton,
  Text6,
  BigFilledButton,
  BigOutlineButton
} from "components/Base";
import OrderView from "components/Global/Orders";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const View = ({ formName, formId, onEditForm, onShareForm, onSubmitForm }) => (
  <OrderView
    orderTypeName={"Passes / Manage / Form"}
    ordersPath={`/passes/manage/sources/${formId}`}
    orderType="passes"
    title={formName}
    typeId={CREDENTIAL_TYPE_ID}
    sourceId={formId}
    Actions={() => [
      onEditForm && (
        <BigOutlineButton onClick={onEditForm} mr={2}>
          Edit Form
        </BigOutlineButton>
      ),
      <BigFilledButton bg="altB5" onClick={onSubmitForm}>
        Submit
      </BigFilledButton>
    ]}
    EmptyState={() => (
      <Div pt={10} display="row.center.center">
        <Div display="column.center.center">
          <Text6 bold pb={4}>
            Nothing here yet
          </Text6>
          <Div pb={1} width={1} display="row.flex-start.flex-start">
            {onShareForm && (
              <SuperOutlineButton onClick={onShareForm} mr={1}>
                Share Form
              </SuperOutlineButton>
            )}
            <SuperFilledButton flex={1} bg="altB5" onClick={onSubmitForm}>
              Submit
            </SuperFilledButton>
          </Div>

          <Div width={1} display="row.flex-start.flex-start">
            {onEditForm && (
              <SuperOutlineButton
                onClick={onEditForm}
                flex={1}
                display="row.center.center"
              >
                Edit Form
              </SuperOutlineButton>
            )}
          </Div>
        </Div>
      </Div>
    )}
  />
);

export default View;
