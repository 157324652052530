import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const SelectInput = CSSModules(
  ({ value, placeholder, ...other }) => (
    <div
      {...other}
      tabIndex="0"
      role="listbox"
      className={`global-select ${css.selectInput}`}
    >
      <div styleName={value ? "selectLabel" : "selectPlaceholderLabel"}>
        {value || placeholder}
      </div>
      <i className={["material-icons", css.arrow].join(" ")}>expand_more</i>
    </div>
  ),
  css
);

const Select = CSSModules(
  ({ children, placeholder, value, width, ...other }) => (
    <div>
      <SelectInput
        {...other}
        style={{
          width,
          ...other.style
        }}
        value={value}
        placeholder={placeholder}
      />
      {children}
    </div>
  )
);

Select.defaultProps = {
  width: 250
};

Select.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.string,
  value: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Select;
