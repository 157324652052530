export const NAMESPACE = "ActivityDetails";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const SIDEBAR_INSTANCE_ID = "ActivityDetailsSidebar";

export const ACTIVITY_DETAILS_FORM_ID = "ActivityDetailsForm";

export const HISTORY_DATE_FORMAT = "MMM D H:mma";
export const DATE_FORMAT = "M/D/YYYY";
export const TIME_FORMAT = "h:mm a";
export const ACTIVITY_STARTS_AT_ID = STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT;
export const ACTIVITY_ENDS_AT_ID = STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT;
export const ACTIVITY_NAME = STANDARD_FIELD_IDS.SCHEDULES.NAME;

export const FIELDS_ONLY_EDITOR = ["checkbox", "lookup"];
export const FIELDS_ON_POPOVER = ["event-days", "user", "file"];

export const RECURRENCE_TYPES = {
  NO_REPEAT: "no-repeat",
  DAILY: "daily",
  WEEKDAY: "weekday"
};

export const VISIBILITY_IDS = {
  DEFAULT: "default",
  SHOW_ON_PORTAL: "show_on_default",
  DONT_SHOW_ON_PORTAL: "dont_show_on_portal"
};

export const recurrenceOptions = [
  {
    id: RECURRENCE_TYPES.NO_REPEAT,
    value: "Does not repeat"
  },
  { id: RECURRENCE_TYPES.DAILY, value: "Daily" },
  {
    id: RECURRENCE_TYPES.WEEKDAY,
    value: "Every weekday (Monday to Friday)"
  }
];

export const visibilityOptions = [
  {
    id: VISIBILITY_IDS.DEFAULT,
    value: "Default"
  },
  {
    id: VISIBILITY_IDS.SHOW_ON_PORTAL,
    value: "Show on portal"
  },
  {
    id: VISIBILITY_IDS.SHOW_ON_PORTAL,
    value: "Don’t show on portal"
  }
];

export const SCHEDULING_EXTRA_FIELD_IDS = {
  ALL_DAY: "isAllDay",
  RECURRENCE: "recurrence",
  STARTS_AT_DATE: "startsAtDate",
  STARTS_AT_TIME: "startsAtTime",
  ENDS_AT_DATE: "endsAtDate",
  ENDS_AT_TIME: "endsAtTime",
  DURATION: "duration",
  VISIBILITY: "visibility",
  REMINDERS: "reminders",
  COLOR: "color",
  IS_COMPLETED: STANDARD_FIELD_IDS.SCHEDULES.COMPLETE
};

export const DATE_TIME_FIELD_MATCH = {
  [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE]: "startsAtTime",
  [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME]: "startsAtDate",
  [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE]: "endsAtTime",
  [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME]: "endsAtDate"
};

export const DATE_TIME_FORMAT = {
  [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE]: DATE_FORMAT,
  [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME]: TIME_FORMAT,
  [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE]: DATE_FORMAT,
  [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME]: TIME_FORMAT
};

export const CORRESPONDING_FIELD_ID = {
  [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE]: ACTIVITY_STARTS_AT_ID,
  [SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME]: ACTIVITY_STARTS_AT_ID,
  [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE]: ACTIVITY_ENDS_AT_ID,
  [SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME]: ACTIVITY_ENDS_AT_ID
};

export const SCHEDULING_OPTIONS_FIELDS = [
  /*
  // @NOTE: Hide until implemented
  {
    name: "Options",
    id: "options",
    fields: [
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.ALL_DAY,
        type: "toggle",
        name: "All Day"
      },
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.RECURRENCE,
        type: "dropdown",
        settings: {
          allowMultipleSelect: false,
          options: recurrenceOptions
        }
      }
    ]
  },
  */
  {
    id: "startDate",
    name: "Start Date / Time",
    fields: [
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE,
        type: "date",
        settings: {
          dateFormat: DATE_FORMAT,
          displayType: "calendar"
        }
      },
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME,
        type: "time",
        settings: {
          timeFormat: TIME_FORMAT
        }
      }
    ]
  },
  {
    id: "endDate",
    name: "End Date / Time",
    fields: [
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE,
        type: "date",
        settings: {
          dateFormat: DATE_FORMAT,
          displayType: "calendar"
        }
      },
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME,
        type: "time",
        settings: {
          timeFormat: TIME_FORMAT
        }
      }
    ]
  }
];

export const SCHEDULING_SETTINGS_FIELDS = [
  {
    name: "Visibility",
    id: "visibility",
    fields: [
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.VISIBILITY,
        type: "dropdown",
        settings: {
          allowMultipleSelect: false,
          options: visibilityOptions
        }
      }
    ]
  },
  {
    id: "reminders",
    name: "Reminders",
    fields: [
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.REMINDERS,
        type: "lookup",
        settings: {}
      }
    ]
  },
  {
    id: "color",
    name: "Color",
    fields: [
      {
        id: SCHEDULING_EXTRA_FIELD_IDS.COLOR,
        type: "color"
      }
    ]
  }
];

export const ACTIONS = {
  DELETE: "deleteActivity",
  CLONE: "cloneActivity"
};
