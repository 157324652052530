import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions, getters } from "Modules/ImportModal";
import { PAGES } from "Modules/ImportModal/constants";
import ImportFile from "./ImportFile";
import PasteTable from "./PasteTable";
import MapCsv from "./MapCsv";
import InvalidRows from "./InvalidRows";
import Summary from "./Summary";

const decorate = connect(
  state => ({
    selectedPage: getters.selectedPage(state)
  }),
  {
    goTocopyPaste: () => actions.setSelectedPage(PAGES.COPY_PASTE),
    goToImportFile: () => actions.setSelectedPage(PAGES.IMPORT_FILE),
    gotToMapCsv: () => actions.setSelectedPage(PAGES.MAP_CSV)
  }
);

const Body = props => {
  const { selectedPage, handlers } = props;
  if (selectedPage === PAGES.COPY_PASTE) {
    return <PasteTable />;
  } else if (
    selectedPage === PAGES.MAP_CSV ||
    selectedPage === PAGES.MAP_CSV_PREVIEW
  ) {
    return <MapCsv />;
  } else if (selectedPage === PAGES.INVALID_ROWS) {
    return <InvalidRows handlers={handlers} />;
  } else if (selectedPage === PAGES.SUMMARY_SCENE) {
    return <Summary handlers={handlers} />;
  }
  return <ImportFile handlers={handlers} />;
};

Body.propTypes = {
  selectedPage: PropTypes.func.isRequired
};

export default decorate(Body);
