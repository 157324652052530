import { combineReducers, concatReducers } from "redux-mvc";
import optimist from "redux-optimist";
import { routerReducer } from "react-router-redux";
import { flopflipReducer, FLOPFLIP_STATE_SLICE } from "@flopflip/react-redux";

import { history } from "./history";
import errors from "./errors/reducer";

import marketingResources from "./marketing/resources/reducer";

import activities from "./activities/reducer";

import approvalWorkflowSpaces from "./approvalWorkflows/spaces/reducer";
import approvalWorkflowWorkflows from "./approvalWorkflows/workflows/reducer";

import billing from "./billing/reducer";

import organization from "./organization/reducer";
import organizationUsers from "redux/modules/organization/users/reducer";
import organizations from "./organizations/reducer";

import event from "./event/reducer";
import eventEmailMergeTags from "./event/email-merge-tags/reducer";
import eventSetupProgress from "./event/progress/reducer";
import events from "./events/reducer";

import report from "./reports/report/reducer";
import reports from "./reports/reducer";

import eventHome from "./eventHome/reducer";
import eventContacts from "./eventContacts";
import eventContactList from "./eventContactList";
import eventGroups from "./eventGroups";
import eventMembers from "./eventMembers";

import eventInventory from "./inventory/reducer";
import inventoryCategoryOverview from "redux/modules/inventory/reports/category-overview/reducer";
import inventoryAccountOverview from "redux/modules/inventory/reports/account-overview/reducer";
import inventoryNeedsOverview from "redux/modules/inventory/reports/needs-overview/reducer";

import contactProfile from "./contacts/profile/reducer";
import contactAssignedForms from "./contacts/assignedForms/reducer";
import contactAssignedDocumentRequests from "./contacts/assignedDocumentRequests/reducer";

import eventPermissionRoles from "./eventPermissionRoles/reducer";

import eventEmailTemplates from "./eventEmailTemplates/reducer";

import portal from "./portal/reducer";

import eventUsers from "./eventUsers/reducer";

import forms from "./forms/reducer";
import formPreferences from "./formPreferences/reducer";
import formOverview from "./formOverview/reducer";
import formRequests from "./formRequests/reducer";

import dashboards from "./dashboards/reducer";
import formsV2_forms from "./formsV2/forms/reducer";
import formsV2_form from "./formsV2/form/reducer";
import formsV2_messages from "./formsV2/messages/reducer";
import formsV2_fields from "./formsV2/form/fields/reducer";
import formsV2_formOverview from "./formsV2/overview/reducer";
import formsV2_submission from "./formsV2/submission/reducer";
import formsV2_submissionCart from "./formsV2/submission/cart/reducer";
import formsV2_submissionActivity from "./formsV2/submission/activity/reducer";
import formsV2_submissionComments from "./formsV2/submission/comments/reducer";
import formsV2_submissions from "./formsV2/submissions/reducer";
import formsV2_references from "./formsV2/references/reducer";
import formsV2_assignments from "./formsV2/form/assignments/reducer";
import peopleBlocks from "./formsV2/people-blocks/reducer";

import credentialsList from "./credentials/credentials/list/reducer";
import credentialsCredential from "./credentials/credentials/credential/reducer";
import credentialsCredentialGroups from "./credentials/credential-groups/list/reducer";
import credentialsIntegrations from "./credentials/integrations/reducer";
import credentialsModules from "./credentials/modules/reducer";

import guestLists from "./guestLists/reducer";

import credentialsLineItems from "./credentials/lineItems/reducer";
import credentialsCounts from "./credentials/counts/reducer";
import credentialsOrderSchema from "./credentials/order/schema/reducer";
import credentialsOrders from "./credentials/orders/reducer";
import credentialsOrder from "./credentials/order/reducer";
import credentialsCheckin from "./credentials/checkin/reducer";
import passesCheckin from "./passesCheckin/reducer";
import passes from "./passes/reducer";

import accountProfile from "./accounts/profile/reducer";
import accountList from "./accounts/list/reducer";
import accountReports from "./accounts/reports/reducer";
import accountPermissions from "./accounts/permissionSets/reducer";
import accountCredentials from "./accounts/credentials/reducer";
import accountAssignedForms from "./accounts/assignedForms/reducer";
import accountAssignedDocumentRequests from "./accounts/assignedDocumentRequests/reducer";

import ticketingProviders from "./ticketing/providers/reducer";

import eventbriteEvents from "./integrations/eventbrite/events/reducer";
import eventbriteOrders from "./integrations/eventbrite/orders/reducer";
import eventbriteTicketClasses from "./integrations/eventbrite/ticketClasses/reducer";
import eventbriteOrdersWithEventbriteCredentials from "./integrations/eventbrite/ordersWithEventbriteCredentials/reducer";

import stripeConnectAuth from "./integrations/stripe-connect/auth/reducer";

import moduleEventCatering from "./moduleEventCatering/reducer";
import cateringModules from "./catering/modules/reducer";
import cateringCheckin from "./catering/checkin/reducer";

import moduleEventAccounts from "./moduleEventAccounts/reducer";

import features from "./features/reducer";

import moduleModule from "./modules/module/reducer";
import moduleManagers from "./modules/managers/reducer";
import moduleModules from "./modules/modules/reducer";
import moduleMessages from "./modules/messages/reducer";
import moduleEmail from "./modules/email/reducer";
import moduleNotes from "./modules/notes/reducer";
import moduleActivity from "./modules/activity/reducer";
import moduleLayouts from "./modules/layouts/reducer";
import moduleLayout from "./modules/layout/reducer";
import moduleLayoutVisibleFields from "./modules/layouts/visibleFields/reducer";
import moduleRecordTypes from "./modules/recordTypes/reducer";
import moduleFields from "./modules/fields/reducer";
import moduleSidebar from "./modules/sidebar/reducer";
import moduleRecords from "./modules/records/reducer";
import moduleRecordOwners from "./modules/owners/reducer";
import moduleRecordSubscription from "./modules/subscription/reducer";
import moduleRelatedRecords from "./modules/relatedRecords/reducer";
import moduleRelatedModuleVisibleFields from "./modules/relatedModules/visibleFields/reducer";
import moduleTemplates from "./modules/templates/reducer";
import moduleViews from "./modules/views/reducer";
import moduleForm from "./modules/form/reducer";
import recordView from "./modules/record/reducer";

import permissionGroups from "./permissions/permission-groups/reducer";
import permissionProfiles from "./permissions/permission-profiles/reducer";
import userPermissionProfile from "./permissions/user-permission-profile/reducer";

import eventForms from "./eventForms";
import sidebar from "./sidebar";
import user from "./user/reducer";
import userAccessLevels from "./user/access-levels/reducer";
import userPortals from "./user/portals/reducer";
import modal from "./modal/reducer";
import snackbar from "./snackbar/reducer";
import entityReferences from "./entityReferences/reducer";
import documents from "./documents/reducer";

import orders from "./orders/orders/reducer";
import preparedLineItems from "./orders/bulk-edit-line-items/reducer";
import ordersByRecord from "./orders/ordersByRecord/reducer";
import orderSummaryByRecord from "./orders/orderSummaryByRecord/reducer";
import orderReports from "./orders/reports/reducer";
import orderSync from "./orders/sync/reducer";
import orderSources from "./orders/sources/reducer";
import orderInvoices from "./orders/invoices/reducer";

import items from "./items/items/reducer";
import itemInventory from "./items/item-variant-inventory/reducer";
import itemTypes from "./items/types/reducer";
import itemGroups from "./items/item-groups/reducer";
import itemBlocks from "./items/item-blocks/reducer";
import itemBlock from "./items/item-block/reducer";

import zones from "./items/zones/reducer";
import zoneGroups from "./items/zone-groups/reducer";

import globalModule from "./global";

const reducer = optimist(
  combineReducers({
    [FLOPFLIP_STATE_SLICE]: flopflipReducer,
    activities,
    approvalWorkflows: combineReducers({
      spaces: approvalWorkflowSpaces,
      workflows: approvalWorkflowWorkflows
    }),
    documents,
    event: combineReducers({
      event,
      emailMergeTags: eventEmailMergeTags,
      setup: eventSetupProgress
    }),
    events,
    errors,
    eventHome,
    eventEmailTemplates,
    eventContacts,
    eventContactList,
    eventGroups,
    eventMembers,
    inventory: eventInventory,
    items: combineReducers({
      items,
      types: itemTypes,
      itemInventory,
      groups: itemGroups,
      itemBlock,
      itemBlocks,
      zones,
      zoneGroups
    }),
    orders: combineReducers({
      orders,
      ordersByRecord,
      orderSummaryByRecord,
      reports: orderReports,
      sync: orderSync,
      preparedLineItems,
      sources: orderSources,
      invoices: orderInvoices
    }),
    inventoryV2: combineReducers({
      reports: combineReducers({
        accountOverview: inventoryAccountOverview,
        categoryOverview: inventoryCategoryOverview,
        needsOverview: inventoryNeedsOverview
      })
    }),
    eventUsers,
    modules: combineReducers({
      activity: moduleActivity,
      email: moduleEmail,
      fields: moduleFields,
      form: moduleForm,
      layout: moduleLayout,
      layoutVisibleFields: moduleLayoutVisibleFields,
      layouts: moduleLayouts,
      managers: moduleManagers,
      messages: moduleMessages,
      module: moduleModule,
      modules: moduleModules,
      notes: moduleNotes,
      owners: moduleRecordOwners,
      records: moduleRecords,
      recordTypes: moduleRecordTypes,
      recordView,
      relatedRecords: moduleRelatedRecords,
      relatedModuleVisibleFields: moduleRelatedModuleVisibleFields,
      showSidebar: moduleSidebar,
      subscription: moduleRecordSubscription,
      templates: moduleTemplates,
      views: moduleViews
    }),
    billing,
    contacts: combineReducers({
      profile: contactProfile,
      assignedForms: contactAssignedForms,
      assignedDocumentRequests: contactAssignedDocumentRequests
    }),
    accounts: combineReducers({
      assignedDocumentRequests: accountAssignedDocumentRequests,
      assignedForms: accountAssignedForms,
      credentials: accountCredentials,
      list: accountList,
      permissionSets: accountPermissions,
      profile: accountProfile,
      reports: accountReports
    }),
    formsV2: combineReducers({
      list: formsV2_forms,
      form: formsV2_form,
      fields: formsV2_fields,
      messages: formsV2_messages,
      overview: formsV2_formOverview,
      submissionCart: formsV2_submissionCart,
      submissionActivity: formsV2_submissionActivity,
      submissionComments: formsV2_submissionComments,
      submission: formsV2_submission,
      submissions: formsV2_submissions,
      references: formsV2_references,
      assignments: formsV2_assignments,
      peopleBlocks
    }),
    form: combineReducers({
      overview: formOverview,
      list: forms,
      preferences: formPreferences,
      requests: formRequests
    }),
    dashboards,
    integrations: combineReducers({
      eventbrite: combineReducers({
        events: eventbriteEvents,
        orders: eventbriteOrders,
        ticketClasses: eventbriteTicketClasses,
        ordersWithEventbriteCredentials: eventbriteOrdersWithEventbriteCredentials
      }),
      stripeConnect: combineReducers({
        auth: stripeConnectAuth
      })
    }),
    portal,
    permissions: combineReducers({
      roles: eventPermissionRoles, // @NOTE: Deprecated
      profiles: permissionProfiles,
      groups: permissionGroups,
      userProfile: userPermissionProfile
    }),
    guestLists,
    passesCheckin,
    passes,
    credentials: combineReducers({
      checkin: credentialsCheckin,
      credentials: credentialsList,
      credential: credentialsCredential,
      credentialGroups: credentialsCredentialGroups,
      integrations: credentialsIntegrations,
      modules: credentialsModules,
      lineItems: credentialsLineItems,
      counts: credentialsCounts,
      orders: credentialsOrders,
      order: credentialsOrder,
      orderSchema: credentialsOrderSchema
    }),
    ticketing: combineReducers({
      providers: ticketingProviders
    }),
    catering: combineReducers({
      checkin: cateringCheckin,
      modules: cateringModules
    }),
    reports: combineReducers({
      reports,
      report
    }),
    marketing: combineReducers({
      resources: marketingResources
    }),
    moduleEventCatering,
    moduleEventAccounts,
    eventForms,
    sidebar,
    user,
    userPortals,
    userAccessLevels,
    modal,
    snackbar,
    features,
    entityReferences,
    organization: combineReducers({
      organization,
      users: organizationUsers
    }),
    organizations,
    history,
    routing: routerReducer
  })
);

export default concatReducers([globalModule.reducer, reducer]);
