import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as R from "ramda";
import View from "./View";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { getResources } from "redux/modules/marketing/resources/actions";
import { resources } from "redux/modules/marketing/resources/selectors";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

function mapStateToProps(state) {
  const resourceType = "guest-list-overview";

  return {
    types: recordTypes(state, STANDARD_MODULES.accounts.id),
    resourceType,
    resources: resources(state, resourceType),
    shouldHideAllocations: selectFeatureFlag(
      flags.SHOULD_HIDE_ALLOCATIONS.NAME
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecordTypes,
      getResources
    },
    dispatch
  );
}

class GuestListSetupController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.resources && props.resources.images ? false : true
    };
  }

  async componentDidMount() {
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULES.accounts.id,
      options: { eventId: this.props.params.eventId }
    });

    if (this.state.loading) {
      await this.props.getResources(this.props.resourceType);

      return this.setState({
        loading: false
      });
    }
    return true;
  }

  gotoManagementView = id => {
    this.props.router.push(
      `/event/${this.props.params.eventId}/passes/guest-list/${id}`
    );
  };

  render() {
    const types = R.map(type => ({
      id: type.id,
      title: type.name,
      color: type.background_color,
      icon: type.icon,
      iconColor: type.text_color,
      onClick: () => this.gotoManagementView(type.id)
    }))(this.props.types);

    return (
      <View
        {...{
          types: this.props.shouldHideAllocations ? [] : types,
          loading: this.state.loading,
          resources: this.props.resources.images || []
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestListSetupController);
