import React from "react";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { Div } from "components/Base";

import Body from "./Body";
import Loading from "ui-kit/Loading";
import Footer from "./Footer";

import { connect } from "react-redux";
import { getters } from "Schedules/SelectScheduleFieldsModal";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ handlers, loading, hideModal }) => (
  <MiniModalWrapper
    hideModal={hideModal}
    width={743}
    height={600}
    bg="white"
    showModal
    isPortal={false}
    showHeader={false}
    stopPropagation
  >
    {loading ? (
      <Div display="row.center.center" width={1} height={1}>
        <Loading />
      </Div>
    ) : (
      <Body handlers={handlers} />
    )}
    <Footer handlers={handlers} hideModal={hideModal} />
  </MiniModalWrapper>
);
export default decorate(Layout);
