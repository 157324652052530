import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../../../model";

import { Div, Text5, CloseIcon, BigFilledButton } from "components/Base";
import { Input, Toggle } from "ui-kit/Form/View";
import { FieldWrapper, Label } from "EventLight/Common/FieldLayout";

import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { SALESFORCE_FORM_ID, FIELD_IDS } from "../../../constants";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { noop } from "utils/General";

import CSSModules from "react-css-modules";
import css from "../../styles.scss";

const Header = ({ title = "", hideModal = noop }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="white"
    p={4}
    bb={1}
    bc="neutral2"
    brt={1}
  >
    <Text5 bold color="neutral8">
      {title}
    </Text5>

    <Div onClick={hideModal}>
      <CloseIcon color="neutral6" sizeWFS={6} />
    </Div>
  </Div>
);

const decorate = R.compose(
  WithFormInstanceProvider(R.always(SALESFORCE_FORM_ID)),
  CSSModules(css),
  connect(
    (state, props) => ({
      showModal: getters.isSalesforceModalOpen(state, props),
      data: getters.data(state),
      error: getters.error(state)
    }),
    {
      hideModal: () => actions.setIsSalesforceModalOpen(false),
      saveSalesforceSettings: actions.saveSalesforceSettings
    }
  )
);

const Modal = ({
  showModal,
  hideModal = noop,
  saveSalesforceSettings = noop,
  data,
  error
}) => (
  <MiniModalWrapper
    hideModal={hideModal}
    isPortal={false}
    showModal={showModal}
    showHeader={false}
    style={{
      width: 450,
      backgroundColor: "white",
      height: "auto",
      maxHeight: "500px"
    }}
    bg="neutral0"
  >
    <Header title="Connect with Salesforce" hideModal={hideModal} />
    <Div
      width={1}
      height={1}
      p={4}
      display="column.flex-start.stretch"
      bb={1}
      bg="neutral0"
      bc="neutral1"
      shadow={1}
      style={{ overflowY: "auto", flex: "1 1 auto" }}
    >
      <Div width={1} display="column.flex-start.stretch" mb={4}>
        <Div mb={4}>
          You've successfully connected Fondi to your Salesforce instance. The
          next step is to install the Fondi app from the Salesforce AppExchange.{" "}
          <a
            href="https://learn.lennd.com/en/articles/5243994-connect-your-salesforce-integration"
            target="_blank"
            style={{
              textDecoration: "underline"
            }}
          >
            Click here
          </a>{" "}
          to learn how to configure your app.
        </Div>

        <FieldWrapper>
          <Label>API Username</Label>
          <Input
            disabled
            fieldId={FIELD_IDS.SALESFORCE.API_USERNAME}
            width={1}
            iniValue={R.prop(FIELD_IDS.SALESFORCE.API_USERNAME, data)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>API Password</Label>
          <Input
            disabled
            fieldId={FIELD_IDS.SALESFORCE.API_PASSWORD}
            width={1}
            iniValue={R.prop(FIELD_IDS.SALESFORCE.API_PASSWORD, data)}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Label>Saleforce Org ID</Label>
          <Input
            disabled
            fieldId={FIELD_IDS.SALESFORCE.SALESFORCE_ORG_ID}
            width={1}
            iniValue={R.prop(FIELD_IDS.SALESFORCE.SALESFORCE_ORG_ID, data)}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Label>Saleforce URL</Label>
          <Input
            disabled
            fieldId={FIELD_IDS.SALESFORCE.SALESFORCE_URL}
            width={1}
            iniValue={R.prop(FIELD_IDS.SALESFORCE.SALESFORCE_URL, data)}
          />
        </FieldWrapper>
      </Div>
    </Div>
    <Div
      bg="white"
      width={1}
      display="column.flex-start.stretch"
      p={4}
      bt={1}
      bc="neutral2"
    >
      {error && error.length ? (
        <Div
          bra={1}
          bg="danger3"
          p={1}
          mb={1}
          fs={1}
          color="black"
          display="row.center.center"
        >
          {error}
        </Div>
      ) : null}
      <BigFilledButton
        bg="altB5"
        color="white"
        width={1}
        display="row.center.center"
        onClick={saveSalesforceSettings}
      >
        Done
      </BigFilledButton>
    </Div>
  </MiniModalWrapper>
);

export default decorate(Modal);
