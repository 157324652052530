import PropTypes from "prop-types";
import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Loading from "components/Global/Loading";

const Retrieving = ({ title, message }) => (
  <StyleWrapper
    hideModal={() => {}}
    heading={title}
    width={300}
    containerStyles={{ overflowY: "visible" }}
  >
    <Loading content={message} />
  </StyleWrapper>
);

Retrieving.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default Retrieving;
