/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import * as R from "ramda";
import { getters as FormGetters } from "ui-kit/Form/model";
import { REQUEST_ITEM_SETTINGS_MODAL_FORM_ID } from "../constants";

const getAutoAssignedItems = function*({ payload: showLoading }) {
  try {
    if (showLoading) {
      yield put(actions.setLoadingAutoAssignItems(true));
    }
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/auto-assignment`
    });

    yield put(actions.setAssignedAutoItems(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading auto assigned items"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingAutoAssignItems(false));
  }
};

const updateAutoAssignedItems = function*({ payload }) {
  try {
    const selectedItems = yield select(getters.assignedAutoItems);

    const variantIds = R.compose(
      R.filter(id => R.path([id, "qty"])(payload)),
      R.keys
    )(payload);
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/auto-assignment`,
      data: {
        items: R.map(vId => {
          const selectedItem = R.find(R.propEq("id", vId), selectedItems);
          return {
            variantId: vId,
            quantity: `${R.propOr("1", "quantity", selectedItem)}`,
            status: R.propOr("pending", "status", selectedItem)
          };
        }, variantIds)
      }
    });

    yield all([
      put(actions.setShowSelectAutoAssignItemsModal(false)),
      put(actions.getAutoAssignedItems(true))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving settings"
        }
      ])
    );
  }
};

const removeGroupAutoAssignItem = function*({ payload: variantId }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const eventId = yield select(getEventId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/auto-assignment/variant/${variantId}`
    });

    yield put(actions.getAutoAssignedItems());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting item"
        }
      ])
    );
  }
};

const updateGroupItemData = function*() {
  const fields = yield select(FormGetters.fields, {
    instanceId: REQUEST_ITEM_SETTINGS_MODAL_FORM_ID
  });
  const quantity = R.pathOr(0, ["max_quantity", "value"], fields) || 0;
  const status = R.pathOr("pending", ["status", "value", 0], fields);

  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const variantId = yield select(getters.selectedModalId);
    const eventId = yield select(getEventId);

    yield put(actions.setLoadingAutoAssignItems(true));

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/auto-assignment/variant/${variantId}`,
      data: {
        quantity: quantity < 1 ? "1" : quantity.toString(),
        status
      }
    });

    yield all([
      put(actions.setShowUpdatePersonItemModal(false)),
      put(actions.getAutoAssignedItems(true))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating item"
        }
      ])
    );
  }
};

const watchUpdateAutoAssignedItems = function*() {
  yield takeEvery(
    actions.updateAutoAssignedItems.type,
    updateAutoAssignedItems
  );
};

const watchGetAutoAssignedItems = function*() {
  yield takeEvery(actions.getAutoAssignedItems.type, getAutoAssignedItems);
};

const watchRemoveGroupAutoAssignItem = function*() {
  yield takeEvery(
    actions.removeGroupAutoAssignItem.type,
    removeGroupAutoAssignItem
  );
};

const watchUpdateGroupItemData = function*() {
  yield takeEvery(actions.updateGroupItemData.type, updateGroupItemData);
};

const rootSaga = function*() {
  yield all([
    fork(watchUpdateAutoAssignedItems),
    fork(watchGetAutoAssignedItems),
    fork(watchRemoveGroupAutoAssignItem),
    fork(watchUpdateGroupItemData)
  ]);
};

export default rootSaga;
