import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="healthPass">
    <SubNavLink
      name="Manage"
      active={selectedView === TAB_IDS.MANAGE}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/health-pass/manage`
        })
      }
    />
    <SubNavLink
      name="Settings"
      active={[TAB_IDS.QUESTIONS, TAB_IDS.EMAILS, TAB_IDS.ASSIGN].includes(
        selectedView
      )}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/health-pass/settings/questionnaire`
        })
      }
    />
  </SubNavWrapper>
);

export default withRouter(SubNav);
