import {
  put,
  all,
  takeEvery,
  fork,
  take,
  call,
  select
} from "redux-saga/effects";
import { actions } from "Portal/PortalSession/model";
import * as R from "ramda";
import { PAGE_ACTIONS } from "./constants";
import { getCredentials } from "redux/modules/user/selectors";

import api from "./api";

import { registerError } from "redux/modules/errors/actions";

import mockdata from "./mockdata";

const init = function*({ payload: sessionId }) {
  yield put(actions.setLoading(true));

  const credentials = yield select(getCredentials);
  try {
    yield put(
      actions.setInitialData({
        ...mockdata.payload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting portal session"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const openLink = function*({ payload: { url } }) {
  yield call(window.open, url, "_blank");
};

const openAlert = function*() {
  // @TODO: When you click on it, should go to a page {PORTAL_URL}/alerts (not developed yet)
};

const openSession = function*() {
  // @TODO: When you click on it, open session
};

const openConference = function*({ payload: { conference_url } }) {
  yield call(window.open, conference_url, "_blank");
};

const openReplay = function*({ payload: { replay_url } }) {
  yield call(window.open, replay_url, "_blank");
};

const openTranscript = function*({ payload: { transcript_url } }) {
  yield call(window.open, transcript_url, "_blank");
};

const watchPageActions = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.type, {
      [PAGE_ACTIONS.OPEN_LINK]: openLink,
      [PAGE_ACTIONS.OPEN_ALERT]: openAlert,
      [PAGE_ACTIONS.OPEN_SESSION]: openSession,
      [PAGE_ACTIONS.OPEN_CONFERENCE]: openConference,
      [PAGE_ACTIONS.OPEN_REPLAY]: openReplay,
      [PAGE_ACTIONS.OPEN_TRANSCRIPT]: openTranscript
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchPageActions)]);
};

export default rootSaga;
