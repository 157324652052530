import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import KanbanView from "./KanbanView";
import { withRouter } from "react-router";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { addReference } from "redux/modules/entityReferences/actions";
import { addField } from "redux/modules/modules/fields/actions";
import { updateView } from "redux/modules/modules/views/actions";
import { updateTableLayout } from "redux/modules/modules/records/settings/actions";
import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { tableLayoutOptions } from "redux/modules/modules/records/settings/selectors";
import { activeView } from "redux/modules/modules/views/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state, props) {
  const view = activeView(state, props.moduleId, props.params.viewId);

  return {
    user: user(state),
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    kanbanOptions: tableLayoutOptions(state, props.moduleId),
    activeView: view,
    groupedByField: props.fields.find(f => f.id === view.grouped_by)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      addReference,
      updateTableLayout,
      addField,
      updateView,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(KanbanView)
);
