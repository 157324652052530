export const NAMESPACE = "OrganizationHome";

export const CONTENT_WIDTH = 880;

export const LEFT_COLUMN_WIDTH = 307;

export const RIGHT_COLUMN_WIDTH = 558;

export const TABS = {
  ASSIGNED_TO_ME: "assigned_to_me",
  DUE: "due",
  COMPLETED: "completed"
};

export const EVENT_TABS = {
  UPCOMING: "upcoming",
  PAST: "past"
};

export const EVENT_OPTIONS = {
  LIST: "list",
  CALENDAR: "calendar"
};
