/* eslint-disable no-underscore-dangle */
import React from "react";

import {
  put,
  call,
  all,
  fork,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";
import * as R from "ramda";
import { addS } from "utils/General";
import { push } from "react-router-redux";

import { actions, getters } from "EventLight/FileRequests/Results";
import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";
import { actions as TableActions } from "ui-kit/Table/model";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { VIEWPICKER_INSTANCE_ID } from "EventLight/FileRequests/Results/constants";

import { getCredentials } from "redux/modules/user/selectors";
import { getAllRecordReferences } from "App/Data/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import {
  TABLE_INSTANCE_ID,
  ROW_ACTIONS
} from "EventLight/FileRequests/Results/constants";
import { ROW_ACTIONS as TABLE_ROW_ACTIONS } from "ui-kit/Table/constants";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import addValueApi from "redux/modules/modules/values/api";
import recordApi from "redux/modules/modules/records/api";
import { getFieldsForEditModal } from "../selectors";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmail from "SendEmailModal/View";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import SubscribeModal from "Modules/SubscribeModal/View";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";

import { registerError } from "redux/modules/errors/actions";

import { review } from "App/Data/sagas";

const sendEmail = function*({
  payload: { row, selectedOptions, subject = null, message = null }
}) {
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: (
        <SendEmail
          moduleId={moduleId}
          records={[row.id]}
          subject={subject}
          message={message}
          selectedOptions={
            selectedOptions || moduleId === STANDARD_MODULE_IDS.accounts.id
              ? [SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS]
              : moduleId === STANDARD_MODULE_IDS.contacts.id
              ? [SEND_TO_OPTION_TYPES.EMAIL_FIELD]
              : null
          }
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showBulkEditModal = function*({ toggledRows, fields, references }) {
  const handleEdit = makeFuture();

  yield put(
    showModal({
      content: (
        <BulkEdit
          selected={toggledRows}
          getMetaData={(rowData, column) => ({
            meta: {
              columnId: column.id,
              columnSettings: column.settings,
              references
            },
            helpers: {}
          })}
          columns={fields}
          onSave={handleEdit.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleEdit.onRealized);
};

const bulkEdit = function*({ payload: { toggledRows } }) {
  const credentials = yield select(getCredentials);
  const references = yield select(getAllRecordReferences);
  const fields = yield select(getFieldsForEditModal);
  const rows = yield select(getters.rows);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);
  const moduleId = yield select(getters.moduleId);

  const result = yield call(showBulkEditModal, {
    toggledRows,
    fields,
    references
  });

  if (!result) {
    return false;
  }

  let fieldIdToUse = result.fieldId;

  const values = R.map(recordId => {
    const column = R.find(R.propEq("id", fieldIdToUse))(fields);
    const row = R.find(R.propEq("id", recordId))(rows);
    let recordIdToUse = row.submission_record_id;

    if (column.settings.isReferenceField) {
      fieldIdToUse = column.settings.referenceFieldId;
      recordIdToUse = R.path([
        column.settings.lookupFieldId,
        "value",
        "records",
        0
      ])(row);
    }

    return {
      recordId: recordIdToUse,
      value: result.value,
      fieldId: fieldIdToUse
    };
  })(toggledRows);

  yield call(addValueApi.post, credentials, {
    orgId,
    eventId,
    fieldId: result.fieldId,
    moduleId,
    bulk: true,
    values
  });

  yield put(
    showSnackbar({
      message: `Record${toggledRows.length !== 1 ? "s" : ""} updated`,
      action: "OK"
    })
  );

  yield put(actions.fetchData());
};

const showDeleteOrRemoveConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteOrRemoveConfirmation
          countOfSelected={1}
          heading={`Remove Record?`}
          onRemove={() => handleDelete.done(true)}
          onDelete={() => handleDelete.done(false)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const showDeleteConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this record?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(false)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const deleteRecord = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);
  const moduleId = yield select(getters.moduleId);
  let removeOnly = false;

  if (
    !orgId &&
    [STANDARD_MODULE_IDS.accounts.id, STANDARD_MODULE_IDS.contacts.id].includes(
      moduleId
    )
  ) {
    removeOnly = yield call(showDeleteOrRemoveConfirmation);
  } else {
    yield call(showDeleteConfirmation);
  }

  yield call(recordApi.delete, credentials, {
    moduleId,
    orgId,
    eventId,
    record: { id: row.id },
    options: {
      orgId,
      eventId,
      removeOnly
    }
  });

  yield put(
    showSnackbar({
      message: `Record ${removeOnly ? "removed" : "deleted"}`,
      action: "OK"
    })
  );

  yield put(actions.fetchData());
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );
};

const showFileRequestModal = function*({ row }) {
  const handleClose = makeFuture();

  yield put(
    showModal({
      content: (
        <DocumentRequestModal
          view="admin"
          recordId={row.id}
          hideModal={() => handleClose.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleClose.onRealized);
};

const openRecord = function*({ payload: { row } }) {
  const result = yield call(showFileRequestModal, { row });
  if (result) {
    yield put(hideModal());
    yield put(actions.fetchData());
  }
};

const showDeleteSubmissionModal = function*(toggledRows) {
  const handleSubmit = makeFuture();

  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading={`Delete Record${addS(toggledRows.length)}?`}
          message={
            <div>
              {`
                    Are you sure you want to remove
                    ${
                      toggledRows.length === 1
                        ? "this"
                        : `these ${toggledRows.length}`
                    }
                    submission${addS(toggledRows.length)}?
                    `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleSubmit.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleSubmit.onRealized);
};

const deleteRecords = function*({ payload: recordIds }) {
  const credentials = yield select(getCredentials);
  const shouldDelete = yield call(showDeleteSubmissionModal, recordIds);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);
  const moduleId = yield select(getters.moduleId);

  if (shouldDelete) {
    yield call(recordApi.delete, credentials, {
      moduleId,
      orgId,
      eventId,
      records: recordIds,
      bulk: true,
      options: {
        orgId,
        eventId,
        removeOnly: false
      }
    });

    yield put(
      showSnackbar({
        message: `Record${addS(recordIds.length)} deleted`,
        action: "OK"
      })
    );

    yield put(actions.fetchData());
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [TABLE_ROW_ACTIONS.OPEN_RECORD]: openRecord,
      [ROW_ACTIONS.VIEW_RECORD]: openRecord,
      [ROW_ACTIONS.SEND_EMAIL]: sendEmail
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const showSubscribeModal = function*() {
  const fileRequestId = yield select(getters.fileRequestId);
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  yield put(
    showModal({
      content: (
        <SubscribeModal
          type="fileRequest"
          fileRequestId={fileRequestId}
          viewId={activeViewId}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const goToAllFileRequests = function*() {
  const eventId = yield select(getEventId);

  yield put(push(`/event-light/${eventId}/file-requests`));
};

const watchReview = function*() {
  for (;;) {
    const { payload, type, meta } = yield take([
      actions.approve.type,
      actions.deny.type
    ]);
    const response = R.propOr(null, type, {
      [actions.approve.type]: "approve",
      [actions.deny.type]: "reject"
    });
    try {
      const { success } = yield call(review, {
        response,
        targetType: "recordId",
        recordIds: payload.recordIds
      });

      yield put(actions.fetchData({ useSilentRefresh: true }, { meta }));
      if (success) {
        yield put(showSnackbar({ message: "Review finished" }));
      }
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred approving line items"
          }
        ])
      );
    }
  }
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const watchShowDeleteSubmissionModal = function*() {
  yield takeEvery(actions.showDeleteSubmissionModal.type, deleteRecords);
};

const watchGoToAllFileRequests = function*() {
  yield takeEvery(actions.goToAllFileRequests.type, goToAllFileRequests);
};

const watchShowBulkEditModal = function*() {
  yield takeEvery(actions.showBulkEditModal.type, bulkEdit);
};

const rootSaga = function*() {
  yield all([
    fork(watchTableActions),
    fork(watchShowSubscribeModal),
    fork(watchShowDeleteSubmissionModal),
    fork(watchGoToAllFileRequests),
    fork(watchShowBulkEditModal),
    fork(watchReview)
  ]);
};

export default rootSaga;
