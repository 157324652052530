import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ContactBadge extends Component {
  render() {
    return (
      <div styleName="container">
        <div styleName="avatar">
          <i className="material-icons" styleName="avatarIcon">
            person
          </i>
        </div>
        <div styleName="info">
          <div styleName="name">
            <div styleName="nameText">{this.props.user.fname}</div>
          </div>
          <div styleName="email">{this.props.user.email}</div>
        </div>
      </div>
    );
  }
}

ContactBadge.propTypes = {
  user: PropTypes.object.isRequired
};

export default ContactBadge;
