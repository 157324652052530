import React from "react";
import Body from "./Body";
import ConnectModal from "./ConnectModal";
import MappingModal from "./MappingModal";

import { Page, Title } from "EventLight/Common/FieldLayout";
import PageTitle from "EventLight/Common/PageTitle";

const Layout = ({ handlers }) => (
  <>
    <PageTitle titles={["Integrations", "Fuzion"]} />
    <Page>
      <Title
        icon="sync_alt"
        title="Fuzion"
        description="Connect with other event management tools"
      />

      <Body handlers={handlers} />
    </Page>
    <ConnectModal />
    <MappingModal />
  </>
);

export default Layout;
