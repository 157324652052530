import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "Forms/WizardModal";
import Header from "Forms/WizardModal/View/Header";
import {
  Div,
  SuperOutlineButton,
  Text2,
  Text3,
  Text6,
  BigFilledButton,
  AddCircleIcon
} from "components/Base";
import SelectBox from "Forms/WizardModal/View/Common/SelectBox";
import { getApplicationTypesTitle } from "Forms/WizardModal/selectors";

const decorate = connect(
  state => ({
    recordTypes: getters.recordTypes(state),
    headerText: getApplicationTypesTitle(state)
  }),
  {
    getAccountsAndContactsFields: actions.getAccountsAndContactsFields
  }
);

const SelectRecordTypes = ({
  handlers,
  back,
  recordTypes,
  headerText,
  steps = ["Step 1", "Step 2", "Step 3", "Step 4"],
  getAccountsAndContactsFields
}) => (
  <Div width={1} height={1}>
    <Header steps={steps} handlers={handlers} />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      style={{ height: "calc(100% - 157px)", overflow: "auto" }}
    >
      <Text6 bold color="black">
        {headerText.title}
      </Text6>
      <Div textAlign="center" width={630} mt={2}>
        <Text2>{headerText.description}</Text2>
      </Div>
      <Div width={1} mt={6}>
        {R.map(
          recordType => (
            <SelectBox
              {...recordType}
              key={recordType.id}
              onClick={() => {
                getAccountsAndContactsFields({
                  recordTypeId: recordType.id,
                  recordTypeName: recordType.name
                });
              }}
            />
          ),
          recordTypes
        )}
      </Div>
      <Div display="column.flex-start.center" p={4}>
        <Text3 color="neutral6" bold>
          Don't see the type you
        </Text3>
        <Text3 color="neutral6" bold>
          need to accept submissions for?
        </Text3>
        <BigFilledButton
          mt={2}
          color="neutral7"
          bg="neutral2"
          LeftIcon={AddCircleIcon}
          onClick={() => handlers.showRecordTypeModal()}
        >
          Add another type
        </BigFilledButton>
      </Div>
    </Div>
    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      p={5}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperOutlineButton onClick={handlers.hideModal}>
        Cancel
      </SuperOutlineButton>
    </Div>
  </Div>
);

export default decorate(SelectRecordTypes);
