import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { liftToArr } from "utils/General";
import { NAMESPACE } from "./constants";

const model = createHandlers({
  iniState: {
    selectedDays: [],
    mealStep: 0,
    mealItems: {},
    mealItemsOrder: [],
    mealDaysDiff: [],
    updatedDietaryRestrictions: {},
    removedDietaryRestrictions: [],
    passwordFieldIsActive: false,
    password: ""
  },
  reducers: {
    nextMealStep: ({ mealStep }) => ({
      mealStep: mealStep + 1 > 4 ? mealStep : mealStep + 1
    }),
    nextMealStepResponse: R.identity,
    prevMealStep: ({ mealStep }) => ({
      mealStep: mealStep - 1 < 0 ? mealStep : mealStep - 1
    }),
    setMealItems: (_, { payload: items }) => ({
      mealItems: R.indexBy(R.prop("id"), items),
      mealItemsOrder: R.map(R.prop("id"), R.sortBy(R.prop("order"), items))
    }),
    editMeal: R.identity,
    editMealResponse: ({ mealItems }, { payload: data }) => ({
      mealItems: R.assoc(data.id, { ...mealItems[data.id], ...data }, mealItems)
    }),
    addMeal: R.identity,
    cloneMeal: R.identity,
    cloneMealResponse: ({ mealItems, mealItemsOrder }, { payload: data }) => ({
      mealItems: R.assoc(data.id, data, mealItems),
      mealItemsOrder: R.append(data.id, mealItemsOrder)
    }),
    deleteMeal: R.identity,
    deleteMealResponse: ({ mealItems, mealItemsOrder }, { payload: id }) => ({
      mealItems: R.omit([id], mealItems),
      mealItemsOrder: R.without([id], mealItemsOrder)
    }),
    moveMealDown: (state, { payload: id }) => {
      const index = R.findIndex(R.equals(id), state.mealItemsOrder);
      if (index === -1 || index + 1 === R.length(state.mealItemsOrder)) {
        return state;
      }

      return {
        mealItemsOrder: [
          ...state.mealItemsOrder.slice(0, index),
          state.mealItemsOrder[index + 1],
          state.mealItemsOrder[index],
          ...state.mealItemsOrder.slice(index + 2)
        ]
      };
    },
    moveMealUp: (state, { payload: id }) => {
      const index = R.findIndex(R.equals(id), state.mealItemsOrder);
      if (index < 1) {
        return state;
      }

      return {
        mealItemsOrder: [
          ...state.mealItemsOrder.slice(0, index - 1),
          state.mealItemsOrder[index],
          state.mealItemsOrder[index - 1],
          ...state.mealItemsOrder.slice(index + 1)
        ]
      };
    },
    daysToggle: ({ selectedDays }, { payload: days }) => ({
      selectedDays: R.symmetricDifference(selectedDays, liftToArr(days))
    }),
    initSelectedDays: (state, { payload: days }) => ({
      selectedDays: liftToArr(days)
    }),
    groupDaysSelect: ({ selectedDays }, { payload: days }) => ({
      selectedDays: R.uniq(R.concat(selectedDays, days))
    }),
    groupDaysDeselect: ({ selectedDays }, { payload: days }) => ({
      selectedDays: R.without(days, selectedDays)
    }),
    toggleMealDay: ({ mealDaysDiff }, { payload }) => ({
      mealDaysDiff: R.symmetricDifferenceWith(
        (a, b) => a.mealId === b.mealId && a.dayId === b.dayId,
        mealDaysDiff,
        [payload]
      )
    }),
    removeDietaryRestriction: (
      { removedDietaryRestrictions },
      { payload: id }
    ) => ({
      removedDietaryRestrictions: R.uniq(
        R.append(id, removedDietaryRestrictions)
      )
    }),
    updateDietaryRestriction: (
      { updatedDietaryRestrictions },
      { payload: option }
    ) => ({
      updatedDietaryRestrictions: R.propEq("value", "", option)
        ? updatedDietaryRestrictions
        : R.assoc(option.id, option, updatedDietaryRestrictions)
    }),
    finishMealsSetup: R.identity,
    addItemBlock: R.identity,
    savePassword: R.identity,
    disablePassword: R.identity,
    init: R.identity,
    backToFirstStep: R.always({
      mealStep: 0,
      mealDaysDiff: [],
      updatedDietaryRestrictions: {},
      removedDietaryRestrictions: []
    }),
    savePasswordResponse: R.always({
      password: "",
      passwordFieldIsActive: false
    }),
    cancelPassword: R.always({
      password: "",
      passwordFieldIsActive: false
    }),
    editPassword: R.always({
      passwordFieldIsActive: true
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
