import React from "react";
import { connect } from "react-redux";

import { getGroupByOptions } from "EventLight/Common/Dashboard/selectors";

import { GROUP_BY_FILTER_ID } from "Passes/Common/constants";

import { MultipleFilter } from "ui-kit/FilterControls/View";

import { ThreeCirclesIcon, Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  options: getGroupByOptions(state, props)
}));

const GroupBy = ({ options = [], ...styleProps }) => (
  <MultipleFilter
    options={options}
    filtersOn
    instanceId={GROUP_BY_FILTER_ID}
    {...styleProps}
  >
    <ThreeCirclesIcon size={16} mr={2} />
    <Text2 bold>Group By</Text2>
  </MultipleFilter>
);

export default decorate(GroupBy);
