import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Popover from "@lennd/material-ui/Popover";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const DEFAULT_ICON_GROUP = [
  "business",
  "mic",
  "home",
  "music_note",
  "build",
  "stars",
  "business_center",
  "local_shipping",
  "restaurant",
  "group"
];

@CSSModules(css)
class IconSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({ showPopover: true });
  }

  @autobind
  onRequestClose() {
    this.setState({ showPopover: false });
  }

  selectIcon(icon) {
    this.onRequestClose();
    this.props.selectIcon(icon);
  }

  render() {
    const { iconGroup } = this.props;
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div
            styleName="container"
            style={{
              width: Math.ceil(iconGroup.length / 5) * 40
            }}
          >
            {iconGroup.map(icon => (
              <div
                key={icon}
                styleName="iconWrapper"
                onClick={() => this.selectIcon(icon)}
              >
                <i className="material-icons">{icon}</i>
              </div>
            ))}
          </div>
        </Popover>
      </div>
    );
  }
}

IconSelector.defaultProps = {
  iconGroup: DEFAULT_ICON_GROUP
};

IconSelector.propTypes = {
  selectIcon: PropTypes.func.isRequired,
  iconGroup: PropTypes.arrayOf(PropTypes.string)
};

export default IconSelector;
