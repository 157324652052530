import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Portal/Settings/AddVideoLinkModal/model";
import { isAddBtnDisabled } from "Portal/Settings/AddVideoLinkModal/selectors";
import {
  Div,
  Text2,
  MediumOutlineInput,
  MediumFilledButton,
  MediumOutlineButton,
  UploadIcon
} from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const decorate = connect(
  state => ({
    title: getters.title(state),
    url: getters.url(state),
    thumbnailImageUrl: getters.thumbnailImageUrl(state),
    addBtnDisabled: isAddBtnDisabled(state)
  }),
  {
    setTitle: actions.setTitle,
    setUrl: actions.setUrl,
    uploadThumbnailImage: actions.uploadThumbnailImage,
    init: actions.init
  }
);

const AddVideoLinkModal = ({
  hideModal,
  title,
  url,
  setTitle,
  setUrl,
  thumbnailImageUrl,
  uploadThumbnailImage,
  isLink,
  addBtnDisabled,
  init
}) => {
  useEffect(() => {
    init();
  }, []);
  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title={`Add ${isLink ? "Link" : "Video"}`}
      style={{ width: 320, height: 380 }}
      isPortal={false}
      bg="white"
      stopPropagation
      zIndex={101}
    >
      <Div display="column.space-between" p={5} height={1}>
        <div>
          <div>
            <Text2 color="black" bold mb={1}>
              {isLink ? "Link" : "Video"} Title
            </Text2>
            <MediumOutlineInput
              width={1}
              value={title}
              onChange={value => setTitle(value)}
              continuous
            />
          </div>
          <div style={{ marginTop: 12 }}>
            <Text2 color="black" bold mb={1}>
              {isLink ? "Link" : "Video Embeded"} URL
            </Text2>
            <MediumOutlineInput
              width={1}
              value={url}
              onChange={value => setUrl(value)}
              continuous
            />
          </div>
          <div style={{ marginTop: 12 }}>
            <Text2 color="black" bold mb={1}>
              Upload Thumbnail Image
            </Text2>
            <Div
              className="hoverContainer"
              onClick={uploadThumbnailImage}
              bra={2}
              width={150}
              height={75}
              bg="neutral2"
              style={{
                flexShrink: 0,
                backgroundImage: thumbnailImageUrl
                  ? `url("${thumbnailImageUrl}")`
                  : "inherit",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top center",
                backgroundSize: "cover"
              }}
              display="row.center.center"
            >
              <UploadIcon color="white" size={30} className="showOnHover" />
            </Div>
          </div>
        </div>
        <Div display="row.space-between.center">
          <MediumFilledButton
            color="white"
            bg="altB5"
            width={200}
            disabled={addBtnDisabled}
          >
            Add {isLink ? "Link" : "Video"}
          </MediumFilledButton>
          <MediumOutlineButton width={75} onClick={hideModal}>
            Cancel
          </MediumOutlineButton>
        </Div>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(AddVideoLinkModal);
