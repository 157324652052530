import React, { PureComponent } from "react";
import { Div } from "components/Base";

const Wrapper = ({ children }) => (
  <Div
    display="row.flex-start.center"
    minHeight={23}
    style={{
      overflow: "hidden",
      userSelect: "none"
    }}
  >
    {children}
  </Div>
);

class ItemBlockQuantity extends PureComponent {
  render() {
    const { quantity } = this.props;
    return <Wrapper>{quantity}</Wrapper>;
  }
}

export default ItemBlockQuantity;
