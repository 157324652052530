import React, { Component } from "react";
import {
  Div,
  MediumOutlineButton,
  CloseIcon,
  Text2,
  AttachIcon
} from "components/Base";
import FileIcon from "components/Atoms/FileIcon";

import * as R from "ramda";
import Helpers from "utils/Global/Helpers";
import { openInNewWindow } from "utils/General";

const MAX_NUMBER_OF_FILES = 5;
const MAX_FILES_SIZE = 20 * 1000 * 1000;

class Attachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.attachments
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.attachments !== state.files) {
      return {
        files: props.attachments
      };
    }
    return null;
  }

  removeFile = file => {
    this.props.updateAttachments(
      R.reject(R.propEq("uploadId", file.uploadId), this.state.files)
    );
  };

  openFile = url => openInNewWindow(url);

  getFilePicker = event => {
    const { files } = this.state;
    event.preventDefault();
    const options = {
      multiple: true,
      accept: [],
      maxSize: MAX_FILES_SIZE,
      maxFiles: MAX_NUMBER_OF_FILES - files.length,
      fromSources: ["local_file_system", "dropbox"]
    };

    const path = { path: "email-attachments/" };

    const callback = filesAdded => {
      this.props.updateAttachments([...files, ...filesAdded]);
    };

    return Helpers.getFilepicker(options, path, callback);
  };

  getFilesSize() {
    const { files } = this.state;
    return files.reduce((acc, curr) => {
      acc += curr.size;
      return acc;
    }, 0);
  }

  isExceedingMaxFilesSize() {
    return this.getFilesSize() > MAX_FILES_SIZE;
  }

  isExceedingMaxFilesNumber() {
    const { files } = this.state;
    return files.length >= MAX_NUMBER_OF_FILES;
  }

  isUploadButtonDisabled() {
    return this.isExceedingMaxFilesSize() || this.isExceedingMaxFilesNumber();
  }

  render() {
    const { files } = this.state;
    const isExceedingFileSize = this.isExceedingMaxFilesSize();
    const isUploadButtonDisabled = this.isUploadButtonDisabled();

    return (
      <Div>
        <Div display="row.flex-start.center" mt={4}>
          <MediumOutlineButton
            LeftIcon={AttachIcon}
            onClick={this.getFilePicker}
            mr={4}
            disabled={isUploadButtonDisabled}
          >
            Attach Files
          </MediumOutlineButton>
          <Text2>
            <span
              style={{
                color: isExceedingFileSize ? "red" : "inherit",
                fontWeight: isExceedingFileSize ? "bold" : "inherit"
              }}
            >
              {Helpers.formatSizeUnits(this.getFilesSize())}
            </span>{" "}
            of {Helpers.formatSizeUnits(MAX_FILES_SIZE)} Limit
          </Text2>
        </Div>

        <Div mt={4}>
          {files.map(file => {
            return (
              <Div
                key={file.uploadId.toString()}
                mt={3}
                bg="neutral0"
                style={{ padding: 10, borderRadius: 6 }}
                display="row.space-between.center"
              >
                <Div display="row">
                  <Div>
                    <FileIcon size={15} mimetype={file.mimetype} />
                  </Div>
                  <Div
                    truncate
                    ml={3}
                    maxWidth={200}
                    color="info8"
                    onClick={() => this.openFile(file.url)}
                  >
                    {file.filename}
                  </Div>
                  <Div ml={3}>({Helpers.formatSizeUnits(file.size)})</Div>
                </Div>

                <CloseIcon
                  onClick={() => {
                    this.removeFile(file);
                  }}
                />
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }
}

export default Attachments;
