import { createSelector } from "reselect";
import { getters } from "Orders/ImportModal";

export const getConfirmationCounters = createSelector(
  getters.importConfirmationCounters,
  counters => ({
    rowsProcessed: counters.rows_number,
    accountsCreated: counters.accounts_created,
    existingAccounts: counters.existing_accounts,
    contactsCreated: counters.contacts_created,
    existingContacts: counters.existing_contacts,
    ordersCreated: counters.orders,
    orderVariantsCreated: counters.order_line_items,
    skippedRows: counters.rows_number - counters.rows_ok
  })
);
