import React from "react";
import { connect } from "react-redux";

import { Div, FontIcon } from "components/Base";

import Circle from "ui-kit/Circle";
import CollapseButton from "Schedules/Schedule/View/Sidebar/CollapseButton";

import { getters } from "Schedules/Schedule";

const decorate = connect(state => ({
  schedule: getters.schedule(state)
}));

const Header = ({ schedule }) => (
  <Div display="row.flex-start.flex-start" width={1} mb={5}>
    {schedule.id ? (
      <Div display="row.flex-start.center" pt={3} flex={1}>
        <Circle
          size={32}
          style={{
            backgroundColor: schedule.color,
            flexShrink: 0
          }}
        >
          <FontIcon
            fs={3}
            style={{
              color: "white"
            }}
          >
            {schedule.icon}
          </FontIcon>
        </Circle>
        <Div color="white" fw={3} fs={5} ml={2} mr={2} data-cy="module-title">
          {schedule.name}
        </Div>
      </Div>
    ) : (
      <Div height={64} flex={1} color="transparent">
        a
      </Div>
    )}
    <CollapseButton />
  </Div>
);

export default decorate(Header);
