import React from "react";
import { connect } from "react-redux";
import { getters } from "../model";

import Loading from "ui-kit/Loading";
import Body from "./Body";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading }) => {
  return loading ? <Loading /> : <Body />;
};

export default decorate(Layout);
