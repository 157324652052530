import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import Checkbox from "material-ui/Checkbox";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import QuantityInput from "./QuantityInput";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Footer from "../Footer";
import { NONE } from "utils/system-field-values";

const styles = {
  label: {
    flex: 1,
    color: "#424242",
    fontSize: "16px",
    fontWeight: 600,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginTop: "3px"
  },
  credential: {
    padding: "5px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

@CSSModules(css)
class CredentialEditorList extends Component {
  createIconStyle(background) {
    const style = {
      marginTop: "1.5px",
      marginRight: "8px",
      fill: background
    };
    if (background === "#FFFFFF") {
      return {
        ...style,
        stroke: "#ccc",
        strokeWidth: "1px"
      };
    }
    return style;
  }

  renderQuantity(options) {
    const radios = options.map(credential => {
      const value = this.props.selectedCredentials.find(
        selected => selected.id === credential.id
      );

      return (
        <li key={credential.id} style={styles.credential}>
          <div className={css.checkboxWithQuantity}>
            <Checkbox
              style={{
                flex: 1,
                display: "flex"
              }}
              checked={value}
              value={value ? 0 : 1}
              onCheck={e =>
                !this.props.disabled
                  ? this.props.updateQuantity(credential, e.target.value)
                  : undefined
              }
              label={credential.value}
              labelStyle={styles.label}
              iconStyle={this.createIconStyle(credential.backgroundColor)}
              disabled={this.props.disabled}
            />
          </div>
          <QuantityInput
            onChange={this.props.updateQuantity}
            credential={credential}
            quantity={value ? value.quantity : 0}
            disabled={this.props.disabled}
          />
        </li>
      );
    });

    return <ul styleName="credentialList">{radios}</ul>;
  }

  renderMultiple(options) {
    const radios = options.map(credential => {
      const checked =
        this.props.selectedCredentials.find(
          selected => selected.id === credential.id
        ) || 0;

      return (
        <Checkbox
          style={{
            ...styles.credential
          }}
          checked={checked}
          value={checked ? 0 : 1}
          onCheck={e =>
            !this.props.disabled
              ? this.props.updateQuantity(credential, e.target.value)
              : undefined
          }
          label={credential.value}
          labelStyle={styles.label}
          iconStyle={this.createIconStyle(credential.backgroundColor)}
          disabled={this.props.disabled}
        />
      );
    });

    return <div styleName="credentialList">{radios}</div>;
  }

  renderSingle(options) {
    const selectedId = get(this.props, "selectedCredentials[0].id", null);
    const selected = options.find(c => c.id === selectedId);
    const radios = options.map(credential => (
      <RadioButton
        style={{
          ...styles.credential
        }}
        value={credential}
        label={credential.value}
        labelStyle={styles.label}
        iconStyle={this.createIconStyle(credential.backgroundColor)}
        disabled={this.props.disabled}
      />
    ));

    return (
      <RadioButtonGroup
        valueSelected={selected}
        onChange={(event, value) =>
          !this.props.disabled
            ? this.props.updateSingleCredential(value)
            : undefined
        }
        className={css.credentialList}
      >
        {radios}
      </RadioButtonGroup>
    );
  }

  renderCredentials(type, options) {
    switch (type) {
      case "quantity":
        return this.renderQuantity(options);
      case "single":
        return this.renderSingle(options);
      case "multiple":
        return this.renderMultiple(options);
      default:
        return "";
    }
  }

  render() {
    const {
      customWidth,
      disabled,
      onClose,
      onSelectNone,
      options,
      selectType,
      selectedCredentials
    } = this.props;

    const showQuantity = selectType === "quantity";

    return (
      <div
        styleName="credentialSelectBox"
        style={{
          width: customWidth
        }}
      >
        {showQuantity ? (
          <div styleName="heading">
            <span>Types</span>
            <span>QTY per person</span>
          </div>
        ) : (
          ""
        )}
        {!options.length ? (
          <div styleName="empty">No available passes</div>
        ) : (
          this.renderCredentials(selectType, options)
        )}
        <Footer
          onClose={onClose}
          onSelectNone={onSelectNone}
          isNone={selectedCredentials.some(c => c.id === NONE.id)}
          disabled={disabled}
        />
      </div>
    );
  }
}

CredentialEditorList.propTypes = {
  updateQuantity: PropTypes.func.isRequired,
  updateSingleCredential: PropTypes.func.isRequired,
  selectedCredentials: PropTypes.object.isRequired,
  onSelectNone: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectType: PropTypes.string.isRequired,
  customWidth: PropTypes.number
};

export default CredentialEditorList;
