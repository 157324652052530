import PropTypes from "prop-types";
import React, { Component } from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import "styles/less/style_v2.less";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import LenndMuiTheme from "components/Global/Themes/LenndMuiTheme";
import PlatformLoading from "components/Platform/PlatformLoading";

import { ConfigureFlopFlip } from "@flopflip/react-redux";
import adapter from "@flopflip/launchdarkly-adapter";

const shouldDeferAdapterConfiguration = ({
  routes,
  userId,
  orgId,
  eventId
}) => {
  const isEventRoute = routes.find(r =>
    [
      "editEventProfile",
      "PortalV2_LoggedInView",
      "PortalV2_LoggedOutView",
      "eventLightGate"
    ].includes(r.name)
  );
  const isOrgRoute = routes.find(r =>
    ["organization", "OrgLightGate", "formsV3_Edit"].includes(r.name)
  );

  if (isEventRoute && isOrgRoute) {
    return !userId || !orgId || !eventId;
  } else if (isEventRoute) {
    return !userId || !eventId;
  } else if (isOrgRoute) {
    return !userId || !orgId;
  }
  return !userId;
};

require("es6-promise").polyfill();
require("url-search-params-polyfill");
require("classlist-polyfill");

@DragDropContext(HTML5Backend)
class View extends Component {
  componentDidMount() {
    this.props.checkSession();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // @NOTE: Since we're an SPA, we need to let AppCues know when
    // a page change has occurred
    /*
    if (window.Appcues) {
      window.Appcues.start();
    }
    */
  }

  render() {
    // prepare LaunchDarkly payload
    const { user, orgDetails, eventDetails, routes } = this.props;
    const userId = user.id ? parseInt(user.id, 10) : null;
    const currentOrgId = eventDetails.org_id || orgDetails.id;
    const currentEventId = eventDetails.id
      ? parseInt(eventDetails.id, 10)
      : null;
    const adapterArgs = {
      // eslint-disable-next-line no-underscore-dangle
      clientSideId: window.__LAUNCHDARKLY_API_KEY__,
      user: {
        key: user.email,
        firstName: user.fname,
        lastName: user.lname,
        email: user.email,
        avatar: user.photoUrl,
        custom: {
          userId,
          currentOrgId,
          currentEventId
        }
      }
    };

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(LenndMuiTheme)}>
        <ConfigureFlopFlip
          adapter={adapter}
          adapterArgs={adapterArgs}
          shouldDeferAdapterConfiguration={shouldDeferAdapterConfiguration({
            routes,
            userId: adapterArgs.user.custom.userId,
            orgId: adapterArgs.user.custom.currentOrgId,
            eventId: adapterArgs.user.custom.currentEventId
          })}
        >
          {this.props.isSessionCheckComplete ? (
            this.props.children
          ) : (
            <PlatformLoading />
          )}
        </ConfigureFlopFlip>
      </MuiThemeProvider>
    );
  }
}

View.propTypes = {
  children: PropTypes.element
};

export default View;
