import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  pagePayload: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (_, { payload }) => ({
      pagePayload: payload
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
