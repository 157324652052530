import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/RadioEditor";
import Formatter from "../CellFormatters/Text";

export default class InventoryListEditor extends EditorBase {
  render() {
    const { rowMetaData } = this.props;

    return (
      <Cell
        ref="editor"
        formatter={
          <Formatter value={this.state} dependentValues={rowMetaData} />
        }
        editor={
          <Editor
            {...this.props}
            value={this.state}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}

InventoryListEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired
};
