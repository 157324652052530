import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import View from "./View";

class AddEventModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.eventName ? this.props.eventName : "",
      startDate: this.props.startDate || moment(new Date()),
      endDate: this.props.endDate || moment(new Date())
    };
  }

  handleNameChange = name => {
    this.setState({ name });
  };

  handleStartDateChange = startDate => {
    let endDate = this.state.endDate;

    if (!endDate || endDate.startOf("day").isBefore(startDate)) {
      endDate = startDate;
    }

    this.setState({ startDate, endDate });
  };

  handleEndDateChange = endDate => {
    let startDate = this.state.endDate;

    if (!startDate || endDate.startOf("day").isBefore(startDate)) {
      startDate = endDate;
    }

    this.setState({ startDate, endDate });
  };

  handleSave = () => {
    const data = {
      orgId: this.props.orgId,
      name: this.state.name,
      startDate: this.state.startDate.startOf("day").format(),
      endDate: this.state.endDate.startOf("day").format()
    };
    this.props.handleCreateEvent(data);
    this.props.hideModal();
  };

  canSave = () =>
    this.state.name.length && this.state.startDate && this.state.endDate;

  render() {
    const { hideModal } = this.props;
    const { name, startDate, endDate } = this.state;
    return (
      <View
        {...{
          hideModal,
          canSave: this.canSave(),
          handleSave: this.handleSave,
          name,
          startDate,
          endDate,
          handleNameChange: this.handleNameChange,
          handleStartDateChange: this.handleStartDateChange,
          handleEndDateChange: this.handleEndDateChange
        }}
      />
    );
  }
}

AddEventModal.propTypes = {
  handleCreateEvent: PropTypes.func.isRequired,
  eventName: PropTypes.string,
  hideModal: PropTypes.func.isRequired
};

export default AddEventModal;
