import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { SidebarWrapper } from "ui-kit/Sidebar/View";
import PassesSidebar from "Inventory/Main/View/Sidebar";

import * as R from "ramda";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import CreateOrderModal from "Orders/CreateOrderModal";
import ImportOrdersModal from "Orders/ImportModal/View";

import { eventDetails } from "redux/modules/event/selectors";
import { getGroupsByType } from "redux/modules/items/item-groups/selectors";
import { user } from "redux/modules/user/selectors";
import { sourcesByTypeId } from "redux/modules/orders/sources/selectors";
import { applications } from "redux/modules/applications/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import { CREDENTIAL_TYPE_ID, INVENTORY_TYPE_ID } from "utils/item-types";

import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";

import { getLinks, getSetupLink } from "../selectors";

const decorate = R.compose(
  connect(
    (state, props) => ({
      eventDetails: eventDetails(state),
      user: user(state),
      sources: sourcesByTypeId(state, CREDENTIAL_TYPE_ID),
      applications: applications(state),
      cando: canUserDo(state, props.params.orgId || props.params.eventId),
      categories: getGroupsByType(state, {
        ...props,
        type: INVENTORY_TYPE_ID
      })
    }),
    {
      getItemGroupsByEventAndType
    }
  ),
  withRouter
);

class View extends Component {
  componentDidMount() {
    const eventId = R.path(["eventDetails", "id"], this.props);

    // get: item groups
    if (!this.props.categories || !this.props.categories.length) {
      this.props.getItemGroupsByEventAndType(eventId, INVENTORY_TYPE_ID);
    }
  }

  showCreateOrderModal = () => {
    this.props.showModal({
      content: <CreateOrderModal hideModal={this.props.hideModal} />,
      wrapper: ModalWrapper
    });
  };

  showImportOrdersModal = () => {
    this.props.showModal({
      content: <ImportOrdersModal />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const { children, eventDetails, cando, categories, params } = this.props;

    return (
      <SidebarWrapper
        instanceId="inventory"
        sidebar={
          <PassesSidebar
            links={getLinks({
              eventDetails,
              cando,
              categories,
              showCreateOrderModal: this.showCreateOrderModal,
              showImportOrdersModal: this.showImportOrdersModal,
              params
            })}
            setupLink={getSetupLink({ cando, eventDetails })}
          />
        }
      >
        {children}
      </SidebarWrapper>
    );
  }
}

export default decorate(View);
