export const NAMESPACE = "SignupOnboard";

export const PAGES = {
  ABOUT: "about",
  ORGANIZATION: "organization",
  EVENT: "event"
};

export const FIELDS = {
  DESCRIBE_ORGANIZATION: "describeOrganization",
  OFTEN_HOST: "howOftenHostEvents",
  ORGANIZATION_NAME: "organizationName",
  COLLABORATOR_EMAIL_1: "collaboratorEmail1",
  COLLABORATOR_EMAIL_2: "collaboratorEmail2",
  COLLABORATOR_EMAIL_3: "collaboratorEmail3",
  EVENT_NAME: "eventName",
  EVENT_TIMEZONE: "eventTimezone",
  EVENT_START_AT: "eventStartAt",
  EVENT_END_AT: "eventEndAt"
};

export const EVENTS_LIST = [
  "Webinars",
  "Conferences",
  "Exhibits & Shows",
  "Networking",
  "Trade Shows",
  "Training",
  "Forums",
  "Podcasts",
  "Online Courses",
  "Recruiting",
  "Meetups",
  "Creative Workshops",
  "Concerts",
  "Celebrations",
  "Alumni Events",
  "Fundraising",
  "Sports"
];

export const DATE_FORMAT = "M/D [at] h:mma";
