import React from "react";
import { Div, Popover, DownIcon, CheckIcon, NoneIcon } from "components/Base";
import * as R from "ramda";
import { connect } from "react-redux";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  formatAmountForEvent: formatAmountForEvent(state)
}));

const PriceUndecorated = ({
  id,
  onChange,
  closePopover,
  name,
  price,
  selected,
  color,
  formatAmountForEvent
}) => (
  <Div
    display="row.flex-start.center"
    bra={1}
    px={1}
    py={2}
    onClick={() => {
      onChange(id);
      closePopover();
    }}
    bg={{
      default: "white",
      hover: "neutral0"
    }}
  >
    <Div size={30}>
      {selected ? (
        <CheckIcon size={28} color={color} mr={2} />
      ) : (
        <NoneIcon size={28} />
      )}
    </Div>
    <Div display="column" ml={2}>
      <Div fw={3} fs={3} color="gray7" noLineHeight truncate>
        {name}
      </Div>
      {price ? (
        <Div fw={2} color="gray6" noLineHeight>
          {formatAmountForEvent(price)} ea
        </Div>
      ) : null}
    </Div>
  </Div>
);

const Price = decorate(PriceUndecorated);

const View = ({
  selectedPriceId,
  prices = [],
  onChange,
  color,
  formatAmountForEvent
}) => {
  const selectedPrice = R.find(R.propEq("id", selectedPriceId))(prices);

  return (
    <span>
      <Popover
        Label={({ onClick }) => (
          <Div
            minWidth={75}
            height={30}
            bra={1}
            bg=""
            bc="neutral1"
            b={1}
            display="row.flex-start.center"
            pl={2}
            pr={1}
            onClick={onClick}
          >
            <Div flex={1} color="gray6" fw={3} fs={2}>
              {selectedPrice
                ? `${formatAmountForEvent(selectedPrice.price)}`
                : "—"}
            </Div>
            <DownIcon color="gray6" ml={2} size={20} />
          </Div>
        )}
      >
        {({ closePopover }) => (
          <Div p={1} bg="white" bra={2}>
            {prices.map(price => (
              <Price
                color={color}
                key={price.id}
                closePopover={closePopover}
                onChange={onChange}
                selected={price.id === selectedPriceId}
                {...price}
              />
            ))}
            <Div
              width={1}
              bg="neutral1"
              style={{
                height: 1
              }}
            />
            <Price
              closePopover={closePopover}
              onChange={onChange}
              id={null}
              name="None"
              selected={!selectedPriceId}
            />
          </Div>
        )}
      </Popover>
    </span>
  );
};

export default decorate(View);
