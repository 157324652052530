import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { FORMS_INSTANCE_ID, CARD_TYPES, EXPERIENCE_URL } from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import CollapsableCard from "../CollapsableCard";

const columns = [
  {
    name: "Name",
    value: "name",
    style: { flex: 1.2 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Alias",
    value: "alias",
    style: { flex: 1 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Due Date",
    value: "close_date_formatted",
    style: { flex: 1.4, fontSize: 15 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Required",
    value: "required",
    type: "required",
    style: { width: 80, textAlign: "center" }
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORMS_INSTANCE_ID)),
  connect(
    state => ({
      assignedForms: getters.assignedForms(state),
      loadingForms: getters.loadingForms(state)
    }),
    {
      goToLearnLennd: actions.goToLearnLennd,
      openModal: () => actions.openAssignModal(CARD_TYPES.FORMS),
      goToForms: actions.goToForms,
      deleteAssginedForm: actions.deleteAssginedForm,
      openUpdateModal: ({ id }) =>
        actions.openUpdateModal({ assignModalType: CARD_TYPES.FORMS, id }),
      reorderForms: actions.reorderForms
    }
  ),
  CSSModules(css)
);

const Forms = ({
  goToLearnLennd,
  assignedForms,
  goToForms,
  openModal,
  loadingForms,
  deleteAssginedForm,
  openUpdateModal,
  reorderForms
}) => (
  <CollapsableCard
    title="Collect Form Submissions"
    description="Assign forms for users to fill out on this portal."
    btnText="forms"
    goToLearn={() =>
      goToLearnLennd({
        expectedExperienceUrl: EXPERIENCE_URL.collect_form_submissions
      })
    }
    loading={loadingForms}
    openModal={openModal}
    goToManage={goToForms}
    deleteRow={deleteAssginedForm}
    openUpdateModal={openUpdateModal}
    assignedRows={assignedForms}
    assignedRowsText="Form"
    onReorder={reorderForms}
    emptyText="You haven't assigned any forms to this portal yet."
    columns={columns}
  />
);

export default decorate(Forms);
