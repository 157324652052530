/* eslint-disable react/prop-types */

import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { ACCOUNTS, CONTACTS } from "utils/standard-module-field-ids";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmailModal from "SendEmailModal/View";
import AddAccountModal from "components/Global/CRM/Modals/AddAccountModal";
import AddRelatedAccountModal from "components/Event/ContactProfile/RelatedAccounts/AddRelatedAccountModal";
import EditRelatedAccountModal from "components/Event/ContactProfile/RelatedAccounts/EditRelatedAccountModal";
import ViewMoreModal from "components/Global/CRM/Modals/ViewMoreModal";
import ChangeRecordTypeModal from "components/Global/CRM/Modals/ChangeRecordType";
import LoginDetailsModal from "components/Event/Accounts/Account/People/LoginDetailsModal";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";
import RunReportModal from "Reports/RunReportModal/View";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";

import NotesController from "../ModuleNotes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  getContact,
  invalidateContact
} from "redux/modules/contacts/profile/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { logAction } from "redux/modules/log/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import {
  removeEventAssignments,
  updateType,
  deleteRecord,
  addRecord
} from "redux/modules/modules/records/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { updateContactNotifications } from "redux/modules/eventContactProfile";
import { deleteAccountUser } from "redux/modules/accounts/users/actions";
import { sendEmail } from "redux/modules/email/actions";

import { contact, isFetching } from "redux/modules/contacts/profile/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { orgDetails } from "redux/modules/organization/selectors";

import {
  addRelationship,
  updateRelationship,
  deleteRelationship
} from "redux/modules/accounts/contactRelationship/actions";

const CONTACTS_ID = STANDARD_MODULES.contacts.id;
const ACCOUNTS_ID = STANDARD_MODULES.accounts.id;

export class BaseController extends Component {
  componentDidMount() {
    this.fetchProfile();
  }

  componentDidUpdate(oldProps) {
    if (this.props.params.recordId !== oldProps.params.recordId) {
      this.props.getRecord({
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        contactId: this.props.params.recordId
      });
    }
  }

  componentWillUnmount() {
    this.props.invalidateRecord(this.props.params.recordId);
  }

  addNote = async note => {
    await this.props.addNote({
      moduleId: CONTACTS_ID,
      recordId: this.props.params.recordId,
      note
    });
    return this.fetchProfile();
  };

  updateNote = async (note, noteId) => {
    await this.props.updateNote({
      recordId: this.props.params.recordId,
      noteId,
      note
    });
    return this.fetchProfile();
  };

  deleteNote = async noteId => {
    await this.props.deleteNote({ noteId });
    return this.fetchProfile();
  };

  showSendEmailModal = () => {
    this.props.showModal({
      content: (
        <SendEmailModal
          moduleId={CONTACTS_ID}
          records={[this.props.params.recordId]}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  fetchProfile = async () => {
    const { eventId, recordId } = this.props.params;

    return await this.props.getRecord({
      eventId,
      contactId: recordId
    });
  };

  showAddRelationshipModal = () => {
    this.props.showModal({
      content: (
        <AddRelatedAccountModal
          addRelationship={this.addRelationship}
          hideModal={this.props.hideModal}
          params={this.props.params}
          onAddAccount={this.showAddAccountModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditRelationshipModal = account => {
    this.props.showModal({
      content: (
        <EditRelatedAccountModal
          account={account}
          hideModal={this.props.hideModal}
          onSave={this.editRelationship}
          onDelete={this.removeRelationship}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddAccountModal = () => {
    this.props.showModal({
      content: (
        <AddAccountModal
          addAccount={this.addAccount}
          accountTypes={this.props.details.module_settings.accounts.types}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  addAccount = async data => {
    const record = await this.props.addRecord({
      moduleId: ACCOUNTS_ID,
      typeId: data.type,
      record: {
        [ACCOUNTS.NAME]: data.name
      },
      options: {
        eventId: this.props.params.eventId
      }
    });

    await this.addRelationship({ accountId: record.id, role: null });

    await this.fetchProfile();
  };

  addRelationship = async ({ accountId, role }) => {
    const data = {
      orgId: this.props.params.orgId,
      eventId: this.props.params.eventId,
      accountId,
      contactId: this.props.params.recordId,
      role
    };
    this.props.hideModal();
    await this.props.addRelationship(data);
    await this.fetchProfile();
    this.props.showSnackbar({
      message: "Group added to contact",
      status: "OK"
    });
  };

  editRelationship = async ({ relationshipId, role }) => {
    const data = {
      relationshipId,
      role
    };
    await this.props.updateRelationship({
      ...data,
      orgId: this.props.params.orgId,
      eventId: this.props.params.eventId
    });
    await this.fetchProfile();
    this.props.showSnackbar({
      message: "Relationship updated",
      status: "OK"
    });
  };

  removeRelationship = async ({ relationshipId, accountId }) => {
    const relationship = await this.props.deleteRelationship({
      relationshipId,
      accountId
    });
    await this.fetchProfile();
    this.props.showSnackbar({
      message: "Group removed from record",
      status: "OK"
    });
    return relationship;
  };

  removeRelatedContact = async row => {
    try {
      await this.deleteContactRelationship(row);
      this.props.showSnackbar({ message: "Group removed from record" });
    } catch (e) {
      this.props.showSnackbar({
        message: "Error removing group from record"
      });
    } finally {
      this.fetchProfile();
    }
  };

  async deleteContactRelationship({
    id: relationshipId,
    can_login: userId,
    account_id: accountId
  }) {
    await this.props.deleteRelationship({ accountId, relationshipId });
    if (userId) {
      await this.revokeLoginAccess(accountId, userId);
    }
  }

  async revokeLoginAccess(accountId, userId) {
    const accountUser = this.props.record.accounts.find(
      a => a.account_id === accountId && a.can_login === userId
    );
    if (accountUser) {
      return this.props.deleteAccountUser({
        eventId: this.props.eventDetails.id,
        userId,
        accountId
      });
    }
    throw new Error("No account user found");
  }

  updateRelatedContact = async data => {
    await this.props.updateRelationship({
      ...data,
      orgId: this.props.params.orgId,
      eventId: this.props.params.eventId,
      contactId: this.props.params.recordId
    });

    this.fetchProfile();

    this.props.showSnackbar({ message: "Relationship updated" });
  };

  handleLoginAccess = async row => {
    if (row.can_login) {
      try {
        await this.revokeLoginAccess(row.account_id, row.can_login);
        this.props.showSnackbar({ message: "Login access has been removed" });
      } catch (e) {
        this.props.showSnackbar({ message: "Error removing login access" });
      } finally {
        this.fetchProfile();
      }
    } else {
      this.showGiveLoginAccessModal(row.account_id);
    }
  };

  showGiveLoginAccessModal = accountId => {
    this.props.showModal({
      content: (
        <BulkLoginDetails
          contacts={
            typeof accountId === "string"
              ? [
                  {
                    accountId,
                    contactId: this.props.params.recordId
                  }
                ]
              : accountId.map(record => ({
                  accountId: record.account_id,
                  contactId: this.props.params.recordId
                }))
          }
          onDone={() => {
            this.fetchProfile();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showLoginDetailsModal = (row, showFlashMessage = false) =>
    this.props.showModal({
      content: (
        <LoginDetailsModal
          showFlashMessage={showFlashMessage}
          row={{
            ...row,
            values: Object.keys(this.props.record.values).map(fieldId => {
              return {
                field_id: fieldId,
                ...this.props.record.values[fieldId]
              };
            })
          }}
          hideModal={this.props.hideModal}
          eventDetails={this.props.eventDetails}
          onEmailSend={this.sendNotificationEmail}
        />
      ),
      wrapper: ModalWrapper
    });

  sendNotificationEmail = async ({ customMessage, email }) => {
    const { eventDetails, name } = this.props;

    const variables = {
      message: customMessage,
      event_name: eventDetails.name,
      event_slug: eventDetails.slug,
      portal_id: eventDetails.uuid,
      account_name: name,
      user_email: email
    };

    // @FIX SEND EMAIL
    await this.props.sendEmail({
      eventId: eventDetails.id,
      templateName: "notification-account-invite",
      recipients: [{ email }],
      variables: Object.keys(variables).map(key => ({
        name: key,
        content: variables[key]
      }))
    });

    this.props.showSnackbar({ message: "Email sent" });
  };

  showViewMoreModal = content => {
    this.props.showModal({
      content: (
        <ViewMoreModal
          heading="Related Groups"
          data={content}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  // eslint-disable-next-line consistent-return
  getFieldValue = fieldId => {
    if (!R.isEmpty(this.props.record)) {
      return R.compose(
        value =>
          getValue(R.prop("value")(value), R.path(["value", "type"])(value)),
        R.path(["values", fieldId])
      )(this.props.record);
    }
  };

  updateField = async ({ fieldId, value }) => {
    // @NOTE: Normalize how contact values are returned so that
    // `addValue` can virtually update it and not have to refetch record
    await this.props.addValue({
      moduleId: CONTACTS_ID,
      recordId: this.props.params.recordId,
      fieldId,
      value
    });

    return this.fetchProfile();
  };

  isEvent = () => {
    return Boolean(this.props.params.eventId);
  };

  isEnabledModule = moduleId => {
    if (this.isEvent()) {
      return this.props.enabledModules.some(m => m.id === moduleId);
    }
    return false;
  };

  handleRemoveFromEvent = async eventId => {
    await this.props.removeEventAssignments({
      eventIds: [eventId],
      recordIds: [this.props.params.recordId],
      moduleId: CONTACTS_ID
    });
    this.fetchProfile();
  };

  showDeleteContactModal = () => {
    if (this.props.params.orgId) {
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading="Delete contact?"
            message={
              <div>
                Are you sure you want to delete this person?
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => this.handleDelete(false)}
          />
        ),
        wrapper: ModalWrapper
      });
    } else {
      this.props.showModal({
        content: (
          <DeleteOrRemoveConfirmation
            hideModal={this.props.hideModal}
            countOfSelected={1}
            heading="Remove contact?"
            onRemove={() => this.handleDelete(true)}
            onDelete={() => this.handleDelete(false)}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  };

  handleDelete = async (removeOnly = false) => {
    const { orgId, eventId, recordId } = this.props.params;
    await this.props.deleteRecord({
      moduleId: CONTACTS_ID,
      orgId,
      eventId,
      record: { id: recordId },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        removeOnly
      }
    });
    this.props.router.replace({
      pathname: orgId
        ? `/organization/${orgId}/module/${CONTACTS_ID}`
        : `/event/${eventId}/module/${CONTACTS_ID}/dashboard`
    });
  };

  showChangeRecordTypeModal = () => {
    this.props.showModal({
      content: (
        <ChangeRecordTypeModal
          moduleId={CONTACTS_ID}
          update={async ({ type }) => {
            await this.props.updateType({
              moduleId: CONTACTS_ID,
              recordId: this.props.params.recordId,
              type,
              options: {
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId
              }
            });
            return this.fetchProfile();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showChangeAccountRecordTypeModal = (accountId, typeId) => {
    this.props.showModal({
      content: (
        <ChangeRecordTypeModal
          moduleId={ACCOUNTS_ID}
          typeId={typeId}
          update={async ({ type }) => {
            await this.props.updateType({
              moduleId: ACCOUNTS_ID,
              recordId: accountId,
              type,
              options: {
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId
              }
            });
            return this.fetchProfile();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  runReport = () => {
    this.props.showModal({
      content: (
        <RunReportModal
          moduleId={CONTACTS_ID}
          recordIds={[this.props.params.recordId]}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  getActiveView = () => {
    if (
      this.props.routes.find(r => r.name === "OrgContactProfile") ||
      this.props.routes.find(r => r.name === "contactProfile") ||
      this.props.routes.find(r => r.name === "EventLightContactProfile") ||
      this.props.routes.find(r => r.name === "OrgLightContactProfile")
    ) {
      return "overview";
    } else if (
      this.props.routes.find(r => r.name === "OrgContactProfileActivities") ||
      this.props.routes.find(r => r.name === "contactProfileActivities") ||
      this.props.routes.find(r => r.name === "EventLightContactActivity") ||
      this.props.routes.find(r => r.name === "OrgLightContactActivity")
    ) {
      return "activity";
    }
    return undefined;
  };

  goToView = path => {
    const { orgId, eventId, recordId } = this.props.params;
    this.props.router.push({
      pathname: orgId
        ? `/organization/${orgId}/contact/${recordId}${path}`
        : `/event/${eventId}/contact/${recordId}${path}`
    });
  };

  render() {
    return null;
  }
}

const fullname = R.compose(
  R.join(" "),
  R.map(R.pathOr("", ["value", "value"])),
  R.values,
  R.pick([CONTACTS.FIRST_NAME, CONTACTS.LAST_NAME]),
  R.propOr({}, "values")
);

const email = R.compose(
  R.pathOr(null, [CONTACTS.EMAIL, "value", "value"]),
  R.propOr({}, "values")
);

function mapStateToProps(state, props) {
  const context = {
    ...props.params,
    recordId: props.contactId || props.params.recordId
  };

  const permissionKey = props.params.orgId || props.params.eventId;
  return {
    params: context,
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    enabledModules: R.pathOr([], ["enabled_modules"], eventDetails(state)),
    record: contact(state, context.recordId),
    recordName: fullname(contact(state, context.recordId)),
    email: email(contact(state, context.recordId)),
    readOnly: !canUserDo(state, permissionKey)(`${CONTACTS_ID}_update`),
    canManageFields: !canUserDo(state, permissionKey)(`${CONTACTS_ID}_manage`),
    cando: canUserDo(state, permissionKey),
    loading: isFetching(state, context.recordId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRelationship,
      addValue,
      deleteRelationship,
      getRecord: getContact,
      invalidateRecord: invalidateContact,
      logAction,
      removeEventAssignments,
      showModal,
      hideModal,
      showSnackbar,
      updateContactNotifications,
      updateRelationship,
      updateType,
      deleteRecord,
      addRecord,
      deleteAccountUser,
      sendEmail
    },
    dispatch
  );
}

export const withState = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  NotesController
);
