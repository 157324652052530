import * as R from "ramda";
import { createSelector } from "reselect";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

export const getTabs = createSelector(getEventDetails, event => [
  {
    id: "EventLightEmailsHistory",
    name: "History",
    to: `/event-light/${event.id}/emails/history/email`
  }
]);

export const getSubtabs = R.always([]);
