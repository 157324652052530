import React, { Component } from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import { connect } from "react-redux";
import Tooltip from "components/Global/Tooltip";
import { bindInstance } from "redux-mvc";

import ViewPopover from "./Popover";
import ViewSelector from "./ViewSelector";

import {
  Div,
  MenuItem,
  ReplyIcon,
  AddIcon,
  MoreIcon,
  FontIcon,
  EditIcon,
  DeleteIcon,
  Popover
} from "components/Base";

import MenuPopover from "ui-kit/MenuPopover";

import { actions, getters } from "ui-kit/ViewPicker";

const decorate = R.compose(
  connect(
    (state, props) => ({
      views: getters.views(state, props),
      activeViewId: getters.activeViewId(state, props),
      editing: getters.showEditViewName(state, props)
    }),
    bindInstance({
      init: actions.init,
      saveViewChanges: actions.saveViewChanges,
      revertViewChanges: actions.revertViewChanges,
      addView: actions.addView,
      deleteView: actions.deleteView,
      setShowEditViewName: actions.setShowEditViewName
    })
  )
);

const Button = ({ children, onClick, ...styleProps }) => {
  return (
    <Div
      height={1}
      flex={1}
      fs={2}
      fw={3}
      bra={1}
      display="row.flex-start.center"
      px={3}
      py={3}
      bg={{
        default: "primary7",
        hover: "primary6"
      }}
      onClick={onClick}
      {...styleProps}
    >
      {children}
    </Div>
  );
};

class SaveView extends Component {
  constructor(props) {
    super(props);

    props.init({
      views: props.views,
      activeViewId: props.activeViewId
    });
  }

  render() {
    const {
      instanceId,
      hasViewChanged,
      saveViewChanges,
      addView,
      revertViewChanges,
      deleteView,
      setShowEditViewName,
      activeViewId,
      collapsed,
      editing,
      small = false,
      onClick,
      ...styleProps
    } = this.props;

    const showOptions = !editing && hasViewChanged;
    const hideMenu = (editing && collapsed) || activeViewId === "default";

    if (activeViewId === "default" && !collapsed && !hasViewChanged) {
      return null;
    }

    return (
      <Div
        bra={1}
        px={1}
        mr={2}
        display="row.flex-start.center"
        {...styleProps}
      >
        <Div
          width={1}
          style={{
            maxWidth: 165
          }}
          bra={1}
          py={toolbar ? 0 : 2}
          px={3}
          {...styleProps}
        >
          {onClick ? (
            <ViewSelector
              instanceId={instanceId}
              small={small}
              white={true}
              onClick={onClick}
            />
          ) : (
            <Popover
              wrapperProps={{
                width: 1,
                height: 1
              }}
              innerSpanStyle={{
                width: "100%",
                height: "100%"
              }}
              Label={({ onClick }) => (
                <ViewSelector
                  instanceId={instanceId}
                  small={small}
                  white={true}
                  onClick={onClick}
                />
              )}
            >
              {({ closePopover }) => (
                <ViewPopover
                  instanceId={instanceId}
                  composeClosePopover={closePopover}
                />
              )}
            </Popover>
          )}
        </Div>

        <Div
          display="row.flex-start.center"
          color="white"
          bra={1}
          width={1}
          height={35}
        >
          {showOptions ? (
            <Tooltip
              tooltip={
                <span>
                  <b>Save all changes</b>
                  <br />
                  made to this view
                </span>
              }
            >
              <Button
                height={small ? 32 : undefined}
                className={css.button3}
                onClick={() => saveViewChanges()}
              >
                <FontIcon color="white" mr={1} fs={4}>
                  autorenew
                </FontIcon>
                <span>{collapsed ? "Save" : "Save View"}</span>
              </Button>
            </Tooltip>
          ) : null}

          {showOptions && !small ? (
            <Tooltip tooltip="Reset view changes">
              <Button
                height={small ? 32 : undefined}
                onClick={() => revertViewChanges()}
                ml={2}
                px={2}
                className={css.button4}
              >
                <ReplyIcon color="white" />
              </Button>
            </Tooltip>
          ) : null}

          {showOptions && activeViewId !== "default" && !small ? (
            <Tooltip
              tooltip={
                <span>
                  <b>Save changes</b>
                  <br />
                  as new view
                </span>
              }
            >
              <Button
                height={small ? 32 : undefined}
                onClick={() => addView()}
                ml={2}
                px={2}
                className={css.button4}
              >
                <FontIcon color="white" mr={1} fs={3}>
                  file_copy
                </FontIcon>
              </Button>
            </Tooltip>
          ) : null}

          {hideMenu ? null : (
            <MenuPopover
              height={1}
              display="row.flex-start.center"
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom"
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top"
              }}
              Label={
                <Button
                  onClick={onClick}
                  ml={showOptions || collapsed ? 2 : 0}
                  px={2}
                  height={small ? 32 : undefined}
                  className={css.button1Light}
                >
                  {collapsed || hasViewChanged ? null : "Edit View"}
                  <MoreIcon
                    ml={collapsed || hasViewChanged ? 0 : 2}
                    color="#222"
                  />
                </Button>
              }
            >
              {({ closePopover }) => (
                <Div bra={3} bg="white" py={2}>
                  <MenuItem
                    LeftIcon={EditIcon}
                    onClick={() => {
                      setShowEditViewName(true);
                      closePopover();
                    }}
                  >
                    Rename
                  </MenuItem>
                  {small && showOptions ? (
                    <MenuItem
                      LeftIcon={ReplyIcon}
                      onClick={() => {
                        revertViewChanges();
                        closePopover();
                      }}
                    >
                      Revert changes
                    </MenuItem>
                  ) : null}
                  {small && showOptions && activeViewId !== "default" ? (
                    <MenuItem
                      LeftIcon={AddIcon}
                      onClick={() => {
                        addView();
                        closePopover();
                      }}
                    >
                      Save changes as new view
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    LeftIcon={DeleteIcon}
                    onClick={() => {
                      deleteView();
                      closePopover();
                    }}
                  >
                    Delete View
                  </MenuItem>
                </Div>
              )}
            </MenuPopover>
          )}
        </Div>
      </Div>
    );
  }
}

export default CSSModules(decorate(SaveView), css);
