import React from "react";
import { connect } from "react-redux";

import { actions } from "SelectiveApproversLabel";

import { Popover, MediumOutlineButton, DownFilledIcon } from "components/Base";

import Menu from "./Menu";

import approversLabel from "SelectiveApproversLabel";
import approversLabelSagas from "SelectiveApproversLabel/sagas";

approversLabel.setRootSaga(approversLabelSagas);

const decorate = connect(
  null,
  {
    fetchRecordsIsApproverFor: actions.fetchRecordsIsApproverFor
  }
);

const ApproverLabelView = ({
  fetchRecordsIsApproverFor,
  Button = MediumOutlineButton,
  ButtonProps = {},
  ...props
}) => {
  // build options menu
  const menu = (
    <Popover
      Label={({ onClick }) => (
        <Button
          {...ButtonProps}
          onClick={e => {
            onClick(e);
            fetchRecordsIsApproverFor({
              type: props.type,
              recordIds: props.recordIds
            });
          }}
        >
          Review
          <DownFilledIcon ml={2} size={24} />
        </Button>
      )}
      labelProps={{}}
      wrapperProps={{}}
    >
      {({ closePopover }) => (
        <Menu
          {...{
            ...props,
            closeMenu: closePopover
          }}
        />
      )}
    </Popover>
  );

  return menu;
};

export default decorate(ApproverLabelView);
