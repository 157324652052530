import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Card from "components/Global/CRM/Card";
import * as R from "ramda";
import EmptyState, {
  ButtonWrapper
} from "components/Global/CRM/Card/EmptyState";
import Header from "components/Global/CRM/Card/Header";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import {
  HelpButton,
  OutlineButton,
  SolidButton
} from "components/Global/CRM/Card/Buttons";
import HelpArticleUrls from "utils/help-article-urls";
import Actions from "components/Global/CRM/Card/Actions";
import Title from "components/Global/CRM/Card/Title";
import Body from "components/Global/CRM/Card/Body";
import AssignedRequest from "./AssignedRequest";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Loading from "ui-kit/Loading";

import { connect } from "react-redux";
import { actions, getters } from "../model";
import { userId as getUserId } from "redux/modules/user/selectors";

const COLOR = STANDARD_MODULE_IDS.documentRequests.color;
const ICON = STANDARD_MODULE_IDS.documentRequests.icon;

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    requests: getters.requests(state),
    mode: getters.mode(state),
    userId: getUserId(state)
  }),
  {
    onAssignFileRequests: actions.onAssignFileRequests
  }
);

const Requests = props => {
  const isAdminMode = R.prop("mode", props) === "admin";

  const body = R.path(["requests", "length"], props) ? (
    R.compose(
      R.map(fileRequest => {
        const isManager = R.prop("request_type_admins", fileRequest).find(
          user => R.prop("user_id", user) === R.prop("userId", props)
        );

        return (
          <AssignedRequest
            key={R.prop("id", fileRequest)}
            isAdminMode={isAdminMode}
            request={fileRequest}
            isAssignedToType={Boolean(
              R.prop("account_type_document_request_id", fileRequest)
            )}
            isManager={isManager}
          />
        );
      }),
      R.sortBy(
        R.compose(name => (name ? R.toLower(name) : name), R.propOr("", "name"))
      )
    )(R.prop("requests", props))
  ) : (
    <EmptyState
      center={!isAdminMode}
      icon={
        <img src="//d2dks4tzxs6xee.cloudfront.net/img/inbox_icon.png" alt="" />
      }
    >
      <div>
        {isAdminMode
          ? "File Requests are ways to collect files or documents from a group or individual."
          : "No files have been requested yet for you to submit"}
      </div>
      {isAdminMode && (
        <ButtonWrapper>
          <SolidButton onClick={R.prop("onAssignFileRequests", props)}>
            <i className="material-icons">add</i>
            Assign Requests
          </SolidButton>
        </ButtonWrapper>
      )}
    </EmptyState>
  );

  return (
    <Card color={COLOR}>
      <Header>
        <Title>
          <IconTitle title="File Requests" color={COLOR}>
            <i className={`material-icons ${css.icon}`}>{ICON}</i>
          </IconTitle>
          {isAdminMode && (
            <Actions>
              <OutlineButton onClick={R.prop("onAssignFileRequests", props)}>
                Assign Requests
              </OutlineButton>
              <HelpButton
                url={R.prop("MANAGE_ACCOUNT_FILE_REQUESTS", HelpArticleUrls)}
              >
                Learn about managing file requests
              </HelpButton>
            </Actions>
          )}
        </Title>
      </Header>
      <Body>{R.prop("loading", props) ? <Loading /> : body}</Body>
    </Card>
  );
};

export default decorate(CSSModules(Requests));
