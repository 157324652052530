import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, filter, difference, has, without, uniq } from "lodash";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/event-days";
import moment from "moment";
import EventDaySelector from "components/Global/EventDaySelector";
import createRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const format = "YYYY-MM-DD";

@CSSModules(css)
class EventDaysEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: this.formatValue(props.value)
    };
  }

  @autobind
  wrapValue(days) {
    return {
      type: "event-days",
      value: days && days.length ? createRanges(days) : undefined
    };
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  handleDayClick(ydm) {
    this.setState(state => {
      if (this.isDaySelected(ydm)) {
        state.selectedDays = without(state.selectedDays, ydm);
      } else {
        state.selectedDays.push(ydm);
      }

      this.props.onChange(this.wrapValue(state.selectedDays));
      return state;
    });
  }

  @autobind
  handleGroupSelectAll(id) {
    this.setState(state => {
      const group = filter(this.props.dayGroups, { id });
      get(group, "[0]", { days: [] }).days.forEach(day => {
        state.selectedDays.push(day);
      });
      state.selectedDays = uniq(state.selectedDays);

      this.props.onChange(this.wrapValue(state.selectedDays));
      return state;
    });
  }

  @autobind
  handleGroupDeselectAll(id) {
    this.setState(state => {
      const group = filter(this.props.dayGroups, { id });
      state.selectedDays = difference(
        state.selectedDays,
        get(group, "[0].days", [])
      );

      this.props.onChange(this.wrapValue(state.selectedDays));
      return state;
    });
  }

  @autobind
  isDaySelected(ydm) {
    return this.state.selectedDays.includes(ydm);
  }

  @autobind
  buildDayMap(startDate, endDate, dayGroups) {
    const map = {};
    dayGroups.forEach(group => {
      group.days.forEach(day => {
        map[day] = {
          color: group.color,
          isSelected: this.isDaySelected(day)
        };
      });
    });
    for (
      let m = moment(startDate).utc();
      m.isSameOrBefore(endDate);
      m.add(1, "days")
    ) {
      const day = m.format(format);
      if (!has(map, day)) {
        map[day] = {
          color: "#333333",
          isSelected: this.isDaySelected(day)
        };
      }
    }
    return map;
  }

  render() {
    const { startDate, close, endDate, dayGroups, disabled } = this.props;

    return (
      <EventDaySelector
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        dayGroups={dayGroups}
        dayMap={this.buildDayMap(startDate, endDate, dayGroups)}
        showCountOfSelectedDays
        countOfSelectedDays={this.state.selectedDays.length}
        handleDayClick={this.handleDayClick}
        handleGroupSelectAll={this.handleGroupSelectAll}
        handleGroupDeselectAll={this.handleGroupDeselectAll}
        close={close}
      />
    );
  }
}

EventDaysEditor.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
  dayGroups: []
};

EventDaysEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  dayGroups: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  close: PropTypes.func.isRequired
};

export default EventDaysEditor;
