import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue, getDropdownOptions } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import { Div, DownFilledIcon } from "components/Base";
import Select from "react-select";

const ArrowRenderer = ({ onMouseDown }) => (
  <Div display="row.center.center" onMouseDown={onMouseDown}>
    <DownFilledIcon sizeWFS={5} />
  </Div>
);

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.DROPDOWN }),
  connect(
    (state, props) => ({
      value: getValue(state, props),
      options: getDropdownOptions(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue,
      onBlur: actions.blurField
    })
  )
);

export const UCDropdown = ({
  value = "",
  iniValue,
  fieldId,
  fieldType,
  multipleSelect = false,
  options = [],
  usePortal,
  width,
  onChange = noop,
  setIniValue = noop,
  onBlur = noop,
  instanceId, // eslint-disable-line no-unused-vars
  bg = "white"
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId, multipleSelect }
    });
  }, []);

  const styles = {
    control: provided => ({
      ...provided,
      backgroundColor: bg
    }),
    multiValueLabel: provided => ({
      ...provided,
      whiteSpace: "inherit",
      wordBreak: "break-all"
    })
  };
  if (usePortal) {
    styles.menu = provided => ({ ...provided, zIndex: 9999 });

    styles.menuPortal = styles => ({
      ...styles,
      zIndex: 9999
    });
  }

  if (width) {
    styles.container = provided => ({
      ...provided,
      width
    });
  }

  styles.option = (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.backgroundColor || styles.backgroundColor,
      color: data.textColor || styles.color
    };
  };

  styles.singleValue = (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.backgroundColor || styles.backgroundColor,
      color: data.textColor || styles.color
    };
  };

  return (
    <Select
      menuPortalTarget={usePortal ? document.body : undefined}
      arrowRenderer={ArrowRenderer}
      isMulti={multipleSelect}
      onChange={val =>
        onChange(val, {
          meta: { fieldType, fieldId, multipleSelect }
        })
      }
      onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
      value={value}
      options={options}
      styles={styles}
    />
  );
};

export const Dropdown = decorate(UCDropdown);
