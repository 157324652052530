import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CardTitle = ({ children }) => <div styleName="container">{children}</div>;

CardTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object])
};

export default CSSModules(CardTitle, css);
