import PropTypes from "prop-types";
import React, { Component } from "react";
import View from "./View";

class Controller extends Component {
  render() {
    const { records } = this.props;

    return (
      <View
        {...{
          records
        }}
      />
    );
  }
}

Controller.propTypes = {
  records: PropTypes.object.isRequired
};

export default Controller;
