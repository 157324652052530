import React from "react";
import { connect } from "react-redux";

import { actions } from "../model";
import { getIsOpenedActivityLog } from "../selectors";
import { TABLE_LOGS_INSTANCE_ID } from "../constants";

import {
  Div,
  CircleIcon,
  DownFilledIcon,
  MediumAvatar,
  Text2
} from "components/Base";
import CollapsiblePanel from "ui-kit/CollapsiblePanel";
import Table from "ui-kit/Table/View";

const decorate = connect(
  (state, props) => ({
    isOpened: getIsOpenedActivityLog(state, props)
  }),
  {
    toggleActivityLog: actions.toggleActivityLog
  }
);

const ActivityLog = ({
  id,
  name,
  user_image_url,
  user_name,
  module_id: moduleId,
  created_at,
  isOpened,
  records,
  type,
  toggleActivityLog
}) => (
  <Div width={1} bl={isOpened ? 3 : 0} bc={isOpened ? "primary8" : "neutral0"}>
    <CollapsiblePanel
      onClick={() => toggleActivityLog({ id, type, moduleId, records })}
      opened={isOpened}
      heading={
        <Div
          display="row.flex-start.center"
          width={1}
          pl={5}
          py={2}
          bg={{
            hover: "transparent",
            default: "transparent"
          }}
        >
          <Div display="row.flex-start.center" width={0.6}>
            <CircleIcon mr={2} size={8} />
            <span
              dangerouslySetInnerHTML={{ __html: name }}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "90%",
                fontSize: "14px"
              }}
            />
          </Div>
          <Div display="row.flex-start.center" width={0.25}>
            <MediumAvatar
              photoURL={user_image_url}
              bg="neutral3"
              text={user_name || ""}
              singleInitial
              longInitials
            />
            <Text2 bold ml={4}>
              {user_name}
            </Text2>
          </Div>
          <Text2 width={0.15} textAlign="right">
            {created_at}
          </Text2>
        </Div>
      }
      bg="transparent"
      hoverHeaderBg="neutral0"
      arrowIcon={DownFilledIcon}
      arrowColor="neutral4"
      right
      collapsedDefault
    >
      <Div width={1} style={{ overflow: "hidden" }}>
        <Table
          instanceId={`${TABLE_LOGS_INSTANCE_ID}${id}`}
          showOpenRecord={false}
          showCheckbox={false}
        />
      </Div>
    </CollapsiblePanel>
  </Div>
);

export default decorate(ActivityLog);
