import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import {
  getPeopleTypesForGuestListModal,
  getVisibleFieldsCount
} from "../../selectors";
import {
  GUEST_LIST_SETTINGS_MODAL_DATA,
  GUEST_LIST_FORM_ID,
  GUEST_LIST_DATE_FORMAT
} from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import {
  WithFormInstanceProvider,
  DateInput,
  AdvancedWYSIWYG,
  Dropdown,
  Toggle
} from "ui-kit/Form/View";
import Loading from "ui-kit/Loading";
import { BigFilledButton, Div } from "components/Base";
import { addS, noop } from "utils/General";
import { actions as FormActions } from "ui-kit/Form/model";
import moment from "moment";
import { eventDetails } from "redux/modules/event/selectors";
import { FIELD_IDS } from "EventLight/Details/constants";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(GUEST_LIST_FORM_ID)),
  connect(
    state => ({
      visibleFieldsCount: getVisibleFieldsCount(state),
      currentEvent: eventDetails(state),
      loadingGuestList: getters.loadingGuestList(state),
      peopleTypes: getPeopleTypesForGuestListModal(state),
      settingsData: getters.guestListSettings(state)
    }),
    {
      clearValues: FormActions.clearValues,
      hideUpdateModal: () => actions.setShowGuestListSettingsModal(false),
      openSelectRequiredFieldsModal: actions.openSelectRequiredFieldsModal,
      showSelectGuestListItemsModal: () =>
        actions.setShowSelectGuestListItemsModal(true),
      updateModalData: actions.updateGuestListSettings
    }
  ),
  CSSModules(css)
);

const UpdateGuestListSettingsModal = ({
  clearValues,
  visibleFieldsCount,
  currentEvent,
  hideUpdateModal,
  loadingGuestList,
  settingsData,
  showSelectGuestListItemsModal,
  onClose = noop,
  openSelectRequiredFieldsModal,
  peopleTypes,
  updateModalData
}) => {
  const [isSavingData, setIsSavingData] = useState(false);
  useEffect(
    () => () =>
      clearValues(null, {
        meta: {
          instanceId: GUEST_LIST_FORM_ID
        }
      }),
    []
  );

  useEffect(() => {
    if (isSavingData) {
      updateModalData();
    }
  }, [isSavingData]);

  const closeModals = () => {
    onClose();
    hideUpdateModal();
  };
  const getLimitsText = () => {
    const limits = Object.keys(settingsData.variant_limits || []).length;
    return `${limits} item limit${addS(limits)} set`;
  };

  const {
    close_date,
    contact_record_type_id,
    description,
    is_enabled
  } = settingsData;

  const closeDateIniValue = R.or(
    close_date,
    R.propOr(moment(), FIELD_IDS.DATE_TO, currentEvent)
  );
  const settingsAreLoaded = !R.isNil(is_enabled);
  const fieldWrapperMaxHeight = { height: 100 };
  const fieldWrapperMinHeight = { height: 60 };
  const advancedWYSIWYGSettings = { width: 700, height: 265 };

  const modalBody = (settingsAreLoaded && (
    <>
      <div styleName="row" className="row-1">
        <div styleName="fieldWrapper-enable-guest-list">
          <div>
            <div styleName="label">Enable Guest Lists</div>

            <div styleName="description">
              Allows you to share a unique link for each group to collect name
              assignments on allocated and unassigned passes
            </div>
          </div>
          <Toggle
            fieldId={GUEST_LIST_SETTINGS_MODAL_DATA.IS_ENABLED}
            iniValue={is_enabled}
          />
        </div>
      </div>
      <div styleName="row" className="row-2">
        <div styleName="fieldWrapper">
          <div styleName="label">Welcome Message</div>
          <div styleName="description">
            Add a welcome message / instructions
          </div>
          <AdvancedWYSIWYG
            fieldId={GUEST_LIST_SETTINGS_MODAL_DATA.DESCRIPTION}
            settings={advancedWYSIWYGSettings}
            iniValue={description}
          />
        </div>
      </div>
      <div styleName="row" className="row-3">
        <div styleName="fieldWrapper" style={fieldWrapperMaxHeight}>
          <div styleName="label">Close Date</div>
          <div styleName="description">
            If set, no additions or changes will be allowed after specified date
          </div>
          <DateInput
            fieldId={GUEST_LIST_SETTINGS_MODAL_DATA.CLOSE_DATE}
            format={GUEST_LIST_DATE_FORMAT}
            size="big"
            width={300}
            inputType="outline"
            iniValue={closeDateIniValue}
            showTime
            showCalendar
            showClearBtn
          />
        </div>
        <div styleName="fieldWrapper" style={fieldWrapperMaxHeight}>
          <div styleName="label">Select the category of the attendee</div>
          <div styleName="description">
            This attendee / contact category allows you to report accurately on
            the people added in the allocation manager.
          </div>
          <Dropdown
            fieldId={GUEST_LIST_SETTINGS_MODAL_DATA.CONTACT_RECORD_TYPE_ID}
            iniValue={contact_record_type_id}
            width="100%"
            clearable={false}
            options={peopleTypes}
            placeholder="Select..."
          />
        </div>
      </div>
      <div styleName="row" className="row-4">
        <div styleName="fieldWrapper" style={fieldWrapperMinHeight}>
          <div styleName="label">Select what fields to show</div>
          <div styleName="description"></div>
          <Div
            onClick={() => openSelectRequiredFieldsModal({ id: null })}
            fs={2}
            fw={3}
            color="purple8"
          >
            {visibleFieldsCount} field
            {addS(visibleFieldsCount)} selected
          </Div>
        </div>
        <div styleName="fieldWrapper" style={fieldWrapperMinHeight}>
          <div styleName="label">Add item limits</div>
          <div styleName="description">
            Limit how many of a single item can be assigned
          </div>

          {loadingGuestList ? (
            <Loading iconSize="30" paddingVertical="2" />
          ) : (
            <Div
              onClick={() => showSelectGuestListItemsModal()}
              fs={2}
              fw={3}
              color="purple8"
            >
              {getLimitsText()}
            </Div>
          )}
        </div>
      </div>
    </>
  )) || <Loading />;

  return (
    <MiniModalWrapper
      showModal
      hideModal={closeModals}
      title="Guest List Settings"
      display="column.flex-start.stretch"
      width={750}
      height={700}
      isPortal={false}
      zIndex={99}
    >
      <div styleName="edit-modal">
        <div styleName="body">{isSavingData ? <Loading /> : modalBody}</div>
        {settingsAreLoaded && (
          <div styleName="footer">
            <BigFilledButton
              bg="primary7"
              onClick={() => setIsSavingData(true)}
              disabled={isSavingData}
            >
              {isSavingData ? "Saving..." : "Save"}
            </BigFilledButton>
          </div>
        )}
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(UpdateGuestListSettingsModal);
