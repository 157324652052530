import { createSelector } from "reselect";

import { getters } from "Items/AssignQuestionsItemsModal";

import * as R from "ramda";

const mapIndexed = R.addIndex(R.map);

export const getFilteredQuestions = createSelector(
  getters.questions,
  getters.questionsFilter,
  (questions, questionsFilter) => {
    if (R.isEmpty(questionsFilter)) {
      return questions;
    }
    return R.filter(
      field => R.contains(R.toLower(questionsFilter), R.toLower(field.name)),
      questions
    );
  }
);

export const getFilteredSets = createSelector(
  getters.sets,
  getters.questionsFilter,
  (sets, questionsFilter) => {
    if (R.isEmpty(questionsFilter)) {
      return sets;
    }
    return R.filter(
      field => R.contains(R.toLower(questionsFilter), R.toLower(field.name)),
      sets
    );
  }
);

export const getSelectedQuestionsAndSets = createSelector(
  getters.questions,
  getters.sets,
  getters.questionsAndSetsIds,
  (questions, sets, questionsAndSetsIds) =>
    mapIndexed((selectedId, index) => {
      const question = R.find(({ id }) => id === selectedId, questions);
      const set = R.find(({ id }) => id === selectedId, sets);

      if (question) {
        return { ...question, order: index };
      }
      return { ...set, order: index };
    }, questionsAndSetsIds)
);
