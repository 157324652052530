import * as R from "ramda";
import { ACCOUNTS } from "utils/standard-module-field-ids";

export const isFetching = state => state.accounts.profile.fetching;
export const account = state => state.accounts.profile.account;
export const getAccountId = R.compose(
  R.propOr("", "id"),
  account
);
export const error = state => state.accounts.profile.error;
export const accountName = state =>
  R.pathOr("", ["values", ACCOUNTS.NAME, "value", "value"], account(state));

export const getUserById = (state, props = {}) =>
  R.find(
    R.compose(
      R.equals(props.id),
      R.prop("user_id")
    ),
    R.propOr([], "users", account(state))
  );
