import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";
import { Div } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import File from "components/Atoms/File";

import { showModal } from "redux/modules/modal/actions";
import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";

import { FIELD_TYPES } from "ui-kit/Form/constants";
import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.FILE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setFieldValue: actions.setFieldValue,
      showFilePicker: actions.showFilePicker,
      showModal
    })
  ),
  CSSModules(css)
);

const Preview = ({
  fieldType,
  fieldId,
  value: files = [],
  isReadOnly = false,
  showFilePicker = noop,
  setFieldValue = noop,
  showModal = noop,
  noShadow,
  small = false,
  options = {}
}) => {
  const deleteFile = file =>
    setFieldValue({ remove: [file] }, { meta: { fieldType, fieldId } });

  const showFileViewer = file => {
    showModal({
      content: (
        <FileViewer
          onFileDelete={deleteFile}
          start={files.indexOf(file)}
          files={files}
          isReadOnly={isReadOnly}
        />
      ),
      wrapper: FileViewerWrapper
    });
  };

  return (
    <div
      className="ignore-react-onclickoutside"
      styleName={noShadow ? "containerNoShadow" : "container"}
    >
      {!isReadOnly ? (
        <div
          styleName={small ? "uploadSmall" : "upload"}
          onClick={() => {
            if (
              R.isEmpty(files) ||
              (!R.isEmpty(files) && R.propEq("multiple", true, options))
            ) {
              showFilePicker(options, { meta: { fieldId, fieldType } });
            }
          }}
          style={
            R.propEq("multiple", false, options) && !R.isEmpty(files)
              ? { opacity: 0.3, cursor: "default" }
              : {}
          }
        >
          <i
            className="material-icons"
            styleName={small ? "clipSmall" : "clip"}
            style={
              R.propEq("multiple", false, options) && !R.isEmpty(files)
                ? { color: "#d4d4d4" }
                : {}
            }
          >
            attach_file
          </i>
        </div>
      ) : null}

      {R.map(
        file => (
          <Div mt={2}>
            <File
              key={file.id}
              file={file}
              onClick={showFileViewer}
              downloadFile={url => window.open(url, "_blank")}
              deleteFile={deleteFile}
              isReadOnly={isReadOnly}
            />
          </Div>
        ),
        files
      )}
    </div>
  );
};

export default decorate(Preview);
