/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getPortalData: ({ credentials, pageId, accountId }) =>
    new Promise((success, error) => {
      const options = {
        skipUserId: true,
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sites/site/portal/page/${pageId}`,
        credentials,
        qs: {
          accountId
        },
        success,
        error
      };
      Helpers.request(options);
    }),

  getPreferences: ({ credentials, eventUUID }) =>
    new Promise((success, error) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/event/${eventUUID}/preferences`,
        credentials,
        success,
        error
      };
      Helpers.request(options);
    }),
  updateNotificationSettings: ({ credentials, data }) =>
    new Promise((success, error) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/notifications/settings/event/${data.eventId}`,
        credentials,
        data,
        success,
        error
      };
      Helpers.request(options);
    })
};
