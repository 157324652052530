import React, { Component } from "react";
import { connect } from "react-redux";

import { getValue } from "../../utils";
import { actions } from "../../model";
import { ROW_ACTIONS } from "../../constants";
import { bindInstance } from "redux-mvc";
import styleSpects from "components/Base/Base/styleSpecs";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";
import Tooltip from "components/Global/Tooltip";

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

class GeneralActions extends Component {
  handleAction = id => {
    const { data: row, field: column } = this.props;
    if (id === "edit") {
      this.props.execute({
        actionId: ROW_ACTIONS.EDIT_RECORD,
        row,
        column
      });
    } else if (id === "delete") {
      this.props.execute({
        actionId: ROW_ACTIONS.DELETE_RECORD,
        row,
        column
      });
    } else if (id === "view") {
      this.props.execute({
        actionId: ROW_ACTIONS.OPEN_RECORD,
        row,
        column
      });
    } else if (id === "select") {
      this.props.execute({
        actionId: ROW_ACTIONS.SELECT_RECORD,
        row,
        column
      });
    } else if (id === "play") {
      this.props.execute({
        actionId: ROW_ACTIONS.PLAY_RECORD,
        row,
        column
      });
    } else if (id === "download") {
      this.props.execute({
        actionId: ROW_ACTIONS.DOWNLOAD_RECORD,
        row,
        column
      });
    } else if (id === ROW_ACTIONS.TRANSCRIBE) {
      this.props.execute({
        actionId: ROW_ACTIONS.TRANSCRIBE,
        row,
        column
      });
    } else if (id === ROW_ACTIONS.VIEW_TRANSCRIPT) {
      this.props.execute({
        actionId: ROW_ACTIONS.VIEW_TRANSCRIPT,
        row,
        column
      });
    } else if (id === ROW_ACTIONS.COPY) {
      this.props.execute({
        actionId: ROW_ACTIONS.COPY,
        row,
        column
      });
    }
  };

  render() {
    const actions = getValue(this.props) || [];
    const toRender = actions.map((a, idx) => {
      const content =
        a.id === "delete" ? (
          <DeleteConfirmation
            text="Are you sure?"
            confirmLabel="Delete"
            onConfirm={() => this.handleAction(a.id)}
          >
            <div
              className="underline-on-hover"
              style={{
                color: styleSpects.color.primary9
              }}
            >
              {a.name}
            </div>
          </DeleteConfirmation>
        ) : (
          <div
            onClick={a.text ? undefined : () => this.handleAction(a.id)}
            className={a.text ? undefined : "underline-on-hover"}
            style={{
              color: a.text ? "#000" : styleSpects.color.primary9
            }}
          >
            {a.name}
          </div>
        );

      return (
        <React.Fragment key={a.id}>
          {a.tooltip ? (
            <Tooltip tooltip={a.tooltip}>{content}</Tooltip>
          ) : (
            content
          )}

          {actions.length - 1 !== idx ? (
            <span
              style={{
                fontWeight: "600",
                color: "#333"
              }}
            >
              &nbsp;|&nbsp;
            </span>
          ) : null}
        </React.Fragment>
      );
    });
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "14px"
        }}
      >
        {toRender}
      </div>
    );
  }
}

export default decorate(GeneralActions);
