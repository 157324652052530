import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { NAMESPACE, STATUS, VIEWS } from "Orders/List/constants";

const iniState = {
  loading: false,
  ordersList: {},
  invoicesList: {},
  transactionsList: {},
  pagination: {
    pageSize: 10,
    pages: 0,
    page: 0
  },
  tabStats: Object.values(STATUS).reduce(
    (acc, val) => ({ ...acc, [val]: 0 }),
    {}
  ),
  searchTerm: "",
  view: VIEWS.ORDERS
};

const STATUS_MAP = {
  fullfilled: "fulfilled",
  pending_review: "pending",
  free: "free_order"
};

const mapStatus = status => STATUS_MAP[status] || status;

const mapIndexed = R.addIndex(R.map);

const mapOrders = (order, idx) => ({
  orderId: order.order_id,
  orderNumber: order.order_number,
  contact: {
    name: order.contact,
    id: order.contact_id
  },
  approvalStatus: mapStatus(order.approval_status),
  paymentStatus: mapStatus(order.payment_status),
  fulfillmentStatus: mapStatus(order.fulfillment_status),
  total: order.price,
  account: {
    name: order.account,
    id: order.account_id
  },
  handlers: {},
  id: idx
});

const mapInvoices = (invoice, idx) => ({
  orderId: invoice.order_id,
  invoiceNumber: invoice.invoice_number,
  customer: {
    name: invoice.contact,
    contactType: "",
    photoUrl: "",
    groupName: ""
  },
  paymentStatus: invoice.payment_status,
  total: invoice.total,
  notes: invoice.notes,
  created: {
    createdAt: invoice.created_at,
    by: ""
  },
  dueDate: invoice.due_date,
  id: idx
});

const mapTransactions = (transaction, idx) => ({
  orderId: transaction.order_id,
  invoiceNumber: transaction.order_number,
  transactionId: transaction.stripe_transaction_id,
  customer: {
    name: transaction.contact,
    id: transaction.contact_id,
    contactType: "",
    photoUrl: "",
    groupName: ""
  },
  paymentStatus: transaction.payment_status || "",
  total: transaction.transaction_amount,
  transactedAt: transaction.created_at,
  id: idx
});

const viewMapper = {
  [VIEWS.ORDERS]: mapOrders,
  [VIEWS.INVOICES]: mapInvoices,
  [VIEWS.TRANSACTIONS]: mapTransactions
};

const viewList = {
  [VIEWS.ORDERS]: "ordersList",
  [VIEWS.INVOICES]: "invoicesList",
  [VIEWS.TRANSACTIONS]: "transactionsList"
};

const viewAllCounter = {
  [VIEWS.ORDERS]: STATUS.ALL_ORDERS,
  [VIEWS.INVOICES]: STATUS.ALL_INVOICES,
  [VIEWS.TRANSACTIONS]: STATUS.ALL_TRANSACTIONS
};

const reducers = {
  receiveList: ({ view }, { payload }) => {
    return {
      [viewList[view]]: R.indexBy(
        R.prop("id"),
        mapIndexed(viewMapper[view], payload.detail)
      ),
      pagination: {
        ...payload.pagination,
        total: Number(payload.pagination.total),
        page: payload.pagination.page - 1 < 0 ? 0 : payload.pagination.page - 1
      },
      tabStats: {
        ...payload.counters,
        [viewAllCounter[view]]: R.pathOr(0, ["counters", "all"], payload)
      }
    };
  },
  setPageSize: ({ pagination }, { payload }) => ({
    pagination: {
      ...pagination,
      pageSize: payload
    }
  }),
  setCurrentPage: ({ pagination }, { payload }) => ({
    pagination: {
      ...pagination,
      page: payload
    }
  }),
  init: R.identity,
  exportData: R.identity,
  archiveOrder: R.identity,
  deleteOrder: R.identity,
  unarchiveOrder: R.identity,
  search: R.identity
};

export const handlers = createHandlers({
  iniState,
  reducers,
  namespace: NAMESPACE
});

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule({
  ...handlers,
  observedDomains: ["user", "event", "permissions", "organization"]
});

export default module;
