import React from "react";

import {
  Div,
  TinyToggle,
  DownIcon,
  RightIcon,
  DragIcon,
  Cards,
  MediumClearButton,
  LeftArrowIcon,
  AddIcon
} from "components/Base";

import {
  Toolbar,
  Sidebar,
  Separator,
  InputGroup,
  InputLabel,
  Input
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";

const AddField = ({ onAddField, label }) => (
  <Div
    display="row.flex-start.center"
    fw={3}
    my={2}
    color={{
      default: "gray6",
      hover: "gray7"
    }}
    onClick={onAddField}
  >
    <AddIcon size={20} mr={1} /> {label}
  </Div>
);

const Field = ({ name, type, isToggled, onToggle, isRequired, onRequire }) => (
  <Div
    display="row.flex-start.center"
    p={2}
    mb={2}
    bra={1}
    b={1}
    bc="neutral2"
    bg="white"
  >
    {isToggled ? (
      <DragIcon size={20} mr={3} style={{ flexShrink: 0, cursor: "move" }} />
    ) : null}

    <Div display="row.space-between.center" width={1}>
      <Div display="column" width={1}>
        <Div fw={3} fs={2} color="gray9" width={160} truncate>
          {name}
        </Div>
        <Div color="gray6" fs={1} truncate>
          {type}
        </Div>
      </Div>
      <Div display="row.flex-start.center">
        <TinyToggle
          mr={isToggled ? 4 : 0}
          active={isToggled}
          onClick={onToggle}
          style={{ flexShrink: 0 }}
        />
        {isToggled ? (
          <TinyToggle
            active={isRequired}
            onClick={onRequire}
            style={{ flexShrink: 0 }}
          />
        ) : null}
      </Div>
    </Div>
  </Div>
);

const OrderFields = Cards(Field, "ORDER_FIELDS");

const View = ({
  field,
  orderFields,
  onToggleOrderFields,
  showUntoggledOrderFields,
  onReorderOrderFields,
  updateBlockName,
  updateBlockDescription,
  onReturn,
  onAddOrderField
}) => (
  <Sidebar>
    <Toolbar>
      <LeftArrowIcon sizeWFS={4} mr={3} onClick={onReturn} />
      <Div fs={4} fw={3} color="gray7" flex={1}>
        Order Details
      </Div>
      <MediumClearButton onClick={onReturn} color="primary5">
        Done
      </MediumClearButton>
    </Toolbar>

    <Div py={4} px={6}>
      <InputGroup>
        <InputLabel>Name</InputLabel>
        <Input
          value={field ? field.name : ""}
          onChange={value => updateBlockName(value)}
        />
      </InputGroup>
      <InputGroup>
        <InputLabel>Description</InputLabel>
        <Input
          value={field ? field.settings.description || "" : ""}
          onChange={value => updateBlockDescription(value)}
        />
      </InputGroup>

      <Separator />

      <Div display="row.space-between.center" mb={2}>
        <InputLabel>Order Fields</InputLabel>
        {orderFields.toggled.length ? (
          <Div display="row.flex-start.center" mr={3}>
            <Div color="gray5" fs={1} mr={2}>
              Include
            </Div>
            <Div color="gray5" fs={1}>
              Require
            </Div>
          </Div>
        ) : null}
      </Div>
      <Div>
        <OrderFields
          cards={orderFields.toggled}
          onReorder={onReorderOrderFields}
        />

        <Div display="row.space-between.flex-end" mt={3} ml={1}>
          <Div display="row.flex-start.center" onClick={onToggleOrderFields}>
            {showUntoggledOrderFields ? (
              <DownIcon size={25} mr={1} color="gray7" />
            ) : (
              <RightIcon size={25} mr={1} color="gray7" />
            )}
            <Div color="gray7" fs={2} fw={3}>
              See all order fields
            </Div>
          </Div>
          {showUntoggledOrderFields ? (
            <Div display="row.flex-start.center" mr={5}>
              <Div color="gray5" fs={1}>
                Include
              </Div>
            </Div>
          ) : null}
        </Div>

        {showUntoggledOrderFields ? (
          <Div mx={3} mt={3}>
            {orderFields.untoggled.map(f => (
              <Field key={f.id} {...f} />
            ))}

            <AddField
              label="Create new Order field"
              onAddField={onAddOrderField}
            />
          </Div>
        ) : null}
      </Div>
    </Div>
  </Sidebar>
);

export default View;
