import resolveFormatterRDG from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import FileCellWithViewerFormatter from "components/Global/ReactTable/CellFormatters/File";
import Status from "components/Global/ReactTable/CellFormatters/Status";
import OrderStatus from "components/Global/ReactTable/CellFormatters/OrderStatus";
import AccountNameFormatter from "components/Global/ReactTable/CellFormatters/AccountNameFormatter";
import ACCOUNT_FIELD_IDS from "components/Event/Accounts/constants";

/**
 * Use this to override any cell formatters *specifically* for `ReactTable`.
 * Otherwise it will fallback to the ReactDataGrid `resolveFormatter` util.
 */

export default function resolveFormatter({ type, id, ...rest }) {
  switch (true) {
    case id === ACCOUNT_FIELD_IDS.name:
      return AccountNameFormatter;
    case type === "file":
      return FileCellWithViewerFormatter;
    case type === "status":
      return Status;
    case type === "order_status":
      return OrderStatus;
    default:
      return resolveFormatterRDG({ id, type, ...rest });
  }
}
