import { get } from "lodash";

export const countTotalByStatus = (status, state) =>
  Object.keys(get(state.inventory.modules.counts, status, {})).reduce(
    (total, key) => countsByModuleAndStatus(key, status, state) + total,
    0
  );

export const countsByModuleAndStatus = (moduleId, status, state) =>
  get(state.inventory.modules.counts, [status, moduleId], 0);

export const countsByStatus = (status, state) =>
  get(state.inventory.modules.counts, [status], {});
