import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import classnames from "classnames";
import SelectAll from "components/Global/ReactTable/CellFormatters/SelectAllCell";

const RowsSelected = CSSModules(
  ({
    children,
    className,
    ActionsComponent,
    selectedRows,
    helpers,
    helpers: { selectAll, isSelectAllChecked },
    ...rest
  }) => (
    <div className={classnames("rt-thead", className)} {...rest}>
      {selectedRows && selectedRows.length && className !== "-headerGroups" ? (
        <div className={css.selectedContainer}>
          <div
            className={classnames("rt-th", css.columnContainer)}
            style={{ width: 69 }}
          >
            <SelectAll checked={isSelectAllChecked()} onClick={selectAll} />
          </div>
          <ActionsComponent
            clearSelected={selectAll}
            selectedRows={selectedRows}
            helpers={helpers}
          />
        </div>
      ) : (
        children
      )}
    </div>
  ),
  css
);

export default headerProps => ({ ...props }) => (
  <RowsSelected {...props} {...headerProps} />
);

RowsSelected.propTypes = {
  children: PropTypes.object.isRequired,
  className: PropTypes.string
};
