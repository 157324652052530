import React from "react";

import { indexedColors } from "components/Base/Base/colorSpecs";

import { noop } from "utils/General";

const Circle = ({
  size,
  children,
  bg = "neutral3",
  style,
  className,
  onClick = noop,
  "data-cy": dataCy
}) => (
  <div
    display="row.center.center"
    className={className}
    style={{
      backgroundColor: indexedColors[bg] || bg,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...style,
      width: size,
      height: size,
      borderRadius: "50%"
    }}
    onClick={onClick}
    data-cy={dataCy}
  >
    {children}
  </div>
);

Circle.defaultProps = {
  style: {}
};

export default Circle;
