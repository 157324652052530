import { createModule } from "redux-mvc";

import sliderSidebar from "ui-kit/SliderSidebar";
import searchBarModule from "ui-kit/SearchBar";

import model from "./model";
import rootSaga from "./sagas";

const module = createModule({ ...model, optimist: true });

module.plugModule(sliderSidebar);
module.plugModule(searchBarModule);
module.setRootSaga(rootSaga);

export default module;
