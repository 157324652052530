import React, { useEffect } from "react";
import { connect } from "react-redux";

import Body from "./Body";

import { actions } from "../model";

const decorate = connect(
  null,
  {
    init: actions.init
  }
);

const Layout = ({ init }) => {
  useEffect(() => {
    init();
  }, []);

  return <Body />;
};

export default decorate(Layout);
