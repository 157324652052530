import * as R from "ramda";

import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { eventDetails } from "redux/modules/event/selectors";

import { getValue } from "../../utils";
import { actions } from "../../model";

const decorate = connect(
  state => ({
    eventDetails: eventDetails(state)
  }),
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class OrderName extends Component {
  goToAccountProfile = accountId => {
    window.open(
      `/event-light/${this.props.eventDetails.id}/account/${accountId}`,
      "_blank"
    );
  };

  goToContactProfile = contactId => {
    window.open(
      `/event-light/${this.props.eventDetails.id}/contact/${contactId}`,
      "_blank"
    );
  };

  render() {
    const value = getValue(this.props, R.identity, {});
    const orderType = getValue(this.props, "orderType");
    const groupType = getValue(this.props, "groupType");
    const groupName = getValue(this.props, "groupName");
    const personName = getValue(this.props, "personName");

    return (
      <div
        style={{
          paddingLeft: "8px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          color: "rgb(73, 73, 73)"
        }}
      >
        {orderType === "individual" ? (
          <>
            <div
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "rgb(222, 222, 222)",
                borderRadius: "100px",
                fontWeight: 600,
                fontSize: "16px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0
              }}
            >
              {R.path(["personName", 0], value)}
            </div>
            <div
              style={{
                marginLeft: "8px",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer"
                }}
                className="underline-on-hover"
                onClick={() => this.goToContactProfile(value.personId)}
              >
                {personName}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textTransform: "uppercase",
                  fontSize: "10px"
                }}
              >
                {groupType}
                {groupType && groupType.length ? (
                  <div
                    style={{
                      margin: "0 4px",
                      fontSize: "10px"
                    }}
                  >
                    ·
                  </div>
                ) : null}
                {groupName && groupName.length ? (
                  <div
                    style={{
                      fontSize: "10px",
                      cursor: "pointer",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    className="underline-on-hover"
                    onClick={() => this.goToAccountProfile(value.groupId)}
                  >
                    {groupName}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "rgb(222, 222, 222)",
                borderRadius: "100px",
                fontWeight: 600,
                fontSize: "16px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0
              }}
            >
              {R.path(["groupName", 0], value)}
            </div>
            <div
              style={{
                marginLeft: "8px",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer"
                }}
                className="underline-on-hover"
                onClick={() => this.goToAccountProfile(value.groupId)}
              >
                {groupName}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textTransform: "uppercase",
                  fontSize: "10px"
                }}
              >
                {groupType}
                {groupType && groupType.length ? (
                  <div
                    style={{
                      margin: "0 4px",
                      fontSize: "10px"
                    }}
                  >
                    ·
                  </div>
                ) : null}
                {personName && personName.length ? (
                  <div
                    style={{
                      fontSize: "10px",
                      cursor: "pointer",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    className="underline-on-hover"
                    onClick={() => this.goToContactProfile(value.personId)}
                  >
                    {personName}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default decorate(OrderName);
