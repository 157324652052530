import React, { Component } from "react";
import { get, isEqual } from "lodash";
import getValue from "utils/value-types/get-value/catalog-item";
import OptionsList from "./List";
import OptionQuantity from "./QuantityInput";

class Option extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(value) {
    return getValue(value);
  }

  handleClick(props) {
    if (props.column && props.column.onClick) {
      return () => props.column.onClick(props);
    }
    return null;
  }

  render() {
    const { value, dependentValues } = this.props;
    const type = get(dependentValues, "meta.columnSettings.selectType");
    const options = get(
      dependentValues,
      "meta.columnSettings.availableCatalogItems"
    );
    const order = get(dependentValues, "meta.columnSettings.order");
    const records = this.formatValue(value).records;

    switch (type) {
      case "quantity-only-dropdown":
      case "quantity-only-input":
        return (
          <OptionQuantity
            quantity={get(records, ["0", "quantity"])}
            onClick={this.handleClick(this.props)}
          />
        );
      default:
        return (
          <OptionsList
            order={order}
            options={options}
            records={records}
            onClick={this.handleClick(this.props)}
          />
        );
    }
  }
}

export default Option;
