import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import Text from "components/Global/Table3/ModalColumn/TextSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      textSettings: getters.textSettings(state, props)
    }),
    {
      setTextSettings: actions.setTextSettings
    }
  ),
  CSSModules(css)
);

const TextSettings = ({ textSettings, setTextSettings = noop }) => (
  <div styleName="dropdownOptionsWrapper">
    <Text settings={textSettings} updateTextSettings={setTextSettings} />
  </div>
);

export default decorate(TextSettings);
