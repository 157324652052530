import React, { Fragment } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { Div, Text3, FontIcon, MediumTextButton } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { getBreadcrumbs } from "../selectors";
import { withProps } from "utils/General";

const VideoIcon = withProps({
  children: "smart_display",
  color: "primary5",
  fs: 4
})(FontIcon);

const BookIcon = withProps({
  children: "menu_book",
  color: "primary5",
  ml: 2,
  fs: 4
})(FontIcon);

const decorate = R.compose(
  CSSModules(css),
  withRouter,
  connect((state, props) => ({
    breadcrumbLinks: getBreadcrumbs(state, props)
  }))
);

const Breadcrumbs = ({ breadcrumbLinks = [] }) => {
  return (
    <div className={css.breadcrumbs}>
      <Div className={css.breadcrumbLinks}>
        {R.addIndex(R.map)(
          (breadcrumb, idx) => (
            <Fragment key={breadcrumb.id}>
              <Link to={breadcrumb.link} key={breadcrumb.id}>
                <Text3 color="neutral7" mx={1}>
                  {breadcrumb.label}
                </Text3>
              </Link>
              {idx !== breadcrumbLinks.length - 1 ? <span> > </span> : ""}
            </Fragment>
          ),
          breadcrumbLinks
        )}
      </Div>

      <Div className={css.helpLinks}>
        <MediumTextButton color="primary5" LeftIcon={VideoIcon}>
          Watch a Video
        </MediumTextButton>
        <MediumTextButton color="primary5" LeftIcon={BookIcon}>
          Learn More
        </MediumTextButton>
      </Div>
    </div>
  );
};

export default decorate(Breadcrumbs);
