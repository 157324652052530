import React from "react";
import * as R from "ramda";
import { Div, PeopleIcon } from "components/Base";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import {
  getFiltersOn,
  getColumnActions,
  getRowActions,
  getPeopleFilterOn,
  getPeopleFilterMessage
} from "../selectors";
import { actions, getters } from "Modules/Dashboard";
import { TABLE_INSTANCE_ID } from "Modules/Dashboard/constants";

import PaginationBar from "./PaginationBar";
import Toolbar from "./Toolbar";
import Tabs from "./Tabs";
import FiltersOn from "ui-kit/FiltersOn";

import ShowNoResults from "ui-kit/ShowNoResults";

import Table, { TableWrapper } from "ui-kit/Table/View";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      filtersOn: getFiltersOn(state, props),
      loading: getters.loading(state, props),
      showNoResults: getters.showNoResults(state, props),
      preferences: getters.preferences(state, props),
      columnActions: getColumnActions(state, props),
      rowActions: getRowActions(state, props),
      peopleFilterOn: getPeopleFilterOn(state, props),
      peopleFilterMessage: getPeopleFilterMessage(state, props)
    }),
    {
      onClearFilters: actions.clearFilters,
      updatePeopleType: actions.updatePeopleType
    }
  )
);

const Body = ({
  handlers,
  filtersOn,
  onClearFilters,
  rowActions = [],
  columnActions = [],
  loading = false,
  showNoResults = false,
  peopleFilterOn,
  peopleFilterMessage,
  updatePeopleType
}) => (
  <Div flex={1} display="column.flex-start.stretch" height={1}>
    <Toolbar handlers={handlers} />
    <Tabs />
    <FiltersOn
      on={peopleFilterOn}
      onClearFilters={() => updatePeopleType(null)}
      caption={peopleFilterMessage}
      Icon={PeopleIcon}
      bg="primary1"
    />
    <FiltersOn
      on={filtersOn}
      onClearFilters={onClearFilters}
      caption="Only showing results that contain or match filters"
    />
    {showNoResults ? (
      <ShowNoResults flex={1} />
    ) : (
      <TableWrapper
        loading={loading}
        style={{ flex: 1 }}
        instanceId={TABLE_INSTANCE_ID}
      >
        <Table
          instanceId={TABLE_INSTANCE_ID}
          rowActions={rowActions}
          columnActions={columnActions}
          showEmptyTable={true}
          showOpenRecord={peopleFilterOn ? false : true}
        />
      </TableWrapper>
    )}
    <PaginationBar />
  </Div>
);

export default decorate(Body);
