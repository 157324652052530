import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Popover from "./Popover";
import { groupBy } from "lodash";

import { cateringSettings } from "redux/modules/portal/groupManager/selectors";
import { resources } from "redux/modules/portal/groupManager/resources/selectors";
import { MEAL_TYPE_ID } from "utils/item-types";
import { datesInRanges } from "components/PortalV2/GroupManager/Popovers/Credentials/utils";

const getMealResources = availableResources =>
  availableResources.filter(r => r.type_id === MEAL_TYPE_ID);

const getMeals = mealResources => {
  const groupedByItems = groupBy(mealResources, "item_id");
  const items = Object.keys(groupedByItems).reduce((map, itemId) => {
    if (!map[itemId]) {
      const resource = groupedByItems[itemId][0];
      map[itemId] = {
        id: itemId,
        value: resource.name,
        textColor: "#000000",
        color:
          resource.background_color && resource.background_color.length
            ? resource.background_color
            : null,
        item: resource.item
      };
    }
    return map;
  }, {});
  return Object.keys(items)
    .reduce((a, b) => {
      a.push(items[b]);
      return a;
    }, [])
    .sort((a, b) => a.item.order - b.item.order);
};

const getMealItems = mealResources => {
  const groupedByItems = groupBy(mealResources, "item_id");
  return Object.keys(groupedByItems).reduce((list, itemId) => {
    list.push({
      id: itemId,
      variants: groupedByItems[itemId]
    });
    return list;
  }, []);
};

const getMealDays = (daysOnSite, mealResources) => {
  const mealDays = [];
  mealResources.forEach(r =>
    r.dates_available.map(d =>
      mealDays.push({
        dayId: d,
        mealId: r.item_id,
        variantId: r.id,
        item: r.item
      })
    )
  );
  return mealDays;
};

const getSelectedMeals = (mealResources, existingAssignments) => {
  const selectedMeals = existingAssignments.reduce((list, assignment) => {
    const variant = mealResources.find(r => r.id === assignment.id);
    if (variant) {
      if (!list[variant.item_id]) {
        list[variant.item_id] = {
          id: variant.item_id,
          name: variant.name,
          dates: variant.dates_available.map(date => ({
            date,
            quantity: 1
          }))
        };
      } else {
        list[variant.item_id].dates = [
          ...list[variant.item_id].dates,
          ...variant.dates_available.map(date => ({
            date,
            quantity: 1
          }))
        ];
      }
    }
    return list;
  }, {});

  mealResources.forEach(meal => {
    if (!selectedMeals[meal.item_id]) {
      selectedMeals[meal.item_id] = {
        id: meal.item_id,
        name: meal.name,
        dates: []
      };
    }
  });

  return Object.keys(selectedMeals).reduce((list, mealId) => {
    list.push(selectedMeals[mealId]);
    return list;
  }, []);
};

function mapStateToProps(state, { member, assignments }) {
  const eventCateringSettings = cateringSettings(state);
  const allAvailableResources = resources(state);
  const mealResources = getMealResources(allAvailableResources);
  const mealDays = getMealDays(member.contact.days_on_site, mealResources);
  const selectedDays = datesInRanges(member.contact.days_on_site).filter(d =>
    mealDays.find(md => md.dayId === d)
  );
  const mealItems = getMealItems(mealResources);
  const existingAssignments = assignments.filter(
    a => a.typeId === MEAL_TYPE_ID
  );
  const selectedMeals = getSelectedMeals(mealResources, existingAssignments);

  return {
    selectedMeals,
    resources: allAvailableResources,
    settings: eventCateringSettings,
    mealDays,
    mealItems,
    meals: getMeals(mealResources),
    selectedDays,
    rowMetaData: {
      days_on_site: { value: member.contact.days_on_site },
      meta: {
        columns: [
          {
            type: "event-days",
            id: "days_on_site"
          }
        ]
      }
    }
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Popover);
