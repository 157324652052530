import React, { Component } from "react";
import * as R from "ramda";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";

import { noop } from "utils/General";
import { getValue, getSettings } from "../../utils";

import styleSpects from "components/Base/Base/styleSpecs";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class HasPortalAccess extends Component {
  showAddToPortalModal = () => {
    const contactId =
      getValue(this.props, "contactId") || R.path(["data", "id"], this.props);
    const userId = getValue(this.props, "userId");

    this.props.showModal({
      content: (
        <BulkLoginDetails
          contacts={[
            {
              contactId
            }
          ]}
          shareOnly={Boolean(userId)}
          onDone={this.props.onDone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const userId = getValue(this.props, "userId");
    const fname = getValue(this.props, "user_fname");
    const lname = getValue(this.props, "user_lname");
    const email = getValue(this.props, "user_email");
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    if (!userId) {
      return (
        !isFieldReadOnly && (
          <div
            onClick={this.showAddToPortalModal}
            style={{
              color: styleSpects.color.gray6,
              fontSize: styleSpects.fontSize[3],
              cursor: "pointer",
              textDecoration: "underline"
            }}
          >
            + Invite to portal
          </div>
        )
      );
    }

    return (
      <div
        onClick={isFieldReadOnly ? noop : this.showAddToPortalModal}
        className={isFieldReadOnly ? "" : "underline-on-hover"}
        style={{
          cursor: "pointer",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {[fname, lname].filter(v => v).join(" ")} ({email})
      </div>
    );
  }
}

export default decorate(HasPortalAccess);
