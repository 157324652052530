/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";
import {
  MediumTextButton,
  Div,
  Text1,
  Type9Card,
  NoShrink
} from "components/Base";
import css from "./styles.scss";

const PassSection = ({ rows }) => {
  return (
    <Div>
      {R.map(
        ({
          title: cardTitle,
          color,
          quantity,
          updateVariantQuantity,
          additionalInfo
        }) => {
          return (
            <Type9Card
              mb={1}
              color={color}
              title={cardTitle}
              subTitle={additionalInfo}
              count={quantity}
              onChangeCount={updateVariantQuantity}
            />
          );
        },
        rows
      )}
    </Div>
  );
};

const Group = ({ showTitle, title, sections }) => {
  return showTitle ? (
    <Div>
      <Div fw={3} mt={5} mb={2} color="gray7">
        {title}
      </Div>
      <Div>{R.map(PassSection, sections)}</Div>
    </Div>
  ) : (
    <Div>{R.map(PassSection, sections)}</Div>
  );
};

const CredentialsList = ({ items, limit, handleSave, isSaving }) => {
  return (
    <Div>
      <Div>
        <Div px={1} style={{ maxHeight: 300, overflowY: "auto" }}>
          {R.map(
            group => (
              <Group key={group.title} {...group} />
            ),
            items.groups
          )}
        </Div>
      </Div>
      <NoShrink
        bt={1}
        bc={"neutral1"}
        px={3}
        py={2}
        mt={2}
        display="row.space-between.center"
      >
        <Div display="row.flex-start.center">
          <Text1 uppercase bold>
            {`Quantity: ${items.quantity}`}
          </Text1>
          {limit ? (
            <Text1 ml={1} uppercase bold>
              ({`Limit: ${limit}`})
            </Text1>
          ) : null}
        </Div>
        <MediumTextButton
          color={"primary7"}
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? (
            <span>
              <i
                className={["fa", "fa-circle-o-notch", css.processingIcon].join(
                  " "
                )}
              />
              SAVING
            </span>
          ) : (
            "SAVE"
          )}
        </MediumTextButton>
      </NoShrink>
    </Div>
  );
};

export { CredentialsList };
