import React from "react";
import { Div } from "components/Base";
import Panel from "./Panel";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import Sections from "./Sections/Sections";
import Header from "./Header";
import CSSModules from "react-css-modules";

import css from "./styles.scss";

const MainSection = () => (
  <Panel styleProps={{ marginBottom: 23 }} className="portal-page">
    <Header />
    <Div className="main-section">
      <Div className="sections">
        <Sections />
      </Div>
      <Div className="player">
        <VideoPlayer />
      </Div>
    </Div>
  </Panel>
);

export default CSSModules(MainSection, css);
