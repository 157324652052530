import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  data: {
    design_html: "",
    wrapper_html: "",
    settings_json: ""
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    setInitialData: (_, { payload }) => ({
      loading: false,
      data: payload
    }),
    updateValue: ({ data }, { payload: { id, value } }) => ({
      data: {
        ...data,
        [id]: value
      }
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
