export default function getMetaData({
  row = {},
  rowId,
  field,
  fields = [],
  orgDetails,
  eventDetails,
  userId,
  orgId,
  eventId,
  moduleId,
  references
}) {
  return {
    ...row,
    meta: {
      references,
      rowId,
      columns: fields,
      columnId: field.id,
      columnSettings: field.settings,
      orgDetails,
      eventDetails,
      userId,
      orgId,
      eventId,
      moduleId
    }
  };
}
