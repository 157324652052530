import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { find } from "lodash";
import { Link } from "react-router";

import { MODULES } from "components/Global/NavIcons";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

// import ModalWrapper from 'components/Global/Modal/Wrappers/Black';
// import AddModuleModal from 'components/Event/Settings/Modals/AddModuleModal';
import CanUserDo from "components/Global/Security/CanUserDo";

const getIcon = tabKey => {
  if (MODULES[tabKey]) {
    return MODULES[tabKey].icon;
  }
  return "";
};

@CSSModules(css)
class Sidebar extends Component {
  activeTab = tabKey => {
    const { routes, params } = this.props;
    if (find(routes, { name: "organizationSettingsGeneral" })) {
      return tabKey === "settings";
    } else if (
      find(routes, { name: "organizationSettingsUsers" }) ||
      find(routes, { name: "organizationSettingsPermissions" })
    ) {
      return tabKey === "users";
    }
    return tabKey === `module-${params.moduleId}`;
  };

  render() {
    // const { details, userPermissionProfile } = this.props;
    // const canDo = can(userPermissionProfile).do;
    const path = route =>
      `/organization/${this.props.params.orgId}/settings${route}`;

    return (
      <div styleName="leftColumn">
        <div styleName="inner">
          <div styleName="settingsTitle">
            <span styleName="navIcon">
              <i className="material-icons">settings</i>
            </span>{" "}
            Settings
          </div>

          {/* <CanUserDo
            any={[
              `${STANDARD_MODULE_IDS.settings.id}_update`,
              `${STANDARD_MODULE_IDS.settings.id}_manage_permissions`
            ]}
          > */}
          {/* <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_update`}> */}
          <Link
            to={path("")}
            styleName={this.activeTab("settings") ? "navItemActive" : "navItem"}
          >
            General Settings
          </Link>

          <Link
            to={path("/users")}
            styleName={this.activeTab("users") ? "navItemActive" : "navItem"}
          >
            Users
          </Link>

          <div styleName="navHeading">Manage</div>

          {/* accounts */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.accounts.id}_manage`}>
            <Link
              to={path(`/module/${STANDARD_MODULE_IDS.accounts.id}`)}
              styleName={
                this.activeTab(`module-${STANDARD_MODULE_IDS.accounts.id}`)
                  ? "navItemActive"
                  : "navItem"
              }
            >
              <span className="settings-icon" styleName="navIcon">
                {getIcon("accounts")}
              </span>{" "}
              Groups
            </Link>
          </CanUserDo>

          {/* contacts */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.contacts.id}_manage`}>
            <Link
              to={path(`/module/${STANDARD_MODULE_IDS.contacts.id}`)}
              styleName={
                this.activeTab(`module-${STANDARD_MODULE_IDS.contacts.id}`)
                  ? "navItemActive"
                  : "navItem"
              }
            >
              <span className="settings-icon" styleName="navIcon">
                {getIcon("contacts")}
              </span>{" "}
              People
            </Link>
          </CanUserDo>
        </div>
      </div>
    );
  }
}

export default Sidebar;
