import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  connect(state => ({
    data: getters.data(state)
  })),
  CSSModules(css)
);

const Header = ({ data }) => (
  <div styleName="header">
    <div styleName="title">{R.propOr("", "page_title", data)}</div>
    <div
      dangerouslySetInnerHTML={{
        __html: R.propOr("", "page_description", data)
      }}
    />
  </div>
);

export default decorate(Header);
