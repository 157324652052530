import Helpers from "utils/Global/Helpers";

export default {
  getAccountActivityLogs: (from, credentials, sectionId, accountId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      // eslint-disable-next-line no-unused-vars
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/activity/${from}/${sectionId}/account/${accountId}`,
        qs: { ...data },
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    }),
  getContactActivityLogs: (from, credentials, sectionId, contactId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      // eslint-disable-next-line no-unused-vars
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/activity/${from}/${sectionId}/contact/${contactId}`,
        qs: { ...data },
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
