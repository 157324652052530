import catering from "./catering";
import checkbox from "./checkbox";
import accountReference from "./account-reference";
import credentialOrderReference from "./credential-order-reference";
import contactReference from "./contact-reference";
import submitterReference from "./submitter-reference";
import reference from "./reference";
import contact from "./contact";
import countries from "./countries";
import credentials from "./credentials";
import date from "./date";
import datetime from "./datetime";
import dependency from "./dependency";
import dropdown from "./dropdown";
import eventDays from "./event-days";
import file from "./file";
import inventory from "./inventory";
import scheduleActivity from "./schedule-activity";
import phone from "./phone";
import radio from "./radio";
import email from "./email";
import text from "./text";
import textarea from "./textarea";
import time from "./time";
import number from "./number";
import percent from "./percent";
import currency from "./currency";

export default function(type) {
  switch (type) {
    case "catering":
      return catering;
    case "checkbox":
      return checkbox;
    case "contact-reference":
      return contactReference;
    case "contact":
      return contact;
    case "countries":
      return countries;
    case "credentials":
      return credentials;
    case "date":
      return date;
    case "datetime":
      return datetime;
    case "dependency":
      return dependency;
    case "dropdown":
      return dropdown;
    case "event-days":
      return eventDays;
    case "file":
      return file;
    case "inventory":
      return inventory;
    case "schedule-activity":
      return scheduleActivity;
    case "account-reference":
      return accountReference;
    case "credential-order-reference":
      return credentialOrderReference;
    case "submitter-reference":
      return submitterReference;
    case "reference":
      return reference;
    case "phone":
      return phone;
    case "radio":
      return radio;
    case "text":
      return text;
    case "email":
      return email;
    case "textarea":
      return textarea;
    case "time":
      return time;
    case "number":
      return number;
    case "percent":
      return percent;
    case "currency":
      return currency;
    default:
  }
}
