import React, { Component } from "react";
import * as R from "ramda";

import moment from "moment";
import getValue from "utils/value-types/get-value/date";
import { EDITOR_FORMAT_MAP } from "components/Global/Table3/ModalColumn/DateEditorSettings";
import { Div } from "components/Base";

import { getSettings } from "ui-kit/Datagrid/utils";

const FALLBACK_FORMAT = "dddd, MMMM D, YYYY";

class DateFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const { value } = this.props;
    const date = getValue(value);

    let dateFormat = getSettings(this.props, "dateFormat");
    if (!dateFormat) {
      const displayType = getSettings(this.props, "displayType");
      if (displayType && displayType in EDITOR_FORMAT_MAP) {
        dateFormat = EDITOR_FORMAT_MAP[displayType];
      } else {
        dateFormat = FALLBACK_FORMAT;
      }
    }

    const text = date ? moment(date).format(dateFormat) : "";

    return (
      <Div
        truncate
        title={text}
        style={{
          userSelect: "none",
          lineHeight: "34px"
        }}
      >
        {text}
      </Div>
    );
  }
}

export default DateFormatter;
