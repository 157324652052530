import { createHandlers } from "redux-mvc";
import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity
  }
});

export default handlers;
