import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import AccountNameFormatter from "components/Global/Table3/CellFormatters/AccountNameFormatter";
import ApprovalFormatter from "components/Global/Table3/CellFormatters/Approval";
import CalculatedNumberFormatter from "components/Global/Table3/CellFormatters/CalculatedNumber";
import CatalogItemFormatter from "components/Global/Table3/CellFormatters/CatalogItem";
import CateringCellFormatter from "components/Global/Table3/CellFormatters/Catering";
import CheckboxCellFormatter from "components/Global/Table3/CellFormatters/Checkbox";
import ContactNameFormatter from "components/Global/Table3/CellFormatters/ContactName";
import CountriesCellFormatter from "components/Global/Table3/CellFormatters/Countries";
import CredentialCellFormatter from "components/Global/Table3/CellFormatters/Credential";
import CurrencyFormatter from "components/Global/Table3/CellFormatters/Currency";
import DateCellFormatter from "components/Global/Table3/CellFormatters/Date";
import DateTimeCellFormatter from "components/Global/Table3/CellFormatters/DateTime";
import DietaryRestrictionsCellFormatter from "components/Global/Table3/CellFormatters/CateringDietaryRestrictions";
import EventDaysCellFormatter from "components/Global/Table3/CellFormatters/EventDays";
import FileCellFormatter from "components/Global/Table3/CellFormatters/File";
import FormRowApprovalCellFormatter from "components/Global/Table3/CellFormatters/FormRowApproval";
import FormRowHasChangesCellFormatter from "components/Global/Table3/CellFormatters/FormRowHasChanges";
import ItemBlockCellFormatter from "components/Global/Table3/CellFormatters/ItemBlock";
import LinkedRecordsFormatter from "components/Global/Table3/CellFormatters/LinkedRecords";
import MultipleSelectCellFormatter from "components/Global/Table3/CellFormatters/MultipleSelect";
import OrderSourceFormatter from "components/Global/Table3/CellFormatters/Source";
import OpenRecordFormatter from "components/Global/Table3/CellFormatters/OpenRecord";
import PercentFormatter from "components/Global/Table3/CellFormatters/Percent";
import ReferenceFormatter from "components/Global/Table3/CellFormatters/Reference";
import TextAreaCellFormatter from "components/Global/Table3/CellFormatters/TextArea";
import TextCellFormatter from "components/Global/Table3/CellFormatters/Text";
import TimeCellFormatter from "components/Global/Table3/CellFormatters/Time";
import UserCellFormatter from "components/Global/Table3/CellFormatters/User";
import EventListFormatter from "components/Global/Table3/CellFormatters/EventList";
import AccountListFormatter from "components/Global/Table3/CellFormatters/AccountList";
import ItemGroupFormatter from "components/Global/Table3/CellFormatters/ItemGroup";
import FormFormatter from "components/Global/Table3/CellFormatters/Form";
import DocumentRequestFormatter from "components/Global/Table3/CellFormatters/DocumentRequest";
import PrimaryContactsFormatter from "components/Global/Table3/CellFormatters/PrimaryContacts";
import PaymentFormatter from "components/Global/Table3/CellFormatters/Payment";
import AllocatedItemsFormatter from "components/Global/Table3/CellFormatters/AllocatedItems";
import OrderNameFormatter from "components/Global/Table3/CellFormatters/OrderName";
import OrderNumbersFormatter from "components/Global/Table3/CellFormatters/OrderNumbers";
import OrderItemFormatter from "components/Global/Table3/CellFormatters/OrderItem";
import OrderItemDetailsFormatter from "components/Global/Table3/CellFormatters/OrderItemDetails";
import OrderActionsFormatter from "components/Global/Table3/CellFormatters/OrderActions";
import SignatureFormatter from "components/Global/Table3/CellFormatters/Signature";

export default function resolveFormatter(column) {
  if (column.type === "open-record") {
    return OpenRecordFormatter;
  } else if (column.id === STANDARD_MODULE_FIELDS.ACCOUNTS.NAME) {
    return AccountNameFormatter;
  } else if (
    column.id === STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME ||
    column.id === STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME ||
    column.id === STANDARD_MODULE_FIELDS.MODULE.FULL_NAME
  ) {
    return ContactNameFormatter;
  } else if (column.type === "checkbox" || column.type === "boolean") {
    return CheckboxCellFormatter;
  } else if (column.type === "dropdown" || column.type === "dependency") {
    return MultipleSelectCellFormatter;
  } else if (column.type === "credentials") {
    return CredentialCellFormatter;
  } else if (column.type === "item-block") {
    return ItemBlockCellFormatter;
  } else if (column.type === "catering") {
    return CateringCellFormatter;
  } else if (column.type === "catering-dietary-restrictions") {
    return DietaryRestrictionsCellFormatter;
  } else if (column.type === "event-days") {
    return EventDaysCellFormatter;
  } else if (column.type === "date") {
    return DateCellFormatter;
  } else if (column.type === "datetime") {
    return DateTimeCellFormatter;
  } else if (column.type === "time") {
    return TimeCellFormatter;
  } else if (column.type === "user") {
    return UserCellFormatter;
  } else if (column.type === "file") {
    return FileCellFormatter;
  } else if (column.type === "countries") {
    return CountriesCellFormatter;
  } else if (column.type === "has-changes") {
    return FormRowHasChangesCellFormatter;
  } else if (column.type === "action-approve-form-row") {
    return FormRowApprovalCellFormatter;
  } else if (column.type === "reference") {
    return ReferenceFormatter;
  } else if (column.type === "source") {
    return OrderSourceFormatter;
  } else if (column.type === "percent") {
    return PercentFormatter;
  } else if (column.type === "calculated-number") {
    return CalculatedNumberFormatter;
  } else if (column.type === "currency") {
    return CurrencyFormatter;
  } else if (column.type === "lookup") {
    return LinkedRecordsFormatter;
  } else if (column.type === "catalog-item") {
    return CatalogItemFormatter;
  } else if (column.type === "textarea") {
    return TextAreaCellFormatter;
  } else if (column.type === "approval") {
    return ApprovalFormatter;
  } else if (column.type === "event-list") {
    return EventListFormatter;
  } else if (column.type === "account-list") {
    return AccountListFormatter;
  } else if (column.type === "item-group") {
    return ItemGroupFormatter;
  } else if (column.type === "document-request") {
    return DocumentRequestFormatter;
  } else if (column.type === "form") {
    return FormFormatter;
  } else if (column.type === "primary-contacts") {
    return PrimaryContactsFormatter;
  } else if (column.type === "payment") {
    return PaymentFormatter;
  } else if (column.type === "allocated-passes") {
    return AllocatedItemsFormatter;
  } else if (column.type === "allocated-meals") {
    return AllocatedItemsFormatter;
  } else if (column.type === "order-name") {
    return OrderNameFormatter;
  } else if (column.type === "order-numbers") {
    return OrderNumbersFormatter;
  } else if (column.type === "order-item") {
    return OrderItemFormatter;
  } else if (column.type === "order-item-details") {
    return OrderItemDetailsFormatter;
  } else if (column.type === "order-actions") {
    return OrderActionsFormatter;
  } else if (column.type === "signature") {
    return SignatureFormatter;
  }

  return TextCellFormatter;
}
