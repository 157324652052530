import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ModuleSummaries = ({
  counts,
  showApprovals,
  showRejections,
  showBudget,
  classNames
}) => (
  <div className={css.counts}>
    {counts.map((count, index) => (
      <ModuleSummary
        key={index}
        showApprovals={showApprovals}
        showRejections={showRejections}
        showBudget={showBudget}
        classNames={classNames}
        {...count}
      />
    ))}
  </div>
);

ModuleSummaries.defaultProps = {
  classNames: {}
};

ModuleSummaries.propTypes = {
  counts: PropTypes.array.isRequired,
  classNames: PropTypes.object
};

export default CSSModules(ModuleSummaries, css);

const ModuleSummary = ({
  label,
  values,
  showApprovals,
  showRejections,
  showBudget,
  classNames
}) => (
  <div className={css.field}>
    <div className={css.fieldGroup}>
      <div className={[css.fieldLabel, classNames.fieldLabel].join(" ")}>
        {label}
      </div>
      <div className={css.fieldGroup}>
        <div
          className={[css.fieldCountLabel, classNames.fieldCountLabel].join(
            " "
          )}
        >
          Req.
        </div>
        {showApprovals ? (
          <div
            className={[css.fieldCountLabel, classNames.fieldCountLabel].join(
              " "
            )}
          >
            Appr.
          </div>
        ) : (
          ""
        )}
        {showRejections ? (
          <div
            className={[css.fieldCountLabel, classNames.fieldCountLabel].join(
              " "
            )}
          >
            Rej.
          </div>
        ) : (
          ""
        )}
        {showBudget ? (
          <div
            className={[css.fieldCountLabel, classNames.fieldCountLabel].join(
              " "
            )}
          >
            Budget
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
    {values.map(
      ({ label: valueLabel, requested, approved, rejected, budget }, index) => (
        <div key={index} className={css.fieldGroup}>
          <div
            className={[css.fieldCountValue, classNames.fieldCountValue].join(
              " "
            )}
          >
            {valueLabel}
          </div>
          <div className={css.fieldGroup}>
            <div className={[css.fieldCount, classNames.fieldCount].join(" ")}>
              {requested}
            </div>
            {showApprovals ? (
              <div
                className={[css.fieldCount, classNames.fieldCount].join(" ")}
              >
                {approved}
              </div>
            ) : (
              ""
            )}
            {showRejections ? (
              <div
                className={[css.fieldCount, classNames.fieldCount].join(" ")}
              >
                {rejected}
              </div>
            ) : (
              ""
            )}
            {showBudget ? (
              <div
                className={[css.fieldCount, classNames.fieldCount].join(" ")}
              >
                {typeof budget !== "undefined" ? budget : "-"}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    )}
  </div>
);

ModuleSummary.propTypes = {
  showApprovals: PropTypes.bool,
  showRejections: PropTypes.bool,
  values: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  classNames: PropTypes.object
};
