import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Controller from "./Controller";

import { getForm, updateForm } from "redux/modules/formsV2/form/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";
import { getAssignments } from "redux/modules/formsV2/form/assignments/actions";
import {
  addAccountFormAssignment,
  updateAccountFormAssignment,
  removeAccountFormAssignment
} from "redux/modules/formsV2/form/assignments/account/actions";
import {
  addAccountTypeFormAssignment,
  removeAccountTypeFormAssignment
} from "redux/modules/formsV2/form/assignments/account-type/actions";
import {
  addContactFormAssignment,
  updateContactFormAssignment,
  removeContactFormAssignment
} from "redux/modules/formsV2/form/assignments/contact/actions";
import {
  addContactTypeFormAssignment,
  removeContactTypeFormAssignment
} from "redux/modules/formsV2/form/assignments/contact-type/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { form } from "redux/modules/formsV2/form/selectors";
import {
  eventDetails,
  isConferenceTemplate
} from "redux/modules/event/selectors";
import { assignments } from "redux/modules/formsV2/form/assignments/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  const formToPass = form(state);
  return {
    form: formToPass,
    eventDetails: eventDetails(state),
    isConferenceTemplate: isConferenceTemplate(state),
    assignments: assignments(state, formToPass.id),
    recordTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id),
    peopleRecordTypes: recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
    userId: user(state).id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      getForm,
      updateForm,
      getRecordTypes,
      getAssignments,
      addAccountFormAssignment,
      updateAccountFormAssignment,
      removeAccountFormAssignment,
      addAccountTypeFormAssignment,
      removeAccountTypeFormAssignment,
      addContactFormAssignment,
      updateContactFormAssignment,
      removeContactFormAssignment,
      addContactTypeFormAssignment,
      removeContactTypeFormAssignment
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
