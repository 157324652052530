import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";

import { Div } from "components/Base";
import LeftSection from "./LeftSection";
import CentralSection from "./CentralSection";
import RightSection from "./RightSection";
import RightSectionMaterial from "./RightSectionMaterial";
import VideoModal from "./VideoModal";
import SliderSidebarLayout from "ui-kit/SliderSidebar/View";

import CSSModules from "react-css-modules";
import NotificationModal from "Portal/PortalPagePGA/NotificationModal/View";

import { getters as SidebarGetters } from "ui-kit/SliderSidebar/model";

import css from "./styles.scss";

import { connect } from "react-redux";
import {
  getLeftSidebarColor,
  getNetworkChatLabel,
  getNetworkPeopleLabel,
  getEnablePolls,
  getEnableQA,
  getUseMaterialIcons
} from "../selectors";
import { SIDEBAR_INSTANCE_ID } from "../constants";

const decorator = R.compose(
  connect(state => ({
    leftSidebarBgColor: getLeftSidebarColor(state),
    networkChatLabel: getNetworkChatLabel(state),
    networkPeopleLabel: getNetworkPeopleLabel(state),
    enablePolls: getEnablePolls(state),
    enableQA: getEnableQA(state),
    useMaterialIcons: getUseMaterialIcons(state),
    isSidebarOpen: SidebarGetters.isDrawerOpen(state, {
      instanceId: SIDEBAR_INSTANCE_ID
    })
  })),
  withRouter
);

export const Body = ({ leftSidebarBgColor, useMaterialIcons }) => {
  const Right = useMaterialIcons ? RightSectionMaterial : RightSection;
  return (
    <>
      <Div className="portal-page-pga" id="pga-wrapper">
        <LeftSection
          className="portal-page-pga-left"
          style={{
            backgroundColor: leftSidebarBgColor
          }}
        />
        <CentralSection className="portal-page-pga-central" />
        <Right className="portal-page-pga-right" />
      </Div>
      <VideoModal />
      <NotificationModal />
      <SliderSidebarLayout
        position="right"
        width={385}
        instanceId={SIDEBAR_INSTANCE_ID}
        style={{
          borderRadius: 0
        }}
      ></SliderSidebarLayout>
    </>
  );
};

export default CSSModules(decorator(Body), css);
