import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import getRows from "utils/EventForms/FormGrid/getRows";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import { get } from "lodash";
import autobind from "autobind-decorator";
import Loading from "components/Global/Loading";

import CSSModules from "react-css-modules";
import css from "../styles.scss";

import NotFound from "components/Event/FormsV2/NotFound";

import PreviewBar from "components/Event/FormsV2/Sections/FormGridBarPreview";

import FormBackground from "components/Event/FormsV2/Sections/FormBackground";
import FormInfo from "components/Event/FormsV2/Sections/FormInfo";
import FormFooter from "components/Event/FormsV2/Form/FormFooter";

import Section from "components/Event/FormsV2/Sections/Section";

@CSSModules(css)
class FormHandler extends Component {
  getFormRows({
    schema,
    gridId,
    formValues,
    response,
    isApprovingForm,
    isFillingFormOut,
    isViewingApproval,
    recipientCanViewChanges
  }) {
    let rows = getRows({
      schema,
      gridId,
      formValues,
      response,
      isApprovingForm,
      isFillingFormOut,
      isViewingApproval,
      recipientCanViewChanges
    });

    if (get(response, "status") === "approval_sent") {
      rows = rows.filter(row => isRowNotEmpty(row, schema.columns));
    }

    return rows;
  }

  @autobind
  toggleEditing() {
    this.props.router.push({
      pathname: `/event/${this.props.form.event_id}/forms-v2/${this.props.form.id}/edit`
    });
  }

  @autobind
  getContextMenus() {
    return { headerCellMenu: [] };
  }

  componentDidMount() {
    this.props.getForm(this.props.params.formId);
  }

  render() {
    if (
      this.props.errorMessages.length ||
      (!this.props.isFetching && !this.props.form.id)
    ) {
      return <NotFound />;
    }

    return (
      <form ref="container" noValidate styleName="form" className="page-form">
        <PreviewBar
          location={this.props.location}
          router={this.props.router}
          form={this.props.form}
          toggleEditing={this.toggleEditing}
          isEditing={false}
        />

        {/* form container */}

        <div styleName="container">
          {/* form top */}
          <FormBackground
            backgroundUrl={
              this.props.form.background_image_url ||
              get(this.props.eventDetails, "background_image_url")
            }
            isEditing={false}
          />

          {/* form content */}
          <div styleName="contentContainer" className="page-form-content">
            {/* form header */}
            <div styleName="headerContainer">
              <div>
                <div styleName="headerTitle">{this.props.form.title}</div>
                <div styleName="headerDescription">
                  {this.props.form.description}
                </div>
              </div>
            </div>

            <div styleName="formContainer">
              {!this.props.isFetching ? (
                <Section
                  sectionId={0}
                  form={this.props.form}
                  preferences={this.props.preferences}
                  fields={this.props.sortedFormFields}
                  eventDetails={this.props.eventDetails}
                  getFormRows={this.getFormRows}
                  isEditing={false}
                  dispatch={this.props.dispatch}
                  showModal={this.props.showModal}
                  isFillingFormOut
                  isApprovingForm={false}
                  isResponseLocked
                  isViewingApproval={false}
                  isPreviewing
                />
              ) : (
                ""
              )}

              <div styleName="sendResponseRow">
                <div styleName="sendResponseDisabled">
                  <div>Send</div>
                  <i
                    className="material-icons"
                    styleName="sendResponseIconFooter"
                  >
                    &#xE163;
                  </i>
                </div>
              </div>

              <FormFooter formId={this.props.form.id} />
            </div>
          </div>
        </div>

        {this.props.isFetching ? (
          <Loading dark content="Loading form..." styleName="loading" />
        ) : (
          ""
        )}
      </form>
    );
  }
}

FormHandler.propTypes = {
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  preferences: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  sortedFormFields: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  getForm: PropTypes.func.isRequired,
  updateFieldOrder: PropTypes.func.isRequired
};

export default FormHandler;
