import Helpers from "../Global/Helpers";

module.exports = {
  receiveVirtualUser: virtualUserHash => {
    return new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/user/invite/${virtualUserHash}`,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    });
  },
  convertVirtualUser: ({ virtualUserHash, ...data }) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/user/invite/convert/${virtualUserHash}`,
        data,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    })
};
