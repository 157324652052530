import React from "react";
// import Forms from "../Forms";
import FileRequests from "EventLight/CRMProfile/FileRequests/View";
import Forms from "EventLight/CRMProfile/Forms/View";

import CredentialOrders from "../CredentialOrders";
import RelatedModule from "components/Global/CRM/RelatedModule";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { FILE_FIELDS } from "../constants";
import Reports from "../Reports";
import People from "../People";
import Activities from "Schedules/ScheduleProfileWidget";

import { connect } from "react-redux";

import { isEventFeatureEnabled } from "redux/modules/event/selectors";
import { FEATURES } from "utils/event-types-and-feature-constants";

const decorate = connect(state => ({
  canViewSchedules: isEventFeatureEnabled(state)(FEATURES.SCHEDULE)
}));

const View = ({
  eventDetails,
  name,
  fetchProfile,
  params,
  account,
  showChangeContactRecordTypeModal,
  canViewSchedules
}) => {
  const showFiles = eventDetails.enabled_modules.some(
    m => m.id === STANDARD_MODULE_IDS.files.id
  );

  const showDocumentRequests = eventDetails.enabled_modules.some(
    m => m.id === STANDARD_MODULE_IDS.documentRequests.id
  );

  const cardProps = {
    accountName: name,
    getAccount: fetchProfile,
    params
  };

  return [
    <>
      <Forms
        key="forms"
        recordId={params.recordId}
        forms={account.forms}
        {...cardProps}
      />
      {showDocumentRequests ? (
        <FileRequests
          key="file-requests"
          mode="admin"
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordId={params.recordId}
        />
      ) : null}
    </>,
    <CredentialOrders key="cOrders" {...cardProps} />,

    <Reports key="reports" forms={account.forms} />,

    <People
      key="people"
      users={account.users}
      contacts={account.related_contacts}
      showChangeContactRecordTypeModal={showChangeContactRecordTypeModal}
      {...cardProps}
    />,

    canViewSchedules && (
      <Activities key="activities" recordId={params.recordId} />
    ),

    showFiles ? (
      <RelatedModule
        module={{
          ...STANDARD_MODULE_IDS.files,
          event_id: params.eventId,
          preferences: {
            visible_fields: FILE_FIELDS.map(f => f.id),
            field_order: FILE_FIELDS.reduce((map, f, idx) => {
              map[f.id] = idx;
              return map;
            }, {})
          },
          fields: {
            fields: FILE_FIELDS
          }
        }}
        record={{
          id: params.recordId,
          name,
          valueName: "accountId",
          moduleId: STANDARD_MODULE_IDS.accounts.id,
          moduleInternalName: "accounts",
          values: []
        }}
      />
    ) : null,

    account.related_modules
      ? account.related_modules.map(moduleDetails => (
          <RelatedModule
            key={`${moduleDetails.id}_${moduleDetails.lookup_field.id}`}
            module={moduleDetails}
            onUpdate={fetchProfile}
            record={{
              id: params.recordId,
              name,
              valueName: "accountId",
              moduleId: STANDARD_MODULE_IDS.accounts.id,
              moduleInternalName: "accounts",
              values: [
                {
                  fieldId: moduleDetails.lookup_field.id,
                  value: {
                    type: "lookup",
                    value: {
                      moduleId: STANDARD_MODULE_IDS.accounts.id,
                      records: [params.recordId]
                    }
                  }
                }
              ]
            }}
          />
        ))
      : null
  ];
};

export default decorate(View);
