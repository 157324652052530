import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  saveSelectedIds: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/variant-question-assignments`,
        credentials,
        success,
        error,
        data
      });
    })
};
