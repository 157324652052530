import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/DropdownEditor";
import BeforeClose from "./BeforeClose";

import { getSettings, ep } from "ui-kit/Datagrid/utils";

export default class DropDownEditor extends EditorBase {
  handleClose = () => this.props.onCommit();

  render() {
    const options = getSettings(ep(this.props), "options");

    return (
      <div style={{ width: this.props.column.width - 2 }}>
        <BeforeClose do={this.handleClose}>
          <Editor
            options={options}
            noTether
            value={this.state}
            onChange={this.handleChange}
            autofocus
          />
        </BeforeClose>
      </div>
    );
  }
}
