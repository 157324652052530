import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tab from "./Tab";

@CSSModules(css)
class LenndTabs extends Component {
  state = { activeIndex: 0 };

  selectTab = activeIndex => this.setState({ activeIndex });

  render() {
    const { children, labelsStyles, ...props } = this.props;
    const childrenArray = children && !children.length ? [children] : children;
    const labels = childrenArray.filter(Boolean).map((child, idx) => (
      <div
        key={`${child.props.label}_${idx}`}
        onClick={() => {
          if (child.props.onActive && this.state.activeIndex !== idx) {
            child.props.onActive();
          }
          this.selectTab(idx);
        }}
        styleName={idx === this.state.activeIndex ? "labelActive" : "label"}
      >
        {child.props.label}
      </div>
    ));
    return (
      <div styleName="container" {...props}>
        <div styleName="labels" style={labelsStyles}>
          {labels}
        </div>
        {childrenArray[this.state.activeIndex]}
      </div>
    );
  }
}

LenndTabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Tab)),
    PropTypes.node
  ]),
  labelsStyles: PropTypes.object
};

export default LenndTabs;
