import { REQUEST, RECEIVE } from "./constants";
import { registerError } from "redux/modules/errors/actions";
import * as selectors from "./selectors";
import api from "./api";

export function getAuthStatus(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const response = await api.get(getState().user.user.credentials, eventId);
      dispatch({ type: RECEIVE, payload: response.status });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting auth status"
          }
        ])
      );
    }
  };
}

export function disconnectPaymentsAuth(authId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const response = await api.delete(
        getState().user.user.credentials,
        authId
      );
      dispatch({ type: RECEIVE, payload: response.success });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred while disconnecting from Stripe"
          }
        ])
      );
    }
  };
}
