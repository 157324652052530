import React from "react";
import {
  PopMenu,
  PopMenuCard,
  Div,
  MediumOutlineButton
} from "components/Base";

const ItemQuestionsActions = ({
  onEdit,
  onDelete,
  showEditQuestionSetQuestionsModal,
  showAssignQuestionToItemsModal
}) => (
  <PopMenu
    Label={({ onClick }) => (
      <MediumOutlineButton ml={1} display="row.center.center" onClick={onClick}>
        ...
      </MediumOutlineButton>
    )}
  >
    {({ closeMenu }) => (
      <PopMenuCard position="bottom" align="right">
        <Div
          width={200}
          bg="white"
          bra={1}
          shadow={1}
          style={{ overflow: "hidden" }}
        >
          <Div
            p={2}
            onClick={() => {
              showEditQuestionSetQuestionsModal();
              closeMenu();
            }}
            fw={3}
            bg={{ default: "white", hover: "neutral0" }}
            transition="fast"
          >
            Manage Questions
          </Div>
          <Div
            p={2}
            onClick={() => {
              showAssignQuestionToItemsModal();
              closeMenu();
            }}
            fw={3}
            bg={{ default: "white", hover: "neutral0" }}
            transition="fast"
          >
            Manage Items
          </Div>
          <Div
            p={2}
            onClick={() => {
              onEdit();
              closeMenu();
            }}
            fw={3}
            bg={{ default: "white", hover: "neutral0" }}
            transition="fast"
          >
            Edit
          </Div>
          <Div
            p={2}
            onClick={() => {
              onDelete();
              closeMenu();
            }}
            fw={3}
            bg={{ default: "white", hover: "neutral0" }}
            transition="fast"
          >
            Delete
          </Div>
        </Div>
      </PopMenuCard>
    )}
  </PopMenu>
);

export default ItemQuestionsActions;
