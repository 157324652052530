/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  addPage: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/sites/pages`,
        data,
        credentials,
        success,
        error
      });
    })
};
