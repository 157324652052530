import React, { Component } from "react";
import View from "./View";

class Controller extends Component {
  goToSettings = () =>
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings`
    });

  goToDays = () =>
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/event-days`
    });

  render() {
    const { route } = this.props;
    const tabs = [
      [
        "editEventSettingsSettings",
        "General Settings",
        this.goToSettings,
        route.name === "editEventSettingsSettings"
      ],
      [
        "editEventSettingsEventDays",
        "Event Days",
        this.goToDays,
        route.name === "editEventSettingsEventDays"
      ]
    ];

    return (
      <View
        {...{
          tabs,
          activeTabValue: route.name
        }}
      />
    );
  }
}

export default Controller;
