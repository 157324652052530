import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";

import CurrentVideo from "./CurrentVideo";
import VideoSlider from "./VideoSlider";

import { getters } from "../../model";

const decorator = connect((state, props) => ({
  featuredContentTitle: getters.featuredContentTitle(state, props)
}));

export const VideoPlayer = ({ featuredContentTitle }) => (
  <Div style={{ marginBottom: 20 }}>
    <Div
      style={{
        fontWeight: 600,
        fontSize: 18,
        color: "#222222",
        marginBottom: 14
      }}
    >
      {featuredContentTitle}
    </Div>
    <Div style={{ marginBottom: 9 }}>
      <CurrentVideo />
    </Div>
    <VideoSlider />
  </Div>
);

export default decorator(VideoPlayer);
