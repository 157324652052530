import React from "react";

export default () => (
  <svg width="38px" height="36px" viewBox="0 0 38 36">
    <g id="Group-69" transform="translate(1.000000, -5.000000)">
      <rect
        id="Rectangle-8"
        fill="#FFFFFF"
        opacity="0.254605877"
        x="0"
        y="6"
        width="35"
        height="35"
      />
      <g
        id="Group-65"
        transform="translate(19.242405, 22.437310) scale(-1, 1) rotate(29.000000) translate(-19.242405, -22.437310) translate(10.242405, 1.937310)"
      >
        <g id="Group-70" transform="translate(-0.000000, 0.000000)">
          <path
            d="M3.52765019,14.7575372 C6.01025916,8.29011969 11.1677344,3.47955611 17.3798383,1.29449768 C16.9565295,0.5 17.3798383,1.29449768 16.9565295,0.387535882 C10.5859298,2.65693426 5.20766996,7.59050793 2.59406976,14.3991692 C-0.561486891,22.6196753 1.10686911,31.5055669 6.21035043,37.9375765 L6.70651398,36.9464624 C1.91721794,30.6540075 0.634425494,22.2946452 3.52765019,14.7575372 Z"
            id="Oval-2"
            fill="#000000"
            fillRule="nonzero"
          />
          <g id="Path-9">
            <use fill="#000000" fillRule="evenodd" />
            <path
              stroke="#000000"
              d="M2.79993155,37.5486429 L8.56603695,34.3524384 L7.95478159,39.7240792 L2.79993155,37.5486429 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
