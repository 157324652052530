import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { bindInstance } from "redux-mvc";

import { Div } from "components/Base";
import { noop } from "utils/General";

import Footer from "./Footer";
import Header from "./Header";
import Body from "./Body";

import { actions } from "ui-kit/MiniItemsSelector";

import { MODE_SINGLE_SELECT } from "../constants";

const decorate = connect(
  null,
  bindInstance({
    init: actions.init
  })
);

const View = ({
  mode = MODE_SINGLE_SELECT,
  instanceId,
  fields: groups = [],
  iniSelected = [],
  onDone,
  onCancel,
  enableReorder,
  init = noop
}) => {
  useEffect(() => {
    init({
      selectedItems: iniSelected,
      selectedTab: R.propOr("", "id", R.head(groups || []) || {}),
      enableReorder,
      mode
    });
  }, []);

  const handlers = { onDone, onCancel };

  return (
    <Div
      display="column.flex-start.stretch"
      bg="white"
      flex={1}
      style={{ overflowY: "hidden" }}
    >
      <Header instanceId={instanceId} groups={groups} />
      <Body instanceId={instanceId} groups={groups} />
      <Footer instanceId={instanceId} handlers={handlers} />
    </Div>
  );
};

const SelectAndCreateVariants = decorate(View);

export { SelectAndCreateVariants };
