import { get, isEqual, find, findIndex, merge } from "lodash";
import sortText from "utils/value-types/sort/text";
import sortDropdown from "utils/value-types/sort/dropdown";
import sortCredentials from "utils/value-types/sort/credentials";
import sortStatic from "utils/value-types/sort/static";
import sortBoolean from "utils/value-types/sort/boolean";
import sortDate from "utils/value-types/sort/date";
import sortTime from "utils/value-types/sort/time";
import sortNumber from "utils/value-types/sort/number";
import sortUser from "utils/value-types/sort/user";
import sortCountries from "utils/value-types/sort/countries";
import sortCatalogItem from "utils/value-types/sort/catalog-item";
import sortLookup from "utils/value-types/sort/lookup";
import sortEventDays from "utils/value-types/sort/event-days";

const Helpers = {
  /**
   * format column id
   */
  formatColumnId: columnId => {
    if (isNaN(parseInt(columnId, 10))) {
      return columnId;
    }
    return parseInt(columnId, 10);
  },

  valuesAreEqual: (value1, value2) =>
    isEqual(get(value1, "value"), get(value2, "value")),

  /**
   * check if copy/paste action can accept value into column
   */
  canAcceptPaste: (column, dataType) => {
    let accept = false;
    if (column.type === dataType) {
      accept = true;
    }

    if (
      column.type === "dropdown" &&
      column.settings &&
      column.settings.allowMultipleSelect &&
      dataType === "multiple_select"
    ) {
      accept = true;
    } else if (
      column.type === "dropdown" &&
      column.settings &&
      !column.settings.allowMultipleSelect &&
      dataType === "dropdown"
    ) {
      accept = false; // Disable for Dropdown until we use react-select https://github.com/lennd/platform-v2/issues/1797#issuecomment-270492079
    } else if (column.type === "checkbox" && dataType === "boolean") {
      accept = true;
    } else if (column.type === "inventory" && dataType === "dropdown") {
      accept = true;
    } else if (column.type === "quantity" && dataType === "number") {
      accept = true;
    }
    return accept;
  },
  getSortFunction(column, direction, meta = {}) {
    if (this.canSort(column)) {
      return this.rowComparers(column.id, {
        ...meta,
        column
      })[column.type][direction];
    }
    return false;
  },
  canSort(column) {
    return Object.keys(this.rowComparers()).includes(column.type);
  },
  rowComparers: (columnId, meta = {}) => {
    const { eventDetails, references, column } = meta;
    return {
      "action-approve-form-row": {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      "approval-notes": {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      "approve-reject": {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      "approval-status": {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      "catalog-item": {
        DESC: (a, b) =>
          sortCatalogItem.DESC(a[columnId], b[columnId], {
            availableCatalogItems: column.settings.availableCatalogItems
          }),
        ASC: (a, b) =>
          sortCatalogItem.ASC(a[columnId], b[columnId], {
            availableCatalogItems: column.settings.availableCatalogItems
          })
      },
      checkbox: {
        DESC: (a, b) => sortBoolean.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortBoolean.ASC(a[columnId], b[columnId])
      },
      contact: {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      countries: {
        DESC: (a, b) => sortCountries.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortCountries.ASC(a[columnId], b[columnId])
      },
      credentials: {
        DESC: (a, b) =>
          sortCredentials.DESC(a[columnId], b[columnId], { eventDetails }),
        ASC: (a, b) =>
          sortCredentials.ASC(a[columnId], b[columnId], { eventDetails })
      },
      date: {
        DESC: (a, b) => sortDate.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortDate.ASC(a[columnId], b[columnId])
      },
      dropdown: {
        DESC: (a, b) => sortDropdown.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortDropdown.ASC(a[columnId], b[columnId])
      },
      "has-changes": {
        DESC: (a, b) => sortBoolean.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortBoolean.ASC(a[columnId], b[columnId])
      },
      lookup: {
        DESC: (a, b) =>
          sortLookup.DESC(a[columnId], b[columnId], {
            references,
            fieldId: column.settings.fieldId
          }),
        ASC: (a, b) =>
          sortLookup.ASC(a[columnId], b[columnId], {
            references,
            fieldId: column.settings.fieldId
          })
      },
      number: {
        DESC: (a, b) => sortNumber.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortNumber.ASC(a[columnId], b[columnId])
      },
      static: {
        DESC: (a, b) => sortStatic.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortStatic.ASC(a[columnId], b[columnId])
      },
      text: {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      textarea: {
        DESC: (a, b) => sortText.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortText.ASC(a[columnId], b[columnId])
      },
      time: {
        DESC: (a, b) => sortTime.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortTime.ASC(a[columnId], b[columnId])
      },
      user: {
        DESC: (a, b) => sortUser.DESC(a[columnId], b[columnId]),
        ASC: (a, b) => sortUser.ASC(a[columnId], b[columnId])
      },
      "event-days": {
        DESC: (a, b) =>
          sortEventDays.DESC(a[columnId], b[columnId], { eventDetails }),
        ASC: (a, b) =>
          sortEventDays.ASC(a[columnId], b[columnId], { eventDetails })
      }
    };
  },
  defaultContextMenus(
    columns,
    contextMenuHelpers,
    customMenus = {},
    menuOrder = {},
    meta
  ) {
    // contextMenuHelpers: PropTypes.shape({
    //   editHeader: PropTypes.func.isRequired,
    //   toggleColumnVisibility: PropTypes.func.isRequired,
    //   addColumn: PropTypes.func.isRequired,
    //   deleteColumn: PropTypes.func.isRequired,
    //   sortRows: PropTypes.func.isRequired,
    //   deleteRow: PropTypes.func.isRequired,
    //   insertRow: PropTypes.func.isRequired
    // })

    const additionalMenuItems = {
      headerMenus: {},
      rowMenus: {},
      ...customMenus
    };
    // customMenus = { headerMenus: { menuItemKey: {} }, rowMenus: { menuItemKey: {} } };
    // menuOrder = { headerMenu: [ menuItemKeys ], rowMenu: [ menuItemKeys ] };

    const columnMenu = menuOrder.headerMenu || [
      "editField",
      "hideColumn",
      "separator",
      "insertLeft",
      "insertRight",
      "separator",
      "deleteColumn"
    ];
    const rowMenu = menuOrder.rowMenu || [
      "deleteRow",
      "insertAbove",
      "insertBelow"
    ];

    const headerMenus = merge(
      {
        editField: {
          type: "row",
          title: "Edit Field",
          icon: "settings",
          allow: info => {
            const column = find(columns, { id: info.column });
            return (
              column &&
              column.editable &&
              [column.origin, column.source].includes("custom")
            );
          },
          action: info => {
            const column = find(columns, { id: info.column });
            contextMenuHelpers.editHeader(column);
          }
        },
        hideColumn: {
          type: "row",
          title: "Hide Column",
          icon: "remove_red_eye",
          allow: info => {
            const column = find(columns, { id: info.column });
            return column && !column.is_always_visible;
          },
          action: info => {
            contextMenuHelpers.toggleColumnVisibility(info.column, false);
          }
        },
        separator: {
          type: "separator"
        },
        insertLeft: {
          type: "row",
          title: "Insert Left",
          icon: "arrow_back",
          action: info => {
            const columnIndex = findIndex(columns, { id: info.column });
            contextMenuHelpers.addColumn(columnIndex);
          }
        },
        insertRight: {
          type: "row",
          title: "Insert Right",
          icon: "arrow_forward",
          action: info => {
            const columnIndex = findIndex(columns, { id: info.column });
            contextMenuHelpers.addColumn(columnIndex + 1);
          }
        },
        deleteColumn: {
          type: "row",
          title: "Delete Column",
          icon: "delete",
          allow: info => {
            const column = find(columns, { id: info.column });
            return column.source !== "standard";
          },
          action: info => {
            const column = find(columns, { id: info.column });
            contextMenuHelpers.deleteColumn(column);
          }
        },
        sortDesc: {
          type: "row",
          title: "Sort A-Z",
          icon: "sort_by_alpha",
          allow: info => {
            const column = find(columns, { id: info.column });
            return (
              column &&
              Object.keys(this.rowComparers(column.id)).includes(column.type)
            );
          },
          action: info => {
            const column = find(columns, { id: info.column });
            contextMenuHelpers.sortRows(
              this.getSortFunction(column, "ASC", meta),
              column,
              "ASC"
            );
          }
        },
        sortAsc: {
          type: "row",
          title: "Sort Z-A",
          icon: "sort_by_alpha",
          allow: info => {
            const column = find(columns, { id: info.column });
            return (
              column &&
              Object.keys(this.rowComparers(column.id)).includes(column.type)
            );
          },
          action: info => {
            const column = find(columns, { id: info.column });
            contextMenuHelpers.sortRows(
              this.getSortFunction(column, "DESC", meta),
              column,
              "DESC"
            );
          }
        }
      },
      additionalMenuItems.headerMenus
    );

    const rowMenus = merge(
      {
        deleteRow: {
          type: "row",
          title: "Delete Row",
          icon: "delete",
          action: info => {
            contextMenuHelpers.deleteRow(parseInt(info.row, 10));
          }
        },
        insertAbove: {
          type: "row",
          title: "Insert Above",
          icon: "arrow_upward",
          action: info => {
            contextMenuHelpers.insertRow(parseInt(info.row, 10));
          }
        },
        insertBelow: {
          type: "row",
          title: "Insert Below",
          icon: "arrow_downward",
          action: info => {
            contextMenuHelpers.insertRow(parseInt(info.row, 10) + 1);
          }
        },
        separator: {
          type: "separator"
        }
      },
      additionalMenuItems.rowMenus
    );

    const reducer = menus => (list, key) => {
      if (menus[key]) {
        list.push(menus[key]);
      }
      return list;
    };

    return {
      headerCellMenu: columnMenu.reduce(reducer(headerMenus), []),
      gridCellMenu: rowMenu.reduce(reducer(rowMenus), [])
    };
  }
};

export default Helpers;
