import React from "react";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import reports, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";

import EditReportTemplateModal from "Reports/EditReportTemplateModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { hideModal, showModal } from "redux/modules/modal/actions";

reports.setRootSaga(rootSaga);

const contextConfig = {
  module: reports,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    showEditReportModal(templateId) {
      this.props.showModal({
        content: (
          <EditReportTemplateModal
            templateId={templateId}
            onDone={() => {
              this.store.dispatch(actions.refetchReports());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", reports.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization", "permissions"]
  }
};

const decorate = R.compose(
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  createContext(contextConfig)
);

export default decorate(Layout);
