import React from "react";
import EditorBase from "../TableConnector";
import Editor from "components/Global/Editors/Reference";

export default class ReferenceEditor extends EditorBase {
  render() {
    return (
      <div style={{ fontSize: "13px" }}>
        <Editor
          {...this.props}
          open
          value={this.state}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
