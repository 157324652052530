/* eslint-disable no-empty */
import React from "react";
import * as R from "ramda";
import {
  take,
  fork,
  all,
  put,
  call,
  select,
  takeEvery
} from "redux-saga/effects";
import { COMMIT, REVERT } from "redux-optimist";

import { MEAL_TYPE_ID } from "utils/item-types";
import { mapKeys, toUnderscore } from "utils/General";

import { actions, getters } from "./model";
import { actions as TableActions } from "ui-kit/Table/model";
import { getEvent } from "redux/modules/event/actions";

import {
  getMeals,
  getMealDays,
  getDietaryRestrictions,
  getSelectedDays
} from "./selectors";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";

import { apiCall } from "App/Data/sagas";
import { makeFuture } from "utils/General/sagas";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import MealModal from "components/Event/Settings/Catering/Modals/Meal";
import AddItemBlockModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";

import { showModal } from "redux/modules/modal/actions";
import { getItemBlocksByEventAndType } from "redux/modules/items/item-blocks/actions";

import { Text2, Div } from "components/Base";

import { ROW_ACTIONS } from "ui-kit/Table/constants";

const editOrCloneMeal = function*({ payload: id, type }) {
  const handleDone = yield call(makeFuture);
  const clone = type === actions.cloneMeal.type;

  yield put(
    showModal({
      content: <MealModal onDone={handleDone.done} mealId={id} clone={clone} />,
      wrapper: ModalWrapper
    })
  );

  const data = yield call(handleDone.onRealized);
  const userId = yield select(getUserId);

  if (clone || !id) {
    const eventDetails = yield select(getEventDetails);

    const meals = yield select(getMeals);
    const defaultDays = yield select(getSelectedDays);

    try {
      const clonedData = {
        ...data,
        defaultDays,
        item: {
          ...data.item,
          order: R.length(meals)
        }
      };
      const result = yield call(apiCall, {
        method: "post",
        url: `/catering/event/${eventDetails.id}/meals`,
        data: clonedData,
        qs: { userId }
      });

      yield put(
        actions.cloneMealResponse({ ...clonedData.item, id: result.payload.id })
      );
    } catch (error) {}
  } else {
    try {
      yield call(apiCall, {
        method: "put",
        url: `/items/items/${data.item.id}`,
        data,
        qs: { userId }
      });
      yield put(actions.editMealResponse(mapKeys(toUnderscore, data.item)));
    } catch (error) {}
  }
};

const watchEditOrClone = function*() {
  yield takeEvery(
    [actions.editMeal.type, actions.cloneMeal.type, actions.addMeal.type],
    editOrCloneMeal
  );
};

const watchDelete = function*() {
  for (;;) {
    const { payload: itemId } = yield take(actions.deleteMeal.type);
    const userId = yield select(getUserId);
    try {
      yield call(apiCall, {
        method: "delete",
        url: `/items/items`,
        qs: { userId },
        data: { itemId }
      });
      yield put(actions.deleteMealResponse(itemId));
    } catch (error) {}
  }
};

const fetchMeals = function*() {
  const eventDetails = yield select(getEventDetails);
  const result = yield call(apiCall, {
    method: "get",
    url: `/items/groups/event/${eventDetails.id}/type/${MEAL_TYPE_ID}`
  });

  yield put(
    actions.setMealItems(R.flatten(R.map(R.prop("items"), result.payload)))
  );
};

const watchNext = function*() {
  for (;;) {
    const { optimist } = yield take(actions.nextMealStep.type);
    const eventDetails = yield select(getEventDetails);
    const selectedDays = yield select(getSelectedDays);
    const meals = yield select(getMeals);
    const mealDays = yield select(getMealDays);
    const dietaryRestrictions = yield select(getDietaryRestrictions);

    const settings = {
      eventId: eventDetails.id,
      selectedDays,
      meals,
      mealDays,
      dietaryRestrictions,
      items: meals,
      variants: []
    };

    try {
      const userId = yield select(getUserId);
      yield call(apiCall, {
        method: "post",
        url: `/catering/event/${eventDetails.id}/settings`,
        data: settings,
        qs: { userId }
      });
      yield put(getEvent(eventDetails.id));

      yield put(
        actions.nextMealStepResponse(null, {
          optimist: { ...optimist, type: COMMIT }
        })
      );
    } catch (error) {
      yield put(
        actions.nextMealStepResponse(null, {
          optimist: { ...optimist, type: REVERT }
        })
      );
    }
  }
};

const addS = thing => (thing === 1 ? "" : "s");

const columns = [
  {
    id: "name",
    name: "Block Name",
    Cell: ({ data }) => (
      <Text2 truncate color="gray7">
        {R.propOr("", "name", data)}
      </Text2>
    )
  },
  {
    id: "count",
    name: "# of Items",
    Cell: ({ data }) => {
      const count = R.length(R.propOr([], "items", data));
      return (
        <Div display="row.flex-end.center">
          <Text2 color="gray7">{`${count} item${addS(count)}`}</Text2>
        </Div>
      );
    }
  }
];

const fetchItemGroups = function*() {
  const eventDetails = yield select(getEventDetails);
  const res = yield put(
    getItemBlocksByEventAndType(eventDetails.id, MEAL_TYPE_ID)
  );
  const itemBlocks = yield call(R.always(res));
  yield put(
    TableActions.setData({
      columns,
      rows: itemBlocks,
      columnWidths: { count: 100 }
    })
  );
};

const addItemBlock = function*() {
  const handleDone = yield call(makeFuture);

  yield put(
    showModal({
      content: (
        <AddItemBlockModal
          onDone={handleDone.done}
          onCancel={handleDone.error}
          selectedTypeId={MEAL_TYPE_ID}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  try {
    yield call(handleDone.onRealized);
    yield call(fetchItemGroups);
  } catch (error) {}
};

const watchAddItemBlock = function*() {
  yield takeEvery(actions.addItemBlock.type, addItemBlock);
};

const editItemBlock = function*({ payload: { row } }) {
  const handleDone = yield call(makeFuture);

  yield put(
    showModal({
      content: (
        <ItemBlockModal
          onDone={handleDone.done}
          onCancel={handleDone.error}
          itemBlockId={row.id}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  try {
    yield call(handleDone.onRealized);
    yield call(fetchItemGroups);
  } catch (error) {}
};

const watchEditItemBlock = function*() {
  yield takeEvery(
    ac =>
      ac.type === TableActions.executeAction.type &&
      ac.payload.actionId === ROW_ACTIONS.OPEN_RECORD,
    editItemBlock
  );
};

const savePassword = function*({ optimist }) {
  const password = yield select(getters.password);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  try {
    yield call(apiCall, {
      method: "post",
      url: `/catering/event/${eventDetails.id}/settings`,
      data: {
        eventId: eventDetails.id,
        managerOverridePassword: password === "" ? null : password
      },
      qs: { userId }
    });

    const res = yield put(getEvent(eventDetails.id));
    yield call(R.always(res));
    yield put(
      actions.savePasswordResponse(null, {
        optimist: { ...optimist, type: COMMIT }
      })
    );
  } catch (error) {
    yield put(
      actions.savePasswordResponse(null, {
        optimist: { ...optimist, type: REVERT }
      })
    );
  }
};

const watchSavePassword = function*() {
  yield takeEvery(
    [actions.savePassword.type, actions.disablePassword.type],
    savePassword
  );
};

const updateMealOrder = function*() {
  const meals = yield select(getMeals);
  const userId = yield select(getUserId);
  const eventDetails = yield select(getEventDetails);
  try {
    yield call(apiCall, {
      method: "put",
      url: `/items/items/bulk-update`,
      data: {
        bulk: true,
        items: R.map(R.pick(["id", "order"]), meals),
        eventId: eventDetails.id
      },
      qs: { userId }
    });
  } catch (error) {}
};

const watchUpdateMealOrder = function*() {
  yield takeEvery(
    [actions.moveMealUp.type, actions.moveMealDown.type],
    updateMealOrder
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchDelete),
    fork(fetchMeals),
    fork(watchEditOrClone),
    fork(watchNext),
    fork(watchSavePassword),
    fork(watchAddItemBlock),
    fork(watchEditItemBlock),
    fork(watchUpdateMealOrder),
    fork(fetchItemGroups)
  ]);
};

export default rootSaga;
