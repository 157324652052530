import {
  put,
  select,
  call,
  takeEvery,
  all,
  fork,
  take,
  delay
} from "redux-saga/effects";
import React from "react";
import * as R from "ramda";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";
import { getUsers as fetchUsers } from "redux/modules/eventUsers/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { orgId as getOrganizationId } from "redux/modules/organization/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getCurrentPage, getPageSize } from "Passes/Confirmations/selectors";

import { registerError } from "redux/modules/errors/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmailModal from "SendEmailModal/View";
import ViewOrderModal from "Orders/OrderModal/View";

import { getters, actions } from "./model";

import { apiCall } from "App/Data/sagas";

import { debounce, makeFuture } from "utils/General/sagas";

import {
  CREATED_VIA_PORTAL,
  CREATED_VIA_GLM,
  PASS_MODAL_ID,
  USER_MODAL_ID,
  SOURCE_MODAL_ID,
  EVERYONE,
  ALL_GROUPS,
  MANAGE_BY,
  TABLE_VIEW_ID
} from "Passes/Common/constants";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";
import { actions as FiltersActions } from "ui-kit/FiltersPanel";
import * as FilterSelectors from "ui-kit/FiltersPanel/selectors";

import {
  actions as MiniItemsActions,
  getters as MiniItemsGetters
} from "ui-kit/MiniItemsSelector";

import {
  actions as DoubleSelectActions,
  getters as DoubleSelectGetters
} from "ui-kit/DoubleSelect";

const notResetPagination = [
  actions.setCurrentPage.type,
  actions.fetchData.type
];

const getSearchData = function*(actionType) {
  const itemTypeIds = yield select(getters.itemTypeIds);

  const sourceIds = yield select(MiniItemsGetters.selectedItems, {
    instanceId: SOURCE_MODAL_ID
  });

  const resetPagination = R.not(
    R.any(R.equals(actionType), notResetPagination)
  );

  const option = yield select(DoubleSelectGetters.selectedOption);
  const subOption = yield select(DoubleSelectGetters.selectedSubOptions);
  const selectedAffiliationOptions = yield select(
    FilterSelectors.getSelectedOptionsForSection,
    {
      id: MANAGE_BY.GROUP_AFFILIATION
    }
  );
  const selectedAttendeeListOptions = yield select(
    FilterSelectors.getSelectedOptionsForSection,
    {
      id: MANAGE_BY.ATTENDEE_LIST
    }
  );
  const variantIds = yield select(MiniItemsGetters.selectedItems, {
    instanceId: PASS_MODAL_ID
  });
  const sourceUserIds = yield select(MiniItemsGetters.selectedItems, {
    instanceId: USER_MODAL_ID
  });
  const searchTerm = yield select(SearchBarGetters.searchTerm);
  const currentPage = yield select(getCurrentPage);
  const pageSize = yield select(getPageSize);
  return {
    itemTypeIds,
    confirmationStatus: option,
    status: subOption,
    accountRecordTypes: R.without([ALL_GROUPS], selectedAffiliationOptions),
    contactRecordTypes: R.without([EVERYONE], selectedAttendeeListOptions),
    search: searchTerm,
    variantIds,
    sourceFormIds: R.without([CREATED_VIA_GLM, CREATED_VIA_PORTAL], sourceIds),
    sourceUserIds,
    [CREATED_VIA_PORTAL]: R.contains(CREATED_VIA_PORTAL, sourceIds),
    [CREATED_VIA_GLM]: R.contains(CREATED_VIA_GLM, sourceIds),
    page: resetPagination ? 1 : currentPage + 1,
    pageSize
  };
};

const loading = function*(scrollTop = true) {
  if (scrollTop) {
    const elm = yield call([document, document.getElementById], TABLE_VIEW_ID);
    if (elm) {
      elm.scrollTop = 0;
    }
  }
  yield put(actions.setLoading(true));
  yield take(actions.receiveList.type);
  yield delay(400);
  yield put(actions.setLoading(false));
};

const search = function*({ type }) {
  const eventIdToUse = yield select(getEventId);
  const userId = yield select(getUserId);
  const data = yield call(getSearchData, type);
  yield fork(loading);
  try {
    const result = yield call(apiCall, {
      method: "get",
      url: `/orders/line-items/bulk-confirmations-list`,
      qs: { eventId: eventIdToUse, userId, ...data }
    });
    yield put(actions.receiveList(result.payload));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching passes list"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const deselect = function*() {
  yield put(actions.selectAll());
};

const watchInitialized = function*() {
  yield all([
    take(FiltersActions.init.type),
    take(DoubleSelectActions.init.type)
  ]);
  yield put(actions.fetchData());
};

const watchClear = function*() {
  for (;;) {
    const action = yield take(FiltersActions.clearFilters.type);
    yield all([
      put(
        MiniItemsActions.erase(null, { meta: { instanceId: USER_MODAL_ID } })
      ),
      put(
        MiniItemsActions.erase(null, { meta: { instanceId: PASS_MODAL_ID } })
      ),
      put(
        MiniItemsActions.erase(null, {
          meta: { instanceId: SOURCE_MODAL_ID }
        })
      )
    ]);
    yield call(search, action);
  }
};

const watchSearch = function*() {
  yield takeEvery(
    [
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setTypeFilters.type,
      actions.fetchData.type,
      FiltersActions.toggleFilter.type,
      DoubleSelectActions.selectOption.type,
      DoubleSelectActions.toggleSubOption.type
    ],
    search
  );
};

const watchOptionChange = function*() {
  yield takeEvery(
    [
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setTypeFilters.type,
      FiltersActions.toggleFilter.type,
      DoubleSelectActions.selectOption.type,
      DoubleSelectActions.toggleSubOption.type
    ],
    deselect
  );
};

const watchSearchTerm = debounce(
  SearchBarActions.setSearchTerm.type,
  search,
  500
);

const showSendConfirmationEmailModal = function*({
  payload: { emailType = "confirmation" }
}) {
  const orders = yield select(getters.selectedRows);
  const handleDone = makeFuture();
  yield put(
    showModal({
      content: (
        <SendEmailModal
          emailType={emailType}
          moduleId={STANDARD_MODULE_IDS.orders.id}
          selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
          records={orders}
          onDone={handleDone.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  yield call(handleDone.onRealized);

  yield call(deselect);
  yield put(actions.fetchData());
};

const showViewOrderModal = function*({ payload: { number, id } }) {
  const handleDone = makeFuture();
  yield put(
    showModal({
      content: (
        <ViewOrderModal
          orderNumber={id ? undefined : number}
          orderId={id ? id : undefined}
          onDone={handleDone.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  yield call(handleDone.onRealized);
  yield call(deselect);
  yield put(actions.fetchData());
};

const init = function*({ payload: { itemTypeId } }) {
  const eventId = yield select(getEventId);
  const orgId = yield select(getOrganizationId);

  yield all([
    put(actions.setItemTypeIds([itemTypeId])),
    put(
      getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: {
          orgId,
          eventId
        }
      })
    ),
    put(
      getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: {
          orgId,
          eventId
        }
      })
    ),
    put(getItemGroupsByEventAndType(eventId, itemTypeId)),
    put(getForms(eventId)),
    put(fetchUsers(eventId))
  ]);
};

const watchShowSendConfirmationEmail = function*() {
  yield takeEvery(
    actions.showSendConfirmationEmail.type,
    showSendConfirmationEmailModal
  );
};

const watchShowViewOrder = function*() {
  yield takeEvery(actions.showViewOrder.type, showViewOrderModal);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowSendConfirmationEmail),
    fork(watchShowViewOrder),
    fork(watchSearch),
    fork(watchOptionChange),
    fork(watchSearchTerm),
    fork(watchInitialized),
    fork(watchClear)
  ]);
};

export default rootSaga;
