import { RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getSettings(eventId, accountId) {
  return async (dispatch, getState) => {
    try {
      const settings = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId
      );
      dispatch({ type: RECEIVE, payload: settings });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting settings"
          }
        ])
      );
    }
  };
}
