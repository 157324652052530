import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditRecord from "./EditRecord";

import { addValue } from "redux/modules/modules/values/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import * as ModuleRecordTypesSelectors from "redux/modules/modules/recordTypes/selectors";
import * as EventSelectors from "redux/modules/event/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetching: ModuleSelectors.isFetching(state, props.moduleId),
    module: ModuleSelectors.moduleDetails(state, props.moduleId),
    fields: ModuleSelectors.fields(state, props.moduleId),
    fieldGroups: ModuleSelectors.fieldGroups(state, props.moduleId),
    starredFields: ModuleSelectors.fieldGroups(state, props.moduleId),
    recordTypes: ModuleRecordTypesSelectors.recordTypes(state, props.moduleId),

    // @NOTE: In the future likely want to hit a unique endpoint for pulling preferences.
    preferences:
      props.preferences || ModuleSelectors.preferences(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecordTypes,
      addValue,
      getModule
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRecord);
