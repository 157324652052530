import React from "react";
import { createContext } from "redux-mvc";
import { connect } from "react-redux";

import module from "../index";

import { Div } from "components/Base";

import Cards from "./Cards";
import Sidebar from "./Sidebar";
import Table from "./Table";
import SettingsModal from "./SettingsModal/View";
import { getters } from "../model";

const decorate = createContext({
  module
});

const con = connect(state => ({
  isSettingsModalVisible: getters.isSettingsModalVisible(state),
  settingsModalProps: getters.settingsModalProps(state)
}));

const Layout = ({ isSettingsModalVisible, settingsModalProps }) => {
  const {
    moduleId,
    onClose,
    recordTypeId,
    recordTypeName
  } = settingsModalProps;
  return (
    <Div
      bg="neutral0"
      width={1}
      display="row.flex-start.stretch"
      flex={1}
      style={{ overflowY: "hidden" }}
    >
      <Sidebar />
      <Div
        flex={1}
        display="column.flex-start.stretch"
        style={{ overflow: "hidden" }}
      >
        <Cards />
        <Table />
      </Div>
      {isSettingsModalVisible && (
        <SettingsModal
          isModal={true}
          moduleId={moduleId}
          onClose={onClose}
          recordTypeId={recordTypeId}
          recordTypeName={recordTypeName}
        />
      )}
    </Div>
  );
};

export default decorate(con(Layout));
