import PropTypes from "prop-types";
import React from "react";
import Popover from "../Popover";
import CanUserDo from "components/Global/Security/CanUserDo";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import Helpers from "utils/Global/Helpers";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import getValue from "utils/value-types/get-value";
import { get } from "lodash";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import SendDocument from "components/Event/Accounts/Modals/SendDocument";
import AssignFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignAccountForms";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ACCOUNT_FIELDS from "components/Event/Accounts/constants";
import accountApi from "redux/modules/accounts/profile/api";
import Retrieving from "components/Global/CRM/Modals/Retrieving";
import Base from "../Base";
import AssignEventsModal from "components/Global/CRM/Modals/Assign/AssignEvents";
import RunReportModal from "Reports/RunReportModal/View";
import SendEmail from "SendEmailModal/View";
import CreateDocuments from "Modules/GenerateDocumentsModal/View";

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";

@CSSModules(css)
class DataTableActionsBar extends Base {
  showAssignFormsModal = () => {
    const { showModal, selectedRows } = this.props;
    showModal({
      content: (
        <AssignFormsModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          recordIds={selectedRows}
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignDocumentRequestsModal = () => {
    const { selectedRows, showModal } = this.props;
    showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          recordIds={selectedRows}
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showGenerateDocumentsModal = () => {
    const {
      selectedRows,
      records,
      showModal,
      orgDetails,
      eventDetails
    } = this.props;
    const accounts = records.map(a => ({
      id: a.id,
      // NOTE: Since the modal is generalized, the caller *must*
      // pass the formatted name as a string rather than passing the entity
      name: getValue(get(a.values, [ACCOUNT_FIELDS.name]), "text")
    }));

    showModal({
      content: (
        <CreateDocuments
          onDone={({ documents, send }) => {
            this.props.deselectAllRows();

            // Handle sending document notifications
            if (send) {
              this.showDocumentSendModal(documents, accounts);
            }
          }}
          orgId={orgDetails.id}
          eventId={eventDetails.id}
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          selected={selectedRows}
          showSaveAndSend={true}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showRunReportModal = () => {
    this.props.showModal({
      content: (
        <RunReportModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordIds={this.props.selectedRows}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showDocumentSendModal = async (documents, accounts) => {
    this.props.showModal({
      content: (
        <Retrieving message="Please wait..." title="Retrieving Accounts" />
      ),
      wrapper: ModalWrapper
    });

    const fetchedAccounts = await Promise.all(
      accounts.map(account =>
        accountApi.get(this.props.user.credentials, {
          accountId: account.id,
          orgId: this.props.orgDetails.id,
          eventId: this.props.eventDetails.id
        })
      )
    );

    const recipients = fetchedAccounts
      .map(({ account }) => account)
      .filter(Boolean)
      .map(a => ({
        name: getValue(get(a.values, [ACCOUNT_FIELDS.name, "value"]), "text"),
        users: a.users.filter(u => u.user_id && u.email)
      }));

    this.props.hideModal();

    this.props.showModal({
      content: (
        <SendDocument
          onDone={count => {
            this.props.showSnackbar({
              message: `${count} Document${
                count === 1 ? "" : "s"
              } Saved & Sent`,
              action: "OK"
            });
          }}
          recipients={recipients}
          documents={documents}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleFileUpload = async files => {
    const moduleFiles = await this.props.addRecords({
      records: files.map(file => ({
        moduleId: STANDARD_MODULE_IDS.files.id,
        record: {
          [UPLOAD_FIELD_ID]: { type: "file", value: { files: [file] } }
        }
      })),
      options: {
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id
      }
    });
    const relatedRecordIds = moduleFiles.map(({ id }) => id);
    await this.props.bulkRelateRecords({
      recordIds: this.props.selectedRows,
      relatedRecordIds,
      relatedModuleId: STANDARD_MODULE_IDS.files.id
    });
    this.props.deselectAllRows();
    this.props.showSnackbar({ message: "Files added" });
  };

  showFilepickerModal = () => {
    const callback = files => {
      if (files.length) {
        const filteredFiles = files.filter(f => Boolean(f.url));
        if (filteredFiles.length) {
          this.handleFileUpload(files);
        }
      }
    };

    const options = {
      multiple: true,
      accept: [],
      fromSources: ["local_file_system", "dropbox", "googledrive"]
    };

    const path = { path: "event-files/" };

    Helpers.getFilepicker(options, path, callback);
  };

  showAssignEventsModal = () => {
    this.props.showModal({
      content: (
        <AssignEventsModal
          selectedRecords={this.props.selectedRows}
          moduleId={this.props.module.id}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  sendEmailModal = async () => {
    this.props.showModal({
      content: (
        <SendEmail
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          records={this.props.selectedRows}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { selectedRows, deselectAllRows } = this.props;
    const selectedRowsCount = selectedRows.length;
    const pluralize = text => `${text}${selectedRowsCount !== 1 ? "s" : ""}`;
    const isEvent = Boolean(this.props.eventDetails.id);

    return (
      <div styleName="container">
        <div styleName="count">
          {selectedRowsCount} selected row
          {selectedRowsCount !== 1 ? "s" : ""}
        </div>

        {/* TODO WRAP IN ORG PERMISSIONS */}
        {!isEvent ? (
          <Popover label="Assign">
            {({ closeOnClick }) => (
              <Menu>
                <MenuItem
                  primaryText="Events"
                  onClick={closeOnClick(this.showAssignEventsModal)}
                />
              </Menu>
            )}
          </Popover>
        ) : (
          ""
        )}

        <CanUserDo
          action={`${STANDARD_MODULE_IDS.accounts.id}_update`}
          className={css.candoWrapper}
        >
          {!this.props.reportId ? (
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.showEditRecordsModal}
            >
              <i className="material-icons">edit</i>
              Edit
            </div>
          ) : null}

          {isEvent ? (
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.showRunReportModal}
            >
              <i className="material-icons">show_chart</i>
              Run Report
            </div>
          ) : null}

          {isEvent ? (
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.sendEmailModal}
            >
              {pluralize("Send Email")}
            </div>
          ) : null}

          {isEvent ? (
            <Popover label="Assign">
              {({ closeOnClick }) => (
                <Menu>
                  <MenuItem
                    primaryText="Forms"
                    onClick={closeOnClick(this.showAssignFormsModal)}
                  />
                  <MenuItem
                    primaryText="File Requests"
                    onClick={closeOnClick(this.showAssignDocumentRequestsModal)}
                  />
                  <MenuItem
                    primaryText="Group Owners"
                    onClick={closeOnClick(this.showAssignOwnersModal)}
                  />
                </Menu>
              )}
            </Popover>
          ) : null}

          {isEvent ? (
            <Popover label="More">
              {({ closeOnClick }) => (
                <Menu>
                  <MenuItem
                    primaryText={pluralize("Change Type")}
                    onClick={closeOnClick(this.changeRecordTypes)}
                  />
                  <MenuItem
                    primaryText={pluralize("Generate Document")}
                    onClick={closeOnClick(this.showGenerateDocumentsModal)}
                  />
                  <MenuItem
                    primaryText="Upload Files"
                    onClick={closeOnClick(this.showFilepickerModal)}
                  />
                </Menu>
              )}
            </Popover>
          ) : null}

          <CanUserDo action={`${STANDARD_MODULE_IDS.accounts.id}_delete`}>
            <div
              role="button"
              tabIndex={0}
              styleName="menuItem"
              onClick={this.showDeleteRecordsModal}
            >
              Delete
            </div>
          </CanUserDo>
        </CanUserDo>
        <div
          role="button"
          tabIndex={0}
          styleName="menuTextButton"
          onClick={deselectAllRows}
        >
          Clear selected
        </div>
      </div>
    );
  }
}

DataTableActionsBar.propTypes = {
  deselectAllRows: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  showModal: PropTypes.func.isRequired
};

export default DataTableActionsBar;
