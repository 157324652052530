import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";

import { Text3, Div, MediumAvatar } from "components/Base";
import MenuPopover from "ui-kit/MenuPopover";

import styleSpects from "components/Base/Base/styleSpecs";

import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";

const styles = {
  avatar: {
    width: 30,
    height: 30,
    cursor: "pointer"
  }
};

const decorate = connect((state, props) => ({
  user: getPortalUser(state, props),
  event: getEventDetails(state, props)
}));

const HeaderAvatar = ({ user = {}, event = {} }) => {
  return (
    <div style={styles.avatar}>
      <MenuPopover
        Label={
          <MediumAvatar
            size={30}
            userId={user.id}
            photoURL={user.user_photo_url}
            bg="neutral6"
            text={user.user_fname + " " + user.user_lname}
            singleInitial
          />
        }
      >
        {() => (
          <Div bra={3} bg="white" width={200} py={2}>
            <Link
              to={`/logout?returnTo=/portal-login/${event.slug}/${event.uuid}`}
            >
              <Div
                fs={2}
                px={3}
                py={2}
                display="row.flex-start.center"
                bg={{
                  hover: "neutral0",
                  default: "white"
                }}
                color="black"
              >
                <i
                  className="material-icons"
                  style={{
                    color: "black",
                    fontSize: styleSpects.fontSize[3]
                  }}
                >
                  logout
                </i>
                <Text3 ml={4}>Logout</Text3>
              </Div>
            </Link>
          </Div>
        )}
      </MenuPopover>
    </div>
  );
};

export default decorate(HeaderAvatar);
