import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "Orders/ImportModal";
import { getConfirmationCounters } from "./reviewImport";

export const getInvalidFields = createSelector(
  getters.uploadCsvErrors,
  getters.importedColumns,
  (error, importedColumns) => ({
    totalRowsOK: R.pathOr(0, ["counters", "rows_ok"], error),
    totalRecordsProcessed: R.pathOr(0, ["counters", "rows_number"], error),
    url: R.pathOr("", ["errorsfile", "url"], error),
    summaryErrors: R.compose(
      R.map(summaryError => {
        const filteredErrors = R.compose(
          R.filter(
            validationError => summaryError.description === validationError[3]
          )
        )(R.propOr([], "validationErrors", error));

        return {
          ...summaryError,
          columns: R.map(row => {
            const rowsWithErrors = R.filter(
              errorRow => Number(errorRow[0]) === row
            )(filteredErrors);
            return R.map(r => R.indexOf(r[1], importedColumns) + 1)(
              rowsWithErrors
            );
          })(summaryError.rows)
        };
      }),
      R.propOr([], "summaryErrors")
    )(error),
    summaryWarnings: R.compose(
      R.filter(({ errors }) => errors > 0),
      R.propOr([], "summaryWarnings")
    )(error)
  })
);

export const getCountOfRowsWithErrors = createSelector(
  getters.uploadCsvErrors,
  uploadCsvErrors => {
    return R.compose(
      R.length,
      R.uniq,
      R.flatten,
      R.map(R.prop("rows")),
      R.propOr([], "summaryErrors")
    )(uploadCsvErrors);
  }
);

export const getFields = createSelector(
  getters.fieldsGroups,
  R.compose(
    R.filter(R.propEq("selected", true)),
    R.flatten,
    R.map(R.propOr([], "fields"))
  )
);

const getFieldsWithAllErrors = createSelector(
  getters.uploadCsvErrors,
  getFields,
  (errors, fields) =>
    R.compose(
      R.map(error => R.find(field => field.column === error[1])(fields)),
      R.propOr([], "validationErrors")
    )(errors)
);

export const getFieldsWithErrors = createSelector(
  getFieldsWithAllErrors,
  R.uniqWith(R.eqProps("id"))
);

export const isThereAnyValidRow = createSelector(
  getConfirmationCounters,
  counters => counters.rowsProcessed > counters.skippedRows
);
