import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function addItemBlock({ permissionSetId, recordTypeId, blockId }) {
  return async (dispatch, getState) => {
    try {
      await api.post(getState().user.user.credentials, {
        permissionSetId,
        recordTypeId,
        blockId
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission set"
          }
        ])
      );
    }
  };
}

export function removeItemBlock(permissionSetId, assignmentId) {
  return async (dispatch, getState) => {
    try {
      await api.delete(
        getState().user.user.credentials,
        permissionSetId,
        assignmentId
      );
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission set"
          }
        ])
      );
    }
  };
}

export function updateItemBlock({
  permissionSetId,
  recordTypeId,
  blockId,
  required
}) {
  return async (dispatch, getState) => {
    try {
      await api.put(getState().user.user.credentials, {
        blockId,
        recordTypeId,
        permissionSetId,
        required
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission set"
          }
        ])
      );
    }
  };
}
