import SignupStart from "components/Signup/Start";
import SignupComplete from "components/Signup/Complete";
import SignupResend from "components/Signup/Resend";
import SignupVerificationConfirm from "components/Signup/Confirm";
import LoginView from "views/Auth/LoginView";
import CallbackView from "views/Auth/CallbackView";
import LogoutView from "views/Auth/LogoutView";
import LogoutCallbackView from "views/Auth/LogoutCallbackView";
import ForgotPasswordHandler from "views/Auth/ForgotPassword";
import InvoiceHandler from "Invoice";
import JoinVirtualUserHandler from "components/Join/JoinVirtualUserHandler";
import LoggedOutView from "views/LoggedOutView";
import SignupOnboard from "components/Signup/Onboard/View";

import { hot } from "App/Config/hot";

const Auth = {
  SignupStart,
  SignupComplete,
  SignupResend,
  SignupVerificationConfirm,
  LoginView,
  CallbackView,
  LogoutView,
  LogoutCallbackView,
  ForgotPasswordHandler,
  InvoiceHandler,
  JoinVirtualUserHandler,
  LoggedOutView,
  SignupOnboard
};

export default hot(Auth);
