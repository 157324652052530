import React, { useState, useEffect } from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { actions } from "../model";
import {
  filteredSessionsBySelection,
  getLeftSidebarSecondaryColor,
  getUseEmbargoes
} from "../selectors";
import { PAGE_ACTIONS } from "../constants";
import Tooltip from "components/Global/Tooltip";
import moment from "moment";
import * as R from "ramda";

const decorator = connect(
  (state, props) => ({
    sessions: filteredSessionsBySelection(state, props),
    secondaryColor: getLeftSidebarSecondaryColor(state, props),
    useEmbargoes: getUseEmbargoes(state, props)
  }),
  {
    executeAction: actions.executeAction
  }
);

const EmptyListPlaceholder = ({ children, bg }) => (
  <Div
    display="column.center.center"
    bg={bg || "#002d60"}
    height={100}
    width={1}
    bra={2}
    color="#c7d0db"
    style={{
      marginTop: "30px"
    }}
  >
    {children}
  </Div>
);

const Sessions = ({
  sessions,
  secondaryColor,
  executeAction,
  useEmbargoes
}) => {
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => setNow(moment()), 30000);
    return () => clearInterval(interval);
  }, []); // ping to refresh the view and compute live sessions

  return (
    <Div>
      {R.isEmpty(sessions) ? (
        <EmptyListPlaceholder bg={secondaryColor}>
          <Div
            mb={1}
            style={{
              color: "#FFFFFF",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "bold",
              opacity: "0.8"
            }}
          >
            Nothing scheduled at this time
          </Div>
          <Div
            style={{
              color: "#FFFFFF",
              fontFamily: "Roboto",
              fontSize: "14px",
              opacity: "0.8"
            }}
          >
            New items will appear when scheduled.
          </Div>
        </EmptyListPlaceholder>
      ) : (
        sessions.map(session => {
          const isLive =
            !useEmbargoes &&
            now.isBetween(moment(session.starts_at), moment(session.ends_at));
          const isSoon =
            !useEmbargoes &&
            now.isBetween(
              moment(session.starts_at).subtract(10, "minutes"),
              moment(session.starts_at)
            );
          const isLiveOrSoon = isLive || isSoon;
          const isSessionEnded = now.isAfter(moment(session.ends_at));
          const hasPast = session.embargoed_at
            ? now.isAfter(moment(session.embargoed_at))
            : isSessionEnded;

          const hasVideoUrl = Boolean(
            session.video_url && session.video_url.length
          );
          const hasConferenceUrl = Boolean(
            session.conference_url && session.conference_url.length
          );
          const hasTranscriptUrl = Boolean(
            session.transcript_url && session.transcript_url.length
          );
          const hasReplayUrl = Boolean(
            session.replay_url && session.replay_url.length
          );

          return (
            <Div
              key={session.id}
              className="hoverContainer"
              display="row.flex-start.flex-start"
              py={3}
              bb={1}
              bc="#043877"
              bg={isLiveOrSoon ? "rgba(5, 73, 162, .16)" : undefined}
            >
              <Div
                width={65}
                color="white"
                fw={4}
                fs={3}
                style={{
                  marginLeft: "8px",
                  marginRight: "30px",
                  fontFamily: "'Roboto', sans-serif",
                  letterSpacing: "-0.17px",
                  fontWeight: 500
                }}
              >
                {session.date_label}
              </Div>
              <Div flex={1}>
                <Div
                  display="row.space-between.center"
                  mb={isLiveOrSoon && (hasVideoUrl || hasConferenceUrl) ? 3 : 0}
                >
                  <Div
                    flex={1}
                    color="white"
                    fs={3}
                    style={{
                      fontFamily: "'Roboto', sans-serif",
                      letterSpacing: "-0.17px",

                      fontWeight: isLiveOrSoon ? 700 : undefined
                    }}
                  >
                    {session.name}
                  </Div>

                  {!useEmbargoes && !hasPast && !isLiveOrSoon ? (
                    <Div
                      style={{
                        marginRight: "10px"
                      }}
                    >
                      {session.subscribed_to_notifications ? (
                        <Tooltip tooltip="Turn off notifications for this session">
                          <img
                            src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/interview-notification-ico-selected.svg"
                            alt="Toggle Session Notification Icon"
                            width={15}
                            onClick={() =>
                              executeAction({
                                type: PAGE_ACTIONS.NOTIFY_ME,
                                status: !session.subscribed_to_notifications,
                                section: "session",
                                id: session.id
                              })
                            }
                            style={{
                              cursor: "pointer"
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip tooltip="Notify me when this session is about to begin">
                          <img
                            src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/interview-notification-ico.svg"
                            alt="Toggle Session Notification Icon"
                            width={15}
                            onClick={() =>
                              executeAction({
                                type: PAGE_ACTIONS.NOTIFY_ME,
                                status: !session.subscribed_to_notifications,
                                section: "session",
                                id: session.id
                              })
                            }
                            style={{
                              cursor: "pointer"
                            }}
                          />
                        </Tooltip>
                      )}
                    </Div>
                  ) : isLive ? (
                    <Div display="row.flex-start.center">
                      {session.conferenceLogoUrl ? (
                        <img
                          src={session.conferenceLogoUrl.src}
                          alt="Conference Provider Logo"
                          height={session.conferenceLogoUrl.height}
                          width={session.conferenceLogoUrl.width}
                        />
                      ) : null}
                      <Div
                        display="row.center.center"
                        width={54}
                        height={24}
                        bra={1}
                        color="white"
                        bg="#F40A42"
                        fs={2}
                        uppercase
                        style={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          letterSpacing: "0.4px",
                          fontWeight: "bold",
                          marginLeft: "15px",
                          marginRight: "14px",
                          flexShrink: "0"
                        }}
                      >
                        Live
                      </Div>
                    </Div>
                  ) : isSoon ? (
                    <Div display="row.flex-start.center">
                      {session.conferenceLogoUrl ? (
                        <img
                          src={session.conferenceLogoUrl.src}
                          alt="Conference Provider Logo"
                          height={session.conferenceLogoUrl.height}
                          width={session.conferenceLogoUrl.width}
                        />
                      ) : null}
                      <Div
                        display="row.center.center"
                        width={54}
                        height={24}
                        bra={1}
                        color="white"
                        bg="#740bd4"
                        fs={2}
                        uppercase
                        style={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          letterSpacing: "0.4px",
                          fontWeight: "bold",
                          marginLeft: "15px",
                          marginRight: "14px",
                          flexShrink: "0"
                        }}
                      >
                        Soon
                      </Div>
                    </Div>
                  ) : isSessionEnded && (hasTranscriptUrl || hasReplayUrl) ? (
                    <Div
                      display="row.flex-start.center"
                      style={{
                        marginRight: "10px"
                      }}
                    >
                      {hasTranscriptUrl ? (
                        <Tooltip tooltip="View transcript">
                          <img
                            src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/interview-transcript-ico.svg"
                            alt="Interview Transcript Icon"
                            width={12}
                            onClick={() =>
                              executeAction({
                                type: PAGE_ACTIONS.VIEW_TRANSCRIPT,
                                url: session.transcript_url
                              })
                            }
                            style={{
                              cursor: "pointer"
                            }}
                          />
                        </Tooltip>
                      ) : null}

                      {hasReplayUrl ? (
                        <Tooltip tooltip="View replay">
                          <img
                            src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/interview-replay-ico.svg"
                            alt="View Replay Icon"
                            width={18}
                            style={{
                              marginLeft: hasTranscriptUrl ? "14px" : "0px",
                              cursor: "pointer"
                            }}
                            onClick={() =>
                              executeAction({
                                type: PAGE_ACTIONS.VIEW_REPLAY,
                                url: session.replay_url
                              })
                            }
                          />
                        </Tooltip>
                      ) : null}
                    </Div>
                  ) : null}
                </Div>

                <Div display={["column", "column", "row.flex-start.center"]}>
                  {isLiveOrSoon && hasConferenceUrl ? (
                    <Div
                      bra={1}
                      display="row.center.center"
                      color="white"
                      bg={{
                        default: "#084289",
                        hover: "#073c7b"
                      }}
                      height={37}
                      mr={3}
                      uppercase
                      fs={2}
                      flex={1}
                      style={{
                        fontFamily: "'Roboto Condensed', sans-serif",
                        padding: "0 20px"
                      }}
                      mb={[2, 2, 0]}
                      onClick={() =>
                        executeAction({
                          type: PAGE_ACTIONS.JOIN_AS_PARTICIPANT,
                          url: session.conference_url
                        })
                      }
                    >
                      join as participant
                    </Div>
                  ) : null}
                  {isLiveOrSoon && hasVideoUrl ? (
                    <Div
                      bra={1}
                      display="row.center.center"
                      color="white"
                      bg={{
                        default: "#084289",
                        hover: "#073c7b"
                      }}
                      height={37}
                      uppercase
                      fs={2}
                      flex={[1, 1, 1]}
                      style={{
                        fontFamily: "'Roboto Condensed', sans-serif",
                        padding: "0 20px",
                        marginRight: "12px"
                      }}
                      onClick={() =>
                        executeAction({
                          type: PAGE_ACTIONS.WATCH,
                          url: session.video_url
                        })
                      }
                    >
                      watch
                    </Div>
                  ) : null}
                </Div>
              </Div>
            </Div>
          );
        })
      )}
    </Div>
  );
};

export default decorator(Sessions);
