import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { Div } from "components/Base";
import * as R from "ramda";

const decorate = connect(
  (state, props) => ({
    title: getters.title(state, props),
    subtitle: getters.subtitle(state, props),
    content: getters.content(state, props)
  }),
  { init: actions.init }
);

const Layout = ({ init, pageData, title, subtitle, content }) => {
  useEffect(() => {
    init(pageData);
  }, [pageData]);

  return (
    <Div width={1} height={1} display="column.flex-start.center">
      <Div
        height={1}
        width={1}
        maxWidth={1000}
        style={{
          padding: "50px 40px"
        }}
        display="column.flex-start.flex-start"
      >
        <Div
          color="neutral8"
          fw={4}
          style={{
            fontSize: 26,
            marginBottom: 8
          }}
        >
          {title}
        </Div>
        {!R.isEmpty(subtitle) && (
          <Div
            color="neutral8"
            style={{
              fontSize: 22,
              marginBottom: 8
            }}
          >
            {subtitle}
          </Div>
        )}

        <Div dangerouslySetInnerHTML={{ __html: content }} />
      </Div>
    </Div>
  );
};

export default decorate(Layout);
