import React, { Component } from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import * as R from "ramda";
import { CONTENT_WIDTH } from "Organizations/Home/constants";
import { withRouter } from "react-router";
import { orgDetails } from "redux/modules/organization/selectors";
import css from "./styles.scss";
import { Link } from "react-router";
import { Div, SuperAvatar, LeftIcon } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    organization: orgDetails(state)
  }))
);

@CSSModules(css)
class Header extends Component {
  render() {
    const {
      organization,
      router: { push }
    } = this.props;

    return (
      <Div
        pt={4}
        pb={5}
        bg="primary8"
        height={150}
        display="column.flex-start.center"
      >
        <Div width={CONTENT_WIDTH}>
          <Div
            onClick={() => push("/home")}
            color="primary3"
            fs={2}
            underline
            display="row.flex-start.center"
            mb={1}
            mt={3}
          >
            <LeftIcon color="primary3" size={15} />
            My Home
          </Div>
          <Div display="row.space-between.flex-end" mt={3}>
            <Div display="row.flex-start.center">
              <Div mr={4}>
                <SuperAvatar
                  id={organization.id}
                  photoURL={organization.logo_image_url}
                  text={organization.name}
                  square
                  bc="white"
                  b={1}
                />
              </Div>
              <Div>
                <Div noLineHeight fs={7} fw={3} color="white">
                  {organization.name}
                </Div>
              </Div>
            </Div>
            <Div display="row.flex-start.center">
              <Link
                to={`/organization/${organization.id}/settings`}
                styleName="settings"
              >
                <i className="material-icons" styleName="settingsIcon">
                  settings
                </i>
                <span>Settings</span>
              </Link>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default decorate(Header);
