import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import { debounce, get } from "lodash";
import constructSearchObject from "utils/Search/construct-searchable-object";
import searchTable from "utils/Search/table-search";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";

/**
 * This is a component that takes in the list of records and searches through the
 * values.  It return records that match the search term.
 */
class SearchFactory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: this.props.records,
      isSearching: false
    };
    this.search = debounce(this.search, 500);
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.records !== this.props.records ||
      newProps.searchTerm !== this.props.searchTerm
    ) {
      this.handleSearchChange(newProps);
    }
  }

  componentWillUnmount() {
    this.search.cancel();
  }

  handleSearchChange = ({
    searchTerm = "",
    fields,
    records,
    eventDetails,
    references
  }) => {
    // if current and incoming are empty string, assume nothing has been searched yet
    // so just set state to incoming records
    if (!get(this.props, "searchTerm.length") && !get(searchTerm, "length")) {
      return this.setState({
        records
      });
    }

    const fieldsToSearch = flattenFields(fields).map(({ id }) => id);
    const searchObjects = records.map(record =>
      constructSearchObject(
        flattenFields(fields),
        record.values,
        record.id,
        eventDetails,
        {
          references
        }
      )
    );

    return this.search(fieldsToSearch, searchObjects, searchTerm);
  };

  search = async (keys, searchObjects, term) => {
    if (!term) {
      // Bail out if we don't have a search term
      return this.setState({
        records: this.props.records || []
      });
    }
    this.setState({ isSearching: true });

    const filtered = await searchTable(keys, searchObjects, term);
    this.setState({
      records: this.filterRows(
        filtered.map(({ id }) => id),
        this.props.records
      ),
      isSearching: false
    });
    return filtered;
  };

  filterRows = (ids, records) => records.filter(r => ids.includes(r.id));

  render() {
    const { children } = this.props;
    return cloneElement(children, {
      records: this.state.records,
      isSearching: this.state.isSearching
    });
  }
}

SearchFactory.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.object.isRequired
    })
  ).isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  searchTerm: PropTypes.string,
  references: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Child => props => (
  <SearchFactory {...props}>
    <Child {...props} />
  </SearchFactory>
);
