import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Orders/TicketsPasses";

import {
  getFilterCredentials,
  getAssignedPassesText
} from "Orders/TicketsPasses/selectors";

import * as R from "ramda";

import {
  Div,
  MediumFilledInput,
  SearchIcon,
  Text2,
  Text3,
  SmallCheckbox
} from "components/Base";

const decorate = connect(
  state => ({
    tempData: getFilterCredentials(state),
    credentialsFilter: getters.credentialsFilter(state),
    assignedPassesText: getAssignedPassesText(state),
    pricesTemp: getters.pricesTemp(state)
  }),
  {
    enableAllcredentials: () => actions.enableAllcredentials(),
    disableAllcredentials: () => actions.disableAllcredentials(),
    toggleCredential: actions.toggleCredential,
    setCredentialsFilter: actions.setCredentialsFilter
  }
);

const QuickAssignPricesCredentials = ({
  credentialsFilter,
  setCredentialsFilter,
  enableAllcredentials,
  disableAllcredentials,
  toggleCredential,
  tempData,
  assignedPassesText,
  pricesTemp
}) => (
  <Div display="row">
    <Div width={350} bg="white" bra={1}>
      <MediumFilledInput
        bg="neutral0"
        LeftIcon={SearchIcon}
        width={1}
        placeholder="Search..."
        color="neutral7"
        continuous
        value={credentialsFilter}
        onChange={value => setCredentialsFilter(value)}
      />
      <Div mt={3}>
        <Div display="row.space-between.center" mb={1}>
          <Text2 bold color="neutral5">
            Working Credentials
          </Text2>
          <Div display="row.flex-start.center">
            <Text2 bold color="neutral5" mr={3}>
              Select:
            </Text2>
            <Div
              fw={3}
              fs={2}
              color="primary7"
              underline
              mr={2}
              onClick={enableAllcredentials}
            >
              All
            </Div>
            <Div
              fw={3}
              fs={2}
              color="primary7"
              underline
              onClick={disableAllcredentials}
            >
              None
            </Div>
          </Div>
        </Div>
        <Div width={1}>
          {R.map(
            ({ sort_order: id, name, selected }) => (
              <Div
                key={id}
                mb={1}
                shadow={1}
                bra={1}
                bg="white"
                p={2}
                display="row.flex-start.center"
                width={1}
                bc="neutral1"
                ba={1}
              >
                <SmallCheckbox
                  selected={selected}
                  onClick={() => {
                    toggleCredential({ id });
                  }}
                />
                <Text2 black bold ml={2}>
                  {name}
                </Text2>
              </Div>
            ),
            tempData
          )}
        </Div>
      </Div>
    </Div>
    <Div width={171} ml={4}>
      <Text3 bold color="black">
        {assignedPassesText}
      </Text3>
      {!R.isEmpty(assignedPassesText) && (
        <Div>
          {R.map(
            ({ name, amount, tempId }) => (
              <Div key={tempId} display="row" mt={4}>
                <Text2 color="black">{`${name}:`}</Text2>
                <Text2 color="black" bold ml={1}>{`$${amount}`}</Text2>
              </Div>
            ),
            R.filter(
              price => price.amount > 0 && price.name.length >= 2,
              pricesTemp
            )
          )}
        </Div>
      )}
    </Div>
  </Div>
);

export default decorate(QuickAssignPricesCredentials);
