export const NAMESPACE = "OrdersList";

export const STATUS = {
  ALL: "all",
  ALL_ORDERS: "all_orders",
  ALL_INVOICES: "all_invoices",
  ALL_TRANSACTIONS: "all_transactions",
  PENDING_APPROVAL: "pending_approval",
  UNPAID: "unpaid",
  PAID: "paid",
  UNFULFILLED: "unfulfilled",
  ARCHIVED: "archived",
  OVERDUE: "overdue",
  FAILED: "failed",
  SUCCESSFUL: "successful",
  REFUNDS: "refunds",
  COMP_ORDERS: "comp_orders",
  CONFIRMATION_NOT_SENT: "conf_not_sent"
};

export const VIEWS = {
  ORDERS: "orders",
  INVOICES: "invoices",
  TRANSACTIONS: "transactions"
};
