import * as R from "ramda";
import { createSelector } from "reselect";

import { getFieldValue } from "ui-kit/Form/selectors";

import {
  PAYMENT_FIELDS,
  PAYMENT_FORM_ID
} from "EventLight/Expo/Sales/constants";
import { eventDetails } from "redux/modules/event/selectors";

export const getCollectDepositOptions = R.always([
  { id: "no", label: "No" },
  { id: "yes", label: "Yes" }
]);

export const getApplicationFeeOptions = getCollectDepositOptions;

export const getShowCollectPanel = createSelector(
  (state, props) =>
    getFieldValue(state, {
      ...props,
      instanceId: PAYMENT_FORM_ID,
      fieldId: PAYMENT_FIELDS.COLLECT_DEPOSIT
    }),
  R.equals("yes")
);

export const getShowApplicationPanel = createSelector(
  (state, props) =>
    getFieldValue(state, {
      ...props,
      instanceId: PAYMENT_FORM_ID,
      fieldId: PAYMENT_FIELDS.APPLICATION_FEE
    }),
  R.equals("yes")
);

export const getPaymentPercent = createSelector(
  state => eventDetails(state),
  R.propOr(0, "transaction_fee_percent")
);

export const getPaymentFee = createSelector(
  state => eventDetails(state),
  R.propOr("0.00", "transaction_fee_flat")
);
