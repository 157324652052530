import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";

const getRouteNames = createSelector(
  (_, props) => props.routes,
  R.map(R.propOr("", "name"))
);

const mapTabId = {
  EventLightSettingsEmailTemplates: TAB_IDS.EMAIL_TEMPLATES,
  EventLightSettingsDocumentTemplates: TAB_IDS.DOCUMENT_TEMPLATES,
  EventLightSettingsApprovalWorkflows: TAB_IDS.APPROVAL_WORKFLOWS,
  EventLightSettingsInvoiceSettings: TAB_IDS.INVOICE_SETTINGS,
  EventLightSettingsWebhooks: TAB_IDS.WEBHOOKS
};

export const getCurrentRoute = createSelector(
  getRouteNames,
  R.reduce((id, route) => {
    const tabId = R.prop(route, mapTabId);
    if (tabId) {
      return R.reduced(tabId);
    }
    return id;
  }, "")
);
