import PropTypes from "prop-types";
import React from "react";
import Recipient from "../Recipient";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Group = ({ name, users, toggleRecipient, selectedRecipients }) => (
  <div>
    <div styleName="heading">{name}</div>
    {users.length ? (
      users.map(u => (
        <Recipient
          selected={selectedRecipients.find(
            r => r.email === u.email && r.account === name
          )}
          toggleRecipient={(val, email) => toggleRecipient(val, email, name)}
          {...u}
        />
      ))
    ) : (
      <span styleName="noRecipients">No recipients on account</span>
    )}
  </div>
);

Group.propTypes = {
  toggleRecipient: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selectedRecipients: PropTypes.object.isRequired,
  users: PropTypes.arrayOf({
    email: PropTypes.string
  }).isRequired
};

export default CSSModules(Group, css);
