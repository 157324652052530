import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Popover from "material-ui/Popover";

class CellBase extends Component {
  constructor(props) {
    super(props);
    this.popoverContainer = React.createRef();
    this.wrapper = React.createRef();
    this.state = {
      showEditor: false
    };
  }

  componentDidMount() {
    // @NOTE: We're setting state here because we need refs to be set
    // before rendering. Thus, setting state forces re-render w/ resolved refs
    this.setState({
      showEditor: true
    });
  }

  @autobind
  onFocus(event) {
    event.preventDefault();
    this.setState({
      showEditor: true
    });
  }

  @autobind
  onToggleEditor() {
    // if showing editor, pass to handleRequestClose
    if (this.state.showEditor === true) {
      this.handleRequestClose();
    } else {
      this.setState({
        showEditor: true
      });
    }
  }

  handleRequestClose = reason => {
    if (reason === "clickAway") {
      this.setState(
        {
          showEditor: false
        },
        // @NOTE: Use this callback to handle anything that should be triggered
        // after the popover closes
        () => {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }
      );
    }
  };

  render() {
    const { editor, formatter } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <div className="react-grid-user-container" ref={this.wrapper}>
          <div
            tabIndex="0"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onMouseDown={this.onToggleEditor}
            ref="focus"
          >
            <div
              ref={this.popoverContainer}
              style={{
                height: "34px",
                display: "flex",
                padding: "0 0 0 8px",
                overflow: "hidden",
                background: "white"
              }}
            >
              {formatter}
            </div>
          </div>
          <Popover
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom"
            }}
            targetOrigin={{
              horizontal: "left",
              vertical: "top"
            }}
            open={this.state.showEditor}
            onRequestClose={this.handleRequestClose}
            anchorEl={this.popoverContainer.current}
            style={{ overflow: "visible" }}
            transitionDuration={0.1}
          >
            {this.state.showEditor
              ? React.cloneElement(editor, {
                  close: () => this.handleRequestClose("clickAway")
                })
              : null}
          </Popover>
        </div>
      </div>
    );
  }
}

CellBase.propTypes = {
  editor: PropTypes.node.isRequired,
  formatter: PropTypes.node
};

export default CellBase;
