import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import { isEqual } from "lodash";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";
import isRequiredValid from "utils/value-types/validations/time/required";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import moment from "moment";

// TODO: Global localizer kind of sucks.
import momentLocalizer from "react-widgets-moment";

const FORMAT = "hh:mm a";

import Flatpickr from "react-flatpickr";

const decorate = CSSModules(css);

class FormTime extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: props.formValues[props.field.id],
      isShown: false
    };
  }

  componentDidMount() {
    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    momentLocalizer(moment);

    // @NOTE: Copying this below from FormBaseInput because we're overriding componentDidMount
    this.setState({
      isValid: true
    });
    if (typeof this.props.register === "function") {
      this.props.register(this.validate);
    }
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: nextProps.formValues[this.props.field.id]
      });
    }
  }

  @autobind
  async isValid() {
    const errors = [];
    const required = this.props.field.is_required;
    const value = this.state.value;

    if (required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  formatValue(value) {
    // Kind of a hack for now, but the widget requires a date object.
    // Eventaully it would make sense to use `moment.duration`
    if (value && value.value) {
      const date = new Date(`1/1/1970 ${value.value}`);
      return value && moment(date).isValid() ? date : undefined;
    }
    return undefined;
  }

  wrapValue(value) {
    return value
      ? {
          type: "time",
          value: moment(value).format(FORMAT)
        }
      : undefined;
  }

  @autobind
  handleSave() {
    this.save(this.props.field.id, this.state.value);
  }

  @autobind
  handleChange(value) {
    this.setState({
      value: this.wrapValue(value)
    });
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  render() {
    const {
      name,
      settings: { isAdminField, description }
    } = this.props.field;
    const { connectDragSource, canShowSimpleSideBar, isChildren } = this.props;
    const required = this.props.field.is_required;
    const readonly = this.props.field.is_readonly;
    const style = this.props.styles;
    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
        isChildren={isChildren}
      >
        <InputGroup style={this.props.groupStyles}>
          {name ? (
            !canShowSimpleSideBar || isChildren ? (
              <Label
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
              >
                {name}
              </Label>
            ) : (
              <LabelSimpleForm
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
                instanceId={this.props.field.id}
                fieldData={this.props.field}
                isShown={this.state.isShown}
                setIsShown={() => this.handleToogleShowEditMode()}
              >
                {name}
              </LabelSimpleForm>
            )
          ) : null}

          <Flatpickr
            className={style.dateTimeInput}
            disabled={this.props.disabled}
            options={{
              altInput: true,
              enableTime: true,
              noCalendar: true,
              minuteIncrement: 5
            }}
            value={this.formatValue(this.state.value)}
            onClose={val => {
              this.setState(
                {
                  value: this.wrapValue(val[0])
                },
                () => {
                  this.handleSave();
                }
              );
            }}
            onChange={val => this.handleChange(val[0])}
          />
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormTime.propTypes = {
  groupStyles: PropTypes.object,
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default decorate(FormTime);
