import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/CateringDietaryRestrictionsEditor";
import Portal from "./CellPortal";
import BeforeClose from "./BeforeClose";

import { getMeta, ep } from "ui-kit/Datagrid/utils";

export default class DietaryRestrictionsEditor extends EditorBase {
  handleClose = () => this.props.onCommit();

  render() {
    const props = ep(this.props);
    const editorProps = {
      options: getMeta(props, [
        "eventDetails",
        "module_settings",
        "catering",
        "dietary_restrictions"
      ])
    };

    return (
      <Portal
        ref="editor"
        onClose={this.handleClose}
        editor={
          <div style={{ width: this.props.column.width - 2 }}>
            <BeforeClose do={this.handleClose}>
              <Editor
                {...editorProps}
                value={this.state}
                onChange={this.handleChange}
                autofocus
              />
            </BeforeClose>
          </div>
        }
      />
    );
  }
}
