import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  itemTypeId: null,
  items: [],
  itemGroups: [],
  searchTerm: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    showAddGroupModal: R.identity,
    showUpdateGroupModal: R.identity,
    showDeleteGroupModal: R.identity,
    showItemModal: R.identity,
    deleteItem: R.identity,
    moveItemUp: R.identity,
    moveItemDown: R.identity,
    moveGroupUp: R.identity,
    moveGroupDown: R.identity,
    reorderItems: R.identity,
    setInitialData: (_, { payload }) => ({
      items: payload.items,
      itemGroups: payload.itemGroups
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
