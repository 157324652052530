import FormInput from "components/Event/FormsV2/Sections/FormInput";
import FormNumber from "components/Event/FormsV2/Sections/FormNumber";
import FormCurrency from "components/Event/FormsV2/Sections/FormCurrency";
import FormDate from "components/Event/FormsV2/Sections/FormDate";
import FormTime from "components/Event/FormsV2/Sections/FormTime";
import FormDateTime from "components/Event/FormsV2/Sections/FormDateTime";
import FormTextarea from "components/Event/FormsV2/Sections/FormTextarea";
import FormCheckbox from "components/Event/FormsV2/Sections/FormCheckbox";
import FormDropdown from "components/Event/FormsV2/Sections/FormDropdown";
import FormRadios from "components/Event/FormsV2/Sections/FormRadios";
import FormImage from "components/Event/FormsV2/Sections/FormImage";
import FormFileUpload from "components/Event/FormsV2/Sections/FormFileUpload";
import FormCountries from "components/Event/FormsV2/Sections/FormCountries";
import FormPhone from "components/Event/FormsV2/Sections/FormPhone";
import FormReference from "components/Event/FormsV2/Sections/FormReference";
import FormCatering from "components/Event/FormsV2/Sections/FormCatering";
import FormCateringDietaryRestrictions from "components/Event/FormsV2/Sections/FormCateringDietaryRestrictions";
import FormCredentials from "components/Event/FormsV2/Sections/FormCredentials";
import FormEventDays from "components/Event/FormsV2/Sections/FormEventDays";
import FormCatalogItem from "components/Event/FormsV2/Sections/FormCatalogItem";

export default field => {
  switch (field.type) {
    case "email":
    case "text":
      return FormInput;
    case "number":
      return FormNumber;
    case "currency":
      return FormCurrency;
    case "checkbox":
      return FormCheckbox;
    case "catering-dietary-restrictions":
      return FormCateringDietaryRestrictions;
    case "dropdown":
      return FormDropdown;
    case "radio":
      return FormRadios;
    case "image":
      return FormImage;
    case "textarea":
      return FormTextarea;
    case "file":
      return FormFileUpload;
    case "date":
      return FormDate;
    case "time":
      return FormTime;
    case "datetime":
      return FormDateTime;
    case "phone":
      return FormPhone;
    case "countries":
      return FormCountries;
    case "reference":
      return FormReference;
    case "catering":
      return FormCatering;
    case "credentials":
      return FormCredentials;
    case "event-days":
      return FormEventDays;
    case "catalog-item":
      return FormCatalogItem;
    default:
      return null;
  }
};
