import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import AddScheduleModal from "Schedules/Create/View/Layout";

const decorate = connect(
  state => ({
    showCreateModal: getters.showCreateModal(state)
  }),
  {
    hideModal: () => actions.setShowCreateModal(false)
  }
);

const MiniLocationModal = ({ hideModal }) => (
  <AddScheduleModal hideModal={hideModal} redirectAfterSave={false} />
);

export default decorate(MiniLocationModal);
