import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GridView from "./GridView";
import { withRouter } from "react-router";

import {
  updateTableLayout,
  bulkSelectRows
} from "redux/modules/modules/records/settings/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { addReference } from "redux/modules/entityReferences/actions";
import { updateView } from "redux/modules/modules/views/actions";
import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import {
  tableLayoutOptions,
  selectedRows
} from "redux/modules/modules/records/settings/selectors";
import { activeView } from "redux/modules/modules/views/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state, props) {
  return {
    user: user(state),
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    moduleSettings: tableLayoutOptions(state, props.moduleId),
    selectedRows: selectedRows(state, props.moduleId),
    activeView: activeView(
      state,
      props.moduleId,
      props.params.viewId,
      props.params.groupId
    )
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      updateView,
      addReference,
      updateTableLayout,
      showModal,
      hideModal,
      setSelectedRows: rows => bulkSelectRows(props.moduleId, rows)
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GridView)
);
