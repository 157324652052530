import PropTypes from "prop-types";
import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ViewMoreModal = CSSModules(
  ({ hideModal, data, heading }) => (
    <StyleWrapper width={500} heading={heading} hideModal={hideModal}>
      <div styleName="container">{data}</div>
    </StyleWrapper>
  ),
  css
);

ViewMoreModal.propTypes = {
  data: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ViewMoreModal;
