import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import CSSModules from "react-css-modules";
import TimeAgo from "components/Global/CRM/Forms/Form/Submission/TimeAgo";
import Messages from "components/Global/CRM/Forms/Form/Submission/Messages";
import css from "./styles.scss";
import SubmissionCard from "components/PortalV2/Home/Form/Submission/Submission";

@CSSModules(css)
class MessageCard extends SubmissionCard {
  render() {
    const { form, submission } = this.props;

    const context = this.getContext(submission, form);

    return (
      <div styleName="container">
        <div styleName="main">
          <TimeAgo>
            {context.verb}&nbsp;{moment(context.timeAgo).fromNow()}
          </TimeAgo>
          {context.badges}
        </div>
        <Messages
          countOfMessages={submission.count_of_messages}
          countOfUnreadMessages={submission.count_of_unread_messages}
        />
      </div>
    );
  }
}

MessageCard.propTypes = {
  submission: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  }).isRequired
};

export default MessageCard;
