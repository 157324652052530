import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import {
  getModuleManagers,
  moduleManagers,
  moduleManagerRoles
} from "redux/modules/modules/managers";
import {
  getSpace,
  updateSpace
} from "redux/modules/approvalWorkflows/spaces/actions";
import { updateDefaultApprovers } from "redux/modules/approvalWorkflows/defaultApprovers/actions";
import {
  getWorkflow,
  updateWorkflows,
  updateWorkflow,
  addWorkflow,
  deleteWorkflow
} from "redux/modules/approvalWorkflows/workflows/actions";
import { eventDetails } from "redux/modules/event/selectors";
import { space } from "redux/modules/approvalWorkflows/spaces/selectors";
import { workflow } from "redux/modules/approvalWorkflows/workflows/selectors";
import { getUsers } from "redux/modules/eventUsers/actions";
import { users } from "redux/modules/eventUsers/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  const spaceKey = null;
  const spaceType = "credentials";
  return {
    user: user(state),
    spaceKey,
    spaceType,
    managers: moduleManagers(state, STANDARD_MODULES.credentials.id),
    managerRoles: moduleManagerRoles(state, STANDARD_MODULES.credentials.id),
    space: space(state, spaceKey),
    defaultWorkflow: workflow(state, "default"),
    eventDetails: eventDetails(state),
    users: R.filter(
      R.compose(
        R.equals(true),
        R.prop("is_event_user")
      )
    )(users(state))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getSpace,
      getUsers,
      getModuleManagers,
      updateSpace,
      updateDefaultApprovers,
      getWorkflow,
      updateWorkflows,
      updateWorkflow,
      addWorkflow,
      deleteWorkflow
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
