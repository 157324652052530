import React, { Component } from "react";
import getValue from "utils/value-types/get-value/boolean";
import { Div } from "components/Base";
import { isEqual } from "lodash";

export default class CheckboxCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    let value;
    if (getValue(this.props.value) === true) {
      value = <i className="material-icons">&#xE834;</i>;
    } else {
      value = <i className="material-icons">&#xE835;</i>;
    }
    return <Div display="row.flex-start.center">{value}</Div>;
  }
}
