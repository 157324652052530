import React from "react";
import { connect } from "react-redux";
import { actions } from "EventLight/Portals/Pages/model";
import { actions as treeTableActions } from "ui-kit/TreeTable/model";
import {
  Div,
  SearchIcon,
  BigFilledInput,
  BigFilledButton,
  AddCircleIcon
} from "components/Base";
import Table from "./Table";

const decorate = connect(
  null,
  {
    openAddPageModal: actions.openAddPageModal,
    openAddFolderModal: actions.openAddFolderModal,
    setFilterName: treeTableActions.setFilterName
  }
);

const Body = ({
  openAddPageModal,
  // openAddFolderModal,
  setFilterName
}) => (
  <>
    <Div display="row.space-between.center" mb={4}>
      <BigFilledInput
        LeftIcon={SearchIcon}
        width={1}
        bg="neutral1"
        color="neutral5"
        placeholder="Search pages..."
        onChange={value => setFilterName(value)}
        continuous
      />
      <Div ml={5} display="row.flex-start.center">
        {/* <BigOutlineButton
          mr={2}
          LeftIcon={() => (
            <FontIcon fs={5} color="black">
              folder_open
            </FontIcon>
          )}
        >
          <Div
            fs={3}
            fw={2}
            color="black"
            ml={2}
            onClick={() => openAddFolderModal({ id: null, folderName: "" })}
          >
            Create Folder
          </Div>
          </BigOutlineButton> */}
        <BigFilledButton
          color="white"
          bg="altB5"
          LeftIcon={AddCircleIcon}
          onClick={() => openAddPageModal({ id: null })}
        >
          New Page
        </BigFilledButton>
      </Div>
    </Div>
    <Table />
  </>
);

export default decorate(Body);
