import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "./model";

import { ROW_ACTIONS, ACTIONS_COL_ID, FIELD_IDS } from "./constants";

import { getters as FormGetters } from "ui-kit/Form/model";

export const getColumns = R.always([
  {
    id: "url",
    name: "URL",
    type: "text"
  },
  {
    id: "triggers",
    name: "Triggers",
    type: "text"
  },
  {
    id: ACTIONS_COL_ID,
    type: ACTIONS_COL_ID,
    name: "Actions",
    settings: {
      actions: [
        {
          id: ROW_ACTIONS.EDIT,
          leftIcon: "edit",
          name: "Edit"
        },
        {
          id: ROW_ACTIONS.DELETE,
          name: "Delete",
          leftIcon: "delete",
          confirm: "Are you sure?"
        }
      ]
    }
  }
]);

export const getRows = createSelector(
  getters.webhooks,
  R.compose(
    R.map(({ id, ...otherProps }) => {
      return {
        id,
        ...R.compose(
          R.mergeAll,
          R.map(key => {
            const value = R.prop(key, otherProps);
            return {
              [key]: {
                value:
                  key === "triggers"
                    ? `${R.compose(
                        R.length,
                        R.filter(isEnabled => isEnabled),
                        R.values
                      )(value)} triggers`
                    : value,
                type: [key] === status ? "status" : "text"
              }
            };
          }),
          R.keys
        )(otherProps)
      };
    }),
    R.sort((a, b) => a.order - b.order)
  )
);

export const getIsEditing = createSelector(
  getters.webhookId,
  R.compose(
    R.not,
    R.isEmpty
  )
);

export const getIsSaveValid = createSelector(
  FormGetters.fields,
  fields => {
    const url = R.pathOr("", [FIELD_IDS.URL, "value"], fields);
    return R.length(url) > 1;
  }
);

export const getSubscriptionsSave = createSelector(
  FormGetters.fields,
  fields => {
    const createOrder = R.pathOr(
      false,
      [FIELD_IDS.CREATE_ORDER, "value"],
      fields
    );
    const updateOrder = R.pathOr(
      false,
      [FIELD_IDS.UPDATE_ORDER, "value"],
      fields
    );
    const deleteOrder = R.pathOr(
      false,
      [FIELD_IDS.DELETE_ORDER, "value"],
      fields
    );
    const addLineItem = R.pathOr(
      false,
      [FIELD_IDS.ADD_LINE_ITEM, "value"],
      fields
    );
    const updateLineItem = R.pathOr(
      false,
      [FIELD_IDS.UPDATE_LINE_ITEM, "value"],
      fields
    );
    const deleteLineItem = R.pathOr(
      false,
      [FIELD_IDS.DELETE_LINE_ITEM, "value"],
      fields
    );
    const createEventRegistration = R.pathOr(
      false,
      [FIELD_IDS.CREATE_EVENT_REGISTRATION, "value"],
      fields
    );
    const createIssuance = R.pathOr(false, [FIELD_IDS.CREATE_ISSUANCE], fields);
    const deleteIssuance = R.pathOr(false, [FIELD_IDS.DELETE_ISSUANCE], fields);

    return [
      ...(createOrder ? [FIELD_IDS.CREATE_ORDER] : []),
      ...(updateOrder ? [FIELD_IDS.UPDATE_ORDER] : []),
      ...(deleteOrder ? [FIELD_IDS.DELETE_ORDER] : []),
      ...(addLineItem ? [FIELD_IDS.ADD_LINE_ITEM] : []),
      ...(updateLineItem ? [FIELD_IDS.UPDATE_LINE_ITEM] : []),
      ...(deleteLineItem ? [FIELD_IDS.DELETE_LINE_ITEM] : []),
      ...(createEventRegistration ? [FIELD_IDS.CREATE_EVENT_REGISTRATION] : []),
      ...(createIssuance ? [FIELD_IDS.CREATE_ISSUANCE] : []),
      ...(deleteIssuance ? [FIELD_IDS.DELETE_ISSUANCE] : [])
    ];
  }
);
