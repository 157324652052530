import { createModule } from "redux-mvc";

import approversLabel from "ApproversLabel";
import miniItemsSelector from "ui-kit/MiniItemsSelector";
import filterControls from "ui-kit/FilterControls";
import searchBar from "ui-kit/SearchBar";
import table from "ui-kit/Table";
import questions from "Items/QuestionsDetails";
import selectiveApproversLabel from "SelectiveApproversLabel";
import viewPicker from "ui-kit/ViewPicker";
import AddZonesModal from "Orders/AddZonesModal";

import handlers from "./handlers";

const manageBy = createModule({ ...handlers, batch: true });

manageBy.plugModule(approversLabel);
manageBy.plugModule(miniItemsSelector);
manageBy.plugModule(filterControls);
manageBy.plugModule(searchBar);
manageBy.plugModule(questions);
manageBy.plugModule(table);
manageBy.plugModule(selectiveApproversLabel);
manageBy.plugModule(viewPicker);
manageBy.plugModule(AddZonesModal);

const { actions, getters } = manageBy;

export default manageBy;

export { actions, getters };
