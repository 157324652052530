import React, { Component } from "react";
import {
  Div,
  Text3,
  SmallToggle,
  TinyToggle,
  Dropdown,
  BigInsetInput,
  CollapsablePanelType2,
  FontIcon
} from "components/Base";
import Tooltip from "components/Global/Tooltip";

const Inventory = ({
  itemName,
  accessType,
  integrationCountOfAvailable,
  integrationName,
  inventoryQuantity,
  isIntegrationItem,
  onQuantityChange,
  onTrackInventory,
  onTrackingTypeChange,
  trackInventory,
  isLightMode,
  assignInventoryOnApproval,
  onAssignInventoryOnApprovalonTrackInventory
}) => (
  <CollapsablePanelType2
    headingText="Track inventory for this item"
    headingExtras={
      <SmallToggle active={trackInventory} onClick={onTrackInventory} />
    }
  >
    <Div display="column" flex={1}>
      {isIntegrationItem && integrationCountOfAvailable !== null ? (
        <Div p={3} fs={2} bg="neutral2" mb={3}>
          The total amount remaining in {integrationName} is{" "}
          <i>{integrationCountOfAvailable}</i>.
        </Div>
      ) : null}

      <Div px={5}>
        <Div py={1}>
          <Text3 bold>Total Available</Text3>
          <Div pb={1} fs={1}>
            Total amount that you have now or will start with
          </Div>
          <BigInsetInput
            name="inventoryQuantity"
            width={1}
            fs={3}
            flex={1}
            style={{
              width: 200
            }}
            type="number"
            min="0"
            onChange={onQuantityChange}
            continuous
            value={inventoryQuantity}
            disabled={!trackInventory}
            bg={trackInventory ? undefined : "transparent"}
          />
        </Div>

        {isLightMode ? null : (
          <Div py={1}>
            <Text3 bold>Tracking Method</Text3>
            <Div pb={1} fs={1}>
              How this {itemName} is tracked or issued.
            </Div>
            <Dropdown
              disabled={!trackInventory}
              usePortal
              options={[
                {
                  label: "None",
                  value: null
                },
                {
                  label: "Barcode",
                  value: "barcode"
                },
                {
                  label: "Number Range",
                  value: "number_range"
                },
                {
                  label: "RFID",
                  value: "rfid"
                }
              ]}
              selected={accessType}
              onChange={onTrackingTypeChange}
            />
          </Div>
        )}

        <Div pt={2} pb={1}>
          <Text3 mb={1} bold display="row.flex-start.center">
            Auto-assign inventory on Approval
            <Tooltip tooltip="If this is toggled on, when an item of this type is approved it will be assigned the next available inventory.">
              <FontIcon ml={1} color="gray5" fs={3}>
                help
              </FontIcon>
            </Tooltip>
          </Text3>
          <SmallToggle
            active={assignInventoryOnApproval}
            onClick={onAssignInventoryOnApprovalonTrackInventory}
          />
        </Div>
      </Div>
    </Div>
  </CollapsablePanelType2>
);

const Field = ({ label, active, onClick }) => (
  <Div display="row.flex-start.center" mb={3}>
    <TinyToggle active={active} onClick={onClick} />
    <Div fw={3} ml={3}>
      {label}
    </Div>
  </Div>
);

const AssignmentManager = ({
  showInAssignmentManager,
  onShowInAssignmentManagerChange,
  showInPortalReports,
  onShowInPortalReportsChange
}) => (
  <CollapsablePanelType2 headingText="Portal Settings">
    <Div display="column" flex={1}>
      <Div fs={3} px={5}>
        <Field
          label="Show in Assignment Manager"
          active={showInAssignmentManager}
          onClick={onShowInAssignmentManagerChange}
        />
        <Field
          label="Show in Portal Reports"
          active={showInPortalReports}
          onClick={onShowInPortalReportsChange}
        />
        {/*
        // @NOTE: Hiding until in use
        <Field
          label="Allow multiple of this item to be assigned to one person in Assignment Manager"
          active={allowMultiplePerPersonInAssignmentManager}
          onClick={onMultiplePerPersonChange}
        />
        */}
      </Div>
    </Div>
  </CollapsablePanelType2>
);
class View extends Component {
  render() {
    const {
      accessType,
      integrationCountOfAvailable,
      integrationName,
      inventoryQuantity,
      isIntegrationItem,
      onQuantityChange,
      onTrackInventory,
      onTrackingTypeChange,
      trackInventory,
      assignInventoryOnApproval,
      onAssignInventoryOnApprovalonTrackInventory,
      showInAssignmentManager,
      onShowInAssignmentManagerChange,
      showInPortalReports,
      onShowInPortalReportsChange,
      allowMultiplePerPersonInAssignmentManager,
      onMultiplePerPersonChange,
      isLightMode
    } = this.props;
    return (
      <div>
        <Inventory
          {...{
            accessType,
            integrationCountOfAvailable,
            integrationName,
            inventoryQuantity,
            isIntegrationItem,
            onQuantityChange,
            onTrackInventory,
            onTrackingTypeChange,
            trackInventory,
            isLightMode,
            assignInventoryOnApproval,
            onAssignInventoryOnApprovalonTrackInventory
          }}
        />
        {isLightMode ? null : (
          <AssignmentManager
            {...{
              showInAssignmentManager,
              onShowInAssignmentManagerChange,
              showInPortalReports,
              onShowInPortalReportsChange,
              allowMultiplePerPersonInAssignmentManager,
              onMultiplePerPersonChange
            }}
          />
        )}
        {/*
        // @NOTE: Hiding until in use
        <RequiredFields
          {...{
            requiredFields,
            countOfActiveRequiredFields
          }}
        />
        */}
      </div>
    );
  }
}
export default View;
