import React from "react";
import { connect } from "react-redux";
import { getters } from "../model";
import { Div } from "components/Base";
import Body from "./Body";
import Modals from "./Modals";
import Loading from "ui-kit/Loading";
import Empty from "./Empty";

const decorate = connect(state => ({
  loading: getters.loading(state),
  hasPortal: getters.hasPortal(state),
  showIntakeSettingsModal: getters.showIntakeSettingsModal(state),
  showCustomizeSectionsModal: getters.showCustomizeSectionsModal(state)
}));

const Layout = ({ loading, hasPortal }) => (
  <Div width={1} display="row.center.flex-start" pt={5} pb={5} px={5}>
    <Div
      width={1}
      display="column"
      style={{
        maxWidth: "800px"
      }}
    >
      {loading ? <Loading /> : !hasPortal ? <Empty /> : <Body />}
    </Div>
    <Modals />
  </Div>
);

export default decorate(Layout);
