import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Error = ({ children, ...props }) => (
  <div styleName="error" {...props}>
    {children}
  </div>
);

Error.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(Error, css);
