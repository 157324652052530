import PropTypes from "prop-types";
import React, { Component } from "react";
import Loading from "components/Global/Loading";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class EventProfile extends Component {
  /*
  // @TODO: Figure out better way to handle this than putting in on the UI
  @autobind
  isUserMemberOfEvent (eventId) {
    return (find(this.props.myEvents, {id: eventId})) ? true : false;
  }

  @autobind
  handleUnauthenticatedUser () {
     this.props.showSnackbar({
      message: You are not a member of this event.
    });
    this.props.router.push({ pathname: `/home` });
  }
  */

  // eslint-disable-next-line consistent-return
  async componentDidMount() {
    const event = await this.getEvent();
    if (
      event &&
      event.is_light &&
      !window.location.pathname.includes("form") &&
      !window.location.pathname.includes("checkin")
    ) {
      window.location = `/event-light/${this.props.params.eventId}/dashboard`;
      return true;
    }
    if (event && !event.is_light && event.is_draft) {
      window.location = `/event/${this.props.params.eventId}/setup`;
      return true;
    }
  }

  getEvent = () => this.props.getEvent(this.props.params.eventId);

  render() {
    if (
      (this.props.isFetchingEventDetails &&
        !("id" in this.props.eventDetails)) ||
      (!this.props.eventDetails.is_light && this.props.eventDetails.is_draft)
    ) {
      return (
        <div styleName="loadingWrapper">
          <Loading content="Loading event..." />
        </div>
      );
    }

    return React.cloneElement(this.props.children, {
      details: this.props.eventDetails,
      getEvent: this.getEvent,
      eventId: this.props.params.eventId,
      params: this.props.params,
      location: this.props.location,
      showSnackbar: this.props.showSnackbar,
      showModal: this.props.showModal,
      hideModal: this.props.hideModal,
      dispatch: this.props.dispatch
    });
  }
}

EventProfile.propTypes = {
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  children: PropTypes.object.isRequired,
  isFetchingEventDetails: PropTypes.bool.isRequired,
  eventDetails: PropTypes.object.isRequired,
  getEvent: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default EventProfile;
