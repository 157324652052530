import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Forms from "./Forms";
import * as formTemplateActions from "redux/modules/eventEmailTemplates/actions";
import * as formTemplateSelectors from "redux/modules/eventEmailTemplates/selectors";

function mapStateToProps(state) {
  return {
    list: formTemplateSelectors.list(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, formTemplateActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
