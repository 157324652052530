import * as R from "ramda";

import PropTypes from "prop-types";
import React, { Component } from "react";
import FileIcon from "components/Atoms/FileIcon";
import Helpers from "utils/Global/Helpers";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import DeleteConfirmation from "ui-kit/DeleteConfirmation";

import { noop } from "utils/General";

@CSSModules(css)
class FileTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };
  }

  @autobind
  onDelete() {
    this.props.deleteFile(this.props.file);
  }

  @autobind
  onDownload(e) {
    e.stopPropagation();
    this.props.downloadFile(this.props.file.url);
  }

  @autobind
  onPreviewClick() {
    const onClick = R.propOr(noop, "onClick", this.props);
    return onClick(this.props.file);
  }

  render() {
    const { file, onClick } = this.props;

    // TODO: A couple ways to do this. Mimetype, extension, has map of the two.
    const fileType = file.mimetype ? file.mimetype.split("/").pop() : null;
    const isImage = Helpers.isImage(file.mimetype);
    const isClickable = typeof onClick === "function";

    return (
      <div
        ref="file"
        onClick={this.onPreviewClick}
        styleName={isClickable ? "clickableFile" : "file"}
        key={file.id}
      >
        <div
          styleName="preview"
          style={
            isImage
              ? {
                  backgroundImage: `url(${file.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top center"
                }
              : {}
          }
        >
          {!this.props.isReadOnly ? (
            <DeleteConfirmation
              onConfirm={this.onDelete}
              text="Are you sure you want to delete this file? This cannot be undone."
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div styleName="trash">
                <i className="material-icons">delete</i>
              </div>
            </DeleteConfirmation>
          ) : null}
          <div styleName="download" onClick={this.onDownload}>
            <i className="material-icons">file_download</i>
          </div>
          {!isImage ? (
            <div styleName="fileIcon">
              <FileIcon size={134} mimetype={file.mimetype} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div styleName="bar">
          <div styleName="filename">{file.filename}</div>
          <div styleName="mimetype">{fileType}</div>
        </div>
      </div>
    );
  }
}

FileTile.propTypes = {
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export default FileTile;
