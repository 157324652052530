import React, { Component } from "react";
import * as R from "ramda";

import constructName from "components/Global/Editors/Reference/utils/construct-name";
import constructPlaceholder from "components/Global/Editors/Reference/utils/construct-placeholder";
import isPerson from "components/Global/Editors/Reference/utils/is-person";
import { Div } from "components/Base";

import { getCellValue } from "ui-kit/Datagrid/utils";

class ReferenceFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const value = getCellValue(this.props, R.identity, "");
    const text = value
      ? isPerson(value)
        ? constructName(value.first, value.last)
        : constructPlaceholder(value.placeholder, value.count)
      : "";

    return (
      <Div
        title={text}
        display="row.flex-start.center"
        style={{
          lineHeight: "34px"
        }}
      >
        <Div truncate width={1}>
          {text}
        </Div>
      </Div>
    );
  }
}

export default ReferenceFormatter;
