import React from "react";
import ViewPicker from "ui-kit/ViewPicker/View";
import { Div } from "components/Base";
import { VIEWPICKER_INSTANCE_ID } from "EventLight/Common/Dashboard/constants";
import SaveViewVertical from "ui-kit/ViewPicker/View/SaveViewVertical";
import CanUserDo from "components/Global/Security/CanUserDo";
import { connect } from "react-redux";

import { getHasViewChanged } from "EventLight/Common/Dashboard/selectors";

const decorate = connect((state, props) => ({
  hasViewChanged: getHasViewChanged(state, props)
}));

const ViewPickerWrapper = ({ moduleId, hasViewChanged, ...styleProps }) => {
  return (
    <Div bg="neutral1" bra={1} p={1} {...styleProps}>
      <ViewPicker
        white
        instanceId={VIEWPICKER_INSTANCE_ID}
        moduleId={moduleId}
        shadow={{ default: "1", hover: "4" }}
      />
      <CanUserDo action={`${moduleId}_can_manage_shared_views`}>
        <SaveViewVertical
          instanceId={VIEWPICKER_INSTANCE_ID}
          hasViewChanged={hasViewChanged}
          collapsed={false}
          white
          vertical
        />
      </CanUserDo>
    </Div>
  );
};

export default decorate(ViewPickerWrapper);
