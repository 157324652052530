import { createContext } from "redux-mvc";
import * as R from "ramda";
import module from "../index";
import Layout from "./Layout";

const decorate = createContext({
  module,
  lifeCycle: {},
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "eventUsers"
    ]
  }
});

export default decorate(Layout);
