import React from "react";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import reportTemplates, { actions } from "Reports/ReportTemplates";
import Layout from "./Layout";
import rootSaga from "Reports/ReportTemplates/sagas";

import EditReportTemplateModal from "Reports/EditReportTemplateModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";

import { hideModal, showModal } from "redux/modules/modal/actions";

reportTemplates.setRootSaga(rootSaga);

const contextConfig = {
  module: reportTemplates,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init(this.props.moduleId));
    }
  },
  handlers: {
    showEditReportReportTemplateModal(templateId) {
      this.props.showModal({
        content: (
          <EditReportTemplateModal
            templateId={templateId}
            onDone={() => {
              this.store.dispatch(actions.refetchTemplates());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showDeleteReportTemplateModal(templateId) {
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading="Delete report template?"
            message={
              <div>
                Are you sure you want to delete this template?
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => {
              this.store.dispatch(actions.deleteTemplate(templateId));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", reportTemplates.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
};

const decorate = R.compose(
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  createContext(contextConfig)
);

export default decorate(Layout);
