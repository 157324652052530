/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getVenues: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/venues`,
        credentials,
        success,
        error
      });
    }),
  getTags: ({ credentials, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/event-tags`,
        credentials,
        success,
        error
      });
    }),
  getSession: ({ credentials, sessionId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sessions/session/${sessionId}`,
        credentials,
        success,
        error
      });
    }),
  addSession: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/sessions/session`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateSession: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sessions/session/${data.sessionId}`,
        credentials,
        data,
        success,
        error
      });
    })
};
