import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "ui-kit/FiltersPanel";

export const getSelectedOptions = createSelector(
  getters.selectedOptions,
  R.compose(
    R.flatten,
    R.values
  )
);

const getOptions = createSelector(
  (_, props) => R.prop("sections", props),
  R.compose(
    R.indexBy(R.prop("id")),
    R.map(R.pick(["id", "all"])),
    R.flatten,
    R.map(R.propOr([], "options"))
  )
);

export const getFiltersOn = createSelector(
  (_, props) => R.prop("filtersOn", props),
  getSelectedOptions,
  getOptions,
  (filtersOn, selectedOptions, options) => {
    if (typeof filtersOn !== "undefined") {
      return filtersOn;
    }

    return R.not(
      R.isEmpty(
        R.filter(
          id => R.not(R.pathOr(false, [id, "all"], options)),
          selectedOptions
        )
      )
    );
  }
);

export const getSelectedOptionsForSection = (state, props) => {
  return R.propOr(
    [],
    R.propOr("", "id", props),
    getters.selectedOptions(state, props)
  );
};
