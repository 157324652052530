import { ADD_REFERENCE, ADD_REFERENCES } from "./constants";

import { actions as DataActions } from "App/Data/model";

export function addReference(type, reference) {
  return {
    type: ADD_REFERENCE,
    payload: {
      type,
      reference
    }
  };
}

export function addReferences(references) {
  return dispatch => {
    dispatch({
      type: ADD_REFERENCES,
      payload: references
    });

    dispatch(DataActions.addReferences(references));
  };
}
