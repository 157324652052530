import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, SORTING } from "./constants";

const iniState = {
  loading: false,
  saving: false,
  forms: [],
  filterForm: "",
  selectedSorting: SORTING.POPULAR
};

const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    addSubmission: R.identity
  },
  namespace: NAMESPACE
});

export default handlers;
