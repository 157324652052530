import moment from "moment";
import { get, isNil } from "lodash";

export default function(value, meta = {}) {
  const DISPLAY_FORMAT = get(meta, "settings.format", "LL LT");
  if (value && moment(value).isValid()) {
    if (!get(meta, "settings.useDateTimeTimezone", false)) {
      const offset = get(value, "offset");
      if (!isNil(offset)) {
        return moment(value)
          .utcOffset(offset)
          .format(DISPLAY_FORMAT);
      }
    }
    return moment(value).format(DISPLAY_FORMAT);
  }
  return value;
}
