import React, { useState } from "react";
import * as R from "ramda";
import { MAX_LIMIT_OF_CHARACTERS } from "Portal/Description/constants";
import {
  Div,
  Text3,
  BigOutlineButton,
  UpIcon,
  DownIcon
} from "components/Base";

const Description = ({ title = "", description = "" }) => {
  const [viewMore, setViewMore] = useState(false);
  return (
    <Div>
      <Div
        mb={2}
        fw={4}
        style={{
          fontSize: "30px",
          color: "#123059"
        }}
      >
        {title}
      </Div>
      <Text3
        mb={4}
        style={{
          color: "#123059"
        }}
        className="lennd-wysiwyg-content portal-message-text"
      >
        <span
          dangerouslySetInnerHTML={{
            __html:
              R.length(description) > MAX_LIMIT_OF_CHARACTERS && !viewMore
                ? `${description.slice(0, MAX_LIMIT_OF_CHARACTERS)}...`
                : description
          }}
          style={{
            whiteSpace: "pre-line"
          }}
        />
      </Text3>
      {R.length(description) > MAX_LIMIT_OF_CHARACTERS && (
        <BigOutlineButton
          bg="neutral3"
          color="neutral8"
          LeftIcon={viewMore ? UpIcon : DownIcon}
          onClick={() => setViewMore(!viewMore)}
          pill
        >
          {`View ${viewMore ? "less" : "more"}`}
        </BigOutlineButton>
      )}
    </Div>
  );
};

export default Description;
