import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { Div, Text2, AddIcon, CloseIcon, FilterIcon } from "components/Base";
import { noop } from "utils/General";
import FilterPicker from "components/Global/Module/Sidebar/Popovers/Filter";
import Tooltip from "components/Global/Tooltip";

import { Panel } from "ui-kit/FilterControls/View";

import {
  getFieldFilters,
  getSelectedFieldFilters
} from "EventLight/Forms/Form/Results/selectors";

import { actions } from "EventLight/Forms/Form/Results";

const decorate = R.compose(
  connect(
    (state, props) => ({
      fields: getFieldFilters(state),
      filters: getSelectedFieldFilters(state)
    }),
    {
      setFilters: actions.setSelectedFieldFilters,
      removeFilter: actions.removeSelectedFieldFilter
    }
  )
);

const FieldSelect = ({
  fields,
  setFilters,
  filters,
  removeFilter,
  ...styleProps
}) => {
  if (!fields.length) return null;

  return (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <FilterIcon fs={4} mr={2} />
          <Text2 bold>Filters</Text2>
        </Div>
      }
      labelStyles={{
        pl: 2
      }}
      collapsedDefault={false}
      filtersOn={R.length(R.propOr([], "filters", filters))}
      bodyProps={{ ml: 3, mr: 2 }}
      {...styleProps}
    >
      <Div display="column.flex-start.flex-start">
        <FilterPicker filters={filters} onChange={setFilters} fields={fields}>
          <Div
            display="row"
            onClick={noop}
            mb={filters && filters.filters && filters.filters.length ? 2 : 0}
          >
            <Text2 bold color="primary7">
              Add Filter
              {filters && filters.filters && filters.filters.length
                ? ` (${filters.filters.length})`
                : null}
            </Text2>
            <AddIcon color="primary7" ml={1} />
          </Div>
        </FilterPicker>

        {filters && filters.filters && filters.filters.length ? (
          <Div display="column" width={1}>
            {filters.filters.map((filter, idx) => {
              const title = `"${R.compose(
                R.prop("name"),
                R.find(R.propEq("id", filter.fieldId))
              )(fields)}" ${filter.label}`;
              return (
                <Tooltip tooltip={title}>
                  <Div
                    display="row.space-between.center"
                    width={1}
                    fs={1}
                    mb={1}
                    bra={1}
                    p={2}
                    style={{
                      backgroundColor: "#eee"
                    }}
                  >
                    <Div truncate color="#222" fs={1}>
                      {title}
                    </Div>
                    <CloseIcon
                      size={15}
                      ml={1}
                      color={{
                        default: "gray5",
                        hover: "gray7"
                      }}
                      onClick={() => removeFilter(idx)}
                    />
                  </Div>
                </Tooltip>
              );
            })}
          </Div>
        ) : null}
      </Div>
    </Panel>
  );
};

export default decorate(FieldSelect);
