import * as R from "ramda";

export const iniState = {
  packages: {},
  showDescription: [],
  showAddPrice: [],
  showMultiplePrice: []
};

const EMPTY_PACKAGE = {
  name: "",
  description: "",
  prices: [],
  variants: []
};

export const reducers = {
  setPrices: ({ packages }, { payload: { id, prices } }) => ({
    packages: R.assocPath([id, "prices"], prices, packages)
  }),
  addPackageRequest: R.identity,
  addPackage: ({ packages }, { payload: { id, type } }) => ({
    packages: R.assoc(id, { ...EMPTY_PACKAGE, id, type }, packages)
  }),
  addPackageResponse: (
    state,
    { payload: { oldId, newItem, variants }, error }
  ) =>
    error
      ? state
      : {
          packages: R.assoc(
            newItem.id,
            {
              ...state.packages[oldId],
              id: newItem.id,
              variants: variants || []
            },
            R.omit([oldId], state.packages)
          )
        },
  deletePackageRequest: R.identity,
  deletePackageResponse: R.identity,
  deletePackage: (state, { payload: id }) => ({
    packages: R.omit([id], state.packages)
  }),
  updatePackageResponse: R.identity,
  showAddDescription: ({ showDescription }, { payload: packageId }) => ({
    showDescription: R.uniq(R.append(packageId, showDescription))
  }),
  showAddPrice: ({ showAddPrice }, { payload: packageId }) => ({
    showAddPrice: R.uniq(R.append(packageId, showAddPrice))
  })
};
