import React from "react";
import { Link } from "react-router";
import ShouldShowFormsV2 from "utils/should-show-forms-v2";
import { injectFeatureToggles } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export const MODULES = {
  accounts: {
    color: "#FF9F25",
    icon: <i className="material-icons">business_center</i>,
    iconKey: "business_center",
    name: "Groups",
    key: "accounts",
    permission: "accounts_read",
    path: params =>
      params.orgId
        ? `/organization/${params.orgId}/module/${STANDARD_MODULE_IDS.accounts.id}`
        : `/event/${params.eventId}/module/${STANDARD_MODULE_IDS.accounts.id}/dashboard`
  },
  schedules: {
    color: "#FA3C3C",
    icon: <i className="material-icons">schedule</i>,
    iconKey: "schedule",
    name: "Schedule",
    key: "schedule",
    permission: "schedules_read",
    path: params =>
      params.orgId
        ? `/organization/${params.orgId}/schedule/master`
        : `/event/${params.eventId}/schedule/master`
  },
  locations: {
    color: "#2987ab",
    icon: <i className="material-icons">location_city</i>,
    name: "Locations",
    key: "locations",
    permission: "locations_read",
    path: params => `/event/${params.eventId}/locations`
  },
  orders: {
    color: "#51cf66",
    icon: <i className="material-icons">attach_money</i>,
    name: "Orders",
    key: "orders",
    permission: "orders_read",
    path: params =>
      params.orgId
        ? `/organization/${params.orgId}/orders/orders`
        : `/event/${params.eventId}/orders/orders`
  },
  inventory: {
    color: "#080078",
    icon: <i className="material-icons">widgets</i>,
    iconKey: "",
    name: "Inventory",
    key: "inventory",
    permission: "inventory_read",
    path: (params, _, featureToggles) => {
      return featureToggles.viewInventory
        ? `/event/${params.eventId}/inventory/manage/all-requests`
        : `/event/${params.eventId}/inventory`;
    }
  },
  contacts: {
    color: "#00A7F0",
    icon: <i className="material-icons">people</i>,
    iconKey: "contacts",
    name: "People",
    key: "contacts",
    permission: "contacts_read",
    path: params =>
      params.orgId
        ? `/organization/${params.orgId}/module/${STANDARD_MODULE_IDS.contacts.id}`
        : `/event/${params.eventId}/module/${STANDARD_MODULE_IDS.contacts.id}/dashboard`
  },
  files: {
    color: "#238FCC",
    icon: <i className="material-icons">folder_open</i>,
    iconKey: "insert_drive_file",
    name: "Files",
    key: "files",
    permission: "documents_read",
    path: params =>
      params.orgId
        ? `/organization/${params.orgId}/files`
        : `/event/${params.eventId}/module/${STANDARD_MODULE_IDS.files.id}`
  },
  sessions: {
    color: "#23ccbc",
    icon: <i className="material-icons">event_note</i>,
    iconKey: "event_note",
    name: "Sessions",
    key: "sessions",
    permission: "sessions_read",
    path: params => `/event/${params.eventId}/sessions`
  },
  virtual: {
    color: "#0A29CA",
    icon: <i className="material-icons">present_to_all</i>,
    iconKey: "present_to_all",
    name: "Virtual",
    key: "virtual",
    permission: "virtual_read",
    path: params => `/event/${params.eventId}/virtual/dashboard`
  },
  document_requests: {
    color: "#00A7F0",
    icon: <i className="material-icons">attach_file</i>,
    iconKey: "attach_file",
    name: "File Requests",
    key: "document_requests",
    permission: "document_requests_read",
    path: params =>
      `/event/${params.eventId}/module/${STANDARD_MODULE_IDS.documentRequests.id}`
  },
  formsv3: {
    color: "#7529CA",
    icon: <i className="material-icons">&#xE051;</i>,
    iconKey: "#xE051w;",
    name: "Forms",
    key: "forms",
    permission: "forms_read",
    path: params => `/event/${params.eventId}/forms-v2`
  },
  portal: {
    color: "#7529CA",
    icon: <i className="material-icons">pages</i>,
    iconKey: "pages",
    name: "Portals",
    key: "portal",
    permission: "portal_read",
    path: params => `/event/${params.eventId}/portals`
  },
  credentials: {
    color: "#C3005C",
    icon: <i className="material-icons">assignment_turned_in</i>,
    iconKey: "assignment_turned_in",
    name: "Passes",
    key: "credentials",
    permission: "credentials_read",
    path: params => `/event/${params.eventId}/passes/manage/all-requests`
  },
  catering: {
    color: "#F59D00",
    icon: <i className="material-icons">restaurant</i>,
    iconKey: "restaurant",
    name: "Catering",
    key: "catering",
    permission: "catering_read",
    path: params => `/event/${params.eventId}/catering/manage/all-requests`
  },
  booths: {
    color: "#51cf9b",
    icon: <i className="material-icons">store_mall_directory</i>,
    iconKey: "store_mall_directory",
    name: "Booths",
    key: "booths",
    permission: "credentials_read",
    path: params => `/event/${params.eventId}/booths/manage/all-requests`
  },
  users: {
    color: "#295DAB",
    icon: <i className="material-icons">&#xE7FB;</i>,
    iconKey: "&#xE7FB;",
    name: "Users",
    key: "users",
    permission: "users_read",
    path: params => `/event/${params.eventId}/settings/users`
  },
  settings: {
    color: "#CCCCCC",
    icon: <i className="material-icons">settings</i>,
    iconKey: "settings",
    name: "Settings",
    key: "settings",
    permission: "settings_read",
    path: params => `/event/${params.eventId}/settings`
  },
  reports: {
    color: "#cc5788",
    icon: <i className="material-icons">show_chart</i>,
    iconKey: "reports",
    name: "Reports",
    key: "reports",
    permission: "settings_read",
    path: params => `/event/${params.eventId}/reports`
  },
  unknown: {
    color: "#CCCCCC",
    icon: <i className="material-icons">event_note</i>,
    iconKey: "event_note",
    key: "unknown"
  },
  requests: {
    color: "#86DBA9",
    icon: <i className="material-icons">inbox</i>,
    iconKey: "inbox",
    name: "Requests",
    key: "requests",
    path: params => `/organization/${params.orgId}/requests/manage`
  },
  requests_portal: {
    color: "#8755F2",
    icon: <i className="material-icons">pages</i>,
    iconKey: "pages",
    name: "Request Portal",
    key: "requests_portal",
    path: params => `/requests/${params.orgId}`
  },
  events: {
    color: "#444444",
    icon: <i className="material-icons">list</i>,
    iconKey: "events",
    name: "Events",
    key: "events",
    path: params => `/organization/${params.orgId}/events`
  }
};

export const StandardModuleIcon = injectFeatureToggles([
  flags.CAN_VIEW_CREDENTIALS_MANAGEMENT.NAME,
  flags.CAN_VIEW_CATERING_MANAGEMENT.NAME,
  flags.CAN_VIEW_INVENTORY.NAME
])(
  ({
    className,
    params,
    eventDetails,
    module,
    showLink,
    showLabel,
    featureToggles
  }) => {
    const matchingModule = MODULES[module.internal_name];

    if (!matchingModule) {
      return null;
    }

    const { name: moduleName, color, icon, path } = matchingModule;

    return showLink ? (
      <Link
        id={module.internal_name}
        className={`${className} menu-icon-link`}
        to={path(params, eventDetails, featureToggles)}
      >
        <div className="circle" style={{ backgroundColor: color }}>
          {icon}
        </div>
        {showLabel ? <div className="label">{moduleName}</div> : null}
      </Link>
    ) : (
      <div id={module.internal_name} className={className}>
        <div className="circle" style={{ backgroundColor: color }}>
          {icon}
        </div>
        {showLabel ? <div className="label">{moduleName}</div> : null}
      </div>
    );
  }
);

const CustomModuleIcon = ({
  className,
  eventDetails,
  module,
  showLink,
  showLabel
}) =>
  showLink ? (
    <Link
      id={module.internal_name}
      className={`${className} menu-icon-link`}
      to={
        module.type_id === STANDARD_MODULE_IDS.schedules.id
          ? `/event/${eventDetails.id}/schedule/${module.id}`
          : `/event/${eventDetails.id}/module/${module.id}/dashboard`
      }
    >
      <div className="circle" style={{ backgroundColor: module.color }}>
        <i className="material-icons">{module.icon}</i>
      </div>
      <div className="label">{module.name}</div>
    </Link>
  ) : (
    <div id={module.internal_name} className={className}>
      <div className="circle" style={{ backgroundColor: module.color }}>
        <i className="material-icons">{module.icon}</i>
      </div>
      {showLabel ? <div className="label">{module.name}</div> : null}
    </div>
  );

export const SmallMenuIcon = props => {
  if (props.module.source === "standard") {
    return <StandardModuleIcon {...props} className="small-menu-icon" />;
  }
  return <CustomModuleIcon {...props} className="small-menu-icon" />;
};

export const LargeMenuIcon = props => {
  if (props.module.source === "standard") {
    return <StandardModuleIcon {...props} className="large-menu-icon" />;
  }

  return <CustomModuleIcon {...props} className="large-menu-icon" />;
};
