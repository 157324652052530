/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getters, actions } from "Schedules/Create";
import { selectedUserAndProfiles } from "Schedules/Create/selectors";
import { actions as allowAccessActions } from "Schedules/AllowAccessUsersModal";
import AllowAccessUsers from "Schedules/AllowAccessUsersModal/View";
import ColorPicker from "components/Global/ColorPicker";
import IconSelector from "components/Global/OptionSelector/AccountsOption/IconSelector";
import FormElements from "components/Global/Modal/Layout/FormElements";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import TypeToggler from "./TypeToggler";

import { noop } from "utils/General";

import { getVisibleColumns, getIsProject } from "./../selectors";

import {
  DATE_TIME_OPTIONS,
  TIME_ZONES,
  END_DATE,
  DATE_FORMATS,
  TIME_FORMATS,
  START_DATE,
  DATE_TIME_TABLE_CONFIG
} from "../constants";

import IconSelectorLabel from "components/Global/OptionSelector/AccountsOption/IconSelectorLabel";
import {
  CollapsablePanelType2,
  SmallFilledButton,
  SmallToggle,
  Dropdown,
  Div,
  MediumOutlineInput,
  SmallOutlineButton,
  Text2,
  Text3,
  SimpleTable,
  Text1,
  FontIcon
} from "components/Base";

const { InputGroup, Label } = FormElements;

import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";

const { headers, rowProps } = DATE_TIME_TABLE_CONFIG;
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const decorate = connect(
  (state, props) => ({
    scheduleName: getters.scheduleName(state, props),
    color: getters.color(state, props),
    icon: getters.icon(state, props),
    sharing: getters.sharing(state, props),
    moreOptions: getters.moreOptions(state, props),
    dateTimeOptions: getters.dateTimeOptions(state, props),
    dateFormat: getters.dateFormat(state, props),
    timeFormat: getters.timeFormat(state, props),
    timeZone: getters.timeZone(state, props),
    showOnPortals: getters.showOnPortals(state, props),
    showOnProfileRecords: getters.showOnProfileRecords(state, props),
    loading: getters.loading(state, props),
    selectedElements: selectedUserAndProfiles(state),
    visibleColumns: getVisibleColumns(state),
    scheduleId: getters.scheduleId(state),
    isProject: getIsProject(state, props)
  }),
  {
    setScheduleName: actions.setScheduleName,
    setColor: actions.setColor,
    setIcon: actions.setIcon,
    setSharing: actions.setSharing,
    selectDateTimeOptions: actions.selectDateTimeOptions,
    setTimeZone: actions.setTimeZone,
    setShowOnPortals: actions.setShowOnPortals,
    setShowOnProfileRecords: actions.setShowOnProfileRecords,
    saveNewSchedule: actions.saveNewSchedule,
    updateSchedule: actions.updateSchedule,
    setDateFormat: actions.setDateFormat,
    setTimeFormat: actions.setTimeFormat,
    openAllowAccessModal: () =>
      allowAccessActions.setShowAllowAccessModal(true),
    init: actions.init,
    reset: actions.reset
  }
);

const renderDropDownValue = ({ label, value }) => (
  <Div display="column.space-around" style={{ height: "100%" }}>
    <Text2 m={0} style={{ lineHeight: 1 }}>
      {label}
    </Text2>
    <Text1 m={0} bold style={{ lineHeight: 1 }}>
      {value}
    </Text1>
  </Div>
);

const renderTimeDifference = (startDate, endDate) => {
  const timeDelta = moment.duration(
    moment.unix(endDate).diff(moment.unix(startDate))
  );
  return `${timeDelta.hours()}h ${timeDelta.minutes()}m ${timeDelta.seconds()}s`;
};

const renderFormattedDate = (date, format) => moment.unix(date).format(format);

const renderSelectedUsersAndProfile = ({ profileIds, userIds }) => {
  let output = "Only you";
  const userIdsLength = userIds.length;
  const profileIdsLength = profileIds.length;
  if (userIdsLength && profileIdsLength) {
    output = `${userIdsLength} users and ${profileIdsLength} profiles`;
  } else if (userIdsLength) {
    output = `${userIdsLength} users`;
  } else if (profileIdsLength) {
    output = `${profileIdsLength} profiles`;
  }
  return output;
};

const AddScheduleModal = ({
  loading,
  scheduleName,
  setScheduleName,
  color,
  setColor,
  icon,
  setIcon,
  sharing,
  setSharing,
  dateTimeOptions,
  selectDateTimeOptions,
  showOnPortals,
  showOnProfileRecords,
  setShowOnPortals,
  setShowOnProfileRecords,
  timeZone,
  setTimeZone,
  hideModal,
  saveNewSchedule,
  dateFormat,
  timeFormat,
  setDateFormat,
  setTimeFormat,
  openAllowAccessModal,
  selectedElements,
  visibleColumns,
  scheduleId,
  isProject,
  updateSchedule,
  onSave = noop,
  init,
  reset
}) => {
  useEffect(() => {
    if (scheduleId) {
      init(scheduleId);
    }
  }, [scheduleId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const DateTimeFormatsTable = SimpleTable({
    columnProps: visibleColumns,
    headerCellComps: headers,
    rowCellComps: rowProps
  });

  function renderOptions({ value, label }) {
    const isChecked =
      dateTimeOptions.find(option => option === value) === value;
    return isChecked ? (
      <SmallFilledButton
        key={`${value}-${label}`}
        bg="primary8"
        mr={2}
        mb={1}
        mt={1}
        onClick={() => selectDateTimeOptions(value)}
      >
        {label}
      </SmallFilledButton>
    ) : (
      <SmallOutlineButton
        key={`${value}-${label}`}
        mr={2}
        mb={1}
        mt={1}
        onClick={() => selectDateTimeOptions(value)}
      >
        {label}
      </SmallOutlineButton>
    );
  }

  const FORMATTED_ROW = [
    {
      date: renderFormattedDate(START_DATE, dateFormat),
      time: renderFormattedDate(START_DATE, timeFormat),
      endTime: renderFormattedDate(END_DATE, timeFormat),
      endDate: renderFormattedDate(END_DATE, dateFormat),
      duration: renderTimeDifference(START_DATE, END_DATE)
    }
  ];

  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title={
        scheduleId
          ? `Edit ${isProject ? "Project" : "Schedule"}`
          : `Create ${isProject ? "Project" : "Schedule"}`
      }
      style={{ width: 715, height: 570 }}
      isPortal={false}
      bg="white"
      stopPropagation
    >
      <Body style={{ height: 438, maxHeight: 438 }}>
        <Div display="column" bg="white" mt={6} my={3} mx={15}>
          {/* <TypeToggler /> */}
          <Div display="row.space-between.center" mx={5} mb={6}>
            <Div display="column" mr={3} width={0.3}>
              <Text3 bold color="neutral8">
                {isProject ? "Project" : "Schedule"} Name
              </Text3>
            </Div>

            <Div display="column.center.flex-start" width={0.7}>
              <MediumOutlineInput
                continuous
                type="text"
                value={scheduleName}
                name="name"
                onChange={setScheduleName}
                autoFocus
                width={1}
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mx={5} mb={6}>
            <Div display="column" mr={3} width={0.3}>
              <Text3 bold color="neutral8">
                Color & Icon
              </Text3>
            </Div>

            <Div display="row.flex-start.center" width={0.7}>
              <Div mr={3}>
                <ColorPicker selected={color} onSelect={setColor} />
              </Div>

              <IconSelector selectIcon={setIcon}>
                <IconSelectorLabel icon={icon} />
              </IconSelector>
            </Div>
          </Div>

          {/*
          <Div display="row.space-between.center" mx={5} mb={6}>
            <Div display="column" mr={3} width={0.5}>
              <Div fs={5} fw={3} color="gray9">
                Sharing
              </Div>
            </Div>

            <Div display="row.flex-start.center" width={0.5}>
              <SmallToggle
                active={sharing}
                onClick={() => setSharing(!sharing)}
              />
              {sharing && (
                <Div display="row.space-arround.center" fw={3} ml={3}>
                  <Div>Private to: </Div>
                  <Div ml={2} underline onClick={openAllowAccessModal}>
                    {renderSelectedUsersAndProfile(selectedElements)}
                  </Div>
                </Div>
              )}
            </Div>
          </Div>
          */}

          <Div display="row.space-between.center" mx={5} mb={6}>
            <Div display="column" mr={3} width={0.3}>
              <Text3 bold color="neutral8">
                Preview
              </Text3>
            </Div>

            <Div display="row.flex-start.center" width={0.7}>
              <Div display="row.flex-start.center" bg="gray0" p={3} bra={1}>
                <Div
                  display="row.center.center"
                  width={58}
                  height={58}
                  bra={2}
                  bg={color.backgroundColor}
                  mr={2}
                  style={{
                    flexShrink: 0
                  }}
                >
                  <FontIcon
                    fs={6}
                    style={{
                      color: color.textColor
                    }}
                  >
                    {icon}
                  </FontIcon>
                </Div>
                <Div>{scheduleName}</Div>
              </Div>
            </Div>
          </Div>

          {/*
          <CollapsablePanelType2 headingText="More Options" collapsedDefault>
            <Div display="row.space-between.center" mb={6}>
              <Div display="column" mr={3} width={0.5}>
                <Div fs={4} fw={3} color="gray9">
                  Date/Time Options
                </Div>
                <Div
                  fs={1}
                  color="neutral6"
                  style={{
                    maxWidth: 200
                  }}
                >
                  What will display in your schedule. You can change this at
                  anytime.
                </Div>
              </Div>
              <Div
                display="row.flex-start"
                style={{ flexWrap: "wrap" }}
                width={0.5}
              >
                {DATE_TIME_OPTIONS.map(renderOptions)}
              </Div>
            </Div>

            <Div display="column" mb={6}>
              <Div display="row.space-between.center">
                <Div display="column" width={0.5}>
                  <Div fs={4} fw={3} color="gray9">
                    Formats
                  </Div>
                </Div>

                <Div display="row.flex-start.center" width={0.5}>
                  <InputGroup style={{ marginRight: 10 }}>
                    <Label>Date Formats</Label>
                    <Dropdown
                      selected={dateFormat}
                      onChange={({ value }) => setDateFormat(value)}
                      options={DATE_FORMATS}
                      style={{ minWidth: 130 }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Label>Time Format</Label>
                    <Dropdown
                      selected={timeFormat}
                      onChange={({ value }) => setTimeFormat(value)}
                      options={TIME_FORMATS}
                      style={{ minWidth: 130 }}
                    />
                  </InputGroup>
                </Div>
              </Div>
              {!R.isEmpty(dateTimeOptions) && (
                <DateTimeFormatsTable rowsData={FORMATTED_ROW} />
              )}
            </Div>

            <Div display="row.space-between.center" mb={6}>
              <Div fs={4} fw={3} color="gray9" width={0.5}>
                Timezone
              </Div>
              <Div width={0.5}>
                <Dropdown
                  selected={timeZone}
                  onChange={value => setTimeZone(value ? value.value : value)}
                  options={TIME_ZONES}
                  style={{ minWidth: 310 }}
                  valueRenderer={renderDropDownValue}
                />
              </Div>
            </Div>

            <Div display="row.space-between.center" mb={6}>
              <Div display="column" mr={3} width={0.5}>
                <Div fs={4} fw={3} color="gray9">
                  Visibility
                </Div>
              </Div>
              <Div display="column.flex-end" width={0.5}>
                <Div display="row.flex-start.center" mb={1}>
                  <SmallToggle
                    mr={3}
                    active={showOnPortals}
                    onClick={() => setShowOnPortals(!showOnPortals)}
                  />
                  <Div fw={3}>Show on Portals (Group & User)</Div>
                </Div>

                <Div display="row.flex-start.center">
                  <SmallToggle
                    mr={3}
                    active={showOnProfileRecords}
                    onClick={() =>
                      setShowOnProfileRecords(!showOnProfileRecords)
                    }
                  />
                  <Div fw={3}>Show on Profile Record</Div>
                </Div>
              </Div>
            </Div>
          </CollapsablePanelType2>
          */}
        </Div>
      </Body>

      {/* footer */}
      <Div display="row.flex-start.center" bg="white" mx={15} bt={1} bc="gray1">
        <ButtonGroup>
          {scheduleId ? (
            <Submit
              title={`Update ${isProject ? "Project" : "Schedule"}`}
              disabled={!scheduleName.length}
              onClick={updateSchedule}
            />
          ) : (
            <Submit
              title={
                loading
                  ? "Saving..."
                  : `Finish: Continue to the ${
                      isProject ? "Project" : "Schedule"
                    }`
              }
              disabled={!scheduleName.length}
              onClick={
                loading
                  ? undefined
                  : () => saveNewSchedule({ onSaveCallback: onSave, hideModal })
              }
            />
          )}
          <ButtonOutline title="Cancel" onClick={hideModal} />
        </ButtonGroup>
      </Div>

      <AllowAccessUsers />
    </MiniModalWrapper>
  );
};

export default decorate(AddScheduleModal);
