import { createModule } from "redux-mvc";
import handlers from "./handlers";

import fuzionConnectModal from "Integrations/FuzionConnectModal";
import fuzionMappingModal from "Integrations/FuzionMappingModal";

const module = createModule(handlers);

module.plugModule(fuzionConnectModal);
module.plugModule(fuzionMappingModal);

const { actions, getters } = handlers;

export { actions, getters };

export default module;
