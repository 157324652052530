import moment from "moment";
import { get, isNil } from "lodash";
import getDatetimeValue from "utils/value-types/get-value/datetime";

export default datetimeValue => {
  const value = getDatetimeValue(datetimeValue);
  if (value && moment(value).isValid()) {
    const offset = get(datetimeValue, "value.offset");
    if (!isNil(offset)) {
      return moment(value).utcOffset(offset);
    }
    return moment(value);
  }
  return value;
};
