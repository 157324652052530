import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { findDOMNode } from "react-dom";
import * as R from "ramda";
import { DragSource, DropTarget } from "react-dnd";
import { toClass } from "../utils";
import { Div } from "../Base/index";

// Use like so:
// const MyCards = Cards(MyCardView, "MY_CARD_TYPE");
// <MyCards cards={myData} onReorder={handleReorder} />

const ItemTypes = {
  CARD: "card"
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  },

  endDrag(props, monitor) {
    props.saveOrder();
  }
};

// see React dnd docs for comments
const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};

// //////////////////////////////////////////////////////////////////////////////

function CardWrapper(CardToBeWrapped, ItemTypeId = ItemTypes.CARD) {
  const CardClass = toClass(CardToBeWrapped);

  const DropTargetWrapper = DropTarget(ItemTypeId, cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }));

  const DragSourceWrapper = DragSource(
    ItemTypeId,
    cardSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  );

  class Card extends Component {
    static propTypes = {
      connectDragSource: PropTypes.func.isRequired,
      connectDropTarget: PropTypes.func.isRequired,
      index: PropTypes.number.isRequired,
      isDragging: PropTypes.bool.isRequired,
      id: PropTypes.any.isRequired,
      moveCard: PropTypes.func.isRequired,
      saveOrder: PropTypes.func.isRequired
    };

    render() {
      const { connectDragSource, connectDropTarget, ...other } = this.props;
      return (
        <CardClass
          ref={instance => {
            this.props.connectDropTarget(findDOMNode(instance));
            this.props.connectDragSource(findDOMNode(instance));
          }}
          {...other}
        />
      );
    }
  }
  return DropTargetWrapper(DragSourceWrapper(Card));
}

// //////////////////////////////////////////////////////////////////////////////

function Cards(CardToBeWrapped, ItemTypeId) {
  const Card = CardWrapper(CardToBeWrapped, ItemTypeId);

  class CardsController extends Component {
    state = {
      cards: this.props.cards
    };

    componentWillReceiveProps(nextProps) {
      this.setState({
        cards: nextProps.cards
      });
    }

    moveCard = (dragIndex, hoverIndex) => {
      const { cards } = this.state;
      const dragCard = cards[dragIndex];
      const updatedState = update(this.state, {
        cards: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
        }
      });

      this.setState(updatedState);
    };

    saveOrder = () => {
      this.props.onReorder(this.state.cards);
    };

    render() {
      const { cards } = this.state;
      const { cards: cardsProps, Wrapper = Div, ...otherProps } = this.props;

      return (
        <Wrapper>
          {R.addIndex(R.map)(
            (card, i) => (
              <Card
                key={card.key || card.id}
                index={i}
                moveCard={this.moveCard}
                saveOrder={this.saveOrder}
                {...otherProps}
                {...card}
              />
            ),
            cards || []
          )}
        </Wrapper>
      );
    }
  }

  return CardsController;
}

// //////////////////////////////////////////////////////////////////////////////

export { CardWrapper, Cards };
