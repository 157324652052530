import * as R from "ramda";
import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take,
  delay
} from "redux-saga/effects";

import { userId, getCredentials } from "redux/modules/user/selectors";
import { orgId } from "redux/modules/organization/selectors";
import { eventId } from "redux/modules/event/selectors";

import { actions, getters } from "Orders/ImportModal";

import Helpers from "utils/Global/Helpers";
import importModalApi from "Orders/ImportModal/api";
import recordTypeApi from "redux/modules/modules/recordTypes/api";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { registerError } from "redux/modules/errors/actions";
import { hideModal } from "redux/modules/modal/actions";

import { navigateTo } from "utils/General";

import {
  getSectionFieldsToDownload,
  getMappedFields,
  getCustomOrderType
} from "Orders/ImportModal/selectors";

import {
  VARIANTS_GROUP_ID,
  WIZARD_PAGE,
  ZONES_GROUP_ID
} from "Orders/ImportModal/constants";

import { sectionsToShow } from "./selectors/customizeDownload";

const getParams = function*() {
  return {
    userId: yield select(userId),
    orgId: yield select(orgId),
    eventId: yield select(eventId)
  };
};

const importFile = function*(url) {
  const params = yield call(getParams);
  const credentials = yield select(getCredentials);
  const availableSections = yield select(sectionsToShow);

  yield put(actions.setLoadingStatus({ loading: true, ratioBar: 0.7 }));

  try {
    const [
      { payload },
      accountRecordTypePayload,
      contactRecordTypePayload
    ] = yield all([
      call(importModalApi.getMappedFile, credentials, {
        ...params,
        url
      }),
      call(recordTypeApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: { eventId: params.eventId }
      }),
      call(recordTypeApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: { eventId: params.eventId }
      })
    ]);

    yield put(
      actions.setDataAfterImport({
        ...payload,
        sectionsToShow: availableSections,
        accountRecordTypes: R.propOr(
          [],
          "record_types",
          accountRecordTypePayload
        ),
        contactRecordTypes: R.propOr(
          [],
          "record_types",
          contactRecordTypePayload
        ),
        url
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred importing the file"
        }
      ])
    );
  }
  yield put(actions.setLoadingStatus({ loading: false, ratioBar: 0 }));
};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "text/csv",
        "application/excel",
        "application/x-excel",
        "application/x-msexcel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ]
    };

    const path = { path: "orders-import/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const isValidationAction = [actions.saveCsvData.type];

const nextPage = {
  [actions.saveCsvData.type]: WIZARD_PAGE.REVIEW,
  [actions.importValid.type]: WIZARD_PAGE.CONFIRM
};

const saveCsvData = function*({ type }) {
  const credentials = yield select(getCredentials);
  const mappedFields = yield select(getMappedFields);
  const cleanedFieldsGroups = mappedFields.filter(group => group.fields.length);
  yield put(actions.setLoadingStatus({ loading: true, ratioBar: 0.7 }));
  try {
    const url = yield select(getters.url);
    const orderType = yield select(getters.orderType);
    const accountImportSettings = yield select(getters.accountImportSettings);
    const requireAssignment = yield select(getters.requireAssignment);
    const contactImportSettings = yield select(getters.contactImportSettings);
    const { eventId, userId } = yield getParams();
    const data = {
      url,
      require_assignment: requireAssignment,
      is_validation: R.contains(type, isValidationAction),
      fields_groups: cleanedFieldsGroups,
      event_id: eventId,
      user_id: userId,
      order_type: orderType,
      import_only_valid_rows: true,
      accountImportSettings,
      contactImportSettings
    };
    const {
      payload: { import_id: importId }
    } = yield call(importModalApi.postCsvData, credentials, {
      data,
      moduleId: STANDARD_MODULE_IDS.accounts.id
    });

    yield delay(500);

    let payload = yield call(importModalApi.getCsvUploadStatus, credentials, {
      moduleId: STANDARD_MODULE_IDS.accounts.id,
      importId
    });

    while (payload.finished === false) {
      yield delay(500);
      payload = yield call(importModalApi.getCsvUploadStatus, credentials, {
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        importId
      });
      const currentRecord = payload.current_record || 0;
      const totalRecords = payload.total_records || 1;
      yield put(
        actions.setLoadingStatus({
          loading: true,
          ratioBar: currentRecord / totalRecords,
          pendingSeconds: payload.pending_seconds
        })
      );
    }
    const { success } = payload;
    const { counters } = payload.detail;
    const hasError =
      R.has("errorsfile", payload.detail) ||
      R.has("summaryWarnings", payload.detail);

    if (success) {
      if (hasError === false || type === actions.importValid.type) {
        yield put(
          actions.setDataAfterUpload({
            counters,
            page: nextPage[type],
            errorsFileUrl: R.pathOr(
              "",
              ["detail", "errorsfile", "url"],
              payload
            ),
            skippedFileUrl: R.pathOr(
              "",
              ["detail", "invalidlinesfile", "url"],
              payload
            )
          })
        );
      } else {
        yield put(
          actions.setUploadCsvErrors({
            uploadCsvErrors: payload.detail
          })
        );
      }
    } else {
      const error = { message: R.propOr("", "error", payload.detail) };
      yield put(
        registerError([
          {
            system: error,
            user: error.message
          }
        ])
      );
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user:
            error.status === 400
              ? "Some data sent is malformed or incomplete. Please review them and try again."
              : error.message || "An error occurred importing the file"
        }
      ])
    );
  }
  yield put(actions.setLoadingStatus({ loading: false, ratioBar: 0 }));
};

const uploadFile = function*() {
  yield call(importFile, (yield call(getFilePicker))?.[0]?.url);
};

const watchUploadFile = function*() {
  yield takeEvery(actions.uploadFile.type, uploadFile);
};

const init = function*() {
  const params = yield call(getParams);
  const credentials = yield select(getCredentials);

  try {
    const { payload } = yield call(importModalApi.getMappedFile, credentials, {
      ...params
    });

    yield put(actions.setFieldsMetadata(R.values(payload)));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: error.message
        }
      ])
    );
  }
};

const downloadCustomized = function*() {
  const credentials = yield select(getCredentials);
  const params = yield call(getParams);
  const selectedFields = yield select(getSectionFieldsToDownload);
  const orderType = yield select(getCustomOrderType);

  /**
   * The following lines provide an "empty mocked" data,
   * necessary to not break the BE handling the passed fields.
   * @type {(string)[]} Array of GROUP ID
   */
  const mockedCustomModuleIds = [VARIANTS_GROUP_ID, ZONES_GROUP_ID];
  const mockedData = R.compose(
    R.map(moduleId => ({ module_id: moduleId, fields: [] })),
    R.reject(moduleId => {
      return R.find(field => field.module_id === moduleId, selectedFields);
    })
  )(mockedCustomModuleIds);
  const normalizedGroupFields = [...mockedData, ...selectedFields];

  const data = {
    ...params,
    orderType,
    fieldGroups: R.filter(
      section => section.module_id !== "ordersType",
      normalizedGroupFields
    )
  };
  try {
    const { url } = yield call(
      importModalApi.downloadCustomized,
      credentials,
      data
    );
    yield all([
      put(actions.downloadCustomizedResponse(url)),
      call(navigateTo, url),
      put(hideModal())
    ]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred downloading the custom template"
          }
        ])
      ),
      put(actions.downloadCustomizedResponse("", {}, true))
    ]);
  }
};

const watchDownloadCustomizedTemplate = function*() {
  yield takeEvery(actions.downloadCustomizedRequest.type, downloadCustomized);
};

const watchSaveCsvData = function*() {
  yield takeEvery(
    [actions.saveCsvData.type, actions.importValid.type],
    saveCsvData
  );
};

const watchUpload = function*() {
  for (;;) {
    yield take(actions.uploadResolved.type);
    yield put(actions.uploadFile());
  }
};

const watchDownloadSkipped = function*() {
  for (;;) {
    yield take(actions.downloadSkippedReport.type);
    const url = yield select(getters.skippedFileUrl);
    if (url) {
      yield call(navigateTo, url);
    }
  }
};

const watchDownloadErrors = function*() {
  for (;;) {
    yield take(actions.downloadErrorsReport.type);
    const url = yield select(getters.errorsFileUrl);
    if (url) {
      yield call(navigateTo, url);
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchUploadFile),
    fork(watchDownloadSkipped),
    fork(watchDownloadErrors),
    fork(init),
    fork(watchSaveCsvData),
    fork(watchDownloadCustomizedTemplate),
    fork(watchUpload)
  ]);
};

export default rootSaga;
