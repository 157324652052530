import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { get } from "lodash";
import EditorBase from "components/Global/Table3/Utilities/EditorBase";
import autobind from "autobind-decorator";

export default class TableConnector extends EditorBase {
  constructor(props) {
    super(props);
    // @NOTE: Incoming value must either be null, undefined, or of type object.
    // ProTip typeof null === 'object';
    this.state = ["object", "undefined"].includes(typeof props.value)
      ? props.value
      : undefined;
  }

  getInputNode() {
    // Use this from now on
    if (this.focusElement) {
      return this.focusElement;
    }

    // Fallback
    return get(
      this.refs,
      "editor.refs.inner.refs.focus",
      ReactDOM.findDOMNode(this)
    );
  }

  @autobind
  getValue() {
    return {
      [this.props.column.key]: this.state
    };
  }

  @autobind
  handleChange(state, callback) {
    if (state) {
      this.setState(state, callback);
    } else {
      callback();
    }
  }
}

TableConnector.propTypes = {
  value: PropTypes.object,
  rowIdx: PropTypes.number,
  column: PropTypes.shape({
    id: PropTypes.string,
    onCellChange: PropTypes.func
  }),
  dependentValues: PropTypes.object,
  rowMetaData: PropTypes.object
};
