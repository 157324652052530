import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/email/event/${data.eventId}`,
        data: {
          emails: data.emails
        },
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
