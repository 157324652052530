import { ImageIcon, ShortTextIcon, LineStyleIcon } from "components/Base";
export default [
  {
    id: "header",
    name: "Heading",
    Icon: ShortTextIcon
  },
  {
    id: "image",
    name: "Image",
    Icon: ImageIcon
  },
  {
    id: "separator",
    name: "Divider",
    Icon: LineStyleIcon
  }
];
