import React from "react";
import { Div } from "components/Base";
import { Page, Title } from "EventLight/Common/FieldLayout";

import IntegrationCard from "components/Event/Settings/Credentials/Cards/Integration";

const View = ({ ticketingProviders, ticketTypes }) => (
  <Div style={{ width: "100%" }}>
    {ticketingProviders.map(
      ({
        id,
        icon,
        color,
        name,
        activated,
        disabled,
        notices,
        Wrapper,
        provider,
        providerConfiguration,
        //
        enableEvent,
        enableMapping,
        enableSettings,
        //
        onEditEvent
      }) => (
        <Wrapper
          key={id}
          provider={provider}
          providerConfiguration={providerConfiguration}
          ticketTypes={ticketTypes}
        >
          {({
            showEditIntegrationModal,
            showEditIntegrationSettingsModal,
            disconnect,
            configureProviderForEvent,
            syncTicketTypes
          }) => (
            <IntegrationCard
              icon={
                <Div
                  size={20}
                  bg={color}
                  style={{
                    backgroundImage: `url(${icon})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover"
                  }}
                />
              }
              name={name}
              activated={activated}
              disabled={disabled}
              integrationItemName="orders"
              notices={notices}
              activateIntegration={configureProviderForEvent}
              syncTicketTypes={syncTicketTypes}
              goToIntegration={() => {}}
              onEdit={showEditIntegrationModal}
              onEditEvent={onEditEvent}
              onEditSettings={showEditIntegrationSettingsModal}
              onDelete={disconnect}
              enableEvent={enableEvent}
              enableMapping={enableMapping}
              enableSettings={enableSettings}
            />
          )}
        </Wrapper>
      )
    )}
  </Div>
);

const Layout = ({ ticketingProviders, ticketTypes }) => (
  <>
    <Page>
      <Title
        icon="assignment_turned_in"
        title="Integrations"
        description="Manage ticketing and RFID integrations"
      />
      <View {...{ ticketingProviders, ticketTypes }} />
    </Page>
  </>
);

export default Layout;
