import React from "react";
import PropTypes from "prop-types";
import {
  Div,
  SmallOutlineButton,
  SmallFilledButton,
  BigFilledButton,
  Text2,
  Text3,
  DownIcon,
  CloseIcon,
  AddIcon,
  BigOutlineButton
} from "components/Base";
import { lighten } from "components/Base/colorUtil";

const makeAddButton = Comp => ({ label, color, ...props }) => (
  <Comp {...props}>
    <Div display="row.center.center" py={1} pr={1}>
      <i className="material-icons" style={{ color }} mr={3}>
        add
      </i>
      <Text3 bold ml={1} color={color}>
        {label}
      </Text3>
    </Div>
  </Comp>
);

const makeDropdownButton = Comp => ({
  children,
  labelColor = "white",
  ...props
}) => (
  <Comp bg="blue6" {...props}>
    <Div flex={1} py={1} display="row.space-between.center">
      <Text2 bold color={labelColor} pr={2}>
        {children}
      </Text2>
      <DownIcon sizeWFS={2} color={lighten(1, labelColor)} />
    </Div>
  </Comp>
);
const makePrimaryButton = Comp => props =>
  makeAddButton(Comp)({
    ...props,
    color: "white",
    bg: "blue6",
    display: "inline-flex.center.center"
  });

const PrimaryButton = props =>
  makePrimaryButton(SmallFilledButton)({ label: "Add Person", ...props });

const SecondaryButton = ({ label, ...props }) => (
  <BigOutlineButton leftIconColor="gray6" LeftIcon={AddIcon} {...props}>
    <Div fw={3}>{label}</Div>
  </BigOutlineButton>
);

const FilledDropdownButton = makeDropdownButton(BigFilledButton);
const OutlineDropdownButton = makeDropdownButton(SmallOutlineButton);

const RemoveButton = ({ style, onClick, size = 13, ...props }) => (
  <Div
    bg={{ default: "gray2", hover: "gray3" }}
    display="row.center.center"
    onClick={onClick}
    size={size}
    transition="fast"
    style={{ borderRadius: 3, ...style }}
    {...props}
  >
    <CloseIcon size={size - 4} color="neutral8" />
  </Div>
);

export {
  FilledDropdownButton,
  OutlineDropdownButton,
  PrimaryButton,
  RemoveButton,
  SecondaryButton
};
