import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import ExpandButton from "./ExpandButton";
import { Div, AttachIcon, LeftArrowIcon } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { noop } from "utils/General";

import Preview from "./Preview";

const decorate = R.compose(
  CSSModules(css),
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.FILE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      showFilePicker: actions.showFilePicker
    })
  )
);

const FileEditor = ({
  value,
  iniValue,
  isReadOnly,
  setIniValue = noop,
  showFilePicker = noop,
  fieldType,
  fieldId,
  withoutPopover = false,
  instanceId,
  small,
  options
}) => {
  useEffect(() => {
    setIniValue(
      { only: iniValue },
      {
        meta: { fieldType, fieldId }
      }
    );
  }, []);

  if (withoutPopover) {
    return (
      <Preview
        isReadOnly={isReadOnly}
        fieldId={fieldId}
        noShadow
        instanceId={instanceId}
        options={options}
        small={small}
      />
    );
  }

  return (
    <div styleName={isReadOnly ? "containerReadOnly" : "container"}>
      {!isReadOnly ? (
        <Div
          display="row.flex-start.center"
          bg="white"
          bc="gray4"
          ba={1}
          ml={2}
          shadow={{
            default: 1,
            hover: 2
          }}
          bra={1}
          mr={3}
          style={{
            cursor:
              R.isEmpty(value) ||
              (!R.isEmpty(value) && R.propEq("multiple", true, options))
                ? "pointer"
                : "default",
            userSelect: "none",
            flexShrink: 0
          }}
          height={24}
          onClick={() => {
            if (
              R.isEmpty(value) ||
              (!R.isEmpty(value) && R.propEq("multiple", true, options))
            ) {
              showFilePicker(options, { meta: { fieldId, fieldType } });
            }
          }}
        >
          <AttachIcon
            color="gray5"
            style={{
              opacity:
                R.isEmpty(value) ||
                (!R.isEmpty(value) && R.propEq("multiple", true, options))
                  ? 1
                  : 0.3
            }}
          />

          {!R.isEmpty(value) ? (
            <>
              <Div ml={1} fs={0} fw={3} color="blue6">
                Upload a file
              </Div>
              <LeftArrowIcon
                ml={1}
                mr={1}
                size={13}
                color="blue6"
                style={{
                  transform: "rotate(135deg)"
                }}
              />
            </>
          ) : null}
        </Div>
      ) : null}

      <div styleName="formatter">Formatter</div>
      {!R.isEmpty(value) ? (
        <ExpandButton
          isReadOnly={isReadOnly}
          fieldId={fieldId}
          options={options}
          small={small}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export const File = decorate(FileEditor);
