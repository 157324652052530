import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import { actions } from "./model";
import { actions as UserInfoModalActions } from "EventLight/Team/UserInfoModal/model";
import { registerError } from "redux/modules/errors/actions";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const formatUsers = R.compose(
  R.sortBy(r => (r && r.user_lname ? r.user_lname.toLowerCase() : null)),
  R.filter(
    R.compose(
      R.equals(true),
      R.prop("is_event_user")
    )
  )
);

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventDetails } = yield call(getParams);

    const { payload } = yield call(Api.list, credentials, eventDetails.id);

    const users = formatUsers(payload);

    yield put(
      actions.setInitialData({
        users
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getUsers = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const { payload } = yield call(Api.list, credentials, eventDetails.id);

    const users = formatUsers(payload);

    yield put(actions.setUsers(users));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting users"
        }
      ])
    );
  }
};

// const openUserInfoModal = function*({ payload: userId }) {
//   yield put(UserInfoModalActions.setInitialData(userId));
// };

const updateUsersListAfterSave = function*() {
  yield all([put(actions.init()), put(actions.setShowUserInfoModal(false))]);
};

const watchGetUsers = function*() {
  yield takeEvery(actions.getUsers.type, getUsers);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUpdateUsersList = function*() {
  yield takeEvery(
    UserInfoModalActions.saveResponse.type,
    updateUsersListAfterSave
  );
};

// const watchOpenUserInfoModal = function*() {
//   yield takeEvery(actions.openUserInfoModal.type, openUserInfoModal);
// };

const rootSaga = function*() {
  yield all([
    fork(watchGetUsers),
    fork(watchInit),
    // fork(watchOpenUserInfoModal),
    fork(watchUpdateUsersList)
  ]);
};

export default rootSaga;
