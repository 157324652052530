import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import Signature from "./Signature";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const getValue = (formValues, fieldId) =>
  R.pathOr("", [fieldId, "value"], formValues);

@CSSModules(css)
class FormSignature extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: getValue(props.formValues, props.field.id),
      firstChange: false,
      isShown: false
    };
  }

  componentWillUpdate(nextProps) {
    const oldValue = getValue(this.props.formValues, this.props.field.id);
    const nextValue = getValue(nextProps.formValues, this.props.field.id);
    if (!this.props.isEditing && !R.equals(oldValue, nextValue)) {
      this.setState({
        value: nextValue,
        firstChange: true
      });
    }
  }

  @autobind
  handleChange(value) {
    this.setState({ value });
  }

  @autobind
  handleSave(value) {
    if (!this.props.isEditing) {
      if (R.isEmpty(value)) {
        this.setState({ firstChange: true });
      }
      this.save(this.props.field.id, {
        type: "signature",
        value
      });
    }
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  @autobind
  async isValid() {
    const required = this.props.field.is_required;
    const { value } = this.state;
    const errors =
      required && R.isEmpty(value) ? ["Signature is required"] : [];

    this.setState({
      errors
    });
    return !errors.length;
  }

  render() {
    const {
      disabled,
      isEditing,
      field: {
        name,
        settings: { description, isAdminField },
        is_required: required,
        is_readonly: readonly
      },
      connectDragSource,
      canShowSimpleSideBar
    } = this.props;
    const { firstChange, value } = this.state;

    const newValid =
      !firstChange || !required || (required && !R.isEmpty(value));

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={newValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <InputGroup style={this.props.groupStyles}>
          {name ? (
            !canShowSimpleSideBar ? (
              <Label
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
              >
                {name}
              </Label>
            ) : (
              <LabelSimpleForm
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
                instanceId={this.props.field.id}
                fieldData={this.props.field}
                isShown={this.state.isShown}
                setIsShown={() => this.handleToogleShowEditMode()}
              >
                {name}
              </LabelSimpleForm>
            )
          ) : null}
          <div styleName="picker">
            {!isEditing ? (
              <Signature
                onEnd={this.handleSave}
                base64Value={value}
                disabled={disabled}
              />
            ) : (
              <div styleName="signatureDisabled" />
            )}
          </div>
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormSignature.propTypes = {
  groupStyles: PropTypes.object,
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormSignature;
