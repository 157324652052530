import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions } from "Orders/List";

import {
  Div,
  PopoverMenu,
  MediumOutlineButton,
  DownFilledIcon
} from "components/Base";

import SearchBar from "Orders/List/View/SearchBar";
import PaginationBar from "Orders/List/View/PaginationBar";
import Table from "Orders/List/View/Table";
import Tabs from "ui-kit/Tabs/View";

import { Title } from "Passes/Common";

import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { CONTENT_TYPES } from "Items/Manage/constants";

import { getTabs, getViewTitle, getBreadcrumbs } from "Orders/List/selectors";

const decorate = R.compose(
  connect(
    state => ({
      tabs: getTabs(state),
      title: getViewTitle(state),
      breadcrumbs: getBreadcrumbs(state)
    }),
    {
      onExport: actions.exportData
    }
  )
);

const Page = ({ title = "Orders", handlers, tabs, onExport }) => (
  <Div
    display="column.flex-start.stretch"
    height={1}
    style={{ minHeight: "100%" }}
  >
    <Div
      display="row.flex-start.stretch"
      pt={6}
      px={4}
      bg="white"
      style={{ flexShrink: 0, position: "relative" }}
      bb={1}
      bc="neutral3"
    >
      <Div bg="white" display="column.space-between.stretch" flex={1}>
        <Title>{title}</Title>
        <Tabs tabs={tabs} />
      </Div>
      <Div display="column.flex-end" pb={2}>
        <CanUserDo any={[`${STANDARD_MODULE_IDS.orders.id}_export`]}>
          <PopoverMenu
            Label={({ onClick }) => (
              <MediumOutlineButton RightIcon={DownFilledIcon} onClick={onClick}>
                Export
              </MediumOutlineButton>
            )}
            menuItems={[
              ["XLSX", () => onExport(CONTENT_TYPES.XLSX)],
              ["CSV", () => onExport(CONTENT_TYPES.CSV)]
            ]}
          />
        </CanUserDo>
      </Div>
    </Div>
    <Div
      bg="neutral0"
      flex={1}
      display="column.flex-start.stretch"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <SearchBar m={3} handlers={handlers} />
      <Div flex={1} style={{ overflow: "auto" }}>
        <Table handlers={handlers} />
      </Div>
      <PaginationBar />
    </Div>
  </Div>
);

export default decorate(Page);
