import {
  REQUEST_SCAN,
  RECEIVE_SCAN,
  REQUEST,
  RECEIVE,
  ERROR
} from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getMeal({ mealId, limit }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.get(getState().user.user.credentials, {
        mealId,
        limit
      });
      dispatch({
        type: RECEIVE,
        payload: { id: mealId, scans: result.payload }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching meal"
          }
        ])
      );
    }
  };
}
export function getMealStats({ mealId }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getStats(getState().user.user.credentials, {
        mealId
      });
      const {
        latest_checkin,
        count_of_overrides,
        count_of_checkins
      } = result.payload;
      dispatch({
        type: RECEIVE,
        payload: {
          id: mealId,
          latestScan: latest_checkin,
          overrides: count_of_overrides,
          checkins: count_of_checkins
        }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching most recent scan"
          }
        ])
      );
    }
  };
}
export function scanMeal({
  mealId,
  type,
  query,
  parentCheckinId,
  accountId,
  contactId,
  message
}) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post({
        credentials: getState().user.user.credentials,
        mealId,
        type,
        query,
        parentCheckinId,
        contactId,
        accountId,
        message
      });
      return result;
    } catch (error) {
      dispatch({ type: ERROR });
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred scanning meal"
          }
        ])
      );
    }
  };
}
