import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Event/ItemCatalog";
import { Div, MediumFilledButton } from "components/Base";

const decorate = connect(
  state => ({
    refreshingZoneAssignments: getters.refreshingZoneAssignments(state)
  }),
  {
    refreshZoneAssignments: actions.refreshZoneAssignments
  }
);

const Layout = ({ refreshZoneAssignments, refreshingZoneAssignments }) => {
  return (
    <Div
      py={3}
      pl={7}
      display="column"
      style={{
        maxWidth: "500px"
      }}
    >
      <Div fw={3} color="gray7">
        Refresh Line Item Zones
      </Div>
      <Div fs={1} color="gray5" mb={4}>
        This will refresh the assignment of zones to line items based on the
        current zone assignment settings.
      </Div>
      <MediumFilledButton
        width={170}
        bg="altB5"
        disabled={refreshingZoneAssignments}
        onClick={refreshZoneAssignments}
      >
        {refreshingZoneAssignments ? "Refreshing..." : "Refresh Data"}
      </MediumFilledButton>
    </Div>
  );
};

export default decorate(Layout);
