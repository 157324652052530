import React from "react";
import * as R from "ramda";
import { FontIcon, Div } from "components/Base";
import Row from "./Row/Row";
import Icon from "./Row/Icon";
import Content from "./Row/Content";
import Message from "./Row/Message";
import Timestamp from "./Row/Timestamp";
import Actions from "./Row/Actions";
import { connect } from "react-redux";
import { actions } from "../model";
import { bindInstance } from "redux-mvc";

const decorator = connect(
  null,
  bindInstance({
    executeAction: actions.executeAction
  })
);

export const NotificationItem = ({
  showEventName,
  notification,
  last,
  instanceId,
  sectionId,
  executeAction
}) => (
  <Row
    isRead={notification.is_read}
    last={last}
    onClick={event => {
      if (!event.target.className.includes("invisible")) {
        executeAction(notification);
      }
    }}
  >
    <Icon>
      <FontIcon
        style={{
          color: notification.color
        }}
        fs={4}
      >
        {notification.icon}
      </FontIcon>
    </Icon>
    <Content>
      <Message>{notification.label}</Message>
      <Timestamp
        stamp={notification.created_at}
        eventName={showEventName ? R.path(["event", "name"])(notification) : ""}
      />
    </Content>
    <Div
      display="row.center.center"
      style={{ marginLeft: "auto" }}
      ml={3}
      width={2}
    >
      <Div display="row.center.center" className="showOnHover">
        <Actions
          instanceId={instanceId}
          id={notification.notification_ids[0]}
          sectionId={sectionId}
        />
      </Div>
    </Div>
  </Row>
);

export default decorator(NotificationItem);
