import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import * as R from "ramda";

import Helpers from "utils/Global/Helpers";

import tagsVenuesApi from "Organizations/TagsVenues/api";

import editEventModalApi from "Organizations/EditEventModal/api";

import { orgId } from "redux/modules/organization/selectors";
import { userId, getCredentials } from "redux/modules/user/selectors";

import { registerError } from "redux/modules/errors/actions";

import { STATUS } from "Organizations/EventDetails/constants";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import { actions, getters } from "Organizations/EditEventModal";

import { PAGES } from "Organizations/EditEventModal/constants";

import {
  actions as TagsVenuesActions,
  getters as TagsVenuesGetters
} from "Organizations/TagsVenues";

import { getSelectedTagsIds } from "Organizations/TagsVenues/selectors";

import {
  actions as EventDetailsActions,
  getters as EventDetailsGetters
} from "Organizations/EventDetails";

import {
  actions as TicketsPassesActions,
  getters as TicketsPassesGetters
} from "Orders/TicketsPasses";

import {
  isCredentialsModalEnabled,
  getVariantsPrices,
  getVariantsData
} from "Organizations/EditEventModal/selectors";

const getParams = function*() {
  const org = yield select(orgId);
  const id = yield select(userId);
  const credentials = yield select(getCredentials);

  return { orgId: org, userId: id, credentials };
};

const getInitialData = function*({
  payload: { id, initialScene = PAGES.EVENT_DETAILS, org }
}) {
  const { orgId, credentials } = yield call(getParams);

  yield put(actions.setLoading(true));
  yield put(actions.setSelectedPage(initialScene));
  yield put(actions.setOrgId(org));

  try {
    const [
      {
        payload: {
          is_draft: isDraft,
          has_access: hasAccess,
          name: eventName,
          logo_image_url: logoImageUrl,
          background_image_url: backgroundImageUrl,
          show_in_ticket_portal,
          date_from: startDate,
          date_to: endDate
        }
      },
      { payload: categories }
    ] = yield all([
      call(editEventModalApi.getEvent, { credentials, id, orgId }),
      call(editEventModalApi.getCategories, { credentials, id })
    ]);

    const ticketsPasses = categories.reduce((allItems, category) => {
      const items = R.map(
        item => ({
          sort_order: item.id,
          category: {
            name: category.name,
            tempId: category.id,
            id: category.id
          },
          id: item.id,
          name: item.name,
          variantId: item.variants[0].id,
          prices: R.map(
            ({ id, name, price }) => ({
              id,
              name,
              price,
              amount: price,
              tempId: id
            }),
            item.variants[0].prices
          ),
          limit: 0,
          who_has_access: []
        }),
        category.items
      );
      return [...allItems, ...items];
    }, []);

    const categoriesList = R.map(
      category => ({
        tempId: category.id,
        id: category.id,
        name: category.name
      }),
      categories
    );

    yield put(
      TagsVenuesActions.setInitialData({
        venues: [],
        venueId: null,
        tags: [],
        selectedTags: []
      })
    );
    yield put(
      EventDetailsActions.setInitialData({
        eventName,
        logoImageUrl,
        backgroundImageUrl,
        status: show_in_ticket_portal === true ? "open" : "close",
        startDate,
        endDate,
        onBlurEnabled: true
      })
    );
    yield put(
      TicketsPassesActions.init({
        data: ticketsPasses,
        categoriesList,
        onBlurEnabled: true
      })
    );

    yield put(actions.setEventId(id));
    yield put(actions.setIsDraft(isDraft));
    yield put(actions.setHasAccess(hasAccess));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading item types"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/png"
      ]
    };

    const path = { path: "event-background-image/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const addVenue = function*() {
  yield put(actions.setLoading(true));

  const { orgId, userId, credentials } = yield call(getParams);
  const name = yield select(TagsVenuesGetters.venueName);
  const data = { name, created_by_user_id: userId };

  try {
    yield call(tagsVenuesApi.postVenue, {
      credentials,
      orgId,
      data
    });

    const {
      payload: { venues }
    } = yield call(tagsVenuesApi.getVenues, {
      credentials,
      orgId
    });

    yield put(TagsVenuesActions.updateVenues({ venues }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading item types"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const updateVenue = function*({ payload: { name, venueId } }) {
  yield put(actions.setLoading(true));

  const { orgId, credentials } = yield call(getParams);
  const data = { name };

  try {
    yield call(tagsVenuesApi.putVenue, {
      credentials,
      orgId,
      data,
      venueId
    });

    const {
      payload: { venues }
    } = yield call(tagsVenuesApi.getVenues, {
      credentials,
      orgId
    });

    yield put(TagsVenuesActions.updateVenues({ venues }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading item types"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const removeVenue = function*({ payload: { venueId } }) {
  yield put(actions.setLoading(true));

  const { orgId, credentials } = yield call(getParams);

  try {
    yield call(tagsVenuesApi.deleteVenue, {
      credentials,
      orgId,
      venueId
    });

    const {
      payload: { venues }
    } = yield call(tagsVenuesApi.getVenues, {
      credentials,
      orgId
    });

    yield put(TagsVenuesActions.updateVenues({ venues }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading item types"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const updateEventDetails = function*({ payload: { data } }) {
  yield put(actions.setSavingLoading(true));

  const id = yield select(getters.eventId);
  const credentials = yield select(getCredentials);

  try {
    yield call(editEventModalApi.updateEvent, {
      credentials,
      id,
      data
    });

    yield put(actions.setSavingLoading(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );

    yield put(actions.setSavingLoading(false));
  }
};

const updateSelectedVenue = function*({ payload: { venueId } }) {
  yield put(TagsVenuesActions.selectVenue({ id: venueId }));

  yield call(updateEventDetails, {
    payload: { data: { venueId } }
  });
};

const updateSelectedTags = function*({ payload: { tagId } }) {
  yield put(TagsVenuesActions.selectTag({ id: tagId }));

  const tagIds = yield select(getSelectedTagsIds);

  yield call(updateEventDetails, {
    payload: { data: { tagIds } }
  });
};

const updateEventName = function*({ payload: { name } }) {
  yield put(EventDetailsActions.setEventName(name));

  yield call(updateEventDetails, {
    payload: { data: { name } }
  });
};

const updateEventStatus = function*({ payload: { status } }) {
  const showInTicketPortal =
    status === STATUS.OPEN ? STATUS.CLOSED : STATUS.OPEN;

  yield put(EventDetailsActions.setStatus(showInTicketPortal));

  yield call(updateEventDetails, {
    payload: {
      data: { showInTicketPortal: showInTicketPortal === STATUS.OPEN }
    }
  });
};

const updateEventStartDate = function*({ payload: { startDate } }) {
  yield put(EventDetailsActions.setStartDate(startDate));

  yield call(updateEventDetails, {
    payload: { data: { dateFrom: startDate } }
  });
};

const updateEventEndDate = function*({ payload: { endDate } }) {
  yield put(EventDetailsActions.setEndDate(endDate));

  yield call(updateEventDetails, {
    payload: { data: { dateTo: endDate } }
  });
};

const uploadLogoImg = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield put(EventDetailsActions.setLogoImageUrl(url));

  const onBlurEnabled = yield select(EventDetailsGetters.onBlurEnabled);

  if (onBlurEnabled) {
    yield call(updateEventDetails, {
      payload: { data: { logoImageUrl: url } }
    });
  }
};

const uploadHeaderImg = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield put(EventDetailsActions.setBackgroundImageUrl(url));

  const onBlurEnabled = yield select(EventDetailsGetters.onBlurEnabled);

  if (onBlurEnabled) {
    yield call(updateEventDetails, {
      payload: { data: { backgroundImageUrl: url } }
    });
  }
};

const updateRemovePass = function*({ payload: { id } }) {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.removePass({ id }));

  const data = {
    bulk: true,
    itemIds: [id]
  };

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.deleteItemRow, {
      credentials,
      data
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateAddPass = function*() {
  const { credentials } = yield call(getParams);
  const categoriesList = yield select(TicketsPassesGetters.categoriesList);
  const category = categoriesList[0];
  yield put(TicketsPassesActions.addPass());

  const data = {
    bulk: true,
    items: [
      {
        typeId: CREDENTIAL_TYPE_ID,
        groupId: R.propOr("", "id", category),
        name: "New Pass",
        description: null,
        backgroundColor: null
      }
    ]
  };

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.addItemRow, {
      credentials,
      data
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateItemName = function*({ payload: { id, name } }) {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.setPassName({ id, name }));

  const data = {
    bulk: true,
    items: [
      {
        id,
        name
      }
    ]
  };

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateItemRow, {
      credentials,
      data
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateItemCategory = function*({ payload: { passId, categoryId } }) {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.setPassCategory({ passId, categoryId }));

  const data = {
    bulk: true,
    items: [
      {
        id: passId,
        groupId: categoryId
      }
    ]
  };

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateItemRow, {
      credentials,
      data
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateNextPriceAction = function*() {
  const { credentials } = yield call(getParams);

  const isCredentialsModal = yield select(isCredentialsModalEnabled);

  const oldTicketsPasses = yield select(TicketsPassesGetters.data);

  yield put(TicketsPassesActions.quickAssignNextAction());

  const ticketsPasses = yield select(TicketsPassesGetters.data);

  if (isCredentialsModal && !R.equals(oldTicketsPasses, ticketsPasses)) {
    const variants = yield select(getVariantsPrices);
    const data = {
      bulk: true,
      variants
    };
    yield put(actions.setSavingLoading(true));

    try {
      yield call(editEventModalApi.updateVariantPrices, {
        credentials,
        data
      });
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred updating the event"
          }
        ])
      );
    } finally {
      yield put(actions.setSavingLoading(false));
    }
  }
};

const updateItemPricesList = function*({ payload: { id } }) {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.updatePricesList({ id }));

  const ticketsPasses = yield select(TicketsPassesGetters.data);

  const variants = R.compose(
    R.map(item => ({
      id: R.propOr("", "variantId", item),
      prices: R.map(
        price => ({
          id: R.propOr(null, "id", price),
          name: price.name,
          price: price.amount
        }),
        item.prices
      )
    })),
    R.filter(R.propEq("sort_order", id))
  )(ticketsPasses);

  const data = {
    bulk: true,
    variants
  };
  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateVariantPrices, {
      credentials,
      data
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateItemProfileLimit = function*() {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.updateProfileLimit());

  const profileId = yield select(TicketsPassesGetters.selectedProfileId);

  const data = yield select(getVariantsData, { profileId });

  const orgId = yield select(getters.orgId);

  try {
    yield call(editEventModalApi.updateUserProfileVariantPrices, {
      credentials,
      data,
      orgId
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateClearProfileAllAssignedItems = function*({
  payload: { profileId }
}) {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.clearProfileAllAssignedItems({ profileId }));

  const data = yield select(getVariantsData, { profileId });

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateUserProfileVariantPrices, {
      credentials,
      data,
      orgId
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const copyItemProfileSettings = function*() {
  const { credentials } = yield call(getParams);

  yield put(TicketsPassesActions.copyProfileSettings());

  const profileId = yield select(TicketsPassesGetters.copyToProfileId);

  const data = yield select(getVariantsData, { profileId });

  const orgId = yield select(getters.orgId);

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateUserProfileVariantPrices, {
      credentials,
      data,
      orgId
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const updateItemLimitPricesProfiles = function*({
  payload: { profileId, assignItemsCredentials }
}) {
  const { credentials } = yield call(getParams);

  yield put(
    TicketsPassesActions.updateLimitPricesProfiles({
      profileId,
      assignItemsCredentials
    })
  );

  const orgId = yield select(getters.orgId);

  const data = yield select(getVariantsData, { profileId });

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateUserProfileVariantPrices, {
      credentials,
      data,
      orgId
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const reorderItems = function*({ payload: { orderedItems } }) {
  const { credentials } = yield call(getParams);

  const data = {
    bulk: true,
    items: orderedItems.map(({ id }, i) => ({ id, order: i }))
  };

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.updateItemRow, {
      credentials,
      data
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
  }
};

const addCategory = function*() {
  const categoryName = yield select(TicketsPassesGetters.categoryName);
  const categoriesList = yield select(TicketsPassesGetters.categoriesList);
  const categoriesLength = R.length(categoriesList);
  const credentials = yield select(getCredentials);
  const eventId = yield select(getters.eventId);

  yield put(actions.setSavingLoading(true));

  try {
    yield call(editEventModalApi.addItemGroup, {
      data: {
        eventId,
        typeId: CREDENTIAL_TYPE_ID,
        order: categoriesLength,
        name: categoryName
      },
      credentials
    });

    const { payload: categories } = yield call(
      editEventModalApi.getCategories,
      { credentials, id: eventId }
    );

    const categoriesList = R.map(
      category => ({
        tempId: category.id,
        id: category.id,
        name: category.name
      }),
      categories
    );

    yield put(TicketsPassesActions.setCategoriesList(categoriesList));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setSavingLoading(false));
    yield put(TicketsPassesActions.hideCategoryModal());
  }
};

const watchUpdateItemPricesList = function*() {
  yield takeEvery(
    TicketsPassesActions.updateItemPricesList.type,
    updateItemPricesList
  );
};

const watchAddCategory = function*() {
  yield takeEvery(TicketsPassesActions.addCategory.type, addCategory);
};

const watchReorderItems = function*() {
  yield takeEvery(TicketsPassesActions.reorderItems.type, reorderItems);
};

const watchUpdateItemLimitPricesProfiles = function*() {
  yield takeEvery(
    TicketsPassesActions.updateItemLimitPricesProfiles.type,
    updateItemLimitPricesProfiles
  );
};

const watchCopyItemProfileSettings = function*() {
  yield takeEvery(
    TicketsPassesActions.copyItemProfileSettings.type,
    copyItemProfileSettings
  );
};

const watchUpdateClearProfileAllAssignedItems = function*() {
  yield takeEvery(
    TicketsPassesActions.updateClearProfileAllAssignedItems.type,
    updateClearProfileAllAssignedItems
  );
};

const watchUpdateItemProfileLimit = function*() {
  yield takeEvery(
    TicketsPassesActions.updateItemProfileLimit.type,
    updateItemProfileLimit
  );
};

const watchUpdateRemovePass = function*() {
  yield takeEvery(TicketsPassesActions.updateRemovePass.type, updateRemovePass);
};

const watchUpdateAddPass = function*() {
  yield takeEvery(TicketsPassesActions.updateAddPass.type, updateAddPass);
};

const watchUpdateItemName = function*() {
  yield takeEvery(TicketsPassesActions.updateItemName.type, updateItemName);
};

const watchUpdateItemCategory = function*() {
  yield takeEvery(
    TicketsPassesActions.updateItemCategory.type,
    updateItemCategory
  );
};

const watchUpdateNextPriceAction = function*() {
  yield takeEvery(
    TicketsPassesActions.updateNextPriceAction.type,
    updateNextPriceAction
  );
};

const watchUpdateSelectedVenue = function*() {
  yield takeEvery(
    TagsVenuesActions.updateSelectedVenue.type,
    updateSelectedVenue
  );
};

const watchUpdateEventName = function*() {
  yield takeEvery(EventDetailsActions.updateEventName.type, updateEventName);
};

const watchUpdateEventStatus = function*() {
  yield takeEvery(
    EventDetailsActions.updateEventStatus.type,
    updateEventStatus
  );
};

const watchUpdateStartDate = function*() {
  yield takeEvery(
    EventDetailsActions.updateEventStartDate.type,
    updateEventStartDate
  );
};

const watchUpdateEndDate = function*() {
  yield takeEvery(
    EventDetailsActions.updateEventEndDate.type,
    updateEventEndDate
  );
};

const watchUpdateSelectedTags = function*() {
  yield takeEvery(
    TagsVenuesActions.updateSelectedTags.type,
    updateSelectedTags
  );
};

const watchAddAvenue = function*() {
  yield takeEvery(TagsVenuesActions.addVenue.type, addVenue);
};

const watchUpdateAvenue = function*() {
  yield takeEvery(TagsVenuesActions.updateVenue.type, updateVenue);
};

const watcRemoveAvenue = function*() {
  yield takeEvery(TagsVenuesActions.removeVenue.type, removeVenue);
};

const watchUploadLogoImg = function*() {
  yield takeEvery(actions.uploadLogoImg.type, uploadLogoImg);
};

const watchUploadHeaderImg = function*() {
  yield takeEvery(actions.uploadHeaderImg.type, uploadHeaderImg);
};

const watchVenueAndTagItems = function*() {
  yield takeEvery(actions.getInitialData.type, getInitialData);
};

const rootSaga = function*() {
  yield all([
    fork(watchUploadLogoImg),
    fork(watchUploadHeaderImg),
    fork(watchVenueAndTagItems),
    fork(watchAddAvenue),
    fork(watchUpdateAvenue),
    fork(watcRemoveAvenue),
    fork(watchUpdateEventName),
    fork(watchUpdateEventStatus),
    fork(watchUpdateStartDate),
    fork(watchUpdateEndDate),
    fork(watchUpdateSelectedVenue),
    fork(watchUpdateSelectedTags),
    fork(watchUpdateRemovePass),
    fork(watchUpdateAddPass),
    fork(watchUpdateItemName),
    fork(watchUpdateItemCategory),
    fork(watchUpdateNextPriceAction),
    fork(watchUpdateItemProfileLimit),
    fork(watchUpdateClearProfileAllAssignedItems),
    fork(watchCopyItemProfileSettings),
    fork(watchUpdateItemLimitPricesProfiles),
    fork(watchReorderItems),
    fork(watchAddCategory),
    fork(watchUpdateItemPricesList)
  ]);
};

export default rootSaga;
