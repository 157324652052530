import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";

import SearchBarModule from "ui-kit/SearchBar";
import TableModule from "ui-kit/Table";
import PaginationModule from "ui-kit/PaginationBar";
import AddPersonModal from "PortalHub/AddPersonModal";
import ActionComps from "PortalHub/ActionComponents";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "portal", "modal"]
});

module.plugModule(TableModule);
module.plugModule(SearchBarModule);
module.plugModule(PaginationModule);
module.plugModule(AddPersonModal);
module.plugModule(ActionComps);
module.setRootSaga(sagasModule);

export default module;
