import React from "react";
import * as R from "ramda";
import { Link } from "react-router";

import { MODES } from "EventLight/Common/Manage/constants";

import {
  Div,
  Text0,
  Text4,
  MediumOutlineButton,
  DownFilledIcon
} from "components/Base";
import { withProps } from "utils/General";

const ArrowIcon = withProps({
  mt: 3
})(DownFilledIcon);

import { PassesTable, COLUMNS } from "./PassesTable";
import BulkActionsButton from "./BulkActionsButton";

import ArrayLayout from "ui-kit/ArrayLayout";
import ProgressBar from "ui-kit/ProgressBar";

import CollapsablePanel from "ui-kit/CollapsiblePanel";

export const ACTION_CELLS = {
  [MODES.REVIEW]: "status",
  [MODES.ISSUANCE]: "issueActions"
};

const Header = ({ title, stats }) => (
  <Div display="column.flex-start.flex-start" ml={4} my={4}>
    <Text4 bold mb={4}>
      {title}
    </Text4>
    <Div display="row.flex-start.center">
      {R.map(
        stat => (
          <Div display="column.flex-start.flex-start" key={stat.name} mr={7}>
            <Text0 uppercase bold mb={1}>
              {stat.name}
            </Text0>
            {stat.name === "progress" ? (
              <Div display="row.flex-start.center" width={130}>
                <ProgressBar progress={stat.value / stat.total} />
                <Text0 bold ml={2}>
                  {stat.value} / {stat.total}
                </Text0>
              </Div>
            ) : (
              <Text0 bold>{stat.value}</Text0>
            )}
          </Div>
        ),
        stats
      )}
    </Div>
  </Div>
);

const ByCategory = ({
  category = {},
  items = [],
  mode = MODES.ISSUANCE,
  expandUrl = "",
  handlers = {},
  allLineItemIds,
  allCredentialLineItemIds,
  allMealLineItemIds,
  instanceId = ""
}) => {
  const buttons = [
    [
      <MediumOutlineButton>
        <Link to={expandUrl}>Expand View</Link>
      </MediumOutlineButton>,
      <BulkActionsButton
        key="bulk"
        cardType="byCategory"
        mode={mode}
        handlers={handlers}
        meta={{
          allLineItemIds,
          allCredentialLineItemIds,
          allMealLineItemIds
        }}
      />
    ]
  ];

  return (
    <CollapsablePanel
      heading={<Header title={category.title} stats={category.stats} />}
      right={false}
      shadow={2}
      mb={2}
      width={1}
      display="column.flex-start.stretch"
      bg="white"
      arrowAlignment="top"
      arrowIcon={ArrowIcon}
      arrowColor="black"
      bra={2}
      collapsedDefault
    >
      <PassesTable
        instanceId={instanceId}
        columns={R.props(
          [
            ACTION_CELLS[mode],
            "quantity",
            "item",
            "attendee",
            // "location",
            // "daysNeeded",
            "order"
          ],
          COLUMNS
        )}
        rows={items}
      />
      <ArrayLayout components={buttons} m={2} />
    </CollapsablePanel>
  );
};

export default ByCategory;
