import PropTypes from "prop-types";
import React, { Component } from "react";
import { Dropdown, LoadingIcon, Div } from "components/Base";

class ReferenceSettings extends Component {
  state = { loading: false };

  componentDidMount() {
    if (this.props.value.lookupFieldId) {
      const lookupField = this.props.lookupFields.find(
        f => f.id === this.props.value.lookupFieldId
      );
      this.fetchFields(lookupField.settings.moduleId);
    }
  }

  onLookupFieldChange = value => {
    this.props.onChange({
      ...this.props.value,
      lookupFieldId: value.value,
      referenceFieldId: null
    });

    const lookupField = this.props.lookupFields.find(f => f.id === value.value);

    this.fetchFields(lookupField.settings.moduleId);
  };

  onModuleFieldChange = value => {
    this.props.onChange({
      ...this.props.value,
      referenceFieldId: value.value
    });
  };

  fetchFields = async moduleId => {
    this.setState({ loading: true });

    await this.props.getFields({
      moduleId,
      options: {
        eventId: this.props.eventId
      }
    });

    this.setState({ loading: false });
  };

  render() {
    const { value, editing, lookupFields, referencedModuleFields } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <div className="input-wrapper">
          <Div fw={3} fs={2} mb={2} color="gray9">
            Field on this module that links to the records you want to reference
          </Div>
          <Dropdown
            disabled={editing}
            options={lookupFields.map(({ id, name }) => ({
              label: name,
              value: id
            }))}
            selected={value.lookupFieldId}
            onChange={this.onLookupFieldChange}
          />
        </div>
        {loading ? (
          <Div pt={5} display="row.center.center">
            <LoadingIcon fs={5} />
          </Div>
        ) : value.lookupFieldId ? (
          <div className="input-wrapper">
            <Div fw={3} fs={2} mb={2} color="gray9">
              Module field that you'd like to reference{" "}
            </Div>
            <Dropdown
              options={referencedModuleFields.map(({ id, name }) => ({
                label: name,
                value: id
              }))}
              selected={value.referenceFieldId}
              onChange={this.onModuleFieldChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ReferenceSettings;
