import { combineReducers } from "redux";
import {
  BULK_SET_SELECTED_ROWS,
  CLEAR_SEARCH,
  DESELECT_ALL_ROWS,
  SEARCH,
  TABLE_LAYOUT_OPTIONS,
  TABLE_LAYOUT_STYLE,
  VIEW
} from "./constants";

const search = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_SEARCH:
      return { ...state, [action.moduleId]: "" };
    case SEARCH:
      return { ...state, [action.moduleId]: action.searchTerm };
    default:
      return state;
  }
};

const view = (state = {}, action) => {
  switch (action.type) {
    case VIEW:
      return { ...state, [action.moduleId]: action.viewId };
    default:
      return state;
  }
};

// TODO Immutable
const tableLayout = (state = {}, action) => {
  switch (action.type) {
    case TABLE_LAYOUT_OPTIONS:
      const oldModuleState = state[action.moduleId] || {};
      return {
        ...state,
        [action.moduleId]: {
          ...oldModuleState,
          options: { ...oldModuleState.options, ...action.options }
        }
      };
    case TABLE_LAYOUT_STYLE:
      return {
        ...state,
        [action.moduleId]: {
          ...state[action.moduleId],
          activeStyle: action.activeStyle
        }
      };
    default:
      return state;
  }
};

const selectedRows = (state = {}, action) => {
  switch (action.type) {
    case BULK_SET_SELECTED_ROWS:
      return {
        ...state,
        [action.moduleId]: action.selectedRows
      };
    case DESELECT_ALL_ROWS:
      return {
        ...state,
        [action.moduleId]: []
      };
    default:
      return state;
  }
};

export default combineReducers({
  search,
  selectedRows,
  tableLayout,
  view
});
