import React, { Component } from "react";
import { Link } from "react-router";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import HeaderAvatar from "../HeaderAvatar";
import HeaderTitle from "../HeaderTitle";
import HeaderHelp from "../HeaderHelp";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import CanUserDo from "components/Global/Security/CanUserDo";
import Notifications from "Notifications/View";

@CSSModules(css)
class OrganizationHeader extends Component {
  render() {
    const { organization, routes, params } = this.props;
    const isLinkActive = (names, moduleId = "") =>
      routes.some(({ name }) => names.includes(name)) ||
      params.moduleId === moduleId;
    const orgId = organization.id;

    return (
      <div styleName="container">
        <div styleName="flex">
          <Link
            styleName="logo"
            to={{ pathname: `/org-light/${orgId}/dashboard` }}
            title="Organization"
          >
            <i className="material-icons" styleName="logoIcon">
              &#xE5C3;
            </i>
          </Link>
          <HeaderTitle
            isEvent
            user={this.props.user}
            location={this.props.location}
            myEvents={this.props.myEvents}
            name={organization.name}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
          />
          <div styleName="links">
            <Link
              key="events"
              to={`/organization/${orgId}/events`}
              className={ClassNames({
                "nav-link": true,
                "nav-link-wrapper": true,
                active: isLinkActive(["Events"])
              })}
              activeClassName="active"
            >
              <span className="nav-link-label">Events</span>
            </Link>

            <CanUserDo action={`${STANDARD_MODULE_IDS.accounts.id}_read`}>
              <Link
                key="accounts"
                to={`/organization/${orgId}/module/${STANDARD_MODULE_IDS.accounts.id}`}
                className={ClassNames({
                  "nav-link": true,
                  "nav-link-wrapper": true,
                  active: isLinkActive(
                    ["orgAccountProfileOverview"],
                    STANDARD_MODULE_IDS.accounts.id
                  )
                })}
                activeClassName="active"
              >
                <span className="nav-link-label">Groups</span>
              </Link>
            </CanUserDo>

            <CanUserDo action={`${STANDARD_MODULE_IDS.contacts.id}_read`}>
              <Link
                key="contacts"
                to={`/organization/${orgId}/module/${STANDARD_MODULE_IDS.contacts.id}`}
                className={ClassNames({
                  "nav-link": true,
                  "nav-link-wrapper": true,
                  active: isLinkActive(
                    ["OrgContactProfile"],
                    STANDARD_MODULE_IDS.contacts.id
                  )
                })}
                activeClassName="active"
              >
                <span className="nav-link-label">People</span>
              </Link>
            </CanUserDo>

            {/*
            <Link
              key="tasks"
              to={`/organization/${orgId}/tasks`}
              className={ClassNames({
                "nav-link": true,
                "nav-link-wrapper": true,
                active: isLinkActive(
                  ["orgAccountProfileOverview"],
                  STANDARD_MODULE_IDS.accounts.id
                )
              })}
              activeClassName="active"
            >
              <span className="nav-link-label">Tasks</span>
            </Link>
            */}

            {/*
            <CanUserDo action={`${STANDARD_MODULE_IDS.files.id}_read`}>
              <Link
                key="file"
                to={`/organization/${orgId}/files`}
                className={ClassNames({
                  "nav-link": true,
                  "nav-link-wrapper": true,
                  active: isLinkActive(["Files"])
                })}
                activeClassName="active"
              >
                <span className="nav-link-label">Files</span>
              </Link>
            </CanUserDo>
            */}

            <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_read`}>
              <Link
                key="settings"
                to={`/organization/${orgId}/settings`}
                className={ClassNames({
                  "nav-link": true,
                  "nav-link-wrapper": true,
                  active: isLinkActive(["organizationSettings"])
                })}
                activeClassName="active"
              >
                <span className="nav-link-label">Settings</span>
              </Link>
            </CanUserDo>
          </div>
        </div>
        <div styleName="flex">
          <Notifications />

          <HeaderHelp
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
          />

          <div styleName="avatar">
            <HeaderAvatar />
          </div>
        </div>
      </div>
    );
  }
}

OrganizationHeader.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default OrganizationHeader;
