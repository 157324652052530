import { makeReducer } from "utils/General";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";

const iniState = {
  account: null,
  error: null,
  fetching: false
};

const reducers = {
  [RECEIVE]: (state, action) => ({
    account: action.payload.account.account,
    fetching: false
  }),
  [INVALIDATE]: () => ({
    account: {}
  }),
  [REQUEST]: () => ({
    fetching: true
  }),
  [ERROR]: (_, action) => ({
    error: action.payload,
    fetching: false
  }),
  [CLEAR_ERROR]: () => ({
    error: false,
    fetching: false
  })
};

export default makeReducer(reducers, iniState);
