import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Portal/PortalPage/model";

import * as R from "ramda";
import Page from "Portal/Page";
import Body from "./Body";

const decorate = connect(
  (state, props) => ({
    backgroundImageUrl: getters.backgroundImageUrl(state, props),
    title: getters.title(state, props)
  }),
  { init: actions.init }
);

const Layout = ({ init, backgroundImageUrl, title, pageData }) => {
  useEffect(() => {
    init(pageData);
  }, [pageData]);

  useEffect(() => {
    const originalTitle = document.title;
    document.title = title || originalTitle;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);

  return (
    <Page
      instanceId="portal-page"
      backgroundImageUrl={backgroundImageUrl}
      style={{
        width: "90%",
        maxWidth: "1420px",
        boxShadow: "none",
        padding: 0,
        backgroundColor: "transparent"
      }}
    >
      <Body />
    </Page>
  );
};

export default R.compose(decorate)(Layout);
