import React from "react";
import CSSModules from "react-css-modules";
import css from "components/Global/Orders/styles.scss";
import {
  LoadingIcon,
  Div,
  Paginator,
  BigOutlineInput,
  SearchIcon,
  BigFilledButton
} from "components/Base";
import CanUserDo from "components/Global/Security/CanUserDo";
import { ReportCards } from "components/Global/OrderReports/Common";
import EmptyState from "components/Global/OrderReports/EmptyState";
import { Title, Breadcrumbs } from "Passes/Common";

const View = CSSModules(
  ({
    countOfResults,
    currentPage,
    goToPage,
    loading,
    moduleId,
    onSearch,
    onSelectResultsPerPage,
    report,
    reportSource,
    reportTitle,
    resultsPerPage,
    searchTerm = "",
    showAddOrderModal,
    views
  }) => (
    <div>
      <Div
        px={6}
        pt={6}
        bg="white"
        width={1}
        bb={1}
        bc="neutral3"
        display="row.space-between.center"
        style={{
          flexShrink: 0
        }}
      >
        <Div pr={5} pb={3}>
          <Breadcrumbs>{reportSource}</Breadcrumbs>
          <Title>{reportTitle}</Title>
        </Div>

        <CanUserDo
          className={css.actionButtons}
          any={[`${moduleId}_manage`, `${moduleId}_export`, `${moduleId}_add`]}
        >
          <CanUserDo action={`${moduleId}_add`}>
            <BigFilledButton bg="altB5" onClick={showAddOrderModal}>
              Create Request/Order
            </BigFilledButton>
          </CanUserDo>
        </CanUserDo>
      </Div>

      <Div mx={5}>
        <Div my={3}>
          <BigOutlineInput
            LeftIcon={SearchIcon}
            continuous
            placeholder={"Search..."}
            onChange={onSearch}
            style={{ width: "100%" }}
          />
        </Div>

        {report.length ? (
          <Div mb={3}>
            <ReportCards rows={report} />
            <Div bg="white" p={2}>
              <Paginator
                showingResultsProps={{ label: "Records" }}
                goTo={goToPage}
                resultsPerPage={resultsPerPage}
                paginatorLength={5}
                totalNumResults={countOfResults}
                currentPage={currentPage}
                selectOptions={[25, 50, 100]}
                onSelectResultsPerPage={onSelectResultsPerPage}
              />
            </Div>
          </Div>
        ) : loading ? (
          <Div mt={10} display="column.center.center" flex={1}>
            <LoadingIcon size={60} color="neutral4" />
            <Div mt={5}>Loading requests &amp; orders...</Div>
          </Div>
        ) : (
          <EmptyState
            title={
              searchTerm.length
                ? "No items match this search"
                : "You have not added any items yet"
            }
            bg="white"
          />
        )}
      </Div>
    </div>
  ),
  css
);

export default View;
