import React from "react";
import { get } from "lodash";
import * as R from "ramda";
import BaseController from "components/Global/Module/Modals/AddRecord/Layouts/Base";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import isValid from "utils/value-types/validations/is-valid";
import getValue from "utils/value-types/get-value";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import FormElements from "components/Global/Modal/Layout/FormElements";
import TypePicker from "components/Global/StandAloneEditors/Types";
import { Div } from "components/Base";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import { Loading } from "components/Global/Module/Modals/AddRecord/Layouts/Common";
import { RelatedAccountsView } from "components/Global/Module/Modals/AddRecord/Layouts/Contact/RelatedAccounts";

const { Label, InputSection, InputGroup } = FormElements;

class AddRecordModal extends BaseController {
  async componentDidMount() {
    await Promise.all([
      this.props.getModule({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.props.getFields({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.props.getRecordTypes({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.props.getLayoutVisibleFields({
        layoutType: "add-record-layout",
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        // @NOTE: By not passing `recordTypeId` here we receive back all toggled fields
        // across all record types. This way we can conditionally show the fields based on
        // what record type is selected -- or fallback to defaults if no fields available.
        // recordTypeId: [empty],
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      })
    ]);

    this.setState({ loading: false });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ ...newProps.values });
    if (this.state.type === null && newProps.recordTypes.length) {
      this.setState({
        type:
          get(newProps.recordTypes.find(t => t.is_default), "id") ||
          get(newProps.recordTypes, "[0].id")
      });
    }
  }

  goToContactProfile = contactId =>
    this.props.router.push({
      pathname: this.props.orgId
        ? `/organization/${this.props.orgId}/contact/${contactId}`
        : `/event/${this.props.eventId}/contact/${contactId}`
    });

  validateForm = () => {
    const requiredFields = R.compose(R.filter(R.prop("isRequired")))(
      this.getFieldsToShow()
    );

    if (requiredFields.length) {
      for (const field of requiredFields) {
        const isValidValue = isValid(this.state.values[field.id], field.type);
        if (!isValidValue) {
          return false;
        }
      }
    }

    return Object.keys(this.getValuesForSelectedType()).length;
  };

  handleSave = async goToNextScene => {
    if (this.state.saving) {
      return false;
    }

    this.setState({ saving: true });

    if (this.validateForm()) {
      const record = await this.props.addRecord({
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        moduleId: this.props.moduleId,
        record: { ...this.getValuesForSelectedType() },
        typeId: this.state.type,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      });

      if (this.props.onSave) {
        this.props.onSave(record);
      }

      if (goToNextScene) {
        this.setState({
          scene: 1,
          createdRecord: record,
          saving: false
        });
      } else {
        this.props.hideModal();
      }
    }
    return false;
  };

  handleTypeSelect = ({ id }) => {
    this.setState({ type: id });
  };

  renderField = (field, fields) => {
    return (
      <InputSection key={field.id}>
        <InputGroup>
          {this.getFieldEditor({
            field,
            fields
          })}
        </InputGroup>
      </InputSection>
    );
  };

  renderFields = fields => {
    // @NOTE: Currently, we only show types if in an event
    return <div>{fields.map(field => this.renderField(field, fields))}</div>;
  };

  getFieldsToShow = () =>
    this.props.getFieldsToShow(this.state.type || "default");

  getValuesForSelectedType = () => {
    const fieldIds = R.map(R.prop("id"))(this.getFieldsToShow());
    return R.pick(fieldIds, this.state.values);
  };

  renderScene = () => {
    if (this.state.scene === 0) {
      let fieldsToShow = this.getFieldsToShow();
      const fnameField = fieldsToShow.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME
      );
      const lnameField = fieldsToShow.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
      );
      const emailField = fieldsToShow.find(
        f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      );
      fieldsToShow = fieldsToShow.filter(
        f =>
          ![
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
          ].includes(f.id)
      );

      return (
        <Div pb={3}>
          <Div display="column" flex={1}>
            {!this.props.onlyRecordTypeId &&
            this.props.eventId &&
            this.props.recordTypes.length > 1 ? (
              <InputGroup>
                <Label>
                  <Div display="row.space-between.center" mb={1}>
                    <Div fw={3} color="gray7">
                      Type
                    </Div>
                  </Div>
                </Label>
                <TypePicker
                  value={this.getRecordTypeName(this.state.type)}
                  list={[...this.props.recordTypes]}
                  onSelect={this.handleTypeSelect}
                />
              </InputGroup>
            ) : (
              ""
            )}
          </Div>
          <Div display="row.space-between.center" width={1}>
            <Div display="column" flex={1}>
              {this.renderField(fnameField, fieldsToShow)}
            </Div>
            <Div display="column" flex={1} ml={6}>
              {this.renderField(lnameField, fieldsToShow)}
            </Div>
          </Div>
          <Div display="column" flex={1}>
            {this.renderField(emailField, fieldsToShow)}
          </Div>
          <Div>{this.renderFields(fieldsToShow)}</Div>
        </Div>
      );
    } else if (this.state.scene === 1) {
      const recordType = this.getRecordType(this.state.type);
      return (
        <Div>
          <RelatedAccountsView
            onDone={() =>
              this.props.onSave(this.state.createdRecord || undefined)
            }
            contactId={this.state.createdRecord.id}
            contactName={[
              getValue(
                this.state.values[
                  STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME
                ],
                "text"
              ),
              getValue(
                this.state.values[STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME],
                "text"
              )
            ]
              .filter(v => v && v.length)
              .join(" ")}
            recordTypeColor={recordType.background_color}
            recordTypeIcon={recordType.icon}
          />
        </Div>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.scene === 0) {
      return (
        <Div display="row.space-between.center" py={3} width={1}>
          <Div display="row.flex-start.center" flex={1}>
            <Submit
              title={this.state.saving ? "Saving..." : "Save"}
              onClick={() => this.handleSave(false)}
              disabled={!this.validateForm()}
              style={{
                marginRight: "10px"
              }}
            />
            <ButtonOutline
              title="Save & Add Related Group"
              onClick={() => this.handleSave(true)}
              disabled={!this.validateForm()}
            />
          </Div>

          <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
        </Div>
      );
    } else if (this.state.scene === 1) {
      return (
        <ButtonGroup>
          <Submit title="Done" onClick={this.props.hideModal} />
          <ButtonOutline
            title="Add Another"
            onClick={() => this.resetAndKeepOpen()}
          />
          <ButtonOutline
            title="View Contact"
            onClick={() => this.goToContactProfile(this.state.createdRecord.id)}
          />
        </ButtonGroup>
      );
    }
    return null;
  };

  render() {
    const { loading } = this.state;
    const { moduleDetails, onlyRecordTypeId, onlyRecordTypeName } = this.props;

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading={
          <Div display="column">
            <span>Add Person</span>
            {onlyRecordTypeId ? (
              <span
                style={{
                  fontSize: 13,
                  color: "rgba(255, 255, 255, 0.8)"
                }}
              >
                {onlyRecordTypeName}
              </span>
            ) : null}
          </Div>
        }
        hideModal={this.props.hideModal}
        width={630}
      >
        <Body style={{ padding: "20px 30px 10px 30px" }}>
          {loading ? <Loading /> : this.renderScene()}
        </Body>
        <Div
          display="row.space-between.center"
          style={{
            padding: "0 30px"
          }}
        >
          {this.renderFooter()}
        </Div>
      </StyleWrapper>
    );
  }
}

export default AddRecordModal;
