import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getDashboard(type, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const dashboard = await api.get(
        getState().user.user.credentials,
        type,
        eventId
      );
      dispatch({
        type: RECEIVE,
        payload: { dashboard: dashboard.dashboard, type }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function addDashboard(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function updateDashboard(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteDashboard(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
