import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { resources } from "redux/modules/marketing/resources/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { getResources } from "redux/modules/marketing/resources/actions";

function mapStateToProps(state) {
  const resourceType = "passes-overview";

  return {
    eventDetails: eventDetails(state),
    user: user(state),
    resourceType,
    resources: resources(state, resourceType)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      getResources
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
