import { combineReducers } from "redux";
import * as constants from "./constants";

const providers = (state = [], action) => {
  switch (action.type) {
    case constants.TICKETING_PROVIDERS_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const configurations = (state = [], action) => {
  switch (action.type) {
    case constants.TICKETING_CONFIGURATIONS_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const fetchingProviders = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_PROVIDERS_REQUEST:
      return true;
    case constants.TICKETING_PROVIDERS_RESPONSE:
      return false;
    default:
      return state;
  }
};

const fetchingConfigurations = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_CONFIGURATIONS_REQUEST:
      return true;
    case constants.TICKETING_CONFIGURATIONS_RESPONSE:
      return false;
    default:
      return state;
  }
};

const fetchedConfigurations = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_CONFIGURATIONS_REQUEST:
      return true;
    default:
      return state;
  }
};

const configuringEvent = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_CONFIGURING_REQUEST:
      return true;
    case constants.TICKETING_CONFIGURING_RESPONSE:
      return false;
    default:
      return state;
  }
};

const removingProvider = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_REMOVING_REQUEST:
      return true;
    case constants.TICKETING_REMOVING_RESPONSE:
      return false;
    default:
      return state;
  }
};

const fetchingProviderEvents = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_EVENTS_REQUEST:
      return true;
    case constants.TICKETING_EVENTS_RESPONSE:
      return false;
    default:
      return state;
  }
};

const events = (state = [], action) => {
  switch (action.type) {
    case constants.TICKETING_EVENTS_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const ticketTypes = (state = [], action) => {
  switch (action.type) {
    case constants.TICKETING_TYPES_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const ticketTypeMapping = (state = [], action) => {
  switch (action.type) {
    case constants.TICKETING_TYPES_MAPPING_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const isSyncingTicketTypes = (state = false, action) => {
  switch (action.type) {
    case constants.TICKETING_SYNC_TYPES_REQUEST:
      return true;
    case constants.TICKETING_SYNC_TYPES_RESPONSE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  fetchingConfigurations,
  fetchedConfigurations,
  configurations,
  fetchingProviders,
  providers,
  fetchingProviderEvents,
  events,
  ticketTypes,
  ticketTypeMapping,
  configuringEvent,
  removingProvider,
  isSyncingTicketTypes
});
