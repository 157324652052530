import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import SelectBase from "../SelectBase";
import Formatter from "../../Table3/CellFormatters/CatalogItem";
import Editor from "../../Editors/CatalogItemEditor";

const CatalogItemEditor = ({
  onChange,
  options,
  value,
  rowMetaData,
  customWidth,
  selectType,
  disabled
}) => {
  const editor = (
    <Editor
      onChange={onChange}
      options={options}
      value={value}
      disabled={disabled}
      customWidth={customWidth}
      selectType={selectType}
      className="styled"
    />
  );

  if (["quantity-only-input", "quantity-only-dropdown"].includes(selectType)) {
    return editor;
  }

  // Add dropdown wrapper
  return (
    <SelectBase
      formatter={
        get(value, "value.records.length") ? (
          <Formatter dependentValues={rowMetaData} value={value} />
        ) : null
      }
      editor={editor}
    />
  );
};
CatalogItemEditor.propTypes = {
  value: PropTypes.object,
  selectType: PropTypes.string.isRequired,
  customWidth: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CatalogItemEditor;
