import { createContext } from "redux-mvc";
import * as R from "ramda";
import module from "../index";
import { actions } from "../model";
import Layout from "./Layout";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "events",
      "@flopflip",
      "EventMyApprovals"
    ],
    persist: true
  }
});

export default decorate(Layout);
