import React from "react";
import * as R from "ramda";
import PermissionSelect from "components/Global/Inputs/PermissionSelect";
import { connect } from "react-redux";
import { getPermissionRole, getApiToken, getEnableApi } from "../selectors";
import { FIELDS } from "../constants";
import { actions, getters } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Input, Toggle } from "ui-kit/Form/View";
import { MediumFilledButton, MediumOutlineButton } from "components/Base";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";
import Header from "./Header";

const decorate = R.compose(
  connect(
    state => ({
      permissionRole: getPermissionRole(state),
      user: getters.user(state),
      apiToken: getApiToken(state),
      enableApi: getEnableApi(state)
    }),
    {
      updateUserField: actions.updateUserField,
      save: actions.save,
      generateApiToken: actions.generateApiToken,
      copyApiToken: actions.copyApiToken,
      toggleTokenAccess: actions.toggleTokenAccess
    }
  ),
  CSSModules(css)
);

const Body = ({
  permissionRole,
  updateUserField,
  user,
  save,
  apiToken,
  generateApiToken,
  copyApiToken,
  toggleTokenAccess,
  enableApi
}) => (
  <div styleName="wrapper">
    <Header />
    <div styleName="content">
      <div styleName="input-container">
        <div styleName="label">What is the user's role in this event?</div>
        <Input
          fieldId={FIELDS.ROLE}
          size="big"
          placeholder="ex: Event Director"
          iniValue={R.prop(FIELDS.ROLE, user)}
          width={250}
        />
      </div>
      <div styleName="input-container">
        <div styleName="label">Permissions</div>
        <PermissionSelect
          value={permissionRole}
          onSelect={value =>
            updateUserField({ value, field: FIELDS.PERMISSION_ROLE })
          }
        />
      </div>
      <div styleName="input-container">
        <div styleName="label">API Token</div>
        <div styleName="api-access" onClick={toggleTokenAccess}>
          <Toggle
            fieldId={FIELDS.ENABLE_API}
            iniValue={R.propOr(false, FIELDS.ENABLE_API, user)}
            mr={2}
            size="tiny"
          />
          {enableApi ? "API Access Enabled" : "API Access Disabled"}
        </div>
        {enableApi ? (
          <div>
            {!apiToken ? (
              <div styleName="input-container">
                <div styleName="api-text" onClick={generateApiToken}>
                  <span class="material-icons" style={{ fontSize: 16 }}>
                    add
                  </span>
                  Generate API token for this event
                </div>
              </div>
            ) : (
              <div
                styleName="input-container"
                style={{
                  alignItems: "flex-end",
                  flexDirection: "column",
                  display: "flex"
                }}
              >
                <div style={{ width: "100%" }}>
                  <Input
                    fieldId={FIELDS.API_TOKEN}
                    iniValue={apiToken}
                    disabled
                    size="medium"
                    style={{
                      background: "#ddd",
                      borderColor: "#999",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      width: "calc(100% - 59px)",
                      borderRight: 0
                    }}
                  />
                  <MediumOutlineButton
                    bg="white"
                    style={{
                      borderColor: "#c5c5c5",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderWidth: 1,
                      color: "#999"
                    }}
                    onClick={copyApiToken}
                  >
                    Copy
                  </MediumOutlineButton>
                </div>

                <DeleteConfirmation
                  width={200}
                  onConfirm={generateApiToken}
                  text="Are you sure ?"
                  paragraph="Refreshing a token will invalidate the current token and will stop it from working"
                  confirmLabel="Generate"
                >
                  <div styleName="new-key" onClick={generateApiToken}>
                    <span
                      class="material-icons"
                      style={{ fontSize: 10, marginRight: 2 }}
                    >
                      add_circle_outline
                    </span>
                    Generate new token
                  </div>
                </DeleteConfirmation>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
    <div styleName="footer">
      <MediumFilledButton bg="primary7" color="white" onClick={save}>
        Save
      </MediumFilledButton>
    </div>
  </div>
);

export default decorate(Body);
