import { REQUEST, RECEIVE, DELETE_SUBMISSION } from "./constants";
import { ERROR } from "../constants";
import { deleteSubmission as deleteSubmissionApi } from "redux/modules/formsV2/submission/actions";
import * as selectors from "./selectors";
import api from "./api";

export function getForms(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const forms = await api.get(getState().user.user.credentials, data);
      dispatch({ type: RECEIVE, payload: { forms } });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteFormSubmission(formId, submissionId) {
  return dispatch =>
    Promise.all([
      dispatch(deleteSubmissionApi({ submissionId })),
      dispatch({
        type: DELETE_SUBMISSION,
        payload: {
          formId,
          submissionId
        }
      })
    ]);
}
