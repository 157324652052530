import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*({
  payload: { moduleId, recordTypeId, mode, visibleFields, fieldOrder }
}) {
  const { credentials, orgId, eventId } = yield call(getParams);

  try {
    yield put(actions.setLoading(true));

    const {
      payload: { fields, all_fields, default_preferences }
    } = yield call(Api.getFields, {
      credentials,
      moduleId,
      data: {
        orgId,
        eventId,
        recordTypeId,
        mode
      }
    });

    yield put(
      actions.setInitialData({
        moduleId,
        recordTypeId,
        mode,
        fields,
        defaultPreferences: default_preferences,
        allFields: all_fields,
        visibleFields,
        fieldOrder
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred loading initializing view"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refreshFields = function*() {
  const { credentials, orgId, eventId } = yield call(getParams);
  const moduleId = yield select(getters.moduleId);
  const recordTypeId = yield select(getters.recordTypeId);
  const mode = yield select(getters.mode);

  try {
    const {
      payload: { fields, all_fields }
    } = yield call(Api.getFields, {
      credentials,
      moduleId,
      data: {
        orgId,
        eventId,
        recordTypeId,
        mode
      }
    });

    yield put(
      actions.setRefreshedFields({
        fields,
        allFields: all_fields
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred refreshing fields"
          }
        ])
      )
    ]);
  }
};

const watchRefreshFields = function*() {
  yield takeEvery(actions.refreshFields.type, refreshFields);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchRefreshFields)]);
};

export default rootSaga;
