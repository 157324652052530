import React from "react";
import * as R from "ramda";
import { actions, getters } from "../model";
import { getUsername, getEventName } from "../selectors";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.loading(state),
      username: getUsername(state),
      eventName: getEventName(state)
    }),
    {
      hideModal: () => actions.setShowModal(false)
    }
  ),
  CSSModules(css)
);

const Header = ({ hideModal, username, eventName }) => (
  <div styleName="header">
    <div>
      <div styleName="user-name">{username}</div>
      <div styleName="event-name">{eventName}</div>
    </div>
    <span
      class="material-icons"
      onClick={hideModal}
      style={{ cursor: "pointer" }}
    >
      close
    </span>
  </div>
);

export default decorate(Header);
