import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";

import AddSubformModal from "components/Event/FormsV2/Modals/FormGridModal";
import AddSectionModal from "components/Event/FormsV2/Modals/FormAddSectionModal";
import Helpers from "utils/Global/Helpers";

import * as FieldActions from "redux/modules/formsV2/form/fields/actions";

@CSSModules(css)
class AddNewSectionMenuItem extends Component {
  @autobind
  onClick() {
    switch (this.props.type) {
      case "subform":
        this.props.showModal({
          content: (
            <AddSubformModal
              index={this.props.index}
              eventId={this.props.eventId}
              formId={this.props.formId}
            />
          )
        });
        break;
      case "section":
        this.props.showModal({
          content: (
            <AddSectionModal
              index={this.props.index}
              eventId={this.props.eventId}
              formId={this.props.formId}
            />
          )
        });
        break;
      case "question":
        this.props.showAddQuestionModal(this.props.index);
        break;
      case "image":
        this.handleImagePick();
        break;
      default:
        this.props.dispatch(
          FieldActions.addField({
            eventId: this.props.eventId,
            formId: this.props.formId,
            type: this.props.type,
            order: this.props.index
          })
        );
    }
  }

  @autobind
  handleImagePick() {
    const path = { path: "event-form-image/" };
    const callback = InkBlobs => {
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.props.dispatch(
          FieldActions.addField({
            eventId: this.props.eventId,
            formId: this.props.formId,
            type: this.props.type,
            order: this.props.index,
            settings: {
              url: InkBlobs[0].url
            }
          })
        );
      }
    };
    Helpers.getFilepicker({}, path, callback);
  }

  render() {
    return (
      <div styleName="menuItem" onClick={this.onClick}>
        <i className="material-icons" styleName="menuIcon">
          {this.props.icon}
        </i>
        <div styleName="menuText">{this.props.name}</div>
      </div>
    );
  }
}

AddNewSectionMenuItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  showAddQuestionModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default AddNewSectionMenuItem;
