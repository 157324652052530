import {
  REQUEST,
  RECEIVE,
  RECEIVE_REFERENCES,
  ADD_REFERENCE
} from "./constants";
import { RECEIVE_VALUES } from "./values/constants";
import * as selectors from "./selectors";
import api from "./api";
import { normalize, schema } from "normalizr";
import { get } from "lodash";

const value = new schema.Entity("value", {
  record_id: reference
});

const reference = new schema.Entity("reference", {
  values: [value]
});

export function getReferences(eventId, userId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const result = normalize(
        await api.get(getState().user.user.credentials, eventId, userId),
        [reference]
      );
      dispatch({ type: RECEIVE });
      dispatch({
        type: RECEIVE_REFERENCES,
        payload: get(result, "entities.reference", {})
      });
      dispatch({
        type: RECEIVE_VALUES,
        payload: get(result, "entities.value", {})
      });
    } catch (error) {
      // Swallow error
    }
  };
}

/* NOTE: This is intended to be called in a try catch so the consumer can handle the error */
export function addReferenceDangerous(eventId, userId, data) {
  return async (dispatch, getState) => {
    const result = await api.post(
      getState().user.user.credentials,
      eventId,
      userId,
      data
    );
    dispatch({
      type: ADD_REFERENCE,
      payload: result.reference
    });

    return result.reference;
  };
}
