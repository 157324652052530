import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

export const iniState = {
  venues: [],
  tags: [],
  addVenueActive: false,
  addTagActive: false,
  tagName: "",
  venueName: "",
  templateName: ""
};

export const reducers = {
  addVenue: R.identity,
  updateVenue: R.identity,
  removeVenue: R.identity,
  addTag: R.identity,
  updateTag: R.identity,
  removeTag: R.identity,
  cancelInstance: R.always(iniState),
  updateSelectedVenue: R.identity,
  updateSelectedTags: R.identity,
  setInitialData: (
    _,
    { payload: { venues, venueId = "", tags, selectedTags = [] } }
  ) => ({
    venues: R.map(
      venue => ({
        ...venue,
        selected: venue.id === venueId,
        editing: false,
        editingName: venue.name,
        confirmDelete: false
      }),
      venues
    ),
    tags: R.map(
      tag => ({
        ...tag,
        selected: R.any(R.pathEq(["tag", "id"], tag.id), selectedTags),
        editing: false,
        editingName: tag.name,
        confirmDelete: false
      }),
      tags
    )
  }),
  selectTag: (state, { payload: { id } }) => {
    const tagIndex = R.findIndex(R.propEq("id", id), state.tags);
    const selectedTag = state.tags[tagIndex];
    return {
      tags: [
        ...state.tags.slice(0, tagIndex),
        {
          ...selectedTag,
          selected: !selectedTag.selected
        },
        ...state.tags.slice(tagIndex + 1)
      ]
    };
  },
  selectVenue: (state, { payload: { id } }) => ({
    venues: R.map(
      venue => ({ ...venue, selected: venue.id === id }),
      state.venues
    )
  }),
  selectVenueTags: (state, { payload: { venueId, tags } }) => ({
    venues: R.map(
      venue => ({ ...venue, selected: venue.id === venueId }),
      state.venues
    ),
    tags: R.map(
      tag => ({
        ...tag,
        selected: R.any(({ tag: { id } }) => id === tag.id, tags)
      }),
      state.tags
    )
  }),
  cancelEditingVenue: () => ({
    editVenueActive: false,
    addVenueActive: false,
    venueName: ""
  }),
  cancelEditingEventTag: () => ({
    addTagActive: false,
    tagName: ""
  }),
  updateVenues: (state, { payload: { venues } }) => ({
    addVenueActive: false,
    venueName: "",
    venues: R.map(venue => {
      const venueIndex = R.findIndex(R.propEq("id", venue.id), state.venues);
      return {
        ...venue,
        selected: venueIndex === -1 ? false : state.venues[venueIndex].selected,
        editing: false,
        editingName: venue.name,
        confirmDelete: false
      };
    }, venues)
  }),
  updateEventsTags: (state, { payload: { tags } }) => ({
    addTagActive: false,
    tagName: "",
    tags: R.map(tag => {
      const tagIndex = R.findIndex(R.propEq("id", tag.id), state.tags);
      return {
        ...tag,
        selected: tagIndex === -1 ? false : state.tags[tagIndex].selected,
        editing: false,
        editingName: tag.name,
        confirmDelete: false
      };
    }, tags)
  }),
  enableEditingVenue: (state, { payload: { id } }) => {
    const venueIndex = R.findIndex(R.propEq("id", id), state.venues);
    const currentVenue = state.venues[venueIndex];
    return {
      editVenueActive: true,
      venues: [
        ...state.venues.slice(0, venueIndex),
        {
          ...currentVenue,
          editing: !currentVenue.editing,
          editingName: currentVenue.name
        },
        ...state.venues.slice(venueIndex + 1)
      ]
    };
  },
  toggleEditingTag: (state, { payload: { id } }) => {
    const tagIndex = R.findIndex(R.propEq("id", id), state.tags);
    const currentTag = state.tags[tagIndex];
    return {
      tags: [
        ...state.tags.slice(0, tagIndex),
        {
          ...currentTag,
          editing: !currentTag.editing,
          editingName: currentTag.name
        },
        ...state.tags.slice(tagIndex + 1)
      ]
    };
  },
  toggleConfirmDeleteVenue: (state, { payload: { id } }) => {
    const venueIndex = R.findIndex(R.propEq("id", id), state.venues);
    const currentVenue = state.venues[venueIndex];
    return {
      editVenueActive: true,
      venues: [
        ...state.venues.slice(0, venueIndex),
        {
          ...currentVenue,
          confirmDelete: !currentVenue.confirmDelete
        },
        ...state.venues.slice(venueIndex + 1)
      ]
    };
  },
  toggleConfirmDeleteTag: (state, { payload: { id } }) => {
    const tagIndex = R.findIndex(R.propEq("id", id), state.tags);
    const currentVenue = state.tags[tagIndex];
    return {
      tags: [
        ...state.tags.slice(0, tagIndex),
        {
          ...currentVenue,
          confirmDelete: !currentVenue.confirmDelete
        },
        ...state.tags.slice(tagIndex + 1)
      ]
    };
  },
  updateEditingVenueName: (state, { payload: { id, value } }) => {
    const venueIndex = R.findIndex(R.propEq("id", id), state.venues);
    const currentVenue = state.venues[venueIndex];
    return {
      editVenueActive: true,
      venues: [
        ...state.venues.slice(0, venueIndex),
        {
          ...currentVenue,
          editingName: value
        },
        ...state.venues.slice(venueIndex + 1)
      ]
    };
  },
  updateEditingTagName: (state, { payload: { id, value } }) => {
    const tagIndex = R.findIndex(R.propEq("id", id), state.tags);
    const currentVenue = state.tags[tagIndex];
    return {
      tags: [
        ...state.tags.slice(0, tagIndex),
        {
          ...currentVenue,
          editingName: value
        },
        ...state.tags.slice(tagIndex + 1)
      ]
    };
  }
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers
});

export default handlers;
