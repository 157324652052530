import React, { Component } from "react";
import * as R from "ramda";
import Fuse from "fuse.js";
import View from "./View";
import CircularProgress from "material-ui/CircularProgress";
import moment from "moment";
import createDateRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";
import { IntegrationIconsById } from "components/Event/Settings/Credentials/constants";

const mapIndexed = R.addIndex(R.map);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

const getTrackingMethodName = method => {
  switch (method) {
    case "barcode":
      return "Barcode";
    case "rfid":
      return "RFID";
    case "number_range":
      return "Number Range";
    default:
      return "None";
  }
};

class InternalHandler extends Component {
  state = {
    searchTerm: ""
  };

  componentDidMount() {
    this.credentialsFuse = new Fuse(this.props.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });
  }

  componentDidUpdate() {
    this.credentialsFuse = new Fuse(this.props.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });
  }

  onSearch = searchTerm => this.setState({ searchTerm });

  render() {
    const {
      eventDetails,
      itemGroups,
      loading,
      moveCredentialDown,
      moveCredentialUp,
      moveGroupDown,
      moveGroupUp,
      reorderCredentials,
      deleteCredential,
      showAddCredentialGroupModal,
      showCloneCredentialModal,
      showCredentialModal,
      showDeleteCredentialGroupModal,
      showUpdateCredentialGroupModal
    } = this.props;

    if (loading) {
      return <CircularProgress color="#ccc" />;
    }

    const { searchTerm } = this.state;

    const credentialGroupsWithHandlers = mapIndexed((group, index) => {
      const credentials =
        searchWithFuse(searchTerm, this.credentialsFuse, group.items, "id") ||
        [];
      return {
        ...group,
        onEdit: () => showUpdateCredentialGroupModal(group),
        onDelete:
          itemGroups.length > 1
            ? () => showDeleteCredentialGroupModal(group)
            : null,
        moveGroupUp: index === 0 ? undefined : () => moveGroupUp(index),
        moveGroupDown:
          index === this.props.itemGroups.length - 1
            ? undefined
            : () => moveGroupDown(index),
        countOfCredentials: credentials.length,
        reorderCredentials: newOrder => reorderCredentials(group.id, newOrder),
        forceCollapse: searchTerm.length && !credentials.length,
        collapsedDefault: !credentials.length,
        credentials: mapIndexed((credential, cIndex) => {
          const variant = credential.variants[0];
          return {
            id: credential.id,
            name: credential.name,
            color: credential.background_color,
            source: credential.source,
            IntegrationIcon: IntegrationIconsById[variant.provider_id],
            quantity: variant.track_inventory
              ? variant.inventory_quantity || "—"
              : "—",
            type: variant.track_inventory
              ? getTrackingMethodName(variant.tracking_method)
              : "—",
            numZones: 0,
            dateGroups: eventDetails.date_groups,
            dates: variant.rules
              ? createDateRanges(
                  variant.rules.map(rule =>
                    moment(rule.value).format("YYYY-MM-DD")
                  )
                )
              : null,
            onCredentialClick: () => showCredentialModal(credential.id),
            onEdit: () => showCredentialModal(credential.id),
            onClone: () => showCloneCredentialModal(credential.id),
            onDelete: () => deleteCredential(credential.id),
            moveCredentialUp:
              cIndex === 0 || searchTerm.length
                ? undefined
                : () => moveCredentialUp(group.id, cIndex),
            moveCredentialDown:
              cIndex === credentials.length - 1 || searchTerm.length
                ? undefined
                : () => moveCredentialDown(group.id, cIndex)
          };
        }, credentials)
      };
    }, itemGroups);

    return (
      <View
        {...{
          credentialGroups: credentialGroupsWithHandlers,
          onSearch: this.onSearch,
          showAddCredentialGroupModal,
          showCredentialModal
        }}
      />
    );
  }
}

export default InternalHandler;
