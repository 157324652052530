import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import {
  isPreviousBtnVisible,
  nextBtnText,
  isPreviewEnabled,
  hasAlreadyBeenSent
} from "../selectors";

import {
  Div,
  BigFilledButton,
  RightArrowIcon,
  BigOutlineButton
} from "components/Base";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    sending: getters.sending(state, props),
    isPreviousBtnVisible: isPreviousBtnVisible(state, props),
    isNextEnabled: isPreviewEnabled(state, props),
    nextBtnText: nextBtnText(state, props),
    hasAlreadyBeenSent: hasAlreadyBeenSent(state, props)
  }),
  {
    hideModal: () => actions.closeModal,
    previewAlert: actions.previewAlert,
    sendAlert: actions.sendAlert,
    saveAlert: actions.saveAlert,
    editAlert: actions.backToEdit
  }
);

const Footer = ({
  isPreviousBtnVisible,
  previousBtnText = "Edit",
  editAlert = () => {},
  isNextEnabled = false,
  sendAlert = () => {},
  saveAlert = () => {},
  previewAlert = () => {},
  nextBtnText = "Preview",
  sending,
  hasAlreadyBeenSent
}) => (
  <Div
    height={70}
    bg="transparent"
    width={1}
    display="row.flex-start.center"
    p={4}
    bt={1}
    bc="neutral2"
  >
    {isPreviousBtnVisible && (
      <BigOutlineButton mr={4} onClick={editAlert}>
        {previousBtnText}
      </BigOutlineButton>
    )}
    <BigFilledButton
      bg={isNextEnabled ? "altB5" : "neutral3"}
      color={isNextEnabled ? "white" : "neutral6"}
      disabled={!isNextEnabled}
      RightIcon={hasAlreadyBeenSent || sending ? undefined : RightArrowIcon}
      width={isPreviousBtnVisible ? 0.7 : 1}
      display="row.center.center"
      onClick={
        sending
          ? undefined
          : hasAlreadyBeenSent
          ? saveAlert
          : isPreviousBtnVisible
          ? sendAlert
          : previewAlert
      }
    >
      {nextBtnText}
    </BigFilledButton>
  </Div>
);

export default decorate(Footer);
