import * as R from "ramda";

import moment from "moment";

import { TYPES as VALIDATION_TYPES, EMPTY_ERRORS } from "./constants";

const makeValidator = (predicate, description) => (...params) =>
  predicate(...params)
    ? EMPTY_ERRORS
    : { valid: false, description: description(...params) };

export const composeValidators = (...validators) => (...params) =>
  R.reduce(
    (acc, validator) => {
      const result = validator(...params);
      return result.valid ? result : R.reduced(result);
    },
    {},
    validators
  );

const isEmpty = v => R.isEmpty(v) || R.isNil(v);

export const notEmpty = makeValidator(
  (value, params) =>
    R.propOr(
      R.compose(
        //default not empty validator
        R.not,
        isEmpty
      ),
      params.type,
      {
        [VALIDATION_TYPES.ITEM_BLOCK]: R.compose(
          R.not,
          isEmpty,
          R.prop("value")
        )
      } // add not empty validators for e.g item block
    )(value),
  (v, { key }) => `${key} is required`
);

// eslint-disable-next-line no-control-regex
const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const isEmail = makeValidator(
  R.test(emailRegex),
  R.always("Not a valid email")
);

export const isDateGreaterThan = makeValidator(
  (value, { compareWithValue }) => {
    return moment(value).isSameOrAfter(compareWithValue);
  },
  (v, { key, compareWithKey }) => `${key} must be after ${compareWithKey}`
);
export const isDateGreaterThanIfValueExists = makeValidator(
  (value, { compareWithValue }) => {
    if (value) {
      return moment(value).isSameOrAfter(compareWithValue);
    }
    return true;
  },
  (v, { key, compareWithKey }) => `${key} must be after ${compareWithKey}`
);

const validate = ({ value, ...params }) => {
  const selectedValidator = R.propOr(R.always(EMPTY_ERRORS), params.type, {
    [VALIDATION_TYPES.NOT_EMPTY]: notEmpty,
    [VALIDATION_TYPES.EMAIL]: isEmail,
    [VALIDATION_TYPES.DATE_GREATER_THAN]: isDateGreaterThan,
    [VALIDATION_TYPES.DATE_GREATER_THAN_IF_VALUE_EXISTS]: isDateGreaterThanIfValueExists
  });

  const validator = params.required
    ? composeValidators(notEmpty, selectedValidator)
    : selectedValidator;

  return { ...params, ...validator(value, params) };
};

export default validate;
