import PropTypes from "prop-types";
import React, { Component } from "react";
import Contact from "./Contact";
import ReturnToSearch from "../ReturnToSearch";
import RoleInput from "../RoleInput";
import FormActions from "../FormActions";
import ButtonGroup from "../ButtonGroup";

class SelectedContact extends Component {
  render() {
    const {
      isOrg,
      returnToSearch,
      hasMessage,
      addMessage,
      isPrimary,
      sendNotification,
      giveLoginAccess,
      selectedContact,
      handleFormFieldChange,
      hideModal,
      updateMessage,
      newTemplate,
      message,
      templateTitle,
      updateTemplateTitle,
      toggleTemplate
    } = this.props;

    return (
      <div>
        <ReturnToSearch returnToSearch={returnToSearch} />
        <Contact {...selectedContact} />
        <RoleInput onChange={handleFormFieldChange} />
        <FormActions
          isOrg={isOrg}
          newTemplate={newTemplate}
          message={message}
          templateTitle={templateTitle}
          handleFormFieldChange={handleFormFieldChange}
          hasMessage={hasMessage}
          addMessage={addMessage}
          updateMessage={updateMessage}
          updateTemplateTitle={updateTemplateTitle}
          toggleTemplate={toggleTemplate}
          isPrimary={isPrimary}
          sendNotification={sendNotification}
          giveLoginAccess={giveLoginAccess}
          giveLoginAccessDisabled={!selectedContact.email}
        />
        <ButtonGroup
          sendNotification={sendNotification}
          giveLoginAccess={giveLoginAccess}
          hideModal={hideModal}
        />
      </div>
    );
  }
}

SelectedContact.propTypes = {
  newTemplate: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  templateTitle: PropTypes.string.isRequired,
  hasMessage: PropTypes.bool.isRequired,
  addMessage: PropTypes.func.isRequired,
  updateTemplateTitle: PropTypes.func.isRequired,
  toggleTemplate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  returnToSearch: PropTypes.func.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  sendNotification: PropTypes.bool.isRequired,
  giveLoginAccess: PropTypes.bool.isRequired,
  selectedContact: PropTypes.object.isRequired
};

export default SelectedContact;
