import React, { Fragment } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import { withState, addS } from "utils/General";
import {
  Div,
  DarkTab,
  SuperShadedInput,
  MediumFilledButton,
  BigFilledButton,
  BigOutlineButton,
  DownIcon,
  LoadingIcon,
  Popover,
  SearchIcon,
  CheckCircleIcon,
  HalfSizeDotIcon,
  ErrorIcon
} from "components/Base";
import Details from "components/Event/Settings/Credentials/Integrations/Ticketing/Details";
import * as R from "ramda";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import MiniPassSelector from "components/Event/Settings/Credentials/Integrations/Ticketing/Modals/EditIntegration/MiniPassSelector/View";
import {
  DO_NOT_MAP,
  MAP_TO_EXISTING,
  NEW_ITEM,
  SELECT_ITEM
} from "./constants";

const EmptyState = ({ children }) => (
  <Div display="row.center.center" my={8}>
    {children}
  </Div>
);

const NotImportedView = ({
  integrationName,
  ticketTypes,
  hasSearchTerm,
  goToMappedItems
}) => (
  <Fragment>
    {ticketTypes && ticketTypes.length ? (
      <Div
        display="row.space-between.center"
        px={6}
        fw={3}
        fs={2}
        color="gray6"
        mb={2}
      >
        <Div uppercase>{integrationName} Items</Div>
        <Div uppercase fs={2} color="gray6">
          Map to LENND Items
        </Div>
      </Div>
    ) : null}

    <Body style={{ maxHeight: 400, border: 0 }}>
      <Div px={5}>
        {ticketTypes && ticketTypes.length ? (
          ticketTypes.map(ticketType => (
            <TicketType key={ticketType.id} ticketType={ticketType} />
          ))
        ) : hasSearchTerm ? (
          <EmptyState>No items match your search</EmptyState>
        ) : (
          <Div display="column.center.center" my={12}>
            <CheckCircleIcon color="neutral2" size={70} mb={3} />
            <Div
              textAlign="center"
              fw={4}
              fs={3}
              mb={3}
              color="gray7"
              maxWidth={275}
            >
              All available items have been imported & mapped
            </Div>
            <MediumFilledButton bg="altB5" onClick={goToMappedItems}>
              Update Mapped Items
            </MediumFilledButton>
          </Div>
        )}
      </Div>
    </Body>
  </Fragment>
);

const UpdateMappingView = ({ itemGroups, integrationName, hasSearchTerm }) => (
  <Body style={{ maxHeight: 400, border: 0 }}>
    <Div px={5}>
      {itemGroups && itemGroups.length ? (
        itemGroups.map(itemGroup => (
          <Div mb={6}>
            <Div
              display="row.space-between.center"
              px={3}
              fw={3}
              fs={2}
              color="gray6"
              mb={3}
            >
              <Div uppercase fs={2} color="gray6">
                {itemGroup.name}
              </Div>
              <Div uppercase>Mapped {integrationName} Item</Div>
            </Div>
            {itemGroup.items.map(item => (
              <LenndItem key={item.id} item={item} />
            ))}
          </Div>
        ))
      ) : (
        <EmptyState>
          {hasSearchTerm
            ? "No items match your search"
            : "No items have been created yet"}
        </EmptyState>
      )}
    </Div>
  </Body>
);

const PassMapSelectorLabel = ({ children }) => (
  <Div fw={3} color="gray7">
    {children}
  </Div>
);

const PassMapSelectorDefaultButton = () => (
  <Div color="primary9" fw={4} fs={5}>
    +
  </Div>
);

const PassMapSelector = withState(
  "selectedMethod",
  "setMethod",
  ({ method }) => method || DO_NOT_MAP
)(
  ({
    selected,
    selectedMethod,
    setMethod,
    onUpdateMapping,
    onUpdateMethod,
    getItemGroups,
    countOfSelectedItems,
    allowedMethods,
    selectedTicketTypeName
  }) => (
    <Popover
      innerSpanStyle={{
        width: "100%"
      }}
      Label={({ onClick }) => (
        <Div
          b={1}
          bra={1}
          px={3}
          py={1}
          display={selected ? "row.space-between.center" : "row.center.center"}
          onClick={onClick}
          width={1}
          flex={1}
          height={36}
          bg={
            selected
              ? {
                  default: "orange0",
                  hover: "orange1"
                }
              : {
                  default: "white",
                  hover: "gray1"
                }
          }
          bc={selected ? "orange5" : "gray4"}
        >
          {R.prop(selectedMethod, {
            [SELECT_ITEM]: (
              <Fragment>
                <Div fw={3} color="gray7" fs={2} flex={1}>
                  {selectedTicketTypeName ? (
                    selectedTicketTypeName
                  ) : (
                    <Div display="row.flex-start.center">
                      <ErrorIcon color="orange4" mr={2} />
                      No Item Mapped. Will not sync.
                    </Div>
                  )}
                </Div>
                <DownIcon color="gray7" />
              </Fragment>
            ),
            [DO_NOT_MAP]: <PassMapSelectorDefaultButton />,
            [NEW_ITEM]: (
              <Div fw={3} color="gray7" fs={2}>
                +&nbsp;&nbsp;Create as a new item
              </Div>
            ),
            [MAP_TO_EXISTING]: selected ? (
              <Fragment>
                <Div fw={3} color="gray7" fs={2}>
                  {countOfSelectedItems} Selected Item
                  {addS(countOfSelectedItems)}
                </Div>
                <DownIcon color="gray7" />
              </Fragment>
            ) : (
              <PassMapSelectorDefaultButton />
            )
          })}
        </Div>
      )}
    >
      {({ closePopover }) => (
        <Div bra={1} width={350} p={2}>
          {R.compose(
            R.length,
            R.filter(m => m !== SELECT_ITEM)
          )(allowedMethods) ? (
            <RadioButtonGroup
              onChange={(event, method) => {
                setMethod(method);
                onUpdateMethod(method);
              }}
              name="method"
              valueSelected={selectedMethod}
            >
              {R.map(method =>
                R.prop(method, {
                  [DO_NOT_MAP]: (
                    <RadioButton
                      key={DO_NOT_MAP}
                      value={DO_NOT_MAP}
                      label={
                        <PassMapSelectorLabel>Do not map</PassMapSelectorLabel>
                      }
                      iconStyle={{ alignSelf: "center" }}
                    />
                  ),
                  [NEW_ITEM]: (
                    <RadioButton
                      key={NEW_ITEM}
                      value={NEW_ITEM}
                      label={
                        <PassMapSelectorLabel>
                          Create a new item...
                        </PassMapSelectorLabel>
                      }
                      iconStyle={{ alignSelf: "center" }}
                    />
                  ),
                  [MAP_TO_EXISTING]: (
                    <RadioButton
                      key={MAP_TO_EXISTING}
                      value={MAP_TO_EXISTING}
                      label={
                        <PassMapSelectorLabel>
                          Map to existing pass...
                        </PassMapSelectorLabel>
                      }
                      iconStyle={{ alignSelf: "center" }}
                    />
                  )
                })
              )(allowedMethods)}
            </RadioButtonGroup>
          ) : null}
          {[MAP_TO_EXISTING, SELECT_ITEM].includes(selectedMethod) ? (
            <MiniPassSelector
              mode={
                selectedMethod === MAP_TO_EXISTING
                  ? "multiple-select"
                  : "single-select"
              }
              buttonLabel="Save"
              itemGroups={getItemGroups()}
              onDone={mapping => {
                onUpdateMapping(mapping);
                closePopover();
              }}
            />
          ) : null}
        </Div>
      )}
    </Popover>
  )
);

const TicketType = ({ ticketType }) => (
  <Div
    display="row.flex-start.center"
    bg="white"
    shadow={1}
    mb={2}
    bra={1}
    pt={2}
    pb={2}
    pl={3}
    pr={3}
  >
    <Div flex={1}>
      <Div display="column">
        <Div fw={3} fs={4} color="gray7">
          {ticketType.name}
        </Div>
        <Details details={ticketType} />
      </Div>
    </Div>
    <Div width={265}>
      <PassMapSelector
        selected={ticketType.isSelected}
        onUpdateMapping={ticketType.onUpdateMapping}
        onUpdateMethod={ticketType.onUpdateMethod}
        getItemGroups={ticketType.getItemGroups}
        countOfSelectedItems={ticketType.countOfSelectedItems}
        method={ticketType.method}
        allowedMethods={ticketType.allowedMethods}
      />
    </Div>
  </Div>
);

const LenndItem = ({ item }) => (
  <Div
    display="row.flex-start.center"
    bg="white"
    shadow={1}
    mb={2}
    bra={1}
    pt={2}
    pb={2}
    pl={3}
    pr={3}
  >
    <Div flex={1} display="row.flex-start.center">
      <Div mr={2}>
        <HalfSizeDotIcon
          style={{
            color: item.color
          }}
        />
      </Div>
      <Div display="column">
        <Div fw={3} fs={4} color="gray7">
          {item.name}
        </Div>
        <Details details={item} />
      </Div>
    </Div>
    <Div width={265}>
      <PassMapSelector
        selected={item.isSelected}
        onUpdateMapping={item.onUpdateMapping}
        getItemGroups={item.getItemGroups}
        allowedMethods={item.allowedMethods}
        method={item.method}
        selectedTicketTypeName={item.selectedTicketTypeName}
      />
    </Div>
  </Div>
);

const View = ({
  onCancel,
  onSave,
  saving,
  loading,
  hideModal,
  ticketTypes,
  tabs,
  onSearch,
  integrationName,
  activeTab,
  itemGroups,
  countOfItemsToBeMapped,
  goToMappedItems,
  hasSearchTerm
}) => (
  <StyleWrapper
    heading="Import & Update Item Mapping"
    hideModal={hideModal}
    width={700}
    bodyStyles={{
      padding: 0
    }}
    containerStyles={{
      backgroundColor: "#F9F9F9"
    }}
  >
    <Div bb={1} bc="gray2" px={5}>
      {tabs.map(({ id, name, onClick, active }) => {
        return (
          <DarkTab
            key={id}
            px={2}
            fs={3}
            active={active}
            onClick={onClick}
            height={38}
          >
            {name}
          </DarkTab>
        );
      })}
    </Div>

    <Fragment>
      <Div bg="neutral1" px={5}>
        <SuperShadedInput
          onChange={onSearch}
          continuous
          placeholder="Search items..."
          display="row.flex-start.center"
          mb={4}
          RightIcon={SearchIcon}
        />
      </Div>

      {loading ? (
        <Div display="row.center.center" flex={1} my={6}>
          <LoadingIcon size={50} color="neutral4" />
        </Div>
      ) : (
        R.prop(activeTab, {
          "not-imported": (
            <NotImportedView
              {...{
                ticketTypes,
                integrationName,
                hasSearchTerm,
                goToMappedItems
              }}
            />
          ),
          "update-mapping": (
            <UpdateMappingView
              {...{
                itemGroups,
                integrationName,
                hasSearchTerm
              }}
            />
          )
        })
      )}
    </Fragment>

    <Div
      px={5}
      py={5}
      bg="white"
      display="row.flex-start.center"
      bt={1}
      bc="gray2"
      mt={3}
    >
      {activeTab === "not-imported" ? (
        <Div flex={1} color="gray7" fw={3}>
          {countOfItemsToBeMapped
            ? `${countOfItemsToBeMapped} item${addS(
                countOfItemsToBeMapped
              )} will be
          imported / mapped`
            : "No items selected to import"}
        </Div>
      ) : (
        <Div flex={1} />
      )}

      <BigOutlineButton onClick={saving ? null : onCancel} mr={2}>
        Cancel
      </BigOutlineButton>
      <BigFilledButton
        bg="altB5"
        onClick={saving ? null : onSave}
        disabled={
          activeTab === "not-imported"
            ? !countOfItemsToBeMapped || saving
            : saving
        }
      >
        {R.prop(activeTab, {
          "not-imported": "Import & Map Selected",
          "update-mapping": "Save Changes"
        })}
      </BigFilledButton>
    </Div>
  </StyleWrapper>
);

export default View;
