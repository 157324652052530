import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const Action = CSSModules(
  ({ children, ...props }) => (
    <div styleName="action" {...props}>
      {children}
    </div>
  ),
  css
);

export const ActionLink = CSSModules(
  ({ children, ...props }) => (
    <Link className={css.action} {...props}>
      {children}
    </Link>
  ),
  css
);

const actionsTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired
};
Action.propTypes = actionsTypes;
ActionLink.propTypes = actionsTypes;

export default Action;
