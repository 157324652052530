import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import AccountTypeIcon from "components/Global/CRM/AccountTypeIcon";

const AccountBadge = ({ name, type }) => (
  <div styleName="container">
    <div styleName="avatar">
      <AccountTypeIcon
        name={name}
        backgroundColor={type.background_color}
        color={type.text_color}
        icon={type.icon}
        size={31}
        fontSize={26}
      />
    </div>
    <div styleName="info">
      <div styleName="name">
        <div styleName="nameText">{name}</div>
      </div>
    </div>
  </div>
);

AccountBadge.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.shape({
    icon: PropTypes.string
  }).isRequired
};

export default CSSModules(AccountBadge, css);
