import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";

const Footer = ({ hideModal, onSubmit, processing, isValid }) => (
  <ButtonGroup className={css.container}>
    <ButtonOutline
      title="Cancel"
      onClick={processing ? undefined : hideModal}
    />
    <Submit
      title={processing ? "Saving..." : "Save Payment"}
      onClick={onSubmit}
      disabled={!isValid || processing}
    />
  </ButtonGroup>
);

Footer.propTypes = {
  processing: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired
};

export default CSSModules(Footer, css);
