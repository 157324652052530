import React, { useState } from "react";
import { connect } from "react-redux";
import { actions } from "Inventory/Dashboard";
import * as R from "ramda";
import {
  Div,
  DownFilledIcon,
  Text4,
  MediumFilledButton,
  DownloadIcon,
  AddIcon,
  collapsableHandler,
  Collapsable,
  TinyOutlineButton,
  Text0,
  Text1,
  Text2
} from "components/Base";
import {
  COLUMNS_SIZE,
  COLLAPSABLE_TYPE_TOTAL,
  COLLAPSABLE_TYPE_APPROVED
} from "Inventory/Dashboard/constants";
import AddPopover from "./AddPopover";
import ProgressChart from "Passes/Dashboard/SummaryPanel/ProgressChart";

const formatWidth = val =>
  val >= 1 ? "100%" : `${Math.round(Math.floor(val * 100))}%`;

const progressProps = ({ requested, approved, budget, issued, picked_up }) => {
  // default empty state
  const progressProps = {
    primary: {
      color: "#eae8ed",
      width: "100%"
    }
  };

  if (requested) {
    progressProps.primary.color = "#d1d0d3";
    progressProps.primary.title = "Requested";
  }
  if (approved) {
    progressProps.tertiary = {
      color: "#00c57c",
      width: formatWidth(approved / requested),
      title: "Approved"
    };
  }
  if (budget && budget > 0) {
    progressProps.primary.color = "#eae8ed";
    progressProps.primary.title = "Budget";
    progressProps.secondary = {
      color: "#d1d0d3",
      width: formatWidth(requested / budget),
      title: "Requested"
    };
  }
  if (budget && budget > 0 && approved) {
    progressProps.tertiary.width = formatWidth(approved / budget);
  }
  if (budget && budget > 0 && approved && approved > budget) {
    progressProps.tertiary = {
      width: formatWidth(1),
      color: "#8b0000",
      title: "Over budget"
    };
  }

  if (issued || picked_up) {
    // This bar is relative to the width of tertiary bar (approved).
    // However, the width should be expressed relative to the full bar width 100%.
    const denominator = budget && budget > 0 && approved ? budget : requested;
    progressProps.quaternary = {
      color: "#38578e",
      width: formatWidth(
        (approved / denominator) *
          (issued === "issued" ? issued / approved : picked_up / approved)
      ),
      title: issued === "issued" ? "Issued" : "Picked Up"
    };
  }

  return progressProps;
};

const decorate = connect(
  null,
  {
    downloadReport: actions.downloadReport,
    goToFilteredItem: actions.goToFilteredItem,
    goToFilteredCategory: actions.goToFilteredCategory
  }
);

const CategoryCollapsable = collapsableHandler(
  ({ toggleCollapsed, collapsed, category, downloadReport, handlers }) => {
    const [totalType, setTotalType] = useState(COLLAPSABLE_TYPE_TOTAL.PENDING);
    const [approvedType, setApprovedType] = useState(
      COLLAPSABLE_TYPE_APPROVED.APPROVED
    );

    return (
      <Div
        id={category.id}
        width={1}
        bg="white"
        bra={2}
        shadow={1}
        p={2}
        mb={2}
      >
        <Div
          width={1}
          // height={50}
          display="row.space-between.center"
          onClick={toggleCollapsed}
          mb={2}
        >
          <Div display="row.flex-start.center" height={1}>
            <DownFilledIcon
              color="black"
              size={24}
              style={{ transform: `rotate(${collapsed ? "-90" : "0"}deg)` }}
            />
            <Text4 color="black" bold>
              {category.name}
            </Text4>
          </Div>
          <Div display="row.flex-start.center">
            <MediumFilledButton
              bg="neutral0"
              color="neutral7"
              LeftIcon={DownloadIcon}
              onClick={ev => {
                ev.stopPropagation();
                downloadReport(category.export_url);
              }}
            >
              Download Report
            </MediumFilledButton>
            <MediumFilledButton
              bg="neutral0"
              color="neutral7"
              ml={1}
              onClick={() => handlers.goToCategory(category.id)}
            >
              Detail View
            </MediumFilledButton>
            <MediumFilledButton
              bg="neutral0"
              color="neutral7"
              LeftIcon={AddIcon}
              ml={1}
              onClick={ev => {
                ev.stopPropagation();
                handlers.showCreateOrderModal();
              }}
            >
              Add
            </MediumFilledButton>
          </Div>
        </Div>
        <Collapsable collapsed={collapsed}>
          <Div>
            <Div display="row.space-between.center" px={3}>
              <Div width={COLUMNS_SIZE.TYPE}>
                <Text0>TYPE</Text0>
              </Div>
              <Div width={COLUMNS_SIZE.PENDING}>
                <TinyOutlineButton
                  width={1}
                  onClick={() =>
                    setTotalType(
                      totalType === COLLAPSABLE_TYPE_TOTAL.PENDING
                        ? COLLAPSABLE_TYPE_TOTAL.TOTAL
                        : COLLAPSABLE_TYPE_TOTAL.PENDING
                    )
                  }
                >
                  {totalType}
                </TinyOutlineButton>
              </Div>
              <Div width={COLUMNS_SIZE.APPROVED}>
                <TinyOutlineButton
                  width={1}
                  onClick={() =>
                    setApprovedType(
                      approvedType === COLLAPSABLE_TYPE_APPROVED.APPROVED
                        ? COLLAPSABLE_TYPE_APPROVED.DENIED
                        : COLLAPSABLE_TYPE_APPROVED.APPROVED
                    )
                  }
                >
                  {approvedType}
                </TinyOutlineButton>
              </Div>
              <Div width={COLUMNS_SIZE.PLANNED} display="row.flex-end" pr={1}>
                <Text0>PLANNED</Text0>
              </Div>
              <Div width={COLUMNS_SIZE.REMAINNG} display="row.flex-end" mr={2}>
                <Text0>REMAING</Text0>
              </Div>
              <Div
                width={COLUMNS_SIZE.PROGRESS}
                display="row.flex-start"
                ml={3}
              >
                <Text0>PROGRESS</Text0>
              </Div>
            </Div>
            {R.map(
              item => (
                <Div
                  display="row.space-between.center"
                  p={3}
                  key={item.id}
                  className="hoverContainer"
                  bg={{ default: "white", hover: "neutral0" }}
                  bra={2}
                  transition="fast"
                >
                  <Div
                    width={COLUMNS_SIZE.TYPE}
                    style={{ position: "relative" }}
                    display="row.flex-start.center"
                  >
                    <Text2 bold color="black">
                      {item.name}
                    </Text2>
                    <Div
                      bra={3}
                      py={1}
                      px={3}
                      display="row.center.center"
                      style={{
                        position: "absolute",
                        right: 0,
                        cursor: "pointer"
                      }}
                      bg="white"
                      shadow={2}
                      className="showOnHover"
                      onClick={() => handlers.goToItem(item.item_id)}
                    >
                      <Text1 color="primary7" bold>
                        View Requests
                      </Text1>
                    </Div>
                  </Div>
                  <Div
                    width={COLUMNS_SIZE.PENDING}
                    display="row.flex-end"
                    pr={1}
                  >
                    <Text1 bold color="black">
                      {totalType === COLLAPSABLE_TYPE_TOTAL.PENDING
                        ? R.propOr("-", "count_of_pending", item)
                        : R.propOr("-", "total", item)}
                    </Text1>
                  </Div>
                  <Div
                    width={COLUMNS_SIZE.APPROVED}
                    display="row.flex-end"
                    pr={1}
                    ml={1}
                  >
                    <Text1>
                      {approvedType === COLLAPSABLE_TYPE_APPROVED.APPROVED
                        ? R.propOr("-", "count_of_approved", item)
                        : R.propOr("-", "count_of_denied", item)}
                    </Text1>
                  </Div>
                  <Div
                    width={COLUMNS_SIZE.PLANNED}
                    display="row.flex-end"
                    pr={1}
                    ml={1}
                  >
                    <AddPopover
                      title={item.name}
                      value={item.count_of_planned || 0}
                      id={item.id}
                      countOfPlanned={item.count_of_planned}
                    />
                  </Div>
                  <Div
                    width={COLUMNS_SIZE.REMAINNG}
                    display="row.flex-end"
                    pr={1}
                    mr={2}
                  >
                    <Text1>{item.count_of_remaining || "-"}</Text1>
                  </Div>
                  <Div
                    width={COLUMNS_SIZE.PROGRESS}
                    display="row.flex-start"
                    ml={3}
                  >
                    <ProgressChart
                      {...progressProps({
                        approved: item.count_of_approved,
                        budget: item.count_of_planned
                      })}
                    />
                  </Div>
                </Div>
              ),
              category.items
            )}
          </Div>
        </Collapsable>
      </Div>
    );
  }
);

export default decorate(CategoryCollapsable);
