import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getters } from "./model";
import { apiCall } from "App/Data/sagas";

const init = function*() {
  try {
    const eventId = yield select(getEventId);
    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/items/event/${eventId}/designs`
    });

    yield put(actions.setInitialData(payload));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred loading settings"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  try {
    const eventId = yield select(getEventId);
    const data = yield select(getters.data);

    const toSave = {
      designHtml: data.design_html,
      wrapperHtml: data.wrapper_html,
      settingsJson: data.settings_json
    };

    yield call(apiCall, {
      method: "put",
      url: `/items/event/${eventId}/designs`,
      data: toSave
    });

    yield put(showSnackbar({ message: "Settings Saved" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving settings"
        }
      ])
    );
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSave)]);
};

export default rootSaga;
