import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

const allNewsAndAlertsList = createModule(model);

allNewsAndAlertsList.setRootSaga(sagas);

export default allNewsAndAlertsList;
