import React from "react";
import {
  Div,
  HalfSizeDotIcon,
  MediumNumberSelector,
  SmallCheckbox,
  MediumOutlineButton,
  AddIcon,
  Text0
} from "components/Base";
import * as R from "ramda";
import PriceSelector from "Orders/CreateOrderModal/PriceSelector";

import { IntegrationIconsById } from "components/Event/Settings/Credentials/constants";

const MODE_SINGLE_SELECT = "single-select";
const MODE_MULTIPLE_SELECT = "multiple-select";
const MODE_QUANTITY = "quantity";
const DEFAULT_MODE = MODE_QUANTITY;

const View = ({
  itemGroups,
  enableToggle,
  mode = DEFAULT_MODE,
  quantityLabel = "How many?",
  maxWidth = 345,
  showPrices = true
}) => {
  return (
    <Div>
      {R.map(group => (
        <Div key={group.id} mb={5}>
          <Div>
            <Div
              uppercase
              fs={2}
              fw={3}
              color="gray6"
              display="row.flex-start.center"
              mx={3}
            >
              <Div flex={1} truncate>
                {group.name}
              </Div>
              {showPrices ? (
                <Div width={100} textAlign="right" mr={8}>
                  Price
                </Div>
              ) : null}

              <Div width={mode === MODE_QUANTITY ? 100 : 50} textAlign="right">
                {mode === MODE_QUANTITY ? quantityLabel : null}
              </Div>
            </Div>
            {group.selectAll && group.deselectAll ? (
              <Div display="row.flex-start.center" mt={1} mx={3}>
                <Text0 color="primary9" onClick={group.selectAll} uppercase>
                  Select All
                </Text0>
                <Text0 mx={1} color="primary9">
                  -
                </Text0>
                <Text0 color="primary9" onClick={group.deselectAll} uppercase>
                  Deselect All
                </Text0>
              </Div>
            ) : null}
          </Div>
          {R.map(item => (
            <Div
              key={item.id}
              shadow={1}
              bra={1}
              bg="white"
              my={2}
              px={3}
              py={2}
              display="row.flex-start.center"
              style={{
                minHeight: 48
              }}
            >
              <Div display="row.flex-start.center" flex={1}>
                {enableToggle ? (
                  <SmallCheckbox
                    selected={item.selected}
                    onClick={item.toggleVariant}
                    mr={3}
                  />
                ) : (
                  <HalfSizeDotIcon
                    style={{
                      color: item.color || "gray"
                    }}
                    mr={3}
                  />
                )}

                <Div display="column.center.flex-start" flex={1}>
                  <Div display="row.flex-start.center">
                    {item.providerId
                      ? IntegrationIconsById[item.providerId]
                      : null}
                    <Div
                      color="gray7"
                      fw={3}
                      fs={4}
                      width={1}
                      truncate
                      title={item.name}
                      maxWidth={maxWidth}
                      noLineHeight
                    >
                      {item.name}
                    </Div>
                  </Div>
                  {item.description && item.description.length ? (
                    <Div color="gray6" fs={1} maxWidth={maxWidth} noLineHeight>
                      {item.description}
                    </Div>
                  ) : null}
                </Div>
                {showPrices && !item.showItemDetailsModal ? (
                  <Div width={100} display="row.flex-end" mr={8}>
                    {item.prices && item.prices.length ? (
                      <PriceSelector
                        prices={item.prices}
                        selectedPriceId={item.selectedPriceId}
                        onChange={item.updateVariantPriceId}
                      />
                    ) : null}
                  </Div>
                ) : null}
                <Div width={mode === MODE_QUANTITY ? 100 : 50}>
                  {(enableToggle && item.selected) || !enableToggle
                    ? R.prop(mode, {
                        [MODE_QUANTITY]: item.showItemDetailsModal ? (
                          <MediumOutlineButton
                            type="button"
                            OnlyIcon={AddIcon}
                            onClick={item.showItemDetailsModal}
                            color="primary8"
                            bg="white"
                            width={100}
                          />
                        ) : (
                          <MediumNumberSelector
                            value={item.qty}
                            onChangeValue={item.updateVariantQuantity}
                          />
                        ),
                        [MODE_SINGLE_SELECT]: (
                          <SmallCheckbox
                            selected={Boolean(item.qty)}
                            onClick={item.updateVariantQuantity}
                          />
                        ),
                        [MODE_MULTIPLE_SELECT]: (
                          <SmallCheckbox
                            selected={Boolean(item.qty)}
                            onClick={item.updateVariantQuantity}
                          />
                        )
                      })
                    : null}
                </Div>
              </Div>
            </Div>
          ))(group.items)}
        </Div>
      ))(itemGroups)}
    </Div>
  );
};

export default View;
