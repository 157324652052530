import React, { Component } from "react";
import { Link } from "react-router";
import { Div } from "components/Base";
import { isEqual, get } from "lodash";
import PillButton from "components/Global/CRM/PillButton";

class AccountNameFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { dependentValues, value } = this.props;
    const suppressLinkToRecord = get(
      dependentValues,
      "meta.columnSettings.suppressLinkToRecord"
    );
    const big = get(dependentValues, "meta.columnSettings.big");
    const showButton = get(dependentValues, "meta.showButton");
    const path = dependentValues.meta.orgId
      ? `/organization/${dependentValues.meta.orgId}/account/${dependentValues.meta.rowId}`
      : `/event/${dependentValues.meta.eventId}/account/${dependentValues.meta.rowId}`;

    return (
      <Div
        width={1}
        className="hoverContainer"
        style={{
          position: "relative"
        }}
      >
        {!suppressLinkToRecord ? (
          <Link to={path}>
            <Div fs={big ? 4 : 3} fw={3} color="purple8">
              {get(value, "value")}
            </Div>
          </Link>
        ) : (
          <Div fs={big ? 4 : 3} fw={3} color="black">
            {get(value, "value")}
          </Div>
        )}
        {showButton ? (
          <Div
            className="showOnHover"
            style={{
              position: "absolute",
              top: 10,
              right: 3,
              top: "50%",
              transform: "translateY(-50%)"
            }}
          >
            <PillButton title="View Group" linkTo={path} />
          </Div>
        ) : null}
      </Div>
    );
  }
}

export default AccountNameFormatter;
