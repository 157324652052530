import React from "react";
import {
  Div,
  MediumAvatar,
  Text3,
  Text2,
  Text1,
  MediumFilledButton,
  EditIcon,
  PopoverMenu,
  DownFilledIcon
} from "components/Base";

const Event = ({
  can_view,
  is_draft,
  name,
  date,
  label,
  editEvent,
  color,
  id,
  cloneEvent,
  deleteEvent,
  viewEvent
}) => (
  <Div
    bg={can_view ? { default: "white", hover: "neutral0" } : "white"}
    transition="fast"
    p={2}
    display="row.space-between.center"
    bra={1}
    mb={1}
    style={{ position: "relative", overflow: "hidden" }}
    className="hoverContainer"
  >
    {can_view ? (
      <Div
        height={1}
        bg="neutral0"
        style={{ position: "absolute", right: 0, top: 0 }}
        display="row.flex-end.center"
        px={2}
        className="showOnHover"
      >
        <MediumFilledButton
          bg="white"
          color="black"
          mr={2}
          //LeftIcon={EditIcon}
          onClick={() => viewEvent({ id, isDraft: is_draft })}
        >
          {is_draft ? "Setup" : "View"}
        </MediumFilledButton>
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumFilledButton
              bg="white"
              color="black"
              LeftIcon={DownFilledIcon}
              onClick={onClick}
            >
              Actions
            </MediumFilledButton>
          )}
          menuItems={[
            ["Edit", () => editEvent({ id, isDraft: is_draft })],
            is_draft ? null : ["Clone", () => cloneEvent(id)],
            ["Delete", () => deleteEvent(id)]
          ].filter(v => v)}
        />
      </Div>
    ) : null}
    <Div display="row.flex-start.center">
      <MediumAvatar bg={color} text={name} square longInitials />
      <Div ml={2}>
        <Text3 color="black" bold>
          {name}
        </Text3>
        <Text1 color="neutral5">{date}</Text1>
      </Div>
    </Div>
    <Text2 color="black" bold>
      {label}
    </Text2>
  </Div>
);

export default Event;
