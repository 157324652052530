import React from "react";
import { connect } from "react-redux";

import { getters } from "EventLight/Common/Manage";
import { getInventoryGroups } from "EventLight/Common/Manage/selectors";
import { INVENTORY_FILTER_ID } from "Passes/Common/constants";

import { ItemFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  itemGroups: getInventoryGroups(state, props),
  iniSelected: getters.selectedInventoryItems(state, props)
}));

const Inventory = ({ itemGroups = [], iniSelected = [] }) => (
  <ItemFilter
    mt={2}
    collapsedDefault
    instanceId={INVENTORY_FILTER_ID}
    itemGroups={itemGroups}
    modalTitle="Select Items to show"
    iniSelected={iniSelected}
    selectedText="Items"
  >
    <Text2 bold>Items</Text2>
  </ItemFilter>
);

export default decorate(Inventory);
