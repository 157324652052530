export const NAMESPACE = "Datagrid";

export const TEMP_ID = `${NAMESPACE}/@@tempId`;

export const EMPTY_CELL = {};

export const MODES = {
  PREVIEW: "preview",
  EDIT: "edit",
  SUBMISSION_MODAL: "submission_modal",
  SUBMISSION: "submission"
};

export const COLUMN_CONTEXT_MENU_ID = "column-context-menu-id";

export const BUTTONS = {
  REVIEW: {
    id: "review"
  },
  ADD_ROW: {
    id: "addRow",
    label: "Add Row"
  },
  DUPLICATE_ROW: {
    id: "duplicateRow"
  },
  REMOVE_ROW: {
    id: "removeRow",
    prompt: "Are you sure you want to remove the rows?",
    confirm: true
  },
  IMPORT: {
    id: "import"
  },
  SAVE: {
    id: "save"
  }
};
