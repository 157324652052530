import * as R from "ramda";
import {
  put,
  select,
  call,
  takeEvery,
  take,
  all,
  fork
} from "redux-saga/effects";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";

import { debounce } from "utils/General/sagas";

import {
  getters as TableGetters,
  actions as TableActions
} from "ui-kit/Datagrid";
import { MODES } from "ui-kit/Datagrid/constants";
import * as TableSelectors from "ui-kit/Datagrid/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import api from "Submission/Subform/api";
import { getSectionByFieldId } from "Submission/Subform/selectors";
import { getters } from "Submission/Subform";

import { createTempIds, getNow } from "utils/General";

const duplicateRow = function*({ meta: actionMeta }) {
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });
  const tableProps = { instanceId: actionMeta.instanceId };
  let selectedRowIds = yield select(
    TableSelectors.getSelectedRowIds,
    tableProps
  );
  selectedRowIds = selectedRowIds.filter(id => id);

  if (R.isEmpty(selectedRowIds)) {
    yield put(TableActions.setLoading(false, { meta: actionMeta }));
  } else {
    const tempIds = yield call(createTempIds, selectedRowIds);

    const transactionId = yield call([uuid, uuid.v4]);
    const credentials = yield select(getCredentials);

    yield put(
      TableActions.duplicateRow(tempIds, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const meta = yield select(TableGetters.meta, tableProps);
    const data = {
      formId: meta.form.id,
      subformId: meta.field.id,
      submissionIds: R.keys(tempIds),
      quantity: 1
    };
    try {
      const result = yield call(api.clone, credentials, data);
      yield put(
        TableActions.duplicateRowResponse(
          { submissions: result.submissions, tempIds, mode },
          {
            optimist: { type: COMMIT, id: transactionId },
            meta: actionMeta
          }
        )
      );
    } catch (error) {
      yield put(
        TableActions.duplicateRowResponse(
          { error: error.error || "Could not duplicate the rows." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const watchDuplicate = function*() {
  yield takeEvery(TableActions.duplicateRowRequest.type, duplicateRow);
};

const removeRow = function*({ meta: actionMeta }) {
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });
  const tableProps = { instanceId: actionMeta.instanceId };
  let selectedRowIds = yield select(
    TableSelectors.getSelectedRowIds,
    tableProps
  );
  selectedRowIds = selectedRowIds.filter(id => id);

  if (R.isEmpty(selectedRowIds)) {
    yield put(TableActions.setLoading(false, { meta: actionMeta }));
  } else {
    const transactionId = yield call([uuid, uuid.v4]);
    const credentials = yield select(getCredentials);

    yield put(
      TableActions.removeRow(null, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const meta = yield select(TableGetters.meta, tableProps);
    const data = {
      formId: meta.form.id,
      subformId: meta.field.id,
      submissionIds: selectedRowIds,
      bulk: true
    };

    try {
      yield call(api.deleteSubmission, credentials, data);
      yield put(
        TableActions.removeRowResponse(null, {
          optimist: { type: COMMIT, id: transactionId },
          payload: {
            selectedRowIds,
            mode
          },
          meta: actionMeta
        })
      );
    } catch (error) {
      yield put(
        TableActions.removeRowResponse(
          { error: error.error || "Could not delete the rows." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const watchRemove = function*() {
  for (;;) {
    const action = yield take(TableActions.removeRowRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(removeRow, action);
    }
  }
};

const addRow = function*({ meta: actionMeta }) {
  const tableProps = { instanceId: actionMeta.instanceId };
  const meta = yield select(TableGetters.meta, tableProps);
  const version = meta.version;
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });

  const transactionId = yield call([uuid, uuid.v4]);
  const credentials = yield select(getCredentials);

  yield put(
    TableActions.addRow(transactionId, {
      optimist: { type: BEGIN, id: transactionId },
      meta: actionMeta
    })
  );

  const submissionData = {
    version,
    formId: R.pathOr("", ["field", "subform", "form_id"], meta),
    subformId: R.pathOr("", ["field", "subform", "field_id"], meta),
    parentSubmissionId: meta.submission.id,
    addCollaborator: false,
    isSubformSubmission: ![MODES.EDIT, MODES.PREVIEW].includes(mode),
    isDefault: [MODES.EDIT, MODES.PREVIEW].includes(mode)
  };

  try {
    const data = {
      moduleId: R.pathOr(
        "",
        ["field", "subform", "form", "base_module_id"],
        meta
      ),
      record: {
        isDraft: [MODES.SUBMISSION].includes(mode),
        [R.pathOr("", ["field", "module_field_id"], meta)]: {
          type: "lookup",
          value: {
            moduleId: R.pathOr("", ["form", "base_module_id"], meta),
            records: [R.path(["submission", "submission_record_id"], meta)]
          }
        }
      },
      options: {
        eventId: meta.eventDetails.id
      }
    };
    const response = yield call(api.addRecord, credentials, data);
    const rowsResult = yield call(api.createSubformSubmission, credentials, {
      ...submissionData,
      submissionRecordId: R.pathOr("", ["record", "id"], response)
    });

    yield put(
      TableActions.addRowResponse(
        { submission: rowsResult.submission, tId: transactionId, mode },
        {
          optimist: { type: COMMIT, id: transactionId },
          meta: actionMeta
        }
      )
    );
  } catch (error) {
    yield put(
      TableActions.addRowResponse(
        { error: error.error || "Could not create the rows." },
        {
          optimist: { type: REVERT, id: transactionId },
          meta: actionMeta
        },
        true
      )
    );
  }
};

const watchAdd = function*() {
  for (;;) {
    const action = yield take(TableActions.addRowRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(addRow, action);
    }
  }
};

const updateCell = function*({ payload, meta: actionMeta }) {
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });

  const tableProps = { instanceId: actionMeta.instanceId };
  const {
    fromRowData: row,
    fromRowId: rowId,
    cellKey,
    updated,
    action
  } = payload;

  const isTemporary = yield select(TableSelectors.isTemporaryRow, {
    ...tableProps,
    rowId
  });

  if (!isTemporary) {
    // prevent updating a temporary row
    const meta = yield select(TableGetters.meta, tableProps);
    const version = meta.formVersion;

    const transactionId = yield call([uuid, uuid.v4]);
    const credentials = yield select(getCredentials);

    // currently bailing if trying to drag or copy + paste values
    if (!["CELL_DRAG", "COPY_PASTE"].includes(action)) {
      yield put(
        TableActions.updateCell(payload, {
          optimist: { type: BEGIN, id: transactionId },
          meta: actionMeta
        })
      );

      const moduleFieldId = R.pathOr(
        "",
        ["columns", cellKey, "module_field_id"],
        meta
      );

      const submissionRecordId = R.propOr("", "submission_record_id", row);

      const data = {
        version,
        submissionRecordId,
        moduleFieldId,
        subformFieldId: meta.fieldId,
        submissionId: row.id,
        fieldId: cellKey,
        value: updated[cellKey],
        userId: meta.user.id,
        parentSubmissionId: meta.submissionId
      };

      try {
        yield call(api.addSubformValue, credentials, data);
        yield put(
          TableActions.updateCellResponse(
            {
              submissionId: row.id,
              fieldId: cellKey,
              value: updated[cellKey],
              mode
            },
            {
              optimist: { type: COMMIT, id: transactionId },

              meta: actionMeta
            }
          )
        );
      } catch (error) {
        yield put(
          TableActions.updateCellResponse(
            { error: error.error || "Could not update the cell." },
            {
              optimist: { type: REVERT, id: transactionId },
              meta: actionMeta
            },
            true
          )
        );
      }
    }
  }
  return false;
};

const watchUpdateCell = function*() {
  for (;;) {
    const action = yield take(TableActions.updateCellRequest.type);
    const tableIds = yield select(getters.tableIds);

    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(updateCell, action);
    }
  }
};

const makeReview = function*({ rowId, response, meta, actionMeta }) {
  const credentials = yield select(getCredentials);
  const tableProps = { instanceId: actionMeta.instanceId };
  const row = yield select(TableSelectors.getRow, { ...tableProps, rowId });

  const data = {
    review: {
      // record
      moduleId: R.pathOr("", ["field", "settings", "moduleId"], meta),
      recordId: R.prop("submission_record_id", row),
      userId: R.path(["user", "id"], meta),
      // submission
      rowId,
      submissionRecordId: R.prop("submission_record_id", row),
      subformId: R.path(["field", "subform", "field_id"], meta),
      submissionId: R.path(["submission", "id"], meta),
      response
    }
  };

  try {
    if (response) {
      const result = yield call(api.review, credentials, data);
      return { rowId, response: result.payload };
    } else {
      yield call(api.deleteReview, credentials, data);
      return {
        rowId,
        response: { response, user_id: data.review.userId, updated_at: null }
      };
    }
  } catch (error) {
    return { error: error.error || "An error occurred adding review." };
  }
};

const watchMakeReview = function*() {
  for (;;) {
    const { payload, meta: actionMeta } = yield take(
      TableActions.makeReviewRequest.type
    );
    const tableProps = { instanceId: actionMeta.instanceId };
    const meta = yield select(TableGetters.meta, tableProps);
    const credentials = yield select(getCredentials);

    const transactionId = yield call([uuid, uuid.v4]);

    const now = yield call(getNow);

    yield put(
      TableActions.makeReview(
        {
          rowId: payload.rowId,
          response: {
            response: payload.response,
            user_id: credentials.userId,
            updated_at: now.format()
          }
        },
        {
          optimist: { type: BEGIN, id: transactionId },
          meta: actionMeta
        }
      )
    );

    const review = yield call(makeReview, {
      ...payload,
      meta,
      credentials,
      actionMeta
    });

    yield put(
      TableActions.makeReviewResponse(
        review.error
          ? {
              error: review.error || "An error occurred adding review."
            }
          : review,
        {
          optimist: { type: review.error ? REVERT : COMMIT, id: transactionId },
          meta: actionMeta
        },
        !R.isNil(review.error)
      )
    );
  }
};

const reviewRemaining = function*({ payload: { response }, meta: actionMeta }) {
  const tableProps = { instanceId: actionMeta.instanceId };
  const pendingIds = yield select(TableSelectors.getPendingRowIds, tableProps);
  if (R.isEmpty(pendingIds)) {
    yield put(TableActions.setLoading(false, { meta: actionMeta }));
  } else {
    const meta = yield select(TableGetters.meta, tableProps);

    const reviews = yield all(
      R.map(
        id => call(makeReview, { rowId: id, response, meta, actionMeta }),
        pendingIds
      )
    );

    yield put(
      TableActions.reviewRemainingResponse(reviews, { meta: actionMeta })
    );
  }
};

const watchReviewRemaining = function*() {
  yield takeEvery(TableActions.reviewRemaining.type, reviewRemaining);
};

const resizeColumn = function*({ payload, meta: actionMeta }) {
  const tableIds = yield select(getters.tableIds);

  if (R.contains(actionMeta.instanceId, tableIds)) {
    const credentials = yield select(getCredentials);

    const meta = yield select(TableGetters.meta, {
      instanceId: actionMeta.instanceId
    });
    const mode = yield select(TableGetters.mode, {
      instanceId: actionMeta.instanceId
    });

    const transactionId = yield call([uuid, uuid.v4]);
    yield put(
      TableActions.columnResize(payload, {
        meta: actionMeta,
        optimist: { type: BEGIN, id: transactionId }
      })
    );

    const section = yield select(getSectionByFieldId, {
      instanceId: actionMeta.instanceId,
      fieldId: payload.id
    });

    let data;

    if (mode === MODES.PREVIEW || mode === MODES.EDIT) {
      const field =
        R.find(
          R.compose(
            R.equals(payload.id),
            R.prop("id")
          ),
          section.fields || []
        ) || {};

      data = {
        subformId: R.path(["field", "subform", "field_id"], meta),
        fieldId: payload.id,
        sectionId: section.id,
        settings: {
          ...field.settings,
          width: payload.width < 50 ? 50 : payload.width
        }
      };
    } else {
      data = {
        subformId: R.path(["field", "subform", "field_id"], meta),
        formId: R.path(["field", "subform", "form_id"], meta),
        fieldId: payload.id,
        width: payload.width < 50 ? 50 : payload.width
      };
    }

    try {
      const method =
        mode === MODES.PREVIEW || mode === MODES.EDIT
          ? api.updateSubformFieldWidth
          : api.updateFieldWidth;

      const { success } = yield call(method, credentials, data);
      if (success) {
        yield put(
          TableActions.columnResizeResponse(null, {
            meta: actionMeta,
            optimist: { type: COMMIT, id: transactionId }
          })
        );
      } else {
        yield put(
          TableActions.columnResizeResponse(null, {
            meta: actionMeta,
            optimist: { type: REVERT, id: transactionId }
          })
        );
      }
    } catch (e) {
      yield put(
        TableActions.columnResizeResponse(null, {
          meta: actionMeta,
          optimist: { type: REVERT, id: transactionId }
        })
      );
    }
  }
};

const watchColumnResize = debounce(
  TableActions.columnResizeRequest.type,
  resizeColumn,
  500
);

const rootSaga = function*() {
  yield all([
    fork(watchDuplicate),
    fork(watchRemove),
    fork(watchAdd),
    fork(watchUpdateCell),
    fork(watchMakeReview),
    fork(watchReviewRemaining),
    fork(watchColumnResize)
  ]);
};

export default rootSaga;
