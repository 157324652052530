import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import IconMenu from "material-ui/IconMenu";
import FileIcon from "components/Atoms/FileIcon";
import { DownloadIcon } from "components/Base";
import * as R from "ramda";

const Status = CSSModules(({ status, isCurrent }) => {
  if (status === "Approved") {
    return <div styleName="statusApproved">approved</div>;
  }
  if (status === "Denied") {
    return <div styleName="statusDenied">denied</div>;
  }
  if (isCurrent) {
    return <div styleName="status">current</div>;
  }
  return null;
}, css);

const FileCard = ({
  files,
  showFileViewer,
  showStatus,
  status,
  isCurrent,
  onRevisionMenuChange,
  menuOptions
}) => {
  const fileUrl = R.path([0, "url"])(files);

  return (
    <div styleName={!isCurrent ? "documentCardPrevious" : "documentCard"}>
      <div styleName="info">
        <div styleName="name">
          {files.map(file => (
            <div styleName="file" onClick={() => showFileViewer(file, files)}>
              <div styleName="fileIcon">
                <FileIcon size={14} mimetype={file.mimetype} />
              </div>
              <span>{file.filename}</span>
            </div>
          ))}
        </div>
        <div styleName="separator" />
        {showStatus ? <Status isCurrent={isCurrent} status={status} /> : null}
      </div>
      <div styleName="options">
        {fileUrl ? (
          <div
            onClick={() => {
              window.open(fileUrl, "_blank");
            }}
          >
            <DownloadIcon
              tooltip="Download File"
              color={{
                default: "#b3b3b3",
                hover: "#ccc"
              }}
            />
          </div>
        ) : null}
        {menuOptions && menuOptions.length ? (
          <IconMenu
            onChange={onRevisionMenuChange}
            iconButtonElement={
              <i className={[css.icon, "material-icons"].join(" ")}>
                more_vert
              </i>
            }
          >
            {menuOptions}
          </IconMenu>
        ) : null}
      </div>
    </div>
  );
};

FileCard.propTypes = {
  files: PropTypes.array.isRequired,
  showFileViewer: PropTypes.func.isRequired,
  showStatus: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  onRevisionMenuChange: PropTypes.bool.isRequired,
  menuOptions: PropTypes.array.isRequired
};

export default CSSModules(FileCard, css);
