import React from "react";

import { Div, Text1, CheckIcon, CloseIcon } from "components/Base";

import { noop } from "utils/General";

const PassInfo = ({ pass, onRemove = noop, ...styleProps }) => (
  <Div display="row.flex-start.center" py={1} flex={1} {...styleProps}>
    <Text1 color="neutral4" style={{ minWidth: 15 }}>
      {pass.count || ""}
    </Text1>
    <Text1 color="black" ml={3}>
      {pass.name}
    </Text1>
    {(pass.issued === true && ( // may be undefined
      <Div display="row.center.center" ml={2}>
        <CheckIcon color="neutral4" />
        <Text1 color="neutral6" ml={1}>
          Issued
        </Text1>
      </Div>
    )) ||
      null}
    {(onRemove && onRemove !== noop && !pass.issued && (
      <Div
        bg="neutral1"
        bra={1}
        display="row.center.center"
        ml={2}
        style={{ cursor: "pointer" }}
        onClick={() => onRemove(pass.id)}
      >
        <CloseIcon color="neutral6" />
      </Div>
    )) ||
      null}
  </Div>
);

export default PassInfo;
