import * as R from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { noop } from "utils/General";

import { actions } from "Sponsors/SelfSetup";
import { STEPS } from "Sponsors/SelfSetup/constants";
import {
  getIsEventShowcasePublished,
  getEventShowcaseUrl,
  getEventId
} from "Sponsors/SelfSetup/selectors";

import { Div, MediumToggle } from "components/Base";
import { PageTitle } from "Sponsors/Common/View";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    state => ({
      eventId: getEventId(state),
      eventShowcaseUrl: getEventShowcaseUrl(state),
      isEventShowcasePublished: getIsEventShowcasePublished(state)
    }),
    {
      init: actions.init,
      toggleEventShowcasePublished: value =>
        actions.updateEventShowcaseDetails({ fieldId: "isPublished", value })
    }
  )
);

const FinishedPage = ({
  init = noop,
  Th = defaultTh,
  eventShowcaseUrl,
  isEventShowcasePublished = false,
  toggleEventShowcasePublished = noop,
  eventId,
  ...styleProps
}) => {
  const inputRef = useRef(null);

  const onClickInput = event => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, event.target.value.length);
    }
  };

  useEffect(() => {
    init(STEPS.FINISHED);
  }, []);

  const [copied, setCopied] = useState(false);

  const onCopyLink = () => {
    // is element selectable?
    if (inputRef.current && inputRef.current.select) {
      inputRef.current.select();

      try {
        document.execCommand("copy");
        inputRef.current.blur();
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (err) {
        alert("Please press Ctrl/Cmd+C to copy."); // eslint-disable-line no-alert
      }
    }
  };

  return (
    <Div
      {...Th([], {
        display: "column",
        ...styleProps
      })}
    >
      <PageTitle>Great! You’re all done!</PageTitle>

      <Div mt={3} px={6} py={6} bg="neutral0" bra={1}>
        <Div {...Th(["color"], { mb: 3, fs: 4, fw: 3 })}>
          Share your Sales Portal
        </Div>
        <Div display="row.flex-start.center" mb={5}>
          <input
            style={{
              display: "flex",
              width: "570px",
              background: "none",
              border: "1px solid #C9C9C9",
              borderRight: 0,
              outline: "none",
              fontSize: 18,
              color: "#494949",
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              height: 43,
              padding: "0 15px",
              borderRadius: "7px 0 0 7px"
            }}
            ref={inputRef}
            value={eventShowcaseUrl}
            onChange={() => {}}
            onClick={onClickInput}
          />
          <Div
            uppercase
            fw={3}
            bg={{
              default: "purple8",
              hover: "purple9"
            }}
            color="white"
            onClick={onCopyLink}
            mr={3}
            display="row.center.center"
            px={3}
            style={{
              borderRadius: "0 7px 7px 0",
              letterSpacing: "1px"
            }}
            fs={2}
            height={43}
          >
            {copied ? "Copied" : "Copy"}
          </Div>
        </Div>

        {/*
        <Div display="row.flex-start.center">
          <MediumToggle
            active={isEventShowcasePublished}
            onClick={() =>
              toggleEventShowcasePublished(!isEventShowcasePublished)
            }
          />
          {isEventShowcasePublished ? (
            <Div display="column" ml={2}>
              <Div {...Th(["color"], { fw: 3 })}>Public</Div>
              <Div {...Th(["color"], { fs: 1 })}>Visible to the public</Div>
            </Div>
          ) : (
            <Div display="column" ml={2}>
              <Div {...Th(["color"], { fw: 3 })}>Private</Div>
              <Div {...Th(["color"], { fs: 1 })}>
                Only visible to your event team
              </Div>
            </Div>
          )}
        </Div>
          */}
      </Div>

      <Div mt={5}>
        <Div fs={4} fw={3} mb={2} color="gray7">
          Next Steps:
        </Div>
        <Link to={`/event/${eventId}/settings/orders/payments`}>
          <Div mb={1} underline color="primary8" onClick={() => {}}>
            Set up collecting payments
          </Div>
        </Link>
        <Link
          to={`/event/${eventId}/module/${STANDARD_MODULE_IDS.documentRequests.id}`}
        >
          <Div underline color="primary8" onClick={() => {}}>
            Configure what documents to collect
          </Div>
        </Link>
      </Div>
    </Div>
  );
};

export default decorate(FinishedPage);
