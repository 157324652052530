import React, { Component } from "react";
import Modal from "components/Global/Modal";
import PlatformLoading from "components/Platform/PlatformLoading";
import EnvironmentTag from "components/Platform/EnvironmentTag";
import Snackbar from "components/Global/Snackbar";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class PortalUserView extends Component {
  showModal = modal => {
    this.props.showModal(modal.content ? modal : { content: modal });
  };

  hideModal = () => {
    this.props.hideModal();
  };

  showSnackbar = options => {
    this.props.showSnackbar({
      key: Date.now(),
      ...options
    });
  };

  render() {
    if (!this.props.user.id) {
      return <PlatformLoading />;
    }

    return (
      <div styleName="container">
        <div style={{ height: "100%" }}>
          {React.cloneElement(this.props.children, {
            showModal: this.showModal,
            hideModal: this.hideModal,
            showSnackbar: this.showSnackbar
          })}
          <Modal params={this.props.params} router={this.props.router} />

          <Snackbar />
        </div>
        <EnvironmentTag />
      </div>
    );
  }
}

export default PortalUserView;
