import React, { Component } from "react";
import * as R from "ramda";
import { isEqual } from "lodash";
import View from "./View";
import Overview from "./Scenes/Overview";
import Items from "./Scenes/Items";

const buildExistingItemState = (item, variantId, clone) => {
  return {
    color: item.background_color || "",
    description: item.description,
    code: item.code,
    groupId: item.group_id,
    parentZoneId: item.parent_zone_id,
    name: clone ? `Copy of ${item.name}` : item.name || "",
    variants: item.variants || []
  };
};

class InternalHandler extends Component {
  constructor(props) {
    super(props);
    const activeTab = "overview";

    this.modified = false;

    if (props.mode === "create") {
      // state: create
      this.state = {
        activeTab,
        color: "",
        description: "",
        code: "",
        groupId: null,
        parentZoneId: null,
        loading: false,
        saving: false,
        name: "",
        variants: []
      };
    } else {
      // state: update or clone
      this.state = {
        activeTab,
        loading: true,
        saving: false,
        ...buildExistingItemState(props.item, props.variantId, props.clone)
      };
    }
  }

  async componentDidMount() {
    const { mode } = this.props;
    if (["update", "clone"].includes(mode)) {
      await this.props.fetchItem();
      this.setState({ loading: false });
    }
    this.props.getItemGroups();
    this.props.getPassTypes();
  }

  componentDidUpdate(oldProps) {
    if (!isEqual(this.props.item, oldProps.item)) {
      this.setState({
        ...buildExistingItemState(
          this.props.item,
          this.props.variantId,
          this.props.clone
        )
      });
    }
  }

  componentWillUnmount() {
    if (this.modified && this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  setTab = tab => this.setState({ activeTab: tab });

  handleFieldChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  canSave = () =>
    R.length(R.propOr("", "name", this.state)) &&
    R.length(R.propOr("", "code", this.state)) &&
    this.state.groupId;

  handleSave = () => {
    this.setState({ saving: true });

    this.modified = false;

    this.props.saveItem({
      zone: {
        id:
          this.props.item && this.props.mode !== "clone"
            ? this.props.item.id
            : null,
        name: this.state.name,
        description: this.state.description,
        code: this.state.code,
        groupId: this.state.groupId,
        parentZoneId: this.state.parentZoneId,
        backgroundColor:
          this.state.color && this.state.color.length ? this.state.color : null,
        variants: this.state.variants
      }
    });
    this.setState({ saving: false });
  };

  disableGroupItems = groupId => {
    const variantIds = R.compose(
      R.reduce((list, group) => {
        const variants = group.items.reduce(
          (v, i) => [...v, ...i.variants],
          []
        );

        return [...list, ...variants.map(v => v.id)];
      }, []),
      R.filter(group => group.id === groupId)
    )(this.props.itemGroups);

    this.setState({
      variants: R.filter(
        ({ id }) => R.all(variantId => variantId !== id, variantIds),
        this.state.variants
      )
    });
  };

  enableGroupItems = groupId => {
    const variantIds = R.compose(
      R.reduce((list, group) => {
        const variants = group.items.reduce(
          (v, i) => [...v, ...i.variants],
          []
        );

        return [...list, ...variants.map(v => ({ id: v.id }))];
      }, []),
      R.filter(group => group.id === groupId)
    )(this.props.itemGroups);

    this.setState({
      variants: R.compose(
        R.concat(variantIds),
        R.filter(({ id }) => R.all(v => v.id !== id, variantIds))
      )(this.state.variants)
    });
  };

  toggleVariant = (variantId, toggle) => {
    this.setState(state => {
      if (toggle) {
        state.variants.push({ id: variantId });
      } else {
        state.variants = state.variants.filter(({ id }) => id !== variantId);
      }
      return state;
    });
  };

  renderScene = key => {
    switch (key) {
      case "items":
        const itemGroups = R.compose(
          R.reduce((list, group) => {
            const variants = R.compose(
              R.reduce((v, i) => [...v, ...i.variants], []),
              R.sortBy(R.prop("order"))
            )(group.items);

            return [
              ...list,
              {
                ...group,
                items: variants.map(v => {
                  const selected = this.state.variants.some(
                    variant => variant.id === v.id
                  );
                  return {
                    ...v,
                    name: v.display_name,
                    selected,
                    onToggle: () => this.toggleVariant(v.id, !selected)
                  };
                })
              }
            ];
          }, []),
          R.sortBy(R.prop("order"))
        )(this.props.itemGroups);

        return (
          <Items
            itemGroups={itemGroups}
            itemsSelectedLength={R.length(this.state.variants)}
            disableGroupItems={this.disableGroupItems}
            enableGroupItems={this.enableGroupItems}
          />
        );
      case "overview":
      default: {
        const {
          color,
          description,
          code,
          groupId,
          parentZoneId,
          name
        } = this.state;

        return (
          <Overview
            {...{
              itemName: this.props.item ? this.props.item.name : null,
              activeGroupId: groupId,
              parentZoneId: parentZoneId,
              color,
              description,
              code,
              groups: this.props.groups.map(({ id, name }) => ({
                label: name,
                value: id
              })),
              zones: R.compose(
                R.sortBy(z => z.label.toLowerCase()),
                R.reduce((list, group) => {
                  return [
                    ...list,
                    ...group.zones.map(({ id, name }) => ({
                      label: name,
                      value: id
                    }))
                  ];
                }, [])
              )(this.props.groups),
              name,
              removeUrl: () => this.setState({ photoUrl: null }),
              onNameChange: value =>
                this.handleFieldChange({ name: "name", value }),
              onDescriptionChange: value =>
                this.handleFieldChange({ name: "description", value }),
              onCodeChange: value =>
                this.handleFieldChange({ name: "code", value }),
              onGroupChange: ({ value }) =>
                this.handleFieldChange({ name: "groupId", value }),
              onParentZoneChange: opt =>
                this.handleFieldChange({
                  name: "parentZoneId",
                  value: opt ? opt.value : null
                }),
              onSelectColor: value =>
                this.handleFieldChange({ name: "color", value })
            }}
          />
        );
      }
    }
  };

  updateVariant = async data => {
    this.setState({ saving: true });
    await this.props.updateVariant(data);
    this.props.fetchItem();
    this.modified = true;
    this.setState({ saving: false });
  };

  deleteVariant = async variantId => {
    this.setState({ saving: true });
    await this.props.deleteVariant(variantId);
    this.props.fetchItem();
    this.modified = true;
    this.setState({ saving: false });
  };

  render() {
    const { hideModal, mode } = this.props;
    const { activeTab, loading, saving } = this.state;
    const itemExists = this.props.item && this.props.item.id;

    return (
      <View
        {...{
          hideModal,
          loading,
          saving,
          heading: `${
            ["create", "clone"].includes(mode) ? "Add" : "Edit"
          } Zone`,
          subheading: itemExists ? this.props.item.name : null,
          Body: this.renderScene(activeTab),
          canSave: this.canSave(),
          onSave: this.handleSave,
          tabs: [
            {
              id: "overview",
              name: "Overview",
              active: activeTab === "overview",
              enabled: true,
              onClick: () => this.setTab("overview")
            },
            {
              id: "items",
              name: "Related Pass Types",
              active: activeTab === "items",
              enabled: true,
              onClick: () => this.setTab("items")
            }
          ]
        }}
      />
    );
  }
}

InternalHandler.defaultProps = {
  itemId: null,
  item: {},
  clone: false
};

export default InternalHandler;
