import { createHandlers } from "redux-mvc";

const mergeIds = ids => ids.join("_");

// Initial State
const iniState = {
  loadingApproval: {},
  responses: {}
};

// Handlers
const handlers = createHandlers({
  iniState,
  reducers: {
    fetchRecordsIsApproverFor: (state, { payload }) => ({
      loadingApproval: {
        ...state.loadingApproval,
        [mergeIds(payload.recordIds)]: true
      }
    }),
    handleResponse: (state, { payload: { result, recordIds } }) => ({
      responses: {
        ...state.responses,
        [mergeIds(recordIds)]: result
      },
      loadingApproval: {
        ...state.loadingApproval,
        [mergeIds(recordIds)]: false
      }
    }),
    setLoadingApproval: (state, { payload }) => ({
      loadingApproval: {
        ...state.loadingApproval,
        [mergeIds(payload.recordIds)]: payload.status
      }
    })
  },
  namespace: "SelectiveApproversLabel"
});

export default handlers;
