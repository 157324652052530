import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters } from "../model";
import { FIELDS_IDS, ALERT_TYPES } from "../constants";

import { capitalizeFirst } from "utils/General";

import { Text1, Div } from "components/Base";

import { Input, Dropdown, Wysiwyg } from "ui-kit/Form/View";
import { VARIANTS } from "ui-kit/Theme";
import Container from "./Container";

const decorate = connect(
  (state, props) => ({
    data: getters.data(state, props)
  }),
  null
);

const MessageContent = ({ data }) => (
  <Container title="Message Content">
    <Text1 bold color="neutral9" mb={2}>
      Header / Alert Name
    </Text1>

    <Input
      fieldId={FIELDS_IDS.MESSAGE.HEADER}
      width={1}
      iniValue={data.title}
      size="big"
      inputType="outline"
      bg="neutral0"
      bc={{ default: "neutral2", hover: "purple5" }}
      variant={VARIANTS.SURFACE}
      mb={2}
    />
    <Div mb={2}>
      <Wysiwyg
        style={{
          width: "100%",
          resize: "none"
        }}
        fieldId={FIELDS_IDS.MESSAGE.CONTENT}
        width={1}
        mb={2}
        color="neutral9"
        bg="neutral0"
        bc={{ default: "neutral2", hover: "purple5" }}
        iniValue={data.content}
        placeholder="Message content..."
      />
    </Div>
    <Div display="row.space-between.center" mb={2}>
      {/* <Text1 color="neutral9">{R.length(data.content)} / 330</Text1> */}
      {/* <SmallFilledButton pill LeftIcon={AddIcon} bg="neutral1" color="neutral9">
        Insert Data
</SmallFilledButton>*/}
    </Div>
    <Div display="row.flex-start.center" mb={2}>
      <Text1 bold color="neutral9">
        Alert Type
      </Text1>
      <Text1 color="neutral9" ml={1}>
        (Portal & emails only)
      </Text1>
    </Div>
    <Div width={200}>
      <Dropdown
        fieldId={FIELDS_IDS.MESSAGE.TYPE}
        clearable={false}
        iniValue={R.prop(FIELDS_IDS.MESSAGE.TYPE, data)}
        options={R.map(id => ({ id, label: capitalizeFirst(id) }), ALERT_TYPES)}
      />
    </Div>
  </Container>
);

export default decorate(MessageContent);
