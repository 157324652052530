import PropTypes from "prop-types";
import React from "react";
import Editor from "../../Editors/LookupEditor";

const LookupSelect = ({ onChange, value, options, rowMetaData }) => (
  <Editor
    rowMetaData={rowMetaData}
    onChange={onChange}
    options={options}
    value={value}
  />
);

LookupSelect.propTypes = {
  value: PropTypes.object,
  rowMetaData: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LookupSelect;
