import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import uuid from "node-uuid";

import { noop } from "utils/General";

import { actions, getters } from "Sponsors/SelfSetup";
import { STEPS } from "Sponsors/SelfSetup/constants";
import {
  getExhibitorPackages,
  getSponsorPackages
} from "Sponsors/SelfSetup/selectors";

import { Div, Text3, Span } from "components/Base";
import { PageTitle, StepDescription, FAQsPanel } from "Sponsors/Common/View";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import Loading from "ui-kit/Loading";

import PackagesList from "./PackagesList";

import { SPONSORSHIP_TYPE_ID, BOOTH_TYPE_ID } from "utils/item-types";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    (state, props) => ({
      exhibitorPackages: getExhibitorPackages(state, props),
      sponsorPackages: getSponsorPackages(state, props),
      loading: getters.loading(state, props)
    }),
    {
      addExhibitorPackage: () =>
        actions.addPackageRequest({ id: uuid.v4(), type: BOOTH_TYPE_ID }),
      addSponsorPackage: () =>
        actions.addPackageRequest({
          id: uuid.v4(),
          type: SPONSORSHIP_TYPE_ID
        }),
      init: actions.init
    }
  )
);

const faqs = [
  {
    id: "multiple space",
    title: "What do I do if I have multiple price points?",
    text:
      "You will be able to set multiple price points per item later in the process."
  },
  {
    id: "offer multiple",
    title:
      "What do I do if I want to offer packages with multiple items in them? (e.g. Add tables, chairs and badges to the package.)",
    text: `You will be able to create these items (e.g. "Table" and "Chair") in Step 2. Then in Step 3 you will choose which of these items should go in your packages that you're creating here in Step 1.`,
    isLast: true
  }
];

const CreatePackagePage = ({
  exhibitorPackages = [],
  sponsorPackages = [],
  loading = false,
  init = noop,
  addExhibitorPackage = noop,
  addSponsorPackage = noop,
  Th = defaultTh,
  ...styleProps
}) => {
  useEffect(() => {
    init(STEPS.OPPORTUNITIES);
  }, []);

  return (
    <Div
      {...Th([], {
        display: "column",
        ...styleProps
      })}
    >
      <PageTitle>Create Sales Packages</PageTitle>
      <StepDescription mt={4}>
        <Text3 {...Th(["color"], { ml: 2, bold: true })}>
          Step 1: Name and price your packages
        </Text3>
        <Text3 {...Th(["color"], { mt: 4, ml: 2 })}>
          Packages are what you sell to exhibiting companies and sponsors. Tthey
          allow you to keep track of total quantities needed for included
          package items like tables, chairs, comp. tickets and more.
        </Text3>
        <Text3 {...Th(["color"], { ml: 2, mt: 4 })}>
          <Span {...Th(["color"], { fw: 3 })}>For Example: </Span>
          10x10 Exhibitor Package (includes one table, two chairs, two exhibitor
          badges and two tickets to the "VIP Exhibitor Meet & Greet").
        </Text3>
      </StepDescription>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PackagesList
            title="Exhibitor Packages"
            items={exhibitorPackages}
            onAdd={addExhibitorPackage}
            testId="exhibitorPackages"
          />
          <PackagesList
            title="Sponsor Packages"
            items={sponsorPackages}
            onAdd={addSponsorPackage}
            testId="sponsorPackages"
          />
        </>
      )}
      <FAQsPanel questions={faqs} mt={6} />
    </Div>
  );
};

export default decorate(CreatePackagePage);
