import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";
import { DEFAULT_ICON_GROUP } from "components/Global/OptionSelector/AccountsOption/IconSelector";

const iniState = {
  name: "",
  icon: DEFAULT_ICON_GROUP[0],
  backgroundColor: "#ececec",
  textColor: "#000",
  showModal: false
};

const reducers = {
  save: R.identity,
  setInitialData: (state, { payload }) => ({
    name: R.propOr("", "name", payload),
    icon: R.propOr(DEFAULT_ICON_GROUP[0], "icon", payload),
    backgroundColor: R.propOr("", "backgroundColor", payload),
    textColor: R.propOr("", "textColor", payload)
  }),
  handleColorChange: (state, { payload: { textColor, backgroundColor } }) => ({
    textColor,
    backgroundColor
  })
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
