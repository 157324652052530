import React from "react";
import * as R from "ramda";
import { Panel, PanelTitle } from "EventLight/Common/FieldLayout";

import { connect } from "react-redux";
import { actions, getters } from "../model";
import { isEventFeatureEnabled } from "redux/modules/event/selectors";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import TemplatePanel from "./TemplatePanel";
import EmptyState from "EventLight/Common/EmptyState";

import { MediumFilledButton, AddIcon } from "components/Base";
import { FEATURES } from "utils/event-types-and-feature-constants";

const decorate = R.compose(
  CSSModules(css),
  connect(
    state => ({
      list: getters.list(state),
      lifecycleList: getters.lifecycleList(state),
      isEnabled: isEventFeatureEnabled(state)
    }),
    {
      openAddTemplateModal: actions.openAddTemplateModal,
      openEditTemplateModal: actions.openEditTemplateModal,
      deleteTemplate: actions.deleteEmailTemplate,
      duplicateTemplate: actions.duplicateEmailTemplate
    }
  )
);

const Body = ({
  list,
  openAddTemplateModal,
  openEditTemplateModal,
  deleteTemplate,
  duplicateTemplate,
  isEnabled
}) => {
  const templates = R.compose(
    R.map(template => (
      <TemplatePanel
        key={template.id}
        template={template}
        editTemplate={() => openEditTemplateModal({ template })}
        deleteTemplate={() => deleteTemplate({ templateId: template.id })}
        duplicateTemplate={() => duplicateTemplate({ templateId: template.id })}
      />
    )),
    R.filter(template => template.type !== "invoice")
  )(list);

  const invoiceTemplates = R.compose(
    R.map(template => (
      <TemplatePanel
        key={template.id}
        template={template}
        editTemplate={() => openEditTemplateModal({ template })}
        deleteTemplate={() => deleteTemplate({ templateId: template.id })}
        duplicateTemplate={() => duplicateTemplate({ templateId: template.id })}
      />
    )),
    R.filter(template => template.type === "invoice")
  )(list);

  return (
    <>
      {isEnabled(FEATURES.INVOICES) ? (
        <Panel>
          <PanelTitle
            right={
              <MediumFilledButton
                color="white"
                bg="altB5"
                LeftIcon={AddIcon}
                onClick={() => openAddTemplateModal({ type: "invoice" })}
              >
                Add
              </MediumFilledButton>
            }
          >
            Invoice Templates
          </PanelTitle>
          <div styleName="well">
            {!invoiceTemplates.length ? (
              <EmptyState
                title="You haven't added any invoice templates yet"
                description="Invoice templates are used when sending invoices to collect payment"
              />
            ) : (
              invoiceTemplates
            )}
          </div>
        </Panel>
      ) : null}

      <Panel>
        <PanelTitle
          right={
            <MediumFilledButton
              color="white"
              bg="altB5"
              LeftIcon={AddIcon}
              onClick={() => openAddTemplateModal({ type: "custom" })}
            >
              Add
            </MediumFilledButton>
          }
        >
          Custom Templates
        </PanelTitle>
        <div styleName="well">
          {!templates.length ? (
            <EmptyState
              title="You haven't added any templates yet"
              description="Custom email templates make it easy to send common emails quickly"
            />
          ) : (
            templates
          )}
        </div>
      </Panel>
    </>
  );
};

export default decorate(Body);
