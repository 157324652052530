import React from "react";
import { connect } from "react-redux";

import { Div, Text1, InfoIcon, FontIcon } from "components/Base";
import { actions } from "Modules/SubscribeModal";

import {
  getXmlUrl,
  getJsonUrl,
  getCalendarUrl
} from "Modules/SubscribeModal/selectors";

const decorate = connect(
  state => ({
    xmlUrl: getXmlUrl(state),
    jsonUrl: getJsonUrl(state),
    calendarUrl: getCalendarUrl(state)
  }),
  {
    copyUrl: actions.copySubscriptionUrl,
    clickInput: actions.clickInput
  }
);

const CopyLink = ({
  copyButtonText = "Copy Link",
  link = "http",
  onClickLinkInput,
  onCopyLinkInput,
  ...props
}) => {
  const textInput = React.createRef();

  return (
    <Div display="row.flex-start.center" mb={8} flex={1} {...props}>
      <input
        ref={textInput}
        style={{
          display: "flex",
          flex: 1,
          background: "none",
          border: 0,
          outline: "none",
          fontSize: 15,
          color: "#494949",
          cursor: "pointer",
          backgroundColor: "#efefef",
          height: 40,
          padding: "0 15px",
          borderRadius: "5px 0 0 5px"
        }}
        value={link}
        onChange={() => {}}
        onClick={event => onClickLinkInput({ event, ref: textInput })}
      />
      <Div
        bg={{
          default: "purple8",
          hover: "purple9"
        }}
        color="white"
        onClick={() => onCopyLinkInput(textInput)}
        display="row.center.center"
        px={3}
        fw={3}
        style={{
          borderRadius: "0 5px 5px 0"
        }}
        width={150}
        fs={1}
        height={40}
      >
        <FontIcon color="white" mr={2} fs={2}>
          file_copy
        </FontIcon>
        {copyButtonText}
      </Div>
    </Div>
  );
};

const Json = decorate(({ jsonUrl, copyUrl, clickInput }) => (
  <Div display="column">
    <Div mb={3} pb={3} bc="neutral3" bb={1}>
      Subscribe to a live feed of the data you’re viewing using the URL below.
      This can be used to refresh content on your websites & mobile applications
      or to create live reports in Excel & Google Sheets.
      <br />
      <br />
      Note: the data in this feed is affected by your current view. If you save
      changes to filters, columns or sorting, the output of this feed will be
      affected.
    </Div>

    <CopyLink
      copyButtonText="Copy"
      link={jsonUrl}
      mb={3}
      onClickLinkInput={clickInput}
      onCopyLinkInput={copyUrl}
    />
  </Div>
));

const Xml = decorate(({ xmlUrl, copyUrl, clickInput }) => (
  <Div display="column">
    <Div mb={3} pb={3} bc="neutral3" bb={1}>
      Subscribe to a live feed of the data you’re viewing using the URL below.
      This can be used to refresh content on your websites & mobile applications
      or to create live reports in Excel & Google Sheets.
      <br />
      <br />
      Note: the data in this feed is affected by your current view. If you save
      changes to filters, columns or sorting, the output of this feed will be
      affected.
    </Div>

    <CopyLink
      copyButtonText="Copy"
      link={xmlUrl}
      mb={3}
      onClickLinkInput={clickInput}
      onCopyLinkInput={copyUrl}
    />
  </Div>
));

const Calendar = decorate(({ calendarUrl, copyUrl, clickInput }) => (
  <Div display="column">
    <Div mb={3} pb={3} bc="neutral3" bb={1}>
      Subscribe to a feed of the schedule you’re viewing by adding the URL below
      to your calendar software. Everything will stay up to date as activities
      are adjusted in Lennd.
      <br />
      <br />
      Note: the data in this feed is affected by your current view. If you save
      changes to filters, columns or sorting, the output of this feed will be
      affected.
    </Div>

    <Div fw={3} color="gray7">
      Copy the link and add it to your calendar software:
    </Div>
    <Div mt={1}>
      <CopyLink
        copyButtonText="Copy"
        link={calendarUrl}
        mb={3}
        onClickLinkInput={clickInput}
        onCopyLinkInput={copyUrl}
      />
    </Div>
    {/*<Text1 color="gray6">
      Note: Sharing this link with anyone else will let them view any
</Text1>*/}
    <Div mt={5}>
      <Div fw={3} color="gray7" mb={1}>
        For instructions, click on your calendar software below:
      </Div>
      <Div mb={1}>
        <a
          href="https://support.google.com/calendar/answer/37100"
          target="_blank"
          style={{
            textDecoration: "underline"
          }}
        >
          Google Calendar
        </a>
      </Div>
      <Div mb={1}>
        <a
          href="https://support.office.com/en-us/article/import-or-subscribe-to-a-calendar-in-outlook-on-the-web-503ffaf6-7b86-44fe-8dd6-8099d95f38df"
          target="_blank"
          style={{
            textDecoration: "underline"
          }}
        >
          Microsoft Outlook
        </a>
      </Div>
      <Div>
        <a
          href="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
          target="_blank"
          style={{
            textDecoration: "underline"
          }}
        >
          Apple Calendar
        </a>
      </Div>
    </Div>
  </Div>
));

export { Json, Xml, Calendar };
