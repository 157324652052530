import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ExternalHandler from "./External";
import * as flags from "utils/feature-flags";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import {
  getViews,
  createView,
  updateView,
  deleteView
} from "redux/modules/modules/views/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { eventDetails } from "redux/modules/event/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";

import ReportPicker from "components/Global/Module/Sidebar/Popovers/ReportPicker";
import {
  GridView,
  ListView,
  KanbanView,
  ReportView
} from "components/Global/Module/Sidebar/ViewSelector/Popover/Icons";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import getRouteContext from "components/Event/Module/utils/get-route-context";

const TYPES = ({ moduleData = {}, reports = [] }) => [
  {
    id: "list",
    name: "List",
    icon: <ListView color="inherit" size={13} />,
    enabled: true
  },
  {
    id: "grid",
    name: "Grid",
    icon: <GridView color="inherit" size={13} />,
    enabled: true
  },
  {
    id: "kanban",
    name: "Kanban",
    icon: <KanbanView color="inherit" size={13} />,
    enabled: true
  },
  {
    id: "report",
    name: "Report",
    icon: <ReportView color="inherit" size={17} />,
    enabled: Boolean(reports.length),
    picker: {
      component: ReportPicker,
      data: {
        reports
      }
    }
  },
  {
    id: "calendar",
    name: "Calendar",
    icon: <ReportView color="inherit" size={17} />,
    enabled: STANDARD_MODULE_IDS.schedules.id === moduleData.type_id
  }
];

function mapStateToProps(state, props) {
  const context = getRouteContext({
    routes: props.routes,
    params: props.params,
    context: props.context
  });

  const moduleData = moduleDetails(state, props.moduleId);
  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    views: views(state, props.moduleId),
    activeView: activeView(
      state,
      props.moduleId,
      props.activeViewId,
      props.groupId
    ),
    types: TYPES({
      moduleData,
      reports: props.reports
    }).filter(t => t.enabled),
    module: moduleData,
    context,
    params: context
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      getViews,
      createView,
      updateView,
      deleteView,
      ...SnackbarActions,
      ...ModalActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalHandler);
