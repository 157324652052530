import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { Div, BigFilledButton } from "components/Base";

class NotifyModal extends Component {
  render() {
    return (
      <StyleWrapper
        heading={`Activate Integration: ${this.props.integrationName}`}
        hideModal={this.props.hideModal}
        width={550}
      >
        <Div mb={3}>
          <Div mb={5}>
            Turning on the <strong>{this.props.integrationName}</strong>{" "}
            integration for your event requires some assistance. When you're
            ready to turn it on, reach out to us and and we will help you get
            started.
          </Div>
          <BigFilledButton
            bg="altB5"
            color="white"
            onClick={() => {
              window.Intercom(
                "showNewMessage",
                `Hello! I would like to turn on the ${
                  this.props.integrationName
                } integration for my event. Thank you for your help.`
              );
              this.props.hideModal();
            }}
          >
            Contact Us
          </BigFilledButton>
        </Div>
      </StyleWrapper>
    );
  }
}

export default NotifyModal;
