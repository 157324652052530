import React from "react";
import * as R from "ramda";

import { Div, MediumAvatar } from "components/Base";

const reverse = (position, arr) =>
  position === "right" ? R.reverse(arr) : arr;

const MessageCard = ({
  avatar = { name: "", photoURL: "" },
  icon,
  children,
  position = "left",
  ...styleProps
}) => {
  const orientation = [
    icon || (
      <MediumAvatar
        key="avatar"
        photoURL={R.prop("photoUrl", avatar)}
        bg="neutral3"
        text={R.propOr("", "name", avatar)}
        singleInitial
      />
    ),
    children
  ];

  return (
    <Div
      display={`row.${
        position === "left" ? "flex-start" : "flex-end"
      }.flex-start`}
      pt={3}
      {...styleProps}
    >
      {reverse(position, orientation)}
    </Div>
  );
};

export default MessageCard;
