import React from "react";
import { connect } from "react-redux";
import Profile from "./Profile";
import { getters } from "Portal/PortalPreviewProfile/model";
import Loading from "ui-kit/Loading";
import { Div } from "components/Base";
import Header from "./Header";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const DefaultLayout = ({ handlers, loading }) => (
  <Div
    style={{
      position: "relative"
    }}
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        <Header handlers={handlers} />
        <Profile />
      </>
    )}
  </Div>
);

const Layout = ({ loading, hideModal, useModal, handlers }) => {
  if (useModal) {
    return (
      <ModalWrapper>
        <StyleWrapper
          heading="Preview Profile"
          hideModal={hideModal}
          width="95%"
          containerStyles={{
            height: "calc(100vh - 100px)"
          }}
          bodyStyles={{ padding: 0 }}
        >
          <DefaultLayout handlers={handlers} loading={loading} />
        </StyleWrapper>
      </ModalWrapper>
    );
  } else {
    return <DefaultLayout handlers={handlers} loading={loading} />;
  }
};

export default decorate(Layout);
