import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../model";

import { Div } from "components/Base";
import ActivityLogs from "Event/ActivityLogs/View";

const decorate = connect(
  null,
  {
    init: actions.init
  }
);

const Layout = ({
  orgId = null,
  eventId = null,
  accountId = null,
  contactId = null,
  init
}) => {
  useEffect(() => {
    init({ orgId, eventId, accountId, contactId });
  }, [orgId, eventId, accountId, contactId]);
  return (
    <Div mb={5} width={1}>
      <ActivityLogs />
    </Div>
  );
};

export default decorate(Layout);
