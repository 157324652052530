import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get, find } from "lodash";
import { Link } from "react-router";

import { LeftIcon } from "components/Base";
import { MODULES } from "components/Global/NavIcons";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { CREDENTIAL_TYPE_ID, INVENTORY_TYPE_ID } from "utils/item-types";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddModuleModal from "components/Event/Settings/Modals/AddModuleModal";
import CanUserDo from "components/Global/Security/CanUserDo";

const getIcon = tabKey => {
  if (MODULES[tabKey]) {
    return MODULES[tabKey].icon;
  }
  return "";
};

@CSSModules(css)
class Sidebar extends Component {
  activeTab = tabKey => {
    const { routes, params } = this.props;
    if (
      find(routes, { name: "editEventSettingsSettings" }) ||
      find(routes, { name: "editEventSettingsEventDays" })
    ) {
      return tabKey === "settings";
    } else if (
      find(routes, { name: "editEventSettingsUsers" }) ||
      find(routes, { name: "editEventSettingsPermissions" })
    ) {
      return tabKey === "users";
    } else if (find(routes, { name: "editEventSettingsOrderFields" })) {
      return tabKey === "orders";
    } else if (find(routes, { name: "editEventSettingsScheduleFields" })) {
      return tabKey === "schedules";
    } else if (find(routes, { name: "editEventSettingsOrderPayments" })) {
      return tabKey === "payments";
    } else if (
      find(routes, { name: "editEventSettingsCatering" }) ||
      find(routes, { name: "editEventSettingsCateringApprovalWorkflows" })
    ) {
      return tabKey === "catering";
    } else if (find(routes, { name: "editEventSettingsAccounts" })) {
      return tabKey === "accounts";
    } else if (
      find(routes, { name: "editEventSettingsCredentials" }) ||
      find(routes, { name: "editEventSettingsCredentialsApprovalWorkflows" })
    ) {
      return tabKey === "credentials";
    } else if (find(routes, { name: "editEventSettingsEmailTemplates" })) {
      return tabKey === "email-templates";
    } else if (find(routes, { name: "editEventSettingsDocumentTemplates" })) {
      return tabKey === "document-templates";
    } else if (find(routes, { name: "editEventSettingsApprovalWorkflows" })) {
      return tabKey === "approval-workflows";
    } else if (find(routes, { name: "editEventSettingsPortal" })) {
      return tabKey === "portal";
    } else if (find(routes, { name: "editEventSettingsInventoryCatalog" })) {
      return tabKey === "inventory";
    } else if (find(routes, { name: "editEventSettingsLocations" })) {
      return tabKey === "locations";
    } else if (
      find(routes, { name: "editEventSettingsCatalogItems" }) ||
      find(routes, { name: "editEventSettingsCatalogActuals" }) ||
      find(routes, { name: "editEventSettingsCatalogBlocks" })
    ) {
      return tabKey === "catalog";
    } else if (find(routes, { name: "editEventSettingsSalesPortal" })) {
      return tabKey === "sales-portal";
    } else if (find(routes, { name: "settingsIntegrations" })) {
      return tabKey === "integrations";
    } else if (find(routes, { name: "editEventPortalSettingsPage" })) {
      return tabKey === "portal-pages";
    }
    return tabKey === `module-${params.moduleId}`;
  };

  showAddModuleModal = () => {
    this.props.showModal({
      content: (
        <AddModuleModal
          eventId={this.props.params.eventId}
          hideModal={this.props.hideModal}
          onSave={this.handleAddModule}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleAddModule = async data => {
    const { event_id, id } = await this.props.addModule(data);
    await this.props.getEvent(event_id);
    this.props.router.push({
      pathname: `/event/${event_id}/settings/module/${id}`
    });
    this.props.hideModal();
  };

  render() {
    const { details, canDo, setExpanded, expanded } = this.props;
    const path = route =>
      `/event/${this.props.params.eventId}/settings${route}`;
    const customModules = details.enabled_modules
      .filter(
        m =>
          m.source === "custom" &&
          canDo(`${m.id}_manage`) &&
          m.type_id !== STANDARD_MODULE_IDS.schedules.id
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div
        styleName="leftColumn"
        style={
          expanded ? {} : { width: 50, overflow: "hidden", maxHeight: "100%" }
        }
      >
        <div styleName="arrow-container" style={expanded ? {} : { right: 0 }}>
          <div
            styleName="arrow-wrapper"
            onClick={() => setExpanded(!expanded)}
            style={expanded ? {} : { transform: "rotate(180deg)" }}
          >
            <LeftIcon size={24} color="white" />
          </div>
        </div>
        <div
          styleName="inner"
          style={
            expanded
              ? {}
              : {
                  transform: "translateX(-250px)"
                }
          }
        >
          <div styleName="settingsTitle">
            <span styleName="navIcon">{getIcon("settings")}</span> Settings
          </div>

          <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_update`}>
            <Link
              to={path("")}
              styleName={
                this.activeTab("settings") ? "navItemActive" : "navItem"
              }
            >
              Event Settings
            </Link>
          </CanUserDo>
          <CanUserDo
            any={[
              `${STANDARD_MODULE_IDS.settings.id}_read`,
              `${STANDARD_MODULE_IDS.settings.id}_manage_permissions`
            ]}
          >
            <Link
              to={path("/users")}
              styleName={this.activeTab("users") ? "navItemActive" : "navItem"}
            >
              Users
            </Link>
          </CanUserDo>
          <div styleName="navDivider" />

          <CanUserDo
            any={[`${STANDARD_MODULE_IDS.settings.id}_manage_email_templates`]}
          >
            <div styleName="navHeading">Customize</div>
            <CanUserDo
              action={`${STANDARD_MODULE_IDS.settings.id}_manage_email_templates`}
            >
              <Link
                to={path("/email-templates")}
                styleName={
                  this.activeTab("email-templates")
                    ? "navItemActive"
                    : "navItem"
                }
              >
                Email Templates
              </Link>
            </CanUserDo>

            <Link
              to={path("/document-templates")}
              styleName={
                this.activeTab("document-templates")
                  ? "navItemActive"
                  : "navItem"
              }
            >
              Document Templates
            </Link>

            <Link
              to={path(`/catalog/type/${CREDENTIAL_TYPE_ID}/items`)}
              styleName={
                this.activeTab("catalog") ? "navItemActive" : "navItem"
              }
            >
              Item Catalog
            </Link>

            {/*
            {isLenndAdmin ? (
              <Link
                to={path("/sales-portal")}
                styleName={
                  this.activeTab("sales-portal") ? "navItemActive" : "navItem"
                }
              >
                Sales Portal
              </Link>
            ) : null}
            */}
            <CanUserDo action={`${STANDARD_MODULE_IDS.accounts.id}_manage`}>
              <Link
                to={path(`/module/${STANDARD_MODULE_IDS.accounts.id}/types`)}
                styleName="navItem"
              >
                Group Portals
              </Link>
            </CanUserDo>

            <Link
              to={path("/portal")}
              styleName={this.activeTab("portal") ? "navItemActive" : "navItem"}
            >
              Event Updates
            </Link>

            <Link
              to={`/event/${this.props.params.eventId}/settings/integrations`}
              styleName={
                this.activeTab("integrations") ? "navItemActive" : "navItem"
              }
            >
              Integrations
            </Link>

            {this.props.canViewApprovalWorkflows ? (
              <Link
                to={path("/approval-workflows")}
                styleName={
                  this.activeTab("approval-workflows")
                    ? "navItemActive"
                    : "navItem"
                }
              >
                Approval Workflows
              </Link>
            ) : null}

            {/*
            <Link
              to={path("/portal/pages")}
              styleName={
                this.activeTab("portal-pages") ? "navItemActive" : "navItem"
              }
            >
              Manage Portal
            </Link>
            */}

            <div styleName="navDivider" />
          </CanUserDo>

          <div styleName="navHeading">Manage</div>

          {/* accounts */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.accounts.id}_manage`}>
            {get(details, "modules.accounts.enabled") && (
              <Link
                to={path(`/module/${STANDARD_MODULE_IDS.accounts.id}/fields`)}
                styleName={
                  this.activeTab(`module-${STANDARD_MODULE_IDS.accounts.id}`)
                    ? "navItemActive"
                    : "navItem"
                }
              >
                <span className="settings-icon" styleName="navIcon">
                  {getIcon("accounts")}
                </span>{" "}
                Groups
              </Link>
            )}
          </CanUserDo>

          {/* contacts */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.contacts.id}_manage`}>
            {get(details, "modules.contacts.enabled") && (
              <Link
                to={path(`/module/${STANDARD_MODULE_IDS.contacts.id}/fields`)}
                styleName={
                  this.activeTab(`module-${STANDARD_MODULE_IDS.contacts.id}`)
                    ? "navItemActive"
                    : "navItem"
                }
              >
                <span className="settings-icon" styleName="navIcon">
                  {getIcon("contacts")}
                </span>{" "}
                People
              </Link>
            )}
          </CanUserDo>

          {this.props.canViewSchedules ? (
            <Link
              to={path(`/schedules/fields`)}
              styleName={
                this.activeTab("schedules") ? "navItemActive" : "navItem"
              }
            >
              <span className="settings-icon" styleName="navIcon">
                {getIcon("schedules")}
              </span>{" "}
              Schedules
            </Link>
          ) : null}

          {/* credentials */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.credentials.id}_manage`}>
            {get(details, "modules.credentials.enabled") && (
              <Link
                to={`/event/${this.props.params.eventId}/passes/setup`}
                styleName={
                  this.activeTab("credentials") ? "navItemActive" : "navItem"
                }
              >
                <span className="settings-icon" styleName="navIcon">
                  {getIcon("credentials")}
                </span>{" "}
                Passes
              </Link>
            )}
          </CanUserDo>

          {/* catering */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.catering.id}_manage`}>
            {get(details, "modules.catering.enabled") && (
              <Link
                to={path("/catering")}
                styleName={
                  this.activeTab("catering") ? "navItemActive" : "navItem"
                }
              >
                <span className="settings-icon" styleName="navIcon">
                  {getIcon("catering")}
                </span>{" "}
                Catering
              </Link>
            )}
          </CanUserDo>

          {/* inventory */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.inventory.id}_manage`}>
            {get(details, "modules.inventory.enabled") && (
              <Link
                to={
                  this.props.canViewInventory
                    ? path(`/catalog/type/${INVENTORY_TYPE_ID}/items`)
                    : path("/inventory/catalog")
                }
                styleName={
                  this.activeTab("inventory") ? "navItemActive" : "navItem"
                }
              >
                <span className="settings-icon" styleName="navIcon">
                  {getIcon("inventory")}
                </span>{" "}
                Inventory
              </Link>
            )}
          </CanUserDo>

          {/*
          <Link
            to={path("/locations")}
            styleName={
              this.activeTab("locations") ? "navItemActive" : "navItem"
            }
          >
            <span className="settings-icon" styleName="navIcon">
              {getIcon("locations")}
            </span>{" "}
            Locations
          </Link>
          */}

          {/* files */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.files.id}_manage`}>
            <Link
              to={path(`/module/${STANDARD_MODULE_IDS.files.id}`)}
              styleName={
                this.activeTab(`module-${STANDARD_MODULE_IDS.files.id}`)
                  ? "navItemActive"
                  : "navItem"
              }
            >
              <span className="settings-icon" styleName="navIcon">
                {getIcon("files")}
              </span>{" "}
              Files
            </Link>
          </CanUserDo>

          {/* orders */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_update`}>
            <Link
              to={path("/orders/fields")}
              styleName={this.activeTab("orders") ? "navItemActive" : "navItem"}
            >
              <span className="settings-icon" styleName="navIcon">
                {getIcon("orders")}
              </span>{" "}
              Orders
            </Link>
          </CanUserDo>

          {/* payments */}
          <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_update`}>
            <Link
              to={path("/orders/payments")}
              styleName={
                this.activeTab("payments") ? "navItemActive" : "navItem"
              }
            >
              <span className="settings-icon" styleName="navIcon">
                <i className="material-icons">attach_money</i>
              </span>{" "}
              Payments
            </Link>
          </CanUserDo>

          {customModules.length ? <div styleName="navDivider" /> : null}

          {customModules.map(moduleDetails => (
            <div key={moduleDetails.id}>
              <Link
                to={path(`/module/${moduleDetails.id}`)}
                styleName={
                  this.activeTab(`module-${moduleDetails.id}`)
                    ? "navItemActive"
                    : "navItem"
                }
              >
                <span className="settings-icon" styleName="navIcon">
                  <i className="material-icons">{moduleDetails.icon}</i>
                </span>{" "}
                {moduleDetails.name}
              </Link>
            </div>
          ))}

          <br />

          <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_add_modules`}>
            <div styleName="addModuleLink" onClick={this.showAddModuleModal}>
              <span className="settings-icon" styleName="navIcon">
                <i className="material-icons">add_circle</i>
              </span>{" "}
              Add Module
            </div>
          </CanUserDo>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  details: PropTypes.object.isRequired,
  getEvent: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  params: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  userPermissionProfile: PropTypes.shape({
    id: PropTypes.string,
    permission: PropTypes.array,
    permissionList: PropTypes.array
  }).isRequired
};

export default Sidebar;
