import PropTypes from "prop-types";
import React, { Component } from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Input from "components/Global-2016/Forms/Input";
// import Wysiwyg from "components/Global/Wysiwyg";

import { Editor } from "@tinymce/tinymce-react";
import {
  DEFAULT_MODULES,
  DEFAULT_FORMATS
} from "ui-kit/Form/View/AdvancedWYSIWYG";

import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import {
  Div,
  Dropdown,
  SmallToggle,
  DatePicker,
  MediumOutlineButton,
  AttachIcon,
  CloseIcon,
  Text2
} from "components/Base";
import FileIcon from "components/Atoms/FileIcon";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import CircularProgress from "material-ui/CircularProgress";
import { capitalize } from "lodash";
import Helpers from "utils/Global/Helpers";
import moment from "moment";
import { openInNewWindow } from "utils/General";
import MergeMenu from "components/Global/Email/Message/MergeMenu";

const DUE_DATE_TWO_WEEKS_BEFORE = "2-weeks-before";
const DUE_DATE_ONE_WEEK_BEFORE = "1-week-before";
const DUE_DATE_ONE_WEEK_AFTER = "1-week-after";
const DUE_DATE_TWO_WEEKS_AFTER = "2-weeks-after";
const DUE_DATE_CUSTOM = "custom";
const MAX_NUMBER_OF_FILES = 5;
const MAX_FILES_SIZE = 20 * 1000 * 1000;

const resolveDueDate = (value, eventDetails) => {
  if (value === DUE_DATE_TWO_WEEKS_BEFORE) {
    return moment(new Date(eventDetails.date_from))
      .subtract(2, "weeks")
      .format();
  } else if (value === DUE_DATE_ONE_WEEK_BEFORE) {
    return moment(new Date(eventDetails.date_from))
      .subtract(1, "week")
      .format();
  } else if (value === DUE_DATE_ONE_WEEK_AFTER) {
    return moment(new Date(eventDetails.date_to))
      .add(1, "week")
      .format();
  } else if (value === DUE_DATE_TWO_WEEKS_AFTER) {
    return moment(new Date(eventDetails.date_to))
      .add(2, "weeks")
      .format();
  }
  return null;
};

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

const Divider = ({ name }) => (
  <Div display="row.flex-start.center" uppercase my={3}>
    <Div fw={3} color="gray7" mr={3} fs={3}>
      {name}
    </Div>
    <Div height={2} bg="gray2" flex={1} />
  </Div>
);

const Label = ({ children, ...props }) => (
  <Div mb={2} fw={3} color="gray7" {...props}>
    {children}
  </Div>
);

const SelectDateButton = ({ onClick, customDateString }) => (
  <Div onClick={onClick} display="row.flex-start.center">
    {customDateString} &middot;
    <Div underline ml={1}>
      Change Date
    </Div>
  </Div>
);

@CSSModules(css)
class EditTemplateModal extends Component {
  constructor(props) {
    super(props);

    const invoiceDueDateOption = props.template.invoice_due_date
      ? DUE_DATE_CUSTOM
      : DUE_DATE_TWO_WEEKS_BEFORE;

    this.state = {
      loading: true,
      error: "",
      enabled:
        props.template.enabled === undefined ? true : props.template.enabled,
      type: props.template.type || "custom",
      moduleId: props.template.module_id,
      title: props.template.title,
      subject: props.template.subject,
      replyTo: props.template.reply_to,
      contentFormatted: props.template.content_formatted,
      contentPlaintext: props.template.content_plaintext,
      //
      invoiceDueDateOption,
      invoiceDueDate: props.template.invoice_due_date
        ? props.template.invoice_due_date
        : resolveDueDate(invoiceDueDateOption, props.eventDetails),
      invoiceNotes: props.template.invoice_notes || "",
      files: props.template.attachments
    };
  }

  async componentDidMount() {
    if (this.props.params.eventId) {
      await this.props.getModules(this.props.params.eventId);
    }

    if (
      this.props.template &&
      this.props.template.module_id &&
      this.props.template.module_id.length
    ) {
      await this.props.getEmailMergeTags({
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        moduleId: this.props.template.module_id
      });
    }

    this.setState({ loading: false });
  }

  onSave = e => {
    e.preventDefault();
    if (!this.state.contentPlaintext.length || !this.state.title.length) {
      return this.setState({ error: "Cannot save empty template" });
    }
    const { done, hideModal, template } = this.props;

    const {
      moduleId,
      enabled,
      title,
      subject,
      replyTo,
      contentFormatted,
      contentPlaintext,
      type,
      invoiceDueDate,
      invoiceNotes,
      files
    } = this.state;

    done({
      enabled: this.props.isLifecycle ? enabled : undefined,
      moduleId: this.props.isLifecycle ? undefined : moduleId,
      title: this.props.isLifecycle ? undefined : title,
      subject,
      replyTo,
      contentFormatted,
      contentPlaintext,
      type,
      invoiceNotes:
        this.props.params.orgId || this.props.isLifecycle
          ? undefined
          : invoiceNotes,
      invoiceDueDate:
        this.props.params.orgId || this.props.isLifecycle
          ? undefined
          : invoiceDueDate,
      id: template.id,
      attachments: files
    });
    hideModal();
  };

  deleteTemplate = () => {
    this.props.hideModal();
    this.props.deleteTemplate(this.props.template.id);
  };

  onTitleChange = e => {
    this.setState({ title: e.target.value });
  };

  onSubjectChange = e => {
    this.setState({ subject: e.target.value });
  };

  onReplyToChange = e => {
    this.setState({ replyTo: e.target.value });
  };

  onTextChange = content => {
    this.setState({ contentFormatted: content, contentPlaintext: content });
  };

  onToggleEnabled = value => {
    this.setState({ enabled: value });
  };

  onModuleChange = selected => {
    this.setState({
      moduleId: selected ? selected.value : selected
    });
    if (selected && selected.value && selected.value.length) {
      this.props.getEmailMergeTags({
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        moduleId: selected.value
      });
    }
  };

  onInvoiceDueDateChange = ({ value }) => {
    this.setState({
      invoiceDueDateOption: value,
      invoiceDueDate:
        value === DUE_DATE_CUSTOM
          ? this.state.invoiceDueDate
          : resolveDueDate(value, this.props.eventDetails)
    });
  };

  onInvoiceCustomDueDateChange = customDueDate => {
    this.setState({
      invoiceDueDate: customDueDate
    });
  };

  onInvoiceNotesChange = e => this.setState({ invoiceNotes: e.target.value });

  formatModule = moduleDetails => ({
    label: moduleDetails.name,
    value: moduleDetails.id,
    ...moduleDetails
  });

  validateForm = () => {
    if (
      this.state.contentPlaintext &&
      this.state.contentPlaintext.length &&
      this.state.title &&
      this.state.title.length &&
      this.state.subject &&
      this.state.subject.length &&
      !this.isExceedingMaxFilesSize() &&
      this.state.files.length <= MAX_NUMBER_OF_FILES
    ) {
      return true;
    }
    return false;
  };

  removeFile = file => {
    this.setState({
      files: R.reject(R.propEq("uploadId", file.uploadId), this.state.files)
    });
  };

  openFile = url => openInNewWindow(url);

  getFilePicker = event => {
    const { files } = this.state;
    event.preventDefault();
    const options = {
      multiple: true,
      accept: [],
      maxSize: MAX_FILES_SIZE,
      maxFiles: MAX_NUMBER_OF_FILES - files.length,
      fromSources: ["local_file_system", "dropbox"]
    };

    const path = { path: "template-attachments/" };

    const callback = filesAdded => {
      this.setState({ files: [...files, ...filesAdded] });
    };

    return Helpers.getFilepicker(options, path, callback);
  };

  getFilesSize() {
    const { files } = this.state;
    return files.reduce((acc, curr) => {
      acc += curr.size;
      return acc;
    }, 0);
  }

  isExceedingMaxFilesSize() {
    return this.getFilesSize() > MAX_FILES_SIZE;
  }

  isExceedingMaxFilesNumber() {
    const { files } = this.state;
    return files.length >= MAX_NUMBER_OF_FILES;
  }

  isUploadButtonDisabled() {
    return this.isExceedingMaxFilesSize() || this.isExceedingMaxFilesNumber();
  }

  onMergeTagSelect = tag => {
    const message = `${this.state.contentFormatted} ${tag}`;
    this.setState({
      contentPlaintext: message,
      contentFormatted: message
    });
  };

  render() {
    const { files } = this.state;
    const isExceedingFileSize = this.isExceedingMaxFilesSize();
    const isUploadButtonDisabled = this.isUploadButtonDisabled();

    const tags = this.props.mergeTags
      ? R.map(({ fields, ...t }) => ({
          ...t,
          primary: R.filter(
            f => f.is_primary_field && f.meta.type !== "summary"
          )(fields),
          summary: R.filter(f => f.meta.type === "summary")(fields),
          forms: R.filter(f => f.meta.type === "form")(fields),
          secondary: R.filter(
            f => !f.is_primary_field && f.meta.type !== "summary"
          )(fields)
        }))(this.props.mergeTags)
      : [];

    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading={`${this.props.deleteTemplate ? "Edit" : "Create"} ${
          this.props.isLifecycle
            ? "Lifecycle"
            : this.props.template.type
            ? capitalize(this.props.template.type)
            : "Email"
        } Template`}
        width={700}
      >
        {this.state.loading ? (
          <Loading />
        ) : (
          <form onSubmit={this.onSave}>
            <div styleName="inputGroup">
              <Label>Template Name</Label>
              <Input
                onChange={this.onTitleChange}
                value={this.state.title}
                required
                disabled={this.props.isLifecycle}
              />
            </div>

            {this.props.isLifecycle ? (
              <div styleName="inputGroup">
                <Label>Email Enabled</Label>
                <span
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.onToggleEnabled(!this.state.enabled);
                  }}
                >
                  <SmallToggle active={this.state.enabled} />
                </span>
              </div>
            ) : null}

            {!this.props.isLifecycle &&
            this.props.template.type !== "invoice" ? (
              <div styleName="inputGroup" style={{ width: "50%" }}>
                <Label>Module</Label>
                <Dropdown
                  arrowRenderer={ArrowRenderer}
                  clearable={false}
                  onChange={this.onModuleChange}
                  options={[
                    {
                      label: "(None)",
                      value: null
                    },
                    ...this.props.modules
                      .map(this.formatModule)
                      .sort((a, b) => a.label - b.label)
                  ]}
                  placeholder="Select a module"
                  value={this.state.moduleId}
                  usePortal
                />
              </div>
            ) : null}

            <Divider name="Email" />

            <div styleName="inputGroup">
              <Label>Reply To</Label>
              <Input
                type="email"
                onChange={this.onReplyToChange}
                value={this.state.replyTo}
              />
            </div>

            <div styleName="inputGroup">
              <Label>Subject Line</Label>
              <Input
                onChange={this.onSubjectChange}
                value={this.state.subject}
              />
            </div>

            <div styleName="inputGroup">
              <Div
                display="row.space-between.center"
                mb={this.props.mergeTags.length ? 2 : 0}
              >
                <Div>
                  <Label mb={this.props.mergeTags.length ? 0 : 2}>
                    Message Body
                  </Label>
                  <span
                    style={{ color: "red", fontSize: 12, fontStyle: "italic" }}
                  >
                    {this.state.error}
                  </span>
                </Div>
                {this.state.moduleId && this.props.mergeTags.length ? (
                  <MergeMenu
                    mergeTags={tags}
                    onMergeTagSelect={this.onMergeTagSelect}
                  />
                ) : null}
              </Div>

              <Editor
                apiKey={window.__TINY_MCE_API_KEY__}
                // onFocus={() => onFocus(null, { meta: { fieldId, fieldType } })}
                onEditorChange={val => this.onTextChange(val)}
                // onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
                value={this.state.contentFormatted}
                init={{
                  placeholder: "Enter text here...",
                  plugins: DEFAULT_MODULES,
                  toolbar: DEFAULT_FORMATS,
                  paste_as_text: true,
                  menubar: false,
                  branding: false,
                  relative_urls: false,
                  remove_script_host: false,
                  image_caption: true,
                  file_picker_types: "image",
                  image_title: true,
                  urlconverter_callback: function(url) {
                    return url;
                  },
                  file_picker_callback: function(cb) {
                    const options = {
                      multiple: false,
                      accept: ["image/*"],
                      maxSize: MAX_FILES_SIZE,
                      maxFiles: MAX_NUMBER_OF_FILES - files.length,
                      fromSources: ["local_file_system", "dropbox"]
                    };

                    const path = { path: "template-img/" };

                    const callback = filesAdded => {
                      const file = filesAdded[0];
                      cb(file.url, { title: file.filename });
                    };

                    return Helpers.getFilepicker(options, path, callback);
                  }
                }}
              />

              {/*
              <Wysiwyg
                text={this.state.contentFormatted}
                onChange={this.onTextChange}
                height={400}
                placeholder="Enter text here..."
                {...this.props.wysiwygProps}
              />
              */}

              {!this.props.isLifecycle ? (
                <>
                  <Div display="row.flex-start.center" mt={4}>
                    <MediumOutlineButton
                      LeftIcon={AttachIcon}
                      onClick={this.getFilePicker}
                      mr={4}
                      disabled={isUploadButtonDisabled}
                    >
                      Attach Files
                    </MediumOutlineButton>
                    <Text2>
                      <span
                        style={{
                          color: isExceedingFileSize ? "red" : "inherit",
                          fontWeight: isExceedingFileSize ? "bold" : "inherit"
                        }}
                      >
                        {Helpers.formatSizeUnits(this.getFilesSize())}
                      </span>{" "}
                      of {Helpers.formatSizeUnits(MAX_FILES_SIZE)} Limit
                    </Text2>
                  </Div>

                  <Div mt={4}>
                    {files.map(file => {
                      return (
                        <Div
                          key={file.uploadId.toString()}
                          mt={3}
                          bg="neutral0"
                          style={{ padding: 10, borderRadius: 6 }}
                          display="row.space-between.center"
                        >
                          <Div display="row">
                            <Div>
                              <FileIcon size={15} mimetype={file.mimetype} />
                            </Div>
                            <Div
                              truncate
                              ml={3}
                              maxWidth={200}
                              color="info8"
                              onClick={() => this.openFile(file.url)}
                            >
                              {file.filename}
                            </Div>
                            <Div ml={3}>
                              ({Helpers.formatSizeUnits(file.size)})
                            </Div>
                          </Div>

                          <CloseIcon
                            onClick={() => {
                              this.removeFile(file);
                            }}
                          />
                        </Div>
                      );
                    })}
                  </Div>
                </>
              ) : null}
            </div>

            {this.props.template.type === "invoice" ? (
              <Div>
                <Divider name="Invoice Details" />

                <Div display="row.flex-start.flex-start">
                  <Div display="column" flex={1} mr={5}>
                    <div styleName="inputGroup">
                      <Label>Due Date</Label>
                      <Dropdown
                        options={[
                          {
                            label: "2 weeks before start of event",
                            value: DUE_DATE_TWO_WEEKS_BEFORE
                          },
                          {
                            label: "1 week before start of event",
                            value: DUE_DATE_ONE_WEEK_BEFORE
                          },
                          {
                            label: "1 week after end of event",
                            value: DUE_DATE_ONE_WEEK_AFTER
                          },
                          {
                            label: "2 weeks after end of event",
                            value: DUE_DATE_TWO_WEEKS_AFTER
                          },
                          {
                            label: "Select a date...",
                            value: DUE_DATE_CUSTOM
                          }
                        ]}
                        usePortal
                        selected={this.state.invoiceDueDateOption}
                        onChange={this.onInvoiceDueDateChange}
                      />
                      <Div bg="neutral1" p={2} display="row.flex-start.center">
                        {this.state.invoiceDueDateOption !== DUE_DATE_CUSTOM ? (
                          <span>
                            {moment(new Date(this.state.invoiceDueDate)).format(
                              "MMM Do YYYY"
                            )}{" "}
                            &middot;{" "}
                            {moment(
                              new Date(this.state.invoiceDueDate)
                            ).fromNow()}
                          </span>
                        ) : (
                          <DatePicker
                            defaultValue={this.state.invoiceDueDate}
                            onChange={this.onInvoiceCustomDueDateChange}
                            Label={SelectDateButton}
                            labelProps={{
                              customDateString: moment(
                                this.state.invoiceDueDate
                              ).format("MMM Do YYYY")
                            }}
                          />
                        )}
                      </Div>
                    </div>
                  </Div>

                  <Div display="column" flex={1}>
                    <div styleName="inputGroup">
                      <Label>Invoice Terms & Notes</Label>
                      <textarea
                        style={{
                          width: "100%",
                          height: 80
                        }}
                        placeholder="ex: Terms of Service or thank you notes"
                        value={this.state.invoiceNotes}
                        onChange={this.onInvoiceNotesChange}
                      />
                    </div>
                  </Div>
                </Div>
              </Div>
            ) : null}

            <div styleName="actionRow">
              <ButtonGroup>
                <Submit
                  type="submit"
                  disabled={!this.validateForm()}
                  title="Save"
                />
                <ButtonOutline onClick={this.props.hideModal} title="Cancel" />
              </ButtonGroup>
              {!!this.props.deleteTemplate && (
                <a onClick={this.deleteTemplate}>
                  <i className="material-icons" title="Delete" styleName="icon">
                    delete
                  </i>
                </a>
              )}
            </div>
          </form>
        )}
      </StyleWrapper>
    );
  }
}

EditTemplateModal.getModalClassName = () => "EditTemplateModal";

EditTemplateModal.propTypes = {
  modules: PropTypes.array.isRequired,
  getModules: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func,
  done: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  wysiwygProps: PropTypes.object
};

export default EditTemplateModal;
