/* eslint-disable */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { injectStripe } from "react-stripe-elements";
import { Div } from "components/Base";
import Helpers from "utils/Global/Helpers";
import * as R from "ramda";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import CreditCard from "./CreditCard";
import Receipt from "./Receipt";
import Address from "./Address";
import Footer from "./Footer";

import { addInvoiceTransaction } from "redux/modules/orders/invoices/actions";

const decorate = connect(
  null,
  {
    addTransaction: addInvoiceTransaction
  }
);

const DEFAULT_STRIPE_MESSAGE =
  "There was an issue with the payment information you provided. Please try again.";

@CSSModules(css)
class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null,
      stripeToken: null,
      card: {
        token: null,
        name: ""
      },
      fname: "",
      lname: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      //
      shippingAddressLine1: "",
      shippingAddressLine2: "",
      shippingAddressCity: "",
      shippingAddressState: "",
      shippingAddressZip: "",
      shippingAddressCountry: "",
      //
      phone: "",
      email: props.email || "",
      sendConfirmation: R.pathOr(true, [
        "transactionParams",
        "sendConfirmation"
      ])(props),
      requireShippingInformation: R.pathOr(false, [
        "transactionParams",
        "requireShippingInformation"
      ])(props),
      isValid: {
        cardNumber: false,
        expirationDate: false,
        cvc: false,
        zip: false
      }
    };
  }

  onUpdateCardNumber = ({ brand, complete, error, value }) => {
    this.setState(state => {
      if (complete) {
        state.isValid.cardNumber = true;
      } else {
        state.isValid.cardNumber = false;
      }
      return state;
    });
  };

  onUpdateExpirationDate = ({ brand, complete, error, value }) => {
    this.setState(state => {
      if (complete) {
        state.isValid.expirationDate = true;
      } else {
        state.isValid.expirationDate = false;
      }
      return state;
    });
  };

  onUpdateCVC = ({ brand, complete, error, value }) => {
    this.setState(state => {
      if (complete) {
        state.isValid.cvc = true;
      } else {
        state.isValid.cvc = false;
      }
      return state;
    });
  };

  onUpdateZipCode = ({ brand, complete, error, value }) => {
    this.setState(state => {
      if (complete) {
        state.isValid.zip = true;
      } else {
        state.isValid.zip = false;
      }
      return state;
    });
  };

  onSubmit = async e => {
    this.setState({
      saving: true,
      error: null
    });

    // We don't want to let default form submission happen here, which would refresh the page.
    e.preventDefault();

    const email = this.state.email ? this.state.email.trim() : "";

    if (!Helpers.isValidEmail(email)) {
      return this.setState({
        saving: false,
        error: "Please enter a valid email address"
      });
    }

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    const { token, error } = await this.props.stripe.createToken({
      name: this.state.card.name
    });

    // console.log("Received Stripe token:", token);

    if (error) {
      console.error("Caught: Stripe Payment Modal Error", error);
      return this.setState({
        saving: false,
        error: error.message || DEFAULT_STRIPE_MESSAGE
      });
    } else if (!token || !token.id) {
      console.error(
        "Caught: Stripe Payment Modal - No error or token returned"
      );
      return this.setState({
        saving: false,
        error: DEFAULT_STRIPE_MESSAGE
      });
    }

    try {
      await this.props.addTransaction({
        ...this.props.transactionParams,
        sendConfirmation: this.state.sendConfirmation,
        invoiceId: this.props.invoiceId,
        amount: this.props.amount,
        stripeTokenId: token.id,
        email,
        shipping: {
          addressLine1: this.state.shippingAddressLine1,
          addressLine2: this.state.shippingAddressLine2,
          city: this.state.shippingAddressCity,
          state: this.state.shippingAddressState,
          zip: this.state.shippingAddressZip,
          country: this.state.shippingAddressCountry
        }
      });

      this.props.onDone();
      this.props.hideModal();
    } catch (e) {
      console.error(e);
      return this.setState({
        saving: false,
        error: e.message
      });
    }
  };

  isValid = () =>
    this.state.email.length &&
    this.state.card.name.length &&
    this.state.isValid.cardNumber &&
    this.state.isValid.expirationDate &&
    this.state.isValid.cvc &&
    this.state.isValid.zip;

  render() {
    const { hideModal, amount, transactionError } = this.props;
    const {
      saving,
      card,
      fname,
      lname,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZip,
      addressCountry,
      useBillingAddress,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressZip,
      shippingAddressCountry,
      email,
      phone,
      sendConfirmation
    } = this.state;

    const cardHelpers = {
      name: card.name,
      updateCardNumber: this.onUpdateCardNumber,
      updateExpirationDate: this.onUpdateExpirationDate,
      updateCVC: this.onUpdateCVC,
      updateZipCode: this.onUpdateZipCode,
      updateName: e => {
        const value = e.target.value;
        this.setState(state => {
          state.card.name = value;
          return state;
        });
      }
    };

    const addressHelpers = {
      fname,
      lname,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZip,
      addressCountry,
      useBillingAddress,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressZip,
      shippingAddressCountry,
      phone,
      //
      updateFname: e => this.setState({ fname: e.target.value }),
      updateLname: e => this.setState({ lname: e.target.value }),
      updateAddressLine1: e => this.setState({ addressLine1: e.target.value }),
      updateAddressLine2: e => this.setState({ addressLine2: e.target.value }),
      updateAddressCity: e => this.setState({ addressCity: e.target.value }),
      updateAddressState: e => this.setState({ addressState: e.target.value }),
      updateAddressZip: e => this.setState({ addressZip: e.target.value }),
      updateAddressCountry: e =>
        this.setState({ addressCountry: e.target.value }),
      //
      updateShippingAddressLine1: e =>
        this.setState({ shippingAddressLine1: e.target.value }),
      updateShippingAddressLine2: e =>
        this.setState({ shippingAddressLine2: e.target.value }),
      updateShippingAddressCity: e =>
        this.setState({ shippingAddressCity: e.target.value }),
      updateShippingAddressState: e =>
        this.setState({ shippingAddressState: e.target.value }),
      updateShippingAddressZip: e =>
        this.setState({ shippingAddressZip: e.target.value }),
      updateShippingAddressCountry: e =>
        this.setState({ shippingAddressCountry: e.target.value }),
      //
      requireShippingInformation: this.state.requireShippingInformation,
      updateUseBillingAddress: e =>
        this.setState({ useBillingAddress: e.target.value }),
      updatePhone: e => this.setState({ phone: e.target.value })
    };

    const receiptHelpers = {
      showSendConfirmationOption: this.props.showSendConfirmationOption,
      sendConfirmation,
      toggleSendConfirmation: () =>
        this.setState({ sendConfirmation: !this.state.sendConfirmation }),
      email,
      updateEmail: e => this.setState({ email: e.target.value })
    };

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        hideModal={hideModal}
        heading="Pay with credit card"
        width={600}
      >
        <Body style={{ height: 400 }}>
          <CreditCard {...cardHelpers} />
          <Receipt {...receiptHelpers} />
          <Address {...addressHelpers} />
        </Body>
        <Div display="column">
          {this.state.error && this.state.error.length ? (
            <Div bg="danger3" color="gray7" p={3} bra={1} mt={2} mx={5}>
              {this.state.error}
            </Div>
          ) : null}
          <Footer
            amount={`$${amount}`}
            hideModal={hideModal}
            isValid={this.isValid()}
            processing={saving}
            onSubmit={this.onSubmit}
          />
        </Div>
      </StyleWrapper>
    );
  }
}

PaymentModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onDone: PropTypes.func.isRequired
};

export default injectStripe(decorate(PaymentModal));
