import {
  compose,
  isNil,
  isEmpty,
  reject,
  map,
  find,
  propEq,
  values as rValues,
  groupBy
} from "ramda";
import { CONTACTS } from "utils/standard-module-field-ids";

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (isNil(searchTerm) || isEmpty(searchTerm)) {
    return opt;
  }
  return compose(
    reject(isNil),
    map(o => find(propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

const resolveName = ({ fname, lname, email } = {}) => {
  if (fname || lname) {
    return `${fname} ${lname}`.trim();
  }
  return email;
};

const initials = name => {
  return name.trim().length > 0
    ? name
        .trim()
        .split(" ")
        .reduce((name, section) => (name += section[0]), "")
        .toUpperCase()
        .slice(0, 2)
    : "-";
};

const resourceGetter = type => {
  switch (type) {
    case "credentials":
    case "catering":
      // return function that returns values for resource
      return (values, fields) => {};
    default:
      // return function that returns values for all resources and values for event-days field
      return (values, fields) => {};
  }
};

const wrapAction = func => cb => (...args) => {
  cb(...args);
  func();
};
const valueWrapper = function(value, type = "text") {
  return { type, value };
};
const formatContactValues = ({ days_on_site }) => ({
  [CONTACTS.EVENT_DAYS]: valueWrapper(days_on_site, "event-days")
});

const formatAssignmentsFromProps = assignments => {
  return map(a => {
    return {
      id: a[0].id,
      quantity: a.length,
      name: a[0].name
    };
  }, assignments);
};

const groupedAssignmentsFromProps = data => {
  return rValues(groupBy(a => a.id, data));
};

const enforceVariantLimitsOnAssigments = ({
  assignments,
  variantLimits = {}
}) =>
  assignments.map(a => {
    return {
      ...a,
      quantity: variantLimits[a.id]
        ? Math.min(variantLimits[a.id], a.quantity)
        : a.quantity
    };
  });

const mergeAssignments = assignments =>
  assignments.reduce((all, assignment) => {
    const existingIndex = all.findIndex(
      existing => existing.id === assignment.id
    );
    if (existingIndex !== -1) {
      all[existingIndex].quantity =
        all[existingIndex].quantity + assignment.quantity;
    } else {
      all.push(assignment);
    }
    return all;
  }, []);

export {
  wrapAction,
  resourceGetter,
  resolveName,
  initials,
  searchWithFuse,
  formatContactValues,
  formatAssignmentsFromProps,
  groupedAssignmentsFromProps,
  enforceVariantLimitsOnAssigments,
  mergeAssignments
};
