import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const InventoryBody = ({ type = "short", children }) => (
  <div styleName={`container${type}`}>{children}</div>
);

InventoryBody.propTypes = {
  type: PropTypes.oneOf(["short", "tall"])
};

export default CSSModules(InventoryBody, css);
