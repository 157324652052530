import React, { Component } from "react";
import PropTypes from "prop-types";
import InternalHandler from "./Internal";
import * as R from "ramda";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import CreateOrderModal from "Orders/CreateOrderModal";
import ViewOrderModal from "Orders/OrderModal/View";

class ExternalHandler extends Component {
  state = {
    // eslint-disable-next-line no-extra-boolean-cast
    loading: !Boolean(this.props.orders.length)
  };

  async componentDidMount() {
    await Promise.all([this.getOrdersByEvent()]);
    this.setState({ loading: false });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.sourceId !== this.props.sourceId) {
      this.setState({ loading: true });
      await this.getOrdersByEvent();
      this.setState({ loading: false });
    }
  }

  getTicketingProviderConfigurations = () => {};

  getOrdersByEvent = () =>
    this.props.getOrdersByEventAndType(
      this.props.eventId,
      this.props.typeId,
      this.props.sourceId
    );

  showAddOrderModal = () => {
    this.props.showModal({
      content: <CreateOrderModal onDone={() => this.getOrdersByEvent()} />,
      wrapper: ModalWrapper
    });
  };

  showViewOrderModal = ({ orderId }) => {
    this.props.showModal({
      content: (
        <ViewOrderModal
          orderId={orderId}
          hideModal={() => {
            this.getOrdersByEvent();
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  syncAllOrders = integrationId => {
    this.props.syncAllOrders(this.props.eventId, integrationId);
  };

  showAccountModal = accountId =>
    this.showRecordModal(STANDARD_MODULES.accounts.id, accountId);

  showContactModal = contactId =>
    this.showRecordModal(STANDARD_MODULES.contacts.id, contactId);

  showRecordModal = (moduleId, recordId) => {
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={moduleId}
          recordId={recordId}
          onClose={() => {
            this.getOrdersByEvent();
            return this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  deleteOrder = ({ orderId }) => {
    this.props.showModal({
      content: (
        <DeleteConfirmation
          hideModal={this.props.hideModal}
          heading="Delete order?"
          message={
            <div>
              Are you sure you want to delete this order?
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => this.handleDeleteOrder(orderId)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleDeleteOrder = async orderId => {
    await this.props.deleteOrder({
      orderId
    });

    this.props.showSnackbar({
      message: "Order deleted",
      action: "OK"
    });

    return this.getOrdersByEvent();
  };

  archiveOrder = async ({ orderId }) => {
    await this.props.updateOrder({
      orderId,
      isArchived: true
    });

    this.props.showSnackbar({
      message: "Order archived",
      action: "OK"
    });

    return this.getOrdersByEvent();
  };

  unarchiveOrder = async ({ orderId }) => {
    await this.props.updateOrder({
      orderId,
      isArchived: false
    });

    this.props.showSnackbar({
      message: "Order unarchived",
      action: "OK"
    });

    return this.getOrdersByEvent();
  };

  isActiveView = view => this.props.location.query.view === view;

  isActiveSubView = (view, subView) =>
    this.props.location.query.view === view &&
    this.props.location.query.subView === subView;

  goToView = view => {
    this.props.router.push({
      pathname: `/event/${this.props.eventId}${this.props.ordersPath}`,
      query: view
        ? {
            view
          }
        : null
    });
  };

  goToSubView = (view, subView) => {
    this.props.router.push({
      pathname: `/event/${this.props.eventId}${this.props.ordersPath}`,
      query:
        view && subView
          ? {
              view,
              subView
            }
          : null
    });
  };

  goToContactProfile = contactId =>
    this.props.router.push({
      pathname: `/event/${this.props.eventId}/contact/${contactId}`,
      query: {
        returnTo: this.props.ordersPath
      }
    });

  goToAccountProfile = accountId =>
    this.props.router.push({
      pathname: `/event/${this.props.eventId}/account/${accountId}`,
      query: {
        returnTo: this.props.ordersPath
      }
    });

  exportData = (contentType, data, fieldsToShow) => {
    const { exportSheet } = this.props;

    const ordersToExport = R.map(props => {
      return {
        values: R.compose(
          R.fromPairs,
          R.map(({ id, type }) => [
            id,
            R.is(Object, props[id])
              ? props[id]
              : {
                  type,
                  value: props[id]
                }
          ])
        )(fieldsToShow)
      };
    }, data);

    return exportSheet(contentType, "orders", ordersToExport, fieldsToShow);
  };

  render() {
    return (
      <InternalHandler {...this.props} {...this} loading={this.state.loading} />
    );
  }
}

ExternalHandler.propTypes = {
  getOrdersByEvent: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  showAddOrderModal: PropTypes.func.isRequired,
  showViewOrderModal: PropTypes.func.isRequired,
  syncAllOrders: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default ExternalHandler;
