import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "EventLight/Expo/Sales/model";
import { STEPS } from "EventLight/Expo/Sales/constants";

import { getTabs } from "EventLight/Expo/Sales/selectors";
import { getters } from "EventLight/Expo/Sales/model";

import { Div, Text3 } from "components/Base";
import Tabs from "ui-kit/Tabs/View";
import { PageTitle, StepDescription } from "Sponsors/Common/View";
import { Row } from "ui-kit/Form/View";
import NewCategoryModal from "./NewCategoryModal";
import Loading from "ui-kit/Loading";

import Items from "./Items";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    (state, props) => ({
      tabs: getTabs(state, props),
      loading: getters.loading(state)
    }),
    {
      init: actions.init
    }
  )
);

const CreateItemsPage = ({
  init = noop,
  tabs = [],
  Th = defaultTh,
  loading,
  ...styleProps
}) => {
  useEffect(() => {
    init(STEPS.ITEMS);
  }, []);

  return (
    <Div
      {...Th([], {
        display: "column",
        ...styleProps
      })}
    >
      <PageTitle>Create Package Items & Add-ons</PageTitle>
      <StepDescription mt={4}>
        <Text3 {...Th(["color"], { ml: 2, bold: true })}>
          Step 2: Create the items that are included in your packages
        </Text3>
        <Text3 {...Th(["color"], { mt: 4, ml: 2 })}>
          Items are things you make available as add-ons or as components of a
          package (e.g. "Table", "Chair", "VIP Pass", etc.). A good rule of
          thumb is to create an item for anything you would like to collect
          payment for or to track total quantity requested of.
        </Text3>
        <Row>
          <Text3 {...Th(["color"], { mt: 4, ml: 2, bold: true })}>
            Example:
          </Text3>
          <Text3 {...Th(["color"], { mt: 4, ml: 2 })}>
            Tables, Chairs, Linens, A/V Tech, Electrical, Decor, etc
          </Text3>
        </Row>
      </StepDescription>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Tabs tabs={tabs} {...Th(["bc"], { bb: 1, width: 1, mt: 4 })} />
          <Items mt={2} />
        </>
      )}
      <NewCategoryModal />
    </Div>
  );
};

export default decorate(CreateItemsPage);
