import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import { actions } from "../model";
import * as R from "ramda";
import module from "../index";
import Layout from "./Layout";
import { showModal, hideModal } from "redux/modules/modal/actions";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import { getSelectedAccount } from "../selectors";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      const { customerAccount, customerContact } = this.props;
      this.store.dispatch(
        actions.init({
          accountId:
            customerAccount && customerAccount.id ? customerAccount.id : null,
          contactId:
            customerContact && customerContact.id ? customerContact.id : null
        })
      );
    }
  },
  handlers: {
    showAddContactModal: function({ isLastStep, accountId, accountName }) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={STANDARD_MODULE_IDS.contacts.id}
            onSave={res => {
              const contactEmail = R.pathOr(
                "",
                ["values", STANDARD_MODULE_FIELDS.CONTACTS.EMAIL, "value"],
                res
              );
              const contactName = R.pathOr(
                "",
                ["values", "full_name", "value"],
                res
              );

              if (isLastStep) {
                this.props.onSelectCustomer({
                  accountId,
                  accountName,
                  contactId: res.id,
                  contactName,
                  contactEmail
                });
              } else {
                this.store.dispatch(
                  actions.selectContact({
                    contactId: res.id,
                    contactName,
                    contactEmail,
                    filter: ""
                  })
                );
              }
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddAccountModal: function({
      isLastStep,
      contactId,
      contactName,
      contactEmail
    }) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={STANDARD_MODULE_IDS.accounts.id}
            onSave={res => {
              const accountName = R.pathOr(
                "",
                ["values", STANDARD_MODULE_FIELDS.ACCOUNTS.NAME, "value"],
                res
              );

              if (isLastStep) {
                this.props.onSelectCustomer({
                  accountId: res.id,
                  accountName,
                  contactId,
                  contactName,
                  contactEmail
                });
              } else {
                this.store.dispatch(
                  actions.selectAccount({
                    accountId: res.id,
                    accountName,
                    filter: ""
                  })
                );
              }
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels"
    ],
    persist: false
  }
});

export default R.compose(
  connect(
    state => ({
      selectedAccount: getSelectedAccount(state)
    }),
    { showModal, hideModal }
  ),
  withRouter,
  decorate
)(Layout);
