import React from "react";
import { set } from "lodash";

import {
  Div,
  makeTable,
  Text3,
  ExpandIcon,
  PopoverMenu,
  SmallOutlineButton,
  FontIcon
} from "components/Base";

import {
  HeaderStyler,
  RowStyler,
  TableStyler
} from "components/Event/Settings/Layout/Tables/stylers";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const addS = thing => (thing === 1 ? "" : "s");

const ItemCell = CSSModules(
  ({ name, color, ...props }) => (
    <Div display="row.flex-start.center" {...props}>
      <Text3 bold mr={3} primary>
        {name}
      </Text3>
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
      />
    </Div>
  ),
  css
);

const ItemsTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [{ width: 10 / 12 }, { width: 2 / 12 }],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name }) => <ItemCell name={name} />,
    ({ onEdit, onDelete }) => (
      <PopoverMenu
        Label={({ onClick }) => (
          <SmallOutlineButton onClick={onClick} py={1}>
            <FontIcon fs={3}>more_horiz</FontIcon>
          </SmallOutlineButton>
        )}
        menuItems={[["Edit", onEdit], ["Delete", onDelete]].filter(i => i)}
      />
    )
  ]
});

export default ({ items }) => <ItemsTable rowsData={items} />;
