import React, { useEffect, useState, useRef } from "react";

const ENTER_KEY = 13;

const FocusableSearch = props => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);

  const enterKeyFilter = e => {
    if (e.which === ENTER_KEY) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  const clearInput = () => {
    setValue("");
  };
  const reset = () => {
    clearInput();
    focusInput();
  };
  const handleFormSubmit = e => {
    e.preventDefault();
    const v = inputRef.current ? inputRef.current.value : "";
    if (v.length) props.onSubmit(v);
  };
  const handleChange = e => {
    setValue(e.target.value);
    if (props.onChange) props.onChange(e.target.value);
  };

  useEffect(() => {
    document.addEventListener("keypress", enterKeyFilter);
    return () => document.removeEventListener("keypress", enterKeyFilter);
  });

  return (
    <form style={{ height: "100%", width: "100%" }} onSubmit={handleFormSubmit}>
      {props.children({
        clearInput: clearInput,
        focusInput: focusInput,
        resetInput: reset,
        inputProps: {
          ref: inputRef,
          style: {
            outline: "none",
            height: "100%",
            width: "100%",
            border: "none"
          },
          autoFocus: true,
          value: value || "",
          onChange: handleChange
        }
      })}
    </form>
  );
};

export default FocusableSearch;
