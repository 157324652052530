/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";

import {
  MediumFilledButton,
  MediumOutlineButton,
  DownFilledIcon,
  PopMenu,
  PopMenuCard
} from "components/Base";

const ActionsButton = ({
  title = "",
  secondaryTheme = false,
  buttons = [],
  buttonStyle = {},
  ...styleProps
}) => (
  <PopMenu
    {...styleProps}
    Label={({ onClick }) =>
      React.createElement(
        secondaryTheme ? MediumOutlineButton : MediumFilledButton,
        R.mergeAll([
          secondaryTheme ? {} : { color: "black" },
          {
            onClick,
            RightIcon: DownFilledIcon,
            bg: secondaryTheme ? "transparent" : "neutral0",
            width: 1,
            ...buttonStyle
          }
        ]),
        title
      )
    }
  >
    {({ closeMenu }) => (
      <PopMenuCard position="bottom" align="right">
        {R.map(
          (button, index) =>
            React.cloneElement(button, { key: index, closeMenu }),
          buttons
        )}
      </PopMenuCard>
    )}
  </PopMenu>
);

export default ActionsButton;
