import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";

import * as R from "ramda";

import { actions, getters } from "Schedules/ActivityDetailsModal/model";

import { actions as ScheduleActions } from "Schedules/Schedule";

import { getCredentials } from "redux/modules/user/selectors";

import {
  getValuesToBeUpdated,
  getValuesToBeSaved
} from "Schedules/ActivityDetailsModal/selectors";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import * as snackbarActions from "redux/modules/snackbar/actions";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const scheduleId = yield select(getters.scheduleId);

  return {
    credentials,
    eventId,
    scheduleId
  };
};

const getActivity = function*({ payload: { activityId = "", values } }) {
  const { credentials, scheduleId } = yield call(getParams);
  yield put(actions.setLoading(true));

  try {
    if (R.isEmpty(activityId)) {
      const { payload: fields } = yield call(Api.getActivityFields, {
        credentials,
        scheduleId
      });
      yield put(actions.setInitialDataNoId({ fields, values }));
    } else {
      const [{ payload: activity }, { payload: fields }] = yield all([
        call(Api.getActivity, {
          credentials,
          scheduleId,
          activityId
        }),
        call(Api.getActivityFields, {
          credentials,
          scheduleId
        })
      ]);
      yield put(actions.setInitialData({ activity, fields, activityId }));
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while fetching activity"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const saveActivity = function*() {
  const { credentials, eventId, scheduleId } = yield call(getParams);

  yield put(actions.setLoading(true));
  const activityId = yield select(getters.activityId);
  try {
    if (!R.isEmpty(activityId)) {
      const values = yield select(getValuesToBeUpdated);
      const data = {
        moduleId: scheduleId,
        recordId: activityId,
        bulk: true,
        values
      };

      yield call(Api.updateActivity, {
        credentials,
        moduleId: scheduleId,
        eventId,
        data
      });

      yield put(
        snackbarActions.showSnackbar({
          message: "Activity Updated"
        })
      );

      yield put(ScheduleActions.fetchData());
    } else {
      const values = yield select(getValuesToBeSaved);
      yield put(
        ScheduleActions.addActivity({
          values,
          moduleId: scheduleId
        })
      );
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving activity"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));

    yield put(actions.hideModal());
  }
};

const deleteActivity = function*() {
  const activityId = yield select(getters.activityId);

  if (!R.isEmpty(activityId)) {
    try {
      const { credentials, eventId, scheduleId } = yield call(getParams);

      const data = {
        moduleId: scheduleId,
        eventId,
        record: { id: activityId },
        options: {
          eventId
        }
      };

      yield call(Api.deleteActivity, {
        credentials,
        moduleId: scheduleId,
        eventId,
        data
      });

      yield put(
        snackbarActions.showSnackbar({
          message: "Activity Deleted"
        })
      );

      yield put(actions.setLoading(true));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while deleting activity"
          }
        ])
      );
    } finally {
      yield put(actions.setLoading(false));
      yield put(actions.hideModal());
      yield put(ScheduleActions.fetchData());
    }
  }
};

const watchGetActivity = function*() {
  yield takeEvery(actions.getActivity.type, getActivity);
};

const watchSaveActivity = function*() {
  yield takeEvery(actions.saveActivity.type, saveActivity);
};

const watchDeleteActivity = function*() {
  yield takeEvery(actions.deleteActivity.type, deleteActivity);
};

const rootSaga = function*() {
  yield all([
    fork(watchSaveActivity),
    fork(watchDeleteActivity),
    fork(watchGetActivity)
  ]);
};

export default rootSaga;
