import React from "react";
import { Div } from "components/Base";

export const EmptyState = ({ children }) => (
  <Div display="column.center.center" width={1} style={{ padding: "13px 0" }}>
    <Div
      bg="rgba(230, 230, 230, 0.35)"
      width={95}
      height={95}
      display="column.center.center"
      pill
    >
      <img
        src="https://d2dks4tzxs6xee.cloudfront.net/img/form-overview-empty-icon.png"
        height="29"
        width="35"
      />
    </Div>
    <Div mt={2}>{children}</Div>
  </Div>
);

export default EmptyState;
