import * as R from "ramda";
import React, { useMemo } from "react";
import { connect } from "react-redux";

import { getValue, getSettings } from "../../utils";
import { indexedColors } from "components/Base/Base/colorSpecs";

import getValueType from "utils/value-types/get-value";

import toString from "utils/value-types/to-string";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import { CONTACTS } from "utils/standard-module-field-ids";

import * as DataSelectors from "App/Data/selectors";

const { ID } = SYSTEM_FIELD_IDS;
const { EMAIL } = CONTACTS;

const getRecordLabel = (recordId, linkedField, linkedRecord) => {
  if (!linkedField || linkedField.id === ID) {
    const value =
      R.prop(ID, linkedRecord) || R.path(["values", ID], linkedRecord);
    return toString(getValueType(value, "text"), "text");
  }

  const value =
    R.prop(linkedField.id, linkedRecord) ||
    R.path(["values", linkedField.id], linkedRecord);
  const name = toString(
    getValueType(value, linkedField.type),
    linkedField.type
  );

  // if attempting to get full name and no value, return email
  if ((!name || !name.length) && linkedField.id === "full_name") {
    return toString(getValueType(linkedRecord.values[EMAIL], "email"), "email");
  }

  return name;
};
const getRecords = ({
  linkedFieldId,
  fieldReferences,
  recordReferences,
  recordIds
}) => {
  const field = R.prop(linkedFieldId, fieldReferences);
  return R.filter(
    R.compose(
      R.not,
      R.isNil
    ),
    R.map(recordId => {
      const record = R.prop(recordId, recordReferences);
      if (!record) {
        return null;
      }
      return {
        id: recordId,
        label: getRecordLabel(recordId, field, record)
      };
    }, recordIds)
  );
};

const decorate = connect(state => ({
  recordReferences: DataSelectors.getAllRecordReferencesById(state),
  fieldReferences: DataSelectors.getAllFieldReferencesById(state)
}));

const Lookup = props => {
  const value = getValue(props, "records", []);
  const linkedFieldId = getSettings(props, "fieldId", ID);

  const records = useMemo(
    () =>
      getRecords({
        linkedFieldId,
        recordReferences: props.recordReferences,
        fieldReferences: props.fieldReferences,
        recordIds: value || []
      }),
    [linkedFieldId, props.recordReferences, props.fieldReferences, value]
  );
  return (
    <div
      display="row"
      style={{
        overflow: "hidden",
        display: "flex"
      }}
    >
      {R.map(
        record => (
          <div
            key={record.id}
            style={{
              padding: "5px 6px",
              marginRight: 4,
              color: indexedColors.gay9,
              backgroundColor: indexedColors.neutral1,
              flexShrink: 0,
              fontWeight: 600,
              fontSize: 14,
              borderRadius: 4
            }}
          >
            {record.label}
          </div>
        ),
        records
      )}
    </div>
  );
};

export default decorate(Lookup);
