import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Payments from "./Payments";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { getOwners } from "redux/modules/modules/owners/actions";

import { owners } from "redux/modules/modules/owners/selectors";
import { eventId } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventId: eventId(state),
    notifications: owners(state, STANDARD_MODULE_IDS.orders.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getNotifications: getOwners
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments);
