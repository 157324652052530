import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const Button = CSSModules(
  ({ label, onClick }) => (
    <div styleName="actionButton" onClick={onClick}>
      {label}
    </div>
  ),
  css
);

const SwimLaneEmptyState = CSSModules(
  ({ caption, actionButtons }) => (
    <div>
      <div styleName="placeholder">
        <div styleName="placeholderContent">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/form-overview-empty-icon.png"
            height="29px"
            width="35px"
          />
          <span styleName="caption">{caption}</span>
        </div>
      </div>
      <div styleName="actions">{actionButtons}</div>
    </div>
  ),
  css
);

SwimLaneEmptyState.propTypes = {
  caption: PropTypes.string.isRequired,
  actionButtons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default SwimLaneEmptyState;
