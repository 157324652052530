import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import FormBaseInput from "../FormBaseInput";
import autobind from "autobind-decorator";
import BoolCheckbox from "components/Global-2016/Forms/BoolCheckbox";
import { isEqual } from "lodash";
import getValue from "utils/value-types/get-value/boolean";
import isRequiredValid from "utils/value-types/validations/checkbox/required";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormCheckbox extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: getValue(props.formValues[props.field.id]),
      isShown: false
    };
  }

  wrapValue(value) {
    return {
      type: "boolean",
      value
    };
  }

  @autobind
  async isValid() {
    const errors = [];
    const { type, field } = this.props;

    // We need to mock this value as value types expect this schema
    const value = {
      type,
      value: this.state.value
    };

    if (field.is_required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  handleChange() {
    this.setState({
      value: !this.state.value
    });
    this.props.updateFormValue(
      this.props.field.id,
      this.wrapValue(!this.state.value)
    );
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: getValue(nextProps.formValues[this.props.field.id])
      });
    }
  }

  render() {
    const { connectDragSource, canShowSimpleSideBar } = this.props;
    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <BoolCheckbox
          disabled={this.props.disabled}
          placeholder={this.props.field.settings.placeholder}
          label={this.props.field.name}
          onClick={this.handleChange}
          checked={this.state.value}
          isAdminField={this.props.field.settings.isAdminField}
          description={this.props.field.settings.description}
          required={this.props.field.is_required}
          instanceId={this.props.field.id}
          fieldData={this.props.field}
          isShown={this.state.isShown}
          canShowSimpleSideBar={canShowSimpleSideBar}
          readOnly={this.props.field.is_readonly}
          setIsShown={() => this.handleToogleShowEditMode()}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "flex-end",
            cursor: "pointer"
          }}
          labelStyle={{
            minWidth: "auto",
            margin: 0,
            marginRight: 10,
            cursor: "pointer"
          }}
          checkboxStyle={{
            width: 37
          }}
        />
      </FormInputWrapper>
    );
  }
}

FormCheckbox.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default FormCheckbox;
