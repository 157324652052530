import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import { addLineItems } from "redux/modules/orders/line-items/actions";
import { addToGroupOrder } from "redux/modules/portal/groupOrder/actions";

import { user } from "redux/modules/user/selectors";
import { types as itemTypes } from "redux/modules/items/types/selectors";

function mapStateToProps(state, props) {
  const { types, groups, items, variants } = itemTypes(state).reduce(
    (list, type) => {
      list.types.push(type);
      type.groups.forEach(group => {
        list.groups.push(group);
        group.items.forEach(item => {
          list.items.push(item);
          item.variants.forEach(variant => {
            list.variants.push({
              ...variant,
              item
            });
          });
        });
      });
      return list;
    },
    {
      types: [],
      groups: [],
      items: [],
      variants: []
    }
  );
  return {
    mode: props.mode || "update",
    user: user(state),
    types: types.filter(type => {
      if (!type.groups) return false;
      const countOfItems = type.groups.reduce((count, group) => {
        if (group.items) {
          count += group.items.length;
        }
        return count;
      }, 0);
      return countOfItems > 0;
    }),
    groups,
    items,
    variants
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemTypesByEvent,
      addLineItems,
      addToGroupOrder
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
