import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  requestId: null
};

export const model = createHandlers({
  iniState,
  reducers: {},
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
