import React from "react";
import {
  Div,
  BigOutlineInput,
  BigOutlineBox,
  TextAreaInput,
  Text1,
  Text2,
  Text3,
  Text6,
  TinyToggle,
  CloseIcon,
  DatePicker,
  BoxGroup,
  CalendarIcon,
  BigFilledButton,
  DragIcon,
  DarkTab
} from "components/Base";
import { connect } from "react-redux";
import * as R from "ramda";
import moment from "moment";
import { getters, actions } from "SalesPortal/Settings";
import Circle from "ui-kit/Circle";
import Wysiwyg from "components/Global/Wysiwyg";
import { getEventDescriptionLeft } from "SalesPortal/Settings/selectors";

import TypesModal from "SalesPortal/TypesModal/View/Wrapper";
import {
  actions as TypesActions,
  getters as TypesGetters
} from "SalesPortal/TypesModal";

const decorate = connect(
  state => ({
    data: getters.data(state),
    eventDescriptionLeft: getEventDescriptionLeft(state),
    saving: getters.saving(state),
    typesUpdated: TypesGetters.typesUpdated(state)
  }),
  {
    updateDataField: actions.updateDataField,
    uploadFile: actions.uploadFile,
    removeImage: actions.removeImage,
    showTypesModal: () => TypesActions.setShowModal(true)
  }
);

const DateSpan = ({ onClick, dateString }) => (
  <BoxGroup m={1} display="row.flex-start.flex-start">
    <BigOutlineBox bg="white">{dateString}</BigOutlineBox>
    <BigFilledButton onClick={onClick} OnlyIcon={CalendarIcon} />
  </BoxGroup>
);

const variantsQuantity = type =>
  R.compose(
    R.reduce((acc, curr) => {
      if (curr.selected === false) {
        return acc;
      }
      return acc + 1;
    }, 0),
    R.reduce((acc, curr) => [...acc, ...curr], []),
    R.map(({ variants }) => variants)
  )(type.items);

const ImageFrame = ({ url, removeImage }) => (
  <Div
    key={url}
    width={60}
    height={60}
    bg="neutral3"
    style={{ position: "relative" }}
    className="hoverContainer"
    mr={1}
    mb={1}
  >
    <Div
      width={1}
      height={1}
      style={{ overflow: "hidden" }}
      display="row.center.center"
    >
      <img src={url} style={{ height: "110%" }} />
    </Div>
    <Div
      width={1}
      height={15}
      style={{ position: "absolute", top: 0, left: 0 }}
      bg="white"
      shadow={0}
      className="showOnHover"
      pl={2}
    >
      <DragIcon style={{ transform: "rotate(90deg)" }} color="black" />
    </Div>
    <Div
      style={{
        position: "absolute",
        top: "-5px",
        right: "-5px",
        cursor: "pointer",
        zIndex: 2
      }}
      className="showOnHover"
      onClick={() => {
        removeImage({ url });
      }}
    >
      <Circle bg="black" size={14}>
        <CloseIcon color="white" size={10} />
      </Circle>
    </Div>
  </Div>
);

const Layout = ({
  data: {
    description,
    name,
    attendees,
    venue_name: venueName,
    venue_address_line_1: venueAddress1,
    venue_address_line_2: venueAddress2,
    venue_address_city: venueCity,
    venue_address_state: venueState,
    venue_address_zip: venueZip,
    terms,
    images,
    floor_map: floorMap,
    date_to: dateTo
  },
  eventDescriptionLeft,
  updateDataField,
  uploadFile,
  removeImage,
  showTypesModal,
  typesUpdated
}) => {
  const tabs = [
    {
      key: "salesPortalSettings",
      visible: true,
      label: "Settings",
      active: true,
      onClick: () => {}
    }
  ];

  return (
    <Div style={{ minHeight: "100%" }} bg="neutral0">
      <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
        <Div flex={1}>
          <Text6 pl={8} pt={6}>
            Sales Portal
          </Text6>

          <Div pt={3} pl={8}>
            {R.compose(
              R.map(({ key, label, active, onClick }) => {
                return (
                  <DarkTab
                    key={key}
                    px={2}
                    fs={3}
                    active={active}
                    onClick={!active ? onClick : undefined}
                  >
                    {label}
                  </DarkTab>
                );
              }),
              R.filter(R.prop("visible"))
            )(tabs)}
          </Div>
        </Div>
      </Div>

      <Div style={{ minHeight: "100%" }} pb={14} display="row">
        <Div p={6} width={600}>
          <Div width={1} bra={1} bg="neutral1" p={4}>
            <Div display="row.flex-start.center">
              <TinyToggle active />
              <Text3 ml={3} bold color="black">
                Published
              </Text3>
            </Div>
            <Div display="row.flex-start.center" mt={2}>
              <input
                style={{
                  display: "flex",
                  width: "calc(100% - 120px)",
                  background: "none",
                  border: "1px solid #C9C9C9",
                  borderRight: 0,
                  outline: "none",
                  fontSize: 18,
                  color: "#494949",
                  cursor: "pointer",
                  backgroundColor: "#EFF2F5",
                  height: 43,
                  padding: "0 15px",
                  borderRadius: "7px 0 0 7px"
                }}
              />
              <Div
                uppercase
                bg={{
                  default: "purple8",
                  hover: "purple9"
                }}
                color="white"
                mr={3}
                display="row.center.center"
                px={3}
                style={{
                  borderRadius: "0 7px 7px 0",
                  letterSpacing: "1px"
                }}
                fs={2}
                height={43}
                width={120}
              >
                COPY LINK
              </Div>
            </Div>
          </Div>
          <Div mt={3}>
            <Div display="row.space-between.center">
              <Div width={417}>
                <Text3 bold>Name of Event</Text3>
                <BigOutlineInput
                  mt={1}
                  width={1}
                  bg="white"
                  onChange={value => {
                    updateDataField({ value, name: "name" });
                  }}
                  value={name}
                  continuous
                />
              </Div>
              <Div width={108}>
                <Text3 bold># of Attendees</Text3>
                <BigOutlineInput
                  mt={1}
                  width={1}
                  bg="white"
                  onChange={value => {
                    updateDataField({ value, name: "attendees" });
                  }}
                  value={attendees}
                  continuous
                />
              </Div>
            </Div>
            <Div mt={3}>
              <Text3 bold>Event Date(s)</Text3>
              <Div width={1} display="row.flex-start.center">
                <DatePicker
                  defaultValue={dateTo}
                  onChange={date => {
                    updateDataField({
                      value: moment(date).format(),
                      name: "date_to"
                    });
                  }}
                  labelProps={{
                    dateString: moment(dateTo).format("ddd, MMM DD, YYYY")
                  }}
                  Label={DateSpan}
                />
                <Text3 color="primary7" ml={2}>
                  + Add Event Date
                </Text3>
              </Div>
            </Div>
          </Div>
          <Div>
            <Div display="row.space-between" mt={3} mb={1}>
              <Text3 bold>Event Description</Text3>
              <Text1>{`${eventDescriptionLeft} / 250 rem`}</Text1>
            </Div>
            <TextAreaInput
              width={1}
              height={131}
              value={description}
              onChange={value =>
                updateDataField({ name: "description", value })
              }
              maxLength={250}
              continuous
            />
          </Div>
          <Wysiwyg
            text={terms}
            onChange={value => updateDataField({ name: "terms", value })}
          />
        </Div>
        <Div width={434} py={6}>
          <Div bra={1} bg="neutral1" p={2} width={1}>
            <Text3 bold mb={2}>
              Items to showcase
            </Text3>
            <Div width={1}>
              {R.map(type => {
                const quantity = variantsQuantity(type);
                if (quantity <= 0) {
                  return null;
                }
                return (
                  <Div
                    bra={1}
                    shadow={1}
                    width={1}
                    display="row.flex-start.center"
                    bg="white"
                    p={2}
                    key={type.id}
                  >
                    <Circle size={24} display="row.center.center">
                      {quantity}
                    </Circle>
                    <Text1 ml={2} bold>
                      {type.name}
                    </Text1>
                  </Div>
                );
              }, typesUpdated)}
            </Div>

            <Text2 color="primary7" mt={2} onClick={showTypesModal}>
              + Select More
            </Text2>
          </Div>
          <Div bra={1} mt={2} bg="neutral1" p={2} width={1}>
            <Text3 bold mb={2}>
              Link forms to site
            </Text3>
            <Text2 color="primary7" mt={2}>
              + Select forms
            </Text2>
          </Div>
          <Div bra={1} mt={2} bg="neutral1" px={3} py={4} width={1}>
            <Div display="row.space-between.center">
              <Text3 bold>Name of Venue/Location</Text3>
              <Text2 bold color="neutral5">
                *optional
              </Text2>
            </Div>
            <BigOutlineInput
              mt={1}
              width={1}
              bg="white"
              onChange={value => {
                updateDataField({ value, name: "venue_name" });
              }}
              value={venueName}
              continuous
            />
            <Div display="row.space-between.center" mt={2}>
              <Text3 bold>Address Line 1</Text3>
            </Div>
            <BigOutlineInput
              mt={1}
              width={1}
              bg="white"
              onChange={value => {
                updateDataField({ value, name: "venue_address_line_1" });
              }}
              value={venueAddress1}
              continuous
            />
            <Div display="row.space-between.center" mt={2}>
              <Text3 bold>Address Line 2</Text3>
            </Div>
            <BigOutlineInput
              mt={1}
              width={1}
              bg="white"
              onChange={value => {
                updateDataField({ value, name: "venue_address_line_2" });
              }}
              value={venueAddress2}
              continuous
            />
            <Text3 bold mt={2}>
              City
            </Text3>
            <BigOutlineInput
              mt={1}
              width={1}
              bg="white"
              onChange={value => {
                updateDataField({ value, name: "venue_address_city" });
              }}
              value={venueCity}
              continuous
            />
            <Div display="row.space-between.center" mt={2}>
              <Div width={217}>
                <Text3 bold>State/Region</Text3>
                <BigOutlineInput
                  mt={1}
                  width={1}
                  bg="white"
                  onChange={value => {
                    updateDataField({ value, name: "venue_address_state" });
                  }}
                  value={venueState}
                  continuous
                />
              </Div>
              <Div width={151}>
                <Text3 bold>Zip/Postal Code</Text3>
                <BigOutlineInput
                  mt={1}
                  width={1}
                  bg="white"
                  onChange={value => {
                    updateDataField({ value, name: "venue_address_zip" });
                  }}
                  value={venueZip}
                  continuous
                />
              </Div>
            </Div>
          </Div>
          <Div bra={1} mt={2} bg="neutral1" p={3} width={1}>
            <Text3 bold mb={2}>
              Event Images
            </Text3>
            <Div display="row.flex-start.center" style={{ flexWrap: "wrap" }}>
              {R.map(
                ({ url }) => (
                  <ImageFrame url={url} removeImage={removeImage} />
                ),
                images
              )}
            </Div>
            <Text2
              color="primary7"
              mt={2}
              onClick={() => uploadFile({ type: "images" })}
            >
              Upload Images
            </Text2>
          </Div>
          <Div bra={1} mt={2} bg="neutral1" p={3} width={1}>
            <Text3 bold mb={2}>
              Venue / Floor Map
            </Text3>
            {!R.isEmpty(floorMap.url) && (
              <Div
                width={119}
                height={83}
                bg="neutral3"
                display="row.center.center"
                style={{ overflow: "hidden" }}
              >
                <img src={floorMap.url} style={{ height: "110%" }} />
              </Div>
            )}
            <Div display="row.space-between.center">
              <Text2
                color="primary7"
                mt={2}
                onClick={() => uploadFile({ type: "floorMap" })}
              >
                Re-Upload Floor Map
              </Text2>
              <Text2 color="altB7" mt={2}>
                + Create Interactive FloorMap
              </Text2>
            </Div>
          </Div>
        </Div>
      </Div>
      <TypesModal />
    </Div>
  );
};

export default decorate(Layout);
