import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { Div, BigFilledInput, SearchIcon } from "components/Base";
import NotAccess from "./NotAccess";
import HasAccess from "./HasAccess";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    usersFilter: getters.usersFilter(state),
    loading: getters.loading(state)
  }),
  {
    init: actions.init,
    setUsersFilter: actions.setUsersFilter
  }
);

const Layout = ({
  init,
  usersFilter,
  setUsersFilter,
  loading,
  handlers,
  typeId,
  moduleId
}) => {
  useEffect(() => {
    init({
      typeId,
      moduleId
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Div px={6} pb={6} display="column" maxWidth={900}>
          <BigFilledInput
            bg="neutral1"
            LeftIcon={SearchIcon}
            color="neutral6"
            width={1}
            mt={2}
            continuous
            value={usersFilter}
            onChange={value => setUsersFilter(value)}
            placeholder="Search all users..."
          />
          {loading ? (
            <Loading />
          ) : (
            <>
              <HasAccess handlers={handlers} />
              <NotAccess handlers={handlers} />
            </>
          )}
        </Div>
      )}
    </>
  );
};

export default decorate(Layout);
