import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST, INVALIDATE } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const report = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          ["byReportType", action.payload.reportType],
          action.payload.report
        )
        .toJS();
    case INVALIDATE:
      return Immutable.fromJS(state)
        .deleteIn(["byReportType", action.payload.reportType])
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn(["byReportType", action.payload.reportType], true)
        .toJS();
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byReportType", action.payload.reportType], false)
        .toJS();
    case ERROR:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  report,
  fetching
});
