import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  records: [],
  moduleId: null,
  recordTypeId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    goToPortal: R.identity,
    setInitialData: (_, { payload: { records, modueId, recordTypeId } }) => ({
      records,
      modueId,
      recordTypeId
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
