import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StatusFilter from "./StatusFilter";
import Search from "./Search";

const Toolbar = ({
  onStatusFilterChange,
  activeFilter = "all",
  catalogItem,
  searchTerm,
  onSearchChange
}) => (
  <div styleName="toolbar">
    <StatusFilter onMenuSelect={onStatusFilterChange}>
      <div styleName="dropdown">
        Status:&nbsp;
        <span styleName="flexCenter">
          <div styleName="activeFilter">{activeFilter}</div>
          <i className="material-icons">arrow_drop_down</i>
        </span>
      </div>
    </StatusFilter>
    <Search
      onChange={onSearchChange}
      placeholder="Search..."
      value={searchTerm}
      className={css.searchInput}
    />
  </div>
);

Toolbar.propTypes = {
  onStatusFilterChange: PropTypes.func.isRequired
};

export default CSSModules(Toolbar, css);
