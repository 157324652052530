import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import PATTERNS from "@lennd/value-types/src/filter/patterns";
import { Div, MultiSelect } from "components/Base";

const {
  IS,
  IS_NOT,
  IS_EMPTY,
  IS_NOT_EMPTY,
  CONTAINS,
  DOES_NOT_CONTAIN,
  IS_CHECKED,
  IS_NOT_CHECKED
} = PATTERNS;

const Pattern = ({ onChange, selected, label, value, children }) => (
  <div>
    <RadioButtonGroup
      name={value}
      className={css.radioGroup}
      onChange={onChange}
      valueSelected={selected}
    >
      <RadioButton value={value} label={label} />
    </RadioButtonGroup>
    {selected === value ? children : null}
  </div>
);

const Patterns = CSSModules(
  ({ field, pattern, handlePatternChange, query, handleQueryChange }) => {
    const QueryInput = (
      <input
        type="text"
        styleName="filterInput"
        onChange={handleQueryChange}
        value={query}
      />
    );
    const IsEmpty = (
      <Pattern
        label="is empty"
        value={IS_EMPTY.pattern}
        onChange={handlePatternChange}
        selected={pattern}
      />
    );
    const IsNotEmpty = (
      <Pattern
        label="is not empty"
        value={IS_NOT_EMPTY.pattern}
        onChange={handlePatternChange}
        selected={pattern}
      />
    );

    switch (field.type) {
      case "checkbox":
        return (
          <div>
            <Pattern
              label="is checked"
              value={IS_CHECKED.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            />
            <Pattern
              label="is not checked"
              value={IS_NOT_CHECKED.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            />
          </div>
        );
      case "dropdown": {
        const MultipleSelect = (
          <MultiSelect
            menuPlacement="top"
            onChange={values =>
              handleQueryChange({ target: { value: values } })
            }
            value={query}
            options={field.settings.options.map(o => ({
              value: o.id,
              label: o.value,
              color: o.backgroundColor
            }))}
          />
        );
        return (
          <div>
            <Pattern
              label="contains"
              value={CONTAINS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              <Div pb={2}>{MultipleSelect}</Div>
            </Pattern>
            <Pattern
              label="does not contain"
              value={DOES_NOT_CONTAIN.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              <Div pb={2}>{MultipleSelect}</Div>
            </Pattern>
            {IsEmpty}
            {IsNotEmpty}
          </div>
        );
      }
      case "payment": {
        const MultipleSelect = (
          <MultiSelect
            menuPlacement="top"
            onChange={values =>
              handleQueryChange({ target: { value: values } })
            }
            value={query}
            options={[
              {
                id: "no-payment-necessary",
                value: "No payment necessary"
              },
              {
                id: "no-payment-against-balance",
                value: "No payment against balance"
              },
              {
                id: "remaining-balance",
                value: "Has remaining balance"
              },
              {
                id: "paid",
                value: "Paid"
              }
            ].map(o => ({
              value: o.id,
              label: o.value
            }))}
          />
        );
        return (
          <div>
            <Pattern
              label="is"
              value={IS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              <Div pb={2}>{MultipleSelect}</Div>
            </Pattern>
            <Pattern
              label="is not"
              value={IS_NOT.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {<Div pb={2}>{MultipleSelect}</Div>}
            </Pattern>
          </div>
        );
      }
      case "document-request": {
        const MultipleSelect = (
          <MultiSelect
            menuPlacement="top"
            onChange={values =>
              handleQueryChange({ target: { value: values } })
            }
            value={query}
            options={[
              {
                id: "not-submitted",
                value: "Not Submitted"
              },
              {
                id: "pending",
                value: "Pending"
              },
              {
                id: "approved",
                value: "Approved"
              }
            ].map(o => ({
              value: o.id,
              label: o.value
            }))}
          />
        );
        return (
          <div>
            <Pattern
              label="is"
              value={IS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              <Div pb={2}>{MultipleSelect}</Div>
            </Pattern>
            <Pattern
              label="is not"
              value={IS_NOT.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {<Div pb={2}>{MultipleSelect}</Div>}
            </Pattern>
          </div>
        );
      }
      case "health-pass": {
        const MultipleSelect = (
          <MultiSelect
            menuPlacement="top"
            onChange={values =>
              handleQueryChange({ target: { value: values } })
            }
            value={query}
            options={[
              {
                id: "not-assigned",
                value: "Not Assigned"
              },
              {
                id: "not-submitted",
                value: "Not Submitted"
              },
              {
                id: "expired",
                value: "Expired"
              },
              {
                id: "approved",
                value: "Approved"
              },
              {
                id: "denied",
                value: "Denied"
              }
            ].map(o => ({
              value: o.id,
              label: o.value
            }))}
          />
        );
        return (
          <div>
            <Pattern
              label="is"
              value={IS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              <Div pb={2}>{MultipleSelect}</Div>
            </Pattern>
            <Pattern
              label="is not"
              value={IS_NOT.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {<Div pb={2}>{MultipleSelect}</Div>}
            </Pattern>
          </div>
        );
      }
      case "form": {
        const MultipleSelect = (
          <MultiSelect
            menuPlacement="top"
            onChange={values =>
              handleQueryChange({ target: { value: values } })
            }
            value={query}
            options={[
              {
                id: "not-submitted",
                value: "Not Submitted"
              },
              {
                id: "pending",
                value: "Pending"
              },
              {
                id: "rejected",
                value: "Denied"
              },
              {
                id: "approved",
                value: "Approved"
              }
            ].map(o => ({
              value: o.id,
              label: o.value
            }))}
          />
        );
        return (
          <div>
            <Pattern
              label="is"
              value={IS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              <Div pb={2}>{MultipleSelect}</Div>
            </Pattern>
            <Pattern
              label="is not"
              value={IS_NOT.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {<Div pb={2}>{MultipleSelect}</Div>}
            </Pattern>
          </div>
        );
      }
      case "file":
      case "allocated-meals":
      case "allocated-passes": {
        return (
          <div>
            {IsEmpty}
            {IsNotEmpty}
          </div>
        );
      }
      case "has-portal-access": {
        return (
          <div>
            <Pattern
              label="yes"
              value={IS_CHECKED.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            />
            <Pattern
              label="no"
              value={IS_NOT_CHECKED.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            />
          </div>
        );
      }
      case "simple-text":
        return (
          <div>
            <Pattern
              label="contains"
              value={CONTAINS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {QueryInput}
            </Pattern>
          </div>
        );
      default:
        return (
          <div>
            {/*
            <Pattern
              label="is"
              value={IS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {QueryInput}
            </Pattern>
            <Pattern
              label="is not"
              value={IS_NOT.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {QueryInput}
            </Pattern>
            */}
            <Pattern
              label="contains"
              value={CONTAINS.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {QueryInput}
            </Pattern>
            <Pattern
              label="does not contain"
              value={DOES_NOT_CONTAIN.pattern}
              onChange={handlePatternChange}
              selected={pattern}
            >
              {QueryInput}
            </Pattern>
            {IsEmpty}
            {IsNotEmpty}
          </div>
        );
    }
  },
  css
);

Patterns.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string
  }).isRequired,
  query: PropTypes.string,
  pattern: PropTypes.string.isRequired,
  handleQueryChange: PropTypes.func.isRequired,
  handlePatternChange: PropTypes.func.isRequired
};

export default Patterns;
