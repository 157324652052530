import Helpers from "utils/Global/Helpers";
import { Quill } from "react-quill";

const Delta = Quill.import("delta");

export function filepickerImageHandler() {
  const onSuccess = (files = []) => {
    if (files.length) {
      const range = this.quill.getSelection(true);
      this.quill.updateContents(
        new Delta()
          .retain(range.index)
          .delete(range.length)
          .insert({ image: files[0].url })
      );
    }
  };
  Helpers.getFilepicker({}, { path: "portal-images/" }, onSuccess);
}
