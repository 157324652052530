import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Orders/CreateOrderModal/SelectItems/constants";

const iniState = {
  loading: false,
  filterItemType: "",
  filterItem: ""
};

const model = createHandlers({
  iniState,
  reducers: {},
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
