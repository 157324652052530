import React, { cloneElement, Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Internal from "./Internal";

import RecordTypeModal from "components/Event/Settings/Module/Modals/RecordType";
import DeleteRecordTypeModal from "components/Event/Settings/Module/Modals/DeleteRecordType";

import { difference, find, map, prop, propEq } from "ramda";

class External extends Component {
  componentDidMount() {
    this.props.getModuleRecordTypes();
  }

  componentDidUpdate(prevProps) {
    const { selectedRecordType, recordTypes } = this.props;

    // set active pset to newest when adding one
    if (
      selectedRecordType &&
      recordTypes.length < prevProps.recordTypes.length
    ) {
      const mapId = map(prop("id"));

      const selected = find(
        propEq(
          "id",
          difference(mapId(recordTypes), mapId(prevProps.recordTypes))[0]
        )
      )(this.props.recordTypes);

      this.setActiveRecordType(selected ? selected.id : null);
    }
  }

  showRecordTypeModal = recordType => {
    this.props.showModal({
      content: (
        <RecordTypeModal
          onSave={
            recordType
              ? this.handleUpdateRecordType
              : this.handleCreateRecordType
          }
          recordType={recordType}
          moduleId={this.props.params.moduleId}
          recordName={this.props.module.record_name}
          eventId={this.props.eventId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleCreateRecordType = async data => {
    const { layout } = await this.props.addLayout({
      moduleId: this.props.params.moduleId,
      layout: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.props.addRecordType({
      moduleId: this.props.params.moduleId,
      recordType: {
        ...data,
        layoutId: layout.id
      },
      options: {
        eventId: this.props.eventId
      }
    });

    this.props.showSnackbar({ message: "Type added", action: "OK" });
    return this.props.getModuleRecordTypes();
  };

  handleUpdateRecordType = data => {
    this.props
      .updateRecordType({
        moduleId: this.props.params.moduleId,
        recordType: data,
        options: {
          eventId: this.props.eventId
        }
      })
      .then(() => {
        this.props.getModuleRecordTypes();
        this.props.showSnackbar({ message: "Type updated", action: "OK" });
      });
  };

  showDeleteRecordTypeModal = recordType => {
    this.props.showModal({
      content: (
        <DeleteRecordTypeModal
          onSave={this.handleDeleteRecordType}
          recordType={recordType}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleDeleteRecordType = data => {
    this.props
      .deleteRecordType({
        moduleId: this.props.params.moduleId,
        recordType: data,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      })
      .then(() => {
        this.props.getModuleRecordTypes();
        this.props.showSnackbar({ message: "Type deleted", action: "OK" });
      });
  };

  handleUpdateDefaultRecordType = recordType => {
    this.props
      .updateDefaultRecordType({
        moduleId: this.props.params.moduleId,
        recordType,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      })
      .then(() => {
        this.props.getModuleRecordTypes();
        this.props.showSnackbar({
          message: "Type marked as default",
          action: "OK"
        });
      });
  };

  setActiveRecordType = typeId => {
    this.props.router.push({
      pathname: typeId
        ? `/event/${this.props.params.eventId}/settings/module/${
            this.props.params.moduleId
          }/types/${typeId}`
        : `/event/${this.props.params.eventId}/settings/module/${
            this.props.params.moduleId
          }/types`
    });
  };

  setActiveRecordTypeSection = sufPath => {
    const pathname = `/event/${this.props.params.eventId}/settings/module/${
      this.props.params.moduleId
    }/types/${this.props.selectedRecordType.id}/${sufPath}`;
    this.props.router.push({ pathname });
  };

  render() {
    const { recordTypes, selectedRecordType, routes, module } = this.props;

    const methods = {
      showRecordTypeModal: this.showRecordTypeModal,
      showDeleteRecordTypeModal: this.showDeleteRecordTypeModal,
      handleUpdateDefaultRecordType: this.handleUpdateDefaultRecordType,
      setActiveRecordType: this.setActiveRecordType,
      setActiveRecordTypeSection: this.setActiveRecordTypeSection
    };

    const childProps = {
      recordTypes,
      selectedRecordType,
      selectedModule: module,
      routes
    };
    return <Internal {...childProps} {...methods} />;
  }
}

export default External;
