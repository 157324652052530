import * as R from "ramda";
import { createSelector } from "reselect";
import { addS } from "utils/General";
import { getters } from "./model";

export const getItemsList = createSelector(
  getters.items,
  getters.typeId,
  R.compose(
    R.filter(item => !R.isEmpty(item)),
    R.split("\n")
  )
);

export const getItemsBtnText = createSelector(
  getItemsList,
  itemsList => {
    const itemsLength = R.length(itemsList);
    if (itemsLength === 0) {
      return "Add items";
    }
    return `${itemsLength} item${addS(itemsLength)}`;
  }
);

export const getIsBtnDisabled = createSelector(
  getItemsList,
  itemsList => {
    return R.length(itemsList) < 1;
  }
);

export const getGroupsOptions = createSelector(
  getters.groups,
  R.map(({ id, name }) => ({ label: name, value: id }))
);
