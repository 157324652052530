import { REQUEST, RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getWorkflow(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST,
        payload: {
          worklowId: data.workflowId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);

      dispatch({
        type: RECEIVE,
        payload: {
          worklowId: data.workflowId,
          workflow: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting workflow"
          }
        ])
      );
    }
  };
}

export function addWorkflow(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding workflow"
          }
        ])
      );
    }
  };
}

export function updateWorkflow(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating workflow"
          }
        ])
      );
    }
  };
}

export function updateWorkflows(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, {
        ...data,
        bulk: true
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating workflows"
          }
        ])
      );
    }
  };
}

export function deleteWorkflow(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting workflow"
          }
        ])
      );
    }
  };
}
