import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import {
  CREATE as CREATE_VALUE,
  BULK_CREATE as BULK_CREATE_VALUE
} from "redux/modules/accounts/values/constants";

const list = (state = { preferences: {}, accounts: [] }, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload ? action.payload.result.result : state;

    case CREATE_VALUE: {
      const indexOfRecord = state.accounts.findIndex(
        r => r.id === action.payload.accountId
      );

      if (indexOfRecord === -1) {
        return state;
      }

      return Immutable.fromJS(state)
        .setIn(
          ["accounts", indexOfRecord, "values", action.payload.fieldId],
          action.payload.value
        )
        .toJS();
    }

    case BULK_CREATE_VALUE: {
      return action.payload.values
        .reduce((immutableState, { accountId, fieldId, value }) => {
          const indexOfRecord = state.accounts.findIndex(
            r => r.id === accountId
          );
          if (indexOfRecord === -1) {
            return immutableState;
          }
          return immutableState.setIn(
            ["accounts", indexOfRecord, "values", fieldId],
            value
          );
        }, Immutable.fromJS(state))
        .toJS();
    }

    case INVALIDATE:
      return {
        preferences: {},
        accounts: []
      };

    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  error,
  fetching
});
