import { sort as stableSort } from "timsort";
import { get } from "lodash";
import resolveOnClickPrimaryField from "components/Global/ReactTable/CellFormatters/PrimaryField/resolveOnClick";

export default (
  fields = [],
  { isFieldVisible, getFieldOrder, getFieldWidth, meta }
) => {
  const fieldDefinitions = fields.map(c => {
    const field = { ...c };
    field.visible = isFieldVisible(c);
    field.order = getFieldOrder(c);
    field.width = getFieldWidth(c);
    if (field.is_primary_field) {
      field.onClick = resolveOnClickPrimaryField({
        orgId: meta.orgId,
        eventId: meta.eventId,
        moduleId: meta.moduleId,
        recordId: meta.recordId,
        helpers: meta.helpers
      });
    }
    return field;
  });

  stableSort(
    fieldDefinitions,
    (a, b) => get(a, "order", 1) - get(b, "order", 1)
  );

  return fieldDefinitions;
};
