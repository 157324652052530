import { RECEIVE, REQUEST } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getItemGroupsByEventAndType(eventId, typeId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getItemGroupsByEventAndType(
        getState().user.user.credentials,
        eventId,
        typeId
      );
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item groups"
          }
        ])
      );
    }
  };
}

export function addItemGroup(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addItemGroup(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding item group"
          }
        ])
      );
    }
  };
}

export function updateItemGroup(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateItemGroup(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item group"
          }
        ])
      );
    }
  };
}

export function bulkUpdateItemGroups(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkUpdateItemGroups(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred bulk updating item groups"
          }
        ])
      );
    }
  };
}

export function deleteItemGroup(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteItemGroup(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting item group"
          }
        ])
      );
    }
  };
}
