import React, { useState } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import InputGroup from "components/Global/Modal/Layout/FormElements/InputGroup";
import Label from "components/Global/Modal/Layout/FormElements/Label";
import { Dropdown } from "components/Base";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import SingleEditor from "components/Global/Editors/utils/SingleEditor";
import { noop } from "utils/General";

const BulkEditModal = ({
  columns = [],
  onSave = noop,
  hideModal = noop,
  getMetaData = noop,
  selected = []
}) => {
  const [value, setValue] = useState(null);
  const [field, setField] = useState(null);

  const validateForm = () => Boolean(field);

  const update = e => {
    e.preventDefault();
    if (validateForm()) {
      onSave({ fieldId: field.id, value }, selected);
      hideModal();
    }
  };

  const handleFieldChange = selected => {
    const field = selected ? columns.find(c => c.id === selected.value) : null;
    setValue(null);
    setField(field);
  };

  const handleFieldValueChange = value => {
    setValue(value);
  };

  return (
    <StyleWrapper
      hideModal={hideModal}
      heading="Edit"
      width={410}
      containerStyles={{ overflowY: "visible" }}
    >
      <span>
        <form onSubmit={update}>
          <InputGroup>
            <Label>Field to update</Label>
            <Dropdown
              arrowRenderer={ArrowRenderer}
              clearable={false}
              onChange={handleFieldChange}
              /* eslint-disable no-unused-vars */
              options={[...columns].map(({ label, ...c }) => ({
                label: c.name,
                value: c.id,
                ...c
              }))}
              placeholder="Select a field to edit"
              value={field ? field.id : null}
              usePortal
            />
          </InputGroup>
          {field && (
            <InputGroup className={css.inputGroupCustom}>
              <Label>{field.name}</Label>
              <SingleEditor
                onChange={handleFieldValueChange}
                field={field}
                metaData={getMetaData({}, field)}
                value={value}
              />
            </InputGroup>
          )}
          <ButtonGroup>
            <Submit type="submit" disabled={!validateForm()} title="Update" />
            <ButtonOutline onClick={hideModal} title="Cancel" />
          </ButtonGroup>
        </form>
      </span>
    </StyleWrapper>
  );
};

export default CSSModules(css)(BulkEditModal);
