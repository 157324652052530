import React from "react";

import { connect } from "react-redux";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Loading from "ui-kit/Loading";

import {
  Div,
  BigFilledButton,
  Span,
  BigOutlineButton,
  Dropdown
} from "components/Base";
import { addS } from "utils/General";

import { getters, actions } from "../index";

const FORMATS = [
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "XLSX",
    value: "xlsx"
  },
  {
    label: "HTML",
    value: "html"
  }
];

const decorate = connect(
  state => ({
    recordIds: getters.recordIds(state),
    reports: getters.reports(state),
    loading: getters.loading(state),
    processing: getters.processing(state),
    selectedReport: getters.selectedReport(state),
    selectedFormat: getters.selectedFormat(state)
  }),
  {
    goToModuleReports: actions.goToModuleReports,
    runReport: actions.runReport,
    selectReport: value =>
      actions.setSelectedReport(value ? value.value : null),
    selectFormat: value => actions.setSelectedFormat(value ? value.value : null)
  }
);

const Layout = ({
  recordIds,
  reports,
  hideModal,
  processing,
  loading,
  runReport,
  selectReport,
  selectedReport,
  selectFormat,
  selectedFormat,
  goToModuleReports
}) => (
  <StyleWrapper
    bodyStyles={{ padding: 0 }}
    containerStyles={{ overflowY: "hidden" }}
    heading={
      recordIds.length
        ? `Run report for ${recordIds.length} record${addS(recordIds.length)} `
        : "Run report"
    }
    hideModal={hideModal}
    width={450}
  >
    {loading ? (
      <Div display="row.center.center" width={1} height={1}>
        <Loading />
      </Div>
    ) : (
      <Div
        width={1}
        style={{
          padding: "20px 30px 20px 30px"
        }}
        bg="neutral0"
      >
        <Div display="column" mb={4}>
          <Div display="row.space-between.center" mb={1}>
            <Div fs={4} fw={3} color="black">
              Report Template
            </Div>
            <Div fs={2} onClick={goToModuleReports} color="purple8">
              Manage templates
            </Div>
          </Div>

          {reports.length ? (
            <Dropdown
              options={reports}
              selected={selectedReport}
              onChange={selectReport}
              usePortal
            />
          ) : (
            <Div>No reports created</Div>
          )}
        </Div>

        <Div display="column" mb={1}>
          <Div fs={4} fw={3} color="black" mr={3} mb={1}>
            Format
          </Div>
          <Dropdown
            options={FORMATS}
            selected={selectedFormat}
            onChange={selectFormat}
            usePortal
          />
        </Div>
      </Div>
    )}
    <Div
      width={1}
      bt={1}
      bc="neutral1"
      display="row.flex-start.center"
      px={4}
      height={80}
    >
      <BigFilledButton
        bg="altB4"
        color="white"
        mr={3}
        onClick={() => runReport({ onDone: hideModal })}
        disabled={loading || processing || !selectedFormat || !selectedReport}
      >
        {processing ? "Running..." : "Run Report"}
      </BigFilledButton>

      <BigOutlineButton width={121} onClick={hideModal}>
        Cancel
      </BigOutlineButton>
    </Div>
  </StyleWrapper>
);

export default decorate(Layout);
