import { createModule } from "redux-mvc";

import formModule from "ui-kit/Form";
import tabsModule from "ui-kit/Tabs";
import searchbarModule from "ui-kit/SearchBar";

import model from "./model";

import rootSaga from "./sagas";

const module = createModule(model);

module.setRootSaga(rootSaga);
module.plugModule(formModule);
module.plugModule(tabsModule);
module.plugModule(searchbarModule);

export default module;
