import React, { Component } from "react";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddEditColumnModal from "Modules/AddEditColumnModal/View";
import ModalColumnDelete from "components/Global/Table3/ModalColumnDelete";
import FieldGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import DeleteFieldGroupModal from "components/Event/Settings/Module/Modals/DeleteFieldGroup";
import DeleteModuleModal from "components/Event/Settings/Modals/DeleteModule";
import Internal from "./Internal";

class ExternalHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !props.hasFetchedModule
    };
  }

  componentDidMount() {
    this.setScene(this.props.params.moduleId);
  }

  componentDidUpdate(prevProps) {
    const differentModule =
      prevProps.params.moduleId !== this.props.params.moduleId;

    if (!this.state.loading && differentModule) {
      this.setState(
        {
          loading: true
        },
        () => {
          this.setScene(this.props.params.moduleId);
        }
      );
    }
  }

  getBasePath = () =>
    this.props.params.orgId
      ? `/organization/${this.props.params.orgId}`
      : `/event/${this.props.params.eventId}`;

  fetchEvent = () => {
    this.getModule();
    return this.props.getEvent(this.props.params.eventId);
  };

  setScene = moduleId => {
    this.getModule(moduleId).then(() => {
      this.setState({
        loading: false
      });
      return null;
    });
  };

  getModule = moduleId =>
    this.props.getModule({
      moduleId: moduleId || this.props.params.moduleId,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

  showEmptyValueError = () => {
    this.props.showSnackbar({
      message: "Oops! Cannot save empty value.",
      action: "OK"
    });
  };

  // //////////////////////////////////////////////////////////////////////////////

  showCreateFieldGroupModal = () => {
    this.props.showModal({
      content: (
        <FieldGroupModal
          label="field group"
          onSave={this.handleCreateFieldGroup}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleCreateFieldGroup = data => {
    const { params, showSnackbar, addFieldGroup } = this.props;
    const { moduleId, orgId, eventId } = params;
    addFieldGroup({
      moduleId,
      group: data,
      options: {
        orgId,
        eventId
      }
    }).then(() => {
      showSnackbar({ message: "Field group added", action: "OK" });
      return this.getModule();
    });
  };

  showFieldGroupModal = fieldGroup => {
    this.props.showModal({
      content: (
        <FieldGroupModal
          data={{
            ...fieldGroup
          }}
          onSave={this.handleUpdateFieldGroup}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleUpdateFieldGroup = data => {
    const { params, showSnackbar, updateFieldGroup } = this.props;
    const { moduleId, orgId, eventId } = params;
    updateFieldGroup({
      moduleId,
      group: data,
      options: {
        orgId,
        eventId
      }
    }).then(() => {
      showSnackbar({ message: "Field group updated", action: "OK" });
      return this.getModule();
    });
  };

  showDeleteFieldGroupModal = fieldGroup => {
    const modal = (
      <DeleteFieldGroupModal
        name={fieldGroup.name}
        handleDelete={() => this.handleDeleteFieldGroup(fieldGroup)}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleDeleteFieldGroup = fieldGroup => {
    const { params, showSnackbar, deleteFieldGroup } = this.props;
    const { moduleId, orgId, eventId } = params;
    deleteFieldGroup({
      moduleId,
      group: fieldGroup,
      options: {
        orgId,
        eventId
      }
    }).then(() => {
      showSnackbar({ message: "Field group deleted", action: "OK" });
      return this.getModule();
    });
  };

  showCreateFieldModal = () => {
    const modal = (
      <AddEditColumnModal
        orgId={this.props.params.orgId}
        eventId={this.props.params.eventId}
        moduleId={this.props.params.moduleId}
        onSave={this.handleCreateField}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleCreateField = () => {
    this.props.showSnackbar({ message: "Field added", action: "OK" });
    return this.getModule();
  };

  showUpdateFieldModal = field => {
    const modal = (
      <AddEditColumnModal
        orgId={this.props.params.orgId}
        eventId={this.props.params.eventId}
        moduleId={this.props.params.moduleId}
        fieldId={field.id}
        onSave={() => this.handleUpdateField()}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleUpdateField = () => {
    this.props.showSnackbar({ message: "Field updated", action: "OK" });
    return this.getModule();
  };

  showDeleteFieldModal = field => {
    const modal = (
      <ModalColumnDelete
        column={field}
        handleDeleteColumn={this.handleDeleteField}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleDeleteField = async data => {
    const { params, showSnackbar, deleteField } = this.props;
    const { moduleId, orgId, eventId } = params;
    await deleteField({
      moduleId,
      field: {
        id: data.columnId
      },
      options: {
        orgId,
        eventId
      }
    });

    showSnackbar({ message: "Field deleted", action: "OK" });
    return this.getModule();
  };

  removePrimary = async field => {
    const { params } = this.props;
    const { moduleId, orgId, eventId } = params;
    await this.props.deletePrimaryField({
      moduleId,
      orgId,
      eventId,
      fieldId: field.id,
      options: { orgId, eventId }
    });

    this.getModule();
  };

  addPrimary = async field => {
    const { params } = this.props;
    const { moduleId, orgId, eventId } = params;
    await this.props.addPrimaryField({
      moduleId,
      orgId,
      eventId,
      fieldId: field.id,
      options: {
        orgId,
        eventId
      }
    });

    return this.getModule();
  };

  handleUpdateModule = async data => {
    const { params, updateModule } = this.props;
    const { orgId, eventId } = params;

    await updateModule({
      ...data,
      orgId,
      eventId,
      moduleId: this.props.module.id
    });
    await this.fetchEvent();
  };

  showDeleteModuleModal = () => {
    this.props.showModal({
      content: (
        <DeleteModuleModal
          hideModal={this.props.hideModal}
          onDelete={this.handleDeleteModule}
          moduleId={this.props.module.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleDeleteModule = async moduleId => {
    const { router, deleteModule, showSnackbar } = this.props;

    await deleteModule({ moduleId });
    await this.fetchEvent();
    router.replace(`${this.getBasePath()}/settings`);
    return showSnackbar({ message: "Module deleted", action: "OK" });
  };

  updatePath = sufPath => {
    const pathname = `${this.getBasePath()}/settings/module/${
      this.props.params.moduleId
    }/${sufPath}`;
    this.props.router.push(pathname);
  };

  render() {
    return <Internal {...this} {...this.props} loading={this.state.loading} />;
  }
}

export default ExternalHandler;
