import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Feed from "./Feed";
import { getActivity } from "redux/modules/modules/activity/actions";
import {
  getMessages,
  addMessage,
  updateMessage,
  deleteMessage
} from "redux/modules/modules/messages/actions";
import {
  getNotes,
  addNote,
  updateNote,
  deleteNote
} from "redux/modules/modules/notes/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import * as UserSelectors from "redux/modules/user/selectors";
import * as ModuleActivitySelectors from "redux/modules/modules/activity/selectors";
import * as ModuleMessagesSelectors from "redux/modules/modules/messages/selectors";
import * as ModuleNotesSelectors from "redux/modules/modules/notes/selectors";

function mapStateToProps(state, props) {
  return {
    activity: ModuleActivitySelectors.activity(
      state,
      props.moduleId,
      props.recordId
    ),
    messages: ModuleMessagesSelectors.messages(
      state,
      props.moduleId,
      props.recordId
    ),
    notes: ModuleNotesSelectors.notes(state, props.moduleId, props.recordId),
    isFetchingMessages: ModuleMessagesSelectors.isFetching(
      state,
      props.moduleId,
      props.recordId
    ),
    isFetchingActivity: ModuleActivitySelectors.isFetching(
      state,
      props.moduleId,
      props.recordId
    ),
    isFetchingNotes: ModuleNotesSelectors.isFetching(
      state,
      props.moduleId,
      props.recordId
    ),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addMessage,
      addNote,
      deleteMessage,
      deleteNote,
      getActivity,
      getMessages,
      getNotes,
      updateMessage,
      updateNote,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
