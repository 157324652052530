import { registerError } from "redux/modules/errors/actions";
import { RECEIVE } from "./constants";
import api from "./api";

export function getResources(resourceType) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(
        getState().user.user.credentials,
        resourceType
      );
      dispatch({
        type: RECEIVE,
        payload: {
          data: result.payload,
          resourceType
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting resources"
          }
        ])
      );
    }
  };
}
