import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import * as R from "ramda";
import moment from "moment";
import createDateRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";
import View from "./View";
import Overview from "./Scenes/Overview";
import Dates from "./Scenes/Dates";
import Inventory from "./Scenes/Inventory";
import Prices from "./Scenes/Prices";
import Variants from "./Scenes/Variants";
import Questions from "./Scenes/Questions/View";
import Zones from "./Scenes/Zones";
import update from "immutability-helper";
import { camelcaseObjKeys, capitalize } from "utils/General";
import { CONTACTS } from "utils/standard-module-field-ids";
import { INTEGRATIONS_BY_ID } from "utils/integration-types";
import AddSingleDayVariantModal from "components/Event/Settings/Catalog/Modals/ItemType/Modals/AddSingleDayVariant";
import AddMultiDayVariantModal from "components/Event/Settings/Catalog/Modals/ItemType/Modals/AddMultiDayVariant";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CredentialTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";
import { FEATURES } from "./constants";
import Helpers from "utils/Global/Helpers";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import { connect } from "react-redux";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  formatAmountForEvent: formatAmountForEvent(state)
}));

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: ["image/*"]
    };

    const path = { path: "item-catalog/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const isIntegrationItem = ({ provider_id } = {}) =>
  Boolean(INTEGRATIONS_BY_ID[provider_id]);

const integrationName = item =>
  isIntegrationItem(item) &&
  R.pathOr(null, [item.provider_id, "name"])(INTEGRATIONS_BY_ID);

const integrationItemName = item =>
  isIntegrationItem(item) &&
  R.pathOr(null, ["integration_item", "meta", "name"])(item);

const integrationCountOfAvailable = item =>
  isIntegrationItem(item) &&
  R.pathOr(null, ["integration_item", "meta", "quantity_available"])(item);

const convertDatesToRules = (dates = []) =>
  dates.reduce((list, { start, end }) => {
    for (let m = moment(start).utc(); m.isSameOrBefore(end); m.add(1, "days")) {
      list.push({
        pattern: "is_valid_for",
        key: "valid_date",
        valueType: "date",
        value: m.format("YYYY-MM-DD")
      });
    }
    return list;
  }, []);

const REQUIRED_FIELDS = [
  {
    id: CONTACTS.FIRST_NAME,
    label: "First name"
  },
  {
    id: CONTACTS.LAST_NAME,
    label: "Last name"
  },
  {
    id: CONTACTS.EMAIL,
    label: "Email"
  },
  {
    id: CONTACTS.MOBILE_PHONE,
    label: "Phone number"
  },
  {
    id: CONTACTS.AVATAR_URL,
    label: "Photo (Headshot)"
  }
];

const formatInventoryQuantity = value => {
  if (
    typeof value === "undefined" ||
    value === null ||
    !String(value).length ||
    parseInt(value, 10) < 0
  ) {
    return null;
  }
  return value;
};

const variantLens = R.pathOr({}, ["variants", 0]);

const formatVariantOrder = variants =>
  R.compose(
    R.reduce((map, v, idx) => {
      map[v.id] = idx;
      return map;
    }, {}),
    R.sortBy(R.prop("order"))
  )(variants);

const buildExistingItemState = (item, variantId, clone, iniValues = {}) => {
  const variant = variantId
    ? item.variants.find(v => v.id === variantId)
    : variantLens(item);

  return {
    order: variant.order || undefined,
    accessType: variant.tracking_method || "",
    accountPermissions: {},
    color: item.background_color || "",
    dates: variant.rules
      ? createDateRanges(
          variant.rules.map(rule => moment(rule.value).format("YYYY-MM-DD"))
        )
      : [],
    requiredFields: item.required_fields
      ? item.required_fields.map(f => f.field_id)
      : [],
    description: item.description,
    groupId: item.group_id,
    trackInventory: Boolean(variant.track_inventory),
    assignInventoryOnApproval: R.propOr(
      false,
      "assign_inventory_on_approval",
      variant
    ),
    inventoryQuantity: isNaN(parseInt(variant.inventory_quantity, 10))
      ? 1
      : parseInt(variant.inventory_quantity, 10),
    name: variantId
      ? variant.name || ""
      : clone
      ? `Copy of ${item.name}`
      : item.name || "",
    photoUrl: item.photo_url,
    peoplePermissions: {},
    zones: variant.zones || [],
    enabledPrices: variant.enable_prices,
    prices: variant.prices,
    isIntegrationItem: isIntegrationItem(variant),
    integrationName: integrationName(variant),
    integrationItemName: integrationItemName(variant),
    integrationCountOfAvailable: integrationCountOfAvailable(variant),
    showInAssignmentManager: item.show_in_assignment_manager,
    showInPortalReports: item.show_in_portal_reports,
    allowMultiplePerPersonInAssignmentManager:
      item.allow_multiple_per_person_in_assignment_manager,
    allowAccessToVirtualEvent:
      item.allow_access_to_virtual_event || iniValues.allowAccessToVirtualEvent,
    hideOnRegistrationPage: item.hide_on_registration_page,
    variants: item.variants || [],
    variantOrder: formatVariantOrder(item.variants || [])
  };
};

class InternalHandler extends Component {
  constructor(props) {
    super(props);
    const activeTab = "overview";
    const iniValues = props.iniValues || {};

    this.modified = false;

    if (props.mode === "create") {
      // state: create
      this.state = {
        order: undefined,
        accessType: "",
        accountPermissions: {},
        activeTab,
        color: "",
        dates: [],
        description: "",
        enabledPrices: false,
        groupId: null,
        integrationCountOfAvailable: integrationCountOfAvailable(),
        integrationItemName: integrationItemName(),
        integrationName: integrationName(),
        inventoryQuantity: 1,
        isIntegrationItem: isIntegrationItem(),
        loading: false,
        saving: false,
        name: "",
        photoUrl: null,
        peoplePermissions: {},
        prices: [],
        trackInventory: false,
        zones: [],
        requiredFields: [],
        showInAssignmentManager: true,
        showInPortalReports: true,
        allowMultiplePerPersonInAssignmentManager: false,
        allowAccessToVirtualEvent: iniValues.allowAccessToVirtualEvent || false,
        hideOnRegistrationPage: false,
        variants: [],
        assignInventoryOnApproval: false
      };
    } else {
      // state: update or clone
      this.state = {
        activeTab,
        loading: true,
        saving: false,
        ...buildExistingItemState(
          props.item,
          props.variantId,
          props.clone,
          iniValues
        )
      };
    }
  }

  async componentDidMount() {
    const { mode } = this.props;
    if (["update", "clone"].includes(mode)) {
      await this.props.fetchItem();
      this.setState({ loading: false });
    }
    this.props.getItemGroups();
  }

  componentDidUpdate(oldProps) {
    if (!isEqual(this.props.item, oldProps.item)) {
      this.setState({
        ...buildExistingItemState(
          this.props.item,
          this.props.variantId,
          this.props.clone,
          this.props.iniValues
        )
      });
    }
    if (
      this.props.groups &&
      this.props.groups.length &&
      this.props.groups[0].type_id === this.props.typeId &&
      ((!isEqual(this.props.groups, oldProps.groups) &&
        !this.state.groupId &&
        this.props.groups.length) ||
        (!this.state.groupId && this.props.groups && this.props.groups.length))
    ) {
      this.setState({
        groupId: this.props.groups[0].id
      });
    }
  }

  componentWillUnmount() {
    if (this.modified && this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  setTab = tab => this.setState({ activeTab: tab });

  handleFieldChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  canSave = () =>
    this.state.name.length &&
    (this.props.isLightMode ? true : this.state.groupId);

  handleSave = () => {
    this.setState({ saving: true });
    this.modified = false;

    this.props.saveItem(
      this.props.variantId
        ? {
            variant: {
              eventId: this.props.eventDetails.id,
              id: this.props.variantId,
              order: this.state.order,
              name: this.state.name,
              photoUrl: this.state.photoUrl,
              sku: null,
              barcode: null,
              requiresShipping: false,
              ounces: null,
              weight: null,
              weightUnit: null,
              enablePrices: this.state.enabledPrices,
              trackingMethod:
                this.state.accessType && this.state.accessType.length
                  ? this.state.accessType
                  : null,
              inventoryPolicy: "deny",
              trackInventory: this.state.trackInventory,
              assignInventoryOnApproval: this.state.assignInventoryOnApproval,
              inventoryQuantity: formatInventoryQuantity(
                this.state.inventoryQuantity
              ),
              rules: convertDatesToRules(this.state.dates),
              prices: R.map(camelcaseObjKeys, this.state.prices)
            }
          }
        : {
            item: {
              eventId: this.props.eventDetails.id,
              id:
                this.props.item && this.props.mode !== "clone"
                  ? this.props.item.id
                  : null,
              typeId: this.props.typeId,
              name: this.state.name,
              photoUrl: this.state.photoUrl,
              description: this.state.description,
              groupId: this.state.groupId,
              backgroundColor:
                this.state.color && this.state.color.length
                  ? this.state.color
                  : null,
              requiredFields: this.state.requiredFields,
              showInAssignmentManager: this.state.showInAssignmentManager,
              showInPortalReports: this.state.showInPortalReports,
              allowMultiplePerPersonInAssignmentManager: this.state
                .allowMultiplePerPersonInAssignmentManager,
              allowAccessToVirtualEvent: this.state.allowAccessToVirtualEvent,
              hideOnRegistrationPage: this.state.hideOnRegistrationPage,
              variants: [
                {
                  id: this.props.item
                    ? variantLens(this.props.item).id
                    : undefined,
                  order: this.state.order,
                  sku: null,
                  barcode: null,
                  requiresShipping: false,
                  ounces: null,
                  weight: null,
                  weightUnit: null,
                  enablePrices: this.state.enabledPrices,
                  trackingMethod:
                    this.state.accessType && this.state.accessType.length
                      ? this.state.accessType
                      : null,
                  inventoryPolicy: "deny",
                  trackInventory: this.state.trackInventory,
                  assignInventoryOnApproval: this.state
                    .assignInventoryOnApproval,
                  inventoryQuantity: formatInventoryQuantity(
                    this.state.inventoryQuantity
                  ),
                  rules: convertDatesToRules(this.state.dates),
                  prices: R.map(camelcaseObjKeys, this.state.prices),
                  zones: this.state.zones
                }
              ]
            }
          }
    );
    this.setState({ saving: false });
  };

  updatePrice = (index, key, value) => {
    this.setState(state => {
      if (key === "is_default" && value) {
        state.prices = state.prices.map(p => ({
          ...p,
          is_default: false
        }));
      }

      state.prices = update(state.prices, {
        [index]: { $set: { ...state.prices[index], [key]: value } }
      });

      return state;
    });
  };

  removePrice = index => {
    this.setState(state => {
      state.prices = state.prices.filter((p, idx) => idx !== index);
      return state;
    });
  };

  toggleRequiredField = (fieldId, toggle) => {
    this.setState(state => {
      if (toggle) {
        state.requiredFields.push(fieldId);
      } else {
        state.requiredFields = state.requiredFields.filter(
          fId => fId !== fieldId
        );
      }
      return state;
    });
  };

  toggleZone = (zoneId, toggle) => {
    this.setState(state => {
      if (toggle) {
        state.zones.push({ id: zoneId });
      } else {
        state.zones = state.zones.filter(({ id }) => id !== zoneId);
      }
      return state;
    });
  };

  disableGroupZones = groupId => {
    const groupZoneIds = R.compose(
      R.map(R.prop("id")),
      R.propOr([], "zones"),
      R.find(R.propEq("id", groupId))
    )(this.props.zoneGroups);

    this.setState({
      zones: R.filter(
        ({ id }) => R.all(zoneId => zoneId !== id, groupZoneIds),
        this.state.zones
      )
    });
  };

  enableGroupZones = groupId => {
    const groupZoneIds = R.compose(
      R.map(({ id }) => ({ id })),
      R.propOr([], "zones"),
      R.find(R.propEq("id", groupId))
    )(this.props.zoneGroups);

    this.setState({
      zones: R.compose(
        R.concat(groupZoneIds),
        R.filter(({ id }) => R.all(zone => zone.id !== id, groupZoneIds))
      )(this.state.zones)
    });
  };

  showAddSingleDayVariantModal = () => {
    this.props.showModal({
      content: (
        <AddSingleDayVariantModal onSave={this.onCreateSingleDayVariants} />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddMultiDayVariantModal = () => {
    this.props.showModal({
      content: (
        <AddMultiDayVariantModal onSave={this.onCreateMultiDayVariants} />
      ),
      wrapper: ModalWrapper
    });
  };

  onCreateSingleDayVariants = async dates => {
    this.setState({ saving: true });

    const rules = convertDatesToRules(dates);

    await this.props.addVariant({
      bulk: true,
      variants: rules.map((rule, idx) => ({
        itemId: this.props.item.id,
        name: moment(rule.value)
          .format("ddd")
          .toUpperCase(),
        sku: null,
        barcode: null,
        order: this.state.variants.length + idx + 1,
        requiresShipping: null,
        ounces: null,
        weight: null,
        weightUnit: null,
        enablePrices: false,
        trackInventory: false,
        trackingMethod: null,
        inventoryPolicy: "deny",
        inventoryQuantity: null,
        //
        rules: [rule],
        prices: [],
        values: []
      }))
    });

    this.modified = true;

    await this.props.fetchItem();
    this.setState({ saving: false });
  };

  onCreateMultiDayVariants = async dates => {
    this.setState({ saving: true });

    const rules = convertDatesToRules(dates);

    await this.props.addVariant({
      bulk: true,
      variants: [
        {
          itemId: this.props.item.id,
          name: `${rules.length} Day`,
          sku: null,
          barcode: null,
          order: this.state.variants.length + 1,
          requiresShipping: null,
          ounces: null,
          weight: null,
          weightUnit: null,
          enablePrices: false,
          trackInventory: false,
          trackingMethod: null,
          inventoryPolicy: "deny",
          inventoryQuantity: null,
          //
          rules,
          prices: [],
          values: []
        }
      ]
    });

    this.modified = true;

    await this.props.fetchItem();
    this.setState({ saving: false });
  };

  onVariantReorder = async variants => {
    this.setState({ saving: true });

    const order = variants.reduce((map, v, idx) => {
      map[v.id] = idx;
      return map;
    }, {});

    const sortedVariants = R.compose(
      R.map(v => ({
        ...v,
        order: order[v.id]
      })),
      R.sortBy(v => order[v.id])
    )(this.state.variants);

    this.setState({
      variantOrder: order,
      variants: sortedVariants
    });

    await this.props.bulkUpdateVariants({
      bulk: true,
      variants: sortedVariants.map(v => ({
        id: v.id,
        order: v.order
      }))
    });

    this.modified = true;

    this.setState({ saving: false });
  };

  hasMultipleVariants = () => this.state.variants.length > 1;

  uploadPhoto = async () => {
    const files = await getFilePicker();
    const photoUrl = files[0].url;

    this.setState({ photoUrl });
  };

  renderScene = key => {
    switch (key) {
      case "prices": {
        return (
          <Prices
            prices={this.state.prices.map((p, index) => ({
              name: p.name,
              value: p.price,
              isEnabled: p.is_enabled,
              isDefault: p.is_default,
              onToggleIsDefault: () =>
                this.updatePrice(index, "is_default", !p.is_default),
              onNameChange: val =>
                val && val.length ? this.updatePrice(index, "name", val) : null,
              onPriceChange: val => this.updatePrice(index, "price", val),
              togglePrice: () => {
                if (p.is_enabled) {
                  this.updatePrice(index, "is_default", false);
                }
                this.updatePrice(index, "is_enabled", !p.is_enabled);
              },
              onRemove: () => this.removePrice(index)
            }))}
            onTogglePrices={() =>
              this.handleFieldChange({
                name: "enabledPrices",
                value: !this.state.enabledPrices
              })
            }
            onAddNewPrice={name =>
              this.handleFieldChange({
                name: "prices",
                value: [
                  ...this.state.prices,
                  { is_enabled: true, price: 0, name }
                ]
              })
            }
            pricesEnabled={this.state.enabledPrices}
          />
        );
      }
      case "dates": {
        return (
          <Dates
            dates={this.state.dates}
            onDaySelect={({ value }) =>
              this.handleFieldChange({ name: "dates", value })
            }
          />
        );
      }
      case "inventory": {
        const {
          accessType,
          integrationCountOfAvailable,
          integrationName,
          inventoryQuantity,
          isIntegrationItem,
          trackInventory,
          showInAssignmentManager,
          showInPortalReports,
          allowMultiplePerPersonInAssignmentManager,
          allowAccessToVirtualEvent,
          hideOnRegistrationPage,
          assignInventoryOnApproval
        } = this.state;
        const requiredFields = REQUIRED_FIELDS.map(field => {
          const isActive = this.state.requiredFields.includes(field.id);
          return {
            ...field,
            active: isActive,
            onClick: () => this.toggleRequiredField(field.id, !isActive)
          };
        });

        return (
          <Inventory
            {...{
              isLightMode: this.props.isLightMode,
              itemTypeName: this.props.itemName,
              accessType,
              integrationCountOfAvailable,
              integrationName,
              inventoryQuantity,
              isIntegrationItem,
              trackInventory,
              assignInventoryOnApproval,
              requiredFields,
              countOfActiveRequiredFields: requiredFields.filter(f => f.active)
                .length,
              showInAssignmentManager,
              showInPortalReports,
              allowMultiplePerPersonInAssignmentManager,
              allowAccessToVirtualEvent,
              hideOnRegistrationPage,
              showVirtualEventSettings: this.props.typeId === CREDENTIAL_TYPE_ID
            }}
            onTrackInventory={() =>
              this.handleFieldChange({
                name: "trackInventory",
                value: !trackInventory
              })
            }
            onAssignInventoryOnApprovalonTrackInventory={() =>
              this.handleFieldChange({
                name: "assignInventoryOnApproval",
                value: !assignInventoryOnApproval
              })
            }
            onTrackingTypeChange={({ value }) =>
              this.handleFieldChange({ name: "accessType", value })
            }
            onQuantityChange={value =>
              this.handleFieldChange({ name: "inventoryQuantity", value })
            }
            onShowInAssignmentManagerChange={() =>
              this.handleFieldChange({
                name: "showInAssignmentManager",
                value: !showInAssignmentManager
              })
            }
            onShowInPortalReportsChange={() =>
              this.handleFieldChange({
                name: "showInPortalReports",
                value: !showInPortalReports
              })
            }
            onMultiplePerPersonChange={() =>
              this.handleFieldChange({
                name: "allowMultiplePerPersonInAssignmentManager",
                value: !allowMultiplePerPersonInAssignmentManager
              })
            }
            onAllowAccessToVirtualEventChange={() =>
              this.handleFieldChange({
                name: "allowAccessToVirtualEvent",
                value: !allowAccessToVirtualEvent
              })
            }
            onHideOnRegistrationPageChange={() =>
              this.handleFieldChange({
                name: "hideOnRegistrationPage",
                value: !hideOnRegistrationPage
              })
            }
          />
        );
      }
      case "variants": {
        const countOfVariants = this.state.variants.length;
        const variantsWithHandlers = this.state.variants.map(variant => {
          let days = null;
          let prices = null;

          if (variant.rules.length) {
            if (variant.rules.length > 1) {
              days = `Multi-Day · Valid for ${variant.rules.length} days`;
            } else {
              days = moment(variant.rules[0].value).format("dddd, MMM DD");
            }
          }

          if (variant.prices.length) {
            prices = R.compose(
              R.join(", "),
              R.map(p => this.props.formatAmountForEvent(p.price)),
              R.sortBy(R.prop("price"))
            )(variant.prices);
          }

          return {
            id: variant.id,
            name: variant.display_name || this.props.item.name,
            editableName: variant.name,
            color: this.props.item.background_color,
            days,
            prices,
            countOfVariants,
            disabled: countOfVariants === 1,
            onEdit: () => this.showVariantModal(variant.id),
            onDelete:
              countOfVariants === 1
                ? undefined
                : () => this.deleteVariant(variant.id),
            saveUpdatedName: name => {
              const trimmedName = name.trim();
              if (
                (countOfVariants === 1 && trimmedName.length === 0) ||
                (trimmedName.length && trimmedName !== variant.name)
              ) {
                this.updateVariant({
                  variant: {
                    id: variant.id,
                    name: trimmedName
                  }
                });
              }
            }
          };
        });
        const variantMethods = [
          {
            id: "add-single-day",
            name: "+ Add Single Day Variant",
            description: "ie: GA Fri, GA Sat, Staff Parking - Thursday, etc",
            onClick: this.showAddSingleDayVariantModal
          },
          {
            id: "add-multi-day",
            name: "+ Add Multi-Day Variant",
            description: "ie: GA 3-DAY, GA-Weekend, GA-Fri+Sat Only, etc",
            onClick: this.showAddMultiDayVariantModal
          }
        ];

        return (
          <Variants
            {...{
              variants: variantsWithHandlers,
              variantMethods,
              reorderVariants: this.onVariantReorder
            }}
          />
        );
      }
      case "questions":
        return (
          <Questions
            fetchItem={() => this.props.fetchItem()}
            questions={R.propOr([], "questions", this.state.variants[0])}
            variantIds={R.map(R.propOr("", "id"), this.state.variants)}
          />
        );
      case "zones":
        return (
          <Zones
            zones={R.map(
              zoneGroup => ({
                ...zoneGroup,
                zones: R.compose(
                  R.map(zone => ({
                    ...zone,
                    selected: R.any(
                      ({ id }) => id === zone.id,
                      this.state.zones
                    ),
                    onToggle: () =>
                      this.toggleZone(
                        zone.id,
                        !this.state.zones.some(({ id }) => id === zone.id)
                      )
                  })),
                  R.sortBy(R.prop("order"))
                )(zoneGroup.zones)
              }),
              this.props.zoneGroups
            )}
            zonesSelectedLength={R.length(this.state.zones)}
            disableGroupZones={this.disableGroupZones}
            enableGroupZones={this.enableGroupZones}
          />
        );
      case "overview":
      default: {
        const {
          color,
          description,
          groupId,
          integrationItemName,
          integrationName,
          isIntegrationItem,
          name,
          photoUrl,
          order
        } = this.state;
        const isViewingVariant = Boolean(this.props.variantId);

        return (
          <Overview
            {...{
              isLightMode: this.props.isLightMode,
              hideImage: false,
              itemTypeName: this.props.itemName,
              isViewingVariant,
              itemName: this.props.item ? this.props.item.name : null,
              activeGroupId: groupId,
              color,
              description,
              groups: this.props.groups.map(({ id, name }) => ({
                label: name,
                value: id
              })),
              integrationItemName,
              integrationName,
              isIntegrationItem,
              name,
              photoUrl,
              order,
              uploadPhoto: () => this.uploadPhoto(),
              removeUrl: () => this.setState({ photoUrl: null }),
              onNameChange: value =>
                this.handleFieldChange({ name: "name", value }),
              onDescriptionChange: value =>
                this.handleFieldChange({ name: "description", value }),
              onOrderChange: value =>
                this.handleFieldChange({ name: "order", value }),
              onGroupChange: ({ value }) =>
                this.handleFieldChange({ name: "groupId", value }),
              onSelectColor: value =>
                this.handleFieldChange({ name: "color", value })
            }}
          />
        );
      }
    }
  };

  updateVariant = async data => {
    this.setState({ saving: true });
    await this.props.updateVariant(data);
    this.props.fetchItem();
    this.modified = true;
    this.setState({ saving: false });
  };

  deleteVariant = async variantId => {
    this.setState({ saving: true });
    await this.props.deleteVariant(variantId);
    this.props.fetchItem();
    this.modified = true;
    this.setState({ saving: false });
  };

  showVariantModal = variantId => {
    this.props.showModal({
      content: (
        <CredentialTypeModal
          onDone={this.updateVariant}
          itemId={this.props.item.id}
          typeId={this.props.item.type_id}
          variantId={variantId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  isViewingSingleVariant = () =>
    Boolean(this.props.variantId) || this.state.variants.length <= 1;

  getSelectedVariantName = () => {
    if (this.props.variantId) {
      const variant = this.state.variants.find(
        v => v.id === this.props.variantId
      );
      return variant ? variant.display_name : this.props.item.name;
    }
    return this.props.item ? this.props.item.name : this.state.name;
  };

  render() {
    const {
      isLightMode,
      hideModal,
      mode,
      enabledFeatures,
      itemName
    } = this.props;
    const { activeTab, loading, saving } = this.state;
    const isViewingSingleVariant = this.isViewingSingleVariant();
    const selectedVariantName = this.getSelectedVariantName();
    const itemExists = this.props.item && this.props.item.id;

    return (
      <View
        {...{
          hideModal,
          loading,
          saving,
          heading: `${["create", "clone"].includes(mode) ? "Add" : "Edit"} ${
            isLightMode ? "Ticket" : `${capitalize(itemName)} Type`
          }`,
          subheading: itemExists ? selectedVariantName : null,
          Body: this.renderScene(activeTab),
          canSave: this.canSave(),
          onSave: this.handleSave,
          tabs: [
            {
              id: "overview",
              name: "Overview",
              active: activeTab === "overview",
              onClick: () => this.setTab("overview"),
              enabled: true,
              visible: true
            },
            {
              id: "dates",
              name: "Dates",
              active: activeTab === "dates",
              onClick: isViewingSingleVariant
                ? () => this.setTab("dates")
                : undefined,
              enabled: isViewingSingleVariant,
              visible: !isLightMode && enabledFeatures.includes(FEATURES.DATES)
            },
            {
              id: "zones",
              name: "Zones",
              active: activeTab === "zones",
              onClick: isViewingSingleVariant
                ? () => this.setTab("zones")
                : undefined,
              enabled: isViewingSingleVariant,
              visible: !isLightMode && enabledFeatures.includes(FEATURES.ZONES)
            },
            {
              id: "inventory",
              name: "Tracking",
              active: activeTab === "inventory",
              onClick: isViewingSingleVariant
                ? () => this.setTab("inventory")
                : undefined,
              enabled: isViewingSingleVariant,
              visible: enabledFeatures.includes(FEATURES.TRACKING)
            },
            {
              id: "prices",
              name: "Prices",
              active: activeTab === "prices",
              onClick: isViewingSingleVariant
                ? () => this.setTab("prices")
                : undefined,
              enabled: isViewingSingleVariant,
              visible: enabledFeatures.includes(FEATURES.PRICES)
            },
            {
              id: "variants",
              name: "Variants",
              active: activeTab === "variants",
              onClick: itemExists ? () => this.setTab("variants") : undefined,
              enabled: itemExists,
              tooltip: itemExists
                ? null
                : `Add variations of this ${itemName} after saving it`,
              visible:
                !isLightMode &&
                !this.props.variantId &&
                enabledFeatures.includes(FEATURES.VARIANTS)
            },
            {
              id: "questions",
              name: "Questions",
              active: activeTab === "questions",
              onClick:
                itemExists && isViewingSingleVariant
                  ? () => this.setTab("questions")
                  : undefined,
              enabled: itemExists && isViewingSingleVariant,
              tooltip: itemExists
                ? null
                : `Add questions of this ${itemName} after saving it`,
              visible:
                !isLightMode && enabledFeatures.includes(FEATURES.QUESTIONS)
            }
            /*
            // @NOTE: Commenting out until permissions is in
            {
              id: 'permissions',
              name: 'Permissions',
              active: activeTab === 'permissions',
              onClick: () => this.setTab('permissions')
            }
            */
          ].filter(item => item.visible)
        }}
      />
    );
  }
}

InternalHandler.defaultProps = {
  itemId: null,
  item: {},
  clone: false
};

InternalHandler.propTypes = {
  mode: PropTypes.string.isRequired,
  fetchItem: PropTypes.func.isRequired,
  getItemGroups: PropTypes.func.isRequired,
  saveItem: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    security: PropTypes.string,
    account_permissions: PropTypes.object,
    background_color: PropTypes.string,
    dates: PropTypes.array,
    description: PropTypes.string,
    item_group_id: PropTypes.string,
    budget: PropTypes.number,
    name: PropTypes.string,
    contact_permissions: PropTypes.object,
    zones: PropTypes.array
  }),
  itemId: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  clone: PropTypes.bool
};

export default decorate(InternalHandler);
