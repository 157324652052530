import React, { Component } from "react";
import getValue from "utils/value-types/get-value/text";
import { isEqual } from "lodash";
import { Div } from "components/Base";

export default class TextAreaCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { style = {} } = this.props;
    const value = getValue(this.props.value);

    return (
      <Div
        style={{
          ...style,
          borderRadius: 4,
          fontSize: 12,
          padding: "0 6px",
          lineHeight: "16px",
          marginRight: 4
        }}
        fw={3}
        title={value}
      >
        {value}
      </Div>
    );
  }
}
