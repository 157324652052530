import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "EventLight/Integrations/FuzionMappingModal";
import * as R from "ramda";
import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  DarkTab
} from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Loading from "ui-kit/Loading";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import Exhibitors from "./Exhibitors";

import {
  TYPES,
  EXHIBITORS
} from "EventLight/Integrations/FuzionMappingModal/constants";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    saving: getters.saving(state),
    error: getters.error(state),
    activeTab: getters.activeTab(state)
  }),
  {
    saveMapping: actions.saveMapping
  }
);

const MappingIntegrationModal = ({
  canSave = true,
  saving,
  loading,
  hideModal,
  saveMapping,
  activeTab,
  setActiveTab
}) => {
  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title="Fuzion Integration Mapping"
      style={{ width: 800, height: 568 }}
      isPortal={false}
      bg="white"
      stopPropagation
    >
      <>
        <Div
          display="row.flex-start.flex-end"
          bc="neutral1"
          bb={1}
          height={40}
          width={1}
          style={{
            flexShrink: 0
          }}
        >
          <Div px={8} height={1} width={1} display="row.flex-start.flex-end">
            {R.map(type => (
              <DarkTab
                key={type.value}
                px={2}
                fs={3}
                active={activeTab === type.value}
                onClick={() => setActiveTab(type.value)}
              >
                {type.label}
              </DarkTab>
            ))(TYPES)}
          </Div>
        </Div>

        {loading ? (
          <Loading />
        ) : (
          <ScrollableModalBody
            style={{
              height: 405,
              display: "flex",
              backgroundColor: "rgba(224, 224, 224, 0.19)"
            }}
          >
            <Div flex={1} style={{ width: "100%" }}>
              {R.prop(activeTab, {
                [EXHIBITORS]: <Exhibitors />
              })}
            </Div>
          </ScrollableModalBody>
        )}

        <Div
          height={60}
          px={8}
          bc="neutral1"
          bt={1}
          display="row.flex-start.center"
          style={{
            flexShrink: 0
          }}
        >
          <BigFilledButton
            mr={2}
            bg={canSave && !saving ? "altB5" : "neutral1"}
            onClick={canSave && !saving ? saveMapping : null}
          >
            {saving ? "Saving..." : "Save Settings"}
          </BigFilledButton>
          <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
        </Div>
      </>
    </MiniModalWrapper>
  );
};

export default decorate(MappingIntegrationModal);
