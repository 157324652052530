import { createContext } from "redux-mvc";
import * as R from "ramda";
import module, { actions } from "../index";
import Layout from "./Layout";
import { hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      const {
        columns,
        requiredFields,
        visibleFields,
        fieldOrder,
        notDraggableFieldIds
      } = this.props;
      this.store.dispatch(
        actions.setInitialData({
          columns,
          requiredFields,
          visibleFields,
          fieldOrder,
          notDraggableFieldIds: notDraggableFieldIds || []
        })
      );
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default R.compose(
  connect(
    null,
    {
      hideModal
    }
  ),
  decorate
)(Layout);
