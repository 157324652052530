import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import { map, sortBy, isEqual, get, set, unset } from "lodash";
import Toggle, { Tab } from "./Toggle";
import TableView from "./TableView";
import CardView from "./CardView";
import MediaQuery from "react-responsive";
import FormBaseInput from "../FormBaseInput";
import ErrorList from "./ErrorList";
import jQuery from "jquery";
import Dialog from "components/Global/Modals/Dialog";
import isAdminField from "components/Event/FormsV2/Utils/isAdminField";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import isEmpty from "utils/value-types/is-empty";
import InvalidRowCount from "./InvalidRowCount";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import getModuleFieldId from "components/Event/FormsV2/Utils/get-module-field-id";

class FormGrid extends FormBaseInput {
  constructor(props) {
    super(props);

    this.validatorsMap = {};

    this.state = {
      activeTab: "card",
      invalidRows: [],
      popoverOpen: false,
      popoverAnchorEl: null,
      popoverContent: null
    };
  }

  @autobind
  registerValidator(isValid, row, field) {
    if (row && field) {
      set(this.validatorsMap, [row, field], isValid);
    }
  }

  @autobind
  unregisterValidator(isValid, row, field) {
    if (row && field) {
      unset(this.validatorsMap, [row, field]);
    }
  }

  // Validates a specific cell based on row/field.
  // Happens on changes to a cell.
  async validateCell(row, field) {
    const rowHash = this.props.rows.reduce((hash, r) => {
      hash[r.id] = r.values;
      return hash;
    }, {});
    const validator = get(this.validatorsMap, [row, field]);
    const fields = this.getFields();
    const isRowEmpty = !isRowNotEmpty(rowHash[row], fields);
    if (isRowEmpty) {
      const curRow = get(this.validatorsMap, row);
      if (curRow) {
        // Execute all validators for the current row
        Object.keys(curRow).map(k => curRow[k](rowHash));
      }
    } else if (validator) {
      validator(rowHash);
    }

    this.validateFormData();
  }

  // Top level validation on the table. Manages whether the table
  // is valid or not and the tabel level error messages.
  async validateFormData() {
    if (this.props.isEditing || this.props.isApproving) return true;

    const errors = [];
    const fields = this.getFields();
    const requiredFields = fields.filter(f => f.is_required);
    const nonEmptyRows = this.props.isResponseLocked
      ? this.props.rows.filter(
          r =>
            // if response is locked, only validate approved rows, in case of pending rows being
            // modified during a "Change Name" update
            isRowNotEmpty(r.values, fields) &&
            ["approved"].includes(
              get(
                r,
                `values[${DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id}].value`
              )
            )
        )
      : this.props.rows.filter(r => isRowNotEmpty(r.values, fields));

    const invalidRows = nonEmptyRows.reduce((invalid, r) => {
      const isRowInvalid = requiredFields
        .map(c => isEmpty(r.values[c.id], c.type))
        .some(v => v);
      if (isRowInvalid) {
        invalid.push(r.id);
      }
      return invalid;
    }, []);

    if (invalidRows.length) {
      errors.push(<InvalidRowCount count={invalidRows.length} />);
    }

    this.setState({
      errors,
      invalidRows,
      isValid: !errors.length
    });

    return !errors.length;
  }

  // Triggered on submit. Validates the table as well as validators (cells) in the table.
  async isValid() {
    const rowHash = this.props.rows.reduce((hash, r) => {
      hash[r.id] = r;
      return hash;
    }, {});

    // Validate data
    const isGridValid = this.validateFormData();

    // Trigger validation on all cells
    Object.keys(this.validatorsMap)
      .reduce(
        (validators, row) =>
          validators.concat(
            Object.keys(this.validatorsMap[row]).map(
              field => this.validatorsMap[row][field]
            )
          ),
        []
      )
      .map(isValid => isValid(rowHash));

    return isGridValid;
  }

  onTabSelect(key) {
    this.setState({
      activeTab: key
    });
  }

  getScrollParent() {
    return jQuery(".page-form")[0];
  }

  @autobind
  getRowMetaData(rowData, field) {
    return {
      ...rowData,
      ...rowData.values,
      meta: {
        eventId: this.props.eventId,
        formId: this.props.formId,
        responseId: this.props.responseId,
        recipientId: this.props.recipientId,
        gridId: this.props.field.id,
        rowId: rowData.id,
        columnId: field.id,
        columns: this.props.form.fields,
        columnSettings: field.settings,
        column: field,
        changes: get(this.props.response, "changes", []),
        via: "form",
        eventDetails: this.props.eventDetails,
        isViewingApproval: this.props.isViewingApproval,
        isApprovingForm: this.props.isApprovingForm,
        isResponseLocked: get(this.props.response, "locked"),
        readOnly: this.props.isViewingApproval
      },
      helpers: {
        showAdminChangeDialog: this.showAdminChangeDialog,
        registerValidator: this.registerValidator,
        unregisterValidator: this.unregisterValidator
      }
    };
  }

  @autobind
  onRequestClose() {
    this.setState({ popoverOpen: false });
  }

  @autobind
  showRejectedChangeDialog() {
    // @DEPRECATED
  }

  @autobind
  showRejectedRowDeletionDialog() {
    const modal = (
      <Dialog
        confirmLabel="Ok"
        title="Oops!"
        message={
          <div>
            You cannot remove a row that has been approved or rejected. Please
            unapprove / unreject the row before removing it.
          </div>
        }
      />
    );
    this.props.showModal({ content: modal });
  }

  @autobind
  showRejectedCellUpdateDialog() {
    const modal = (
      <Dialog
        confirmLabel="Ok"
        title="Oops!"
        message={
          <div>
            You cannot update a cell that has been already changed by an
            administrator.
          </div>
        }
      />
    );
    this.props.showModal({ content: modal });
  }

  @autobind
  showAdminChangeDialog(onConfirm) {
    const modal = (
      <Dialog
        confirmLabel="Continue"
        title="Heads up"
        message={
          <div>
            By making this update, you will lock this form submission and the
            recipient will no longer be able to make updates unless you unlock
            it.
          </div>
        }
        onConfirm={onConfirm}
      />
    );
    this.props.showModal({ content: modal });
  }

  addNewRow = async () => {
    let submission;

    // create module record
    const submissionModuleRecord = await this.props.addRecord({
      moduleId: this.props.field.subform.form.base_module_id,
      record: {
        // @NOTE: Always mark as draft initially. This will cover all cases, including if it's a default row
        // or a row a user adds. The ensuing "Submit" will then flip the necessary flags.
        isDraft: true,
        [this.props.field.module_field_id]: this.props.isEditing
          ? undefined
          : {
              type: "lookup",
              value: {
                moduleId: this.props.parentForm.base_module_id,
                records: [this.props.submission.submission_record_id]
              }
            }
      },
      options: {
        eventId: this.props.eventId
      }
    });

    if (this.props.isEditing) {
      // create default submission row
      submission = await this.props.createSubformDefaultSubmission({
        version: 3,
        formId: this.props.form.id,
        subformId: this.props.field.id,
        isDefault: true,
        submissionRecordId: submissionModuleRecord.id
      });
    } else {
      // create submission row
      submission = await this.props.createSubformSubmission({
        version: 3,
        formId: this.props.form.id,
        subformId: this.props.field.id,
        parentSubmissionId: this.props.submission.id,
        addCollaborator: false,
        isSubformSubmission: true,
        submissionRecordId: submissionModuleRecord.id
      });
    }

    return submission;
  };

  @autobind
  async duplicateRow(submissionIds, quantity) {
    if (this.props.isEditing) {
      await this.props.cloneSubformDefaultSubmissions({
        formId: this.props.form.id,
        subformId: this.props.field.id,
        submissionIds,
        quantity
      });
    } else {
      await this.props.cloneSubformSubmissions({
        formId: this.props.form.id,
        subformId: this.props.field.id,
        parentSubmissionId: this.props.submission.id,
        submissionIds,
        quantity
      });
    }

    this.validate();
  }

  canUpdateFormValue({ field, rowIdx }) {
    // @NOTE: Shouldn't fire for admin fields
    // @NOTE: Shouldn't fire for changeable fields when "Change Mode" is enabled
    const reviewStatus = get(
      this.props.rows,
      `[${rowIdx}].values.${DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id}.value`
    );

    // check: submission is locked
    if (this.props.isResponseLocked) {
      return false;
    }

    // check: row is not reviewed
    if (
      (this.props.isViewingApproval ||
        get(this.props.submission, "is_submitted")) &&
      ["approved", "rejected"].includes(reviewStatus) &&
      !isAdminField(field)
    ) {
      return false;
    }

    return true;
  }

  @autobind
  async updateFormValue(submissionId, fieldId, newValue, oldValue) {
    const submission = this.props.rows.find(r => r.id === submissionId);

    if (isEqual(get(newValue, "value"), get(oldValue, "value"))) {
      // Trigger validation, but need a set timeout for the formatter to mount
      setTimeout(() => this.validateCell(submissionId, fieldId));
      return;
    }

    if (this.props.isEditing) {
      await this.props.addDefaultValue({
        version: getFormVersion(this.props.parentForm),
        submissionRecordId: submission.submission_record_id,
        moduleFieldId: getModuleFieldId(fieldId, this.props.form.fields),
        subformId: this.props.field.id,
        submissionId,
        fieldId,
        value: newValue,
        userId: this.props.user.id
      });
    } else {
      await this.props.addSubformValue({
        version: getFormVersion(this.props.parentForm),
        submissionRecordId: submission.submission_record_id,
        moduleFieldId: getModuleFieldId(fieldId, this.props.form.fields),
        subformFieldId: this.props.field.id,
        submissionId,
        fieldId,
        value: newValue,
        userId: this.props.user.id,
        parentSubmissionId: this.props.submission.id
      });
    }

    // clear the values of any of this field's dependencies
    await Promise.all(
      this.props.form.fields
        .filter(field => {
          if (["inventory", "schedule-activity"].includes(field.type)) {
            return (
              field.settings.hasDependency &&
              field.settings.dependencySettings.columnId === fieldId
            );
          } else if (field.type === "dependency") {
            return field.settings.columnId === fieldId;
          }
          return false;
        })
        .map(field => {
          if (this.props.isEditing) {
            return this.props.addDefaultValue({
              version: getFormVersion(this.props.parentForm),
              submissionRecordId: submission.submission_record_id,
              moduleFieldId: getModuleFieldId(fieldId, this.props.form.fields),
              subformId: this.props.field.id,
              submissionId,
              fieldId: field.id,
              value: undefined,
              userId: this.props.user.id
            });
          }
          return this.props.addSubformValue({
            version: getFormVersion(this.props.parentForm),
            submissionRecordId: submission.submission_record_id,
            moduleFieldId: getModuleFieldId(fieldId, this.props.form.fields),
            subformFieldId: this.props.field.id,
            submissionId,
            fieldId: field.id,
            value: undefined,
            userId: this.props.user.id,
            parentSubmissionId: this.props.submission.id
          });
        })
    );

    this.validateCell(submissionId, fieldId);
  }

  @autobind
  async updateFormValues(values) {
    const version = getFormVersion(this.props.parentForm);

    // v3
    if (version === 3) {
      if (this.props.isEditing) {
        await this.props.bulkAddDefaultValues({
          version: 3,
          subformId: this.props.field.id,
          submissionId: 0,
          values: values.map(v => ({
            ...v,
            moduleFieldId: getModuleFieldId(v.fieldId, this.props.form.fields)
          }))
        });
      } else {
        await this.props.bulkAddSubformValues({
          version: 3,
          subformId: this.props.field.id,
          submissionId: this.props.submission.id,
          values: values.map(v => ({
            ...v,
            moduleFieldId: getModuleFieldId(v.fieldId, this.props.form.fields)
          }))
        });
      }
    }

    // v2
    if (version === 2) {
      if (this.props.isEditing) {
        await this.props.bulkAddDefaultValues({
          subformId: this.props.field.id,
          submissionId: 0,
          values
        });
      } else {
        await this.props.bulkAddSubformValues({
          subformId: this.props.field.id,
          submissionId: this.props.submission.id,
          values
        });
      }
    }

    values.map(v => this.validateCell(v.submissionId, v.fieldId));
  }

  @autobind
  async removeSelectedRows(submissionIds) {
    if (this.props.isEditing) {
      await this.props.deleteSubformDefaultSubmission({
        formId: this.props.form.id,
        subformId: this.props.field.id,
        submissionIds,
        bulk: true
      });
    } else if (this.props.isFillingFormOut) {
      await this.props.deleteSubformSubmission({
        formId: this.props.form.id,
        subformId: this.props.field.id,
        submissionIds,
        bulk: true
      });
    }
    this.validate();
  }

  sortFields(fields) {
    return sortBy(fields, "order");
  }

  @autobind
  getFields() {
    return this.filterOutAdminFields(this.props.form.fields);
  }

  @autobind
  getFieldsGroupedBySection() {
    return sortBy(this.props.form.fields_grouped_by_section, "order").reduce(
      (fields, section) => {
        fields.push(...sortBy(section.fields, "order"));
        return fields;
      },
      []
    );
  }

  @autobind
  filterOutAdminFields(fields) {
    if (!this.showAdminFields()) {
      return fields.filter(f => !isAdminField(f));
    }
    return fields;
  }

  @autobind
  filterOutNonInputFields(fields) {
    return fields.filter(
      field =>
        !["separator", "image", "header", "section", "order"].includes(
          field.type
        )
    );
  }

  @autobind
  showAdminFields() {
    return !!(this.props.isEditing || this.props.isResponseLocked);
  }

  @autobind
  sortSubmissions(submissions) {
    const rowOrders = get(this.props, ["preferences", "row_order"], {});
    return sortBy(submissions, s => get(rowOrders, s.id, 1));
  }

  @autobind
  onRowSort(comparer) {
    const rowIndexes = this.props.rows
      .sort((a, b) => comparer(a.values, b.values))
      .reduce((rs, row, index) => {
        rs[row.id] = index;
        return rs;
      }, {});

    this.updateRowOrder(rowIndexes);
  }

  @autobind
  updateRowOrder(rowOrder) {
    // React data grid is handling drag and drop, but calls this method *before* drag end. This
    // changes the row index order which destroys the keys react data grid is using so the work around
    // is to push it to the bottom of the stack so dragEnd is executed before this.
    setTimeout(async () => {
      if (this.props.isEditing || this.props.isPreviewing) {
        this.props.updateDefaultRowOrder({
          formId: this.props.form.id,
          subformId: this.props.field.id,
          rowOrder
        });
      } else {
        this.props.updateRowOrder({
          formId: this.props.form.id,
          subformId: this.props.field.id,
          rowOrder
        });
      }
      this.validate();
    }, 50);
  }

  @autobind
  shouldHideCardView() {
    const { field, isFillingFormOut } = this.props;
    if (field.settings.style === "table") {
      return true;
    }
    return !isFillingFormOut;
  }

  @autobind
  shouldHideTableView() {
    return this.props.field.settings.style === "card";
  }

  render() {
    const hideCard = this.shouldHideCardView();
    const activeTab = hideCard ? "table" : this.state.activeTab;
    const options = {
      card: {
        name: (
          <span>
            <i
              className="material-icons"
              style={{
                fontSize: 13,
                color: "#C1C1C1",
                verticalAlign: "sub",
                marginRight: 5
              }}
            >
              view_agenda
            </i>
            Single Entry
          </span>
        ),
        component: CardView
      },
      table: {
        name: (
          <span>
            <i
              className="material-icons"
              style={{
                fontSize: 13,
                color: "#C1C1C1",
                verticalAlign: "sub",
                marginRight: 5
              }}
            >
              view_list
            </i>
            Multiple Entry
          </span>
        ),
        component: TableView
      }
    };
    const View = options[activeTab].component;
    const scrollParent = this.getScrollParent();
    return (
      <div
        style={{
          marginBottom: 20
        }}
      >
        {hideCard || this.shouldHideTableView() ? (
          ""
        ) : (
          <MediaQuery query="(min-width: 768px)">
            <div
              style={{
                marginBottom: 20
              }}
            >
              <Toggle>
                {map(options, (option, key) => (
                  <Tab
                    active={key === activeTab}
                    onClick={this.onTabSelect.bind(this, key)}
                    key={key}
                  >
                    {option.name}
                  </Tab>
                ))}
              </Toggle>
            </div>
          </MediaQuery>
        )}
        <ErrorList
          type={this.state.activeTab}
          isValid={this.state.isValid}
          errorMessages={this.state.errors}
          field={this.props.field}
          allowNewRows={this.props.field.settings.allowNewRows}
          rows={this.props.rows}
        >
          <View
            {...this.props}
            ref="view"
            field={this.props.field}
            addNewRow={this.addNewRow}
            allowNewRows={this.props.field.settings.allowNewRows}
            fieldsGroupedBySection={this.getFieldsGroupedBySection()}
            duplicateRow={this.duplicateRow}
            getRowMetaData={this.getRowMetaData}
            hideModal={this.props.hideModal}
            invalidRows={this.state.invalidRows}
            isResponseLocked={this.props.isResponseLocked}
            removeSelectedRows={this.removeSelectedRows}
            response={this.props.response}
            rows={this.sortSubmissions(this.props.rows)}
            scrollParent={scrollParent}
            showAdminChangeDialog={this.showAdminChangeDialog}
            showAdminFields={this.showAdminFields()}
            showModal={this.props.showModal}
            showRejectedCellUpdateDialog={this.showRejectedCellUpdateDialog}
            showRejectedChangeDialog={this.showRejectedChangeDialog}
            showRejectedRowDeletionDialog={this.showRejectedRowDeletionDialog}
            updateColumnDescription={this.props.updateColumnDescription}
            updateFormValue={this.updateFormValue}
            updateFormValues={this.updateFormValues}
          />
        </ErrorList>
      </div>
    );
  }
}

FormGrid.propTypes = {
  styles: PropTypes.object,
  rows: PropTypes.array.isRequired,
  response: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  user: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  responseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  field: PropTypes.object.isRequired,
  recipientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  updateFormValue: PropTypes.func.isRequired,
  updateColumnDescription: PropTypes.func.isRequired,
  isResponseLocked: PropTypes.bool.isRequired,
  isViewingApproval: PropTypes.bool.isRequired,
  isApprovingForm: PropTypes.bool.isRequired,
  isPreviewing: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isFillingFormOut: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  updateContactValue: PropTypes.func.isRequired,
  addReferenceValue: PropTypes.func.isRequired,
  references: PropTypes.shape({
    values: PropTypes.object.isRequired
  })
};

export default FormGrid;
