import {
  CLEAR_SEARCH,
  REQUEST,
  RECEIVE_PAGINATION,
  RECEIVE
} from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { eventId } from "redux/modules/event/selectors";

export function clearSearch() {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEAR_SEARCH
    });
    try {
      const result = await api.search(
        getState().user.user.credentials,
        "",
        1,
        eventId(getState())
      );
      dispatch({
        type: RECEIVE_PAGINATION,
        payload: result.pagination
      });
      dispatch({
        type: RECEIVE,
        payload: result.results
      });

      return result.results;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred searching for items"
          }
        ])
      );
    }

    return {};
  };
}
export function search(searchTerm, page = 1) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.search(
        getState().user.user.credentials,
        searchTerm,
        page,
        eventId(getState())
      );
      dispatch({
        type: RECEIVE_PAGINATION,
        payload: result.pagination
      });
      dispatch({
        type: RECEIVE,
        payload: result.results
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred searching for items"
          }
        ])
      );
    }

    return {};
  };
}
