import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import Circle from "ui-kit/Circle";
import MenuPopover from "ui-kit/MenuPopover";
import {
  PrevNext,
  DateRangeSelector,
  EventDatesCalendar
} from "ui-kit/TimeTable/View";

import OptionsButton from "./OptionsButton";

import {
  DownFilledIcon,
  AddIcon,
  MediumFilledButton,
  Div
} from "components/Base";

import { getIsViewingMasterSchedule } from "../selectors";
import { actions } from "../model";

import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const wrapperStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 8
};

const decorate = connect(
  (state, props) => ({
    isViewingMasterSchedule: getIsViewingMasterSchedule(state, props)
  }),
  bindInstance({
    create: actions.eventCreate
  })
);

const Toolbar = ({
  create = noop,
  isViewingMasterSchedule,
  moduleId,
  instanceId,
  handlers,
  ...styleProps
}) => (
  <Div display="row.flex-start.center" mb={2} {...styleProps}>
    <Circle
      size={40}
      bg="warning5"
      onClick={create}
      style={{ cursor: "pointer" }}
      data-cy="create-activity"
    >
      <AddIcon color="white" size={30} />
    </Circle>
    <Div p={1} bg="#7a38c3" ml={2}>
      <MenuPopover
        Label={
          <MediumFilledButton RightIcon={DownFilledIcon} bg="primary7">
            Go to...
          </MediumFilledButton>
        }
      >
        {({ closePopover }) => (
          <EventDatesCalendar
            instanceId={instanceId}
            closePopover={closePopover}
          />
        )}
      </MenuPopover>
    </Div>
    <PrevNext instanceId={instanceId} />
    <Div display="row.center.stretch">
      <DateRangeSelector instanceId={instanceId} />
      <CanUserDo
        any={[
          `${
            isViewingMasterSchedule ? STANDARD_MODULES.schedules.id : moduleId
          }_manage`,
          `${
            isViewingMasterSchedule ? STANDARD_MODULES.schedules.id : moduleId
          }_export`
        ]}
      >
        <OptionsButton
          wrapperStyle={wrapperStyle}
          flex={1}
          handlers={handlers}
        />
      </CanUserDo>
    </Div>
  </Div>
);

export default decorate(Toolbar);
