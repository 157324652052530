import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import { getters } from "./model";

const getInstance = (_, props) => R.prop("instanceId", props);

export const getEvents = makeInstanceSelector(
  getters.events,
  R.pathOr([], ["events"])
)(getInstance);

export const currentEventName = makeInstanceSelector(
  getEvents,
  getters.eventId,
  getEventDetails,
  (events, eventId, eventDetails) =>
    R.compose(
      name => (name && name.length > 20 ? `${name.substring(0, 17)}...` : name),
      event =>
        event
          ? R.prop("name")(event)
          : parseInt(eventDetails.id, 10) === parseInt(eventId, 10)
          ? eventDetails.name
          : null,
      R.find(R.propEq("id", eventId))
    )(events)
)(getInstance);

export const getNotificationListFiltered = makeInstanceSelector(
  getters.notifications,
  getters.showFilters,
  getters.selectedTypeFilters,
  (notifications, showFilter, selectedTypeFilters) => {
    if (!showFilter) {
      return R.reject(R.isEmpty)(notifications);
    }
    return R.compose(
      R.reject(R.isEmpty),
      R.map(
        R.filter(notification => {
          if (R.isEmpty(selectedTypeFilters)) {
            return true;
          }
          return R.propSatisfies(R.contains(R.__, selectedTypeFilters), "type")(
            notification
          );
        })
      )
    )(notifications);
  }
)(getInstance);

export const getNotificationsIds = makeInstanceSelector(
  getNotificationListFiltered,
  R.compose(
    R.map(R.path(["notification_ids", "0"])),
    R.flatten,
    R.values
  )
)(getInstance);

export const selectedEventCount = makeInstanceSelector(
  getters.events,
  getters.eventId,
  (events, eventId) =>
    R.propOr(
      0,
      "count_of_notifications",
      R.find(R.propEq("id", eventId), R.propOr([], "events", events))
    )
)(getInstance);

export const eventsFilteredByName = makeInstanceSelector(
  SearchBarGetters.searchTerm,
  getEvents,
  (searchTerm, events) =>
    R.filter(
      R.propSatisfies(
        eventName =>
          eventName &&
          eventName.toLowerCase().includes(searchTerm.toLowerCase()),
        "name"
      ),
      events
    )
)(getInstance);

export const getCountOfAllNotifications = makeInstanceSelector(
  getters.events,
  events => R.propOr(0, "count_of_all_notifications", events)
)(getInstance);

export const getHasMoreToShow = makeInstanceSelector(
  getters.eventId,
  selectedEventCount,
  getCountOfAllNotifications,
  getters.countOfVisibleNotifications,
  (
    eventId,
    selectedEventCount,
    countOfAllNotifications,
    countOfVisibleNotifications
  ) => {
    if (eventId) {
      return countOfVisibleNotifications < selectedEventCount;
    }
    return countOfVisibleNotifications < countOfAllNotifications;
  }
)(getInstance);

export const showEventName = makeInstanceSelector(
  getters.eventId,
  eventId => !parseInt(eventId, 10)
)(getInstance);
