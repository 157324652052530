import Helpers from "utils/Global/Helpers";

export default {
  getManifest: ({
    credentials,
    recordType = "lineItem",
    recordIds,
    manifestId
  }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/manifests/${manifestId}`,
        credentials,
        data: { ids: recordIds, recordType },
        success,
        error
      });
    }),

  addReview: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/reviews`,
        credentials,
        data,
        success,
        error
      });
    }),

  undoReview: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/reviews`,
        credentials,
        data,
        success,
        error
      });
    }),

  removeLineItems: data =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items`,
        credentials: data.credentials,
        data,
        success,
        error
      });
    })
};
