import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import {
  NAMESPACE,
  FILTER_IDS,
  AVAILABLE_SECTIONS,
  SUMMARY_TAB_IDS
} from "./constants";

const iniState = {
  loading: true,
  recordTypeId: null,
  data: {},
  collapsableIds: {},
  selectedTabs: {},
  openedRequestTabs: {},
  activeFilterId: FILTER_IDS.ALL,
  pagination: {
    page: 1,
    offset: 10,
    total: 0
  },
  selectedPeopleIds: [],
  fieldsOfSections: {},
  isAddPersonModalOpened: false,
  requestsCols: {},
  requestSummary: [],
  allDueDates: [],
  activeSummaryTab: SUMMARY_TAB_IDS.DUE_DATES
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    filterPeople: R.identity,
    sortPeople: R.identity,
    goToViewAllReports: R.identity,
    showAddStaffModal: R.identity,
    toggleCollapsibleClick: R.identity,
    duplicatePerson: R.identity,
    removePerson: R.identity,
    handleSelectedPeopleIds: R.identity,
    showAddPersonModal: R.always({ isAddPersonModalOpened: true }),
    refetchData: R.identity,
    submitRequest: R.identity,
    editInfo: R.identity,
    editRequest: R.identity,
    openDocument: R.identity,
    setInitialData: (_, { payload: { data } }) => ({
      data,
      fieldsOfSections: data.section_columns,
      collapsableIds: R.compose(
        R.reduce(
          (acc, person) => ({
            ...acc,
            [person.id]: false
          }),
          {}
        ),
        R.propOr([], "people")
      )(data),
      openedRequestTabs: {},
      requestsCols: R.path(
        ["section_columns", AVAILABLE_SECTIONS.REQUESTS],
        data
      )
    }),
    setSummaryData: (_, { payload: { dueDates, summary } }) => ({
      requestSummary: summary,
      allDueDates: dueDates
    }),
    toggleCollapsibleId: (state, { payload: id }) => ({
      collapsableIds: R.assoc(
        id,
        !R.propOr(true, id, state.collapsableIds),
        state.collapsableIds
      )
    }),
    selectTabOfRequestType: (
      state,
      { payload: { personId, requestTypeId, requestGroupId } }
    ) => ({
      openedRequestTabs: R.assocPath(
        [personId, requestTypeId],
        requestGroupId,
        state.openedRequestTabs
      )
    }),
    toggleSelectedPeopleIds: ({ selectedPeopleIds }, { payload: id }) => ({
      selectedPeopleIds: R.contains(id, selectedPeopleIds)
        ? R.without([id], selectedPeopleIds)
        : R.concat([id], selectedPeopleIds)
    }),
    clearSelectedPeopleIds: () => ({
      selectedPeopleIds: []
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
