import React, { Component } from "react";
import * as R from "ramda";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { ACCOUNTS, CONTACTS } from "utils/standard-module-field-ids";
import {
  Div,
  Text1,
  StarIcon,
  FontIcon,
  Text2,
  Text4,
  CloseIcon,
  MediumAvatar,
  BigOutlineButton,
  LeftIcon,
  HalfSizeDotIcon,
  BigInsetInput,
  SmallShadedButton,
  BigTextInput,
  SearchIcon,
  LoadingIcon,
  SmallCheckbox,
  PopoverMenu,
  SmallClearButton,
  MoreIcon,
  SmallOutlineInput,
  CheckIcon,
  MediumFilledButton,
  MediumOutlineButton,
  Modal as BaseModal,
  modalHandler
} from "components/Base";
import Avatar from "components/PortalV2/GroupManager/ScalableAvatar";
import ContactProfileController from "components/Global/Controllers/ContactProfile/WithoutMoreInfo";
import { withHandlers, withState } from "utils/General";
import { Modal } from "Passes/GuestLists/AddRecordModal";
import SearchAccounts from "components/Global/Controllers/SearchAccount";
import { withRouter } from "react-router";
import Helpers from "utils/Global/Helpers";
import TypePicker from "components/Global/StandAloneEditors/Types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";
import { createPortalUserFromContact } from "redux/modules/user/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { track } from "redux/modules/analytics/actions";
import { hideModal } from "redux/modules/modal/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

function mapStateToProps(state, props) {
  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    accountRecordTypes: recordTypes(state, STANDARD_MODULES.accounts.id),
    contactName: props.contactName || props.name,
    recordTypeColor:
      props.recordTypeColor ||
      R.path(["contact", "type", "background_color"])(props),
    recordTypeIcon:
      props.recordTypeIcon || R.path(["contact", "type", "icon"])(props)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      addRecord,
      addRelationship,
      addValue,
      createPortalUserFromContact,
      hideModal: props.hideModal || hideModal,
      track,
      getRecordTypes
    },
    dispatch
  );
}

export const EditableValue = ({
  toggled,
  placeholder,
  updatedValue,
  setUpdatedValue,
  saveUpdatedValue,
  setToggle,
  originalValue,
  validateEmail,
  readOnly,
  ...props
}) => {
  const isValid =
    toggled && validateEmail ? Helpers.isValidEmail(updatedValue) : true;

  if (readOnly) {
    return originalValue && originalValue.length ? (
      <Div display="row.flex-start.center" {...props}>
        {originalValue}
      </Div>
    ) : null;
  }

  return toggled ? (
    <Div display="row.flex-start.center" {...props}>
      <SmallOutlineInput
        continuous
        placeholder={placeholder}
        value={updatedValue}
        onChange={setUpdatedValue}
      />
      <Div
        ml={2}
        b={1}
        bc={isValid ? "altA5" : "gray5"}
        bra={1}
        display="row.center.center"
        onClick={
          isValid
            ? () => {
                saveUpdatedValue(updatedValue);
                setToggle(false);
              }
            : undefined
        }
      >
        <CheckIcon color={isValid ? "altA5" : "gray5"} />
      </Div>
      <Div
        ml={1}
        b={1}
        bc="gray5"
        bra={1}
        display="row.center.center"
        onClick={() => setToggle(false)}
      >
        <CloseIcon />
      </Div>
    </Div>
  ) : (
    <Div
      fs={1}
      underline={readOnly ? false : true}
      color={
        readOnly
          ? "gray6"
          : {
              default: "gray6",
              hover: "gray7"
            }
      }
      onClick={
        readOnly
          ? undefined
          : () => {
              setUpdatedValue(originalValue);
              setToggle(true);
            }
      }
      {...props}
    >
      {originalValue && originalValue.length ? originalValue : placeholder}
    </Div>
  );
};

const AddNewAccountModal = modalHandler()(
  R.compose(
    withState("isPrimary", "setIsPrimary", true),
    withState("hasLoginAccess", "setHasLoginAccess", props =>
      !props.hasEmail || props.isViewingAsOrg ? false : true
    ),
    withState("type", "setType", null),
    withState("values", "setValues", {}),
    withHandlers({
      setValue: ({ values, setValues }) => (name, value) => {
        setValues({ ...values, [name]: value });
      }
    })
  )(
    ({
      showModal,
      closeModal,
      values: { role, name },
      setValue,
      isModalShowing,
      onCreateAccount,
      setIsPrimary,
      isPrimary,
      hasLoginAccess,
      setHasLoginAccess,
      type,
      setType,
      accountRecordTypes,
      isViewingAsOrg,
      hasEmail
    }) => {
      const defaultRecordTypeId = R.compose(
        R.prop("id"),
        R.find(R.propEq("is_default", true))
      )(accountRecordTypes);
      const recordTypeName = R.compose(
        R.prop("name"),
        R.find(R.propEq("id", type || defaultRecordTypeId))
      )(accountRecordTypes);
      return (
        <div>
          <Text2 onClick={showModal} bold color="purple8">
            + Create a new group
          </Text2>
          <BaseModal background="translucent" isModalShowing={isModalShowing}>
            <Div bg="white" width={450} bra={1} py={5} px={10}>
              <Div
                display="row.flex-start.center"
                color="primary8"
                onClick={closeModal}
                fw={3}
                fs={2}
                mb={5}
              >
                <LeftIcon color="primary8" size={20} mr={1} />
                <span>Back to results</span>
              </Div>
              <Text2>Account Name*</Text2>
              <BigInsetInput
                onChange={val => setValue("name", val)}
                mb={3}
                display="row.flex-start.center"
              />
              <Text2>Role with this group</Text2>
              <BigInsetInput
                onChange={val => setValue("role", val)}
                mb={3}
                display="row.flex-start.center"
              />

              {isViewingAsOrg ? null : (
                <Div display="column" mb={4}>
                  <Text2>Record Type</Text2>
                  <TypePicker
                    value={recordTypeName}
                    list={[...accountRecordTypes]}
                    onSelect={({ id }) => setType(id)}
                  />
                </Div>
              )}

              <Div
                display="row.flex-start.center"
                onClick={() => setIsPrimary(!isPrimary)}
                mb={isViewingAsOrg ? 3 : 0}
              >
                <SmallCheckbox onClick={() => {}} selected={isPrimary} mr={2} />
                <Div fw={3} fs={1} color="gray7">
                  Mark as primary person
                </Div>
              </Div>

              {!hasEmail || isViewingAsOrg ? null : (
                <Div
                  display="row.flex-start.center"
                  onClick={() => setHasLoginAccess(!hasLoginAccess)}
                  mb={3}
                >
                  <SmallCheckbox
                    onClick={() => {}}
                    selected={hasLoginAccess}
                    mr={2}
                  />
                  <Div fw={3} fs={1} color="gray7">
                    Give login access to this group's portal
                  </Div>
                </Div>
              )}

              <Div display="row.flex-start.center">
                <MediumFilledButton
                  mr={2}
                  bg="altB5"
                  onClick={() => {
                    onCreateAccount({
                      role,
                      name,
                      isPrimary,
                      hasLoginAccess,
                      recordTypeId: type
                    });
                    closeModal();
                  }}
                  disabled={!name}
                >
                  Add
                </MediumFilledButton>
                <MediumOutlineButton onClick={closeModal}>
                  Cancel
                </MediumOutlineButton>
              </Div>
            </Div>
          </BaseModal>
        </div>
      );
    }
  )
);

const SelectAccountModal = modalHandler()(
  R.compose(
    withState("role", "setRole"),
    withState("isPrimary", "setIsPrimary", true),
    withState("hasLoginAccess", "setHasLoginAccess", props =>
      !props.hasEmail || props.isViewingAsOrg ? false : true
    )
  )(
    ({
      showModal,
      closeModal,
      role,
      setRole,
      isModalShowing,
      onAdd,
      id,
      name,
      setIsPrimary,
      isPrimary,
      setHasLoginAccess,
      hasLoginAccess,
      hasEmail,
      isViewingAsOrg
    }) => (
      <div>
        <SmallShadedButton onClick={showModal}>Select</SmallShadedButton>
        <BaseModal background="translucent" isModalShowing={isModalShowing}>
          <Div bg="white" width={450} bra={1} py={5} px={10}>
            <Div pb={4} display="row.flex-start.center">
              <MediumAvatar text={name} />
              <Text2 bold ml={2}>
                {name}
              </Text2>
            </Div>

            <Div pb={1} display="row.space-between.flex-end">
              <Text2>Role with this group</Text2>
              <Text2 italic color="neutral4">
                Optional
              </Text2>
            </Div>

            <BigInsetInput
              continuous
              onChange={val => setRole(val)}
              mb={3}
              display="row.flex-start.center"
            />

            <Div
              display="row.flex-start.center"
              onClick={() => setIsPrimary(!isPrimary)}
              mb={!hasEmail || isViewingAsOrg ? 3 : 0}
            >
              <SmallCheckbox onClick={() => {}} selected={isPrimary} mr={2} />
              <Div fw={3} fs={1} color="gray7">
                Mark as primary person
              </Div>
            </Div>

            {!hasEmail || isViewingAsOrg ? null : (
              <Div
                display="row.flex-start.center"
                onClick={() => setHasLoginAccess(!hasLoginAccess)}
                mb={3}
              >
                <SmallCheckbox
                  onClick={() => {}}
                  selected={hasLoginAccess}
                  mr={2}
                />
                <Div fw={3} fs={1} color="gray7">
                  Give login access to this group's portal
                </Div>
              </Div>
            )}

            <Div display="row.flex-start.center">
              <MediumFilledButton
                mr={2}
                bg="altB5"
                onClick={() => {
                  onAdd({ id, role, isPrimary, hasLoginAccess });
                  closeModal();
                }}
              >
                Add group
              </MediumFilledButton>
              <MediumOutlineButton onClick={closeModal}>
                Cancel
              </MediumOutlineButton>
            </Div>
          </Div>
        </BaseModal>
      </div>
    )
  )
);

function relatedAccounts(Comp) {
  class RelatedAccounts extends Component {
    state = { loading: false };

    componentDidMount() {
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULES.accounts.id,
        options: {
          orgId: this.props.orgDetails.id,
          eventId: this.props.eventDetails.id
        }
      });
    }

    componentWillUnmount() {
      if (this.props.onDone) {
        this.props.onDone();
      }
    }

    goToAccountProfile = (accountId, newWindow) => {
      const url =
        this.props.orgDetails && this.props.orgDetails.id
          ? `/org-light/${this.props.orgDetails.id}/account/${accountId}`
          : `/event-light/${this.props.eventDetails.id}/account/${accountId}`;
      if (newWindow) {
        window.open(url, "_blank");
      } else {
        this.props.router.push({
          pathname: url
        });
      }
    };

    handleAddAccount = async ({
      id,
      role,
      name,
      isPrimary,
      hasLoginAccess,
      recordTypeId
    }) => {
      this.setState({ loading: true });

      const orgId = this.props.orgDetails.id;
      const eventId = this.props.eventDetails.id;
      let accountId = id;

      if (
        accountId &&
        this.props.contact.accounts.find(c => c.account_id === accountId)
      ) {
        this.setState({ loading: false });
        return false;
      }
      if (!accountId) {
        if (!name) {
          this.setState({ loading: false });
          return false;
        }
        // If we need to create a account, create it
        const createdAccount = await this.props.addRecord({
          moduleId: STANDARD_MODULES.accounts.id,
          typeId: recordTypeId,
          record: {
            [ACCOUNTS.NAME]: {
              type: "text",
              value: name
            }
          },
          options: {
            orgId,
            eventId
          }
        });

        // Update the id
        accountId = createdAccount.id;
      }

      // Associate the contact with the account
      await this.props.addRelationship({
        orgId,
        eventId,
        isPrimary,
        role,
        accountId,
        contactId: this.props.contact.id
      });

      this.props.onShowAddAnother(false);

      if (hasLoginAccess) {
        await this.props.createPortalUserFromContact({
          accountId: id,
          contactId: this.props.contact.id,
          eventId
        });
      }

      if (this.props.onAddAccount) {
        this.props.onAddAccount();
      }

      await this.props.fetchProfile();
      this.setState({ loading: false });

      return null;
    };

    handleEditRole = async row => {
      this.props.showEditRelationshipModal(row);
    };

    handleRemoveAccount = async row => {
      this.setState({ loading: true });
      await this.props.removeRelatedContact(row);
      this.setState({ loading: false });
    };

    handleTogglePrimaryContact = async (
      relationshipId,
      accountId,
      isPrimary
    ) => {
      this.setState({ loading: true });
      await this.props.updateRelatedContact({
        accountId,
        relationshipId,
        isPrimary
      });
      this.setState({ loading: false });
    };

    saveUpdatedEmail = async email => {
      this.setState({ loading: true });
      await this.props.addValue({
        fieldId: CONTACTS.EMAIL,
        value: { type: "text", value: email },
        moduleId: STANDARD_MODULES.contacts.id,
        recordId: this.props.contact.id
      });
      await this.props.fetchProfile();
      this.setState({ loading: false });
    };

    render() {
      const isViewingAsOrg = Boolean(
        this.props.orgDetails && this.props.orgDetails.id
      );

      const relatedAccounts = R.map(a => {
        return {
          id: a.relationship_id,
          name: a.name,
          accountId: a.account_id,
          canLogin: Boolean(a.can_login),
          isPrimary: a.is_primary,
          recordTypeId: a.type.id,
          recordTypeName: isViewingAsOrg ? null : a.type.name,
          ...a
        };
      })(
        this.props.contact && this.props.contact.accounts
          ? this.props.contact.accounts
          : []
      );
      return (
        <Comp
          {...{
            loading: this.state.loading,
            onAddAccount: this.handleAddAccount,
            onEditRole: this.handleEditRole,
            onRemoveContact: this.handleRemoveAccount,
            onToggleLoginAccess: this.props.onToggleLoginAccess,
            onTogglePrimaryContact: this.handleTogglePrimaryContact,
            contactName: this.props.contactName,
            contactEmail: this.props.email,
            contacts: this.props.contacts,
            recordTypeColor: this.props.recordTypeColor,
            recordTypeIcon: this.props.recordTypeIcon,
            showAddAnother: this.props.showAddAnother,
            onShowAddAnother: this.props.onShowAddAnother,
            goToAccountProfile: this.goToAccountProfile,
            showLoginDetailsModal: this.props.showLoginDetailsModal,
            relatedAccounts: relatedAccounts,
            showChangeAccountRecordTypeModal: this.props
              .showChangeAccountRecordTypeModal,
            accountRecordTypes: this.props.accountRecordTypes,
            isViewingAsOrg,
            hasEmail: Boolean(this.props.email && this.props.email.length),
            saveEmailAddress: this.saveUpdatedEmail,
            hideModal: this.props.hideModal
          }}
        />
      );
    }
  }
  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      R.compose(
        withState("accounts", "setAccounts", props => props.accounts || []),
        withState("showAddAnother", "onShowAddAnother", true)
      )(RelatedAccounts)
    )
  );
}

const View = R.compose(
  withState("editingEmail", "setEditingEmail", false),
  withState("updatedEmail", "setUpdatedEmail", props => props.email)
)(
  ({
    contactName,
    contactEmail,
    onAddAccount,
    onRemoveContact,
    recordTypeColor,
    recordTypeIcon,
    showAddAnother,
    onShowAddAnother,
    onEditRole,
    onTogglePrimaryContact,
    onToggleLoginAccess,
    showLoginDetailsModal,
    goToAccountProfile,
    showChangeAccountRecordTypeModal,
    relatedAccounts,
    accountRecordTypes,
    loading,
    isViewingAsOrg,
    hasEmail,
    editingEmail,
    setEditingEmail,
    saveEmailAddress,
    updatedEmail,
    setUpdatedEmail
  }) => (
    <SearchAccounts limit={15}>
      {({ isSearching, results, searchTerm, onSearch, clearSearch }) => {
        const accountIds = R.map(R.prop("contactId"))(relatedAccounts);
        const cleanResults = R.reject(a => accountIds.includes(a.id))(results);
        return (
          <Div width={1}>
            <Div display="row.space-between.center" mb={5}>
              <Div display="row.flex-start.center" flex={1}>
                <Avatar
                  size={29}
                  style={{ backgroundColor: recordTypeColor }}
                  mr={2}
                  content={
                    <FontIcon
                      style={{
                        fontSize: 16,
                        color: "rgba(255,255,255,0.6"
                      }}
                    >
                      {recordTypeIcon}
                    </FontIcon>
                  }
                />
                <Div display="column" fw={3} fs={6} color="gray7">
                  <Div>{contactName}</Div>
                  <Div>
                    <EditableValue
                      {...{
                        toggled: editingEmail,
                        placeholder: "Add email",
                        updatedValue: updatedEmail,
                        setUpdatedValue: data => {
                          setUpdatedEmail(data);
                        },
                        saveUpdatedValue: data => {
                          saveEmailAddress(data);
                        },
                        setToggle: setEditingEmail,
                        originalValue: contactEmail,
                        validateEmail: true,
                        mb: 1,
                        readOnly: false
                      }}
                    />
                  </Div>
                </Div>
              </Div>
              {loading ? (
                <LoadingIcon size={30} color="neutral4" mr={2} />
              ) : null}
            </Div>

            <Div bg="gray0" width={1} p={6} bra={1} mb={3}>
              {Boolean(relatedAccounts.length) && (
                <Div bb={1} pb={2} bc="neutral3" mb={3}>
                  <Text2 pb={1} bold>
                    Related Groups:
                  </Text2>
                  {relatedAccounts.map(a => (
                    <Div key={a.id} py={1} display="row.space-between.center">
                      <Div display="row.flex-start.center">
                        {a.canLogin ? (
                          <HalfSizeDotIcon
                            color={"primary7"}
                            size={16}
                            tooltip="Has portal login access"
                          />
                        ) : null}
                        {a.isPrimary ? (
                          <StarIcon
                            color="yellow5"
                            size={16}
                            tooltip="Primary contact"
                          />
                        ) : null}
                        <Text2
                          ml={1}
                          bold
                          color="primary8"
                          onClick={() => goToAccountProfile(a.accountId, true)}
                        >
                          {a.name}
                        </Text2>
                        <Text1 ml={2} color="gray6">
                          {[a.recordTypeName]
                            .filter(v => v && v.length)
                            .join(" · ")}
                        </Text1>
                      </Div>
                      <Div display="row.flex-end.center">
                        <Text1>{a.role}</Text1>

                        <PopoverMenu
                          Label={({ onClick }) => (
                            <SmallClearButton
                              ml={2}
                              OnlyIcon={MoreIcon}
                              onClick={onClick}
                            />
                          )}
                          menuItems={[
                            [
                              "Open Record",
                              () => goToAccountProfile(a.accountId, true)
                            ],
                            ["Change Role", () => onEditRole(a)],
                            [
                              a.isPrimary
                                ? "Remove as primary"
                                : "Mark as primary",
                              () =>
                                onTogglePrimaryContact(
                                  a.id,
                                  a.accountId,
                                  !a.isPrimary
                                )
                            ],
                            isViewingAsOrg
                              ? null
                              : a.canLogin || hasEmail
                              ? [
                                  a.canLogin
                                    ? "Remove login access"
                                    : "Give login access",
                                  () => onToggleLoginAccess(a)
                                ]
                              : null,
                            isViewingAsOrg
                              ? null
                              : a.canLogin
                              ? [
                                  "Login Details",
                                  () => showLoginDetailsModal(a)
                                ]
                              : null,
                            isViewingAsOrg
                              ? null
                              : [
                                  "Change Type",
                                  () =>
                                    showChangeAccountRecordTypeModal(
                                      a.accountId,
                                      a.recordTypeId
                                    )
                                ],
                            ["Remove Related Group", () => onRemoveContact(a)]
                          ].filter(o => o)}
                        />
                      </Div>
                    </Div>
                  ))}
                </Div>
              )}

              {relatedAccounts.length && !showAddAnother ? (
                <Div mt={2}>
                  <Text2
                    bold
                    color="purple8"
                    onClick={() => onShowAddAnother(true)}
                  >
                    + Add another
                  </Text2>
                </Div>
              ) : (
                <div>
                  <Text4 mb={2} bold>
                    Add {relatedAccounts.length ? "another" : "an"} account
                    related to <strong>{contactName}</strong>
                  </Text4>
                  <Div>
                    <Div width={1} shadow={1} bg="white" bra={1}>
                      <BigTextInput
                        RightIcon={isSearching ? LoadingIcon : SearchIcon}
                        onChange={onSearch}
                        placeholder="Find an account..."
                        value={searchTerm}
                        continuous
                        display="row.flex-start.center"
                        style={{
                          border: "1px solid #eee"
                        }}
                      />
                    </Div>
                    {searchTerm ? (
                      <Text1 bold mt={4}>
                        Results:
                      </Text1>
                    ) : (
                      ""
                    )}
                    {cleanResults.length || searchTerm ? (
                      <Div bg="neutral0" bra={1} p={3} fs={3}>
                        {!cleanResults.length && searchTerm ? (
                          <Div textAlign="center" width={1}>
                            No results for '{searchTerm}'
                          </Div>
                        ) : (
                          cleanResults.map(({ id, name }) => (
                            <Div
                              key={id}
                              display="row.flex-start.center"
                              mt={2}
                            >
                              <Text2 bold flex={1}>
                                {name}
                              </Text2>
                              <SelectAccountModal
                                id={id}
                                isViewingAsOrg={isViewingAsOrg}
                                name={name}
                                hasEmail={hasEmail}
                                onAdd={({
                                  role,
                                  isPrimary,
                                  hasLoginAccess
                                }) => {
                                  onAddAccount({
                                    id,
                                    name: name,
                                    role,
                                    isPrimary,
                                    hasLoginAccess
                                  });
                                  clearSearch();
                                }}
                              />
                            </Div>
                          ))
                        )}
                      </Div>
                    ) : null}
                    <Div px={4}>
                      <Text2 pt={6} pb={4}>
                        Can't find who you are looking for?
                      </Text2>
                      <AddNewAccountModal
                        hasEmail={hasEmail}
                        isViewingAsOrg={isViewingAsOrg}
                        accountRecordTypes={accountRecordTypes}
                        onCreateAccount={data => {
                          onAddAccount(data);
                          clearSearch();
                        }}
                      />
                    </Div>
                  </Div>
                </div>
              )}
            </Div>
          </Div>
        );
      }}
    </SearchAccounts>
  )
);

const ModalView = props => (
  <Modal
    hideModal={props.hideModal}
    heading="Add Related Groups"
    footer={[
      <Div flex={1} />,
      <BigOutlineButton onClick={props.hideModal}>Done</BigOutlineButton>
    ]}
    body={<View {...props} />}
  />
);

export const RelatedAccountsView = ContactProfileController(
  relatedAccounts(View)
);
export const RelatedAccountsModal = ContactProfileController(
  relatedAccounts(ModalView)
);

export default RelatedAccountsView;
