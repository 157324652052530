import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "Items/Manage";

import { bindInstance } from "redux-mvc";

import { COLUMNS } from "Items/Manage/View/PassesTable";
import { ACTION_CELLS } from "Items/Manage/constants";

import { MediumOutlineButton, Text2 } from "components/Base";
import { Paper, Header } from "./Common";

import { PassesTable } from "./PassesTable";
import BulkActionsButton from "./BulkActionsButton";

import ArrayLayout from "ui-kit/ArrayLayout";

const decorate = connect(
  null,
  bindInstance({
    viewProfile: actions.viewContactProfile
  })
);

const ByPerson = ({
  person,
  passes,
  allLineItemIds,
  allCredentialLineItemIds,
  allMealLineItemIds,
  handlers,
  viewProfile,
  mode,
  instanceId
}) => {
  const caption = (
    <Text2 ml={4}>
      {[person.email, person.accountName]
        .filter(v => v && v.length)
        .join(" · ")}
    </Text2>
  );

  const buttons = [
    [
      <MediumOutlineButton
        onClick={() => viewProfile(person.id)}
        key="viewProfile"
      >
        View Profile
      </MediumOutlineButton>,
      <BulkActionsButton
        cardType="byPerson"
        mode={mode}
        meta={{
          allLineItemIds,
          allCredentialLineItemIds,
          allMealLineItemIds
        }}
        handlers={{
          ...handlers,
          sendEmail: () =>
            handlers.sendEmail({
              type: "contact",
              recordId: person.id
            })
        }}
        key="bulkActions"
      />
    ]
  ];

  return (
    <Paper>
      <Header
        title={person.name}
        caption={caption}
        statuses={person.statuses}
      />
      <PassesTable
        columns={R.props(
          ["quantity", "item", "details", "order", ACTION_CELLS[mode]],
          COLUMNS
        )}
        rows={passes}
        instanceId={instanceId}
      />
      <ArrayLayout components={buttons} m={2} />
    </Paper>
  );
};

ByPerson.defaultProps = {
  handlers: {},
  mode: "issuance"
};

export default decorate(ByPerson);
